import types from './types'

const initialState = {
  
  articleList: {},
  
}

const articleReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.GET_ARTICLE_RESPONSE:
      // console.log('action: ', action)
      return {
        ...state,
        articleList: action.payload,
      }
    
    default:
      return state

  }
}

export default articleReducer;