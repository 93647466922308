const types = {
  //userDetail
  USER_DETAIL: "USER_DETAIL",
  USER_DETAIL_RESPONSE: "USER_DETAIL_RESPONSE",
  //userDetail Broker
  USER_DETAIL_BROKER: "USER_DETAIL_BROKER",
  USER_DETAIL_BROKER_RESPONSE: "USER_DETAIL_BROKER_RESPONSE",
  USER_DETAIL_BROKER_CLEAR: "USER_DETAIL_BROKER_CLEAR",

  //userDetail Iframe User
  USER_DETAIL_IFRAME_USER: "USER_DETAIL_IFRAME_USER",
  USER_DETAIL_IFRAME_USER_RESPONSE: "USER_DETAIL_IFRAME_USER_RESPONSE",
  USER_DETAIL_IFRAME_USER_CLEAR: "USER_DETAIL_IFRAME_USER_CLEAR",

  //userUpdate
  USER_UPDATE: "USER_UPDATE",
  USER_UPDATE_RESPONSE: "USER_UPDATE_RESPONSE",
  USER_UPDATE_CLEAR: "USER_UPDATE_CLEAR",

  //select user
  SELECT_USER: "SELECT_USER",
  SELECTED_TEAM_LEAD: "SELECT_TEAM_LEAD",
  SELECTED_MENTOR: "SELECT_MENTOR",
  SELECTED_COACH: "SELECT_COACH",

  //search user
  SEARCH_USER_REQ: "SEARCH_USER_REQ",
  SEARCH_USER_RES: "SEARCH_USER_RES",

  //search user
  ON_UPLOAD_REQ: "ON_UPLOAD_REQ",
  ON_UPLOAD_RES: "ON_UPLOAD_RES",

  //upload user image
  ON_UPLOAD_REQ_BROKER: "ON_UPLOAD_REQ_BROKER",
  ON_UPLOAD_RES_BROKER: "ON_UPLOAD_RES_BROKER",

  //  User is premium
  SET_USER_PREMIUM: "SET_USER_PREMIUM",

  // Profile complete percentage
  GET_PROFILE_PERCENTAGE: "GET_PROFILE_PERCENTAGE",
  GET_PROFILE_PERCENTAGE_RESPONSE: "GET_PROFILE_PERCENTAGE_RESPONSE",
  GET_PROFILE_PERCENTAGE_CLEAR: "GET_PROFILE_PERCENTAGE_CLEAR",

  // Role
  SET_ROLE: "SET_ROLE",
  SET_ROLE_CLEAR: "SET_ROLE_CLEAR",
  SET_IFRAME_USER_ROLE: "SET_IFRAME_USER_ROLE",
  SET_IFRAME_USER_ROLE_CLEAR: "SET_IFRAME_USER_ROLE_CLEAR",

  // Other Role
  SET_OTHER_ROLE: "SET_OTHER_ROLE",
  SET_OTHER_ROLE_CLEAR: "SET_OTHER_ROLE_CLEAR",

  // Session Count
  SET_SESSION_COUNT: "SET_SESSION_COUNT",
  SET_SESSION_COUNT_CLEAR: "SET_SESSION_COUNT_CLEAR",


  // GET_LICENSED_AS
  GET_LICENSED_AS: "GET_LICENSED_AS",
  GET_LICENSED_AS_RESPONSE: "GET_LICENSED_AS_RESPONSE",
  GET_LICENSED_AS_CLEAR: "GET_LICENSED_AS_CLEAR",

  // Banner Card Summary
  BANNER_CARD_SUMMARY: "BANNER_CARD_SUMMARY",
  BANNER_CARD_SUMMARY_RESPONSE: "BANNER_CARD_SUMMARY_RESPONSE",
  BANNER_CARD_SUMMARY_CLEAR: "BANNER_CARD_SUMMARY_CLEAR",

  // GET_ROLE_REQUESTED
  GET_ROLE_REQUESTED: "GET_ROLE_REQUESTED",
  GET_ROLE_REQUESTED_RESPONSE: "GET_ROLE_REQUESTED_RESPONSE",
  GET_ROLE_REQUESTED_CLEAR: "GET_ROLE_REQUESTED_CLEAR",

  // ACCEPT_ROLE_REQUESTED
  ACCEPT_ROLE_REQUESTED: "ACCEPT_ROLE_REQUESTED",
  ACCEPT_ROLE_REQUESTED_RESPONSE: "ACCEPT_ROLE_REQUESTED_RESPONSE",
  ACCEPT_ROLE_REQUESTED_CLEAR: "ACCEPT_ROLE_REQUESTED_CLEAR",


  // ACCEPT_ROLE_REQUESTED
  GET_USER_OF_ROLE_ACCEPTED: "GET_USER_OF_ROLE_ACCEPTED",
  GET_USER_OF_ROLE_ACCEPTED_RESPONSE: "GET_USER_OF_ROLE_ACCEPTED_RESPONSE",
  GET_USER_OF_ROLE_ACCEPTED_CLEAR: "GET_USER_OF_ROLE_ACCEPTED_CLEAR",

  // USER ROLE CANCEL FUNCTIONALITY
  USER_ROLE_CANCEL: "USER_ROLE_CANCEL",
  USER_ROLE_CANCEL_RESPONSE: "USER_ROLE_CANCEL_RESPONSE",
  USER_ROLE_CANCEL_CLEAR: "USER_ROLE_CANCEL_CLEAR",
  // USER ROLE REQUEST STATUS  FUNCTIONALITY
  USER_ROLE_REQUEST_STATUS: "USER_ROLE_REQUEST_STATUS",
  USER_ROLE_REQUEST_STATUS_RESPONSE: "USER_ROLE_REQUEST_STATUS_RESPONSE",
  USER_ROLE_REQUEST_STATUS_CLEAR: "USER_ROLE_REQUEST_STATUS_CLEAR",

  TIMEZONE_ARRAY_FETCH: "TIMEZONE_ARRAY_FETCH",
  TIMEZONE_ARRAY_RESPONSE: "TIMEZONE_ARRAY_RESPONSE",
  TIMEZONE_ARRAY_ERROR: "TIMEZONE_ARRAY_ERROR",
  TIMEZONE_ARRAY_CLEAR: "TIMEZONE_ARRAY_CLEAR",

  TIMEZONE_ABBR_FETCH: "TIMEZONE_ABBR_FETCH",
  TIMEZONE_ABBR_RESPONSE: "TIMEZONE_ABBR_RESPONSE",
  TIMEZONE_ABBR_CLEAR: "TIMEZONE_ABBR_CLEAR",

  IS_MOBILE_WEBVIEW: "IS_MOBILE_WEBVIEW",
  IS_MOBILE_WEBVIEW_CLEAR: "IS_MOBILE_WEBVIEW_CLEAR",


  //user's post upload
  POST_UPLOAD: "POST_UPLOAD",
  POST_UPLOAD_RESPONSE: "POST_UPLOAD_RESPONSE",
  POST_UPLOAD_CLEAR: "POST_UPLOAD_CLEAR",

  //user's post upload
  EDIT_UPLOAD: "EDIT_UPLOAD",
  EDIT_UPLOAD_RESPONSE: "EDIT_UPLOAD_RESPONSE",
  EDIT_UPLOAD_CLEAR: "EDIT_UPLOAD_CLEAR",

  //user's post upload
  LEADERBOARD: "LEADERBOARD",
  LEADERBOARD_RESPONSE: "LEADERBOARD_RESPONSE",
  LEADERBOARD_CLEAR: "LEADERBOARD_CLEAR",

  ASSESSMENT: "ASSESMENT",
  ASSESSMENT_CLEAR: "ASSESMENT_CLEAR",

  //user's post upload
  USER_POINTS: "USER_POINTS",
  USER_POINTS_RESPONSE: "USER_POINTS_RESPONSE",
  USER_POINTS_CLEAR: "USER_POINTS_CLEAR",

  // Check list approval manager section
  CHECKLITST_APPROVAL_LIST: "CHECKLITST_APPROVAL_LIST",
  CHECKLITST_APPROVAL_LIST_RESPONSE: "CHECKLITST_APPROVAL_LIST_RESPONSE",
  CHECKLITST_APPROVAL_LIST_CLEAR: "CHECKLITST_APPROVAL_LIST_CLEAR",

   // Check list approval status manager section
   CHECKLITST_APPROVAL_STATUS: "CHECKLITST_APPROVAL_STATUS",
   CHECKLITST_APPROVAL_STATUS_RESPONSE: "CHECKLITST_APPROVAL_STATUS_RESPONSE",
   CHECKLITST_APPROVAL_STATUS_CLEAR: "CHECKLITST_APPROVAL_STATUS_CLEAR",


   //CHANGE PASSWORD
     //userDetail Iframe User
  CHANGE_PASSWORD_REQ: "CHANGE_PASSWORD_REQ",
  CHANGE_PASSWORD_RESPONSE: "CHANGE_PASSWORD_RESPONSE",
  CHANGE_PASSWORD_RES_CLEAR: "CHANGE_PASSWORD_RES_CLEAR",

   UPDATE_IS_MOBILE: "UPDATE_IS_MOBILE",
   UPDATE_IS_MOBILE_CLEAR: "UPDATE_IS_MOBILE_CLEAR",

}

export default types;