import React, { useState } from "react";
import "./section.css";
import ChipSection from "./chipSection";
import { useEffect } from "react";
const FeedbackSectionOne = ({
  sectionOneName,
  sectionTwoName,
  courseData,
  moduleData,
  setModuleData,
  setModuleSelected,
  moduleSelected,
  setSelectedCourse,
  selectedCourse,
  isLoading,
  error,
}) => {
  useEffect(() => {
    const selectedCourseModules = courseData?.find(
      (obj) => obj.name === selectedCourse
    )?.modules;
    setModuleData(selectedCourseModules);
    setModuleSelected(selectedCourseModules?.[0]?.name);
  }, [selectedCourse]);

  return (
    <div className="feedback--section--one">
      <div className="section-one">
        <ChipSection
          chipSelected={selectedCourse}
          setChipSelected={setSelectedCourse}
          sectionName={sectionOneName}
          chipData={courseData}
        />
      </div>
      <div className="section-two">
        <ChipSection
          chipSelected={moduleSelected}
          setChipSelected={setModuleSelected}
          sectionName={sectionTwoName}
          chipData={moduleData}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </div>
  );
};

export default FeedbackSectionOne;
