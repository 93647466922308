import _ from "lodash";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {
  getAnyTotalReportClear,
  getProfileCompletionReportClear,
} from "../../../redux/reports/actions";
import NextArrow from "../../LearningPaths/NextArrow";
import PrevArrow from "../../LearningPaths/PrevArrow";
import Pagination from "../../../pages/Orders/Pagination";
import exportFromJSON from "export-from-json";
import EventDetailShowModal from "../../modal/EventDetailShowModal";
import * as XLSX from "xlsx";
import ReportGraphSection from "../ReportGraphSection";

const fileName = "EventReport";
const exportType = "xls";
let PageSize = 20;
const EventTableSeaction = (props) => {
  const {
    setSearchInput,
    getFilteredData,
    setIsLoading,
    isLoading,
    isView,
    statusFilterData,
    setCanShow,
    type,
  } = props;

  const dispatch = useDispatch();
  const profileReportRes = useSelector((state) => state.report.profileReport);
  const anyTotalReportRes = useSelector((state) => state.report.anyTotalReport);
  const [profileReports, setProfileReports] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);

  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true);
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, profileReports]);

  // useEffect(() => {
  //   setConfirmModal(true)
  // }, currentItem)

  useEffect(() => {
    if (isLoading) {
      window.scroll({
        top: 100,
        left: 0,
        behavior: "smooth",
      });
      setSeries([]);
      setLegends([]);
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log('profileReportRes: ', profileReportRes)
    if (!_.isEmpty(profileReportRes)) {
      setIsLoading(false);
      setSeries([]);
      setLegends([]);
      if (profileReportRes?.status === "success") {
        // setProfileReports(profileReportRes?.payload?.payload)
        const reportData = profileReportRes?.payload?.payload;
        setProfileReports(reportData);
        if (statusFilterData.includes("buy")) {
          series.push(reportData.filter((data) => data.type === "Buy").length);
          legends.push("Buy");
        }
        if (statusFilterData.includes("free")) {
          series.push(reportData.filter((data) => data.type === "Free").length);
          legends.push("Free");
        }
        if (statusFilterData.includes("brokerageSession")) {
          series.push(
            reportData.filter((data) => data.type === "Brokerage Session")
              .length
          );
          legends.push("Brokerage Session");
        }

        if (legends.length < 3) {
          legends.push("Others");
          // console.log('TOTALRECORD', totalRecord, series)
          series.push(totalRecord - _.sum(series));
        }
        // console.log('LEGENDS', legends)
        // console.log('SERIES', series)
        setLegends(legends);
        setSeries(series);

        setCurrentPage(1);
      } else {
        setProfileReports([]);
        setCanShow(false);
      }
      dispatch(getProfileCompletionReportClear());
    }
  }, [profileReportRes]);

  useEffect(() => {
    if (!_.isEmpty(anyTotalReportRes)) {
      if (anyTotalReportRes.status === "success") {
        setTotalRecord(anyTotalReportRes.payload.payload.length);
      }
      return () => {
        dispatch(getAnyTotalReportClear());
      };
    }
  }, [anyTotalReportRes]);

  const ExportToExcel = () => {
    const data = _.cloneDeep(profileReports);

    for (let item of data) {
      item.type =
        item?.type === undefined && item?.type === "" ? "-" : item?.type;
      item.session_name =
        item?.session_details?.session_title === undefined &&
        item?.session_details?.session_title === ""
          ? "-"
          : item?.session_details?.session_title;
    }

    const newThing = [];
    data.map((item) => {
      newThing.push(
        _.mapKeys(item, (value, key) => {
          switch (key) {
            case "name":
              return "Name";
            case "email":
              return "Email";
            case "office_name":
              return "Office Name";
            case "event_name":
              return "Event Name";
            case "session_name":
              return "Session Name";
            case "type":
              return "Type";
            default:
              return;
          }
        })
      );
    });

    for (let i = 0; i < newThing.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete newThing[i].undefined;
    }

    for (let i = 0; i < data.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete data[i]._id;
    }
    // console.log('data:', data)
    // exportFromJSON({ data, fileName, exportType })

    const worksheet = XLSX.utils.json_to_sheet(newThing);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName + ".xlsx");
  };

  const renderTableReport = () => {
    return (
      <div className="row list-coach report-table-rsp">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive1">
            <div className="tablebox">
              <table className="table mb-0 office_list table-striped">
                <thead>
                  <tr>
                    <th className="office-province">Name</th>
                    <th className="office-province">Email</th>
                    <th className="office-province">Office Name</th>
                    <th className="office-province">Event Name</th>

                    <th className="office-province">Session Name</th>

                    <th className="office-province text-start">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData?.map((item, index) => {
                    return (
                      <tr className="addBGcustomw">
                        <td>
                          <div>
                            {item?.name}

                            <EventDetailShowModal
                              confirmModal={confirmModal}
                              setConfirmModal={setConfirmModal}
                              eventDetail={currentItem}
                            />
                          </div>
                        </td>
                        <td>{item?.email}</td>
                        <td>{item?.office_name}</td>
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            // console.log('modal:1 ', item.session_details)
                            setCurrentItem(item.session_details);
                            setConfirmModal(true);
                          }}
                          dangerouslySetInnerHTML={{
                            __html: `<b>${item?.event_name}</b>`,
                          }}
                        ></td>

                        <td
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.session_details?.session_title ===
                                undefined &&
                              item?.session_details?.session_title === ""
                                ? "-"
                                : item?.session_details?.session_title,
                          }}
                        ></td>

                        <td>
                          <strong>
                            {item?.type === undefined && item?.type === ""
                              ? "-"
                              : item?.type}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={profileReports.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                window.scroll({
                  top: 100,
                  left: 0,
                  behavior: "smooth",
                });
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderGraphReport = () => {
    return (
      <ReportGraphSection
        series={series}
        legends={legends}
        isLoading={isLoading}
        type={type}
        isAmount={false}
      />
    );
  };

  return (
    <>
      <section className="table-report-layout">
        {true ? (
          <div className="container">
            <div className="row">
              <div className="col-md-5 search__input"></div>
              <div className="col-7">
                {currentTableData &&
                currentTableData?.length > 0 &&
                isView === "table" ? (
                  <button
                    className="button-reset downbtnsreport"
                    onClick={() => {
                      if (window.callbackHandler != undefined) {
                        window.callbackHandler.postMessage("downbtnsreport");
                      } else if (window.webkit != undefined) {
                        if (window.webkit.messageHandlers.callbackHandler != undefined) {
                          window.webkit.messageHandlers.callbackHandler.postMessage("downbtnsreport");
                        }
                      }

                      ExportToExcel();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#00b7f1" />
                          <stop offset="1" stop-color="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_57"
                        data-name="Ellipse 57"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_179"
                        data-name="Ellipse 179"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="file-chart"
                        d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z"
                        transform="translate(5.342 5.927)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {currentTableData && currentTableData?.length > 0 ? (
              isView === "table" ? (
                renderTableReport()
              ) : (
                renderGraphReport()
              )
            ) : profileReports !== null ? (
              <div className="not-found mt-3">
                <div className=" interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p>Sorry! No result found for your search.</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default EventTableSeaction;
