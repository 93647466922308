import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import _ from "lodash";

import SliderCourseListing from "./SliderCourseListing";
import { SessionKeys } from "../../utils/AppUtils";
import { setFavoriteCourses } from "../../redux/courses/actions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Slider from "react-slick";
import CourseCardListBody from "../Course/CourseCardListBody";
import { Carousel, Tab, Tabs } from "react-bootstrap";
import LearningPathModal from "./LearningPathModal";
import FilterSection from "./FilterSection";
import { Events, scroller } from "react-scroll";
import ShimmerCardBody from "../Layouts/ShimmerCardBody";
import CONFIG from "../../utils/config";
const LearningPathSection = (props) => {
  const { 
    learningPathData, lpUnAssignedData, 
    isLoading, isLoadingUnAssigned 
  } = props;

  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const [learningListingData, setLearningListingData] = useState([]);
  const [lpUnAssignListingData, setLpUnAssignListingData] = useState([]);
  const [pageType, setPageType] = useState("course");
  const [closeChildModal, setCloseChildModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showLearningData, setShowLearningData] = useState({});
  const authBroker = useSelector(state => state.auth.authBroker);

  useEffect(() => {
    // console.log(learningPathData)
    if (!_.isEmpty(learningPathData)) {
      const lpData = _.clone(learningPathData);
      console.log('LP DATA123', lpData)
      for (let lp of lpData) {
        // console.log(lp.coursesList,"levelStageDates");
        const coursesList = lp.coursesList.map((item, index) => {
          const course_hours = Math.floor(item.period / 60);
         const course_minutes = item.period % 60;
          return {
            ...item,
            course_hours,
            course_minutes
          };
        });
        // console.log(coursesList,"levelStageDates");
        if (!_.isArray(coursesList)) {
        
          for (let course of coursesList) {
         
            course.is_manager_checklist = lp.is_manager_checklist
            if (course.durationUnit === "Hours") {
              const convertArray = _.toString(course.duration).includes(":")
                ? course.duration.split(":")
                : [course.duration, 0];
              const hoursMinutes = _.toNumber(convertArray[0]) * 60;
              const minutes = _.toNumber(convertArray[1]);
              const totalMinutes = hoursMinutes + minutes;
              course.duration = totalMinutes;
            }
            if (_.toNumber(course.duration) > 60) {
              let hours = Math.floor(course.duration / 60);
              let minutes = course.duration % 60;
              hours = hours >= 10 ? hours : `0${hours}`;
              minutes = minutes >= 10 ? minutes : `0${minutes}`;
              // console.log(course.duration, hours, minutes)
              // course.duration = `${hours}:${minutes}`
              course.duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
                } ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
              course.durationUnit = "";
            } else if (_.toNumber(course.duration) < 60) {
              // console.log('Minutes')
              course.duration = `${course.duration} ${course.duration > 1 ? "Mins" : "Min"
                }`;
              course.durationUnit = "";
            } else {
              // console.log('hour')
              course.duration = "1 Hour";
              course.durationUnit = "";
            }
          }
      
        }
      }
      setLearningListingData(lpData);
    }
  }, [learningPathData]);

  useEffect(() => {
    // console.log(lpUnAssignedData)
    if (!_.isEmpty(lpUnAssignedData)) {
      const lpData = _.clone(lpUnAssignedData);
      console.log('LP DATA UnAssigned', lpData)
      for (let lp of lpData) {
            const coursesList = lp.coursesList.map((item, index) => {
          const course_hours = Math.floor(item.period / 60);
         const course_minutes = item.period % 60;
          return {
            ...item,
            course_hours,
            course_minutes
          };
        });
        // console.log(coursesList,"levelStageDates");
        if (!_.isArray(lp.coursesList)) {
          for (let course of lp.coursesList) {
            course.is_manager_checklist = lp.is_manager_checklist
            if (course.durationUnit === "Hours") {
              const convertArray = _.toString(course.duration).includes(":")
                ? course.duration.split(":")
                : [course.duration, 0];
              const hoursMinutes = _.toNumber(convertArray[0]) * 60;
              const minutes = _.toNumber(convertArray[1]);
              const totalMinutes = hoursMinutes + minutes;
              course.duration = totalMinutes;
            }
            if (_.toNumber(course.duration) > 60) {
              let hours = Math.floor(course.duration / 60);
              let minutes = course.duration % 60;
              hours = hours >= 10 ? hours : `0${hours}`;
              minutes = minutes >= 10 ? minutes : `0${minutes}`;
              // console.log(course.duration, hours, minutes)
              // course.duration = `${hours}:${minutes}`
              course.duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
                } ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
              course.durationUnit = "";
            } else if (_.toNumber(course.duration) < 60) {
              // console.log('Minutes')
              course.duration = `${course.duration} ${course.duration > 1 ? "Mins" : "Min"
                }`;
              course.durationUnit = "";
            } else {
              // console.log('hour')
              course.duration = "1 Hour";
              course.durationUnit = "";
            }
          }
        }
      }
      setLpUnAssignListingData(lpData);
    }
  }, [lpUnAssignedData]);

  useEffect(() => {
    // console.log('learning listing data', learningListingData)
    scroller.scrollTo("learning-" + id, {
      duration: 100,
      delay: 10,
      smooth: "easeInOutQuart",
    });
  }, [learningListingData]);

  const setFavorite = (id) => {
    // console.log('setFAV_ID', id)
    // return;
    const postData = {
      course_id: {
        _id: {
          $oid: id,
        },
        bkmrk: true,
        type: pageType,
      },
    };
    // dispatch(setFavoriteCourses({
    //     module: 'courseFavorite',
    //     token: localStorage.getItem(SessionKeys.API_TOKEN),
    //     postData
    // }));
  };

  const hideInfoModal = () => {
    setShowInfoModal(false);
    setShowLearningData({});
    // console.log('hide modal')
  };

  const showProgessNaming = (progress) => {
    if (_.toNumber(progress) === 0) {
      return "Not Started";
    } else if (_.toNumber(progress) === 100) {
      return "Completed";
    } else {
      return "In Progress";
    }
  };

  const settings = {
    dots: true,
  };

  return (
    <>
      <section className="my-credit-points learningpaths">
        <div className="rows">
          <div className="mb-5 profile-left-sec ">
            <div className="search_heading mt-5 container">
              {/* <img src="images/learning_img.png" width="109" height="106" alt="" /> */}
              <p className="lable-heading_search">Learning Paths</p>
            </div>
            {/* <div className="container">
              <FilterSection
                learningListingData={learningListingData}
                setLearningListingData={setLearningListingData}
                learningPathData={learningPathData}
              />
            </div> */}
            <div className="mt-5">
              <div className="rows my_learningpath_tab">
                <Tabs defaultActiveKey="assigned" className="container">
                  <Tab eventKey="assigned" title="Assigned">
                    {
                      isLoading ?
                      <>
                        <div className="container mt-5 mb-5">
                          <div className="row">
                            {[1, 2, 3, 4].map((i) => {
                              return <ShimmerCardBody key={`shimmer-${i}`} />;
                            })}
                          </div>
                        </div>
                      </>
                      :
                      learningListingData.length > 0 ? (
                        learningListingData.map((learning, index) => {
                          return (
                            <div
                              key={"learning-" + index}
                              className={`${(index + 1) % 2 === 0 ? "learning" : ""}`}
                              id={"learning-" + learning.id}
                            >
                              <div className="container mb-5">
                                <div className="row">
                                  <div className="learn-head">
                                    <p className="head-title">{learning.prgm_nm}</p>
                                    {/* <div>
                                                        <button className="btn btn-edit-learn" type="submit">Edit</button>
                                                    </div> */}
                                  </div>
                                  <div className="mb-4">
                                    <div
                                      className="col-md-12"
                                      title={learning.description}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          learning.prgm_description
                                      }}
                                    ></div>
                                  </div>
                                  <div className="container-pie">
                                    <div className="container-chart">
                                      <div className="box d-flex align-items-baseline mb-2">
                                        <div
                                          className="chart-7"
                                          style={{
                                            width: 150,
                                            height: 150,
                                            position: "relative",
                                          }}
                                        >
                                          <CircularProgressbar
                                            value={learning.prgm_progress}
                                            text={`${isNaN(learning.prgm_progress) ? _.toNumber(learning.prgm_progress).toFixed(0) : learning.prgm_progress.toFixed(0)}%`}
                                            styles={buildStyles({
                                              pathColor:
                                                96 < 100 ? `#6CC869` : "#6CC869", //FF9700=yellow
                                              trailColor: "#EBEBEB",
                                              textSize: "16px",
                                              text: {
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                fill: "#000",
                                                fontFamily: "Verdana",
                                              },
                                            })}
                                          />
                                        </div>
                                        <p className="chart-content">
                                          {showProgessNaming(learning.prgm_progress)}
                                        </p>
                                        {/* <button type="button"
                                                                className="btn btn-primary btn-block setup-button ml-2 cursor-pointer"
                                                                onClick={() => {
                                                                    setShowInfoModal(true)
                                                                    setShowLearningData(learning)
                                                                }}
                                                            >Info</button> */}
                                      </div>
                                    </div>
                                  </div>
                                  {learning.coursesList.length > 0 ? (
                                    <SliderCourseListing
                                      isOnboarding={
                                        learning.is_onboarding ? true : false
                                      }
                                      coursesList={learning.coursesList}
                                      setFavoriteCourse={setFavorite}
                                      closeModal={closeChildModal}
                                      setCloseModal={setCloseChildModal}
                                      hasDescription={false}
                                      pageType="course"
                                      showBlankCard={false}
                                      id={learning.id}
                                      path="/learning-paths"
                                      index={0}
                                    />
                                  ) : (
                                    "No courses found"
                                  )}
                                </div>
                              </div>
                              <div>
                                {index === learningListingData.length - 1 ? (
                                  ""
                                ) : (
                                  <div className="center_line"></div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="container mt-5 h-60">
                          <div className="not-found">
                            <div className="interboxes">
                              <img src="/images/telescope.svg" alt="Not Found" />
                              <p>No learning path assigned yet.</p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </Tab>
                  {
                    authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                    <Tab eventKey="more" title="Explore More">
                    {
                      isLoadingUnAssigned ?
                      <>
                        <div className="container mt-5 mb-5">
                          <div className="row">
                            {[1, 2, 3, 4].map((i) => {
                              return <ShimmerCardBody key={`shimmer-${i}`} />;
                            })}
                          </div>
                        </div>
                      </>
                      :
                      lpUnAssignListingData.length > 0 ? (
                        lpUnAssignListingData.map((learning, index) => {
                          return (
                            <div
                              key={"learning-" + index}
                              className={`${(index + 1) % 2 === 0 ? "learning" : ""}`}
                              id={"learning-" + learning.id}
                            >
                              <div className="container mb-5">
                                <div className="row">
                                  <div className="learn-head">
                                    <p className="head-title">{learning.prgm_nm}</p>
                                    {/* <div>
                                                        <button className="btn btn-edit-learn" type="submit">Edit</button>
                                                    </div> */}
                                  </div>
                                  <div className="mb-4">
                                    <div
                                      className="col-md-12"
                                      title={learning.description}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          learning.prgm_description
                                      }}
                                    ></div>
                                  </div>
                                  <div className="container-pie">
                                    <div className="container-chart">
                                      <div className="box d-flex align-items-baseline mb-2">
                                        <div
                                          className="chart-7"
                                          style={{
                                            width: 150,
                                            height: 150,
                                            position: "relative",
                                          }}
                                        >
                                          <CircularProgressbar
                                            value={learning.prgm_progress}
                                            text={`${isNaN(learning.prgm_progress) ? _.toNumber(learning.prgm_progress).toFixed(0) : learning.prgm_progress.toFixed(0)}%`}
                                            styles={buildStyles({
                                              pathColor:
                                                96 < 100 ? `#6CC869` : "#6CC869", //FF9700=yellow
                                              trailColor: "#EBEBEB",
                                              textSize: "16px",
                                              text: {
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                fill: "#000",
                                                fontFamily: "Verdana",
                                              },
                                            })}
                                          />
                                        </div>
                                        <p className="chart-content">
                                          {showProgessNaming(learning.prgm_progress)}
                                        </p>
                                        {/* <button type="button"
                                                                className="btn btn-primary btn-block setup-button ml-2 cursor-pointer"
                                                                onClick={() => {
                                                                    setShowInfoModal(true)
                                                                    setShowLearningData(learning)
                                                                }}
                                                            >Info</button> */}
                                      </div>
                                    </div>
                                  </div>
                                  {learning.coursesList.length > 0 ? (
                                    <SliderCourseListing
                                      isOnboarding={
                                        learning.is_onboarding ? true : false
                                      }
                                      coursesList={learning.coursesList}
                                      setFavoriteCourse={setFavorite}
                                      closeModal={closeChildModal}
                                      setCloseModal={setCloseChildModal}
                                      hasDescription={false}
                                      pageType="course"
                                      showBlankCard={false}
                                      id={learning.id}
                                      path="/learning-paths"
                                      index={0}
                                    />
                                  ) : (
                                    "No courses found"
                                  )}
                                </div>
                              </div>
                              <div>
                                {index === learningListingData.length - 1 ? (
                                  ""
                                ) : (
                                  <div className="center_line"></div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="container mt-5 h-60">
                          <div className="not-found">
                            <div className="interboxes">
                              <img src="/images/telescope.svg" alt="Not Found" />
                              <p>No learning path yet.</p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </Tab>
                  }
                
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LearningPathModal
        show={showInfoModal}
        onHide={() => hideInfoModal()}
        learningData={showLearningData}
      />
    </>
  );
};

export default LearningPathSection;
