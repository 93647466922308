import { fork, take, call, put } from 'redux-saga/effects'

import {
  checkoutSummaryResponse,
  fetchPaymentIntentResponse,
  fetchPIDirectCheckoutResponse,
  sendOrderPlaceResponse,
  zeroSendOrderResponse
} from './actions'
import types from './types'
import { checkoutData } from '../../api/checkout'
import { postAPIWithoutModule } from '../../api/generic'


export function* doCheckoutSummary() {
  while (true) {
    // console.log('is called')
    const { payload } = yield take(types.CHECKOUT_SUMMARY)
    const response = yield call(checkoutData, payload)
    yield put(checkoutSummaryResponse(response))
  }
}

export function* doFetchPaymentIntent() {
  while (true) {
    // console.log('is called')
    const { payload } = yield take(types.PAYMENT_INTENT)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(fetchPaymentIntentResponse(response))
  }
}

export function* doFetchPIDirectCheckout() {
  while (true) {
    // console.log('is called')
    const { payload } = yield take(types.PI_DIRECT_CHECKOUT)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(fetchPIDirectCheckoutResponse(response))
  }
}

export function* doSendOrderPlace() {
  while (true) {
    // console.log('is called')
    const { payload } = yield take(types.SEND_ORDER_PLACE)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(sendOrderPlaceResponse(response))
  }
}

export function* doZeroSendOrderPlace() {
  while (true) {
    // console.log('is called')
    const { payload } = yield take(types.ZERO_SEND_ORDER_PLACE)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(zeroSendOrderResponse(response))
  }
}


function* checkout() {
  yield fork(doCheckoutSummary)
  yield fork(doFetchPaymentIntent)
  yield fork(doFetchPIDirectCheckout)
  yield fork(doSendOrderPlace)
  yield fork(doZeroSendOrderPlace)
}


export default checkout