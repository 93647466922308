import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addToCart,
  addToCartClear,
  addToWishList,
  addToWishListClear,
  courseClear,
} from "../../redux/product/actions";
import { useParams } from "react-router-dom";
import {
  clickStreamUser,
  convertHoursToMins,
  convertSecondsToMins,
  convertTimeFormat,
  SessionKeys,
  timezoneArea,
} from "../../utils/AppUtils";
import { myCart } from "../../redux/Cart/actions";
import ConfirmModal from "../modal/ConfirmModal";
import Spinner from "react-bootstrap/Spinner";
import { userDetail, userDetailBroker } from "../../redux/user/actions";
import _ from "lodash";
import { cartPageDataResponse } from "../../redux/checkout/actions";
import { Carousel } from "react-bootstrap";
import moment from "moment";
import { getMembershipProducts, getMembershipProductsClear } from "../../redux/membership/actions";
import ShimmerScreen from "../Layouts/ShimmerScreen";
import ProviderComponent from "../Layouts/ProviderComponent";
import ImageComponent from "../Layouts/ImageComponent";

const ShoppingSection = (props) => {
  const { getCourseDetail, setProductLoading } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const courseDetailRes = useSelector((state) => state.product.courseDetail);
  const addToCartRes = useSelector((state) => state.product.addToCart);
  const addToWishListRes = useSelector((state) => state.product.addToWishList);
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const auth = useSelector((state) => state.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const membershipProducts = useSelector(
    (state) => state.membership.membershipProducts
  );

  const [courseDetail, setCourseDetail] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState(
    "Added in cart successfully"
  );
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMsg, setErrorModalMsg] = useState(
    "Added in cart successfully"
  );
  const [confirmModal1, setConfirmModal1] = useState(false);
  const [showModalMsg1, setShowModalMsg1] = useState(
    "Added in wishlist successfully"
  );
  const { courseId } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPremiumAd, setShowPremiumAd] = useState(false);
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isBuyNowCall, setIsBuyNowCall] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isAddToCart = params.get("addToCart");
  const source = params.get("source");
  const [isMemDataLoading, setIsMemDataLoading] = useState(true)
  const [memProductData, setMemProductData] = useState({});

  useEffect(() => {
    // dispatch(userDetail())
    if (!_.isEmpty(authBroker)) {
      getDetail();
      getMembershipData();
    }
  }, [dispatch, authBroker]);

  const getDetail = () => {
    dispatch(
      userDetailBroker({
        module: "getOrderDetailBroker",
        apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      })
    );
  };

  const getMembershipData = () => {
    setIsMemDataLoading(true)
    dispatch(
      getMembershipProducts({
        module: "get_membership_details",
        postData: {
          user_id: authBroker.payload.user.user_id,
          broker_id: authBroker.payload.user.broker_id,
          email: authBroker.payload.user.email,
        },
      })
    );
  }

  // useEffect(() => {
  //   if (isAddToCart === "true") setIsAlreadyInCart(true);
  //   else setIsAlreadyInCart(false);
  // }, isAddToCart);

  useEffect(() => {
    //console.log("userDetailRes: ", userDetailRes);
    setUserDetails(userDetailRes?.payload?.payload);
  }, [userDetailRes]);

  useEffect(() => {
    if (!_.isEmpty(membershipProducts)) {
      if (membershipProducts.status === "success") {
        const data = membershipProducts.payload.payload[0];
        if (data.product_sale_price) {
          data.product_sale_price = data.product_sale_price.toFixed(2);
          setMemProductData(data)
        }
      }
      dispatch(getMembershipProductsClear());
      setIsMemDataLoading(false)
    }
  }, [membershipProducts])

  useEffect(() => {
    // console.log('courseDetail1: ', courseDetailRes)
    if (!_.isEmpty(courseDetailRes?.payload)) {
      let courseDetail = _.cloneDeep(courseDetailRes?.payload);
      courseDetail.is_bundle = false;
      courseDetail.isOffer = false;

      setIsAlreadyInCart(courseDetail.is_addtocart);
      if (
        moment().isBetween(
          moment(courseDetail.start_date_time_of_offer),
          moment(courseDetail.end_date_time_of_offer)
        )
      ) {
        courseDetail.isOffer = true;
      }
      if (
        courseDetail.sfdc_product_type.toLowerCase() === "basic" &&
        !isPremiumUser
      ) {
        setShowPremiumAd(true);
      } else if (courseDetail.sfdc_product_type.toLowerCase() === "bundle") {
        courseDetail.is_bundle = true;
        for (let course of courseDetail.course_list) {
          courseDetail.course_lessons =
            _.toNumber(courseDetail.course_lessons) +
            _.toNumber(course.lessons);

          if (source === 'videos') {
            if (
              course.durationUnit === "Second(s)" &&
              course.duration >= 60
            ) {
              if (course.duration % 60) {
                course.duration = convertTimeFormat(
                  course.duration,
                  true
                );
              } else {
                course.duration = convertSecondsToMins(
                  course.duration
                );
                course.duration = convertTimeFormat(
                  course.duration
                );
              }
              course.durationUnit = "";
            } else if (
              course.durationUnit === "Second(s)" &&
              course.duration < 60
            ) {
              course.duration = `${course.duration} Sec${course.duration > 1 ? "s" : ""
                }`;
              course.durationUnit = "";
            } else if (course.durationUnit === "Hours") {
              course.duration = convertHoursToMins(
                course.duration
              );
              course.duration = convertTimeFormat(
                course.duration
              );
              course.durationUnit = "";
            }
          } else {


            if (course.durationUnit === "Minutes") {
              courseDetail.course_duration =
                _.toNumber(courseDetail.course_duration) +
                _.toNumber(course.duration);
            } else if (course.durationUnit === "Hours") {
              course.duration = convertHoursToMins(course.duration);
              courseDetail.course_duration =
                _.toNumber(courseDetail.course_duration) +
                _.toNumber(course.duration);
            }
            course.duration = convertTimeFormat(_.toNumber(course.duration));
          }
          course.durationUnit = "";
          course.course_period = "";

          courseDetail.course_views += course.views;
        }
      }
      if (
        courseDetail.course_period === "Hours" ||
        courseDetail.duration === "Hours"
      ) {
        courseDetail.duration = convertHoursToMins(courseDetail.duration);
        courseDetail.course_duration = convertHoursToMins(
          courseDetail.course_duration
        );
      }



      if (source === "videos" && courseDetail.sfdc_product_type.toLowerCase() !== "bundle") {
        if (
          courseDetail.durationUnit === "Second(s)" &&
          courseDetail.course_duration >= 60
        ) {
          if (courseDetail.course_duration % 60) {
            courseDetail.course_duration = convertTimeFormat(
              courseDetail.course_duration,
              true
            );
          } else {
            courseDetail.course_duration = convertSecondsToMins(
              courseDetail.course_duration
            );
            courseDetail.course_duration = convertTimeFormat(
              courseDetail.course_duration
            );
          }
          courseDetail.durationUnit = "";
        } else if (
          courseDetail.durationUnit === "Second(s)" &&
          courseDetail.course_duration < 60
        ) {
          courseDetail.course_duration = `${courseDetail.course_duration} Sec${courseDetail.course_duration > 1 ? "s" : ""
            }`;
          courseDetail.durationUnit = "";
        } else if (courseDetail.durationUnit === "Hours") {
          courseDetail.course_duration = convertHoursToMins(
            courseDetail.course_duration
          );
          courseDetail.course_duration = convertTimeFormat(
            courseDetail.course_duration
          );
          courseDetail.durationUnit = "";
        }
      } else {
        courseDetail.duration = convertTimeFormat(
          _.toNumber(courseDetail.duration)
        );
        courseDetail.course_duration = convertTimeFormat(
          _.toNumber(courseDetail.course_duration)
        );
      }


      courseDetail.durationUnit = "";
      courseDetail.course_period = "";

      if (source === 'session') {
        let area = timezoneArea(courseDetail.timezone.includes(":") ? courseDetail.timezone.replace(":", "") : "-0500")
        let startDateTime = moment
          .utc(
            courseDetail?.start_date?.replaceAll("-", "/") +
            " " +
            courseDetail.start_time
          )
          .tz(area)

        let endDateTime = moment
          .utc(
            courseDetail?.end_date?.replaceAll("-", "/") +
            " " +
            courseDetail.end_time
          )
          .tz(area)

        let durationValue = _.toInteger(
          moment
            .duration(endDateTime.diff(startDateTime))
            .asHours()
        )

        courseDetail.startDateTime = startDateTime;
        courseDetail.endDateTime = endDateTime;
        courseDetail.durationValue = durationValue;
      }
      // console.log('courseDetail: ', courseDetail)
      setCourseDetail(courseDetail);
      setProductLoading(false);
      dispatch(courseClear());
    }
  }, [courseDetailRes]);

  useEffect(() => {
    // setShowModalMsg(addToCartRes?.payload.message)
    // console.log('addToCartRes: ', addToCartRes)
    if (Object.keys(addToCartRes).length !== 0) {
      if (addToCartRes.status === "success") {
        if (isBuyNowCall) {
          history.push("/cart");
        } else {
          dispatch(
            myCart({
              module: "myCart",
              email: userDetails?.email,
            })
          );
          setShowModalMsg(addToCartRes?.payload?.message);
          setConfirmModal(true);
          window.scrollTo(0, 0);
        }
        setIsAlreadyInCart(true);
      } else {
        let errorMsg = addToCartRes.error?.message;
        window.scrollTo(0, 0);
        setErrorModal(true);
        setErrorModalMsg(errorMsg ? errorMsg : "Error adding product in cart");
        setIsBuyNowCall(false);
        setTimeout(() => {
          setErrorModal(false);
        }, 3000);
      }
      setIsLoading(false);
      dispatch(addToCartClear());
    }
  }, [addToCartRes]);

  useEffect(() => {
    // console.log('addToWishListRes: ', addToWishListRes)

    if (!_.isEmpty(addToWishListRes)) {
      setProductLoading(false);
      if (addToWishListRes.status === "success") {
        window.scrollTo(0, 0);
        setShowModalMsg1(addToWishListRes?.payload?.message);
        setConfirmModal1(true);
        dispatch(addToWishListClear());
        dispatch(
          getCourseDetail({
            module: "getCourseDetail",
            courseId: courseId,
            email: userDetails?.email,
          })
        );
      }
    }
  }, [addToWishListRes]);

  useEffect(() => {
    return () => {
      // console.log('addToClear: ')
      dispatch(addToCartClear());
    };
  }, []);

  const goToMembership = () => {
    clickStreamUser("explore_plans_btn_clicked", {
      userId: auth.authBroker?.payload.user.user_id,
    });
    history.push("/membership");
  };

  const addToCartFunction = (courseId) => {
    dispatch(
      addToCart({
        module: "addToCart",
        postData: {
          pid: courseId,
          email: userDetails?.email
            ? userDetails?.email
            : localStorage.getItem(SessionKeys.EMAIL_ID),
          first_name: userDetails?.firstname,
          last_name: userDetails?.lastname,
          username: userDetails?.username,
          company: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          phone: "",
          isPremium: isPremiumUser,
          broker_id: userDetails?.broker_id,
          company_id: userDetails?.company_id,
        },
      })
    );
  };

  const renderAlertCard = () => {
    return (
      <div
        className={`alert-card d-flex justify-content-center ${confirmModal ? "" : "d-none"
          }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </svg>
            <p className="m-0">{showModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <span
              className="cursor-pointer"
              onClick={() => {
                clickStreamUser("go-to-cart-from-shopping-cart-btn-clicked", {
                  userId: auth.authBroker?.payload.user.user_id,
                  ecommerceId: courseId,
                });
                history.push("/cart");
              }}
            >
              View Cart
            </span>
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setConfirmModal(false);
                setShowModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const renderErrorAlertCard = () => {
    return (
      <div
        className={`alert-card d-flex justify-content-center ${errorModal ? "" : "d-none"
          }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                                <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg> */}
            <p className="m-0">{errorModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setErrorModal(false);
                setErrorModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const renderCourseCard = () => {
    return (
      <div className="img-box_nw">
        <div className="img-videosec learn_image-card">
          {/* <img
            className="img-fluid galleryImg_lern"
            src={courseDetail?.product_thumbnail}
            onError={({currentTarget}) => {
              currentTarget.onerror = true;
              currentTarget.src = "/images/default-demo-img.jpg"
            }}
          /> */}
          <ImageComponent
            name={courseDetail.product_name}
            thumbnail={courseDetail.product_thumbnail ? courseDetail.product_thumbnail : ""}
            cssClass={"img-fluid galleryImg_lern"}
            errorClass={"img-fluid galleryImg_lern"}
          />
          {/* <div className="progress custom_progress_barr custom_bartwoRealtor">
                        <div className="progress-bar "></div>
                    </div> */}
        </div>
        <div className="details-card_nw">
          <h5
            className="course-head-al_search"
            title={courseDetail?.product_name?.replace('&amp;', '&')}
            dangerouslySetInnerHTML={{
              __html:
                courseDetail?.product_name?.length <= 25
                  ? courseDetail?.product_name
                  : courseDetail?.product_name?.substring(0, 25) + "...",
            }}
          ></h5>
          <div className="row info-bottm-card_nw">
            {source === "session" ? (
              <>
                <p className="d-flex timeboxde">
                  <div className="side-image">
                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.105"
                    height="21.105"
                    viewBox="0 0 11.105 11.105"
                  >
                    <path
                      id="clock-time-four"
                      d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z"
                      transform="translate(-2 -2)"
                      fill="var(--color-element-1)"
                    />
                  </svg> */}
                    Starts On:
                  </div>
                  <span>{moment(courseDetail.startDateTime).format('DD MMM, YYYY hh:mm A z')}</span>
                </p>
                {/* <p className="d-flex">
                <div className="side-image">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.105"
                    height="21.105"
                    viewBox="0 0 11.105 11.105"
                  >
                    <path
                      id="clock-time-four"
                      d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z"
                      transform="translate(-2 -2)"
                      fill="var(--color-element-1)"
                    />
                  </svg>
                </div>
                <span>{moment(courseDetail.endDateTime).format('DD MMM, YYYY hh:mm a z')}</span>
              </p> */}
                {/* <p className="d-flex timeboxde">
                <div className="side-image">
                  Duration: 
                </div>
                <span>{courseDetail.durationValue} {courseDetail.durationValue > 1 ? 'hours' : 'hour'}</span>
              </p> */}
              </>
            ) : (
              <div className="col-md-12 cou_cardIcon">
                {source === "videos" ? (
                  ""
                ) : (
                  <p>
                    <span>{courseDetail?.course_lessons}</span>
                    {courseDetail?.course_lessons > 1 ? " Modules" : " Module"}
                  </p>
                )}
                <div className="carouseL_Iconno2 d-flex flex-column">
                  {source === "videos" ? (
                    <div className="ico__imG01 d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="clock-time-four"
                          d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                          transform="translate(-2 -2)"
                          fill="var(--color-element-1)"
                        />
                      </svg>
                      <p>
                        {" "}
                        <span>{courseDetail?.course_duration}</span>{" "}
                        {courseDetail?.durationUnit}
                      </p>
                    </div>
                  ) : (
                    <div className="ico__imG01 d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="clock-time-four"
                          d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                          transform="translate(-2 -2)"
                          fill="var(--color-element-1)"
                        />
                      </svg>
                      <p>
                        {" "}
                        <span>{courseDetail?.course_duration}</span>{" "}
                        {courseDetail?.course_period}
                      </p>
                    </div>
                  )}
                  <div className="ico_texxt02 d-flex pt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        id="eye-circle"
                        d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                        transform="translate(-2 -2)"
                        fill="var(--color-element-1)"
                      ></path>
                    </svg>
                    <p>
                      <span>
                        {
                          courseDetail?.course_views
                        }
                      </span>{" "}
                      {courseDetail?.course_views > 1 ? "Views" : "View"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="row infoIcon_nw">
            <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
              {/* <div className="d-flex carouseL_IconnBY cross--Icon">
                                <img src="/images/card_x_icon.png" alt="" />
                            </div> */}
              {/* {courseDetail?.provider?.provider_img !== "" ? (
                <img src={courseDetail?.provider?.provider_img} alt="" /> //"/images/card_x_icon.png"
              ) : (
                <div className="carouseL_IconnBY d-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Exclusion_3"
                      data-name="Exclusion 3"
                      d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                      transform="translate(-21473.998 951.002)"
                      fill="var(--color-element-1)"
                      opacity="1"
                    ></path>
                  </svg>

                  <p>
                    {" "}
                    <span
                      className="sub_txt_lern"
                      title={courseDetail?.provider?.provider_display_name}
                    >
                      {courseDetail?.provider?.provider_display_name?.substring(
                        0,
                        10
                      ) + "..."}
                    </span>
                  </p>
                </div>
              )} */}
              <ProviderComponent providerData={courseDetail?.provider} />
              {/* <div className="d-flex justify-content-between comp_revt ">
            <img src="/images/Component 46-78.svg" width="60" height="25" alt="" />
</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderIsBundleCourseCard = () => {
    return (
      <div id="shoppingCardCarousel" className="img-box_nw position-relative">
        <div className="combo-tag">
          <i className="fa fa-star" aria-hidden="true"></i> COMBO
        </div>
        <Carousel id="courseCardCarousel">
          {courseDetail?.course_list.map((listData, index) => {
            return (
              <Carousel.Item key={"listdata-" + index}>
                <div className="">
                  <div className="img-videosec learn_image-card">
                    {/* <img
                      className="img-fluid galleryImg_lern"
                      src={listData?.imageURL}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = true;
                        currentTarget.src = "/images/default-demo-img.jpg"
                    }}
                    /> */}
                    <ImageComponent
                      name={listData.name}
                      thumbnail={listData.imageURL ? listData.imageURL : ""}
                      cssClass={"img-fluid galleryImg_lern"}
                      errorClass={"img-fluid galleryImg_lern"}
                    />
                  </div>
                  <div className="details-card_nw">
                    <h5
                      className="course-head-al_search"
                      title={listData?.name}
                      dangerouslySetInnerHTML={{
                        __html:
                          listData?.name?.length <= 25
                            ? listData?.name
                            : listData?.name.substring(0, 25) + "...",
                      }}
                    ></h5>
                    <div className="row info-bottm-card_nw">
                      <div className="col-md-12 cou_cardIcon">
                        {
                          source ? "" :
                            <p>
                              <span>{listData?.lessons}</span>{" "}
                              {listData?.lessons > 1 ? "Modules" : "Module"}
                            </p>
                        }
                        <div className="carouseL_Iconno2 d-flex flex-column">
                          <div className="ico__imG01 d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                id="clock-time-four"
                                d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                transform="translate(-2 -2)"
                                fill="var(--color-element-1)"
                              />
                            </svg>
                            <p>
                              {" "}
                              <span>{listData?.duration}</span>{" "}
                              {listData?.durationUnit}
                            </p>
                          </div>
                          <div className="ico_texxt02 d-flex pt-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                id="eye-circle"
                                d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                transform="translate(-2 -2)"
                                fill="var(--color-element-1)"
                              ></path>
                            </svg>
                            <p>
                              <span>
                                {listData?.views ? listData?.views : 0}
                              </span>{" "}
                              {listData?.views > 1 ? "Views" : "View"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row infoIcon_nw">
                      <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                        {/* <div className="d-flex carouseL_IconnBY cross--Icon">
                                                        <img src="/images/card_x_icon.png" alt="" />
                                                    </div> */}
                        {/* {courseDetail?.provider?.provider_img !== "" ? (
                          <img
                            src={courseDetail?.provider?.provider_img}
                            alt=""
                          /> //"/images/card_x_icon.png"
                        ) : (
                          <div className="carouseL_IconnBY">
                            <div className="icons-shop">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  id="Exclusion_3"
                                  data-name="Exclusion 3"
                                  d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                                  transform="translate(-21473.998 951.002)"
                                  fill="var(--color-element-1)"
                                  opacity="1"
                                ></path>
                              </svg>
                            </div>
                            <p>
                              {" "}
                              <span
                                className="sub_txt_lern"
                                title={
                                  courseDetail?.provider?.provider_display_name
                                }
                              >
                                {courseDetail?.provider?.provider_display_name?.substring(
                                  0,
                                  10
                                ) + "..."}
                              </span>
                            </p>
                          </div>
                        )} */}
                        <ProviderComponent providerData={listData.provider} />
                        {/* <div className="d-flex justify-content-between comp_revt ">
                                                    <img src="/images/Component 46-78.svg" width="60" height="25" alt="" />
                                                    </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
        <div className="position-absolute course-length-bar">
          <span>{courseDetail.course_list.length}</span>&nbsp;
          item{(courseDetail.course_list.length > 1 ? 's' : '')}
        </div>
      </div>
    );
  };

  return (
    <section className="shopping">
      <div className="container position-relative">
        {renderAlertCard()}
        {renderErrorAlertCard()}
        <div className="row">
          <div className="col-md-12 profile-left-sec">
            <div className="search_heading mt-5 shop-search">
              {/* <img src="/images/shopping_img.png" width="109" height="105" alt="" /> */}
              <p className="lable-heading_search">Shopping</p>
            </div>
            {!_.isEmpty(courseDetail) && (
              <div className="row showcart">
                <div className="col-md-6 col-lg-6 col-xl-3 mb-4 broker-box">
                  {!_.isEmpty(courseDetail)
                    ? courseDetail.is_bundle
                      ? source === "session"
                        ? renderCourseCard()
                        : renderIsBundleCourseCard()
                      : renderCourseCard()
                    : renderCourseCard()}
                  {source === "session" ? "" :
                    <div
                      className=" d-flex shop-card "
                      onClick={() => {
                        if (!courseDetail?.is_addtowishlist) {
                          setProductLoading(true);
                          // console.log('click');
                          dispatch(
                            addToWishList({
                              module: "addToWishList",
                              postData: {
                                pid: courseDetail?.product_id,
                                uemail: userDetails?.email
                                  ? userDetails?.email
                                  : localStorage.getItem(
                                    SessionKeys.EMAIL_SESSION
                                  ),
                              },
                            })
                          );
                        } else {
                          setShowModalMsg1("Already added in wishlist");
                          setConfirmModal1(true);
                        }
                      }}
                    >

                      <div className="button-reset">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          id="Like"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              y1="0.5"
                              x2="1"
                              y2="0.5"
                              gradientUnits="objectBoundingBox"
                            >
                              <stop offset="0" stop-color="#00b7f1" />
                              <stop offset="1" stop-color="#ed156e" />
                            </linearGradient>
                          </defs>
                          <circle
                            id="Ellipse_57"
                            data-name="Ellipse 57"
                            cx="15"
                            cy="15"
                            r="15"
                            fill="url(#linear-gradient)"
                          />
                          <circle
                            id="Ellipse_179"
                            data-name="Ellipse 179"
                            cx="13"
                            cy="13"
                            r="13"
                            transform="translate(2 2)"
                            fill="#fff"
                          />
                          <path
                            id="Path_491"
                            data-name="Path 491"
                            d="M498.393,492.377c-2.813-.525-3.636,2.346-3.636,2.346s-.823-2.87-3.636-2.346a3.375,3.375,0,0,0-2.838,3.5c-.023,4.474,6.473,7.665,6.473,7.665s6.5-3.191,6.473-7.665A3.375,3.375,0,0,0,498.393,492.377Z"
                            transform="translate(-479.757 -482.926)"
                            fill="var(--color-element-1)"
                          />
                        </svg>
                      </div>
                      <p className="cursor-pointer">
                        {courseDetail?.is_addtowishlist
                          ? "Added to wishlist"
                          : "Add to wishlist"}
                      </p>
                    </div>
                  }
                </div>
                <div className="col-md-6 col-lg-6 col-xl-5 mb-4 broker-box">
                  <div className="row">
                    <div className="shop-section">
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: courseDetail?.product_name,
                        }}
                      ></h4>
                      <div className="button-reset">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                id="Component_80_1" data-name="Component 80 – 1" width="30" height="30" viewBox="0 0 30 30">
                                                <defs>
                                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stop-color="#00b7f1" />
                                                        <stop offset="1" stop-color="#ed156e" />
                                                    </linearGradient>
                                                </defs>
                                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15"
                                                    fill="url(#linear-gradient)" />
                                                <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)"
                                                    fill="#fff" />
                                                <path id="share-variant"
                                                    d="M15,13.265a2.33,2.33,0,0,0-1.568.616l-5.7-3.32A2.619,2.619,0,0,0,7.8,10a2.619,2.619,0,0,0-.072-.56l5.64-3.288A2.395,2.395,0,
                                1,0,12.6,4.4a2.619,2.619,0,0,0,.072.56L7.032,8.249a2.4,2.4,0,1,0,0,3.5l5.7,3.32a2.272,2.272,0,0,0-.064.528A2.336,2.336,0,1,0,15,13.265Z"
                                                    transform="translate(3.799 5.035)" fill="var(--color-element-1)" />
                                            </svg> */}
                      </div>
                    </div>
                    <div className="containers ">
                      <div className="shop-details">
                        <div className="shopping-price">
                          <div className="container-chart chart-section">
                            <div className="box d-flex oboardg_pop_icon shop-cardSUP">
                              <p>
                                {courseDetail?.course_discount
                                  ? "-" + courseDetail.course_discount + "%"
                                  : ""}
                              </p>
                              {courseDetail?.offer_available ? (
                                isPremiumUser ? (
                                  courseDetail?.product_premium_price ? (
                                    <h4 className="strike-through mb-0">
                                      $
                                      <span className="numr29">
                                        {
                                          courseDetail?.product_premium_price.split(
                                            "."
                                          )[0]
                                        }
                                        <sup>
                                          {
                                            courseDetail?.product_premium_price.split(
                                              "."
                                            )[1]
                                          }
                                        </sup>
                                        &nbsp;CAD
                                      </span>
                                    </h4>
                                  ) : (
                                    ""
                                  )
                                ) : courseDetail?.product_basic_price ? (
                                  <h4 className="strike-through mb-0">
                                    $
                                    <span className="numr29">
                                      {
                                        courseDetail?.product_basic_price.split(
                                          "."
                                        )[0]
                                      }
                                      <sup>
                                        {
                                          courseDetail?.product_basic_price.split(
                                            "."
                                          )[1]
                                        }
                                      </sup>
                                      &nbsp;CAD
                                    </span>
                                  </h4>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              <h5 className="">
                                $
                                <span className="numr29">
                                  {
                                    courseDetail?.product_sale_price?.split(
                                      "."
                                    )[0]
                                  }
                                  <sup>
                                    {
                                      courseDetail?.product_sale_price?.split(
                                        "."
                                      )[1]
                                    }
                                  </sup>
                                  &nbsp;CAD
                                </span>
                              </h5>
                            </div>
                          </div>
                          {parseInt(courseDetail?.product_regular_price) !==
                            parseInt(courseDetail?.product_sale_price) ? (
                            <div className=" shop-cost">
                              <p>Retail price:</p>
                              <p>
                                {" "}
                                ${_.toNumber(courseDetail?.product_regular_price).toFixed(2)}&nbsp;
                                <span>CAD</span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          <p className="taxesShop">Excluding Taxes</p>
                          {showPremiumAd && (
                            <div className="btn-shop">
                              <button type="button" className="btn-premiumSub">
                                {" "}
                                Included in
                                <span>&nbsp;premium subscription</span>
                              </button>
                              <button
                                type="button"
                                className="btn-primary  btn-SubScribe"
                              >
                                Subscribe Now
                              </button>
                            </div>
                          )}
                        </div>

                        {courseDetail?.offer_available ? (
                          <div className="dis-icon">
                            <div className="offer-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33.004"
                                height="33.004"
                                viewBox="0 0 33.004 33.004"
                              >
                                <path
                                  id="Offer"
                                  d="M21677.219,1123h0l-2.275-3.087-3.791.572-.92-3.719-3.719-.92.572-3.79-3.088-2.276,1.982-3.283-1.982-3.278,3.088-2.276-.572-3.79,3.719-.92.92-3.724,3.791.577,2.275-3.087,3.283,1.982,3.279-1.982,2.275,3.087,3.789-.577.922,3.724,3.719.92-.572,3.79,3.088,2.276-1.982,3.278,1.982,3.283-3.088,2.276.572,3.79-3.719.92-.922,3.719-3.789-.572-2.275,3.087-3.279-1.981-3.283,1.982Zm9.465-17.763a3.991,3.991,0,0,0-2.979,1.051,4.442,4.442,0,0,0-1.029,3.218,4.541,4.541,0,0,0,1.006,3.2,4.695,4.695,0,0,0,5.947.011,4.562,4.562,0,0,0,1.008-3.24,4.491,4.491,0,0,0-.992-3.185A3.883,3.883,0,0,0,21686.684,1105.24Zm-3.971-4.83-6.855,13.09h2.357l6.861-13.09Zm-8.445-.25a4.005,4.005,0,0,0-2.984,1.051,4.452,4.452,0,0,0-1.023,3.218,4.509,4.509,0,0,0,1,3.2,4.694,4.694,0,0,0,5.947.011,4.552,4.552,0,0,0,1.012-3.24,4.484,4.484,0,0,0-1-3.185A3.879,3.879,0,0,0,21674.268,1100.16Zm12.389,11.751a1.183,1.183,0,0,1-1.062-.5,5.763,5.763,0,0,1,0-3.823,1.4,1.4,0,0,1,2.129,0,5.829,5.829,0,0,1,0,3.823A1.183,1.183,0,0,1,21686.656,1111.911Zm-12.416-5.08a1.169,1.169,0,0,1-1.066-.5,5.829,5.829,0,0,1,0-3.823,1.4,1.4,0,0,1,2.129,0,5.763,5.763,0,0,1,0,3.823A1.183,1.183,0,0,1,21674.24,1106.83Z"
                                  transform="translate(-21663.998 -1089.999)"
                                  fill="#ed156e"
                                />
                              </svg>
                            </div>
                            <div className="shop-offer">
                              <h5>OFFER PRICE</h5>
                              {/* <p>Offer starts on</p>
                                                    <p>{courseDetail?.start_date_time_of_offer}</p> */}
                              <p>Offer ends on</p>
                              <p>
                                {moment(
                                  courseDetail?.end_date_time_of_offer,
                                  "YYYY-MM-DD"
                                ).format("MM/DD/YYYY")}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <section className="mt-4 shop_content">
                          <a href="#">
                            <img
                              className="shop_logoICON"
                              src="/images/LOGO_head.png"
                              width="76"
                              height="44"
                            />
                          </a>
                          {/* <div className="col-md-2 col-sm-2">
                                <div className="courseDes_icon_shoppg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                        <g id="Group_509" data-name="Group 509" transform="translate(-0.064)">
                                        <g id="Ellipse_71" data-name="Ellipse 71" transform="translate(0.064)" fill="#fff"
                                            stroke="#7a549e" stroke-width="2">
                                            <circle cx="11.5" cy="11.5" r="11.5" stroke="none" />
                                            <circle cx="11.5" cy="11.5" r="10.5" fill="none" />
                                        </g>
                                        <path id="Icon_material-accessibility" data-name="Icon material-accessibility"
                                            d="M11.5,3A1.556,1.556,0,1,1,9.947,4.556,1.561,1.561,0,0,1,11.5,3Zm7,5.447H13.838V18.564H12.282V13.895H10.726v4.669H9.169V8.447H4.5V6.891H18.508Z"
                                            transform="translate(0.169 1.15)" fill="var(--color-element-1)" />
                                        </g>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
                                        <g id="Group_510" data-name="Group 510" transform="translate(-0.064)">
                                        <g id="Ellipse_71" data-name="Ellipse 71" transform="translate(0.064)" fill="#fff"
                                            stroke="#7a549e" stroke-width="2">
                                            <ellipse cx="12" cy="11.5" rx="12" ry="11.5" stroke="none" />
                                            <ellipse cx="12" cy="11.5" rx="11" ry="10.5" fill="none" />
                                        </g>
                                        <path id="Icon_awesome-headphones" data-name="Icon awesome-headphones"
                                            d="M6.961,2.25A6.957,6.957,0,0,0,0,9.211v1.305a.87.87,0,0,0,.481.778l.391.2a3.045,3.045,0,0,0,3.043,2.942h.653a.653.653,0,0,0,.653-.653V8.993a.653.653,0,0,0-.653-.653H3.915a3.036,3.036,0,0,0-2.175.914V9.211a5.221,5.221,0,0,1,10.441,0v.044a3.036,3.036,0,0,0-2.175-.914H9.354a.653.653,0,0,0-.653.653v4.786a.653.653,0,0,0,.653.653h.653A3.045,3.045,0,0,0,13.05,11.49l.391-.2a.87.87,0,0,0,.481-.778V9.211A6.957,6.957,0,0,0,6.961,2.25Z"
                                            transform="translate(5.039 3.352)" fill="#7a549e" />
                                        </g>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                        <g id="Group_511" data-name="Group 511" transform="translate(-0.064)">
                                        <g id="Ellipse_71" data-name="Ellipse 71" transform="translate(0.064)" fill="#fff"
                                            stroke="#7a549e" stroke-width="2">
                                            <circle cx="11.5" cy="11.5" r="11.5" stroke="none" />
                                            <circle cx="11.5" cy="11.5" r="10.5" fill="none" />
                                        </g>
                                        <path id="Icon_awesome-mobile" data-name="Icon awesome-mobile"
                                            d="M6.988,0H1.233A1.233,1.233,0,0,0,0,1.233V11.921a1.233,1.233,0,0,0,1.233,1.233H6.988a1.233,1.233,0,0,0,1.233-1.233V1.233A1.233,1.233,0,0,0,6.988,0ZM4.111,12.332a.822.822,0,1,1,.822-.822A.821.821,0,0,1,4.111,12.332Z"
                                            transform="translate(7.197 5.115)" fill="#7a549e" />
                                        </g>
                                    </svg>
                                </div>
                            </div> */}
                          <section className="mt-4 shop_ContXT">
                            <h5>About this {source ? source : "Course"}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: courseDetail?.product_description,
                              }}
                            />
                          </section>
                          {source ? (
                            ""
                          ) : (
                            <div className="row align-items-start">
                              <div className="col-md-3 col-sm-3 d-flex justify-content-center  flex-column shop_Icon_txt ">
                                <div className="border-det">
                                  <div className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24.031"
                                      height="24.031"
                                      viewBox="0 0 24.031 24.031"
                                    >
                                      <path
                                        id="Exclusion_2"
                                        data-name="Exclusion 2"
                                        d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm-6.506-17.373h0v10.72h13.018v-10.72h-13.018Zm11.486,9.189h-6.891v-1.532h6.891v1.531Zm-8.428,0h-1.527v-1.532h1.527v1.531Zm8.428-3.065h-6.891v-1.532h6.891v1.531Zm-8.428,0h-1.527v-1.532h1.527v1.531Zm8.428-3.065h-6.891v-1.527h6.891v1.526Zm-8.428,0h-1.527v-1.527h1.527v1.526Z"
                                        transform="translate(-21473.998 951.002)"
                                        fill="var(--color-element-1)"
                                        opacity="0.6"
                                      />
                                    </svg>
                                  </div>
                                  <p>{courseDetail?.course_lessons} </p>
                                  <span>
                                    {courseDetail?.course_lessons > 1
                                      ? " Modules"
                                      : " Module"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-3 d-flex timeperiud justify-content-center  flex-column shop_Icon_txt ">
                                <div className="border-det">
                                  <div className="icons-shop">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        id="clock-time-four"
                                        d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                        transform="translate(-2 -2)"
                                        fill="var(--color-element-1)"
                                      />
                                    </svg>
                                  </div>
                                  <p className="time-for-course">
                                    {courseDetail?.course_duration}
                                  </p>
                                  <span>{courseDetail?.course_period}</span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-3 d-flex justify-content-center  flex-column shop_Icon_txt ">
                                <div className="border-det">
                                  <div className="icons-shop">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        id="eye-circle"
                                        d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                        transform="translate(-2 -2)"
                                        fill="var(--color-element-1)"
                                      />
                                    </svg>
                                  </div>
                                  <p>
                                    {
                                      courseDetail?.course_views
                                    }
                                  </p>
                                  <span>
                                    {courseDetail?.course_views > 1
                                      ? "Views"
                                      : "View"}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={`${courseDetail?.provider &&
                                  courseDetail?.provider
                                    ?.provider_display_name !== ""
                                  ? "col-md-2 col-sm-2"
                                  : "col-md-3 col-sm-3"
                                  } d-flex  align-items-center flex-column shop_Icon_txt btndtext`}
                              >
                                {courseDetail?.provider?.provider_img !== "" ? (
                                  <img
                                    src={courseDetail?.provider?.provider_img}
                                    alt=""
                                  /> //"/images/card_x_icon.png"
                                ) : (
                                  <div className="carouseL_IconnBY">
                                    <div className="icons-shop">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          id="Exclusion_3"
                                          data-name="Exclusion 3"
                                          d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                                          transform="translate(-21473.998 951.002)"
                                          fill="var(--color-element-1)"
                                          opacity="1"
                                        ></path>
                                      </svg>
                                    </div>
                                    <p>
                                      {" "}
                                      <span
                                        className="sub_txt_lern"
                                        title={
                                          courseDetail?.provider
                                            ?.provider_display_name
                                        }
                                      >
                                        {courseDetail?.provider?.provider_display_name?.substring(
                                          0,
                                          13
                                        ) + "..."}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </section>
                        {/* <section className="mt-4 shop_ContXT shop-icons">
                          <h5>Legal Disclaimer</h5>
                          <p>
                            Mauris tristique ante quis turpis dapibus, A
                            pellentesque lectus auctor. Aliquam erat volutpat.
                            Donec sagittis vehicula est ut tempor. Etiam cursus
                            suscipit leo id fringilla.
                          </p>
                        </section> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-6 col-xl-4 mb-4 broker-box">
                  <div className="card-shop">
                    <div
                      className="card border-light payment-card"
                      style={{ maxWidth: "33rem" }}
                    >
                      <div className="payment-header-shop">
                        <h5 className="card-title payment-title-shop d-flex">
                          You pay:
                        </h5>
                        <div
                          className={`pricebox ${courseDetail?.offer_available
                            ? "justify-content-ends"
                            : ""
                            }`}
                        >
                          {/* {courseDetail?.offer_available ?
                                                    isPremiumUser ?
                                                        (courseDetail?.product_premium_price ? <p className="strike-through">${courseDetail?.product_premium_price}&nbsp;<span>CAD</span></p> : '')
                                                        : (courseDetail?.product_basic_price ? <p className="strike-through">${courseDetail?.product_basic_price}&nbsp;<span>CAD</span></p> : '')
                                                    : ''} */}

                          <h2>
                            ${courseDetail?.product_sale_price?.split(".")[0]}
                            <sup>
                              {courseDetail?.product_sale_price?.split(".")[1]}
                            </sup>
                            <span>&nbsp;CAD</span>
                          </h2>
                        </div>
                      </div>
                      <div className="card-body payment-body">
                        <div className="body-card shop">
                          <div
                            className={`pay-title ${courseDetail?.offer_available
                              ? "d-flex justify-content-ends w-100"
                              : ""
                              }`}
                          >
                            {!courseDetail?.offer_available &&
                              parseInt(courseDetail?.product_regular_price) !==
                              parseInt(courseDetail?.product_sale_price) ? (
                              <div className="price-retail">
                                <p>Retail price:</p>
                                <p>
                                  ${_.toNumber(courseDetail?.product_regular_price).toFixed(2)}&nbsp;
                                  <span>CAD</span>
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            <p>Excluding Taxes</p>
                          </div>
                          <div className="pay-perc">
                            <p>
                              {courseDetail?.course_discount
                                ? "-" + courseDetail.course_discount + "%"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        className={`btn-shop_cart btn-primary ${isLoading ? "clicked-btn" : ""
                          }`}
                        onClick={() => {
                          !isLoading &&
                            addToCartFunction(courseDetail?.product_id);
                          setIsLoading(true);
                        }}
                      >
                        {" "}
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="primary"
                          />
                        ) : isAlreadyInCart ? (
                          "Added in cart"
                        ) : (
                          "Add to cart"
                        )}{" "}
                      </button>

                      <button
                        className="btn-shop_cart btn-primary"
                        onClick={() => {
                          clickStreamUser("shoppingPageBuyNowBtnClicked", {
                            userEmail: auth?.authBroker?.payload?.user?.email,
                            productId: courseDetail.product_id,
                            productName: courseDetail.product_name,
                            productAmount: courseDetail.product_sale_price,
                          });
                          // dispatch(cartPageDataResponse({
                          //     item: 1,
                          //     price: courseDetail.product_sale_price,
                          // }))
                          // history.push(`/payment?product_id=${courseId}`)

                          if (isAlreadyInCart) {
                            history.push("/cart");
                          } else {
                            addToCartFunction(courseDetail?.product_id);
                            setIsBuyNowCall(true);
                          }
                        }}
                      >
                        {isBuyNowCall ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="primary"
                          />
                        ) : (
                          "Buy now"
                        )}
                      </button>
                    </div>
                    {
                      !isMemDataLoading ?
                        (!isPremiumUser && !_.isEmpty(memProductData))
                          ? (
                            <div className="content-shop_text">
                              {/* <p>Or get access to <span> Mandatory Document Workflow</span> and 150+ such courses with personal
                                            guidance with a subscription.</p> */}
                              <p>
                                Upgrade to Premium for additional Discounts and <strong className="cursor-pointer hoverclick" onClick={() => { window.open('https://www.xsel.app/terms-of-service/', '_blank') }}>Reward points</strong>.
                              </p>
                              <p>
                                {/* Subscription starting at $9<sup>99</sup>&nbsp;
                          <span>CAD</span>/month */}
                                Subscription starting at
                                ${memProductData?.product_sale_price.split(".")[0]}<sup>
                                  {memProductData?.product_sale_price.split(".")[1]}</sup>&nbsp;
                                <span>CAD</span>/{memProductData.tenure}
                              </p>
                              <div className="btn-explore">
                                <button
                                  className="btn-plan btn-primary"
                                  onClick={() => goToMembership()}
                                >
                                  Explore Plans
                                </button>
                              </div>
                            </div>
                          ) : ''
                        :
                        <div className="content-shop_text">
                          <ShimmerScreen
                            case="rect"
                            width="100%"
                            height="1rem"
                          />
                          <div className="mt-2">
                            <ShimmerScreen
                              case="rect"
                              width="100%"
                              height="1rem"
                            />
                          </div>
                          <div className="btn-explore d-flex justify-content-center mt-2">
                            <ShimmerScreen
                              case="rect"
                              width="50%"
                              height="2rem"
                            />
                          </div>
                        </div>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        confirmModal={confirmModal1}
        setConfirmModal={setConfirmModal1}
        showModalMsg={showModalMsg1}
        isConfirm={false}
        confirmId={courseId}
        confirmAction={function () { }}
        isRightIcon={true}
      />
    </section>
  );
};

export default ShoppingSection;
