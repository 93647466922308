import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAssignUserReq,
  getTeamMatesReq,
  getTeamMatesResClear,
  setCurrentView,
} from "../../redux/assignCourse/actions";
import { setCurrentFilterView } from "../../redux/reports/actions";
import { SessionKeys } from "../../utils/AppUtils";
import StaticUtils from "../../utils/StaticUtils";
const ChooseProvider = (props) => {

  const {
    selectedProviderList, setSelectedProviderList,
    parentId, parentName,
    isMobile
  } = props;

  const dispatch = useDispatch();
  const { courseId } = useParams();

  const teamMatesListRes = useSelector(
    (state) => state.assignCourse.teamMatesList
  );
  const assignedUserRes = useSelector(
    (state) => state.assignCourse.assignedUsers
  );
  const [searchInput, setSearchInput] = useState("");
  const [teamMatesList, setTeamMatesList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    dispatch(getTeamMatesResClear());
  }, []);

  useEffect(() => {
    setSelectedCount(selectedProviderList?.length);
  }, [selectedProviderList]);

  useEffect(() => {
    if (searchInput !== undefined && searchInput.length > 2)
      getTeamMatesOnSearch();
    else setErrorMsg("");
  }, [searchInput]);

  const getTeamMatesOnSearch = () => {
    dispatch(
      getTeamMatesReq({
        module: "chooseProvider",
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          search: searchInput,
        },
      })
    );
  };



  useEffect(() => {
    // console.log("teamMatesListRes: ", teamMatesListRes);
    if (!_.isEmpty(teamMatesListRes)) {
      if (teamMatesListRes?.status === "success") {
        setTeamMatesList(teamMatesListRes?.payload?.payload);

        if (!_.isEmpty(searchInput) && searchInput?.length > 2 && teamMatesListRes?.payload?.payload?.length === 0) {
          setErrorMsg("No result found for your search.");
        } else {
          setErrorMsg("");
        }
      }
    } else {
      setTeamMatesList([]);
      setErrorMsg("");
    }
  }, [teamMatesListRes]);




  const selectItem = (index) => {
    let tempArr = _.cloneDeep(teamMatesList);
    let selectedTempArray = _.cloneDeep(selectedProviderList);
    if (!StaticUtils.isContainObject(selectedTempArray, tempArr[index], 0)) {
      selectedTempArray.push(tempArr[index]);
    }

    setSelectedProviderList(selectedTempArray);
    tempArr.splice(index, 1);
    setTeamMatesList(tempArr);
  };

  const removeItem = (index) => {
    let selectedTempArray = _.cloneDeep(selectedProviderList);
    let tempArr = _.cloneDeep(teamMatesList);

    if (!StaticUtils.isContainObject(tempArr, selectedTempArray[index], 0)) {
      tempArr.push(selectedTempArray[index]);
    }

    setTeamMatesList(tempArr);
    selectedTempArray.splice(index, 1);
    setSelectedProviderList(selectedTempArray);
  };

  return (
    <>
      <div className="chooseteamate">
        <h4>Choose Provider</h4>
        <div className="input-lear position-relative d-flex">
          <label for="teamsearch" className="input__label_learning">
            Search
          </label>
          <input
            type="text"
            id="teamsearch"
            name="teamsearch"
            className="input_searchfield_search-cart "
            placeholder="Start searching"
            onChange={(evt) => {
              setSearchInput(evt.target.value);
            }}
          />
        </div>
        <p className="error-msg">{errorMsg}</p>
        <div className="search-team-result">
          {teamMatesList?.map((item, index) => {
            return (
              <div className="team-result-box">
                <div className="team-user-img">
                  <img
                    src={
                      item?.p_logo !== ""
                        ? item?.p_logo
                        : "/images/avatar.png"
                    }
                    alt="user"
                  />
                </div>
                <div className="userteamname">{item?.p_name}</div>
                <div
                  className="addteammate cursor-pointer"
                  onClick={() => {
                    selectItem(index);
                  }}
                >
                  +
                </div>
              </div>
            );
          })}
        </div>
        <div className="teamselected">
          <div className="selectresilt">
            Selected Provider<strong>({selectedCount})</strong>
          </div>
          <div className="search-team-result slectedteammates">
            {selectedProviderList?.map((item, index) => {
              return (
                <div className="team-result-box">
                  <div className="team-user-img">
                    <img
                      src={
                        item?.p_logo !== ""
                          ? item?.p_logo
                          : "/images/avatar.png"
                      }
                      alt="user"
                    />
                  </div>
                  <div className="userteamname">{item?.p_name}</div>
                  <div
                    className="addteammate cursor-pointer"
                    onClick={() => {
                      //   console.log("removeItem");
                      removeItem(index);
                    }}
                  >
                    +
                  </div>
                </div>
              );
            })}
          </div>
        </div>



        <div className="buttonsteammate">
          <button
            type="button"
            className="btn btn-primary btn-block back-button"
            onClick={() => {
              dispatch(setCurrentFilterView(1));
            }}
          >
            Back
          </button>
          {selectedCount > 0 ? (
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              onClick={() => {
                dispatch(setCurrentFilterView(1));
              }}
            >
              Proceed
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              disabled
            >
              Proceed
            </button>
          )}
        </div>




      </div>
    </>
  );
};

export default ChooseProvider;
