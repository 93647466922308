import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MyBadges from "./MyBadges";
import MyCertificates from "./MyCertificates";
import SideNav from "./SideNav";
import SideNavDetail from "./SideNavDetail";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { learningPoints } from "../../redux/Cart/actions";
import Loader from "../../components/Loader/Loader";
import StaticUtils from "../../utils/StaticUtils";
import { leaderBoardData, leaderBoardDataTest } from "../../utils/testJson";
import ReactRoundedImage from "react-rounded-image";
import { getLeaderBoardReq, getUserPointsReq } from "../../redux/user/actions";
import { SessionKeys } from "../../utils/AppUtils";
import moment from "moment";

const MenuDetailComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const section = params.get("section");
  const userRole = useSelector(state => state.user.role)
  const isOther = params.get("isOther");
  const user_id = params.get("user_id");
  // console.log("Sectionnnnns", isOther);

  const authBroker = useSelector((state) => state.auth.authBroker);
  const leaderBoardDataTestRes = useSelector((state) => state.user.leaderBoard);
  const userPointsRes = useSelector(state => state.user.userBroker);
  const [totalLearningPoints, setTotalLearningPoints] = useState(0);
  const myLearningPointsRes = useSelector(
    (state) => state.carts?.learningPoints
  );
  const [isLoading, setIsLoading] = useState(true);

  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [lastUpdateTime, setLastUpdateTime] = useState('');
  const [coursePoints, setCoursePoints] = useState(0);
  const [videoPoints, setVideoPoints] = useState(0);
  const [rolePlayPoints, setRolePlayPoints] = useState(0);

  const classNameArr = [
    "points-one",
    "points-two",
    "points-theree",
    "points-four",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log('leaderBoardDataTestRes: ',leaderBoardDataTestRes);
    if (!_.isEmpty(leaderBoardDataTestRes)) {
      if (leaderBoardDataTestRes?.status === "success") {
        // console.log('filteredArrayFromComponent1', StaticUtils.getLeaderBoardData(leaderBoardDataTestRes?.payload?.payload));
        setLeaderBoardData(
          leaderBoardDataTestRes?.payload?.payload?.list?.reverse()
        );
        setLastUpdateTime(moment.unix(leaderBoardDataTestRes?.payload?.payload?.last_updated).format('Do MMMM YYYY, h:mm A'))
      }
    }
  }, [leaderBoardDataTestRes]);

  useEffect(() => {
    if (!_.isEmpty(userPointsRes)) {
      if (userPointsRes?.status === "success") {
        console.log("userPointsRes:", userPointsRes);
        setCoursePoints(
          userPointsRes?.payload?.learning_points?.course !== null
            ? userPointsRes?.payload?.learning_points?.course
            : 0
        );
        setVideoPoints(
          userPointsRes?.payload?.learning_points?.video !== null
            ? userPointsRes?.payload?.learning_points?.video
            : 0
        );
        setRolePlayPoints(
          userPointsRes?.payload?.payload?.role_play_lp !== null
            ? userPointsRes?.payload?.payload?.role_play_lp
            : 0
        );
        // let totalPoints =
        //   userPointsRes?.payload?.payload?.course_lp !== null
        //     ? Number(userPointsRes?.payload?.payload?.course_lp)
        //     : 0 + userPointsRes?.payload?.payload?.video_lp !== null
        //     ? Number(userPointsRes?.payload?.payload?.video_lp)
        //     : 0 + userPointsRes?.payload?.payload?.role_play_lp !== null
        //     ? Number(userPointsRes?.payload?.payload?.role_play_lp)
        //     : 0;

        let totalPoints = 
        Number(userPointsRes?.payload?.learning_points?.course || 0) +
        Number(userPointsRes?.payload?.learning_points?.video || 0) +
        Number(userPointsRes?.payload?.learning_points?.event || 0); 
          // +
          // Number(userPointsRes?.payload?.payload?.role_play_lp);

        setTotalLearningPoints(totalPoints);
      }
    }
  }, [userPointsRes]);


  useEffect(() => {
    setIsLoading(false);
  }, [dispatch, authBroker]);

  useEffect(() => {
    // console.log("learningpoint", myLearningPointsRes);

    if (myLearningPointsRes?.status === "success") {
      var lpArray = myLearningPointsRes?.payload;

      let totalPoint = 0;
      if (!_.isEmpty(lpArray)) {
        if (_.isArray(lpArray) && lpArray.length > 0) {
          totalPoint = 0;
          for (let learningPoint of lpArray) {
            totalPoint = totalPoint + _.toNumber(learningPoint.point);
          }
        }
        myLearningPointsRes.payload = lpArray;
        // setTotalLearningPoints(totalPoint);
      }
    }
  }, [myLearningPointsRes]);

  useEffect(() => {
    switch (section) {
      case "dashboard":
        scroller.scrollTo("dashboardId", {
          duration: 50,
          delay: 0,
          smooth: "easeInOutQuart",
        });
        break;
      case "certificate":
        scroller.scrollTo("certificate", {
          duration: 50,
          delay: 0,
          smooth: "easeInOutQuart",
        });
        break;
      case "badge":
        scroller.scrollTo("badge", {
          duration: 50,
          delay: 0,
          smooth: "easeInOutQuart",
        });
        break;
      default: {
        scroller.scrollTo("editProfileId", {
          duration: 50,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    }
  }, [section]);

  return (
    <>
      <Loader start={isLoading} />
      <section id="editProfileId">
        <section className="profile-banner container">
          {/*<img src="/images/profile_img_icon.png" alt="" />*/}
          <h1>Profile</h1>
        </section>

        <section className="container">
          <div className="row">
            <SideNav setIsLoading={setIsLoading} />
            <SideNavDetail setIsLoading={setIsLoading} />
          </div>
        </section>
        {userRole !== 'instructor' && <section id="dashboardId" className="container mt-5">
          <div className="row">
            <div className="col-md-12 profile-right-sec">
              <div className="row mt-5">
                <div className="col-md-6 required">
                  <div className="content_profile_pg profile_containerone">
                    <div className="content_prof">
                      <div className="d-flex headertitle justify-space-between">
                        <h3>My Dashboard</h3>
                        {/* <div className="icon-image">
                          <a>
                            <button
                              className="button-reset"
                              onClick={() => {
                                history.push("/learning-points");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                id="More"
                                width="26"
                                height="26"
                                viewBox="0 0 30 30"
                              >
                                <defs>
                                  <linearGradient
                                    id="linear-gradient"
                                    y1="0.5"
                                    x2="1"
                                    y2="0.5"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop
                                      offset="0"
                                      stopColor="#00b7f1"
                                    ></stop>
                                    <stop
                                      offset="1"
                                      stopColor="#ed156e"
                                    ></stop>
                                  </linearGradient>
                                </defs>
                                <circle
                                  id="Ellipse_57"
                                  data-name="Ellipse 57"
                                  cx="15"
                                  cy="15"
                                  r="15"
                                  fill="url(#linear-gradient)"
                                ></circle>
                                <circle
                                  id="Ellipse_179"
                                  data-name="Ellipse 179"
                                  cx="13"
                                  cy="13"
                                  r="13"
                                  transform="translate(2 2)"
                                  fill="#fff"
                                ></circle>
                                <g
                                  id="Group_2342"
                                  data-name="Group 2342"
                                  transform="translate(-775 -762)"
                                >
                                  <circle
                                    id="Ellipse_183"
                                    data-name="Ellipse 183"
                                    cx="2"
                                    cy="2"
                                    r="2"
                                    transform="translate(782 775)"
                                    fill="var(--color-element-1)"
                                  ></circle>
                                  <circle
                                    id="Ellipse_184"
                                    data-name="Ellipse 184"
                                    cx="2"
                                    cy="2"
                                    r="2"
                                    transform="translate(788 775)"
                                    fill="var(--color-element-1)"
                                  ></circle>
                                  <circle
                                    id="Ellipse_185"
                                    data-name="Ellipse 185"
                                    cx="2"
                                    cy="2"
                                    r="2"
                                    transform="translate(794 775)"
                                    fill="var(--color-element-1)"
                                  ></circle>
                                </g>
                              </svg>
                            </button>
                          </a>
                        </div> */}
                      </div>
                      <div className="content-progress">
                        <p className="learngBlack">Learning Points</p>
                        <p className="points_profile">
                          {" "}
                          <span className="fontBolt">
                            {" "}
                            {totalLearningPoints} &nbsp;{" "}
                          </span>{" "}
                          Point{totalLearningPoints > 1 ? 's' : ''}
                        </p>
                      </div>
                      <div className="container-pie-chart">
                        <div className="container-chart mt-3">
                          <div className="">
                            <div
                              className="chart-1 d-flex px-2"
                              data-percent="25"
                              data-scale-color="#FF6C02"
                            >
                              <div className="marginimg">
                                <img
                                  src="/images/elearning.svg"
                                  width="48"
                                  alt=""
                                />
                              </div>
                              <div style={{ "marginTop": "10px" }}>
                                <h3 className="content_prof02">
                                  {coursePoints}{" "}
                                </h3>
                                <p>Point{coursePoints > 1 ? 's' : ''}</p>
                              </div>
                            </div>
                            <div className="d-flex   flex-column learningPoint">
                              <p>Course</p>
                              {/* <div className="icon-image">
                                <a>
                                  <button
                                    className="button-reset"
                                    onClick={() => {
                                      history.push("/learning-points");
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      id="More"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 30 30"
                                    >
                                      <defs>
                                        <linearGradient
                                          id="linear-gradient"
                                          y1="0.5"
                                          x2="1"
                                          y2="0.5"
                                          gradientUnits="objectBoundingBox"
                                        >
                                          <stop
                                            offset="0"
                                            stop-color="#00b7f1"
                                          ></stop>
                                          <stop
                                            offset="1"
                                            stop-color="#ed156e"
                                          ></stop>
                                        </linearGradient>
                                      </defs>
                                      <circle
                                        id="Ellipse_57"
                                        data-name="Ellipse 57"
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradient)"
                                      ></circle>
                                      <circle
                                        id="Ellipse_179"
                                        data-name="Ellipse 179"
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        transform="translate(2 2)"
                                        fill="#fff"
                                      ></circle>
                                      <g
                                        id="Group_2342"
                                        data-name="Group 2342"
                                        transform="translate(-775 -762)"
                                      >
                                        <circle
                                          id="Ellipse_183"
                                          data-name="Ellipse 183"
                                          cx="2"
                                          cy="2"
                                          r="2"
                                          transform="translate(782 775)"
                                          fill="var(--color-element-1)"
                                        ></circle>
                                        <circle
                                          id="Ellipse_184"
                                          data-name="Ellipse 184"
                                          cx="2"
                                          cy="2"
                                          r="2"
                                          transform="translate(788 775)"
                                          fill="var(--color-element-1)"
                                        ></circle>
                                        <circle
                                          id="Ellipse_185"
                                          data-name="Ellipse 185"
                                          cx="2"
                                          cy="2"
                                          r="2"
                                          transform="translate(794 775)"
                                          fill="var(--color-element-1)"
                                        ></circle>
                                      </g>
                                    </svg>
                                  </button>
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="container-chart mt-3">
                          <div className="">
                            <div
                              className="chart-1 d-flex px-2"
                              data-percent="25"
                              data-scale-color="#FF6C02"
                            >
                              <div className="marginimg">
                                <img
                                  src="/images/vidoe-profle.svg"
                                  width="48"
                                  alt=""
                                />
                              </div>
                              <div style={{ "marginTop": "10px" }}>
                                <h3 className="content_prof02">
                                  {videoPoints}{" "}
                                </h3>
                                <p>Point{videoPoints > 1 ? 's' : ''}</p>
                              </div>
                            </div>
                            <div className="d-flex   flex-column learningPoint">
                              <p>Video</p>
                              {/* <div className="icon-image">
                                <a>
                                  <button
                                    className="button-reset"
                                    onClick={() => {
                                      history.push("/learning-points");
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      id="More"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 30 30"
                                    >
                                      <defs>
                                        <linearGradient
                                          id="linear-gradient"
                                          y1="0.5"
                                          x2="1"
                                          y2="0.5"
                                          gradientUnits="objectBoundingBox"
                                        >
                                          <stop
                                            offset="0"
                                            stop-color="#00b7f1"
                                          ></stop>
                                          <stop
                                            offset="1"
                                            stop-color="#ed156e"
                                          ></stop>
                                        </linearGradient>
                                      </defs>
                                      <circle
                                        id="Ellipse_57"
                                        data-name="Ellipse 57"
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradient)"
                                      ></circle>
                                      <circle
                                        id="Ellipse_179"
                                        data-name="Ellipse 179"
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        transform="translate(2 2)"
                                        fill="#fff"
                                      ></circle>
                                      <g
                                        id="Group_2342"
                                        data-name="Group 2342"
                                        transform="translate(-775 -762)"
                                      >
                                        <circle
                                          id="Ellipse_183"
                                          data-name="Ellipse 183"
                                          cx="2"
                                          cy="2"
                                          r="2"
                                          transform="translate(782 775)"
                                          fill="var(--color-element-1)"
                                        ></circle>
                                        <circle
                                          id="Ellipse_184"
                                          data-name="Ellipse 184"
                                          cx="2"
                                          cy="2"
                                          r="2"
                                          transform="translate(788 775)"
                                          fill="var(--color-element-1)"
                                        ></circle>
                                        <circle
                                          id="Ellipse_185"
                                          data-name="Ellipse 185"
                                          cx="2"
                                          cy="2"
                                          r="2"
                                          transform="translate(794 775)"
                                          fill="var(--color-element-1)"
                                        ></circle>
                                      </g>
                                    </svg>
                                  </button>
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="container-chart mt-3">
                          <div className="">
                            <div
                              className="chart-1 d-flex px-2"
                              data-percent="25"
                              data-scale-color="#FF6C02"
                            >
                              <div className="marginimg">
                                <img
                                  src="/images/roleplay.svg"
                                  width="48"
                                  alt=""
                                />
                              </div>
                              <div style={{ "marginTop": "10px" }}>
                                <h3 className="content_prof02">
                                  {rolePlayPoints}{" "}
                                </h3>
                                <p>Point{rolePlayPoints > 1 ? 's' : ''}</p>
                              </div>
                            </div>
                            <div className="d-flex   flex-column learningPoint">
                              <p>Role Play</p>
                             
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {
                  authBroker?.payload?.user?.company_id !== 521 && 
                  <div className="col-md-6 required">
                  <div className="content_prof leadefbord">
                    <h3 className="leaderboadetics">Leaderboard</h3>

                    {leaderBoardData?.length > 0 ? <p><strong>Last updated on  </strong>{lastUpdateTime}</p> : ''}
                   
                    <div className="leaderboad-graph">
                      {leaderBoardData?.length > 0 ? leaderBoardData?.map((item, index) => {
                        return (
                          <div className={classNameArr[index]} key={index}>
                            <span
                              className={item.is_me ? "profile-box" : "dot"}
                            >
                              {item.is_me ? (
                                <ReactRoundedImage
                                  image={
                                    authBroker?.payload?.user?.profile_img !==
                                      undefined &&
                                      authBroker?.payload?.user?.profile_img !==
                                      ""
                                      ? authBroker?.payload?.user?.profile_img
                                      : "/images/avatar.png"
                                  }
                                  roundedSize="0"
                                  imageWidth="40"
                                  imageHeight="40"
                                />
                              ) : (
                                ""
                              )}
                            </span>
                            <span className="tootip-text">
                              Rank {item.rank}
                              <br />
                              {item.user_rank} Points
                            </span>
                          </div>
                        );
                      }) : <div className="not-found mt-3">
                        <div className="interboxes">
                          <img src="/images/telescope.svg" alt="Not Found" />
                          <p>Be the first one on our Leaderboard!</p>
                        </div>
                      </div>}
                    </div>
                    <h4>{localStorage.getItem(SessionKeys.BROKER_NAME)}</h4>
                  </div>
                </div>
                }
               
              </div>
            </div>
          </div>
        </section>}

        <div
          id="certificate"
        ></div>
        {/* <div
          className="certify-profile center_line mt-3"
        ></div> */}
        {
          authBroker?.payload?.user?.company_id !== 521 && 
          userRole !== 'instructor' && 
          <MyCertificates isOther={isOther} />
        }

        {/* <div className="center_line m-0"></div> */}

        {/* <MyBadges isOther={isOther} /> */}
      </section>
    </>
  );
};
export default MenuDetailComponent;
