import React from 'react'
import Page from '../../components/Page'
import MyCertificates from '../../components/menu_detail/MyCertificates'

const MyCertification = () => {
  return (
    <Page>
        <MyCertificates/>
    </Page>
  )
}

export default MyCertification