import { fork, take, call, put } from 'redux-saga/effects'

import {
  loginResponse,
  loginBrokerResponse,
  forgotPwdResponse,
  forgotPwdBrokerResponse,
  resetPwdResponse,
  resetPwdBrokerResponse,
  logoutResponse,
  logoutBrokerResponse,
  verifyActivationLinkResponse,
  setupPasswordResponse,
  setupPasswordBrokerResponse,
  resendActivationLinkResponse,
  loginHostInstResponse,
  logoutHostInstResponse
} from './actions'
import types from './types'
import { login, logout } from '../../api/login'
import { forgotPwd } from '../../api/forgotPwd'
import { resetPwd } from '../../api/resetPwd'
import { getAPI, postAPI, getAPIWithID, postAPIWithoutModule } from '../../api/generic'


export function* doLogin() {
  while (true) {
    const { payload } = yield take(types.LOGIN)
    const response = yield call(login, payload)
    // console.log('loginResponseSaga: ',response)
    yield put(loginResponse(response))
  }
}

export function* doLoginHostInst() {
  while (true) {
    const { payload } = yield take(types.LOGIN_HOSTINST)
    const response = yield call(login, payload)
    // console.log('loginResponseSaga: ',response)
    yield put(loginHostInstResponse(response))
  }
}

export function* doLoginBroker() {
  while (true) {
    const { payload } = yield take(types.LOGIN_BROKER)
    const response = yield call(login, payload)
    // console.log('loginResponseSaga: ',response)
    yield put(loginBrokerResponse(response))
  }
}

export function* doForgetPassword() {
  while (true) {
    const { payload } = yield take(types.FORGOT_PWD)
    const response = yield call(forgotPwd, payload)
    yield put(forgotPwdResponse(response))
  }
}

export function* doForgetPasswordBroker() {
  while (true) {
    const { payload } = yield take(types.FORGOT_PWD_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(forgotPwdBrokerResponse(response))
  }
}

export function* doResetPassword() {
  while (true) {
    const { payload } = yield take(types.RESET_PWD)
    const response = yield call(resetPwd, payload)
    yield put(resetPwdResponse(response))
  }
}

export function* doResetPasswordBroker() {
  while (true) {
    const { payload } = yield take(types.RESET_PWD_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(resetPwdBrokerResponse(response))
  }
}

export function* doLogout() {
  while (true) {
    const { payload } = yield take(types.LOGOUT)
    const response = yield call(logout, payload)
    yield put(logoutResponse(response))
  }
}

export function* doLogoutBroker() {
  while (true) {
    const { payload } = yield take(types.LOGOUT_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(logoutBrokerResponse(response))
  }
}

export function* doLogoutHostInst() {
  while (true) {
    const { payload } = yield take(types.LOGOUT_HOSTINST)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(logoutHostInstResponse(response))
  }
}

export function* doVerifyLink() {
  while (true) {
    const { payload } = yield take(types.VERIFY_ACTIVATION_LINK)
    const response = yield call(postAPIWithoutModule, payload)
    // console.log(response)
    yield put(verifyActivationLinkResponse(response))
  }
}

export function* doResendActivationLink() {
  while (true) {
    const { payload } = yield take(types.RESEND_ACTIVATION_LINK)
    const response = yield call(postAPIWithoutModule, payload)
    // console.log(response)
    yield put(resendActivationLinkResponse(response))
  }
}

export function* doSetupPassword() {
  while (true) {
    const { payload } = yield take(types.SETUP_PASSWORD)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(setupPasswordResponse(response))
  }
}

export function* doSetupPasswordBroker() {
  while (true) {
    const { payload } = yield take(types.SETUP_PASSWORD_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(setupPasswordBrokerResponse(response))
  }
}

function* auth() {
  yield fork(doLogin)
  yield fork(doLoginBroker)
  yield fork(doLoginHostInst)
  yield fork(doForgetPassword)
  yield fork(doForgetPasswordBroker)
  yield fork(doResetPassword)
  yield fork(doResetPasswordBroker)
  yield fork(doLogout)
  yield fork(doLogoutBroker)
  yield fork(doLogoutHostInst)
  // yield fork(doLogout)
  yield fork(doVerifyLink)
  yield fork(doSetupPassword)
  yield fork(doSetupPasswordBroker)
  yield fork(doResendActivationLink)
}


export default auth