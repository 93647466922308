import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cartPageDataResponse } from "../../redux/checkout/actions";
import {
  getMembershipProducts,
  getMembershipProductsClear,
} from "../../redux/membership/actions";
import { clickStreamUser } from "../../utils/AppUtils";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const MembershipSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const membershipProducts = useSelector(
    (state) => state.membership.membershipProducts
  );
  const authBroker = useSelector((state) => state.auth.authBroker);
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const [productData, setProductData] = useState({});
  const [productPrice, setProductPrice] = useState("");

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        getMembershipProducts({
          module: "get_membership_details",
          postData: {
            user_id: authBroker.payload.user.user_id,
            broker_id: authBroker.payload.user.broker_id,
            email: authBroker.payload.user.email,
          },
        })
      );
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    if (!_.isEmpty(membershipProducts)) {
      if (membershipProducts.status === "success") {
        const data = membershipProducts.payload.payload[0];
        //  console.log('data', data)
        data.product_sale_price = data.product_sale_price.toFixed(2);
        setProductPrice(data.product_sale_price);
        setProductData(data);
      }
      dispatch(getMembershipProductsClear());
    }
  }, [membershipProducts]);

  const subscribedClicked = () => {
    clickStreamUser("clicked-subscribe-now-btn", {
      user_id: authBroker.payload.user.user_id,
    });
    dispatch(
      cartPageDataResponse({
        item: 1,
        subTotalPrice: 0,
        price: _.toNumber(productData.product_sale_price),
      })
    );
    history.push(
      `/payment?auto=false&subscription=true&product_id=${productData.product_id}`
    );
  };

  return (
    <section className="container">
      <div className="row">
        <div className="col-md-12 membership_container">
          <div className="search_heading">
            {/* <img src="images/Group 2712.png" width="109" height="106" alt="" /> */}
            <p className="lable-heading_search">Membership</p>
           
          </div>

          <section className="container-fluidprice-list-content membershipplanmob mt-4">
            <div>
              <div className="row no-wrap">
                <div className="pricing-table-new">
                  <table className="membershiptable-rsp table table-striped">
                    <thead>
                      <tr>
                        <th className="membershipBG_WHd"><div className="listLitem">Our Plans</div></th>
                        <th className="membershipBG_BasicPland"><div className="listLitem text-center">Basic Plan</div></th>
                        <th className="price-list-memt">
                          <div className="d-flex justify-content-center"><div className="listLitem text-center">Xsel Premium</div><img src="/images/diamond-stone.svg" alt="" /></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>


                      <tr>
                        <td><div className="listLitem">Pricing</div></td>
                        <td><div className="listLitem"> Free</div></td>
                        <td><div className="listLitem"> {!_.isEmpty(productData) && (
                          <>
                            <span className="supscript"> $</span>
                            <span>
                              {productData?.product_sale_price.split(".")[0]}
                            </span>
                            <sup>
                              {productData?.product_sale_price.split(".")[1]}
                            </sup>
                            &nbsp;CAD
                            <span className="forever-monthly-plan c_whtolor">
                              /{productData.tenure}
                            </span>
                          </>
                        )}</div></td>
                      </tr>
{/* 
                      <tr>
                        <td><div className="listLitem">Single sign on Integration</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td><div className="listLitem">Mobile enabled platform</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td><div className="listLitem">Brokerage branding</div></td>
                        <td><div className="listLitem">Logo change, color scheme</div></td>
                        <td><div className="listLitem">Logo change, color scheme</div></td>
                      </tr> */}
                      <tr>
                        <td><div className="listLitem">20 + hours of Foundational courses 
                         <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Access to all foundational courses developed by NIIT
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        
                        </div>
                        </td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td><div className="listLitem">Best-in class partner courses
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Available for Purchase
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                          </div>
                          </td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td><div className="listLitem">Free access to library of videos
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Short byte-size videos
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">NAR Certification courses
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Available for Purchase
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd">
                          <div className="listLitem">Leaderboard and dashboards
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            For gamification and badges
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd">
                          <div className="listLitem">Monthly Webinar / Live Streaming by Industry Leaders
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Available for free
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem">1</div></td>
                        <td><div className="listLitem">2</div></td>
                      </tr>
                      {/* <tr>
                        <td className="bg-tableodd"><div className="listLitem">Ability to upload Brokerage specific content or curate content from external sources</div></td>
                        <td><div className="listLitem">6 per month</div></td>
                        <td><div className="listLitem">Unlimited</div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Compliance tracking and reporting</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr> */}
                      <tr>
                        <td className="bg-tableodd">
                          <div className="listLitem">NIIT curated learning paths
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Applicable for new and experienced real estate agents
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div>
                        </td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      {/* <tr>
                        <td className="bg-tableodd"><div className="listLitem">Create Brokerage specific onboarding and/or learning paths</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Complete event management, Setup live virtual events (Zoom Integrated) with calendar and Interactive notifications</div></td>
                        <td><div className="listLitem"> 4 per month</div></td>
                        <td><div className="listLitem"> 8 per month</div></td>
                      </tr> */}
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Networking groups for knowledge sharing / collaboration</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Coaching, Mentoring and Live interactions
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Capability to assign coach, mentor, manager and live interaction
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      {/* <tr>
                        <td className="bg-tableodd"><div className="listLitem">Reward Points to Brokerage for all purchases on the Platform for future learning purchases</div></td>
                        <td><div className="listLitem"> 1 points (each $1 spent)</div></td>
                        <td><div className="listLitem"> 2 points (each $1 spent)</div></td>
                      </tr> */}
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Exclusive discounts and offers on purchases</div></td>
                        <td><div className="listLitem"> - </div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Reward points to real estate agents for purchases
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            Can be redeemed on the Platform for future learning purchases
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem"> 1 points (each $1 spent)</div></td>
                        <td><div className="listLitem"> 2 points (each $1 spent)</div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Pre-loaded Interactive and simulated role plays
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 300 }}
                            overlay={ <Tooltip id="button-tooltip">
                            NIIT developed
                          </Tooltip>}
                          >
                            <span variant="success" className="ifobox">i</span>
                          
                          </OverlayTrigger>
                        </div></td>
                        <td><div className="listLitem"> 2</div></td>
                        <td><div className="listLitem"> 4   </div></td>
                      </tr>
                      {/* <tr>
                        <td className="bg-tableodd"><div className="listLitem">Brokerage marketing / communication to the members</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr> */}
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Reporting</div></td>
                        <td><div className="listLitem"> Standard</div></td>
                        <td><div className="listLitem"> Standard</div></td>
                      </tr>
                      <tr>
                        <td className="bg-tableodd"><div className="listLitem">Technical support</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr>
                      {/* <tr>
                        <td className="bg-tableodd"><div className="listLitem">Platform administration control area</div></td>
                        <td><div className="listLitem"> <img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                        <td><div className="listLitem"><img src="/images/Group 5699.svg" width="18" height="18" alt="" /></div></td>
                      </tr> */}
                      <tr>
                        <td></td>
                        <td> {!isPremiumUser && (
                          <div className="membershipCurrentPlans">
                            <div className="listLitem"><strong>Current Plan</strong></div>
                          </div>
                        )}</td>
                        <td> {!isPremiumUser ? (
                          <div className="d-flex justify-content-center">
                            <button
                              type="button"
                              className="btn btn-primary_membership btn-block setup-button btn-primary"
                              disabled={!_.isEmpty(productData) ? "" : true}
                              onClick={() => subscribedClicked()}
                            >
                              Subscribe Now
                            </button>
                          </div>
                        ) : (
                          <div className="membershipCurrentPlans">
                            <div className="listLitem"><strong>Current Plan</strong></div>
                          </div>
                        )}
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <div className="col-xl-4 col-md-4 price-list basic-plan">
                  <ul className="list-inline check-list-member">
                    <div className="membershipBG_WH">
                      <li className="listLitem">Our Plans</li>
                    </div>
                    <div className="membershipBG">
                      <li className="listLitem">Single sign on Integration</li>
                    </div>
                    <div className="membershipBG_Secondary">
                      <li className="listLitem">Mobile enabled platform</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Brokerage branding</li>
                    </div>
                    <div className="membershipBG_Secondary">
                      <li className="listLitem">Access to over 20 hours of Foundational courses (developed by NIIT)</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Best-in class partner courses for purchase </li>
                    </div>
                    <div className="membershipBG_Secondary">
                      <li className="listLitem">Free access to library of bite-sized videos</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">NAR Certification courses for purchase</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Leaderboard and dashboards for gamification and badges</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Free Monthly Webinar / Live Streaming by Industry Leaders</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Ability to upload Brokerage specific content or curate content from external sources</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Compliance tracking and reporting</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">NIIT curated learning paths for new and experienced real estate agents</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Create Brokerage specific onboarding and/or learning paths </li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Complete event management, Setup live virtual events (Zoom Integrated) with calendar and Interactive notifications</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Networking groups for knowledge sharing / collaboration</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Capability to assign coach, mentor, manager and live interaction</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Reward Points to Brokerage for all purchases on the Platform for future learning purchases</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Exclusive discounts and offers on purchases</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Reward points to real estate agents for future learning purchases</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Pre-loaded Interactive and simulated role plays (NIIT developed)</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Brokerage marketing / communication to the members</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Reporting</li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Technical support </li>
                    </div>
                    <div className="membershipBG_Secondary_Grey">
                      <li className="listLitem">Platform administration control area</li>
                    </div>
                  </ul>
                </div>
                <div className="col-xl-3 col-md-4 price-list basic-plan">
                  <ul className="list-inline check-list-member">
                    <div className="membershipBG_BasicPlan">
                      <li className="listLitem">Basic Plan</li>
                    </div>
                    <div className="membershipSecondary_Grey">
                      <li className="listLitem"> <img
                        src="images/Group 5699.svg"
                        width="18"
                        height="18"
                        alt=""
                      /></li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        Logo change, color scheme
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem"><img
                        src="images/Group 5699.svg"
                        width="18"
                        height="18"
                        alt=""
                      /></li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        1
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                      6 per month
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        4 per month
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        1 points (each $1 spent)
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        -
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        1 points (each $1 spent)
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        2
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        Standard
                      </li>
                    </div>
                    <div className="membershipSecondary">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    <div className="membershipSecondary_GreySmall">
                      <li className="listLitem">
                        <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        />
                      </li>
                    </div>
                    {!isPremiumUser && (
                      <div className="membershipCurrentPlans">
                        <li className="listLitem">Current Plan</li>
                      </div>
                    )}
                  </ul>
                </div> */}
                {/* <div className="col-xl-3 col-md-4 price-list basic-plan Premium_MS">
                  <ul className="list-inline check-list-member">
                    <div className="price-list-mem d-flex">
                      <li className="listLitem">Xsel Premium</li>
                      <img src="images/diamond-stone.svg" alt="" />
                    </div>
                    <div className="membershipSecondary_XselPremium">
                      <li className="list-inline-item price-cots ">
                        {!_.isEmpty(productData) && (
                          <>
                            <span className="supscript"> $</span>
                            <span>
                              {productData?.product_sale_price.split(".")[0]}
                            </span>
                            <sup>
                              {productData?.product_sale_price.split(".")[1]}
                            </sup>
                            &nbsp;CAD
                            <span className="forever-monthly-plan c_whtolor">
                              /{productData.tenure}
                            </span>
                          </>
                        )}
                      </li>
                        </div>
                        <div className="membershipSecondary_Grey">
                        <li className="listLitem"> <img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        /></li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          Logo change, color scheme
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem"><img
                          src="images/Group 5699.svg"
                          width="18"
                          height="18"
                          alt=""
                        /></li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          2
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          Unlimited
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          8 per month
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          2 points (each $1 spent)
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          2 points (each $1 spent)
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          4
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          Standard
                        </li>
                      </div>
                      <div className="membershipSecondary">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                      <div className="membershipSecondary_GreySmall">
                        <li className="listLitem">
                          <img
                            src="images/Group 5699.svg"
                            width="18"
                            height="18"
                            alt=""
                          />
                        </li>
                      </div>
                    {!isPremiumUser ? (
                      <div className="mt-4 d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-primary_membership btn-block setup-button btn-primary"
                          disabled={!_.isEmpty(productData) ? "" : true}
                          onClick={() => subscribedClicked()}
                        >
                          Subscribe Now
                        </button>
                      </div>
                    ) : (
                      <div className="membershipCurrentPlans">
                        <li className="listLitem">Current Plan</li>
                      </div>
                    )}
                  </ul>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default MembershipSection;
