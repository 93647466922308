import _ from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toolboxUpdateTimeRequest } from "../../redux/orders/actions";
import { getDateTime, SessionKeys } from "../../utils/AppUtils";
import CONFIG from "../../utils/config";
import Page from "../Page";

const DocumentView = () => {

    const dispatch = useDispatch();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const title = params.get("title");
    const updated = params.get("updatedTime");
    const documentId = params.get("documentId");

    const [userDetails, setUserDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const updateTimeRes = useSelector(state => state.orders.updateTimeRes);
    const [updatedTime, setUpdatedTime] = useState(0)



    const userDetailRes = useSelector(state => state.user.userBroker)
    useEffect(() => {
        // console.log('userDetailRes: ', userDetailRes)
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
            setUserDetails(userDetailRes?.payload?.payload)
        }
    }, [userDetailRes])

    useEffect(() => {
        setUpdatedTime(updated)
    }, [updated])

    const updateTime = (updateTime, docId) => {
        setIsLoading(true);
        dispatch(toolboxUpdateTimeRequest({
            module: "toolboxUpdateTime",
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                document_id: docId,
                updated_at: updateTime
            }
        }))
    }

    useEffect(() => {
        if (!_.isEmpty(updateTimeRes)) {
            setIsLoading(false)
            if (updateTimeRes?.status === "success") {

            }
        }

    }, [updateTimeRes])

    useEffect(() => {
        const timeChange = evt => {
            let dataObj = typeof evt.data === 'object' ? evt.data : JSON.parse(evt.data);
            console.log('timeChangeBefore: ', dataObj)
            if (dataObj && dataObj.method === 'timeChange') {
                console.log('timeChange: ', dataObj)
                setUpdatedTime(dataObj.time)
                // getFolderDetails();
                updateTime(dataObj.time, documentId)


            }
        }
        window.addEventListener("message", timeChange)
        return () => window.removeEventListener("message", timeChange)
    })

    return (
        <>
            <Page>
                <div className="pdf-viewerpage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        
                    <h4>{title}</h4>
                    <p><strong>Last modified  </strong>{getDateTime(Number(updatedTime), userDetails)}</p>
                    <div className="pdfviewsec">
                    <iframe
                        className="w-100"
                        onLoad={() => { window.scrollTo(0, 0); }}
                        src={CONFIG.API_URL_ADMIN + "pdfview/?id=" + documentId + "&u_token=" + localStorage.getItem(SessionKeys.API_TOKEN_BROKER) + "&access=toolbox"} height="100%" width="100%" />
               </div>
                </div>
                </div>
                    </div>
                </div>
            </Page>
        </>
    )
}
export default DocumentView;