import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllEventsBroker,
  getAllEventsNIIT,
  getAllEventsNIITClear,
  getAllEventsBrokerClear,
  getAllEvents,
  getAllEventsClear,
} from "../../redux/events/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import XselEventListSection from "./XselEventListSection";
import BrokerEventListSection from "./BrokerEventListSection";
import Loader from "./../Loader/Loader";
// import ShimmerCardBody from "../Layouts/ShimmerCardBody";
import ShimmerEventCard from "../Layouts/ShimmerEventCard";

const EventListingSection = () => {
  const history = useHistory();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const eventsList = useSelector((state) => state.events.eventsList);

  // const allEventsResBroker = useSelector(
  //   (state) => state.events.allEventsBroker
  // );

  //NIIT EVENTS
  const allEventsRes = useSelector(
    (state) => state.events.allEvents
  );
  //BROKER EVENTS
  const allEventsResBroker = useSelector((state) => state.events.allEventsBroker);

  const allXselEventList = useSelector((state) => state.events.allEvents);
  const allBrokerEventList = useSelector(
    (state) => state.events.allEventsBroker
  );
  // const [searchData, setSearchData] = useState([
  //   allEventsRes,
  //   allEventsResBroker,
  // ]);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showAllData, setShowAllData] = useState(true);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const source = params.get("source");
  const [allEvents, setAllEvents] = useState([]);
  const [allEventsBroker, setAllEventsBroker] = useState([]);
  const [filterEventList, setFilterEventList] = useState([]);
  const [filterBrokerList, setFilterBrokerList] = useState([]);
  const [isLoad, setIsLoad] = useState(true);

  const [isNoDataFound, setIsNoDataFound] = useState(false);

  useEffect(() => {
    // console.log('FILTER', filterEventList, filterBrokerList)
    if (!_.isEmpty(allEvents) || !_.isEmpty(allEventsBroker)) {
      if (filterBrokerList?.length === 0 && filterEventList.length === 0) {
        console.log('FILTER1234', filterEventList, filterBrokerList)
        setIsNoDataFound(true)
      } else {
        setIsNoDataFound(false)
      }
    }
    // if (filterBrokerList !== null && filterEventList !== null) {
    // }
  }, [filterEventList, filterBrokerList])

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      // dispatch(
      //   getAllEvents({
      //     ecom: {
      //       module: "AllEventsApi",
      //       postData: {
      //         module: "AllEventsApi",
      //         user_id: authBroker.payload.user.user_id,
      //         company_id: authBroker?.payload?.user.company_id,
      //       },
      //     },
      //     broker: {
      //       module: "eventListBroker",
      //       postData: {
      //         token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      //       },
      //     },
      //   })
      // );
      getEventDatas();

    }
  }, [dispatch, authBroker]);

  const getEventDatas = () => {
    // setIsNoDataFound(false)
    //XSEL API
    dispatch(getAllEventsNIIT({
      module: 'AllEventsApi',
      postData: {
        module: "AllEventsApi",
        user_id: authBroker.payload.user.user_id,
        company_id: authBroker?.payload?.user.company_id,
      }
    }))
    //BROKER API
    dispatch(getAllEventsBroker({
      module: 'eventListBroker',
      postData: {
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      }
    }))
  }

  useEffect(() => {
    if (!_.isEmpty(allXselEventList) && !_.isEmpty(allBrokerEventList)) {
      if (
        allXselEventList.status !== "success" &&
        allBrokerEventList.status !== "success"
      ) {
        // console.log("all empty");
        setShowAllData(false);
      }
    }
  }, [allXselEventList, allBrokerEventList]);

  useEffect(() => {
    // console.log("Event List", eventsList);
  }, [eventsList]);

  // useEffect(() => {
  //   if (!_.isEmpty(allXselEventList)) {
  //     setIsLoading(false)
  //   }
  // }, [allXselEventList])

  useEffect(() => {
    if (!_.isEmpty(allBrokerEventList)) {
      setIsLoading(false);
    }
  }, [allBrokerEventList, allXselEventList]);

  const goToEventDetail = (item) => {
    clickStreamUser("go-to-event-detail", {
      eventId: item.eventId,
      eventCode: item.eventCode,
      source: item.source,
      userId: authBroker?.payload.user.user_id,
    });
    history.push(
      `/event-detail?event_id=${item?.eventId}&event_code=${item?.event_code}&source=${item.source}`
    );
  };

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchInput(lowerCase);
    // setFilterData("");
  };

  const filterDataHandler = () => {
    setIsNoDataFound(false)
    setFilterData(searchInput);
  };

  //XSEL AND BROKER EVENTS SECTION
  useEffect(() => {
    if (!_.isEmpty(allEventsRes)) {
      setIsLoad(false)
      if (allEventsRes?.status === "success") {
        const eventList = allEventsRes?.payload.payload;
        for (let event of eventList) {
          event.source = "ecom";
        }
        eventList?.sort(function (x, y) {
          return y.event_created - x.event_created;
        });
        setAllEvents(eventList);
      }
      dispatch(getAllEventsNIITClear());
    }
    
  }, [allEventsRes]);

  useEffect(() => {
    if (!_.isEmpty(allEventsResBroker)) {
      setIsLoad(false)
      if (allEventsResBroker?.status === "success") {
        const eventList = allEventsResBroker?.payload.payload;
        for (let event of eventList) {
          event.source = "broker";
        }
        eventList?.sort(function (x, y) {
          return y.event_created - x.event_created;
        });
        setAllEventsBroker(eventList);
      }
      dispatch(getAllEventsBrokerClear());
    }
  }, [allEventsResBroker])


  return (
    <>
      {/* <Loader start={isLoading} /> */}
      <section className="events_page">
        <div className="events_section">
          <div className="container events-content h-76vh">
            <div className="col-md-12">
              <div className="events_banner">
                {/*<img className="img-fluid" src="images/img_icons/event_img.png" alt="events_img" />*/}
                <p className="label-heading_events mb-4">Browse Events</p>
              </div>

              {/* for Search */}
              <>
                {true ? <div className="input-text position-relative d-flex">
                  <label htmlFor="search" className="input__label">
                    Search
                  </label>
                  <input
                    type="search"
                    id="search"
                    name="search"
                    className="input_field mr-2"
                    placeholder=" "
                    value={searchInput}
                    onChange={inputHandler}
                  />
                  <button
                    className="button-reset"
                    style={{ marginTop: "-20px" }}
                    onClick={filterDataHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stopColor="#00b7f1" />
                          <stop offset="1" stopColor="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_191"
                        data-name="Ellipse 191"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_192"
                        data-name="Ellipse 192"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="Icon_awesome-search"
                        data-name="Icon awesome-search"
                        d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                        transform="translate(7.715 7.714)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                </div> : ""}
                {!isLoad ?
                  (filterBrokerList?.length === 0 && filterEventList.length === 0) ? 
                  <div className="not-found cart-notfound">
                    <div className="interboxes">
                      <img src="/images/telescope.svg" alt="Not Found" />
                      <p>Sorry no events are available for your search!</p>
                    </div>
                  </div>:
                <div className="row more_events">
                    <>
                      
                      <XselEventListSection
                        goToEventDetail={goToEventDetail}
                        filterDataset={filterData}
                        allEvents={allEvents}
                        setFilterEventList={setFilterEventList}
                      />
                      <BrokerEventListSection
                        goToEventDetail={goToEventDetail}
                        filterDataset={filterData}
                        allEvents={allEventsBroker}
                        setFilterBrokerList={setFilterBrokerList}
                      />
                    </>
                  </div>
                  : <div className="simmerbox">
                    <div className="row videolistbox">
                      {[1, 2, 3].map((i) => {
                        return <ShimmerEventCard key={`shimmer-${i}`} />;
                      })}
                    </div>
                  </div>
                }
              </>
              {/* {(!isLoading && showAllData)
              ?
              :
              <div className="not-found cart-notfound">
                <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>Presently there are no events, come back later.</p>
                </div>
              </div>
              } */}
            </div>
          </div>
          {/* <p className="text-center load-btn">
                  <button className="btn-primary btn-load">Load More</button>
              </p> */}
        </div>
      </section>
    </>
  );
};

export default EventListingSection;
