import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Page from "../../components/Page";
import ReportDashboardSection from "../../components/Reports/ReportDashboardSection";
import { loginBrokerResponse, loginIframeUserResponse } from "../../redux/auth/actions";
import { userDetailIframeUser, setIframeUserRole } from "../../redux/user/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import CONFIG from "../../utils/config";
import $ from "jquery";

const ReportDashboardMobile = () => {

    const dispatch = useDispatch()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');
    const user_id = params.get('user_id');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const role = params.get('role');

    const [isLoading, setIsLoading] = useState(true)
    const [isValidKey, setIsValidKey] = useState(false)

    useEffect(() => {
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {

            $('html').css("--color-element-1", color_1 ? '#' + color_1 : CONFIG.COLOR_ELEMENT_1);
            $('html').css("--color-element-2", color_2 ? '#' + color_2 : CONFIG.COLOR_ELEMENT_2);
            localStorage.setItem(SessionKeys.API_TOKEN, token);
            localStorage.setItem(SessionKeys.IFRAME_USERNAME, username);
            setIsValidKey(true)
            dispatch(setIframeUserRole(role))
            dispatch(loginIframeUserResponse({
                payload: {
                    user: {
                        user_id: user_id,
                        username: username,
                        role: [role]
                    },
                    apiToken: token
                }
            }))

            dispatch(
                userDetailIframeUser({
                    module: "getOrderDetailBroker",
                    apiToken: token,
                })
            );
            clickStreamUser('report_mobile_page', {
                userId: user_id,
            })
            // TODO: REMOVE
            // setIsValidKey(false)
        }
    }, [token, key, user_id, dispatch])

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
    }, [])

    useEffect(() => {

        window.scrollTo(0, 0)
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
                document.getElementById("cc_div").style.display = "none";
        }, 100)
    }, [])

    return (
        <>
            {isValidKey ?
                <>
                    <ReportDashboardSection
                        isMobile={true} />
                </>
                :
                <div>
                    Invalid Key
                </div>
            }
        </>
    )
};

export default ReportDashboardMobile;
