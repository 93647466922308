import _ from "lodash"
import { useEffect, useState } from "react"
import { getWordSpeedType } from "../../utils/AppUtils"

const RolePlayVideoCard = (props) => {

    const {
        roleInfo,
        selectedRP,
        setSelectedRP
    } = props

    const [roleData, setRoleData] = useState()

    useEffect(() => {
        if (!_.isEmpty(roleInfo)) {
            setRoleData(roleInfo)
        }
    }, [roleInfo])

    return (
        <>
            {!_.isEmpty(roleData) ? 
            <div 
            className={`recording-sec cursor-pointer ${selectedRP.roleplay_id == roleData.roleplay_id ? 'selected' : ''}`} 
            onClick={() => setSelectedRP(roleInfo)}>
                <div className="video-box position-relative">
                    <img src={roleData.thumbnail} />
                    <div className="playbtnvideo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                        <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                            <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                            <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" stroke-width="4">
                                <circle cx="24" cy="24" r="24" stroke="none"/>
                                <circle cx="24" cy="24" r="22" fill="none"/>
                            </g>
                            </g>
                            <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                        </g>
                        </svg>

                    </div>
                </div>
                <div className="video-details-role">
                    <div className="video-title-role">{roleData.title}</div>
                    <div className="video-rating-role">
                        <p className="setimatetitle">Sentiment</p>
                        <div className="d-flex ratings-role">
                            <div className="rolerate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="18.71" viewBox="0 0 18.71 18.71">
                                    <g id="emoji" transform="translate(0 0)">
                                        <path id="Path_12027" data-name="Path 12027" d="M138.895,283.408a4.3,4.3,0,0,0,4.3-4.3h-8.607A4.3,4.3,0,0,0,138.895,283.408Z" transform="translate(-129.54 -268.511)" fill="#0faa36"/>
                                        <ellipse id="Ellipse_57" data-name="Ellipse 57" cx="1.662" cy="1.662" rx="1.662" ry="1.662" transform="translate(4.372 5.043)" fill="#0faa36"/>
                                        <ellipse id="Ellipse_58" data-name="Ellipse 58" cx="1.662" cy="1.662" rx="1.662" ry="1.662" transform="translate(11.013 5.043)" fill="#0faa36"/>
                                        <path id="Path_12028" data-name="Path 12028" d="M2.638,18.71H16.072a2.641,2.641,0,0,0,2.638-2.638V2.638A2.641,2.641,0,0,0,16.072,0H2.638A2.641,2.641,0,0,0,0,2.638V16.072A2.641,2.641,0,0,0,2.638,18.71ZM1.169,2.638A1.47,1.47,0,0,1,2.638,1.169H16.072a1.47,1.47,0,0,1,1.469,1.469V16.072a1.47,1.47,0,0,1-1.469,1.469H2.638a1.47,1.47,0,0,1-1.469-1.469Z" transform="translate(0 0)" fill="#0faa36"/>
                                    </g>
                                </svg>
                                <strong> {roleData.roleplay_sentiment_positive}%</strong>
                            </div>
                            <div className="rolerate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="18.71" viewBox="0 0 18.71 18.71">
                                <g id="sad_1_" data-name="sad (1)" transform="translate(0 0)">
                                    <ellipse id="Ellipse_59" data-name="Ellipse 59" cx="1.662" cy="1.662" rx="1.662" ry="1.662" transform="translate(4.372 5.043)" fill="#e78312"/>
                                    <ellipse id="Ellipse_60" data-name="Ellipse 60" cx="1.662" cy="1.662" rx="1.662" ry="1.662" transform="translate(11.014 5.043)" fill="#e78312"/>
                                    <rect id="Rectangle_321" data-name="Rectangle 321" width="7.162" height="1.194" transform="translate(5.774 12.104)" fill="#e78312"/>
                                    <path id="Path_12029" data-name="Path 12029" d="M2.638,18.71H16.072a2.641,2.641,0,0,0,2.638-2.638V2.638A2.641,2.641,0,0,0,16.072,0H2.638A2.641,2.641,0,0,0,0,2.638V16.072A2.641,2.641,0,0,0,2.638,18.71ZM1.169,2.638A1.47,1.47,0,0,1,2.638,1.169H16.072a1.47,1.47,0,0,1,1.469,1.469V16.072a1.47,1.47,0,0,1-1.469,1.469H2.638a1.47,1.47,0,0,1-1.469-1.469Z" transform="translate(0 0)" fill="#e78312"/>
                                </g>
                                </svg>
                                <strong> {roleData.roleplay_sentiment_neutral}%</strong>
                            </div>
                            <div className="rolerate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="18.71" viewBox="0 0 18.71 18.71">
                                <g id="sad" transform="translate(0 0)">
                                    <ellipse id="Ellipse_61" data-name="Ellipse 61" cx="1.662" cy="1.662" rx="1.662" ry="1.662" transform="translate(4.372 5.043)" fill="#e91e63"/>
                                    <ellipse id="Ellipse_62" data-name="Ellipse 62" cx="1.662" cy="1.662" rx="1.662" ry="1.662" transform="translate(11.014 5.043)" fill="#e91e63"/>
                                    <path id="Path_12030" data-name="Path 12030" d="M120.075,300.717c.165-.127,4.072-3.076,8.031,0l.685-.957c-4.656-3.618-9.356-.036-9.4,0Z" transform="translate(-114.735 -286.797)" fill="#e91e63"/>
                                    <path id="Path_12031" data-name="Path 12031" d="M2.638,18.71H16.072a2.641,2.641,0,0,0,2.638-2.638V2.638A2.641,2.641,0,0,0,16.072,0H2.638A2.641,2.641,0,0,0,0,2.638V16.072A2.641,2.641,0,0,0,2.638,18.71ZM1.169,2.638A1.47,1.47,0,0,1,2.638,1.169H16.072a1.47,1.47,0,0,1,1.469,1.469V16.072a1.47,1.47,0,0,1-1.469,1.469H2.638a1.47,1.47,0,0,1-1.469-1.469Z" transform="translate(0 0)" fill="#e91e63"/>
                                </g>
                                </svg>
                                <strong> {roleData.roleplay_sentiment_negative}%</strong>
                            </div>
                        </div>
                    </div>
                    <div className="videotiming-role d-flex">
                        <div className="videotimebox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path id="clock-time-four" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z" transform="translate(-2 -2)" fill="var(--color-element-1)"/>
                            </svg>
                            <span><strong> {roleData.roleplay_duration}</strong> mins</span>
                        </div>
                        <div className="videotimebox">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="Speed" d="M11690-1677a8.009,8.009,0,0,1-8-8,8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8A8.009,8.009,0,0,1,11690-1677Zm-.023-6.749h0l.967.909v2.727h.919v-3.408l-.966-.91.275-1.365a3.409,3.409,0,0,0,2.534,1.136v-.907a2.267,2.267,0,0,1-1.979-1.092l-.461-.728a.958.958,0,0,0-.783-.455.9.9,0,0,0-.186.024.908.908,0,0,1-.185.023l-2.4,1v2.134h.922v-1.544l.828-.32-.737,3.684-2.255-.455-.185.91,3.224.637.461-2Zm1.2-6.139a.917.917,0,0,0-.922.91.917.917,0,0,0,.922.91.917.917,0,0,0,.922-.91A.917.917,0,0,0,11691.174-1689.888Z" transform="translate(-11682 1693)" fill="#7a549e"/>
                        </svg>
                            <span><strong className="slowcolor"> {getWordSpeedType(roleData.roleplay_wpm)}</strong> - {roleData.roleplay_wpm} words/min</span>
                        </div>
                    </div>
                </div>
            </div> 
            : ''}
        </>
    )
}

export default RolePlayVideoCard