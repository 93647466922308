const types = {

  // courses categories
  VIDEO_COURSE_CATEGORIES: "VIDEO_COURSE_CATEGORIES",
  VIDEO_COURSE_CATEGORIES_RESPONSE: "VIDEO_COURSE_CATEGORIES_RESPONSE",
  VIDEO_COURSE_CATEGORIES_CLEAR: "VIDEO_COURSE_CATEGORIES_CLEAR",

  // Video listing
  VIDEO_COURSES_LISTING: "VIDEO_COURSES_LISTING",
  VIDEO_COURSES_LISTING_RESPONSE: "VIDEO_COURSES_LISTING_RESPONSE",
  VIDEO_COURSES_LISTING_CLEAR: "VIDEO_COURSE_CATEGORIES_CLEAR",

  // Video listing
  VIDEO_COURSES_LISTING_BY_CAT: "VIDEO_COURSES_LISTING_BY_CAT",
  VIDEO_COURSES_LISTING_BY_CAT_RESPONSE: "VIDEO_COURSES_LISTING_BY_CAT_RESPONSE",
  VIDEO_COURSES_LISTING_BY_CAT_CLEAR: "VIDEO_COURSES_LISTING_BY_CAT_CLEAR",

  // Video listing
  VIDEO_COURSES_LISTING_RELATED: "VIDEO_COURSES_LISTING_RELATED",
  VIDEO_COURSES_LISTING_RELATED_RESPONSE: "VIDEO_COURSES_LISTING_RELATED_RESPONSE",
  VIDEO_COURSES_LISTING_RELATED_CLEAR: "VIDEO_COURSES_LISTING_BY_CAT_CLEAR",

  // Video listing
  VIDEO_COURSES_LISTING_BY_SUB_CAT: "VIDEO_COURSES_LISTING_BY_SUB_CAT",
  VIDEO_COURSES_LISTING_BY_SUB_CAT_RESPONSE: "VIDEO_COURSES_LISTING_BY_SUB_CAT_RESPONSE",
  VIDEO_COURSES_LISTING_BY_SUB_CAT_CLEAR: "VIDEO_COURSES_LISTING_BY_SUB_CAT_CLEAR",

   // Video Details from Ecom 
   VIDEO_DETAIL_LISTING: "VIDEO_DETAIL_LISTING",
   VIDEO_DETAIL_LISTING_RESPONSE: "VIDEO_DETAIL_LISTING_RESPONSE",
   VIDEO_DETAIL_LISTING_CLEAR: "VIDEO_DETAIL_LISTING_CLEAR",

   //video list from brokerage  
   VIDEO_DETAIL_BROKER_ADMIN: "VIDEO_DETAIL_BROKER_ADMIN",
   VIDEO_DETAIL_BROKER_ADMIN_RESPONSE: "VIDEO_DETAIL_BROKER_ADMIN_RESPONSE",
   VIDEO_DETAIL_BROKER_ADMIN_CLEAR: "VIDEO_DETAIL_BROKER_ADMIN_CLEAR",
   //video detail from brokerage  
   VIDEO_INFO_BROKER_ADMIN: "VIDEO_INFO_BROKER_ADMIN",
   VIDEO_INFO_BROKER_ADMIN_RESPONSE: "VIDEO_INFO_BROKER_ADMIN_RESPONSE",
   VIDEO_INFO_BROKER_ADMIN_CLEAR: "VIDEO_INFO_BROKER_ADMIN_CLEAR",

   //video completion track  
   VIDEO_COMPLETION_TRACK: "VIDEO_COMPLETION_TRACK",
   VIDEO_COMPLETION_TRACK_RESPONSE: "VIDEO_COMPLETION_TRACK_RESPONSE",
   VIDEO_COMPLETION_TRACK_CLEAR: "VIDEO_COMPLETION_TRACK_CLEAR",

   MY_VIDEO_FETCH: "MY_VIDEO_FETCH",
   MY_VIDEO_RESPONSE: "MY_VIDEO_RESPONSE",
   MY_VIDEO_CLEAR: "MY_VIDEO_CLEAR",
}
export default types;