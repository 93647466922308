import _ from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Gallery from "react-fine-uploader";
import { useDispatch, useSelector } from "react-redux";
import { createFileReq, createFoldrReq } from "../../redux/orders/actions";
import { SessionKeys } from "../../utils/AppUtils";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Loader from "../../components/Loader/Loader";
import CONFIG from "../../utils/config";
import ConfirmModal from "../../components/modal/ConfirmModal";

const FolderCreateFileUpload = (props) => {
    const { getFolderDetails, folderId } = props;
    const dispatch = useDispatch();
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [folderName, setFolderName] = useState("")
    const [color, setColor] = useState("#808080");
    const [isLoading, setIsLoading] = useState(false)
    const [showUpload, setShowDetailsUpload] = useState(false)
    const [fileCheck, setFileCheck] = useState(false)
    const createFolderRes = useSelector(state => state.orders.createFolderRes);
    const createFileRes = useSelector(state => state.orders.createFileRes);
    const [fileCreateStatus, setFileCreateStatus] = useState(true);
    const [noFolderError, setNoFolderError] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);

    const handleCloseUpload = () => {
        setShowDetailsUpload(false);
        setFileCheck(false)
    };
    const createFolderFun = (e) => {
        if (_.isEmpty(folderName.trim())) {
            return;
        }
        let req = {
            module: 'createFolder',
            postData: {
                folder_name: folderName,
                folder_color: color,
                folder_parent_id: folderId,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
            }
        }
        setIsLoading(true);
        dispatch(createFoldrReq(req));
        handleClose()
    }
    useEffect(() => {
        // console.log('folderList:', createFolderRes)
        if (!_.isEmpty(createFolderRes)) {
            setIsLoading(false)
            if (createFolderRes?.status === "success") {
                getFolderDetails();
            }
        }
    }, [createFolderRes])
    const showFileUpload = () => {
        setShowDetailsUpload(true)
    }

    const createFileFunction = (fileName, filePath, fileSize) => {
        dispatch(createFileReq({
            module: "createFile",
            postData: {
                file_name: fileName,
                file_path: filePath,
                file_type: "pdf",
                file_size: fileSize,
                folder_id: folderId,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
            }
        }))

        // setFileCreateStatus(true)
    }
    useEffect(() => {
        if (!_.isEmpty(createFileRes)) {
            setIsLoading(false)
            setFileCreateStatus(false)
            if (createFileRes?.status === "success") {
                handleCloseUpload();
                getFolderDetails();
            }
        }
    }, [createFileRes])

    const uploader = new FineUploaderTraditional({
        options: {
            chunking: {
                enabled: true,
                success: {

                    endpoint: CONFIG.API_URL_REAL_BROKER + "wp-content/plugins/xsel-toolbox/fine-uploader/endpoint-cors.php?done"
                },
                concurrent: {
                    enabled: true
                },

            },
            deleteFile: {
                method: 'DELETE',
                enabled: true,
                forceConfirm: true,
                endpoint: CONFIG.API_URL_REAL_BROKER + "wp-content/plugins/xsel-toolbox/fine-uploader/endpoint-cors.php"
            },
            request: {
                endpoint: CONFIG.API_URL_REAL_BROKER + "wp-content/plugins/xsel-toolbox/fine-uploader/endpoint-cors.php"
            },
            retry: {
                enableAuto: true
            },
            resume: {
                enabled: true
            },
            multiple: false,
            callbacks: {
                onSubmit: async (id) => {
                    // console.log('onsubmit id', id)
                },
                onComplete: (id, name, response) => {
                    // console.log('on complete callback', response)
                    createFileFunction(response.originalFileName, response.weblink, response.fileSize)
                },
                onTotalProgress: (totalUploadedBytes, totalBytes) => {
                    // console.log('onTotalProgress', totalUploadedBytes, totalBytes)
                },
                onError: (id, name, errorReason) => {
                    // console.log('error', errorReason)
                    if (errorReason.includes('invalid extension')) {
                        handleCloseUpload();
                        setConfirmModal(true);
                    }
                }
            },
            validation: {
                itemLimit: 1,
                minSizeLimit: 1,
                sizeLimit: (80 * 1000000),
                allowedExtensions: ['pdf']
            }
        }
    });

    return (<>
        <Loader start={isLoading} />
        <div className="upload-btn-sec mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex gap-3">
                        <button className="btn-primary reviewbtn rpsubmitbtn" onClick={() => {
                            setColor("#808080");
                            handleShow();
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.313" height="13.05" viewBox="0 0 16.313 13.05">
                                <g id="Group_6599" data-name="Group 6599" transform="translate(-22.333 -8.551)">
                                    <path id="Subtraction_5" data-name="Subtraction 5" d="M14.68,13.05H1.631A1.633,1.633,0,0,1,0,11.418L.009,1.631A1.629,1.629,0,0,1,1.631,0H6.524L8.156,1.631H14.68a1.633,1.633,0,0,1,1.632,1.631v8.156A1.634,1.634,0,0,1,14.68,13.05ZM6.2,6.526a.876.876,0,0,0,0,1.752H7.517V9.591a.876.876,0,0,0,1.752,0V8.278h1.315a.876.876,0,0,0,0-1.752H9.269V5.211a.876.876,0,0,0-1.752,0V6.526Z" transform="translate(22.333 8.551)" fill="#7a549e" />
                                </g>
                            </svg>
                            New Folder
                        </button>
                        <button className="btn-primary reviewbtn rpsubmitbtn" onClick={showFileUpload}>
                            <svg id="Group_1507" data-name="Group 1507" xmlns="http://www.w3.org/2000/svg" width="14.325" height="15.334" viewBox="0 0 14.325 15.334">
                                <g id="Icon_ionic-ios-document" data-name="Icon ionic-ios-document">
                                    <path id="Path_1288" data-name="Path 1288" d="M21.237,7.253h3.045a.166.166,0,0,0,.167-.167h0a.99.99,0,0,0-.358-.766L21.538,4.192a1.075,1.075,0,0,0-.689-.248h0a.247.247,0,0,0-.248.248V6.618A.635.635,0,0,0,21.237,7.253Z" transform="translate(-14.278 -3.941)" fill="var(--color-element-1)" />
                                    <path id="Path_1289" data-name="Path 1289" d="M12.766,6.614V3.937H8.383A1.074,1.074,0,0,0,7.313,5.008V16.25A1.074,1.074,0,0,0,8.383,17.32h8.03a1.074,1.074,0,0,0,1.071-1.071V8.12H14.271A1.508,1.508,0,0,1,12.766,6.614Z" transform="translate(-7.313 -3.937)" fill="var(--color-element-1)" />
                                </g>
                                <path id="Icon_awesome-download" data-name="Icon awesome-download" d="M2.8,0h1.7A.508.508,0,0,1,5,.509V4.072h1.86a.423.423,0,0,1,.3.723L3.934,8.023a.411.411,0,0,1-.579,0L.125,4.8a.423.423,0,0,1,.3-.723H2.286V.509A.508.508,0,0,1,2.8,0Z" transform="translate(13.813 14.834) rotate(180)" fill="var(--color-element-1)" stroke="#fff" stroke-width="1" />
                            </svg>
                            File Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            show={show}
            onHide={handleClose}
            size="md"
            dialogClassName="modalassign addfoldermodal"
            aria-labelledby="example-custom-modal-styling-title"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    New Folder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-add-folder">
                    <div className="input-lear position-relative d-flex">
                        <label for="FolderName" className="input__label_learning">Folder Name</label>
                        <input type="text" id="foldername" name="foldername" className="input_searchfield_search" placeholder="Folder Name"
                            autocomplete="off"
                            onChange={(e) => setFolderName(e.target.value)}
                        />
                    </div>
                    <div className="folder-color">
                        <h5>Choose Color</h5>
                        <div className="bGButton">
                            <button className="grey" onClick={() => setColor("#B1B1B1")}>
                                {(color === "#B1B1B1") ? <i className="fa fa-check" aria-hidden="true"></i> : ""}
                            </button>
                            <button className="purple" onClick={() => setColor(" #A020F0")}>{(color === " #A020F0") ? <i className="fa fa-check" aria-hidden="true"></i> : ""}</button>
                            <button className="red" onClick={() => setColor("#ED156E")}>{(color === "#ED156E") ? <i className="fa fa-check" aria-hidden="true"></i> : ""}</button>
                            <button className="orange" onClick={() => setColor("#FF6C02")}>{(color === "#FF6C02") ? <i className="fa fa-check" aria-hidden="true"></i> : ""}</button>
                            <button className="blue" onClick={() => setColor("#63C3DD")}>{(color === "#63C3DD") ? <i className="fa fa-check" aria-hidden="true"></i> : ""}</button>
                            <button className="green" onClick={() => setColor("#00B70E")}>{(color === "#00B70E") ? <i className="fa fa-check" aria-hidden="true"></i> : ""}</button>
                        </div>
                    </div>
                    <div className="buttonsteammate mt-3">
                        <button type="button" className="btn btn-primary btn-block back-button" onClick={handleClose}>Back</button>
                        <button type="button" className="btn btn-primary btn-block setup-button" onClick={() => { createFolderFun() }}>Create</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>


        <Modal
            show={showUpload}
            onHide={handleCloseUpload}
            size="lg"
            dialogClassName="modalassign addfoldermodal"
            aria-labelledby="example-custom-modal-styling-title"
            centered>
            {/* <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Details
                    </Modal.Title>
                </Modal.Header> */}
            <Modal.Body>
                <Gallery uploader={uploader} />
                <div className="buttonsteammate mt-3">
                    {false && <button type="button" className="btn btn-primary btn-block setup-button" onClick={() => {
                        // createFileFunction();
                        // setFileCreateStatus(true)
                        // setFileCheck(true)
                    }}>
                        {(!fileCheck) ? "Save" : "File Uploaded"}
                    </button>}&nbsp;&nbsp;
                    <button type="button" className="btn btn-primary btn-block setup-button" onClick={
                        handleCloseUpload
                    }>Close</button>
                </div>
            </Modal.Body>
        </Modal>

        <ConfirmModal
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            showModalMsg={"Only pdf files are allowed."}
            isConfirm={false}
            confirmId={''}
            confirmAction={function name(params) { }}
            isRightIcon={false} />
    </>)
}
export default FolderCreateFileUpload;