import EventListingSection from "../../components/Events/EventListingSection";
import Page from "../../components/Page";


const AllEventsPage = () => {

  return (
    <Page>
      <EventListingSection />
    </Page>

  )


}

export default AllEventsPage;