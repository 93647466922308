import { fork, take, call, put, all } from "redux-saga/effects";

import {
  getProfileCompletionReportResponse,
  searchResponse,
  getOnboardingCompletionReportResponse,
  getAnyTotalReportResponse,
  getOfficeListResponse,
  getOverAllSummaryResponse,
  getOverAllDataResponse,
  getOverAllGraphResponse,
  getCrsCompletionTrendResponse,
  getComplianceReportResponse,
  getComplianceCompletionTrendResponse,
  getCoeReaResponse,
  getCoeManagersResponse,
  getCoeOfficesResponse,
  getCoeTrendCurveResponse,
  getLpTableResponse,
  getLpReaResponse,
  getLpManagersResponse,
  getLpOfficesResponse,
  getLpTrendResponse,
  getLpStatsResponse,
  getOnboardReaResponse,
  getOnboardManagersResponse,
  getOnboardOfficesResponse,
  getOnboardTrendResponse,
  getOnboardStatsResponse,
  getCrsReportReaResponse,
  getCrsReportManagersResponse,
  getCrsReportOfficesResponse,
  getCrsReportStatsResponse,
  getCrsReportTrendResponse
} from "./actions";
import types from "./types";
import { getAPIWithID, postAPIWithoutModule } from "../../api/generic";

export function* doGetProfileReport() {
  while (true) {
    const { payload } = yield take(types.PROFILE_COMPLETION_REPORT);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getProfileCompletionReportResponse(response));
  }
}
// Onboarding Report section
export function* doGetonBoardingReport() {
  while (true) {
    const { payload } = yield take(types.ONBOARDING_COMPLETION_REPORT);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOnboardingCompletionReportResponse(response));
  }
}

export function* doGetAnyTotalReport() {
  while (true) {
    const { payload } = yield take(types.ANY_TOTAL_REPORT);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getAnyTotalReportResponse(response));
  }
}

export function* doGetOfficeList() {
  while (true) {
    const { payload } = yield take(types.GET_OFFICE_LIST);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOfficeListResponse(response));
  }
}
export function* doGetOverallCourseSummary() {
  while (true) {
    const { payload } = yield take(types.OVERALL_SUMMARY_PROGRESS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOverAllSummaryResponse(response));
  }
}

export function* doGetOverallData() {
  while (true) {
    const { payload } = yield take(types.OVERALL_DATA);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOverAllDataResponse(response));
  }
}
export function* doGetOverallCrsGraph() {
  while (true) {
    const { payload } = yield take(types.OVERALL_PROGRESS_GRAPH);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOverAllGraphResponse(response));
  }
}
export function* doGetCrsCompletionTrend() {
  while (true) {
    const { payload } = yield take(types.COURSE_COMPLETION_TREND);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCrsCompletionTrendResponse(response));
  }
}
export function* doGetComplianceReport() {
  while (true) {
    const { payload } = yield take(types.COMPLIANCE_REPORT);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getComplianceReportResponse(response));
  }
}
export function* doGetComplianceCompletionTrend() {
  while (true) {
    const { payload } = yield take(types.COMPLIANCE_COMPLETION_TREND);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getComplianceCompletionTrendResponse(response));
  }
}
//COE REA
export function* doGetCoeReaSec() {
  while (true) {
    const { payload } = yield take(types.COE_REA);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCoeReaResponse(response));
  }
}
//COE Managers
export function* doGetCoeManagerSec() {
  while (true) {
    const { payload } = yield take(types.COE_MANAGER);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCoeManagersResponse(response));
  }
}
//COE Offices
export function* doGetCoeOfficesSec() {
  while (true) {
    const { payload } = yield take(types.COE_OFFICES);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCoeOfficesResponse(response));
  }
}

//COE TREND
export function* doGetCoeTrend() {
  while (true) {
    const { payload } = yield take(types.COE_TREND);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCoeTrendCurveResponse(response));
  }
}

//LP Table
export function* doGetLpTable() {
  while (true) {
    const { payload } = yield take(types.LP_TABLE);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLpTableResponse(response));
  }
}
//lp rea
export function* doGetLpRea() {
  while (true) {
    const { payload } = yield take(types.LP_REA);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLpReaResponse(response));
  }
}
//lp Managers
export function* doGetLpManagers() {
  while (true) {
    const { payload } = yield take(types.LP_MANAGERS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLpManagersResponse(response));
  }
}
//lp Offices
export function* doGetLpOffices() {
  while (true) {
    const { payload } = yield take(types.LP_OFFICES);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLpOfficesResponse(response));
  }
}
//lp Offices
export function* doGetLpTrend() {
  while (true) {
    const { payload } = yield take(types.LP_TREND);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLpTrendResponse(response));
  }
}
//lp Stats
export function* doGetLpStats() {
  while (true) {
    const { payload } = yield take(types.LP_STATS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLpStatsResponse(response));
  }
}

//Onboard REA
export function* doGetOnboardRea() {
  while (true) {
    const { payload } = yield take(types.ONBOARD_REPORT_REA);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOnboardReaResponse(response));
  }
}
//Onboard MANAGERS
export function* doGetOnboardManagers() {
  while (true) {
    const { payload } = yield take(types.ONBOARD_REPORT_MANAGERS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOnboardManagersResponse(response));
  }
}
//Onboard offices
export function* doGetOnboardOffices() {
  while (true) {
    const { payload } = yield take(types.ONBOARD_REPORT_OFFICES);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOnboardOfficesResponse(response));
  }
}
//Onboard Trend
export function* doGetOnboardTrend() {
  while (true) {
    const { payload } = yield take(types.ONBOARD_REPORT_TREND);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOnboardTrendResponse(response));
  }
}
//Onboard Stats
export function* doGetOnboardStats() {
  while (true) {
    const { payload } = yield take(types.ONBOARD_REPORT_STATS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOnboardStatsResponse(response));
  }
}

//Course Report REA
export function* doGetCrsReportRea() {
  while (true) {
    const { payload } = yield take(types.COURSE_REPORT_REA);
    const response = yield call(postAPIWithoutModule,payload);
    yield put(getCrsReportReaResponse(response));
  }
}
//Course Report Managers
export function* doGetCrsReportManagers() {
  while (true) {
    const { payload } = yield take(types.COURSE_REPORT_MANAGERS);
    const response = yield call(postAPIWithoutModule,payload);
    yield put(getCrsReportManagersResponse(response));
  }
}
//Course Report Offices
export function* doGetCrsReportOffices() {
  while (true) {
    const { payload } = yield take(types.COURSE_REPORT_OFFICES);
    const response = yield call(postAPIWithoutModule,payload);
    yield put(getCrsReportOfficesResponse(response));
  }
}
//Course Report Stats
export function* doGetCrsReportStats() {
  while (true) {
    const { payload } = yield take(types.COURSE_REPORT_STATS);
    const response = yield call(postAPIWithoutModule,payload);
    yield put(getCrsReportStatsResponse(response));
  }
}
//Course Report Completion Trend
export function* doGetCrsReportTrend() {
  while (true) {
    const { payload } = yield take(types.COURSE_REPORT_COMP_TREND);
    const response = yield call(postAPIWithoutModule,payload);
    yield put(getCrsReportTrendResponse(response));
  }
}

function* search() {
  yield fork(doGetProfileReport);
  yield fork(doGetonBoardingReport);
  yield fork(doGetAnyTotalReport);
  yield fork(doGetOfficeList);
  yield fork(doGetOverallCourseSummary);
  yield fork(doGetOverallData);
  yield fork(doGetOverallCrsGraph);
  yield fork(doGetCrsCompletionTrend);
  yield fork(doGetComplianceReport);
  yield fork(doGetComplianceCompletionTrend);
  yield fork(doGetCoeReaSec);
  yield fork(doGetCoeManagerSec);
  yield fork(doGetCoeOfficesSec);
  yield fork(doGetCoeTrend);
  yield fork(doGetLpTable);
  yield fork(doGetLpRea);
  yield fork(doGetLpManagers);
  yield fork(doGetLpOffices);
  yield fork(doGetLpTrend);
  yield fork(doGetLpStats);
  yield fork(doGetOnboardRea);
  yield fork(doGetOnboardManagers);
  yield fork(doGetOnboardOffices);
  yield fork(doGetOnboardTrend);
  yield fork(doGetOnboardStats);
  yield fork(doGetCrsReportRea);
  yield fork(doGetCrsReportManagers);
  yield fork(doGetCrsReportOffices);
  yield fork(doGetCrsReportStats);
  yield fork(doGetCrsReportTrend);
}
export default search;
