import Modal from 'react-bootstrap/Modal';
import SelectOption from "../AssignModal/SelectOption";
import ChooseTeammate from "../AssignModal/ChooseTeammate";
import ChooseTeams from "../AssignModal/ChooseTeams";
import CreateTeam from "../AssignModal/CreateTeam";
import CreateteamOptions from "../AssignModal/CreateteamOptions";
import CoursesAssign from "../AssignModal/CoursesAssign";
import ChoosePeople from "../AssignModal/ChoosePeople";
import SelectpeopleOptions from "../AssignModal/SelectpeopleOptions";
import GenerateaReport from "../AssignModal/GenerateaReport";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ChooseFromCompletedCourse from '../AssignModal/ChooseFromCompletedCourse';
import Loader from '../Loader/Loader';
import { editPostReq, editPostResponseClear, getEditComments, getEditCommentsResponseClear } from '../../redux/myNetwork/actions';
import { SessionKeys } from '../../utils/AppUtils';
import _ from 'lodash';
import ReactRoundedImage from "react-rounded-image";
import { onEditUploadReq, onPostUploadReq } from '../../redux/user/actions';
const EditPostModal = (props) => {
    const { show, setShow, currPostId, currEditContent, isCmnt,
        currentClickPos,
        currentClickCmnt,
        currentClickSubCmnt,
        setPostFeedList,
        postFeedList,
        preImagesParent,
        fromPage,

    } = props;
    const dispatch = useDispatch();
    var editPostRes = useSelector((state) => state.myNetwork.editPostRes);
    var editCommentsRes = useSelector((state) => state.myNetwork.editCommentsRes);
    const [editContent, setEditContent] = useState(currEditContent);
    const [postImage, setPostImage] = useState([]);
    const [preImages, setPreImages] = useState([]);
    const [uploadImgErr, setUploadImgErr] = useState('');
    const [uploadContentErr, setUploadContentErr] = useState('');
    const [loading, setLoading] = useState(false);
    const authBroker = useSelector(state => state.auth.authBroker);
    var editUploadRes = useSelector(state => state.user.editUploadRes);


    useEffect(() => {
        setPostImage([])
        setEditContent(currEditContent)
    }, [currEditContent])

    useEffect(() => {
        if (preImagesParent && preImagesParent?.length > 0) {
            setPreImages(preImagesParent)
        } else {
            setPreImages([])
        }
    }, [preImagesParent])



    const onUpload = (mediaType) => {
        setUploadImgErr("")
        setLoading(true)
        setUploadContentErr('');
        // setIsLoading(true)
        const formData = new FormData();
        formData.append('token', localStorage.getItem(SessionKeys.API_TOKEN_BROKER));
        formData.append('activity_id', currPostId);
        formData.append('post_content', editContent);
        postImage.map((item) => {
            formData.append('image[]', item);
        })
        formData.append('media_type', postImage.length > 0 ? 'image' : '');
        formData.append('old_image', preImages.toString());
        let tempObj = {
            module: 'editPost',
            postData: formData
        }
        dispatch(onEditUploadReq(tempObj))


    }

    useEffect(() => {
        if (!_.isEmpty(editUploadRes)) {
            setLoading(false)
            if (editUploadRes?.status === "success") {
                addPostLocally(editUploadRes?.data)
                dispatch(editPostResponseClear())
                setShow(false);
            }
        }
    }, [editUploadRes])

    const addPostLocally = (res) => {

        if (postFeedList && postFeedList?.length > 0) {
            let arr = _.cloneDeep(postFeedList)
            let tempObj = arr[currentClickPos];
            tempObj.post_content = res?.post_content;
            tempObj.images = res?.images;
            setPostFeedList(arr);
        }


    }


    const editPost = () => {
        setLoading(true);

        if (isCmnt === 0) {
            dispatch(
                editPostReq({
                    module: "editPost",
                    postData: {
                        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                        activity_id: currPostId,
                        post_content: editContent
                    },
                })
            );
        } else {
            dispatch(
                getEditComments({
                    module: "getEditComments",
                    postData: {
                        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                        comments_id: currPostId,
                        comments: editContent,
                    },
                })
            );
        }
    }

    useEffect(() => {
        if (!_.isEmpty(editPostRes)) {
            setLoading(false)
            if (editPostRes?.status === 'success') {
                setShow(false);
                // getActivityPostList();
                dispatch(editPostResponseClear())
                if (postFeedList && postFeedList?.length > 0) {
                    let arr = _.cloneDeep(postFeedList)
                    let tempObj = arr[currentClickPos];
                    tempObj.post_content = editContent;
                    setPostFeedList(arr);
                }


            }
        }
    }, [editPostRes])

    useEffect(() => {
        if (!_.isEmpty(editCommentsRes)) {
            setLoading(false)
            if (editCommentsRes?.status === 'success') {
                setShow(false);
                // getActivityPostList();
                dispatch(getEditCommentsResponseClear())
                if (postFeedList && postFeedList?.length > 0) {
                    if (currentClickSubCmnt === -1) {
                        let arr = _.cloneDeep(postFeedList)
                        let tempObj = arr[currentClickPos].comment_details[currentClickCmnt];
                        tempObj.comments = editContent;
                        setPostFeedList(arr);
                    } else {
                        let arr = _.cloneDeep(postFeedList)
                        let tempObj = arr[currentClickPos].comment_details[currentClickCmnt].sub_comments[currentClickSubCmnt];
                        tempObj.comments = editContent;
                        setPostFeedList(arr);
                    }
                }
            }
        }
    }, [editCommentsRes])

    const removeSelectedImage = (index) => {

        let imgArr = _.cloneDeep(postImage)
        imgArr.splice(index, 1);
        setPostImage(imgArr);
        setUploadImgErr("");
    }

    const removePreImage = (index) => {

        let imgArr = _.cloneDeep(preImages)
        imgArr.splice(index, 1);
        setPreImages(imgArr);
        setUploadImgErr("");
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="md"
                dialogClassName="modal-90w modalassign"
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Edit {isCmnt === 0 ? 'Post' : isCmnt === 1 ? 'Comment' : 'Reply'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loader start={loading} />
                    <div className="justify-content-between profileUserList community-postsec">

                        {isCmnt !== 0 ? <div className={isCmnt === 0 ? "input-text position-relative mywall-comm d-flex" : "input-text position-relative d-flex"}>
                            <label htmlFor="password" className="input__label">
                                Edit Post
                            </label>
                            <textarea
                                type="text"
                                id="password"
                                name="password"
                                className="input__Field02"
                                placeholder=" "
                                value={editContent}
                                onChange={(evt) => { setEditContent(evt.target.value) }}
                            ></textarea>
                        </div> :
                            <section className="Mywall_profile mywall-comm mb-3">
                                <div className=" d-flex justify-content-between profileUserList community-postsec">
                                    <div className="wallavatarimg">
                                        <ReactRoundedImage
                                            image={authBroker?.payload?.user?.profile_img !== undefined && authBroker?.payload?.user?.profile_img !== '' ? authBroker?.payload?.user?.profile_img : "/images/avatar.png"}
                                            roundedSize="0" imageWidth="55" imageHeight="55" />
                                    </div>
                                    <div className="input-text position-relative">
                                        <label htmlFor="password" className="input__label">
                                            Write a Post
                                        </label>
                                        <textarea
                                            type="text"
                                            id="password"
                                            name="password"
                                            className="input__Field02 "
                                            placeholder=" "
                                            value={editContent}
                                            onChange={(evt) => {
                                                setUploadContentErr('');
                                                setEditContent(evt.target.value)
                                            }}
                                        ></textarea>
                                        <div style={{ color: "red" }}>{uploadContentErr}</div>
                                    </div>

                                    <div className="d-flex justify-content-end iconLP02 acticons-post">
                                        <a >
                                            {/* <button className="button-reset" >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            id="Like"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="linear-gradient"
                                                    y1="0.5"
                                                    x2="1"
                                                    y2="0.5"
                                                    gradientUnits="objectBoundingBox"
                                                >
                                                    <stop offset="0" stop-color="#00b7f1" />
                                                    <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                            </defs>
                                            <circle
                                                id="Ellipse_57"
                                                data-name="Ellipse 57"
                                                cx="15"
                                                cy="15"
                                                r="15"
                                                fill="url(#linear-gradient)"
                                            />
                                            <circle
                                                id="Ellipse_179"
                                                data-name="Ellipse 179"
                                                cx="13"
                                                cy="13"
                                                r="13"
                                                transform="translate(2 2)"
                                                fill="#fff"
                                            />
                                           
                                             <path id="Path_491"
                                                data-name="Path 491" d="M 4 4 C 2.9069372 4 2 4.9069372 2 6 L 2 18 C 2 19.093063 2.9069372 20 4 20 L 20 20 C 21.093063 20 22 19.093063 22 18 L 22 6 C 22 4.9069372 21.093063 4 20 4 L 4 4 z M 4 6 L 20 6 L 20 18 L 4 18 L 4 6 z M 14.5 11 L 11 15 L 8.5 12.5 L 5.7773438 16 L 18.25 16 L 14.5 11 z" transform="translate(3 2)"
                                                fill="var(--color-element-1)"/>
                                        </svg>
                                    </button> */}
                                            <div className="upload-gallery-mywall">
                                                {/* <label><strong>Upload Images</strong></label> */}

                                                <div className="images-uploadbox">

                                                    <div className="upload-image-gallery cursor-pointer" title="Upload image">
                                                        <input type="file" name="file" accept="image/png,image/jpeg" className="form-control" id="exampleFile"
                                                            onChange={
                                                                (e) => {
                                                                    if (e.target.files[0] !== undefined && e.target.files[0] !== null) {

                                                                        let imgArr = _.cloneDeep(postImage)
                                                                        let imgCount = imgArr.length + preImages.length;
                                                                        if (imgCount < 5) {
                                                                            imgArr.push(e.target.files[0])
                                                                            setPostImage(imgArr);
                                                                            setUploadImgErr("");
                                                                        } else {
                                                                            setUploadImgErr("You can't upload more than five image")
                                                                        }
                                                                    }
                                                                }
                                                            } />
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                            <defs>
                                                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                                    <stop offset="0" stopColor="#00b7f1"></stop>
                                                                    <stop offset="1" stopColor="#ed156e"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                            <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                                            <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                                            <g id="surface1" className="imageuploadsv" transform="translate(5 4.586)">
                                                                <path fill="var(--color-element-1)" stroke="var(--color-element-1)" d="M 3.332031 3.332031 C 2.421875 3.332031 1.667969 4.089844 1.667969 5 L 1.667969 15 C 1.667969 15.910156 2.421875 16.667969 3.332031 16.667969 L 16.667969 16.667969 C 17.578125 16.667969 18.332031 15.910156 18.332031 15 L 18.332031 5 C 18.332031 4.089844 17.578125 3.332031 16.667969 3.332031 Z M 3.332031 5 L 16.667969 5 L 16.667969 15 L 3.332031 15 Z M 12.082031 9.167969 L 9.167969 12.5 L 7.082031 10.417969 L 4.816406 13.332031 L 15.207031 13.332031 Z M 12.082031 9.167969 " />
                                                            </g>
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                </div>

                               {isCmnt===0? <div className="img-uploaded-sec">
                                    {
                                        postImage.map((item, index) => {
                                            return <div className="img-box-wall">
                                                <img src={URL.createObjectURL(item)} width="120" height="120" className="img-wall-uploaded" />
                                                <button className="remove-img-wall" onClick={() => { removeSelectedImage(index) }} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30">

                                                        <defs>
                                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                                <stop offset="0" stopColor="#00b7f1"></stop>
                                                                <stop offset="1" stopColor="#ed156e"></stop>
                                                            </linearGradient>
                                                        </defs>
                                                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                                        <g id="Group_1344 prev" title="Move Back" data-name="Group 1344" transform="translate(15 9) rotate(90)">
                                                            <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                            <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                        </g>
                                                        <g id="Group_2376 next" title="Move Ahead" data-name="Group 2376" transform="translate(9 14.586)">
                                                            <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                            <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        })
                                    }
                                </div>:""}
                                <div style={{ color: "red" }}>{uploadImgErr}</div>
                            </section>}

                        {isCmnt===0&& preImages ? <div className="img-uploaded-sec">
                            {
                                preImages.map((item, index) => {
                                    return <div className="img-box-wall">
                                        <img src={item} width="300" height="500" className="img-wall-uploaded" />
                                        <button className="remove-img-wall" onClick={() => { removePreImage(index) }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30">

                                                <defs>
                                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#00b7f1"></stop>
                                                        <stop offset="1" stopColor="#ed156e"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                                <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                                <g id="Group_1344 prev" title="Move Back" data-name="Group 1344" transform="translate(15 9) rotate(90)">
                                                    <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                    <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                </g>
                                                <g id="Group_2376 next" title="Move Ahead" data-name="Group 2376" transform="translate(9 14.586)">
                                                    <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                    <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                })
                            }
                        </div> : ""}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-btn-footer">
                        <button className="confirm btn btn-secondary" onClick={() => { isCmnt === 0 ? onUpload() : editPost() }}>Save</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EditPostModal;