import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash";
import LearningPathSection from "../../components/LearningPaths/LearningPathSection";
import Page from "../../components/Page";
import {
    getLearningPath, getLearningPathClear, getLearningPathBroker,
    getLearningPathBrokerClear,
    getMyLearningPaths, getMyLearningPathsClear,
    getCheckListInsert,
    getCheckListInsertResponseClear,
    getLPUnAssigned,
    getLPUnAssignedClear
} from "../../redux/courses/actions";
import { convertTimeFormat, SessionKeys } from "../../utils/AppUtils";
import Loader from "../../components/Loader/Loader";

const LearningPathsPage = () => {

    const dispatch = useDispatch();

    const learningPath = useSelector(state => state.courses.learningPathBroker);
    const myLearningPathsData = useSelector(state => state.courses.myLearningPaths)
    const learningPathUnAssigned = useSelector(state => state.courses.learningPathUnAssigned)
    const insertCheckList = useSelector(state => state.courses.checkListInsert)
    const authBroker = useSelector(state => state.auth.authBroker);
    const [learningPathData, setLearningPathData] = useState([]);
    const [lpUnAssignedData, setLPUnAssignedData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUnAssigned, setIsLoadingUnAssigned] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!_.isEmpty(insertCheckList)) {
            dispatch(getCheckListInsertResponseClear())
        }
    }, [insertCheckList])

    useEffect(() => {
        // 
        if (!_.isEmpty(myLearningPathsData)) {
            console.log('LearningPath1234', myLearningPathsData);
            if (myLearningPathsData.status === 'success') {
                setIsLoading(false);
                if (!_.isEmpty(myLearningPathsData.payload.payload)) {
                  
                    let learningData = myLearningPathsData.payload.payload
                    console.log('learningPath1233', learningData)
                    for (let learning of learningData) {
                        let sendFlag = false;
                        // console.log('Learning', sendFlag, learning)
                        if (learning.coursesList) {
                            learning.is_manager_checklist = false;
                            for (let [index, course] of learning.coursesList.entries()) {
                                let currentCourse = course;
                                let nextCourse = learning.coursesList[index + 1];
                                if (learning.sequence === 1) {
                                    if (index === 0) {
                                        currentCourse.isLock = false;
                                    }
                                    currentCourse.isLock = currentCourse.isLock == false ? currentCourse.isLock : true;
                                    if (currentCourse.crs_progress > 0) {
                                        currentCourse.isLock = false;
                                        if (index != learning.coursesList.length - 1) {
                                            nextCourse.isLock = _.toNumber(currentCourse.crs_progress) >= 100 ? false : true;
                                        }
                                    }
                                } else {
                                    currentCourse.isLock = currentCourse.isLock ? currentCourse.isLock : false;
                                }
                                currentCourse.duration = convertTimeFormat(_.toNumber(currentCourse.duration));
                                currentCourse.durationUnit = ''
                                // console.log("detaiiii", course)
                                if (index < learning.coursesList.length - 1) {
                                    if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present) {
                                        nextCourse.isLock = nextCourse.crs_progress == 0 ? true : false
                                    } else if ((currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present)) {
                                        nextCourse.isLock = false
                                    }

                                    if (currentCourse.is_checklist_present) {
                                        learning.is_manager_checklist = true;
                                    }
                                }
                                if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present && !sendFlag) {
                                    if (currentCourse.crs_progress >= 100 && (index != learning.coursesList.length - 1)) {
                                        console.log(getCheckListInsert({
                                            module: 'checklist-insert',
                                            postData: {
                                                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                                                lp_id: learning?.id,
                                                entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                                                entity_type: !currentCourse?.is_level ? "course" : "level"
                                            }
                                        }));
                                        dispatch(
                                            getCheckListInsert({
                                            module: 'checklist-insert',
                                            postData: {
                                                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                                                lp_id: learning?.id,
                                                entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                                                entity_type: !currentCourse?.is_level ? "course" : "level"
                                            }
                                        }))
                                        sendFlag = true;
                                    }
                                }
                            }
                            for (let course of learning.coursesList) {
                                course.is_manager_checklist = learning.is_manager_checklist
                            }
                            // learning.courseList[0].isLock = false;
    
                        }
                    }
    
                    // console.log(learningData)
                    setLearningPathData(learningData)
                }
            }
            dispatch(getMyLearningPathsClear())
        }
    }, [myLearningPathsData])

    useEffect(() => {
        console.log('LearningPath UnAssigned', learningPathUnAssigned)
        if (!_.isEmpty(learningPathUnAssigned)) {
            if (learningPathUnAssigned.status === 'success') {
                setIsLoadingUnAssigned(false);
                if (!_.isEmpty(learningPathUnAssigned.payload.payload)) {
                    let learningData = _.cloneDeep(learningPathUnAssigned.payload.payload)
                    console.log('learningPath', learningData)
                    for (let learning of learningData) {
                        let sendFlag = false;
                        console.log('Learning', sendFlag, learning)
                        if (learning.coursesList) {
                            learning.is_manager_checklist = false;
                            for (let [index, course] of learning.coursesList.entries()) {
                                let currentCourse = course;
                                let nextCourse = learning.coursesList[index + 1];
                                if (learning.sequence === 1) {
                                    if (index === 0) {
                                        currentCourse.isLock = false;
                                    }
                                    currentCourse.isLock = currentCourse.isLock == false ? currentCourse.isLock : true;
                                    if (currentCourse.crs_progress > 0) {
                                        currentCourse.isLock = false;
                                        if (index != learning.coursesList.length - 1) {
                                            nextCourse.isLock = _.toNumber(currentCourse.crs_progress) >= 100 ? false : true;
                                        }
                                    }
                                } else {
                                    currentCourse.isLock = currentCourse.isLock ? currentCourse.isLock : false;
                                }
                                currentCourse.duration = convertTimeFormat(_.toNumber(currentCourse.duration));
                                currentCourse.durationUnit = ''
                                // console.log("detaiiii", course)
                                if (index < learning.coursesList.length - 1) {
                                    if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present) {
                                        nextCourse.isLock = nextCourse.crs_progress == 0 ? true : false
                                    } else if ((currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present)) {
                                        nextCourse.isLock = false
                                    }

                                    if (currentCourse.is_checklist_present) {
                                        learning.is_manager_checklist = true;
                                    }
                                }
                                // if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present && !sendFlag) {
                                //     if (currentCourse.crs_progress >= 100 && (index != learning.coursesList.length - 1)) {
                                //         console.log(getCheckListInsert({
                                //             module: 'checklist-insert',
                                //             postData: {
                                //                 token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                                //                 lp_id: learning?.id,
                                //                 entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                                //                 entity_type: !currentCourse?.is_level ? "course" : "level"
                                //             }
                                //         }));
                                //         dispatch(
                                //             getCheckListInsert({
                                //             module: 'checklist-insert',
                                //             postData: {
                                //                 token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                                //                 lp_id: learning?.id,
                                //                 entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                                //                 entity_type: !currentCourse?.is_level ? "course" : "level"
                                //             }
                                //         }))
                                //         sendFlag = true;
                                //     }
                                // }
                            }
                            for (let course of learning.coursesList) {
                                course.is_manager_checklist = learning.is_manager_checklist
                            }
                            // learning.courseList[0].isLock = false;
    
                        }
                    }
    
                    // console.log(learningData)
                    setLPUnAssignedData(learningData)
                }
            }
            dispatch(getLPUnAssignedClear())
        }
    }, [learningPathUnAssigned])


    return (
        <>
            {/* <Loader start={isLoading} /> */}
            <Page>
                <LearningPathSection
                    learningPathData={learningPathData}
                    lpUnAssignedData={lpUnAssignedData}
                    isLoading={isLoading}
                    isLoadingUnAssigned={isLoadingUnAssigned}
                />
            </Page>
        </>
    );
}

export default LearningPathsPage;