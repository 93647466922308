const types = {
    // Get all events
    GET_ALL_EVENTS: "GET_ALL_EVENTS",
    GET_ALL_EVENTS_RES: "GET_ALL_EVENTS_RES",
    GET_ALL_EVENTS_CLEAR: "GET_ALL_EVENTS_CLEAR",
    GET_ALL_EVENTS_NIIT: "GET_ALL_EVENTS_NIIT",
    GET_ALL_EVENTS_NIIT_RES: "GET_ALL_EVENTS_NIIT_RES",
    GET_ALL_EVENTS_NIIT_CLEAR: "GET_ALL_EVENTS_NIIT_CLEAR",

    GET_MY_EVENTS: "GET_MY_EVENTS",
    GET_MY_EVENTS_RES: "GET_MY_EVENTS_RES",
    GET_MY_EVENTS_CLEAR: "GET_MY_EVENTS_CLEAR",

    //Get event details
    GET_EVENTS_DETAILS_NIIT: "GET_EVENTS_DETAILS_NIIT",
    GET_EVENTS_DETAILS_NIIT_RES: "GET_EVENTS_DETAILS_NIIT_RES",
    GET_EVENTS_DETAILS_NIIT_CLEAR: "GET_EVENTS_DETAILS_NIIT_CLEAR",
    // Sessions info
    SESSION_LIST_NIIT: "SESSION_LIST_NIIT",
    SESSION_LIST_NIIT_RES: "SESSION_LIST_NIIT_RES",
    SESSION_LIST_NIIT_CLEAR: "SESSION_LIST_NIIT_CLEAR",

    // Get all events for broker
    GET_ALL_EVENTS_BROKER: "GET_ALL_EVENTS_BROKER",
    GET_ALL_EVENTS_BROKER_RES: "GET_ALL_EVENTS_BROKER_RES",
    GET_ALL_EVENTS_BROKER_CLEAR: "GET_ALL_EVENTS_BROKER_CLEAR",

    //Get event details for broker
    GET_EVENTS_DETAILS_BROKER: "GET_EVENTS_DETAILS_BROKER",
    GET_EVENTS_DETAILS_BROKER_RES: "GET_EVENTS_DETAILS_BROKER_RES",
    GET_EVENTS_DETAILS_BROKER_CLEAR: "GET_EVENTS_DETAILS_BROKER_CLEAR",
    // Sessions info for broker
    SESSION_LIST_BROKER: "SESSION_LIST_BROKER",
    SESSION_LIST_BROKER_RES: "SESSION_LIST_BROKER_RES",
    SESSION_LIST_BROKER_CLEAR: "SESSION_LIST_BROKER_CLEAR",
    // enroll session for broker
    ENROLL_BROKER_SESSION: "ENROLL_BROKER_SESSION",
    ENROLL_BROKER_SESSION_RES: "ENROLL_BROKER_SESSION_RES",
    ENROLL_BROKER_SESSION_CLEAR: "ENROLL_BROKER_SESSION_CLEAR",
    SET_ENROLL_BROKER_SESSION_RES: "SET_ENROLL_BROKER_SESSION_RES",
    SET_ENROLL_BROKER_SESSION_CLEAR: "SET_ENROLL_BROKER_SESSION_CLEAR",
    // Session info for Calandar
    CALENDAR_VIEW_SESSIONS: "CALENDAR_VIEW_SESSIONS",
    CALENDAR_VIEW_SESSIONS_RES: "CALENDAR_VIEW_SESSIONS_RES",
    CALENDAR_VIEW_SESSIONS_CLEAR: "CALENDAR_VIEW_SESSIONS_CLEAR",

    CALENDAR_SESSION_LIST: "CALENDAR_SESSION_LIST",
    CALENDAR_SESSION_LIST_RES: "CALENDAR_SESSION_LIST_RES",
    CALENDAR_SESSION_LIST_CLEAR: "CALENDAR_SESSION_LIST_CLEAR",

    LIVE_CALENDAR_SESSION_LIST: "LIVE_CALENDAR_SESSION_LIST",
    LIVE_CALENDAR_SESSION_LIST_RES: "LIVE_CALENDAR_SESSION_LIST_RES",
    LIVE_CALENDAR_SESSION_LIST_CLEAR: "LIVE_CALENDAR_SESSION_LIST_CLEAR",
    // Session info for Calandar of Host
    CALENDAR_HOST_SESSIONS: "CALENDAR_HOST_SESSIONS",
    CALENDAR_HOST_SESSIONS_RES: "CALENDAR_HOST_SESSIONS_RES",
    CALENDAR_HOST_SESSIONS_CLEAR: "CALENDAR_HOST_SESSIONS_CLEAR",
    // Session info for Calandar of Instructor
    CALENDAR_INSTRUCTOR_SESSIONS: "CALENDAR_INSTRUCTOR_SESSIONS",
    CALENDAR_INSTRUCTOR_SESSIONS_RES: "CALENDAR_INSTRUCTOR_SESSIONS_RES",
    CALENDAR_INSTRUCTOR_SESSIONS_CLEAR: "CALENDAR_INSTRUCTOR_SESSIONS_CLEAR",
    // Host Zoom Session
    HOST_SESSIONS: "HOST_SESSIONS",
    HOST_SESSIONS_RES: "HOST_SESSIONS_RES",
    HOST_SESSIONS_CLEAR: "HOST_SESSIONS_CLEAR",
    // Join Zoom Session
    JOIN_SESSIONS: "JOIN_SESSIONS",
    JOIN_SESSIONS_RES: "JOIN_SESSIONS_RES",
    JOIN_SESSIONS_CLEAR: "JOIN_SESSIONS_CLEAR",
    // Event Notification
    EVENT_NOTIFICATION: "EVENT_NOTIFICATION",
    EVENT_NOTIFICATION_RES: "EVENT_NOTIFICATION_RES",
    EVENT_NOTIFICATION_CLEAR: "EVENT_NOTIFICATION_CLEAR",
    // Session Attedance
    SESSION_ATTENDANCE: "SESSION_ATTENDANCE",
    SESSION_ATTENDANCE_RES: "SESSION_ATTENDANCE_RES",
    SESSION_ATTENDANCE_CLEAR: "SESSION_ATTENDANCE_CLEAR",
    // Check Notification
    CHECK_NOTIFICATION: "CHECK_NOTIFICATION",
    CHECK_NOTIFICATION_RES: "CHECK_NOTIFICATION_RES",
    CHECK_NOTIFICATION_CLEAR: "CHECK_NOTIFICATION_CLEAR",
    // Cancel Session
    CANCEL_SESSION: "CANCEL_SESSION",
    CANCEL_SESSION_RES: "CANCEL_SESSION_RES",
    CANCEL_SESSION_CLEAR: "CANCEL_SESSION_CLEAR",
    // Video Watch URL Session
    SESSION_WATCH_URL: "SESSION_WATCH_URL",
    SESSION_WATCH_URL_RES: "SESSION_WATCH_URL_RES",
    SESSION_WATCH_URL_CLEAR: "SESSION_WATCH_URL_CLEAR",

    FILTER_BY: "FILTER_BY",
    FILTER_CLEAR: "FILTER_CLEAR",

    BOARDCAST_RESPONSE: "BOARDCAST_RESPONSE",
    BOARDCAST_CLEAR: "BOARDCAST_CLEAR",

    FETCH_BOARDCAST: "FETCH_BOARDCAST",
    FETCH_BOARDCAST_RESPONSE: "FETCH_BOARDCAST_RESPONSE",
    FETCH_BOARDCAST_CLEAR: "FETCH_BOARDCAST_CLEAR",

    CALENDAR_LIVE_EVENTS: "CALENDAR_LIVE_EVENTS",
    CALENDAR_LIVE_EVENTS_CLEAR: "CALENDAR_LIVE_EVENTS_CLEAR"
}
export default types;