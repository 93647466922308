import types from "./types";

// Certificate detail post
export const certificateData = (payload) => ({
  type: types.CERTIFICATE_DATA,
  payload,
});

export const certificateDataResponse = (payload) => ({
  type: types.CERTIFICATE_DATA_RESPONSE,
  payload,
});
export const certificateDataClear = () => ({
  type: types.CERTIFICATE_DATA_CLEAR,
});
