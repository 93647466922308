import types from "./types";

const initialState = {
  postListRes: {},
  addCommentsRes: {},
  editCommentsRes: {},
  likeCommentRes: {},
  activityLikeRes: {},
  deleteCommentRes: {},
  deletePostRes:{},
  editPostRes:{},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.POST_LIST_RESPONSE:
      return {
        ...state,
        postListRes: action.payload,
      };
    case types.POST_LIST_RESPONSE_CLEAR:
      return {
        ...state,
        postListResClear: {},
      };
    case types.ADD_COMMENTS_RESPONSE:
      return {
        ...state,
        addCommentsRes: action.payload,
      };
    case types.ADD_COMMENTS_RESPONSE_CLEAR:
      return {
        ...state,
        addCommentsRes: {},
      };
    case types.EDIT_COMMENTS_RESPONSE:
      return {
        ...state,
        editCommentsRes: action.payload,
      };
    case types.EDIT_COMMENTS_RESPONSE_CLEAR:
      return {
        ...state,
        editCommentsRes: {},
      };
    case types.GET_LIKE_RESPONSE:
      return {
        ...state,
        likeCommentRes: action.payload,
      };
    case types.GET_LIKE_RESPONSE_CLEAR:
      return {
        ...state,
        likeCommentRes: {},
      };
    case types.GET_ACTIVITY_LIKE_RESPONSE:
      return {
        ...state,
        activityLikeRes: action.payload,
      };
    case types.GET_ACTIVITY_LIKE_RESPONSE_CLEAR:
      return {
        ...state,
        activityLikeRes: {},
      };
    case types.DELETE_COMMENT_RESPONSE:
      return {
        ...state,
        deleteCommentRes: action.payload,
      };
    case types.DELETE_COMMENT_RESPONSE_CLEAR:
      return {
        ...state,
        deleteCommentRes: {},
      };
    case types.DELETE_POST_RESPONSE:
      return {
        ...state,
        deletePostRes: action.payload,
      };
    case types.DELETE_POST_RESPONSE_CLEAR:
      return {
        ...state,
        deletePostRes: {},
      };
      case types.EDIT_POST_RESPONSE:
      return {
        ...state,
        editPostRes: action.payload,
      };
    case types.EDIT_POST_RESPONSE_CLEAR:
      return {
        ...state,
        editPostRes: {},
      };


    default:
      return state;
  }
};
