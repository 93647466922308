import { useHistory } from 'react-router-dom'

const HeaderLogoSection = () => {
    const history = useHistory();
    
    return (
        <nav className="navbar navbar-expand-sm sticky-top bg-menu-dark justify-content-between reset">
            <div className="container content-logo">
                <img className="img-fluid" src="images/logo/Untitled-6.png" alt="" onClick={()=>history.push('/')}/>
            </div>
        </nav>
    );
}

export default HeaderLogoSection;