import data from '../../data/product'
import types from './types'

const initialState = {
  searchData: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        searchData: action.payload
      }
    case types.SEARCH_CLEAR:
      return {
        ...state,
        searchData: {}
      }
    default:
      return state
  }
}



