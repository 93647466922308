import { useEffect } from "react";
import DatePicker from 'react-datepicker'
const SelectpeopleOptions = (props) => {

    const {
        startDate,setStartDate,endDate,setEndDate,
        clickCompleted,setClickCompleted,clickNotCompleted,setClickNotCompleted,
        getFilteredData,clickInProgress,setClickInProgress,
        setOfficeIds,parentName
      } = props
    return (
        <>
							<div className="createteamsOptions">
								<div className="chooseteamate">
                                <div className="optionsd">
                                    Select one or more options below
                                </div>
                                <p>Choose people from <strong><span className="selectedteam">Office </span></strong></p>
                                <div className="crateteamform">
                                 <div className="coose_form">Choose people who joined</div>
                                    <div className="select-report-date">
                                        <div className="input-lear position-relative d-flex">
                                            <label for="password" className="input__label_learning">Select</label>
                                            <select className="form-select input_searchfield report-selct" aria-label="Default select example">
                                                <option value="Created by me">Before</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-text position-relative d-flex">
                                    <label for="date_join" className="input__label" >Start Date</label>
                                    <DatePicker selected={startDate} placeholderText="MM/DD/YYYY" className="input__field02" maxDate={new Date()} onChange={(date) => setStartDate(date)} />
                                    <img src="/images/Cal.svg" width="28" height="28" alt="calendar" />
                                    </div>
                                </div>
                                <div className="crateteamform">
                                 <div className="coose_form">Choose people with</div>
                                    <div className="select-report-date">
                                        <div className="input-lear position-relative d-flex">
                                            <label for="password" className="input__label_learning">Select</label>
                                            <select className="form-select input_searchfield report-selct" aria-label="Default select example">
                                                <option value="Created by me">Before</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="coose_form">of experience</div>
                                </div>
                                <p>Choose people who have completed <strong className="choseselect">{parentName}</strong></p>
								
								</div>
							</div>
                            <hr />
                            <div className="chooseteams chooseppl">
								<div className="chooseteamate">
									<div className="headong d-flex justify-content-between"><h4>Choose People from Office</h4> </div>
									<div className="input-lear position-relative d-flex">
										<label for="peoplesearch" className="input__label_learning">Search</label>
										<input type="text" id="peoplesearch" name="peoplesearch" className="input_searchfield_search-cart " placeholder="Start searching"/>
									</div>
									<div className="search-team-result">
										<div className="team-result-box">
											<div className="userteamname">Tottenham <span className="peoplecount">32 people</span></div>
											<div className="addteammate">+</div>
										</div>
										<div className="team-result-box">
											<div className="userteamname">Tottenham <span className="peoplecount">32 people</span></div>
											<div className="addteammate">+</div>
										</div>
									</div>
									<div className="teamselected">
										<div className="selectresilt">Selected Offices<strong>(1)</strong></div>
										<div className="search-team-result slectedteammates">
											<div className="team-result-box">
                                                <div className="userteamname">Tottenham <span className="peoplecount">32 people</span></div>
                                                <div className="addteammate">+</div>
											</div>
										</div>
									</div>
									<div className="buttonsteammate">
										<button type="button" className="btn btn-primary btn-block back-button">Back</button>
										<button type="button" className="btn btn-primary btn-block setup-button">Proceed</button>
									</div>
								</div>
							</div>
        </>

    )
}

export default SelectpeopleOptions;