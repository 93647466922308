const types = {
  GET_TEAMMATES_REQ: "GET_TEAMMATES_REQ",
  GET_TEAMMATES_RESPONSE: "GET_TEAMMATES_RESPONSE",
  GET_TEAMMATES_RESPONSE_CLEAR: "GET_TEAMMATES_RESPONSE_CLEAR",

  CURRENT_VIEW: "CURRENT_VIEW",

  ASSIGN_TEAMMATES_REQ: "ASSIGN_TEAMMATES_REQ",
  ASSIGN_TEAMMATES_RESPONSE: "ASSIGN_TEAMMATES_RESPONSE",
  ASSIGN_TEAMMATES_RESPONSE_CLEAR: "ASSIGN_TEAMMATES_RESPONSE_CLEAR",

  SEARCH_OFFICE_LIST_REQ: "SEARCH_OFFICE_LIST_REQ",
  SEARCH_OFFICE_LIST_RESPONSE: "SEARCH_OFFICE_LIST_RESPONSE",
  SEARCH_OFFICE_LIST_RESPONSE_CLEAR: "SEARCH_OFFICE_LIST_RESPONSE_CLEAR",

  COMPLETED_COURSE_LIST_REQ: "COMPLETED_COURSE_LIST_REQ",
  COMPLETED_COURSE_LIST_RESPONSE: "COMPLETED_COURSE_LIST_RESPONSE",
  COMPLETED_COURSE_LIST_RESPONSE_CLEAR: "COMPLETED_COURSE_LIST_RESPONSE_CLEAR",

  GET_USERS_BY_OFFICE_LIST: "GET_USERS_BY_OFFICE_LIST",
  GET_USERS_BY_OFFICE_LIST_RESPONSE: "GET_USERS_BY_OFFICE_LIST_RESPONSE",
  GET_USERS_BY_OFFICE_LIST_CLEAR: "GET_USERS_BY_OFFICE_LIST_CLEAR",


  GET_TEAMS_LIST_REQ: "GET_TEAMS_LIST_REQ",
  GET_TEAMS_LIST_RES: "GET_TEAMS_LIST_RES",
  GET_TEAMS_LIST_RES_CLEAR: "GET_TEAMS_LIST_RES_CLEAR",

  ASSIGN_TO_TEAMS_REQ: "ASSIGN_TO_TEAMS_REQ",
  ASSIGN_TO_TEAMS_RES: "ASSIGN_TO_TEAMS_RES",
  ASSIGN_TO_TEAMS_RES_CLEAR: "ASSIGN_TO_TEAMS_RES_CLEAR",

  GET_ASSIGN_USERS: "GET_ASSIGN_USERS",
  GET_ASSIGN_USERS_RES: "GET_ASSIGN_USERS_RES",
  GET_ASSIGN_USERS_RES_CLEAR: "GET_ASSIGN_USERS_RES_CLEAR",

  //LP Filter
  CHOOSE_LP_REPORT_FILTER: "CHOOSE_LP_REPORT_FILTER",
  CHOOSE_LP_REPORT_FILTER_RES: "CHOOSE_LP_REPORT_FILTER_RES",
  CHOOSE_LP_REPORT_FILTER_CLEAR: "CHOOSE_LP_REPORT_FILTER_CLEAR",

  GET_CLASS: "GET_CLASS"


};

export default types;
