import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getCoeManagers,
  getCoeManagersResClear,
} from "../../../redux/reports/actions";
import { SessionKeys } from "../../../utils/AppUtils";
import _ from "lodash";
import CONFIG from "../../../utils/config";
import { CircularProgressbar } from "react-circular-progressbar";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const ManagerReportSecCoe = ({ isMobile }) => {
  // const { overAllManagersData } = props;
  const coeManagerSectRes = useSelector(
    (state) => state.report?.coeReportManager
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [overCrsGraphData, setOverCrsGraphData] = useState([]);
  const [crsCompltTrendValues, setCrsCompltTrendValues] = useState([]);
  const [crsCompleTrendKeys, setCrsCompleTrendKeys] = useState([])
  const [overAllData, setOverAllData] = useState([]);
  const [realEstateAgent, setRealEstateAgent] = useState([]);
  const [overAllOfficesData, setOverAllOfficesData] = useState([]);
  const [overAllManagersData, setOverAllManagersData] = useState([]);
  const [overAllLearningPoints, setOverAllLearningPoints] = useState([]);
  const [overAllLearningHours, setOverAllLearningHours] = useState([]);
  const [OverAllStatsData, setOverAllStatsData] = useState([]);
  const [isView, setIsView] = useState("table");
  const [type, setType] = useState("pie");
  const [canShow, setCanShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [limitData, setLimitData] = useState();
  const [imgLoader, setImgLoader] = useState(true);
  const [reaList, setReaList] = useState([])
  const [limitStatus, setLimitStatus] = useState("")
  const [loadSpin, setLoadSpin] = useState(true)
  const [loadSpining, setLoadSpining] = useState(true)
  const [profileReports, setProfileReports] = useState([]);
  const [totalRea, setTotalRea] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);
  const [counter, setCounter] = useState(1);
  const [actualCoursesList, setActualCoursesList] = useState([]);
  const [totalCountRea, setTotalCountRea] = useState("")
  const [totalManagers, setTotalManagers] = useState([])


  // console.log("Selected REA", selectedRea);
  const rea_ids = []
  const office_ids = []
  const [selectedOfficeList] = useState(
    history?.location?.state?.selectedOfficeList
  );
  const [selectedRea] = useState(
    history?.location?.state?.selectedTeamMatesList
  );
  // console.log("Selected officelist", selectedOfficeList);

  // const office_ids = selectedOfficeList.selectedOfficeList[0].office_id;
  //const rea_ids = selectedRea.selectedTeamMatesList[0].user_id;

  //console.log("Office id, REA ID", office_ids, rea_ids);
  //const overAllDataRes = useSelector((state) => state.report.overallData);

  let PageSize = 20;
  useEffect(() => {
    //   array.forEach(function(obj){
    //     a.push(obj.user_id);
    // })
    selectedOfficeList.selectedOfficeList.forEach(function (obj) {
      office_ids.push(obj.office_id);
    })
    selectedRea.selectedTeamMatesList.forEach(function (obj) {
      rea_ids.push(obj.user_id);
    })

  })
  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }

  // COE MANAGERS SECTION API DISPACTCH
  useEffect(() => {
    dispatch(
      getCoeManagers({
        module: "getCoeReportManagers",
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          limit: counter,
          office_ids: office_ids,
          rea_ids: rea_ids,
        },
      })
    );
  }, [dispatch, counter]);
  useEffect(() => {
    if (!_.isEmpty(coeManagerSectRes)) {
      setLoadSpining(false)
      if (coeManagerSectRes?.status === "success") {
        let allData = coeManagerSectRes?.payload?.payload?.managers;
        setTotalManagers(coeManagerSectRes?.payload?.payload)
        let temparr = _.cloneDeep(overAllManagersData)
        if (counter === 1) {
          temparr = []
        }
        setOverAllManagersData([...temparr, ...allData]);
        dispatch(getCoeManagersResClear())
      }
    }
  }, [coeManagerSectRes])
  useEffect(() => {
    setCounter(1)
    setOverAllManagersData([])
    return (() => {
      dispatch(getCoeManagersResClear())
    })
  }, [])


  return (
    <section className="manager-section-report progress-box-report">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="progess-count-tiel">
              Managers &nbsp;<strong>({totalManagers?.total_managers})</strong>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {(!_.isEmpty(overAllManagersData)) ? overAllManagersData?.map((m, index) => {
            return (
              <div className="col-md-6 col-lg-4 col-xl-3 col-12 mb-report" key={index}>
                <div className="reopor-box-progess">
                  <div className="progess-profile d-flex">
                    <div className="user-progess-img">
                      <img src={m?.profile_pic} width="48" height="48" />
                    </div>
                    <div className="userprogress-details">
                      <h5 className="user-tiele">{m?.user_name}</h5>
                      <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <div
                                            style={{
                                                color: "white",
                                                backgroundColor: "black",
                                            }}
                                            
                                        >{m?.office_name}</div>
                                    </Tooltip>
                                }
                            >
                        <p >{m?.office_name}</p>
                        </OverlayTrigger>
                      {/* <p>{m?.office_name}</p> */}
                    </div>
                  </div>
                  <hr />
                  <div className="progress-details">
                    <div className="progess-report">
                      <div className="circlebox">
                        <CircularProgressbar value={m?.learning_percentage} />
                      </div>
                      <div className="progess-text-repfot">
                        {`${m?.learning_percentage}%`}
                        <div className="progespte">Progress</div>
                      </div>
                    </div>
                    {/* <div className="progess-count-show">
                      <div className="learning-points">
                        <h5>Learning Points</h5>
                        <div className="learningc-countshow">
                          {m?.learning_points}
                        </div>
                      </div>
                      <div className="learning-points ">
                        <h5>Learning Hours</h5>
                        <div className="learningc-countshow">
                          {(Math.floor(m?.learning_hrs)) > 0 ? `${Math.floor(m?.learning_hrs)}` : ""}&nbsp;
                          {Math.floor(m?.learning_hrs) > 1 ? "Hrs" : Math.floor(m?.learning_hrs) > 0 ? "Hr" : ""}{" "}
                          &nbsp;
                          {(Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60)) > 0 ?
                            Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60) : ""}&nbsp;
                          {(Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60)) > 1 ? "Mins" : (Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60)) > 0 ? "Min" : ""}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            );
          }) : loadSpining && counter === 1 ?
            <div className="not-found mt-3">
              <div className="interboxes">
                <Spinner animation="border" />
              </div>
            </div>
            :
            <div className="col-md-12">
              <div className="not-found mt-3">
                <div className="interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p>This section will display the Managers that have to complete the Code of Ethics.</p>
                </div>
              </div>
            </div>
          }
          <div className="col-md-12 mt-3">
            <p className="text-center">
              {overAllManagersData?.length === 0 || (totalManagers?.total_managers === overAllManagersData?.length) ? "" : (totalManagers?.total_managers < 4) ? "" :
                <button
                  type="button"
                  id="number"
                  className="btn btn-primary-complete"
                  onClick={() => {
                    setCount(counter, setCounter);
                    setLoadSpining(true);
                  }
                  }
                >
                  {(loadSpining) ?
                    <div className="d-flex justify-content-center w-100">
                      <Spinner animation="border" />
                    </div>
                    : "Load More"}
                </button>
              }
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ManagerReportSecCoe;