import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion 
} from "reactstrap";
import CourseCard from "../../components/Course/CourseCard";
import SideMenuComponent from "../../components/menu_detail/SideMenuComponent";
import ConfirmModal from "../../components/modal/ConfirmModal";
import Page from "../../components/Page";
import { getFavoriteCourses, setFavoriteCourses, setFavoriteCoursesClear } from "../../redux/courses/actions";
import { SessionKeys } from "../../utils/AppUtils";

const Favorites = () => {

  const dispatch = useDispatch();

  const favoriteCourses = useSelector(state => state.courses.favoriteCourses);
  const setFavoriteCourse = useSelector(state => state.courses.setFavoriteCourses);

  const [favoriteCoursesData, setFavoriteCoursesData] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [closeChildModal, setCloseChildModal] = useState(false);
  

  useEffect(() => {
    // dispatch(getFavoriteCourses({
    //   module: 'getFavoriteCourses',
    //   token: localStorage.getItem(SessionKeys.API_TOKEN)
    // }))
  }, [dispatch])

  useEffect(() => {
    // console.log(setFavoriteCourse)
    if (setFavoriteCourse?.status === 'success') {
        // show Message
        // dispatch(getFavoriteCourses({
        //   module: 'getFavoriteCourses',
        //   token: localStorage.getItem(SessionKeys.API_TOKEN)
        // }))
        setConfirmModal(true)
        setCloseChildModal(true)
        setShowModalMsg("Course removed from favorites successfully")
        dispatch(setFavoriteCoursesClear())
    }
    // return () => {
    //     dispatch(setFavoriteCoursesClear())
    // }
}, [setFavoriteCourse])

  useEffect(() => {
    // console.log('Favorite Courses', favoriteCourses)
    if (favoriteCourses?.status === 'success') {
      setFavoriteCoursesData(favoriteCourses.payload)
    }
  }, [favoriteCourses])

  useEffect(() => {
    // console.log(favoriteCoursesData)
  }, [favoriteCoursesData])

  const removeFavorite = (courseId) => {
    // console.log(courseId);
    const postData = {
        course_id : {
            _id: {
                $oid: courseId
            },
            bkmrk: false,
            type: 'course'
        }
    }
    // dispatch(setFavoriteCourses({
    //     module: 'courseFavorite',
    //     token: localStorage.getItem(SessionKeys.API_TOKEN),
    //     postData
    // }));
  }

  const searchBar = () => {
    return (
      <div className="d-flex justify-content-between filtersection mb-3">
        <div className="">
            <form className="d-flex containsearchbox">
                <input className="form-control inputsearch " type="text" placeholder="Search" />
                <button className="btn searchbtn-body" type="button"><i className="fa fa-search"
                  aria-hidden="true"></i></button>
            </form>
        </div>
        <div className="refinefilter">
            <div className="row">
                <div className="col-auto droplabel">
                    <label htmlFor="" className="col-form-label">Sort by:</label>
                </div>
                <div className="col-auto dropselectinp">
                    <select className="form-select" aria-label="Short By">
                        <option value="1">Date</option>
                        <option value="2">Date New to Old</option>
                        <option value="3">Date New to Old</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    );
  }

  return (
    <Page>
      <section className="container-fluid">
        <div className="row">
          <SideMenuComponent />
          <div className="main-content">
            <UncontrolledAccordion
              defaultOpen={'favorites-tab'}
            >
              <AccordionItem>
                <AccordionHeader targetId="favorites-tab">
                  <img src="images/favorites-white.png" /> <span className="acc-btn-cont">favorites</span>  <img src="images/info-icon.png" />
                </AccordionHeader>
                <AccordionBody accordionId="favorites-tab">
                  {searchBar()}
                  <div className="section-sep-border-non">
                    <section className="allactiveslid slider mb-4 d-flex">
                      {favoriteCoursesData.map((course, index) => {
                        return (
                          <CourseCard 
                            key={index} 
                            course={course} 
                            remove={removeFavorite}
                            closeModal={closeChildModal}
                            setCloseModal={setCloseChildModal}
                          />
                        )
                      })}
                      <ConfirmModal 
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        showModalMsg={showModalMsg}
                      />
                    </section>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default Favorites;