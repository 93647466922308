import axios from 'axios'
import data from '../data/courseList'
import CONFIG from '../utils/config'
import { objectToFormData } from './data'
import { useHistory as history } from "react-router-dom";

export const getUserCourses = async (payload) => {
  const url = CONFIG.API_URL + CONFIG.API_ROUTES.BASE_PATH + "?module=" + payload.module + "&token=" + payload.token
  return axios({
    method: "GET",
    url,
  }).then(response => {
    // console.log(response.data)
    if (response.data.status) {
      return {
        status: 'success',
        payload: response.data.data
      }
    } else {
      if (response.data.message === 'Token is not valid') {
        alert("Your Session is Logged Out. Please Login.")
        window.alert = () => false
        localStorage.clear()
        window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
      }
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    console.log("errorData", error.respoinse);
    if (error.response != undefined && error.response?.status === 401) {
      alert("Your Session is Logged Out. Please Login.")
      window.alert = () => false
      localStorage.clear();
      window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
      history.push("/", { sessionExpired: true });
    }
    return {
      status: 'error',
      error
    }
  })
}