import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../components/Page";
import { VideoCoursesDataSetupSection } from "../../components/Video/VideoCoursesDataSetupSection";
import { getMyVideos, getMyVideosClear } from "../../redux/video/actions";
import { SessionKeys } from "../../utils/AppUtils";
import VideoCoursesListingCard from "../videoCourses/VideoCoursListingCard";

const MyVideosPage = () => {

    const dispatch = useDispatch();

    const authBroker = useSelector((state) => state.auth.authBroker);
    const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
    const myVideoList = useSelector((state) => state.video.myVideos)

    const [isLoading, setIsLoading] = useState([])
    const [videoList, setVideoList] = useState([])

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            // console.log('authbroker')
            dispatch(
                getMyVideos({
                    broker: {
                        module: "myVideoBroker",
                        postData: {
                            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
                        }
                    }
                })
            )
        }
    }, [dispatch, authBroker])

    useEffect(() => {
        // console.log('my video list', myVideoList)
        if (!_.isEmpty(myVideoList)) {
            let sortedData = [];
            for (let video of myVideoList) {
                if (video.status === 'success') {
                    let videoData = _.isArray(video.payload.payload) ? video.payload.payload : [];
                    sortedData = sortByAlphabetical([...videoData, ...sortedData]);
                    if (!_.isEmpty(sortedData)) {
                        sortedData = VideoCoursesDataSetupSection(sortedData, isPremiumUser)
                        for (let data of sortedData) {
                            data.is_paid = true;
                        }
                        // console.log(sortedData)
                    }
                }
            }
            setVideoList(sortedData);
            dispatch(getMyVideosClear())
            setIsLoading(false)
        }
    }, [myVideoList])

    const sortByAlphabetical = (list) => {
        return list.sort((a, b) =>
          b.published_date.localeCompare(a.published_date)
        );
      };

    return (
        <>
        <Page>
            <div className="my-credit-points">
                <section className="container">
                    <div className="row">
                        <div className="col-md-12 profile-left-sec">
                            <div className="search_heading">
                            {/* <img src="/images/learning_img.png" width="109" height="106" alt="" /> */}
                            <p className="lable-heading_search">Videos</p>
                            </div>
                            <div className="rows my_courses mt-3">
                                <VideoCoursesListingCard
                                    coursesList={videoList}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Page>
        </>
    )
}

export default MyVideosPage;