import _ from "lodash";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import NextArrow from "../../LearningPaths/NextArrow";
import PrevArrow from "../../LearningPaths/PrevArrow";
import Pagination from "../../../pages/Orders/Pagination";
import exportFromJSON from "export-from-json";
import StaticUtils from "../../../utils/StaticUtils";
import * as XLSX from "xlsx";
import PieChart from "../../Layouts/Graphs/PieChart";
import ReportGraphSection from "../ReportGraphSection";
import { SessionKeys } from "../../../utils/AppUtils";

import moment from "moment";
import PaginationProgress from "../progressReport/PaginationProgress";

const fileName = "CodeOfEthicsReport";
const exportType = "xls";
let PageSize = 20;
const sliderRef = createRef();
const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  dots: true,
  arrows: true,
  autoplaySpeed: 2000,
  infinite: false,
  centerMode: false,
  swipe: false,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const ReportTableCoe = (props) => {
  const {
    setSearchInput,
    getFilteredData,
    setIsLoading,
    isLoading,
    isView,
    statusFilterData,
    setCanShow,
    type,
    profileReports,
    // currentTableData,
    lpName,
    message,
  } = props;

  const dispatch = useDispatch();
  const profileReportRes = useSelector((state) => state.report.profileReport);
  const anyTotalReportRes = useSelector((state) => state.report.anyTotalReport);
  // const [profileReports, setProfileReports] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);
  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true);
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, profileReports]);

  const ExportToExcel = () => {
    const data = _.cloneDeep(profileReports);
    for (let item of data) {
      item.status =
        item?.status === "In Progress"
          ? "In Progress"
          : item?.status === "NotStarted"
            ? "Yet to start"
            : "Completed";
      item.progress =
        item?.progress !== undefined && item?.progress !== ""
          ? item?.progress + "%"
          : "-";
      item.completion_date =
        item?.completion_date !== undefined && item?.completion_date !== ""
          ? moment(
            StaticUtils.getDateFromTimeStamp(item?.completion_date),
            "MM/DD/YYYY"
          ).format("DD MMM, YYYY")
          : "-";
    }

    let tempArr = [];
    for (const item of data) {
      let obj = {
        "username":item.username,
         "email":item.email,
         "office_name":item.office_name,
         "status":item.status,
         "progress":item.progress,
         "completion_date":item.completion_date,
      }
      tempArr.push(obj)
    }

    const newThing = [];
    tempArr.map((item) => {
      newThing.push(
        _.mapKeys(item, (value, key) => {
          switch (key) {
            case "username":
              return "Name";
            case "email":
              return "Email";
            case "office_name":
              return "Office Name";
            case "status":
              return "Status";
            case "progress":
              return "% Completed";
            case "completion_date":
              return "Date of Completion";
            default:
              return;
          }
        })
      );
    });

    for (let i = 0; i < newThing.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete newThing[i].undefined;
    }

    for (let i = 0; i < data.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete data[i]._id;
    }
    // console.log('data:', data)
    // exportFromJSON({ data, fileName, exportType })

    //For mobile
    let tempObj = {
      method:"CodeOfEthicsReport",
      report:newThing
    }
    if (window.callbackHandler != undefined) {
      window.callbackHandler.postMessage(JSON.stringify(tempObj));
    } else if (window.webkit != undefined) {
      if (window.webkit.messageHandlers.callbackHandler != undefined) {
        window.webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify(tempObj));
      }
    }

    const worksheet = XLSX.utils.json_to_sheet(newThing);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName + ".xlsx");
  };

  const renderTableReport = () => {
    return (
      <div className="row list-coach report-table-rsp">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive1">
            <div className="tablebox">
              <table className="table mb-0 office_list table-striped">
                <thead>
                  <tr>
                    <th className="office-province">Name</th>
                    <th className="office-province">Email</th>
                    <th className="office-province">Office Name</th>
                    <th className="office-province text-center">Status</th>
                    <th className="office-province text-center">% Completed</th>
                    <th className="office-province text-center dateboxhead">
                      Date of Completion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData?.map((item, index) => {
                    return (
                      <tr className="addBGcustomw">
                        <td>{item?.username}</td>
                        <td>{item?.email}</td>
                        <td>{item?.office_name}</td>
                        <td className="text-center">
                          <span className="complte">
                            <img
                              src={
                                item?.status === "In Progress"
                                  ? "/images/inprogress.svg"
                                  : item?.status == "NotStarted"
                                    ? "/images/yet-to-start-svg.svg"
                                    : "/images/check-report.svg"
                              }
                              width="18"
                              height="18"
                            />
                          </span>
                          <strong>
                            {item?.status === "In Progress"
                              ? "In Progress"
                              : item?.status === "NotStarted"
                                ? "Yet to start"
                                : "Completed"}
                          </strong>
                        </td>

                        <td className="text-center percoe">
                          <strong>
                            {item?.progress !== undefined &&
                              item?.progress !== "" &&
                              item?.progress !== null
                              ? item?.progress + "%"
                              : "-"}
                          </strong>
                        </td>
                        <td className="text-center dateoe">
                          <strong>
                            {item?.completion_date !== undefined &&
                              item?.completion_date !== ""
                              ? moment(
                                StaticUtils.getDateFromTimeStamp(
                                  item?.completion_date
                                ),
                                "MM/DD/YYYY"
                              ).format("DD MMM, YYYY")
                              : "-"}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* <PaginationProgress
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={profileReports?.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                window.scroll({
                  top: 100,
                  left: 0,
                  behavior: "smooth",
                });
                setCurrentPage(page);
              }}
            /> */}
            <PaginationProgress
              className="pagination-bar "
              currentPage={currentPage}
              totalCount={profileReports?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderGraphReport = () => {
    return (
      <ReportGraphSection
        series={series}
        legends={legends}
        isLoading={isLoading}
        type={type}
        isAmount={false}
      />
    );
  };

  return (
    <>
      <section className="table-report-layout mt-3">
        {true ? (
          <div className="container">
            <div className="row">
              <div className="col-md-5 search__input"></div>
              <div className="col-7">
                {currentTableData &&
                  currentTableData?.length > 0 &&
                  isView == "table" ? (
                  <button
                    className="button-reset downbtnsreport"
                    onClick={() => {
                      if (window.callbackHandler != undefined) {
                        window.callbackHandler.postMessage("downbtnsreport");
                      } else if (window.webkit != undefined) {
                        if (window.webkit.messageHandlers.callbackHandler != undefined) {
                          window.webkit.messageHandlers.callbackHandler.postMessage("downbtnsreport");
                        }
                      }

                      ExportToExcel();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#00b7f1" />
                          <stop offset="1" stop-color="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_57"
                        data-name="Ellipse 57"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_179"
                        data-name="Ellipse 179"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="file-chart"
                        d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z"
                        transform="translate(5.342 5.927)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {currentTableData && currentTableData?.length > 0 ? (
              renderTableReport()
            ) : profileReports !== null ? (
              <div className="not-found mt-3">
                <div className=" interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p>{message}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};
export default ReportTableCoe;
