const CertifySection = () => {
  return (
    <section className="container-fluid ">
      <div className="row">
        <div className="col-md-10 offset-1 search_sec">
          <div className="certify_heading mt-5">
            {/* <img src="images/certify_pg_img.png" width="109" height="106" alt="" /> */}
            <p className="lable-heading_search">Certify</p>
          </div>
          <div className="input-textt certify-input position-relative d-flex">
            <label for="password" className="input__label-certify">Certifications</label>
            <select type="text" id="password" name="password" className="input_field-certify " placeholder="">
              <option className="option-field">All</option>
            </select>
          </div>
          <div className="container-certificate">
            <div className="row">
              <div className="certify-wrapper">
                <h3>Accredited Buyer’s<br />Representative®<br />(ABR®)</h3>
              </div>
              <div className="col-md-12 col-xl-4 col-lg-4">
                <div className="certify-container">
                  <h5>What is it?</h5>
                  <p>The Accredited Buyer’s Representative (ABR®) designation is for real estate buyer agents who focus on
                    working directly with buyer-clients at every stage of the home-buying process.</p>
                </div>
                <div className="certify-container">
                  <h5>Who is it for?</h5>
                  <p>Valuable real estate education that elevates your skills and knowledge in the eyes of home
                    buyers.<br /><br />
                    Ongoing specialized information, programs, and updates that keep you knowledgeable on the issues and
                    trends facing home buyers.<br /><br />Access to members-only publications, marketing tools and
                    resources(link is
                    external).</p>
                </div>
              </div>
              <div className="col-md-12 col-xl-8 col-lg-8 certify-table">
                <h3>Registration</h3>
                <table className="table">
                  <thead className="thead_bdy thead_bdy_border">
                    <tr>
                      <td scope="col">Date</td>
                      <td scope="col" className="col-md-3">Company</td>
                      <td scope="col" className="col-md-3">Instructor</td>
                      <td scope="col" className="col-md-3">Location</td>
                      <td scope="col" className="col-md-3">Register</td>
                    </tr>
                  </thead>
                  <tbody className="tble_bdy">
                    <tr>
                      <td>2/17/2022</td>
                      <td>Policy Association</td>
                      <td></td>
                      <td>Live Broadcast</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td>2/17/2022</td>
                      <td>REALTORS of South Central Kansas</td>
                      <td></td>
                      <td>Whichita</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td>2/17/2022</td>
                      <td>Greater Bergen Relators</td>
                      <td></td>
                      <td>Saddle Book</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="center_line"></div>
        <div className="container-certificate-bg">
          <div className="col-md-10 offset-1">
            <div className="row certificate-pg">
              <div className="certify-content">
                <h3>Seniors Real Estate<br /> Specialist®, SRES®</h3>
              </div>
              <div className="col-md-12 col-xl-4 col-lg-4">
                <div className="certify-container">
                  <h5>What is it?</h5>
                  <p>The Accredited Buyer’s Representative (ABR®) designation is for real estate buyer agents who focus on
                    working directly with buyer-clients at every stage of the home-buying process.</p>
                </div>
                <div className="certify-container">
                  <h5>Who is it for?</h5>
                  <p>Valuable real estate education that elevates your skills and knowledge in the eyes of home
                    buyers.<br /><br />
                    Ongoing specialized information, programs, and updates that keep you knowledgeable on the issues and
                    trends facing home buyers.<br /><br />Access to members-only publications, marketing tools and
                    resources(link is
                    external).</p>
                </div>
              </div>
              <div className="col-md-12 col-xl-8 col-lg-8 certify-table">
                <h3>Registration</h3>
                <table className="table">
                  <thead className="thead_bdy thead_bdy_border">
                    <tr>
                      <td scope="col">Date</td>
                      <td scope="col" className="col-md-3">Company</td>
                      <td scope="col" className="col-md-3">Instructor</td>
                      <td scope="col" className="col-md-3">Location</td>
                      <td scope="col" className="col-md-3">Register</td>
                    </tr>
                  </thead>
                  <tbody className="tble_bdy">
                    <tr>
                      <td>2/17/2022</td>
                      <td>Policy Association</td>
                      <td></td>
                      <td>Live Broadcast</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td>2/17/2022</td>
                      <td>REALTORS of South Central Kansas</td>
                      <td></td>
                      <td>Whichita</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td>2/17/2022</td>
                      <td>Greater Bergen Relators</td>
                      <td></td>
                      <td>Saddle Book</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CertifySection;