import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Page from '../components/Page';
import { verifyActivationLink, verifyActivationLinkClear } from '../redux/auth/actions';
import { useHistory } from 'react-router-dom';
import { isAndroid, isIOS } from "react-device-detect";
import CONFIG from "../utils/config"
import FooterNew from '../components/FooterNew';
import HeaderLogoSection from '../components/HeaderLogoSection';
import Loader from '../components/Loader/Loader';
import MESSAGE from '../utils/messages';

const VerifyEmailPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email');
    const key = params.get('key');
    const action = params.get('action');
    const verifyLink = useSelector(state => state.auth.verifyLink)
    const [showConfirmBox, setShowConfirmBox] = useState(false)
    const [isUserActive, setIsUserActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
    }, [])

    useEffect(() => {
        dispatch(verifyActivationLink({
            module: 'setpassword',
            postData: {
                email: email,
                token: key
            }
        }))
        return (() => {
            dispatch(verifyActivationLinkClear())
        })
    }, [dispatch])

    useEffect(() => {
        // if ((isAndroid || isIOS) && CONFIG.ENV != 'DEV') {
        if (false) {
            setShowConfirmBox(true)
        }
    }, [isAndroid, isIOS])

    useEffect(() => {
        console.log('verifylink', verifyLink)
        // return;
        if (!_.isEmpty(verifyLink)) {
            // if ((!(isAndroid || isIOS) && CONFIG.ENV === 'DEV')) {
            if (true) {
            if (verifyLink.status === 'success') {
                    const verifyLinkData = verifyLink.payload.payload;
                    if (!_.isEmpty(verifyLinkData)) {
                        const isTokenValid = verifyLinkData.token
                        const isActive = verifyLinkData.active
                        if (isTokenValid) {
                            if (isActive) {
                                if (action === 'reset') {
                                    history.push(`/resetPass?email=${email}&key=${key}&action=reset`)
                                } else {
                                    setIsUserActive(true)
                                }
                            } else {
                                if (action === 'reset') {
                                    // history.push(`/`, { resetTokenExpired: true })
                                    history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                                } else {
                                    history.push(`/setup-password?email=${email}&key=${key}&action=updatepassword`)
                                }
                            }
                        } else {
                            if (isActive) {
                                if (action === 'reset') {
                                    history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                                } else {
                                    setIsUserActive(true)
                                }
                            } else {
                                if (action === 'reset') {
                                    // history.push(`/`, { resetTokenExpired: true })
                                    history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                                } else {
                                    history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                                }
                            }
                        }
                    }
                    // setShowConfirmBox(true)
                } else {
                    if (action != 'reset') {
                        history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                    } else {
                        history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                        // history.push(`/`, { resetTokenExpired: true })
                    }
                }
                setIsLoading(false)
            } else {
                if (verifyLink.status === 'success') {
                    const verifyLinkData = verifyLink.payload.payload;
                    if (!_.isEmpty(verifyLinkData)) {
                        const isTokenValid = verifyLinkData.token
                        const isActive = verifyLinkData.active
                        if (!isTokenValid) {
                            history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                        }
                    }
                    // setShowConfirmBox(true)
                }
                setIsLoading(false)
            }
            setIsLoading(false)
            // console.log('not empty')
        }
    }, [verifyLink])

    const proceedToWeb = () => {
        if (verifyLink.status === 'success') {
            if (action === 'setpassword') {
                history.push(`/setup-password?email=${email}&key=${key}&action=updatepassword`)
            } else if (action === 'reset') {
                history.push(`/resetPass?email=${email}&key=${key}&action=reset`)
            }
            // history.push(`/setup-password?email=${email}&key=${key}&action=updatepassword`)
        } else {
            history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
        }
        return "";
    }

    const proceedToApp = () => {
        if (verifyLink.status === 'success') {
            if (isAndroid) {
                let url;
                if (action === 'setpassword') {
                    url =
                        `Xsel://http?clicked_from=setup_password&user_name=${email}&token=${key}`;
                } else if (action === 'reset') {
                    url =
                        `Xsel://http?clicked_from=reset_password&user_name=${email}&token=${key}`;
                }

                window.location.replace(url);
                // setTimeout(() => {
                //     history.push(`/setup-password?email=${email}&key=${key}&action=updatepassword`)
                //   }, 1000);
            } else if (isIOS) {
                let url;
                if (action === 'setpassword') {
                    url =
                        `Xsel://?clicked_from=setup_password&user_name=${email}&token=${key}`;
                } else if (action === 'reset') {
                    url =
                        `Xsel://?clicked_from=reset_password&user_name=${email}&token=${key}`;
                }


                window.location.replace(url);
                // setTimeout(() => {
                //     history.push(`/setup-password?email=${email}&key=${key}&action=updatepassword`)
                // }, 1000);
            }
        } else {
            if (isAndroid) {
                let url;
                if (action === 'setpassword') {
                    url =
                        `Xsel://http?clicked_from=resend_link&user_name=${email}&token=${key}`;
                } else if (action === 'reset') {
                    url =
                        `Xsel://http?clicked_from=reset_link_fail&user_name=${email}&token=${key}`;
                }


                window.location.replace(url);
                // setTimeout(() => {
                //     history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                //   }, 1000);
            } else if (isIOS) {
                let url;
                if (action === 'setpassword') {
                    url =
                        `Xsel://?clicked_from=resend_link&user_name=${email}&token=${key}`;
                } else if (action === 'reset') {
                    url =
                        `Xsel://?clicked_from=reset_link_fail&user_name=${email}&token=${key}`;
                }

                // `Xsel://`;

                window.location.replace(url);
                // setTimeout(() => {
                //     history.push(`/activation-expiry?email=${email}&key=${key}&action=${action}`)
                // }, 1000);
            }
        }
    }


    return (
        <>
            {/* Create Mediator Page */}
            <div className="page-wrapper-rsp">
                <HeaderLogoSection />
                <div className="header-border"></div>
                <section className="setup-password">
                    <Loader start={isLoading} />
                    <div className="container">
                        {(false) ?
                            <>
                                {(showConfirmBox && !isUserActive) ?
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="borderboxsec">
                                                <div className="row">
                                                    <div className="col-12 mb-3 d-flex justify-content-center hedig">
                                                        How would you like to proceed?
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button className="btn-order btn-primary onbtn"
                                                            onClick={() => proceedToWeb()}
                                                        >On Web App</button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button className="btn-order btn-primary onbtn"
                                                            onClick={() => proceedToApp()}
                                                        >On Mobile App</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </>
                            : ""}
                        {isUserActive ?
                            action == 'reset' ?
                                <div className="row">
                                    <div className="col-md-5 py-5 right-space">

                                        <p>
                                            <i style={{ 'fontWeight': '100' }}>
                                                {MESSAGE.LINK_EXPIRED}
                                            </i>
                                        </p>
                                        {/* <p><i style={{'fontWeight':'100'}}>You can regenerated by clicking on <u style={{ color: 'royalblue' }} onClick={() => resend()} className="cursor-pointer">resend verification</u> link.</i></p> */}
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-5 py-5 right-space">

                                        <p>
                                            <i style={{ 'fontWeight': '100' }}>
                                                {MESSAGE.LINK_EXPIRED}
                                            </i>
                                        </p>
                                        <p>
                                            <i style={{ 'fontWeight': '100' }}>
                                                If password setup is already completed, please click&nbsp;
                                                <u
                                                    style={{ color: 'royalblue' }}
                                                    onClick={() => history.push('/')}
                                                    className="cursor-pointer">here</u>&nbsp;to login
                                            </i>
                                        </p>
                                        {/* <p>
                                <i style={{'fontWeight':'100'}}>
                                    <br />Incase, you have forgotten, click on forgot password link on login page.
                                </i>
                            </p> */}
                                        {/* <p><i style={{'fontWeight':'100'}}>You can regenerated by clicking on <u style={{ color: 'royalblue' }} onClick={() => resend()} className="cursor-pointer">resend verification</u> link.</i></p> */}
                                    </div>
                                </div>

                            : ""}
                    </div>
                </section>
                <FooterNew />
            </div>
        </>
    );
}

export default VerifyEmailPage;