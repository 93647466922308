import { useEffect, useState } from "react";
import _ from "lodash";

const OrderPlacedToastMessage = (props) => {
  const { orderInfo } = props;

  const [orderData, setOrderData] = useState();
  useEffect(() => {
    // console.log(props)
    setOrderData(props.orderInfo);
  }, [orderInfo]);

  return (
    <div className="order-details">
      <p>
        Your order containing <span>{orderData?.itemCount} item{_.toNumber(orderData?.itemCount) > 1 ? 's' : ''}</span> has been
        placed successfully.
      </p>
      {/* {orderData.orderNumber ? <p>ORDER# XSEL_{orderData?.orderNumber}</p> : ""} */}
      {/* <span>View Order Details</span> */}
    </div>
  );
};

export default OrderPlacedToastMessage;
