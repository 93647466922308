import _ from "lodash"
import { useEffect, useState } from "react"
import ContentBox from "./ContentBox"

const ContentBoxWrapper = (props) => {

    const {
        contentArr,
        reviewType,
        setReviewType,
        setIsLoading
    } = props

    const [contentDataArr, setContentDataArr] = useState([])

    useEffect(() => {
        if (!_.isEmpty(contentArr)) {
            setContentDataArr(contentArr)
        }
    }, [contentArr])

    return (
        <div className="review-role-box">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 filtervres">
                        <div className="row boxes rpfiltersecbox">
                            {
                                contentDataArr.map((content, i) => {
                                    return (
                                        <ContentBox 
                                            key={`content-${i}`}
                                            title={content.title}
                                            count={content.count}
                                            tag={content.key}
                                            reviewType={reviewType}
                                            setReviewType={setReviewType}
                                            setIsLoading={setIsLoading}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentBoxWrapper;