import MoreArticles from "./MoreArticles";
import { useDispatch, useSelector } from "react-redux";
import { getArticle } from "../../redux/article/actions";
import { useEffect, useState } from "react";
import { SessionKeys } from "../../utils/AppUtils";

const ArticleSection = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.article.articleList);
  const [articleList, setArticleList] = useState([]);
  //console.log("NewsnArticles: ", articleList);

  useEffect(() => {
    //console.log('articleList: ',articleList)
    setArticleList(articles);
  }, [articles]);

  return (
    <div className="newsarticle">
      <section className="container">
        <div className="row">
          <div className="col-md-12 article_container">
            <div className="search_heading">
              {/* <img src="/images/news_article_img.png" width="109" height="106" alt="" /> */}
              <p className="lable-heading_search">News Article</p>
            </div>
            <div className="row">
              <div className="view-button mt-4">
                <p className="label_article">
                  Lorem ipsum dolor sit amet Ipsum
                </p>
              </div>
              <div className="col-md-4 mb-4 broker-box cursor-pointer">
                <div className="img-sear">
                  <div className="details-card_article">
                    <p>
                      <span className="text_color">
                        Lorem ipsum dolor sit amet,
                      </span>
                      consectetur adipiscing elit. Sed egestas, ipsum ut rhoncus
                      tempor, nisl sapien dictum felis, sed gravida arcu urna at
                      arcu. Nullam interdum efficitur luctus. Ut at sollicitudin
                      mauris. Mauris ultricies, turpis nec accumsan euismod,
                      risus quam laoreet dui, in hendrerit massa mauris sit amet
                      ligula. Maecenas egestas nibh sed semper accumsan.
                      Curabitur dapibus, quam vitae tristique efficitur, libero
                      sapien pulvinar ligula, quis suscipit ante lacus suscipit
                      lorem. In a vestibulum magna.
                      <br />
                      <br />
                      Etiam urna tellus, pretium nec hendrerit nec, imperdiet et
                      est. Duis malesuada diam porttitor ante interdum
                      condimentum. Mauris ante justo, mattis vitae venenatis sit
                      amet, laoreet vel lectus. Maecenas pretium ornare ex,
                      fermentum condimentum enim rhoncus vel. Nulla luctus
                      mauris eget eros commodo efficitur. Phasellus viverra ac
                      orci vel bibendum. Ut egestas convallis feugiat. Sed et
                      tincidunt ante.
                      <br />
                      <br />
                      <span className="text_color">
                        {" "}
                        In urna augue, luctus eget quam non,
                      </span>{" "}
                      laoreet rutrum orci. Vivamus ex erat, malesuada vitae
                      sapien et, sollicitudin egestas nibh. Nulla velit orci,
                      sagittis in diam ultrices, viverra bibendum lacus. Proin
                      non magna ac libero porttitor pellentesque nec rhoncus
                      nibh. Aliquam erat volutpat. Etiam porttitor est sem, eget
                      posuere sapien porta in. In scelerisque mattis metus,
                      posuere efficitur dolor fringilla id. Vestibulum enim
                      sapien, iaculis eu eleifend et, vulputate at leo.
                      Vestibulum sit amet viverra neque. Etiam fringilla
                      lobortis est, eu euismod purus.
                      <br />
                      <br />
                      <span className="text_color">Vestibulum ullamcorper</span>
                      arcu ipsum. Sed porta sagittis tortor sit amet egestas.
                      Duis eros nulla, sollicitudin aliquam maximus faucibus,
                      auctor sit amet sem. Nullam cursus gravida velit, quis
                      convallis lorem euismod ut. Vestibulum porta accumsan dui,
                      id volutpat lorem malesuada et. Nulla venenatis leo ipsum,
                      vel ultrices magna vulputate ut. Vestibulum ornare sodales
                      urna a mollis. Morbi dapibus mauris sed nunc convallis,
                      sed sodales mauris iaculis. Etiam interdum dui mauris,
                      venenatis pellentesque lectus aliquam sit amet. Aliquam
                      erat volutpat. Suspendisse sodales semper odio auctor
                      vulputate. Suspendisse potenti.
                      <br />
                      <br /> Nulla a ante ligula. Vestibulum mauris mi,
                      tristique consectetur suscipit vitae, luctus non metus.
                      Praesent id dignissim odio, ac varius sem. Proin accumsan
                      vulputate nisl.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-8 pl-5 broker-box cursor-pointer">
                <div className="img-article">
                  <div className="img-article">
                    <img
                      className="img-fluid"
                      src="/images/article_img.jpg"
                      alt="image"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-4 textColr">
                    {" "}
                    <p>
                      <span className="text_color">In urna augue,</span>
                      Vivamus ex erat, malesuada vitae sapien et, sollicitudin
                      egestas nibh. Nulla velit orci, sagittis in diam ultrices,
                      viverra bibendum lacus. Proin non magna ac libero
                      porttitor pellentesque nec rhoncus nibh. Aliquam erat
                      volutpat. Etiam porttitor est sem, eget posuere sapien
                      porta in. In scelerisque mattis metus, posuere efficitur
                      dolor fringilla id. Vestibulum enim sapien, iaculis eu
                      eleifend et, vulputate at leo. Vestibulum sit amet viverra
                      neque. Etiam fringilla lobortis est, eu euismod purus.
                      <br />
                      <br />
                      <span className="text_color">
                        Vestibulum ullamcorper{" "}
                      </span>
                      arcu ipsum. Sed porta sagittis tortor sit amet egestas.
                      Duis eros nulla, sollicitudin aliquam maximus faucibus,
                      auctor sit amet sem. Nullam cursus gravida velit, quis
                      convallis lorem euismod ut. Vestibulum porta accumsan dui,
                      id volutpat lorem malesuada et. Nulla venenatis leo ipsum,
                      vel ultrices magna vulputate ut. Vestibulum ornare sodales
                      urna a mollis. Morbi dapibus mauris sed nunc convallis,
                      sed sodales mauris iaculis. Etiam interdum dui mauris,
                      venenatis pellentesque lectus aliquam sit amet. Aliquam
                      erat volutpat. Suspendisse sodales semper odio auctor
                      vulputate. Suspendisse potenti.
                      <br />
                      <br /> Nulla a ante ligula. Vestibulum mauris mi,
                      tristique consectetur suscipit vitae, luctus non metus.
                      Praesent id dignissim odio, ac varius sem. Proin accumsan
                      vulputate nisl.
                    </p>
                  </div>
                  <div className="col-md-6 mt-4 textColr">
                    {" "}
                    <p>
                      Donec tellus turpis, tincidunt ac suscipit vel, pulvinar
                      in diam. Vestibulum luctus odio nisi, tristique convallis
                      justo vehicula id. Pellentesque eleifend porttitor
                      aliquet. Vestibulum eget diam eget urna dapibus gravida
                      commodo ac nisl. Suspendisse ullamcorper tristique orci,
                      eget finibus elit vulputate nec. Quisque quis libero sit
                      amet massa varius tincidunt id ut dolor. In convallis enim
                      nec nulla sollicitudin faucibus. Praesent in purus dictum,
                      dignissim mi in, laoreet felis. Duis dui felis, euismod at
                      vulputate ornare, efficitur nec eros. Ut varius mi eget
                      sem imperdiet eleifend. Fusce sed hendrerit nibh.
                      Pellentesque ac lectus at risus pretium hendrerit. Fusce
                      quis malesuada leo. Praesent tincidunt lorem egestas sem
                      viverra, accumsan interdum est semper. Nulla condimentum
                      turpis vitae nibh rhoncus, a malesuada dolor imperdiet.
                      <br />
                      <br />{" "}
                      <span className="text_color">Suspendisse potenti. </span>
                      Aliquam erat volutpat. Ut id rutrum sem. Praesent augue
                      libero, mollis ac nibh at, mollis facilisis dui. Nam eget
                      sagittis est. Cras id ultricies tellus. Praesent
                      consectetur quam lectus, nec auctor odio gravida vitae.
                      Maecenas egestas neque eros, at sagittis sem venenatis
                      nec. Morbi sapien felis, fermentum a dolor nec, tempus
                      elementum urna. Integer at augue augue. Vivamus euismod
                      neque consectetur malesuada blandit. In a fringilla massa.
                      Curabitur tincidunt egestas est eget fringilla. Aenean
                      interdum egestas nunc a rhoncus..
                    </p>
                    <div className="row infoIcon_nw">
                      <div className="col-md-12 mt-4 actionIcon action_icons_article">
                        <a
                          className="c"
                          href=""
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#coursepoup"
                        >
                          <img
                            src="/images/article_FB.png"
                            width="26"
                            height="26"
                            alt=""
                          />
                        </a>
                        <a
                          className="c"
                          href=""
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#coursepoup"
                        >
                          <img
                            src="/images/article_share_icon.png"
                            width="26"
                            height="26"
                            alt=""
                          />
                        </a>
                        <a
                          className="c"
                          href=""
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#coursepoup"
                        >
                          <img
                            src="/images/article_link.png"
                            width="26"
                            height="26"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="center_line mt-3"></div>

            <MoreArticles articles={articleList.payload} />

            <div className=" d-flex py-3">
              <button
                type="button"
                className="btn btn-primary btn-block setup-button"
              >
                Load More
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArticleSection;
