import { useEffect, useState, useMemo } from "react";
import {
  getAnyTotalReport,
  getOnboardingCompletionReport,
} from "../../../redux/reports/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getDateTime, SessionKeys } from "../../../utils/AppUtils";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "../../../utils/config";
import {
  getAnyTotalReportClear,
  getProfileCompletionReport,
  getProfileCompletionReportClear,
  getCoeRea,
  getCoeReaResClear,
  getCoeOffices,
  getCoeOfficesResClear,
  getCoeManagers,
  getCoeManagersResClear,
  getCoeTrendCurve,
  getCoeTrendCurveResClear,
} from "../../../redux/reports/actions";
import _ from "lodash";
import PieChartComponent from "../ReportsComponents/PieChartComponent";
import ReportTableOnboard from "../ReportsComponents/ReportTableOnboard";
import { useHistory } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import ManagerReportSecCoe from "../ReportsComponents/ManagerReportSecCoe";
import OfficeSectionCoe from "../ReportsComponents/OfficeSectionCoe";
import Loader from "../../../components/Loader/Loader";
import CompletionTrendGraph from "../ReportsComponents/CompletionTrendGraph";
import { Spinner } from "react-bootstrap";
import ReportTableCoe from "../ReportsComponents/ReportTableCoe";
import AlertModalReports from "../ReportsComponents/alertModalReports";

const CodeOfEthicsReportHomeSection = (props) => {

  const {
    isMobile
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [overCrsGraphData, setOverCrsGraphData] = useState([]);
  const [crsCompltTrendValues, setCrsCompltTrendValues] = useState([]);
  const [filterYearData, setFilterYearData] = useState([])
  const [overAllData, setOverAllData] = useState([]);
  const [realEstateAgent, setRealEstateAgent] = useState([]);
  const [overAllOfficesData, setOverAllOfficesData] = useState([]);
  const [overAllManagersData, setOverAllManagersData] = useState([]);
  const [overAllLearningPoints, setOverAllLearningPoints] = useState([]);
  const [overAllLearningHours, setOverAllLearningHours] = useState([]);
  const [OverAllStatsData, setOverAllStatsData] = useState([]);
  const [isView, setIsView] = useState("table");
  const [type, setType] = useState("pie");
  const [canShow, setCanShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [limitData, setLimitData] = useState();
  const [imgLoader, setImgLoader] = useState(true);
  const [reaList, setReaList] = useState([])
  const [limitStatus, setLimitStatus] = useState("")
  const [loadSpin, setLoadSpin] = useState(true)
  const FILTER_BY = [
    { id: 0, value: "Select" },
    { id: 1, value: "Progress" },
    // { id: 1, value: "Learning Points" },
    // { id: 3, value: "Learning Hours" },
  ];
  // console.log("Thhh", overAllDataRes?.payload?.payload?.stats);
  const [statusFilterData] = useState([
    "Completed",
    "In Progress",
    "NotStarted",
  ]);
  const [profileReports, setProfileReports] = useState([]);
  const [totalRea, setTotalRea] = useState([])
  const profileReportRes = useSelector((state) => state.report?.profileReport);
  const d = new Date();
  let year = d.getFullYear();
  const [trendYear, setTrendYear] = useState(year)
  const anyTotalReportRes = useSelector(
    (state) => state.report?.anyTotalReport
  );
  const coeReaSectRes = useSelector(
    (state) => state.report?.coeReportRea
  );

  const coeOfficesSectRes = useSelector(
    (state) => state.report?.coeReportOffices
  );
  const coeTrendCurveRes = useSelector(
    (state) => state.report?.coeTrend
  );
  // const crsCompletionTrendRes = useSelector(
  //   (state) => state.report.complianceCompletionTrend
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);
  const [counter, setCounter] = useState(1);
  const [filterBy, setFilterBy] = useState(FILTER_BY[0].value);
  const [actualCoursesList, setActualCoursesList] = useState([]);
  const [totalCountRea, setTotalCountRea] = useState("")
  const [showModalMsg, setShowModalMsg] = useState("Available Real Estate Agents limit reached!");
  const userDetailRes = useSelector(state => state.user.userBroker)
  const userDetailIframe = useSelector(state => state.user.userDetailIframe)
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log('userDetailRes: ', userDetailRes)
    if (isMobile) {
      if (!_.isEmpty(userDetailIframe?.payload?.payload)) {
        setUserDetails(userDetailIframe?.payload?.payload)
      }
    } else {
      if (!_.isEmpty(userDetailRes?.payload?.payload)) {
        setUserDetails(userDetailRes?.payload?.payload)
      }
    }
  }, [userDetailRes, userDetailIframe, isMobile])

  // console.log("Selected REA", selectedRea);
  const rea_ids = []
  const office_ids = []
  const [selectedOfficeList] = useState(
    history?.location?.state?.selectedOfficeList
  );
  const [selectedRea] = useState(
    history?.location?.state?.selectedTeamMatesList
  );
  // console.log("Selected officelist", selectedOfficeList);

  // const office_ids = selectedOfficeList.selectedOfficeList[0].office_id;
  //const rea_ids = selectedRea.selectedTeamMatesList[0].user_id;

  //console.log("Office id, REA ID", office_ids, rea_ids);
  //const overAllDataRes = useSelector((state) => state.report.overallData);

  let PageSize = 20;
  useEffect(() => {
    //   array.forEach(function(obj){
    //     a.push(obj.user_id);
    // })
    selectedOfficeList.selectedOfficeList.forEach(function (obj) {
      office_ids.push(obj.office_id);
    })
    selectedRea.selectedTeamMatesList.forEach(function (obj) {
      rea_ids.push(obj.user_id);
    })

  })



  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }

  //COE REA SECTION API DISPATCH
  useEffect(() => {
    dispatch(
      getCoeRea({
        module: "getCoeReportRea",
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          rea_ids: rea_ids,
          office_ids: office_ids,
          limit: counter,
        },
      })
    );
  }, [dispatch, counter]);
  // COE TREND SECTION API DISPATCH
  useEffect(() => {
    dispatch(
      getCoeTrendCurve({
        module: "getCoeReporTrend",
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          rea_ids: rea_ids,
          office_ids: office_ids,
          year: _.toNumber(trendYear),
        },
      })
    );
  }, [dispatch, trendYear]);

  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true);
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, profileReports]);
  useEffect(() => {
    dispatch(
      getAnyTotalReport({
        module: "getCodeOfEthicsReport",
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          rea_ids: rea_ids,
          office_ids: office_ids,
        },
      })
    );

  }, [dispatch]);



  // *******************************//
  useEffect(() => {
    if (!_.isEmpty(coeReaSectRes)) {
      setLoadSpin(false)
      setLimitStatus(coeReaSectRes?.status)
      if (coeReaSectRes?.status === "success") {
        let allData = coeReaSectRes?.payload?.payload?.real_estate_agents;
        setTotalRea(coeReaSectRes?.payload?.payload)
        let temparr = _.cloneDeep(reaList)
        if (counter === 1) {
          temparr = []
        }
        setReaList([...temparr, ...allData])
        getCoeReaResClear()
      }
    }
  }, [coeReaSectRes])
  useEffect(() => {
    setCounter(1)
    setReaList([])
    return (() => {
      dispatch(getCoeReaResClear())
    })
  }, [])



  // useEffect(() => {
  //   if (!_.isEmpty(coeOfficesSectRes)) {
  //     if (coeOfficesSectRes?.status === "success") {
  //       let allData = coeOfficesSectRes?.payload?.payload?.offices;
  //       setOverAllOfficesData(allData);
  //     }
  //   }
  // }, [coeOfficesSectRes])
  useEffect(() => {
    // setCrsCompltTrendValues([])    
    return (() => {
      dispatch(getCoeTrendCurveResClear())
    })
  }, [])
  useEffect(() => {
    if (!_.isEmpty(coeTrendCurveRes)) {
      if (coeTrendCurveRes?.status === "success") {
        setImgLoader(false);
        let allData = []
        allData = coeTrendCurveRes?.payload?.payload?.completion_trend;
        setCrsCompltTrendValues(Object.values(allData));
        setFilterYearData(coeTrendCurveRes?.payload?.payload?.year)
        dispatch(getCoeTrendCurveResClear())
      }
    }
  }, [coeTrendCurveRes])


  useEffect(() => {
    if (!_.isEmpty(anyTotalReportRes)) {
      // console.log("REA onboard",realEstateAgent)
      setIsLoaded(false);
      // setImgLoader(false);
      let sortedData = [];
      let legends = [];
      let series = [];
      if (anyTotalReportRes?.status === "success") {
        setIsLoading(false);
        const reportData = anyTotalReportRes?.payload?.payload?.user_data;
        setProfileReports(reportData);
        if (statusFilterData.includes("Completed")) {
          let completedCount = reportData?.filter((data) => data.status === "Completed").length
          series.push(
            reportData?.filter((data) => data.status === "Completed").length
          );
          legends.push(`Completed (${completedCount})`);
        }
        if (statusFilterData.includes("In Progress")) {
          let inprogressCount = reportData?.filter((data) => data.status === "In Progress").length
          series.push(
            reportData?.filter((data) => data.status === "In Progress").length
          );
          legends.push(`In Progress(${inprogressCount})`);
        }
        if (statusFilterData.includes("NotStarted")) {
          let notStartedCount = reportData?.filter((data) => data.status === "NotStarted").length
          series.push(
            reportData?.filter((data) => data.status === "NotStarted").length
          );
          legends.push(`Yet to start (${notStartedCount})`);
        }
        // if (statusFilterData.includes("OverDue")) {
        //   series.push(
        //     reportData.filter((data) => data.status === "OverDue").length
        //   );
        //   legends.push("Over Due");
        // }

        if (legends.length < 3) {
          legends.push("Others");
          // console.log("TOTALRECORD", totalRecord, series);
          series.push(totalRecord - _.sum(series));
        }
        // console.log("LEGENDS", legends);
        // console.log("SERIES", series);
        setLegends(legends);
        setSeries(series);
        setCurrentPage(1);
      } else {
        setProfileReports([]);
        setCanShow(false);
        setIsLoading(false);
      }
      dispatch(getAnyTotalReportClear());
    }
  }, [anyTotalReportRes]);

  // console.log("REA", reaList)
  return (
    <>
      <Loader start={isLoading} />
      <section className="onboarding-report">
        <div className="container">
          <div className="row">
            <div className="col-md-12 selctdrop-report">
              <div className="progess-report-title">Code Of Ethics Report</div>
            </div>
          </div>
        </div>
      </section>
      <section className="progress-report-data">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-start mb-4">
              <button
                type="button"
                id="backbtnreport"
                className="btn btn-primary-complete bckbtn"
                onClick={() => {
                  history.goBack();
                  dispatch(getCoeReaResClear(),
                    getCoeTrendCurveResClear()
                  );
                }}
              >
                Back
              </button>
            </div>
            {/* <p><strong>Last updated on  </strong>{getDateTime(totalRea?.last_updated, userDetails)}</p> */}
            <div className="col-md-12 col-lg-6 mb-4">
              <div className="graph-box-learnong">
                <PieChartComponent
                  type={type}
                  isView={isView}
                  legends={legends}
                  series={series}
                  setCanShow={setCanShow}
                  statusFilterData={statusFilterData}
                  totalRecord={totalRecord}
                  currentTableData={currentTableData}
                  isCoeReport={"coe"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="progress-box-report">
        <div className="container">
          <div className="row">
            <div className="col-md-12 flex-titlereport d-flex justify-content-between align-items-baseline">
              <div className="progess-count-tiel">
                Real Estate Agents&nbsp;
                <strong>({totalRea?.total_rea})</strong>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {(!_.isEmpty(reaList)) ? reaList?.map((m, index) => {
              return (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 col-12 mb-report"
                  key={index}
                >
                  <div className="reopor-box-progess">
                    <div className="progess-profile d-flex">
                      <div className="user-progess-img">
                        <img src={m?.profile_pic} width="48" height="48" />
                      </div>
                      <div className={`userprogress-details ${(m?.is_allow === true) ? "cursor-pointer" : "cursor-not-allowed"}`}>
                        <h5
                          className="user-tiele"
                          onClick={() => {
                            if (m?.is_allow === true) {
                              history.push(`/profile/edit?section=edit&user_id=${m?.uid}&isOther=1`);
                            }
                          }}
                        >
                          {m?.user_name}
                        </h5>
                        <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <div
                                            style={{
                                                color: "white",
                                                backgroundColor: "black",
                                            }}
                                            
                                        >{m?.office_name}</div>
                                    </Tooltip>
                                }
                            >
                        <p >{m?.office_name}</p>
                        </OverlayTrigger>
                        {/* <p>{m?.office_name}</p> */}
                      </div>
                    </div>
                    <hr />
                    <div className="progress-details">
                      <div className="progess-report">
                        <div className="circlebox">
                          <CircularProgressbar value={m?.learning_percentage} />
                        </div>
                        <div className="progess-text-repfot">
                          {`${m?.learning_percentage}%`}
                          <div className="progespte">Progress</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              );
            }) : loadSpin && counter === 1 ?
              <div className="not-found mt-3">
                <div className="interboxes">
                  <Spinner animation="border" />
                </div>
              </div>
              :
              <div className="col-md-12">
                <div className="not-found mt-3">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>This section will display the REA's that have to complete the Code of Ethics.</p>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-12 mt-3">
              {/* <p className="text-center">
                {(limitStatus === "error") ?
                  "" :
                  <button
                    type="button"
                    id="number"
                    className="btn btn-primary-complete"
                    onClick={() => {
                      setCount(counter, setCounter);
                      setLoadSpin(true);
                    }
                    }
                  >
                    {(loadSpin) ?
                      <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" />
                      </div>
                      : "Load More"}
                  </button>
                }
              </p> */}
              <p className="text-center">
                {reaList?.length === 0 || (totalRea?.total_rea === reaList?.length) ? "" : (totalRea?.total_rea < 4) ? "" :
                  <button
                    type="button"
                    id="number"
                    className="btn btn-primary-complete"
                    onClick={() => {
                      setCount(counter, setCounter);
                      setLoadSpin(true);
                    }
                    }
                  >
                    {(loadSpin) ?
                      <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" />
                      </div>
                      : "Load More"}
                  </button>
                }
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <RealEstateAgentsReportSection overAllData={overAllData} /> */}
      <ManagerReportSecCoe isMobile={isMobile} />
      <OfficeSectionCoe isMobile={isMobile} />
      <section className="report-graphname">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="report-graph-trend">
                <div className="input-text fliterreportsgen graphselct">
                  <label for="filter" className="input__label">Select Year</label>
                  <select type="text" id="filter" name="filter" className="form-select input_searchfield mb-0 session-filter"
                    onChange={(e) => {
                      const selectedYear = e.target.value;
                      setTrendYear(selectedYear)
                      setImgLoader(true)
                    }}
                  >
                    {filterYearData?.map((item, index) => {
                      return (
                        <option seleted key={index} value={item[index]}
                        >{item}</option>
                      )
                    })}
                  </select>
                </div>
                {imgLoader ? (
                  <div className="d-flex justify-content-center w-100">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-start gap-5 mob-res w-100">
                    <CompletionTrendGraph
                      crsCompltTrendData={crsCompltTrendValues}
                      crsCompltTrend={filterYearData}
                      completionTrendLabel={"Code of Ethics Completion"}
                      setTrendYear={setTrendYear}
                      trendYear={trendYear}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReportTableCoe
        setSearchInput={""}
        getFilteredData={[]}
        lpName={"Onboarding Name"}
        isView={isView}
        statusFilterData={statusFilterData}
        setCanShow={setCanShow}
        profileReports={profileReports}
        currentTableData={currentTableData}
        type={type}
        message={"No Code Of Ethics created yet."}
      />
    </>
  );
};

export default CodeOfEthicsReportHomeSection;
