import Page from "../../components/Page";
import { useEffect, useState } from "react";
import LearningPathReportHomeSection from "../../components/Reports/learningPathProgress/LearningPathReportHomeSection";

export const LearningPathProgressReportPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);

        let sendHeight = setInterval(() => {
            let obj = { 'method': 'height', 'value': document.body.scrollHeight }
            console.log('height: ', obj);
            if (window.callbackHandler != undefined) {
                window.callbackHandler.postMessage(JSON.stringify(obj));
            } else if (window.webkit != undefined) {
                if (window.webkit.messageHandlers.callbackHandler != undefined) {
                    window.webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify(obj));
                }
            }

        }, 3000)

        return (() => { clearInterval(sendHeight) })

    }, [])

    return (
        <>
            <Page>
                <LearningPathReportHomeSection
                    isMobile={false}
                />
            </Page>
        </>
    )
}

export default LearningPathProgressReportPage;