import types from './types'

// Checkout Fetch
export const checkoutSummary = (payload) => ({
  type: types.CHECKOUT_SUMMARY, payload
})
export const checkoutSummaryResponse = (payload) => ({
  type: types.CHECKOUT_SUMMARY_RESPONSE, payload
})
export const checkoutSummaryClear = () => ({ type: types.CHECKOUT_SUMMARY_CLEAR })

export const fetchPaymentIntent = (payload) => ({type: types.PAYMENT_INTENT, payload})
export const fetchPaymentIntentResponse = (payload) => ({type: types.PAYMENT_INTENT_RESPONSE, payload})
export const fetchPaymentIntentClear = () => ({ type: types.PAYMENT_INTENT_CLEAR })

// Direct Checkout
export const fetchPIDirectCheckout = (payload) => ({type: types.PI_DIRECT_CHECKOUT, payload})
export const fetchPIDirectCheckoutResponse = (payload) => ({type: types.PI_DIRECT_CHECKOUT_RESPONSE, payload})
export const fetchPIDirectCheckoutClear = () => ({ type: types.PI_DIRECT_CHECKOUT_CLEAR })

export const sendOrderPlaceResponse = (payload) => ({
  type: types.SEND_ORDER_PLACE_RESPONSE, payload
})

export const sendOrderPlace = (payload) => ({
  type: types.SEND_ORDER_PLACE, payload
})

export const sendOrderPlaceClear = () => ({ type: types.SEND_ORDER_PLACE_CLEAR })

export const zeroSendOrderResponse = (payload) => ({
  type: types.ZERO_SEND_ORDER_PLACE_RESPONSE, payload
})

export const zeroSendOrder = (payload) => ({
  type: types.ZERO_SEND_ORDER_PLACE, payload
})

export const zeroSendOrderClear = () => ({ type: types.ZERO_SEND_ORDER_PLACE_CLEAR })

export const orderPlacedResponse = (payload) => ({
  type: types.ORDER_PLACED_RESPONSE, payload
})
export const orderPlacedClear = () => ({ type: types.ORDER_PLACED_CLEAR })

// Data fetch from cart page

export const cartPageDataClear = () => ({ type: types.CART_PAGE_DATA_CLEAR })

export const cartPageDataResponse = (payload) => ({type: types.CART_PAGE_DATA_RESPONSE, payload})
