import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import MembershipSection from "../../components/Membership/MembershipSection";
import Page from "../../components/Page";

const MembershipPage = () => {

    const membershipProducts = useSelector(state => state.membership.membershipProducts);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!_.isEmpty(membershipProducts)) {
            setIsLoading(false)
        }
    }, [membershipProducts])

    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <MembershipSection />
            </Page>
        </>
    )
}

export default MembershipPage;