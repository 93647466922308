import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  getCheckListInsert,
  getCheckListInsertResponseClear,
  getLearningPathBroker,
  getLearningPathBrokerClear,
  setFavoriteCourses,
} from "../../redux/courses/actions";
import { convertTimeFormat, SessionKeys } from "../../utils/AppUtils";
import { ProgressBar } from "react-bootstrap";
import SliderCourseListing from "../LearningPaths/SliderCourseListing";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Loader from "../Loader/Loader";
import ShimmerCardBody from "../Layouts/ShimmerCardBody";

const OnBoardingSectionBroker = (props) => {
  const dispatch = useDispatch();

  const learningPathBroker = useSelector(
    (state) => state.courses.learningPathBroker
  );
  const authBroker = useSelector((state) => state.auth.authBroker);
  const insertCheckList = useSelector(state => state.courses.checkListInsert)
  const [onBoardingData, setOnBoardingData] = useState([]);
  const [pageType, setPageType] = useState("course");
  const [closeChildModal, setCloseChildModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [noData, setNoData] = useState(false)


  useEffect(() => {
    if (!_.isEmpty(insertCheckList)) {
        dispatch(getCheckListInsertResponseClear())
    }
}, [insertCheckList])

  useEffect(() => {
    // console.log("learning path broker", learningPathBroker);
    if (
      !_.isEmpty(learningPathBroker) &&
      learningPathBroker.status === "success" &&
      _.isArray(learningPathBroker.payload.payload)
    ) {
      const learningData = _.cloneDeep(learningPathBroker.payload.payload);
      // console.log("learningPath", learningData);
      let newLearningList = [];
      for (let learning of learningData) {
        if (learning.is_onboarding) {
          // console.log("setLearningPathData", learning);
          let courseCount = learning.courseList.length;
          let completedCourseCount = 0;
          for (let course of learning.courseList) {
            if (course.crs_progress == 100) {
              completedCourseCount++;
            }
          }
          // learning.programprogress = completedCourseCount / courseCount * 100;
          newLearningList.push(learning);
        }
      }
      setOnBoardingData(newLearningList);
      // setOnBoardingData(learningPathBroker.payload)
      setIsLoading(false);
      if (learningPathBroker.payload.payload.length === 0) {
        setNoData(true)
      }
      dispatch(getLearningPathBrokerClear());

    }
  }, [learningPathBroker]);

  useEffect(() => {
    // console.log('learning path broker', learningPathBroker)
    if (
      !_.isEmpty(learningPathBroker) &&
      learningPathBroker.status === "success" &&
      _.isArray(learningPathBroker.payload.payload)
    ) {
      const learningData = _.cloneDeep(learningPathBroker.payload.payload);
      // console.log('learningPath', learningData)
      let newLearningList = [];
      for (let learning of learningData) {
        let sendFlag = false;
        console.log('Learning', sendFlag, learning)
        if (learning.is_onboarding) {
          // console.log('setLearningPathData', learning)
          let courseCount = learning.courseList.length;
          let completedCourseCount = 0;
          for (let [index, course] of learning.courseList.entries()) {
            learning.is_manager_checklist = false;
            let currentCourse = course;
            let nextCourse = learning.courseList[index + 1];
            if (learning.sequence === 1) {
                if (index === 0) {
                    currentCourse.isLock = false;
                }
                currentCourse.isLock = currentCourse.isLock == false ? currentCourse.isLock : true;
                if (currentCourse.crs_progress > 0) {
                    currentCourse.isLock = false;
                    if (index != learning.courseList.length - 1) {
                        nextCourse.isLock = _.toNumber(currentCourse.crs_progress) >= 100 ? false : true;
                    }
                }
            } else {
                currentCourse.isLock = currentCourse.isLock ? currentCourse.isLock : false;
            }
            currentCourse.duration = convertTimeFormat(_.toNumber(currentCourse.duration));
            currentCourse.durationUnit = ''
            // console.log("detaiiii", course)
            if (index < learning.courseList.length - 1) {
                if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present) {
                    nextCourse.isLock = nextCourse.crs_progress == 0 ? true : false
                } else if ((currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present)) {
                    nextCourse.isLock = false
                }

                if (currentCourse.is_checklist_present) {
                  learning.is_manager_checklist = true;
                  nextCourse.is_manager_checklist = true;
                  console.log('HI', currentCourse.is_checklist_present, nextCourse);
                }
            }
            if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present && !sendFlag) {
                if (currentCourse.crs_progress >= 100 && (index != learning.courseList.length - 1)) {
                    console.log(getCheckListInsert({
                        module: 'checklist-insert',
                        postData: {
                            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                            lp_id: learning?.id,
                            entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                            entity_type: !currentCourse?.is_level ? "course" : "level"
                        }
                    }));
                    dispatch(
                        getCheckListInsert({
                        module: 'checklist-insert',
                        postData: {
                            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                            lp_id: learning?.programID,
                            entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                            entity_type: !currentCourse?.is_level ? "course" : "level"
                        }
                    }))
                    sendFlag = true;
                }
            }
            // if (course.durationUnit === "Hours") {
            //   const convertArray = _.toString(course.duration).includes(":")
            //     ? course.duration.split(":")
            //     : [course.duration, 0];
            //   const hoursMinutes = _.toNumber(convertArray[0]) * 60;
            //   const minutes = _.toNumber(convertArray[1]);
            //   const totalMinutes = hoursMinutes + minutes;
            //   course.duration = totalMinutes;
            // }
            // if (_.toNumber(course.duration) > 60) {
            //   let hours = Math.floor(course.duration / 60);
            //   let minutes = course.duration % 60;
            //   hours = hours >= 10 ? hours : `0${hours}`;
            //   minutes = minutes >= 10 ? minutes : `0${minutes}`;
            //   // console.log(course.duration, hours, minutes)
            //   course.duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
            //     } ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
            //   course.durationUnit = "";
            // } else if (_.toNumber(course.duration) < 60) {
            //   console.log('Minutes')
            //   course.duration = `${course.duration} ${course.duration > 1 ? "Mins" : "Min"
            //     }`;
            //   course.durationUnit = "";
            // } else {
            //   console.log('hour', course)
            //   course.duration = "1 Hour";
            //   course.durationUnit = "";
            // }
            if (course.crs_progress == 100) {
              completedCourseCount++;
            }
          }
          // for (let course of learning.courseList) {
          //   course.is_manager_checklist = learning.is_manager_checklist
          // }
          // learning.programprogress = completedCourseCount / courseCount * 100;
          newLearningList.push(learning);
        }
      }
      console.log(newLearningList)
      setOnBoardingData(newLearningList);
      // setOnBoardingData(learningPathBroker.payload)
      if (learningPathBroker.payload.payload.length === 0) {
        setNoData(true)
      }
      dispatch(getLearningPathBrokerClear());
    }
  }, [learningPathBroker]);

  const showProgessNaming = (progres) => {
    if (progres === 0) {
      return "Not Started";
    } else if (progres === 100) {
      return "Completed";
    } else {
      return "In Progress";
    }
  };

  return (
    <>
      {/* <Loader start={isLoading} /> */}
      {onBoardingData && onBoardingData?.length > 0 ? (
        <>
          {!props.hideLine && (
            <div id="myOnboard" className="center_line mt-3"></div>
          )}
          {onBoardingData.map((data, index) => {
            return (
              <div
                className="New-dashboard learning"
                key={`onboard-${index + 1}`}
              >
                <section className="my_onbording pt-5g pb-4">
                  <div className="container">
                  <div className="search_heading mb-5">
                                <p className="lable-heading_search">My Onboarding</p>
                            </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <div className="d-flex mb-4 myOnboarding align-items-center">
                          <h3>{data?.programName}</h3>
                          {!props.hideLine && (
                            <div className="border_lines w-100">
                              <ProgressBar
                                className="progressbar"
                                variant="success"
                                now={data?.programprogress}
                              />
                            </div>
                          )}
                        </div>
                        {props.hideLine && (
                          <div className="container-pie">
                            <div className="container-chart">
                              <div className="box d-flex align-items-baseline mb-2">
                                <div
                                  className="chart-7"
                                  style={{
                                    width: 150,
                                    height: 150,
                                    position: "relative",
                                  }}
                                >
                                  <CircularProgressbar
                                    value={data.programprogress}
                                    text={`${data.programprogress}%`}
                                    styles={buildStyles({
                                      pathColor:
                                        96 < 100 ? `#6CC869` : "#6CC869", //FF9700=yellow
                                      trailColor: "#EBEBEB",
                                      textSize: "16px",
                                      text: {
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        fill: "#000",
                                        fontFamily: "Verdana",
                                      },
                                    })}
                                  />
                                </div>
                                <p className="chart-content">
                                  {showProgessNaming(data.programprogress)}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </h2>

                      <div className="row responsive-carousel">
                        {data?.courseList && data?.courseList.length > 0 ? (
                          <SliderCourseListing
                            isOnboarding={data.is_onboarding ? true : false}
                            coursesList={data.courseList}
                            setFavoriteCourse={() => { }}
                            closeModal={closeChildModal}
                            setCloseModal={setCloseChildModal}
                            hasDescription={false}
                            pageType="course"
                            showBlankCard={false}
                            path={props.hideLine ? '/my-onboarding' : '/home'}
                          />
                        ) : (
                          <div className="col-md-12">
                            No on boarding course found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {isLoading ? (
            ""
          ) : props.hideLine ? (
            <div className="New-dashboard learning">
              <section className="my_onbording pt-5g pb-4">
                <div className="container">
                <div className="search_heading">
                                <p className="lable-heading_search">My Onboarding</p>
                            </div>
                  <div className="not-found mt-5">
                    <div className="interboxes">
                      <img src="/images/telescope.svg" alt="Not Found" />
                      <p>Onboarding is not been assigned yet.</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <>
             {!noData?<div className="simmerbox">
                              <div className="container">
                  <div className="row videolistbox">
                    {[1, 2, 3, 4].map((i) => {
                      return <ShimmerCardBody key={`shimmer-${i}`} />;
                    })}
                  </div>
                </div>
              </div>:""}
            </>
          )}
        </>
      )}
    </>
  );
};

export default OnBoardingSectionBroker;
