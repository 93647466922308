import { convertHoursToMins, convertSecondsToMins, convertTimeFormat, formatSecondsHand } from "../../utils/AppUtils";

export const VideoCoursesDataSetupSection = (videoListData, isPremiumUser) => {
    for (let course of videoListData) {
        course.is_purchased = course.is_paid;
        course.showBuy = course.is_purchased ? false : true;
        course.is_bundle = false;
        course.is_free = false;
        if (course.source != "xsel") {
          course.is_paid = true;
          course.is_purchased = false;
        }
        if (course.sfdc_product_type.toLowerCase() === "basic") {
          course.showBuy = false;
          course.is_addtocart = false;
          course.is_paid = true;
          course.is_purchased = false;
        } else if (course.sfdc_product_type.toLowerCase() === "premium") {
          if (isPremiumUser) {
            course.showBuy = false;
            course.is_paid = true;
            course.is_free = true;
          } else {
            if (course.product_offer_for_basic) {
              course.sale_price = 0.0;
              course.regular_price = 0.0;
            } else {
              course.sale_price = course.product_basic_price;
              course.regular_price = course.product_market_price;
            }
          }
        } else if (
          course.sfdc_product_type.toLowerCase() === "alacarte"
        ) {
          if (isPremiumUser) {
            if (course.product_offer_for_premium) {
              course.sale_price = 0.0;
              course.regular_price = 0.0;
            } else {
              course.sale_price = course.product_premium_price;
            }
          } else {
            if (course.product_offer_for_basic) {
              course.sale_price = 0.0;
              course.regular_price = 0.0;
            } else {
              course.sale_price = course.product_basic_price;
            }
          }
          course.regular_price = course.product_market_price;
        } else if (
          course.sfdc_product_type.toLowerCase() === "bundle"
        ) {
          course.is_bundle = true;
          for (let video of course.list) {
            video.source = course.source
            video.is_free = course.is_free
            video.is_paid = course.is_paid
            video.product_id = video.id
            video.course_code = video.crscd
          }
        }

        if (course.sfdc_product_type.toLowerCase() !== "bundle") {
          if (
            course.durationUnit === "Second(s)" &&
            course.course_duration >= 60
          ) {
            if (course.course_duration % 60) {
              course.course_duration = convertTimeFormat(
                course.course_duration,
                true
              );
            } else {
              course.course_duration = convertSecondsToMins(
                course.course_duration
              );
              course.course_duration = convertTimeFormat(
                course.course_duration
              );
            }
            course.durationUnit = "";
          } else if (
            course.durationUnit === "Second(s)" &&
            course.course_duration < 60
          ) {
            course.course_duration = formatSecondsHand(course.course_duration);
            course.durationUnit = "";
          } else if (course.durationUnit === "Hours") {
            course.course_duration = convertHoursToMins(
              course.course_duration
            );
            course.course_duration = convertTimeFormat(
              course.course_duration
            );
            course.durationUnit = "";
          }
        } else {
          for (let cl of course.list) {
            if (
              cl.durationUnit === "Second(s)" &&
              cl.duration >= 60
            ) {
              if (cl.duration % 60) {
                cl.duration = convertTimeFormat(
                  cl.duration,
                  true
                );
              } else {
                cl.duration = convertSecondsToMins(
                  cl.duration
                );
                cl.duration = convertTimeFormat(
                  cl.duration
                );
              }
              cl.durationUnit = "";
            } else if (
              cl.durationUnit === "Second(s)" &&
              cl.duration < 60
            ) {
              cl.duration = formatSecondsHand(cl.duration);
              cl.durationUnit = "";
            } else if (cl.durationUnit === "Hours") {
              cl.duration = convertHoursToMins(
                cl.duration
              );
              cl.duration = convertTimeFormat(
                cl.duration
              );
              cl.durationUnit = "";
            }
          }
        }
      }
      return videoListData
}