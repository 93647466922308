import types from './types'

export const fixedFooter = () => ({type: types.FIXED_FOOTER})
export const fixedFooterClear = () => ({type: types.FIXED_FOOTER_CLEAR})

export const homePageMsg = (payload) => ({type: types.HOME_MSG, payload})
export const homePageMsgClear = () => ({type: types.HOME_MSG_CLEAR})

export const homePageShowMsg = (payload) => ({type: types.SHOW_HOME_MSG, payload})

export const checkContentAccess = (payload) => ({type: types.CONTENT_ACCESS, payload})
export const checkContentAccessResponse = (payload) => ({type: types.CONTENT_ACCESS_RESPONSE, payload})
export const checkContentAccessClear = () => ({type: types.CONTENT_ACCESS_CLEAR})

export const setBreadcrumbStack = (payload) => ({type: types.BREADCRUMB_STACK, payload})
export const clearBreadcrumbStack = () => ({type: types.CLEAR_BREADCRUMB_STACK})