const data = {
    categories: [
        {
            id: 'all',
            name: 'All',
        },
        {
            id: 'RST001',
            name: 'Real Estate Technology',
        },
        {
            id: 'TBO001',
            name: 'Trasactions Buyer Offer',
        },
        {
            id: 'TSL001',
            name: 'Transactions Sellers Listing',
        },
        {
            id: 'MLG001',
            name: 'Marketing and Lead Generation',
        },
    ],
    coursesData: [
        {
            categoryName: 'Real Estate Technology',
            categoryId: 'RST001',
            courseList: [
                {
                    id: 'RST001_PRD001',
                    name: 'Realm',
                    link: '',
                    mp4Link: 'movie.mp4',
                    oggLink: 'movie.ogg',
                    isPremium: false,
                    isNew: false,
                    duration: 1,
                    durationUnit: 'Hour',
                    views: 753,
                    assignedByManager: false
                },
                {
                    id: 'RST001_RS001',
                    name: 'Geowearhouse',
                    link: '',
                    mp4Link: 'movie.mp4',
                    oggLink: 'movie.ogg',
                    isPremium: false,
                    isNew: false,
                    duration: 30,
                    durationUnit: 'Mins',
                    views: 587,
                    assignedByManager: false
                },
                {
                    id: 'RST001_WS001',
                    name: 'Stratus',
                    link: '',
                    mp4Link: 'movie.mp4',
                    oggLink: 'movie.ogg',
                    isPremium: false,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 986,
                    assignedByManager: false
                },
                {
                    id: 'RST001_ASC001',
                    name: 'Matrix',
                    link: '',
                    mp4Link: 'movie.mp4',
                    oggLink: 'movie.ogg',
                    isPremium: false,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 543,
                    assignedByManager: false
                },
            ]
        }
    ]
}

export default data