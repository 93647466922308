import ShimmerScreen from "./ShimmerScreen";

const ShimmerModuleLaunch = () => {
  return (
    <>
      <div className="col-md-6 col-xl-3 col-lg-3 col-sm-12 mb-4 broker-box cursor-pointer module-border-box">
        <div className="img-box_realtor">
          <div className="img-videosec learn-img_card learning cursor-pointer">
            <ShimmerScreen width="100%" height="9.125rem" case="rect" />
          </div>

          <div className="details-card_realtor mt-2">
            {/*Heading Shimmer */}
            <h5>
              <ShimmerScreen case="rect" width="100%" height="1.5rem" />
            </h5>
            {/* <div className="mt-1"></div>
              <ShimmerScreen case="rect" width="10rem" height="1.2rem" /> */}

            <div className="row info-bottm-cardRealtor mt-2">
              <div className="col-md-12 cardicon_realtor">
                {/* Circle Shimmer */}
                <div className="d-flex gap-2 mb-2">
                  <ShimmerScreen case="circle" width="1rem" height="1rem" />
                  <span className="cardicon_realtor">
                    {/*Timer Shimmer */}
                    <ShimmerScreen
                      case="rect"
                      width="3.125rem"
                      height="0.9375rem"
                    />
                  </span>
                </div>
                <div>
                  <ShimmerScreen case="rect" width="100%" height="3.625rem" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShimmerModuleLaunch;
