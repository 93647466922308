/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import Chart from "react-apexcharts";

const PieChart = (props) => {
  let themeColors = [];
  if (props.isCoeReport === "coe") {
    themeColors = ["#1DB128", "#FEBE10", "#ED156E"];
  } else {
    themeColors = ["#1DB128", "#FEBE10", "var(--color-element-1)", "#ED156E"];
  }

  const [options, setOptions] = useState({
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: true,
      position: 'top',
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined,
        // background:'black',
        // color:'white',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        format: 'dd MMM',
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      z: {
        formatter: undefined,
        title: 'Size: '
      },
      marker: {
        show: true,
      },
      // items: {
      //   display: flex,
      // },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    colors: props.themeColors ? props.themeColors : themeColors,
    legend: {
      itemMargin: {
        horizontal: 2,
      },
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 575,
        options: {
          chart: {
            width: 300,
            height: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            width: 400,
            height: 200,
          },
          legend: {
            position: "right",
          },
        },
      },
    ],
    labels: props.legends ? props.legends : [],
  });
  const [series, setSeries] = useState(props.series ? props.series : []);

  useEffect(() => {
    setSeries(props.series);
    setOptions({
      chart: {
        type: "pie",
      },
      // noData:{text:"No Data is available."},
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, w }) {
          // return w.config.series[seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10,
          },
          customScale: 1,
          expandOnClick: true,
        },
      },
      colors: props.themeColors ? props.themeColors : themeColors,
      legend: {
        itemMargin: {
          horizontal: 2,
        },
        position: "right",
      },
      responsive: [
        {
          breakpoint: 575,
          options: {
            chart: {
              width: 300,
              height: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              width: 400,
              height: 200,
            },
            legend: {
              position: "right",
            },
          },
        },
      ],
      total: {
        fontSize: "1rem",
      },
      labels: props.legends,
    });
    // console.log(props)
  }, [props]);

  return (
    <Chart
      options={options}
      series={series}
      type="pie"
      height={120}
      width={400}
    />
  );
};

export default PieChart;
