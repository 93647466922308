import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showBuyModal } from "../../redux/courses/actions";
import { CircularProgress } from "@mui/material";
import { getOrderDetail } from "../../redux/product/actions";
import moment from "moment";

import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import $ from "jquery";
import jsPDF from "jspdf";
import _ from "lodash";

const InvoiceShowModal = (props) => {
  const { confirmModal, setConfirmModal, order } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  // const orderDetail = useSelector(state => state.product.orderDetail)
  // const [order, setOrder] = useState({})

  useEffect(() => {
    // if (confirmModal && invoiceDetail !== null)
    //   dispatch(getOrderDetail({
    //     module: 'getOrderDetail',
    //     courseId: orderId
    //   }))
    // console.log('orderModalData: ',orderDetail)
  });

  // useEffect(() => {
  //   console.log(orderDetail)
  //   if (!_.isEmpty(orderDetail)) {
  //     console.log('orderDetail: ', orderDetail?.payload)
  //     setOrder(orderDetail?.payload[0])
  //   }
  // }, [orderDetail])

  const handleCaptureClick = () => {
    // const input = document.getElementsByClassName(".invoiceD");
    // const canvas = await html2canvas(input);

    html2canvas($(".invoiceD")[0]).then((canvas) => {
      const dataURL = canvas.toDataURL("image/png");
      downloadjs(dataURL, "download.png", "image/png");
    });
  };

  const getPDF = (download) => {
    var HTML_Width = $(".invoiceD").width();
    var HTML_Height = $(".invoiceD").height();
    var top_left_margin = 18;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas($(".invoiceD")[0], {
      allowTaint: true,
      useCORS: true,
      onclone: (document) => {
        var delIcon = document.getElementsByClassName("invoiceIcons_modal");
        if (delIcon.length > 0) {
          for (var i = 0; i < delIcon.length; i++) {
            delIcon[i].style.display = "none";
          }
        } else {
          delIcon.style.display = "none";
        }
      },
    }).then(function (canvas) {
      canvas.getContext("2d");
      // console.log(canvas.height + "  " + canvas.width);
      var imgData = canvas.toDataURL("image/jpeg", 0.9);
      var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(String(PDF_Width), String(PDF_Height));
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height,
          { align: "center" }
        );
      }
      if (download) pdf.save("#XSEL_" + order?.order_number + ".pdf");
      else pdf.output(`dataurlnewwindow`);
    });
  };

  return (
    <Modal
      className="invoice-modal"
      show={confirmModal}
      onHide={() => setConfirmModal(false)}
    >
      <Modal.Body style={{ width: "800px", "marginLeft": "-100px" }}>
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          style={{ backgroundColor: "white" }}
        >
          <div className="modal-content ">
            <div className="modal-body custom_modal">
              <div className="invoiceD">
                <div className="col-md-12 col-sm-12">
                  <div className="invoiceMainModal invocust_container">
                    <div className="invoice_main_modal ">
                      <a href="home_new.html">
                        <img
                          src="images/cross_logo_white.png"
                          width="50"
                          height="44"
                        />
                      </a>
                      <h6>Invoice</h6>
                    </div>

                    <div className="invoiceIcons_modal">
                    <svg id="Download" onClick={() => {
                          getPDF(true);
                        }} xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="26"
                        height="26"
                        viewBox="0 0 30 30">
                        <defs>
                          <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#00b7f1"/>
                            <stop offset="1" stop-color="#ed156e"/>
                          </linearGradient>
                        </defs>
                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"/>
                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"/>
                        <path id="Polygon_5" data-name="Polygon 5" d="M5.293.707a1,1,0,0,1,1.414,0l3.586,3.586A1,1,0,0,1,9.586,6H2.414a1,1,0,0,1-.707-1.707Z" transform="translate(21 18) rotate(180)" fill="var(--color-element-1)"/>
                        <rect id="Rectangle_2728" data-name="Rectangle 2728" width="10" height="2" rx="1" transform="translate(10 19)" fill="var(--color-element-1)"/>
                        <rect id="Rectangle_2729" data-name="Rectangle 2729" width="4" height="7" rx="1" transform="translate(13 8)" fill="var(--color-element-1)"/>
                      </svg>

                      {/* <img
                        src="images/Download.svg"
                        width="30"
                        height="30"
                        alt=""
                        onClick={() => {
                          getPDF(true);
                        }}
                      /> */}
                      <svg onClick={() => {getPDF(false); }} xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              id="Print"
                              width="26"
                              height="26"
                              viewBox="0 0 30 30"
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradient"
                                  y1="0.5"
                                  x2="1"
                                  y2="0.5"
                                  gradientUnits="objectBoundingBox"
                                >
                                  <stop offset="0" stop-color="#00b7f1" />
                                  <stop offset="1" stop-color="#ed156e" />
                                </linearGradient>
                              </defs>
                              <circle
                                id="Ellipse_57"
                                data-name="Ellipse 57"
                                cx="15"
                                cy="15"
                                r="15"
                                fill="url(#linear-gradient)"
                              />
                              <circle
                                id="Ellipse_179"
                                data-name="Ellipse 179"
                                cx="13"
                                cy="13"
                                r="13"
                                transform="translate(2 2)"
                                fill="#fff"
                              />
                              <g
                                id="Icon_ionic-ios-print"
                                data-name="Icon ionic-ios-print"
                                transform="translate(4.504 4.504)"
                              >
                                <path
                                  id="Path_1248"
                                  data-name="Path 1248"
                                  d="M9,16.86v6.846a.549.549,0,0,0,.548.548h7.668a.549.549,0,0,0,.548-.548V16.86a.549.549,0,0,0-.548-.548H9.548A.549.549,0,0,0,9,16.86Z"
                                  transform="translate(-2.886 -6.639)"
                                  fill="var(--color-element-1)"
                                />
                                <g
                                  id="Group_1142"
                                  data-name="Group 1142"
                                  transform="translate(3.375 3.375)"
                                >
                                  <path
                                    id="Path_1249"
                                    data-name="Path 1249"
                                    d="M17.186,3.375H8.423a.549.549,0,0,0-.548.548v.685a.137.137,0,0,0,.137.137H17.6a.137.137,0,0,0,.137-.137V3.923A.549.549,0,0,0,17.186,3.375Z"
                                    transform="translate(-5.684 -3.375)"
                                    fill="var(--color-element-1)"
                                  />
                                  <path
                                    id="Path_1250"
                                    data-name="Path 1250"
                                    d="M16.537,7.875H4.488A1.1,1.1,0,0,0,3.375,8.946v5.97a1.125,1.125,0,0,0,1.113,1.106h.531a.275.275,0,0,0,.274-.274V12.12a.957.957,0,0,1,.958-.958H14.74a.957.957,0,0,1,.958.958v3.629a.275.275,0,0,0,.274.274h.565a1.1,1.1,0,0,0,1.078-1.106V8.946A1.066,1.066,0,0,0,16.537,7.875Z"
                                    transform="translate(-3.375 -5.684)"
                                    fill="var(--color-element-1)"
                                  />
                                </g>
                              </g>
                            </svg>
                      {/* <img
                        src="images/icon2.png"
                        width="26"
                        height="26"
                        alt=""
                        onClick={() => {
                          getPDF(false);
                        }}
                      /> */}
                      <svg id="Close" onClick={() => {
                          setConfirmModal(false);
                        }} xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="26"
                        height="26"
                        viewBox="0 0 30 30">
                        <defs>
                          <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#00b7f1"/>
                            <stop offset="1" stop-color="#ed156e"/>
                          </linearGradient>
                        </defs>
                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"/>
                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"/>
                        <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                          <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                          <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                        </g>
                        <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                          <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                          <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                        </g>
                      </svg>

                      {/* <img
                        src="images/cross.png"
                        width="26"
                        height="26"
                        alt=""
                        onClick={() => {
                          setConfirmModal(false);
                        }}
                      /> */}
                    </div>
                  </div>

                  <section className="invoice_cust_container mt-5">
                    <div className="invSectionMain">
                      <div className="invSectionOne">
                        <h6>Billed to </h6>
                        <p>
                          {order?.billing_first_name} {order?.billing_last_name}{" "}
                          {order?.billing_address_1}{" "}
                          {order?.billing_address_2
                            ? order?.billing_address_2 + ","
                            : ""}{" "}
                          {order?.billing_city ? order?.billing_city + "," : ""}
                          {order?.billing_state}
                        </p>
                      </div>
                      <div className="invSectionOne">
                        <h6>Invoice Number </h6>

                        <p> #XSEL_{order?.order_number}</p>
                        <h6>Invoice Date </h6>
                        <p>
                          {moment(order?.order_paid?.date).format(
                            "Do MMM YYYY"
                          )}
                        </p>
                      </div>
                      <div className="invSectionOne">
                        <h6>Invoice Total </h6>
                        <p className="invoice_currency">${order?.total} CAD</p>
                      </div>
                    </div>
                  </section>

                  <section className="invoice_cust_container paymentdis mt-3">
                    <div className="invSectionMain">
                      <div className="invDecrpn_Amont">
                        <p>Description</p>
                      </div>
                      <div className="invDecrpn_Amont">
                        <p>Amount</p>
                      </div>
                    </div>
                    <div
                      className="center_line mb-3"
                      style={{ "margin-top": "0px !important" }}
                    ></div>

                    {order?.courseList.map((course, index) => {
                      return (
                        <div className="invSectionMain" key={index}>
                          <div className="invSectionTwo">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: course?.product_name,
                              }}
                            ></p>
                          </div>
                          <div className="invSectionOneExtra">
                            <p>${course?.selling_price} CAD</p>
                          </div>
                        </div>
                      );
                    })}

                    <div className="center_line_invoice mb-2"></div>
                    {/* // order?.credit_Point 
                    // && order?.credit_Points > 0  */}
                    {
                      true
                    ? (
                      <>
                        <div className="invSectionMain">
                          <div className="invSectionTwo">
                            <p>Sub Total</p>
                          </div>
                          <div className="invTotal_Amont">
                            <p>${_.toNumber(order?.subtotal).toFixed(2)} CAD</p>
                          </div>
                        </div>
                        <div className="invSectionMain">
                          <div className="invSectionTwo">
                            <p>Reward Points Redeem</p>
                          </div>
                          <div className="invTotal_Amont">
                            <p>
                              - $
                              {_.toNumber(
                                order?.credit_Points
                                  ? order?.credit_Points
                                      .toString()
                                      .replace("-", "")
                                  : 0
                              ).toFixed(2)}{" "}
                              CAD
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="invSectionMain">
                      <div className="invSectionTwo">
                        <p>Total</p>
                      </div>
                      <div className="invTotal_Amont">
                        <p>${order?.total} CAD</p>
                      </div>
                    </div>

                    <div className="invSectionMain mt-5">
                      <div className="invoiceTC">
                        <p>Terms and Conditions</p>
                        <p className="terms-conditions">
                          Sed molestie commodo faucibus. Praesent consectetur
                          ultrices sem. Sed a nisl leo.
                        </p>
                      </div>
                      <div className="invoiceTHK">
                        <img src="images/heart_invoice.png" alt="" />
                        <p>Thank You!</p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default InvoiceShowModal;
