import { useEffect } from "react";
import MyEventListingSection from "../../components/Events/MyEventListingSection";
import Page from "../../components/Page";


const MyEventsPage = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
},[])

  return (
    <Page>
      <MyEventListingSection />
    </Page>

  )


}

export default MyEventsPage;