import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getOfficeListReq, getOfficeListResClear, setCurrentView } from "../../redux/assignCourse/actions";
import { setCurrentFilterView } from "../../redux/reports/actions";
import { SessionKeys } from "../../utils/AppUtils";
const ChooseOfficePeople = (props) => {

	const {
		selectedOfficeList, setSelectedOfficeList,
		isMobile
	} = props;

	const dispatch = useDispatch();
	const officeListRes = useSelector((state) => state.assignCourse.officeList);
	const [searchInput, setSearchInput] = useState('')
	const [officeList, setOfficeList] = useState([])
	const [selectedCount, setSelectedCount] = useState(0);
	const [errorMsg, setErrorMsg] = useState('');

	useEffect(() => {
		dispatch(getOfficeListResClear())
	}, [])

	useEffect(() => {
		setSelectedCount(selectedOfficeList?.length)
	}, [selectedOfficeList])

	useEffect(() => {
		if (searchInput !== undefined && searchInput.length > 2)
			searchOffice();
		else setErrorMsg("");
	}, [searchInput])

	const searchOffice = () => {
		// console.log('officeListRes1')
		dispatch(getOfficeListReq({
			module: 'getOfficeListSearch',
			postData: {
				token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
				search: searchInput,
				type: 'report'
			}
		}))
	}

	useEffect(() => {
		// console.log('officeListRes: ', officeListRes)
		if (!_.isEmpty(officeListRes)) {
			if (officeListRes?.status === 'success') {
				setOfficeList(officeListRes?.payload?.payload)

			} else {
				setOfficeList([])
				if (!_.isEmpty(searchInput) && searchInput?.length > 2) {
					setErrorMsg("No result found for your search.");
				} else {
					setErrorMsg("");
				}
			}
		} else {
			setOfficeList([])
			setErrorMsg("");
		}
	}, [officeListRes])


	const selectItem = (index) => {
		let tempArr = _.cloneDeep(officeList);
		let selectedTempArray = _.cloneDeep(selectedOfficeList);
		selectedTempArray.push(tempArr[index])
		setSelectedOfficeList(selectedTempArray)
		tempArr.splice(index, 1);
		setOfficeList(tempArr)
	}

	const removeItem = (index) => {
		let selectedTempArray = _.cloneDeep(selectedOfficeList);
		let tempArr = _.cloneDeep(officeList);
		tempArr.push(selectedTempArray[index])
		setOfficeList(tempArr)
		selectedTempArray.splice(index, 1);
		setSelectedOfficeList(selectedTempArray)
	}


	return (
		<>
			<div className="chooseteams chooseppl">
				<div className="chooseteamate">
					<div className="headong d-flex justify-content-between"><h4>Choose People from Office</h4> </div>
					<div className="input-lear position-relative d-flex">
						<label for="peoplesearch" className="input__label_learning">Search</label>
						<input type="text" id="peoplesearch" name="peoplesearch" className="input_searchfield_search-cart " placeholder="Start searching"
							onChange={(evt) => {
								setSearchInput(evt.target.value)
							}} />
					</div>
					<p className="error-msg">{errorMsg}</p>
					<div className="search-team-result">
						{
							officeList?.map((item, index) => {
								return <div className="team-result-box">
									<div className="userteamname">{item?.office_name} <span className="peoplecount">{item?.user_count} people</span></div>
									<div className="addteammate" onClick={() => { selectItem(index) }}>+</div>
								</div>
							})
						}


					</div>
					<div className="teamselected">
						<div className="selectresilt">Selected Offices<strong>({selectedCount})</strong></div>
						<div className="search-team-result slectedteammates">
							{selectedOfficeList?.map((item, index) => {
								return <div className="team-result-box">
									<div className="userteamname">{item?.office_name} <span className="peoplecount">{item?.user_count} people</span></div>
									<div className="addteammate" onClick={() => {
										// console.log('removeItem');
										removeItem(index)
									}}>+</div>
								</div>
							})}

						</div>
					</div>
					<div className="buttonsteammate">
						<button type="button" className="btn btn-primary btn-block back-button" onClick={() => { dispatch(setCurrentFilterView(1)) }}>Back</button>
						<button type="button" className="btn btn-primary btn-block setup-button" onClick={() => { dispatch(setCurrentFilterView(1)) }}>Proceed</button>
					</div>
				</div>
			</div>
		</>

	)
}

export default ChooseOfficePeople;