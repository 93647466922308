import DatePicker from 'react-datepicker'
import ReportOfficeNameFilter from '../ReportOfficeNameFilter'


const ProfileFilterSearchSection = (props) => {

  const {
    startDate,setStartDate,endDate,
    setEndDate,clickCompleted,
    setClickCompleted,clickNotCompleted,
    setClickNotCompleted,getFilteredData,
    setOfficeIds
  } = props

  return (
    <>
      <section className="form-onboarding">
        <div className="container">
          <div className="edit_profile_input">
            <div className="row">

              <div className="col-md-5 required">
                <div className="input-text position-relative d-flex">
                  <label for="date_join" className="input__label" >Start Date</label>
                  <DatePicker selected={startDate} placeholderText="MM/DD/YYYY" className="input__field02" maxDate={new Date()} onChange={(date) => setStartDate(date)} />
                  <img src="/images/Cal.svg" width="28" height="28" alt="calendar" />
                </div>
                <div className="input-text position-relative d-flex">
                  <label for="date_join" className="input__label" >End Date</label>
                  <DatePicker selected={endDate} title={startDate==null?"Please select start date first":""} disabled={startDate==null?true:false} placeholderText="MM/DD/YYYY" className="input__field02" maxDate={new Date()} onChange={(date) => setEndDate(date)} />
                  <img src="/images/Cal.svg" width="28" height="28" alt="calendar" />
                </div>
              </div>
              <div className="col-md-3 required">
                <div className="status-updt form-group input-text">
                  <label>Status<span className="required"></span></label>
                  <div className="form-group input-text checkbox-field">
                    <input type="checkbox" name="learning-checkbox" className="on_checked"
                      checked={clickCompleted}
                      onChange={(ev) => { setClickCompleted(ev.target.checked) }} /><span>Completed</span>
                  </div>
                  <div className="form-group input-text checkbox-field">
                    <input type="checkbox" name="learning-checkbox" className="on_checked"
                      checked={clickNotCompleted}
                      onChange={(ev) => { setClickNotCompleted(ev.target.checked) }} /><span>Not Completed</span>
                  </div>

                </div>
              </div>
              <div className="col-md-4 btnbtmalign">
                <ReportOfficeNameFilter 
                  setOfficeIds={setOfficeIds}
                />
                <div className="edit_btn d-flex reportbtns">
                  <button className="btn-primary" onClick={() => {
                    getFilteredData(0)

                  }}>Create Report</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

    </>
  )
}

export default ProfileFilterSearchSection