import { all, fork } from "redux-saga/effects";

import authSaga from "./auth/saga";
import homeSaga from "./home/saga";
// import {userInfo,userUpdateInfo} from './user/saga'
import userSaga from "./user/saga";
import coursesSaga from "./courses/saga";
import productSaga from "./product/saga";
import checkoutSaga from "./checkout/saga";
import videoSaga from "./video/saga";
import articleSaga from "./article/saga";
import searchSaga from "./search/saga";
import orderSaga from "./orders/saga";
import cartSaga from "./Cart/saga";
import creditPointsSaga from "./creditPoints/saga";
import allEventsSaga from "./events/saga";
import certifiedSaga from "./certified/saga";
import membershipSaga from "./membership/saga";
import recommendationSaga from "./recommendation/saga";
import recommendationViewSaga from "./recommendationView/saga";
import reportSaga from "./reports/saga";
import rolePlaySaga from "./roleplay/saga";
import certificateURLSaga from "./certificateURL/saga";
import myNetworkSaga from "./myNetwork/saga";
import notificationSaga from "./notification/saga";
import notificationCountSaga from "./notification/saga";
import assignCourseSaga from "./assignCourse/saga"

function* rootSaga() {
  yield fork(authSaga);
  yield fork(homeSaga);
  yield fork(userSaga);
  yield fork(coursesSaga);
  yield fork(productSaga);
  yield fork(checkoutSaga);
  yield fork(videoSaga);
  yield fork(articleSaga);
  yield fork(searchSaga);
  yield fork(orderSaga);
  yield fork(cartSaga);
  yield fork(creditPointsSaga);
  yield fork(allEventsSaga);
  yield fork(certifiedSaga);
  yield fork(membershipSaga);
  yield fork(recommendationSaga);
  yield fork(recommendationViewSaga);
  yield fork(reportSaga);
  yield fork(rolePlaySaga);
  yield fork(certificateURLSaga);
  yield fork(myNetworkSaga);
  yield fork(notificationSaga);
  yield fork(notificationCountSaga);
  yield fork(assignCourseSaga);
}

export default rootSaga;
