import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Page from "../../components/Page";
import { getAllEvents, getEventDetails, getSessionList } from "../../redux/events/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import _ from "lodash";
import EventDetailSection from "../../components/Events/EventDetailSection";
import CONFIG from "../../utils/config";
import FooterNew from "../../components/FooterNew";
import { setUserPremium } from "../../redux/user/actions";
import { loginBrokerResponse } from "../../redux/auth/actions";


const EventDetailMobilePage = () => {


  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const key = params.get('key');
  const isPremium = params.get('isPremium');
  const userId = params.get('user_id');
  const username = params.get('username');

  const [isValidKey, setIsValidKey] = useState(false)

  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])

  useEffect(() => {
    if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
      localStorage.setItem(SessionKeys.API_TOKEN_BROKER, token);
      localStorage.setItem(SessionKeys.USERNAME, username);
      setIsValidKey(true)
      dispatch(loginBrokerResponse({
        payload: {
          user: {
            user_id: userId,
            username: username
          },
          apiToken: token
        }
      }))
      isPremium && dispatch(setUserPremium(true))
      clickStreamUser('event-detail-page-mobile', {
        userId: userId,
      })
    }
  }, [token, key, userId, dispatch])

  return (
    <>
      {isValidKey ?
        <>
          <EventDetailSection />
          <FooterNew />
        </>
        : 'invalid key'}
    </>
  )
}

export default EventDetailMobilePage;