import { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentFilterView } from "../../redux/reports/actions";
const GenerateaReport = (props) => {
  
  const {
    selectedTeamMatesList,
    selectedOfficeList,
    selectedCourseList,
    selectedManagerCoachList,
    selectedProviderList,
    routeLink,
    currentReportObj,
    setShow,
    selectedLpList,
    isMobile
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [isClose, setIsClose] = useState(true);

  const [courseTitle, setCourseTitle] = useState("Course");

  useEffect(() => {
    if (currentReportObj.id === 0 || currentReportObj.id === 1) {
      setCourseTitle("Course");
    } else if (currentReportObj.id === 2) {
      setCourseTitle("Learning path");
    } else if (currentReportObj.id === 4) {
      setCourseTitle("Onboarding");
    }
  }, [currentReportObj]);


  useEffect(() => {
    //console.log('officeList: ', selectedOfficeList)
  }, []);

  return (
    <>
      {isClose ? (
        <div className="createteamsOptions">
          <div className="chooseteamate">
            <div className="optionsd">Select an option below</div>
            {currentReportObj.id === 3 ? (
              ""
            ) : (currentReportObj.id === 2 || currentReportObj.id === 4) ?
              <p>
                Choose all people enrolled in{" "}
                <strong>
                  <span
                    className="chosoeselect cursor-pointer"
                    onClick={() => {
                      dispatch(setCurrentFilterView(2));
                    }}
                  >
                    {courseTitle}
                    {selectedLpList?.length > 0
                      ? "(" + selectedLpList.length + ")"
                      : ""}{" "}
                  </span>
                </strong>
              </p>
              :
              (
                <p>
                  Choose all people enrolled in{" "}
                  <strong>
                    <span
                      className="chosoeselect cursor-pointer"
                      onClick={() => {
                        dispatch(setCurrentFilterView(2));
                      }}
                    >
                      {courseTitle}
                      {selectedCourseList?.length > 0
                        ? "(" + selectedCourseList.length + ")"
                        : ""}{" "}
                    </span>
                  </strong>
                </p>
              )}
            <p>
              Choose all people from
              <strong>
                <span
                  className="chosoeselect cursor-pointer"
                  onClick={() => {
                    dispatch(setCurrentFilterView(3));
                  }}
                >
                  {" "}
                  Offices{" "}
                  {selectedOfficeList?.length > 0
                    ? "(" + selectedOfficeList.length + ")"
                    : ""}
                </span>
              </strong>
            </p>
            <p>
              Choose{" "}
              <strong>
                <span
                  className="chosoeselect cursor-pointer"
                  onClick={() => {
                    dispatch(setCurrentFilterView(4));
                  }}
                >
                  Learners{" "}
                  {selectedTeamMatesList?.length > 0
                    ? "(" + selectedTeamMatesList.length + ")"
                    : ""}
                </span>
              </strong>
            </p>
            {/* <p>
              Choose all agents reporting to{" "}
              <strong>
                <span className="chosoeselect" onClick={()=>{ dispatch(setCurrentFilterView(5))}} >Manager/Coach/Team Lead {selectedManagerCoachList?.length>0?'('+selectedManagerCoachList.length+')':''}</span>
              </strong>
      </p>*/}
            {currentReportObj?.id === 0 ? (
              <p>
                Choose all courses from{" "}
                <strong>
                  <span
                    className="chosoeselect"
                    onClick={() => {
                      dispatch(setCurrentFilterView(6));
                    }}
                  >
                    Provider{" "}
                    {selectedProviderList?.length > 0
                      ? "(" + selectedProviderList.length + ")"
                      : ""}{" "}
                  </span>
                </strong>
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="buttonsteammate">
            <button
              type="button"
              className="btn btn-primary btn-block back-button"
              onClick={() => setShow(false)}
            >
              Back
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              onClick={() => {
                history.push({
                  pathname: routeLink,
                  state: {
                    selectedTeamMatesList: { selectedTeamMatesList },
                    selectedOfficeList: { selectedOfficeList },
                    selectedCourseList: { selectedCourseList },
                    selectedProviderList: { selectedProviderList },
                    selectedLpList: { selectedLpList },
                  },
                });
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GenerateaReport;
