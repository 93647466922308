import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Photogrid from "react-facebook-photo-grid";
import { useDispatch, useSelector } from "react-redux";
import ReactRoundedImage from "react-rounded-image";
import { deleteCommentReq, deleteCommentResponseClear, deletePostReq, deletePostResponseClear, getActivityLike, getActivityLikeResponseClear, getAddComments, getAddCommentsResponseClear } from "../../redux/myNetwork/actions";
import { getDateTime, SessionKeys, timezoneArea } from "../../utils/AppUtils";
import Dropdown from 'react-bootstrap/Dropdown';
import { NavDropdown } from "react-bootstrap";
import ImageShowModal from "../modal/ImageShowModal";
import EditPostModal from "../modal/EditPostModal";
const PostList = (props) => {

    const { postList, getActivityPostList, fromPage, setIsLoading } = props;
    const dispatch = useDispatch();
    const authBroker = useSelector(state => state.auth.authBroker);
    var activityLikeRes = useSelector((state) => state.myNetwork.activityLikeRes);
    var addCommentsRes = useSelector((state) => state.myNetwork.addCommentsRes);
    var deleteCommentRes = useSelector((state) => state.myNetwork.deleteCommentRes);
    var deletePostRes = useSelector((state) => state.myNetwork.deletePostRes);
    const userDetailRes = useSelector(state => state.user.userBroker)

    const [postFeedList, setPostFeedList] = useState(null);
    //for post edit modal
    const [show, setShow] = useState(false);
    const [currPostId, setCurrPostId] = useState('');
    const [currEditContent, setCurrEditContent] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [currentClickPos, setCurrentClickPos] = useState(0);
    const [currentClickCmnt, setCurrentClickCmnt] = useState(0);
    const [currentClickSubCmnt, setCurrentClickSubCmnt] = useState(0);
    const [preImagesParent, setPreImagesParent] = useState([]);

    const userRole = useSelector((state) => state.user.role);
    //for coment edit modal
    const [isCmnt, setIsCmnt] = useState(false);

    useEffect(() => {
        // console.log('userDetailRes: ', userDetailRes)
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
            setUserDetails(userDetailRes?.payload?.payload)
        }
    }, [userDetailRes])

    useEffect(() => {
        if (fromPage === 'home') {
            setPostFeedList(postList.slice(0, 1))
        } else {
            setPostFeedList(postList)
        }
    }, [postList])

    useEffect(() => {
        dispatch(getActivityLikeResponseClear());
        dispatch(getAddCommentsResponseClear());
        dispatch(deleteCommentResponseClear())
        dispatch(deletePostResponseClear())
    }, [])

    const postLike = (position, activityId, isLike) => {
        dispatch(
            getActivityLike({
                module: "getActivityLike",
                postData: {
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    activity_id: activityId,
                    is_like: isLike,
                },
            })
        );

        let arr = _.cloneDeep(postFeedList)
        arr[position].is_like_by_me = isLike;
        let likeCount = arr[position].like;

        arr[position].like = isLike ? (likeCount + 1) : (likeCount - 1);
        setPostFeedList(arr)
    }



    useEffect(() => {
        // console.log('activityLikeRes: ',activityLikeRes)
        if (!_.isEmpty(activityLikeRes)) {
            if (activityLikeRes?.status === "success") {
                getActivityPostList();
                dispatch(getActivityLikeResponseClear());
            }
        }
    }, [activityLikeRes])

    const postComment = (position, cmntIndex, parentCommentId, activityId, commentText) => {
        // if(commentText?.trim() === ''){
        //     return;
        // }
        setIsLoading(true)
        setCurrentClickPos(position);
        setCurrentClickCmnt(cmntIndex);
        dispatch(
            getAddComments({
                module: "getAddComments",
                postData: {
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    activity_id: activityId,
                    parent_comments_id: parentCommentId,
                    comments: commentText,
                },
            })
        );


        if (cmntIndex === -1) {
            let arr = _.cloneDeep(postFeedList)
            arr[position].localComment = '';
            setPostFeedList(arr)
        } else {

            document.getElementById(parentCommentId).value = "";
            let arr = _.cloneDeep(postFeedList)
            arr[position].comment_details[cmntIndex].cmntReply = '';
            setPostFeedList(arr)


        }




    }

    useEffect(() => {
        if (!_.isEmpty(addCommentsRes)) {
            setIsLoading(false)
            if (addCommentsRes?.status === 'success') {
                getActivityPostList();
                addCommentLocal(addCommentsRes.payload.payload)
                dispatch(getAddCommentsResponseClear())
            }
        }
    }, [addCommentsRes])

    const addCommentLocal = (res) => {
        let tempObj = {
            comments_id: res?.comment_id,
            activity_id: res?.activity_id,
            user_details: res?.user_details,
            parent_comments_id: res?.parent_comments_id,
            comments: res?.comments,
            sub_comments: [],
            is_me_c: true,
            like_count: 0,
            is_like_by_me_c: false,
            created_date: res?.created_date
        }

        if (currentClickCmnt === -1) {
            let arr = _.cloneDeep(postFeedList)
            let commentList = arr[currentClickPos].comment_details.concat([tempObj])
            arr[currentClickPos].comment_details = commentList;
            arr[currentClickPos].cmntCount = arr[currentClickPos].cmntCount + 2;
            setPostFeedList(arr);
        } else {
            let arr = _.cloneDeep(postFeedList)
            let tempArr = _.isArray(arr[currentClickPos].comment_details[currentClickCmnt].sub_comments) ? arr[currentClickPos].comment_details[currentClickCmnt].sub_comments : [];
            let commentList = [tempObj].concat(tempArr);
            arr[currentClickPos].comment_details[currentClickCmnt].sub_comments = commentList;
            setPostFeedList(arr);
        }


    }


    const delComment = (postIndex, cmntIndex, subCmntIndex, cmntId) => {
        setIsLoading(true)
        setCurrentClickPos(postIndex);
        setCurrentClickCmnt(cmntIndex);
        setCurrentClickSubCmnt(subCmntIndex);
        dispatch(
            deleteCommentReq({
                module: "delComment",
                postData: {
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    comments_id: cmntId,
                },
            })
        );
    }

    useEffect(() => {
        if (!_.isEmpty(deleteCommentRes)) {
            setIsLoading(false)
            if (deleteCommentRes?.status === "success") {
                getActivityPostList()

                if (currentClickSubCmnt === -1) {
                    let arr = _.cloneDeep(postFeedList)
                    let commentList = arr[currentClickPos].comment_details
                    commentList.splice(currentClickCmnt, 1)
                    arr[currentClickPos].comment_details = commentList;
                    setPostFeedList(arr);
                } else {
                    let arr = _.cloneDeep(postFeedList)
                    let commentList = arr[currentClickPos].comment_details[currentClickCmnt].sub_comments
                    commentList.splice(currentClickSubCmnt, 1)
                    arr[currentClickPos].comment_details[currentClickCmnt].sub_comments = commentList;
                    setPostFeedList(arr);
                }
                dispatch(deleteCommentResponseClear())
            }
        }
    }, [deleteCommentRes])

    const deletePost = (activityId, pos) => {
        setIsLoading(true)
        setCurrentClickPos(pos)
        dispatch(
            deletePostReq({
                module: "delPost",
                postData: {
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    activity_id: activityId,
                },
            })
        );
    }

    useEffect(() => {
        if (!_.isEmpty(deletePostRes)) {
            setIsLoading(false)
            if (deletePostRes?.status === "success") {
                let arr = _.cloneDeep(postFeedList)
                arr.splice(currentClickPos, 1)
                setPostFeedList(arr);
                dispatch(deletePostResponseClear());
            }
        }
    }, [deletePostRes])

    const showEditModal = (pos, cmntPos, subCmntPos, activityId, postContent, isCmnt) => {
        setCurrPostId(activityId)
        setCurrEditContent(postContent)
        setIsCmnt(isCmnt)
        setCurrentClickPos(pos)
        setCurrentClickCmnt(cmntPos)
        setCurrentClickSubCmnt(subCmntPos)
        setShow(true);
    }

    return (
        <>
            {postFeedList !== null && postFeedList?.length === 0 ? <div className="firsttimepost">

                <div className="bgftu">

                    <span className="shapecircle">

                        <label className="mywallpostcon">WALL</label>

                    </span>

                </div>

                <p className="befonecont">Be the first one to post in your network.</p>

            </div> : postFeedList?.map((item, index) => {
                return <div className="postcommunity-sec" key={index}>
                    <div className="mgb ">
                        <div className=" d-flex  align-items-center profile_user-list">
                            <div className="profileuserimg">
                                <ReactRoundedImage
                                    image={item?.user_details?.user_profile !== undefined && item?.user_details?.user_profile !== '' ? item?.user_details?.user_profile : "/images/avatar.png"}
                                    roundedSize="0" imageWidth="58" imageHeight="58" />
                            </div>
                            <div className="d-flex justify-content-between mywalluserDetail w-100">
                                <div className="d-flex  flex-column">
                                    <h4>{item?.user_details?.first_name + ' ' + item?.user_details?.last_name} </h4>
                                    <p className="mb-0">
                                        {/* {item?.user_details?.main_designation},  */}
                                        {userRole === "realtor" ? <>
                                        Learner,
                                        </>: userRole === "manager" ? <>
                                        Manager,</> : <></>} {" "}
                                        {}
                                        {getDateTime(item?.created_date,userDetails)}
                                    </p>
                                </div>
                                {item?.is_me ? <NavDropdown className="profile-nav" title={
                                    <button className="button-reset">
                                        <svg
                                            id="More"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="linear-gradient"
                                                    y1="0.5"
                                                    x2="1"
                                                    y2="0.5"
                                                    gradientUnits="objectBoundingBox"
                                                >
                                                    <stop offset="0" stop-color="#00b7f1" />
                                                    <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                            </defs>
                                            <circle
                                                id="Ellipse_57"
                                                data-name="Ellipse 57"
                                                cx="15"
                                                cy="15"
                                                r="15"
                                                fill="url(#linear-gradient)"
                                            />
                                            <circle
                                                id="Ellipse_179"
                                                data-name="Ellipse 179"
                                                cx="13"
                                                cy="13"
                                                r="13"
                                                transform="translate(2 2)"
                                                fill="#fff"
                                            />
                                            <g
                                                id="Group_2342"
                                                data-name="Group 2342"
                                                transform="translate(-775 -762)"
                                            >
                                                <circle
                                                    id="Ellipse_183"
                                                    data-name="Ellipse 183"
                                                    cx="2"
                                                    cy="2"
                                                    r="2"
                                                    transform="translate(782 775)"
                                                    fill="var(--color-element-1)"
                                                />
                                                <circle
                                                    id="Ellipse_184"
                                                    data-name="Ellipse 184"
                                                    cx="2"
                                                    cy="2"
                                                    r="2"
                                                    transform="translate(788 775)"
                                                    fill="var(--color-element-1)"
                                                />
                                                <circle
                                                    id="Ellipse_185"
                                                    data-name="Ellipse 185"
                                                    cx="2"
                                                    cy="2"
                                                    r="2"
                                                    transform="translate(794 775)"
                                                    fill="var(--color-element-1)"
                                                />
                                            </g>
                                        </svg>
                                    </button>}>
                                    <NavDropdown.Item onClick={() => {
                                        setPreImagesParent(item.images)
                                        showEditModal(index, -1, -1, item?.activity_id, item?.post_content, 0)
                                    }}>Edit</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => {
                                        deletePost(item?.activity_id, index);
                                    }}>Delete</NavDropdown.Item>


                                </NavDropdown> : ""}
                            </div>
                        </div>
                    </div>

                    <section className="postbox-details">
                        <div className="profileext pt-3">
                            <p>
                                {item?.post_content}
                            </p>
                        </div>
                        {item?.images?.length > 0 ? <div className="profilImage pt-3">
                            {
                                // <img
                                // className="img-fluid"
                                // src="images/capturephoto.png"
                                // alt=""
                                // />
                                <div className="post-grid">
                                    <Photogrid
                                        images={item?.images} //required
                                        countFrom={2}
                                        
                                    // width={600} //optional according to your need
                                    // maxWidth={400} //optional according to your need
                                    ></Photogrid>
                                </div>
                            }
                        </div> : ""}
                        <div className="row pt-3">
                            <div className="col-md-2 d-flex icon_profile " title="Like">
                                <img
                                    className="heart_images cursor-pointer"
                                    src={item?.is_like_by_me ? "images/heart.png" : "images/heart-outline.png"}
                                    width="25"
                                    alt=""
                                    onClick={() => { postLike(index, item?.activity_id, item?.is_like_by_me ? false : true) }}
                                />
                                <p className="mb-0">{item?.like}</p>
                            </div>
                            <div className="col-md-2 d-flex icon_profile cursor-pointer" title="Comment" onClick={() => {
                                let arr = _.cloneDeep(postFeedList)
                                if (arr[index].isShowCmntView)
                                    arr[index].isShowCmntView = false;
                                else arr[index].isShowCmntView = true;
                                setPostFeedList(arr)
                            }}>
                                <img
                                    className="heart_images"
                                    src="images/Icon metro-bubbles.svg"
                                    width="25"
                                    alt=""
                                />
                                <p className="mb-0">{item?.comment}</p>
                            </div>
                        </div>
                    </section>

                    {item?.isShowCmntView ? <section className="Mywall_profile">
                        {/* Comment and Reply */}
                        {item?.comment_details?.length > 1 && !(item?.cmntCount >= item?.comment_details?.length) ? <div className="button-reset reply-button cursor-pointer col"
                            onClick={() => {
                                let arr = _.cloneDeep(postFeedList)
                                arr[index].cmntCount = arr[index].cmntCount + 2;
                                setPostFeedList(arr)
                            }} ><strong><u>Load More</u></strong>
                        </div> : ""}
                        {item?.comment_details?.length > 0 ?
                            item?.comment_details?.slice(0, item.cmntCount)?.map((cmnt, cmntIndex) => {
                                return <div className="comment-thread">
                                    <div open className="comment" id="comment-1">

                                        <div className="comnt-summary">
                                            <div className="comment-heading">
                                                <div className="commentrepavatar">
                                                    <ReactRoundedImage
                                                        image={cmnt?.user_details?.user_profile !== undefined && cmnt?.user_details?.user_profile !== '' ? cmnt?.user_details?.user_profile : "/images/avatar.png"}
                                                        roundedSize="0" imageWidth="32" imageHeight="32" />
                                                </div>
                                                <div className="comment-info">
                                                    <a href="#" className="comment-author">{cmnt?.user_details?.first_name + ' ' + cmnt?.user_details?.last_name}</a>
                                                    <p className="m-0">
                                                        {getDateTime(cmnt?.created_date,userDetails)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="comment-body">
                                            <p>
                                                {cmnt?.comments}
                                            </p>
                                            <div className="row fitContent">
                                                {cmnt.is_me_c ? <div onClick={() => {
                                                    showEditModal(index, cmntIndex, -1, cmnt?.comments_id, cmnt?.comments, 1)
                                                }} className="button-reset reply-button cursor-pointer col" ><strong><u>Edit</u></strong>
                                                </div> : ""}
                                                <div onClick={() => {
                                                    let arr = _.cloneDeep(postFeedList)
                                                    if (arr[index].comment_details[cmntIndex].isReplyShow)
                                                        arr[index].comment_details[cmntIndex].isReplyShow = false;
                                                    else arr[index].comment_details[cmntIndex].isReplyShow = true;
                                                    setPostFeedList(arr)

                                                }} className="button-reset reply-button cursor-pointer col" ><strong><u>Reply</u></strong>
                                                </div>
                                                {cmnt.is_me_c ? <div onClick={() => {
                                                    delComment(index, cmntIndex, -1, cmnt.comments_id)
                                                }} className="button-reset reply-button cursor-pointer col" ><strong><u>Delete</u></strong>
                                                </div> : ""}
                                            </div>
                                            {/* <button type="button">Flag</button> */}
                                            {cmnt?.isReplyShow ? <section className="reply-form initialhide" id="comment-1-reply-form">
                                                <div className="position-relative d-flex commenttorepbox">
                                                    <label for="password" className="input__label">Reply to comment</label>
                                                    <textarea className="input__field " rows="2"
                                                        id={cmnt.comments_id}
                                                        onChange={(evt) => {
                                                            let arr = _.cloneDeep(postFeedList)
                                                            arr[index].comment_details[cmntIndex].cmntReply = evt.target.value;
                                                            setPostFeedList(arr)

                                                        }}
                                                    ></textarea>
                                                </div>

                                                <button className="button-reset" type="submit" onClick={() => { postComment(index, cmntIndex, postFeedList[index].comment_details[cmntIndex].comments_id, postFeedList[index]?.activity_id, postFeedList[index].comment_details[cmntIndex].cmntReply); }}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        id="Post"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                    >
                                                        <defs>
                                                            <linearGradient
                                                                id="linear-gradient"
                                                                y1="0.5"
                                                                x2="1"
                                                                y2="0.5"
                                                                gradientUnits="objectBoundingBox"
                                                            >
                                                                <stop offset="0" stop-color="#00b7f1"></stop>
                                                                <stop offset="1" stop-color="#ed156e"></stop>
                                                            </linearGradient>
                                                            <clipPath id="clip-path">
                                                                <rect
                                                                    id="Rectangle_1976"
                                                                    data-name="Rectangle 1976"
                                                                    width="16.27"
                                                                    height="12.079"
                                                                    fill="var(--color-element-1)"
                                                                ></rect>
                                                            </clipPath>
                                                        </defs>
                                                        <circle
                                                            id="Ellipse_180"
                                                            data-name="Ellipse 180"
                                                            cx="15"
                                                            cy="15"
                                                            r="15"
                                                            fill="url(#linear-gradient)"
                                                        ></circle>
                                                        <circle
                                                            id="Ellipse_181"
                                                            data-name="Ellipse 181"
                                                            cx="13"
                                                            cy="13"
                                                            r="13"
                                                            transform="translate(2 2)"
                                                            fill="#fff"
                                                        ></circle>
                                                        <g
                                                            id="Group_1978"
                                                            data-name="Group 1978"
                                                            transform="translate(6.865 8.961)"
                                                        >
                                                            <path
                                                                id="Path_3107"
                                                                data-name="Path 3107"
                                                                d="M15.429,41.841l3.338-3.482L15.429,36Z"
                                                                transform="translate(-12.084 -29.763)"
                                                                fill="var(--color-element-1)"
                                                            ></path>
                                                            <g
                                                                id="Group_1977"
                                                                data-name="Group 1977"
                                                                transform="translate(0 0)"
                                                            >
                                                                <g
                                                                    id="Group_1976"
                                                                    data-name="Group 1976"
                                                                    clipPath="url(#clip-path)"
                                                                >
                                                                    <path
                                                                        id="Path_3108"
                                                                        data-name="Path 3108"
                                                                        d="M15.917.034.263,5.214c-.323.107-.353.345-.069.528L3.474,7.868,14.878.842,4.488,8.525l4.567,2.963a.579.579,0,0,0,.8-.153c.01-.014.019-.029.027-.044L16.19.371c.174-.29.05-.444-.273-.337"
                                                                        transform="translate(0 0)"
                                                                        fill="var(--color-element-1)"
                                                                        fillRule="evenodd"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                                <button onClick={() => {
                                                    let arr = _.cloneDeep(postFeedList)
                                                    arr[index].comment_details[cmntIndex].isReplyShow = false;
                                                    setPostFeedList(arr)
                                                }} className="button-reset cancel-replycom" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">

                                                        <defs>
                                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                                <stop offset="0" stop-color="#00b7f1"></stop>
                                                                <stop offset="1" stop-color="#ed156e"></stop>
                                                            </linearGradient>
                                                        </defs>
                                                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                                        <g id="Group_1344 prev" title="Move Back" data-name="Group 1344" transform="translate(15 9) rotate(90)">
                                                            <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                            <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                        </g>
                                                        <g id="Group_2376 next" title="Move Ahead" data-name="Group 2376" transform="translate(9 14.586)">
                                                            <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                            <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </section> : ""}
                                        </div>

                                        {cmnt?.sub_comments?.length > 0 ?
                                            cmnt.sub_comments.map((subCmnt, subCmntIndex) => {
                                                return <div className="replies">
                                                    <div open className="comment" id="comment-2">
                                                        {/* <a href="#comment-2" class="comment-border-link">
                                                            <span class="sr-only">Jump to comment-2</span>
                                            </a>*/}
                                                        <div className="repliebox">
                                                            <div className="comment-heading">
                                                                <div className="commentrepavatar">
                                                                    <ReactRoundedImage
                                                                        image={subCmnt?.user_details?.user_profile !== undefined && subCmnt?.user_details?.user_profile !== '' ? subCmnt?.user_details?.user_profile : "/images/avatar.png"}
                                                                        roundedSize="0" imageWidth="45" imageHeight="45" />
                                                                </div>
                                                                <div className="comment-info">
                                                                    <a href="#" className="comment-author">{subCmnt?.user_details?.first_name + ' ' + subCmnt?.user_details?.last_name}</a>
                                                                    <p className="m-0">
                                                                        {getDateTime(subCmnt?.created_date,userDetails)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="comment-body">
                                                            <p>
                                                                {subCmnt?.comments}
                                                            </p>
                                                            <div className="row fitContent">
                                                                {subCmnt.is_me_c ? <div onClick={() => {
                                                                    showEditModal(index, cmntIndex, subCmntIndex, subCmnt?.comments_id, subCmnt?.comments, 2)
                                                                }} className="button-reset reply-button cursor-pointer col" ><strong><u>Edit</u></strong>
                                                                </div> : ""}

                                                                {subCmnt.is_me_c ? <div onClick={() => {
                                                                    delComment(index, cmntIndex, subCmntIndex, subCmnt.comments_id)
                                                                }} className="button-reset reply-button cursor-pointer col" ><strong><u>Delete</u></strong>
                                                                </div> : ""}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            })
                                            : ""}
                                    </div>
                                </div>
                            })
                            : ""}
                        <div className={fromPage === "home" ? " d-flex justify-content-between align-items-center profileUserList allcon-wallpage-homepage" : " d-flex justify-content-between align-items-center profileUserList allcon-wallpage"}>
                            <ReactRoundedImage
                                image={authBroker?.payload?.user?.profile_img !== undefined && authBroker?.payload?.user?.profile_img !== '' ? authBroker?.payload?.user?.profile_img : "/images/avatar.png"}
                                roundedSize="0" imageWidth="48" imageHeight="48" />
                            <div className="input-text position-relative d-flex">
                                <label htmlFor="password" className="input__label">
                                    Comment
                                </label>
                                <textarea
                                    type="text"
                                    id="password"
                                    name="password"
                                    className="input__field "
                                    placeholder=" "
                                    value={item.localComment}
                                    onChange={(evt) => {

                                        let arr = _.cloneDeep(postFeedList)
                                        arr[index].localComment = evt.target.value;
                                        setPostFeedList(arr)

                                    }}
                                ></textarea>
                            </div>
                            {item?.localComment?.trim().length > 0 ? <div className="d-flex justify-content-end icon_LP">
                                <a >
                                    <button className="button-reset" onClick={() => { postComment(index, -1, '0', item?.activity_id, item.localComment.trim()); }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            id="Post"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="linear-gradient"
                                                    y1="0.5"
                                                    x2="1"
                                                    y2="0.5"
                                                    gradientUnits="objectBoundingBox"
                                                >
                                                    <stop offset="0" stop-color="#00b7f1"></stop>
                                                    <stop offset="1" stop-color="#ed156e"></stop>
                                                </linearGradient>
                                                <clipPath id="clip-path">
                                                    <rect
                                                        id="Rectangle_1976"
                                                        data-name="Rectangle 1976"
                                                        width="16.27"
                                                        height="12.079"
                                                        fill="var(--color-element-1)"
                                                    ></rect>
                                                </clipPath>
                                            </defs>
                                            <circle
                                                id="Ellipse_180"
                                                data-name="Ellipse 180"
                                                cx="15"
                                                cy="15"
                                                r="15"
                                                fill="url(#linear-gradient)"
                                            ></circle>
                                            <circle
                                                id="Ellipse_181"
                                                data-name="Ellipse 181"
                                                cx="13"
                                                cy="13"
                                                r="13"
                                                transform="translate(2 2)"
                                                fill="#fff"
                                            ></circle>
                                            <g
                                                id="Group_1978"
                                                data-name="Group 1978"
                                                transform="translate(6.865 8.961)"
                                            >
                                                <path
                                                    id="Path_3107"
                                                    data-name="Path 3107"
                                                    d="M15.429,41.841l3.338-3.482L15.429,36Z"
                                                    transform="translate(-12.084 -29.763)"
                                                    fill="var(--color-element-1)"
                                                ></path>
                                                <g
                                                    id="Group_1977"
                                                    data-name="Group 1977"
                                                    transform="translate(0 0)"
                                                >
                                                    <g
                                                        id="Group_1976"
                                                        data-name="Group 1976"
                                                        clipPath="url(#clip-path)"
                                                    >
                                                        <path
                                                            id="Path_3108"
                                                            data-name="Path 3108"
                                                            d="M15.917.034.263,5.214c-.323.107-.353.345-.069.528L3.474,7.868,14.878.842,4.488,8.525l4.567,2.963a.579.579,0,0,0,.8-.153c.01-.014.019-.029.027-.044L16.19.371c.174-.29.05-.444-.273-.337"
                                                            transform="translate(0 0)"
                                                            fill="var(--color-element-1)"
                                                            fillRule="evenodd"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </a>
                            </div> : ""}
                        </div>
                        {/* End Comment and Reply */}
                    </section> : ""}
                </div>
            })

            }
            <EditPostModal isCmnt={isCmnt}
                show={show}
                setShow={setShow}
                currPostId={currPostId}
                currEditContent={currEditContent}
                currentClickPos={currentClickPos}
                currentClickCmnt={currentClickCmnt}
                currentClickSubCmnt={currentClickSubCmnt}
                setPostFeedList={setPostFeedList}
                postFeedList={postFeedList}
                fromPage={fromPage}
                preImagesParent={preImagesParent} />
        </>
    )
}
export default PostList;