import { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import CONFIG from "../../utils/config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  orderPlacedResponse,
  sendOrderPlace,
  sendOrderPlaceClear,
  zeroSendOrder,
} from "../../redux/checkout/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { myCart } from "../../redux/Cart/actions";
import Spinner from "react-bootstrap/Spinner";
import ConfirmModal from "../modal/ConfirmModal";
import {
  getEnrollBrokerSession,
  setEnrollBrokerSessionClear,
} from "../../redux/events/actions";
import Country from "../../../src/data/country.json";
import {
  sendMembershipOrderPlace,
  sendMembershipOrderPlaceClear,
  setIsMembershipSucceed,
} from "../../redux/membership/actions";
import { setUserPremium } from "../../redux/user/actions";
import { isAndroid, isIOS } from "react-device-detect";

const StripeCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const type = params.get("type");
  const product_id = params.get("product_id");
  const redeempoints = params.get("redeempoints");
  const auto = params.get("auto");

  const sendOrderPlaced = useSelector((state) => state.checkout.sendOrderPlace);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const userDetailBrokerData = useSelector((state) => state.user.userBroker);
  const enrollSessionData = useSelector(
    (state) => state.events.setEnrollBrokerSession
  );
  const sendMembershipOrder = useSelector(
    (state) => state.membership.membershipOrderPlace
  );

  const [clientSecret, setClientSecret] = useState("");
  const [orderId, setOrderId] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [showPaymentElement, setShowPaymentElement] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardCVVError, setCardCVVError] = useState("");
  const [cardExpError, setCardExpError] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [enrollData, setEnrollData] = useState({});
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "CA",
  });

  const stripeFormSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    addressLine1: Yup.string().required("Please enter an address"),
    addressLine2: Yup.string().optional(),
    city: Yup.string().required("Please select a city"),
    state: Yup.string().required("Please select a state"),
    zip: Yup.string()
      // .matches(
      //   /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      //   "Invalid pincode"
      // )
      .required("Please enter a pincode"),
    country: Yup.string().required("Please select a country"),
  });

  useEffect(() => {
    // console.log('PRODUCT ID', product_id, props.paymentData)
    if (!_.isEmpty(props.paymentData)) {
      setShowForm(false);
      setClientSecret(props.paymentData.clientSecret);
      setOrderId(props.paymentData.orderId);
      setItemCount(props.paymentData.item);
    }
  }, [props.paymentData]);

  useEffect(() => {
    if (!_.isEmpty(enrollSessionData)) {
      setEnrollData(enrollSessionData);
      dispatch(setEnrollBrokerSessionClear());
    }
  }, [enrollSessionData]);

  useEffect(() => {
    // console.log('payment form error', props.showPaymentFormError)
    if (props.showPaymentFormError) {
      setShowForm(true);
    }
  }, [props.showPaymentFormError]);

  useEffect(() => {
    // console.log('sendOrderPlaced', sendOrderPlaced)
    if (!_.isEmpty(sendOrderPlaced)) {
      if (sendOrderPlaced.status === "success") {
        const data = sendOrderPlaced.payload.payload;
        setIsLoading(false);
        dispatch(
          myCart({
            module: "myCart",
            email: authBroker?.payload?.user?.email,
          })
        );
        clickStreamUser("order-placed-succesfully", {
          amount: props.paymentData.amount,
          itemCount: itemCount,
          userId: authBroker?.payload?.user.user_id,
        });
        if (type === "event") {
          if (!_.isEmpty(enrollData)) {
            dispatch(
              getEnrollBrokerSession({
                module: "enrollBrokerSession",
                postData: enrollData.postData,
              })
            );
          }
        }
        if (isAndroid || isIOS) {
          try {
            // alert("Window CallbackHanlder");
            if (window.callbackHandler != undefined) {
              window.callbackHandler.postMessage("orderplaced");
            }
            if (window.webkit != undefined) {
              if (window.webkit.messageHandlers.callbackHandler != undefined) {
                window.webkit.messageHandlers.callbackHandler.postMessage(
                  "orderplaced"
                );
              }
            }
            setTimeout(() => {
              if (!_.isEmpty(sendOrderPlaced.payload)) {
                history.push(
                  `/orders?orderNumber=${data?.order_id}&orderStatus=succeeded&itemCount=${itemCount}`
                );
              } else {
                history.push(
                  `/orders?orderStatus=succeeded&itemCount=${itemCount}`
                );
              }
            }, 500)
          } catch (err) {
            // alert("Window CallbackHanlder Err");
            // console.log('The native context does not exist yet');
            if (!_.isEmpty(sendOrderPlaced.payload)) {
              history.push(
                `/orders?orderNumber=${data?.order_id}&orderStatus=succeeded&itemCount=${itemCount}`
              );
            } else {
              history.push(
                `/orders?orderStatus=succeeded&itemCount=${itemCount}`
              );
            }
          }
        } else {
          if (!_.isEmpty(sendOrderPlaced.payload)) {
            history.push(
              `/orders?orderNumber=${data?.order_id}&orderStatus=succeeded&itemCount=${itemCount}`
            );
          } else {
            history.push(
              `/orders?orderStatus=succeeded&itemCount=${itemCount}`
            );
          }
        }
      }
      dispatch(sendOrderPlaceClear());
    }
  }, [sendOrderPlaced]);

  useEffect(() => {
    if (!_.isEmpty(sendMembershipOrder)) {
      if (sendMembershipOrder.status === "success") {
        const data = sendMembershipOrder.payload.payload;
        setIsLoading(false);
        clickStreamUser("order-placed-succesfully", {
          amount: props.paymentData.amount,
          itemCount: itemCount,
          userId: authBroker?.payload?.user.user_id,
        });
        if (isAndroid || isIOS) {
          try {
            alert("Window CallbackHanlder");
            if (window.callbackHandler != undefined) {
              window.callbackHandler.postMessage("orderplaced-membership");
            }
            if (window.webkit != undefined) {
              if (window.webkit.messageHandlers.callbackHandler != undefined) {
                window.webkit.messageHandlers.callbackHandler.postMessage(
                  "orderplaced-membership"
                );
              }
            }
            setTimeout(() => {
              history.push(`/home`);
            }, 500)
          } catch (err) {
            // alert("Window CallbackHanlder Err");
            // console.log('The native context does not exist yet');
            history.push(`/home`);
          }
        } else {
          history.push(`/home`);
        }

        dispatch(setUserPremium(true));
        dispatch(setIsMembershipSucceed());
      }
      dispatch(sendMembershipOrderPlaceClear());
    }
  }, [sendMembershipOrder]);

  useEffect(() => {
    // console.log('User Detail Broker', userDetailBrokerData)
    // console.log('Country', Country)
    if (!_.isEmpty(userDetailBrokerData)) {
      if (userDetailBrokerData.status === "success") {
        const userData = userDetailBrokerData.payload.payload;
        // console.log("User", userData)
        setInitialValues({
          firstName: userData.firstname,
          lastName: userData.lastname,
          addressLine1: "",
          addressLine2: "",
          city: userData.office_city,
          state: userData.office_province,
          zip: "",
          country: "CA",
        });
      }
    }
  }, [userDetailBrokerData]);

  const renderAlertCard = () => {
    return (
      <div
        className={`alert-card d-flex justify-content-center ${confirmModal ? "" : "d-none"
          }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </svg>
            <p className="m-0">{successMsg}</p>
          </div>
          <div className="view-cart-icon">
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setConfirmModal(false);
                setSuccessMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const renderBillingInformation = (formik) => {
    return (
      <div>
        <div className="bill-info">
          <p className="number">1</p>
          <p>Billing Information</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-text ">
              <label htmlFor="addressLine1" className="input__label">
                Address 1<span>*</span>
              </label>
              <input
                type="text"
                id="addressLine1"
                name="addressLine1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="input__field"
                placeholder="Enter address line 1"
              />
              <span className="error-message">
                {formik.errors.addressLine1 ? formik.errors.addressLine1 : ""}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-text ">
              <label htmlFor="addressLine2" className="input__label">
                Address 2
              </label>
              <input
                type="text"
                id="addressLine2"
                name="addressLine2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="input__field"
                placeholder="Enter address line 2"
              />
              <span className="error-message">
                {formik.errors.addressLine2 ? formik.errors.addressLine2 : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input-text ">
              <label htmlFor="city" className="input__label">
                City<span>*</span>
              </label>
              <input
                type="text"
                id="city"
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                className="input__field"
                placeholder="Enter city"
              />
              <span className="error-message">
                {formik.errors.city ? formik.errors.city : ""}
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-text ">
              <label htmlFor="state" className="input__label">
                Province<span>*</span>
              </label>
              {/* <select type="text" id="state" name="state" 
                            className="form-select input_searchfield" placeholder="Enter state">
                            </select> */}
              <input
                type="text"
                id="state"
                name="state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                className="input__field"
                placeholder="Enter state"
              />
              <span className="error-message">
                {formik.errors.state ? formik.errors.state : ""}
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-text ">
              <label htmlFor="zip" className="input__label">
                Postal Code<span>*</span>
              </label>
              <input
                type="text"
                id="zip"
                name="zip"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="input__field"
                placeholder="Enter postalcode"
              />
              <span className="error-message">
                {formik.errors.zip ? formik.errors.zip : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-text ">
              <label htmlFor="country" className="input__label">
                Country<span>*</span>
              </label>
              <select
                type="text"
                id="country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-select input_searchfield"
                placeholder=""
              >
                <option value="">Select Country</option>
                {/* <option value="US">United States</option>
                                <option value="CA">Canada</option> */}
                {Country.map((country, index) => {
                  return (
                    <option key={index} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
              <span className="error-message">
                {formik.errors.country ? formik.errors.country : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCardInformation = (formik) => {
    return (
      <>
        <div className="bill-info payment-info">
          <p className="number">2</p>
          <p>Payment Information</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-text ">
              <label htmlFor="firstName" className="input__label">
                First Name<span>*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                className="input__field"
                placeholder="Enter first name"
              />
              <span className="error-message">
                {formik.errors.firstName ? formik.errors.firstName : ""}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-text ">
              <label htmlFor="lastName" className="input__label">
                Last Name<span>*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className="input__field"
                placeholder="Enter last name"
              />
              <span className="error-message">
                {formik.errors.lastName ? formik.errors.lastName : ""}
              </span>
            </div>
          </div>
        </div>
        {auto == "false" ? (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="input-text ">
                  <label htmlFor="Card Number" className="input__label">
                    Card Number<span>*</span>
                  </label>
                  <div className="input__field stripe_field">
                    <CardNumberElement />
                  </div>
                  <span className="error-message">{cardNumberError}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-text ">
                  <label htmlFor="CVV" className="input__label">
                    CVC<span>*</span>
                  </label>
                  <div className="input__field stripe_field">
                    <CardCvcElement />
                  </div>
                  <span className="error-message">{cardCVVError}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-text ">
                  <label htmlFor="Expiration Month" className="input__label">
                    Expiration Month / Year<span>*</span>
                  </label>
                  <div className="input__field stripe_field">
                    <CardExpiryElement />
                  </div>
                  <span className="error-message">{cardExpError}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/* <div className="col-12 form-check pay_check">
                <input className="form-check-input" type="checkbox" value="" id
                onChange={formik.handleChange}
                            onBlur={formik.handleBlur}="flexCheckDefault" />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    Save Credit card information for future use
                <span>*</span></label>
            </div> */}
      </>
    );
  };

  const handleSubmit = async (values, event) => {
    setErrorMsg("");
    setSuccessMsg("");
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      // console.log('not loaded')
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // console.log(elements, values)
    clickStreamUser("submit-btn-clicked_payment_form", {
      amount: props.paymentData.amount,
      itemCount: itemCount,
      userId: authBroker?.payload?.user.user_id,
    });
    // return;
    // const result = await stripe.confirmPayment({
    //     //`Elements` instance that was used to create the Payment Element
    //     elements,
    //     confirmParams: {
    //         return_url: `${CONFIG.STRIPE_RETURN_URL}\order-confirmation`,
    //     },
    // });
    if (!clientSecret && auto == "false") {
      setErrorMsg("Error processing payment, please try again later.");
      return;
    }
    if (auto != "false") {
      dispatch(
        zeroSendOrder({
          module: "zeroSendOrderResponse",
          postData: {
            first_name: values.firstName,
            last_name: values.lastName,
            address_1: values.addressLine1,
            address_2: values.addressLine2,
            city: values.city,
            state: values.state,
            postcode: values.zip,
            country: values.country,
            company: "",
            phone: "",
            order_status: "completed",
            email: authBroker.payload.user.email,
            token: "",
            user_token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            credit_points: redeempoints === 'true' ? true : false
          },
        })
      );
    } else {
      const result = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${values.firstName} ${values.lastName}`,
              address: {
                line1: values.addressLine1,
                line2: values.addressLine2,
                city: values.city,
                state: values.state,
                postal_code: values.zip,
                country: values.country,
              },
            },
          },
          return_url: `${CONFIG.STRIPE_RETURN_URL}/orders`,
        },
        {
          handleActions: true,
        }
      );

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        // console.log(result.error.message);
        setErrorMsg(result.error.message);
        setIsLoading(false);
      } else {
        // console.log(result.paymentIntent)
        if (result.paymentIntent.status === "succeeded") {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          // console.log('success')
          window.scrollTo(0, 0);
          clickStreamUser("received-success-from-stripe", {
            userId: authBroker?.payload?.user.user_id,
            amount: props.paymentData.amount,
            // orderId: props.paymentData.orderId,
          });
          dispatch(orderPlacedResponse(result.paymentIntent));
          if (props.paymentData.isSubscription) {
            dispatch(
              sendMembershipOrderPlace({
                module: "get_membership_checkout",
                postData: {
                  email: authBroker?.payload?.user.email,
                  product_id: _.toInteger(product_id),
                  response: "success",
                  subscription_id: props.paymentData.paymentToken,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  address_1: values.addressLine1,
                  address_2: values.addressLine2,
                  city: values.city,
                  state: values.state,
                  postcode: values.zip,
                  country: values.country,
                  company: "",
                  phone: "",
                  email: authBroker.payload.user.email,
                },
              })
            );
            dispatch(setUserPremium(true));
          } else {
            dispatch(
              sendOrderPlace({
                module: "sendOrderResponse",
                postData: {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  address_1: values.addressLine1,
                  address_2: values.addressLine2,
                  city: values.city,
                  state: values.state,
                  postcode: values.zip,
                  country: values.country,
                  company: "",
                  phone: "",
                  order_status: "completed",
                  email: authBroker.payload.user.email,
                  token: props.paymentData.paymentToken,
                  user_token: localStorage.getItem(
                    SessionKeys.API_TOKEN_BROKER
                  ),
                },
              })
            );
          }
          setSuccessMsg("Payment successful, generating order please wait...");
          setConfirmModal(true);

          // window.location.href = `${CONFIG.STRIPE_RETURN_URL}/order-confirmation`
        } else if (result.paymentIntent.status === "error") {
          setErrorMsg("Payment Failed");
          // dispatch(sendOrderPlace({
          //     module: 'sendOrderResponse',
          //     orderId: orderId,
          //     orderStatus: 'failed',
          // }))
          setIsLoading(false);
        } else {
          setErrorMsg(
            "Payment Processing is taking time, check later for order status"
          );
          // dispatch(sendOrderPlace({
          //     module: 'sendOrderResponse',
          //     orderId: orderId,
          //     orderStatus: 'processing',
          // }))
          setIsLoading(false);
        }
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  };

  return (
    <>
      {renderAlertCard()}
      {/* {
            clientSecret ?  */}
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={stripeFormSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formik) => {
            const { handleSubmit } = formik;

            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                {renderBillingInformation(formik)}
                {/* <CardElement /> */}
                {renderCardInformation(formik)}
                <p className="submit_btn payment_btn">
                  <button
                    type={`${isLoading ? "button" : "submit"}`}
                    className="pay_submit btn-primary"
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="primary"
                      />
                    ) : (
                      "Pay Now"
                    )}
                  </button>
                </p>
                <span className="color-red">{errorMsg}</span>
                {/* <span>{successMsg}</span> */}
              </form>
            );
          }}
        </Formik>
        {/* <ConfirmModal
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        showModalMsg={successMsg}
                        isConfirm={false}
                        confirmId={''}
                        confirmAction={function () { }}
                    /> */}
      </>
      {/* // : 'Payment Form is loading....'  
            // // !showForm ? 
            // // : 'Error loading payment form, please refresh the page'
        } */}
    </>
  );
};

export default StripeCheckoutForm;
