/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import Chart from "react-apexcharts";

let themeColors = ["#ff6384", "#36a2eb", "#ffcd56", "#ff9f40", "#4bc0c0"];

const RadialBarChart = (props) => {
  const [options, setOptions] = useState({
    chart: {
      type: "radialBar",
    },
    dataLabels: {
      enabled: false,
    },
    colors: props.themeColors ? props.themeColors : themeColors,
    legend: {
      itemMargin: {
        horizontal: 2,
      },
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 350,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: props.legends ? props.legends : [],
  });
  const [series, setSeries] = useState(props.series ? props.series : []);

  useEffect(() => {
    setSeries(props.series);
    setOptions({
      chart: {
        type: "radialBar",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, w }) {
          // console.log(w.config.series[seriesIndex])
          return w.config.series[seriesIndex];
        },
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            offset: 0,
          },
          customScale: 1,
          expandOnClick: true,
        },
      },
      colors: props.themeColors ? props.themeColors : themeColors,
      legend: {
        itemMargin: {
          horizontal: 2,
        },
        position: "right",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      total: {
        fontSize: "1rem",
      },
      labels: props.legends,
    });
    // console.log(props)
  }, [props]);

  return (
    <Chart
      options={options}
      series={series}
      type="radialBar"
      height={700}
      width={800}
    />
  );
};

export default RadialBarChart;
