
import Page from "../../components/Page";
import RolePlayHeadingSection from "../../components/RolePlay/RolePlayHeadingSection";
import React, { createRef, useEffect, useState } from "react";
import Slider from "react-slick";
import PrevArrow from "../../components/LearningPaths/PrevArrow";
import NextArrow from "../../components/LearningPaths/NextArrow";

const ReviewsRolePlays = () => {
                 
    const sliderRef = createRef();
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      dots: true,
      arrows: true,
      autoplaySpeed: 2000,
      infinite: false,
      centerMode: false,
      swipe: false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const [isLoading, setIsLoading] = useState(true)

    return (
        <>
            
            <Page>
                    <RolePlayHeadingSection page="review"/>
                    <div className="review-role-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="row boxes">
                                        <div className="col-md-4">
                                            <div className="reviewbox-content">
                                                <div className="lefttext">All</div>
                                                <div className="counttext">8</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="reviewbox-content">
                                                <div className="lefttext">To Review</div>
                                                <div className="counttext">8</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="reviewbox-content clicked">
                                                <div className="lefttext">Completed</div>
                                                <div className="counttext">8</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="reviews-data-table table-report-layout">
                        <div className="container">
                             <div className="row">
                                <div className="col-md-5 search__input">
                                    <div className="input-lear position-relative d-flex">
                                    <label for="password" className="input__label_learning">Search</label>
                                    <input type="text" id="password" name="password" className="input_searchfield_search-cart " placeholder=" "/>
                                    <a href="#">
                                            <button className="button-reset">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                id="Find"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 30 30"
                                            >
                                                <defs>
                                                <linearGradient
                                                    id="linear-gradient"
                                                    y1="0.5"
                                                    x2="1"
                                                    y2="0.5"
                                                    gradientUnits="objectBoundingBox"
                                                >
                                                    <stop offset="0" stop-color="#00b7f1" />
                                                    <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                                </defs>
                                                <circle
                                                id="Ellipse_191"
                                                data-name="Ellipse 191"
                                                cx="15"
                                                cy="15"
                                                r="15"
                                                fill="url(#linear-gradient)"
                                                />
                                                <circle
                                                id="Ellipse_192"
                                                data-name="Ellipse 192"
                                                cx="13"
                                                cy="13"
                                                r="13"
                                                transform="translate(2 2)"
                                                fill="#fff"
                                                />
                                                <path
                                                id="Icon_awesome-search"
                                                data-name="Icon awesome-search"
                                                d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                                                transform="translate(7.715 7.714)"
                                                fill="var(--color-element-1)"
                                                />
                                            </svg>
                                            </button>
                                        </a>
                                        </div>
                                        </div>
                                    <div className="col-7">
                                    
            <button className="button-reset downbtnsreport">
            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Find"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            >
                            <defs>
                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#00b7f1" />
                                <stop offset="1" stop-color="#ed156e" />
                                </linearGradient>
                            </defs>
                            <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                            <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                            <path id="file-chart" d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z" transform="translate(5.342 5.927)" fill="var(--color-element-1)"/>
                            </svg>
                                            </button>
                                    </div>
                                        </div>
                        <div className="row list-coach report-table-rsp">
                        <div className="col-lg-12 col-md-12">
                            <div className="table-responsive1">
                                <Slider ref={sliderRef} {...settings}>
                                <div className="tablebox">
                                    <table className="table mb-0 office_list table-striped">
                                    <thead>
                                        <tr>
                                        <th className="office-province">
                                            Title and Category
                                        </th>
                                        <th className="office-province">
                                            Participant
                                        </th>
                                        <th className="office-province">
                                            Date Submitted
                                        </th>
                                        <th className="office-province">
                                            Feedback Score
                                        </th>
                                       
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                            <tr className="addBGcustomw">
                                                <td>
                                                    <div className="d-flex vidollirole">
                                                        <div className="videoimgtable"><img src="/images/review-roleplay.jpg" width="80" height="44"/></div>
                                                        <div className="text-box">
                                                            <p className="titlebox-vid"><strong>Lorem Ipsum Dolor Sit Amet ligula</strong></p>
                                                            <p className="categorytitle">Overcoming Objections</p>
                                                        </div>
                                                    </div>
                                               
                                                </td>
                                                <td>
                                                    Brandon Foster
                                                </td>
                                                <td>22 Oct 2022</td>
                                                <td><strong>4</strong></td>
                                            </tr>
                                            <tr className="addBGcustomw">
                                                <td>
                                                    <div className="d-flex vidollirole">
                                                        <div className="videoimgtable"><img src="/images/review-roleplay.jpg" width="80" height="44"/></div>
                                                        <div className="text-box">
                                                            <p className="titlebox-vid"><strong>Lorem Ipsum Dolor Sit Amet ligula</strong></p>
                                                            <p className="categorytitle">Overcoming Objections</p>
                                                        </div>
                                                    </div>
                                               
                                                </td>
                                                <td>
                                                    Brandon Foster
                                                </td>
                                                <td>22 Oct 2022</td>
                                                <td><strong>4</strong></td>
                                            </tr>
                                    </tbody>
                                    </table>
                                </div>
                                <div className="tablebox">
                                    <table className="table mb-0 office_list table-striped">
                                    <thead>
                                        <tr>
                                        <th className="office-province">
                                            Title and Category
                                        </th>
                                        <th className="office-province">
                                            Participant
                                        </th>
                                        <th className="office-province">
                                            Date Submitted
                                        </th>
                                        <th className="office-province">
                                            Feedback Score
                                        </th>
                                       
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                            <tr className="addBGcustomw">
                                                <td>
                                                    <div className="d-flex vidollirole">
                                                        <div className="videoimgtable"><img src="/images/review-roleplay.jpg" width="80" height="44"/></div>
                                                        <div className="text-box">
                                                            <p className="titlebox-vid"><strong>Lorem Ipsum Dolor Sit Amet ligula</strong></p>
                                                            <p className="categorytitle">Overcoming Objections</p>
                                                        </div>
                                                    </div>
                                               
                                                </td>
                                                <td>
                                                    Brandon Foster
                                                </td>
                                                <td>22 Oct 2022</td>
                                                <td><strong>4</strong></td>
                                            </tr>
                                            <tr className="addBGcustomw">
                                                <td>
                                                    <div className="d-flex vidollirole">
                                                        <div className="videoimgtable"><img src="/images/review-roleplay.jpg" width="80" height="44"/></div>
                                                        <div className="text-box">
                                                            <p className="titlebox-vid"><strong>Lorem Ipsum Dolor Sit Amet ligula</strong></p>
                                                            <p className="categorytitle">Overcoming Objections</p>
                                                        </div>
                                                    </div>
                                               
                                                </td>
                                                <td>
                                                    Brandon Foster
                                                </td>
                                                <td>22 Oct 2022</td>
                                                <td><strong>4</strong></td>
                                            </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </Slider>
                                <div className="table-repost-de">
                                Showing 1-11 of 42
                                </div>
                            </div>
                            
                            </div>
                        </div>
                </div>
                    </section>

            </Page>
        </>
    )
}

export default ReviewsRolePlays