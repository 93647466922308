import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
const ConfirmCheckListModal = ({ show, handleClose, showMesg, setIsLoadingPopuUp }) => {

    useEffect(() => {
    }, [showMesg])
    return (
        <>
            <Modal show={show} onHide={handleClose}
                size="500px"
                aria-labelledby="contained-modal-title-vcenter"
                className="alert-modal" centered>
                {/* <Modal.Header closeButton className="modal-header">
                    <Modal.Title>Favourite Delete Alert</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="modal-body" style={{ marginLeft: "129px" }}>{showMesg}</Modal.Body>
                <Button variant="secondary" className="confirm" style={{
                    width: "100px", marginLeft: "183px", height: "42px",
                    marginBottom: "10px"
                }} onClick={() => {
                    handleClose()
                    setIsLoadingPopuUp(true)
                }
                }>
                    Ok
                </Button>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}
export default ConfirmCheckListModal;