import types from './types'


// userDetails
export const userDetail = (payload) => ({
  type: types.USER_DETAIL, payload
})
export const userDetailResponse = (payload) => (
  {
    type: types.USER_DETAIL_RESPONSE, payload
  })
export const userDetailBroker = (payload) => ({
  type: types.USER_DETAIL_BROKER, payload
})
export const userDetailBrokerResponse = (payload) => (
  {
    type: types.USER_DETAIL_BROKER_RESPONSE, payload
  })
export const userDetailBrokerClear = () => ({ type: types.USER_DETAIL_BROKER_CLEAR })

export const userDetailIframeUser = (payload) => ({
  type: types.USER_DETAIL_IFRAME_USER, payload
})
export const userDetailIframeUserResponse = (payload) => (
  {
    type: types.USER_DETAIL_IFRAME_USER_RESPONSE, payload
  })
export const userDetailIframeUserClear = () => ({ type: types.USER_DETAIL_IFRAME_USER_CLEAR })


export const userUpdate = (payload) => ({
  type: types.USER_UPDATE, payload
})

export const userUpdateResponse = (payload) => ({
  type: types.USER_UPDATE_RESPONSE, payload
})

export const userUpdateClear = () => ({
  type: types.USER_UPDATE_CLEAR
})

export const userSelectUser = (payload) => ({
  type: types.SELECT_USER, payload
})

export const selectedTeamLead = (payload) => ({
  type: types.SELECTED_TEAM_LEAD, payload
})

export const selectedMentor = (payload) => ({
  type: types.SELECTED_MENTOR, payload
})

export const selectedCoach = (payload) => ({
  type: types.SELECTED_COACH, payload
})

export const searchUser = (payload) => ({
  type: types.SEARCH_USER_REQ, payload
})

export const searchUserResponse = (payload) => ({
  type: types.SEARCH_USER_RES, payload
})


export const onUploadReq = (payload) => ({
  type: types.ON_UPLOAD_REQ, payload
})

export const onUploadRes = (payload) => ({
  type: types.ON_UPLOAD_RES, payload
})


export const onUploadReqBroker = (payload) => ({
  type: types.ON_UPLOAD_REQ_BROKER, payload
})

export const onUploadResBroker = (payload) => ({
  type: types.ON_UPLOAD_RES_BROKER, payload
})

export const setUserPremium = (payload) => ({ type: types.SET_USER_PREMIUM, payload })

export const getProfileCompletePercentage = (payload) => ({ type: types.GET_PROFILE_PERCENTAGE, payload })
export const getProfileCompletePercentageResponse = (payload) => ({ type: types.GET_PROFILE_PERCENTAGE_RESPONSE, payload })
export const getProfileCompletePercentageClear = () => ({ type: types.GET_PROFILE_PERCENTAGE_CLEAR })

export const setUserRole = (payload) => ({ type: types.SET_ROLE, payload })
export const setUserRoleClear = () => ({ type: types.SET_ROLE_CLEAR })

export const setIframeUserRole = (payload) => ({ type: types.SET_IFRAME_USER_ROLE, payload })
export const setIframeUserRoleClear = () => ({ type: types.SET_IFRAME_USER_ROLE_CLEAR })

export const setUserOtherRole = (payload) => ({ type: types.SET_OTHER_ROLE, payload })
export const setUserOtherRoleClear = () => ({ type: types.SET_OTHER_ROLE_CLEAR })

export const setSessionCount = (payload) => ({ type: types.SET_SESSION_COUNT, payload })
export const setSessionCountClear = () => ({ type: types.SET_SESSION_COUNT_CLEAR })

export const getLicensedAs = (payload) => ({ type: types.GET_LICENSED_AS, payload })
export const getLicensedAsResponse = (payload) => ({ type: types.GET_LICENSED_AS_RESPONSE, payload })
export const getLicensedAsClear = () => ({ type: types.GET_LICENSED_AS_CLEAR })

export const getBannerCardSummary = (payload) => ({ type: types.BANNER_CARD_SUMMARY, payload })
export const getBannerCardSummaryResponse = (payload) => ({ type: types.BANNER_CARD_SUMMARY_RESPONSE, payload })
export const getBannerCardSummaryClear = () => ({ type: types.BANNER_CARD_SUMMARY_CLEAR })


export const getRoleRequestedAs = (payload) => ({ type: types.GET_ROLE_REQUESTED, payload })
export const getRoleRequestedResponse = (payload) => ({ type: types.GET_ROLE_REQUESTED_RESPONSE, payload })
export const getRoleRequestedClear = () => ({ type: types.GET_ROLE_REQUESTED_CLEAR })

export const acceptRoleReq = (payload) => ({ type: types.ACCEPT_ROLE_REQUESTED, payload })
export const acceptRoleReqResponse = (payload) => ({ type: types.ACCEPT_ROLE_REQUESTED_RESPONSE, payload })
export const acceptRoleReqClear = () => ({ type: types.ACCEPT_ROLE_REQUESTED_CLEAR })

export const getUserOfRoleAccepted = (payload) => ({ type: types.GET_USER_OF_ROLE_ACCEPTED, payload })
export const getUserOfRoleAcceptedResponse = (payload) => ({ type: types.GET_USER_OF_ROLE_ACCEPTED_RESPONSE, payload })
export const getUserOfRoleAcceptedClear = () => ({ type: types.GET_USER_OF_ROLE_ACCEPTED_CLEAR })
// USER ROLE CANCEL
export const UserRoleCancel = (payload) => ({ type: types.USER_ROLE_CANCEL, payload })
export const UserRoleCancelResponse = (payload) => ({ type: types.USER_ROLE_CANCEL_RESPONSE, payload })
export const UserRoleCancelClear = () => ({ type: types.USER_ROLE_CANCEL_CLEAR })
//USER ROLE REQUEST STATUS
export const UserRoleRequestStatus = (payload) => ({ type: types.USER_ROLE_REQUEST_STATUS, payload })
export const UserRoleRequestStatusResponse = (payload) => ({ type: types.USER_ROLE_REQUEST_STATUS_RESPONSE, payload })
export const UserRoleRequestStatusClear = () => ({ type: types.USER_ROLE_REQUEST_STATUS_CLEAR })

export const fetchTimezoneArray = (payload) => ({ type: types.TIMEZONE_ARRAY_FETCH, payload })
export const fetchTimezoneArrayResponse = (payload) => ({ type: types.TIMEZONE_ARRAY_RESPONSE, payload })
export const fetchTimezoneArrayClear = () => ({ type: types.TIMEZONE_ARRAY_CLEAR })

export const fetchTimezoneAbbr = (payload) => ({ type: types.TIMEZONE_ABBR_FETCH, payload })
export const fetchTimezoneAbbrResponse = (payload) => ({ type: types.TIMEZONE_ABBR_RESPONSE, payload })
export const fetchTimezoneAbbrClear = () => ({ type: types.TIMEZONE_ABBR_CLEAR })


export const onPostUploadReq = (payload) => ({
  type: types.POST_UPLOAD, payload
})

export const onPostUploadRes = (payload) => ({
  type: types.POST_UPLOAD_RESPONSE, payload
})

export const onPostUploadClear = (payload) => ({
  type: types.POST_UPLOAD_CLEAR, payload
})

export const onEditUploadReq = (payload) => ({
  type: types.EDIT_UPLOAD, payload
})

export const onEditUploadRes = (payload) => ({
  type: types.EDIT_UPLOAD_RESPONSE, payload
})

export const onEditUploadClear = (payload) => ({
  type: types.EDIT_UPLOAD_CLEAR, payload
})


export const getLeaderBoardReq = (payload) => ({
  type: types.LEADERBOARD, payload
})

export const getAssesmentReq = (payload) => ({
  type: types.ASSESSMENT, payload
})

export const getLeaderBoardRes = (payload) => ({
  type: types.LEADERBOARD_RESPONSE, payload
})

export const getLeaderBoardClear = (payload) => ({
  type: types.LEADERBOARD_CLEAR, payload
})

export const getUserPointsReq = (payload) => ({
  type: types.USER_POINTS, payload
})

export const getUserPointsRes = (payload) => ({
  type: types.USER_POINTS_RESPONSE, payload
})

export const getUserPointsClear = (payload) => ({
  type: types.USER_POINTS_CLEAR, payload
})

//CHECK LIST APPROVAL BY MANAGER SECTION
export const getCheckListApproval = (payload) => ({
  type: types.CHECKLITST_APPROVAL_LIST, payload
})
export const getCheckListApprovalRes = (payload) => ({
  type: types.CHECKLITST_APPROVAL_LIST_RESPONSE, payload
})
export const getCheckListApprovalResClear = (payload) => ({
  type: types.CHECKLITST_APPROVAL_LIST_CLEAR, payload
})

//CHECK LIST APPROVAL STATUS BY MANAGER SECTION
export const checkListApprovalStatus = (payload) => ({
  type: types.CHECKLITST_APPROVAL_STATUS, payload
})
export const checkListApprovalStatusRes = (payload) => ({
  type: types.CHECKLITST_APPROVAL_STATUS_RESPONSE, payload
})
export const checkListApprovalStatusClear = (payload) => ({
  type: types.CHECKLITST_APPROVAL_STATUS_CLEAR, payload
})

export const changePasswordReq = (payload) => ({
  type: types.CHANGE_PASSWORD_REQ, payload
})

export const changePasswordRes = (payload) => ({
  type: types.CHANGE_PASSWORD_RESPONSE, payload
})

export const changePasswordResClear = (payload) => ({
  type: types.CHANGE_PASSWORD_RES_CLEAR, payload
})
export const updateMobileStatus = (payload) => ({
  type: types.IS_MOBILE_WEBVIEW, payload
})

export const updateMobileStatusClear = () => ({
  type: types.IS_MOBILE_WEBVIEW_CLEAR
})
