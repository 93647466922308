
import VideoPlayer from "react-video-js-player";
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Page from "../../components/Page";
import VideoViewPage from "./VideoViewPage";
import { useEffect, useState } from "react";
import { getAssignBrokerCourse, getAssignBrokerCourseClear, setMarkView, setMarkViewClear } from '../../redux/courses/actions';
import { getVideoCompt } from '../../redux/video/actions';
import { SessionKeys } from '../../utils/AppUtils';
import _ from "lodash";
import ShimmerCardBody from "../../components/Layouts/ShimmerCardBody";
import { clickStreamUser } from "../../utils/AppUtils";
import { Carousel } from "react-bootstrap";
import ImageComponent from "../../components/Layouts/ImageComponent";
import ProviderComponent from "../../components/Layouts/ProviderComponent";
import ShimmerCardBodyThree from "../../components/Layouts/ShimmerCardBodyThree";
import Slider from "react-slick/";
import PrevArrow from "../../components/LearningPaths/PrevArrow";
import NextArrow from "../../components/LearningPaths/NextArrow";

const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    dots: true,
    arrows: true,
    autoplaySpeed: 2000,
    infinite: false,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3

        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
// import { getVideoCourseCategories, getVideoDetailListing, getVideoDetailListingClear } from "../../redux/video/actions";
const VideoCoursesListingCard = (props) => {
    const {
        coursesList,
        innerCategoryName,
        isLoading,
    } = props;
    const dispatch = useDispatch();
    const history = useHistory()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get('source');
    const catId = params.get('catId');
    const markView = useSelector((state) => state.courses.markView);
    const [productId, setProductId] = useState("");
    const [videoData, steVideoData] = useState({})
    const authBroker = useSelector(state => state.auth.authBroker)
    const [imgError, setImageError] = useState(false)

    useEffect(() => {
        // console.log(coursesList)
    }, [coursesList])

    useEffect(() => {
        // console.log('markView', markView)
        if (markView?.status === "success" && !_.isEmpty(videoData)) {
            // `/moduleDetail/${course?.crscd}/?source=${course?.source}`
            dispatch(setMarkViewClear());
            //   let courseId = (videoData.source === "xsel") ? videoData.course_code : videoData.product_id
            //   history.push(`/video-view-page/${videoData?.product_id}/?source=${videoData?.source}&code=${courseId}`)
        }
    }, [markView])

    const redirectToPage = (m) => {
        // console.log(m)
        if (!m.is_paid && m.source === 'xsel') {
            history.push(`/shopping/${m.product_id}?addToCart=${m?.is_addtocart}&source=videos`)
            clickStreamUser("buy-button-clicked", {
                userId: authBroker?.payload?.user.user_id,
                title: m?.product_name
            });
        } else {
            clickStreamUser("view-button-clicked", {
                userId: authBroker?.payload?.user.user_id,
                title: m?.product_name
            });
            steVideoData(m)
            dispatch(setMarkView({
                module: 'setMarkView',
                postData: {
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    entity_type: 'videos',
                    entity_id: (m?.source === "xsel") ? m?.course_code : m?.product_id,
                    origin: (m?.source === "xsel") ? "xsel" : "broker",
                    is_type: m?.is_paid ? 'paid' : 'free',
                    comment: (m?.source === "xsel") ? m?.name : ""
                }
            }))
            
            let courseId = (m.source === "xsel") ? m.course_code : m.product_id
            history.push(`/video-view-page/${m?.product_id}/?source=${m?.source}&code=${courseId}&catId=${catId}`)
        }       
    }

    const renderPremiumTag = (video) => {
        return video.is_paid !== undefined && !video.is_paid ? (
        <div className={video?.is_addtocart ? "d-flex justify-content-between revisit_icon buTN_buy added-in-cart" : "d-flex justify-content-between revisit_icon buTN_buy"}>
            <div className="calendar_event">
            <button
                type="button"
                className="btn btn-primary-LR  setup-button"
                onClick={() => {
                    history.push(`/shopping/${video.product_id}?addToCart=${video?.is_addtocart}&source=videos`);
                }}
            >
                {video?.is_addtocart ? "Added in cart" : "Buy"}
            </button>
            </div>
        </div>
        ) : (
        ""
        );
    };

    const renderVideoCard = (m, index) => {
        return (
          props.isSlider ? 
            <div key={'single-' + index} className="boxslidersp">
            <div className="broker-box">
              <div className="img-box_nw">
                    <div className="img-videosec learn_image-card" onClick={() => redirectToPage(m)}>
                        
                        <div className='img-box-video' >
                           
                            <ImageComponent 
                                thumbnail={m.product_thumbnail ? m.product_thumbnail : ""}
                                name={m.product_name}
                                errorClass="d-flex align-items-center justify-content-center bg-grey img-error img-videosec bk-grey"
                                cssClass="img-videosec learn_image-card cursor pointer"
                            />
                            <div className="playbtnvideo"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                            <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                                <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                                <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" strokeWidth="4">
                                    <circle cx="24" cy="24" r="24" stroke="none"/>
                                    <circle cx="24" cy="24" r="22" fill="none"/>
                                </g>
                                </g>
                                <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                            </g>
                            </svg>
                            </div>
                        </div>
                    </div>
                    <div className="details-card_RP">
                        <h5
                            className="cursor-pointer"
                            title={m?.product_name}
                            dangerouslySetInnerHTML={{
                                __html: m?.product_name
                            }}
                            onClick={() =>
                                redirectToPage(m)
                            }
                        ></h5>

                        <div className="row info-bottm-card_nw">
                            <div className="col-md-12 d-flex justify-content-between vidocounspace">
                                
                                <div className="ico__imG01 d-flex"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="clock-time-four" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg>
                                    <span className="videoviews"><span className="videocount">{m?.course_duration}</span>&nbsp;{m?.durationUnit}</span></div>
                                <div className="ico_texxt02 d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="eye-circle" d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg> <span className="videoviews"><span className="videocount">{m?.views}</span> View{m?.views > 1 ? "s" : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className="event_learn">
                            <div className="row infoIcon_nw">
                                <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                                  
                                    <ProviderComponent providerData={m.provider} />
                                    
                                    <div className="d-flex justify-content-between revisit_icon buTN_buy">
                                        <div className="calendar_event">
                                            {
                                                (m.source != 'xsel' ||
                                                m.is_paid ||
                                                !m.showBuy) ?
                                                <button type="button" className="btn btn-primary-LR  setup-button"
                                                    onClick={() =>
                                                        redirectToPage(m)
                                                    }
                                                >View</button>
                                                :
                                                renderPremiumTag(m)

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            : 
            <div className="col-md-6 col-lg-4 col-xl-4 col-12 videobox search__input" key={'single-' + index}>
            <div className="img-box_RP">
                <div className="img-videosec learn_image-card" onClick={() => redirectToPage(m)}>
                   
                    <div className='img-box-video' >
                       
                        <ImageComponent 
                            thumbnail={m.product_thumbnail ? m.product_thumbnail : ""}
                            name={m.product_name}
                            errorClass="d-flex align-items-center justify-content-center bg-grey img-error img-videosec bk-grey"
                            cssClass="img-videosec learn_image-card cursor pointer"
                        />
                        <div className="playbtnvideo"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                        <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                            <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                            <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" strokeWidth="4">
                                <circle cx="24" cy="24" r="24" stroke="none"/>
                                <circle cx="24" cy="24" r="22" fill="none"/>
                            </g>
                            </g>
                            <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                        </g>
                        </svg>
                        </div>
                    </div>
                </div>
                <div className="details-card_RP">
                    <h5
                        className="cursor-pointer"
                        title={m?.product_name}
                        dangerouslySetInnerHTML={{
                            __html: m?.product_name
                        }}
                        onClick={() =>
                            redirectToPage(m)
                        }
                    ></h5>

                    <div className="row info-bottm-card_nw">
                        <div className="col-md-12 d-flex justify-content-between vidocounspace">
                          
                            <div className="ico__imG01 d-flex"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="clock-time-four" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg>
                                <span className="videoviews"><span className="videocount">{m?.course_duration}</span>&nbsp;{m?.durationUnit}</span></div>
                            <div className="ico_texxt02 d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="eye-circle" d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg> <span className="videoviews"><span className="videocount">{m?.views}</span> View{m?.views > 1 ? "s" : ""}</span>
                            </div>
                        </div>
                    </div>
                    <div className="event_learn">
                        <div className="row infoIcon_nw">
                            <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                                
                                <ProviderComponent providerData={m.provider} />
                                
                                <div className="d-flex justify-content-between revisit_icon buTN_buy">
                                    <div className="calendar_event">
                                        {
                                            (m.source != 'xsel' ||
                                            m.is_paid ||
                                            !m.showBuy) ?
                                            <button type="button" className="btn btn-primary-LR  setup-button"
                                                onClick={() =>
                                                    redirectToPage(m)
                                                }
                                            >View</button>
                                            :
                                            renderPremiumTag(m)

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
            
        )
    }

    const renderSliderVideoCard = (video, i) => {
        return (
            <>
             {props.isSlider ? 
              <div
              className="boxslidersp"
              key={"course-" + i}
            >
           <div className="broker-box">
              <div id={video?.id} className="img-box_nw position-relative">
                <div className="combo-tag">
                  <i className="fa fa-star" aria-hidden="true"></i> COMBO
                </div>
                <Carousel id="courseCardCarousel" className="videoCardCarousel">
                  {video.list.map((m, index) => {
                    return (
                      <Carousel.Item key={"listdata-" + index}>
                          <div className="img-box_RP">
                              <div className="img-videosec learn_image-card">
                                  
                                  <div className="img-box-video" onClick={() => 
                                      video.showBuy ? redirectToPage(video) : redirectToPage(m)
                                  }>
                                     
                                      <ImageComponent 
                                          thumbnail={m.imageURL ? m.imageURL : ""}
                                          name={m.name}
                                          errorClass="d-flex align-items-center justify-content-center bg-grey img-error img-videosec bk-grey"
                                          cssClass="img-videosec learn_image-card cursor pointer"
                                      />
                                      <div className="playbtnvideo">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                          <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                                              <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                                              <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" strokeWidth="4">
                                                  <circle cx="24" cy="24" r="24" stroke="none"/>
                                                  <circle cx="24" cy="24" r="22" fill="none"/>
                                              </g>
                                              </g>
                                              <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                                          </g>
                                          </svg>
                                      </div>
                                  </div>

                              </div>
                              <div className="details-card_RP">
                                  <h5
                                      className="cursor-pointer"
                                      title={m?.name}
                                      dangerouslySetInnerHTML={{
                                          __html: m?.name
                                      }}
                                      onClick={() =>
                                          video.showBuy ? redirectToPage(video) : redirectToPage(m)
                                      }
                                  ></h5>

                                  <div className="row info-bottm-card_nw">
                                      <div className="col-md-12 d-flex justify-content-between vidocounspace">
                                         
                                          <div className="ico__imG01 d-flex"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="clock-time-four" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg>
                                              <span className="videoviews"><span className="videocount">{m?.duration}</span>&nbsp;{m?.durationUnit}</span></div>
                                          <div className="ico_texxt02 d-flex">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="eye-circle" d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg> <span className="videoviews"><span className="videocount">{m?.views}</span> View{m?.views > 1 ? "s" : ""}</span>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="event_learn">
                                      <div className="row infoIcon_nw">
                                          <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                                             
                                              <ProviderComponent providerData={m.provider} />
                                             
                                              <div className="d-flex justify-content-between revisit_icon buTN_buy">
                                                  <div className="calendar_event">
                                                      {
                                                          (video.source !== 'xsel' ||
                                                          video.is_paid ||
                                                          !video.showBuy)
                                                          ?
                                                          (
                                                              <button type="button" className="btn btn-primary-LR  setup-button"
                                                                  onClick={() =>
                                                                      video.showBuy ? redirectToPage(video) : redirectToPage(m)
                                                                  }
                                                              >View</button>
                                                          )
                                                          :
                                                          (
                                                              renderPremiumTag(video)

                                                          )
                                                      }
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
                <div className="position-absolute course-length-bar">
                  <span>{video.list.length}</span> item{video.list.length > 1 ? 's' : ''}
                </div>
              </div>
          </div>
            </div>
             
              :
              <div
                className="col-md-6 col-lg-4 col-xl-4 col-12 mb-4 videobox broker-box"
                key={"course-" + i}
              >
                <div id={video?.id} className="img-box_nw position-relative">
                  <div className="combo-tag">
                    <i className="fa fa-star" aria-hidden="true"></i> COMBO
                  </div>
                  <Carousel id="courseCardCarousel" className="videoCardCarousel">
                    {video.list.map((m, index) => {
                      return (
                        <Carousel.Item key={"listdata-" + index}>
                            <div className="img-box_RP">
                                <div className="img-videosec learn_image-card">
                                    
                                    <div className="img-box-video" onClick={() => 
                                        video.showBuy ? redirectToPage(video) : redirectToPage(m)
                                    }>
                                        
                                        <ImageComponent 
                                            thumbnail={m.imageURL ? m.imageURL : ""}
                                            name={m.name}
                                            errorClass="d-flex align-items-center justify-content-center bg-grey img-error img-videosec bk-grey"
                                            cssClass="img-videosec learn_image-card cursor pointer"
                                        />
                                        <div className="playbtnvideo">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                                                <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                                                <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" strokeWidth="4">
                                                    <circle cx="24" cy="24" r="24" stroke="none"/>
                                                    <circle cx="24" cy="24" r="22" fill="none"/>
                                                </g>
                                                </g>
                                                <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                                            </g>
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                                <div className="details-card_RP">
                                    <h5
                                        className="cursor-pointer"
                                        title={m?.name}
                                        dangerouslySetInnerHTML={{
                                            __html: m?.name
                                        }}
                                        onClick={() =>
                                            video.showBuy ? redirectToPage(video) : redirectToPage(m)
                                        }
                                    ></h5>

                                    <div className="row info-bottm-card_nw">
                                        <div className="col-md-12 d-flex justify-content-between vidocounspace">
                                           
                                            <div className="ico__imG01 d-flex"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="clock-time-four" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg>
                                                <span className="videoviews"><span className="videocount">{m?.duration}</span>&nbsp;{m?.durationUnit}</span></div>
                                            <div className="ico_texxt02 d-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="eye-circle" d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg> <span className="videoviews"><span className="videocount">{m?.views}</span> View{m?.views > 1 ? "s" : ""}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event_learn">
                                        <div className="row infoIcon_nw">
                                            <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                                               
                                                <ProviderComponent providerData={m.provider} />
                                                
                                                <div className="d-flex justify-content-between revisit_icon buTN_buy">
                                                    <div className="calendar_event">
                                                        {
                                                            (video.source !== 'xsel' ||
                                                            video.is_paid ||
                                                            !video.showBuy)
                                                            ?
                                                            (
                                                                <button type="button" className="btn btn-primary-LR  setup-button"
                                                                    onClick={() =>
                                                                        video.showBuy ? redirectToPage(video) : redirectToPage(m)
                                                                    }
                                                                >View</button>
                                                            )
                                                            :
                                                            (
                                                                renderPremiumTag(video)

                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                  <div className="position-absolute course-length-bar">
                    <span>{video.list.length}</span> item{video.list.length > 1 ? 's' : ''}
                  </div>
                </div>
              </div>
    }
            </>
          );
    }


    return (

        <>
                { props.isSlider ? 
                ""
                :
                <div id={'top'} className="view-button mt-4">
                <p className="lablesech">{innerCategoryName}</p>
                </div> 
                }
               { 
                !isLoading ? (
                    coursesList?.length > 0 ?
                    props.isSlider ? 
                        <div className="slider-course-listing">
                       
                       <Slider {...settings}>
                            {coursesList?.map((m, index) => {
                                if (
                                    m.is_bundle &&
                                    m.list &&
                                    _.isArray(m.list) &&
                                    m.list.length > 0
                                ) {
                                    return renderSliderVideoCard(m, index)
                                } else {
                                    return renderVideoCard(m, index)
                                }
                            })
                            }
                        </Slider>
                        </div>
                       :
                       <div className="row videolistbox rsp-video-cat-list mt-0">
                       {coursesList?.map((m, index) => {
                                if (
                                    m.is_bundle &&
                                    m.list &&
                                    _.isArray(m.list) &&
                                    m.list.length > 0
                                ) {
                                    return renderSliderVideoCard(m, index)
                                } else {
                                    return renderVideoCard(m, index)
                                }
                            })}
                    
                    </div>
                        :
                        <div className="not-found mt-3">
                            <div className="interboxes">
                                <img src="/images/telescope.svg" alt="Not Found" />
                                <p>Sorry no videos are available for your search!</p>
                            </div>
                        </div>
                ):(
                    <div className="row videolistbox">
                {
                  [1,2,3].map(i => {
                    return (
                      <ShimmerCardBody key={`c-${i}`} />
                    )
                  })
                }
              </div>
                )
              
                 }
        </>
    )
}

export default VideoCoursesListingCard;