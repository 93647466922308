import { NavDropdown, Nav } from "react-bootstrap";
import ShimmerScreen from "./ShimmerScreen";

const ShimmerNotification = () => {
    return (
        <NavDropdown.Item
        className="get_CertifiedContainer dropdownItem_notiFcation"
        // onClick={() => goToNotification(item.redirection_key, item.id)}
        >
        <div className="getCertified">
            <a href="#">
            {" "}
            </a>
        </div>
        <div
            className="getCertified_content w-100"
        >
            <h6>
                <ShimmerScreen 
                    case="rect"
                    height="1rem"
                    width="100%"
                />
                <div className="mt-1">
                    <ShimmerScreen 
                        case="rect"
                        height="1rem"
                        width="50%"
                    />
                </div>
            </h6>
            <p className="text-right d-flex justify-content-end">
                <ShimmerScreen 
                    case="rect"
                    height="1rem"
                    width="20%"
                />
            </p>
        </div>
        </NavDropdown.Item>
    )
}

export default ShimmerNotification;