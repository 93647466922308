import _ from "lodash";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import session from "redux-persist/lib/storage/session";
import { getAPI } from "../../api/generic";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import {
  getEventDetailsBroker,
  getEventDetailsBrokerClear,
  getEventDetailsNIIT,
  getEventDetailsNIITClear,
  getSessionNIITList,
  getSessionNIITListClear,
  getSessionBrokerList,
  getSessionBrokerListClear,
  getEnrollBrokerSession,
  getEnrollBrokerSessionClear,
  joinSession,
  joinSessionClear,
  setEnrollBrokerSessionResponse,
  eventNotification,
  checkEventNotification,
  eventNotificationClear,
  checkEventNotificationClear,
  cancelSessionData,
  cancelSessionDataClear,
  hostSessionClear,
  hostSession,
  sessionWatchURLDataClear,
  sessionWatchURLDataResponse,
  boardcastDataResponse,
  getCalendarSessionList,
} from "../../redux/events/actions";
import { clickStreamUser, SessionKeys, timezoneArea, timezoneTagName } from "../../utils/AppUtils";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { cartPageDataResponse } from "../../redux/checkout/actions";
import { color, style } from "@mui/system";
import {
  fetchTimezoneAbbr,
  fetchTimezoneAbbrClear,
} from "../../redux/user/actions";
import { addToCart, addToCartClear } from "../../redux/product/actions";
import Loader from "../Loader/Loader";
import AssignCourseModal from "../modal/AssignCourseModal";
import { setCurrentView } from "../../redux/assignCourse/actions";
import ConfirmModal from "../modal/ConfirmModal";
import FeedbackCalendarCard from "../../pages/Calender Events/FeedbackCalendarCard";
import SingleCompletedPopup from "../FeedbackPopups/singleCompletedPopup";
import FeedbackSucessPopup from "../FeedbackPopups/FeedbackSuccessPopup";

const EventDetailSection = ({ setIsLoading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const event_id = params.get("event_id");
  const event_code = params.get("event_code");
  const source = params.get("source");

  const sessionListRes = useSelector((state) => state.events.sessionList);
  const sessionBrokerListRes = useSelector(
    (state) => state.events.sessionListBroker
  );
  const eventDetailRes = useSelector((state) => state.events.eventDetail);
  const eventDetailResBroker = useSelector(
    (state) => state.events.eventDetailBroker
  );
  const enrollBrokerSession = useSelector(
    (state) => state.events.enrollBrokerSession
  );
  const addToCartRes = useSelector((state) => state.product.addToCart);
  const notfiyEnrolled = useSelector((state) => state.events.eventNotification);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const joinSessionData = useSelector((state) => state.events.joinSession);
  const checkNotified = useSelector((state) => state.events.checkNotified);
  const sessionCancelledData = useSelector(
    (state) => state.events.sessionCancel
  );
  const hostSessionInfo = useSelector((state) => state.events.hostSession);
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const timezoneAbbr = useSelector((state) => state.user.timezoneAbbr);
  const userRole = useSelector((state) => state.user.role);
  const currentView = useSelector((state) => state.assignCourse.current_view);

  const [isSessionDataLoaded, setIsSessionDataLoaded] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const [eventDetail, setEventDetail] = useState({});
  const [open, setOpen] = useState([]);
  const [infoModal, setInfoModal] = useState(false);
  const [showInfoModalMsg, setShowInfoModalMsg] = useState("");
  const [notifyMeBtn, setNotifyMeBtn] = useState(false);
  const [show, setShow] = useState(false);
  const [isNotified, setIsNotified] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [zoneAbbr, setZoneAbbr] = useState("CDT");
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedSession, setSelectedSession] = useState('')

  const [confirmModal, setConfirmModal] = useState(false)
  const [showModalMsg,] = useState('Would you like to unregister from the session?')
  const [sessionDataForModal, setSessionDataForModal] = useState({})
  const [viaRegisterRoute, setViaRegisterRoute] = useState(false)
  const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf("week"))
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showReminderPopupStep2, setShowReminderPopupStep2] = useState(false);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [name, setName] = useState("");
  const [type, setType] = useState("Segment");
  const [watchRecordings, setWatchRecordings] = useState(false);
  const [recordingURLs, setRecordingURLs] = useState([]);

  const fetchfeedbackQuestions = () => {
    (async () => {
      const res = await getAPI({
        module: "getFeedbackQuestions",
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        type: "Segment",
      });
      if (res.status === "success" && res?.payload) {
        setFeedbackQuestions(res?.payload);
      }
    })();
  };

  useEffect(() => {
    fetchfeedbackQuestions();
  }, []);

  const handleFeedback = (sessionDetails, date) => {
    setName(sessionDetails?.session_name);
    setShowFeedbackPopup(true);
    setSelectedDate(date);
  };

  function handleSubmitReminderFirstPopup() {
    setShowReminderPopupStep2(true);
    setTimeout(() => {
      setShowReminderPopupStep2(false);
    }, 5500);
  }


  useEffect(() => { dispatch(setCurrentView(1)) }, [])

  useEffect(() => {
    if (params.get("register")) {
      setViaRegisterRoute(true)
    }
  }, [params])

  useEffect(() => {
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
      setUserDetails(userDetailRes?.payload?.payload);
    }
  }, [userDetailRes]);

  useEffect(() => {
    if (!_.isEmpty(timezoneAbbr)) {
      if (timezoneAbbr.status === "success") {
        const timezoneAbbrData = timezoneAbbr.payload?.payload;
        if (!_.isEmpty(timezoneAbbrData)) {
          const abbr = timezoneAbbrData.abbr[0].abbreviations;
          setZoneAbbr(abbr);
        }
      }
      dispatch(fetchTimezoneAbbrClear());
    }
  }, [timezoneAbbr]);

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      clickStreamUser("event-detail-page", {
        eventId: event_id,
        user_id: authBroker?.payload?.user?.user_id,
      });
    }
  }, [authBroker]);

  useEffect(() => {
    if (selectedSession !== '') {
      setShow(true)
    }
  }, [selectedSession])

  useEffect(() => {
    if (!_.isEmpty(joinSessionData)) {
      setIsLoading(false);
      if (joinSessionData.status === "success") {
        if (!_.isEmpty(joinSessionData.payload.payload)) {
          const sessionData = joinSessionData.payload.payload;
          if (sessionData.join_url) {
            window.open(sessionData.join_url, "_blank");
          } else {
            setShowInfoModalMsg("Error joining session, please contact support");
          }
        }
      } else {
        setShowInfoModalMsg("Error joining session, please contact support");
      }
      window.scrollTo(0, 0);
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
        setShowInfoModalMsg('')
      }, 5000)
      dispatch(joinSessionClear());
    }
  }, [joinSessionData]);

  useEffect(() => {
    if (Object.keys(addToCartRes).length !== 0) {
      history.push("/cart");
      dispatch(addToCartClear());
    }
  }, [addToCartRes]);

  //SESSIONS INFO
  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      if (source === "broker" && !_.isEmpty(authBroker)) {
        dispatch(
          getEventDetailsBroker({
            module: "eventDetailBroker",
            postData: {
              event_id: event_id,
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            },
          })
        );
        dispatch(
          getSessionBrokerList({
            module: "sessionBrokerList",
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
              event_id: event_id,
              broker_id: authBroker?.payload?.user?.broker_id,
            },
          })
        );
      } else {
        dispatch(
          getSessionNIITList({
            module: "sessionList",
            postData: {
              email: authBroker.payload.user.email,
              event_id: event_id,
              user_id: authBroker.payload.user.user_id,
              company_id: authBroker?.payload?.user.company_id
            },
          })
        );

        dispatch(
          getEventDetailsNIIT({
            module: "GetEvent",
            postData: {
              module: "GetEvent",
              // event_code: event_code,
              eventcode: event_code,
            },
          })
        );
      }
      dispatch(sessionWatchURLDataClear());
    }
  }, [dispatch, authBroker, source]);

  useEffect(() => {
    if (!_.isEmpty(sessionListRes)) {
      setIsLoading(false);
      if (sessionListRes?.status === "success") {
        if (!_.isEmpty(sessionListRes.payload.payload)) {
          let sessionList = _.clone(sessionListRes.payload.payload.sessionList);
          if (_.isArray(sessionList)) {
            let newSessionList = [];
            for (let sessionData of sessionList) {
              let session = _.cloneDeep(sessionData);
              session.source = 'xsel';
              let api_date_format = "YYYY-MM-DD HH:mm";
              // const timezoneOffset = !_.isEmpty(userDetails.timezone)
              //   ? userDetails.timezone.replace(":", "")
              //   : "-0500";
              const timezoneOffset = sessionData.timezone.includes(":") ? sessionData.timezone.replace(":", "") : "+0530";
              // const area = session.timezoneAbbr ? session.timezoneAbbr : 'America/New_York'; //timezoneArea(timezoneOffset);
              const area = timezoneArea(timezoneOffset);
              session.startDate = moment(session.startDate).format(
                "MMM DD, YYYY"
              );
              session.startTime = moment(session.startTime).format("HH") * 100;
              if (session.startTime < 1000) {
                session.startTime = "0" + session.startTime;
              }
              session.can_enroll = false;
              session.hostZoomId = authBroker.payload.user.zoom_id;
              session.userId = authBroker.payload.user.user_id;
              session.host = session.display_name;
              session.show_join = false;
              session.isOffer = false;
              session.originalPrice = session.selling_price;
              session.is_broadcast = session.delivery_mode === 'virtual_broadcast'

              if (isPremiumUser) {
                if (
                  session.sfdc_product_type.toLowerCase() === "basic" ||
                  session.sfdc_product_type.toLowerCase() === "premium"
                ) {
                  session.can_enroll = true;
                }
                if (session.offer_available) {
                  session.isOffer = true;
                  session.originalPrice = session.product_premium_price
                }
              } else {
                session.can_enroll =
                  session.sfdc_product_type.toLowerCase() === "basic";
                if (session.offer_available) {
                  session.isOffer = true;
                  session.originalPrice = session.product_basic_price
                }
              }

              if (session.register) {
                session.is_paid = true;
              }

              for (let record of session.date_time) {
                const userTz = moment.tz.guess();
                // const currentTime = moment().tz(userTz);

                const currentTime = moment().tz(area);
                session.watch_url = record.watch_url;
                record.startUTC = record?.start_date?.replaceAll("-", "/") +
                  " " +
                  record.start_time;
                record.startDateTime = moment
                  .utc(
                    record?.start_date?.replaceAll("-", "/") +
                    " " +
                    record.start_time
                  )
                  .tz(area)
                // .utcOffset(timezoneOffset);
                // .tz(userTz);
                record.endDateTime = moment
                  .utc(
                    record?.end_date?.replaceAll("-", "/") +
                    " " +
                    record.end_time
                  )
                  .tz(area)
                // .utcOffset(timezoneOffset);
                // .tz(userTz);
                record.timezone = record?.startDateTime?.format('z'); // timezoneTagName(session.timezoneAbbr); // moment.tz(userTz).format("z");
                // const startDateTime = moment.utc(event_time.start_date.replaceAll('-', '/') + ' ' + event_time.start_time).tz(userTz);
                // const endDateTime = moment.utc(event_time.end_date.replaceAll('-', '/') + ' ' + event_time.end_time).tz(userTz);
                record.startD = record?.startDateTime?.format("DD MMM YYYY");
                record.endD = record?.endDateTime?.format("DD MMM YYYY");
                session.show_join = session.show_join
                  ? session.show_join
                  : currentTime?.isBetween(
                    record.startDateTime,
                    record.endDateTime
                  );
                session.is_past = currentTime?.isAfter(record.endDateTime);
                record.is_past = currentTime?.isAfter(record.endDateTime);
                session.currentTime = currentTime?.format(api_date_format);
                session.is_host = record.is_host;
                // session.is_participant = record.is_participant;
                session.is_participant = true;
                session.is_registered = true;
                session.meeting_id = record.meeting_id;
                session.segmentId = record.segment_id;
                session.start = moment(
                  record.startDateTime.format(api_date_format)
                ).toDate();
                session.end = moment(
                  record.endDateTime.format(api_date_format)
                ).toDate();
                session.start_url = record.start_url ? record.start_url : "";
                session.join_url = record.join_url ? record.join_url : "";
                session.durationValue = _.toInteger(
                  moment
                    .duration(record.endDateTime.diff(record.startDateTime))
                    .asMinutes()
                );
                session.duration =
                  _.toInteger(
                    moment
                      .duration(record.endDateTime.diff(record.startDateTime))
                      .asMinutes()
                  ) + "mins";
              }
              if (viaRegisterRoute) {
                if (session.is_registered) {
                  newSessionList.push(session)
                }
              } else {
                if (!session.is_past) {
                  newSessionList.push(session);
                } else if (session.is_past && session.is_registered) {
                  newSessionList.push(session);
                }
              }
            }
            setSessionList(newSessionList);
            setIsSessionDataLoaded(true);
          } else {
            setSessionList([]);
            setIsSessionDataLoaded(true);
          }
        }
      } else {
        setIsSessionDataLoaded(true);
        setSessionList([]);
      }
      dispatch(getSessionNIITListClear());
    }
  }, [sessionListRes]);

  useEffect(() => {
    if (!_.isEmpty(sessionBrokerListRes)) {
      setIsLoading(false);
      if (sessionBrokerListRes.status === "success") {
        if (!_.isEmpty(sessionBrokerListRes.payload.payload)) {
          let sessionList = sessionBrokerListRes.payload.payload.sessionList;
          let newSessionList = [];
          if (_.isArray(sessionList)) {
            for (let session of sessionList) {
              let api_date_format = "YYYY-MM-DD HH:mm";
              session.source = 'broker';
              // const timezoneOffset = !_.isEmpty(userDetails.timezone)
              //   ? userDetails.timezone.replace(":", "")
              //   : "-0500";
              // const timezoneOffset = session.timezone.replace(":", "");
              const timezoneOffset = session.timezone.includes(":") ? session.timezone.replace(":", "") : "+0530";
              // const area = session.timezoneAbbr ? session.timezoneAbbr : 'America/New_York'; //timezoneArea(timezoneOffset);
              const area = timezoneArea(timezoneOffset);
              session.startDate = moment(session.startDate).format(
                "MMM DD, YYYY"
              );
              session.startTime = moment(session.startTime).format("HH") * 100;
              session.can_enroll = true;
              session.hostZoomId = authBroker.payload.user.zoom_id;
              session.userId = authBroker.payload.user.user_id;
              session.host = session.display_name;
              session.show_join = false;
              session.is_broadcast = session.delivery_mode === 'virtual_broadcast'

              for (let record of session.date_time) {
                if (record.start_date) {
                  // const startDateTime = moment.utc(record.start_date.replaceAll('-', '/') + ' ' + record.start_time).tz(userTz);
                  // const endDateTime = moment.utc(record.end_date.replaceAll('-', '/') + ' ' + record.end_time).tz(userTz);
                  const userTz = moment.tz.guess();
                  // const currentTime = moment().tz(userTz);
                  const currentTime = moment().tz(area);
                  // record.startDateTime = moment
                  //   .utc(
                  //     record.start_date.replaceAll("-", "/") +
                  //       " " +
                  //       record.start_time
                  //   )
                  //   .tz(userTz);
                  // record.endDateTime = moment
                  //   .utc(
                  //     record.end_date.replaceAll("-", "/") + " " + record.end_time
                  //   )
                  //   .tz(userTz);
                  record.startDateTime = moment
                    .utc(
                      record?.start_date?.replaceAll("-", "/") +
                      " " +
                      record.start_time,

                      "MM-DD-YYYY HH:mm"
                    )
                    .tz(area)
                  // currentTime.format('HH:mm'), currentTime.isAfter(record.endDateTime))
                  record.endDateTime = moment
                    .utc(
                      record?.end_date?.replaceAll("-", "/") +
                      " " +
                      record.end_time,
                      "MM-DD-YYYY HH:mm"
                    )
                    .tz(area)
                  record.startD = record.startDateTime.format("DD MMM YYYY");
                  record.endD = record.endDateTime.format("DD MMM YYYY");
                  // record.startT = record.end
                  // record.timezone = moment.tz(userTz).format("z");
                  record.timezone = record.startDateTime.format('z'); //timezoneTagName(session.timezoneAbbr); //moment.utc().utcOffset(timezoneOffset).format("ZZ");
                  session.show_join = session.show_join
                    ? session.show_join
                    : currentTime?.isBetween(
                      record.startDateTime,
                      record.endDateTime
                    );
                  session.is_host = record.is_host;
                  session.is_participant = true;
                  session.is_registered = true;
                  session.currentTime = currentTime?.format(api_date_format);
                  session.meeting_id = record.meeting_id;
                  session.segmentId = record.segment_id;
                  session.start = moment(
                    record.startDateTime.format(api_date_format)
                  ).toDate();
                  session.end = moment(
                    record.endDateTime.format(api_date_format)
                  ).toDate();
                  session.durationValue = _.toInteger(
                    moment
                      .duration(record.endDateTime.diff(record.startDateTime))
                      .asMinutes()
                  );
                  session.duration =
                    _.toInteger(
                      moment
                        .duration(record.endDateTime.diff(record.startDateTime))
                        .asMinutes()
                    ) + "mins";
                  session.is_past = currentTime?.isAfter(record.endDateTime);
                  record.is_past = currentTime?.isAfter(record.endDateTime);
                  session.start_url = record.start_url ? record.start_url : "";
                  session.join_url = record.join_url ? record.join_url : "";
                }
              }
              if (viaRegisterRoute) {
                if (session.is_registered) {
                  newSessionList.push(session)
                }
              } else {
                if (!session.is_past) {
                  newSessionList.push(session);
                } else if (session.is_past && session.is_registered) {
                  newSessionList.push(session);
                }
              }
            }
            setIsSessionDataLoaded(true);
            setSessionList(newSessionList);
          } else {
            setIsSessionDataLoaded(true);
            setSessionList([]);
          }
        }
      } else {
        setIsSessionDataLoaded(true);
        setSessionList([]);
      }
      dispatch(getSessionBrokerListClear());
    }
  }, [sessionBrokerListRes]);

  useEffect(() => {
    if (!_.isEmpty(eventDetailRes) && eventDetailRes?.status === "success") {
      let eventDetail = eventDetailRes.payload.payload;
      if (!_.isEmpty(eventDetail)) {
        setEventDetail(eventDetail);
      }
      dispatch(getEventDetailsNIITClear());
    }
  }, [eventDetailRes]);

  useEffect(() => {
    if (
      !_.isEmpty(eventDetailResBroker) &&
      eventDetailResBroker?.status === "success"
    ) {
      setEventDetail(eventDetailResBroker?.payload.payload);

      dispatch(getEventDetailsBrokerClear());
    }
  }, [eventDetailResBroker]);

  const dispatchAPICall = () => {
    let weekInput = currentWeekStart.clone();
    let date_range = {
      start_date: weekInput.startOf('week').format("MM/DD/YYYY"),
      end_date: weekInput.clone().endOf('week').format("MM/DD/YYYY")
    }
    dispatch(getCalendarSessionList({
      ecom: {
        module: 'calendarSessionListEcom',
        postData: {
          email: authBroker.payload.user.email,
          user_id: authBroker.payload.user.user_id,
          company_id: authBroker?.payload?.user.company_id,
          date_range
        }
      },
      broker: {
        module: 'calendarSessionListBroker',
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          date_range
        }
      }
    }))
  }

  useEffect(() => {
    if (!_.isEmpty(enrollBrokerSession)) {
      dispatchAPICall();
      if (enrollBrokerSession?.status === "success") {
        if (!_.isEmpty(enrollBrokerSession.payload.payload)) {
          let data = enrollBrokerSession.payload.payload;
          setShowInfoModalMsg(enrollBrokerSession.payload.message);
          window.scrollTo(0, 0);
          // setInfoModal(true);
          clickStreamUser("session-registered-successfull", {
            event_id: event_id,
            session_id: data.session_id,
            user_id: authBroker?.payload?.user?.user_id,
            message: enrollBrokerSession.payload.message,
          });
          fetchSessionList(data);
        } else {
          if (userRole === "realtor") {
            setShowInfoModalMsg(
              "Error registering session, please contact your manager"
            );
          } else if (userRole === "manager") {
            setShowInfoModalMsg(
              "Error registering session, please contact your broker admin"
            );
          } else {
            setShowInfoModalMsg(
              "Error registering session, please contact support"
            );
          }
          // setInfoModal(true);
          setIsLoading(true)
        }
      } else if (enrollBrokerSession?.status === "error") {
        setShowInfoModalMsg(enrollBrokerSession.error.message);
        // window.scrollTo(0, 0);
        setIsLoading(false)
      }
      window.scrollTo(0, 0);
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
        setShowInfoModalMsg('')
      }, 5000)
      dispatch(getEnrollBrokerSessionClear());
    }
  }, [enrollBrokerSession]);

  useEffect(() => {
    if (checkNotified.status === "success") {
      if (checkNotified.payload.payload.is_notified) {
        setNotifyMeBtn(true);
      }
      dispatch(checkEventNotificationClear());
    }
  }, [checkNotified]);

  useEffect(() => {
    if (!_.isEmpty(notfiyEnrolled)) {
      if (notfiyEnrolled.status === "success") {
        window.scrollTo(0, 0);
        setShowInfoModalMsg(notfiyEnrolled.payload.message);
        setIsLoaded(false);
      } else {
        window.scrollTo(0, 0);
        setShowInfoModalMsg('Unable to ');
      }
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
        setShowInfoModalMsg('')
      }, 5000)
      dispatch(eventNotificationClear());
    }
  }, [notfiyEnrolled]);

  useEffect(() => {
    if (!_.isEmpty(sessionCancelledData)) {
      if (sessionCancelledData.status === "success") {
        // setInfoModal(true);
        // fetchSessionList({ origin: source });
        window.scrollTo(0, 0);
        if (source === 'broker') {
          setShowInfoModalMsg('You have successfully unregistered from the session.');
        } else {
          setShowInfoModalMsg("Xsel team will connect with you shortly.");
        }
        let data = {
          origin: source
        }
        fetchSessionList(data)
      } else {
        if (userRole === "realtor") {
          setShowInfoModalMsg(
            "Error cancelling session, please contact your manager."
          );
        } else if (userRole === "manager") {
          setShowInfoModalMsg(
            "Error cancelling session, please contact your broker admin."
          );
        } else {
          setShowInfoModalMsg(
            "Error cancelling session, please contact support."
          );
        }
        window.scrollTo(0, 0);
      }
      setInfoModal(true);
      setIsLoading(false);
      setTimeout(() => {
        setInfoModal(false)
        setShowInfoModalMsg('')
      }, 5000)
      dispatch(cancelSessionDataClear());
    }
  }, [sessionCancelledData]);

  useEffect(() => {
    if (!_.isEmpty(hostSessionInfo)) {
      setIsLoading(false);
      if (hostSessionInfo.status === "success") {
        if (!_.isEmpty(hostSessionInfo.payload.payload)) {
          const hostData = hostSessionInfo.payload.payload.data;
          if (hostData.start_url) {
            window.open(hostData.start_url, "_blank");
          } else {
            window.scrollTo(0, 0);
            setShowInfoModalMsg("Server Error, please contact support");
            // setInfoModal(true);
          }
          // dispatch(hostSessionClear());
        } else {
          setShowInfoModalMsg(hostSessionInfo?.error ? hostSessionInfo?.error.message : "Not able to start the session.");
          // setInfoModal(true);
        }
      } else {
        setShowInfoModalMsg(hostSessionInfo?.error ? hostSessionInfo?.error.message : "Not able to start the session.");
      }
      window.scrollTo(0, 0);
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
        setShowInfoModalMsg('')
      }, 5000)
      dispatch(hostSessionClear())
    }
  }, [hostSessionInfo]);

  const fetchSessionList = (data) => {
    if (data.origin === "broker") {
      dispatch(
        getSessionBrokerList({
          module: "sessionBrokerList",
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            event_id: event_id,
            broker_id: authBroker?.payload?.user?.broker_id,
          },
        })
      );
    } else {
      dispatch(
        getSessionNIITList({
          module: "sessionList",
          postData: {
            email: authBroker.payload.user.email,
            event_id: event_id,
            user_id: authBroker.payload.user.user_id,
          },
        })
      );
    }
  };

  const setDeliveryMode = (deliveryMode) => {
    switch (deliveryMode) {
      case "virtual_broadcast":
        return "Virtual Broadcast";
      case "virtual_interactive":
        return "Virtual Interactive";
      case "face_to_face":
        return "In-person";
    }
  };

  const proceedToRegister = (session, type) => {
    clickStreamUser("register-button-clicked", {
      event_id: event_id,
      session_id: session.session_id,
      session_name: session.session_name,
      user_id: authBroker.payload.user.user_id,
      type: type,
    });
    dispatch(
      getEnrollBrokerSession({
        module: "enrollBrokerSession",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          session_id: session.session_id,
          event_id: event_id,
          type: type,
        },
      })
    );
    setIsLoading(true);
  };

  const proceedToBook = (session) => {
    clickStreamUser("proceed-to-book-session", {
      event_id: event_id,
      session_id: session.session_id,
      session_name: session.session_name,
      user_id: authBroker.payload.user.user_id,
    });
    history.push(`shopping/${session.product_id}?addToCart=${session.is_addtocart}&source=session`)
    setIsLoading(true);
  };

  const launchSession = (session, eventDetail) => {
    clickStreamUser("launch-session", {
      event_id: event_id,
      session_id: session.session_id,
      session_name: session.session_name,
      user_id: authBroker.payload.user.user_id,
    });
    if (session.is_broadcast) {
      dispatch(boardcastDataResponse({
        eventId: event_id,
        sessionId: session.session_id,
        source: session.source
      }))
      history.push('/session/broadcast/watch');
    } else if(session.join_url){
			window.open(session.join_url, "_blank");
		}
    else {
      if (eventDetail.meeting_id) {
        if (eventDetail.join_url) {
       window.open(eventDetail.join_url, "_blank");
        } else if(eventDetail.is_Zoom_integration === "1"){
          dispatch(
            joinSession({
              module: "joinZoomMeeting",
              postData: {
                user_id: authBroker.payload.user.user_id,
                meeting_id: eventDetail.meeting_id,
                session_id: session.session_id,
                segment_id: eventDetail.segment_id,
              },
            })
          );
          setIsLoading(true);
        }
      } else {
        window.scrollTo(0, 0);
        setShowInfoModalMsg("Host has not yet started the meeting.");
        setInfoModal(true);
        setTimeout(() => {
          setInfoModal(false)
        }, 5000)
      }
    }
  };

  const cancelConfirm = (session) => {
    setSessionDataForModal(session)
    setConfirmModal(true)
  }

  const cancelSession = (session) => {
    setConfirmModal(false)

    clickStreamUser("cancel-session", {
      event_id: event_id,
      session_id: session.session_id,
      session_name: session.session_name,
      user_id: authBroker.payload.user.user_id,
    });
    dispatch(
      cancelSessionData({
        module: "sessionCancel",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          session_id: session.session_id,
          session_title: session.session_name,
          event_id: event_id,
          origin: source === 'broker' ? 'broker' : 'xsel'
        },
      })
    );
    setIsLoading(true);
  };

  const watchSession = async (session, watchURL, segment) => {
    clickStreamUser("watch-session-btn-clicked", {
      event_id: event_id,
      session_id: session.session_id,
      session_name: session.session_name,
      user_id: authBroker.payload.user.user_id,
    });
    if(segment.meeting_id){
      const res = await getAPI({
        module: "getZoomMeetingRecordings",
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        MeetingId:segment.meeting_id,
        SegmentId:segment.segment_id
      });
      if (res.status == "success") {
        setRecordingURLs(res?.payload)
        setWatchRecordings(true);
      } else{
        setRecordingURLs([]);
        setWatchRecordings(true);
      }
    }else{
      dispatch(
        sessionWatchURLDataResponse({
          id: session.session_id,
          title: session.session_name,
          watch_url: watchURL,
        })
      );
      history.push("/session/watch");
     }
  };

  const proceedToHost = (session, segment) => {
    if (session.is_broadcast) {
      dispatch(boardcastDataResponse({
        eventId: event_id,
        sessionId: session.session_id,
        source: session.source
      }))
      history.push('/session/broadcast/watch');
    } else {
      if (session.start_url) {
        window.open(session.start_url, "_blank");
      } else if (segment.is_Zoom_integration === "1"){
        dispatch(
          hostSession({
            module: "createZoomMeeting",
            postData: {
              user_id: session.userId,
              host_zoom_id: session.hostZoomId,
              meeting_topic: session.session_name,
              meeting_agaenda: session.session_name,
              meeting_startdate: moment.utc(session.startUTC).format("YYYY-MM-DD HH:mm") + ":00",
              duration: _.toNumber(session.durationValue),
              session_id: session.sessionId,
              segment_id: session.segmentId,
            },
          })
        );
        setIsLoading(true);
      }
      else{
        window.scrollTo(0, 0);
        setShowInfoModalMsg("Not able to start the meeting.");
        setInfoModal(true);
        setTimeout(() => {
          setInfoModal(false)
        }, 5000)
      }
    }
  };

  const renderButton = (session, segment, showSession) => {
    if (source === "broker") {
      if (session.is_host) {
        if (!session?.is_past) {
          if (showSession) {
            return (
              <p className="text-left_ED">
                <button
                  type="button"
                  className={`btn btn-primary btn-primaryBook_ED cursor-pointer`}
                  onClick={() => proceedToHost(session, segment)}
                >
                  Host
                </button>
              </p>
            );
          } else {
            return (
              <p className="text-left_ED">
                <button
                  type="button"
                  className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn disabled"
                  onClick={() => proceedToHost(session, segment)}
                >
                  Host
                </button>
              </p>
            );
          }
        }
      } else if (!session.is_registered && !session.is_participant) {
        if (session?.is_past) {
          return (
            <button
              type="button"
              className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn disabled"
              title={"Session is past"}
              onClick={() => {
                setShowInfoModalMsg("Session is past");
                setInfoModal(true);
              }}
            >
              Register
            </button>
          );
        } else {
          return (
            <p className="text-left_ED">
              <button
                type="button"
                className={`btn btn-primary btn-primaryBook_ED cursor-pointer`}
                onClick={() => proceedToRegister(session, "broker")}
              >
                Register
              </button>
            </p>
          );
        }
      } else {
        return <p className="text-left_ED">{renderJoinButton(session,segment,showSession)}</p>;
      }
    } else {
      return (
        <p className="text-left_ED">
          {!session?.is_paid && !session?.is_registered ? (
            <>
              {session?.can_enroll ? (
                session.is_past ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn disabled"
                    title={"Session is past"}
                    onClick={() => {
                      setShowInfoModalMsg("Session is past");
                      setInfoModal(true);
                    }}
                  >
                    Register
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary btn-primaryBook_ED cursor-pointer"
                    onClick={() => proceedToRegister(session, "xsel")}
                  >
                    Register
                  </button>
                )
              ) :
                session.is_addtocart ?
                  (
                    <button
                      type="button"
                      className="btn btn-primary btn-primaryBook_ED cursor-pointer in-cart-event"
                      onClick={() => proceedToBook(session)}
                    >
                      Added in Cart
                    </button>
                  )
                  :
                  (
                    <button
                      type="button"
                      className="btn btn-primary btn-primaryBook_ED cursor-pointer"
                      onClick={() => proceedToBook(session)}
                    >
                      Book
                    </button>
                  )}
            </>
          ) : (
            <>{renderJoinButton(session,segment,showSession)}</>
          )}
        </p>
      );
    }
  };

  const renderJoinButton = (session,eventDetail,showSession) => {
    if (session.delivery_mode === "face_to_face") {
      return <div className="d-flex flex-column gap-2">
        <button
          type="button"
          className="btn btn-primary btn-primaryBook_ED cursor-pointer"
          disabled
          onClick={() => launchSession(session, eventDetail)}
        >
        In-person
        </button>
        {/* <button
          type="button"
          className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
          onClick={() => cancelConfirm(session)}
        >
          Cancel
        </button> */}
      </div>
    } else if (showSession) {
      return (
        <button
          type="button"
          className="btn btn-primary btn-primaryBook_ED cursor-pointer"
          onClick={() => launchSession(session,eventDetail)}
        >
          Join
        </button>
      );
    } else {
      if (!session.is_past) {
        return (
          <div className="d-flex flex-column gap-2">
            <button
              type="button"
              className="btn btn-primary btn-primaryBook_ED cursor-pointer"
              disabled
              onClick={() => launchSession(session, eventDetail)}
            >
              Join
            </button>
            {/* <button
              type="button"
              className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
              onClick={() => cancelConfirm(session)}
            >
              Cancel
            </button> */}
          </div>
        );
      } else if (session.watch_url) {
        // return (
        //   <button
        //     type="button"
        //     className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
        //   >
        //     Watch
        //   </button>
        // );
      }
    }
  };

  const renderAlertCard = () => {
    return (
      <div
        className={`alert-card d-flex justify-content-center topsecalert ${infoModal ? "" : "d-none"
          }`}
      >
        <div className="shop-cart order-details event-notify-sucess">
          <div className="shop-info justify-content-center">
            <p className="m-0">{showInfoModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            {/* <span
                            className="cursor-pointer"
                            onClick={() => {
                                clickStreamUser('go-to-cart-from-shopping-cart-btn-clicked', {
                                    userId: auth.authBroker?.payload.user.user_id,
                                    ecommerceId: courseId
                                })
                                history.push('/cart')
                            }}
                        >View Cart</span> */}
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setInfoModal(false);
                setShowInfoModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const onNotifyMeHandler = () => {
    setIsLoaded(true);
    dispatch(
      eventNotification({
        module: "event_Notify_Me",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          event_id: event_id,
          origin: source === "broker" ? "broker" : "xsel",
          status: "checked",
        },
      })
    );
    setNotifyMeBtn(true);

    // window.scrollTo(0, 0);
    // setShow(true);
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
    // alert("We will notify you whenever the session is addes to this event");
  };
  //unchecked notify me
  const uncheckNotifyMeHandler = () => {
    // token: <user-token>,
    // event_id : <event_id>,
    setIsLoaded(true);
    dispatch(
      eventNotification({
        module: "event_Notify_Me",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          event_id: event_id,
          origin: source === "broker" ? "broker" : "xsel",
          status: "unchecked",
        },
      })
    );
    setNotifyMeBtn(false);

    // window.scrollTo(0, 0);
    // setShow(true);
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
    // alert("We will notify you whenever the session is addes to this event");
  };

  const renderTableBody = () => {
    return sessionList.map((session, index) => {
      return (
        <>
          <TableRow
            key={`session-${index}`}
            sx={{ "& > *": { borderBottom: "unset" } }}
          >
            <TableCell>
              <div className="d-flex align-items-start gap-2">
                <div
                  onClick={() => {
                    const openList = _.clone(open);
                    openList[index] = !openList[index];
                    setOpen(openList);
                  }}
                >
                  {open[index] ? (
                    <i className="fa fa-minus"></i>
                  ) : (
                    <i className="fa fa-plus"></i>
                  )}
                </div>
                <div className="tableName">
                  <div>{session.session_name}</div>
                  <div className="mt-2">
                    {session.date_time.length}{" "}
                    {session.date_time.length === 1 ? "Part" : "Parts"}
                  </div>
                </div>
              </div>
              {/* <b> {session.startDate.split(',')[0]},</b> {session.startDate.split(',')[1]} <br /> <span><b> {session.startTime} </b>hrs</span> <b> EST</b> */}
            </TableCell>
            <TableCell>{session?.provider?.provider_display_name}</TableCell>
            <TableCell>{session.host}</TableCell>
            <TableCell>{setDeliveryMode(session.delivery_mode)}</TableCell>
            <TableCell className="text-capitalize">
              {/* {session.channel_name} */}
              {session.delivery_mode === "face_to_face" ? <><strong>Address</strong><br />{session.location}</> : "Live"}
            </TableCell>
            {source != "broker" ? (
              <>
                {_.toInteger(session.selling_price) != 0 ? (
                  <TableCell className={`${session.isOffer ? 'd-flex flex-column gap-2 w-100 h-100' : ''}`}>
                    {
                      session.isOffer ?
                        <>
                          <span className="offer-tag-session">OFFER</span>
                          <div className="strike-through">
                            ${session.originalPrice}
                            CAD
                          </div>
                          <div>
                            <span className="supscript_ED"> $</span>
                            <span className="priceED">
                              {session.selling_price.split(".")[0]}
                            </span>
                            <sup>
                              <b>{session.selling_price.split(".")[1]}</b>
                            </sup>{" "}
                            CAD
                          </div>
                        </>
                        :
                        <>
                          <span className="supscript_ED"> $</span>
                          <span className="priceED">
                            {session.selling_price.split(".")[0]}
                          </span>
                          <sup>
                            <b>{session.selling_price.split(".")[1]}</b>
                          </sup>{" "}
                          CAD
                        </>
                    }
                  </TableCell>
                ) : (
                  <TableCell>
                    <b>FREE</b>
                  </TableCell>
                )}


              </>
            ) : (
              ""
            )}
            {/* {userRole === "manager" ? <TableCell>
              <p className="text-left_ED">

                {session.product_basic_price === "" && !session.is_past ? <button
                  type="button"
                  className={`btn btn-primary btn-primaryBook_ED cursor-pointer`}
                  onClick={() => {
                    dispatch(setCurrentView(1))
                    setShow(true)
                    setSelectedSession(source === 'broker' ? session.session_id : session.course_code)
                  }}>
                  Assign
                </button> : ""}
              </p>
            </TableCell> : ""} */}
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={source != "broker" ? 7 : 6}
              style={{ paddingBottom: 0, paddingTop: 0, height: 0 }}
              className="bg-white br-0"
            >
              <Collapse in={open[index]} timeout="auto" unmountOnExit>
                <Table className="clear-border" component={Paper}>
                  <TableHead>
                    <TableRow>
                      <TableCell scope="col" className="col-md-2 table-title" />
                      <TableCell scope="col" className="col-md-2 table-title">
                        Start Date & Time
                      </TableCell>
                      <TableCell scope="col" className="col-md-2 table-title">
                        End Date & Time
                      </TableCell>
                      <TableCell
                        scope="col"
                        className="col-md-2 table-title"
                      ></TableCell>
                      {/* <TableCell
                        scope="col"
                        className="col-md-2 table-title"
                      ></TableCell> */}
                      {/* {source != "broker" ? (
                        <TableCell
                          scope="col"
                          className="col-md-2 table-title"
                        ></TableCell>
                      ) : (
                        <TableCell
                          scope="col"
                          className="col-md-2 table-title"
                        ></TableCell>
                      )} */}
                      <TableCell
                        scope="col"
                        className="col-md-2 table-title"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {session.date_time &&
                      session.date_time.map((date, index) => {
                        const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        const startTime = moment(date.startDateTime).subtract(30, 'minutes');
                        const endTime = moment(date.endDateTime);
                        const currentTime = moment();
                        const showSession = currentTime.isBetween(startTime, endTime);

                        return (
                          <>
                          <TableRow key={`date-${index}`}>
                            <TableCell>Part {index + 1}</TableCell>
                            <TableCell>
                              {date.startD}
                              <br />
                              {/* at&nbsp; */}
                              {date?.startDateTime?.format("hh:mm A") +
                                " " +
                                date.timezone}
                            </TableCell>
                            <TableCell>
                              {date.endD}
                              <br />
                              {/* at&nbsp; */}
                              {date?.endDateTime?.format("hh:mm A") +
                                " " +
                                date.timezone}
                            </TableCell>
                            {/* <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell> */}
                            <TableCell>
                              {session.is_registered &&
                                date.is_past && (date.watch_url || date.meeting_id) ? (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
                                  onClick={() =>
                                    watchSession(session, date.watch_url, date)
                                  }
                                >
                                  Recordings
                                </button>
                              ) : (
                                renderButton(session, date, showSession)
                              )}
                            </TableCell>
                            <TableCell><div
                                  className=""
                                  onClick={() => handleFeedback(session, date)}
                                >
                                  {new Date() > new Date(date?.endDateTime) && (
                                    <div className="btn btn-primary feedbackbtn">
                                      <img
                                        src="/images/feedback.png"
                                        className="img-fluid me-2"
                                      />
                                      Feedback
                                    </div>
                                  )}
                                </div></TableCell>
                          </TableRow>
                         <FeedbackSucessPopup
                           submitFeedbackSuccess={showReminderPopupStep2}
                           setSubmitFeedbackSuccess={() =>
                             setShowReminderPopupStep2(false)
                           }
                         />
                       </>
                     );
                   })}
                    {selectedDate && (
                        <SingleCompletedPopup
                          name={name || ""}
                          setShow={setShowFeedbackPopup}
                          show={showFeedbackPopup && session.session_name === name}
                          userName={authBroker?.payload?.user?.firstname || ""}
                          showReminderPopupStep2={showReminderPopupStep2}
                          setShowReminderPopupStep={() => handleSubmitReminderFirstPopup()}
                          questions={feedbackQuestions}
                          courseId={session?.event_id}
                          type={type}
                          sub_category_id={selectedDate?.segment_id}
                          lastModule={[]}
                        />
                      )}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      );
    });
  };


  const renderNotifyBody = () => {
    return (
      <>
        <div>
          <div className="session-found-notify no-session-found">
            {/* <div className="img-no-session">
              <img
                src="/images/no-session-found.svg"
                alt="No session found"
              />
            </div> */}
            <p className="no-ssesion-found-text">
              Want to get notified, when more sessions will be added? Click on
              the below checkbox.
            </p>
            <div className="d-flex btnsnotify">
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                name="notify-me"
                checked={notifyMeBtn ? true : ""}
                onClick={(e) => {
                  if (e.target.checked) {
                    onNotifyMeHandler();
                  } else {
                    uncheckNotifyMeHandler();
                  }
                }}
              />
              &nbsp;Notify Me
              {/* <button
              className="notfime"
              disabled={notifyMeBtn}
              onClick={onNotifyMeHandler}
            >
              Notify Me
            </button>
            <button
              className="notfime"
              onClick={uncheckNotifyMeHandler}
            >
              Deselect
            </button> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderEmptySessionTable = () => {
    return (
      <>
        <div>
          <div className="session-found-notify no-session-found">
            <div className="img-no-session">
              <img
                src="/images/no-session-found.svg"
                alt="No session found"
              />
            </div>
            <p className="no-ssesion-found-text">
              No sessions currently available.
            </p>
          </div>
        </div>
      </>
    );
  };

  const openUrlInNewTab =(url)=>{
    window.open(url, '_blank');
  }

  return (
    <div>
      <Loader start={isLoaded} />
      <Dialog className="recordingPopup" open={watchRecordings} onClose={() => setWatchRecordings(false)}>
        <DialogTitle sx={{ backgroundColor: '#2196f3', color: 'white' }}>Recording URLs</DialogTitle>
        <DialogActions className="dialogCloseBtn">
          <Button onClick={() => setWatchRecordings(false)}><i class="fa fa-times" aria-hidden="true"></i></Button>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            <ul>
            {recordingURLs && recordingURLs.length > 0 && recordingURLs.map((url, index) => (
              <li key={index}>
                <p>Recording - {index + 1}</p>
                <button className="linkClickBtn btn" onClick={() => openUrlInNewTab(url)}>Watch</button>
              </li>
            ))}
            </ul>
            {recordingURLs && recordingURLs.length === 0 && <p>Recordings are not available, please check later.</p>}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <section
        className="event_ED_BGimage"
        style={{ background: `url(${eventDetail?.bannerURL})` }}
      >
        <div className="row realtorRow event_banner">
          <div className=" col-md-6 bg-whites opacity-68 realtor_col"></div>
        </div>
        <div className="realtor_content">
          <div className="container position-relative">
            {renderAlertCard()}
            <div className="header_img_left_ED">
              {/* <h6
                dangerouslySetInnerHTML={{
                  __html: eventDetail?.event_name,
                }}
              ></h6> */}
            </div>
          </div>
        </div>
      </section>
      <section className="container ">
        {!_.isEmpty(eventDetail) ? (
          <>
            <div className="row">
              <div className="col-md-12 main_realtor mt-5">
                <h6 className="pb-3">
                  Welcome to the
                  <br />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: eventDetail?.event_name,
                    }}
                  ></span>
                </h6>
                <div
                  className="event-details-disc"
                  dangerouslySetInnerHTML={{
                    __html: eventDetail?.event_description,
                  }}
                ></div>
              </div>
            </div>

            <div className="Registration mt-4 ">
              <p>Event</p>
            </div>
          </>
        ) : (
          ""
        )}
        {isSessionDataLoaded ? (
          sessionList.length > 0 ? (
            <div className="registration">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead className="thead_bdy_ED thead_bdy_border">
                    <TableRow>
                      <TableCell scope="col" className="col-md-2 table-title">
                        Session
                      </TableCell>
                      <TableCell scope="col" className="col-md-2 table-title">
                        Provider
                      </TableCell>
                      {/* <TableCell scope="col" className="col-md-2 table-title">
                          Host
                        </TableCell> */}
                      <TableCell scope="col" className="col-md-2 table-title">
                        Instructor
                      </TableCell>
                      <TableCell scope="col" className="col-md-2 table-title">
                        Delivery Mode
                      </TableCell>
                      <TableCell scope="col" className="col-md-1 table-title">
                        Location
                      </TableCell>
                      {source != "broker" ? (
                        <TableCell scope="col" className="col-md-2 table-title">
                          Pricing
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {/* {userRole === "manager" ? <TableCell scope="col" className="col-md-1 table-title">
                        Assign
                      </TableCell> : ''} */}
                    </TableRow>
                  </TableHead>
                  <TableBody className="tble_bdy">
                    {renderTableBody()}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {source === 'ecom' ? renderNotifyBody() : ''} */}
            </div>
          ) : (
            <>
              <div>
                {source === "ecom" ? (
                  <div className="no-session-found">
                    <div className="img-no-session">
                      <img
                        src="/images/no-session-found.svg"
                        alt="No session found"
                      />
                    </div>
                    <p className="no-ssesion-found-text">
                      No sessions currently available. Click on the below
                      checkbox to get notified, when more session are added.
                    </p>

                    <div className="d-flex btnsnotify">
                      <input
                        type="checkbox"
                        className="form-check-input cursor-pointer"
                        name="notify-me"
                        checked={notifyMeBtn ? true : ""}
                        onClick={(e) => {
                          if (e.target.checked) {
                            onNotifyMeHandler();
                          } else {
                            uncheckNotifyMeHandler();
                          }
                        }}
                      />
                      &nbsp;Notify Me
                      {/* <button
                      className="notfime"
                      disabled={notifyMeBtn}
                      onClick={onNotifyMeHandler}
                    >
                      Notify Me
                    </button>
                    <button
                      className="notfime">
                      Deselect
                    </button> */}
                    </div>
                  </div>
                ) : (
                  renderEmptySessionTable()
                )}
              </div>

              {show && (
                <div className="alert-card d-flex justify-content-center event-alter-sucess">
                  <div className="shop-cart order-details event-notify-sucess">
                    <div className="shop-info justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <g
                          id="Group_5699"
                          data-name="Group 5699"
                          transform="translate(0.498 0.498)"
                        >
                          <circle
                            id="Ellipse_222"
                            data-name="Ellipse 222"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(-0.498 -0.498)"
                            fill="#A9C23C"
                          />
                          <path
                            id="Icon_material-done"
                            data-name="Icon material-done"
                            d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                            transform="translate(-3.013 -6.071)"
                            fill="#fff"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                        </g>
                      </svg>
                      <p className="m-0">
                        We will notify you whenever a session is added to this
                        event.
                      </p>
                    </div>
                    <div className="view-cart-icon">
                      {/* <span className="cursor-pointer">View Cart</span> */}
                      <i
                        className="fa fa-times cursor-pointer"
                        aria-hidden="true"
                        onClick={() => setShow(false)}
                      ></i>
                    </div>
                  </div>

                  {/* <button
              className={"btn btn-primary btn-primaryBook_ED cursor-pointer"}
                style={{marginLeft: "40%", width: "230px" }}
                disabled={notifyMeBtn}
                onClick={onNotifyMeHandler}
              >
                Notify Me
              </button> */}
                </div>
              )}
            </>
          )
        ) : (
          <div className="d-flex w-100 gap-2 align-items-center justify-content-start">
            Loading sessions
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        )}
      </section>
      <AssignCourseModal
        show={show}
        parentName={'Event Session'}
        parentId={selectedSession}
        setShow={setShow}
        origin={source === "xsel" || source === "ecom" ? "xsel" : "broker"}
        isMobile={false}
      />
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={showModalMsg}
        isConfirm={true}
        confirmId={sessionDataForModal}
        confirmAction={cancelSession}
      />
    </div>

  );
};

export default EventDetailSection;
