import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import session from "redux-persist/lib/storage/session";
import {
	getCertifiedDetailsBroker, getCertifiedDetailsBrokerClear,
	getCertifiedDetailsNIIT, getCertifiedDetailsNIITClear,
	getSessionNIITList, getSessionNIITListClear,
	getSessionBrokerList, getSessionBrokerListClear, getEnrollBrokerSession,
	getEnrollBrokerSessionClear
} from "../../redux/certified/actions";
import {
	joinSession,
	joinSessionClear,
	eventNotification,
	checkEventNotification,
	eventNotificationClear,
	checkEventNotificationClear,
	setEnrollBrokerSessionResponse,
	cancelSessionData,
	cancelSessionDataClear,
	hostSessionClear,
	hostSession,
	sessionWatchURLDataResponse,
	boardcastDataResponse,
	sessionWatchURLDataClear,
} from "../../redux/events/actions";
import { clickStreamUser, SessionKeys, timezoneArea, timezoneTagName } from "../../utils/AppUtils";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { cartPageDataResponse } from '../../redux/checkout/actions';
import { fetchTimezoneAbbr, fetchTimezoneAbbrClear } from "../../redux/user/actions";
import { addToCart, addToCartClear } from "../../redux/product/actions";
import ConfirmModal from "../modal/ConfirmModal";
import { setCurrentView } from "../../redux/assignCourse/actions";
import AssignCourseModal from "../modal/AssignCourseModal";

const CertifyDetailSection = ({ setIsLoading }) => {

	const history = useHistory()
	const dispatch = useDispatch();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const certify_id = params.get('certify_id');
	const certify_code = params.get('certify_code');
	const source = params.get('source');

	const sessionListRes = useSelector(state => state.certified.sessionList);
	const sessionBrokerListRes = useSelector(state => state.certified.sessionListBroker);
	const certifyDetailRes = useSelector(state => state.certified.certifyDetail);
	const certifyDetailResBroker = useSelector(state => state.certified.certifyDetailBroker);
	const enrollBrokerSession = useSelector(state => state.certified.enrollBrokerSession);
	const addToCartRes = useSelector(state => state.product.addToCart)
	const notfiyEnrolled = useSelector(state => state.events.eventNotification)
	const authBroker = useSelector((state) => state.auth.authBroker);
	const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
	const joinSessionData = useSelector((state) => state.events.joinSession);
	const checkNotified = useSelector((state) => state.events.checkNotified);
	const sessionCancelledData = useSelector(state => state.events.sessionCancel)
	const hostSessionInfo = useSelector(state => state.events.hostSession);
	const userDetailRes = useSelector(state => state.user.userBroker)
	const timezoneAbbr = useSelector(state => state.user.timezoneAbbr)
	const userRole = useSelector(state => state.user.role)

	const [isSessionDataLoaded, setIsSessionDataLoaded] = useState(false);
	const [sessionList, setSessionList] = useState([]);
	const [certifyDetail, setCertifiedDetail] = useState({});
	const [open, setOpen] = useState([]);
	const [infoModal, setInfoModal] = useState(false)
	const [showInfoModalMsg, setShowInfoModalMsg] = useState("")
	const [notifyMeBtn, setNotifyMeBtn] = useState(false);
	const [show, setShow] = useState(false);
	const [isNotified, setIsNotified] = useState(false);
	const [userDetails, setUserDetails] = useState({})
	const [zoneAbbr, setZoneAbbr] = useState('CDT')

	const [selectedSession, setSelectedSession] = useState('')

	const [confirmModal, setConfirmModal] = useState(false)
	const [showModalMsg,] = useState('Would you like to unregister from the session?')
	const [sessionDataForModal, setSessionDataForModal] = useState({})



	useEffect(() => {
		if (!_.isEmpty(authBroker)) {
			clickStreamUser('certify-detail-page', {
				certifyId: certify_id,
				user_id: authBroker?.payload?.user?.user_id
			})
		}
	}, [authBroker])

	useEffect(() => {
		// console.log('userDetailRes: ', userDetailRes)
		if (!_.isEmpty(userDetailRes?.payload?.payload)) {
			setUserDetails(userDetailRes?.payload?.payload)
			dispatch(fetchTimezoneAbbr({
				module: 'fetchTimezoneAbbr',
				postData: {
					offset: userDetailRes.payload.payload.timezone
				}
			}))
		}
	}, [userDetailRes])

	useEffect(() => {
		if (!_.isEmpty(timezoneAbbr)) {
			if (timezoneAbbr.status === 'success') {
				// console.log('timezoneAbbr', timezoneAbbr)
				const timezoneAbbrData = timezoneAbbr.payload?.payload
				if (!_.isEmpty(timezoneAbbrData)) {
					const abbr = timezoneAbbrData?.abbr[0]?.abbreviations
					setZoneAbbr(abbr)
				}
			}
			dispatch(fetchTimezoneAbbrClear())
		}
	}, [timezoneAbbr])

	//SESSIONS INFO
	useEffect(() => {
		// console.log("onurlchage1: ")
		if (!_.isEmpty(authBroker)) {
			if (source === 'broker' && !_.isEmpty(authBroker)) {
				dispatch(getCertifiedDetailsBroker({
					module: 'certifyDetailBroker',
					postData: {
						certify_id: certify_id,
						token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
					}
				}))
				dispatch(getSessionBrokerList({
					module: 'sessionBrokerList',
					postData: {
						token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
						certify_id: certify_id,
						broker_id: authBroker?.payload?.user?.broker_id
					}
				}))
			} else {
				dispatch(getSessionNIITList({
					module: 'certifySessionList',
					postData: {
						email: authBroker.payload.user.email,
						event_id: certify_id,  //it was certify_id
						user_id: authBroker.payload.user.user_id,
						brokerage_id: authBroker.payload.user.company_id
					}
				}))

				dispatch(getCertifiedDetailsNIIT({
					module: "getCertifiedDetails",
					postData: {
						module: "CertificateDetails",
						c_code: certify_code
					}
				}))
			}
			setNotifyMeBtn(false)
			setInfoModal(false)
			setIsLoading(true)
			dispatch(
				checkEventNotification({
					module: "check-notified",
					postData: {
						token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
						event_id: certify_id,
						origin: source === "broker" ? "broker" : "xsel",
					},
				})
			);
			dispatch(sessionWatchURLDataClear());
		}
	}, [dispatch, authBroker, source, certify_id, certify_code])

	useEffect(() => {
		// setShowInfoModalMsg(addToCartRes?.payload.message)
		if (Object.keys(addToCartRes).length !== 0) {
			history.push('/cart')
			dispatch(addToCartClear())
		}
	}, [addToCartRes])

	useEffect(() => {
		if (selectedSession !== '') {
		  setShow(true)
		}
	  }, [selectedSession])

	useEffect(() => {
		// console.log("joinSessionData", joinSessionData);
		if (!_.isEmpty(joinSessionData)) {
			setIsLoading(false)
			if (joinSessionData.status === "success") {
				if (!_.isEmpty(joinSessionData.payload.payload)) {
					const sessionData = joinSessionData.payload.payload;
					if (sessionData.join_url) {
						window.open(sessionData.join_url, "_blank");
					} else {
						window.scrollTo(0, 0)
						setShowInfoModalMsg('Error joining session, please contact support')
						setInfoModal(true);
					}
				}
			} else {
				// console.log(joinSessionData.error.message);
				window.scrollTo(0, 0)
				// setShowInfoModalMsg(joinSessionData.error.message);
				setShowInfoModalMsg('Error joining session, please contact support')
				setInfoModal(true);
			}
			dispatch(joinSessionClear());
		}
	}, [joinSessionData]);

	useEffect(() => {
		// console.log("sessionListRes: ", sessionListRes);
		if (!_.isEmpty(sessionListRes)) {
			setIsLoading(false);
			if (sessionListRes?.status === "success") {
				// console.log('allEvents1: ', sessionListRes.payload.payload)
				if (!_.isEmpty(sessionListRes.payload.payload)) {
					let sessionList = _.clone(sessionListRes.payload.payload.sessionList);
					if (_.isArray(sessionList)) {
						let newSessionList = [];
						for (let sessionData of sessionList) {
							let session = _.cloneDeep(sessionData);
							let api_date_format = "YYYY-MM-DD HH:mm";
							// const timezoneOffset = !_.isEmpty(userDetails.timezone)
							// 	? userDetails.timezone.replace(":", "")
							// 	: "-0500";
							const timezoneOffset = session.timezone.includes(":") ? session.timezone.replace(":", "") : "+0530";
							// const area = session.timezoneAbbr ? session.timezoneAbbr : 'America/New_York'; //timezoneArea(timezoneOffset);
							const area = timezoneArea(timezoneOffset);
							session.startDate = moment(session.startDate).format(
								"MMM DD, YYYY"
							);
							session.startTime = moment(session.startTime).format("HH") * 100;
							if (session.startTime < 1000) {
								session.startTime = "0" + session.startTime;
							}
							session.can_enroll = false;
							session.hostZoomId = authBroker.payload.user.zoom_id;
							session.userId = authBroker.payload.user.user_id;
							session.host = session.display_name;
							session.show_join = false
							session.isOffer = false;
							session.originalPrice = session.selling_price;
							session.is_boardcast = session.delivery_mode === 'virtual_broadcast'

							if (isPremiumUser) {
								if (
									session.sfdc_product_type.toLowerCase() === "basic" ||
									session.sfdc_product_type.toLowerCase() === "premium"
								) {
									session.can_enroll = true;
								}
								if (session.offer_available) {
									session.isOffer = true;
									session.originalPrice = session.product_premium_price
								}
							} else {
								session.can_enroll =
									session.sfdc_product_type.toLowerCase() === "basic";
								if (session.offer_available) {
									session.isOffer = true;
									session.originalPrice = session.product_basic_price
								}
							}

							if (session.register) {
								session.is_paid = true;
							}

							for (let record of session.date_time) {
								const userTz = moment.tz.guess();
								// const currentTime = moment().tz(userTz);
								// const currentTime = moment().utcOffset(timezoneOffset);
								const currentTime = moment().tz(area);
								session.watch_url = record.watch_url;
								record.startDateTime = moment
									.utc(
										record?.start_date?.replaceAll("-", "/") +
										" " +
										record.start_time
									)
									.tz(area)
								// .tz(userTz);
								record.endDateTime = moment
									.utc(
										record?.end_date?.replaceAll("-", "/") +
										" " +
										record.end_time
									)
									.tz(area)
								// .tz(userTz);
								record.timezone = zoneAbbr; // moment.tz(userTz).format("z");
								// const startDateTime = moment.utc(event_time.start_date.replaceAll('-', '/') + ' ' + event_time.start_time).tz(userTz);
								// const endDateTime = moment.utc(event_time.end_date.replaceAll('-', '/') + ' ' + event_time.end_time).tz(userTz);
								record.startUTC = record?.start_date?.replaceAll("-", "/") +
									" " +
									record.start_time;
								record.startD = record.startDateTime.format("DD MMM YYYY");
								record.endD = record.endDateTime.format("DD MMM YYYY");
								// console.log(
								//   "outofsession: ",
								//   session,
								//   record.endDateTime.format("DD MMM YYYY"),
								//   currentTime.isAfter(record.endDateTime),
								//   session.session_name
								// );
								record.timezone = timezoneTagName(session.timezoneAbbr);
								session.show_join = session.show_join ? session.show_join : currentTime.isBetween(
									record.startDateTime,
									record.endDateTime
								);
								session.is_past = currentTime.isAfter(record.endDateTime);
								record.is_past = currentTime.isAfter(record.endDateTime);
								session.currentTime = currentTime.format(api_date_format);
								session.is_host = record.is_host;
								session.is_participant = record.is_participant;
								session.meeting_id = record.meeting_id;
								session.segmentId = record.segment_id;
								session.start = moment(
									record.startDateTime.format(api_date_format)
								).toDate();
								session.end = moment(
									record.endDateTime.format(api_date_format)
								).toDate();
								session.start_url = record.start_url ? record.start_url : "";
								session.join_url = record.join_url ? record.join_url : "";
								session.durationValue = _.toInteger(
									moment
										.duration(record.endDateTime.diff(record.startDateTime))
										.asMinutes()
								);
								session.duration =
									_.toInteger(
										moment
											.duration(record.endDateTime.diff(record.startDateTime))
											.asMinutes()
									) + "mins";
								session.is_Zoom_integration =  record.is_Zoom_integration	
							}
							if (!session.is_past) {
								// console.log("Session Data", session);
								newSessionList.push(session);
							} else if (session.is_past && session.is_registered) {
								newSessionList.push(session);
							}
						}
						setSessionList(newSessionList);
						setIsSessionDataLoaded(true);
					} else {
						setSessionList([]);
						setIsSessionDataLoaded(true);
					}
				}
			} else {
				setIsSessionDataLoaded(true);
				setSessionList([]);
			}
			dispatch(getSessionNIITListClear());
		}
	}, [sessionListRes]);

	useEffect(() => {
		// console.log('sessionListRes: ', sessionBrokerListRes)
		if (!_.isEmpty(sessionBrokerListRes) && sessionBrokerListRes?.status === "success") {
			// console.log('allCertified1: ', sessionBrokerListRes.payload.payload)
			if (!_.isEmpty(sessionBrokerListRes.payload.payload)) {
				let sessionList = sessionBrokerListRes.payload.payload.sessionList;
				if (sessionList !== undefined) {
					setSessionList(sessionList)
					dispatch(getSessionBrokerListClear())
				} else {
					setSessionList([])
				}
			}
		}
	}, [sessionBrokerListRes])

	useEffect(() => {
		// console.log('allCertified1: ', certifyDetailRes)
		if (!_.isEmpty(certifyDetailRes) && certifyDetailRes?.status === "success") {
			let certifyDetail = certifyDetailRes.payload.payload;
			if (!_.isEmpty(certifyDetail)) {
				// console.log('allCertified1: ', certifyDetail)
				setCertifiedDetail(certifyDetail)
			}
			dispatch(getCertifiedDetailsNIITClear())
		}
	}, [certifyDetailRes])

	useEffect(() => {
		if (!_.isEmpty(certifyDetailResBroker) && certifyDetailResBroker?.status === "success") {
			// console.log('allCertified1: ', certifyDetailResBroker)
			setCertifiedDetail(certifyDetailResBroker?.payload.payload[0])
			dispatch(getCertifiedDetailsBrokerClear())
		}
	}, [certifyDetailResBroker])

	useEffect(() => {
		// console.log("enrolled", enrollBrokerSession);
		if (!_.isEmpty(enrollBrokerSession)) {
			if (enrollBrokerSession?.status === "success") {
				if (!_.isEmpty(enrollBrokerSession.payload.payload)) {
					let data = enrollBrokerSession.payload.payload;
					setShowInfoModalMsg(enrollBrokerSession.payload.message);
					window.scrollTo(0, 0);
					setInfoModal(true);
					clickStreamUser("session-registered-successfull", {
						certify_id: certify_id,
						session_id: data.session_id,
						user_id: authBroker?.payload?.user?.user_id,
						message: enrollBrokerSession.payload.message,
					});
					if (data.origin === "broker") {
						dispatch(
							getSessionBrokerList({
								module: "sessionBrokerList",
								postData: {
									token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
									certify_id: certify_id,
									broker_id: authBroker?.payload?.user?.broker_id,
								},
							})
						);
					} else {
						dispatch(getSessionNIITList({
							module: 'certifySessionList',
							postData: {
								email: authBroker.payload.user.email,
								event_id: certify_id,  //it was certify_id
								user_id: authBroker.payload.user.user_id,
								brokerage_id: authBroker.payload.user.company_id
							}
						}))
					}
				} else {
					if (userRole === 'realtor') {
						setShowInfoModalMsg('Error registering session, please contact your manager');
					} else if (userRole === 'manager') {
						setShowInfoModalMsg('Error registering session, please contact your broker admin');
					} else {
						setShowInfoModalMsg('Error registering session, please contact support');
					}
					setInfoModal(true);
					window.scrollTo(0, 0)
				}
			} else if (enrollBrokerSession?.status === "error") {
				setShowInfoModalMsg(enrollBrokerSession.error.message);
				setInfoModal(true);
				window.scrollTo(0, 0)
			}
			setIsLoading(false)
			dispatch(getEnrollBrokerSessionClear());
		}
	}, [enrollBrokerSession]);

	useEffect(() => {
		// console.log('Check notified', checkNotified)
		if (checkNotified.status === 'success') {
			if (checkNotified.payload.payload.is_notified) {
				setNotifyMeBtn(true);
			}
			dispatch(checkEventNotificationClear())
		}
	}, [checkNotified])

	useEffect(() => {
		// console.log('NOTIFIED', notfiyEnrolled)
		if (!_.isEmpty(notfiyEnrolled)) {
			if (notfiyEnrolled.status === 'success') {
				window.scrollTo(0, 0)
				// setNotifyMeBtn(true);
				setShowInfoModalMsg(notfiyEnrolled.payload.message);
				setInfoModal(true);
				dispatch(eventNotificationClear())
			}
		}
	}, [notfiyEnrolled])

	useEffect(() => {
		// console.log('sessionCalledData', sessionCancelledData)
		if (!_.isEmpty(sessionCancelledData)) {
			if (sessionCancelledData.status === 'success') {
				setInfoModal(true);
				window.scrollTo(0, 0)
				setShowInfoModalMsg('Xsel team will connect with you shortly.');
			} else {
				if (userRole === "realtor") {
					setShowInfoModalMsg(
						"Error cancelling session, please contact your manager."
					);
				} else if (userRole === "manager") {
					setShowInfoModalMsg(
						"Error cancelling session, please contact your broker admin."
					);
				} else {
					setShowInfoModalMsg(
						"Error cancelling session, please contact support."
					);
				}
				setInfoModal(true);
				window.scrollTo(0, 0);
			}
			setIsLoading(false)
			dispatch(cancelSessionDataClear())
		}
	}, [sessionCancelledData])

	useEffect(() => {
		if (!_.isEmpty(hostSessionInfo)) {
			setIsLoading(false)
			if (hostSessionInfo.status === "success") {
				if (!_.isEmpty(hostSessionInfo.payload.payload)) {
					// console.log('hostSessionInfo', hostSessionInfo.payload.payload);
					const hostData = hostSessionInfo.payload.payload.data;
					if (hostData.start_url) {
						// window.open(hostData.start_url, '_blank');
					} else {
						window.scrollTo(0, 0)
						setShowInfoModalMsg('Server Error, please contact support');
						setInfoModal(true)
					}
					dispatch(hostSessionClear())
				} else {
					// console.log(hostSessionInfo.error.message)
					window.scrollTo(0, 0)
					setShowInfoModalMsg(hostSessionInfo?.error ? hostSessionInfo?.error.message : "Not able to start the session.");
					setInfoModal(true)
				}
			} else {
				setShowInfoModalMsg(hostSessionInfo?.error ? hostSessionInfo?.error.message : "Not able to start the session.");
				setInfoModal(true)
			}
		}
	}, [hostSessionInfo])

	const setDeliveryMode = (deliveryMode) => {
		switch (deliveryMode) {
			case 'virtual_broadcast':
				return 'Virtual Boardcast'
			case 'virtual_interactive':
				return 'Virtual Interactive'
			case 'face_to_face':
				return 'In-person'
		}
	}

	const proceedToRegister = (session, type) => {
		// console.log("session", session);
		clickStreamUser("register-button-clicked", {
			certify_id: certify_id,
			session_id: session.session_id,
			session_name: session.session_name,
			user_id: authBroker.payload.user.user_id,
			type: type,
		});
		dispatch(
			getEnrollBrokerSession({
				module: "enrollBrokerSession",
				postData: {
					token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
					session_id: session.session_id,
					event_id: certify_id,
					type: type,
				},
			})
		);
		setIsLoading(true);
	};

	const proceedToBook = (session) => {
		// console.log("SESSION", session);
		// return;
		clickStreamUser("proceed-to-book-session", {
			certify_id: certify_id,
			session_id: session.session_id,
			session_name: session.session_name,
			user_id: authBroker.payload.user.user_id,
		});
		history.push(`shopping/${session.product_id}?addToCart=${session.is_addtocart}&source=session`)
		setIsLoading(true);
	};

	const launchSession = (session) => {
		clickStreamUser("launch-session", {
			certify_id: certify_id,
			session_id: session.session_id,
			session_name: session.session_name,
			user_id: authBroker.payload.user.user_id,
		});
		if (session.is_boardcast) {
			dispatch(boardcastDataResponse({
				eventId: certify_id,
				sessionId: session.session_id,
				source: 'xsel'
			}))
			history.push('/session/broadcast/watch');
		} 
		else if(session.join_url){
			window.open(session.join_url, "_blank");
		}
		else {
			if (session.meeting_id) {
				if (session.join_url) {
					window.open(session.join_url, "_blank");
				} else if(session.is_Zoom_integration === "1"){
					dispatch(
						joinSession({
							module: "joinZoomMeeting",
							postData: {
								user_id: authBroker.payload.user.user_id,
								meeting_id: session.meeting_id,
								session_id: session.session_id,
								segment_id: session.segmentId,
							},
						})
					);
					setIsLoading(true);
				} else {
					window.scrollTo(0, 0);
					setShowInfoModalMsg("Host has not yet started the meeting.");
					setInfoModal(true);
				}
			} else {
				window.scrollTo(0, 0);
				setShowInfoModalMsg("Host has not yet started the meeting.");
				setInfoModal(true);
			}
		}
	};

	const cancelConfirm = (session) => {
		setSessionDataForModal(session)
		setConfirmModal(true)
	}

	const cancelSession = (session) => {
		setConfirmModal(false)
		clickStreamUser("cancel-session", {
			certify_id: certify_id,
			session_id: session.session_id,
			session_name: session.session_name,
			user_id: authBroker.payload.user.user_id,
		});
		dispatch(
			cancelSessionData({
				module: "sessionCancel",
				postData: {
					token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
					session_id: session.session_id,
					session_title: session.session_name,
					event_id: certify_id,
					origin: 'xsel'
				},
			})
		);
		setIsLoading(true);
	};

	const watchSession = (session, watchURL) => {
		clickStreamUser("watch-session-btn-clicked", {
			certify_id: certify_id,
			session_id: session.session_id,
			session_name: session.session_name,
			user_id: authBroker.payload.user.user_id,
		});
		dispatch(
			sessionWatchURLDataResponse({
				id: session.session_id,
				title: session.session_name,
				watch_url: watchURL,
			})
		);
		history.push("/session/watch");
	};

	const proceedToHost = (session) => {
		// console.log("zoomMeeting", {
		//   user_id: session.userId,
		//   host_zoom_id: session.hostZoomId,
		//   meeting_topic: session.session_name,
		//   meeting_agaenda: session.session_name,
		//   meeting_startdate: moment(session.start).format("YYYY-MM-DD HH:mm"),
		//   duration: _.toNumber(session.durationValue),
		//   session_id: session.sessionId,
		//   segment_id: session.segmentId,
		// });
		// return;
		if (session.is_boardcast) {
			dispatch(boardcastDataResponse({
				eventId: certify_id,
				sessionId: session.session_id,
				source: 'xsel'
			}))
			history.push('/session/broadcast/watch');
		} else {
			if (session.start_url) {
				window.open(session.start_url, "_blank");
			} else if(session.is_Zoom_integration === "1"){
				dispatch(
					hostSession({
						module: "createZoomMeeting",
						postData: {
							user_id: session.userId,
							host_zoom_id: session.hostZoomId,
							meeting_topic: session.session_name,
							meeting_agaenda: session.session_name,
							meeting_startdate: moment(session.start).format("YYYY-MM-DD HH:mm"),
							duration: _.toNumber(session.durationValue),
							session_id: session.sessionId,
							segment_id: session.segmentId,
						},
					})
				);
				setIsLoading(true);
			} else{
				window.scrollTo(0, 0);
				setShowInfoModalMsg("Not able to start the meeting.");
				setInfoModal(true);
			}
		}
	};

	const renderButton = (session) => {
		if (source === "broker") {
			if (session.is_host) {
				if (!session?.is_past) {
					if (session.show_join) {
						return (
							<p className="text-left_ED">
								<button
									type="button"
									className={`btn btn-primary btn-primaryBook_ED cursor-pointer`}
									onClick={() => proceedToHost(session)}
								>
									Host
								</button>
							</p>
						);
					} else {
						return (
							<p className="text-left_ED">
								<button
									type="button"
									className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn disabled"
									onClick={() => proceedToHost(session)}
								>
									Host
								</button>
							</p>
						);
					}
				}
			} else if (!session.is_registered && !session.is_participant) {
				if (session?.is_past) {
					return (
						<button
							type="button"
							className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn disabled"
							title={"Session is past"}
							onClick={() => {
								setShowInfoModalMsg("Session is past");
								setInfoModal(true);
							}}
						>
							Register
						</button>
					);
				} else {
					return (
						<p className="text-left_ED">
							<button
								type="button"
								className={`btn btn-primary btn-primaryBook_ED cursor-pointer`}
								onClick={() => proceedToRegister(session, "broker")}
							>
								Register
							</button>
						</p>
					);
				}
			} else {
				return <p className="text-left_ED">{renderJoinButton(session)}</p>;
			}
		} else {
			return (
				<p className="text-left_ED">
					{!session?.is_paid && !session?.is_registered ? (
						<>
							{session?.can_enroll ? (
								session.is_past ? (
									<button
										type="button"
										className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn disabled"
										title={"Session is past"}
										onClick={() => {
											setShowInfoModalMsg("Session is past");
											setInfoModal(true);
										}}
									>
										Register
									</button>
								) : (
									<button
										type="button"
										className="btn btn-primary btn-primaryBook_ED cursor-pointer"
										onClick={() => proceedToRegister(session, "xsel")}
									>
										Register
									</button>
								)
							) : session.is_addtocart ?
							(
							  <button
								type="button"
								className="btn btn-primary btn-primaryBook_ED cursor-pointer in-cart-event"
								onClick={() => proceedToBook(session)}
							  >
								Added in Cart
							  </button>
							)
							:
							(
							  <button
								type="button"
								className="btn btn-primary btn-primaryBook_ED cursor-pointer"
								onClick={() => proceedToBook(session)}
							  >
								Book
							  </button>
							)}
						</>
					) : (
						<>{renderJoinButton(session)}</>
					)}
				</p>
			);
		}
	};

	const renderJoinButton = (session) => {
		if (session.show_join) {
			return (
				<button
					type="button"
					className="btn btn-primary btn-primaryBook_ED cursor-pointer"
					onClick={() => launchSession(session)}
				>
					Join
				</button>
			);
		} else {
			if (!session.is_past) {
				return (
					<div className="d-flex flex-column gap-2">
						<button
							type="button"
							className="btn btn-primary btn-primaryBook_ED cursor-pointer"
							disabled
							onClick={() => launchSession(session)}
						>
							Join
						</button>
						<button
							type="button"
							className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
							onClick={() => cancelConfirm(session)}
						>
							Cancel
						</button>
					</div>
				);
			} else if (session.watch_url) {
				// return (
				//   <button
				//     type="button"
				//     className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
				//   >
				//     Watch
				//   </button>
				// );
			}
		}
	};

	const renderAlertCard = () => {
		return (
			<div
				className={`alert-card d-flex justify-content-center topsecalert ${infoModal ? "" : "d-none"
					}`}
			>
				<div className="shop-cart order-details event-notify-sucess">
					<div className="shop-info justify-content-center">
						{/* <svg
				  xmlns="http://www.w3.org/2000/svg"
				  width="18"
				  height="18"
				  viewBox="0 0 18 18"
				>
				  <g
					id="Group_5699"
					data-name="Group 5699"
					transform="translate(0.498 0.498)"
				  >
					<circle
					  id="Ellipse_222"
					  data-name="Ellipse 222"
					  cx="9"
					  cy="9"
					  r="9"
					  transform="translate(-0.498 -0.498)"
					  fill="#A9C23C"
					/>
					<path
					  id="Icon_material-done"
					  data-name="Icon material-done"
					  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
					  transform="translate(-3.013 -6.071)"
					  fill="#fff"
					  stroke="#fff"
					  strokeWidth="2"
					/>
				  </g>
				</svg> */}
						<p className="m-0">{showInfoModalMsg}</p>
					</div>
					<div className="view-cart-icon">
						{/* <span
								className="cursor-pointer"
								onClick={() => {
									clickStreamUser('go-to-cart-from-shopping-cart-btn-clicked', {
										userId: auth.authBroker?.payload.user.user_id,
										ecommerceId: courseId
									})
									history.push('/cart')
								}}
							>View Cart</span> */}
						<i
							className="fa fa-times cursor-pointer"
							aria-hidden="true"
							onClick={() => {
								setInfoModal(false);
								setShowInfoModalMsg("");
							}}
						></i>
					</div>
				</div>
			</div>
		);
	};

	const onNotifyMeHandler = () => {
		// token: <user-token>,
		// certify_id : <certify_id>,
		dispatch(
			eventNotification({
				module: "event_Notify_Me",
				postData: {
					token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
					event_id: certify_id,
					origin: source === 'broker' ? 'broker' : 'xsel',
					status: "checked",
				},
			})
		);
		setNotifyMeBtn(true);
		// window.scrollTo(0, 0);
		// setShow(true);
		// window.scroll({
		//   top: 0, 
		//   left: 0, 
		//   behavior: 'smooth'
		// });
		// alert("We will notify you whenever the session is addes to this event");
	};

	const uncheckNotifyMeHandler = () => {
		// token: <user-token>,
		// event_id : <event_id>,
		// setIsLoading(true);
		dispatch(
			eventNotification({
				module: "event_Notify_Me",
				postData: {
					token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
					event_id: certify_id,
					origin: source === "broker" ? "broker" : "xsel",
					status: "unchecked",
				},
			})
		);
		setNotifyMeBtn(false);

		// window.scrollTo(0, 0);
		// setShow(true);
		// window.scroll({
		//   top: 0,
		//   left: 0,
		//   behavior: 'smooth'
		// });
		// alert("We will notify you whenever the session is addes to this event");
	};

	const renderTableBody = () => {
		return sessionList.map((session, index) => {
			return (
				<>
					<TableRow
						key={`session-${index}`}
						sx={{ "& > *": { borderBottom: "unset" } }}
					>
						<TableCell>
							<div className="d-flex align-items-start gap-2">
								<div
									onClick={() => {
										const openList = _.clone(open);
										openList[index] = !openList[index];
										setOpen(openList);
									}}
								>
									{open[index] ? (
										<i className="fa fa-minus"></i>
									) : (
										<i className="fa fa-plus"></i>
									)}
								</div>
								<div className="tableName">
									{/* <div>Session {index + 1}</div> */}
									<div>{session.session_name}</div>
									<div className="mt-2">
										{session.date_time.length}{" "}
										{session.date_time.length === 1 ? "Part" : "Parts"}
									</div>
								</div>
							</div>
							{/* <b> {session.startDate.split(',')[0]},</b> {session.startDate.split(',')[1]} <br /> <span><b> {session.startTime} </b>hrs</span> <b> EST</b> */}
						</TableCell>
						<TableCell>{session?.provider?.provider_display_name}</TableCell>
						<TableCell>{session.host}</TableCell>
						<TableCell>{setDeliveryMode(session.delivery_mode)}</TableCell>
						<TableCell className="text-capitalize">
							{session.channel_name}
						</TableCell>
						{source != "broker" ? (
							<>
								{_.toInteger(session.selling_price) != 0 ? (
									<TableCell className={`${session.isOffer ? 'd-flex flex-column gap-2 w-100 h-100' : ''}`}>
										{
											session.isOffer ?
												<>
													<span className="offer-tag-session">OFFER</span>
													<div className="strike-through">
														${session.originalPrice}
														CAD
													</div>
													<div>
														<span className="supscript_ED"> $</span>
														<span className="priceED">
															{session.selling_price.split(".")[0]}
														</span>
														<sup>
															<b>{session.selling_price.split(".")[1]}</b>
														</sup>{" "}
														CAD
													</div>
												</>
												:
												<>
													<span className="supscript_ED"> $</span>
													<span className="priceED">
														{session.selling_price.split(".")[0]}
													</span>
													<sup>
														<b>{session.selling_price.split(".")[1]}</b>
													</sup>{" "}
													CAD
												</>
										}
									</TableCell>
								) : (
									<TableCell>
										<b>FREE</b>
									</TableCell>
								)}
							</>
						) : (
							""
						)}
						<TableCell>{renderButton(session)}</TableCell>
						{userRole === "manager" ? <TableCell>
							<p className="text-left_ED">

								{session.product_basic_price === "" && !session.is_past ? <button
									type="button"
									className={`btn btn-primary btn-primaryBook_ED cursor-pointer`}
									onClick={() => {
										dispatch(setCurrentView(1))
										setShow(true)
										setSelectedSession(source === 'broker' ? session.session_id : session.course_code)
									}}>
									Assign
								</button> : ""}
							</p>
						</TableCell> : ""}
					</TableRow>
					<TableRow>
						<TableCell
							colSpan={source != "broker" ? 7 : 6}
							style={{ paddingBottom: 0, paddingTop: 0, height: 0 }}
							className="bg-white br-0"
						>
							<Collapse in={open[index]} timeout="auto" unmountOnExit>
								<Table className="clear-border" component={Paper}>
									<TableHead>
										<TableRow>
											<TableCell scope="col" className="col-md-2 table-title" />
											<TableCell scope="col" className="col-md-2 table-title">
												Start Date & Time
											</TableCell>
											<TableCell scope="col" className="col-md-2 table-title">
												End Date & Time
											</TableCell>
											<TableCell
												scope="col"
												className="col-md-2 table-title"
											></TableCell>
											<TableCell
												scope="col"
												className="col-md-2 table-title"
											></TableCell>
											{source != "broker" ? (
												<TableCell
													scope="col"
													className="col-md-2 table-title"
												></TableCell>
											) : (
												""
											)}
											<TableCell
												scope="col"
												className="col-md-1 table-title"
											></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{session.date_time &&
											session.date_time.map((date, index) => {
												return (
													<TableRow key={`date-${index}`}>
														<TableCell>Part {index + 1}</TableCell>
														<TableCell>
															{date.startD}
															<br />
															{/* at&nbsp; */}
															{date?.startDateTime?.format("hh:mm A") +
																" " +
																date?.startDateTime?.format('z')}
														</TableCell>
														<TableCell>
															{date.endD}
															<br />
															{/* at&nbsp; */}
															{date?.endDateTime?.format("hh:mm A") +
																" " +
																date?.endDateTime.format('z')}
														</TableCell>
														<TableCell></TableCell>
														<TableCell></TableCell>
														<TableCell></TableCell>
														<TableCell>
															{
																(session.is_registered && date.is_past) ?
																	<button
																		type="button"
																		className="btn btn-primary btn-primaryBook_ED cursor-pointer event-btn"
																		onClick={() => watchSession(session, date.watch_url)}
																	>
																		Watch
																	</button> : ''
															}
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</Collapse>
						</TableCell>
					</TableRow>
				</>
			);
		});
	};

	const renderNotifyBody = () => {
		return (
			<>
				<div>
					<div className="session-found-notify no-session-found">
						{/* <div className="img-no-session">
              <img
                src="/images/no-session-found.svg"
                alt="No session found"
              />
            </div> */}
						<p className="no-ssesion-found-text">
							Want to get notified, when more sessions will be added? Click on
							the below checkbox.
						</p>
						<div className="d-flex btnsnotify">
							<input
								type="checkbox"
								className="form-check-input cursor-pointer"
								name="notify-me"
								checked={notifyMeBtn ? true : ""}
								onClick={(e) => {
									if (e.target.checked) {
										// console.log("Target1", e.target.checked);
										onNotifyMeHandler();
									} else {
										// console.log("Target2", e.target.checked);
										uncheckNotifyMeHandler();
										// console.log("Target3", e.target.checked);
									}
								}}
							/>
							&nbsp;Notify Me
							{/* <button
              className="notfime"
              disabled={notifyMeBtn}
              onClick={onNotifyMeHandler}
            >
              Notify Me
            </button>
            <button
              className="notfime"
              onClick={uncheckNotifyMeHandler}
            >
              Deselect
            </button> */}
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div>
			<section className="event_ED_BGimage" style={{ background: `url(${certifyDetail?.bannerURL})` }}>
				<div className="row realtorRow">
					<div className=" col-md-6 bg-whites opacity-68 realtor_col"></div>
				</div>
				<div className="realtor_content">
					<div className="container position-relative">
						{renderAlertCard()}
						<div className="header_img_left_ED">
							{/* <h6 dangerouslySetInnerHTML={{
								__html: certifyDetail?.name
							}}></h6> */}
						</div>
					</div>
				</div>
			</section>
			<section className="container ">

				<div className="row">
					<div className="col-md-12 main_realtor mt-5">
						<h6 className="pb-3">Welcome to the<br /> <span dangerouslySetInnerHTML={{
							__html: certifyDetail?.name
						}}></span></h6>
						<div className="certify-desc" dangerouslySetInnerHTML={{
							__html: certifyDetail?.description
						}}></div>

					</div>
				</div>

				<div className="Registration mt-4 ">
					<p>Registration</p>

				</div>
				{isSessionDataLoaded
					?
					sessionList.length > 0 ?
						<div className="registration">
							<TableContainer component={Paper}>
								<Table aria-label="collapsible table">
									<TableHead className="thead_bdy_ED thead_bdy_border">
										<TableRow>
											<TableCell scope="col" className="col-md-2 table-title">
												Session
											</TableCell>
											<TableCell scope="col" className="col-md-2 table-title">
												Provider
											</TableCell>
											{/* <TableCell scope="col" className="col-md-2 table-title">
								Host
								</TableCell> */}
											<TableCell scope="col" className="col-md-2 table-title">
												Instructor
											</TableCell>
											<TableCell scope="col" className="col-md-2 table-title">
												Delivery Mode
											</TableCell>
											<TableCell scope="col" className="col-md-1 table-title">
												Location
											</TableCell>
											{source != "broker" ? (
												<TableCell scope="col" className="col-md-2 table-title">
													Pricing
												</TableCell>
											) : (
												""
											)}
											<TableCell scope="col" className="col-md-1 table-title">
												Action
											</TableCell>
											{userRole === "manager" ? <TableCell scope="col" className="col-md-1 table-title">
												Assign
											</TableCell> : ''}
										</TableRow>
									</TableHead>
									<TableBody className="tble_bdy">
										{
											sessionList.length > 0 ? renderTableBody() :
												<TableRow>
													<TableCell colSpan={source != 'broker' ? 7 : 6}>No sessions found</TableCell>
												</TableRow>
											// <tr><td colSpan={7}>No sessions found</td></tr>
										}
									</TableBody>
								</Table>
							</TableContainer>
							{renderNotifyBody()}
						</div>
						:
						<>
							<div>
								<div className="no-session-found">
									<div className="img-no-session">
										<img
											src="/images/no-session-found.svg"
											alt="No session found"
										/>
									</div>
									<p className="no-ssesion-found-text">
										No sessions currently available. Click on the below
										checkbox to get notified, when more session are added.
									</p>

									<div className="d-flex btnsnotify">
										<input
											type="checkbox"
											className="form-check-input cursor-pointer"
											name="notify-me"
											checked={notifyMeBtn ? true : ""}
											onClick={(e) => {
												if (e.target.checked) {
													onNotifyMeHandler();
												} else {
													uncheckNotifyMeHandler();
												}
											}}
										/>
										&nbsp;Notify Me
									</div>
								</div>
							</div>
						</>
					:
					<div className="d-flex w-100 gap-2 align-items-center justify-content-start">
						Loading sessions
						<div className="spinner">
							<div className="bounce1"></div>
							<div className="bounce2"></div>
							<div className="bounce3"></div>
						</div>
					</div>
				}
			</section>
			<AssignCourseModal show={show} parentName={'Certificate Session'} parentId={selectedSession}
			setShow={setShow} origin={source === "xsel" || source === "ecom" ? "xsel" : "broker"} />
			<ConfirmModal
				confirmModal={confirmModal}
				setConfirmModal={setConfirmModal}
				showModalMsg={showModalMsg}
				isConfirm={true}
				confirmId={sessionDataForModal}
				confirmAction={cancelSession}
				isMobile={false}
			/>

		</div>
	)
}

export default CertifyDetailSection;