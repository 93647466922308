import { fork, take, call, put, all } from "redux-saga/effects";

import {
  getProfileCompletionReportResponse,
  searchResponse,
  getOnboardingCompletionReportResponse,
  getAnyTotalReportResponse,
  getPostListResponse,
  getAddCommentsResponse,
  getEditCommentsResponse,
  getLikeCommentResponse,
  getActivityLikeResponse,
  deleteCommentResponse,
  deletePostResponse,
  editPostResponse,
} from "./actions";
import types from "./types";
import { getAPIWithID, postAPIWithoutModule } from "../../api/generic";

export function* doGetPostList() {
  while (true) {
    const { payload } = yield take(types.POST_LIST);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getPostListResponse(response));
  }
}

export function* doGetAddComments() {
  while (true) {
    const { payload } = yield take(types.ADD_COMMENTS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getAddCommentsResponse(response));
  }
}

export function* doGetEditComments() {
  while (true) {
    const { payload } = yield take(types.EDIT_COMMENTS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getEditCommentsResponse(response));
  }
}

export function* doGetLikeComment() {
  while (true) {
    const { payload } = yield take(types.GET_LIKE);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLikeCommentResponse(response));
  }
}

export function* doGetActivityLike() {
  while (true) {
    const { payload } = yield take(types.GET_ACTIVITY_LIKE);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getActivityLikeResponse(response));
  }
}

export function* doDeleteComment() {
  while (true) {
    const { payload } = yield take(types.DELETE_COMMENT_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(deleteCommentResponse(response));
  }
}

export function* doDeletePost() {
  while (true) {
    const { payload } = yield take(types.DELETE_POST_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(deletePostResponse(response));
  }
}

export function* doEditPost() {
  while (true) {
    const { payload } = yield take(types.EDIT_POST_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(editPostResponse(response));
  }
}

function* search() {
  yield fork(doGetPostList);
  yield fork(doGetAddComments);
  yield fork(doGetEditComments);
  yield fork(doGetLikeComment);
  yield fork(doGetActivityLike);
  yield fork(doDeleteComment);
  yield fork(doDeletePost);
  yield fork(doEditPost);
}
export default search;
