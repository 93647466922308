import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Page from "../../components/Page";
import { getAllEvents, getEventDetails, getSessionList } from "../../redux/events/actions";
import { SessionKeys } from "../../utils/AppUtils";
import _ from "lodash";
import EventDetailSection from "../../components/Events/EventDetailSection";
import Loader from "../../components/Loader/Loader"

const EventDetailPage = () => {

  const eventDetailRes = useSelector((state) => state.events.eventDetail);
  const eventDetailResBroker = useSelector((state) => state.events.eventDetailBroker);
  
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(eventDetailRes)) {
      setIsLoading(false);
    } else if (!_.isEmpty(eventDetailResBroker)) {
      setIsLoading(false);
    }
  }, [eventDetailRes, eventDetailResBroker]);

  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <EventDetailSection 
          setIsLoading={setIsLoading}
        />
      </Page>
    </>
  )
}

export default EventDetailPage;