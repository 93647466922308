import { fork, take, takeEvery, call, put, all } from 'redux-saga/effects'

import {
  getCourseCategoriesResponse,
  getProductCategoriesResponse,
  getCoursesListingResponse,
  getModuleListingResponse,
  getModuleNotesGetResponse,
  getModuleNotesUpdateResponse,
  getModuleNotesDeleteResponse,
  getModuleFavGetResponse,
  getModuleFavDeleteResponse,
  getFavoriteCoursesResponse,
  getUserCoursesResponse,
  setFavoriteCoursesResponse,
  removeFavoriteCoursesResponse,
  // Learning Path
  getLearningPathResponse,
  // Learning Path Broker
  getLearningPathBrokerResponse,
  getCoursesListingResponseFromWoo,
  getCoursesListingResponseFromBroker,
  getCoursesCategoriesResponseFromBroker,
  getModuleListingResponseBroker,
  getLearningPathListResponseBroker,

  // Assign Course
  getAssignCourseResponse,
  getAssignBrokerCourseResponse,
  getInProgressBrokerResponse,

  // My Courses
  getMyCoursesResponse,
  getMyLearningPathsResponse,

  // Code of Ethics
  getCodeOfEthicsResponse,
  postEthicsAgreeCheckResponse,
  markModuleProgressResponse,
  getLevelDetailResponse,
  setMarkViewResponse,
  getCheckListInsertResponse,
  getLPUnAssignedResponse,
  getProductCategoriesDataResponse,
  getSubProductCategoriesDataResponse,
  getSingleLPResponse,
  getCourseRelatedResponse,
  videoModuleProgressResponse,
} from './actions'
import types from './types'
import { getUserCourses } from '../../api/courses'
import { getAPI, newAPICall, postAPI, postAPIWithoutModule } from '../../api/generic'


export function* doGetUserCourses() {
  while (true) {
    const { payload } = yield take(types.USER_COURSES)
    const response = yield call(getUserCourses, payload)
    yield put(getUserCoursesResponse(response))
  }
}

export function* doInProgressCoursesBroker() {
  while (true) {
    const { payload } = yield take(types.IN_PROGRESS_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getInProgressBrokerResponse(response))
  }
}

export function* doGetCourseCategories() {
  while (true) {
    const { payload } = yield take(types.COURSE_CATEGORIES)
    const response = yield call(getAPI, payload)
    yield put(getCourseCategoriesResponse(response))
  }
}

export function* doGetProductCategories() {
  while (true) {
    const { payload } = yield take(types.PRODUCT_CATEGORIES)
    const response = yield call(getAPI, payload)
    yield put(getProductCategoriesResponse(response))
  }
}

export function* doGetProductCategoriesData() {
  while (true) {
    const { payload } = yield take(types.PRODUCT_CATEGORIES_DATA)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getProductCategoriesDataResponse(response))
  }
}

export function* doGetSubProductCategoriesData() {
  while (true) {
    const { payload } = yield take(types.PRODUCT_SUB_CATEGORIES_DATA)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getSubProductCategoriesDataResponse(response))
  }
}

export function* doGetCoursesListing() {
  while (true) {
    const { payload } = yield take(types.COURSES_LISTING)
    const response = yield all([
      yield call(getAPI, payload.ecom),
      yield call(postAPIWithoutModule, payload.broker)
    ])
    yield put(getCoursesListingResponse(response))
  }
}
export function* doGetModuleListing() {
  while (true) {
    const { payload } = yield take(types.MODULE_LISTING)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getModuleListingResponse(response))
  }
}
export function* doGetModuleNotesGet() {
  while (true) {
    // console.log('Saga getNotes')
    const { payload } = yield take(types.MODULE_NOTES_GET)
    const response = yield call(postAPI, payload)
    yield put(getModuleNotesGetResponse(response))
  }
}
export function* doGetModuleNotesUpdate() {
  while (true) {
    const { payload } = yield take(types.MODULE_NOTES_UPDATE)
    const response = yield call(postAPI, payload)
    yield put(getModuleNotesUpdateResponse(response))
  }
}
export function* doGetModuleNotesDelete() {
  while (true) {
    const { payload } = yield take(types.MODULE_NOTES_DELETE)
    const response = yield call(postAPI, payload)
    yield put(getModuleNotesDeleteResponse(response))
  }
}
export function* doGetModuleFavGet() {
  while (true) {
    const { payload } = yield take(types.MODULE_FAV_GET)
    const response = yield call(postAPI, payload)
    yield put(getModuleFavGetResponse(response))
  }
}
export function* doGetModuleFavDelete() {
  while (true) {
    const { payload } = yield take(types.MODULE_FAV_DELETE)
    const response = yield call(postAPI, payload)
    yield put(getModuleFavDeleteResponse(response))
  }
}

export function* doGetFavoriteCoursesList() {
  while (true) {
    const { payload } = yield take(types.FAVORITE_COURSES)
    const response = yield call(getAPI, payload)
    yield put(getFavoriteCoursesResponse(response))
  }
}

export function* doSetFavoriteCourse() {
  while (true) {
    const { payload } = yield take(types.SET_FAVORITE_COURSES)
    const response = yield call(postAPI, payload, true)
    yield put(setFavoriteCoursesResponse(response))
  }
}

export function* doRemoveFavoriteCourse() {
  while (true) {
    const { payload } = yield take(types.REMOVE_FAVORITE_COURSES)
    const response = yield call(postAPI, payload, true)
    yield put(removeFavoriteCoursesResponse(response))
  }
}

export function* doGetLearningPath() {
  while (true) {
    const { payload } = yield take(types.LEARNING_PATH)
    const response = yield call(getAPI, payload)
    yield put(getLearningPathResponse(response))
  }
}

export function* doGetCoursesListingWoo() {
  while (true) {
    const { payload } = yield take(types.COURSES_LISTING_WOO)
    const response = yield call(getAPI, payload)
    yield put(getCoursesListingResponseFromWoo(response))
  }
}

export function* doGetCoursesCategoriesBroker() {
  while (true) {
    const { payload } = yield take(types.COURSES_CATEGORIES_BROKER)
    const response = yield call(getAPI, payload)
    yield put(getCoursesCategoriesResponseFromBroker(response))
  }
}

export function* doGetCoursesListingBroker() {
  while (true) {
    const { payload } = yield take(types.COURSES_LISTING_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getCoursesListingResponseFromBroker(response))
  }
}

export function* doGetModuleListingBroker() {
  while (true) {
    const { payload } = yield take(types.MODULES_LISTING_BROKER)
    const response = yield call(getAPI, payload)
    yield put(getModuleListingResponseBroker(response))
  }
}





export function* doGetAssignCourse() {
  while (true) {
    const { payload } = yield take(types.ASSIGN_COURSE)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getAssignCourseResponse(response))
  }
}

export function* doGetAssignBrokerCourse() {
  while (true) {
    const { payload } = yield take(types.ASSIGN_COURSE_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getAssignBrokerCourseResponse(response))
  }
}

export function* doGetMyLearningPath() {
  while (true) {
    const { payload } = yield take(types.MY_LEARNING_PATHS)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getMyLearningPathsResponse(response))
  }
}

export function* doGetCodeOfEthics() {
  while (true) {
    const { payload } = yield take(types.MASTER_CODE_OF_ETHICS)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getCodeOfEthicsResponse(response))

  }
}
//TODO:
export function* doPostCodeOfEthicsAgree() {
  while (true) {
    const { payload } = yield take(types.ETHICS_AGREE_CHECK)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(postEthicsAgreeCheckResponse(response))

  }
}

export function* doMarkModuleProgress() {
  while (true) {
    const { payload } = yield take(types.MARK_MODULE_PROGRESS)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(markModuleProgressResponse(response))

  }
}

export function* doVideoModuleProgress() {
  while (true) {
    const { payload } = yield take(types.VIDEO_PROGRESS)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(videoModuleProgressResponse(response))

  }
}

export function* doGetLearningPathListingBroker() {
  while (true) {
    const { payload } = yield take(types.LEARNING_PATH_LISTING_BROKER)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getLearningPathListResponseBroker(response))
  }
}

export function* doGetLevelDetail() {
  while (true) {
    const { payload } = yield take(types.GET_LEVEL_DETAIL_REQ)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getLevelDetailResponse(response))

  }
}

export function* doSetMarkView() {
  while (true) {
    const { payload } = yield take(types.MARK_VIEW)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(setMarkViewResponse(response))
  }
}

export function* doCheckListInsert() {
  yield takeEvery(types.CHECKLIST_INSERT, function* ({payload}) {
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCheckListInsertResponse(response));
  })
}

export function* doGetLPUnAssigned() {
  yield takeEvery(types.LEARNING_PATHS_UNASSIGNED, function* ({payload}) {
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getLPUnAssignedResponse(response));
  })
}

export function* doGetSingleLPData() {
  yield takeEvery(types.SINGLE_LP_DATA, function* ({payload}) {
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getSingleLPResponse(response));
  })
}

export function* doGetCourseRelatedData() {
  yield takeEvery(types.COURSE_RELATED, function* ({payload}) {
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCourseRelatedResponse(response));
  })
}

function* courses() {
  yield fork(doGetUserCourses)
  yield fork(doInProgressCoursesBroker)
  yield fork(doGetCourseCategories)
  yield fork(doGetProductCategories)
  yield fork(doGetCoursesListing)
  yield fork(doGetModuleListing)
  yield fork(doGetModuleNotesGet)
  yield fork(doGetModuleNotesUpdate)
  yield fork(doGetModuleNotesDelete)
  yield fork(doGetModuleFavGet)
  yield fork(doGetModuleFavDelete)
  yield fork(doGetFavoriteCoursesList)
  yield fork(doSetFavoriteCourse)
  yield fork(doRemoveFavoriteCourse)
  yield fork(doGetLearningPath)
  yield fork(doGetCoursesListingWoo)
  yield fork(doGetCoursesListingBroker)
  yield fork(doGetCoursesCategoriesBroker)
  yield fork(doGetModuleListingBroker)
  yield fork(doGetLearningPathListingBroker)
  yield fork(doGetAssignCourse)
  yield fork(doGetAssignBrokerCourse)
  yield fork(doGetMyLearningPath)
  yield fork(doGetCodeOfEthics)
  yield fork(doPostCodeOfEthicsAgree)
  yield fork(doMarkModuleProgress)
  yield fork(doVideoModuleProgress)
  yield fork(doGetLevelDetail)
  yield fork(doSetMarkView)
  yield fork(doCheckListInsert)
  yield fork(doGetLPUnAssigned)
  yield fork(doGetProductCategoriesData)
  yield fork(doGetSubProductCategoriesData)
  yield fork(doGetSingleLPData)
  yield fork(doGetCourseRelatedData)
}
export default courses