import types from './types'

// Product Detail Fetch
export const product = (payload) => ({
  type: types.PRODUCT_DETAIL, payload
})
export const productResponse = (payload) => ({
  type: types.PRODUCT_DETAIL_RESPONSE, payload
})
export const productClear = () => ({ type: types.PRODUCT_DETAIL_CLEAR })

// Recommend Products
export const recommendProduct = (payload) => ({
  type: types.RECOMMEND_PRODUCT, payload
})
export const recommendProductResponse = (payload) => ({
  type: types.RECOMMEND_PRODUCT_RESPONSE, payload
})
export const recommendProductClear = () => ({
  type: types.RECOMMEND_PRODUCT_CLEAR
})

// Course Detail Fetch
export const getCourseDetail = (payload) => ({
  type: types.COURSE_DETAIL, payload
})
export const courseDetailResponse = (payload) => ({
  type: types.COURSE_DETAIL_RESPONSE, payload
})
export const courseClear = () => ({ type: types.COURSE_DETAIL_CLEAR })


// Add To Cart
export const addToCart = (payload) => ({
  type: types.ADD_TO_CART, payload
})
export const addToCartResponse = (payload) => ({
  type: types.ADD_TO_CART_RESPONSE, payload
})
export const addToCartClear = () => ({ type: types.ADD_TO_CART_CLEAR })

// GET ORDER DETAIL
export const getOrderDetail = (payload) => ({
  type: types.GET_ORDER_DETAIL, payload
})
export const getOrderDetailResponse = (payload) => ({
  type: types.GET_ORDER_DETAIL_RESPONSE, payload
})
export const getOrderDetailResponseClear = () => ({ type: types.GET_ORDER_DETAIL_RESPONSE_CLEAR })



// Add To WISHLIST
export const addToWishList = (payload) => ({
  type: types.ADD_TO_WISHLIST, payload
})
export const addToWishListResponse = (payload) => ({
  type: types.ADD_TO_WISHLIST_RESPONSE, payload
})
export const addToWishListClear = () => ({ type: types.ADD_TO_WISHLIST_CLEAR })

// GET WISHLIST
export const getWishList = (payload) => ({
  type: types.GET_WISHLIST, payload
})
export const getWishListResponse = (payload) => ({
  type: types.GET_WISHLIST_RESPONSE, payload
})
export const getWishListClear = () => ({ type: types.GET_WISHLIST_RESPONSE_CLEAR })


//DELETE WISHLIST
export const delWishList = (payload) => ({
  type: types.DELETE_WISHLIST, payload
})
export const delWishListResponse = (payload) => ({
  type: types.DELETE_WISHLIST_RESPONSE, payload
})
export const delWishListClear = () => ({ type: types.DELETE_WISHLIST_RESPONSE_CLEAR })


