import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

const TableRolePlayComponent = (props) => {
  const [dataSet, setDataSet] = useState([]);

  useEffect(() => {
    console.log('Data Set', props.row)
    if (!_.isEmpty(props.row)) {
      setDataSet(props.row);
    }
  }, [props.row]);

  const renderButton = (data) => {
    if (data.showView) {
      return (
        <button
        className="btn-primary reviewbtn rpsubmitbtn"
          onClick={() => props.showForReview(data.id)}
        >
          View
        </button>
      )
    }  else if (data.showSubmit) {
      return (
        <button
          className="btn-primary reviewbtn rpsubmitbtn"
          onClick={() => props.setFormData(data.id, "update")}
        >
          Submit
        </button>
      )
    } else if (data.showReview) {
      return (
        <button
          className="btn-primary reviewbtn"
          onClick={() => props.showForReview(data.id)}
        >
          Review
        </button>
      )
    }
    // if (data.showReSubmit) {
    //   return (
    //     <button
    //       className="btn-primary reviewbtn rpsubmitbtn"
    //       onClick={() => props.setFormData(data, "insert")}
    //     >
    //       Re-Submit
    //     </button>
    //   )
    // }
  }

  const renderReviewTableBody = () => {
    return (
      <>
        {dataSet.map((data) => {
          return (
            <tr className="addBGcustomw">
              <td>
                <div className="d-flex vidollirole">
                  <div className="videoimgtable">
                    <img src={data.thumbnail} width="80" height="44" />
                  </div>
                  <div className="text-box">
                    <p className="titlebox-vid">
                      <strong>{data.title}</strong>
                    </p>
                    <p className="categorytitle">{data.roleplay_category}</p>
                  </div>
                </div>
              </td>
              <td>
                {data.userFirstName}&nbsp;{data.userLastName}
              </td>
              <td>
                {moment(data.submissionDate, "YYYY-MM-DD HH:mm:ss").format(
                  "DD MMM, YYYY"
                )}
              </td>
              <td
                className={`${props.reviewType === "pending" ? "" : "text-center"
                  }`}
              >
                {props.reviewType === "pending" ? (
                  <button
                    className="btn-primary reviewbtn"
                    onClick={() => props.showForReview(data)}
                  >
                    Review
                  </button>
                ) : (
                  <strong>{data.roleplay_score}</strong>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  const renderMyRolePlayTableBody = () => { 
    return (
      <>
        {dataSet.map((data) => {
          return (
            <tr className="addBGcustomw">
              <td>
                <div
                  className="d-flex vidollirole"
                  // onClick={() => props.showForReview(data)}
                >
                  {/* <div className="videoimgtable">
                    <img src={data.thumbnail} width="80" height="44" />
                  </div> */}
                  <div className="text-box">
                    <p className="titlebox-vid">
                      <strong>{data.title}</strong>
                    </p>
                  </div>
                </div>
              </td>
              <td className="aligniconav">
                {/* <i class="fa fa-microphone circlebgmicvideo" aria-hidden="true"></i> */}
                {
                  data.isVideo ? 
                  <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    width="35" 
                    height="35" 
                    viewBox="0 0 36 36"
                  >
                    <defs>
                      <linearGradient id="linear-gradient" x1="0.146" y1="0.854" x2="0.854" y2="0.146" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#c41974" />
                        <stop offset="0.5" stop-color="#ff007f" />
                        <stop offset="1" stop-color="#ff593d" />
                      </linearGradient>
                    </defs>
                    <g id="Video" transform="translate(82 -669)">
                      <g id="Roundel" transform="translate(-82 669)">
                        <circle id="Ellipse_87" data-name="Ellipse 87" cx="18" cy="18" r="18" fill="url(#linear-gradient)" />
                      </g>
                      <path id="reckitt_Icons_Only_Energy_Gradient_RGB_45_Online" d="M5.159,26.549l.292-.594a.625.625,0,0,1,1.122.553l-.292.594a.287.287,0,0,0,.014.283.259.259,0,0,0,.224.127H25.995a.259.259,0,0,0,.224-.127.287.287,0,0,0,.014-.283l-.956-1.94a.265.265,0,0,0-.237-.152H6.876a.625.625,0,0,1-.625-.625V11.459A1.461,1.461,0,0,1,7.71,10H25.221a1.461,1.461,0,0,1,1.459,1.459V21.883a.625.625,0,0,1-1.251,0V11.459a.209.209,0,0,0-.208-.208H7.71a.209.209,0,0,0-.208.208v12.3H25.039a1.509,1.509,0,0,1,1.36.85l.956,1.94a1.534,1.534,0,0,1-.074,1.5,1.507,1.507,0,0,1-1.286.717H6.519a1.507,1.507,0,0,1-1.286-.717A1.534,1.534,0,0,1,5.159,26.549Zm18.027-10.03a.625.625,0,0,1-.057,1.161l-1.91.649-1.077,1.976a.625.625,0,0,1-.549.326.652.652,0,0,1-.079,0,.626.626,0,0,1-.527-.469l-1.251-5a.625.625,0,0,1,.865-.721ZM21.232,17l-1.969-.9L19.81,18.3l.427-.783a.624.624,0,0,1,.348-.293Zm-9.053-.017.2-.117a2.172,2.172,0,0,1-.915-1.784,1.98,1.98,0,1,1,3.961,0,2.1,2.1,0,0,1-.954,1.8l.2.11a3.436,3.436,0,0,1,2.218,3.217.625.625,0,1,1-1.251,0c0-1.247-.73-1.653-1.575-2.124-.218-.121-.441-.245-.648-.386l-.028.019c-.195.122-.386.235-.57.342-1,.589-1.558.914-1.558,2.149a.625.625,0,0,1-1.251,0c0-1.952,1.106-2.6,2.176-3.228Zm.534-1.9c0,.335.033.476.711,1.031.691-.52.748-.651.748-1.031a.73.73,0,0,0-1.459,0Z" transform="translate(-80.257 667)" fill="#fff" />
                    </g>
                  </svg>
                  :
                  <svg 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" 
                  width="35" 
                  height="35" 
                  viewBox="0 0 36 36"
                >
                  <defs>
                    <linearGradient id="linear-gradient" x1="0.146" y1="0.854" x2="0.854" y2="0.146" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#c41974" />
                      <stop offset="0.5" stop-color="#ff007f" />
                      <stop offset="1" stop-color="#ff593d" />
                    </linearGradient>
                  </defs>
                  <g id="Audio" transform="translate(82 -669)">
                    <g id="Roundel" transform="translate(-82 669)">
                      <circle id="Ellipse_87" data-name="Ellipse 87" cx="18" cy="18" r="18" fill="url(#linear-gradient)" />
                    </g>
                    <path id="reckitt_Icons_Only_Energy_Gradient_RGB_36_Microphone" d="M18.358,13.465v3.183a5.2,5.2,0,0,0,5.431,5.189,5.2,5.2,0,0,0,5.431-5.189V14.6h-.905v2.036a4.531,4.531,0,0,1-9.051,0V10.3a4.531,4.531,0,0,1,9.051,0v1.358a.679.679,0,0,1-1.358,0V10.3a3.177,3.177,0,0,0-6.336,0v6.336a3.177,3.177,0,0,0,6.336,0V13.918a.679.679,0,0,1,.679-.679H29.9a.679.679,0,0,1,.679.679v2.73a6.476,6.476,0,0,1-6.109,6.516v5.009h4.525a.679.679,0,1,1,0,1.358H18.584a.679.679,0,0,1,0-1.358h4.525V23.164A6.476,6.476,0,0,1,17,16.648V13.465a.679.679,0,0,1,1.358,0Z" transform="translate(-87.788 668.703)" fill="#fff" />
                  </g>
                  </svg> 
                  
                }
              </td>
              <td>
                {data.status}
                {/* <span class="pfrpending">Pending For Review</span> */}
              </td>
              <td>
                {data.submittedDate}
              </td>
              <td>
                {data.name}
              </td>
              <td>
                {data.reviewedDate}
              </td>
              <td
                className={`${props.reviewType === "saved" ? "" : "text-center"
                  }`}
              >
                {renderButton(data)}
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      {/* <div className="tablebox"> */}
      <div className="mobresscroltable">
        <table className="table office_list roleplaytable">
          <thead>
            <tr>
              {props.header.map((header) => {
                return <th className="office-province">{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {renderMyRolePlayTableBody()}
            {/* {props.view === "review"
              ? renderReviewTableBody()
              : renderMyRolePlayTableBody()} */}
          </tbody>
        </table>
      </div>
      {/* <div className="table-repost-de">
                Showing 1-11 of 42
            </div> */}
    </>
  );
};

export default TableRolePlayComponent;
