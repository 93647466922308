import { useState } from "react";

const ReadMoreLess = (props) => {

    const [characterLimit, setCharacterLimit] = useState(200);

    return (
        <p className="card-text color_main" id="des">
            {props.text.length <= 200 ?
                props.text
            : 
            <>
                {props.text.substring(0, characterLimit)}
                {characterLimit === 200 ? '...' : ''}
                <div>
                    {
                        characterLimit === 200 ? 
                        <button className="reset-button cursor-pointer"
                            onClick={() => setCharacterLimit(props.text.length)}
                        >
                            <span className="reset-text">Read More</span>
                        </button>
                        : 
                        <button className="reset-button cursor-pointer"
                            onClick={() => setCharacterLimit(200)}
                        >
                            <span className="reset-text">Read Less</span>
                        </button>
                    }
                </div>
            </>
            }
            
        </p>
    )
}

export default ReadMoreLess;