import { fork, take, call, put } from 'redux-saga/effects'
import {
    addArchiveVideoReqResponse,
    assignRolePlayDataResponse,
    fetchMyRolePlayListResponse,
    fetchParticipateRoleListResponse,
    fetchRolePlayDataResponse,
    fetchRolePlayMasterListResponse,
    fetchRolePlayMasterListUnAssignedResponse,
    getAWSStatusRolePlayResponse,
    getRolePlayRecordedDataResponse,
    getRolePlayVideoDataResponse,
    isAggreeResponse,
    myRolePlayFilterListResponse,
    privacyContentReqResponse,
    relatedRolePlayListResponse,
    reviewFilterListResponse,
    reviewRolePlayListResponse,
    submitParticipateFormResponse,
    submitReviewFormResponse,

} from "./actions"
import types from "./types"
import { getAPI, postAPI, postAPIWithoutModule } from '../../api/generic'

export function* doGetRolePlayMasterList() {
    while (true) {
        const { payload } = yield take(types.ROLEPLAY_MASTER_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(fetchRolePlayMasterListResponse(response))
    }
}

export function* doGetRolePlayMasterListUnassigned() {
    while (true) {
        const { payload } = yield take(types.ROLEPLAY_MASTER_LIST_UNASSIGNED);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(fetchRolePlayMasterListUnAssignedResponse(response))
    }
}

export function* doGetAllRoleList() {
    while (true) {
        const { payload } = yield take(types.GET_ALL_ROLE_PARTICIPATE_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(fetchParticipateRoleListResponse(response))
    }
}

export function* doSubmitParticipateRole() {
    while (true) {
        const { payload } = yield take(types.PARTICIPATE_IN_ROLEPLAY_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(submitParticipateFormResponse(response))
    }
}

export function* doReviewRolePlayList() {
    while (true) {
        const { payload } = yield take(types.REVIEW_ROLEPLAY_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(reviewRolePlayListResponse(response))
    }
}

export function* doSubmitFeedback() {
    while (true) {
        const { payload } = yield take(types.REVIEW_FEEDBACK_SUBMIT_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(submitReviewFormResponse(response))
    }
}

export function* doGetMyRoleList() {
    while (true) {
        const { payload } = yield take(types.MY_ROLE_PLAY_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(fetchMyRolePlayListResponse(response))
    }
}

export function* doAggreeCheck() {
    while (true) {
        const { payload } = yield take(types.IS_AGREE);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(isAggreeResponse(response))
    }
}

export function* doGetPrivacyContent() {
    while (true) {
        const { payload } = yield take(types.PRIVACY_CONTENT_REQ);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(privacyContentReqResponse(response))
    }
}

export function* doAddArchiveVideo() {
    while (true) {
        const { payload } = yield take(types.ADD_ARCHIVE_VIDEO_REQ);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(addArchiveVideoReqResponse(response))
    }
}

export function* doGetRelatedRolePlayList() {
    while (true) {
        const { payload } = yield take(types.RELATED_VIDEO_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(relatedRolePlayListResponse(response))
    }
}

export function* doGetReviewFilterList() {
    while (true) {
        const { payload } = yield take(types.REVIEW_FILTER_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(reviewFilterListResponse(response))
    }
}

export function* doGetMyRolePlayFilterList() {
    while (true) {
        const { payload } = yield take(types.MY_ROLE_PLAY_FILTER_LIST);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(myRolePlayFilterListResponse(response))
    }
}

export function* doFetchRolePlayData() {
    while (true) {
        const { payload } = yield take(types.FETCH_ROLE_PLAY_DATA);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(fetchRolePlayDataResponse(response));
    }
}

export function* doGetVideoDataRolePlay() {
    while (true) {
        const { payload } = yield take(types.GET_VIDEO_DATA_ROLEPLAY);
        const response = yield call(postAPIWithoutModule, payload);
        yield put(getRolePlayVideoDataResponse(response));
    }
}

export function* doCheckAWSStatusRolePlay() {
    while (true) {
        const { payload } = yield take(types.CHECK_AWS_STATUS_ROLEPLAY);
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getAWSStatusRolePlayResponse(response))
    }
}

export function* doGetRecordedDataRolePlay() {
    while (true) {
        const { payload } = yield take(types.GET_RECORDED_DATA_ROLEPLAY);
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getRolePlayRecordedDataResponse(response))
    }
}

export function* doAssignRolePlay() {
    while (true) {
        const { payload } = yield take(types.ASSIGN_ROLEPLAY);
        const response = yield call(postAPIWithoutModule, payload)
        yield put(assignRolePlayDataResponse(response))
    }
}

function* roleplay() {
    yield fork(doGetRolePlayMasterList)
    yield fork(doGetRolePlayMasterListUnassigned)
    yield fork(doGetAllRoleList)
    yield fork(doSubmitParticipateRole)
    yield fork(doReviewRolePlayList)
    yield fork(doSubmitFeedback)
    yield fork(doGetMyRoleList)
    yield fork(doAggreeCheck)
    yield fork(doGetPrivacyContent)
    yield fork(doAddArchiveVideo)
    yield fork(doGetRelatedRolePlayList)
    yield fork(doGetReviewFilterList)
    yield fork(doGetMyRolePlayFilterList)
    yield fork(doFetchRolePlayData)
    yield fork(doGetVideoDataRolePlay)
    yield fork(doCheckAWSStatusRolePlay)
    yield fork(doGetRecordedDataRolePlay)
    yield fork(doAssignRolePlay)
}

export default roleplay;