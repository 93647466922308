import { fork, take, call, put, all } from "redux-saga/effects";

import { certificateDataResponse } from "./actions";
import types from "./types";
import { postAPIWithoutModule } from "../../api/generic";

export function* doCertificate() {
  while (true) {
    const { payload } = yield take(types.CERTIFICATE_DATA);
    const response = yield call(postAPIWithoutModule, payload);
    // console.log("Certificate Saga", response);
    yield put(certificateDataResponse(response));
  }
}

function* certificateURL() {
  yield fork(doCertificate);
}

export default certificateURL;
