import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersByOfficeList } from "../../redux/assignCourse/actions";
import {
  getActivityLike,
  getAddComments,
  getEditComments,
  getLikeComment,
} from "../../redux/myNetwork/actions";
import { SessionKeys } from "../../utils/AppUtils";
import Page from "../Page";
import Pdftron from "./Pdftron";

const Test = () => {
  const dispatch = useDispatch();

  
  return (
    <Page>
    <Pdftron />
    </Page>
   
  );
};
export default Test;
