import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { resendActivationLink, resendActivationLinkClear } from '../redux/auth/actions';
import { useHistory } from 'react-router-dom';
import FooterNew from '../components/FooterNew';
import HeaderLogoSection from "../components/HeaderLogoSection";

const ActivationFailureResetPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
    }, [])

    return (
        <>
            <div className="page-wrapper-rsp">
                <HeaderLogoSection />
                <div className="header-border"></div>
                <section className="setup-password">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 py-5 right-space">
                                <p><i style={{ 'fontWeight': '100' }}>The email verification link has expired.</i></p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <FooterNew />
        </>
    );
}

export default ActivationFailureResetPage;