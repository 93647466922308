import React from 'react'

const CookiePolicyComp = () => {
  return (
    <div className="container footerstaticpage">
      <h1 className="pagenameheading">Cookie Policy</h1>
      <p className="paradescstatic">Our Cookies Policy explains what cookies are, how we use cookies, how third parties we partner with may use cookies on the Site, and your choices regarding cookies. Please read this Cookies Policy in conjunction with our Privacy Policy, which sets out additional details on how we manage personal information and your various privacy rights. </p>
      <h4 className="qaheading">What Are Cookies And Web Beacons?</h4>
      <p className="paradescstatic">Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Site or a third party to recognize you and make your next visit easier and the Site more useful to you. Web beacons are small graphic files linked to our servers that allow us to track your use of our Site and related functionalities. Cookies and web beacons allow NIIT to serve you better and more efficiently, and to personalize your experience on our Site.</p>
      <p className="paradescstatic">Generally, cookies may contain information about your Internet Protocol ("IP") addresses, the region or general location where your computer or device is accessing the Internet, browser type, operating system and other usage information about the Site or your usage of our Site, including a history of the pages you view</p>
      <p className="paradescstatic">Cookies can be "persistent" or "session" cookies.</p>
      <h4 className="qaheading">How NIIT Uses Cookies And Web Beacons?</h4>
      <p className="paradescstatic">When you use and access the Site, we may place a number of cookies files in your web browser. </p>
      <p className="paradescstatic">NIIT uses or may use cookies and/or web beacons to help us determine and identify repeat visitors, the type of content and sites to which a user of our Site links, the length of time each user spends at any particular area of our Site, and the specific functionalities that users choose to use. To the extent that cookies data constitutes personal information, we process such data on the basis of your consent, in accordance with our Privacy Policy.. </p>
      <p className="paradescstatic ptextboldstatic">We use both session and persistent cookies on the Site, including the following categories:</p>
      <ul className="listingstatic">
        <li><b>Strictly necessary cookies.</b> These cookies are necessary for the operation of the Site. We may use strictly necessary cookies to authenticate users, prevent fraudulent use of user accounts, or offer Site features. You can configure your browser to block or alert you about these cookies, but certain areas of the Site will not function properly. These cookies do not store any personal information.</li>
        <li><b>Analytical/performance cookies.</b> These cookies Aallow us to recognize and count the number of visitors and see which pages are the most and least popular on our Site. This helps us improve the way the Site works. If you do not allow these cookies our aggregated statistics will not have a record of your visit.</li>
        <li><b>Functionality cookies.</b> These cookies are used to recognize you when you return to the Site. This enables us to personalie our content for you , greet you by name,    and remember your preferences (for example, your choice of language or region). If you do not allow these cookies, some or all of the Site features may not function properly</li>
        <li><b>Targeting cookies.</b> These cookies are used to record your visit to the Site, the pages you have visited, and the links you have followed. We will use this information to make the Site and the more relevant to your interests. We may share this information with our advertising partners. If you do not allow these cookies, you will experience less targeted advertising.</li>
      </ul>
      <h4 className="qaheading">Third-Party Cookies</h4>
      <p className="paradescstatic ptextboldstatic">In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Site and refine marketing efforts.</p>
      <ul className="listingstatic">
        <li><b>Tracking cookies.</b> Follow on-site behaviour and tie it to other metrics allowing better understanding of usage habits </li>
        <li><b>Optimization cookies.</b> Allow real-time tracking of user conversion from different marketing channels to evaluate their effectiveness</li>
        <li><b>Partner cookies.</b> Provide marketing conversion metrics to our partners so they can optimize their paid marketing efforts.</li>
      </ul>
      <h4 className="qaheading">What Are Your Choices Regarding Cookies?</h4>
      <p className="paradescstatic">You have choices regarding the management of cookies on your browser. All major browsers allow you to block or delete cookies from your system. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use some or all of the features of our Site. For instance, you may not be able to log in, store your preferences, and some of our pages might not display properly. To learn more about your ability to manage cookies and web beacons and how to disable them, please consult the privacy features in your browser or visit <a className="staticlinkfooter" href="">www.allaboutcookies.org</a>.</p>
      <h4 className="qaheading">How We Manage Your Consent?</h4>
      <p className="paradescstatic">By continuing to use our Site, you are agreeing to our use of cookies and/or web beacons on your browser in order to analyze the way you use our Site, as permitted by applicable law. Please read this Cookie Policy carefully for more details about the information we collect when you use outr Site.</p>
      <p className="paradescstatic">You may withdraw your consent to the use of cookies at any time by removing the cookies via your browser settings.</p>
    </div>
  )
}

export default CookiePolicyComp