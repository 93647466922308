import types from './types'

export const fetchRolePlayMasterList = (payload) => ({type: types.ROLEPLAY_MASTER_LIST, payload})
export const fetchRolePlayMasterListResponse = (payload) => ({type: types.ROLEPLAY_MASTER_LIST_RESPONSE, payload})
export const fetchRolePlayMasterListClear = () => ({type: types.ROLEPLAY_MASTER_LIST_CLEAR})

export const fetchRolePlayMasterListUnAssigned = (payload) => ({type: types.ROLEPLAY_MASTER_LIST_UNASSIGNED, payload})
export const fetchRolePlayMasterListUnAssignedResponse = (payload) => ({type: types.ROLEPLAY_MASTER_LIST_UNASSIGNED_RESPONSE, payload})
export const fetchRolePlayMasterListUnAssignedClear = () => ({type: types.ROLEPLAY_MASTER_LIST_UNASSIGNED_CLEAR})

export const fetchParticipateRoleList = (payload) => ({type: types.GET_ALL_ROLE_PARTICIPATE_LIST, payload})
export const fetchParticipateRoleListResponse = (payload) => ({type: types.GET_ALL_ROLE_PARTICIPATE_LIST_RESPONSE, payload})
export const fetchParticipateRoleListClear = () => ({type: types.GET_ALL_ROLE_PARTICIPATE_LIST_CLEAR})

export const submitParticipateForm = (payload) => ({type: types.PARTICIPATE_IN_ROLEPLAY_LIST, payload})
export const submitParticipateFormResponse = (payload) => ({type: types.PARTICIPATE_IN_ROLEPLAY_LIST_RESPONSE, payload})
export const submitParticipateFormClear = () => ({type: types.PARTICIPATE_IN_ROLEPLAY_LIST_CLEAR})

export const reviewRolePlayList = (payload) => ({type: types.REVIEW_ROLEPLAY_LIST, payload})
export const reviewRolePlayListResponse = (payload) => ({type: types.REVIEW_ROLEPLAY_LIST_RESPONSE, payload})
export const reviewRolePlayListClear = () => ({type: types.REVIEW_ROLEPLAY_LIST_CLEAR})

export const submitReviewForm = (payload) => ({type: types.REVIEW_FEEDBACK_SUBMIT_LIST, payload})
export const submitReviewFormResponse = (payload) => ({type: types.REVIEW_FEEDBACK_SUBMIT_LIST_RESPONSE, payload})
export const submitReviewFormClear = () => ({type: types.REVIEW_FEEDBACK_SUBMIT_LIST_CLEAR})

export const fetchMyRolePlayList = (payload) => ({type: types.MY_ROLE_PLAY_LIST, payload})
export const fetchMyRolePlayListResponse = (payload) => ({type: types.MY_ROLE_PLAY_LIST_RESPONSE, payload})
export const fetchMyRolePlayListClear = () => ({type: types.MY_ROLE_PLAY_LIST_CLEAR})

export const isAggree = (payload) => ({type: types.IS_AGREE, payload})
export const isAggreeResponse = (payload) => ({type: types.IS_AGREE_RESPONSE, payload})
export const isAggreeClear = () => ({type: types.IS_AGREE_CLEAR})

export const privacyContentReq = (payload) => ({type: types.PRIVACY_CONTENT_REQ, payload})
export const privacyContentReqResponse = (payload) => ({type: types.PRIVACY_CONTENT_RESPONSE, payload})
export const privacyContentReqClear = () => ({type: types.PRIVACY_CONTENT_CLEAR})

export const addArchiveVideoReq = (payload) => ({type: types.ADD_ARCHIVE_VIDEO_REQ, payload})
export const addArchiveVideoReqResponse = (payload) => ({type: types.ADD_ARCHIVE_VIDEO_RESPONSE, payload})
export const addArchiveVideoReqClear = () => ({type: types.ADD_ARCHIVE_VIDEO_CLEAR})

export const editRolePlayDataResponse = (payload) => ({type: types.EDIT_ROLEPLAY_DATA_RESPONSE, payload})
export const editRolePlayDataClear = () => ({type: types.EDIT_ROLEPLAY_DATA_CLEAR})

export const relatedRolePlayList = (payload) => ({type: types.RELATED_VIDEO_LIST, payload})
export const relatedRolePlayListResponse = (payload) => ({type: types.RELATED_VIDEO_LIST_RESPONSE, payload})
export const relatedRolePlayListClear = () => ({type: types.RELATED_VIDEO_LIST_CLEAR})

export const reviewFilterList = (payload) => ({type: types.REVIEW_FILTER_LIST, payload})
export const reviewFilterListResponse = (payload) => ({type: types.REVIEW_FILTER_LIST_RESPONSE, payload})
export const reviewFilterListClear = () => ({type: types.REVIEW_FILTER_LIST_CLEAR})

export const myRolePlayFilterList = (payload) => ({type: types.MY_ROLE_PLAY_FILTER_LIST, payload})
export const myRolePlayFilterListResponse = (payload) => ({type: types.MY_ROLE_PLAY_FILTER_LIST_RESPONSE, payload})
export const myRolePlayFilterListClear = () => ({type: types.MY_ROLE_PLAY_FILTER_LIST_CLEAR})

export const fetchRolePlayData = (payload) => ({type: types.FETCH_ROLE_PLAY_DATA, payload})
export const fetchRolePlayDataResponse = (payload) => ({type: types.FETCH_ROLE_PLAY_DATA_RESPONSE, payload})
export const fetchRolePlayDataClear = () => ({type: types.FETCH_ROLE_PLAY_DATA_CLEAR})

export const getRolePlayVideoData = (payload) => ({type: types.GET_VIDEO_DATA_ROLEPLAY, payload})
export const getRolePlayVideoDataResponse = (payload) => ({type: types.GET_VIDEO_DATA_ROLEPLAY_RESPONSE, payload})
export const getRolePlayVideoDataClear = () => ({type: types.GET_VIDEO_DATA_ROLEPLAY_CLEAR})

export const getAWSStatusRolePlay = (payload) => ({type: types.CHECK_AWS_STATUS_ROLEPLAY, payload})
export const getAWSStatusRolePlayResponse = (payload) => ({type: types.CHECK_AWS_STATUS_ROLEPLAY_RESPONSE, payload})
export const getAWSStatusRolePlayClear = () => ({type: types.CHECK_AWS_STATUS_ROLEPLAY_CLEAR})

export const getRolePlayRecordedData = (payload) => ({type: types.GET_RECORDED_DATA_ROLEPLAY, payload})
export const getRolePlayRecordedDataResponse = (payload) => ({type: types.GET_RECORDED_DATA_ROLEPLAY_RESPONSE, payload})
export const getRolePlayRecordedDataClear = () => ({type: types.GET_RECORDED_DATA_ROLEPLAY_CLEAR})

export const setParticipateFormValue = (payload) => ({type: types.SET_PARTICIPATE_FORM_VALUE, payload})
export const setParticipateFormValueClear = () => ({type: types.SET_PARTICIPATE_FORM_VALUE_CLEAR})

export const assignRolePlayData = (payload) => ({type: types.ASSIGN_ROLEPLAY, payload})
export const assignRolePlayDataResponse = (payload) => ({type: types.ASSIGN_ROLEPLAY_RESPONSE, payload})
export const assignRolePlayDataClear = () => ({type: types.ASSIGN_ROLEPLAY_CLEAR})