import React from 'react';
import Page from "../../components/Page";
import TermsOfUseComp from "../../components/FooterSectComponents/TermsOfUseComp";

const TermsOfUse = () => {
  return (
    <Page>
      <TermsOfUseComp/>
    </Page>
  )
}
export default TermsOfUse;