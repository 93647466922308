import Page from "../../components/Page";
import PaymentSection from "../../components/Orders/PaymentSection";
import { useDispatch, useSelector } from "react-redux";
import { clickStreamUser } from "../../utils/AppUtils";
import { useEffect } from "react";

const PaymentPage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    return (
        <Page>
            <PaymentSection />
        </Page>
    )
}

export default PaymentPage;