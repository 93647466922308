import React from 'react'

const TermsOfUseComp = () => {
  return (
    <div className="container footerstaticpage">
      <h1 className="pagenameheading">User Terms of Service</h1>
      <p className="tandcdate">Last updated October ___, 2022.</p>
      <ul className="listingstatic">
        <li>Welcome to the [NIIT Canada] [Subscription Services]  Platform (the “Platform”). Please read these terms and conditions carefully before using the Platform. By accessing the Platform or using any [NIIT] Services accessible via the Platform (the “Services”), you agree to these User Terms of Service (the “Terms”).</li>
        <li>Your use of the Platform and Services is also subject to our Cookie Policy and our Privacy Policy, which covers how we collect, use, share, and store your personal information.</li>
        <li>You agree that by clicking “I Agree”, registering, accessing or using our Services, whether individually or through your Brokerage you are agreeing to enter into a legally binding agreement with NIIT Canada.</li>
        <li>If you have any questions relating to the terms and conditions, please contact to be updated.</li>
      </ul>
      <h4 className="qaheading">Availability</h4>
      <p className="paradescstatic">Our aim and endeavor is to ensure you have access to the Platform at all times. Although on occasion we may encounter unexpected interruptions, we will strive to resolve the issues at the earliest and give the best service. In case of any planned software updates or other interruptions, the users will be informed in advance.</p>
      <h4 className="qaheading">Our Right to Vary These Terms and Conditions</h4>
      <p className="paradescstatic">We may modify these Terms, our Privacy Policy and our Cookies Policy from time to time. If we make material changes to any of the foregoing, we will provide you notice through the Platform, our Services, and/or by other channels, to provide you the opportunity to review the changes. No changes will be imposed on you retroactively.   If you object to any changes, you may discontinue using your subscription, suspend the use of the Platform and Services, and inform the Customer   accordingly. Your continued access of the Platform and/or use of our Services after we publish or send a notice about the changes to these Terms means that you are consenting to the updated Terms as of the date of the update set forth above.</p>
      <h6 className="someqnutxt">Account</h6>
      <p className="paradescstatic">You will access the Platform and Services by using the credentials of the applicable Account subscriber. You shall not share the password or other Account credentials with anyone. </p>
      <p className="paradescstatic">You agree to follow the law and our list of Dos and Don’ts. You are responsible for anything that happens through your account unless you close it or report any misuse.</p>
      <h4 className="qaheading">Dos</h4>
      <p className="tandcdate">You agree that you will:</p>
      <ul className="listingstatic">
        <li>Only access and use our Platform if (a) you are duly issued an End-User account by you’re the primary master subscription holder; and (b) you are employed by, or a contractor of, a real estate brokerage that has signed a NIIT Canada  Master Subscription Agreement permitting access to the Platform.</li>
        <li>Comply with all applicable laws, regulations and ordinances related to your use of the Platform and Services, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements.</li>
        <li>Provide accurate information to us and keep it updated.</li>
        <li>Use your real name on your profile.</li>
        <li>Use the Platform and Services in a professional manner. </li>
        <li>Will comply, at all times, with Canadian and international anti-spam legislation, rules and regulations.</li>
      </ul>
      <h4 className="qaheading">Don’ts</h4>
      <p className="tandcdate">You agree that you will not:</p>
      <ul className="listingstatic">
        <li>Create a false identity on the Platform, misrepresent your identity, create a member profile for anyone other than yourself (a real person), or use or attempt to use another’s account.</li>
        <li>Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins and add-ons or any other technology) to scrape the Platform or otherwise copy profiles and other data from the Services.</li>
        <li>Override any security feature or bypass or circumvent any access controls or use limits of the Platform (such as caps on keyword searches or profile views).</li>
        <li>Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of NIIT Canada Modifying, translating, renting, copying, transferring or assigning all or part of the Services, or any rights granted hereunder, to any other persons and removing any proprietary notices, labels or marks from the Platform is strictly prohibited</li>
        <li>Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer)).</li>
        <li>Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license.</li>
        <li>Not sell the Platform. Your use of the Platform and Services is subject to a limited license only. No title to the intellectual property in the Platform or Services is transferred to you or to the Customer. All feedback, comments, and suggestions for improvements (the "Feedback") that you provide to us, will become our property. Upon giving such Feedback, you agree to transfer and assign to us, all your rights, title, and interest in and to such Feedback, including patent rights, copyright rights, trade secret rights and other intellectual property rights. You further acknowledge that title and full ownership rights to the Platform and the Services will remain the exclusive property of NIIT Canada.</li>
        <li>Violate the intellectual property or other rights of NIIT CANADA, including, without limitation, (i) copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open-source licenses; (iii) using the word “NIIT CANADA” or our logos in any business name, email, or URL except as provided in our branding guidelines. </li>
        <li>Post anything that contains software viruses, worms, or any other harmful code.</li>
        <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source.</li>
        <li>Imply or state that you are affiliated with or endorsed by NIIT CANADA/NIIT without our express consent (e.g., representing yourself as an accredited NIIT trainer).</li>
        <li>Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without NIIT’s consent.</li>
        <li>Deep link to our Services for any purpose other than to promote your profile or a Group on our Services, without NIIT CANADA/NIIT’s consent.</li>
        <li>Use bots or other automated methods to access the Platform or Services, add or download contacts, send or redirect messages.</li>
        <li>Monitor the Services’ availability, performance or functionality for any competitive purpose.</li>
        <li>Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Platform or Services.</li>
        <li>Overlay or otherwise modify the Platform or Services or their appearance (such as by inserting elements into the Platform or Services or removing, covering, or obscuring an advertisement included on the Platform or Services).</li>
        <li>Interfere with the operation of, or place an unreasonable load on, the Platform or Services (e.g., spam, denial of service attack, viruses, gaming algorithms).</li>
        <li>Violate any of the [NIIT] Platform or Services policies or any additional terms concerning a any Service.</li>
        <li>Will not upload, copy, distribute, share, or otherwise use content that is unlawful, obscene, defamatory, libelous, harmful, hateful, harassing, bullying, pornographic, threatening, racially or ethnically offensive or abusive, that would violate another person's rights (including their intellectual property rights), constitute or encourage a criminal offense, give rise to civil liability, or violate any local, state, provincial, national or international law or regulation, or that is otherwise inappropriate;</li>
        <li>Will not upload, transmit, disseminate, post, store or post links to any content that:
          (a) facilitates hacking or unauthorized access or use of data, systems, servers or networks including any attempt to probe, scan or test for vulnerabilities, or to breach security or authentication measures;
          (b) interferes with service to any user, system or network by using flooding techniques, overloading a system or a network, staging or broadcasting an attack or any other means resulting in a crash of a host either deliberately or by negligence;
          (c) infringes on, or contributes to any infringement of, any intellectual property, material protected by copyright, trademark, patent, trade secret or proprietary right of any party.
        </li>
      </ul>
      <h4 className="qaheading">General Terms</h4>
      <p className="paradescstatic">You agree that NIIT Canada, in its sole discretion, has the right (but not the obligation) to delete or deactivate your Account or otherwise terminate your access to or use of the Platform (or any part thereof), immediately and without notice, for any reason, including, without limitation, if NIIT Canada believes that you have acted inconsistently with the letter or spirit of the Terms. </p>
      <p className="paradescstatic">If a court with authority over these Terms finds the Terms, in whole or in part, to be unenforceable, you and we agree that the court should modify the Terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of the Terms.</p>
      <p className="paradescstatic">These Terms along with the Master Subscription agreement is the  only agreement between us regarding use of the Platform and the Services and supersedes all prior agreements for the Platform and Services.</p>
      <p className="paradescstatic">If we don't act to enforce a breach of these Terms, that does not mean that NIIT Canada has waived its right to enforce these Terms. You may not assign or transfer these Terms (or your subscription or use of the Platform or Services) to anyone without our consent. However, you agree that NIIT Canada may assign these Terms to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to these Terms.</p>
      <p className="paradescstatic">You agree that the only way to provide us legal notice is at the addresses provided under “Contact us”.</p>
      <h4 className="qaheading">Governing Law and Dispute Resolution</h4>
      <p className="paradescstatic">This Agreement will be governed exclusively by the laws of Canada. The courts located in the state of Ontario will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to this Agreement. Each party hereby consents to the exclusive jurisdiction of such courts. Each party also hereby waives any right to jury trial in connection with any action or litigation in any way arising out of or related to this Agreement.</p>
      <h4 className="qaheading">Limits</h4>
      <p className="paradescstatic">We have the right to limit how you connect and interact with the Platform and our Services.</p>
      <p className="paradescstatic">NIIT CANADA reserves the right to limit your use of the Services, including the number of your connections and your ability to contact other subscribers or users. NIIT CANADA reserves the right to restrict, suspend, or terminate your Account if you breach these Terms or the law or are misusing the Platform or Services (e.g., violating any of the Dos and Don’ts).</p>
      <h4 className="qaheading">Disclaimer and Limit of Liability</h4>
      <h6 className="someqnutxt">No Warranty</h6>
      <p className="paradescstatic ptextboldstatic">This is our disclaimer of legal liability for the quality, safety, or reliability of our Services.</p>
      <p className="paradescstatic txtboldmax">NIIT CANADA AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE PLATFORM OR SERVICES, INCLUDING ANY REPRESENTATION THAT THE PLATFORM OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE PLATFORM  OR SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, NIIT CANADA AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
      <h6 className="someqnutxt mt-3">Exclusion of Liability</h6>
      <p className="paradescstatic ptextboldstatic">These are the limits of legal liability we may have to you.</p>
      <p className="paradescstatic txtboldmax">TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS NIIT CANADA HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THESE TERMS), NIIT CANADA, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THESE TERMS FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.</p>
      <p className="paradescstatic txtboldmax">NIIT CANADA'S ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY UNDER THIS AGREEMENT IN RESPECT OF THE SERVICES SHALL NOT EXCEED THE TOTAL SUBSCRIPTION FEES, IF ANY, PAID TO NIIT CANADA IN CONNECTION WITH A SUBSCRIPTION AGREEMENT. IN NO EVENT SHALL NIIT CANADA BE LIABLE TO THE CUSTOMER FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL OR INDIRECT DAMAGES OF ANY KIND ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE, EVEN IF THE CUSTOMER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR ANY CLAIM BY A THIRD PARTY.</p>
      <p className="paradescstatic txtboldmax">NIIT CANADA AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THESE TERMS FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO NIIT CANADA FOR THE SERVICES DURING THE TERM OF THESE TERMS, IF ANY, OR (B) CAD $1000, whichever is greater.</p>
      <p className="paradescstatic ptextboldstatic">Basis of the Bargain; Exclusions</p>
      <p className="paradescstatic">The limitations of liability in this Section are part of the basis of the bargain between you and NIIT CANADA and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if NIIT CANADA or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.</p>
      <p className="paradescstatic">These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct, or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to our delivery of services and on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of these Terms and to the extent that they are typical in the context of these Terms.</p>
      <h4 className="qaheading">User purchases and upgrades </h4>
      <p className="paradescstatic">You will be able to purchase    many different items on the Platform.  For example, you may purchase a NIIT master class, or something the brokerage is offering, or a course from one of our service partners.  The purchase will be made online on the Platform through the ecommerce area. You can upgrade to a premium package if the Brokerage is on Basic Package by paying the monthly subscription amount for the Premium Package. However, you may not be able to downgrade from the Plan subscribed by the subscriber Brokerage.  If you purchase an upgrade , you will be required to accept the User privacy policy, cookies and applicable terms of service. </p>
      <p className="paradescstatic ptextboldstatic">Include the access to course after payment.</p>
      <h4 className="qaheading">Cancellation</h4>
      <p className="paradescstatic">Individual purchase - Downgrade from Premium to Basic.</p>
      <p className="paradescstatic">You may downgrade from a Premium to Basis package at any time if you purchased an upgrade to package subscribed by the Account holder. The cancellation will be effective as of the next monthly billing cycle and will be billed at such time. Validity of the purchase is always governed by the applicable Brokerage Master Subscription Agreement.</p>
      <p className="paradescstatic">Users purchasing any e-Learning content or videos whether it is NIIT Canada’s content or of any third party service provider, cannot cancel after purchase and there will be no refunds.  </p>
      <p className="paradescstatic">Users can cancel a purchase of an ILT (Instructor lead Training) or VILTS (Virtual instructor lead training) prior to 30 days of the scheduled training for a full refund for e.g. If the training is scheduled on Nov 1st, any cancellation prior to Sep 30th will be applicable for a full refund. These conditions applies on third parties services which any individual purchases. </p>
      <p className="paradescstatic">Users can cancel a purchase of an ILT (Instructor lead Training) or VILTS (Virtual instructor lead training) withing 30 day of the scheduled training but will not be eligible for a refund for e.g. . If the training is scheduled on Nov 1st, any cancellation post to Sep 30th will not be eligible for a refund.</p>
      <p className="paradescstatic">These Terms shall be co-terminus with Master Subscription Agreement which the Brokerage has entered with NIIT Canada.</p>
      <p className="paradescstatic ptextboldstatic">Refund and charge back verbiage to be added.- Legal and Finance to advice </p>
      <p className="paradescstatic">Refund request is approved – 7 to 10 days, refund will be done to the same card that is paid. </p>
      <p className="paradescstatic">No Bank charges will be applicable for a refund. </p>
      <p className="paradescstatic">Charge back penalty, & bank charges. $10 - $50 is the rage of transactions.</p>
      <h4 className="qaheading">Intellectual Property Rights</h4>
      <p className="paradescstatic ptextboldstatic">We’re providing you notice about our intellectual property rights.</p>
      <p className="paradescstatic">NIIT CANADA reserves all its intellectual property rights in the Platform and Services. Trademarks and logos used in connection with the Platform or Services are the trademarks of their respective owners. NIIT CANADA, and “NIIT” logos and trademarks, service marks, graphics and logos used for the Platform and Services are trademarks or registered trademarks of NIIT</p>
      <p className="paradescstatic">Our Platform contains open source and public domain content, licensed content as well as proprietary content owned by us and by third-parties. </p>
      <p className="paradescstatic">We grant you a revocable, non-exclusive, non-transferrable, limited right to use the Platform and Services, accessible via equipment or other sanctioned device controlled by you, and to access and use the Platform and Services strictly in accordance with these Terms. You acknowledge that no title to the intellectual property in the Platform or Services will be transferred to you or any Account holder. You further acknowledge that title and full ownership rights with respect to the Platform and Services will remain the exclusive property of NIIT Canada, and you will not acquire any rights to the Platform or Services, except as expressly set forth in these Terms. All title and copyrights in and to the Platform (including but not limited to any images, photographs, animations, video, audio, music, text and “applets,” incorporated into the Platform), Feedback from you, are owned by NIIT Canada. The Platform is protected by copyright laws and international treaty provisions. </p>
      <p className="someqnutxt mt-4">Automated Processing</p>
      <p className="paradescstatic ptextboldstatic">We do not conduct any automated processing of data. We use the information and data that you provide and that we have about data subjects to make recommendations for content and features that may be useful. For example, we use your data to recommend other courses and content that may be useful for your training purposes.</p>
      <p className="someqnutxt mt-4">Contact Us</p>
      <p className="paradescstatic">[Institution] c/o Program Administrator</p>
      <p className="paradescstatic"><b>Email:</b> ______________________</p>
      <p className="paradescstatic"><b>Phone:</b> ______________________</p>
    </div>
  )
}

export default TermsOfUseComp