import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFilterView } from "../../redux/reports/actions";
import { reportButtonsArr, reportCategoryData } from "../../utils/testJson";
import ReportFilterModel from "../modal/ReportFilterModel";


const ReportDashboardSection = (props) => {
  const { isMobile } = props;
  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.user.role);
  const iframeRole = useSelector((state) => state.user.iframeRole);

  const [reportListing, setReportListing] = useState([]);
  const [defaultListing] = useState(reportCategoryData);
  const [show, setShow] = useState(false);
  const [showCompliance, setShowCompliance] = useState(false);
  const [heading, setHeading] = useState("Generate a Report");
  const [routeLink, setRouteLink] = useState('progress-report')
  const currentFilterView = useSelector((state) => state.report.currentFilterView);

  const [currentReportObj, setCurrentReportObj] = useState(reportButtonsArr[0])

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setCurrentFilterView(1))
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      if (iframeRole) {
        let newList = [];
        for (let list of defaultListing) {
          if (list.role.includes(iframeRole)) {
            newList.push(list);
          }
        }
        setReportListing(newList);
      }
    } else {
      if (userRole) {
        let newList = [];
        for (let list of defaultListing) {
          if (list.role.includes(userRole)) {
            newList.push(list);
          }
        }
        setReportListing(newList);
      }
    }
  }, [userRole, iframeRole, isMobile]);

  const renderTitle = () => {
    return (
      <>
        <div className="col-md-7">
          <h1 className="requesthead">Reports</h1>
          <p className="subhead">
            Generate, View and Download all types of Reports.
          </p>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (showCompliance) {
      setHeading("Generate a Compliance Report");
    } else {
      setHeading("Generate a Report");
    }
  }, [showCompliance]);

  return (
    <>
      <section className="my-credit-points report-page">
        <div className="container">
          <div className="row">{renderTitle()}</div>
        </div>
        <div className="center_line mt-0"></div>
        <div className="container">
          <div className="row g-3">
            <div className="col-md-12">
              {/* <h6 className="report-view-head">
            Which Report do you want to view?
          </h6> */}
            </div>


            {
              reportButtonsArr?.map((item, index) => {
                return <div className="col-md-6 col-lg-4 b" key={index}>
                  <button
                    className="report-g-btn btn-primary"
                    onClick={() => {
                      setShow(true);
                      dispatch(setCurrentFilterView(1))
                      setHeading(item.heading)
                      let routLinkUrl = isMobile?item.linkMobile:item.link
                      setRouteLink(routLinkUrl)

                      setCurrentReportObj(item)
                    }}
                  >
                    {item.btnText}
                  </button>
                </div>
              })
            }


            {/*<div className="col-md-6 col-lg-4 b">
          <button
            className="report-g-btn btn-primary"
            onClick={() => {
              setShow(true);
              setShowCompliance(false);
            }}
          >
            Learning Progress
          </button>
        </div>
        <div className="col-md-6 col-lg-4 b">
          <button
            className="report-g-btn btn-primary"
            onClick={() => {
              setShow(true);
              setShowCompliance(true);
              
            }}
          >
            Compliance
          </button>
        </div>
        <div className="col-md-6 col-lg-4 b">
          <button
            className="report-g-btn btn-primary"
            onClick={() => setShow(true)}
            disabled={true}
          >
            {" "}
            Learning Path
          </button>
        </div>
        <div className="col-md-6 col-lg-4 b">
          <button
            className="report-g-btn btn-primary"
            onClick={() => setShow(true)}
            disabled={true}
          >
            {" "}
            Code of Ethics
          </button>
        </div>
        <div className="col-md-6 col-lg-4 b">
          <button
            className="report-g-btn btn-primary"
            onClick={() => setShow(true)}
            disabled={true}
          >
            {" "}
            Onboarding
          </button>
          </div>*/}

            {/* {reportListing.map((item,index) => {
                        return (
                            <ReportCardSection key={`list-${index}`} onboardData={item} />
                        )
                    })} */}
            {/* <div className="col-md-4 b">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Onboarding Report</h5>
                            <p className="report-dsic">List of Users with their Onboarding status and progress</p>
                        </div>
                    </div> */}
            {/* <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Checklist Report</h5>
                            <p className="report-dsic">List of Users with the status and progress of their Checklist</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Profile Completion Report</h5>
                            <p className="report-dsic">List of Users with the status of their Profile completion</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Learning Paths Progress</h5>
                            <p className="report-dsic">List of Users undergoing Learning Paths along with their progress</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Course Progress</h5>
                            <p className="report-dsic">List of Users undergoing various courses along with their progress</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Plan Upgrades</h5>
                            <p className="report-dsic">List of Users who have upgraded their subscription plan to Premiuim</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Purchases Report</h5>
                            <p className="report-dsic">List of Users undergoing Learning Paths along with their progress</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Event Report</h5>
                            <p className="report-dsic">List of Users undergoing various courses along with their progress</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="rsp-report-box">
                            <h5 className="report-heading">Role play Report</h5>
                            <p className="report-dsic">List of Users who have upgraded their subscription plan to Premiuim</p>
                        </div>
                    </div> */}
          </div>
        </div>
      </section>

      <ReportFilterModel show={show} setShow={setShow} heading={heading} showCompliance={showCompliance} routeLink={routeLink} currentReportObj={currentReportObj} isMobile={isMobile} />

    </>
  )

}
export default ReportDashboardSection;