import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useSelector, useDispatch } from "react-redux";
import { textAlign } from "@mui/system";
import { ProgressBar } from "react-bootstrap";
import OnBoardingSection from "./Home/OnBoardingSection";
import OnBoardingSectionBroker from "./Home/OnBoardingSectionBroker";
import CourseCardListBody from "./Course/CourseCardListBody";
import InProgressSection from "./Home/InProgressSection";
import CalendarSection from "./Home/CalendarSection";
import MyDashboardSection from "./Home/MyDashboardSection";
import MyAreaSection from "./Home/MyAreaSection";
import {
  recommendationViewResponse,
  recommendationViewData,
  recommendationViewClear,
} from "../redux/recommendationView/actions";
import _, { parseInt } from "lodash";
import Slider from "react-slick";
import PrevArrow from "./LearningPaths/PrevArrow";
import NextArrow from "./LearningPaths/NextArrow";
import SliderCourseListing from "./LearningPaths/SliderCourseListing";
import RecommendationView from "./RecommendationView";
import MyWall from "./Home/MyWall";
import { getSelectedCatId } from "../redux/courses/actions";
import { useHistory } from "react-router-dom";
import CONFIG from "../utils/config";
import Loader from "./Loader/Loader";
import MainSectionBanner from "./MainSectionBanner";

const MainSection = ({startJoyRide}) => {

  const auth = useSelector((state) => state.auth);
  const userRole = useSelector((state) => state.user.role);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      {/*MyDashboard and calendar*/}
      <Loader start={isLoading} />
      <section className="dashboard-calendar New-dashboard">
        <div className="container">
          <div className="row">
            {(userRole === "realtor" || userRole === "manager") && (
              <div className="col-md-5 calen-section mt-4" id="myDashboard">
                <MyDashboardSection auth={auth} />
              </div>
            )}

            <div
              className={
                userRole === "instructor" || userRole === "host"
                  ? "col-md-12 calen-section mt-4"
                  : "col-md-7 calen-section mt-4"
              }

            >
              <div className="calsec" id="myCalendar">
                <CalendarSection />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div id="myOnboard" className="center_line mt-3"></div>
      <OnBoardingSection /> */}

      <div id="myOnboard" className="center_line m-0"></div>

      {(userRole === "realtor" || userRole === "manager") ? <MyAreaSection /> : ''}


      {
        (userRole === 'realtor' || userRole === 'manager')
        &&
        <>
          <div id="myOnboard" className="center_line m-0"></div>
          <section className="John_section New-dashboard gap-top-bottom pt-5g">
            <div className="container">
              <div className="row">
                {/* <div className="col-md-4 mt-1">
                  <MainSectionBanner startJoyRide={startJoyRide}/>
                </div> */}
                <div className="col-md-12 jn-section">
                  <MyWall setIsLoading={setIsLoading} fromPage={'home'} />
                </div>
              </div>
            </div>
          </section>
        </>
      }
      {/* <RecommendationView /> */}


    </>
  );
};

export default MainSection;
