import _ from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import VideoPlayer from "react-video-js-player";

const SolutionModal = (props) => {
    const {
        show,
        setShow,
        solutionData,
        setSolutionData
    } = props

    const [solution, setSolution] = useState({})

    useEffect(() => {
        if (!_.isEmpty(solutionData)) {
            let data =_.clone(solutionData)
            let typeSplit = solutionData.url.includes('.') ? solutionData.url.split('.') : [];
            let extension = typeSplit[typeSplit.length - 1]
            let isVideoFile = typeSplit.length > 0 ? ['m3u8', 'mp4', 'mkv', 'webm', 'avi'].includes(extension) : false
            data.isVideoFile = isVideoFile;
            setSolution(data)
        }
    }, [solutionData])

    return (
        <Modal
            show={show}
            fullscreen={false}
            onHide={() => {
                setShow(false)
                setSolution({})
                setSolutionData({})
            }}
            centered
            size={'lg'}
        >
            {!_.isEmpty(solution)?
            <>
            <Modal.Header
            closeButton
            style={{ backgroundColor: "white", marginTop: "15px" }}
            >
                <Modal.Title>{solution.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "white" }}>
                {
                    <div className="video-full">
                    {solution.isVideoFile ?
                        <VideoPlayer
                        className="img-videosec learn_image-card"
                        controls={true}
                        // src="https://cdn-stg.niit-mts.com/xsel/media/sample-20s.m3u8"
                        src={solution.url}
                        width="720"
                        height="420"
                        autoPictureInPicture={true}
                        poster={""}
                        fluid={true}
                        inline={false}
                        pictureInPictureToggle={true}
                        />
                    :
                        solution.type === 'embed' ?
                            <div
                                className="video-full"
                                dangerouslySetInnerHTML={{
                                __html: solution.url,
                                }}
                            >
                            </div>
                        :
                            <iframe
                            className="w-1000"
                            id="scorm_player"
                            height={"500px"}
                            style={{
                                minHeight: "500px",
                                width: "70%",
                                margin: "0 auto",
                            }}
                            frameBorder="0"
                            marginHeight="0"
                            marginWidth="0"
                            src={solution?.url.replace("\\", "")}
                            allowFullScreen={true}
                            />
                    }
                    </div>
                }
            </Modal.Body>
            </>
            : ""}
        </Modal>
    )
}

export default SolutionModal;