import { useEffect } from "react";
const CoursesAssign = (props) => {

    return (
        <>
        <div className="optionsd">
        Select an option below
    </div>
    <p>Assign this course to <strong><span className="selectedteam">1 Teams</span></strong> or <strong className="choseselect">Agents</strong></p>
    <div className="button-sec-pop">
                <button type="button" className="btn btn-primary btn-block setup-button">+ Assign</button>
        </div>
        </>

    )
}

export default CoursesAssign;