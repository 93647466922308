import types from "./types";

// Recommendation Detail Fetch
export const recommendationData = (payload) => ({
  type: types.RECOMMENDATION,
  payload,
});

export const recommendationResponse = (payload) => ({
  type: types.RECOMMENDATION_RESPONSE,
  payload,
});
export const recommendationClear = () => ({ type: types.RECOMMENDATION_CLEAR });
