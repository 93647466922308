import ShimmerScreen from "./ShimmerScreen"

const ShimmerEventCard = () => {
    return (
        <div className="col-md-6 col-lg-4 col-xl-4 col-12 events_image">
            <div className="mb-1">
                <ShimmerScreen 
                    case="rect"
                    width="80%"
                    height="1.5rem"
                />
            </div>
            <div className="card_image">
                <ShimmerScreen 
                    case="rect"
                    width="100%"
                    height="18rem"
                />
            </div>
            <div className="content_events mt-2">
                <ShimmerScreen 
                    case="rect"
                    width="80%"
                    height="1rem"
                />
                <div className="mt-1">
                    <ShimmerScreen 
                        case="rect"
                        width="50%"
                        height="1rem"
                    />
                </div>
            </div>
            <div className="event_learn mt-2">
                <ShimmerScreen 
                    case="rect"
                    width="10rem"
                    height="2rem"
                />
                <ShimmerScreen 
                    case="rect"
                    width="5rem"
                    height="2rem"
                />
            </div>
        </div>
    )
}

export default ShimmerEventCard;