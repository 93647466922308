import React, { useEffect, useState, useRef, createRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {
  postEthicsAgreeCheck,
} from "../../redux/courses/actions";
import { SessionKeys } from "../../utils/AppUtils";
import Speech from "react-speech";
import { getBannerCardSummary, getBannerCardSummaryClear } from '../../redux/user/actions'
import { ProgressBar } from "react-bootstrap";
import _ from 'lodash';
import ReadMoreLess from "./ReadMoreLess";
import StaticUtils from "../../utils/StaticUtils";

const EthicsAggreeCheck = (props) => {

  const {
    list,
    isLoading,
    setShow,
    isAllChecked
  } = props;

  const dispatch = useDispatch();

  const agreeResponse = useSelector((state) => state.courses.ethicsAgreeData);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const bannerCard = useSelector(state => state.user.bannerCard)
  const [submit, setSubmit] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [storeValue, setStoreValue] = useState(0);
  const [value, setValue] = useState(localStorage.getItem("checkLength") || 0);

  const [agreeRespData, setAgreeRespData] = useState([]);
  const [ethicsData, setEthicsData] = useState([]);

  const [selected, setselected] = useState([]);

  const [showText, setShowText] = useState(false);
  const [tempDesc, setTempDesc] = useState("");

  //FIXME:
  const [checkes, setChecked] = useState({ checkedItems: {} });
  //TODO:
  const [countOfProgess, setCountOfProgess] = useState(0);

  const [currentPos, setCurrentPos] = useState(0)

  const history = useHistory();
  const speakref = useRef();
  const sliderRef = createRef();
  let apilength = list?.length;

  useEffect(() => {
    return (() => {

      if (StaticUtils.staticRefAudio !== null) {
        // console.log('back: ',StaticUtils.staticRefAudio);
        // StaticUtils.staticRefAudio.pause();
        document.getElementById("myAudio").pause();
      }
    })
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setCountOfProgess((oldProgress) => {
        if (100 == oldProgress) return 0;
        return Math.min(oldProgress + Math.random() * 11);
      });
    }, 199);
    return () => {
      clearInterval(timer);
    };
  }, [storeValue]);


  useEffect(() => {

    const timer = setTimeout(() => {
      setIsDisable(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [storeValue]);

  useEffect(() => {
    dispatch(getBannerCardSummary({
      module: 'bannerCardSummary',
      postData: {
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      }
    }))
  }, [dispatch])

  useEffect(() => {
    // console.log('bannerCard', bannerCard)
    if (!_.isEmpty(bannerCard)) {
      if (bannerCard.status === 'success') {
        const bannerData = bannerCard.payload?.payload
        if (!_.isEmpty(bannerData)) {
          if (bannerData.checklist.progress === 100) {
            setSubmit(false);
          } else if (bannerData.checklist.progress < 80) {
            setSubmit(false);
          } else if (bannerData.checklist.progress > 80) {
            setSubmit(true);
          }
        }
      }
    }
  }, [bannerCard])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [submit])

  useEffect(() => {

    if (!_.isEmpty(ethicsData)) {
      const ethicsInfo = _.clone(ethicsData)
      console.log('ethicsInfo: ', ethicsInfo);
      let flag = true;
      for (let [index, ethics] of ethicsInfo.entries()) {
        // console.log('Ethics', ethics, index)
        if (!ethics.isChecked) {
          ethics.isShowText = true;
          setCurrentPos(index)
          speakAuto(index)
          autoScroll(ethicsInfo);
          flag = false;
          break;
        }
      }
      flag && setSubmit(true)
    }
  }, [ethicsData]);

  useEffect(() => {
    // console.log('ethicsList', list)
    if (!_.isEmpty(list)) {
      setEthicsData(list);
    }
  }, [list])

  useEffect(() => {
    // console.log('ethicsAgreeResponse: ', agreeResponse)
    // console.log('idValue1: ', ethicsData, currentPos)
    return;

    if (ethicsData.length > 0 && agreeResponse?.status === "success") {
      // console.log('currentPos', currentPos)
      ethicsData[currentPos].isChecked = true;
      ethicsData[currentPos].isEnable = undefined;
      if (currentPos + 1 !== ethicsData?.length) {
        ethicsData[currentPos + 1].isShowText = true;
        speakAuto(currentPos + 1)
      } else {
        setSubmit(true)
      }

      setCurrentPos(currentPos + 1)
    }
  }, [agreeResponse]);

  useEffect(() => {
    if (tempDesc.length > 0) {
      StaticUtils.staticRefAudio = speakref.current;
      document.getElementById("myAudio").play();
    }
  }, [tempDesc])


  const speakAuto = (position) => {
    // setTempDesc(ethicsData[position].description);
    // setTempDesc(ethicsData[position].voice_url);  //"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
    document.getElementById("myAudio" + position).play();
    setTimeout(() => {
      ethicsData[position].isEnable = true;
      ethicsData[position].isComplete = true;
    }, 5000);
  };




  //FIXME:
  const checkboxHandler = (e, id, item) => {

    // console.log('idValue111: ')
    if (id === 0 || id) {

      const data = _.clone(ethicsData);
      data[id].isChecked = true;
      data[id].isEnable = undefined;
      if (id + 1 !== data?.length) {
        data[id + 1].isShowText = true;
        document.getElementById("myAudio" + id).pause(); //pause current audio before playing next
        autoScroll(data)
        speakAuto(id + 1)
      } else {

        // setSubmit(true)
      }
      setEthicsData(data);
      // return;
      dispatch(
        postEthicsAgreeCheck({
          module: "post_Code_Of_Ethics_Response",
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            consent: `option ${id + 1}`
          },
        })
      );
      setCurrentPos(id)
    }
    // sliderRef.slickNext();
  };

  const { checkedItems } = checkes;
  const checkCount = Object.keys(checkedItems).filter(
    (key) => checkedItems[key]
  ).length;
  localStorage[agreeResponse] = checkCount;
  const disabled = checkCount > 0;

  //TODO:



  const onSubmitShowMessage = () => {
    setShow(true);
    // goToNextHandler();
  };

  const onSubmitHandler = () => {
    if (submit) {
      window.scrollTo(0, 0);
      dispatch(
        postEthicsAgreeCheck({
          module: "post_Code_Of_Ethics_Response",
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            consent: "all",
          },
        })
      );
      onSubmitShowMessage();
      setSubmit(false)
    }
  };

  const getCurrent = (list) => {
    let position = 0;
    for (let [index, ethics] of list.entries()) {
      // console.log('Ethics', ethics, index)
      if (!ethics.isChecked) {
        position = index;
        break;
      }
    }
    return position;
  }

  const autoScroll = (ethicsData) => {
    for (let [index, ethics] of ethicsData.entries()) {
      // console.log('Ethics', ethics, index)
      if (!ethics.isChecked) {

        if (window.screen.width >= 620 && window.screen.width <= 1020) {
          if ((index > 2)) {
            if (sliderRef.current?.slickGoTo)
              sliderRef.current.slickGoTo(parseInt(index - 2));
            console.log('percentage: ', parseInt(index - 2))
          }
        } if (window.screen.width >= 481 && window.screen.width <= 600) {
          if ((index > 1)) {
            if (sliderRef.current?.slickGoTo)
              sliderRef.current.slickGoTo(parseInt(index - 1));
            console.log('percentage: ', parseInt(index - 1))
          }
        } if (window.screen.width <= 480) {
          if ((index > 0)) {
            if (sliderRef.current?.slickGoTo)
              sliderRef.current.slickGoTo(parseInt(index));
            console.log('percentage: ', parseInt(index))
          }
        }else {
          if ((index > 3)) {
            if (sliderRef.current?.slickGoTo)
              sliderRef.current.slickGoTo(parseInt(index - 3));
            console.log('percentage: ', parseInt(index - 3))
          }
        }
        break;
      }

    }
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="certificat-container checklist-section" id="hello">
      <div className="container" id="itemForm">
        <div className="row">
          <div className="col-md-12 code-ethics">
          <div className="col-md-6">
                        <div className="coe-count mt-2">
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"  style={{ width: "75%"}}></div>
                              </div>
                             <strong>Completed 8 of 10</strong>
                          </div>
                        </div>
            <Slider ref={sliderRef} {...settings}>
              {!isLoading && ethicsData.length > 0 ? (
                ethicsData?.map((res, id) => (
                  <div
                    className={` 
                    // FIXME:
                        ${res?.isShowText
                        ? "card-width_cust_check"
                        : "card-width_cust_check opacity-75"
                      }
                      `}
                    key={"checkbox_" + id}
                    index={id + 1}
                    id
                  >
                    <div className="img-box_nw">
                      <p className="card-text-title" title={res.title}>{res.title}</p>

                      <div
                        className="details-cards-oll1 "
                      >
                        <ReadMoreLess text={res.description} />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                          className="sound-box-rsp">
                          <img
                            className="cursor-pointer"
                            src={(res.isPause !== undefined && res.isPause) ? "images/sound-pause.png" : "images/sound.png"}
                            width="20"
                            height="20"
                            alt=""
                            onClick={() => {
                              if (res.isChecked || res.voice_url === ethicsData[getCurrent(ethicsData)]?.voice_url) {
                                if (document.getElementById("myAudio" + id).paused) {
                                  document.getElementById("myAudio" + id).play();
                                  res.isPause = false;
                                } else {
                                  document.getElementById("myAudio" + id).pause();
                                  res.isPause = true;
                                }
                              }
                            }}
                          />{" "}
                          <audio id={"myAudio" + id}>
                            <source src={res.voice_url} type="audio/mpeg" />
                          </audio>


                        </div>
                        <div>
                          <div>
                            {res.isComplete ? (<ProgressBar
                              className="custom_progress_barr custom_bartwoRealtor"
                              variant="success"
                              now={countOfProgess}
                            />) : <ProgressBar
                              className="custom_progress_barr custom_bartwoRealtor"
                              variant="success"
                              now={""}
                            />}
                          </div>
                          <div className="row info-bottm-card">

                            <div className="form-check d-flex justify-content-center mt-3">
                              <input
                                className="form-check-input"
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                // value={res.isChecked}
                                id="flexCheckChecke"
                                name={res.title}
                                checked={res.isChecked}
                                disabled={
                                  res.isEnable ? false : true
                                }
                                onChange={(e) => {
                                  checkboxHandler(e, id, res);
                                }}
                              />
                              <label
                                className="form-check-label fw-bold color_ main video_play"
                                for="flexCheckChecke"
                              // htmlFor="chooseCb"
                              >
                                &nbsp;
                                <a href=""> I Agree</a>
                              </label>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="not-found mt-3">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>Oops! no data found</p>
                  </div>
                </div>

              )}
            </Slider>{" "}
          </div>
        </div>

        <div className="row submitbtncheck">
          <div className="col-md-12">
            <div className="btn-agree-submit d-flex justify-content-center slick-active">
              <button
                id="submit"
                className={!submit ? "disabled_btn" : "enabled_btn"}
                // className="view-btn"
                onClick={() => { onSubmitHandler(); }}
                disabled={!submit ? true : false}
                title={!submit ? "Complete all the tiles before submitting." : ""}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <audio ref={speakref} id="myAudio">
        <source src={tempDesc} type="audio/mpeg" />
      </audio>

    </section>
  );
};
export default EthicsAggreeCheck;
