import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ProfileFilterSearchSection from "./EventFilterSearchSection";
import ProfileTableSeaction from "./EventTableSeaction";
import ProfileTitleSection from "./EventTitleSection";
import { getAnyTotalReport, getProfileCompletionReport } from '../../../redux/reports/actions'
import { SessionKeys } from '../../../utils/AppUtils'
import EventTitleSection from "./EventTitleSection";
import EventFilterSearchSection from "./EventFilterSearchSection";
import EventTableSeaction from "./EventTableSeaction";
import ReportViewFilterSection from "../ReportViewFilterSection";
import CONFIG from "../../../utils/config"

const EventReportHomeSection = (props) => {
    
    const {
        setIsLoading,
        isLoading
    } = props;

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [clickBuy, setClickBuy] = useState(true)
    const [clickFree, setClickFree] = useState(false)
    const [clickBrokerage, setClickBrokerage] = useState(false)
    const [isView, setIsView] = useState('table')
    const [type, setType] = useState('pie')
    const [statusFilterData, setStatusFilterData] = useState('table')
    const [canShow, setCanShow] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [officeIds, setOfficeIds] = useState([])

    useEffect(() => {
        dispatch(getAnyTotalReport({
            module: 'getEventReport',  
            postData: {
                date_range: {},
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                status: ['buy', 'free', 'brokerageSession'],
                office_ids: []
            }
        }))
    }, [dispatch])

    const getFilteredData = (action) => {
        let dateRange = {
            start: startDate?.toLocaleDateString("en-US"),
            end: endDate?.toLocaleDateString("en-US"),
        }
        let profileStatus = []
        // let profileStatus = null;

        if(clickBuy){
            profileStatus.push("buy")
        }
        if(clickFree){
            profileStatus.push("free")
        }
        if(clickBrokerage){
            profileStatus.push("brokerageSession")
        }
        
        let req = {
            date_range: dateRange,
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
        };
        if (profileStatus !== null) {
            req.status = profileStatus;
            setStatusFilterData(profileStatus)
        } 
        if(searchInput!==''){
            req.search=searchInput;
        }
        req.office_ids = officeIds
        setIsLoading(true)
        dispatch(getProfileCompletionReport({
            module: 'getEventReport',
            postData: req
        }))
        dispatch(getAnyTotalReport({
            module: 'getEventReport',  
            postData: {
                date_range: dateRange,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                status: ['buy', 'free', 'brokerageSession'],
                office_ids: officeIds
            }
        }))
    }

    return (
        <>
            <EventTitleSection />
            <EventFilterSearchSection
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                clickBuy={clickBuy}
                setClickBuy={setClickBuy}
                clickFree={clickFree}
                setClickFree={setClickFree}
                clickBrokerage={clickBrokerage}
                setClickBrokerage={setClickBrokerage}
                getFilteredData={getFilteredData}
                isView={isView}
                setIsView={setIsView}
                setOfficeIds={setOfficeIds}
            />
            {(canShow) ? <ReportViewFilterSection 
                isView={isView}
                setIsView={setIsView}
                type={type}
                setType={setType}
            /> : ''}
            <EventTableSeaction
                setSearchInput={setSearchInput}
                getFilteredData={getFilteredData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isView={isView}
                statusFilterData={statusFilterData}
                setCanShow={setCanShow}
                type={type}
            />
        </>
    )
}

export default EventReportHomeSection;