import _ from "lodash";
import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const ReactSelect = (props) => {
  const [options, setOptions] = useState([
    { value: "all", label: "Select All", isDisabled: false },
  ]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(props.options)) {
      let op = _.clone(options);
      for (let p of props.options) {
        op.push({
          value: p.value,
          label: p.label,
          isDisabled: true,
        });
      }
      setOptions(op);
    }
  }, [props.options]);

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      defaultValue={[options[0]]}
      isMulti
      options={options}
      classNamePrefix="xsel"
      className="w-100"
      onChange={(selected) => {
        // console.log(selected)
        if (_.isArray(selected) && !_.isEmpty(selected)) {
          let allFlag = false;
          for (let s of selected) {
            if (s.value === "all") {
              allFlag = true;
            }
          }
          if (allFlag) {
            setOptions(
              options.map((o) => {
                if (o.value === "all") {
                  return o;
                } else {
                  return {
                    value: o.value,
                    label: o.label,
                    isDisabled: true,
                  };
                }
              })
            );
            props.setOfficeIds(
              props.options.map((s) => {
                return _.toNumber(s.value);
              })
            );
          } else {
            setOptions(
              options.map((o) => {
                if (o.value === "all") {
                  return {
                    value: o.value,
                    label: o.label,
                    isDisabled: true,
                  };
                } else {
                  return o;
                }
              })
            );
            props.setOfficeIds(
              selected.map((s) => {
                return _.toNumber(s.value);
              })
            );
          }
        } else {
          setOptions(
            options.map((o) => {
              return {
                value: o.value,
                label: o.label,
                isDisabled: false,
              };
            })
          );
        }
      }}
    />
  );
};

export default ReactSelect;
