import Page from "../Page";


const NoteList =()=>{
return(
    <Page>
    <section className="container-fluid">
<div className="row">
    <div className="sidenav" id="navbar">
        <a href="javascript:void(0)" className="closebtn" onclick="closeNav()">&times;</a>
        <div className="user-show-sec">
            <img className="img-fluid user-dash" src="images/user-dash.png" />
            <div className="dash-user-info">
                <h5>Ronnie Woodkin</h5>
                <p>Accredited Buyer Representative, Calumet City, IL</p>
            </div>
        </div>
        
        <ul className="left-menu">
            <li><a className="" href="my-onboarding-first-time-user.html"><span><i className="handshake" aria-hidden="true"></i></span> Onboarding</a></li>
            <li><a className="" href="dashboard_new.html"><span><i className="dashboard" aria-hidden="true"></i></span> Dashboard</a></li>
            <li><a className="" href="notifications.html"><span><i className="notifications" aria-hidden="true"></i></span> Notifications</a></li>
            <li><a className="" href="my-learning-center.html"><span><i className="mylearningcenters" aria-hidden="true"></i></span> My learning Center</a></li>
            <li><a className="" href="my-event.html"><span><i className="myevents" aria-hidden="true"></i></span> My Events</a></li>
            <li><a className="" href="favorites.html"><span><i className="favorites" aria-hidden="true"></i></span> Favorites</a></li>
            <li className="active"><a className="" href="notes.html"><span><i className="notes" aria-hidden="true"></i></span> Notes</a></li>
            <li><a className="" href="network.html"><span><i className="mynetworks" aria-hidden="true"></i></span> My Network</a></li>
            <li><a className="" href="profile.html"><span><i className="settings" aria-hidden="true"></i></span> Edit Profile / Settings</a></li>
            <li><a className="" href="index.html"><span><i className="signout" aria-hidden="true"></i></span> Logout</a></li>
            <li><a className="" href="#"><span><i className="premium-ic" aria-hidden="true"></i> </span> Upgrade to Premium</a></li>
            
        </ul>
    </div>
    <div className="main-content">
        <div className="accordion" id="accordionleft">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#dashboard-acc" aria-expanded="true" aria-controls="dashboard-acc">
                        <img src="images/notes-white.png"/> <span className="acc-btn-cont">Notes</span>  <img src="images/info-icon.png" />
                    </button>
                </h2>
                <div id="dashboard-acc" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionleft">
                    <div className="accordion-body">
                        <div className="d-flex justify-content-between filtersection mb-3">
                            <div className="">
                                <form className="d-flex containsearchbox">
                                    <input className="form-control inputsearch " type="text" placeholder="Search" />
                                    <button className="btn searchbtn-body" type="button"><i className="fa fa-search"
                                            aria-hidden="true"></i></button>
                                </form>
                            </div>
                            <div className="refinefilter">
                                <div className="row">
                                    <div className="col-auto droplabel">
                                        <label for="" className="col-form-label">Sort by:</label>
                                    </div>
                                    <div className="col-auto dropselectinp">
                                        <select className="form-select" aria-label="Short By">
                                            <option selected>Date</option>
                                            <option value="1">Date New to Old</option>
                                            <option value="2">Date New to Old</option>
                                            <option value="3">Date New to Old</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion accordion-flush notesaccord" id="accordionFlushExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                <div className="actionicons-notes actionicons">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </div>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                  <div className="note-btn-container">
                                    <p>Morbi tempus nibh semper</p>
                                    <span className="color_main">Jan 12, 2022</span>
                                  </div>
                                </button>
                              </h2>
                              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="row noteaccbody">
                                        <div className="col-md-4"><img className="img-fluid" src="images/note-acc-img.png" /></div>
                                        <div className="col-md-8">
                                            <p>These are the notes user has taken. Maecenas et ante condimentum lectus sollicitudin fermentum eu quis velit. Curabitur dignissim pharetra massa, ac laoreet augue maximus sit amet. Donec gravida, tortor vitae convallis vulputate, elit nulla porta felis, id sodales nibh nunc eget dui. Integer ut leo sed nibh tincidunt vestibulum non id enim. Pellentesque commodo ultrices mauris, et suscipit magna finibus non. Duis feugiat, diam id tincidunt tristique, diam mauris placerat arcu, et posuere neque leo quis metus. Proin a risus a risus porta imperdiet vel eget dui.</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingTwo">
                                <div className="actionicons-notes actionicons">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </div>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <div className="note-btn-container">
                                        <p>Morbi tempus nibh semper</p>
                                        <span className="color_main">Jan 12, 2022</span>
                                    </div>
                                </button>
                              </h2>
                              <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="row noteaccbody">
                                        <div className="col-md-4"><img className="img-fluid" src="images/note-acc-img.png" /></div>
                                        <div className="col-md-8">
                                            <p>These are the notes user has taken. Maecenas et ante condimentum lectus sollicitudin fermentum eu quis velit. Curabitur dignissim pharetra massa, ac laoreet augue maximus sit amet. Donec gravida, tortor vitae convallis vulputate, elit nulla porta felis, id sodales nibh nunc eget dui. Integer ut leo sed nibh tincidunt vestibulum non id enim. Pellentesque commodo ultrices mauris, et suscipit magna finibus non. Duis feugiat, diam id tincidunt tristique, diam mauris placerat arcu, et posuere neque leo quis metus. Proin a risus a risus porta imperdiet vel eget dui.</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingThree">
                                <div className="actionicons-notes actionicons">
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </div>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <div className="note-btn-container">
                                        <p>Morbi tempus nibh semper</p>
                                        <span className="color_main">Jan 12, 2022</span>
                                    </div>
                                </button>
                              </h2>
                              <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="row noteaccbody">
                                        <div className="col-md-4"><img className="img-fluid" src="images/note-acc-img.png" /></div>
                                        <div className="col-md-8">
                                            <p>These are the notes user has taken. Maecenas et ante condimentum lectus sollicitudin fermentum eu quis velit. Curabitur dignissim pharetra massa, ac laoreet augue maximus sit amet. Donec gravida, tortor vitae convallis vulputate, elit nulla porta felis, id sodales nibh nunc eget dui. Integer ut leo sed nibh tincidunt vestibulum non id enim. Pellentesque commodo ultrices mauris, et suscipit magna finibus non. Duis feugiat, diam id tincidunt tristique, diam mauris placerat arcu, et posuere neque leo quis metus. Proin a risus a risus porta imperdiet vel eget dui.</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                  <div className="actionicons-notes actionicons">
                                      <i className="fa fa-trash" aria-hidden="true"></i>
                                  </div>
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                                      <div className="note-btn-container">
                                          <p>Morbi tempus nibh semper</p>
                                          <span className="color_main">Jan 12, 2022</span>
                                      </div>
                                  </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                  <div className="accordion-body">
                                      <div className="row noteaccbody">
                                          <div className="col-md-4"><img className="img-fluid" src="images/note-acc-img.png" /></div>
                                          <div className="col-md-8">
                                              <p>These are the notes user has taken. Maecenas et ante condimentum lectus sollicitudin fermentum eu quis velit. Curabitur dignissim pharetra massa, ac laoreet augue maximus sit amet. Donec gravida, tortor vitae convallis vulputate, elit nulla porta felis, id sodales nibh nunc eget dui. Integer ut leo sed nibh tincidunt vestibulum non id enim. Pellentesque commodo ultrices mauris, et suscipit magna finibus non. Duis feugiat, diam id tincidunt tristique, diam mauris placerat arcu, et posuere neque leo quis metus. Proin a risus a risus porta imperdiet vel eget dui.</p>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                  <div className="actionicons-notes actionicons">
                                      <i className="fa fa-trash" aria-hidden="true"></i>
                                  </div>
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseThree">
                                      <div className="note-btn-container">
                                          <p>Morbi tempus nibh semper</p>
                                          <span className="color_main">Jan 12, 2022</span>
                                      </div>
                                  </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                  <div className="accordion-body">
                                      <div className="row noteaccbody">
                                          <div className="col-md-4"><img className="img-fluid" src="images/note-acc-img.png" /></div>
                                          <div className="col-md-8">
                                              <p>These are the notes user has taken. Maecenas et ante condimentum lectus sollicitudin fermentum eu quis velit. Curabitur dignissim pharetra massa, ac laoreet augue maximus sit amet. Donec gravida, tortor vitae convallis vulputate, elit nulla porta felis, id sodales nibh nunc eget dui. Integer ut leo sed nibh tincidunt vestibulum non id enim. Pellentesque commodo ultrices mauris, et suscipit magna finibus non. Duis feugiat, diam id tincidunt tristique, diam mauris placerat arcu, et posuere neque leo quis metus. Proin a risus a risus porta imperdiet vel eget dui.</p>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</Page>

)
}
export default NoteList;