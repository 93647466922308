

const ProductDetailSection = (props) => {
    const { productInfo, productAnalytics } = props;

    return (
        <div className=" premium-bg-product first-time-product">
            <div className="star">
            <h5 className="w-100">{productInfo?.title}</h5> 
            <img src="/images/product_star_img.jpg" width="200" alt="" />
            <img src="/images/line_.jpg" width="2" height="30px" alt="" />
            <p>&nbsp;{productAnalytics?.views} Views</p>
        </div>
            <div className="courseDes_icon">
                <img src="/images/icon001.png" width="25px" height="25px" alt="" />
                <img src="/images/head_phone002.png"  width="25px" height="25px" alt="" />
                <img src="/images/icon003.png"  width="25px" height="25px" alt="" />
            </div>
            <div className="course_content">
                <h5 className="popup-head-bold fw-bold">Course Description</h5>
                <p>{productInfo?.description}</p>
            </div>
        </div>  
    );
}
export default ProductDetailSection;