import { useEffect, useState } from "react";
import ConfirmModal from "../modal/ConfirmModal";

const CourseCard = (props) => {
    const { 
        course,
        remove,
        closeModal,
        setCloseModal,
    } = props;

    const [confirmModal, setConfirmModal] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [showModalMsg, ] = useState('Are you sure you want to remove this course from your favorites?');

    useEffect(() => {
        if (closeModal) {
            setConfirmModal(false);
        }
    }, [closeModal])

    useEffect(() => {
        if (!confirmModal) {
            setCloseModal(false)
        }
    }, [confirmModal])

    return (
        <>
            <div className="img-box" style={{width: '327px'}}>
                <img className="slider-img-inner" src={course.imageURL} />
                <div className="details-cards-oll">
                    <div className="progress">
                        <div className="progress-bar" style={{width:"0%"}}></div>
                    </div>
                    <h5>{course.name}</h5>
                    <div className="row info-bottm-card">
                        <div className="col-md-7">
                            <p><i className="fa fa-clock-o" aria-hidden="true"></i>: {course.duration} {course.durationUnit}</p>
                            <p>{course.views} View{course.views === 0 ? '' : 's'}</p>
                        </div>
                    </div>
                </div>
                <div className="card-footer-section">
                    <p><span className="color_main">Saved on:</span> Jan 12, 2022</p>
                    <div className="actionicons">
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                        <i className="fa fa-trash" aria-hidden="true" onClick={() => {
                            setConfirmModal(true);
                            setIsConfirm(true);
                            // console.log('remove');
                        }}></i>
                    </div>
                </div>
            </div>
            <ConfirmModal 
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
                showModalMsg={showModalMsg}
                isConfirm={isConfirm}
                confirmId={course.id}
                confirmAction={remove}
            />
        </>
    )
}

export default CourseCard;