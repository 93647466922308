import { React, useState } from 'react';
import { toolboxInfo } from "../../utils/toolbox";
import { useHistory } from "react-router-dom";
import ConfirmModal from '../../components/modal/ConfirmModal';
const ToolboxFolders = (props) => {
    const { folderList, globalFolderList, deleteFolderFun, confirmModal, setConfirmModal } = props;
    const history = useHistory();
    console.log("Box location", toolboxInfo?.folderNameSec)
    const [currFolder, setCurrFolder] = useState(0);
    return (
        <>
            {folderList.length > 0 && <div className="col-md-12 mb-3">
                <h6 className="totl-file">Folders</h6>
            </div>
            }

            {folderList && folderList.length > 0 &&
                folderList.map((item, index) => {
                    return <div className="col-md-3 mb-4">
                        <div className="course_management" >
                            <svg id="Close" onClick={() => {
                                setCurrFolder(item?.id)
                                setConfirmModal(true)
                            }} className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="30" height="30" viewBox="0 0 30 30">

                                <defs>
                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#00b7f1" />
                                        <stop offset="1" stop-color="#ed156e" />
                                    </linearGradient>
                                </defs>
                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                                    <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                    <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                </g>
                                <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                                    <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                    <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                </g>
                            </svg>
                            <svg onClick={() => {
                                history.push("/toolbox?folderId=" + item?.id)
                            }} xmlns="http://www.w3.org/2000/svg" width="37.667" height="30.133" viewBox="0 0 37.667 30.133">
                                <path id="folder-245" d="M100.4,170.666H89.1a3.75,3.75,0,0,0-3.748,3.767l-.019,22.6A3.766,3.766,0,0,0,89.1,200.8h30.133A3.766,3.766,0,0,0,123,197.033V178.2a3.766,3.766,0,0,0-3.767-3.767H104.167l-3.767-3.767Z" transform="translate(-85.333 -170.666)" fill={item?.colorValue} />
                            </svg>
                            <div onClick={() => {
                                history.push("/toolbox?folderId=" + item?.id)
                            }} className="foldertex">
                                <h5 title={item?.folderNameSec}>{item?.folderNameSec}</h5>
                                <strong className="course_counts visibile_count">{item?.item_count}</strong><span> resource{Number(item?.item_count) > 1 ? "s" : ""}</span>
                            </div>
                        </div>
                    </div>
                })
                // <p> No folders created yet.</p>
            }


            {confirmModal && <ConfirmModal
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
                showModalMsg={"Are you sure you want to delete the folder?"}
                isConfirm={true}
                confirmId={currFolder}
                confirmAction={deleteFolderFun} />}

        </>
    )
}
export default ToolboxFolders;