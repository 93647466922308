import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rolePlayStatus, timezoneArea } from "../../utils/AppUtils";
import MESSAGE from "../../utils/messages";
import ContentBoxWrapper from "../Layouts/ContentBoxWrapper";
import TableRolePlayComponent from "../Layouts/Table/TableRolePlayComponent";
import RolePlayCardView from "./RolePlayCardView";

const ReviewRolePlayPick = (props) => {

    const {
        isLoading,
        setIsLoading,
        reviewType,
        setReviewType,
        listing,
        roleData,
        pendingCount,
        reviewedCount,
        showForReview
    } = props

    const dispatch = useDispatch();
    const userDetailRes = useSelector(state => state.user.userBroker)

    const [reviewListing, setReviewListing] = useState([])
    const [contentDataSet, setContentDataSet] = useState([
        {title: 'To Review', count: pendingCount, key: "pending"},
        {title: 'Completed', count: reviewedCount, key: "reviewed"}
    ])
    const [header, setHeader] = useState([])
    const [rowData, setRowData] = useState([])
    const [searchText, setSearchText] = useState('')
    const [view, setView] = useState('table');

    useEffect(() => {
        setSearchText('')
    }, [reviewType])
    
    useEffect(() => {
        if (!_.isEmpty(listing) && !_.isEmpty(userDetailRes)) {
            setReviewListing(listing)
            // setRowData(listing)
            
            setRowData(listing.map(record => {
                return {
                    id: record.role_play_id,
                    title: record.title,
                    isVideo: record.uploadedType === 'video',
                    status: rolePlayStatus(record.status, 'reviewer'),
                    submittedDate: record.submissionDate,
                    name: record.userFirstName + " " + record.userLastName,
                    reviewedDate: reviewType === 'reviewed' ? 
                    record.reviewedDate ? record.reviewedDate : "-" : "-",
                    showView: reviewType === 'reviewed', //record.status === 'drafted' ? false : true,
                    showSubmit: false, //record.status === 'drafted' ? true : false,
                    showReSubmit: false,//record.status === 'feedback_received' ? true : false
                    showReview: reviewType === 'pending'
                }
            }))
        } else {
            setReviewListing([])
            setRowData([])
        }
            // setIsLoading(false)
    }, [listing, userDetailRes])

    useEffect(() => {
        // console.log('PENDING COUNT', pendingCount)
        const dataSet = _.clone(contentDataSet)
        for (let data of dataSet) {
            if (data.key === 'pending') {
                data.count = pendingCount
            }
        }
        setContentDataSet(dataSet)
    }, [pendingCount])

    useEffect(() => {
        // console.log('REVIEWED COUNT', reviewedCount)
        const dataSet = _.clone(contentDataSet)
        for (let data of dataSet) {
            if (data.key === 'reviewed') {
                data.count = reviewedCount
            }
        }
        setContentDataSet(dataSet)
    }, [reviewedCount])

    useEffect(() => {
        // if (reviewType === 'pending') {
        //     setHeader(['Title and Category', 'Participant', 'Date Submitted', 'Action'])
        // } else if (reviewType === 'reviewed') {
        //     setHeader(['Title and Category', 'Participant', 'Date Submitted', 'Feedback Score'])
        // }
        setHeader(['Title', 'Audio/Video', 'Status', 'Submitted On', 'Submitted By', 'Feedback On', ''])
    }, [reviewType])

    useEffect(() => {
        // searchInListing()
    }, [searchText])

    const searchInListing = () => {
        let tempArray = []
        if (searchText) {
            for (let record of listing) {
                // console.log(record, record.title.toLowerCase().includes(searchText.toLowerCase()))
                if (record.title.toLowerCase().includes(searchText.toLowerCase())) {
                    tempArray.push({
                        id: record.role_play_id,
                        title: record.title,
                        isVideo: record.roleplay_url.includes('.m3u8') ? true : false,
                        status: rolePlayStatus(record.status, 'reviewer'),
                        submittedDate: record.submissionDate,
                        name: record.userFirstName + " " + record.userLastName,
                        reviewedDate: reviewType === 'reviewed' ? "-" : 
                        record.reviewedDate ? record.reviewedDate : "-",
                        showView: reviewType === 'reviewed', //record.status === 'drafted' ? false : true,
                        showSubmit: false, //record.status === 'drafted' ? true : false,
                        showReSubmit: false,//record.status === 'feedback_received' ? true : false
                        showReview: reviewType === 'pending'
                    })
                }
            }
        } else {
            // tempArray = listing;
            for (let record of listing) {
                tempArray.push({
                    id: record.role_play_id,
                    title: record.title,
                    isVideo: record.roleplay_url.includes('.m3u8') ? true : false,
                    status: rolePlayStatus(record.status, 'reviewer'),
                    submittedDate: record.submissionDate,
                    name: record.userFirstName + " " + record.userLastName,
                    reviewedDate: reviewType === 'reviewed' ? "-" : 
                    record.reviewedDate ? record.reviewedDate : "-",
                    showView: reviewType === 'reviewed', //record.status === 'drafted' ? false : true,
                    showSubmit: false, //record.status === 'drafted' ? true : false,
                    showReSubmit: false,//record.status === 'feedback_received' ? true : false
                    showReview: reviewType === 'pending'
                })
            }
        }
        setRowData(tempArray)
    }

    const renderSearch = () => {
        return (
            <div className="input-lear position-relative d-flex roleplay-search">
                <label for="search" className="input__label_learning">Search</label>
                <input type="search" id="search" name="search"
                    value={searchText}
                    className="input_searchfield_search-cart " placeholder=" "
                    onChange={(evt) => setSearchText(evt.target.value)}
                />
                {/* <span className={`${searchText.length > 0 ? 'close-search-text' : 'hide'}`}
                onClick={() => setSearchText('')}>
                    <i className="fa fa-close"></i>
                </span> */}
                <a href="#">
                    <button 
                        className="button-reset"
                        onClick={() => searchInListing()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Find"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                        >
                            <defs>
                            <linearGradient
                                id="linear-gradient"
                                y1="0.5"
                                x2="1"
                                y2="0.5"
                                gradientUnits="objectBoundingBox"
                            >
                                <stop offset="0" stop-color="#00b7f1" />
                                <stop offset="1" stop-color="#ed156e" />
                            </linearGradient>
                            </defs>
                            <circle
                            id="Ellipse_191"
                            data-name="Ellipse 191"
                            cx="15"
                            cy="15"
                            r="15"
                            fill="url(#linear-gradient)"
                            />
                            <circle
                            id="Ellipse_192"
                            data-name="Ellipse 192"
                            cx="13"
                            cy="13"
                            r="13"
                            transform="translate(2 2)"
                            fill="#fff"
                            />
                            <path
                            id="Icon_awesome-search"
                            data-name="Icon awesome-search"
                            d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                            transform="translate(7.715 7.714)"
                            fill="var(--color-element-1)"
                            />
                        </svg>
                    </button>
                </a>
            </div>
        )
    }

    const renderDownloadBtn = () => {
        return (
            <button className="button-reset downbtnsreport">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="Find"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                >
                <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#00b7f1" />
                    <stop offset="1" stop-color="#ed156e" />
                    </linearGradient>
                </defs>
                <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                <path id="file-chart" d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z" transform="translate(5.342 5.927)" fill="var(--color-element-1)"/>
                </svg>
            </button>
        )
    }

    const renderCardArea = () => {
        return (
            <div className="mt-4">
                <div className="row rolesubmitgridview">
                    {
                        rowData.map((record, index) => {
                            return (
                                <RolePlayCardView 
                                    key={`role-${index + 1}`} 
                                    roleplayData={record}
                                    showForReview={showForReview}
                                    setFormData={() => {}}
                                    isReviewPage={true}
                                />
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            <ContentBoxWrapper
                contentArr={contentDataSet}
                reviewType={reviewType}
                setReviewType={setReviewType}
                setIsLoading={setIsLoading}
            />
            <section className="reviews-data-table table-report-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 search__input">
                            {renderSearch()}
                        </div>
                        <div className="col-7"> 
                            <div className="changeviewbtnal">
                                {
                                    rowData.length > 0 ?
                                    <>
                                        <button className="listviewbtnrole"
                                            onClick={() => setView('table')}
                                        >
                                            {
                                                view === 'table'
                                                ?
                                                <svg xmlns="http://www.w3.org/2000/svg" id="g3988" width="36" height="28.286" viewBox="0 0 36 28.286">
                                                    <path id="path3990" d="M10.286-1385.5v3.857a1.86,1.86,0,0,1-.563,1.366,1.86,1.86,0,0,1-1.366.563H1.929a1.86,1.86,0,0,1-1.366-.562A1.86,1.86,0,0,1,0-1381.643v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H8.357a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,10.286-1385.5Zm0-10.286v3.857a1.86,1.86,0,0,1-.563,1.366,1.86,1.86,0,0,1-1.366.563H1.929a1.86,1.86,0,0,1-1.366-.562A1.86,1.86,0,0,1,0-1391.929v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H8.357a1.86,1.86,0,0,1,1.366.562A1.86,1.86,0,0,1,10.286-1395.786ZM36-1385.5v3.857a1.86,1.86,0,0,1-.562,1.366,1.86,1.86,0,0,1-1.366.563H14.786a1.86,1.86,0,0,1-1.366-.562,1.86,1.86,0,0,1-.562-1.366v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H34.071a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,36-1385.5Zm-25.714-20.572v3.857a1.86,1.86,0,0,1-.563,1.366,1.86,1.86,0,0,1-1.366.563H1.929a1.86,1.86,0,0,1-1.366-.562A1.86,1.86,0,0,1,0-1402.214v-3.857a1.86,1.86,0,0,1,.563-1.366A1.86,1.86,0,0,1,1.929-1408H8.357a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,10.286-1406.072ZM36-1395.786v3.857a1.86,1.86,0,0,1-.562,1.366,1.86,1.86,0,0,1-1.366.563H14.786a1.86,1.86,0,0,1-1.366-.562,1.86,1.86,0,0,1-.562-1.366v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H34.071a1.86,1.86,0,0,1,1.366.562A1.86,1.86,0,0,1,36-1395.786Zm0-10.286v3.857a1.86,1.86,0,0,1-.562,1.366,1.86,1.86,0,0,1-1.366.563H14.786a1.86,1.86,0,0,1-1.366-.562,1.86,1.86,0,0,1-.562-1.366v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H34.071a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,36-1406.072Z" transform="translate(0 1408)" fill="#415a6c"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" id="g3988" width="36" height="28.286" viewBox="0 0 36 28.286">
                                                    <path id="path3990" d="M10.286-1385.5v3.857a1.86,1.86,0,0,1-.563,1.366,1.86,1.86,0,0,1-1.366.563H1.929a1.86,1.86,0,0,1-1.366-.562A1.86,1.86,0,0,1,0-1381.643v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H8.357a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,10.286-1385.5Zm0-10.286v3.857a1.86,1.86,0,0,1-.563,1.366,1.86,1.86,0,0,1-1.366.563H1.929a1.86,1.86,0,0,1-1.366-.562A1.86,1.86,0,0,1,0-1391.929v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H8.357a1.86,1.86,0,0,1,1.366.562A1.86,1.86,0,0,1,10.286-1395.786ZM36-1385.5v3.857a1.86,1.86,0,0,1-.562,1.366,1.86,1.86,0,0,1-1.366.563H14.786a1.86,1.86,0,0,1-1.366-.562,1.86,1.86,0,0,1-.562-1.366v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H34.071a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,36-1385.5Zm-25.714-20.572v3.857a1.86,1.86,0,0,1-.563,1.366,1.86,1.86,0,0,1-1.366.563H1.929a1.86,1.86,0,0,1-1.366-.562A1.86,1.86,0,0,1,0-1402.214v-3.857a1.86,1.86,0,0,1,.563-1.366A1.86,1.86,0,0,1,1.929-1408H8.357a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,10.286-1406.072ZM36-1395.786v3.857a1.86,1.86,0,0,1-.562,1.366,1.86,1.86,0,0,1-1.366.563H14.786a1.86,1.86,0,0,1-1.366-.562,1.86,1.86,0,0,1-.562-1.366v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H34.071a1.86,1.86,0,0,1,1.366.562A1.86,1.86,0,0,1,36-1395.786Zm0-10.286v3.857a1.86,1.86,0,0,1-.562,1.366,1.86,1.86,0,0,1-1.366.563H14.786a1.86,1.86,0,0,1-1.366-.562,1.86,1.86,0,0,1-.562-1.366v-3.857a1.86,1.86,0,0,1,.563-1.366,1.86,1.86,0,0,1,1.366-.562H34.071a1.86,1.86,0,0,1,1.366.563A1.86,1.86,0,0,1,36-1406.072Z" transform="translate(0 1408)" fill="#c4c4c4"/>
                                                </svg>
                                            }
                                        </button>      
                                        <button className="gridviewbtnrole"
                                            onClick={() => setView('card')}
                                        >
                                            {
                                                view === 'table' ? 
                                                    <svg xmlns="http://www.w3.org/2000/svg" id="g3988" width="36.468" height="28.19" viewBox="0 0 36.468 28.19">
                                                        <path id="path3990" d="M16.216-1388.871v6.041a2.9,2.9,0,0,1-.887,2.14,2.943,2.943,0,0,1-2.154.881H3.041a2.943,2.943,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1382.83v-6.041a2.9,2.9,0,0,1,.887-2.14,2.942,2.942,0,0,1,2.154-.881H13.176a2.942,2.942,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1388.871Zm0-16.109v6.041a2.9,2.9,0,0,1-.887,2.139,2.942,2.942,0,0,1-2.154.881H3.041a2.942,2.942,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1398.939v-6.041a2.9,2.9,0,0,1,.887-2.139A2.943,2.943,0,0,1,3.041-1408H13.176a2.943,2.943,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1404.98Z" transform="translate(0 1408)" fill="#c4c4c4"/>
                                                        <path id="path3990-2" data-name="path3990" d="M16.216-1388.871v6.041a2.9,2.9,0,0,1-.887,2.14,2.943,2.943,0,0,1-2.154.881H3.041a2.943,2.943,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1382.83v-6.041a2.9,2.9,0,0,1,.887-2.14,2.942,2.942,0,0,1,2.154-.881H13.176a2.942,2.942,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1388.871Zm0-16.109v6.041a2.9,2.9,0,0,1-.887,2.139,2.942,2.942,0,0,1-2.154.881H3.041a2.942,2.942,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1398.939v-6.041a2.9,2.9,0,0,1,.887-2.139A2.943,2.943,0,0,1,3.041-1408H13.176a2.943,2.943,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1404.98Z" transform="translate(20.252 1408)" fill="#c4c4c4"/>
                                                    </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" id="g3988" width="36.468" height="28.19" viewBox="0 0 36.468 28.19">
                                                    <path id="path3990" d="M16.216-1388.871v6.041a2.9,2.9,0,0,1-.887,2.14,2.943,2.943,0,0,1-2.154.881H3.041a2.943,2.943,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1382.83v-6.041a2.9,2.9,0,0,1,.887-2.14,2.942,2.942,0,0,1,2.154-.881H13.176a2.942,2.942,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1388.871Zm0-16.109v6.041a2.9,2.9,0,0,1-.887,2.139,2.942,2.942,0,0,1-2.154.881H3.041a2.942,2.942,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1398.939v-6.041a2.9,2.9,0,0,1,.887-2.139A2.943,2.943,0,0,1,3.041-1408H13.176a2.943,2.943,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1404.98Z" transform="translate(0 1408)" fill="#415a6c"/>
                                                    <path id="path3990-2" data-name="path3990" d="M16.216-1388.871v6.041a2.9,2.9,0,0,1-.887,2.14,2.943,2.943,0,0,1-2.154.881H3.041a2.943,2.943,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1382.83v-6.041a2.9,2.9,0,0,1,.887-2.14,2.942,2.942,0,0,1,2.154-.881H13.176a2.942,2.942,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1388.871Zm0-16.109v6.041a2.9,2.9,0,0,1-.887,2.139,2.942,2.942,0,0,1-2.154.881H3.041a2.942,2.942,0,0,1-2.154-.881A2.9,2.9,0,0,1,0-1398.939v-6.041a2.9,2.9,0,0,1,.887-2.139A2.943,2.943,0,0,1,3.041-1408H13.176a2.943,2.943,0,0,1,2.154.881A2.9,2.9,0,0,1,16.216-1404.98Z" transform="translate(20.252 1408)" fill="#415a6c"/>
                                                </svg>
                                            }
                                        </button>
                                    </>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row list-coach report-table-rsp">
                        <div className="col-lg-12 col-md-12">
                            <div className="table-responsive1">
                                {rowData.length > 0
                                ? 
                                    view === 'table' ?
                                    <TableRolePlayComponent 
                                        header={header}
                                        row={rowData}
                                        reviewType={reviewType}
                                        showForReview={showForReview}
                                        view='review'
                                    />
                                    : renderCardArea()
                                : !isLoading ? 
                                <div className="not-found cart-notfound">
                                    <div className="interboxes">
                                    <img src="/images/telescope.svg" alt="Not Found" />
                                    <p>
                                        {
                                        reviewType === 'pending' ? 
                                        searchText ? MESSAGE.EMPTY_MYROLEPLAY_SEARCH: MESSAGE.EMPTY_ROLEPLAY_REVIEW
                                        : searchText ? MESSAGE.EMPTY_MYROLEPLAY_SEARCH : MESSAGE.EMPTY_ROLEPLAY_COMPLETE
                                        }
                                    </p>
                                    </div>
                                </div>
                                : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )   
}

export default  ReviewRolePlayPick;