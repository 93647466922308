import Page from "../../components/Page";
import ProgressReportSection from "../../components/Reports/New Progress Report/ProgressReportSection";

export const ProgressReportPage = () => {
    return (
        <Page>
            <ProgressReportSection
                isMobile={false}
            />
        </Page>
    )

}

export default ProgressReportPage;