import React from "react";
import { useState } from "react";
import Page from "../../components/Page";
import Loader from "../../components/Loader/Loader";

import VideoPlayerBroadcastSection from "./VideoPlayerBroadcastSection";

const BroadcastVideoPlayerBroadcastPage = () => {
  
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <VideoPlayerBroadcastSection
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isMobile={false}
        />
      </Page>
    </>
  );
};

export default BroadcastVideoPlayerBroadcastPage;
