import { fork, take, call, put, all } from 'redux-saga/effects'
import {  myCartResponse, myCart,myCartDeleteResponse,myCartSaveLaterResponse,dynamicBannerResponse,dynamicBannerBrokerResponse,learningPointsResponse,myCartSaveLaterViewResponse,delSaveLaterViewResponse, creditPointsResponse, couponCodeApplyRes} from './actions'
import types from './types';
import { getAPIWithID,getAPI,postAPI, postAPIWithoutModule } from '../../api/generic'

export function* viewMyCart() {
    while (true) {
      const { payload } = yield take(types.MY_CART)
      const response = yield call(getAPI, payload)
      yield put(myCartResponse(response))
    }
}
export function* doGetMyCartDelete() {
  while (true) {
    const { payload } = yield take(types.MY_CART_DELETE)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(myCartDeleteResponse(response))
  }
}
export function* MyCartSaveLater() {
  while (true) {
    const { payload } = yield take(types.SAVE_LATER)
    const response = yield call(postAPI, payload)
    yield put(myCartSaveLaterResponse(response))
  }
}
export function* MyCartSaveLaterView() {
  while (true) {
    const { payload } = yield take(types.SAVE_LATER_VIEW)
    const response = yield call(getAPI, payload)
    yield put(myCartSaveLaterViewResponse(response))
  }
}
export function* DelSaveLaterView() {
  while (true) {
    const { payload } = yield take(types.DEL_SAVE_LATER)
    const response = yield call(postAPI, payload)
    yield put(delSaveLaterViewResponse(response))
  }
}
export function* learningPointsHistory() {
  while (true) {
    const { payload } = yield take(types.LEARNING_POINTS)
    const response = yield call(getAPI, payload)
    yield put(learningPointsResponse(response))
  }
}

export function* creditPointsHistory() {
  while (true) {
    const { payload } = yield take(types.CREDIT_POINTS)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(creditPointsResponse(response))
  }
}
// export function* dynamicBannerPage1() {
//   while (true) {
//     const { payload } = yield take(types.DYNAMIC_BANNER)
//     const response = yield call(postAPI, payload)
//     yield put(dynamicBannerResponse(response))
//   }
// }


export function* dynamicBannerPage() {
  while (true) {
    const { payload } = yield take(types.DYNAMIC_BANNER)
    // const response = yield call(postAPIWithoutModule, payload)
    const response = yield all([
      call(getAPI, payload.broker)
    ])
    yield put(dynamicBannerResponse(response))
  }
}



export function* dynamicBannerBrokerPage() {
  while (true) {
    const { payload } = yield take(types.DYNAMIC_BANNER_BROKER)
    const response = yield call(postAPI, payload)
    yield put(dynamicBannerBrokerResponse(response))
  }
}

export function* doCouponCodeApply() {
  while (true) {
    const { payload } = yield take(types.COUPON_CODE_APPLY)
    const response = yield call(postAPI, payload)
    yield put(couponCodeApplyRes(response))
  }
}

function* cart() {  
  yield fork(viewMyCart)
  yield fork (doGetMyCartDelete)
  yield fork (MyCartSaveLater)
  yield fork (MyCartSaveLaterView)
  yield fork (DelSaveLaterView)
  yield fork (learningPointsHistory)
  yield fork (creditPointsHistory)
  yield fork (dynamicBannerPage)
  yield fork (dynamicBannerBrokerPage)
  yield fork (doCouponCodeApply)
}
export default cart;