import types from "./types"

export const getMembershipProducts = (payload) => ({type: types.MEMBERSHIP_PRODUCTS, payload})
export const getMembershipProductsResponse = (payload) => ({type: types.MEMBERSHIP_PRODUCTS_RESPONSE, payload})
export const getMembershipProductsClear = () => ({type: types.MEMBERSHIP_PRODUCTS_CLEAR})

export const getMembershipIntent = (payload) => ({type: types.MEMBERSHIP_INTENT, payload})
export const getMembershipIntentResponse = (payload) => ({type: types.MEMBERSHIP_INTENT_RESPONSE, payload})
export const getMembershipIntentClear = () => ({type: types.MEMBERSHIP_INTENT_CLEAR})

export const sendMembershipOrderPlace = (payload) => ({type: types.MEMBERSHIP_ORDER_PLACE, payload})
export const sendMembershipOrderPlaceResponse = (payload) => ({type: types.MEMBERSHIP_ORDER_PLACE_RESPONSE, payload})
export const sendMembershipOrderPlaceClear = () => ({type: types.MEMBERSHIP_ORDER_PLACE_CLEAR})

export const setIsMembershipSucceed = () => ({type: types.MEMBERSHIP_IS_SUCCEED})
export const setIsMembershipClear = () => ({type: types.MEMBERSHIP_IS_CLEAR})