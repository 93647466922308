import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FeedbackSectionOne from "./Common/section1";
import FeedbackSectionTwo from "./Common/section2";
import { getModuleListing, getMyCourses } from "../../redux/courses/actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { SessionKeys } from "../../utils/AppUtils";
import Loader from "../Loader/Loader";
import {
  getLiveCalendarSessionList,
  getMyEvents,
} from "../../redux/events/actions";
import moment from "moment";
import { postAPIWithoutModule } from "../../api/generic";
import SpinnerLoader from "../Spinner";
import { clickStreamUser } from "../../utils/AppUtils";
const FeedbackPopupMain = ({
  show,
  setShow,
  setSuccessPopup,
  showCourses,
  learnerFeedbackPopup,
  isEureka,
}) => {
  const [feedbackDetails, setFeedbackDetails] = useState({
    selected: "OTHERS",
  });
  const [isLoading, setIsLoading] = useState(true);
  const initialCourseData = useSelector((state) => state.courses.myCourses);
  const initialEventsData = useSelector((state) => state.events.myEventsList);
  const initialSectionData = useSelector(
    (state) => state.events.liveCalendarSessionList
  );

  const [starSelected, setStarSelected] = useState();
  const [userComment, setUserComment] = useState("");
  const [errors, setErrors] = useState({ userComment: false });
  const [selectedCourse, setSelectedCourse] = useState("");
  const [moduleSelected, setModuleSelected] = useState();
  const [moduleData, setModuleData] = useState("");
  const moduleListingData = useSelector((state) => state.courses.moduleListing);
  const dispatch = useDispatch();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const auth = useSelector((state) => state.auth);
  const [courseData, setCourseData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleSubmitPopup() {
    if (!userComment) setErrors({ userComment: true });
    submitFeedbackMain();
  }

  useEffect(() => {
    if (
      initialEventsData?.[1]?.payload?.payload?.length > 0 &&
      initialSectionData?.[1]?.payload?.payload.length > 0
    ) {
      const allEventsData = [
        ...initialEventsData?.[1]?.payload?.payload,
        ...initialEventsData?.[0]?.payload?.payload,
      ];
      const allSectionData = [
        ...initialSectionData?.[0]?.payload?.payload,
        ...initialSectionData?.[1]?.payload?.payload,
      ];
      const completedSections = allSectionData?.filter(
        (each) => each?.date_time?.[0]?.is_completed == true
      );

      const finalEvents = allEventsData?.map((event) => {
        const accurateSessions = completedSections?.filter((session) => {
          return session?.event_id == event?.eventId;
        });

        const finalModules = accurateSessions?.map((each) => {
          return cleanSessionData(each);
        });
        return {
          id: event?.eventId,
          name: event?.event_name,
          modules: finalModules,
        };
      });

      setEventsData(finalEvents);
      setSelectedCourse(finalEvents[0]?.id, finalEvents[0]?.name);
      setIsLoading(false);
    }
  }, [initialEventsData, initialSectionData]);

  function selectedTabUpdate(value) {
    setIsLoading(true);
    if (value === "COURSE") {
      dispatch(
        getMyCourses({
          ecom: {
            module: "getMyCourses",
            broker: false,
            postData: {
              user_id: authBroker.payload.user.user_id,
              email: authBroker.payload.user.email,
            },
          },
          broker: {
            module: "getMyCourses",
            broker: true,
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            },
          },
        })
      );
    } else if (value === "EVENT") {
      const currentDate = moment();
      const startOfYear = moment().startOf("year");
      const date_range = {
        start_date: startOfYear.format("MM/DD/YYYY"),
        end_date: currentDate.format("MM/DD/YYYY"),
      };
      dispatch(
        getMyEvents({
          ecom: {
            module: "myEventsApi",
            postData: {
              module: "MyEventsApi",
              user_id: authBroker?.payload?.user.user_id,
            },
          },
          broker: {
            module: "myEventListBroker",
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            },
          },
        })
      );
      dispatch(
        getLiveCalendarSessionList({
          ecom: {
            module: "calendarSessionListEcom",
            postData: {
              email: authBroker.payload.user.email,
              user_id: authBroker.payload.user.user_id,
              company_id: authBroker?.payload?.user.company_id,
              date_range,
            },
          },
          broker: {
            module: "calendarSessionListBroker",
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
              date_range,
            },
          },
        })
      );
    } else {
      setIsLoading(false);
    }
  }

  async function submitFeedbackMain() {
    if (!starSelected) {
      setErrors({ userComment: true, error: "Rating is Required." });
      return;
    }
    if (!userComment)
      return setErrors({ userComment: true, error: "Comments are required." });

    let requestBody = {};
    if (feedbackDetails?.selected === "OTHER") {
      if (localStorage.getItem(SessionKeys.BATCH_ID) != null) {
        requestBody = {
          remarks: userComment,
          rating: starSelected,
          type: "Other",
          questions: [],
          batch_id: localStorage.getItem(SessionKeys.BATCH_ID),
        };

        clickStreamUser("feedback_btn", {
          userId: authBroker?.payload?.user.user_id,
          remarks: userComment,
          rating: starSelected,
          type: "Other",
          batch_id: localStorage.getItem(SessionKeys.BATCH_ID),
        });
      } else {
        requestBody = {
          remarks: userComment,
          rating: starSelected,
          type: "Other",
          questions: [],
        };

        clickStreamUser("feedback_btn", {
          remarks: userComment,
          rating: starSelected,
          type: "Other",
        });
      }
    } else {
      if (localStorage.getItem(SessionKeys.BATCH_ID) != null) {
        requestBody = {
          category_id: 0,
          remarks: userComment,
          rating: starSelected,
          type: "Other",
          questions: [],
          batch_id: localStorage.getItem(SessionKeys.BATCH_ID),
        };
        clickStreamUser("feedback_btn", {
          userId: authBroker?.payload?.user.user_id,
          remarks: userComment,
          rating: starSelected,
          type: "Other",
          batch_id: localStorage.getItem(SessionKeys.BATCH_ID),
          category_id: 0,
        });
      } else {
        requestBody = {
          category_id: 0,
          remarks: userComment,
          rating: starSelected,
          type: "Other",
          questions: [],
        };
        clickStreamUser("feedback_btn", {
          userId: authBroker?.payload?.user.user_id,
          category_id: 0,
          remarks: userComment,
          rating: starSelected,
          type: "Other",
        });
      }
    }
    setLoading(true);
    const res = await postAPIWithoutModule(
      {
        postData: requestBody,
        module: "postFeedbackQuestions",
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      },
      true
    );
    if (res.status === "success") {
      setLoading(false);
      setSuccessPopup(true);
      setShow(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      selectedTabUpdate(feedbackDetails?.selected);
    }
  }, [feedbackDetails?.selected]);

  function injectModuleIntoCourse(module, courseId) {
    setCourseData((prevCourseData) => {
      return prevCourseData.map((course) => {
        if (course.id === courseId) {
          return {
            ...course,
            modules: [...course.modules, module],
          };
        }
        return course;
      });
    });
  }

  function cleanCourseData(courseData) {
    if (!courseData || !Array.isArray(courseData) || !courseData.length)
      return [];
    const filteredCourses = courseData?.filter(
      (each) => each?.status !== "start"
    );

    const mappedCourses = filteredCourses?.map((each) => ({
      id: each?.crscd,
      name: each?.name,
      modules: each?.modules,
    }));

    return mappedCourses;
  }

  function cleanSessionData(data) {
    return data?.map((each) => {
      return { id: each?.session_id, name: each?.session_name };
    });
  }

  function cleanModulesData(moduleData) {
    return moduleData?.map((each) => {
      return { id: each?.module_id, name: each?.title };
    });
  }

  useEffect(() => {
    if (!moduleListingData?.payload?.payload || !courseData.length) return;
    const updatedCourseData = courseData?.map((course) => {
      const moduleListing = moduleListingData?.payload?.payload;
      const matchingModule = course?.name === moduleListing?.courseName;
      if (matchingModule) {
        const validModules = moduleListing.modules.filter(
          (module) => module.progress === "100.00"
        );

        return {
          ...course,
          modules: cleanModulesData(validModules),
        };
      }
      return course;
    });

    setCourseData(updatedCourseData);
    setIsLoading(false);
  }, [moduleListingData]);

  useEffect(() => {
    if (
      initialCourseData?.length > 0 &&
      initialCourseData?.[0]?.payload?.payload &&
      feedbackDetails?.selected === "COURSE"
    ) {
      const cleanedData = cleanCourseData([
        ...initialCourseData[0]?.payload?.payload,
        ...initialCourseData[1]?.payload?.payload,
      ]);
      setCourseData(cleanedData);
      cleanedData.length &&
        setCourseSelected(cleanedData?.[0]?.id, cleanedData?.[0]?.name);
      setIsLoading(false);
    }
  }, [initialCourseData]);

  // Memoization based updates
  function updateModulesPerCourse(courseId) {
    const course = courseData.find((course) => course.id === courseId);
    if (course?.modules) return;
    setIsLoading(true);
    if (auth?.authBroker?.payload?.user?.user_id) {
      dispatch(
        getModuleListing({
          module: "getNIITAdminModule",
          postData: {
            user_id: auth.authBroker.payload.user.user_id,
            course_id: courseId,
          },
        })
      );
    }
  }

  function setCourseSelected(id, name) {
    if (feedbackDetails?.selected === "COURSE") updateModulesPerCourse(id);
    setSelectedCourse(name);
  }

  useEffect(() => {
    userComment &&
      errors?.userComment &&
      setErrors({ ...errors, userComment: false });
  }, [userComment]);

  return (
    <Modal
      className="submit-feedback--popup past-module--complete--popup"
      show={show}
      onHide={setShow}
      size="lg"
      dialogClassName="modalassign"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header className={"header submit--feedback"}>
        <Modal.Title id="example-custom-modal-styling-title">
          Submit your feedback
        </Modal.Title>
        <img
          onClick={() => setShow(false)}
          src="/images/closeFeedback.svg"
          alt="Close"
          style={{cursor:"pointer"}}

        />
      </Modal.Header>

      <Modal.Body className="modal-body">
        <div className="body">
          <div className="buttons-section">
            {showCourses ? (
              <>
                <div
                  onClick={() =>
                    setFeedbackDetails({
                      ...feedbackDetails,
                      selected: "COURSE",
                    })
                  }
                  className={
                    feedbackDetails?.selected === "COURSE" ? "active" : ""
                  }
                >
                  COURSE
                </div>
                <div
                  onClick={() =>
                    setFeedbackDetails({
                      ...feedbackDetails,
                      selected: "EVENT",
                    })
                  }
                  className={
                    feedbackDetails?.selected === "EVENT" ? "active" : ""
                  }
                >
                  EVENT
                </div>
              </>
            ) : (
              <></>
              // <div
              //   onClick={() =>
              //     setFeedbackDetails({
              //       ...feedbackDetails,
              //       selected: "OTHERS",
              //     })
              //   }
              //   className={
              //     feedbackDetails?.selected === "OTHERS" ? "active" : ""
              //   }
              // >
              //   Overall
              // </div>
            )}
          </div>
          {feedbackDetails?.selected !== "OTHERS" ? (
            <div className="feedback-popup--s-1">
              <FeedbackSectionOne
                sectionOneName={
                  feedbackDetails?.selected === "COURSE"
                    ? "Course Name"
                    : "Event Name"
                }
                sectionTwoName={
                  feedbackDetails?.selected === "COURSE"
                    ? "Module Name"
                    : "Session/Segment Name"
                }
                moduleData={moduleData}
                setModuleData={setModuleData}
                moduleSelected={moduleSelected}
                setModuleSelected={setModuleSelected}
                selectedCourse={selectedCourse}
                setErrors={setErrors}
                setSelectedCourse={setCourseSelected}
                courseData={
                  feedbackDetails?.selected === "COURSE"
                    ? courseData
                    : eventsData
                }
                error={
                  feedbackDetails?.selected === "COURSE" ? "module" : "session"
                }
                isLoading={isLoading}
              />
            </div>
          ) : (
            ""
          )}
          {learnerFeedbackPopup ? (
            <div>
              <div className="feedback-popup--s-2">
                <FeedbackSectionTwo
                  setStarSelected={setStarSelected}
                  starSelected={starSelected}
                  userComment={userComment}
                  setUserComment={setUserComment}
                  errors={errors}
                  setErrors={setErrors}
                  type={"Other"}
                  learnerFeedbackPopup={true}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="feedback-popup--s-2">
                <FeedbackSectionTwo
                  setStarSelected={setStarSelected}
                  starSelected={starSelected}
                  userComment={userComment}
                  setUserComment={setUserComment}
                  errors={errors}
                  type={"Other"}
                  setErrors={setErrors}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer ">
          <Button
            onClick={() => {
              setShow(false);
              clickStreamUser("feedback_skip", {
                userId: authBroker?.payload?.user.user_id,
              });
            }}
            id="save-btn"
            className="skipBtn"
          >
            Skip
          </Button>
          <Button
            id="approve"
            className="confirm feedbackBtn"
            onClick={handleSubmitPopup}
          >
            {loading ? <SpinnerLoader /> : "Send Feedback"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackPopupMain;
