import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import CongratulationsPopup from "./CongratulationsPopUp";

const CongPopupAnimation = (props) => {
    const canvasStyles = {
        position: "fixed",
        zindex: "99999",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0
    };
    const { title, lpPoints, fromPage } = props; // fromPage ==> 0 = learningpoint, 1 = badge, 2 = certificate


    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowPopup(true);
        }, 3000)

    }, [])
    const getAnimationSettings = (angle, originX, originY) => {
        return {
            // particleCount: 5,
            // disableForReducedMotion: false,
            // angle: 70,
            // spread: 85,
            // drift:2,
            // scalar: 1.5,
            // startVelocity: 43,
            // origin: { x: originX, y: originY },
            // shapes: ["square", "circle", "square"],
            // colors: ["#7fff00", "#8a2be2", "#ffff00", "#bdb76b","#8b0000"]
            particleCount: 5,
            // disableForReducedMotion: false,
            angle: 95,
            spread: 195,
            scalar: 1.9,
            startVelocity: 58,
            origin: { x: originX, y: 0.9 },
            shapes: ['square', 'circle', 'square', 'circle'],
            colors: ["#eff542", "#f54242", "#359e28", "#28579e", "#292928"]
        };
    }

    const refAnimationInstance = useRef(null);
    const [intervalId, setIntervalId] = useState();

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings(60, 0));
            refAnimationInstance.current(getAnimationSettings(120, 1));
        }
    }, []);

    const startAnimation = useCallback(() => {
        if (!intervalId) {
            setIntervalId(setInterval(nextTickAnimation, 10));
        }
        setTimeout(() => {
            clearInterval(intervalId);
            setIntervalId(null);
        }, 400)
    }, [nextTickAnimation, intervalId]);
    useEffect(() => {
        startAnimation()
        setTimeout(() => {
            startAnimation()
        }, 400)
    }, [])

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    return (
        <>
            <div>
                {/* <button onClick={startAnimation}>Start</button> */}
                {/* <button onClick={pauseAnimation}>Pause</button>
                <button onClick={stopAnimation}>Stop</button> */}
            </div>
            <ReactCanvasConfetti className="canvasblast" refConfetti={getInstance} style={canvasStyles} />

            {showPopup?<CongratulationsPopup title={title}
                lpPoints={lpPoints}
                fromPage={fromPage}
            />:""}


        </>
    );
}
export default CongPopupAnimation;