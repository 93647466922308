const types = {
  PROFILE_COMPLETION_REPORT: "PROFILE_COMPLETION_REPORT",
  PROFILE_COMPLETION_REPORT_RESPONSE: "PROFILE_COMPLETION_REPORT_RESPONSE",
  PROFILE_COMPLETION_REPORT_RESPONSE_CLEAR:
    "PROFILE_COMPLETION_REPORT_RESPONSE_CLEAR",

  // ONBOARDING SECTION REPORT
  ONBOARDING_COMPLETION_REPORT: "ONBOARDING_COMPLETION_REPORT",
  ONBOARDING_COMPLETION_REPORT_RESPONSE:
    "ONBOARDING_COMPLETION_REPORT_RESPONSE",
  ONBOARDING_COMPLETION_REPORT_RESPONSE_CLEAR:
    "ONBOARDING_COMPLETION_REPORT_RESPONSE_CLEAR",

  // ANY TOTAL REPORT
  ANY_TOTAL_REPORT: "ANY_TOTAL_REPORT",
  ANY_TOTAL_REPORT_RESPONSE: "ANY_TOTAL_REPORT_RESPONSE",
  ANY_TOTAL_REPORT_CLEAR: "ANY_TOTAL_REPORT_CLEAR",

  GET_OFFICE_LIST: "GET_OFFICE_LIST",
  GET_OFFICE_LIST_RESPONSE: "GET_OFFICE_LIST_RESPONSE",
  GET_OFFICE_LIST_CLEAR: "GET_OFFICE_LIST_CLEAR",

  OVERALL_SUMMARY_PROGRESS: "OVERALL_SUMMARY_PROGRESS",
  OVERALL_SUMMARY_PROGRESS_RESPONSE: "OVERALL_SUMMARY_PROGRESS_RESPONSE",
  OVERALL_SUMMARY_PROGRESS_CLEAR: "OVERALL_SUMMARY_PROGRESS_CLEAR",
  OVERALL_DATA: "OVERALL_DATA",
  OVERALL_DATA_RESPONSE: "OVERALL_DATA_RESPONSE",
  OVERALL_DATA_CLEAR: "OVERALL_DATA_CLEAR",
  OVERALL_PROGRESS_GRAPH: "OVERALL_PROGRESS_GRAPH",
  OVERALL_PROGRESS_GRAPH_RESPONSE: "OVERALL_PROGRESS_GRAPH_RESPONSE",
  OVERALL_PROGRESS_GRAPH_CLEAR: "OVERALL_PROGRESS_GRAPH_CLEAR",
  //Course Completion Trend
  COURSE_COMPLETION_TREND: "COURSE_COMPLETION_TREND",
  COURSE_COMPLETION_TREND_RESPONSE: "COURSE_COMPLETION_TREND_RESPONSE",
  COURSE_COMPLETION_TREND_CLEAR: "COURSE_COMPLETION_TREND_CLEAR",
  //Compliance Report
  COMPLIANCE_REPORT: "COMPLIANCE_REPORT",
  COMPLIANCE_REPORT_RESPONSE: "COMPLIANCE_REPORT_RESPONSE",
  COMPLIANCE_REPORT_RESPONSE_CLEAR: "COMPLIANCE_REPORT_RESPONSE_CLEAR",
  //Course Completion Trend
  COMPLIANCE_COMPLETION_TREND: "COMPLIANCE_COMPLETION_TREND",
  COMPLIANCE_COMPLETION_TREND_RESPONSE: "COMPLIANCE_COMPLETION_TREND_RESPONSE",
  COMPLIANCE_COMPLETION_TREND_CLEAR: "COMPLIANCE_COMPLETION_TREND_CLEAR",
  CURRENT_FILTER_VIEW: "CURRENT_FILTER_VIEW",
  //COE REPORT REA 
  COE_REA: "COE_REA",
  COE_REA_RESPONSE: "COE_REA_RESPONSE",
  COE_REA_CLEAR: "COE_REA_CLEAR",
  ////COE MANAGERS 
  COE_MANAGER: "COE_MANAGER",
  COE_MANAGER_RESPONSE: "COE_MANAGER_RESPONSE",
  COE_MANAGER_CLEAR: "COE_MANAGER_CLEAR",
  //COE OFFICES
  COE_OFFICES: "COE_OFFICES",
  COE_OFFICES_RESPONSE: "COE_OFFICES_RESPONSE",
  COE_OFFICES_CLEAR: "COE_OFFICES_CLEAR",

  //COE TREND CURVE
  COE_TREND: "COE_TREND",
  COE_TREND_RESPONSE: "COE_TREND_RESPONSE",
  COE_TREND_CLEAR: "COE_TREND_CLEAR",

  //LP TABLE 
  LP_TABLE: " LP_TABLE",
  LP_TABLE_RESPONSE: "LP_TABLE_RESPONSE",
  LP_TABLE_CLEAR: "LP_TABLE_CLEAR",
  //LP REA 
  LP_REA:"LP_REA",
  LP_REA_RESPONSE:"LP_REA_RESPONSE",
  LP_REA_CLEAR:"LP_REA_CLEAR",
  //LP MANAGERS 
  LP_MANAGERS: "LP_MANAGERS",
  LP_MANAGERS_RESPONSE: "LP_MANAGERS_RESPONSE",
  LP_MANAGERS_CLEAR: "LP_MANAGERS_CLEAR",
  //LP OFFICES 
  LP_OFFICES: "LP_OFFICES",
  LP_OFFICES_RESPONSE: "LP_OFFICES_RESPONSE",
  LP_OFFICES_CLEAR: "LP_OFFICES_CLEAR",
  //LP TREND 
  LP_TREND: "LP_TREND",
  LP_TREND_RESPONSE: "LP_TREND_RESPONSE",
  LP_TREND_CLEAR: "LP_TREND_CLEAR",

  //LP STATS 
  LP_STATS: "LP_STATS",
  LP_STATS_RESPONSE: "LP_STATS_RESPONSE",
  LP_STATS_CLEAR: "LP_STATS_CLEAR",

  //ONBOARD REPORT REA
  ONBOARD_REPORT_REA: "ONBOARD_REPORT_REA",
  ONBOARD_REPORT_REA_RESPONSE: "ONBOARD_REPORT_REA_RESPONSE",
  ONBOARD_REPORT_REA_CLEAR: "ONBOARD_REPORT_REA_CLEAR",
  //ONBOARD REPORT MANAGERS
  ONBOARD_REPORT_MANAGERS: "ONBOARD_REPORT_MANAGERS",
  ONBOARD_REPORT_MANAGERS_RESPONSE: "ONBOARD_REPORT_MANAGERS_RESPONSE",
  ONBOARD_REPORT_MANAGERS_CLEAR: "ONBOARD_REPORT_MANAGERS_CLEAR",
  //ONBOARD REPORT OFFICES
  ONBOARD_REPORT_OFFICES: "ONBOARD_REPORT_OFFICES",
  ONBOARD_REPORT_OFFICES_RESPONSE: "ONBOARD_REPORT_OFFICES_RESPONSE",
  ONBOARD_REPORT_OFFICES_CLEAR: "ONBOARD_REPORT_OFFICES_CLEAR",
  //ONBOARD REPORT TREND
  ONBOARD_REPORT_TREND: "ONBOARD_REPORT_TREND",
  ONBOARD_REPORT_TREND_RESPONSE: "ONBOARD_REPORT_TREND_RESPONSE",
  ONBOARD_REPORT_TREND_CLEAR: "ONBOARD_REPORT_TREND_CLEAR",
  //ONBOARD REPORT STATS
  ONBOARD_REPORT_STATS: "ONBOARD_REPORT_STATS",
  ONBOARD_REPORT_STATS_RESPONSE: "ONBOARD_REPORT_STATS_RESPONSE",
  ONBOARD_REPORT_STATS_CLEAR: "ONBOARD_REPORT_STATS_CLEAR",
  //Course report 
  //COURSE REPORT REA
  COURSE_REPORT_REA: "COURSE_REPORT_REA",
  COURSE_REPORT_REA_RESPONSE: "COURSE_REPORT_REA_RESPONSE",
  COURSE_REPORT_REA_CLEAR: "COURSE_REPORT_REA_CLEAR",
  //COURSE REPORT MANAGERS
  COURSE_REPORT_MANAGERS: "COURSE_REPORT_MANAGERS",
  COURSE_REPORT_MANAGERS_RESPONSE: "COURSE_REPORT_MANAGERS_RESPONSE",
  COURSE_REPORT_MANAGERS_CLEAR: "COURSE_REPORT_MANAGERS_CLEAR",
  //COURSE REPORT OFFICES
  COURSE_REPORT_OFFICES: "COURSE_REPORT_OFFICES",
  COURSE_REPORT_OFFICES_RESPONSE: "COURSE_REPORT_OFFICES_RESPONSE",
  COURSE_REPORT_OFFICES_CLEAR: "COURSE_REPORT_OFFICES_CLEAR",
  //COURSE REPORT STATS
  COURSE_REPORT_STATS: "COURSE_REPORT_STATS",
  COURSE_REPORT_STATS_RESPONSE: "COURSE_REPORT_STATS_RESPONSE",
  COURSE_REPORT_STATS_CLEAR: "COURSE_REPORT_STATS_CLEAR",
  //COURSE REPORT COMPLETION TREND
  COURSE_REPORT_COMP_TREND: "COURSE_REPORT_COMP_TREND",
  COURSE_REPORT_COMP_TREND_RESPONSE: "COURSE_REPORT_COMP_TREND_RESPONSE",
  COURSE_REPORT_COMP_TREND_CLEAR: "COURSE_REPORT_COMP_TREND_CLEAR",
};

export default types;
