import { useEffect } from "react";
import { useSelector } from "react-redux";
import Page from "../../components/Page";
import ReportDashboardSection from "../../components/Reports/ReportDashboardSection";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
const ReportDashboard = () => {

  const userRole = useSelector((state) => state.user.role);
  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
        document.getElementById("id_page_404").style.display = "none";
    }
}, [])

  return (<>
    {!localStorage.getItem(SessionKeys.API_TOKEN_BROKER) || userRole === 'manager'?
      < Page >
      <ReportDashboardSection
        isMobile={false} />
    </Page>: <div className="container mt-3"><strong >Unauthorized Access.</strong></div>
}
  </>
  );
};

export default ReportDashboard;
