import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    getAssignUserReq,
    getTeamMatesReq,
    getTeamMatesResClear,
    setCurrentView,
    getFilterLp,
    getFilterLpClear
} from "../../redux/assignCourse/actions";
import { setCurrentFilterView } from "../../redux/reports/actions";
import { SessionKeys } from "../../utils/AppUtils";
import StaticUtils from "../../utils/StaticUtils";
const ChooseLp = (props) => {

    const {
        selectedLpList, setSelectedLpList,
        currentReportObj, parentId,
        parentName, isMobile
    } = props;

    const dispatch = useDispatch();
    const { courseId } = useParams();
    const teamMatesListRes = useSelector(
        (state) => state.assignCourse.chooseLpPath
    );
    const assignedUserRes = useSelector(
        (state) => state.assignCourse.assignedUsers
    );
    const [searchInput, setSearchInput] = useState("");
    const [teamMatesList, setTeamMatesList] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        dispatch(getFilterLpClear());
    }, []);

    useEffect(() => {
        setSelectedCount(selectedLpList?.length);
    }, [selectedLpList]);

    useEffect(() => {
        if (searchInput !== undefined && searchInput.length > 2)
            getTeamMatesOnSearch();
        else setErrorMsg("");
    }, [searchInput]);

    const getTeamMatesOnSearch = () => {
        if (currentReportObj.id == 2) {
            dispatch(
                getFilterLp({
                    module: "chooseLpPath",
                    postData: {
                        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                        is_onboarding: 0,
                        search: searchInput,
                    },
                })
            );
        }
        if (currentReportObj.id == 4) {
            dispatch(
                getFilterLp({
                    module: "chooseLpPath",
                    postData: {
                        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                        is_onboarding: 1,
                        search: searchInput,
                    },
                })
            );
        }
    };



    useEffect(() => {
        // console.log("teamMatesListRes: ", teamMatesListRes);
        if (!_.isEmpty(teamMatesListRes)) {
            if (teamMatesListRes?.status === "success") {
                setTeamMatesList(teamMatesListRes?.payload?.payload);
                if (!_.isEmpty(searchInput) && searchInput?.length > 2 && teamMatesListRes?.payload?.payload?.length === 0) {
                    setErrorMsg("No result found for your search.");
                } else {
                    setErrorMsg("");
                }
            }
        } else {
            setErrorMsg("");
            setTeamMatesList([]);
        }
    }, [teamMatesListRes]);




    const selectItem = (index) => {
        let tempArr = _.cloneDeep(teamMatesList);
        let selectedTempArray = _.cloneDeep(selectedLpList);
        if (!StaticUtils.isContainObject(selectedTempArray, tempArr[index], 1)) {
            selectedTempArray.push(tempArr[index]);
        }
        setSelectedLpList(selectedTempArray);
        tempArr.splice(index, 1);
        setTeamMatesList(tempArr);
    };

    const removeItem = (index) => {
        let selectedTempArray = _.cloneDeep(selectedLpList);
        let tempArr = _.cloneDeep(teamMatesList);
        if (!StaticUtils.isContainObject(tempArr, selectedTempArray[index], 1)) {
            tempArr.push(selectedTempArray[index]);
        }
        setTeamMatesList(tempArr);
        selectedTempArray?.splice(index, 1);
        setSelectedLpList(selectedTempArray);
    };

    return (
        <>
            <div className="chooseteamate">
                {(currentReportObj.id == 2) ? <h4>Choose Learning Paths</h4> : <h4>Choose Onboarding</h4>}
                <div className="input-lear position-relative d-flex">
                    <label for="teamsearch" className="input__label_learning">
                        Search
                    </label>
                    <input
                        type="text"
                        id="teamsearch"
                        name="teamsearch"
                        className="input_searchfield_search-cart "
                        placeholder="Start searching"
                        onChange={(evt) => {
                            setSearchInput(evt.target.value);
                        }}
                    />
                </div>
                <p className="error-msg">{errorMsg}</p>
                <div className="search-team-result">
                    {teamMatesList?.map((item, index) => {
                        return (
                            <div className="team-result-box">
                                {/*<div class="team-user-img">
                  <img
                    src={
                      item?.profile_img !== ""
                        ? item?.profile_img
                        : "/images/avatar.png"
                    }
                    alt="user"
                  />
                  </div>*/}
                                <div className="userteamname">{item?.prgname
                                }</div>
                                <div
                                    className="addteammate cursor-pointer"
                                    onClick={() => {
                                        selectItem(index);
                                    }}
                                >
                                    +
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="teamselected">
                    {(currentReportObj.id == 2) ?
                        <div className="selectresilt">
                            Selected Learning Paths<strong>({selectedCount})</strong>
                        </div> : <div className="selectresilt">
                            Selected Onboarding<strong>({selectedCount})</strong>
                        </div>

                    }
                    <div className="search-team-result slectedteammates">
                        {selectedLpList?.map((item, index) => {
                            return (
                                <div className="team-result-box">
                                    {/*<div class="team-user-img">
                    <img
                      src={
                        item?.profile_img !== ""
                          ? item?.profile_img
                          : "/images/avatar.png"
                      }
                      alt="user"
                    />
                    </div>*/}
                                    <div className="userteamname">{item?.prgname}</div>
                                    <div
                                        className="addteammate cursor-pointer"
                                        onClick={() => {
                                            //   console.log("removeItem");
                                            removeItem(index);
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>



                <div className="buttonsteammate">
                    <button
                        type="button"
                        className="btn btn-primary btn-block back-button"
                        onClick={() => {
                            dispatch(setCurrentFilterView(1));
                        }}
                    >
                        Back
                    </button>
                    {selectedCount > 0 ? (
                        <button
                            type="button"
                            className="btn btn-primary btn-block setup-button"
                            onClick={() => {
                                dispatch(setCurrentFilterView(1));
                            }}
                        >
                            Proceed
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary btn-block setup-button"
                            disabled
                        >
                            Proceed
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChooseLp;
