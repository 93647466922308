import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionFilterBy,
  setSessionFilterByClear,
} from "../../redux/events/actions";
import CONFIG from "../../utils/config";

const CustomToolbar = (toolbar) => {

  const [isCurrent, SetIsCurrent] = useState("week");
  const [filterBy, setFilterBy] = useState("all");
  const authBroker = useSelector(state => state.auth.authBroker)
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const iframeRole = useSelector((state) => state.user.iframeRole);
  const sessionFilterBy = useSelector((state) => state.events.sessionFilterBy);
  const isMobileDevice = useSelector((state) => state.user.isMobileWebView);

  const [userRole, setUserRole] = useState('')

  useEffect(() => {
    console.log('isMOBILEDEVICE', isMobileDevice)
    if (isMobileDevice) {
      setUserRole(iframeRole)
    } else {
      setUserRole(role)
    }
  }, [role, iframeRole, isMobileDevice])

  useEffect(() => {
    console.log('filter by is changed', filterBy)
    // dispatch(setSessionFilterBy(filterBy));
    // toolbar.setSessionFilterBy(filterBy)
  }, [filterBy]);

  // useEffect(() => {
  //   if (sessionFilterBy) {
  //     console.log(sessionFilterBy)
  //     setFilterBy(sessionFilterBy)
  //   }
  // }, [sessionFilterBy])

  useEffect(() => {
    // console.log(toolbar)
    if (toolbar.view) {
      SetIsCurrent(toolbar.view);
    }
  }, [toolbar]);

  const goToBack = () => {
    if (isCurrent === "month") {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    } else if (isCurrent === "day") {
      toolbar.date.setDate(toolbar.date.getDate() - 1);
    } else if (isCurrent === "week") {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    }
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    if (isCurrent === "month") {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    } else if (isCurrent === "day") {
      toolbar.date.setDate(toolbar.date.getDate() + 1);
    } else if (isCurrent === "week") {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    }
    toolbar.onNavigate("next");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };

  const monthView = () => {
    SetIsCurrent("month");
    toolbar.onView("month");
  };
  const dayView = () => {
    SetIsCurrent("day");
    toolbar.onView("day");
  };
  const weekView = () => {
    SetIsCurrent("week");
    toolbar.onView("week");
  };

  const label = () => {
    const date = moment(toolbar.date);
    if (isCurrent === "month" || isCurrent === "week") {
      return (
        <span>
          <b>{date.format("MMMM")}</b>,<span> {date.format("YYYY")}</span>
        </span>
      );
    } else if (isCurrent === "day") {
      return (
        <span>
          {date.format("DD")}&nbsp;<b>{date.format("MMMM")}</b>,
          <span> {date.format("YYYY")}</span>
        </span>
      );
    }
  };

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-between align-items-start filter-box-sec">
        <div className="left-btn-cl-box">
            <div className="d-flex gap-1">
              <button
                className={`btn btn-primary btn-block setup-button upgrate_colpeteBtn mr-2 ${
                  isCurrent === "day" ? "active" : ""
                }`}
                onClick={dayView}
              >
                Day
              </button>
              <button
                className={`btn btn-primary btn-block setup-button upgrate_colpeteBtn mr-2 ${
                  isCurrent === "month" ? "active" : ""
                }`}
                onClick={monthView}
              >
                Month
              </button>
              <button
                className={`btn btn-primary btn-block setup-button upgrate_colpeteBtn mr-2 ${
                  isCurrent === "week" ? "active" : ""
                }`}
                onClick={weekView}
              >
                Week
              </button>
            </div>
            <label className="calendar-label-title">{label()}</label>
        </div>
        <div className="d-flex button-filteralignment">
          <button className="button-reset" onClick={goToBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              id="Back"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  y1="0.5"
                  x2="1"
                  y2="0.5"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#00b7f1" />
                  <stop offset="1" stopColor="#ed156e" />
                </linearGradient>
              </defs>
              <circle
                id="Ellipse_57"
                data-name="Ellipse 57"
                cx="15"
                cy="15"
                r="15"
                fill="url(#linear-gradient)"
              />
              <circle
                id="Ellipse_179"
                data-name="Ellipse 179"
                cx="13"
                cy="13"
                r="13"
                transform="translate(2 2)"
                fill="#fff"
              />
              <g
                id="Group_1344"
                data-name="Group 1344"
                transform="translate(22 15.002) rotate(135)"
              >
                <path
                  id="Path_415"
                  data-name="Path 415"
                  d="M0,0H8V8"
                  transform="translate(8.001 0) rotate(90)"
                  fill="none"
                  stroke="var(--color-element-1)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                />
              </g>
            </svg>
          </button>
          <button className="button-reset pe-0" onClick={goToNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              id="Next"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  y1="0.5"
                  x2="1"
                  y2="0.5"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#00b7f1"></stop>
                  <stop offset="1" stopColor="#ed156e"></stop>
                </linearGradient>
              </defs>
              <circle
                id="Ellipse_57"
                data-name="Ellipse 57"
                cx="15"
                cy="15"
                r="15"
                fill="url(#linear-gradient)"
              ></circle>
              <circle
                id="Ellipse_179"
                data-name="Ellipse 179"
                cx="13"
                cy="13"
                r="13"
                transform="translate(2 2)"
                fill="#fff"
              ></circle>
              <g
                id="Group_1344"
                data-name="Group 1344"
                transform="translate(43.406 -779.787) rotate(45)"
              >
                <path
                  id="Path_415"
                  data-name="Path 415"
                  d="M0,8H8V0"
                  transform="translate(536.5 587.5) rotate(-90)"
                  fill="none"
                  stroke="var(--color-element-1)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                ></path>
              </g>
            </svg>
          </button>
        </div>
        {
          authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
          <div className="input-text cal-flter-box">
          <label htmlFor="filter" className="input__label">
            Filter By
          </label>
          <select
            // type="text"
            id="filter"
            name="filter"
            className="form-select input_searchfield mb-0 session-filter"
            value={filterBy}
            placeholder=""
            onChange={(e) => {
              e.preventDefault();
              console.log('TRIGGER', e.target.value)
              if (e.target.value) {
                setFilterBy(e.target.value);
                dispatch(setSessionFilterBy(e.target.value));
                // toolbar.setSessionFilterBy(e.target.value)
              } else {
                setFilterBy(e.target.value);
                // dispatch(setSessionFilterBy(e.target.value));
                // toolbar.setSessionFilterBy(e.target.value)
                dispatch(setSessionFilterByClear());
              }
            }}
          >
            
            {
            (userRole === "realtor" || userRole === "manager") ? 
            <>
              <option value="all">All</option>
              <option value="buy">Available for Purchase</option>
              <option value="free">Free</option>
              <option value="enrolled">My Enrolled Sessions</option>
              <option value="host">Hosted By Me</option>
            </>
            : 
              (userRole === "broker" || userRole === "admin") ? 
                <option value="host">Hosted By Me</option>
              :
                <>
                  <option value="all">All</option>
                  <option value="host">Hosted By Me</option>
                </>
            }
          </select>
        </div>
        }
      
      </div>
    </div>
  );
};

export default CustomToolbar;
