import { useState } from "react";

const ImageComponent = ({name, thumbnail, cssClass, errorClass, handleClick}) => {

    const [imageError, setImageError] = useState(false)

    return (
        <>
            {
            imageError ?
            <div 
                className={`d-flex justify-content-center align-items-center bk-grey cursor-pointer ${errorClass}`}
                // onClick={() => handleClick()}
            >
                <p dangerouslySetInnerHTML={{
                __html: name
                }}></p>
            </div>
            :
            <img 
                className={cssClass}
                src={thumbnail} 
                alt="" 
                onError={({currentTarget}) => {
                    currentTarget.onerror = true;
                    // currentTarget.src = "/images/default-demo-img.jpg"
                    setImageError(true)
                }}
                onLoadedData={() => setImageError(false)}
                // onClick={() => handleClick()}
            />
            }
        </>
    )
}

export default ImageComponent