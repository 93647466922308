import Page from "../../components/Page";
import OnboardReportHomeSection from "../../components/Reports/onboardReport/onboardReportHomeSection";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
export const OnboardReportPageMobile = () => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
                document.getElementById("cc_div").style.display = "none";
        }, 100)
    }, [])

    return (
        <>
            <Loader start={isLoading} />
            <OnboardReportHomeSection
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                isMobile={true}
            />

        </>
    )
}

export default OnboardReportPageMobile;