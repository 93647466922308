import { useState } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSelectedCatId } from '../redux/courses/actions';
import CONFIG from '../utils/config';
import {  useSelector } from "react-redux";

const MainSecionBanner = ({ startJoyRide }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const authBroker = useSelector(state => state.auth.authBroker)
    return (
        <div className="stati-add-banner">
            <img src="images/start-exploring-RPS.png"
                className="mobclassfluid"
                alt="ad-banner" />
            {
                authBroker?.payload?.user?.company_id === CONFIG.RPS_COMPANY_ID ? (
                    <>
                        <div className="pointscick"
                            onClick={(e) => {
                                startJoyRide(e)
                            }}>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="pointscick"
                            onClick={(e) => {
                                if (CONFIG.ENV === 'DEV') {
                                    startJoyRide(e)
                                }
                                else {
                                    dispatch(getSelectedCatId(""));
                                    history.push(`/courseListing?selectedCategory=&selectCourse=top`);
                                }
                            }}>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default MainSecionBanner