import { useEffect } from "react";

const ProductDetailCard = (props) => {
    const { productInfo, productAnalytics, canAddToCart } = props

    useEffect(() => {
        // console.log(productInfo)
        // console.log(productAnalytics)
    })

    return (
        <div className="img-box">
            <div className="img-video_pro">
                <img className="img-fluid" src={productInfo?.image} width="100%" />
            </div>                            
            <div className="details-cards-oll">
            
                <h5>{productInfo?.title}</h5>
                <div className="row info-bottm-card">
                    <div className="col-md-7 coud_card">
                        <p><i className="fa fa-clock-o" aria-hidden="true"></i>: {productInfo?.duration} {productInfo?.durationUnit}</p>
                        <p>{productInfo?.lessons}</p>
                        <p>{productAnalytics?.views} Views</p>
                        
                    </div>
                    {canAddToCart ? <div className="col-md-5 actionicons addTocart_btn">
                        <button>Add to Cart</button>
                    </div> : ''}
                </div>
            </div>
        </div>  
    );
}

export default ProductDetailCard