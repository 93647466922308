const ArticleCard = (props) => {
    const {articleData} = props
    return (
        <div className="col-md-4 mb-4 broker-box cursor-pointer">
            <label htmlFor="disabledSelect" className="form-label selectsearch">{articleData.title}</label>
            <div className="img-searcH">
                <div className="img-searcH">
                    <img className="img-fluid" src={articleData.imageURL} />
                </div> 
                <div className="details-card_search">
                    <p>
                        {articleData.description.substring(0,101)}
                    </p>
                
                    <div className="row info-bottm-card_nw">
                        <div className="row infoIcon_nw">
                            <div className="col-md-12 actionicons_search action_icons">
                                <a className="course-head-al_nw" href="" type="button" data-bs-toggle="modal" data-bs-target="#coursepoup">
                                    <img src="/images/search_new_icon.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                   
        </div>
    )
}

export default ArticleCard;