import axios from "axios";
import CONFIG from "../utils/config";
import course from "../data/courseList";
import video from "../data/videoCourseList";
import { objectToFormData, objectToStringData } from "./data";
import { useHistory as history } from "react-router-dom";
import { SessionKeys } from "../utils/AppUtils";
import _ from "lodash";

export const getAPI = async (payload) => {
  if (payload.module === "courseCategories") {
    return {
      status: "success",
      payload: course.categories,
    };
  } else if (payload.module === "courseListing") {
    // console.log("Listing");
    return {
      status: "success",
      payload: course.coursesData,
    };
  } else if (payload.module === "videoCategories") {
    return {
      status: "success",
      payload: video.categories,
    };
  } else if (payload.module === "videoListing") {
    // console.log("Video Listing");
    return {
      payload: video.coursesData,
      status: "success",
    };
  } else {
    let baseUrl = CONFIG.API_URL + CONFIG.API_ROUTES.BASE_PATH;
    let baseUrlBroker = CONFIG.API_URL_BROKER;
    let baseUrlRealBroker = CONFIG.API_URL_REAL_BROKER;
    let headers = { "Content-Type": "application/json" };
    let url = baseUrl + "?module=" + payload.module + "&token=" + payload.token;
    if (payload.module === "getResourcesApi") {
      url = `${url}&course_code=${payload.courseId}`;
    } else if (payload.module === "getCourseDetail") {
      url =
        baseUrlBroker +
        CONFIG.API_ROUTES_BROKER.COURSE_DETAIL_BASE_PATH +
        payload.courseId +
        "?email=" +
        payload.email;
    } else if (payload.module === "getOrderHistory") {
      url =
        baseUrlBroker +
        CONFIG.API_ROUTES_BROKER.ORDER_HISTORY_BASE_PATH +
        payload.userId;
    } else if (payload.module === "addToCart") {
      url =
        baseUrlBroker +
        CONFIG.API_ROUTES_BROKER.ADD_TO_CART_BASE_PATH +
        payload.courseId;
    } else if (payload.module === "getOrderDetail") {
      url =
        baseUrlBroker +
        CONFIG.API_ROUTES_BROKER.GET_ORDER_DETAIL_BASE_PATH +
        payload.courseId;
    } else if (payload.module === "courseListingWoo") {
      url =
        baseUrlBroker +
        CONFIG.API_ROUTES_BROKER.GET_COURSE_LIST_WOO_BASE_PATH +
        "?email=" +
        payload.email +
        "&user_id=" +
        payload.userId +
        "&company_id=" +
        payload.company_id;
    } else if (payload.module === "show-save-later") {
      url =
        baseUrlBroker +
        CONFIG.API_ROUTES_BROKER.SAVE_LATER_VIEW_BASE_PATH +
        payload.userId;
    } else if (payload.module === "get_category_broker") {
      url =
        baseUrlRealBroker +
        CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_BASE_PATH +
        "action=" +
        payload.module +
        "&broker_id=" +
        payload.brokerId;
    } else if (payload.module === "get_all_courses") {
      url =
        baseUrlRealBroker +
        CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_BASE_PATH +
        "action=" +
        payload.module +
        "&broker_id=" +
        payload.brokerId;
    } else if (payload.module === "get_all_course_modules") {
      url =
        baseUrlRealBroker +
        CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_BASE_PATH +
        "action=" +
        payload.module +
        "&course_code=" +
        payload.courseId +
        "&token=" +
        payload.token;
    } else if (payload.module === "learning-points/points") {
      url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.SHOW_LEARNING_POINTS}?email=${payload.email}`;
      headers["X-API-TOKEN"] = localStorage.getItem(SessionKeys.API_TOKEN_BROKER);
    } else if (payload.module === "getWishList") {
      url =
        baseUrlBroker + CONFIG.API_ROUTES_BROKER.GET_WISHLIST + payload.email;
    } else if (payload.module === "get_Code_Of_Ethics") {
      url =
        CONFIG.API_URL_BROKER_ADMIN +
        CONFIG.API_ROUTES_BROKER.MASTER_CODE_OF_ETHICS;
    }   else if (payload.module === "getAssesments") {
      url =
        CONFIG.API_URL_BROKER_ADMIN +
        CONFIG.API_ROUTES_BROKER.GET_ASSESMENTS;
    }
    else if (payload.module === "getProgramDetails") {
        url = `${CONFIG.API_URL_ADMIN}${CONFIG.API_ROUTES.GET_PROGRAM_DETAILS}?status=${payload?.status}`;
    }
    else if (payload.module === "getAllProgramDetails") {
      url = `${CONFIG.API_URL_ADMIN}${CONFIG.API_ROUTES.GET_PROGRAM_DETAILS}`;
    } else if (payload.module === "getFeedbackQuestions") {
      url = `${CONFIG.API_URL_ADMIN}api/v1/feedback/questions?type=${payload.type}`;
    } else if (payload.module === "getZoomMeetingRecordings") {
    url = `${CONFIG.API_URL_ADMIN}${CONFIG.API_ROUTES.GET_ZOOM_RECORDINGS}`;
    headers = {
      "MeetingId": payload?.MeetingId,
      "SegmentId": payload?.SegmentId,
     };
    }else if (payload.module === "getLearningPathDetails") {
      url = `${CONFIG.API_URL_ADMIN}api/v1/my-learning-paths/${payload.programID}`;
    }else if (payload.module === "getModuleDetailsByCourseID") {
      url = `${CONFIG.API_URL_ADMIN}api/v1/getModuleDetailsbyCourseID/${payload.courseID}`;
    }else if (payload.module === "getOrderDetailBroker") {
      url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.USER_DETAIL_PROFILE}`;
    }
    else if (payload.module === "splash-banner") {
      url = CONFIG.API_URL_ADMIN + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_GET_BANNER;
    }
    else if (payload.module === "leaderBoard") {
      url =
        CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_LEADERBOARD;
    }
    url = `${url}`
    if (url.startsWith(CONFIG.API_URL_ADMIN)) {
      headers["X-API-TOKEN"] = localStorage.getItem(SessionKeys.API_TOKEN_BROKER);
    }
    return axios({
      method: "GET",
      url,
      headers
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          return {
            status: "success",
            payload: response.data.data,
            from: payload.module
            // payload: response.data.learning_point_info
          };
        } else {
          if (
            response.data.message === "Token is not valid" ||
            response.data.message === "Token is invalid." ||
            response.data.message === "Token is invalid."
          ) {
            alert("Your Session is Logged Out. Please Login.");
            window.alert = () => false;
            localStorage.clear();
            window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
            history.push("/", { sessionExpired: true });
            // console.log("errorData, history");
          }
          return {
            status: "error",
            error: {
              message: response.data.message,
            },
          };
        }
      })
      .catch((error) => {
        // console.log("errorData", error.respoinse);
        if (error.response != undefined && error.response?.status === 401) {
          alert("Your Session is Logged Out. Please Login.");
          window.alert = () => false;
          localStorage.clear();
          window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
          history.push("/", { sessionExpired: true });
        }
        return {
          status: "error",
          error,
        };
      });
  }
};

export const postAPIWithoutModule = async (payload, isJSON = false) => {
  // console.log("timezone to fetch", payload, isJSON);
  let headers = {};
  let baseUrlBroker = CONFIG.API_URL_BROKER;
  let baseUrlRealBroker = CONFIG.API_URL_REAL_BROKER;
  let formData = isJSON ? payload.postData : objectToFormData(payload);
  // console.log(formData, objectToFormData(payload));
  let url = CONFIG.API_URL + CONFIG.API_ROUTES.BASE_PATH;
  if (payload?.module === "realtor_update_password") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.UPDATE_PASSWORD_BASE_PATH;
    delete payload.module;
    formData = payload;
    isJSON = true;
  } else if (payload.module === "postFeedbackQuestions") {
    url = `${CONFIG.API_URL_ADMIN}api/v1/feedback/`;
    formData = payload.postData;
    isJSON = true;
  } 
else if (payload.module === "resend-verification-email") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.RESEND_SIGNUP_PATH;
    // delete payload.action
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "addToCart") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.ADD_TO_CART_BASE_PATH;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "remove-product") {
    url = `${baseUrlBroker}${CONFIG.API_ROUTES_BROKER.MY_CART_DEL_BASE_PATH}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "fetchPaymentIntent") {
    // url = `${baseUrlBroker}${CONFIG.API_ROUTES_BROKER.PAYMENT_BASE_PATH}`
    // url = `${baseUrlBroker}${CONFIG.API_ROUTES_BROKER.PAYMENT_USER_BASE_PATH}?email=${payload.email}&credit_points=${payload.redeem}`
    url = `${baseUrlBroker}${CONFIG.API_ROUTES_BROKER.PAYMENT_CHECKOUT}?email=${payload.email}&credit_points=${payload.redeem}`;
    formData = {};
    isJSON = true;
  } else if (payload.module === "sendOrderResponse") {
    url = `${baseUrlBroker}${CONFIG.API_ROUTES_BROKER.SEND_ORDER_PLACE_BASE_PATH}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "zeroSendOrderResponse") {
    url = `${baseUrlBroker}${CONFIG.API_ROUTES_BROKER.ZERO_ORDER_PLACE_BASE_PATH}`;
    formData = payload.postData;
    isJSON = true;
  }  else if (payload.module === "getUserDetailBrokerForOther") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.USER_DETAIL_PROFILE_FOR_OTHER}`;
    formData = payload.postData;
    //{
    // apiToken: payload.apiToken,
    // user_id: localStorage.getItem(SessionKeys.USER_ID),
    //};
    isJSON = true;
  } else if (payload.module === "directCheckout") {
    url = `${CONFIG.API_URL_BROKER}${CONFIG.API_ROUTES_BROKER.DIRECT_CHECKOUT_PATH}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCreditPoint") {
    url = `${CONFIG.API_URL_BROKER}${CONFIG.API_ROUTES_BROKER.GET_CREDIT_POINTS}`;
    formData = {
      email: payload.email,
    };
    isJSON = true;
  } else if (payload.module === "creditPoints") {
    url = `${CONFIG.API_URL_BROKER}${CONFIG.API_ROUTES_BROKER.GET_CREDIT_POINTS_HISTORY}`;
    formData = { email: payload.email };
    isJSON = true;
  } else if (payload.module === "getNIITAdminModule") {
    url = `${CONFIG.API_URL}${CONFIG.API_ROUTES.GET_MODULES_LIST_NIIT_ADMIN}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getAllLearningPathBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.GET_ALL_LEARNING_PATH}`;
    formData = {
      // broker_id: payload.brokerId,
      token: payload.token,
      is_onboarding: payload.isOnboarding,
    };
    isJSON = true;
  } else if (payload.module === "assign-course") {
    url = `${CONFIG.API_URL}${CONFIG.API_ROUTES.ASSIGN_COURSE}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getProfilePercentage") {
    // console.log("getProfilePercentage");
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.PROFILE_COMPLETE_DATA}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "sessionList") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.SESSION_LIST_DATA; // + '?email=' + payload.email + '&event_id=' + payload.event_id;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "certifySessionList") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.CERTIFICATE_SESSION_LIST; // + '?email=' + payload.email + '&event_id=' + payload.event_id;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "eventListBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.EVENT_LIST}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "eventDetailBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.EVENT_DETAIL}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "sessionBrokerList") {
    // console.log("called broker");
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.SESSION_LIST_BROKER}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "enrollBrokerSession") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.ENROLL_SESSION_BROKER}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "inProgressBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.IN_PROGRESS_BROKER}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getMyCourses") {
    url = payload.broker
      ? `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.MY_COURSES_BROKER}`
      : `${CONFIG.API_URL_BROKER}${CONFIG.API_ROUTES_BROKER.MY_COURSES_ECOM}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "calendarViewSessions") {
    url = `${CONFIG.API_URL_BROKER}${CONFIG.API_ROUTES_BROKER.CALENDAR_SESSIONS_DATA}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "calendarViewInstructor") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.CALENDAR_INSTRUCTOR_SESSIONS_DATA}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "userlogout") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.LOGOUT_BASE_PATH}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCertifiedDetails") {
    url = `${CONFIG.API_URL}${CONFIG.API_ROUTES.BASE_PATH}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "assignedCourseBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.ASSIGNED_COURSES}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "setMarkView") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.VIEW_MARK}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCertificatesBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.GET_CERTIFICATES}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getBadgesBroker") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.GET_BADGES}`;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getHostCalendar") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.CALENDAR_HOST_SESSIONS_DATA}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "createZoomMeeting") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.CREATE_ZOOM_MEETING}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "joinZoomMeeting") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.JOIN_ZOOM_MEETING}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "post_Code_Of_Ethics_Response") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.MASTER_CODE_OF_ETHICS_AGREE}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "get_Code_Of_Ethics") {
    url = `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.MASTER_CODE_OF_ETHICS}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "addToWishList") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.ADD_TO_WISHLIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "setpassword") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.VERIFY_SIGNUP_PATH;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "get_all_courses") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_COURSES_BROKER;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "delWishList") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.DEL_WISHLIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "forgot-password-link") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.FORGOT_PASSWORD_LINK_PATH;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getLicensedAs") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_LICENSED_AS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCoursesBySearch") {
    url = payload.broker
      ? `${CONFIG.API_URL_BROKER_ADMIN}${CONFIG.API_ROUTES_BROKER.GET_SEARCH_DATA}`
      : `${CONFIG.API_URL_BROKER}${CONFIG.API_ROUTES_BROKER.GET_SEARCH_DATA_WOO}`;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "event_Notify_Me") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.EVENT_NOTIFICATION;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "get_membership_details") {
    // console.log("getMembershipDetails");
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.MEMBERSHIP_DETAILS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "get_membership_payment_intent") {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.MEMBERSHIP_INTENT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "get_membership_checkout") {
    url =
      CONFIG.API_URL_BROKER +
      CONFIG.API_ROUTES_BROKER.MEMBERSHIP_PAYMENT_CHECKOUT;
    formData = payload.postData;
    isJSON = true;
  } 
  else if (payload.module === "calendarSessionListEcom") {
    // url =
    //   CONFIG.API_URL_BROKER +
    //   CONFIG.API_ROUTES_BROKER.CALENDAR_SESSION_ECOM_LIST;
    // formData = payload.postData;
    // isJSON = true;
  } 
  else if (payload.module === "calendarSessionListBroker") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CALENDAR_SESSION_BROKER_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "check-notified") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.CHECK_NOTIFIED;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "sessionCancel") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.CANCEL_SESSION;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "AllEventsApi") {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.ECOM_EVENT_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "GetEvent") {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.ECOM_EVENT_DETAIL;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getRoleRequested") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ROLE_REQUESTED;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "acceptRoleReq") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.ACCEPT_ROLE_REQUESTED;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "acceptRoleReqAll") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.ACCEPT_ALL_ROLE_REQUESTED;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getUserOfRoleAccepted") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.USER_LIST_OF_ROLE_ACCEPTED;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getUserOfRoleAcceptedSearch") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.USER_LIST_OF_ROLE_ACCEPTED_SEARCH;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "markModuleProgress") {
    url =
      CONFIG.API_URL_ADMIN +
      CONFIG.API_ROUTES.ADD_MODULE_PROGRESS;
    formData = payload.postData;
    headers["X-API-TOKEN"] = payload?.token;
    isJSON = true;
  } else if (payload.module === "getUserRoleAcceptanceCancel") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.USER_LIST_OF_ROLE_CANCEL;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "userRoleRequestStatus") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.USER_ROLE_REQUEST_STATUS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getTimezone") {
    // console.log("timezone to fetch");
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.TIMEZONE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "fetchTimezoneAbbr") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.TIMEZONE_ABBR;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getRegisteredBrokerSession") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CALENDAR_SESSIONS_BROKER_DATA;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myEventListBroker") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.MY_EVENTS_BROKER;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myRecommendation") {
    url =
      CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.RECOMMENDATION_RESPONSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myViewRecommendation") {
    url =
      CONFIG.API_URL_BROKER +
      CONFIG.API_ROUTES_BROKER.RECOMMENDATION_VIEW_RESPONSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myInsertViewRecommendation") {
    url =
      CONFIG.API_URL_BROKER +
      CONFIG.API_ROUTES_BROKER.RECOMMENDATION_INSERT_VIEW_RESPONSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myCertificateURL") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CERTIFICATE_DATA_RESPONSE;
    // console.log("URL for certificate", url);
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getProfileReport") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.PROFILE_REPORT;
    formData = payload.postData;
    isJSON = true;
  }
  // else if (payload.module === "getOnboardReport") {
  //   url =
  //     CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_REPORT;
  //   formData = payload.postData;
  //   isJSON = true;
  // }
  else if (payload.module === "getlPReportTable") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.LEARNING_PATH_TABLE;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getlPReportRea") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.LEARNING_PATH_REA;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getlPReportManagers") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.LEARNING_PATH_MANAGERS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getlPReportOffices") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.LEARNING_PATH_OFFICES;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getlPReportTrend") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.LEARNING_PATH_TREND;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getlPReportStats") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.LEARNING_PATH_STATS;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getCourseProgressReport") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.COURSE_REPORT;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getOnboardReport") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_REPORT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getOnBoardRea") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_REA;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getOnBoardManagers") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_MANAGERS;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getOnBoardOffices") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_OFFICES;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getOnBoardReportTrend") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_TREND;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getOnBoardReportStats") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.ONBOARDING_STATS;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getCourseReportRea") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.COURSE_REPORT_REA;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCourseReportManagers") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.COURSE_REPORT_MANAGERS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCourseReportStats") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.COURSE_REPORT_STATS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCourseReportTrend") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.COURSE_REPORT_COMPLETION_TREND;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "getCourseReportOffices") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.COURSE_REPORT_COMPLETION_OFFICES;
    formData = payload.postData;
    isJSON = true;
  }
  // else if (payload.module === "getLearningPathProgressReport") {
  //   url =
  //     CONFIG.API_URL_BROKER_ADMIN +
  //     CONFIG.API_ROUTES_BROKER.LEARNING_PATH_REPORT;
  //   formData = payload.postData;
  //   isJSON = true;
  // } 
  else if (payload.module === "getCodeOfEthicsReport") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CODE_OF_ETHICS_REPORT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCoeReportRea") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CODE_OF_ETHICS_REPORT_REA;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCoeReportManagers") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CODE_OF_ETHICS_REPORT_MANAGERS
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCoeReportOffices") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CODE_OF_ETHICS_REPORT_OFFICES;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCoeReporTrend") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CODE_OF_ETHICS_REPORT_TREND;
    formData = payload.postData;
    isJSON = true;
  }


  else if (payload.module === "getEventReport") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.EVENT_REPORT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getPlanUpgradeReport") {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.PLAN_UPGRADE_REPORT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getPurchaseReport") {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.PURCHASE_REPORT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "participateForm") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.PARTICIPATE_ROLEPLAY;
    formData = payload.postData;
    isJSON = false;
  } else if (payload.module === "reviewRolePlayList") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.REVIEW_ROLEPLAY_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "submitFeedbackRolePlay") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.SUBMIT_FEEDBACK;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myRolePlayList") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.MY_ROLEPLAY_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "isAggree") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.IS_AGGREE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myNotification") {
    url =
      CONFIG.API_URL_REAL_BROKER +
      CONFIG.API_ROUTES_BROKER.NOTIFICATION_RESPONSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myNotificationCount") {
    url =
      CONFIG.API_URL_REAL_BROKER +
      CONFIG.API_ROUTES_BROKER.NOTIFICATION_COUNT_RESPONSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getPostList") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_POST_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getAddComments") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_ADD_COMMENTS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getEditComments") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_EDIT_COMMENTS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getLikeComment") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_LIKE_COMMENT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getActivityLike") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_ACTIVITY_LIKE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getOfficeList") {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.OFFICE_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "privacyContent") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.GET_PRIVACY_CONTENT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "videoCompletionStatus") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.BROKER_ADMIN_VIDEO_COMPLETION;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "roleplayNiitAdmin") {
    url = CONFIG.API_URL + CONFIG.API_ROUTES.ROLE_PLAY;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "roleplayRelateList") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.RELATED_VIDEO_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "roleplayReviewList") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.REVIEW_FILTER_ROLEPLAY_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "myRolePlayFilterList") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.MY_ROLEPLAY_FILTER_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "userPoints") {
    url =
      CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.GET_USER_POINTS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "overallSummary") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.OVERALL_SUMMARY_LEARNING_DATA;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "overallData") {
    url =
      baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.OVERALL_DATA_RESPONSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "overallSummaryGraph") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.OVERALL_SUMMARY_LEARNING_GRAPH;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getTeamMatesList") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_TEAMMATES_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "assignTeamMates") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.ASSIGN_TEAMMATES;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getOfficeListSearch") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_OFFICE_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getCompletedCourseList") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.GET_COMPLETED_COURSE_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getUserByFiler") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_USER_BY_FILTER;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getTeamsList") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_TEAMS_LIST;
    formData = payload.postData;
    isJSON = true;
  }else if (payload.module === "getDefaultTeamsList") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_DEFAULT_TEAMS_LIST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "assignToTeams") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.ASSIGN_TO_TEAMS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "courseCompletionTrend") {
    url =
      baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.COURSE_COMPLETION_TREND;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "complianceReport") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.COMPLIANCE_REPORT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "complianceCompletionTrend") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.COMPLIANCE_COMPLETION_TREND;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getAssignedUsers") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_ASSIGNED_USERS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "checkContentAccess") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CHECK_CONTENT_ACCESS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "videoDetailEcom") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.VIDEO_DETAIL;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "videoDetailBroker") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.BROKER_ADMIN_VIDEO_DETAIL;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getTeamManageCoachMentor") {
    url =
      baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.GET_MANAGER_COACH_MENTOR;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "chooseCourse") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHOOSE_COURSE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "chooseLpPath") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHOOSE_LP_PATH_FILTER;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "chooseProvider") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHOOSE_PROVIDER;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getLevelDetail") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.GET_LEVEL_DETAIL;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "calendarBoardcastData") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.CALENDAR_BOARDCAST_DATA;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "delComment") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.DEL_COMMENT;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "delPost") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.DEL_POST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "editPost") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.EDIT_POST;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === 'myVideoBroker') {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.MY_VIDEO_BROKER;
    formData = payload.postData;
    isJSON = true;
  }  else if (payload.module === 'getSingleRolePlayData') {
    url = CONFIG.API_URL + CONFIG.API_ROUTES.BASE_PATH;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "getRolePlayMasterList") {
    // url = CONFIG.API_URL + CONFIG.API_ROUTES.ROLEPLAY_MASTER_LIST;
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.FETCH_ROLEPLAY_ASSIGNMENT_WISE;
    formData = payload.postData;
    isJSON = true
  } else if (payload.module === "createFolder") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CREATE_FOLDER;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "createFile") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CREATE_FILE;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "deleteFolder") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.DELETE_FOLDER;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "deleteFile") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.DELETE_FILE;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "toolbox-detail") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.FOLDER_DETAILS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "toolbox-search") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.SEARCH_DETAILS;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "checklisttApproval-detail") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHECKLIST_DETAIL;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "checklist-approval") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHECKLIST_APPROVAL;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "toolboxUpdateTime") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.TOOLBOX_UPDATE_TIME;
    formData = payload.postData;
    isJSON = true;
  }
  else if (payload.module === "checklist-insert") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHECKLIST_INSERT;
    formData = payload.postData;
    isJSON = true;
  }else if (payload.module === "changePassword") {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.CHANGE_PASSWORD;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === 'assignRolePlay') {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_BROKER.ASSIGN_ROLEPLAY
    formData = payload.postData
    isJSON = true
  } else if (payload.module === 'getProductCategoriesData') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.PRODUCT_CATEGORIES_DATA;
    formData = payload.postData
    isJSON = true
  }else if (payload.module === 'getProductSubCategoriesData') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.PRODUCT_SUB_CATEGORIES_DATA;
    formData = payload.postData
    isJSON = true
  }else if (payload.module === 'getVideoCourseListingByCat') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.VIDEO_LISTING_DATA_BY_CAT;
    formData = payload.postData
    isJSON = true
  }else if (payload.module === 'getVideoCourseListingBySubCat') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.VIDEO_LISTING_DATA_BY_SUB_CAT;
    formData = payload.postData
    isJSON = true
  }else if (payload.module === 'getVideoCourseListingRelated') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.VIDEO_LISTING_DATA_RELATED;
    formData = payload.postData
    isJSON = true
  } else if (payload.module === 'singleLPInfo') {
    url = baseUrlRealBroker + CONFIG.API_ROUTES_BROKER.SINGLE_LP
    formData = payload.postData
    isJSON = true
  }else if (payload.module === "AddAssessment") {
    url = `${CONFIG.API_URL_ADMIN}${CONFIG.API_ROUTES.ASSESSMENT_SUBMISSION}`;
    formData = payload.data;
    isJSON = false;
  }
  else if (payload.module === 'courseRelated') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.COURSE_RELATED
    formData = payload.postData
    isJSON = true
  }
  else if (payload.module === "getAllLearningPathDetails") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.GET_ALL_LEARNING_PATH_DETAIL;
      formData = payload.postData
      isJSON = true
      // headers = payload?.token;
  }
  else if (payload.module === "getAssesments") {
    url =
      CONFIG.API_URL_BROKER_ADMIN +
      CONFIG.API_ROUTES_BROKER.GET_ASSESMENTS;
    formData = payload.postData;
    isJSON = true;
    // headers["X-API-TOKEN"] = payload?.token;
  } else if (payload.module === "company-settings") {
      url = `${CONFIG.API_URL_ADMIN}${CONFIG.API_ROUTES_BROKER.GET_COMPANY_SETTINGS}`;
      formData = {};
      isJSON = true;
    }
  url = `${url}`
  if (url.startsWith(CONFIG.API_URL_ADMIN)) {
    headers["X-API-TOKEN"] = localStorage.getItem(SessionKeys.API_TOKEN_BROKER);
  }

  return axios({
    method: "post",
    url,
    data: formData,
    headers: isJSON
    ? { "Content-Type": "application/json", ...headers }
    : { "Content-Type": "multipart/form-data", ...headers },
      // ? { "Content-Type": "application/json" }
      // : { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      if (response.data.status) {
        return {
          status: "success",
          payload: {
            message: response.data.message,
            payload: response.data?.data,
          },
          from: payload.module
        };
      } else {
        if (
          response.data.message.toLowerCase() === "token is invalid." ||
          response.data.message.toLowerCase() === "token invalid" ||
          response.data.message.toLowerCase() === "token not found"
        ) {
          alert("Your Session is Logged Out. Please Login.");
          window.alert = () => false;
          localStorage.clear();
          window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
          // alert("Session is expired.")
          history.push("/", { sessionExpired: true });
        }
        return {
          status: "error",
          error: {
            message: response.data.message,
          },
        };
      }
    })
    .catch((error) => {

      if ((error.response != undefined && error.response?.status === 401)) {
        // || (error.message ==="Network Error") 
        console.log("erroData clear local storage");
        alert("Your Session is Logged Out. Please Login.");
        window.alert = () => false;
        localStorage.clear();
        console.log("erroData local storage cleared");
        window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
        // console.log("go to window");
        // alert("Session is expired.")
        history.push("/", { sessionExpired: true });
        // console.log("errorData, history");
      }
      return {
        status: "error",
        error,
      };
    });
};

export const postAPI = async (payload, isJSON = true) => {
  // console.log("userPayload: getNotes ", payload);
  let baseUrl = CONFIG.API_URL;
  let baseUrlBroker = CONFIG.API_URL_BROKER;
  let baseUrlRealBroker = CONFIG.API_URL_REAL_BROKER;
  let formData = isJSON
    ? !_.isEmpty(payload?.postData)
      ? payload.postData
      : {}
    : objectToFormData(payload);

  let url =
    baseUrl +
    CONFIG.API_ROUTES.BASE_PATH +
    "?module=" +
    payload.module +
    "&token=" +
    payload.token;
  if (payload.module === "save-later") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.SAVE_LATER_BASE_PATH;
  } else if (payload.module === "delete-save-later") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.DELETE_SAVE_LATER_BASE_PATH;
  }
  // else if (payload.module === 'splash-banner') {
  //   url = baseUrl + CONFIG.API_ROUTES.DYNAMIC_BANNER
  // } else if (payload.module === 'splash-broker-banner') {
  //   url = baseUrlRealBroker + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_GET_BANNER
  // }
   else if (payload.module === "videoListing") {
    url = payload.broker
      ? baseUrlRealBroker +
      CONFIG.API_ROUTES_REAL_BROKER.BROKER_ADMIN_VIDEO_LIST
      : baseUrlBroker + CONFIG.API_ROUTES_BROKER.VIDEO_LISTING;
  }

  //
  // else if (payload.module === "videoListing") {
  //   url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.VIDEO_LISTING;
  // }
  // else if (payload.module === "videoDetailBroker") {
  //   url =baseUrlRealBroker+ CONFIG.API_ROUTES_REAL_BROKER.BROKER_ADMIN_VIDEO_LIST;
  //   formData = payload.postData;
  //   isJSON = true;
  // }
  //
  else if (payload.module === "applyCoupon") {
    url = baseUrlBroker + CONFIG.API_ROUTES_BROKER.COUPON_CODE_APPLY;
  } else if (payload.module === "getModuleFavourites") {
    // console.log("getModuleFavourites");
    url = baseUrl + CONFIG.API_ROUTES.MODULE_FAV + "?module=" + payload.module;
    formData = payload.postData;
    isJSON = true;
  } else if (payload.module === "deleteModuleFavourites") {
    url =
      baseUrl + CONFIG.API_ROUTES.MODULE_FAV_DEL + "?module=" + payload.module;
  } else if (payload.module === "getNotes") {
    // console.log("getNotes in api", payload);
    url =
      CONFIG.API_URL +
      CONFIG.API_ROUTES.BASE_PATH +
      "?module=" +
      payload.module;
    formData = payload.postData;
    isJSON = true;
    // console.log("getNotes in api 1", url);
  } else if (payload.module === "updateNotes") {
    url =
      baseUrl +
      CONFIG.API_ROUTES.MODULE_NOTES_UPDATE +
      "?module=" +
      payload.module;
  } else if (payload.module === "deleteNotes") {
    url =
      baseUrl +
      CONFIG.API_ROUTES.MODULE_NOTES_DEL +
      "?module=" +
      payload.module;
  }

  url = `${url}`
  const headers = isJSON
      ? { "Content-Type": "application/json" }
      : { "Content-Type": "multipart/form-data" }

  if (url.startsWith(CONFIG.API_URL_ADMIN)) {
    headers["X-API-TOKEN"] = localStorage.getItem(SessionKeys.API_TOKEN_BROKER);
  }
  // console.log("URL getNotes", url);
  return axios({
    method: "post",
    url,
    // url: CONFIG.API_URL + CONFIG.API_ROUTES.BASE_PATH + "?module=" + payload.module + "&token=" + payload.token,
    data: formData,
    headers: headers,
  })
    .then((response) => {
      // console.log("response", response, typeof response.data);

      if (response.data.status) {
        if (typeof response.data == "string") {
          // console.log("response return error");
          return {
            status: "error",
            error: {
              message: response.data.message,
            },
          };
        }
        return {
          status: "success",
          payload: response.data,
        };
      } else {
        return {
          status: "error",
          error: {
            message: response.data.message,
          },
        };
      }
    })
    .catch((error) => {
      // console.log(
      //   "errorData",
      //   JSON.stringify(error),
      //   error.response,
      //   error.message
      // );
      if (error.response != undefined && error.response?.status === 401) {
        // console.log("erroData clear local storage");
        alert("Your Session is Logged Out. Please Login.");
        window.alert = () => false;
        localStorage.clear();
        // console.log("erroData local storage cleared");
        window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
        // console.log("go to window");
        // alert("Session is expired.")
        history.push("/", { sessionExpired: true });
        // console.log("errorData, history");
      }
      return {
        status: "error",
        error,
      };
    });
};

export const getAPIWithID = async (payload) => {
  if (payload.module === "videoCategories") {
    return {
      status: "success",
      payload: video.categories,
    };
  } else if (payload.module === "videoListing") {
    // console.log("Video Listing");
    return {
      status: "success",
      payload: video.coursesData,
    };
  } else {
    const queryParam = objectToStringData(payload);
    const url = `${CONFIG.API_URL}${CONFIG.API_ROUTES.BASE_PATH}?${queryParam}`;
    const headers = { "Content-Type": "application/json" }
    if (url.startsWith(CONFIG.API_URL_ADMIN)) {
      headers["X-API-TOKEN"] = localStorage.getItem(SessionKeys.API_TOKEN_BROKER);
    }
    return axios({
      method: "GET",
      headers: headers,
      url,
    })
      .then((response) => {
        // console.log('myrole play', response.data);
        if (response.data.status) {
          return {
            status: "success",
            payload: response.data.data,
          };
        } else {
          return {
            status: "error",
            error: {
              message: response.data.message,
            },
          };
        }
      })
      .catch((error) => {
        // console.log(
        //   "errorData",
        //   JSON.stringify(error),
        //   error.response,
        //   error.message
        // );
        if (error.response != undefined && error.response?.status === 401) {
          // console.log("erroData clear local storage");
          alert("Your Session is Logged Out. Please Login.");
          window.alert = () => false;
          localStorage.clear();
          // console.log("erroData local storage cleared");
          window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
          // console.log("go to window");
          // alert("Session is expired.")
          history.push("/", { sessionExpired: true });
          // console.log("errorData, history");
        }
        return {
          status: "error",
          error,
        };
      });
  }
};
