import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SliderMyCoursesListing from "../LearningPaths/SliderMyCoursesListing";
import SliderMyLearningPathListing from "../LearningPaths/SliderMyLearningPathListing";
import {
  getMyCoursesBroker,
  getMyCourses,
  setFavoriteCourses,
  getMyLearningPaths,
  getMyLearningPathsClear,
  getMyCoursesClear,
  getSelectedCatId,
} from "../../redux/courses/actions";
import {
  convertHoursToMins,
  convertTimeFormat,
  SessionKeys,
} from "../../utils/AppUtils";
import ExploreCardSection from "../LearningPaths/ExploreCardSection";

import _ from "lodash";
import Loader from "../Loader/Loader";
import ShimmerCardBody from "../Layouts/ShimmerCardBody";
import CONFIG from "../../utils/config";
import { getAPI } from "../../api/generic";

const MyAreaSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const myCoursesData = useSelector((state) => state.courses.myCourses);
  const brokerMyCourses = useSelector((state) => state.courses.myCoursesBroker);
  const userRole = useSelector((state) => state.user.role);
  const [pageType, setPageType] = useState("course");
  const [closeChildModal, setCloseChildModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [progressCoursesList, setProgressCoursesList] = useState([]);
  const [allCoursesList, setAllCoursesList] = useState([])
  const [startCoursesList, setStartCoursesList] = useState([]);
  const [inProgressCoursesList, setInProgressCoursesList] = useState([]);
  const [completedCoursesList, setCompletedCoursesList] = useState([]);
  const [overDueCoursesList, setOverDueCoursesList] = useState([]);
  const [allLearningPathList, setAllLearningPathList] = useState([]);
  const [startLearningPathList, setStartLearningPathList] = useState([]);
  const [inProgressLearningPathList, setInProgressLearningPathList] = useState(
    []
  );
  const [completedLearningPathList, setCompletedLearningPathList] = useState(
    []
  );
  const [overDueLearningPathList, setOverDueLearningPathList] = useState([]);
  const [isStartCourseSet, setIsStartCourseSet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCourseLoading, setIsCourseLoading] = useState(true)
  const [isLPLoading, setIsLPLoading] = useState(true)
  const [tabValue,setTabValue] = useState('');
  const [myLearningPathsData,setMyLearningPathsData] = useState([]);
  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      setIsCourseLoading(true);
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    // console.log('Ecom courses', myCoursesData)
    if (!_.isEmpty(myCoursesData)) {
      let allCourseList = []
      let startCourseList = [];
      let inProgressCourseList = [];
      let completedCourseList = [];
      let overDueCourseList = [];
      for (let courses of myCoursesData) {
        if (courses.status === "success") {
          const coursesListData = courses.payload.payload;
          const coursesList = coursesListData.map((item, index) => {
            const course_hours = Math.floor(item.duration / 60);
           const course_minutes = item.duration % 60;
            return {
              ...item,
              course_hours,
              course_minutes
            };
          });
          // console.log(coursesList,"levelStageDates");
          if (!_.isEmpty(coursesList)) {
            allCourseList = [...coursesList, ...allCourseList]
            for (let course of coursesList) {
              if (course.durationUnit === "Hours") {
                course.duration = convertHoursToMins(course.duration);
              }
              course.duration = convertTimeFormat(course.duration);
              course.durationUnit = "";
            }
            startCourseList = [
              ...coursesList.filter((course) => course.status === "start"),
              ...startCourseList,
            ];
            inProgressCourseList = [
              ...coursesList.filter(
                (course) => course.status === "in-progress"
              ),
              ...inProgressCourseList,
            ];
            completedCourseList = [
              ...coursesList.filter((course) => course.status === "completed"),
              ...completedCourseList,
            ];
            overDueCourseList = [
              ...coursesList.filter((course) => course.status === "over-due"),
              ...overDueCourseList,
            ];
          }
        }
      }
      dispatch(getMyCoursesClear());
      setAllCoursesList(allCourseList)
      setStartCoursesList(startCourseList);
      setInProgressCoursesList(inProgressCourseList);
      setCompletedCoursesList(completedCourseList);
      setOverDueCoursesList(overDueCourseList);
      setIsLoading(false);
      setIsCourseLoading(false)
    }
  }, [myCoursesData]);

  useEffect(() => {
    getProgramDetails(tabValue);
   }, [tabValue]);

  const getProgramDetails = async (tabValue) => {
    setIsLPLoading(true);
    const res = await getAPI({
      module: "getProgramDetails",
      status:tabValue?.toLowerCase(),
      // status: tabValue?.toLowerCase(),
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    });
    if (res.status == "success") {
      setMyLearningPathsData(res?.payload);
      setIsLPLoading(false);
    }
  };

  useEffect(() => {
    if(myLearningPathsData?.length>0){

      const learning = myLearningPathsData;
      setAllLearningPathList(learning);
      setStartLearningPathList(
        learning.filter((course) => course.status === "not-started")
      );
      setInProgressLearningPathList(
        learning.filter((course) => course.status === "in-progress")
      );
      setCompletedLearningPathList(
        learning.filter((course) => course.status === "completed")
      );
      setOverDueLearningPathList(
        learning.filter((course) => course.status === "over-due")
      );
    }
  }, [myLearningPathsData?.length]);


  const setFavorite = (id) => {
    // console.log("setFAV_ID", id);
    // return;
    const postData = {
      course_id: {
        _id: {
          $oid: id,
        },
        bkmrk: true,
        type: pageType,
      },
    };
    // TODO: TO CHANGE AFTER USER ID CHANGES
    // dispatch(setFavoriteCourses({
    //     module: 'courseFavorite',
    //     token: localStorage.getItem(SessionKeys.API_TOKEN),
    //     postData
    // }));
  };

  const renderCoursesList = (courseList, type) => {
    return (
      <>
        {
          !isCourseLoading ? 
            <>
              {courseList.length > 0 ? (
                <SliderMyCoursesListing
                  isOnboarding={false}
                  coursesList={courseList}
                  setFavoriteCourse={setFavorite}
                  closeModal={closeChildModal}
                  setCloseModal={setCloseChildModal}
                  hasDescription={false}
                  pageType="course"
                  showBlankCard={true}
                  courseType={type}
                />
              ) : (
                <div className="not-found mt-3">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>
                      {type === "start"
                      ? 
                      <>
                        <span>
                          Courses that are assigned to you or started by you will appear here. <br />
                          {/* Click <a
                            className="hyperlink-to"
                            onClick={() => {
                              dispatch(getSelectedCatId(''))   
                              history.push(`/courseListing?selectedCategory=&selectCourse=top`)
                            }}
                          >here</a> to explore Courses. */}
                        </span>
                      </>
                      : 
                      type === 'all' ?
                      <>
                        <span>
                          Courses that are assigned to you, will appear here. <br />
                          {/* Click <a
                            className="hyperlink-to"
                            onClick={() => {
                              dispatch(getSelectedCatId(''))  
                              history.push(`/courseListing?selectedCategory=&selectCourse=top`)
                            }}
                          >here</a> to explore Courses. */}
                        </span>
                      </>
                      :
                      <>
                        <span>
                          Courses that are {type} will appear here. <br />
                          {/* Click <a
                            className="hyperlink-to"
                            onClick={() => {
                              dispatch(getSelectedCatId(''))  
                              history.push(`/courseListing?selectedCategory=&selectCourse=top`)
                            }}
                          >here</a> to explore Courses. */}
                        </span>
                      </>
                      }
                    </p>
                  </div>
                </div>
              )}
            </>
          : 
            <>
              <div className="row videolistbox">
                {
                  [1,2,3].map(i => {
                    return (
                      <ShimmerCardBody key={`c-${type}-${i}`} />
                    )
                  })
                }
              </div>
            </>
        }
      </>
    );
  };


  const renderLearningPathList = (learningPathList, type) => {
    return (
      <>
        {
          !isLPLoading ? 
            <>
              {learningPathList.length > 0 ? (
                <SliderMyLearningPathListing
                  isOnboarding={false}
                  learningPathList={learningPathList}
                  setFavoriteCourse={setFavorite}
                  closeModal={closeChildModal}
                  setCloseModal={setCloseChildModal}
                  hasDescription={false}
                  pageType="learningPath"
                  showBlankCard={true}
                  learningType={type}
                />
              ) : (
                <div className="not-found mt-3">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>
                      {type === "start"
                        ? 
                        <>
                          <span>
                            Learning Paths that are assigned to you or started by you will appear here. <br />
                            {/* Click <a
                              className="hyperlink-to"
                              onClick={() => {
                                history.push('/learning-paths')
                              }}
                            >here</a> to explore Learning Paths. */}
                          </span>
                        </>
                        : 
                        type === 'all' ?
                        <>
                          <span>
                            Learning Paths that are assigned to you, will appear here. <br />
                            {/* Click <a
                              className="hyperlink-to"
                              onClick={() => {
                                history.push('/learning-paths')
                              }}
                            >here</a> to explore Learning Paths. */}
                          </span>
                        </>
                        :
                        <>
                          <span>
                            Learning Paths that are {type} will appear here. <br />
                            {/* Click <a
                              className="hyperlink-to"
                              onClick={() => {
                                history.push('/learning-paths')
                              }}
                            >here</a> to explore Learning Paths. */}
                          </span>
                        </>
                      }
                    </p>
                  </div>
                </div>
              )}
            </>
          : 
          <>
            <div className="row videolistbox">
              {
                [1,2,3].map(i => {
                  return (
                    <ShimmerCardBody key={`c-${type}-${i}`} />
                  )
                })
              }
            </div>
          </>
        }
      </>
    );
  };

  const handleProgramStatusValue = (key) => {
    if(key == ""){
      setTabValue('');
    } else {
      setTabValue(key);
    }
  };

  return (
    <>
      {/* <Loader start={isLoading} /> */}
      <section className="my_onbording pt-5g" id="myArea">
        <div className="container">
          <div>
            <div className="d-flex My_Dashboard New_My_Dashboard pb-3">
              <h3>My Programs</h3>
            </div>
            <div className="rows my_courses">
              <Tabs defaultActiveKey="" className="mb-2"  activeKey={tabValue}
             onSelect={handleProgramStatusValue}>
                <Tab eventKey="" title="All">
                  <div>
                    {(userRole === "realtor" || userRole === "manager") ? (
                      <>
                        {renderLearningPathList(allLearningPathList, "")}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Tab>
                <Tab eventKey="not-started" title="Yet to Start">
                  <div>
                    {/* <div className="content-title">Courses</div>
                    {renderCoursesList(startCoursesList, "start")} */}
                      {
                        authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                        <>
                  <div className="content-title">Courses</div>
                    {renderCoursesList(startCoursesList, "not-started")}
                        </>
                      }
                    {(userRole === "realtor" || userRole === "manager") ? (
                      <>
                        {/* <div className="content-title mt-4">Programs</div> */}
                        {renderLearningPathList(startLearningPathList, "not-started")}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Tab>
                <Tab eventKey="in-progress" title="In Progress">
                  <div>
                    {
                        authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                        <>
                        <div className="content-title">Courses</div>
                        {renderCoursesList(inProgressCoursesList, "in-progress")}
                        </>
                      }
                    {(userRole === "realtor" || userRole === "manager") ? (
                      <>
                        {/* <div className="content-title mt-4">Programs</div> */}
                        {renderLearningPathList(
                          inProgressLearningPathList,
                          "in-progress"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Tab>
                <Tab eventKey="completed" title="Completed">
                  <div>
                    {
                      authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                      <>
                       <div className="content-title">Courses</div>
                      {renderCoursesList(completedCoursesList, "completed")}
                      </>
                     
                    }
                   
                    {(userRole === "realtor" || userRole === "manager") ? (
                      <>
                        {/* <div className="content-title mt-4">Programs</div> */}
                        {renderLearningPathList(
                          completedLearningPathList,
                          "completed"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Tab>
                {
                  authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                  <Tab eventKey="overdue" title="Over Due">
                  <div>
                    <div className="content-title">Courses</div>
                    {renderCoursesList(overDueCoursesList, "over-due")}
                    {(userRole === "realtor" || userRole === "manager") ? (
                      <>
                        <div className="content-title mt-4">Learning Paths</div>
                        {renderLearningPathList(
                          overDueLearningPathList,
                          "over due"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Tab>
                }
                
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyAreaSection;
