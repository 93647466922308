import { fork, take, call, put } from 'redux-saga/effects'

import {
  productResponse,
  recommendProductResponse,
  courseDetailResponse,
  addToCartResponse,
  getOrderDetailResponse,
  addToWishListResponse,
  getWishListResponse,
  delWishListResponse
} from './actions'
import types from './types'
import { productDetail, recommendProducts } from '../../api/product'
import { getAPIWithID,getAPI,postAPIWithoutModule } from '../../api/generic'


export function* doProductDetail() {
  while (true) {
    // console.log('is called')
    const { payload } = yield take(types.PRODUCT_DETAIL)
    // console.log('payload')
    const response = yield call(getAPIWithID, payload)
    // console.log(response)
    yield put(productResponse(response))
  }
}

export function* doRecommendProducts() {
  while (true) {
    const { payload } = yield take(types.RECOMMEND_PRODUCT)
    const response = yield call(recommendProducts, payload)
    yield put(recommendProductResponse(response))
  }
}

export function* doCourseDetail() {
  while (true) {
    const { payload } = yield take(types.COURSE_DETAIL)
    const response = yield call(getAPI, payload)
    yield put(courseDetailResponse(response))
  }
}

export function* doAddToCart() {
  while (true) {
    const { payload } = yield take(types.ADD_TO_CART)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(addToCartResponse(response))
  }
}

export function* doGetOrderDetail() {
  while (true) {
    const { payload } = yield take(types.GET_ORDER_DETAIL)
    const response = yield call(getAPI, payload)
    yield put(getOrderDetailResponse(response))
  }
}

export function* doAddToWishList() {
  while (true) {
    const { payload } = yield take(types.ADD_TO_WISHLIST)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(addToWishListResponse(response))
  }
}

export function* doGetWishList() {
  while (true) {
    const { payload } = yield take(types.GET_WISHLIST)
    const response = yield call(getAPI, payload)
    yield put(getWishListResponse(response))
  }
}

export function* doWishList() {
  while (true) {
    const { payload } = yield take(types.DELETE_WISHLIST)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(delWishListResponse(response))
  }
}

function* product() {
  yield fork(doProductDetail)
  yield fork(doRecommendProducts)
  yield fork(doCourseDetail)
  yield fork(doAddToCart)
  yield fork(doGetOrderDetail)
  yield fork(doAddToWishList)
  yield fork(doGetWishList)
  yield fork(doWishList)
}


export default product