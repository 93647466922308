import ArticleSection from "../../components/Article/ArticleSection";
import Page from "../../components/Page";

const NewsnArticles = () => {
    
   
    return (
        <Page>
            <ArticleSection />
        </Page>
    )
}

export default NewsnArticles;