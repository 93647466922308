import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CONFIG from "./utils/config";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/font.css";
import "./assets/css/slick-slider-custom.css";

// import "./assets/css/bootstrap/bootstrap.min.css"
// import './App.css'
import "./assets/fonts/font.css";
import "./assets/css/font-awesome.css";
import "./assets/css/style.css";
import "./assets/css/page.css";
import "./assets/css/custom.css";
// import "./assets/css/profile.css"
import "./assets/css/learning-path.css";
import "react-circular-progressbar/dist/styles.css";
import "./assets/css/introduction.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/media.css";
import "./assets/css/custom-brokarage-theme.css";
import "react-datepicker/dist/react-datepicker.css";

import logo from "./logo.svg";
import ResetPass from "./pages/ResetPassword/ResetPass";

import ProductPage from "./pages/ProductPage";
import NewsnArticles from "./pages/NewsArticles/NewsnArticles";
import MasterCodeOfEthicsPage from "./pages/courses/MasterCodeOfEthicsPage";
import NoteList from "./components/notes/NoteList";
import Favorites from "./pages/menuPages/Favorites";
import SetupPasswordPage from "./pages/SetupPasswordPage";
import ActivationLinkExpiryPage from "./pages/ActivationLinkPage";
import LearningPathsPage from "./pages/learningPaths/LearningPathsPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import CertifyPage from "./pages/Certify/CertifyPage";
import MembershipPage from "./pages/Membership/MembershipPage";
import CartPage from "./pages/Cart/CartPage";
import OrderHistoryPage from "./pages/Orders/OrderHistoryPage";
import PaymentPage from "./pages/Orders/PaymentPage";
import ShoppingPage from "./pages/Cart/ShoppingPage";
import CourseMobileDetail from "./pages/courses/CourseMobileDetail";
import CourseLaunchPage from "./pages/courses/CourseLaunchPage";
import CourseLaunchMobilePage from "./pages/courses/CourseLaunchMobilePage";
import NewsnArticlesNoHeader from "./pages/NewsArticles/NewsArticlesNoHeader";
import CreditPoints from "./components/Cart/CreditPoints";
import CreditPointsHistoryPage from "./pages/courses/CreditPointsHistoryPage";
import LearningPoints from "./components/LearningPaths/LearningPoints";
import CalendarEvents from "././pages/Calender Events/CalenderEvents";
import MyEventsPage from "./pages/Events/MyEventsPage";
import PaymentMobilePage from "./pages/Orders/PaymentMobilePage";
import EventDetailMobilePage from "./pages/Events/EventDetailMobilePage";
import MyCoursesPage from "./pages/menuPages/MyCoursesPage";
import MyCertification from "./pages/menuPages/MyCertification";
import LoginHostInstPage from "./pages/LoginHostInstPage";
import CalendarHostEvents from "./pages/Calender Events/CalendarHostEvents";
import CalenderInstructorEvents from "./pages/Calender Events/CalenderInstructorEvents";
import MyWishList from "./pages/menuPages/MyWishList";
import MyOnboarding from "./pages/menuPages/MyOnboarding";
import AcceptRole from "./pages/menuPages/AcceptRole";
import MyLearningCenter from "./pages/menuPages/MyLearningCenter";
import ReportDashboard from "./pages/Reports/ReportDashboard";
import RolePlay from "./pages/RolePlay/RolePlay";
import ReviewRolePlay from "./pages/RolePlay/ReviewRolePlay";
import ReviewsRolePlays from "./pages/RolePlay/ReviewsRolePlays";
import ToReview from "./pages/RolePlay/ToReview";
import RolePlayMobile from "./pages/RolePlay/RolePlayMobile";
import ReviewRolePlayMobile from "./pages/RolePlay/ReviewRolePlayMobile";
import MyRolePlayMobile from "./pages/RolePlay/MyRolePlayMobile";
import OnboardReportPage from "./pages/Reports/OnboardReportPage";
import ProfileCompletionReportPage from "./pages/Reports/ProfileCompletionReportPage";
import LearningPathProgressReportPage from "./pages/Reports/LearningPathProgressReportPage";
import CourseProgressReportPage from "./pages/Reports/CourseProgressReportPage";
import CodeOfEthicsReportPage from "./pages/Reports/CodeOfEthicsReportPage";
import EventReportPage from "./pages/Reports/EventReportPage";
import PlanUpgradeReport from "./pages/Reports/PlanUpgradeReport";
import PurchaseReport from "./pages/Reports/PurchaseReport";
import PrivacyandTerms from "./pages/Privacy/PrivacyandTerms";
import MyWallPage from "./pages/myNetwork/MyWallPage";
import VideoPlayerPage from "./pages/videoCourses/VideoPlayerPage";
import Pdftron from "./components/Course/Pdftron";
// import Test from './components/Course/Test'
import ProgressReportPage from "./pages/Reports/progressReportPage";
import Test from "./components/Course/Test";
import ComplianceReportPage from "./pages/Reports/ComplianceReportPage";
import NotFoundPage from "./pages/NotFoundPage";
import LevelDetailPage from "./pages/learningPaths/LevelDetailPage";
import VideoPlayerBroadcastPage from "./pages/videoCourses/VideoPlayerBroadcastPage";
import CookiePolicy from "./pages/FooterSectionComp/CookiePolicy";
import PrivacyPolicy from "./pages/FooterSectionComp/PrivacyPolicy";
import TermsOfUse from "./pages/FooterSectionComp/TermsOfUse";
import MyVideosPage from "./pages/menuPages/MyVideosPage"
import ReportDashboardMobile from "./pages/Reports/ReportDashboardMobile";
import OnboardReportPageMobile from "./pages/Reports/OnboardReportPageMobile";
import LearningPathProgressReportPageMobile from "./pages/Reports/LearningPathProgressReportPageMobile";
import ComplianceReportPageMobile from "./pages/Reports/ComplianceReportPageMobile";
import CodeOfEthicsReportPageMobile from "./pages/Reports/CodeOfEthicsReportPageMobile";
import ProgressReportPageMobile from "./pages/Reports/progressReportPageMobile";
import AssignCourseModal from "./components/modal/AssignCourseModal";
import AssignContentPage from "./pages/mobilePages/AssignContentPage";
import CertificateAndBadgeMobile from "./pages/mobilePages/CertificateAndBadgeMobile";
import CalendarEventsMobilePage from "./pages/Calender Events/CalendarEventsMobilePage";
import ActivationFailureResetPage from "./pages/ActivationFailureResetPage";
// import Pdftron from './components/Course/Pdftron'
// import Test from './components/Course/Test'

import CourseListingPage from "./pages/courses/CourseListingPage";
import HomePage from "./pages/HomePage";
import ModuleDetail from "./pages/modulePages/moduleDetail";
import VideoCoursesListingPage from "./pages/videoCourses/VideoCoursesListingPage";
import EditProfilePage from "./pages/menuPages/EditProfilePage";
import MyToolBox from "./pages/Toolbox/MyToolBox";
import AllEventsPage from "./pages/Events/AllEventsPage";
import EventDetailPage from "./pages/Events/EventDetailPage";
import MyRolePlays from "./pages/RolePlay/MyRolePlays";
import SearchPage from "./pages/SearchPage";
import VideoViewPage from "./pages/videoCourses/VideoViewPage";
import CertifiedDetailPage from "./pages/Certify/CertifyDetailPage";


import ServerErrorPage from "./pages/ServerErrorPage";
import PrivacyandTermsMobile from "./pages/Privacy/PrivacyandTermsMobile";
import VideoViewPageMobile from "./pages/videoCourses/VideoViewPageMobile";
import DocumentView from "./components/ToolBox/DocumentView";
import VideoPlayerPageMobile from "./pages/videoCourses/VideoPlayerPageMobile";
import NotFoundPageWithouHeader from "./pages/NotFoundPage/NotFoundPageWithouHeader";
import LoginPage from "./pages/LoginPage";
import ChangePasswordPage from "./pages/ResetPassword/ChangePasswordPage";
import CourseCategories from "./pages/courses/CourseCategories";
import VideoCategoriesPage from "./pages/courses/VideoCategoriesPage";
import Assesment from "./pages/Assesment/Assesment";
import LearningPathSection from './pages/LearningPathSectionV2/LearningpathV2'
import LearningPathCardBody from './components/LearningPaths/LearningPathCardBody';
function App() {
  global.Buffer = global.Buffer || require("buffer").Buffer;
  return (
    <div>
      <Router>
          <Suspense fallback=''>
          <Switch>
          <Route path="/" component={LoginPage} exact />
          {/* Home, Login, Reset Pass */}
            <Route path="/home" component={HomePage} exact />
          <Route path="/resetPass" component={ResetPass} exact />
          <Route path="/setup-password" component={SetupPasswordPage} exact />
          <Route
            path="/activation-expiry"
            component={ActivationLinkExpiryPage}
            exact
          />
          <Route
            path="/activation-reset-expiry"
            component={ActivationFailureResetPage}
            exact
          />
          <Route path="/verify-email" component={VerifyEmailPage} exact />
          {/* Menu Pages */}
          <Route path="/profile/edit" component={EditProfilePage} exact />
          <Route path="/notes" component={NoteList} exact />
          <Route path="/favorites" component={Favorites} exact />
          <Route path="/my-courses" component={MyCoursesPage} exact />
          {/* Courses */}
          <Route
            path="/courseDetail/:courseCode+"
            component={CourseLaunchPage}
            exact
          />
          <Route
            path="/course/module/launch/:courseCode+"
            component={CourseLaunchMobilePage}
            exact
          />{" "}
          {/* for mobile */}
          <Route path="/courseListing" component={CourseListingPage} exact />
          <Route path="/codeOfEthics" component={MasterCodeOfEthicsPage} exact />
          {/* Learning Paths */}
          <Route path="/learning-paths" component={LearningPathsPage} exact />
          {/* Learning Points */}
          <Route path="/learning-points" component={LearningPoints} exact />
          {/* Modules */}
          <Route path="/moduleDetail/:courseId+" component={ModuleDetail} exact />

          <Route
            path="/courseModuleListing/:courseId+"
            component={CourseMobileDetail}
            exact
          />
          {/* for mobile */}
          {/* Video */}
          <Route
              path="/video-listing"
              component={VideoCoursesListingPage}
              exact
            />
          {/* Cart */}
          <Route path="/cart" component={CartPage} exact />
          {/* Shopping */}
          <Route path="/shopping/:courseId" component={ShoppingPage} exact />
          {/* Orders */}
          <Route path="/orders" component={OrderHistoryPage} exact />
          <Route path="/payment" component={PaymentPage} exact />
          <Route path="/payment/mobile" component={PaymentMobilePage} exact />
          {/* Credit points */}
          <Route path="/credit-points" component={CreditPoints} exact />
          <Route
            path="/credit-points-history"
            component={CreditPointsHistoryPage}
            exact
          />
          {/* Products */}
          <Route path="/product/:slug" component={ProductPage} exact />
          <Route path="/product" component={ProductPage} exact />
          {/* Resources */}
          <Route path="/news-articles" component={NewsnArticles} exact />
          <Route path="/new-learningpath" component={LearningPathCardBody} />
          <Route
            path="/device/news-articles"
            component={NewsnArticlesNoHeader}
            exact
          />{" "}
          {/* for mobile */}
          {/* Search Result */}
          <Route path="/search" component={SearchPage} exact />
          {/* Certify */}
          <Route path="/certify" component={CertifyPage} exact />
          {/* Membership */}
          <Route path="/membership" component={MembershipPage} exact />

          {/* Calender Events/Sessions */}
          <Route path="/calendar-events" component={CalendarEvents} exact />
          <Route path="/calendar-events/iframe" component={CalendarEventsMobilePage} exact />
          <Route path="/calendar/host" component={CalendarHostEvents} exact />
          <Route
            path="/calendar/instructor"
            component={CalenderInstructorEvents}
            exact
          />
          <Route path="/events" component={AllEventsPage} exact />
          <Route path="/event-detail" component={EventDetailPage} exact />
          <Route
            path="/event-detail-mobile"
            component={EventDetailMobilePage}
            exact
          />
          <Route path="/my-events" component={MyEventsPage} exact />
          <Route path="/session/watch" component={VideoPlayerPage} exact />
          <Route path="/session/watch-mobile" component={VideoPlayerPageMobile} exact />
          <Route path="/session/broadcast/watch" component={VideoPlayerBroadcastPage} exact />
          {/* Certified */}
          <Route path="/certify-detail" component={CertifiedDetailPage} exact />
          {/* Certificate */}
          <Route path="/my-certificates" component={MyCertification} />
          {/* Host Instructor Login */}
          <Route path="/host-inst/login" component={LoginHostInstPage} />
          <Route path="/wishlist" component={MyWishList} />
          <Route path="/my-onboarding" component={MyOnboarding} />
          <Route path="/accept-role" component={AcceptRole} />
          <Route path="/learning_center" component={MyLearningCenter} />
          <Route path="/my-videos" component={MyVideosPage} exact />
          {/*Video view page */}
          <Route path="/video-view-page/:productId" component={VideoViewPage} />
          <Route path="/video-view-page-mobile/:productId" component={VideoViewPageMobile} />
          {/* Report Pages */}
          <Route path="/report" component={ReportDashboard} />
          <Route
            path="/progress-report&isOnboarding=true"
            component={OnboardReportPage}
          />
          <Route
            path="/progress-report&isOnboardingMobile=true"
            component={OnboardReportPageMobile}
          />
          <Route path="/profile-report" component={ProfileCompletionReportPage} />
          <Route
            path="/progress-report&isLearningPath=true"
            component={LearningPathProgressReportPage}
          />
          <Route
            path="/progress-report&isLearningPathMobile=true"
            component={LearningPathProgressReportPageMobile}
          />
          <Route path="/course-report" component={CourseProgressReportPage} />
          <Route path="/codeofethics-report" component={CodeOfEthicsReportPage} />
          <Route path="/codeofethicsMobile-report" component={CodeOfEthicsReportPageMobile} />
          <Route path="/event-report" component={EventReportPage} />
          <Route path="/purchase-report" component={PurchaseReport} />
          <Route path="/plan-upgrade-report" component={PlanUpgradeReport} />
          <Route path="/assesments" component={Assesment} />
          <Route path="/learning-path-section" component={LearningPathSection} />
          {/* RolePlay  Page */}
          <Route path="/role-play" component={RolePlay} />
          <Route path="/review-role-play" component={ReviewRolePlay} />
          <Route path="/my-role-plays" component={MyRolePlays} />
          <Route path="/review-roleplay" component={ReviewsRolePlays} />
          <Route path="/to-review" component={ToReview} />
          <Route path="/role-play-mobile" component={RolePlayMobile} />
          <Route path="/review-role-play-mobile" component={ReviewRolePlayMobile} />
          <Route path="/my-role-play-mobile" component={MyRolePlayMobile} />

          {/* <Route path="/privacy-and-terms" component={PrivacyandTerms} /> */}
          {/* <Route path="/privacy-and-terms-mobile" component={PrivacyandTermsMobile} /> */}

          {/* MyWall Page */}
          <Route path="/my-wall" component={MyWallPage} />
          <Route path="/testTron" component={Test} />
          <Route path="/progress-report" component={ProgressReportPage} />
          <Route path="/progress-reportMobile" component={ProgressReportPageMobile} />
          <Route
            path="/progress-report&isCompliance=true"
            component={ComplianceReportPage}
          />
          <Route
            path="/progress-report&isComplianceMobile=true"
            component={ComplianceReportPageMobile}
          />
          {/* Not Found Page */}
          <Route path="/not-found" component={NotFoundPage} />
          <Route path="/levelDetail" component={LevelDetailPage} />
          {/* Footer Section Components */}
          <Route path="/cookie-policy" component={CookiePolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/report-dashboard-mobile" component={ReportDashboardMobile} />
          <Route path="/assign-content-modal" component={AssignContentPage} />
          <Route path="/certificate-badges-mobile" component={CertificateAndBadgeMobile} />
          <Route path="/toolbox" component={MyToolBox} />
          <Route path="/504-error" component={ServerErrorPage} />
          <Route path="/documentView" component={DocumentView} />
          <Route path="/change-password" component={ChangePasswordPage} />
          <Route path="/course-categories" component={CourseCategories} />
          <Route path="/video-categories" component={VideoCategoriesPage} />
          <Route path="*" component={NotFoundPage} />
          </Switch>
          </Suspense>
      </Router>
    </div >
  );
}

export default App;
