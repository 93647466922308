import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import CONFIG from "../utils/config";
import Page from "../components/Page"
import BannerSection from "../components/BannerSection"
import MainSection from "../components/MainSection"
import { getUserCourses, getInProgressBroker } from '../redux/courses/actions'
import { clickStreamUser, SessionKeys } from '../utils/AppUtils'
import ReactRoundedImage from "react-rounded-image";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { myCart } from '../redux/Cart/actions'
import { getBannerCardSummary, getBannerCardSummaryClear, getProfileCompletePercentage, getProfileCompletePercentageClear } from '../redux/user/actions'
import { postEthicsAgreeCheck } from '../redux/courses/actions'
import _, { set } from "lodash";
import { setIsMembershipClear } from '../redux/membership/actions'
import { getCodeOfEthics, getCodeOfEthicsResponse } from "../redux/courses/actions";
import Loader from '../components/Loader/Loader'
import { homePageMsgClear, homePageShowMsg } from '../redux/home/actions'
import ShimmerBannerCard from '../components/Layouts/ShimmerBannerCard'
import ChangePassowrdPopup from './ChangePassowrdPopup';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import loginResponse from './loginResponse.json';
import FeedbackSuccessPopup from "../components/FeedbackPopups/FeedbackSuccessPopup";
import FeedbackPopupMain from "../components/FeedbackPopups/submitFeedbackMain";
import { Rating, Stack } from "@mui/material";
import { Button, Modal } from "react-bootstrap";

const HomePage = (props, id) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();

  const auth = useSelector(state => state.auth.auth)
  const authBroker = useSelector(state => state.auth.authBroker);
  const profilePercentData = useSelector(state => state.user.profilePercentageData);
  const checklistData = useSelector(state => state.courses.ethicsAgreeData);
  const bannerCard = useSelector(state => state.user.bannerCard)
  const isMembershipSucceed = useSelector(state => state.membership.isMembershipSucceed)
  const agreeResponse = useSelector(
    (state) => state.courses.ethicsAgreeData
  );
  const codeOfEthics = useSelector((state) => state.courses.ethicsData);
  const userRole = useSelector(state => state.user.role)
  const showHomePageMsg = useSelector(state => state.home.showHomePageMsg)
  const homePageMsg = useSelector(state => state.home.homePageMsg)
  const [ethicsData, setEthicsData] = useState([]);
  const [agreeRespData, setAgreeRespData] = useState([]);

  const [isProfilePopup, setIsProfilePopup] = useState({
    isOpen: false,
    progress: 0
  })
  const [isOnBoardPopup, setIsOnBoardPopup] = useState({
    isOpen: false,
    progress: 0
  })
  const [isOnCheckListPopup, setIsOnCheckListPopup] = useState({
    isOpen: false,
    progress: 0
  })
  const [profilePercent, setProfilePercent] = useState(0)
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmMsgModal, setConfirmMsgModal] = useState(false)
  const [showModalMsg, setShowModalMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [bannerLoading, setBannerLoading] = useState(false)
  const [defaultPassowrd,setDefaultPassword] = useState("");
  const [joyState, setJoyState] = useState({
        run: false,
        steps: [
          {
            content: <h2>Let's begin our journey!</h2>,
            locale: { skip: <strong aria-label="skip">skip</strong> },
            placement: 'center',
            target: 'body',
          },
          {
            target: '.mobclassfluid',
            content: 'This is my awesome feature!',
          },
        ],
    });

  useEffect(() => {
    const loginData = authBroker?.payload?.user?.default_pass_status;
    // const loginData = loginResponse?.payload?.user?.default_password;
    setDefaultPassword(loginData);
  }, [dispatch, userRole])

  useEffect(() => {
    // console.log('bannerCard', bannerCard)
    if (!_.isEmpty(bannerCard)) {
      if (bannerCard.status === 'success') {
        const bannerData = bannerCard.payload?.payload
        if (!_.isEmpty(bannerData)) {
          if (bannerData.onboarding.progress < 100) {
            setIsOnBoardPopup({
              isOpen: true,
              progress: bannerData.onboarding.progress
            })
          } else {
            setIsOnBoardPopup({
              isOpen: false,
              progress: 0
            })
          }
          if (bannerData.profile.completion < 100) {
            setIsProfilePopup({
              isOpen: true,
              progress: bannerData.profile.completion
            })
            setProfilePercent(bannerData.profile.completion)
          } else {
            setIsProfilePopup({
              isOpen: false,
              progress: 0
            })
          }
          if (bannerData.checklist.progress < 100) {
            setIsOnCheckListPopup({
              isOpen: true,
              progress: bannerData.checklist.progress
            })
          } else {
            setIsOnCheckListPopup({
              isOpen: false,
              progress: 0
            })
          }
        }
      } else if (bannerCard.status === 'error') {
        setIsOnBoardPopup(false)
        setIsOnCheckListPopup(false)
        setIsProfilePopup(false)
      }
      setBannerLoading(true)
      dispatch(getBannerCardSummaryClear())
    }
  }, [bannerCard])

  useEffect(() => {
    if (isMembershipSucceed) {
      setConfirmModal(true)
      setTimeout(() => {
        dispatch(setIsMembershipClear())
      }, 10000)
    }
  }, [isMembershipSucceed])


  useEffect(() => {
    if (showHomePageMsg && homePageMsg) {
      window.scrollTo(0, 0)
      setConfirmMsgModal(true)
      setShowModalMsg(homePageMsg)
      setTimeout(() => {
        dispatch(homePageShowMsg(false))
        dispatch(homePageMsgClear(''))
        setConfirmMsgModal(false)
        setShowModalMsg('')
      }, 10000)
    }
  }, [showHomePageMsg, homePageMsg])

  const handleClickStart = (event) => {
    event.preventDefault();
    setJoyState({
      run: true,
      steps: [
        {
          content: <h2>Let's start your journey!</h2>,
          locale: { skip: <strong aria-label="skip">Skip</strong> },
          placement: 'center',
          target: 'body',
        },
        {
          target: '#myDashboard',
          placement: 'top',
          title: 'My Dashboard',
          content: 'Get access to insights like learning points and rank!',
          locale: { skip: <strong aria-label="skip">Skip</strong> },
        },
        {
          target: '#myCalendar',
          placement: 'top',
          title: 'My Calendar',
          content: 'You can see your weekly sessions.',
          locale: { skip: <strong aria-label="skip">Skip</strong> },
        },
        {
          target: '#myArea',
          placement: 'top',
          title: 'My Area',
          content: 'You can see all the assigned courses & learning paths and your learning progress.',
        },
      ]
    });
  };

  const handleJoyrideCallback = (data) => {
      const { status, type } = data;
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
  
      if (finishedStatuses.includes(status)) {
        setJoyState({ run: false, steps: [
          {
            content: <h2>Let's start your journey!</h2>,
            locale: { skip: <strong aria-label="skip">Skip</strong> },
            placement: 'center',
            target: 'body',
          },
          {
            target: '#myDashboard',
            placement: 'top',
            title: 'My Dashboard',
            content: 'Get access to insights like learning points and rank!',
            locale: { skip: <strong aria-label="skip">Skip</strong> },
          },
          {
            target: '#myCalendar',
            placement: 'top',
            title: 'My Calendar',
            content: 'You can see your weekly sessions.',
            locale: { skip: <strong aria-label="skip">Skip</strong> },
          },
          {
            target: '#myArea',
            placement: 'top',
            title: 'My Area',
            content: 'You can see all the assigned courses & learning paths and your learning progress.',
          },
        ] });
      }
  };   

  const renderAlertCard = () => {
    return (
      <div className={`alert-card d-flex justify-content-center premimimmember ${confirmModal ? '' : 'd-none'}`}>
        <div className="premium-band">
          <div className="shop-info justify-content-center align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
              </g>
            </svg>
            <p className="m-0">
              Congratulations! Your account has been upgraded to Xsel Premium membership.
            </p>
          </div>
          <div className="view-cart-icon">
            <span
              className="cursor-pointer"
              onClick={() => {
                clickStreamUser('go-to-myorder-from-band', {
                  userId: auth.authBroker?.payload.user.user_id,
                })
                history.push('/orders')
              }}
            >View Order Details</span>
            <i className="fa fa-times cursor-pointer" aria-hidden="true"
              onClick={() => {
                setConfirmModal(false)
              }}
            ></i>
          </div>
        </div>
      </div>
    )
  }

  const renderCard = () => {
    return (
      <div className={`alert-card d-flex justify-content-center homealertbox ${confirmMsgModal ? '' : 'd-none'}`}>
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            <p className="m-0">{showModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <i className="fa fa-times cursor-pointer" aria-hidden="true"
              onClick={() => {
                setConfirmMsgModal(false)
                dispatch(homePageShowMsg(false))
                dispatch(homePageMsgClear(''))
                setShowModalMsg('')
              }}
            ></i>
          </div>
        </div>
      </div>
    )
  }

  const renderBannerCard = () => {
    return (
      <div className="slider-card" >
        <div className="container">
          {
            bannerLoading ?
              <div className="row">
                {isProfilePopup.isOpen ?
                  <div className="card popup-home">
                    <div className="card-body">
                      <div className="popup-home-pg">
                        <div className="container-chart chart-section">
                          <div className="box d-flex align-items-center justify-content-center pie-chart">
                            <div className='circularProgress'
                              style={{
                                width: 56,
                                height: 56,
                                position: "relative"
                              }}
                            >
                              <CircularProgressbar
                                value={72}
                                styles={buildStyles({
                                  pathColor:
                                    72 < 100 ? `#3e98c7` : "#3e98c7",  //FF9700=yellow
                                  trailColor: "#EBEBEB",
                                })}
                              />
                              <div style={{
                                position: "absolute",
                                top: "0.5rem",
                                left: "0.5rem",
                                width: "40px",
                                textAlign: "center"
                              }}>
                                <ReactRoundedImage
                                  image={authBroker?.payload?.user?.profile_img !== undefined && authBroker?.payload?.user?.profile_img !== '' ?
                                    authBroker?.payload?.user?.profile_img : "/images/avatar.png"}
                                  roundedSize="0" imageWidth="40" imageHeight="40"
                                />
                              </div>
                            </div>

                            <div className="progress-content">
                              <p>{isProfilePopup.progress}% </p>
                              <p>complete</p>
                            </div>
                          </div>
                        </div>
                        <p className="card-text popup">You are a minute away from completing your Profile</p>
                        <p className="text-center">
                          <button type="button" className="btn btn-primary-complete"
                            onClick={() => {
                              clickStreamUser('click-profile-complete-btn', {
                                user_id: authBroker?.payload?.user?.user_id,
                              })
                              history.push('/profile/edit?section=edit')
                            }
                            }>Complete Profile</button>
                        </p>
                        <p className="content-remind cursor-pointer" onClick={() => {
                          clickStreamUser('click-remind-me-later-btn', {
                            user_id: authBroker?.payload?.user?.user_id,
                          });
                          localStorage.setItem(SessionKeys.REMIND_ME_LATER, true);
                          setIsProfilePopup(false)
                        }}>Remind me later</p>
                      </div>
                    </div>
                  </div> 
                  : ""}
              </div>
              :
              <div className="row">
                {
                  [1, 2].map(n => {
                    return (
                      <ShimmerBannerCard />
                    )
                  })
                }
              </div>
          }
     ;   </div>
      </div>
    )
  }

  const [submitFeedbackSuccess, setSubmitFeedbackSuccess] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [oldFeedbackVisible, setOldFeedbackVisible] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState({
    selected: "COURSE",
    errors: {
    },
    data: { rating: 0, experience: "", headingOne: "", headingTwo: "" },
  });
  const [oldFeedbackText, setOldFeedbackText] = useState(
    "Course / Module Name 1"
  );

  const handleNormalFeedbackVisible = () => {
    if (oldFeedbackVisible) {
      setOldFeedbackVisible(false);
    } else {
      setFeedbackVisible(true);
    }
  };

  const submitFeedbackPopupSingleCourse = (
    <div className="submit-feedback--popup  bg--white submit--feedback--single--course">
      <div
        className="cross-icon"
        onClick={() => [
          setOldFeedbackVisible(false),
          setFeedbackDetails({ ...feedbackDetails, errors: {} }),
        ]}
      >
       <img
          src="/images/closeFeedback.svg"
          alt="Close"
          style={{cursor:"pointer"}}
        />
      </div>
      <h2>Submit your feedback</h2>
      <div
        className={
          feedbackDetails?.selected === "OTHERS" ? "others--selected" : ""
        }
      >
        <div className="body">
          <h3>
            How would you like to rate “{oldFeedbackText}” that you have
            recently completed?
          </h3>
          <div className="rating-section">
            <p>How was your experience?</p>
            <div className="star-holder">
              <Stack spacing={1}>
                <Rating
                  name="simple-controlled"
                  defaultValue={0}
                  precision={1}
                  size="large"
                  value={feedbackDetails?.data?.rating}
                  onChange={(event, value) => [
                    setFeedbackDetails({
                      ...feedbackDetails,
                      data: {
                        ...feedbackDetails.data,
                        rating: value,
                      },
                    }),
                  ]}
                />
              </Stack>
            </div>
            {feedbackDetails?.errors?.rating ? (
              <p className="error--msg">Rating must be selected</p>
            ) : (
              ""
            )}
          </div>
          <div className="experience-section">
            <p>Tell Us About Your Experience</p>
            <textarea
              value={feedbackDetails?.data?.experience}
              onChange={(e) => [
                setFeedbackDetails({
                  ...feedbackDetails,
                  data: {
                    ...feedbackDetails.data,
                    experience: e.target.value,
                  },
                }),
              ]}
              maxLength={250}
              placeholder="Comments..."
            ></textarea>
            {feedbackDetails?.errors?.experience ? (
              <p className="error--msg">Experience must be filled.</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <Button
          onClick={() => [
            setOldFeedbackVisible(false),
            setFeedbackDetails({ ...feedbackDetails, errors: {} }),
          ]}
          variant="secondary"
          id="save-btn"
          className="btn-primary"
        >
          Dismiss
        </Button>
        <Button
          variant="primary"
          id="approve"
          className="btn btn-primary"
          onClick={() => handleFeedbackSubmit()}
        >
          Send Feedback
        </Button>
      </div>
    </div>
  );

  const handleFeedbackSubmit = () => {
    handleFeedbackValidation();
  };
  function handleFeedbackValidation() {
    let currentErrors = { ...feedbackDetails.errors };

    if (!feedbackDetails.data?.rating) currentErrors.rating = true;
    else delete currentErrors.rating; 
    if (!feedbackDetails.data?.experience) currentErrors.experience = true;
    else delete currentErrors.experience; 

    setFeedbackDetails({
      ...feedbackDetails,
      errors: {
        ...currentErrors,
      },
    });
    return Object.values(currentErrors) == 0;
  }

  return (
    <div className="main--home--page">
      {CONFIG.RPS_COMPANY_ID == authBroker?.payload?.user?.company_id && (
        <>
          <div
            className="feedback--button contentDesktopView"
            onClick={() => handleNormalFeedbackVisible()}
          >
            <img
            src="/images/normalFeedbackVisible.svg"
            alt="feedback"
             />
            Feedback
          </div>

          {submitFeedbackSuccess && (
            <FeedbackSuccessPopup
              submitFeedbackSuccess={submitFeedbackSuccess}
              setSubmitFeedbackSuccess={setSubmitFeedbackSuccess}
            />
          )}
          {feedbackVisible && (
            <FeedbackPopupMain
              setShow={setFeedbackVisible}
              show={feedbackVisible}
              setSuccessPopup={setSubmitFeedbackSuccess}
              showCourses={false}
              learnerFeedbackPopup={false}
            />
          )}
          {oldFeedbackVisible && (
            <>
              <div class="background-overlay--feedback"></div>{" "}
              {submitFeedbackPopupSingleCourse}
            </>
          )}
        </>
      )}
      <Loader start={isLoading} />
      {
        defaultPassowrd == 1 ?
         (<ChangePassowrdPopup />):
         <></>
      }
      
      <Page>
        <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={joyState.run}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={joyState.steps}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
        <section className="bannermodule">
          <BannerSection
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          {renderAlertCard()}
          {renderCard()}
          {/*<AttentionModal isShow={true}/>*/}
          {/* {(userRole === "realtor" || userRole === "manager") ? renderBannerCard() : ""} */}
        </section>
        <MainSection 
          startJoyRide={handleClickStart}
        />

      </Page>
    </div>
  );
};

export default HomePage;