import Page from "../components/Page";

const ServerErrorPage = () => {
    return (
        <Page>
            <div className="container-error">
              <div className="imgbox-error">
              <img src="/images/barrier.png" className="img-fluid"/>
              </div>
              <div className="box-text-error">
              <h1>
                <span>504</span> <br />
                Internal server error
              </h1>
              <p>We are currently trying to fix the problem.</p>
              </div>
            </div>
        </Page>
    )
}

export default ServerErrorPage;