const PrevArrow = ({className, onClick}) => {
    return (
        <button className={`${className} button-reset`} onClick={onClick} >
            <svg id="Back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
                <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#00b7f1"/>
                    <stop offset="1" stopColor="#ed156e"/>
                    </linearGradient>
                </defs>
                <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"/>
                <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"/>
                <g id="Group_1344" data-name="Group 1344" transform="translate(22 15.002) rotate(135)">
                    <path id="Path_415" data-name="Path 415" d="M0,0H8V8" transform="translate(8.001 0) rotate(90)" fill="none" stroke="var(--color-element-1)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                </g>
            </svg>
        </button>
    );
}

export default PrevArrow;