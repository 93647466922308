
export const objectToFormData = (payload) => {
  const formData = new FormData();
  for ( var key in payload ) {
    formData.append(key, payload[key]);
  }

  return formData;
}

export const objectToStringData = (payload) => {
  let formData = "";
  for ( var key in payload ) {
    formData += key + "=" + payload[key] + "&";
  }

  return formData;
}