const types = {
    // Get Credit points
    GET_CREDIT_POINTS: "GET_CREDIT_POINTS",
    GET_CREDIT_POINTS_RESPONSE: "GET_CREDIT_POINTS_RESPONSE",
    GET_CREDIT_POINTS_CLEAR: "GET_CREDIT_POINTS_CLEAR",

    // Credit points history
    GET_CREDIT_POINTS_HISTORY: "GET_CREDIT_POINTS_HISTORY",
    GET_CREDIT_POINTS_HISTORY_RESPONSE: "GET_CREDIT_POINTS_HISTORY_RESPONSE",
    GET_CREDIT_POINTS_HISTORY_CLEAR: "GET_CREDIT_POINTS_HISTORY_CLEAR",
}

export default types;