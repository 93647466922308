import types from './types'
//EVENTS
export const getAllEvents = (payload) => ({ type: types.GET_ALL_EVENTS, payload })
export const getAllEventsResponse = (payload) => ({ type: types.GET_ALL_EVENTS_RES, payload })
export const getAllEventsClear = () => ({ type: types.GET_ALL_EVENTS_CLEAR })

export const getAllEventsNIIT = (payload) => ({ type: types.GET_ALL_EVENTS_NIIT, payload })
export const getAllEventsNIITResponse = (payload) => ({ type: types.GET_ALL_EVENTS_NIIT_RES, payload })
export const getAllEventsNIITClear = () => ({ type: types.GET_ALL_EVENTS_NIIT_CLEAR })

export const getMyEvents = (payload) => ({ type: types.GET_MY_EVENTS, payload })
export const getMyEventsResponse = (payload) => ({ type: types.GET_MY_EVENTS_RES, payload })
export const getMyEventsClear = () => ({ type: types.GET_MY_EVENTS_CLEAR })

//event details
export const getEventDetailsNIIT = (payload) => ({ type: types.GET_EVENTS_DETAILS_NIIT, payload })
export const getEventDetailsNIITResponse = (payload) => ({ type: types.GET_EVENTS_DETAILS_NIIT_RES, payload })
export const getEventDetailsNIITClear = () => ({ type: types.GET_EVENTS_DETAILS_NIIT_CLEAR })

//SESSIONS
export const getSessionNIITList = (payload) => ({ type: types.SESSION_LIST_NIIT, payload })
export const getSessionNIITListResponse = (payload) => ({ type: types.SESSION_LIST_NIIT_RES, payload })
export const getSessionNIITListClear = () => ({ type: types.SESSION_LIST_NIIT_CLEAR })

//EVENTS
export const getAllEventsBroker = (payload) => ({ type: types.GET_ALL_EVENTS_BROKER, payload })
export const getAllEventsBrokerResponse = (payload) => ({ type: types.GET_ALL_EVENTS_BROKER_RES, payload })
export const getAllEventsBrokerClear = () => ({ type: types.GET_ALL_EVENTS_BROKER_CLEAR })

//event details
export const getEventDetailsBroker = (payload) => ({ type: types.GET_EVENTS_DETAILS_BROKER, payload })
export const getEventDetailsBrokerResponse = (payload) => ({ type: types.GET_EVENTS_DETAILS_BROKER_RES, payload })
export const getEventDetailsBrokerClear = () => ({ type: types.GET_EVENTS_DETAILS_BROKER_CLEAR })

//SESSIONS
export const getSessionBrokerList = (payload) => ({ type: types.SESSION_LIST_BROKER, payload })
export const getSessionBrokerListResponse = (payload) => ({ type: types.SESSION_LIST_BROKER_RES, payload })
export const getSessionBrokerListClear = () => ({ type: types.SESSION_LIST_BROKER_CLEAR })

//Enroll Session Broker
export const getEnrollBrokerSession = (payload) => ({ type: types.ENROLL_BROKER_SESSION, payload })
export const getEnrollBrokerSessionResponse = (payload) => ({ type: types.ENROLL_BROKER_SESSION_RES, payload })
export const getEnrollBrokerSessionClear = () => ({ type: types.ENROLL_BROKER_SESSION_CLEAR })

//Set Enroll Session Broker
export const setEnrollBrokerSessionResponse = (payload) => ({ type: types.SET_ENROLL_BROKER_SESSION_RES, payload })
export const setEnrollBrokerSessionClear = () => ({ type: types.SET_ENROLL_BROKER_SESSION_CLEAR })
//Calendar sessions info
export const getCalendarViewSessions = (payload) => ({ type: types.CALENDAR_VIEW_SESSIONS, payload })
export const getCalendarViewSessionsResponse = (payload) => ({ type: types.CALENDAR_VIEW_SESSIONS_RES, payload })
export const getCalendarViewSessionsClear = () => ({ type: types.CALENDAR_VIEW_SESSIONS_CLEAR })

export const getCalendarSessionList = (payload) => ({ type: types.CALENDAR_SESSION_LIST, payload })
export const getCalendarSessionListResponse = (payload) => ({ type: types.CALENDAR_SESSION_LIST_RES, payload })
export const getCalendarSessionListClear = () => ({ type: types.CALENDAR_SESSION_LIST_CLEAR })

export const getLiveCalendarSessionList = (payload) => ({ type: types.LIVE_CALENDAR_SESSION_LIST, payload })
export const getLiveCalendarSessionListResponse = (payload) => ({ type: types.LIVE_CALENDAR_SESSION_LIST_RES, payload })
export const getLiveCalendarSessionListClear = () => ({ type: types.LIVE_CALENDAR_SESSION_LIST_CLEAR })

//Calendar sessions of host/inst
export const getCalendarHostSessions = (payload) => ({ type: types.CALENDAR_HOST_SESSIONS, payload })
export const getCalendarHostSessionsResponse = (payload) => ({ type: types.CALENDAR_HOST_SESSIONS_RES, payload })
export const getCalendarHostSessionsClear = () => ({ type: types.CALENDAR_HOST_SESSIONS_CLEAR })
export const getCalendarInstSessions = (payload) => ({ type: types.CALENDAR_INSTRUCTOR_SESSIONS, payload })
export const getCalendarInstSessionsResponse = (payload) => ({ type: types.CALENDAR_INSTRUCTOR_SESSIONS_RES, payload })
export const getCalendarInstSessionsClear = () => ({ type: types.CALENDAR_INSTRUCTOR_SESSIONS_CLEAR })

// Host Session
export const hostSession = (payload) => ({ type: types.HOST_SESSIONS, payload })
export const hostSessionResponse = (payload) => ({ type: types.HOST_SESSIONS_RES, payload })
export const hostSessionClear = () => ({ type: types.HOST_SESSIONS_CLEAR })

// Join Session
export const joinSession = (payload) => ({ type: types.JOIN_SESSIONS, payload })
export const joinSessionResponse = (payload) => ({ type: types.JOIN_SESSIONS_RES, payload })
export const joinSessionClear = () => ({ type: types.JOIN_SESSIONS_CLEAR })

// Event Notification
export const eventNotification = (payload) => ({ type: types.EVENT_NOTIFICATION, payload })
export const eventNotificationResponse = (payload) => ({ type: types.EVENT_NOTIFICATION_RES, payload })
export const eventNotificationClear = () => ({ type: types.EVENT_NOTIFICATION_CLEAR })

// Session Attendence
export const sessionAttendence = (payload) => ({ type: types.SESSION_ATTENDANCE, payload })
export const sessionAttendenceResponse = (payload) => ({ type: types.SESSION_ATTENDANCE_RES, payload })
export const sessionAttendenceClear = () => ({ type: types.SESSION_ATTENDANCE_CLEAR })

export const checkEventNotification = (payload) => ({ type: types.CHECK_NOTIFICATION, payload })
export const checkEventNotificationResponse = (payload) => ({ type: types.CHECK_NOTIFICATION_RES, payload })
export const checkEventNotificationClear = () => ({ type: types.CHECK_NOTIFICATION_CLEAR })

export const cancelSessionData = (payload) => ({ type: types.CANCEL_SESSION, payload })
export const cancelSessionDataResponse = (payload) => ({ type: types.CANCEL_SESSION_RES, payload })
export const cancelSessionDataClear = () => ({ type: types.CANCEL_SESSION_CLEAR })

export const sessionWatchURLDataResponse = (payload) => ({ type: types.SESSION_WATCH_URL_RES, payload })
export const sessionWatchURLDataClear = () => ({ type: types.SESSION_WATCH_URL_CLEAR })

export const setSessionFilterBy = (payload) => ({ type: types.FILTER_BY, payload})
export const setSessionFilterByClear = () => ({ type: types.FILTER_CLEAR})

export const boardcastDataResponse = (payload) => ({type: types.BOARDCAST_RESPONSE, payload})
export const boardcastDataClear = () => ({type: types.BOARDCAST_CLEAR})

export const fetchBoardcast = (payload) => ({type: types.FETCH_BOARDCAST, payload})
export const fetchBoardcastResponse = (payload) => ({type: types.FETCH_BOARDCAST_RESPONSE, payload})
export const fetchBoardcastClear = () => ({type: types.FETCH_BOARDCAST_CLEAR})

export const calendarLiveEventSaved = (payload) => ({type: types.CALENDAR_LIVE_EVENTS, payload})
export const calendarLiveEventClear = () => ({type: types.CALENDAR_LIVE_EVENTS_CLEAR})

