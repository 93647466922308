import { NavDropdown, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  clickStreamUser,
  SessionKeys,
  logoutClearSession,
  applyTheme,
  IsLogin,
} from "../utils/AppUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  loginClear,
  logout,
  logoutBroker,
  loginBrokerClear,
  logoutBrokerClear,
  logoutClear,
  loginHostInstClear,
} from "../redux/auth/actions";
import { getSelectedCatId, openHamburgerClear } from "../redux/courses/actions";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import ProfileMenuSection from "./ProfileMenuSection";
import CategoryMenuNIIT from "./LearningMenu/CategoryMenuNIIT";
import CategoryMenuProduct from "./LearningMenu/CategoryMenuProduct";
import CategoryMenuBroker from "./LearningMenu/CategoryMenuBroker";
import { setUserPremium } from "../redux/user/actions";
import CONFIG from "../utils/config";
import $ from "jquery";
import { getAllCertifiedNIIT } from "../redux/certified/actions";
// import {
//   notificationResponse,
//   notificationData,
//   notificationClear,
// } from "../redux/notification/actions";
import Notification from "./Notification";
import Loader from "./Loader/Loader";
import LiveButtonComponent from "./Layouts/LiveButton/LiveButtonComponent";
import { homePageMsgClear, homePageShowMsg } from "../redux/home/actions";
import MsgModal from "./modal/MsgModal";

const HeaderNewMenu = ({ showMenu }) => {
  const coursesListingData = useSelector((state) => {
    return state.courses.coursesListing;
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const hamburgerMenu = useRef();

  const auth = useSelector((state) => state.auth);
  const isLogout = useSelector((state) => state.auth.isLogout);
  const authBroker = useSelector(state => state.auth.authBroker)
  const authLogoutBroker = useSelector((state) => state.auth.authLogoutBroker);
  const openHamburger = useSelector((state) => state.courses.openHamburger);
  const certifiedData = useSelector((state) => state.certified.allCertified);
  const userRole = useSelector((state) => state.user.role);
  const sessionCount = useSelector((state) => state.user.sessionCount);
  const userDetailRes = useSelector(state => state.user.userBroker)
  const showHomePageMsg = useSelector(state => state.home.showHomePageMsg)
  const homePageMsg = useSelector(state => state.home.homePageMsg)

  //console.log("User roles", userRole);
  const [show, setShow] = useState(false);
  const [showMobMenu, setShowMobMenu] = useState(false);
  const [showAllMenu, setShowAllMenu] = useState(true);
  const [logo, setLogo] = useState("");
  const [certifiedList, setCertifiedList] = useState([]);
  const [otherRole, setOtherRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [confirmMsgModal, setConfirmMsgModal] = useState(false)
  const [showModalMsg, setShowModalMsg] = useState('')
  const [activeMenu, setActiveMenu] = useState('')


  useEffect(() => {
    IsLogin();
  }, [dispatch])

  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname) {
      setActiveMenu(fetchActiveMenu(window.location.pathname))
    }
  }, [window.location.pathname])

  const showDropdown = (e) => {
    //console.log("hide");
    setShow(!show);
  };
  const hideDropdown = (e) => {
    //console.log("show");
    setShow(false);
  };
  const [courseCategories, setCourseCategories] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      setName(`${authBroker.payload.user.firstname}`)
      
    }
  }, [authBroker])

  // useEffect(() => {
  //   // console.log(
  //   //   "privacy-temrms ",
  //   //   localStorage.getItem(SessionKeys.IS_AGGREED) === "undefined"
  //   // );
  //   if (
  //     localStorage.getItem(SessionKeys.IS_AGGREED) === undefined ||
  //     localStorage.getItem(SessionKeys.IS_AGGREED) === "undefined" ||
  //     localStorage.getItem(SessionKeys.IS_AGGREED) === "false"
  //   ) {
  //     history.push("/privacy-and-terms");
  //     // history.push("/home")
  //   }
  // }, [dispatch, history]);

  const signOut = () => {
    setIsLoading(true)
    dispatch(
      logoutBroker({
        module: "userlogout",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        },
      })
    );
  };

  useEffect(() => {
    //console.log("LOGOUT IS CALLED", authLogoutBroker);
    if (!_.isEmpty(authLogoutBroker)) {
      window.captureMyEvents("logout", {
        userId: auth?.authBroker?.payload.user.user_id,
        message: "User logged out",
      });
      let email = null,
        pass = null;
      if (localStorage.getItem(SessionKeys.IS_REMEMBERED)) {
        email = localStorage.getItem(SessionKeys.EMAIL);
        pass = localStorage.getItem(SessionKeys.PASS);
      }
      // alert('Logout button clicked',authLogoutBroker)
      localStorage.clear();

      if (email !== null) {
        localStorage.setItem(SessionKeys.IS_REMEMBERED, true);
        localStorage.setItem(SessionKeys.EMAIL, email);
        localStorage.setItem(SessionKeys.PASS, pass);
      }

      $("html").css("--color-element-1", CONFIG.COLOR_ELEMENT_1);
      $("html").css("--color-element-2", CONFIG.COLOR_ELEMENT_2);
      if (authBroker.payload.user.apply_theme) {
        if ($('body').hasClass('cus-bro-theme')) {
          $('body').removeClass('cus-bro-theme')
        }
      }
      dispatch(logoutClear());
      dispatch(loginClear());
      // dispatch(setUserPremium(false))
      logoutClearSession();

      dispatch(loginBrokerClear());
      dispatch(logoutBrokerClear());
      setIsLoading(false)
      history.push("/", { logout: true });
    }
  }, [authLogoutBroker]);

  useEffect(() => {
    if (!_.isEmpty(auth.authBroker)) {
      setOtherRole(auth.authBroker.payload.user.role);
      setLogo(auth.authBroker.payload.user.branding_company_logo);
      const colorElement1 =
        auth.authBroker.payload.user.branding_primary_color_1;
      const colorElement2 =
        auth.authBroker.payload.user.branding_primary_color_2;
      $("html").css("--color-element-1", colorElement1);
      $("html").css("--color-element-2", colorElement2);
      if (auth.authBroker.payload.user.apply_theme) {
        if (!$('body').hasClass('cus-bro-theme')) {
          $('body').addClass('cus-bro-theme')
        }
        const branding = auth.authBroker.payload.user.branding
        !_.isEmpty(branding) && applyTheme(branding)
      } else {
        if ($('body').hasClass('cus-bro-theme')) {
          $('body').removeClass('cus-bro-theme')
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    setShowAllMenu(showMenu);
  }, [showMenu]);

  useEffect(() => {
    if (isLogout) {
      //console.log(isLogout);
      history.push("/", { logout: true });
    }
  }, [isLogout]);

  useEffect(() => {
    //console.log("openHamburger", openHamburger);
    if (openHamburger) {
      // console.log(hamburgerMenu);
      var btnEl = document.querySelector("#mySidenav");
      if (!btnEl.classList.contains("open")) {
        hamburgerMenu.current.click();
      }
      dispatch(openHamburgerClear(false));
    }
  }, [openHamburger]);

  useEffect(() => {
    //console.log("token", SessionKeys.API_TOKEN_BROKER);
    if (Object.entries(auth.authBroker).length > 0) {
      const authData = auth.authBroker;
      //  console.log("authData", authData);
      if (!authData.payload.apiToken) {
        localStorage.removeItem(SessionKeys.API_TOKEN_BROKER);
        localStorage.removeItem(SessionKeys.NOTI_SHOW);
        dispatch(loginClear());
        history.push("/", { relogin: true });
      }
    } else if (!localStorage.getItem(SessionKeys.API_TOKEN_BROKER)) {
      dispatch(loginClear());
      history.push("/", { relogin: true });
    }
  });

  useEffect(() => {
    // console.log("CertifiedData", certifiedData);
    if (!_.isEmpty(certifiedData) && certifiedData.status === "success") {
      if (!_.isEmpty(certifiedData.payload.payload)) {
        let certifiedArray = certifiedData.payload.payload;
        for (let certify of certifiedArray) {
          certify.source = "xsel";
        }
        setCertifiedList(certifiedArray);
      }
    }
  }, [certifiedData]);

  const goToCertifiedDetail = (item) => {
    clickStreamUser("go-to-certified-detail", {
      certifyId: item.wp_id,
      certifyCode: item.c_code,
      source: item.source,
      userId: auth.authBroker?.payload.user.user_id,
    });
    history.push(
      `/certify-detail?certify_id=${item?.wp_id}&certify_code=${item?.c_code}&source=${item.source}`
    );
  };

  const toggleNav = () => {
    var btnEl = document.querySelector("#mySidenav");
    var main = document.querySelector(".main");
    var toogleicons = document.querySelector(".closetoogle");
    // console.log(btnClasslist)
    if (btnEl.classList.contains("open")) {
      //document.getElementById("openbtn").innerHTML = "<span></span><span></span><span></span>";
      document.getElementById("openbtn").classList.add("mystyle");
      toogleicons.classList.remove("closeopen");
      btnEl.classList.remove("open");
      main.style.marginLeft = "0px";
    } else {
      //document.getElementById("openbtn").innerHTML ="<span class='closetoogle'></span><span class='closetoogle'></span>";
      document.getElementById("openbtn").classList.remove("mystyle");
      btnEl.classList.add("open");
      toogleicons.classList.add("closeopen");
      main.style.marginLeft = "0px";
    }
  };
  const toggleNavDesktop = () => {
    var btnEl = document.querySelector("#mySidenav");
    var main = document.querySelector(".main");
    var toogleicon = document.querySelector("#openbtnd .closetoogle")
    var footerslide = document.querySelector(".home-footer_new")
    // console.log(btnClasslist)
    if (btnEl.classList.contains("open")) {
      //document.getElementById("openbtnd").innerHTML = "<span class='closetoogle'></span>";
      btnEl.classList.remove("open");
      toogleicon.classList.add("closeopen");
      main.style.marginLeft = "400px";
      footerslide.style.marginLeft = "400px";
    } else {
      //document.getElementById("openbtnd").innerHTML ="<span class='opentogglbar'></span>";
      btnEl.classList.add("open");
      toogleicon.classList.remove("closeopen");
      main.style.marginLeft = "0px";
      footerslide.style.marginLeft = "0px";
    }
  };
  const goToVideo = () => {
    clickStreamUser("video_menu_clicked", {
      userId: auth?.authBroker?.payload?.user.user_id,
    });
    // history.push("/video-categories");
    history.push('/my-videos')
  };

  const goToRolePlay = (type) => {
    if (type === "review-role-play") {
      clickStreamUser("review-role-play", {
        userId: auth?.authBroker?.payload?.user.user_id,
      });
      history.push("/review-role-play");
    } else if (type === "my-role-plays") {
      clickStreamUser("my-role-plays", {
        userId: auth?.authBroker?.payload?.user.user_id,
      });
      history.push("/my-role-plays");
    } else if (type == "role-play") {
      clickStreamUser("role-play", {
        userId: auth?.authBroker?.payload?.user.user_id,
      });
      history.push("/role-play");
    }
  };
  const goToAssesments = (type) => {
    if (type === "assesments") {
      clickStreamUser("assesments", {
        userId: auth?.authBroker?.payload?.user.user_id,
      });
      history.push("/assesments");
    }
  };
  // const isXselProvided = () => {
  //     return history.location.search.includes("?source=xsel");
  //     if (source === 'xsel' || source === 'ecom') {
  //         return true
  //     } else return false
  // }

  useEffect(() => {
    if (showHomePageMsg && homePageMsg) {
      window.scrollTo(0, 0)
      setConfirmMsgModal(true)
      setShowModalMsg(homePageMsg)
      setTimeout(() => {
        dispatch(homePageShowMsg(false))
        dispatch(homePageMsgClear(''))
        setConfirmMsgModal(false)
        setShowModalMsg('')
      }, 10000)
    }
  }, [showHomePageMsg, homePageMsg])


  const renderCard = () => {
    return (

      <MsgModal
        confirmModal={confirmMsgModal}
        setConfirmModal={setConfirmMsgModal}
        showModalMsg={showModalMsg}
        isConfirm={false}
        confirmId={""}
        confirmAction={function name(params) {
        }} />
    )
  }

  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
  }, [])

  const fetchActiveMenu = (pathname) => {
    if (pathname.includes('courseDetail') ||
      pathname.includes('courseListing') ||
      pathname.includes('courseModuleListing') ||
      pathname.includes('course-categories')) {
      return 'courses';
    } else if (pathname.includes('video-listing') ||
      pathname.includes('video-view-page') ||
      pathname.includes('video-categories')) {
      return 'videos';
    } else if (pathname.includes('role-play') ||
      pathname.includes('review-role-play') ||
      pathname.includes('my-role-plays') ||
      pathname.includes('review-roleplay') ||
      pathname.includes('to-review')) {
      return 'roleplay';
    }
    else if (pathname.includes('assesments')) {
      return 'assesments';
    }
    else if (pathname.includes('learning-paths')) {
      return 'learning-paths';
    }
    else if (pathname.includes('new-learningpath')) {
      return 'new-learningpath';
    }
    else if (pathname.includes('my-events')) {
      return 'my-events';
    }
    else if (pathname.includes('my-videos')) {
      return 'my-videos';
    }
  }

  return (
    <>
      <Loader start={isLoading} />
      <div className="h-4">
        <div className="header-borderThin position-fixed w-100"></div>
      </div>
      {showAllMenu ? (
        <div className="  d-flex mt24 mbtop">
          {/* <NavDropdown
              className="notification-nav"
              title={
                <svg
                  xmlnsXlink="http://www.w3.org/2000/svg"
                  width="13.163"
                  height="16.463"
                  viewBox="0 0 13.163 16.463"
                >
                  <g
                    id="Icon_ionic-ios-notifications"
                    data-name="Icon ionic-ios-notifications"
                    transform="translate(-6.761 -3.93)"
                  >
                    <path
                      id="Path_3370"
                      data-name="Path 3370"
                      d="M16.587,30.517a1.94,1.94,0,0,0,1.979-2.181H14.6A1.941,1.941,0,0,0,16.587,30.517Z"
                      transform="translate(-3.253 -10.123)"
                    />
                    <path
                      id="Path_3371"
                      data-name="Path 3371"
                      d="M19.757,16.123c-.634-.835-1.88-1.325-1.88-5.065,0-3.839-1.7-5.382-3.275-5.752-.148-.037-.255-.086-.255-.243V4.943a1,1,0,1,0-2.008,0v.119c0,.152-.107.206-.255.243-1.584.374-3.275,1.913-3.275,5.752,0,3.74-1.247,4.226-1.88,5.065a.817.817,0,0,0,.654,1.309H19.107A.817.817,0,0,0,19.757,16.123Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              }
            >
              <NavDropdown.Item className="get_CertifiedContainer dropdownItem_notiFcation ">
                <div className="getCertified">
                  <a href="#">
                    {" "}
                    <img
                      src="/images/get_certified01.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    />
                  </a>
                </div>
                <div className="getCertified_content">
                  <h6>Vivamus fringilla Mauris tristique</h6>
                  <p> Accepted your request 3 day ago</p>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item className="get_CertifiedContainer dropdownItem_notiFcation ">
                <div className="getCertified">
                  <a href="#">
                    {" "}
                    <img
                      src="/images/get_certified02.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    />
                  </a>
                </div>
                <div className="getCertified_content">
                  <h6>Quisque vitae commodo turpis.</h6>
                  <p> Accepted your request 3 day ago</p>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item className="get_CertifiedContainer dropdownItem_notiFcation ">
                <div className="getCertified">
                  <a href="#">
                    {" "}
                    <img
                      src="/images/get_certified03.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    />
                  </a>
                </div>
                <div className="getCertified_content">
                  <h6>Etiam laoreet, lectus ut euismod.</h6>
                  <p> Accepted your request 3 day ago</p>
                </div>
              </NavDropdown.Item>

              <div className="getCertified buutndf">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-block setup-button markAll_btn"
                  >
                    Mark all as read
                  </button>
                </a>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <button className="getNotFieedbutton">
                    Show unread only
                  </button>
                </a>
              </div>
            </NavDropdown> */}
          <div className="mob-cal nav-item navbar_custom">
            <a
              className="nav-link position-relative"
              role="button"
              data-bs-toggle="dropdown"
              href="#"
              title="Calendar"
              data-title="Calendar"
            >
              <svg
                xmlnsXlink="http://www.w3.org/2000/svg"
                width="15.109"
                height="17.267"
                viewBox="0 0 15.109 17.267"
                onClick={() => {
                  history.push("/calendar-events");
                  clickStreamUser("calendar-events-button-clicked", {
                    userId: authBroker?.payload?.user.user_id,
                  });
                }
                }
              >
                <path
                  id="Icon_awesome-calendar-alt"
                  data-name="Icon awesome-calendar-alt"
                  d="M0,15.648a1.619,1.619,0,0,0,1.619,1.619H13.49a1.619,1.619,0,0,0,1.619-1.619V6.475H0Zm10.792-6.61a.406.406,0,0,1,.4-.4h1.349a.406.406,0,0,1,.4.4v1.349a.406.406,0,0,1-.4.4H11.2a.406.406,0,0,1-.4-.4Zm0,4.317a.406.406,0,0,1,.4-.4h1.349a.406.406,0,0,1,.4.4V14.7a.406.406,0,0,1-.4.4H11.2a.406.406,0,0,1-.4-.4ZM6.475,9.038a.406.406,0,0,1,.4-.4H8.229a.406.406,0,0,1,.4.4v1.349a.406.406,0,0,1-.4.4H6.88a.406.406,0,0,1-.4-.4Zm0,4.317a.406.406,0,0,1,.4-.4H8.229a.406.406,0,0,1,.4.4V14.7a.406.406,0,0,1-.4.4H6.88a.406.406,0,0,1-.4-.4ZM2.158,9.038a.406.406,0,0,1,.4-.4H3.912a.406.406,0,0,1,.4.4v1.349a.406.406,0,0,1-.4.4H2.563a.406.406,0,0,1-.4-.4Zm0,4.317a.406.406,0,0,1,.4-.4H3.912a.406.406,0,0,1,.4.4V14.7a.406.406,0,0,1-.4.4H2.563a.406.406,0,0,1-.4-.4ZM13.49,2.158H11.871V.54a.541.541,0,0,0-.54-.54H10.252a.541.541,0,0,0-.54.54V2.158H5.4V.54A.541.541,0,0,0,4.856,0H3.777a.541.541,0,0,0-.54.54V2.158H1.619A1.619,1.619,0,0,0,0,3.777V5.4H15.109V3.777A1.619,1.619,0,0,0,13.49,2.158Z"
                />
              </svg>
              {userRole != "realtor" && sessionCount > 0 ? (
                <span className="session-badge">{sessionCount}</span>
              ) : (
                ""
              )}
            </a>
          </div>

          <form className="d-flex navbar_custom">
            <button
              className="btn searchBtn"
              type="button"
              onClick={() => {
                history.push("/search");
              }}
              title="Search"
              data-title="Search"
            >
              <svg
                xmlnsXlink="http://www.w3.org/2000/svg"
                width="19.163"
                height="19.085"
                viewBox="0 0 19.163 19.085"
              >
                <path
                  id="Icon_awesome-search"
                  data-name="Icon awesome-search"
                  d="M17.836,15.636l-3.521-3.521a.847.847,0,0,0-.6-.247h-.576a7.343,7.343,0,1,0-1.271,1.271v.576a.847.847,0,0,0,.247.6l3.521,3.521a.844.844,0,0,0,1.2,0l1-1A.852.852,0,0,0,17.836,15.636ZM7.346,11.867a4.521,4.521,0,1,1,4.521-4.521A4.518,4.518,0,0,1,7.346,11.867Z"
                  transform="translate(0.5 0.5)"
                  stroke="#fff"
                  strokeWidth="1"
                />
              </svg>
            </button>
          </form>
          <div className="nav-item dropdown streamlive livestrembtn  ">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
            >
              <LiveButtonComponent />

            </a>
          </div>

        </div>
      ) : (
        ""
      )}
      <div className=" content-logo mobileViewIcons">
        <div className="mobilenav">
          
          {history.location.pathname.includes("/courseDetail") ||
          history.location.pathname.includes("/learning-path-section") || 
          authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
            !showAllMenu ? (
            <div className="open pr-3">
              <button
                className="openbtn"
                id="openbtn"
                ref={hamburgerMenu}
                onClick={() => {
                  toggleNav();
                }}
              >
                <span className="closetoogle"></span>
              </button>
            </div>
          ) : (
            ""
          )}

          {showAllMenu ? (
            <>
              <div className="mobileViewIconsDetails ">
                <ul className="navbar-nav profile_thumbImg moDeviceAvtaar d-flex flex-row align-items-center">
                  <li className="nav-item dropdown navbar_custom">
                    <span
                      className="nav-link MoHamger"
                      onClick={() => {
                        if (!showMobMenu) {
                          setShowMobMenu(true);
                        } else {
                          setShowMobMenu(false);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <g
                          id="Group_5736"
                          data-name="Group 5736"
                          transform="translate(-18 -51)"
                        >
                          <circle
                            id="Ellipse_249"
                            data-name="Ellipse 249"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(18 51)"
                          ></circle>
                          <circle
                            id="Ellipse_254"
                            data-name="Ellipse 254"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(18 57)"
                          ></circle>
                          <circle
                            id="Ellipse_257"
                            data-name="Ellipse 257"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(18 63)"
                          ></circle>
                          <circle
                            id="Ellipse_250"
                            data-name="Ellipse 250"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(30 51)"
                          ></circle>
                          <circle
                            id="Ellipse_252"
                            data-name="Ellipse 252"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(30 57)"
                          ></circle>
                          <circle
                            id="Ellipse_255"
                            data-name="Ellipse 255"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(30 63)"
                          ></circle>
                          <circle
                            id="Ellipse_251"
                            data-name="Ellipse 251"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(24 51)"
                          ></circle>
                          <circle
                            id="Ellipse_253"
                            data-name="Ellipse 253"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(24 57)"
                          ></circle>
                          <circle
                            id="Ellipse_256"
                            data-name="Ellipse 256"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(24 63)"
                          ></circle>
                        </g>
                      </svg>
                    </span>
                    {showMobMenu ? (
                      <ul className="dropdown-menu menu-cont certy_cont M0Hamburger">
                        {/* Start:Courses */}
                        <li className={`nav-item navbar_custom 
                        ${activeMenu === 'new-learningpath' ? 'active' : ''}`}>
                          <a
                            className="nav-link"
                            onClick={() => {
                           
                              history.push('/new-learningpath');
                              clickStreamUser('learning_menu_clicked', {
                                  "categoryName": "Learning Paths",
                                  "userId": auth.authBroker?.payload?.user.user_id,
                              })
                            }}
                          >
                            Programs
                          </a>
                        </li>
                        
                        <li className={`nav-item navbar_custom 
                        ${activeMenu === 'my-videos' ? 'active' : ''}`}>
                          <a
                            className="nav-link"
                            onClick={() => goToVideo()}
                          >
                            Videos
                          </a>
                          {/* <a
                            className="nav-link"
                            onClick={() => {
                           
                              history.push('/my-videos');
                              clickStreamUser('learning_menu_clicked', {
                                  "categoryName": "Learning Paths",
                                  "userId": auth.authBroker?.payload?.user.user_id,
                              })
                            }}
                          >
                            Video
                          </a> */}
                        </li>
                        { 
                        authBroker?.payload?.user?.company_id === CONFIG.RPS_COMPANY_ID && 
                          userRole !== "instructor" && (
                            <li className={`nav-item navbar_custom 
                            ${activeMenu === 'assesments' ? 'active' : ''}`}>
                              <a
                                className="nav-link"
                                onClick={() => {
                                  clickStreamUser("assesments-clicked", {
                                    userId: auth.authBroker?.payload?.user.user_id,
                                  });
                                  history.push("/assesments");
                                }}
                              >
                                Assessments
                              </a>
                            </li>
                          )
                        }

                        {/* <NavDropdown title="Courses">
                          {
                            <>
                              <NavDropdown.Item
                                className="customHover"
                                onClick={() => {
                                  dispatch(getSelectedCatId(""));
                                  history.push(
                                    `/courseListing?selectedCategory=&selectCourse=top`
                                  );
                                }}
                              >
                                All
                              </NavDropdown.Item>
                              
                              <CategoryMenuProduct showMenu={showMenu} />
                              <CategoryMenuBroker showMenu={showMenu} />
                            </>
                          }
                        </NavDropdown> */}
                        {/* End: Courses */}

                        {/* Start: Video */}
                        {/* <NavDropdown title="Video Library"> */}
                        {/*<NavDropdown.Item
                            className="customHover"
                            href="news-articles"
                          >
                            News Articles
                          </NavDropdown.Item>*/}
                        {/* <NavDropdown.Item
                            className="customHover"
                            onClick={() => goToVideo()}
                          >
                            Videos
                          </NavDropdown.Item> */}
                        {/* </NavDropdown> */}
                        {/* End: Video */}

                        {/* Start:Coaching */}
                        {/* <NavDropdown title="Coaching" active={true}>
                              <NavDropdown.Item href="#action/3.1" className="dropdownItem_custom customHover"><div>
                                  <label className="menu-head">Request External Coach</label>
                                  <span className="menu-content">Phasellus quis fringilla lorem. Vivamus sem orci,<br /> fermentum id pharetra laoreet, efficitur porta dui.<br /> Nullam porta nisl diam, eu laoreet magna suscipit non</span>
                              </div></NavDropdown.Item>
                              <NavDropdown.Item href="#action/3.1" className="dropdownItem_custom customHover"><div>
                                  <label className="menu-head">Manager Connect</label>
                                  <span className="menu-content">Phasellus quis fringilla lorem. Vivamus sem orci,<br /> fermentum id pharetra laoreet, efficitur porta dui.<br />Nullam porta nisl diam, eu laoreet magna suscipit non.</span>
                              </div>
                              </NavDropdown.Item>
                            </NavDropdown> */}
                        {/* End: Coaching */}

                        {/* Start: RolePlay  */}
                        {/* <li className={`nav-item navbar_custom 
                        ${activeMenu === 'roleplay' ? 'active' : ''}`}>
                          <a
                            className="nav-link"
                            onClick={() => {
                              clickStreamUser("xsel-roleplay-menu-clicked", {
                                userId: auth.authBroker?.payload?.user.user_id,
                              });
                              goToRolePlay("my-role-plays")
                            }}
                          >
                            Role play
                          </a>
                        </li> */}

                        {/* <NavDropdown title="RolePlay">
                          <NavDropdown.Item
                            className="customHover"
                            onClick={() => goToRolePlay("role-play")}
                          >
                            Participate in a RolePlay
                          </NavDropdown.Item>
                          {otherRole.includes("coach") && (
                            <NavDropdown.Item
                              className="customHover"
                              onClick={() => goToRolePlay("review-role-play")}
                            >
                              Review a RolePlay
                            </NavDropdown.Item>
                          )}
                          <NavDropdown.Item
                            className="customHover"
                            onClick={() => goToRolePlay("my-role-plays")}
                          >
                            My RolePlays
                          </NavDropdown.Item>
                        </NavDropdown> */}
                        {/*  End: RolePl */}

                        {/* Start: Get Certified */}
                        {/* {
                          certifiedList.length > 0 ?
                            <NavDropdown title=" Certifications">
                              {certifiedList.map((cert, index) => {
                                return (
                                  <NavDropdown.Item
                                    onClick={() => goToCertifiedDetail(cert)}
                                    className="dropdownItem_getCertified customHover"
                                    key={`cert-${index + 1}`}
                                  >
                                    {cert.name}
                                  </NavDropdown.Item>
                                );
                              })}
                            </NavDropdown>
                            : ""
                        } */}
                        {/* End: Get Certified */}

                        {/* Start Events */}
                        {
                          authBroker?.payload?.user?.company_id === CONFIG.RPS_COMPANY_ID ? (
                            <li className={`nav-item item-underline navbar_custom
                        ${activeMenu === 'my-events' ? 'active' : ''}`}>
                            <a
                              className="nav-link"
                              onClick={() => {
                                clickStreamUser("xsel-events-menu-clicked", {
                                  userId: auth.authBroker?.payload?.user.user_id,
                                });
                                history.push('/my-events')
                              }}
                            >
                              Events
                            </a>
                          </li>
                          ): (
                            <li className="nav-item navbar_custom">
                            <a
                              className="nav-link"
                              onClick={() => {
                                clickStreamUser("xsel-events-menu-clicked", {
                                  userId: auth.authBroker?.payload?.user.user_id,
                                });
                                history.push("/events?source=xsel");
                              }}
                            >
                              Events
                            </a>
                          </li>
                          )
                        }
                       
                        
                      
                        {/* End Events */}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>

            </>
          ) : (
            ""
          )}
        </div>
        <div className="mobcenterlogo">
          <a className="navbar-brand" href="/home">
            <img
              id="niit_logo_mob"
              className="niit_moblogo"
              // src={logo ? logo : "/images/LOGO_head.png"}
              src="/images/rps-logo.png"
              width="70"
            />
          </a>
        </div>
        {showAllMenu ? (
          <div className=" d-flex mt24">
            {/* <NavDropdown
              className="notification-nav"
              title={
                <svg
                  xmlnsXlink="http://www.w3.org/2000/svg"
                  width="13.163"
                  height="16.463"
                  viewBox="0 0 13.163 16.463"
                >
                  <g
                    id="Icon_ionic-ios-notifications"
                    data-name="Icon ionic-ios-notifications"
                    transform="translate(-6.761 -3.93)"
                  >
                    <path
                      id="Path_3370"
                      data-name="Path 3370"
                      d="M16.587,30.517a1.94,1.94,0,0,0,1.979-2.181H14.6A1.941,1.941,0,0,0,16.587,30.517Z"
                      transform="translate(-3.253 -10.123)"
                    />
                    <path
                      id="Path_3371"
                      data-name="Path 3371"
                      d="M19.757,16.123c-.634-.835-1.88-1.325-1.88-5.065,0-3.839-1.7-5.382-3.275-5.752-.148-.037-.255-.086-.255-.243V4.943a1,1,0,1,0-2.008,0v.119c0,.152-.107.206-.255.243-1.584.374-3.275,1.913-3.275,5.752,0,3.74-1.247,4.226-1.88,5.065a.817.817,0,0,0,.654,1.309H19.107A.817.817,0,0,0,19.757,16.123Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              }
            >
              <NavDropdown.Item className="get_CertifiedContainer dropdownItem_notiFcation ">
                <div className="getCertified">
                  <a href="#">
                    {" "}
                    <img
                      src="/images/get_certified01.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    />
                  </a>
                </div>
                <div className="getCertified_content">
                  <h6>Vivamus fringilla Mauris tristique</h6>
                  <p> Accepted your request 3 day ago</p>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item className="get_CertifiedContainer dropdownItem_notiFcation ">
                <div className="getCertified">
                  <a href="#">
                    {" "}
                    <img
                      src="/images/get_certified02.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    />
                  </a>
                </div>
                <div className="getCertified_content">
                  <h6>Quisque vitae commodo turpis.</h6>
                  <p> Accepted your request 3 day ago</p>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item className="get_CertifiedContainer dropdownItem_notiFcation ">
                <div className="getCertified">
                  <a href="#">
                    {" "}
                    <img
                      src="/images/get_certified03.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    />
                  </a>
                </div>
                <div className="getCertified_content">
                  <h6>Etiam laoreet, lectus ut euismod.</h6>
                  <p> Accepted your request 3 day ago</p>
                </div>
              </NavDropdown.Item>

              <div className="getCertified buutndf">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-block setup-button markAll_btn"
                  >
                    Mark all as read
                  </button>
                </a>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <button className="getNotFieedbutton">
                    Show unread only
                  </button>
                </a>
              </div>
            </NavDropdown> */}
             {
              authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
              <ul className="mobnotify">
              <Notification />
            </ul>
             }
           

            <ul className={scroll ? "navbar-nav profile_thumbImg moDeviceAvtaar scrolledmob" : "navbar-nav profile_thumbImg moDeviceAvtaar"}>
              <li className="nav-item">
                <ProfileMenuSection signOut={signOut} />
                <span className="wlcmtxt" title={name}>Welcome <span className="nameusershow">{name}</span></span>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
      

      <nav className="navbar navbar-expand-lg bg-menu-lightWhite">
        <div className="position-fixed bg-menu-lightWhite w-100">
          <div className="container content-profile d-flex h-100 align-items-center justify-content-between">
            {history.location.pathname.includes("/courseDetail") ||
            history.location.pathname.includes("/learning-path-section") || 
              !showAllMenu ? (
                (
                  authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                  <div className="open pr-3">
                  <button
                    className="openbtn"
                    id="openbtnd"
                    ref={hamburgerMenu}
                    onClick={() => {
                      toggleNavDesktop();
                    }}
                  >
                    <span className="closetoogle closeopen"></span>
  
                  </button>
                </div>
                )
            
            ) : (
              ""
            )}
            {showAllMenu ? (
              <>
                <div
                  className=" content-logo"
                  onClick={() => {
                    history.push("/home");
                    window.scrollTo(0, 0);
                  }}
                >
                  <a className="navbar-brand cursor-pointer">
                    <img
                      className="niit_logo"
                      // src={logo ? logo : "/images/rps-logo.png"}
                      src="/images/rps-logo.png"

                    />
                  </a>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-between"
                  id="collapsibleNavbar"
                >
                  <ul
                    className={`navbar-nav menu-navbar-main ${userRole != "host" && userRole != "instructor"
                      ? "justify-content-around"
                      : "justify-content-end"
                      }`}
                    // style={{ flex: 1 }}
                  >
                    {userRole != "host" && userRole != "instructor" ? (
                      <>
                        <li className={`nav-item item-underline navbar_custom 
                        ${activeMenu === 'new-learningpath' ? 'active' : ''}`}>
                          <a
                            className="nav-link"
                            onClick={() => {
                              history.push('/new-learningpath');
                              clickStreamUser('learning_menu_clicked', {
                                  "categoryName": "Learning Paths",
                                  "userId": auth.authBroker?.payload?.user.user_id,
                              })
                            }}
                          >
                            Programs
                          </a>
                        </li>
                        <li className={`nav-item item-underline navbar_custom 
                        ${activeMenu === 'my-videos' ? 'active' : ''}`}>


                          <a
                            className="nav-link"
                            onClick={() => goToVideo()
                            }
                          >
                            Videos
                          </a>
                           {/* <a
                              className="nav-link"
                              onClick={() => {
                                clickStreamUser("xsel-events-menu-clicked", {
                                  userId: auth.authBroker?.payload?.user.user_id,
                                });
                                history.push("/my-videos");
                              }}
                            >
                            Video
                          </a> */}
                        </li>
                        {/* <NavDropdown title="Courses">
                          {
                            <>
                              <NavDropdown.Item
                                className="customHover"
                                onClick={() => {
                                  dispatch(getSelectedCatId(""));
                                  history.push(
                                    `/courseListing?selectedCategory=&selectCourse=top`
                                  );
                                }}
                              >
                                All
                              </NavDropdown.Item>
                              <CategoryMenuProduct showMenu={showMenu} />
                              <CategoryMenuBroker showMenu={showMenu} />
                            </>
                          }
                        </NavDropdown> */}

                        {/* <NavDropdown title="Video Library"> */}
                        {/*<NavDropdown.Item
                            className="customHover"
                            href="news-articles"
                          >
                            News Articles
                          </NavDropdown.Item>*/}
                        {/* <NavDropdown.Item
                            className="customHover"
                            onClick={() => goToVideo()}
                          >
                            Videos
                          </NavDropdown.Item>
                        </NavDropdown> */}

                        {/* <li className={`nav-item item-underline navbar_custom 
                        ${activeMenu === 'roleplay' ? 'active' : ''}`}>
                          <a
                            className="nav-link"
                            onClick={() => {
                              clickStreamUser("xsel-events-menu-clicked", {
                                userId: auth.authBroker?.payload?.user.user_id,
                              });
                              goToRolePlay("my-role-plays")
                            }}
                          >
                            Role play
                          </a>
                        </li> */}
                        {/* <NavDropdown title="RolePlay">
                          <NavDropdown.Item
                            className="customHover"
                            onClick={() => goToRolePlay("role-play")}
                          >
                            Participate in a RolePlay
                          </NavDropdown.Item>
                          {otherRole.includes("coach") && (
                            <NavDropdown.Item
                              className="customHover"
                              onClick={() => goToRolePlay("review-role-play")}
                            >
                              Review a RolePlay
                            </NavDropdown.Item>
                          )}
                          <NavDropdown.Item
                            className="customHover"
                            onClick={() => goToRolePlay("my-role-plays")}
                          >
                            My RolePlays
                          </NavDropdown.Item>
                        </NavDropdown> */}
                        {/* {certifiedList.length > 0 ?
                          <NavDropdown title=" Certifications">
                            {certifiedList.map((cert, index) => {
                              return (
                                <NavDropdown.Item
                                  onClick={() => goToCertifiedDetail(cert)}
                                  className="dropdownItem_getCertified customHover"
                                  key={`certlist-${index + 1}`}
                                >
                                  {cert.name}
                                </NavDropdown.Item>
                              );
                            })}
                          </NavDropdown> : ""
                        } */}
                        {
                          authBroker?.payload?.user?.company_id === CONFIG.RPS_COMPANY_ID ? (
                            <li className={`nav-item item-underline navbar_custom
                        ${activeMenu === 'my-events' ? 'active' : ''}`}>
                            <a
                              className="nav-link"
                              onClick={() => {
                                clickStreamUser("xsel-events-menu-clicked", {
                                  userId: auth.authBroker?.payload?.user.user_id,
                                });
                                history.push("/my-events");
                              }}
                            >
                              Events
                            </a>
                          </li>
                          ):(
                            <li className="nav-item item-underline navbar_custom">
                            <a
                              className="nav-link"
                              onClick={() => {
                                clickStreamUser("xsel-events-menu-clicked", {
                                  userId: auth.authBroker?.payload?.user.user_id,
                                });
                                history.push("/events?source=xsel");
                              }}
                            >
                              Events
                            </a>
                          </li>
                          )
                        }
                      
                        {
                           authBroker?.payload?.user?.company_id === CONFIG.RPS_COMPANY_ID && 
                          userRole != "instructor" && (
                            <li className={`nav-item item-underline navbar_custom 
                        ${activeMenu === 'assesments' ? 'active' : ''}`}>
                              <a
                                className="nav-link"
                                onClick={() => {
                                  clickStreamUser("assesments-menu-clicked", {
                                    userId: auth.authBroker?.payload?.user.user_id,
                                  });
                                  history.push("/assesments");
                                }}
                              >
                                Assessments
                              </a>
                            </li>
                          )
                        }

                      </>
                    ) : (
                      ""
                    )}
                   
                  </ul>
                  <ul
                    className={
                      userRole === "instructor" ||
                        userRole === "host" ||
                        userRole === "manager"
                        ? "navbar-nav profile_thumbImg host_inst_login"
                        : userRole === "realtor"
                          ? "navbar-nav profile_thumbImg realtor_manager_login"
                          : "navbar-nav profile_thumbImg"
                    }
                  >
                    
                    <>
                    {
                      authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                      <Notification />
                    }
                      <form className="d-flex navbar_custom">
                        {userRole === "instructor" || userRole === "host" ? (
                          ""
                        ) : (
                          <button
                            className="btn searchBtn"
                            type="button"
                            onClick={() => {
                              history.push("/search");
                              clickStreamUser("global-search-button-clicked", {
                                userId: authBroker?.payload?.user.user_id,
                              });
                            }}
                            title="Search"
                            data-title="Search"
                          >
                            {/* <svg
                              xmlnsXlink="http://www.w3.org/2000/svg"
                              width="19.163"
                              height="19.085"
                              viewBox="0 0 19.163 19.085"
                            >
                              <path
                                id="Icon_awesome-search"
                                data-name="Icon awesome-search"
                                d="M17.836,15.636l-3.521-3.521a.847.847,0,0,0-.6-.247h-.576a7.343,7.343,0,1,0-1.271,1.271v.576a.847.847,0,0,0,.247.6l3.521,3.521a.844.844,0,0,0,1.2,0l1-1A.852.852,0,0,0,17.836,15.636ZM7.346,11.867a4.521,4.521,0,1,1,4.521-4.521A4.518,4.518,0,0,1,7.346,11.867Z"
                                transform="translate(0.5 0.5)"
                                stroke="#fff"
                                strokeWidth="1"
                              />
                            </svg> */}
                            <svg width="30" height="30" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="22" cy="22.5" r="22" fill="#151515"></circle><path d="M20.1842 13.5C19.0598 13.5006 17.9512 13.7649 16.9475 14.2717C15.9439 14.7785 15.0731 15.5137 14.4053 16.4181C13.7374 17.3225 13.2911 18.371 13.1022 19.4792C12.9133 20.5874 12.987 21.7244 13.3175 22.799C13.6481 23.8735 14.2261 24.8555 15.0052 25.6661C15.7843 26.4767 16.7428 27.0932 17.8035 27.4661C18.8643 27.8391 19.9977 27.958 21.1128 27.8134C22.2279 27.6688 23.2935 27.2646 24.224 26.6334L28.6662 31.0711C28.7896 31.2035 28.9384 31.3097 29.1037 31.3833C29.269 31.457 29.4475 31.4966 29.6285 31.4998C29.8094 31.503 29.9892 31.4697 30.157 31.4019C30.3248 31.3342 30.4773 31.2333 30.6052 31.1053C30.7332 30.9774 30.8341 30.825 30.9019 30.6572C30.9697 30.4894 31.003 30.3097 30.9998 30.1287C30.9966 29.9478 30.957 29.7694 30.8833 29.6041C30.8097 29.4388 30.7034 29.29 30.571 29.1667L26.1324 24.7254C26.8667 23.6449 27.2926 22.3847 27.3641 21.0803C27.4357 19.7759 27.1503 18.4766 26.5387 17.3223C25.927 16.1679 25.0122 15.2021 23.8925 14.5286C22.7729 13.8551 21.4909 13.4995 20.1842 13.5ZM15.6917 20.6866C15.6917 19.4953 16.165 18.3529 17.0075 17.5105C17.85 16.6682 18.9927 16.195 20.1842 16.195C21.3757 16.195 22.5184 16.6682 23.361 17.5105C24.2035 18.3529 24.6768 19.4953 24.6768 20.6866C24.6768 21.8778 24.2035 23.0203 23.361 23.8626C22.5184 24.7049 21.3757 25.1782 20.1842 25.1782C18.9927 25.1782 17.85 24.7049 17.0075 23.8626C16.165 23.0203 15.6917 21.8778 15.6917 20.6866Z" fill="white"></path></svg>
                          </button>
                        )}
                      </form>
                      <li className="nav-item navbar_custom dropdown streamlive livestrembtn" style={{ marginTop: '2%' }}>
                        <a
                          className="nav-link dropdown-toggle d-flex gap-2 align-items-center"
                          role="button"
                          data-bs-toggle="dropdown"
                        >
                          <LiveButtonComponent />
                        </a>

                      </li>
                       {/* Start: Calendar */}
                     <li className="nav-item navbar_custom navbar_calendar">
                      <a
                        className="nav-link position-relative"
                        role="button"
                        data-bs-toggle="dropdown"
                        href="#"
                        title="Calendar"
                        data-title="Calendar"
                      >
                        <svg
                          xmlnsXlink="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 15.109 17.267"
                          onClick={() => {
                            history.push("/calendar-events");
                            clickStreamUser("calendar-events-button-clicked", {
                              userId: authBroker?.payload?.user.user_id,
                            });
                          }
                          }
                        >
                          <path
                            id="Icon_awesome-calendar-alt"
                            data-name="Icon awesome-calendar-alt"
                            d="M0,15.648a1.619,1.619,0,0,0,1.619,1.619H13.49a1.619,1.619,0,0,0,1.619-1.619V6.475H0Zm10.792-6.61a.406.406,0,0,1,.4-.4h1.349a.406.406,0,0,1,.4.4v1.349a.406.406,0,0,1-.4.4H11.2a.406.406,0,0,1-.4-.4Zm0,4.317a.406.406,0,0,1,.4-.4h1.349a.406.406,0,0,1,.4.4V14.7a.406.406,0,0,1-.4.4H11.2a.406.406,0,0,1-.4-.4ZM6.475,9.038a.406.406,0,0,1,.4-.4H8.229a.406.406,0,0,1,.4.4v1.349a.406.406,0,0,1-.4.4H6.88a.406.406,0,0,1-.4-.4Zm0,4.317a.406.406,0,0,1,.4-.4H8.229a.406.406,0,0,1,.4.4V14.7a.406.406,0,0,1-.4.4H6.88a.406.406,0,0,1-.4-.4ZM2.158,9.038a.406.406,0,0,1,.4-.4H3.912a.406.406,0,0,1,.4.4v1.349a.406.406,0,0,1-.4.4H2.563a.406.406,0,0,1-.4-.4Zm0,4.317a.406.406,0,0,1,.4-.4H3.912a.406.406,0,0,1,.4.4V14.7a.406.406,0,0,1-.4.4H2.563a.406.406,0,0,1-.4-.4ZM13.49,2.158H11.871V.54a.541.541,0,0,0-.54-.54H10.252a.541.541,0,0,0-.54.54V2.158H5.4V.54A.541.541,0,0,0,4.856,0H3.777a.541.541,0,0,0-.54.54V2.158H1.619A1.619,1.619,0,0,0,0,3.777V5.4H15.109V3.777A1.619,1.619,0,0,0,13.49,2.158Z"
                          />
                        </svg>
                        {userRole != "realtor" && sessionCount > 0 ? (
                          <span className="session-badge">{sessionCount}</span>
                        ) : (
                          ""
                        )}
                      </a>
                    </li>
                    </>
                    {/* {userRole != "host" && userRole != "instructor" ? (
                    ) : (
                      ""
                    )} */}
                    <li className="nav-item d-flex gap-1 align-items-center pf-desk">
                    <ProfileMenuSection signOut={signOut} />
                      <span className="wlcmtxt" title={name}>Welcome <span className="nameusershow">{name}</span></span>
                      
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
      {!(window.location.pathname.includes('/home')) && renderCard()}

    </>
  );
};

export default HeaderNewMenu;
