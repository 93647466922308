import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getLpManagers,
  getLpManagersClear,
} from "../../../redux/reports/actions";
import { SessionKeys } from "../../../utils/AppUtils";
import _ from "lodash";
import CONFIG from "../../../utils/config";
import { CircularProgressbar } from "react-circular-progressbar";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const ManagerReportSec = (props) => {
  const { mesg, section, isMobile } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const lpManagersRes = useSelector((state) => state.report?.lpManagers);
  const [counter, setCounter] = useState(1);
  const [overAllManagersData, setOverAllManagersData] = useState([]);
  const [totalManagers, setTotalManagers] = useState([])
  const rea_ids = [];
  const office_ids = [];
  const lp_ids = [];
  const [selectedOfficeList] = useState(
    history?.location?.state?.selectedOfficeList
  );
  const [selectedRea] = useState(
    history?.location?.state?.selectedTeamMatesList
  );
  const [selectedLpList] = useState(
    history?.location?.state?.selectedLpList
  );
  const [loadSpining, setLoadSpining] = useState(true)
  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }
  useEffect(() => {
    selectedOfficeList.selectedOfficeList.forEach(function (obj) {
      office_ids.push(obj.office_id);
    });
    selectedRea.selectedTeamMatesList.forEach(function (obj) {
      rea_ids.push(obj.user_id);
    });
    selectedLpList.selectedLpList.forEach(function (obj) {
      lp_ids.push(obj.id);
    });
  });
  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }
  //Manager section API
  useEffect(() => {
    if (section === "lp") {
      dispatch(getLpManagers({
        module: 'getlPReportManagers',
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          limit: counter,
          office_ids: office_ids,
          rea_ids: rea_ids,
          is_onboarding: 0,
          entity_id: lp_ids,
        }
      }))
    } else {
      dispatch(getLpManagers({
        module: 'getlPReportManagers',
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          limit: counter,
          office_ids: office_ids,
          rea_ids: rea_ids,
          is_onboarding: 1,
          entity_id: lp_ids,
        }
      }))
    }
  }, [dispatch, counter])
  useEffect(() => {
    setCounter(1)
    setOverAllManagersData([])
    return (() => {
      dispatch(getLpManagersClear())
    })
  }, [])
  //LP Managers
  useEffect(() => {
    if (!_.isEmpty(lpManagersRes)) {
      setLoadSpining(false)
      if (lpManagersRes?.status === "success") {
        let allData = (lpManagersRes?.payload?.payload?.managers);
        setTotalManagers(lpManagersRes?.payload?.payload)
        let temparr = _.cloneDeep(overAllManagersData)
        if (counter === 1) {
          temparr = []
        }
        setOverAllManagersData([...temparr, ...allData]);
        getLpManagersClear()
      }
    }
  }, [lpManagersRes])
  return (
    <section className="manager-section-report progress-box-report">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="progess-count-tiel">
              Managers &nbsp;<strong>({totalManagers?.total_managers})</strong>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {(!_.isEmpty(overAllManagersData)) ? overAllManagersData?.map((m, index) => {
            return (
              <div className="col-md-6 col-lg-4 col-xl-3 col-12 mb-report" key={index}>
                <div className="reopor-box-progess">
                  <div className="progess-profile d-flex">
                    <div className="user-progess-img">
                      <img src={m?.profile_pic} width="48" height="48" />
                    </div>
                    <div className="userprogress-details">
                      <h5 className="user-tiele">{m?.user_name}</h5>
                      <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <div
                                            style={{
                                                color: "white",
                                                backgroundColor: "black",
                                            }}
                                            
                                        >{m?.office_name}</div>
                                    </Tooltip>
                                }
                            >
                        <p >{m?.office_name}</p>
                        </OverlayTrigger>
                      {/* <p>{m?.office_name}</p> */}
                    </div>
                  </div>
                  <hr />
                  <div className="progress-details">
                    <div className="progess-report">
                      <div className="circlebox">
                        <CircularProgressbar value={m?.learning_percentage} />
                      </div>
                      <div className="progess-text-repfot">
                        {`${m?.learning_percentage}%`}
                        <div className="progespte">Progress</div>
                      </div>
                    </div>
                    <div className="progess-count-show">
                      <div className="learning-points">
                        <h5>Learning Points</h5>
                        <div className="learningc-countshow">
                          {m?.learning_points}
                        </div>
                      </div>
                      <div className="learning-points ">
                        <h5>Learning Hours</h5>
                        <div className="learningc-countshow">
                          {/* {(Math.floor(m?.learning_hrs)) > 0 ? `${Math.floor(m?.learning_hrs)}` : ""}
                          {Math.floor(m?.learning_hrs) > 1 ? "Hrs" : Math.floor(m?.learning_hrs) > 0 ? "Hr" : ""}{" "}

                          {(Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60)) > 0 ?
                            Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60) : ""}
                          {(Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60)) > 1 ? "Mins" : (Math.ceil((m?.learning_hrs - Math.floor(m?.learning_hrs)) * 60)) > 0 ? "Min" : m?.learning_hrs}
                         */}
                          {/* {m?.learning_hrs_val} */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: m?.learning_hrs_val,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }) :
            <div className="col-md-12">
              <div className="not-found mt-3">
                <div className="interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p> {mesg}</p>
                </div>
              </div>
            </div>
          }
          <div className="col-md-12 mt-3">
            <p className="text-center">
              {overAllManagersData?.length === 0 || (_.toNumber(totalManagers?.total_managers) === overAllManagersData?.length) ? "" : (_.toNumber(totalManagers?.total_managers) < 4) ? "" :
                <button
                  type="button"
                  id="number"
                  className="btn btn-primary-complete"
                  onClick={() => {
                    setCount(counter, setCounter);
                    setLoadSpining(true);
                  }
                  }
                >
                  {(loadSpining) ?
                    <div className="d-flex justify-content-center w-100">
                      <Spinner animation="border" />
                    </div>
                    : "Load More"}
                </button>
              }
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ManagerReportSec