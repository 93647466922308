import _ from "lodash";

const ProviderComponent = ({providerData}) => {

    return (
        <>
            {
                _.isEmpty(providerData) 
                ? <div></div>
                :
                    (providerData?.provider_img !== '' && 
                    providerData.provider_img !== null)
                    ? 
                        <img 
                            // src={providerData.provider_img} 
                            src = "/images/rps-logo.png"
                            alt="" 
                            title={providerData.provider_display_name}  
                        />
                    : 
                    <div className="d-flex carouseL_IconnBY">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path id="Exclusion_3" data-name="Exclusion 3" d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z" transform="translate(-21473.998 951.002)" fill="var(--color-element-1)" opacity="1"></path></svg>
                        <p> 
                            <span className="sub_txt_lern" 
                            title={providerData?.provider_display_name}>
                                {
                                    providerData?.provider_display_name.length > 13
                                    ? providerData?.provider_display_name?.substring(0, 13) + '...'
                                    : providerData?.provider_display_name
                                }
                            </span>
                        </p>
                    </div>
            }
        </>
    )
}

export default ProviderComponent