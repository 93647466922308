import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"

const RenderButtonForCalendar = (props) => {
    const {
        isActive,
        onClick,
        eventData,
        title,
        isLoaded
    } = props

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoaded && isLoading) {
            setIsLoading(false)
        }
    }, [isLoaded])

    const handleClick = () => {
        if (!isLoading && isActive && title !== 'View') {
            setIsLoading(true)
            onClick(eventData)
        }
    }


    return (
        <button
            id={title === "View" ? "popcal-" + eventData.sessionId + eventData.segmentId : ""}
            className={`btn-join ${isActive ? 'active' : ''}`}
            onClick={handleClick}>
            {isLoading ?
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                />
                : title}
        </button>
    )
}

export default RenderButtonForCalendar