import moment from "moment"
import { useState } from "react"
import { Popover, PopoverHeader, PopoverBody } from "reactstrap"

const PopoverCalendarSection = ({ events }) => {

  const [popover, setPopover] = useState(false)
  const [isHovering, setIsHovering] = useState(false);
  const [hovered, setHovered] = useState(false);
  console.log(events,"events-popup")
  const toggle = () => {
    setPopover(!popover)
  }
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <>
    {/* desktop_calendar_section */}
     {
      events?.delivery_mode === "face_to_face" ? (
        <>
        <div className="event_block">
        <span className="event_block_color">In Person</span>
        </div>
       
        </>
      ):events?.delivery_mode === "virtual_interactive" ? (
       <>
       <div className="event_block">
       <span className="event_block_color">VILT</span>
       </div>
      
       </>
      ):
      events?.delivery_mode === "virtual_broadcast" ? (
        <>
        <div className="event_block">
        <span className="event_block_color">VBT</span>
        </div>
       
        </>
       ):
      <>
      </>
     }
      <div className="event-tile-home desktop_calendar_section"
        // title={events.title}
        id={"popcal-" + events.sessionId + events.segmentId}
        // onMouseEnter={() => toggle()}
        // onMouseLeave={() => toggle()}
        onMouseOver={() => toggle()}
        onMouseOut={() => toggle()}
      >
        {events.title}

      </div>
      <div className="event-tile-home mobile_calendar_section"
        id={"popcal-" + events.sessionId + events.segmentId}
        
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {events.title}

      </div>
      <div className="popover_calendar_desktop">
      <Popover
        placement="right"
        className="popover-container"
        isOpen={popover}
        target={"popcal-" + events.sessionId + events.segmentId}
        // toggle={() => toggle()}
      >
        <PopoverHeader className='events-popup'>{events.title}</PopoverHeader>
        <PopoverBody className='events-popup_body'>
          <div className="d-flex gap-2 align-items-start mb-2">
            <div className="side-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="31.105" height="21.105" viewBox="0 0 11.105 11.105">
                <path id="clock-time-four" d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z" transform="translate(-2 -2)" fill="var(--color-element-1)" />
              </svg>
            </div>
            <span className="side-text">

              {moment(`${events.sessionStartDate}`).format('DD MMM YYYY') + ' at '
                + moment(`${events.sessionStartDate}`).format('hh:mm A') + ' ' + events.actualTimezoneAbbr}
            </span>

          </div>
          <div className="d-flex gap-2 align-items-start mb-2">
            <div className="side-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="31.105" height="21.105" viewBox="0 0 11.105 11.105">
                <path id="clock-time-four" d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z" transform="translate(-2 -2)" fill="var(--color-element-1)" />
              </svg>
            </div>
            <div className="side-text">

              <span>

                {moment(`${events.end}`).format('DD MMM YYYY') + ' at '
                  + moment(`${events.end}`).format('hh:mm A') + ' ' + events.actualTimezoneAbbr}
              </span>
            </div>

          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className="side-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="21.623" height="22.159" viewBox="0 0 8.623 12.159">
                <g id="Icon_feather-map-pin" data-name="Icon feather-map-pin" transform="translate(-4.5 -1.499)">
                  <path id="Exclusion_6" data-name="Exclusion 6" d="M15192.567,408.817h0c-.043-.045-4.309-4.523-4.309-7.844a4.312,4.312,0,1,1,8.623,0c0,3.317-4.27,7.8-4.312,7.844Zm0-9.6a1.752,1.752,0,1,0,1.75,1.754A1.757,1.757,0,0,0,15192.571,399.218Z" transform="translate(-15183.758 -395.16)" fill="var(--color-element-1)" />
                </g>
              </svg>
            </div>
            <span className="side-text text-capitalize">

              {events.delivery_mode === "face_to_face" ? events.location : events.tense === 'past' ? events.watch_url ? 'Recorded' : 'Live' : 'Live'}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
              {
                !(events.is_paid || events.is_registered) && !events.is_host && events.selling_price != 0 ?
                  <>
                    <span className="popover-price">${events.selling_price} CAD</span>
                  </>
                  : ""
              }
            </div>

          </div>
        </PopoverBody>
      </Popover>
      </div>
      <div
        className={`my-element ${hovered ? 'hovered' : ''}`}
      >
        <div className="pophover_calendar_mobile">
          {hovered
            ? <div className="calen_tooltipp_mobile">
              <div className='events-popup event_popup_mobile'>
                <p>{events.title}</p>
                </div>
            
              <div className="d-flex gap-2 align-items-start mb-2 side-image-mobile">
            <div className="side-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="31.105" height="21.105" viewBox="0 0 11.105 11.105">
                <path id="clock-time-four" d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z" transform="translate(-2 -2)" fill="var(--primary-brokarage-color)" />
              </svg>
            </div>
            <span className="side-text">

              {moment(`${events.sessionStartDate}`).format('DD MMM YYYY') + ' at '
                + moment(`${events.sessionStartDate}`).format('hh:mm A') + ' ' + events.actualTimezoneAbbr}
            </span>

          </div>
          <div className="d-flex gap-2 align-items-start mb-2">
            <div className="side-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="31.105" height="21.105" viewBox="0 0 11.105 11.105">
                <path id="clock-time-four" d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z" transform="translate(-2 -2)" fill="var(--primary-brokarage-color)" />
              </svg>
            </div>
            <div className="side-text">

              <span>

                {moment(`${events.sessionEndDate}`).format('DD MMM YYYY') + ' at '
                  + moment(`${events.sessionEndDate}`).format('hh:mm A') + ' ' + events.actualTimezoneAbbr}
              </span>
            </div>

          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className="side-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="21.623" height="22.159" viewBox="0 0 8.623 12.159">
                <g id="Icon_feather-map-pin" data-name="Icon feather-map-pin" transform="translate(-4.5 -1.499)">
                  <path id="Exclusion_6" data-name="Exclusion 6" d="M15192.567,408.817h0c-.043-.045-4.309-4.523-4.309-7.844a4.312,4.312,0,1,1,8.623,0c0,3.317-4.27,7.8-4.312,7.844Zm0-9.6a1.752,1.752,0,1,0,1.75,1.754A1.757,1.757,0,0,0,15192.571,399.218Z" transform="translate(-15183.758 -395.16)" fill="var(--color-element-1)" />
                </g>
              </svg>
            </div>
            <span className="side-text text-capitalize">

              {events.delivery_mode === "face_to_face" ? events.location : events.tense === 'past' ? events.watch_url ? 'Recorded' : 'Live' : 'Live'}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
              {
                !(events.is_paid || events.is_registered) && !events.is_host && events.selling_price != 0 ?
                  <>
                    <span className="popover-price">${events.selling_price} CAD</span>
                  </>
                  : ""
              }
            </div>

          </div>
            </div> : <></>
          }</div>
      </div>

    </>
  )
}

export default PopoverCalendarSection