import types from "./types";

// Product Detail Fetch
export const getProfileCompletionReport = (payload) => ({
  type: types.PROFILE_COMPLETION_REPORT,
  payload,
});
export const getProfileCompletionReportResponse = (payload) => ({
  type: types.PROFILE_COMPLETION_REPORT_RESPONSE,
  payload,
});
export const getProfileCompletionReportClear = () => ({
  type: types.PROFILE_COMPLETION_REPORT_RESPONSE_CLEAR,
});

//ONBOARDING SECTION REPORT
export const getOnboardingCompletionReport = (payload) => ({
  type: types.ONBOARDING_COMPLETION_REPORT,
  payload,
});
export const getOnboardingCompletionReportResponse = (payload) => ({
  type: types.ONBOARDING_COMPLETION_REPORT_RESPONSE,
  payload,
});
export const getOnboardingCompletionReportClear = () => ({
  type: types.ONBOARDING_COMPLETION_REPORT_RESPONSE_CLEAR,
});

export const getAnyTotalReport = (payload) => ({
  type: types.ANY_TOTAL_REPORT,
  payload,
});
export const getAnyTotalReportResponse = (payload) => ({
  type: types.ANY_TOTAL_REPORT_RESPONSE,
  payload,
});
export const getAnyTotalReportClear = () => ({
  type: types.ANY_TOTAL_REPORT_CLEAR,
});

export const getOfficeList = (payload) => ({
  type: types.GET_OFFICE_LIST,
  payload,
});
export const getOfficeListResponse = (payload) => ({
  type: types.GET_OFFICE_LIST_RESPONSE,
  payload,
});
export const getOfficeListClear = (payload) => ({
  type: types.GET_OFFICE_LIST_CLEAR,
  payload,
});

export const getOverAllSummary = (payload) => ({
  type: types.OVERALL_SUMMARY_PROGRESS,
  payload,
});
export const getOverAllSummaryResponse = (payload) => ({
  type: types.OVERALL_SUMMARY_PROGRESS_RESPONSE,
  payload,
});
export const getOverAllSummaryClear = (payload) => ({
  type: types.OVERALL_SUMMARY_PROGRESS_CLEAR,
  payload,
});

export const getOverAllData = (payload) => ({
  type: types.OVERALL_DATA,
  payload,
});
export const getOverAllDataResponse = (payload) => ({
  type: types.OVERALL_DATA_RESPONSE,
  payload,
});
export const getOverAllDataClear = (payload) => ({
  type: types.OVERALL_DATA_CLEAR,
  payload,
});
export const getOverAllGraph = (payload) => ({
  type: types.OVERALL_PROGRESS_GRAPH,
  payload,
});
export const getOverAllGraphResponse = (payload) => ({
  type: types.OVERALL_PROGRESS_GRAPH_RESPONSE,
  payload,
});
export const getOverAllGraphClear = (payload) => ({
  type: types.OVERALL_PROGRESS_GRAPH_CLEAR,
  payload,
});

//COURSE COMPLETION TREND
export const getCrsCompletionTrend = (payload) => ({
  type: types.COURSE_COMPLETION_TREND,
  payload,
});
export const getCrsCompletionTrendResponse = (payload) => ({
  type: types.COURSE_COMPLETION_TREND_RESPONSE,
  payload,
});
export const getCrsCompletionTrendClear = (payload) => ({
  type: types.COURSE_COMPLETION_TREND_CLEAR,
  payload,
});

// Compliance Report
export const getComplianceReport = (payload) => ({
  type: types.COMPLIANCE_REPORT,
  payload,
});
export const getComplianceReportResponse = (payload) => ({
  type: types.COMPLIANCE_REPORT_RESPONSE,
  payload,
});
export const getComplianceReportResponseClear = (payload) => ({
  type: types.COMPLIANCE_REPORT_RESPONSE_CLEAR,
  payload,
});

export const getComplianceCompletionTrend = (payload) => ({
  type: types.COMPLIANCE_COMPLETION_TREND,
  payload,
});
export const getComplianceCompletionTrendResponse = (payload) => ({
  type: types.COMPLIANCE_COMPLETION_TREND_RESPONSE,
  payload,
});
export const getComplianceCompletionTrendClear = (payload) => ({
  type: types.COMPLIANCE_COMPLETION_TREND_CLEAR,
  payload,
});

export const setCurrentFilterView = (payload) => ({
  type: types.CURRENT_FILTER_VIEW,
  payload,
});
//COE REA
export const getCoeRea = (payload) => ({
  type: types.COE_REA,
  payload,
});
export const getCoeReaResponse = (payload) => ({
  type: types.COE_REA_RESPONSE,
  payload,
});
export const getCoeReaResClear = (payload) => ({
  type: types.COE_REA_CLEAR,
  payload,
});
//COE Managers
export const getCoeManagers = (payload) => ({
  type: types.COE_MANAGER,
  payload,
});
export const getCoeManagersResponse = (payload) => ({
  type: types.COE_MANAGER_RESPONSE,
  payload,
});
export const getCoeManagersResClear = (payload) => ({
  type: types.COE_MANAGER_CLEAR,
  payload,
});
//COE Offices
export const getCoeOffices = (payload) => ({
  type: types.COE_OFFICES,
  payload,
});
export const getCoeOfficesResponse = (payload) => ({
  type: types.COE_OFFICES_RESPONSE,
  payload,
});
export const getCoeOfficesResClear = (payload) => ({
  type: types.COE_OFFICES_CLEAR,
  payload,
});
//coe trend curve
export const getCoeTrendCurve = (payload) => ({
  type: types.COE_TREND,
  payload,
});
export const getCoeTrendCurveResponse = (payload) => ({
  type: types.COE_TREND_RESPONSE,
  payload,
});
export const getCoeTrendCurveResClear = (payload) => ({
  type: types.COE_TREND_CLEAR,
  payload,
});

//LP TABLE
export const getLpTable = (payload) => ({
  type: types.LP_TABLE,
  payload,
});
export const getLpTableResponse = (payload) => ({
  type: types.LP_TABLE_RESPONSE,
  payload,
});
export const getLpTableClear = (payload) => ({
  type: types.LP_TABLE_CLEAR,
  payload,
});
//LP REA
export const getLpRea = (payload) => ({
  type: types.LP_REA,
  payload,
});
export const getLpReaResponse = (payload) => ({
  type: types.LP_REA_RESPONSE,
  payload,
});
export const getLpReaClear = (payload) => ({
  type: types.LP_REA_CLEAR,
  payload,
});
//LP Managers
export const getLpManagers = (payload) => ({
  type: types.LP_MANAGERS,
  payload,
});
export const getLpManagersResponse = (payload) => ({
  type: types.LP_MANAGERS_RESPONSE,
  payload,
});
export const getLpManagersClear = (payload) => ({
  type: types.LP_MANAGERS_CLEAR,
  payload,
});
//LP Offices
export const getLpOffices = (payload) => ({
  type: types.LP_OFFICES,
  payload,
});
export const getLpOfficesResponse = (payload) => ({
  type: types.LP_OFFICES_RESPONSE,
  payload,
});
export const getLpOfficesClear = (payload) => ({
  type: types.LP_OFFICES_CLEAR,
  payload,
});
//LP Trend
export const getLpTrend = (payload) => ({
  type: types.LP_TREND,
  payload,
});
export const getLpTrendResponse = (payload) => ({
  type: types.LP_TREND_RESPONSE,
  payload,
});
export const getLpTrendClear = (payload) => ({
  type: types.LP_TREND_CLEAR,
  payload,
});
//LP Stats
export const getLpStats = (payload) => ({
  type: types.LP_STATS,
  payload,
});
export const getLpStatsResponse = (payload) => ({
  type: types.LP_STATS_RESPONSE,
  payload,
});
export const getLpStatsClear = (payload) => ({
  type: types.LP_STATS_CLEAR,
  payload,
});
//ONBOARD REA
export const getOnboardRea = (payload) => ({
  type: types.ONBOARD_REPORT_REA,
  payload,
});
export const getOnboardReaResponse = (payload) => ({
  type: types.ONBOARD_REPORT_REA_RESPONSE,
  payload,
});
export const getOnboardReaClear = (payload) => ({
  type: types.ONBOARD_REPORT_REA_CLEAR,
  payload,
});
//Onboard Managers
export const getOnboardManagers = (payload) => ({
  type: types.ONBOARD_REPORT_MANAGERS,
  payload,
});
export const getOnboardManagersResponse = (payload) => ({
  type: types.ONBOARD_REPORT_MANAGERS_RESPONSE,
  payload,
});
export const getOnboardManagersClear = (payload) => ({
  type: types.ONBOARD_REPORT_MANAGERS_CLEAR,
  payload,
});
//Onboard Offices
export const getOnboardOffices = (payload) => ({
  type: types.ONBOARD_REPORT_OFFICES,
  payload,
});
export const getOnboardOfficesResponse = (payload) => ({
  type: types.ONBOARD_REPORT_OFFICES_RESPONSE,
  payload,
});
export const getOnboardOfficesClear = (payload) => ({
  type: types.ONBOARD_REPORT_OFFICES_CLEAR,
  payload,
});
//Onboard Completion Trend
export const getOnboardTrend = (payload) => ({
  type: types.ONBOARD_REPORT_TREND,
  payload,
});
export const getOnboardTrendResponse = (payload) => ({
  type: types.ONBOARD_REPORT_TREND_RESPONSE,
  payload,
});
export const getOnboardTrendClear = (payload) => ({
  type: types.ONBOARD_REPORT_TREND_CLEAR,
  payload,
});
//Onboard Completion Stats
export const getOnboardStats = (payload) => ({
  type: types.ONBOARD_REPORT_STATS,
  payload,
});
export const getOnboardStatsResponse = (payload) => ({
  type: types.ONBOARD_REPORT_STATS_RESPONSE,
  payload,
});
export const getOnboardStatsClear = (payload) => ({
  type: types.ONBOARD_REPORT_STATS_CLEAR,
  payload,
});
//Course Report REA
export const getCrsReportRea = (payload) => ({
  type: types.COURSE_REPORT_REA,
  payload,
});
export const getCrsReportReaResponse = (payload) => ({
  type: types.COURSE_REPORT_REA_RESPONSE,
  payload,
});
export const getCrsReportReaClear = (payload) => ({
  type: types.COURSE_REPORT_REA_CLEAR,
  payload,
});
//Course Report Managers
export const getCrsReportManagers = (payload) => ({
  type: types.COURSE_REPORT_MANAGERS,
  payload,
});
export const getCrsReportManagersResponse = (payload) => ({
  type: types.COURSE_REPORT_MANAGERS_RESPONSE,
  payload,
});
export const getCrsReportManagersClear = (payload) => ({
  type: types.COURSE_REPORT_MANAGERS_CLEAR,
  payload,
});
//Course Report Offices
export const getCrsReportOffices = (payload) => ({
  type: types.COURSE_REPORT_OFFICES,
  payload,
});
export const getCrsReportOfficesResponse = (payload) => ({
  type: types.COURSE_REPORT_OFFICES_RESPONSE,
  payload,
});
export const getCrsReportOfficesClear = (payload) => ({
  type: types.COURSE_REPORT_OFFICES_CLEAR,
  payload,
});
//Course Report Stats
export const getCrsReportStats = (payload) => ({
  type: types.COURSE_REPORT_STATS,
  payload,
});
export const getCrsReportStatsResponse = (payload) => ({
  type: types.COURSE_REPORT_STATS_RESPONSE,
  payload,
});
export const getCrsReportStatsClear = (payload) => ({
  type: types.COURSE_REPORT_STATS_CLEAR,
  payload,
});
//Course Report Trend curve
export const getCrsReportTrend = (payload) => ({
  type: types.COURSE_REPORT_COMP_TREND,
  payload,
});
export const getCrsReportTrendResponse = (payload) => ({
  type: types.COURSE_REPORT_COMP_TREND_RESPONSE,
  payload,
});
export const getCrsReportTrendClear = (payload) => ({
  type: types.COURSE_REPORT_COMP_TREND_CLEAR,
  payload,
});