const types = {
    // Order
    ORDER_HISTORY: "ORDER_HISTORY",
    ORDER_HISTORY_RESPONSE: "ORDER_HISTORY_RESPONSE",
    ORDER_HISTORY_CLEAR: "ORDER_HISTORY_CLEAR",

    // Toolbox apis
    // Create Folder
    CREATE_FOLDER: "CREATE_FOLDER",
    CREATE_FOLDER_RESPONSE: "CREATE_FOLDER_RESPONSE",
    CREATE_FOLDER_CLEAR: "CREATE_FOLDER_CLEAR",
    //CREATE FILE -TOOLBOX
    CREATE_FILE: "CREATE_FILE",
    CREATE_FILE_RESPONSE: "CREATE_FILE_RESPONSE",
    CREATE_FILE_CLEAR: "CREATE_FILE_CLEAR",
    //DELELTE FOLDER -TOOLBOX
    DELETE_FOLDER: "DELETE_FOLDER",
    DELETE_FOLDER_RESPONSE: "DELETE_FOLDER_RESPONSE",
    DELETE_FOLDER_CLEAR: "DELETE_FOLDER_CLEAR",
    //DELETE FILE -TOOLBOX
    DELETE_FILE: "DELETE_FILE",
    DELETE_FILE_RESPONSE: "DELETE_FILE_RESPONSE",
    DELETE_FILE_CLEAR: "DELETE_FILE_CLEAR",
    //FOLDER DETAIL -TOOLBOX
    FOLDER_DETAIL:"FOLDER_DETAIL",
    FOLDER_DETAIL_RESPONSE:"FOLDER_DETAIL_RESPONSE",
    FOLDER_DETAIL_CLEAR:"FOLDER_DETAIL_CLEAR",

    //TOOLBOX-SEARCH
    TOOLBOX_SEARCH:"TOOLBOX_SEARCH",
    TOOLBOX_SEARCH_RESPONSE:"TOOLBOX_SEARCH_RESPONSE",
    TOOLBOX_SEARCH_CLEAR:"TOOLBOX_SEARCH_CLEAR",

    //TOOLBOX-SEARCH
    TOOLBOX_UPDATE_TIME:"TOOLBOX_UPDATE_TIME",
    TOOLBOX_UPDATE_TIME_RESPONSE:"TOOLBOX_UPDATE_TIME_RESPONSE",
    TOOLBOX_UPDATE_TIME_CLEAR:"TOOLBOX_UPDATE_TIME_CLEAR",
};

export default types;