import React, { useEffect, useState } from 'react';
import Page from "../../components/Page";
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { getModuleListing, getModueleListingClear, getModuleListingBroker, getModuleListingClear, getModuleListingClearBroker } from "../../redux/courses/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ProgressBar from 'react-bootstrap/ProgressBar'
import moment from 'moment';
import FooterNew from '../../components/FooterNew';
import HeaderNewMenu from '../../components/HeaderNewMenu';
import ModuleSection from '../../components/Module/ModuleSection';
import CONFIG from '../../utils/config';
import { loginBrokerResponse } from '../../redux/auth/actions';
import { setUserRole } from '../../redux/user/actions';
import Loader from '../../components/Loader/Loader';
import $ from "jquery";

const CourseMobileDetail = () => {

    const history = useHistory()
    const { courseId } = useParams();
    const dispatch = useDispatch()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');
    const source = params.get('source');
    const user_id = params.get('user_id');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const role = params.get('role');

    const auth = useSelector(state => state.auth)
    const moduleListingData = useSelector(state => state.courses.moduleListing);
    const moduleListingDataBroker = useSelector(state => state.courses.moduleListingBroker);

    const [moduleList, setModuleList] = useState([])
    const [moduleData, setModuleData] = useState({})
    const [isValidKey, setIsValidKey] = useState(false)
    const [courseBrokerId, setCourseBrokerId] = useState('')
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
                document.getElementById("cc_div").style.display = "none";
        }, 100)
    }, [])

    useEffect(() => {
        // console.log(moduleListingData)
        if (source === 'xsel' || source === 'ecom') {
            if (!_.isEmpty(moduleListingData) &&
                moduleListingData.status === 'success') {
                setIsLoading(false);
                // console.log(moduleListingData.payload.payload)
                let modulesInfo = _.cloneDeep(moduleListingData.payload.payload)
                modulesInfo?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
                if (!_.isEmpty(modulesInfo)) {
                    if (modulesInfo.modules.length > 0) {
                        modulesInfo.modules[0].isLock = false;
                        for (let [index, modules] of modulesInfo.modules.entries()) {
                            modules.isLock = modules.isLock == false ? modules.isLock : true;
                            if (modules.progress > 0) {
                                modules.isLock = false;
                                if ((index + 1) !== modulesInfo.modules.length)
                                    modulesInfo.modules[index + 1].isLock = _.toNumber(modules.progress) === 100 ? false : true;
                            }
                        }
                        for (let modules of modulesInfo.modules) {
                            const hour = moment(modules.duration, 'hh:mm').hours();
                            const min = moment(modules.duration, 'hh:mm').minutes();
                            if (hour > 0) {
                                modules.durationTime = hour > 1 ? `${hour} hours` : `${hour} hour`;
                            }
                            if (min > 0) {
                                modules.durationTime = min > 1
                                    ? modules.durationTime ? `${modules.durationTime} ${min} minutes` : `${min} minutes`
                                    : modules.durationTime ? `${modules.durationTime} ${min} minute` : `${min} minute`;
                            }
                        }
                    }
                    setModuleData(modulesInfo);
                    // console.log("Modulelisting datas", modulesInfo, source);
                } else {
                    setModuleData({});
                }

            }
        } else {
            if (!_.isEmpty(moduleListingDataBroker) &&
                moduleListingDataBroker.status === 'success') {
                // console.log(moduleListingDataBroker.payload)
                setIsLoading(false);
                let modulesInfo = _.cloneDeep(moduleListingDataBroker.payload)
                modulesInfo?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
                if (!_.isEmpty(modulesInfo)) {

                    if (modulesInfo?.modules?.length > 0) {
                        setCourseBrokerId(modulesInfo.courseId)
                        modulesInfo.modules[0].isLock = false;
                        for (let [index, modules] of modulesInfo.modules.entries()) {
                            modules.isLock = modules.isLock == false ? modules.isLock : true;
                            if (modules.progress > 0) {
                                modules.isLock = false;
                                if ((index + 1) !== modulesInfo.modules.length)
                                    modulesInfo.modules[index + 1].isLock = _.toNumber(modules.progress) === 100 ? false : true;
                            }
                        }
                        for (let modules of modulesInfo.modules) {
                            const hour = moment(modules.time_hrs, 'hh').hours();
                            const min = moment(modules.time_min, 'mm').minutes();
                            if (hour > 0) {
                                modules.durationTime = hour > 1 ? `${hour} hours` : `${hour} hour`;
                            }
                            if (min > 0) {
                                modules.durationTime = min > 1
                                    ? modules.durationTime ? `${modules.durationTime} ${min} minutes` : `${min} minutes`
                                    : modules.durationTime ? `${modules.durationTime} ${min} minute` : `${min} minute`;
                            }
                        }
                        if (source === 'broker') {
                            // console.log('Modulelisting data change module')
                            for (let modules of modulesInfo.modules) {
                                modules.module_id = modules.id;
                            }
                        }
                    }

                    setModuleData(modulesInfo);
                    // console.log("Modulelisting data", modulesInfo);
                } else {
                    setModuleData({});
                }

            }
        }
    }, [moduleListingData, moduleListingDataBroker])

    useEffect(() => {
        clickStreamUser('module_detail_page', {
            courseId: courseId,
            userId: auth.authBroker?.payload?.user.user_id,
        })
    }, [courseId, source, dispatch, auth.authBroker])

    useEffect(() => {
        // console.log('token', token)
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
            // console.log('color', color_1, color_2)
            $('html').css("--color-element-1", color_1 ? '#' + color_1 : CONFIG.COLOR_ELEMENT_1);
            $('html').css("--color-element-2", color_2 ? '#' + color_2 : CONFIG.COLOR_ELEMENT_2);
            localStorage.setItem(SessionKeys.API_TOKEN_BROKER, token);
            localStorage.setItem(SessionKeys.USERNAME, username);
            setIsValidKey(true)
            dispatch(setUserRole(role))
            dispatch(loginBrokerResponse({
                payload: {
                    user: {
                        user_id: user_id,
                        username: username,
                        role: [role]
                    },
                    apiToken: token
                }
            }))
            if (courseId && (source === 'xsel' || source === 'ecom')) {
                if (!_.isEmpty(auth.authBroker)) {
                    setIsLoading(true)
                    dispatch(getModuleListingClear())
                    setModuleData({})
                    dispatch(getModuleListing({
                        module: 'getNIITAdminModule',
                        postData: {
                            user_id: user_id,
                            course_id: courseId
                        }
                    }))
                }

            } else {
                dispatch(getModuleListingClearBroker())
                setIsLoading(true)
                dispatch(getModuleListingBroker({
                    module: 'get_all_course_modules',
                    token: token,
                    courseId: courseId
                }))
            }
            clickStreamUser('module_detail_page_from_mobile', {
                courseId: courseId,
                userId: auth.authBroker?.payload?.user.user_id,
            })
        }
    }, [token, key, user_id, dispatch])

    const navigateToDetails = (title, progress, m) => {
        if (!m.isLock) {
            // console.log("Module", m);
            let courseID = source === 'broker' ? courseBrokerId : courseId;
            clickStreamUser('module_click_goto', {
                moduleId: m.module_id,
                moduleName: m.title,
                courseName: moduleData.courseName,
                courseId: courseID,
                userId: auth?.authBroker?.payload.user.user_id
            })
            history.push({
                pathname: `/course/module/launch/${courseId}?source=${source}`,
                // pathname: `moduleDetail/${courseId}/courseDetail`,
                state: { courseId: courseID, courseCode: courseId, courseTitle: title, courseProgress: progress, mId: m.module_id, module: m }
            })
        }
    }

    return (
        <>
            {isValidKey ?
                <>
                    <Loader start={isLoading} />
                    <ModuleSection
                        navigateToDetails={navigateToDetails}
                        moduleListData={moduleData}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isMobile={true}
                    />
                    {/*<FooterNew />*/}
                </>
                :
                <div>
                    Invalid Key
                </div>
            }
        </>

    )
}
export default CourseMobileDetail;