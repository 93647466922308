import { useEffect } from "react";

const ProductCheckoutSection = (props) => {

    const {
        checkoutData,
        productPrice,
        policy
    } = props;

    useEffect(() => {
        // console.log('checkout', checkoutData)
        // console.log('productPrice', productPrice)
    })

    return (
        <>
        <label className="back-dark-heading right-sec-product mt-0">
                    <img src="/images/product_cart.jpg" width="40" height="40" alt="" />
                    <p className="dollor">$ {productPrice}</p>
                    </label>
                   <div className="table_summary">
                    <table >
                        <thead className="thead_bdy_product">
                          <tr>
                            <th scope="col">Summary</th>
                 
                          </tr>
                        </thead>
                        <tbody className="tble_bdy_product">
                          <tr>
                          
                            <td>Items........................................................................................................</td>
                       
                            <td> $ {checkoutData.itemPrice}</td>
                          
                          </tr>
                          <tr>
                         
                            <td>Taxes.........................................................................................................</td>
                       
                            <td> $ {checkoutData.taxes}</td>
                            
                          </tr>
                          <tr className="thead_bdy_product">
                            <td scope="col">Order Total</td>
                            <td> $ {checkoutData.totalPrice}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                        <label className="back-dark-product color_wht"><span>Check Out $ {checkoutData.totalPrice}</span></label>
                        
                        <label className="back-dark-product color_wht">Upgrade account for  $ &nbsp; 
                        <span className="fw-bold">  15.00/month&nbsp;<span className="billed">billed annually</span></span></label>
                        <div className="policy_txt">
                        <label className="lable-heading">Policy</label>
                        <p>
                          {policy}
                        </p>
      
                    </div>
        </>
    )
}

export default ProductCheckoutSection;