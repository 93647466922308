import Page from "../components/Page";
import SearchSection from "../components/Search/SearchSection";

const SearchPage = () => {
    return (
        <Page>
            <SearchSection />
        </Page>
    );
}

export default SearchPage;