import { useEffect, useState } from "react"

const ShimmerScreen = (props)  => {

    const [myStyle, setMyStyle] = useState({width: '', height: ''})

    useEffect(() => {
        setMyStyle({
            width: props.width,
            height: props.height
        })
    }, [props])

    const renderRectangle = () => {
        return (
            <div className="skeleton-len" 
            style={{width: props.width, height: props.height}}>
            </div>
        )
    }

    const renderCircle = () => {
        return (
            <div className="skeleton-len" 
            style={{width: props.width, height: props.height, borderRadius: '50%'}}>
            </div>
        )
    }

    const renderSwitch = () => {
        switch (props.case) {
            case 'rect': 
                return (<>{renderRectangle()}</>)
            case 'circle':
                return (<>{renderCircle()}</>)
        }
    }


    return (
        <>{renderSwitch()}</>
    )
}

export default ShimmerScreen;