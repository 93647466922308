import _ from "lodash";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getProfileCompletionReportClear } from "../../../redux/reports/actions";
import NextArrow from "../../LearningPaths/NextArrow";
import PrevArrow from "../../LearningPaths/PrevArrow";
import Pagination from "../../../pages/Orders/Pagination";
import exportFromJSON from 'export-from-json'
import * as XLSX from 'xlsx';
import StaticUtils from "../../../utils/StaticUtils";
import InvoiceShowModal from "../../modal/InvoiceShowModal";
import moment from "moment";
import BarChart from "../../Layouts/Graphs/BarChart";
import ReportGraphSection from "../ReportGraphSection";

const fileName = 'PurchaseReport'
const exportType = 'xls'
let PageSize = 20;

const PurchaseTableSeaction = (props) => {

  const {
    setSearchInput,
    getFilteredData,
    isLoading,
    setIsLoading,
    dateRange,
    isView,
    setCanShow,
    type
  } = props;

  const dispatch = useDispatch();
  const profileReportRes = useSelector(state => state.report.profileReport);
  const [profileReports, setProfileReports] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false)
  const [series, setSeries] = useState([])
  const [legends, setLegends] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true)
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }

  }, [currentPage, profileReports]);

  useEffect(() => {
    if (isLoading) {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth'
      });
      setSeries([])
      setLegends([])
    }
  }, [isLoading])

  useEffect(() => {
    // console.log('profileReportRes: ', profileReportRes)
    if (!_.isEmpty(profileReportRes)) {
      setIsLoading(false)
      if (profileReportRes?.status === "success") {
        const arr = profileReportRes?.payload?.payload
        setSeries([])
        setLegends([])
        setProfileReports(arr)
        let result = {}
        if (!_.isEmpty(dateRange)) {
          let start = moment(dateRange.start, 'MM/DD/YYYY')
          let end = moment(dateRange.end, 'MM/DD/YYYY')
          let diff = start.diff(end, 'hours')
          // console.log('DIFF', diff)
          if (diff >= 719) {
            result = _(arr)
              .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('MMMM'))
              .mapValues(v => _.map(v, 'amount'))
              .value();
          } else if (diff >= 167) {
            result = _(arr)
              .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('MMMM'))
              .mapValues(v => _.map(v, 'amount'))
              .value();
          } else {
            // console.log('RESULT in DIFF')
            result = _(arr)
              .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('DD MMM'))
              .mapValues(v => _.map(v, 'amount'))
              .value();
          }
          // result = _(arr)
          // .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('DD MMM'))
          // .mapValues(v => _.map(v, 'amount'))
          // .value();
        } else {
          result = _(arr)
            .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('MMMM'))
            .mapValues(v => _.map(v, 'amount'))
            .value();
        }
        // console.log('RESULT', result)
        let series = []
        let legends = []
        Object.keys(result).forEach(data => {
          legends.push(data)
          let total = _.sum(result[data]).toFixed(0)
          series.push(_.toNumber(total))
        })
        // console.log(series)
        // console.log(legends)
        setSeries(series)
        setLegends(legends)
        setCurrentPage(1)
      } else {
        setProfileReports([])
        setCanShow(false)
      }
      dispatch(getProfileCompletionReportClear())
    }
  }, [profileReportRes])

  const ExportToExcel = () => {
    const data = _.cloneDeep(profileReports);

    for (let item of data) {
      let orderDetails = item?.order_details;
      item.billed_to = `${orderDetails?.billing_first_name} ${orderDetails?.billing_last_name} ${orderDetails?.billing_address_1} ${orderDetails?.billing_address_2 ? orderDetails?.billing_address_2 + "," : ""} ${orderDetails?.billing_city ? orderDetails?.billing_city + "," : ""} ${orderDetails?.billing_state}`;
      item.invoice_number = `#XSEL_${orderDetails?.order_number}`
      item.invoice_total = `${orderDetails?.total} CAD`
      item.invoice_date = moment(orderDetails?.order_paid?.date).format('Do MMM YYYY')
      item.date = item?.date !== undefined && item?.date !== '' ? StaticUtils.getDateFromTimeStamp(item?.date) : "-"
    }

    const newThing = [];
    data.map(item => {
      newThing.push(
        _.mapKeys(item, (value, key) => {
          switch (key) {
            case "name":
              return "Name";
            case "user_email":
              return "Email";
            case "office_name":
              return "Office Name";
            case "date":
              return "Date of Purchase";
            case "date":
              return "Date of Purchase";
            case "order_id":
              return "Order Id";
            case "amount":
              return "Amount"
            case "billed_to":
              return "Billed To"
            case "invoice_number":
              return "Invoice Number"
            case "invoice_total":
              return "Invoice Total"
            case "invoice_date":
              return "Invoice Date"
            default:
              return;
          }
        })
      )
    });

    for (let i = 0; i < newThing.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete newThing[i].undefined;
    }



    for (let i = 0; i < data.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete data[i]._id;
    }

    // exportFromJSON({ data, fileName, exportType })

    const worksheet = XLSX.utils.json_to_sheet(newThing);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName + ".xlsx");
  }

  const renderTable = () => {
    return (
      <div className="row list-coach report-table-rsp">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive1">

            <div className="tablebox">
              <table className="table mb-0 office_list table-striped">
                <thead>
                  <tr>
                    <th className="office-province">
                      Name
                    </th>
                    <th className="office-province">
                      Email
                    </th>
                    <th className="office-province">
                      Office Name
                    </th>
                    <th className="office-province">
                      Date of Purchase
                    </th>
                    <th className="office-province">
                      Order ID
                    </th>
                    <th className="office-province">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {currentTableData?.map((item, index) => {
                    return <tr className="addBGcustomw">
                      <td>
                        {item?.name}
                      </td>
                      <td>
                        {item?.user_email}
                      </td>
                      <td>
                        {item?.office_name}
                      </td>
                      <td>{item?.date !== undefined && item?.date !== '' ? StaticUtils.getDateFromTimeStamp(item?.date) : "-"}</td>
                      <td className="cursor-pointer"
                        onClick={() => { setConfirmModal(true) }}><strong>{item?.order_id !== undefined && item?.order_id !== '' ? item?.order_id : "-"}</strong></td>
                      <InvoiceShowModal
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        order={item?.order_details} />
                      <td>{item?.amount !== undefined && item?.amount !== '' ? '$' + item?.amount + ' CAD' : "-"}</td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </div>


            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={profileReports.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                window.scroll({
                  top: 100,
                  left: 0,
                  behavior: 'smooth'
                }); setCurrentPage(page)
              }}
            />
          </div>

        </div>
      </div>
    )
  }

  const renderGraph = () => {
    return (
      <>
        <ReportGraphSection
          series={series}
          legends={legends}
          isLoading={isLoading}
          type={type}
          isAmount={true}
        />
      </>
    )
  }

  return (
    <>
      <section className="table-report-layout">
        {true ? <div className="container">
          <div className="row">


            <div className="col-md-5 search__input">
              {/* <div className="input-lear position-relative d-flex">
                <label for="password" className="input__label_learning">Search</label>
                <input type="text" id="password" name="password" className="input_searchfield_search-cart " placeholder=" "
                  onChange={(evt) => { setSearchInput(evt.target.value) }} />
                <a>
                  <button className="button-reset" onClick={() => {
                    getFilteredData(1)
                  }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#00b7f1" />
                          <stop offset="1" stop-color="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_191"
                        data-name="Ellipse 191"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_192"
                        data-name="Ellipse 192"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="Icon_awesome-search"
                        data-name="Icon awesome-search"
                        d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                        transform="translate(7.715 7.714)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                </a>
              </div> */}
            </div>
            <div className="col-7">
              {currentTableData && currentTableData?.length > 0 && isView === 'table' ?
                <button className="button-reset downbtnsreport" onClick={() => {
                  if (window.callbackHandler != undefined) {
                    window.callbackHandler.postMessage("downbtnsreport");
                  } else if (window.webkit != undefined) {
                    if (window.webkit.messageHandlers.callbackHandler != undefined) {
                      window.webkit.messageHandlers.callbackHandler.postMessage("downbtnsreport");
                    }
                  }

                  ExportToExcel()
                }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    id="Find"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <defs>
                      <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#00b7f1" />
                        <stop offset="1" stop-color="#ed156e" />
                      </linearGradient>
                    </defs>
                    <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                    <path id="file-chart" d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z" transform="translate(5.342 5.927)" fill="var(--color-element-1)" />
                  </svg>
                </button> : ''}
            </div>
          </div>
          {currentTableData && currentTableData?.length > 0 ?
            isView === 'table' ? renderTable() : renderGraph()
            : profileReports !== null ? <div className="not-found mt-3">
              <div className=" interboxes">
                <img src="/images/telescope.svg" alt="Not Found" />
                <p>Sorry! No result found for your search.</p>
              </div>
            </div> : ""}
        </div> : ""}
      </section>
    </>
  )
}

export default PurchaseTableSeaction