import types from './types';

export const getOrderHistory = (payload) => ({ type: types.ORDER_HISTORY, payload });
export const getOrderHistoryResponse = (payload) => ({ type: types.ORDER_HISTORY_RESPONSE, payload });
export const getOrderHistoryClear = () => ({ type: types.ORDER_HISTORY_CLEAR });

export const createFoldrReq = (payload) => ({ type: types.CREATE_FOLDER, payload });
export const createFoldrResponse = (payload) => ({ type: types.CREATE_FOLDER_RESPONSE, payload });
export const createFolderResponseClear = () => ({ type: types.CREATE_FOLDER_CLEAR });

// CREATE FILE-TOOLBOX
export const createFileReq = (payload) => ({ type: types.CREATE_FILE, payload });
export const createFileResponse = (payload) => ({ type: types.CREATE_FILE_RESPONSE, payload });
export const createFileResponseClear = () => ({ type: types.CREATE_FILE_CLEAR });
//DELETE FOLDER-TOOLBOX
export const deleteFolderReq = (payload) => ({ type: types.DELETE_FOLDER, payload });
export const deleteFolderReqResponse = (payload) => ({ type: types.DELETE_FOLDER_RESPONSE, payload });
export const deleteFolderReqResClear = () => ({ type: types.DELETE_FOLDER_CLEAR });
//DELETE FILE -TOOLBOX
export const deleteFile = (payload) => ({ type: types.DELETE_FILE, payload });
export const deleteFileResponse = (payload) => ({ type: types.DELETE_FILE_RESPONSE, payload });
export const deleteFileClear = () => ({ type: types.DELETE_FILE_CLEAR });
//DETAIL  -TOOLBOX
export const detailFolder = (payload) => ({ type: types.FOLDER_DETAIL, payload });
export const detailFolderResponse = (payload) => ({ type: types.FOLDER_DETAIL_RESPONSE, payload });
export const detailFolderClear = () => ({ type: types.FOLDER_DETAIL_CLEAR });

//DETAIL  -TOOLBOX-SEARCH
export const toolboxSearchRequest = (payload) => ({ type: types.TOOLBOX_SEARCH, payload });
export const toolboxSearchResponse = (payload) => ({ type: types.TOOLBOX_SEARCH_RESPONSE, payload });
export const toolboxSearchClear = () => ({ type: types.TOOLBOX_SEARCH_CLEAR });

//DETAIL  -TOOLBOX-SEARCH
export const toolboxUpdateTimeRequest = (payload) => ({ type: types.TOOLBOX_UPDATE_TIME, payload });
export const toolboxUpdateTimeResponse = (payload) => ({ type: types.TOOLBOX_UPDATE_TIME_RESPONSE, payload });
export const toolboxUpdateTimeClear = () => ({ type: types.TOOLBOX_UPDATE_TIME_CLEAR });