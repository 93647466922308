import types from "./types";

const initialState = {
  certificateData: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  // console.log("Reducer certificate new one", action.payload, action.type);
  switch (action.type) {
    case types.CERTIFICATE_DATA_RESPONSE:
      return {
        ...state,
        certificateData: action.payload,
      };
    case types.CERTIFICATE_DATA_CLEAR:
      return {
        ...state,
        certificateData: {},
      };

    default:
      return state;
  }
};
