import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { getAssignBrokerCourse, getAssignCourse, getAssignCourseClear, getSelectedCatId, showBuyModal } from '../../redux/courses/actions';
import BuyProductModal from '../modal/BuyProductModal';
import ConfirmModal from '../modal/ConfirmModal';
import Slider from "react-slick/";
import Carousel from 'react-bootstrap/Carousel'
import { ProgressBar } from 'react-bootstrap';
import { clickStreamUser, SessionKeys } from '../../utils/AppUtils';
import PrevArrow from './PrevArrow';
import NextArrow from './NextArrow';
import _ from "lodash";
import ImageComponent from '../Layouts/ImageComponent';
import ProviderComponent from '../Layouts/ProviderComponent';
// import { learningPathDetailResponse } from '../../redux/product/actions';

const SliderMyLearningPathListing = (props) => {

  const {
    learningPathList,
    pageType,
    setFavoriteCourse,
    closeModal,
    setCloseModal,
    hasDescription,
    showBlankCard,
    isOnboarding,
  } = props

  const dispatch = useDispatch();
  const history = useHistory();

  // const [learningPathData, setCourseData] = useState({})
  const auth = useSelector(state => state.auth)
  const authBroker = useSelector(state => state.auth.authBroker)

  const redirectToPage = (learningId) => {
    // history.push('/learning-paths?id=' + learningId);
    history.push('/learning-path-section?id=' + learningId)
  }

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: false,
    dots: true,
    arrows: true,
    autoplaySpeed: 2000,
    infinite: false,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3

        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="slider-learningPath-listing">
      <Slider {...settings}>
        {learningPathList?.length === 0 ? "No learningPaths found" :
          learningPathList?.map((learningPath, i) => {
            if (learningPath.prgm_nm && i < 3) {
              return (
                <div key={'learningPath-' + i} className="boxslidersp">
                  <div className="broker-box">
                    <div className="img-box_nw">
                      <div className={`img-videosec learn_image-card ${learningPath.isLock ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={() => {
                          redirectToPage(learningPath.id);
                        }}>

                        {/* <img className={`img-fluid galleryImg_lern ${learningPath.isLock ? 'grayscale' : ''}`} src={learningPath.thumbnail}  onError={({currentTarget}) => {
                            currentTarget.onerror = true;
                            currentTarget.src = "/images/default-demo-img.jpg"
                        }}/> */}
                        <ImageComponent
                          name={learningPath.prgm_nm}
                          thumbnail={learningPath.thumbnail ? learningPath.thumbnail : ''}
                          cssClass={`img-fluid galleryImg_lern ${learningPath.isLock ? "grayscale" : ""
                            }`}
                          errorClass={`img-fluid galleryImg_lern ${learningPath.isLock ? "grayscale" : ""
                            }`}
                        />
                        {learningPath.crs_progress !== undefined && learningPath.crs_progress != null && learningPath.crs_progress > 0 ? <a href="#">
                          <span data-bs-toggle="modal" data-bs-target="#premium-contentpopu">
                            <span className="lear_icon"><img src={learningPath.crs_progress == 100 ? "/images/check.png" : "/images/forward.png"} /></span></span></a> : ""}
                        {learningPath.isLock ? <span className="lear_icon"><img src="/images/Layer_1.png" alt="lock" /></span> : ''}
                        <ProgressBar
                          className="custom_progress_barr custom_bartwoRealtor"
                          variant="success"
                          now={learningPath.prgm_progress ? learningPath.prgm_progress : 0}
                        />
                      </div>


                      <div className="details-card_nw">

                        <h5 className={`${learningPath.isLock ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                          onClick={() => {
                            redirectToPage(learningPath.id);
                          }
                          }>
                          <a className="course-head-al_search" title={learningPath.prgm_nm}
                            data-toggle="tooltip" data-html="true"
                            dangerouslySetInnerHTML={{
                              __html: learningPath.prgm_nm.length < 33 ? learningPath.prgm_nm : learningPath.prgm_nm.substring(0, 33) + '...',
                            }}>
                          </a>
                        </h5>


                        <div className="row info-bottm-card_nw">
                          <div className="col-md-12 cou_cardIcon">
                            <div className="fgf"><p><span>{learningPath.course_count} </span>{learningPath.course_count == 1 ? 'Course' : 'Courses'}</p></div>
                            {
                                   ((learningPath?.program_hrs != null 
                                    || learningPath?.program_mins != null) && (learningPath?.program_hrs > 0 
                                      || learningPath?.program_mins > 0))  && (
                            
                            <div className="box-codd">
                              <div className="carouseL_Iconn">
                                <div className="ico__imG">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="clock-time-four" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z" transform="translate(-2 -2)" fill="var(--color-element-1)" />
                                  </svg>
                                </div>
                                {
                                  (learningPath?.program_hrs != null 
                                    || learningPath?.program_mins != null)  && (
                                    <div className="ico_texxt">
                                      <p>
                                        {" "}
                                        {/* {course.views > 1 ? "s" : ""} */}
                                        
                                        <span>
                                          {learningPath?.program_hrs > 0
                                            ? learningPath?.program_hrs + " " + (learningPath?.program_hrs > 1 ? "Hours" : "Hour")
                                            : " "}{" "}
                                          {learningPath?.program_mins > 0
                                            ? learningPath?.program_mins +
                                            " " +
                                            (learningPath?.program_mins > 1 ? "Mins" : "Min")
                                            : " "}{" "}
                                          {/* {learningPath?.seconds > 0
                                            ? learningPath?.seconds +
                                            " " +
                                            (learningPath?.seconds > 1 ? "Secs" : "Sec")
                                            : " "}{" "} */}
                                        </span>
                                        {/* <span>{course?.duration1 || "minutes"}</span> */}
                                      </p>
                                      {/* <p> <span>{learningPath.duration}</span> {learningPath.durationUnit}</p> */}
                                    </div>
                                  )
                                }

                              </div>
                              {/* <div className="carouseL_Iconn">
                                <div className="ico__imG">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="eye-circle" d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z" transform="translate(-2 -2)" fill="var(--color-element-1)" />
                                  </svg>
                                </div>
                                <div className="ico_texxt">

                                  <p><span>{learningPath.views}</span> View{learningPath.views > 1 ? 's' : ''}</p>
                                </div>
                              </div> */}
                            </div>
                                    )}
                          </div>
                        </div>

                        <div className="row infoIcon_nw">
                          <div
                            className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                            {/* {
                              learningPath?.provider?.provider_img !== ""
                                ? <img src={learningPath?.provider?.provider_img} alt="" title={learningPath?.provider.provider_name} />  
                                : <div className="d-flex carouseL_IconnBY">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path id="Exclusion_3" data-name="Exclusion 3" d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z" transform="translate(-21473.998 951.002)" fill="var(--color-element-1)" opacity="1"></path></svg>
                                  <p> <span className="sub_txt_lern" title={learningPath?.provider?.provider_display_name}>{learningPath?.provider?.provider_display_name?.substring(0, 10) + '...'}</span></p>
                                </div>

                            } */}
                            <ProviderComponent providerData={learningPath.provider} />
                            <div className="d-flex justify-content-between revisitIconcon comp4530 cursor-pointer" onClick={() => {
                              redirectToPage(learningPath.id);
                            }}>
                              <img
                                src={
                                  (learningPath.prgm_progress === undefined || learningPath.prgm_progress == null || learningPath.prgm_progress === 0) ? "/images/Component 46-78.svg" :
                                    (learningPath.prgm_progress == 100) ? "/images/Component 4427.svg" : "/images/Component 4530.svg"
                                }
                                width="80" height="17" alt="" />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            } else if (showBlankCard && i === 3) {
              return (
                <div key={'learningPath-' + i} className="boxslidersp">
                  <div className="broker-box">
                    <div className="disable_Card">
                      <div className="img-box_telescope">
                        {/* <img src="/images/telescope.svg" height="56" width="60" alt="" /> */}
                        <div className=" col-md-12  telescope_text d-flex justify-content-center align-items-center h-100">
                          <p className="cursor-pointer" onClick={() => {
                            // dispatch(getSelectedCatId(''))
                            clickStreamUser('clicked-view-all-my-learning-paths', {
                              user_id: authBroker.payload.user.user_id
                            })
                            history.push(`/new-learningpath`);
                            // history.push('/learning-paths');
                            // history.push('/learning-paths?id=' + learningId);

                          }}>View all</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })}
      </Slider>
    </div>
  );
}

export default SliderMyLearningPathListing;