import { useEffect, useState } from "react";
import HeaderNewMenu from '../../components/HeaderNewMenu'
import Loader from "../../components/Loader/Loader";
import FooterNew from "../../components/FooterNew";
import AssesmentSlider from './AssesmentSlider';
import ShimmerCardBody from "../../components/Layouts/ShimmerCardBody";
import { truncate } from "lodash";
import ShimmerEventCard from "../../components/Layouts/ShimmerEventCard";
import { getAPI, postAPIWithoutModule } from "../../api/generic";
import { SessionKeys } from '../../utils/AppUtils';
// ../../redux/user/actions
import { getAssesmentReq } from "../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
export const Assesment = () => {

  const [assesment, setAssesment] = useState([]);
  const [isCourseLoading, setIsCourseLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState("");
  const leaderBoardDataTestRes = useSelector((state) => state.user.getAssesments);
  const dispatch = useDispatch();
  useEffect(() => {
    assesmentData();
    // getAssesmentData();
  }, []);


  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchInput(e.target.value);
    setFilterData("")
  }

  const filterDataHandler = () => {
    // console.log('SEARCH INPUT', searchInput)
    setFilterData(searchInput);
  }
  

  const assesmentData = () => {
    setIsCourseLoading(true);
    let requestBody = {};
    requestBody = {
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      userid: localStorage.getItem(SessionKeys.USER_ID)
    };
    (async () => {
      const res = await postAPIWithoutModule({
        module: "getAssesments",
        postData: requestBody
        // token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),

      });
      if (res.status === "success") {
        console.log(res, "ressss");
        let assesments = res.payload;
        // setIsLoading(false);
        setIsCourseLoading(false);
        console.log("assesments-data", assesments?.payload);
        // setAssesment(assesments);
        assesmentdetails(assesments?.payload);

      } else if (res.error.message === "No Assesments found") {
        console.log("No result founnd12344", res.error.message);
        const errMessage1 = res.error.message;
        setIsCourseLoading(false);
        // setBatchErrorMsg(errMessage1);
        console.log(errMessage1);
      } else {
        const errMessage = res.error.message;
        setIsCourseLoading(false);
        // setdataErrMsg(errMessage);
        console.log("result not found");
      }
    })();


  }

  const assesmentdetails = (assesment) => {
    setIsCourseLoading(true);
    const assesment_details = assesment;
    console.log("assesments-data1234", assesment_details);
    const assesmentEntries = assesment_details.map((item, index) => {
      const startDate = new Date(item.exam_start_date);
      const startDateTime = Math.floor(startDate.getTime() / 1000);
      const endDate = new Date(item.exam_end_date);
      const endDateTime = Math.floor(endDate.getTime() / 1000);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return {
        ...item,
        startDateTime,
        endDateTime,
        currentTimestamp
      };
    });
    console.log(assesmentEntries, "assesment_details");
    setIsCourseLoading(false);
    setAssesment(assesmentEntries);
  }
  return (
    <>
      <div className="page-wrapper-rsp">
        <div className="rspinnerwrapper header_margin">
          <HeaderNewMenu
            showMenu={true}
          />
        </div>
        <section className="events_page">
          <div className="events_section">
            <div className="container events-content h-76vh">
              <div className="col-md-12">
                <div className="events_banner">
                  <p className="label-heading_events mb-4">Assessments</p>
                </div>

                {/* for Search */}
                <div className="input-text position-relative d-flex">
                  <label htmlFor="search" className="input__label">
                    Search
                  </label>
                  <input
                    type="text"
                    id="search"
                    name="search"
                    className="input_field mr-2"
                    placeholder=" "
                    value={searchInput}
                    onChange={inputHandler}
                  />
                  <button
                    className="button-reset"
                    style={{ marginTop: "-20px" }}
                    onClick={filterDataHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stopColor="#00b7f1" />
                          <stop offset="1" stopColor="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_191"
                        data-name="Ellipse 191"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_192"
                        data-name="Ellipse 192"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="Icon_awesome-search"
                        data-name="Icon awesome-search"
                        d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                        transform="translate(7.715 7.714)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                </div>

                <div className="row more_events">

                  {
                    !isCourseLoading ?
                      assesment.length == 0 ?
                        <div className="not-found mt-3">
                          <div className=" interboxes">
                            <img src="/images/telescope.svg" alt="Not Found" />
                            <p>Sorry! No result found.</p>
                          </div>
                        </div> :
                        <AssesmentSlider
                          assesment={assesment}
                          filterDataset={filterData}
                        /> :
                      [1, 2, 3, 4].map(m => {
                        return (
                          <ShimmerEventCard />
                        )
                      })
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterNew />
      </div>
    </>
  )
}

export default Assesment;