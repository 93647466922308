import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import ReactRoundedImage from "react-rounded-image";
import { clickStreamUser, SessionKeys } from '../utils/AppUtils';
import { Events } from 'react-scroll'
import _ from "lodash";
import { getSelectedCatId } from '../redux/courses/actions';
import CONFIG from '../utils/config';
import { setCongratClassName } from '../redux/assignCourse/actions';
import { myCartClear } from '../redux/Cart/actions';

const ProfileMenuSection = (props) => {

    const myCartRes = useSelector(state => state.carts.myCart)
    const isPremiumUser = useSelector(state => state.user.isPremiumUser);
    const userDetailRes = useSelector(state => state.user.userBroker)

    const {
        signOut
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const authBroker = useSelector(state => state.auth.authBroker);
    const userRole = useSelector(state => state.user.role)
    const [cartCount, setCartCount] = useState(0);
    const [roleArray, setRoleArray] = useState([])
    const [userDetails, setUserDetails] = useState({})
    const [canShowRole, setCanShowRole] = useState(false)
    const params = window.location.pathname;
    const reaRoleArr = ['coach', 'mentor', 'team_lead'];
    const [reaRole, setReaRole] = useState([])

    const congratClassName = useSelector(state => state.assignCourse.congrat_class_name)
    const [blinkEffect, setBlinkEffect] = useState(congratClassName)

    useEffect(() => {
        if (!_.isEmpty(auth)) {
            setReaRole(auth?.authBroker?.payload?.user?.role)
        }

    }, [auth])

    useEffect(() => {
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
            setUserDetails(userDetailRes?.payload?.payload);
        }
    }, [userDetailRes]);

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            let role = authBroker.payload.user.role
            setRoleArray(authBroker.payload.user.role)
            if (role.includes('coach')) {
                setCanShowRole(true)
            } else if (role.includes('mentor')) {
                setCanShowRole(true)
            } else if (role.includes('team_lead')) {
                setCanShowRole(true)
            } else {
                setCanShowRole(false)
            }
        }
    }, [authBroker])

    useEffect(() => {
        if (!_.isEmpty(myCartRes)) {
            if (myCartRes?.cart_list?.length > 0)
                setCartCount(myCartRes?.cart_list?.length)
            else dispatch(myCartClear())
        } else {
            setCartCount(0)

        }
    }, [myCartRes])

    useEffect(() => {
        return () => {
            Events.scrollEvent.remove("begin")
            Events.scrollEvent.remove("end");
        }
    }, []);

    const goToMenu = (menuName) => {
        switch (menuName) {
            case 'edit-profile':
                history.push('/profile/edit?section=edit')
                clickStreamUser('profile_edit_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'role-request':
                history.push('/accept-role')
                clickStreamUser('role_requests_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'my-dashboard':
                history.push('/profile/edit?section=dashboard')
                clickStreamUser('profile_dashboard_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'learningCenter':
                history.push('/learning_center')
                clickStreamUser('learning_center_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'videos':
                history.push('/my-videos')
                clickStreamUser('my-videos-menu-click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'events':
                history.push('/my-events')
                clickStreamUser('my-events-menu-click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'favorites':
                dispatch(getSelectedCatId('wishlist'))
                history.push('/wishlist')
                clickStreamUser('my_wishlist_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'notes':
                history.push('/notes')
                clickStreamUser('notes_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'cart':
                history.push('/cart')
                clickStreamUser('cart_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'orders':
                history.push('/orders')
                clickStreamUser('orders_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'credit-points':
                history.push('/credit-points')
                clickStreamUser('credit-points_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'learning-points':
                history.push('/learning-points')
                clickStreamUser('learning-points_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;

            case 'my-certificates':
                // { 
                //     params === "/profile/edit" ?
                //     scroller.scrollTo("MyCertificates", {
                //         duration: 50,
                //         delay: 0,
                //         smooth: "easeInOutQuart",
                //     }) :
                //     history.push('/my-certificates')
                // }
                history.push('/profile/edit?section=certificate')
                clickStreamUser('my-certificates_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })

                break;
            case 'my-badges':
                history.push('/profile/edit?section=badge')
                clickStreamUser('my-certificates_menu_click', {
                    userId: auth.authBroker?.payload?.user.user_id
                })
                break;
            case 'learning-path':
                history.push('/learning-path');
                clickStreamUser('learning_menu_clicked', {
                    "categoryName": "Learning Paths",
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
                case 'new-learningpath':
                    history.push('/new-learningpath');
                    clickStreamUser('learning_menu_clicked', {
                        "categoryName": "Learning Paths",
                        "userId": auth.authBroker?.payload?.user.user_id,
                    })
                    break;
            case 'toolbox':
                history.push('/toolbox?folderId=0');
                clickStreamUser('my-toolbox-clicked', {
                    "categoryName": "My Toolbox",
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'courses':
                history.push('/my-courses');
                clickStreamUser('my_courses_menu_clicked', {
                    "categoryName": "Courses",
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'broker-events':
                history.push('/events?source=broker')
                clickStreamUser('broker-events-menu-clicked', {
                    "categoryName": "Learning Paths",
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'membership':
                history.push('/membership');
                clickStreamUser('upgrade_to_premium_menu_clicked', {
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'my-onboarding':
                history.push('/my-onboarding');
                clickStreamUser('my_onboarding_menu_clicked', {
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'report':
                history.push('/report');
                clickStreamUser('report_menu_clicked', {
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'change-password':
                history.push('/change-password');
                clickStreamUser('change_password_clicked', {
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'assesments':
                history.push('/assesments');
                clickStreamUser('assesment_menu_clicked', {
                    "userId": auth.authBroker?.payload?.user.user_id,
                })
                break;
            case 'admin-dashboard':
                clickStreamUser('admin-dashboard-menu-clicked', {
                    "userId": auth.authBroker?.payload?.user.user_id,
                })

                window.open(`${CONFIG.API_URL_BROKER_ADMIN}/?br_key=${localStorage.getItem(SessionKeys.API_TOKEN_BROKER)}`)
        }
    }


    const navigateToDetails = () => {
        history.push({
            pathname: `/cart`,

        })
    }

    const premiumIcon = () => {
        return (
            <div className="position-absolute premium-diamond">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.057" height="9.209" viewBox="0 0 9.057 9.209">
                        <path id="diamond-stone" d="M8.34,5.223H9.7L7.434,8.446M5.623,5.223H7.434L6.528,8.907M3.358,5.223H4.717l.906,
                        3.223M7.887,2.921h.906L9.7,4.3H8.34M6.075,2.921h.906L7.434,4.3H5.623M4.264,2.921H5.17L4.717,
                        4.3H3.358M3.811,2,2,4.763l4.528,6.446,4.528-6.446L9.245,2Z"
                            transform="translate(-2 -2)" fill="#febe10" />
                    </svg>
                </span>
            </div>
        )
    }

    const realtorMenu = () => {
        return (
            <>
                <NavDropdown.Item className="customHoverdrop">
                    <div className="customHover" onClick={() => goToMenu('edit-profile')}>
                        My Account
                    </div>
                    <div className="submenuresp">
                        {/* <div className="customHover b-b-1">Profile</div> */}
                        {/* <div className="customHover b-b-1" onClick={() => goToMenu('my-dashboard')}>Dashboard</div> */}
                        {/* {!isPremiumUser && <div className="customHover b-b-1" onClick={() => goToMenu('membership')}>- Upgrade to Premium</div>}
                        <div className="customHover b-b-1" onClick={() => goToMenu('credit-points')}>- Reward Points</div>
                        <div className="customHover b-b-1" onClick={() => goToMenu('orders')}>- Orders</div>
                        <div className="customHover b-b-1" onClick={() => goToMenu('favorites')}>- Wishlist</div> */}
                        {/*<div className="customHover b-b-1" onClick={() => goToMenu('role-request')}>-  My Network</div> */}
                    </div>
                    {/* <NavDropdown.Item className="customHover b-b-1" onClick={() => goToMenu('favorites')}>Wishlist</NavDropdown.Item> */}
                </NavDropdown.Item>
                <NavDropdown.Item className="customHoverdrop">
                    <div className="customHover"  >
                        My Learning
                    </div>
                    <div className="submenuresp">
                        {/* <div className="customHover b-b-1">Profile</div> */}
                        {/* <div className="customHover b-b-1" onClick={() => goToMenu('my-dashboard')}>Dashboard</div> */}
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
                            <div className="customHover b-b-1" onClick={() => goToMenu('my-onboarding')}>- Onboarding</div>

                        }
                        <div className="customHover b-b-1" onClick={() => goToMenu('new-learningpath')}>- Programs</div>
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                            <div className="customHover b-b-1" onClick={() => goToMenu('courses')}>- Courses</div>
                        }
                        <div className="customHover b-b-1" onClick={() => goToMenu('videos')}>- Videos</div>
                        <div className="customHover b-b-1" onClick={() => goToMenu('events')}>- Events</div>
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
                            (
                                <>
                                    <div className="customHover b-b-1" onClick={() => goToMenu('my-certificates')}>- Certificates</div>
                                    <div className="customHover b-b-1" onClick={() => goToMenu('my-badges')}>- Badges</div>
                                </>
                            )
                        }

                        {/* <div className="customHover b-b-1" onClick={() => goToMenu('learning-points')}>- Learning Points</div> */}
                    </div>
                    {/* <NavDropdown.Item className="customHover b-b-1" onClick={() => goToMenu('favorites')}>Wishlist</NavDropdown.Item> */}
                </NavDropdown.Item>
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('role-request')}>My Team Area</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('courses')}>My Courses</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('learning-path')}>My Learning Paths</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('cart')}>My Cart</NavDropdown.Item>                 */}
                {/* <NavDropdown.Item className="customHover" href="notifications.html">My Notifications</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('learningCenter')}>My Learning Center</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('broker-events')}>My Broker Events</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('events')}>My Events</NavDropdown.Item>
                {!isPremiumUser && <NavDropdown.Item className="customHover" onClick={() => goToMenu('membership')}>Upgrade to Premium</NavDropdown.Item>} */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('toolbox')}>My Toolbox</NavDropdown.Item> */}
                {/* {
                    userRole === 'manager' && (
                        <NavDropdown.Item className="customHover" onClick={() => goToMenu('role-request')}>My Network</NavDropdown.Item>
                    )
                } */}
                {
                    authBroker?.payload?.user?.company_id === 521 ?
                        (userRole !== 'instructor' && (
                            <NavDropdown.Item className="customHover" onClick={() => goToMenu('assesments')}>Assessments</NavDropdown.Item>

                        )) :
                        <>
                        </>
                }

                <NavDropdown.Item className="customHover" onClick={() => goToMenu('change-password')}>Change Password</NavDropdown.Item>
                {/* {canShowRole ? <NavDropdown.Item className="customHover" onClick={() => goToMenu('report')}>Reports</NavDropdown.Item> : ''} */}
                <NavDropdown.Item className="customHover" onClick={() => { signOut() }}>Logout</NavDropdown.Item>
            </>
        )
    }

    const hostInstructorMenu = () => {
        return (
            <>
             <NavDropdown.Item className="customHoverdrop">
                    <div className="customHover" onClick={() => goToMenu('edit-profile')}>
                        My Account
                    </div>
                    <div className="submenuresp">
                        {/* <div className="customHover b-b-1">Profile</div> */}
                        {/* <div className="customHover b-b-1" onClick={() => goToMenu('my-dashboard')}>Dashboard</div> */}
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
                            <>
                                {!isPremiumUser && <div className="customHover b-b-1" onClick={() => goToMenu('membership')}>- Upgrade to Premium</div>}
                                <div className="customHover b-b-1" onClick={() => goToMenu('credit-points')}>- Reward Points</div>
                                <div className="customHover b-b-1" onClick={() => goToMenu('orders')}>- Orders</div>
                                <div className="customHover b-b-1" onClick={() => goToMenu('favorites')}>- Wishlist</div>
                            </>
                        }

                    </div>
                    {/* <NavDropdown.Item className="customHover b-b-1" onClick={() => goToMenu('favorites')}>Wishlist</NavDropdown.Item> */}
                </NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('change-password')}>Change Password</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => { signOut() }}>Logout</NavDropdown.Item>

            </>
        )
    }

    const managerMenu = () => {
        return (
            <>
                <NavDropdown.Item className="customHoverdrop">
                    <div className="customHover" onClick={() => goToMenu('edit-profile')}>
                        My Account
                    </div>
                    <div className="submenuresp">
                        {/* <div className="customHover b-b-1">Profile</div> */}
                        {/* <div className="customHover b-b-1" onClick={() => goToMenu('my-dashboard')}>Dashboard</div> */}
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
                            <>
                                {!isPremiumUser && <div className="customHover b-b-1" onClick={() => goToMenu('membership')}>- Upgrade to Premium</div>}
                                <div className="customHover b-b-1" onClick={() => goToMenu('credit-points')}>- Reward Points</div>
                                <div className="customHover b-b-1" onClick={() => goToMenu('orders')}>- Orders</div>
                                <div className="customHover b-b-1" onClick={() => goToMenu('favorites')}>- Wishlist</div>
                            </>
                        }

                    </div>
                    {/* <NavDropdown.Item className="customHover b-b-1" onClick={() => goToMenu('favorites')}>Wishlist</NavDropdown.Item> */}
                </NavDropdown.Item>
                <NavDropdown.Item className="customHoverdrop">
                    <div className="customHover"  >
                        My Learning
                    </div>
                    <div className="submenuresp">
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
                            <>
                                <div className="customHover b-b-1" onClick={() => goToMenu('my-onboarding')}>- Onboarding</div>

                            </>
                        }
                        <div className="customHover b-b-1" onClick={() => goToMenu('new-learningpath')}>- Programs</div>
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID &&
                            <div className="customHover b-b-1" onClick={() => goToMenu('courses')}>- Courses</div>
                        }
                        
                        <div className="customHover b-b-1" onClick={() => goToMenu('videos')}>- Videos</div>
                        <div className="customHover b-b-1" onClick={() => goToMenu('events')}>- Events</div>
                        {
                            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && (
                                <>
                                    <div className="customHover b-b-1" onClick={() => goToMenu('my-certificates')}>- Certificates</div>
                                    <div className="customHover b-b-1" onClick={() => goToMenu('my-badges')}>- Badges</div>
                                </>
                            )

                        }

                        {/* <div className="customHover b-b-1" onClick={() => goToMenu('learning-points')}>- Learning Points</div> */}
                    </div>
                    {/* <NavDropdown.Item className="customHover b-b-1" onClick={() => goToMenu('favorites')}>Wishlist</NavDropdown.Item> */}
                </NavDropdown.Item>
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('toolbox')}>My Toolbox</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('role-request')}>My Network</NavDropdown.Item> */}
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('assesments')}>Assessments</NavDropdown.Item>

                {
                    authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && (
                        <NavDropdown.Item className="customHover" onClick={() => goToMenu('role-request')}>My Network</NavDropdown.Item>
                    )
                }

                <NavDropdown.Item className="customHover" onClick={() => goToMenu('report')}>Reports</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('change-password')}>Change Password</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => { signOut() }}>Logout</NavDropdown.Item>
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('admin-dashboard')}>Administrator Dashboard</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('role-request')}>Requests for Role</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('my-dashboard')}>My Dashboard</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('my-onboarding')}>My Onboarding</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('role-request')}>My Team Area</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('courses')}>My Courses</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('learning-path')}>My Learning Paths</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('learning-points')}>My Learning Points</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('my-certificates')}>My Certificates</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('cart')}>My Cart</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('orders')}>My Orders</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('credit-points')}>My Reward Points</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" href="notifications.html">My Notifications</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('learningCenter')}>My Learning Center</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('broker-events')}>My Broker Events</NavDropdown.Item> */}
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('events')}>My Events</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('favorites')}>My Wishlist</NavDropdown.Item> */}
                {/* {!isPremiumUser && <NavDropdown.Item className="customHover" onClick={() => goToMenu('membership')}>Upgrade to Premium</NavDropdown.Item>} */}

            </>
        )
    }

    const brokerMenu = () => {
        return (
            <>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('admin-dashboard')}>Administrator Dashboard</NavDropdown.Item>
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('courses')}>My Courses</NavDropdown.Item>
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('cart')}>My Cart</NavDropdown.Item> */}
                <NavDropdown.Item className="customHover" onClick={() => goToMenu('orders')}>My Orders</NavDropdown.Item>
                {/* <NavDropdown.Item className="customHover" onClick={() => goToMenu('report')}>Reports</NavDropdown.Item> */}
                <NavDropdown.Item className="customHover" onClick={() => { signOut() }}>Logout</NavDropdown.Item>
            </>
        )
    }

    useEffect(() => {
        dispatch(setCongratClassName('step1'));
    }, [])

    useEffect(() => {
        // console.log('congratClassName: ', congratClassName)
        setBlinkEffect(congratClassName)
    }, [congratClassName])

    return (
        <>
            {/* Start:Menu dropdown */}
            <NavDropdown title={
                <div className={blinkEffect}>
                    {isPremiumUser ? premiumIcon() : null}
                    <ReactRoundedImage
                        image={authBroker?.payload?.user?.profile_img !== (undefined || null)  && authBroker?.payload?.user?.profile_img !== '' ? authBroker?.payload?.user?.profile_img : "/images/avatar.png"}
                        roundedSize="0" imageWidth="45" imageHeight="45" />
                    {cartCount > 0 && !(window.location.pathname.includes('/cart')) ?
                        <div className='cursor-pointer cart-item-report' style={{
                            marginTop: '-20px'
                        }} onClick={() => { navigateToDetails() }} title="cart" data-title="cart">
                            <img width={32} height={32} src='/images/cart_blank.png' />
                            <p className='cart-text' >{(cartCount < 10 ? '0' : '') + cartCount}</p>
                        </div> : ""}
                </div>
            } className="profile-nav">
                {userRole === 'realtor' && realtorMenu()}
                {userRole === 'host' && hostInstructorMenu()}
                {userRole === 'instructor' && hostInstructorMenu()}
                {userRole === 'broker' && brokerMenu()}
                {userRole === 'admin' && brokerMenu()}
                {userRole === 'manager' && managerMenu()}
            </NavDropdown>
            {/* End:Menu dropdown */}
        </>
    );
}

export default ProfileMenuSection;