import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { cnfPwdValidation, passCharValidation, passLimitValidation, SessionKeys } from "../../utils/AppUtils";
import Loader from "../Loader/Loader";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordReq, changePasswordResClear } from "../../redux/user/actions";
const ChangePasswordSection = () => {
    const dispatch = useDispatch();

    const changePassRes = useSelector((state) => state.user.changePassRes);

    const [isLoading, setIsLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('')
    const [cnfPassword, setCnfPassword] = useState('')
    const [htmlFormError, setFormError] = useState('')
    const [htmlFormSuccess, setFormSuccess] = useState('')
    const [confirmModal, setConfirmModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalMsg, setErrorModalMsg] = useState("");

    const resetPassword = () => {
        setFormError("");
        if (_.isEmpty(oldPassword)) {
            setFormError("Please enter your current password.")
        } else if (!passLimitValidation(password)) {
            setFormError("Please enter at least 6 characters.")
        } else if (!passLimitValidation(cnfPassword)) {
            setFormError("Please enter at least 6 characters.")
        } else if (!passCharValidation(password)) {
            setFormError(<>
                <ul className="setup-password-error">
                    <li>Password must contain at least one uppercase letter</li>
                    <li>Password must contain at least one lowercase letter</li>
                    <li>Password must contain at least one number</li>
                    <li>Password must contain at least one special character like !@#$%^&*</li>
                </ul>
            </>)
        } else if (!passCharValidation(cnfPassword)) {
            setFormError(<>
                <ul className="setup-password-error">
                    <li>Password must contain at least one uppercase letter</li>
                    <li>Password must contain at least one lowercase letter</li>
                    <li>Password must contain at least one number</li>
                    <li>Password must contain at least one special character like !@#$%^&*</li>
                </ul>
            </>)
        } else if (cnfPwdValidation(password, cnfPassword)) {
            setIsLoading(true);
            setConfirmModal(false);
            setErrorModal(false);
            dispatch(changePasswordReq({
                module: 'changePassword',
                postData: {
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    current_password: oldPassword,
                    new_password: cnfPassword
                }
            }))
        } else {
            // alert("Passwords do not match.")
            setFormError("Passwords do not match. Please enter the same password in both fields.");
        }

    }

    useEffect(() => {
        console.log("changePassRes: ", changePassRes);
        if (!_.isEmpty(changePassRes)) {
            setIsLoading(false);
            if (changePassRes?.status === "success") {
                setOldPassword("");
                setPassword("");
                setCnfPassword("");
                setShowModalMsg(changePassRes?.payload?.message);
                setConfirmModal(true);

            } else {
                setErrorModalMsg(changePassRes?.error?.message);
                setErrorModal(true);

            }

            dispatch(changePasswordResClear())
        }
    }, [changePassRes])

    const myFunction = () => {
        var x = document.getElementById("passInput");
        var y = document.getElementById("eyeImg");
        if (x.type === "password") {
          x.type = "text";
          y.src = "/images/view.png";
        } else {
          x.type = "password";
          y.src = "/images/private.png";
        }
      };


  const  myFunctionNewPasword = () => {
    // console.log("hiiiiii")
    var x = document.getElementById("passInput2");
    var y = document.getElementById("eyeImg2");
    if (x.type === "password") {
        console.log("hiiiiii123")
      x.type = "text";
      y.src = "/images/view.png";
    } else {
        console.log("hiiiiii12345")
      x.type = "password";
      y.src = "/images/private.png";
    }
  }

  const myFunctionCnfPassword = () => {
    // console.log("hiiiiii12344")
    var x = document.getElementById("passInput3");
    var y = document.getElementById("eyeImg3");
    if (x.type === "password") {
      x.type = "text";
      y.src = "/images/view.png";
    } else {
      x.type = "password";
      y.src = "/images/private.png";
    }
  }

    const renderAlertCard = () => {
        return (
            <div
                style={{ 'top': '-65px' }}
                className={`alert-card d-flex justify-content-center ${confirmModal ? "" : "d-none"
                    }`}
            >
                <div className="shop-cart">
                    <div className="shop-info justify-content-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                        >
                            <g
                                id="Group_5699"
                                data-name="Group 5699"
                                transform="translate(0.498 0.498)"
                            >
                                <circle
                                    id="Ellipse_222"
                                    data-name="Ellipse 222"
                                    cx="9"
                                    cy="9"
                                    r="9"
                                    transform="translate(-0.498 -0.498)"
                                    fill="#A9C23C"
                                />
                                <path
                                    id="Icon_material-done"
                                    data-name="Icon material-done"
                                    d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                                    transform="translate(-3.013 -6.071)"
                                    fill="#fff"
                                    stroke="#fff"
                                    stroke-width="2"
                                />
                            </g>
                        </svg>
                        <p className="m-0">{showModalMsg}</p>
                    </div>
                    <div className="view-cart-icon">

                        <i
                            className="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={() => {
                                setConfirmModal(false);
                                setShowModalMsg("");
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    };

    const renderErrorAlertCard = () => {
        return (
          <div
            className={`alert-card d-flex justify-content-center ${errorModal ? "" : "d-none"
              }`}
          >
            <div className="shop-cart wrong_password_block">
              <div className="shop-info justify-content-center">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                                    <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                                    <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
                                </g>
                            </svg> */}
                <p className="m-0">{errorModalMsg}</p>
              </div>
              <div className="view-cart-icon">
                <i
                  className="fa fa-times cursor-pointer"
                  aria-hidden="true"
                  onClick={() => {
                    setErrorModal(false);
                    setErrorModalMsg("");
                  }}
                ></i>
              </div>
            </div>
          </div>
        );
      };

    return (<>
        <Loader start={isLoading} />
        <div>
            <section className="setup-password">
                <div className="container position-relative">
                    {renderAlertCard()}
                    {renderErrorAlertCard()}
                    <div className="row">

                        <div className={`col-8 col-sm-7 col-md-5 col-xl-4 col-lg-4 right-space`}>
                            <div className="heading ">

                                <h3>Change your Password</h3>

                                <form onSubmit={(event) => {
                                    event.preventDefault();
                                    resetPassword()
                                   
                                }}>
                                    <div className="input-text position-relative change_password_section">
                                        <label htmlFor="password" className="input__label">Enter Current Password</label>
                                        <input type="password" className="input__field"
                                            placeholder="Enter Password"
                                            id="passInput"
                                            aria-label="password" aria-describedby="basic-addon1"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                             />
                                                   <div
                                            className="show-pass"
                                            onClick={() => {
                                                myFunction();
                                            }}
                                        >
                                            <img
                                                id="eyeImg"
                                                src="/images/private.png"
                                                alt="view"
                                            />
                                        </div>
                                    </div>
                                    <div className="input-text position-relative">
                                        <label htmlFor="password" className="input__label">Enter New Password</label>
                                        <input type="password" className="input__field"
                                         id="passInput2"
                                            placeholder="Enter Password"
                                            aria-label="password" aria-describedby="basic-addon1"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                                    <div
                                            className="show-pass"
                                            onClick={() => {
                                                myFunctionNewPasword();
                                            }}
                                        >
                                            <img
                                                id="eyeImg2"
                                                src="/images/private.png"
                                                alt="view"
                                            />
                                        </div>
                                    </div>
                                    <div className="input-text  position-relative">
                                        <label htmlFor="confirm_password" className="input__label">Confirm New Password</label>
                                        <input type="password" className="input__field" 
                                            id="passInput3"
                                        placeholder="Confirm Password"
                                            aria-label="password" aria-describedby="basic-addon1"
                                            value={cnfPassword}
                                            onChange={(e) => setCnfPassword(e.target.value)} />
                                                    <div
                                            className="show-pass"
                                            onClick={() => {
                                                myFunctionCnfPassword();
                                            }}
                                        >
                                            <img
                                                id="eyeImg3"
                                                src="/images/private.png"
                                                alt="view"
                                            />
                                        </div>
                                    </div>
                                    <div className="save_button">
                                        {
                                            isLoading ?
                                                <Button
                                                    className="btn btn-primary setup-button" >
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                                :
                                                <Button
                                                    type="submit"
                                                    className="btn btn-primary setup-button">
                                                    Submit</Button>
                                        }
                                        {htmlFormError && (<div className="pasword_field_error">{htmlFormError}</div>)}
                                        {htmlFormSuccess && (<div>{htmlFormSuccess}</div>)}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </>)

}
export default ChangePasswordSection;