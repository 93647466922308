import { debug } from "util";
import { lang } from "moment";
import { SessionKeys } from "./AppUtils";

export default class StaticUtils {
  static getDateFromTimeStamp(t) {
    let timestamp = Number(t) * 1000;
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
  }

  static getDateTimeFromTimeStamp(t) {
    let timestamp = Number(t) * 1000;
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(timestamp);
  }

  static diff_minutes(date) {
    let dt1 = new Date();
    let dt2 = new Date(date);
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
  }

  static staticRefAudio = null;

  static getLeaderBoardData(allData) {
    let filteredArr = [];
    if (allData !== undefined)
      allData?.sort(
        (a, b) => parseFloat(a.user_rank) - parseFloat(b.user_rank)
      );
    // console.log('filteredArrayFromComponentSort', allData);
    for (let index = allData?.length - 1; index >= 0; index--) {
      const element = allData[index];
      if (element?.user_id == localStorage.getItem(SessionKeys.USER_ID)) {
        let firstIndex = index > 0 ? index - 1 : 0;
        let lastIndex = allData?.length;

        if (allData?.length - 1 - index === 0) {
          if (allData?.length > 3) {
            firstIndex = index - 3;
          } else if (allData?.length > 2) {
            firstIndex = index - 2;
          }
        }

        if (allData?.length - 2 - index === 0) {
          if (allData?.length > 3) {
            firstIndex = index - 2;
          } else if (allData?.length > 2) {
            firstIndex = index - 1;
          }
        }

        let x = 0;
        let countAfterMe = 0;
        let rankCount = firstIndex;
        let breakPoint = firstIndex === 0 ? 4 : 3;
        for (let j = firstIndex; j < lastIndex; j++) {
          if (countAfterMe === breakPoint) {
            break;
          }
          filteredArr.push(allData[j]);
          filteredArr[x].is_me = false;
          filteredArr[x].rank = allData?.length - rankCount;
          rankCount++;
          if (
            filteredArr[x]?.user_id == localStorage.getItem(SessionKeys.USER_ID)
          ) {
            filteredArr[x].is_me = true;
            countAfterMe++;
          } else {
            if (countAfterMe > 0) {
              countAfterMe++;
            }
          }
          x++;
        }
        return filteredArr;
      }
    }
  }

  static isContainObject(list, obj, from) {
    let isContain = false;

    for (const item of list) {
      if (from === 0) {
        if (item.user_id === obj.user_id) {
          isContain = true;
        }
      } else if (from === 1) {
        if (item.id === obj.id) {
          isContain = true;
        }
      }
    }
    // console.log("isContain: ", isContain);
    return isContain;
  }
}
