const types = {
    MEMBERSHIP_PRODUCTS: "MEMBERSHIP_PRODUCTS",
    MEMBERSHIP_PRODUCTS_RESPONSE: "MEMBERSHIP_PRODUCTS_RESPONSE",
    MEMBERSHIP_PRODUCTS_CLEAR: "MEMBERSHIP_PRODUCTS_CLEAR",

    MEMBERSHIP_INTENT: "MEMBERSHIP_INTENT",
    MEMBERSHIP_INTENT_RESPONSE: "MEMBERSHIP_INTENT_RESPONSE",
    MEMBERSHIP_INTENT_CLEAR: "MEMBERSHIP_INTENT_CLEAR",

    MEMBERSHIP_ORDER_PLACE: "MEMBERSHIP_ORDER_PLACE",
    MEMBERSHIP_ORDER_PLACE_RESPONSE: "MEMBERSHIP_ORDER_PLACE_RESPONSE",
    MEMBERSHIP_ORDER_PLACE_CLEAR: "MEMBERSHIP_ORDER_PLACE_CLEAR",

    MEMBERSHIP_IS_SUCCEED: "MEMBERSHIP_IS_SUCCEED",
    MEMBERSHIP_IS_CLEAR: "MEMBERSHIP_IS_CLEAR",
}

export default types;