const CONFIG = {
  DOMAIN_URL: window.location.origin.includes("stg")
    ? "https://rpsapp-stg.stackroute.io/"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://rpsapp-dev.stackroute.io/" 
      : window.location.origin.includes("demo")
        ? "https://xseldemo.training.com/"
        : window.location.origin.includes("preprod")
        ? "https://learn-preprod.stackroute.in/"
        : window.location.origin.includes("uat")
          ? "https://xselappuat.training.com/"
          : window.location.origin.includes("qa")?
          "https://rpsapp-qa.stackroute.io/"
          : "https://digital.rpsconsulting.in/",
  IOS_PACKAGE_NAME: "com.niit.xceed",
  ANDROID_PACKAGE_NAME: "com.niit.xceed",
  API_URL: window.location.origin.includes("stg")
      ? "https://spark-api-stg.stackroute.io/"
      : window.location.origin.includes("preprod")
          ? "https://learnapi-preprod.stackroute.in/"
          : (window.location.origin.includes("local") ||
              window.location.origin.includes("dev"))
              ? "https://spark-api-dev.stackroute.io/" :
              window.location.origin.includes("qa")
                  ? "https://spark-api-qa.stackroute.io/"
                  : "https://spark-api.niit.com/",
  ROLEPLAY_SOCKET_URL: window.location.origin.includes("stg")
    ? "https://realtorsuccesslearning-stg-videosocket.niit-mts.com/"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://realtorsuccesslearning-qcvideosocket.niit-mts.com/"
      : window.location.origin.includes("demo")
        ? "https://realtorsuccesslearning-demo-videosocket.niit-mts.com/"
        : window.location.origin.includes("uat")
          ? "https://realtorsuccesslearning-uat-videosocket.niit-mts.com/"
          : "https://realtorsuccesslearning-dev-videosocket.niit-mts.com/",
  IN_BUCKET_URL: window.location.origin.includes("stg") ||
    window.location.origin.includes("local") ||
    window.location.origin.includes("dev") ||
    window.location.origin.includes("demo") ||
    window.location.origin.includes("uat")
    ? "https://lt-in.s3.amazonaws.com/stg"
    : "https://lt-in.s3.amazonaws.com/prod",
  OUT_BUCKET_URL: window.location.origin.includes("stg") ||
    window.location.origin.includes("local") ||
    window.location.origin.includes("dev") ||
    window.location.origin.includes("demo") ||
    window.location.origin.includes("uat") ||
    window.location.origin.includes("preprod")
    ? "https://spark-cdn-dev.stackroute.io"
    : "https://spark-cdn.niit.com",
  SCORM_URL: window.location.origin.includes("stg")
    ? "https://sparkadmin-dev.stackroute.io/public/scorm_player"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://spark-api-dev.stackroute.io/scorm-player"
      : window.location.origin.includes("demo")
        ? "https://xseldemo-admin.training.com/public/scorm_player"
        : window.location.origin.includes("uat")
        ? "https://xseldemo-admin.training.com/public/scorm_player"
        : window.location.origin.includes("preprod")
          ? "https://learnapi-preprod.stackroute.in/scorm-player"
          :window.location.origin.includes("qa")?
          "https://spark-api-qa.stackroute.in/scorm-player"
          : "https://spark-api.niit.com/scorm-player",
  SCORM_URL_BROKER: window.location.origin.includes("stg")
    ? "https://realtorsuccessfrontity-stg.niit-mts.com/public/scorm_player.php"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://sparkadmin-dev.stackroute.io/public/scorm_player.php"
      : window.location.origin.includes("demo")
        ? "https://xseldemo-brokerage.training.com/public/scorm_player.php"
        :window.location.origin.includes("qa")?
          "https://sparkadmin-qa.stackroute.io/public/scorm_player.php"
        : window.location.origin.includes("uat")
          ? "https://xselappuatadministrator.training.com/public/scorm_player.php"
          : window.location.origin.includes("preprod")
          ? "https://learnadmin-preprod.stackroute.in/public/scorm_player.php"
          : "https://sparkadmin.niit.com/public/scorm_player.php",

  EXAM_SYSTEM_URL:
          window.location.origin.includes("local") ||
          window.location.origin.includes("dev") ||
          window.location.origin.includes("stg") ||
          window.location.origin.includes("preprod") ||
          window.location.origin.includes("qa")
            ? "https://nasdev.training.com/Assedere/TestIntegration/LaunchTest.login"
            : "https://nas.training.com/Assedere/TestIntegration/LaunchTest.login",
    
  API_URL_BROKER: window.location.origin.includes("stg")
    ? "https://sparkecom-dev.stackroute.io"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://sparkecom-dev.stackroute.io"
      : window.location.origin.includes("demo")
        ? "https://xseldemo-ecom.training.com"
        : window.location.origin.includes("uat")
          ? "https://xselappuatecom.training.com"
          : "https://sparkecom.niit.com",
  API_URL_BROKER_ADMIN: window.location.origin.includes("stg")
    ? "https://sparkadmin-dev.stackroute.io"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev") 
      ? "https://spark-api-dev.stackroute.io"
        : window.location.origin.includes("preprod")
        ? "https://learnapi-preprod.stackroute.in"
        : window.location.origin.includes("uat")
          ? "https://xselappuatadministrator.training.com"
         :window.location.origin.includes("qa")?
          "https://spark-api-qa.stackroute.io"
          : "https://spark-api.niit.com",

  API_URL_ADMIN: window.location.origin.includes("stg")
      ? "https://spark-api-stg.stackroute.io/"
      : window.location.origin.includes("preprod")
          ? "https://learnapi-preprod.stackroute.in/"
          : (window.location.origin.includes("local") ||
              window.location.origin.includes("dev"))
              ? "https://spark-api-dev.stackroute.io/" :
              window.location.origin.includes("qa")
                  ? "https://spark-api-qa.stackroute.io/"
                  : "https://spark-api.niit.com/",

  SHOW_LIVE: window.location.origin.includes("stg")
    ? false
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? true
      : window.location.origin.includes("demo")
        ? true
        : window.location.origin.includes("uat")
          ? false
          : false,
  SESSION_EXPIRED_URL: window.location.origin,
  // RPS_COMPANY_ID: 521,
  RPS_COMPANY_ID: window.location.origin.includes("qa") ? 523
         : window.location.origin.includes("local") ||
          window.location.origin.includes("dev")? 521
         : 521,
  API_ROUTES: {
    // BASE_PATH: "api/webapi",
    // LOGIN: "api/webapi",
    // GET_MODULES_LIST_NIIT_ADMIN: "api/getmodulesapi",
    // // DYNAMIC_BANNER: "api/splash-banner",
    // ASSIGN_COURSE: "api/assigncourseapi",
    // MODULE_FAV: "api/webapi",
    // MODULE_FAV_DEL: "api/webapi",
    // MODULE_NOTES: "api/webapi",
    // MODULE_NOTES_UPDATE: "api/webapi",
    // MODULE_NOTES_DEL: "api/webapi",
    ROLE_PLAY: "api/roleplay",
    ROLEPLAY_MASTER_LIST: "meeting/ajax_roleplay?module=getRoleplayList",
    ASSESSMENT_SUBMISSION: "api/v1/assessment/attempt",
    GET_PROGRAM_DETAILS:"api/v1/my-learning-paths",
    ADD_MODULE_PROGRESS: "api/v1/courses/module-progress",
    GET_ZOOM_RECORDINGS:"api/v1/vilt/get-meeting-recording",
  },
  API_ROUTES_BROKER: {
    GET_PROXY_LOGIN_TOKEN: 'api/v1/proxy-login',
    GET_COMPANY_SETTINGS: 'api/v1/company-settings',
    AJAX_BASE_PATH: "/wp-admin/admin-ajax.php",

    VERIFY_SIGNUP_PATH: "/api/json/xsel/v1/verify-signup-token",
    RESEND_SIGNUP_PATH: "/api/json/xsel/v1/resend-signup-token",
    FORGOT_PASSWORD_LINK_PATH: "/api/json/xsel/v1/realtor-forgot-password",

    // BANNER_CARD_SUMMARY: "/api/json/xsel/v1/banner-card-summary",

    LOGIN_BASE_PATH: "/api/v1/login",
    LOGIN_HOSTINST_BASE_PATH: "/api/json/xsel/v1/host-instructor-login",
    UPDATE_PASSWORD_BASE_PATH: "/api/json/xsel/v1/realtor-update-password",
    USER_DETAIL_PROFILE: "/api/v1/user/profile",
    USER_DETAIL_PROFILE_FOR_OTHER:
      "/api/json/xsel/v1/user-profile-by-manager?debug=true",
    PROFILE_COMPLETE_DATA: "/api/json/xsel/v1/get-profile-complete-data",
    LOGOUT_BASE_PATH: "/api/v1/logout",

    GET_COURSES_BROKER: "/api/json/xsel/v1/get_all_courses",
    COURSE_DETAIL_BASE_PATH: "/api/json/products/single-shopping/",
    // PRODUCT_CATEGORY_BASE_PATH: "/api/json/categories/product-categories/?brokerage_id=",

    ADD_TO_CART_BASE_PATH: "/api/json/customers/add-to-cart/",
    // MY_CART_BASE_PATH: "/api/json/cart/my-cart/?email=",
    MY_CART_DEL_BASE_PATH: "/api/json/cart/remove-product/",
    SAVE_LATER_BASE_PATH: "/api/json/cart/save-later",
    SAVE_LATER_VIEW_BASE_PATH: "/api/json/cart/show-save-later?email=",
    DELETE_SAVE_LATER_BASE_PATH: "/api/json/cart/delete-save-later",

    PAYMENT_CHECKOUT: "/api/json/checkout/user",
    PAYMENT_BASE_PATH: "/api/json/stripe/payment-intents/",
    DIRECT_CHECKOUT_PATH: "/api/json/buynow/response",
    PAYMENT_USER_BASE_PATH: "/api/json/wocommerce/user",
    SEND_ORDER_PLACE_BASE_PATH: "/api/json/checkout-response/response",
    ZERO_ORDER_PLACE_BASE_PATH:
      "/api/json/checkout-response/place-zero-order-direct",

    SESSION_LIST_DATA: "/api/json/products/session-list",
    CALENDAR_SESSIONS_DATA: "/api/json/products/get-my-session-list",
    CALENDAR_SESSIONS_BROKER_DATA:
      "/api/json/xsel/v1/get-my-session-list-broker",
    CALENDAR_HOST_SESSIONS_DATA: "/api/json/xsel/v1/host-assigned-sessions",
    CALENDAR_INSTRUCTOR_SESSIONS_DATA:
      "/api/json/xsel/v1/instructor-assigned-sessions",
    // CALENDAR_SESSION_ECOM_LIST: "/api/json/session/all-list",
    CALENDAR_SESSION_BROKER_LIST: "/api/json/xsel/v1/all-session-list",
    CALENDAR_BOARDCAST_DATA: "/api/json/xsel/v1/get-eventsession-details",

    // Coupon Code
    COUPON_CODE_APPLY: "/api/json/woocommerce/v1/apply-coupon-code",

    // XSel Points
    GET_CREDIT_POINTS: "/api/json/woocommerce/v1/get-credit-points",

    ORDER_HISTORY_BASE_PATH: "/api/json/order/my-orders/?email=",
    GET_COURSE_LIST_WOO_BASE_PATH: "/api/json/products/get-products",
    GET_ORDER_DETAIL_BASE_PATH: "/api/json/order/single-order/",

    GET_ALL_LEARNING_PATH: "/api/json/xsel/v1/get-all-learning-path",
    SHOW_LEARNING_POINTS: "/api/json/learning-points/points",
    GET_CREDIT_POINTS_HISTORY:
      "/api/json/woocommerce/v1/get-credit-points-history",

    EVENT_LIST: "/api/json/events/events-list",
    ECOM_EVENT_LIST: "/api/json/event/list-data",
    EVENT_DETAIL: "/api/json/events/single-event",
    ECOM_EVENT_DETAIL: "/api/json/event/details",
    SESSION_LIST_BROKER: "/api/json/xsel/v1/get_event_sessions",
    ENROLL_SESSION_BROKER: "/api/json/xsel/v1/register-broker-event",
    IN_PROGRESS_BROKER: "/api/json/xsel/v1/in-progress-courses",
    EVENT_NOTIFICATION: "/api/json/xsel/v1/event-notification",
    CHECK_NOTIFIED: "/api/json/xsel/v1/fetch-notified-event",
    CANCEL_SESSION: "/api/json/xsel/v1/session-cancel",
    CERTIFICATE_SESSION_LIST: "/api/json/event/certificate-list",

    // Assigned
    ASSIGNED_COURSES: "/api/json/assign_handler/v1/assign_data",
    VIEW_MARK: "/api/json/xsel/v1/add-entity-record",

    // Check Content
    CHECK_CONTENT_ACCESS: "/api/json/broker-xsel/v1/check-content",

    // WOOCOMMERCE
    MY_COURSES_ECOM: "/api/json/woocommerce/v1/my-courses",

    // Broker
    MY_COURSES_BROKER: "/api/json/xsel/v1/my-courses",
    GET_CERTIFICATES: "/api/json/learning-path/certificates/",
    GET_BADGES: "/api/json/courses/badges/",
    SINGLE_LP: "/api/json/xsel/v1/learningpaths-details",
    // code of ethics
    MASTER_CODE_OF_ETHICS: "/api/json/check/list",
    MASTER_CODE_OF_ETHICS_AGREE: "/api/json/checklist/response",

    // Host
    CREATE_ZOOM_MEETING: "/api/json/zoom/v2/zoom-create-meeting",

    // Join ZOOM
    JOIN_ZOOM_MEETING: "/api/json/zoom/v2/add-zoom-participant",

    ADD_TO_WISHLIST: "/api/json/shopping/addwishlist",
    GET_WISHLIST: "/api/json/shopping/show-addwishlist?email=",
    DEL_WISHLIST: "/api/json/cart/remove-wishlist",
    GET_LICENSED_AS: "/api/json/xsel/v1/fetch-licensed-as",
    GET_SEARCH_DATA: "/api/json/xsel/v1/global-search",
    GET_SEARCH_DATA_WOO: "/api/json/search/term",

    // Video Listing
    VIDEO_LISTING: "/api/json/products/videos-list",

    // Membership
    MEMBERSHIP_DETAILS: "/api/json/woocommerce/membership/info",
    MEMBERSHIP_INTENT: "/api/json/woocommerce/membership/payment-intent",
    MEMBERSHIP_PAYMENT_CHECKOUT:
      "/api/json/woocommerce/membership/checkout-response",

    //Role requested
    ROLE_REQUESTED: "/api/json/designation/list",
    ACCEPT_ROLE_REQUESTED: "/api/json/userstatus/response",
    ACCEPT_ALL_ROLE_REQUESTED: "/api/json/mentors/responseall",
    USER_LIST_OF_ROLE_ACCEPTED: "/api/json/xsel/v1/realtor-list-data",
    USER_LIST_OF_ROLE_ACCEPTED_SEARCH: "/api/json/xsel/v1/realtor-search-data",
    USER_LIST_OF_ROLE_CANCEL: "/api/json/designation/cancel",
    USER_ROLE_REQUEST_STATUS: "/api/json/designation/state",
    ASSIGN_ROLEPLAY: "/api/json/assign-handler/v1/self-assign-roleplay",

    TIMEZONE: "/api/json/xsel/v1/get-timezone",
    TIMEZONE_ABBR: "/api/json/xsel/v1/get-timezone-abbr",

    // MY_EVENTS_ECOM: "/api/json/event/my-event-data",
    MY_EVENTS_BROKER: "/api/json/events/my-events",

    // Recommendations
    RECOMMENDATION_RESPONSE: "/api/json/product/recommendation-purchase",

    // Recommendation View :
    RECOMMENDATION_VIEW_RESPONSE: "/api/json/product/recommendation-view",

    // Recommendation Insert View :
    RECOMMENDATION_INSERT_VIEW_RESPONSE:
      "/api/json/product/recommendation-insert-view",

    // Certificates and Badges Generation :
    CERTIFICATE_DATA_RESPONSE: "/api/json/xsel/generate-certificate-badge",

    // Notifications :
    NOTIFICATION_RESPONSE: "/api/json/user/show-notifications",

    // Notifications Count:
    NOTIFICATION_COUNT_RESPONSE: "/api/json/user/notification-count",

    // report apis endpoint
    PROFILE_REPORT: "/api/json/xsel/report/user-report",
    COURSE_REPORT: "/api/json/xsel/report/course-report",
    EVENT_REPORT: "/api/json/user/event-reports",
    PLAN_UPGRADE_REPORT: "/api/json/woocommerce/report/get-upgrade-report",
    PURCHASE_REPORT: "/api/json/woocommerce/report/get-purchase-report",
    VIDEO_DETAIL: "/api/json/products/videos-detail",
    OFFICE_LIST: "/api/json/xsel/v1/office-list",
    CODE_OF_ETHICS_REPORT: "/api/json/report/coe-list-report",
    CODE_OF_ETHICS_REPORT_REA: "/api/json/report/coe-reas",
    CODE_OF_ETHICS_REPORT_MANAGERS: "/api/json/report/coe-managers-list",
    CODE_OF_ETHICS_REPORT_OFFICES: "/api/json/report/coe-offices",
    CODE_OF_ETHICS_REPORT_TREND: "/api/json/report/coe-trend",
    LEARNING_PATH_TABLE: "/api/json/xsel/report/lp-list-report",
    LEARNING_PATH_REA: "/api/json/xsel/report/lp-reas",
    LEARNING_PATH_MANAGERS: "/api/json/xsel/report/lp-managers-list",
    LEARNING_PATH_OFFICES: "/api/json/xsel/report/lp-offices",
    LEARNING_PATH_TREND: "/api/json/xsel/report/lp-completion-trend",
    LEARNING_PATH_STATS: "/api/json/xsel/report/lp-stats",
    ONBOARDING_REPORT: "/api/json/xsel/report/onboarding-list-report",
    ONBOARDING_REA: "/api/json/xsel/report/oblp-reas",
    ONBOARDING_MANAGERS: "/api/json/xsel/report/oblp-managers",
    ONBOARDING_OFFICES: "/api/json/xsel/report/oblp-offices",
    ONBOARDING_TREND: "/api/json/xsel/report/oblp-completion-trend",
    ONBOARDING_STATS: "/api/json/xsel/report/oblp-stats",
    COURSE_REPORT_REA: "/api/json/xsel/report/course-reas",
    COURSE_REPORT_MANAGERS: "/api/json/xsel/report/course-manager-list",
    COURSE_REPORT_STATS: "/api/json/xsel/v1/course-stats",
    COURSE_REPORT_COMPLETION_TREND: "/api/json/xsel/report/courses-completion-trend",
    COURSE_REPORT_COMPLETION_OFFICES: "/api/json/xsel/report/course-offices",

    // Role Play
    PARTICIPATE_ROLEPLAY: "/api/json/xsel/roleplay",
    REVIEW_ROLEPLAY_LIST: "/api/json/review/list",
    REVIEW_FILTER_ROLEPLAY_LIST: "/api/json/roleplay/review/list",
    SUBMIT_FEEDBACK: "/api/json/xsel/review",
    MY_ROLEPLAY_LIST: "/api/json/myroleplay/list",
    RELATED_VIDEO_LIST: "/api/json/myroleplay/related/list",
    MY_ROLEPLAY_FILTER_LIST: "/api/json/myroleplay/recording/list",
    FETCH_ROLEPLAY_ASSIGNMENT_WISE: "/api/json/woocommerce/list-role-play",
    PRODUCT_CATEGORIES_DATA: "/api/json/subcategories/product-categories-data",
    PRODUCT_SUB_CATEGORIES_DATA: "/api/json/subcategories/product-sub-categories",
    VIDEO_LISTING_DATA_BY_CAT: "/api/json/subcategories/video-categories-data",
    VIDEO_LISTING_DATA_BY_SUB_CAT: "/api/json/subcategories/video-sub-categories",
    VIDEO_LISTING_DATA_RELATED: "/api/json/categories/related-video-categories",
    COURSE_RELATED: "/api/json/categories/course-navigation-api",

    IS_AGGREE: "/api/json/xsel/v1/terms-condition-consent",
    GET_POST_LIST: "/api/json/xsel/network/activity-list-api",
    GET_ADD_COMMENTS: "/api/json/xsel/network/add-comments",
    GET_EDIT_COMMENTS: "/api/json/xsel/network/edit-comments",
    GET_LIKE_COMMENT: "/api/json/xsel/network/comments-like",
    GET_ACTIVITY_LIKE: "/api/json/xsel/network/activity-like",
    GET_PRIVACY_CONTENT: "/api/json/xsel/v1/get-terms-condition-content",
    GET_LEADERBOARD: "/api/v1/leaderboard",
    GET_USER_POINTS: "/api/json/lp/points",

    // MY_VIDEO_ECOM: "/api/json/products/my-videos-list",
    GET_ASSESMENTS:"/api/json/xsel/v1/get-assessment-details",
    GET_ALL_LEARNING_PATH_DETAIL : "/api/json/xsel/v1/get-learning-path-details"
    },
  //Broker Admin related variable
  API_URL_REAL_BROKER: window.location.origin.includes("stg")
    ? "https://spark-api-qa.stackroute.io/"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://spark-api-dev.stackroute.io/"
          : window.location.origin.includes("qa")?
          "https://spark-api-qa.stackroute.io/"
          : window.location.origin.includes("preprod")?
              "https://learnapi-preprod.stackroute.in/"
          : "https://spark-api.niit.com/",
  API_ROUTES_REAL_BROKER: {
    REAL_BROKER_BASE_PATH: "/wp-admin/admin-ajax.php?",
    REAL_BROKER_UPLOAD_IMAGE_PATH: "api/json/xsel/v1/update-profile-picture",
    REAL_BROKER_UPDATE_PROFILE: "api/json/xsel/v1/update-profile",
    REAL_BROKER_SEARCH_USER: "api/json/xsel/v1/search-user",
    REAL_BROKER_GET_BANNER: "api/v1/get-broker-banner",
    BROKER_ADMIN_VIDEO_DETAIL: "api/json/myvideo/details",
    REAL_BROKER_UPLOAD_POST: "api/json/xsel/network/create-activity",
    // Video Listing- Broker admin
    BROKER_ADMIN_VIDEO_LIST: "api/json/video/list",
    BROKER_ADMIN_VIDEO_COMPLETION: "api/json/video/video-completion-lp",
    OVERALL_SUMMARY_LEARNING_DATA: "api/json/report/lp-points",
    OVERALL_DATA_RESPONSE: "api/json/report/user-lp",
    OVERALL_SUMMARY_LEARNING_GRAPH: "api/json/report/lp-graph",
    GET_TEAMMATES_LIST: "api/json/assign-handler/v1/get-manager-rea",
    ASSIGN_TEAMMATES: "api/json/assign-handler/v1/create-team-teammates",
    GET_OFFICE_LIST: "api/json/assign-handler/v1/get-office-list",
    GET_COMPLETED_COURSE_LIST:
      "api/json/assign-handler/v1/get-completed-course-list",
    GET_USER_BY_FILTER: "api/json/assign-handler/v1/get-users-by-filter",
    GET_TEAMS_LIST: "api/json/assign-handler/v1/get-team-list",
    GET_DEFAULT_TEAMS_LIST: "api/json/assign-handler/v1/default-teams-list",
    ASSIGN_TO_TEAMS: "api/json/assign-handler/v1/assign-course-by-teams",
    COURSE_COMPLETION_TREND: "api/json/lp/completion-trend",
    COMPLIANCE_COMPLETION_TREND: "api/json/lp/compliance-trend",
    COMPLIANCE_REPORT: "/api/json/report/compliance-report",
    GET_ASSIGNED_USERS:
      "api/json/assign-handler/v1/get-assigned-users-teams-list",
    GET_MANAGER_COACH_MENTOR:
      "api/json/report-handler/v1/get-manager-coach-mentor",
    CHOOSE_COURSE: "api/json/xsel/v1/fetch-all-courses-for-reports",
    CHOOSE_PROVIDER: "api/json/report/provider",
    GET_LEVEL_DETAIL: "api/json/xsel/v1/get-all-level-course",
    DEL_COMMENT: "api/json/xsel/network/delete-comments",
    DEL_POST: "api/json/xsel/network/delete-activity",
    EDIT_POST: "api/json/xsel/network/update-activity",
    CHOOSE_LP_PATH_FILTER: "api/json/xsel/v1/fetch-all-lps-for-reports",
    MY_VIDEO_BROKER: "api/json/video/my-video",
    CREATE_FOLDER: "api/json/xsel/v1/create-toolbox-folder",
    CREATE_FILE: "api/json/xsel/v1/toolbox-files",
    DELETE_FOLDER: "api/json/xsel/v1/toolbox-remove-folder",
    DELETE_FILE: "api/json/xsel/v1/toolbox-remove-file",
    FOLDER_DETAILS: "api/json/xsel/v1/toolbox-folder-detail",
    SEARCH_DETAILS: "api/json/xsel/v1/toolbox-search",
    CHECKLIST_DETAIL: "api/json/xsel/v1/get-checklist-manager-list",
    CHECKLIST_APPROVAL: "api/json/xsel/v1/save-checklist-review-status",
    TOOLBOX_UPDATE_TIME: "api/json/xsel/v1/toolbox-time",
    CHECKLIST_INSERT: "api/json/xsel/v1/insert-checklist",
    CHANGE_PASSWORD: "api/json/xsel/v1/rea-change-password"
  },

  KEY: {
    // STRIPE_KEY: 'pk_test_51L1VejSAhcjWU0OJxqVqHvGHAro5uMh6eUZGjClM2ak7iVAqktXc4k0F59RLYF7KbsKx6jYqigpjgJG9wdr4SgDz001WV3GMxC',
    STRIPE_KEY: window.location.origin.includes("stg")
      ? "pk_test_51M2YcSGYsyXzgi3mzaqBgWvsVyKbhHGvOYl1NlyrnmItUAUeKi1MQGspv0UtVs5YzjCewvARHubnCyBn3wriQ32u00TBFRM9Kw"
      : window.location.origin.includes("local") ||
        window.location.origin.includes("dev")
        ? "pk_test_51LA5wtF9C1JUoSzJVwn1JbK3wL9emcUTk5ISkt9d4sovOgPFjd5SueTMjDGiYMht5AcrK8YiGImJbGAOxr83Yc3000e2UleAAj"
        : window.location.origin.includes("demo")
          ? "pk_test_51M2YdlEqoicc8qLgiPllPchRDCUrzRiTatBGHKlS4pFLbTgmnuB0JxwIVhSif5YGK6vhVarZIoLEdpqHeCuVWpsm00iG08YRwy"
          : window.location.origin.includes("uat")
            ? "pk_test_51M2YdlEqoicc8qLgiPllPchRDCUrzRiTatBGHKlS4pFLbTgmnuB0JxwIVhSif5YGK6vhVarZIoLEdpqHeCuVWpsm00iG08YRwy"
            : "pk_test_51M2YS8LggEtlECepXZm5Xcz0fCK3G6etZj0YB3xlUcYJGhc8KtuAKRwQHSZc8vsxFKpEaDSoMJZEdpxk2RAUinPI006C4I5NSh",
    MOBILE_ACCESS_KEY: "xsel_1SIEL3islIELABEI",
  },
  STRIPE_RETURN_URL: window.location.origin.includes("stg")
    ? "https://realtorsuccesslearning-stg.niit-mts.com"
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev")
      ? "https://rpsapp-dev.stackroute.io"
      : window.location.origin.includes("demo")
        ? "https://xseldemo.training.com"
        : window.location.origin.includes("uat")
          ? "https://xselappuat.training.com"
          : "https://digital.rpsconsulting.in",
  COLOR_ELEMENT_1: "#00b7f1",
  COLOR_ELEMENT_2: "#ed156e",

  NEXT_RELEASE: window.location.origin.includes("stg")
    ? true
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev") ||
      window.location.origin.includes("uat")
      ? true
      : false,
  C21_BROKER_ID: window.location.origin.includes("stg")
    ? 690
    : window.location.origin.includes("local") ||
      window.location.origin.includes("dev") ||
      window.location.origin.includes("demo")
      ? 349
      : window.location.origin.includes("uat")
        ? 349
        : 3,
  THRESHOLD_FOR_HOST: 1,
  THRESHOLD_FOR_PARTICIPANT: 30,
  THRESHOLD_FOR_LIVE_BUTTON_ACTIVE: 900, // 15 mins * 60
  DEFAULT_AVATAR: "/images/avatar.png",
  ENV: window.location.origin.includes("stg")
    ? 'STG'
    : window.location.origin.includes("demo")
      ? 'DEMO'
      : window.location.origin.includes("uat")
        ? 'UAT'
        : window.location.origin.includes("preprod")
        ? 'PREPROD'
        : (window.location.origin.includes("local") ||
          window.location.origin.includes("dev"))
          ? 'DEV'
          : 'PROD',
};
export default CONFIG;
