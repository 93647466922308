import Page from "../../components/Page";
import OnboardReportHomeSection from "../../components/Reports/onboardReport/onboardReportHomeSection";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
export const OnboardReportPage = () => {
    const [isLoading,setIsLoading] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
        <Loader start ={isLoading}/>
        <Page>
           <OnboardReportHomeSection 
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                isMobile={false}
            />
        </Page>
        </>
    )
}

export default OnboardReportPage;