const types = {
  NOTIFICATION: "NOTIFICATION",
  NOTIFICATION_RESPONSE: "NOTIFICATION_RESPONSE",
  NOTIFICATION_CLEAR: "NOTIFICATION_CLEAR",
  NOTIFICATION_ALL_READ: "NOTIFICATION_ALL_READ",
  NOTIFICATION_ALL_READ_RESPONSE: "NOTIFICATION_ALL_READ_RESPONSE",
  NOTIFICATION_ALL_READ_CLEAR: "NOTIFICATION_ALL_READ_CLEAR",
  NOTIFICATION_SINGLE: "NOTIFICATION_SINGLE",
  NOTIFICATION_SINGLE_RESPONSE: "NOTIFICATION_SINGLE_RESPONSE",
  NOTIFICATION_SINGLE_CLEAR: "NOTIFICATION_SINGLE_CLEAR",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  NOTIFICATION_COUNT_RESPONSE: "NOTIFICATION_COUNT_RESPONSE",
  NOTIFICATION_COUNT_CLEAR: "NOTIFICATION_COUNT_CLEAR",
};

export default types;
