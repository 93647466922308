import { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import ContentLoader from 'react-content-loader'
import VideoPlayer from "react-video-js-player";
import CONFIG from "../../utils/config";
import { SessionKeys } from "../../utils/AppUtils";
// import ReactPDF from from 'react-pdf';
import _ from "lodash";
// import $ from "jquery";
// import FileViewer from "react-file-viewer";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Pdftron from "../../components/Course/Pdftron";
import { videoEvents } from "../../utils/VideoClickStream";
import { markModuleProgress } from "../../redux/courses/actions";
// import {learningPathV2} from './learningPathV2.json';
import YouTube from "react-youtube";
import getVideoId from 'get-video-id';
export const ModuleCompRender = (props) => {

    const {
        mId,
        courseId,
        fetchDataFromIframe,
        openFavourites,
        refer,
        moduleInfo,
        setIsLoad,
        isLoad,
        isModuleChange,
        reload,
        setIsLoading,
        source
    } = props;
    const [iframeHeight, setIframeHeight] = useState(600)
    const [iframeMinHeight, setIframeMinHeight] = useState(600);
    const dispatch = useDispatch()
    const moduleListingData = useSelector(state => {
        return state.courses.moduleListing
    });
    const moduleListingDataBroker = useSelector(state => state.courses.moduleListingBroker);
    const authBroker = useSelector(state => state.auth.authBroker)
    // const [selectedModule, setSelectedModule] = useState(history.location.state.module);
    const [moduleData, setModuleData] = useState({})
    const search = window.location.search;
    const params = new URLSearchParams(search);
    // const source = source;
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [moduleDataInfo, setModuleDataInfo] = useState({})
    // var regExp = "https://www.youtube.com";


const options = {
    height: '420',
    // width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
}
const _onReady = (event) =>{
    event.target.pauseVideo();
  }
const onPlayerEnd = () => {
    // setIsLoading(true)
    dispatch(
        markModuleProgress({
            module: "markModuleProgress",
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            postData: {
                module_id: mId,
                course_id: courseId
            },
        })
    );
  }

  const onPlayRecording = () => {
    dispatch(
        markModuleProgress({
            module: "markModuleProgress",
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            postData: {
                module_id: mId,
                course_id: courseId
            },
        })
    );
  }

    useEffect(() => {
        if (!_.isEmpty(moduleInfo)) {
            const moduleobj = moduleInfo.rsp_scromdata[0];
            const regExp = "www.youtube.com";
            const url = moduleobj.url.slice(8,23)
            if(url === regExp){
                // return true
                const prop1 = "video_type";
                moduleobj[prop1] = "video";
                setModuleDataInfo(moduleobj)
            }
            else if(url.includes("zoom.us")){
                const prop1 = "video_type";
                moduleobj[prop1] = "recordingVideo";
                setModuleDataInfo(moduleobj)
            }
            setModuleDataInfo(moduleInfo)
        }
    }, [moduleInfo])

    // const moduleCallBack = useCallback(() => {
    //     if (!_.isEmpty(moduleInfo)) {
    //         setModuleDataInfo(moduleInfo)
    //     }
    // }, [moduleInfo])

    useEffect(() => {
        if (isLoad) {
            setIframeHeight(600)
        }
    }, [isLoad])

    // useEffect(() => {
    //     if (isModuleChange) {
    //         setModuleDataInfo({})
    //     }
    // }, [isModuleChange])

    useEffect(() => {
        if (!_.isEmpty(moduleListingData)) {
            setIframeHeight(600)
        }
    }, [moduleListingData])

    useEffect(() => {
        if (iframeHeight < iframeMinHeight) {
            setIframeMinHeight(iframeHeight)
        }
    }, [iframeHeight])

    // useEffect(() => {
    //     if (!_.isEmpty(moduleInfo)) {
    //         window.addEventListener("message", handleNotes)
    //         window.addEventListener("message", favourites)
    //         window.addEventListener("message", scrollToSection)
    //         window.addEventListener("message", heightChange)
    //     }

    //     return () => {
    //         window.removeEventListener("message", handleNotes)
    //         window.removeEventListener("message", favourites)
    //         window.removeEventListener("message", heightChange)
    //         window.removeEventListener("message", scrollToSection)
    //     }
    // }, [moduleInfo])

    useEffect(() => {
    },[])

    const handleNotes = evt => {
        let dataObj = typeof evt.data === 'object' ? evt.data : JSON.parse(evt.data);
        if (dataObj && dataObj.method === 'notes') {
            dataObj.url = evt.origin;
            fetchDataFromIframe(dataObj);
        }

    }

    const heightChange = evt => {
        let dataObj = typeof evt.data === 'object' ? evt.data : JSON.parse(evt.data);
        if (dataObj && dataObj.method === 'heightChange') {
            if (dataObj.height > iframeMinHeight) {
                setIframeHeight(dataObj.height)
            }
        }
    }

    const favourites = evt => {
        let dataObj = typeof evt.data === 'object' ? evt.data : JSON.parse(evt.data);
        if (dataObj && dataObj.method === 'favourites') {
            openFavourites(dataObj);
            // window.captureMyEvents("favouriteAdded", {
            //   mid: currentModule.module_id,
            //   sid: dataObj.sectionId,
            // });       
        }

    }

    const scrollToSection = (evt) => {
        let dataObj = typeof evt.data === 'object' ? evt.data : JSON.parse(evt.data);
        if (dataObj && dataObj.method === 'contentScrollTo') {
            window.scrollTo({
                top: dataObj.topPosition + document.getElementById("scorm_player").offsetTop / 2,
                behavior: 'smooth'
            })
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const setLoadFalse = () => {
        setTimeout(() => {
            setIsLoad(false)
        }, 500)
    }

    const markProgress = () => {
        return;
    }

    const onPlayerReady = (player) => {
        const trackEl = player.addRemoteTextTrack(
            {
                // src: 'https://rsplearning-dev.niit-mts.com/public/leadersedgetraining_advertising_and_privacy.webvtt',
                kind: "captions",
                srclang: "en",
                label: "English"
            },
            false
        );
        trackEl.addEventListener('load', function () {
            // your callback goes here
        });

        videoEvents(player, {
            type: 'module',
            course_id: courseId,
            module_id: mId,
            user_id: authBroker?.payload?.user?.user_id
        })

        player.on('ended', () => {
            setIsLoading(true)
            dispatch(
                markModuleProgress({
                    module: "markModuleProgress",
                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                    postData: {
                        module_id: mId,
                        course_id: courseId
                    },
                })
            );
        })
    }
    
    return (
        <div className="row mb-2" aria-hidden="false">
            {(isLoad) ?
                <div className="pt-5 loader-launch">
                    {[...Array(4)].map((el, index) => <ContentLoader key={index} className="pt-2 w-100" />)}
                </div> 
            : null}
            {
                !_.isEmpty(moduleDataInfo) && 
                (source === 'xsel' || source === 'ecom')
                && reload
                ?
                    (<iframe
                        key={mId}
                        type="text/html"
                        className="w-1000"
                        id="scorm_player"
                        height={iframeHeight}
                        style={{ minHeight: iframeMinHeight, width: "70%", margin: "0 auto" }}
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); }}
                        ref={refer}
                        // src="https://jba-learn-dev.niit-mts.com/scorm_player.php?course_id=jbc_hr_od&module_id=jbc_hr_od_m1"
                        // src="https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657078/launch.html?crscd=CSCD_58"
                        // src={`https://rsplearning-dev.niit-mts.com/public/scorm_player?course_id=${courseId}&module_id=${mId}&username=${username}`}
                        src={
                            `${CONFIG.SCORM_URL}?course_id=${courseId}&module_id=${mId}&user_id=${authBroker?.payload?.user?.user_id}&token=${localStorage.getItem(SessionKeys.API_TOKEN_BROKER)}`}
                        // src="https://rsplearning-dev.niit-mts.com/public/scorm_player?course_id=CSCD_72&module_id=624ed8bc5cf45c05dd4bf234"
                        // src="https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657162/index.html"
                        allowFullScreen={true}
                    />)
                :
                    !_.isEmpty(moduleDataInfo) && reload ?
                    (moduleDataInfo?.rsp_scromdata.map((module) => {
                       if(module.type !=  null) {
                        if (module.type.toLowerCase() === "url") {
                            return (
                                <iframe
                                    key={mId}
                                    type="text/html"
                                    className="w-100"
                                    id="scorm_player"
                                    height={iframeHeight}
                                    style={{ minHeight: iframeMinHeight, width: "70%", margin: "0 auto" }}
                                    frameBorder="0"
                                    marginHeight="0"
                                    marginWidth="0"
                                    onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                    ref={refer}
                                    // src="https://jba-learn-dev.niit-mts.com/scorm_player.php?course_id=jbc_hr_od&module_id=jbc_hr_od_m1"
                                    // src="https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657078/launch.html?crscd=CSCD_58"
                                    // src={`https://rsplearning-dev.niit-mts.com/public/scorm_player?course_id=${courseId}&module_id=${mId}&username=${username}`}
                                    src={
                                        `${
                                              CONFIG.SCORM_URL
                                            }?course_id=${courseId}&module_id=${mId}&user_id=${
                                              authBroker?.payload?.user?.user_id
                                            }&token=${localStorage.getItem(
                                              SessionKeys.API_TOKEN_BROKER
                                            )}`
                                      }
                                    // src="https://rsplearning-dev.niit-mts.com/public/scorm_player?course_id=CSCD_72&module_id=624ed8bc5cf45c05dd4bf234"
                                    // src="https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657162/index.html"
                                    allowFullScreen={true}
                                />
                            )
                        } 
                        else if(module.type.toLowerCase() === "mp4" && module.video_type === "video") {
                            getVideoId(module.url);                            
                            const { id } = getVideoId(module.url);
                            setLoadFalse()
                            return (
                                <div className="mt-3">
                                    {/* <iframe
                                        className="w-100"
                                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                        src={module.url.replace("\\", "")}
                                        title={module.title} height="100%" width="100%" 
                                        /> */}
                                        
                                        <YouTube 
                                        videoId={id} options={options} onReady={_onReady} onEnd={onPlayerEnd} />
                                </div>
                            )
                        }
                        else if(module.type.toLowerCase() === "mp4" && module.video_type === "recordingVideo") {
                            setLoadFalse()
                            return (
                                <div className="mt-3">
                                    <iframe
                                        className="w-100"
                                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                        src={module.url}
                                        title={module.title} height="100%" width="100%" 
                                         allowFullScreen={true}
                                    />
                                     <div className="d-flex justify-content-end mt-4 next-btn-center">
                                        <button
                                            className="btn btn-primary btn-block next-course-btn"
                                            onClick={onPlayRecording}
                                            disabled={
                                                moduleInfo?.progress <= 100 ? true : false
                                              }
                                        >
                                           Complete <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
                                        </button>
                                        </div>
                                </div>
                            )
                        }
                        
                        else if (module.type.toLowerCase() === "mp4" || module.type.toLowerCase() === "mov") {
                            setLoadFalse(true)
                            return (
                                <>
                                    {isLoad
                                        ? <></> :
                                        <div className="container-fluid">
                                            <div className="row" >
                                                <div className="col-md-12" >
                                                    <div className="video-fullbox">
                                                        <div className="search__input">
                                                            <div className="img-box_RP">
                                                                <div className="video-full mt-3">
                                                                    <VideoPlayer
                                                                        className="img-videosec learn_image-card"
                                                                        controls={true}
                                                                        // src="https://cdn-stg.niit-mts.com/xsel/media/sample-20s.m3u8"
                                                                        src={module.url}
                                                                        width="720"
                                                                        height="420"
                                                                        autoPictureInPicture={true}
                                                                        // poster={module.}
                                                                        // onLoad={setLoadFalse}
                                                                        fluid={true}
                                                                        inline={false}
                                                                        pictureInPictureToggle={true}
                                                                        type={module.type.toLowerCase() === "mp4" ? "video/mp4" : "video/mov"}
                                                                        onReady={onPlayerReady}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        } else if (module.type === "pdf") {
                            return (

                                <div className="pdf-view-page">

                                    <iframe
                                        className="w-100"
                                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                        src={CONFIG.API_URL_ADMIN + "pdfview/?id=" + mId + "&u_token=" + localStorage.getItem(SessionKeys.API_TOKEN_BROKER)} title={module.title} height="100%" width="100%" />
                                </div>
                            )
                        } else if (module.type === "xlsx") {
                            return (
                                <div className="pdf-view-page" style={{ height: "700px" }}>
                                    <iframe
                                        className="w-100"
                                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${module.url}`}
                                        title={module.title} height="100%" width="100%" />
                                </div>
                            )
                        } else if (module.type === "docx") {
                            return (
                                <div className="pdf-view-page" style={{ height: "700px" }}>
                                    <iframe
                                        className="w-100"
                                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${module.url}`}
                                        title={module.title} height="100%" width="100%" />
                                </div>
                            )
                        } else if (module.type === "pptx") {
                            return (
                                <div className="pdf-view-page" style={{ height: "700px" }}>
                                    <iframe
                                        className="w-100"
                                        onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${module.url}`}
                                        title={module.title} height="100%" width="100%" />
                                </div>
                            )
                        } else {
                            <div className="container-fluid">
                                <div className="row" >
                                    <div className="col-md-12" >
                                        <div className="video-fullbox">
                                            <div className="search__input">
                                                <div className="img-box_RP">
                                                    <div className="video-full mt-3">
                                                        {/* <VideoPlayer
                                                            className="img-videosec learn_image-card"
                                                            controls={true}
                                                            // src="https://cdn-stg.niit-mts.com/xsel/media/sample-20s.m3u8"
                                                            src={module.url}
                                                            width="720"
                                                            height="420"
                                                            autoPictureInPicture={true}
                                                            // poster={module.}
                                                            // onLoad={setLoadFalse}
                                                            fluid={true}
                                                            inline={false}
                                                            pictureInPictureToggle={true}
                                                            onReady={onPlayerReady}
                                                        /> */}
                                                          <iframe
                                                            className="w-100"
                                                            src={module.url}
                                                            title={module.title} height="100%" width="100%" 
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                       }
                    
                    })
                    ) : ''
            }
        </div>
    )
}