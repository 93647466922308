import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const LearningPathModal = (props) => {

  const history = useHistory()
  const dispatch = useDispatch();
  
  return (
    <Modal 
      {...props}
      centered
      className="learning-modal"
      fullscreen="md"
      dialogClassName="modal-40w"
    >
      <Modal.Body className="p-0">
        <div className="grid-column">
          <div className="image-area">
            <img className="img" src="images/info_img.jpg" alt="" width="100%" />
          </div>
          <div className="info_Content__txt">
            <div className="cancel-icon">
              <span>
                <i style={{'font-size':'22px'}} className="fa fa-times-circle-o fa-2x cursor-pointer" onClick={() => props.onHide()}></i>
              </span>
            </div>
            <p>{props.learningData?.description}</p>
            <div className="explore-button">
              <button 
                className="btn btn-primary btn-block setup-button ml-2 cursor-pointer"
              >Explore and Start the Path</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default LearningPathModal;