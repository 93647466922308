import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import CourseCardListBody from "../../components/Course/CourseCardListBody";
import BackButton from "../../components/Layouts/BackButton";
import ShimmerCardBody from "../../components/Layouts/ShimmerCardBody";
import ShimmerModuleListing from "../../components/Layouts/ShimmerModuleListing";
import Page from "../../components/Page";
import { getLevelDetailReq, getLevelDetailResponseClear, getSelectedCatId } from "../../redux/courses/actions";
import { SessionKeys } from "../../utils/AppUtils";
import { levelDetailData, levelListData } from "../../utils/testJson";

const LevelDetailPage = () => {

	const dispatch = useDispatch();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const program_id = params.get('program_id');
	const level_id = params.get('level_id');
	const [isLoading, setIsLoading] = useState(false)
	const [closeChildModal, setCloseChildModal] = useState(true);
	const moduleProgress = useSelector((state) => state.courses.moduleProgress);

	const levelDetails = useSelector(state => state.courses.getLevelDetail)
	const [levelDetail, setLevelDetail] = useState()

	useEffect(() => {
		dispatch(getSelectedCatId("wishlist"))

		if (program_id !== undefined && level_id !== undefined) {
			setIsLoading(true)
			dispatch(getLevelDetailReq({
				module: "getLevelDetail",
				postData: {
					token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
					program_id: program_id,
					level_id: level_id
				}
			}))
		}
	}, [program_id, level_id])

	// useEffect(() => {

	// 	if (!_.isEmpty(moduleData) && !_.isEmpty(moduleProgress)) {
	// 	  console.log(moduleData,"moduleData");
	// 	  console.log(moduleProgress,"moduleProgress");
	// 	  setIsLoading(false)
	// 	  if (
	// 		source === "broker" &&
	// 		moduleProgress?.status === "success"
	// 	  ) {
	// 		const selectModule = _.cloneDeep(selectedModule);

	// 		dispatch(markModuleProgressClear());
	
	// 	  }
	// 	}
	//   }, [moduleProgress]);
	
	//   useEffect(() => {
	
	// 	// console.log("selectedmodule", selectedModule);
	// 	if (!_.isEmpty(moduleData)) {
	// 	  // console.log("selectedmodule", moduleData);
	// 	  const moduleDataClone = _.cloneDeep(moduleData);
	// 	  console.log("moduleDataAtProgress3: ", moduleDataClone);
	// 	  for (let [index, module] of moduleDataClone.modules.entries()) {
	// 		if (module.module_id === selectedModule?.module_id) {
	// 		  // if (moduleDataClone.modules[index].progress < 100)
	// 		  moduleDataClone.modules[index].progress = _.toNumber(
	// 			selectedModule?.progress
	// 		  );
	// 		}
	// 		module.isLock = module.isLock == false ? module.isLock : true;
	// 		if (module.progress > 0) {
	// 		  module.isLock = false;
	// 		  if (index + 1 !== moduleDataClone.modules.length)
	// 			moduleDataClone.modules[index + 1].isLock =
	// 			  _.toNumber(module.progress) === 100 ? false : true;
	// 		}
	// 	  }
	// 	  setModuleData(moduleDataClone);
	// 	}
	//   }, [selectedModule]);


	useEffect(() => {
		console.log('levelDetails: ', levelDetails);

		if (!_.isEmpty(levelDetails)) {
			setIsLoading(false)
			if (levelDetails?.status === 'success') {

				const lvDetails =levelDetails?.payload?.payload;

				if (!_.isEmpty(lvDetails)) {
					for (let [index, course] of lvDetails.courseList.entries()) {
						// const coursesListData = lvDetails.coursesList.map((item, index) => {
						// 	const course_hours = Math.floor(item.period / 60);
						// 	const course_minutes = item.period % 60;
						// 	return {
						// 	  ...item,
						// 	  course_hours,
						// 	  course_minutes
						// 	};
						//   });
						//   console.log
						course.lp_id = program_id;
						let nextCourse = lvDetails.courseList[index + 1];
						if (lvDetails.sequence === "1") {
							if (index === 0) {
								course.isLock = false;
							}
							course.isLock = course.isLock == false ? course.isLock : true;
							if (course.crs_progress > 0) {
								course.isLock = false;
								if (index != lvDetails.courseList.length - 1) {
									nextCourse.isLock = _.toNumber(course.crs_progress) >= 100 ? false : true;
								}
							}
						} else {
							course.isLock = course.isLock ? course.isLock : false;
						}
	
						console.log('courseWithSequecing: ',lvDetails.courseList);
	
						if (course.durationUnit === "Hours") {
						  const convertArray = _.toString(course.duration).includes(":")
							? course.duration.split(":")
							: [course.duration, 0];
						  const hoursMinutes = _.toNumber(convertArray[0]) * 60;
						  const minutes = _.toNumber(convertArray[1]);
						  const totalMinutes = hoursMinutes + minutes;
						  course.duration = totalMinutes;
						}
						if (_.toNumber(course.duration) > 60) {
						  let hours = Math.floor(course.duration / 60);
						  let minutes = course.duration % 60;
						  hours = hours >= 10 ? hours : `0${hours}`;
						  minutes = minutes >= 10 ? minutes : `0${minutes}`;
						  // console.log(course.duration, hours, minutes)
						  // course.duration = `${hours}:${minutes}`
						  course.duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
							} ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
						  course.durationUnit = "";
						} else if (_.toNumber(course.duration) < 60) {
						  // console.log('Minutes')
						  course.duration = `${course.duration} ${course.duration > 1 ? "Mins" : "Min"
							}`;
						  course.durationUnit = "";
						} else {
						  // console.log('hour')
						  course.duration = "1 Hour";
						  course.durationUnit = "";
						}
					}
				}

				setLevelDetail(lvDetails)
			}
		}

	}, [levelDetails])

	useEffect(()=>{

		return(()=>{
			dispatch(getLevelDetailResponseClear())
		})
	},[])

	return (
		<>
			<Page>
				<section className="courselistrsp">
					<section
						className="realtor__safety img-fluid"
						style={{ background: `url(${levelDetail?.banner})` }}
					>
						<div className="row realtorRow leveldetails">
							<div className="col-md-6 col-sm-12 bg-white opacity-68 realtor_col">

							</div>
						</div>
					
					</section>
					<div className="">
							<div className="container level_detail_progress">
								<div className="header_img_left">
									<div className="main__contrR">


										<div className="container-pie-chart">
											<div className="container-chart">
												<div className="box">
													<div className='circularProgress'
														style={{
															width: 150,
															height: 150,
															position: "relative"
														}}
													>
														<CircularProgressbar
															value={levelDetail?.prgm_progress}
															styles={buildStyles({
																pathColor:
																	levelDetail?.prgm_progress < 100 ? `#6CC869` : "#6CC869",  //FF9700=yellow
																trailColor: "#EBEBEB",
															})}
														/>
														<div style={{
															position: "absolute",
															top: "3rem",
															width: "150px",
															textAlign: "center"
														}}>
															{levelDetail?.prgm_progress > -1 ?
																<>
																	<strong style={{ color: 'black' }}>{`${levelDetail.prgm_progress.toFixed(0)}%`}</strong>
																	<div style={{ fontSize: ".8em", color: 'black' }}>Completed</div>
																</> : ''}
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
									{/* <h6 dangerouslySetInnerHTML={{
								__html: levelDetail?.courseName,
							}}>
							</h6> */}
								</div>
							</div>
						</div>
					<div className="container">
						<div className='mt-5'>
							<BackButton  />
						</div>

						{levelDetail?.courseList ? <div className="row">
							<div className="col-md-6 main_realtor ">
								<h6 className="" dangerouslySetInnerHTML={{
									__html: "Welcome to " + levelDetail?.prgm_nm,
								}}></h6>
							</div>

							<div className="col-md-12 main_realtor mt-2">
								
								<div className="module-dsic" dangerouslySetInnerHTML={{
									__html: levelDetail?.prgm_description,
								}}>
								</div>
							</div>
						</div> : 
						""}


						<div className="col-md-12 profile-left-sec">
							<div className="row">
								<div className="col-md-12 mt-3">


								</div>
								{
									isLoading? [1, 2, 3, 4].map((i) => {
										return <ShimmerCardBody key={`shimmer-${i}`} />;
									  }):
									levelDetail?.courseList && levelDetail?.courseList.length > 0 ?


										<CourseCardListBody
											key={0}
											pageType={"course"}
											coursesList={levelDetail?.courseList}
											setFavoriteCourse={() => { }}
											closeModal={closeChildModal}
											setCloseModal={setCloseChildModal}
											hasDescription={false}
											filterData={''}
											path={`/levelDetail?program_id=${program_id}&level_id=${level_id}`}
											index={1}
											program_id = {program_id}
											level_id = {level_id}
										/>


										: <div className='col-md-12 notf-ound'>
											<div className="not-found mt-3">
												<div className="interboxes">
													<img src="/images/telescope.svg" alt="Not Found" />
													<p>No Course Found</p>
												</div>
											</div>
										</div>


								}
							</div>
						</div>


					</div>
				</section>
			</Page>
		</>
	)


}

export default LevelDetailPage;