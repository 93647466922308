import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyCourses,
  getMyCoursesClear,
  getSelectedCatId,
} from "../../redux/courses/actions";
import {
  clickStreamUser,
  convertTimeFormat,
  SessionKeys,
} from "../../utils/AppUtils";
import { ProgressBar, Tab, Tabs } from "react-bootstrap";
import _ from "lodash";
import CourseListBody from "../../components/Course/CourseListBody";
import Page from "../../components/Page";
import CourseCardListBody from "../../components/Course/CourseCardListBody";
import Loader from "../../components/Loader/Loader";
import { useHistory } from "react-router-dom";
import ShimmerCardBody from "../../components/Layouts/ShimmerCardBody";
import CONFIG from "../../utils/config";
const MyCoursesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const myCoursesData = useSelector((state) => state.courses.myCourses);

  const [pageType, setPageType] = useState("myCourse");
  const [allCoursesList, setAllCoursesList] = useState([])
  const [startCoursesList, setStartCoursesList] = useState([]);
  const [inProgressCoursesList, setInProgressCoursesList] = useState([]);
  const [completedCoursesList, setCompletedCoursesList] = useState([]);
  const [overDueCoursesList, setOverDueCoursesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSelectedCatId("myArea"));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      clickStreamUser("my-courses-page", {
        user_id: authBroker.payload.user.user_id,
        email: authBroker.payload.user.email,
      });
      dispatch(
        getMyCourses({
          ecom: {
            module: "getMyCourses",
            broker: false,
            postData: {
              user_id: authBroker.payload.user.user_id,
              email: authBroker.payload.user.email,
            },
          },
          broker: {
            module: "getMyCourses",
            broker: true,
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            },
          },
        })
      );
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    if (!_.isEmpty(myCoursesData)) {
      let allCourseList = []
      let startCourseList = [];
      let inProgressCourseList = [];
      let completedCourseList = [];
      let overDueCourseList = [];
      for (let courses of myCoursesData) {
        if (courses.status === "success") {
          const coursesListData = courses.payload.payload;
          const coursesListEntries = coursesListData.map((item, index) => {
            const course_hours = Math.floor(item.duration / 60);
           const course_minutes = item.duration % 60;
            return {
              ...item,
              course_hours,
              course_minutes
            };
          });
          const coursesList = coursesListEntries;
          console.log('COURSELISTdataaa', coursesList)
          if (!_.isEmpty(coursesList)) {
            allCourseList = [...coursesList, ...allCourseList];
            for (let course of coursesList) {
              if (course.durationUnit === "Hours") {
                const convertArray = _.toString(course.duration).includes(":")
                  ? course.duration.split(":")
                  : [course.duration, 0];
                const hoursMinutes = _.toNumber(convertArray[0]) * 60;
                const minutes = _.toNumber(convertArray[1]);
                const totalMinutes = hoursMinutes + minutes;
                course.duration = totalMinutes;
                course.isLock = false;
              }
              course.duration = convertTimeFormat(_.toNumber(course.duration));
              course.durationUnit = "";
            }
            startCourseList = [
              ...coursesList.filter((course) => course.status === "start"),
              ...startCourseList,
            ];
            inProgressCourseList = [
              ...coursesList.filter(
                (course) => course.status === "in-progress"
              ),
              ...inProgressCourseList,
            ];
            completedCourseList = [
              ...coursesList.filter((course) => course.status === "completed"),
              ...completedCourseList,
            ];
            overDueCourseList = [
              ...coursesList.filter((course) => course.status === "over-due"),
              ...overDueCourseList,
            ];
          }
        }
      }
      setAllCoursesList(allCourseList)
      setStartCoursesList(startCourseList);
      setInProgressCoursesList(inProgressCourseList);
      setCompletedCoursesList(completedCourseList);
      setOverDueCoursesList(overDueCourseList);
      dispatch(getMyCoursesClear());
      setIsLoading(false);
    }
  }, [myCoursesData]);

  return (
    <>
      {/* <Loader start={isLoading} /> */}
      <Page>
        <div className="my-credit-points">
          <section className="container">
            <div className="row">
              <div className="col-md-12 profile-left-sec">
                <div className="search_heading">
                  {/* <img src="/images/learning_img.png" width="109" height="106" alt="" /> */}
                  <p className="lable-heading_search">My Courses</p>
                </div>
                <div className="rows my_courses mt-3">
                  <Tabs defaultActiveKey="home" className="mb-3">
                    <Tab eventKey="home" title="All">
                      <div>
                        {!isLoading ? (
                          allCoursesList.length > 0 ? (
                            <div className="row">
                              <CourseCardListBody
                                pageType={pageType}
                                coursesList={allCoursesList}
                                setFavoriteCourse={() => {}}
                                closeModal={false}
                                setCloseModal={() => {}}
                                hasDescription={false}
                              />
                            </div>
                          ) : (
                            <div className="not-found mt-3">
                              <div className="interboxes">
                                <img
                                  src="/images/telescope.svg"
                                  alt="Not Found"
                                />
                                <p>
                                  <>
                                    <span>
                                      Courses that are assigned to you, will appear here. <br />
                                      {/* Click{" "}
                                      <a
                                        className="hyperlink-to"
                                        onClick={() => {
                                          dispatch(getSelectedCatId(""));
                                          history.push(
                                            `/courseListing?selectedCategory=&selectCourse=top`
                                          );
                                        }}
                                      >
                                        here
                                      </a>{" "}
                                      to explore Courses. */}
                                    </span>
                                  </>
                                </p>
                              </div>
                            </div>
                          )
                        ) : (
                          <>
                            <div className="row videolistbox">
                              {[1, 2, 3, 4].map((i) => {
                                return (
                                  <ShimmerCardBody key={`shimmer-${i}`} />
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="start" title="Yet to Start">
                      <div>
                        {!isLoading ? (
                          startCoursesList.length > 0 ? (
                            <div className="row">
                              <CourseCardListBody
                                pageType={pageType}
                                coursesList={startCoursesList}
                                setFavoriteCourse={() => {}}
                                closeModal={false}
                                setCloseModal={() => {}}
                                hasDescription={false}
                              />
                            </div>
                          ) : (
                            <div className="not-found mt-3">
                              <div className="interboxes">
                                <img
                                  src="/images/telescope.svg"
                                  alt="Not Found"
                                />
                                <p>
                                  <>
                                    <span>
                                      Courses that are assigned to you or
                                      started by you will appear here. <br />
                                      {/* Click{" "}
                                      <a
                                        className="hyperlink-to"
                                        onClick={() => {
                                          dispatch(getSelectedCatId(""));
                                          history.push(
                                            `/courseListing?selectedCategory=&selectCourse=top`
                                          );
                                        }}
                                      >
                                        here
                                      </a>{" "}
                                      to explore Courses. */}
                                    </span>
                                  </>
                                </p>
                              </div>
                            </div>
                          )
                        ) : (
                          <>
                            <div className="row videolistbox">
                              {[1, 2, 3, 4].map((i) => {
                                return (
                                  <ShimmerCardBody key={`shimmer-${i}`} />
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="inProgress" title="In Progress">
                      <div>
                        {!isLoading && inProgressCoursesList.length > 0 ? (
                          <div className="row">
                            <CourseCardListBody
                              pageType={pageType}
                              coursesList={inProgressCoursesList}
                              setFavoriteCourse={() => {}}
                              closeModal={false}
                              setCloseModal={() => {}}
                              hasDescription={false}
                            />
                          </div>
                        ) : (
                          <div className="not-found mt-3">
                            <div className="interboxes">
                              <img
                                src="/images/telescope.svg"
                                alt="Not Found"
                              />
                              <p>
                                <span>
                                  Courses that are in progress will appear here.{" "}
                                  <br />
                                  {/* Click{" "}
                                  <a
                                    className="hyperlink-to"
                                    onClick={() => {
                                      dispatch(getSelectedCatId(""));
                                      history.push(
                                        `/courseListing?selectedCategory=&selectCourse=top`
                                      );
                                    }}
                                  >
                                    here
                                  </a>{" "}
                                  to explore Courses. */}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="completed" title="Completed">
                      <div>
                        {!isLoading && completedCoursesList.length > 0 ? (
                          <div className="row">
                            <CourseCardListBody
                              pageType={pageType}
                              coursesList={completedCoursesList}
                              setFavoriteCourse={() => {}}
                              closeModal={false}
                              setCloseModal={() => {}}
                              hasDescription={false}
                            />
                          </div>
                        ) : (
                          <div className="not-found mt-3">
                            <div className="interboxes">
                              <img
                                src="/images/telescope.svg"
                                alt="Not Found"
                              />
                              <p>
                                <span>
                                  Courses that are completed will appear here.{" "}
                                  <br />
                                  {/* Click{" "}
                                  <a
                                    className="hyperlink-to"
                                    onClick={() => {
                                      dispatch(getSelectedCatId(""));
                                      history.push(
                                        `/courseListing?selectedCategory=&selectCourse=top`
                                      );
                                    }}
                                  >
                                    here
                                  </a>{" "}
                                  to explore Courses. */}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab>
                    {
                      authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                      <Tab eventKey="overdue" title="Over Due">
                      <div>
                        {!isLoading && overDueCoursesList.length > 0 ? (
                          <div className="row">
                            <CourseCardListBody
                              pageType={pageType}
                              coursesList={overDueCoursesList}
                              setFavoriteCourse={() => {}}
                              closeModal={false}
                              setCloseModal={() => {}}
                              hasDescription={false}
                            />
                          </div>
                        ) : (
                          <div className="not-found mt-3">
                            <div className="interboxes">
                              <img
                                src="/images/telescope.svg"
                                alt="Not Found"
                              />
                              <p>
                                <span>
                                  Courses that are over due will appear here.{" "}
                                  <br />
                                  {/* Click{" "}
                                  <a
                                    className="hyperlink-to"
                                    onClick={() => {
                                      dispatch(getSelectedCatId(""));
                                      history.push(
                                        `/courseListing?selectedCategory=&selectCourse=top`
                                      );
                                    }}
                                  >
                                    here
                                  </a>{" "}
                                  to explore Courses. */}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab>
                    }
                   
                  </Tabs>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Page>
    </>
  );
};

export default MyCoursesPage;
