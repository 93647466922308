import types from "./types";

// Recommendation View Detail Fetch
export const recommendationViewData = (payload) => ({
  type: types.RECOMMENDATION_VIEW,
  payload,
});

export const recommendationViewResponse = (payload) => ({
  type: types.RECOMMENDATION_VIEW_RESPONSE,
  payload,
});
export const recommendationViewClear = () => ({
  type: types.RECOMMENDATION_VIEW_CLEAR,
});

// Recommendation Insert View

export const recommendationInsertViewData = (payload) => ({
  type: types.RECOMMENDATION_INSERT_VIEW,
  payload,
});

export const recommendationInsertViewResponse = (payload) => ({
  type: types.RECOMMENDATION_INSERT_VIEW_RESPONSE,
  payload,
});
export const recommendationInsertViewClear = () => ({
  type: types.RECOMMENDATION_VIEW_CLEAR,
});
