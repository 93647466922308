import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getUsersByOfficeList,
  getUsersByOfficeListClear,
  setCurrentView,
} from "../../redux/assignCourse/actions";
import { SessionKeys } from "../../utils/AppUtils";
import Loader from "../Loader/Loader";
const CreateteamOptions = (props) => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const {
    selectedOfficeList,
    selectedCompletedCourseList,
    setFilteredFromCoursesOffices,
    setFilterReq,
    parentId,
    parentName,
    setTitleHeading,
  } = props;

  const usersByOfficeListFilterRes = useSelector(
    (state) => state.assignCourse?.usersByOfficeList
  );

  const [joinDate, setJoinDate] = useState("");
  const [joinDateCondition, setJoinDateCondition] = useState("");
  const [licenseDate, setLicenseDate] = useState("");
  const [licenseDateCondition, setLicenseDateCondition] = useState("");
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
    setTitleHeading('Create a Team');
    return () => {
      dispatch(getUsersByOfficeListClear());
    };
  }, []);

  useEffect(() => {
    // console.log("Officesss1", usersByOfficeListFilterRes);

    if (!_.isEmpty(usersByOfficeListFilterRes)) {
      setIsLoading(false)
      if (usersByOfficeListFilterRes?.status === "success") {
        // console.log('success',usersByOfficeListFilterRes?.payload?.payload)
        setFilteredFromCoursesOffices(
          usersByOfficeListFilterRes?.payload?.payload
        );
        dispatch(setCurrentView(4));
      }
    }
  }, [usersByOfficeListFilterRes]);

  const getFilteredUser = () => {
    // console.log('dates: ', joinDateCondition, licenseDateCondition)

    let selectedOffices = [];
    for (const item of selectedOfficeList) {
      selectedOffices.push(item.office_id);
    }

    let selectedCompletedCourse = [];
    for (const item of selectedCompletedCourseList) {
      selectedCompletedCourse.push(item.id);
    }

    console.log('dates: ',joinDate+' licenceDate: '+licenseDate)

    let reqBody = {
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      office_ids: selectedOffices,
      join_date_condition: joinDateCondition,
      join_date: (joinDate === "") ? "" : moment(joinDate).format("YYYY-MM-DD"),
      license_date_condition: licenseDateCondition,
      license_date: (licenseDate === "") ? "" : moment(licenseDate).format("YYYY-MM-DD"),
      completed_course_ids: selectedCompletedCourse,
      content_type: parentName.toLowerCase().includes('event')?'session':parentName.toLowerCase().includes('certificate')?'certificate':parentName.toLowerCase(),
      content_ids: [parentId],
    };

    setFilterReq(reqBody);

    // console.log('reqBody',reqBody);

    setIsLoading(true)
    dispatch(
      getUsersByOfficeList({
        module: "getUserByFiler",
        postData: reqBody,
      })
    );
  };

  return (
    <>
    <Loader start={isLoading} />
      <div className="createteamsOptions">
        <div className="chooseteamate">
          <p>
            Choose people from{" "}
            <strong>
              <span
                className="selectedteam"
                onClick={() => {
                  dispatch(setCurrentView(7));
                }}
              >
                {selectedOfficeList?.length > 0 && selectedOfficeList?.length}{" "}
                Office{" "}
              </span>
            </strong>
          </p>
          <div className="crateteamform">
            <div className="coose_form">Choose people who joined</div>
            <div className="select-report-date">
              <div className="input-lear position-relative d-flex">
                <label for="password" className="input__label_learning">
                  Select
                </label>
                <select
                  className="form-select input_searchfield report-selct"
                  aria-label="Default select example"
                  onChange={(evt) => {
                    if(evt.target.value===""){
                      setJoinDate("")
                    }
                    setJoinDateCondition(evt.target.value);
                  }}
                >
                  <option value="">Any Date</option>
                  <option value="before">Before</option>
                  <option value="after">After</option>
                </select>
              </div>
            </div>
            <div className="input-text position-relative d-flex">
              <label for="date_join" className="input__label">
                Join Date
              </label>
              <DatePicker
                selected={joinDate}
                placeholderText="MM/DD/YYYY"
                className="input__field02"
                maxDate={new Date()}
                defaultDate="MM/DD/YYYY"
                disabled={joinDateCondition==="" ? true : false}
                onChange={(date) => setJoinDate(date)}
              />
              <img
                src="/images/Cal.svg"
                width="28"
                height="28"
                alt="calendar"
              />
            </div>
          </div>
          <div className="crateteamform">
            <div className="coose_form">Choose license issue date</div>
            <div className="select-report-date">
              <div className="input-lear position-relative d-flex">
                <label for="password" className="input__label_learning">
                  Select
                </label>
                <select
                  className="form-select input_searchfield report-selct"
                  aria-label="Default select example"
                  onChange={(evt) => {
                    if(evt.target.value===""){
                      setLicenseDate("")
                    }
                    setLicenseDateCondition(evt.target.value);
                  }}
                >
                  <option value="">Any Date</option>
                  <option value="before">Before</option>
                  <option value="after">After</option>
                </select>
              </div>
            </div>
            <div className="input-text position-relative d-flex">
              <label for="date_join" className="input__label">
                License Date
              </label>
              <DatePicker
                selected={licenseDate}
                placeholderText="MM/DD/YYYY"
                className="input__field02"
                maxDate={new Date()}
                disabled={licenseDateCondition==="" ? true : false}
                onChange={(date) => setLicenseDate(date)}
              />
              <img
                src="/images/Cal.svg"
                width="28"
                height="28"
                alt="calendar"
              />
            </div>
          </div>
          {parentName.toLowerCase() === "course" ? (
            <p>
              Choose people who have completed{" "}
              <strong
                className="choseselect"
                onClick={() => {
                  dispatch(setCurrentView(8));
                }}
              >
                {selectedCompletedCourseList?.length > 0 &&
                  selectedCompletedCourseList?.length}
                  {" "}{parentName}{" "}
              </strong>
            </p>
          ) : (
            ""
          )}
          <div className="buttonsteammate">
            <button
              type="button"
              className="btn btn-primary btn-block back-button"
              onClick={() => {
                dispatch(setCurrentView(3));
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              onClick={() => {
                getFilteredUser();
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateteamOptions;
