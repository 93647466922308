import { useState } from "react";

const ReportViewFilterSection = (props) => {
  const { isView, setIsView, type, setType } = props;

  const [graphTypeArray] = useState(["pie", "bar", "polar area"]);

  const renderInputRadio = () => {
    return graphTypeArray.map((graph) => {
      return (
        <div className="d-flex gap-2 align-items-center">
          <input
            type="radio"
            name="set-chart"
            className=""
            value={graph}
            checked={type === graph ? true : ""}
            onClick={(e) => {
              //  console.log(e.target.value)
              setType(e.target.value);
            }}
          />
          <label className="text-capitalize">{graph}</label>
        </div>
      );
    });
  };

  return (
    <>
      <section className="view-selectfilter coursefilter">
        <div className="container">
          <div className="edit_profile_input view-filter">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex flex-column flex-mbile-col gap-4">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="view-radio"
                      className=""
                      value="table"
                      checked={isView === "table" ? true : ""}
                      onClick={(e) => {
                        // console.log(e.target.value);
                        setIsView(e.target.value);
                      }}
                    />
                    <label>Table View</label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="view-radio"
                      className=""
                      value="graph"
                      checked={isView === "graph" ? true : ""}
                      onClick={(e) => {
                        // console.log(e.target.value);
                        setIsView(e.target.value);
                      }}
                    />
                    <label>Graph View</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {isView === "graph" ? (
                  <>
                    <div className="charheading">Chart Type</div>
                    <div className="col-md-12 d-flex gap-4">
                      {renderInputRadio()}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportViewFilterSection;
