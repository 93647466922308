import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash"
import { getAllEventsNIIT, getAllEventsNIITClear } from "../../redux/events/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ProviderComponent from "../Layouts/ProviderComponent";

const MyEventListDataSection = ({ allEvents, goToEventDetail, filterDataset }) => {

	const dispatch = useDispatch();
	// const allEventsRes = useSelector(state => state.events.myEventsList);
	// const authBroker = useSelector(state => state.auth.authBroker)

	// const [allEvents, setAllEvents] = useState([])
	const [filterData, setFilterData] = useState([])

	useEffect(() => {
		setFilterData(allEvents.filter((el) => {
			if (filterDataset === "") {
				return el;
			}
			else {
				return el.event_name.toLowerCase().includes(filterDataset.toLowerCase())
			}
		}))
	}, [allEvents, filterDataset])

	useEffect(() => {
		// console.log('FILTER DATA', filterData)
	}, [filterData])


	return (
		<>
			{filterData?.length > 0 ?
				filterData.map((item, index) => {
					return (
						<div className="col-md-6 col-lg-4 col-xl-4 col-12 events_image" onClick={() => goToEventDetail(item)} key={`events-${index}`}>
							<OverlayTrigger
								key='bottom'
								placement='bottom'
								overlay={
									<Tooltip id={`tooltip-bottom`}>
										<div
											style={{
												color: "white",
												backgroundColor: "black",
											}}
											dangerouslySetInnerHTML={{
												__html: item?.event_name
											}}
										></div>
									</Tooltip>
								}
							>
								<p className="col-12 text-truncate cursor-pointer eventtitle"
									dangerouslySetInnerHTML={{
										__html:item?.event_name
									}}
								>
								</p>
							</OverlayTrigger>
							<div className="card_image">
								{/* <img className="img-fluid" src={item?.thumbnailURL} alt="" 
									onError={({currentTarget}) => {
									currentTarget.onerror = true;
									currentTarget.src = "/images/default-demo-event-img.jpg"
									}}
								/> */}
								{
									   <img
									   className="img-fluid"
									   src={item?.thumbnailURL|| require("../../assets/img/Event.png")}
									   alt=""
								
									 />
									// (item?.thumbnailURL != null || item?.thumbnailURL !== "") ? (
									// 	<img className="img-fluid" src={item?.thumbnailURL} alt="" />
									// ) :
									// 	<img className="img-fluid" src="/images/Event.png" alt="" />
								}
								<div className="event-head">
									<div className="event-head_cont  eve_border">
										{/* <p>April 09, <span>2022</span> </p> */}
										{item?.session_count > 0 ? <p>{item?.session_count} <span>{item?.session_count == 1 ? 'Session' : 'Sessions'}</span></p> : ''}
									</div>
								</div>
							</div>
							<div className="content_events">
								<div className="event-discri-rsp" dangerouslySetInnerHTML={{
									__html: item?.event_description.length < 90 ? item?.event_description : item?.event_description.substring(0, 85) + '...'
								}}></div>
							</div>
							<div className="event_learn">
								<p className="btn-primary-startNow cursor-pointer">Learn More</p>
								{item.source === 'broker' ?
									<p
										title={item?.provider?.provider_display_name}
										className="icon-svg"
									>
										<ProviderComponent providerData={item.provider} />
									</p>
									:
									<p className="icon-svg">
										{/* <svg xmlns="http://www.w3.org/2000/svg" width="30.031" height="30.031" viewBox="0 0 30.031 30.031">
										<path id="Exclusion_3" data-name="Exclusion 3" d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z" transform="translate(-21473.998 951.002)" fill="var(--color-element-1)" opacity="1" />
									</svg>
									<span className="sub_txt_lern">Xsel</span> */}
										<ProviderComponent providerData={{
											provider_img: "/images/xsel_logo.png",
											provider_display_name: 'Xsel'
										}} />
									</p>
								}
							</div>
						</div>
					)
				})
				: (
                    <div className="not-found mt-3">
                        <div className=" interboxes">
                            <img src="/images/telescope.svg" alt="Not Found" />
                            <p>Sorry! No result found.</p>
                        </div>
                    </div>
                )}
		</>
	)
}

export default MyEventListDataSection;