import types from './types'

const initialState = {
    eventsList: [],
    myEventsList: [],
    allEvents: {},
    eventDetail:{},
    sessionList: {},
    allEventsBroker: {},
    eventDetailBroker: {},
    sessionListBroker: {},
    enrollBrokerSession: {},
    setEnrollBrokerSession: {},
    calendarViewSessions: {},
    calendarHostSessions: {},
    calendarInstSessions: {},
    calendarSessionList: [],
    liveCalendarSessionList: [],
    hostSession: {},
    joinSession: {},
    eventNotification: {},
    sessionAttendenceMarked: {},
    checkNotified: {},
    sessionCancel: {},
    watchURLData: {},
    sessionFilterBy: '',
    boardcastData: {},
    boardcastInfo: {},
    liveEventData: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_EVENTS_NIIT_RES:
            // console.log("GET_ALL_EVENTS_NIIT_RES", action.payload)
            return {
                ...state,
                allEvents: action.payload
            }
        case types.GET_ALL_EVENTS_NIIT_CLEAR:
            return {
                ...state,
                allEvents: {}
            }
        case types.GET_EVENTS_DETAILS_NIIT_RES:
            return {
                ...state,
                eventDetail: action.payload
            }
        case types.GET_EVENTS_DETAILS_NIIT_CLEAR:
            return {
                ...state,
                eventDetail: {}
            }
        case types.SESSION_LIST_NIIT_RES:
            return {
                ...state,
                sessionList: action.payload
            }
        case types.SESSION_LIST_NIIT_CLEAR:
            return {
                ...state,
                sessionList: {}
            }
        case types.GET_ALL_EVENTS_BROKER_RES:
            // console.log("GET_ALL_EVENTS_BROKER_RES", action.payload)
            return {
                ...state,
                allEventsBroker: action.payload
            }
        case types.GET_ALL_EVENTS_BROKER_CLEAR:
            return {
                ...state,
                allEventsBroker: {}
            }
        case types.GET_EVENTS_DETAILS_BROKER_RES:
            return {
                ...state,
                eventDetailBroker: action.payload
            }
        case types.GET_EVENTS_DETAILS_BROKER_CLEAR:
            return {
                ...state,
                eventDetailBroker: {}
            }
        case types.SESSION_LIST_BROKER_RES:
            return {
                ...state,
                sessionListBroker: action.payload
            }
        case types.SESSION_LIST_BROKER_CLEAR:
            return {
                ...state,
                sessionListBroker: {}
            }
        case types.ENROLL_BROKER_SESSION_RES:
            return {
                ...state,
                enrollBrokerSession: action.payload
            }
        case types.ENROLL_BROKER_SESSION_CLEAR:
            return {
                ...state,
                enrollBrokerSession: {}
            }
        case types.SET_ENROLL_BROKER_SESSION_RES:
            return {
                ...state,
                setEnrollBrokerSession: action.payload
            }
        case types.SET_ENROLL_BROKER_SESSION_CLEAR:
            return {
                ...state,
                setEnrollBrokerSession: {}
            }
        case types.CALENDAR_VIEW_SESSIONS_RES:
            return {
                ...state,
                calendarViewSessions: action.payload
            }
        case types.CALENDAR_VIEW_SESSIONS_CLEAR:
            return {
                ...state,
                calendarViewSessions: {}
            }
        case types.CALENDAR_SESSION_LIST_RES:
            return {
                ...state,
                calendarSessionList: action.payload
            }
        case types.CALENDAR_SESSION_LIST_CLEAR:
            return {
                ...state,
                calendarSessionList: []
            }
        case types.LIVE_CALENDAR_SESSION_LIST_RES:
            return {
                ...state,
                liveCalendarSessionList: action.payload
            }
        case types.LIVE_CALENDAR_SESSION_LIST_CLEAR:
            return {
                ...state,
                liveCalendarSessionList: []
            }
        case types.CALENDAR_HOST_SESSIONS_RES:
            return {
                ...state,
                calendarHostSessions: action.payload
            }
        case types.CALENDAR_HOST_SESSIONS_CLEAR:
            return {
                ...state,
                calendarHostSessions: {}
            }
        case types.CALENDAR_INSTRUCTOR_SESSIONS_RES:
            return {
                ...state,
                calendarInstSessions: action.payload
            }
        case types.CALENDAR_INSTRUCTOR_SESSIONS_CLEAR:
            return {
                ...state,
                calendarInstSessions: {}
            }
        case types.HOST_SESSIONS_RES:
            return {
                ...state,
                hostSession: action.payload
            }
        case types.HOST_SESSIONS_CLEAR:
            return {
                ...state,
                hostSession: {}
            }
        case types.JOIN_SESSIONS_RES:
            return {
                ...state,
                joinSession: action.payload
            }
        case types.JOIN_SESSIONS_CLEAR:
            return {
                ...state,
                joinSession: {}
            }
        case types.EVENT_NOTIFICATION_RES:
            return {
                ...state,
                eventNotification: action.payload
            }
        case types.EVENT_NOTIFICATION_CLEAR:
            return {
                ...state,
                eventNotification: {}
            }
        case types.SESSION_ATTENDANCE_RES:
            return {
                ...state,
                sessionAttendenceMarked: action.payload
            }
        case types.SESSION_ATTENDANCE_CLEAR:
            return {
                ...state,
                sessionAttendenceMarked: {}
            }
        case types.CHECK_NOTIFICATION_RES:
            return {
                ...state,
                checkNotified: action.payload
            }
        case types.CHECK_NOTIFICATION_CLEAR:
            return {
                ...state,
                checkNotified: {}
            }
        case types.CANCEL_SESSION_RES:
            return {
                ...state,
                sessionCancel: action.payload
            }
        case types.CANCEL_SESSION_CLEAR:
            return {
                ...state,
                sessionCancel: {}
            }
        case types.GET_ALL_EVENTS_RES:
            return {
                ...state,
                eventsList: action.payload
            }
        case types.GET_ALL_EVENTS_CLEAR:
            return {
                ...state,
                eventsList: []
            }
        case types.GET_MY_EVENTS_RES:
            return {
                ...state,
                myEventsList: action.payload
            }
        case types.GET_MY_EVENTS_CLEAR:
            return {
                ...state,
                myEventsList: []
            }
        case types.SESSION_WATCH_URL_RES:
            return {
                ...state,
                watchURLData: action.payload
            }
        case types.SESSION_WATCH_URL_CLEAR:
            return {
                ...state,
                watchURLData: {}
            }
        case types.FILTER_BY:
            return {
                ...state,
                sessionFilterBy: action.payload
            }
        case types.FILTER_CLEAR:
            return {
                ...state,
                sessionFilterBy: ''
            }
        case types.BOARDCAST_RESPONSE:
            return {
                ...state,
                boardcastData: action.payload
            }
        case types.BOARDCAST_CLEAR:
            return {
                ...state,
                boardcastData: {}
            }
        case types.FETCH_BOARDCAST_RESPONSE:
            return {
                ...state,
                boardcastInfo: action.payload
            }
        case types.FETCH_BOARDCAST_CLEAR:
            return {
                ...state,
                boardcastInfo: {}
            }

        case types.CALENDAR_LIVE_EVENTS:
            return {
                ...state,
                liveEventData: action.payload
            }

        case types.CALENDAR_LIVE_EVENTS_CLEAR:
            return {
                ...state,
                liveEventData: []
            }
            
        default:
            return state
    }
}