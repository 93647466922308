import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash';
import { SessionKeys } from "../../utils/AppUtils";
import ConfirmModal from '../modal/ConfirmModal';
import { useParams, useHistory } from 'react-router-dom';
import AlertDeleteCartsModal from './alertModalDelCart';
import AlertSaveModal from './alertModalSavelater';
import { myCart, myCartDelete, myCartDeleteClear, doGetMyCartDelete, myCartSaveLater, myCartSaveLaterClear, myCartSaveLaterViewClear, myCartSaveLaterView } from "../../redux/Cart/actions"
import { Carousel } from 'react-bootstrap';
import ImageComponent from '../Layouts/ImageComponent';



const CartCourseCard = (props) => {

    const { myCartDetails, myCartLength } = props;

    const history = useHistory();
    const dispatch = useDispatch()
    const { courseId } = useParams();

    const myCartRes = useSelector(state => state.carts.myCart)
    const myCartDelRes = useSelector(state => state.carts.myCartDelete);
    const saveForLaterRes = useSelector(state => state.carts.myCartSaveLater);
    const saveForLaterVieRes = useSelector(state => state.carts.myCartSaveLaterView);
    const courseDetailRes = useSelector(state => state.product.courseDetail)
    const authBroker = useSelector(state => state.auth.authBroker)
    const [cartDetail, setCartDetail] = useState([])
    const [cartProductId, setCartProductId] = useState()
    const [confirmModal, setConfirmModal] = useState(false)
    const [showModalMsg, setShowModalMsg] = useState("Cart successfully deleted")
    const [productId, setProductId] = useState([])
    const [showdeletecartmodal, setShowDeleteCartModal] = useState(false);
    const [showsavemodal, setShowSaveModal] = useState(false);
    const [currProductId, setCurrProductId] = useState(null);
    useEffect(() => {
        // console.log('courseDetail1 idddd: ', cartProductId)
        setCartProductId(courseDetailRes?.payload)
    }, [courseDetailRes])

    useEffect(() => {
        setCartDetail(myCartRes?.cart_list)
    }, [myCartRes])

    const handleCloseDelete = () => {
        // currentProductId=null
        setShowDeleteCartModal(false);
    };
    const handleCloseSave = () => {
        // currentProductId=null
        setShowSaveModal(false);
    };
    const handleShowForDelete = (product_id) => {
        setCurrProductId(product_id)
        setShowDeleteCartModal(true);
    };
    const handleShowSaveLater = (product_id) => {
        setCurrProductId(product_id)
        setShowSaveModal(true);
    };
    // const handleShow1 = () => {

    //     setShow(true);
    // };
    useEffect(() => {

        if (!_.isEmpty(saveForLaterRes)) {
            if (saveForLaterRes.status === "success") {
                if (!_.isEmpty(saveForLaterRes?.payload)) {
                    dispatch(myCartSaveLaterClear())
                    dispatch(myCartSaveLaterView({
                        module: 'show-save-later',
                        userId: authBroker?.payload.user.email
                    }
                    ))
                    setShowModalMsg(saveForLaterRes?.payload?.message)
                    setConfirmModal(true)
                    dispatch(myCart({
                        module: 'myCart',
                        email: authBroker?.payload.user.email
                    }))
                }
            }
        }
    }, [saveForLaterRes])
    const delMyCartItem = () => {

        dispatch(myCartDelete({
            module: 'remove-product',
            postData: {
                pid: currProductId,
                uemail: authBroker?.payload.user.email
            }
        }))
        setShowDeleteCartModal(true);
        handleCloseDelete();
        props.setIsLoading(true)
    };
    // useEffect(() => {
    //     dispatch(myCartSaveLater({
    //         module: 'save-later',
    //         postData: { pid: currProductId, userId: authBroker?.payload.user.email }
    //     }))
    // }, [])
    const saveForLater = () => {

        dispatch(myCartSaveLater({
            module: 'save-later',
            postData: { pid: currProductId, uemail: authBroker?.payload.user.email }
        }))
        setShowSaveModal(true)
        handleCloseSave();
        props.setIsLoading(true)
    }

    useEffect(() => {
        // console.log("my cart delete data record", myCartDelRes)
        if (!_.isEmpty(myCartDelRes)) {
            if (myCartDelRes.status === "success") {
                if (!_.isEmpty(myCartDelRes?.payload)) {
                    dispatch(myCartDeleteClear())
                    dispatch(myCart({
                        module: 'myCart',
                        email: authBroker?.payload.user.email
                    }))
                }
            }
        }
    }, [myCartDelRes])

    const renderBundleCard = (m) => {
        return (
            <div className="image-cart">
                <div id="cartCardCarousel" className="position-relative">
                    <Carousel id="cartCourseCarousel">
                        {
                            m.course_list.map((course, index) => {
                                return (
                                    <Carousel.Item key={index}>
                                        {/* <img src={course.imageURL} alt="" /> */}
                                        <ImageComponent 
                                            name={m.product_name}
                                            thumbnail={course.imageURL ? course.imageURL : ""}
                                            cssClass={""}
                                            errorClass={""}
                                        />
                                        <div className="combo-tag"><i className="fa fa-star" aria-hidden="true"></i> COMBO</div>
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                    <div className="bundle-course-length">{
                    m.course_list.length}&nbsp;item{m.course_list.length > 1 ? 's' : 's'}</div>
                </div>
            </div>
        )
    }

    const renderSingleCard = (m) => {
        return (
            <div className={`image-cart image-height`}>
                {/* <img src="https://rsplearning-dev.niit-mts.com/meeting/images/3953495288853918.png" alt="" /> */}
                {/* <img src={m.product_thumbnail} alt="" /> */}
                <ImageComponent 
                    name={m.product_name}
                    thumbnail={m.product_thumbnail ? m.product_thumbnail : ""}
                    cssClass=""
                    errorClass="h-100"
                />
                <div className="image-icons">
                    {(m.product_type === "videos") ? <div className="cart-timer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="clock-time-four"
                                d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                transform="translate(-2 -2)" fill="#fff" />
                        </svg>
                        <p>{m.course_duration}</p>
                        {/* <p>{m.durationUnit}</p> */}
                    </div>
                        :
                        <div className="cart-timer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path id="clock-time-four"
                                    d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                    transform="translate(-2 -2)" fill="#fff" />
                            </svg>
                            <p>{m.course_duration}</p>
                            {/* <p>{m.course_period}</p> */}
                        </div>}
                    <div className="cart-timer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="eye-circle"
                                d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                transform="translate(-2 -2)" fill="#fff" />
                        </svg>
                        <p>{m.course_views}<span>&nbsp;{m?.course_views > 1 ? "Views" : "View"}{" "}</span></p>
                    </div>
                </div>
            </div>
        )
    }

    const renderThumbnail = (m) => {
        return (
            <div className="image-cart image-height">
                {/* <img src={m.product_thumbnail} alt="" /> */}
                <ImageComponent 
                    name={m.product_name}
                    thumbnail={m.product_thumbnail ? m.product_thumbnail : ""}
                    cssClass=""
                    errorClass="h-100"
                />
            </div>
        )
    }

    const renderCartCard = (m, index) => {
        return (
            <div className="cart-description" key={index}>
                {
                    !_.isEmpty(m) ?
                        (m.is_bundle ?
                            renderBundleCard(m) :
                            renderSingleCard(m)) : renderSingleCard(m)

                }
                <div className="desc-content">
                    <div className="wrapper-desc cursor-pointer">
                        <p dangerouslySetInnerHTML={{
                            __html: m.product_name,
                        }}
                            onClick={() => {
                                history.push(`/shopping/${m.product_id}/?addToCart=true&source=${m.product_type}`)
                            }}
                        ></p>

                        {/* REALTOR® Safety -<br /> Health and Safety</p> */}
                        <div className="desc-wrap content-cart">
                            {m.product_sale_price === m}
                            <h2><span className="dollar-normal">$</span>{m?.product_sale_price?.split('.')[0]}<sup>{m?.product_sale_price?.split('.')[1]}</sup>&nbsp;<span>CAD</span></h2>
                            {/* <div className="button-reset cursor-pointer" onClick={() => delMyCartItem(m.product_id)}> */}
                            <div className="button-reset cursor-pointer" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    onClick={() => handleShowForDelete(m.product_id)}>
                                    <defs>
                                        <linearGradient
                                            id="linear-gradient"
                                            x2="1"
                                            y1="0.5"
                                            y2="0.5"
                                            gradientUnits="objectBoundingBox"
                                        >
                                            <stop offset="0" stopColor="#00b7f1"></stop>
                                            <stop offset="1" stopColor="#ed156e"></stop>
                                        </linearGradient>
                                    </defs>
                                    <circle
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradient)"
                                        data-name="Ellipse 57"
                                    ></circle>
                                    <circle
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        fill="#fff"
                                        data-name="Ellipse 179"
                                        transform="translate(2 2)"
                                    ></circle>
                                    <path
                                        fill="#7a549e"
                                        d="M10.582.784h-2.94l-.23-.458A.588.588 0 006.885 0h-2.8a.581.581 0 00-.524.326l-.23.458H.392A.392.392 0 000 1.176v.784a.392.392 0 00.392.392h10.19a.392.392 0 00.392-.392v-.784a.392.392 0 00-.392-.392zM1.3 11.439a1.176 1.176 0 001.173 1.1H8.5a1.176 1.176 0 001.173-1.1l.519-8.3H.784z"
                                        data-name="Icon awesome-trash"
                                        transform="translate(9.514 8.729)"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex inner-content">
                        {(m.product_type === "videos") ? "" : (m.course_lessons) > 1 ? <p>{m.course_lessons}<span>&nbsp;Modules</span></p> : <p>{m.course_lessons}<span>&nbsp;Module</span></p>}
                        {_.toNumber(m.product_regular_price) !== _.toNumber(m.product_sale_price) ? <div className=" shop-cost text-right">
                            <p>Retail price:</p>
                            <p> ${_.toNumber(m.product_regular_price).toFixed(2)}&nbsp;CAD</p>
                        </div> : ""}
                        {/* <p className="price-order">$29.99</p> */}
                    </div>
                    <div className="cart-icon d-flex inner-content cart_discount" >
                        <div className="button-reset cart_discount">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                data-name="Save for later"
                                viewBox="0 0 30 30"
                                onClick={() => handleShowSaveLater(m.product_id)}
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x2="1"
                                        y1="0.5"
                                        y2="0.5"
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset="0" stopColor="#00b7f1"></stop>
                                        <stop offset="1" stopColor="#ed156e"></stop>
                                    </linearGradient>
                                </defs>
                                <circle
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    fill="url(#linear-gradient)"
                                    data-name="Ellipse 57"
                                ></circle>
                                <circle
                                    cx="13"
                                    cy="13"
                                    r="13"
                                    fill="#fff"
                                    data-name="Ellipse 179"
                                    transform="translate(2 2)"
                                ></circle>
                                <path
                                    fill="var(--color-element-1)"
                                    d="M10.5 12a6 6 0 116-6 6.007 6.007 0 01-6 6zm-.75-8.688v3.3l2.078 2.078.8-.8-1.753-1.744V3.312zM4.5 10.5H.75a.75.75 0 010-1.5h2.873a7.693 7.693 0 00.877 1.5zM3.037 6.75H1.5a.75.75 0 110-1.5h1.537L3 6l.038.749zM3.623 3H2.25a.75.75 0 110-1.5H4.5A7.706 7.706 0 003.623 3z"
                                    data-name="Exclusion 7"
                                    transform="translate(6.75 9)"
                                ></path>
                            </svg>
                            <span onClick={() => handleShowSaveLater(m.product_id)} className="cursor-pointer"> &nbsp;Save for later</span></div>
                        {(parseInt(m.product_regular_price) !== parseInt(m.product_sale_price)) && (m.product_regular_price && m.product_sale_price != null) ?
                            <span className="discount-tile">DISCOUNTED</span>
                            // <button className="cartDiscountBtn">

                            // </button>
                            : ""}
                    </div>
                </div>
                <AlertDeleteCartsModal
                    deleteCart={() => { delMyCartItem() }}
                    handleClose={handleCloseDelete}
                    show={showdeletecartmodal}
                />
                <AlertSaveModal
                    saveForLater={() => { saveForLater() }}
                    handleClose={handleCloseSave}
                    show={showsavemodal}
                />
            </div>

        )
    }

    const renderSessionCard = (m, index) => {
        return (
            <div className="cart-description" key={index}>
                {renderThumbnail(m)}
                <div className="desc-content d-flex flex-column justify-content-between">
                    <div className="wrapper-desc cursor-pointer">
                        <p dangerouslySetInnerHTML={{
                            __html: m.product_name,
                        }}
                            onClick={() => {
                                if(m?.product_type === 'certificate'){
                                    history.push(`/certify-detail?certify_id=${m?.eventId}&certify_code=${m?.event_code}&source=xsel`);
                                }else{
                                    history.push(`/event-detail?event_id=${m?.eventId}&event_code=${m?.event_code}&source=ecom`);
                                }
                                
                            }}
                        ></p>

                        {/* REALTOR® Safety -<br /> Health and Safety</p> */}
                        <div className="desc-wrap content-cart">
                            {m.product_sale_price === m}
                            <h2><span className="dollar-normal">$</span>{m.product_sale_price.split('.')[0]}<sup>{m.product_sale_price.split('.')[1]}</sup>&nbsp;<span>CAD</span></h2>
                            {/* <div className="button-reset cursor-pointer" onClick={() => delMyCartItem(m.product_id)}> */}
                            <div className="button-reset cursor-pointer" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    onClick={() => handleShowForDelete(m.product_id)}>
                                    <defs>
                                        <linearGradient
                                            id="linear-gradient"
                                            x2="1"
                                            y1="0.5"
                                            y2="0.5"
                                            gradientUnits="objectBoundingBox"
                                        >
                                            <stop offset="0" stopColor="#00b7f1"></stop>
                                            <stop offset="1" stopColor="#ed156e"></stop>
                                        </linearGradient>
                                    </defs>
                                    <circle
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradient)"
                                        data-name="Ellipse 57"
                                    ></circle>
                                    <circle
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        fill="#fff"
                                        data-name="Ellipse 179"
                                        transform="translate(2 2)"
                                    ></circle>
                                    <path
                                        fill="#7a549e"
                                        d="M10.582.784h-2.94l-.23-.458A.588.588 0 006.885 0h-2.8a.581.581 0 00-.524.326l-.23.458H.392A.392.392 0 000 1.176v.784a.392.392 0 00.392.392h10.19a.392.392 0 00.392-.392v-.784a.392.392 0 00-.392-.392zM1.3 11.439a1.176 1.176 0 001.173 1.1H8.5a1.176 1.176 0 001.173-1.1l.519-8.3H.784z"
                                        data-name="Icon awesome-trash"
                                        transform="translate(9.514 8.729)"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex inner-content">
                        <p></p>
                        {_.toNumber(m.product_regular_price) !== _.toNumber(m.product_sale_price) ? <div className=" shop-cost">
                            <p>Retail price:</p>
                            <p> ${_.toNumber(m.product_regular_price).toFixed(2)}&nbsp;CAD</p>
                        </div> : ""}
                        {/* <p className="price-order">$29.99</p> */}
                    </div>
                    <div className="cart-icon d-flex inner-content cart_discount" >
                        {(m.product_type === 'session' || m.product_type === 'certificate') ? <div className="button-reset cart_discount"></div> :
                            <div className="button-reset cart_discount">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    data-name="Save for later"
                                    viewBox="0 0 30 30"
                                    onClick={() => handleShowSaveLater(m.product_id)}
                                >
                                    <defs>
                                        <linearGradient
                                            id="linear-gradient"
                                            x2="1"
                                            y1="0.5"
                                            y2="0.5"
                                            gradientUnits="objectBoundingBox"
                                        >
                                            <stop offset="0" stopColor="#00b7f1"></stop>
                                            <stop offset="1" stopColor="#ed156e"></stop>
                                        </linearGradient>
                                    </defs>
                                    <circle
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradient)"
                                        data-name="Ellipse 57"
                                    ></circle>
                                    <circle
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        fill="#fff"
                                        data-name="Ellipse 179"
                                        transform="translate(2 2)"
                                    ></circle>
                                    <path
                                        fill="var(--color-element-1)"
                                        d="M10.5 12a6 6 0 116-6 6.007 6.007 0 01-6 6zm-.75-8.688v3.3l2.078 2.078.8-.8-1.753-1.744V3.312zM4.5 10.5H.75a.75.75 0 010-1.5h2.873a7.693 7.693 0 00.877 1.5zM3.037 6.75H1.5a.75.75 0 110-1.5h1.537L3 6l.038.749zM3.623 3H2.25a.75.75 0 110-1.5H4.5A7.706 7.706 0 003.623 3z"
                                        data-name="Exclusion 7"
                                        transform="translate(6.75 9)"
                                    ></path>
                                </svg>
                                <span onClick={() => handleShowSaveLater(m.product_id)} className="cursor-pointer"> &nbsp;Save for later</span>
                            </div>}
                        {(parseInt(m.product_regular_price) !== parseInt(m.product_sale_price)) && (m.product_regular_price && m.product_sale_price != null) ?
                            <span className="discount-tile">DISCOUNTED</span>
                            // <button className="cartDiscountBtn">

                            // </button>
                            : ""}
                    </div>
                </div>
                <AlertDeleteCartsModal
                    deleteCart={() => { delMyCartItem() }}
                    handleClose={handleCloseDelete}
                    show={showdeletecartmodal}
                />
                <AlertSaveModal
                    saveForLater={() => { saveForLater() }}
                    handleClose={handleCloseSave}
                    show={showsavemodal}
                />
            </div>
        )
    }

    return (
        <>
            {myCartDetails.length != 0 ? (myCartDetails.map((m, index) => {
                return (
                    <>
                        {m.product_type === 'course' && renderCartCard(m, index)}
                        {m.product_type === 'videos' && renderCartCard(m, index)}
                        {(m.product_type === 'session' || m.product_type === 'certificate') && renderSessionCard(m, index)}
                    </>
                );
            })
            ) : <p> </p>
            }
        </>
    )
}

export default CartCourseCard;