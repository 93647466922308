import React, { createRef, useEffect, useState } from "react";
import Page from "../../components/Page";
import Slider from "react-slick";
import PrevArrow from "../../components/LearningPaths/PrevArrow";
import NextArrow from "../../components/LearningPaths/NextArrow";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar, Tab, Tabs } from "react-bootstrap";
import { useRef } from "react";
import {
  acceptRoleReq,
  acceptRoleReqClear,
  getRoleRequestedAs,
  getUserOfRoleAccepted,
  UserRoleCancel,
  UserRoleCancelClear,
  getCheckListApproval,
  getCheckListApprovalResClear
} from "../../redux/user/actions";
import { convertTimeFormat, SessionKeys, timezoneArea } from "../../utils/AppUtils";
import ConfirmModal from "../../components/modal/ConfirmModal";
//import { roleServingList } from "../../utils/testJson";
import { ListItemButton } from "@mui/material";
import _ from "lodash";
import AlertModalRemoveCoach from "./alertModalRemoveCoach";
import AlertModalRemoveMentor from "./alertModalRemoveMentor";

import AlertModalRemoveTeamLead from "./alertModalRemoveTeamLead";
import Loader from "../../components/Loader/Loader";
import AllAcceptModal from "./allAcceptModal";
import Pagination from "../Orders/Pagination";
import { useMemo } from "react";
import ShimmerCardBody from "../../components/Layouts/ShimmerCardBody";
import ShimmerAcceptRole from "../../components/Layouts/ShimmerAcceptRole";
import { useHistory } from "react-router-dom";
import moment from "moment";
//import LearningpathViewModal from "./LearningpathViewModal";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import { checkList } from "../../utils/checklistlp";
import CheckListApprPopUp from "./CheckListApprPopUp";
import CONFIG from "../../utils/config";

const sliderRef = createRef();
let PageSize = 10;
const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  dots: true,
  arrows: true,
  autoplaySpeed: 2000,
  infinite: false,
  centerMode: false,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const AcceptRole = () => {

  const _searchRef = useRef(null);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const userRole = useSelector(state => state.user.role)
  const userDetailRes = useSelector(state => state.user.userBroker)
  const acceptRoleReq1 = useSelector((state) => state.user.acceptRoleReq);
  const roleRequestedData = useSelector((state) => state.user.roleRequested);
  const usersOfRoleAccepted = useSelector(
    (state) => state.user.userOfRoleAccepted
  );
  //check list approval -managers -list
  const checkListDetail = useSelector((state) => state.user.checkListApprovalList);
  // console.log("Users", usersOfRoleAccepted);
  const usersOfRoleAcceptanceCancel = useSelector(
    (state) => state.user.userRoleCancel
  );
  const authBroker = useSelector(state => state.auth.authBroker)
  const [timezone, setTimezone] = useState({})
  const [roleRequested, setRoleRequested] = useState([]);
  const [totalReq, setTotalReq] = useState(0);
  const [reason, setReason] = useState("");
  const [confirmModal1, setConfirmModal1] = useState(false);
  const [showModalMsg1, setShowModalMsg1] = useState("");
  const [showRemovemodal, setShowRemoveModal] = useState(false);

  const [roleServing, setRoleServing] = useState([]);
  const [showRemovemodalTL, setShowRemoveModalTL] = useState(false);
  const [showRemovemodalMentor, setShowRemoveModalMentor] = useState(false);
  const [showRemovemodalCoach, setShowRemoveModalCoach] = useState(false);
  const [showAllAccept, setShowAllAccept] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);

  const [currentSelectedUser, setcurrentSelectedUser] = useState(null);
  // const [actualUsersList, setActualUsersList] = useState(roleServingList.data);

  const [isLoading, setIsLoading] = useState(true);
  const [startLoader, setStartLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [traverseRoleRequested, setTraverseRoleRequested] = useState();
  const [lpChecklistTabKey, setLPChecklistTabKey] = useState('home')

  //Pagination Work
  const [currentPage, setCurrentPage] = useState(1);
  const [mentorReports, setMentorReports] = useState(null);

  const [currentCoachPage, setCurrentCoachPage] = useState(1);
  const [coachReports, setCoachReports] = useState(null);

  const [currentTeamLeadPage, setCurrentTeamLeadPage] = useState(1);
  const [teamLeadReports, setTeamLeadReports] = useState(null);
  const [checkListLp, setCheckListLp] = useState([])
  const [currentCheckListPage, setCurrentCheckListPage] = useState(1);
  const [checkListData, setCheckListData] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState(false)
  const [filterList, setFilterList] = useState([])
  const [confirmMesg, setConfirmMesg] = useState([])  
  const [confirmModal2, setConfirmModal2] = useState(false);
  //const profileReportRes = useSelector((state) => state.report.profileReport);

  // console.log("Actual :", actualUsersList);
  // console.log("COnfirmation messg", confirmMesg,confirmModal2)
  const [reaRole, setReaRole] = useState([]);

  useEffect(() => {
    // console.log('USER ROLE', auth)
    if (!_.isEmpty(auth)) {
      setReaRole(auth?.authBroker?.payload?.user?.role);
    }
  }, [auth]);

  //Pagination Work
  useEffect(() => {
    // console.log(
    //   "Mentor inside useeffect",
    //   usersOfRoleAccepted?.payload?.payload
    // );
    if (
      !_.isEmpty(usersOfRoleAccepted) &&
      usersOfRoleAccepted?.status === "success"
    ) {
      // console.log(
      //   "User Data is coming",
      //   usersOfRoleAccepted?.payload?.payload?.mentor
      // );
      setMentorReports(usersOfRoleAccepted?.payload?.payload?.mentor);
      setCurrentPage(1);
    }
  }, [usersOfRoleAccepted?.payload?.payload]);

  useEffect(() => {
    // console.log(
    //   "Coach inside useeffect",
    //   usersOfRoleAccepted?.payload?.payload
    // );
    if (
      !_.isEmpty(usersOfRoleAccepted) &&
      usersOfRoleAccepted?.status === "success"
    ) {
      // console.log(
      //   "Coach Data is coming",
      //   usersOfRoleAccepted?.payload?.payload?.coach
      // );
      setCoachReports(usersOfRoleAccepted?.payload?.payload?.coach);
      // console.log(
      //   "Coach data inside",
      //   usersOfRoleAccepted?.payload?.payload?.coach
      // );
      setCurrentCoachPage(1);
    }
  }, [usersOfRoleAccepted?.payload?.payload]);

  useEffect(() => {
    // console.log(
    //   "Team lead inside useeffect",
    //   usersOfRoleAccepted?.payload?.payload
    // );
    if (
      !_.isEmpty(usersOfRoleAccepted) &&
      usersOfRoleAccepted?.status === "success"
    ) {
      console.log(
        "Team lead Data is coming",
        usersOfRoleAccepted?.payload?.payload
      );
      setTeamLeadReports(usersOfRoleAccepted?.payload?.payload?.team_lead);
      setCurrentTeamLeadPage(1);
    }
  }, [usersOfRoleAccepted?.payload?.payload]);

  useEffect(() => {
    // console.log('userDetailRes: ', userDetailRes)
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
        const userDetails = userDetailRes.payload.payload;
        const timezoneOffset = !_.isEmpty(userDetails.timezone) ? userDetails.timezone.replace(':','') : '+0530'
        const area = timezoneArea(timezoneOffset)
        setTimezone(area)
    }
  }, [userDetailRes])

  const currentCheckListData = useMemo(() => {
    // console.log("Data of mentor", mentorReports);
    // console.log("Length of mentor", mentorReports?.length);
    if (checkListData !== null && checkListData !== undefined) {
      const firstPageIndex = (currentCheckListPage - 1) * PageSize;
      // console.log("First mentor page", firstPageIndex);
      const lastPageIndex = firstPageIndex + PageSize;
      return checkListData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentCheckListPage, checkListData]);

  const currentMentorData = useMemo(() => {
    // console.log("Data of mentor", mentorReports);
    // console.log("Length of mentor", mentorReports?.length);
    if (mentorReports !== null && mentorReports !== undefined) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      // console.log("First mentor page", firstPageIndex);
      const lastPageIndex = firstPageIndex + PageSize;
      return mentorReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, mentorReports]);

  const currentCoachData = useMemo(() => {
    // console.log("Data of coach", coachReports);
    console.log("Length of coach", coachReports);
    if (coachReports !== null && coachReports !== undefined) {
      const firstPageIndex = (currentCoachPage - 1) * PageSize;
      // console.log("First coach page", firstPageIndex);
      const lastPageIndex = firstPageIndex + PageSize;
      return coachReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentCoachPage, coachReports]);

  const currentTeamLeadData = useMemo(() => {
    console.log("Data of team lead", teamLeadReports);
    // console.log("Length of team lead", teamLeadReports?.length);
    if (teamLeadReports !== null && teamLeadReports !== undefined) {
      const firstPageIndex = (currentTeamLeadPage - 1) * PageSize;
      // console.log("First team lead page", firstPageIndex);
      const lastPageIndex = firstPageIndex + PageSize;
      return teamLeadReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentTeamLeadPage, teamLeadReports]);

  const handleShowRemovUserCoach = () => {
    setShowRemoveModalCoach(true);
  };
  const handleShowRemovUserMentor = () => {
    setShowRemoveModalMentor(true);
  };
  const handleShowRemovUserTL = () => {
    setShowRemoveModalTL(true);
  };
  const handleCloseRemoveCoach = () => {
    setShowRemoveModalCoach(false);
  };
  const handleCloseRemoveMentor = () => {
    setShowRemoveModalMentor(false);
  };
  const handleCloseRemoveTL = () => {
    setShowRemoveModalTL(false);
  };

  const handleShowAllAccept = () => {
    // console.log("accept all");
    setShowAllAccept(true);
  };

  const handleCloseAllAccept = () => {
    setShowAllAccept(false);
  };

  useEffect(() => {
    // console.log("didMount");
    window.scrollTo(0, 0);
    setIsLoading(true);
    getRoleReq();
    getUserOfRoleReq();
  }, []);

  const getRoleReq = () => {
    dispatch(
      getRoleRequestedAs({
        module: "getRoleRequested",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        },
      })
    );
  };

  const getUserOfRoleReq = () => {
    dispatch(
      getUserOfRoleAccepted({
        module: "getUserOfRoleAccepted",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        },
      })
    );
  };

  const searchUserOfRoleAccepted = (search) => {
    dispatch(
      getUserOfRoleAccepted({
        module: "getUserOfRoleAcceptedSearch",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          search: search,
        },
      })
    );
  };

  useEffect(() => {
    if (roleRequested) {
      for (let obj of roleRequested) {
        obj.reason = "";
      }
    }
  }, [roleRequested]);

  useEffect(() => {
    // console.log("roleRequestedResponse: ", roleRequestedData);
    if (!_.isEmpty(roleRequestedData)) {
      setIsLoading(false);
      if (roleRequestedData?.status === "success") {
        setRoleRequested(roleRequestedData?.payload?.payload);
        setTotalReq(roleRequestedData?.payload?.payload?.length);
      }
    }
  }, [roleRequestedData]);
  // user role accepted data
  useEffect(() => {
    // console.log("accepted role data", usersOfRoleAccepted?.payload?.payload);
    if (usersOfRoleAccepted?.status === "success") {
      setRoleServing(usersOfRoleAccepted?.payload?.payload);

      // setActualUsersList(usersOfRoleAccepted?.payload?.payload);
    }
  }, [usersOfRoleAccepted]);

  useEffect(() => {
    // console.log("acceptRejectRes: ", acceptRoleReq1);
    if (acceptRoleReq1?.status === "success") {
      getRoleReq();
      getUserOfRoleReq();
      setConfirmModal1(true);
      setShowModalMsg1(acceptRoleReq1?.payload?.message);
      dispatch(acceptRoleReqClear());
    }
  }, [acceptRoleReq1]);

  const acceptRejectRole = (reqId, status, reason) => {
    setIsLoading(true);
    dispatch(
      acceptRoleReq({
        module: "acceptRoleReq",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          request_id: reqId,
          status: status,
          reason: reason,
        },
      })
    );
  };

  const acceptAll = (reqId, status, reason) => {
    let reqArr = [];
    setIsLoading(true);
    for (let item of roleRequested) {
      let obj = {
        request_id: item.request_id,
        status: "Accept",
        reason: "I like it",
      };
      reqArr.push(obj);
    }

    dispatch(
      acceptRoleReq({
        module: "acceptRoleReqAll",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          data: reqArr,
        },
      })
    );
  };
  //Delete functionality lead, coach and mentor role
  const getRoleReqCancel = (role_name, user_id) => {
    dispatch(
      UserRoleCancel({
        module: "getUserRoleAcceptanceCancel",
        postData: {
          user_id,
          role_name,
          sender_id: auth.authBroker?.payload?.user.user_id,
        },
      })
    );
    setShowRemoveModalCoach(true);
    handleCloseRemoveCoach();
    setShowRemoveModalMentor(true);
    handleCloseRemoveMentor();
    setShowRemoveModalTL(true);
    handleCloseRemoveTL();
  };
  useEffect(() => {
    // getRoleReqCancel();
    // console.log("User Role delete data record", usersOfRoleAcceptanceCancel);
    if (!_.isEmpty(usersOfRoleAcceptanceCancel)) {
      setIsLoading(false);
      // console.log("request cancel", usersOfRoleAcceptanceCancel);
      if (usersOfRoleAcceptanceCancel?.status === "success") {
        getUserOfRoleReq();
        dispatch(UserRoleCancelClear());
      }
    }
  }, [usersOfRoleAcceptanceCancel]);

  //User Role Request Status
  // useEffect(() => {
  //     dispatch(UserRoleRequestStatus({
  //         module: "userRoleRequestStatus",
  //         postData: { token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER) }
  //     }))
  // },[usersOfRoleAcceptanceStatus])
  const searchCourses = (list, searchText) => {
    // return list.coach.map((item) => {
    //   return (
    //     item.name != null &&
    //     item.name.toLowerCase().includes(searchText.toLowerCase())
    //   );
    // });

    const Data = {
      coach: list.coach.filter(function (item) {
        return item.first_name
          .toLowerCase()
          .includes(
            searchText.toLowerCase(),
            item.last_name.toLowerCase().includes(searchText.toLowerCase())
          );
      }),
      mentor: list.mentor.filter(function (item) {
        return item.first_name
          .toLowerCase()
          .includes(
            searchText.toLowerCase(),
            item.first_name.toLowerCase().includes(searchText.toLowerCase())
          );
      }),
      team_lead: list.team_lead.filter(function (item) {
        return item.first_name
          .toLowerCase()
          .includes(
            searchText.toLowerCase(),
            item.first_name.toLowerCase().includes(searchText.toLowerCase())
          );
      }),
    };
    return Data;
  };
  const onSearchList = (e) => {
    // const sortedLisbyDes = searchCourses(actualUsersList, e.target.value);

    // setRoleServing(sortedLisbyDes);
    setSearchValue(e.target.value);
  };

  const getTimeDiff = (unixTime) => {
    let diffTime = ''
    let postTime = moment.unix(unixTime)
    let currentTime = moment().utc()
    let duration = currentTime.diff(postTime, 'seconds')
    if (duration > 86400) {
      diffTime = currentTime.diff(postTime, 'days') + ' day(s) ago';
    } else {
      diffTime = convertTimeFormat(duration, true) + ' ago';
    }

    return diffTime
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);

  }


  // console.log("Check list data", checkList)

  // ********* Check list approval by managers section   *******//
  useEffect(() => {
    fetchChecklistData(0)
  }, [dispatch])


  useEffect(() => {
    dispatch(getCheckListApprovalResClear());
  }, [checkListData])

  useEffect(() => {
    if (!_.isEmpty(checkListDetail)) {
      if (checkListDetail?.status === "success") {
        setCheckListData(checkListDetail?.payload?.payload?.checklist_array)
        setFilterList(checkListDetail?.payload?.payload?.checklist_array)
        setCurrentCheckListPage(1)
      } else {
        setCheckListData([])
      }
      setStartLoader(false);
      dispatch(getCheckListApprovalResClear());
    }
  }, [checkListDetail]);

  const searchChecklist = (list, searchText) => {
    return list.filter((item) => {
      return (
        item.rea_name != undefined && item.rea_name != "" && item.rea_name != null &&
        item.rea_name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.course_name != undefined && item.course_name != "" && item.course_name != null &&
        item.course_name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.lp_name != undefined && item.lp_name != "" && item.lp_name != null && item.lp_name
          .toLowerCase().includes(searchText.toLowerCase())
      );
    });
  };
  const onSearchCheckList = (e) => {
    const sortedListbyDes = searchChecklist(filterList, e.target.value);
    setCheckListData(sortedListbyDes);
  }


  const renderAlertCard = () => {
    return (
      <div
        className={`alert-card d-flex justify-content-center checklist-alert ${confirmModal2 ? "" : "d-none"
          }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </svg>
            <p className="m-0">{confirmMesg}</p>
          </div>
          <div className="view-cart-icon">
            {/* <span
              className="cursor-pointer"
              onClick={() => {
                clickStreamUser("go-to-cart-from-shopping-cart-btn-clicked", {
                  userId: auth.authBroker?.payload.user.user_id,
                  ecommerceId: courseId,
                });
                history.push("/cart");
              }}
            >
              View Cart
            </span> */}
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setConfirmModal2(false);
                setConfirmMesg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const fetchChecklistData = (status) => {
    dispatch(
      getCheckListApproval({
        module: "checklisttApproval-detail",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          status: status
        }
      })
    );
  }

  const changeLPChecklistTab = (key) => {
    if (key !== lpChecklistTabKey) {
      setLPChecklistTabKey(key)
      setStartLoader(true)
    }
    fetchChecklistData(key === 'home' ? 0 : 1)
  }

  return (
    <>
     <Loader start={startLoader} />
      <Page>
        <>
          {/* <Loader start={isLoading} /> */}
          {isLoading ? (
            [1].map((i) => {
              return <ShimmerAcceptRole key={`shimmer-${i}`} />;
            })
          ) : (
            <section className="rsp-accpet-role-page my-credit-points">
              {/* <div className="container">
                {renderAlertCard()}
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <h1 className="requesthead">
                      Requests <span className="boxsldie">{totalReq}</span>
                    </h1>
                  </div>
                  {roleRequested && roleRequested.length > 0 ? (
                    <div className="col-lg-6 col-md-6">
                      <div className="accept-all btnrightalignacc">
                        {totalReq > 1 ? (
                          <button
                            type="button"
                            className="btn btn-primary-startNow"
                            onClick={() => {
                              handleShowAllAccept();
                            }}
                          >
                            Accept All
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="role-list-rsp">
                  {roleRequested && roleRequested.length > 0 ? (
                    (isViewAll
                      ? roleRequested
                      : roleRequested.length > 5
                        ? roleRequested.slice(0, 5)
                        : roleRequested
                    ).map((item, index) => {
                      return (
                        <>
                          <div className="row accpet-box">
                            <div className="col-lg-6 col-md-6">
                              <div className="list-rsp-box">
                                <div className="prfoli-box">
                                  <img src={item?.profile_pic} alt="" />
                                </div>
                                <div className="rsp-list-desc">
                                  <p className="rsp-list-dec-sort">
                                    {item.user_name} has requested you to be
                                    his/her{" "}
                                    {item?.user_role === "team_lead"
                                      ? "Team Lead"
                                      : item?.user_role === "mentor"
                                        ? "Mentor"
                                        : "Coach"}
                                    .
                                  </p>
                                  <p className="rsp-acp-time">{getTimeDiff(item?.datetime)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="accpet-box-right">
                                <div className="accpet-box-btn-list">
                                  <button
                                    type="button"
                                    className="btn btn-primary-startNow acpt-btn-rsp"
                                    onClick={() => {
                                      acceptRejectRole(
                                        item?.request_id,
                                        "Accept",
                                        item?.reason
                                      );
                                    }}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary-startNow acpt-btn-rsp"
                                    onClick={() => {
                                      acceptRejectRole(
                                        item?.request_id,
                                        "Reject",
                                        item?.reason
                                      );
                                    }}
                                  >
                                    Decline
                                  </button>
                                </div>
                                <div className="input-text position-relative d-flex res-btn-rsp">
                                  <label
                                    htmlFor="reason"
                                    className="input__label"
                                  >
                                    Reason
                                  </label>
                                  <input
                                    type="text"
                                    name="reason"
                                    className="input_field mr-2"
                                    placeholder=""
                                    value={item.reason}
                                    onChange={(evt) => {
                                      // console.log(
                                      //   "ontypereason: ",
                                      //   evt.target.value
                                      // );
                                      item.reason = evt.target.value;
                                      // console.log("ontypereason1: ", item.reason);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="not-found mt-3">
                      <div className="interboxes">
                        <img src="/images/telescope.svg" alt="Not Found" />
                        <p>No request found</p>
                      </div>
                    </div>
                  )}

                  {totalReq > 5 ? (
                    <div
                      className="viewall cursor-pointer"
                      onClick={() => {
                        setIsViewAll(!isViewAll);
                      }}
                    >
                      <a className="viewallbtn">
                        {isViewAll ? "- View Less" : "+ View All"}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}
              {
                 authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && (

                userRole === 'manager' ?
                  <div>
                    {/* <div id="myOnboard" className="center_line m-0"></div> */}
                    <div className="list-users">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="requesthead mb-1">My Team Learning Path</h2>
                            <p className="subtextlearn">List of team members waiting for checklist approval options</p>
                            <div className="usr-search profile-left-sec">
                              <div className="input-text position-relative d-flex">
                                <label htmlFor="usrsearch" className="input__label">
                                  Search
                                </label>
                                <input
                                  type="text"
                                  id="usersearch"
                                  name="usrsearch"
                                  className="input_field mr-2"
                                  placeholder="Search here"
                                  ref={_searchRef}
                                  autocomplete="off"
                                  onChange={onSearchCheckList}
                                />
                                <button
                                  className="button-reset"
                                  style={{ marginTop: "-20px" }}
                                  onClick=""
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    id="Find"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                  >
                                    <defs>
                                      <linearGradient
                                        id="linear-gradient"
                                        y1="0.5"
                                        x2="1"
                                        y2="0.5"
                                        gradientUnits="objectBoundingBox"
                                      >
                                        <stop offset="0" stop-color="#00b7f1" />
                                        <stop offset="1" stop-color="#ed156e" />
                                      </linearGradient>
                                    </defs>
                                    <circle
                                      id="Ellipse_191"
                                      data-name="Ellipse 191"
                                      cx="15"
                                      cy="15"
                                      r="15"
                                      fill="url(#linear-gradient)"
                                    />
                                    <circle
                                      id="Ellipse_192"
                                      data-name="Ellipse 192"
                                      cx="13"
                                      cy="13"
                                      r="13"
                                      transform="translate(2 2)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Icon_awesome-search"
                                      data-name="Icon awesome-search"
                                      d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                                      transform="translate(7.715 7.714)"
                                      fill="var(--color-element-1)"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row list-coach learnigpathsectio">
                          <div className="col-lg-12 col-md-12">
                            <div className="rows my_courses">
                              <Tabs defaultActiveKey="home" className="mb-3 mt-2" 
                              onSelect={(e) => {
                                if (e !== lpChecklistTabKey) {
                                  changeLPChecklistTab(e)
                                  setCheckListData([])
                                }
                              }}>
                                <Tab eventKey="home" title="Pending Approvals">
                                  <div className="pending-approval">
                                    <div className="table-responsive1">
                                      <Slider ref={sliderRef} {...settings}>
                                        <div className="tablebox">
                                          <table className="table mb-0 checklist_list_table office_list dataTable no-footer">
                                            <thead>
                                              <tr>
                                                <th className="office-province">
                                                  Name
                                                </th>
                                                <th className="office-province">
                                                  Course/Level
                                                </th>
                                                <th className="office-province">
                                                  Learning Path
                                                </th>
                                                <th className="office-province text-center actiontable">
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              {(currentCheckListData) &&
                                                currentCheckListData?.length > 0 ?
                                                currentCheckListData?.map((item, i) => {
                                                  return (
                                                    <tr className="addBGcustom" key={i}>
                                                      <td>
                                                        {item?.rea_name}
                                                      </td>
                                                      <td>
                                                        {item?.course_name}
                                                      </td>
                                                      <td>
                                                        {item?.lp_name}
                                                      </td>
                                                      <td className="text-center actionbtnd pe-0 cursor-pointer">
                                                        <div onClick={() => {
                                                          handleShow();
                                                          setCheckListLp(item)
                                                        }
                                                        }>
                                                          <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            id="View" width="24" height="24" viewBox="0 0 24 24">
                                                            <defs>
                                                              <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                                <stop offset="0" stop-color="#00b7f1" />
                                                                <stop offset="1" stop-color="#ed156e" />
                                                              </linearGradient>
                                                            </defs>
                                                            <circle id="Ellipse_202" data-name="Ellipse 202" cx="12" cy="12" r="12" fill="url(#linear-gradient)" />
                                                            <circle id="Ellipse_203" data-name="Ellipse 203" cx="10" cy="10" r="10" transform="translate(2 2)" fill="#fff" />
                                                            <path id="eye" d="M7.142,7.013A1.675,1.675,0,1,0,8.816,8.688,1.675,1.675,0,0,0,7.142,7.013m0,4.468A2.792,2.792,0,1,1,9.933,8.688a2.792,2.792,0,0,1-2.792,2.792m0-6.981A6.6,6.6,0,0,0,1,8.688a6.6,6.6,0,0,0,12.283,0A6.6,6.6,0,0,0,7.142,4.5Z" transform="translate(4.858 3.311)" fill="var(--color-element-1)" />
                                                          </svg>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                }) :
                                                <tr>
                                                  <td colSpan="4" className="p-0">
                                                    <div className="not-found">
                                                      <div className="interboxes">
                                                        <img
                                                          src="/images/telescope.svg"
                                                          alt="Not Found"
                                                          className="checklist-not-found"
                                                        />
                                                        <p
                                                        >List of team members waiting for checklist approval listed here.</p>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              }
                                            </tbody>
                                          </table>
                                          {checkListData?.length ? (
                                            <Pagination
                                              className="pagination-bar"
                                              currentPage={currentCheckListPage}
                                              totalCount={checkListData?.length}
                                              pageSize={PageSize}
                                              onPageChange={(page1) => {
                                                setCurrentCheckListPage(page1);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Slider>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab eventKey="start" title="Approved">
                                  <div>
                                    <div className="table-responsive1">
                                        <Slider ref={sliderRef} {...settings}>
                                          <div className="tablebox">
                                            <table className="table mb-0 checklist_list_table office_list dataTable no-footer">
                                              <thead>
                                                <tr>
                                                  <th className="office-province">
                                                    Name
                                                  </th>
                                                  <th className="office-province">
                                                    Course
                                                  </th>
                                                  <th className="office-province">
                                                    Learning Path
                                                  </th>
                                                  <th className="office-province text-center reviewdatetable">
                                                    Review Date
                                                  </th>
                                                </tr>
                                              </thead>

                                              <tbody>
                                                {(currentCheckListData) &&
                                                  currentCheckListData?.length > 0 ?
                                                  currentCheckListData?.map((item, i) => {
                                                    return (
                                                      <tr className="addBGcustom" key={i}>
                                                        <td>
                                                          {item?.rea_name}
                                                        </td>
                                                        <td>
                                                          {item?.course_name}
                                                        </td>
                                                        <td>
                                                          {item?.lp_name}
                                                        </td>
                                                        <td className="text-center actionbtnd pe-0 cursor-pointer">
                                                          {item.reviewed_date ? 
                                                          moment.unix(item.reviewed_date).tz(timezone).format('DD MMM YYYY, hh:mm: A z')
                                                          : '-'}
                                                        </td>
                                                      </tr>
                                                    )
                                                  }) :
                                                  <tr>
                                                    <td colSpan="4" className="p-0">
                                                      <div className="not-found">
                                                        <div className="interboxes">
                                                          <img
                                                            src="/images/telescope.svg"
                                                            alt="Not Found"
                                                            className="checklist-not-found"
                                                          />
                                                          <p
                                                          >List of team members whose checklist are approved are listed here.</p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                }
                                              </tbody>
                                            </table>
                                            {checkListData?.length ? (
                                              <Pagination
                                                className="pagination-bar"
                                                currentPage={currentCheckListPage}
                                                totalCount={checkListData?.length}
                                                pageSize={PageSize}
                                                onPageChange={(page1) => {
                                                  setCurrentCheckListPage(page1);
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </Slider>
                                    </div>
                                  </div>
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CheckListApprPopUp
                        show={show}
                        handleClose={handleClose}
                        checkListLp1={checkListLp}
                        setApprovalStatus={setApprovalStatus}
                        approvalStatus={approvalStatus}
                        setIsLoading={setIsLoading}
                        setConfirmMesg={setConfirmMesg}
                        confirmMesg={confirmMesg}
                        setConfirmModal2={setConfirmModal2}
                        tabKey={lpChecklistTabKey}
                        reloadData={changeLPChecklistTab}
                      />
                    
                    </div>
                  </div>
                : ""
                
                )
                
              }
              {
                 authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && (
                 <div id="myOnboard" className="center_line m-0"></div>
                 )
              }

             
              <div className="list-users">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="requesthead">List of Users</h2>

                      <div className="usr-search profile-left-sec">
                        <div className="input-text position-relative d-flex">
                          <label htmlFor="usrsearch" className="input__label">
                            Search
                          </label>
                          <input
                            type="text"
                            id="usersearch"
                            name="usrsearch"
                            className="input_field mr-2"
                            placeholder=" Search here"
                            value={searchValue}
                            onChange={onSearchList}
                          />
                          <button
                            className="button-reset"
                            style={{ marginTop: "-20px" }}
                            onClick={() => {
                              searchUserOfRoleAccepted(searchValue);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              id="Find"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradient"
                                  y1="0.5"
                                  x2="1"
                                  y2="0.5"
                                  gradientUnits="objectBoundingBox"
                                >
                                  <stop offset="0" stop-color="#00b7f1" />
                                  <stop offset="1" stop-color="#ed156e" />
                                </linearGradient>
                              </defs>
                              <circle
                                id="Ellipse_191"
                                data-name="Ellipse 191"
                                cx="15"
                                cy="15"
                                r="15"
                                fill="url(#linear-gradient)"
                              />
                              <circle
                                id="Ellipse_192"
                                data-name="Ellipse 192"
                                cx="13"
                                cy="13"
                                r="13"
                                transform="translate(2 2)"
                                fill="#fff"
                              />
                              <path
                                id="Icon_awesome-search"
                                data-name="Icon awesome-search"
                                d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                                transform="translate(7.715 7.714)"
                                fill="var(--color-element-1)"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row list-coach">
                    <div className="col-lg-6 col-md-12">
                      {!reaRole?.includes("coach") ? (
                        ""
                      ) : (
                        <div className="table-responsive1 mb-4">
                          <Slider ref={sliderRef} {...settings}>
                            <div className="tablebox ">
                              <table className="table mb-0 office_list dataTable no-footer">
                                <thead>
                                  <tr>
                                    <th className="office-province" colSpan="2">
                                      Coach to
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {/* {currentMentorData && currentMentorData?.length > 0 ? ( */}
                                  {currentCoachData &&
                                    currentCoachData?.length > 0 ? (
                                    currentCoachData?.map((item, index) => {
                                      return (
                                        <tr className="addBGcustom">
                                          <td className="corporate_manager">
                                            {
                                              <div className="checkrspbox">
                                                <span className="user_type_list corporate_manager" onClick={() => {
                                                  history.push('/profile/edit?section=edit&isOther=1&user_id=' + item._id)
                                                }}>  {/* history.push('/profile/edit?section=edit&isOther=1&user_id='+item._id) */}
                                                  {item?.first_name +
                                                    " " +
                                                    item?.last_name}
                                                </span>
                                              </div>
                                            }
                                          </td>
                                          <td>
                                            {item.email}{" "}
                                            {/* <img
                                              className="img-fluid closesrp"
                                              src="/images/cross.png"
                                              onClick={() => {
                                                setcurrentSelectedUser(item);
                                                handleShowRemovUserCoach(
                                                  "coach",
                                                  item._id
                                                );
                                              }}
                                            /> */}
                                            <svg id="Close" onClick={() => {
                                              setcurrentSelectedUser(item);
                                              handleShowRemovUserCoach(
                                                "coach",
                                                item._id
                                              );
                                            }} className="img-fluid closesrp" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="30" height="30" viewBox="0 0 30 30">

                                              <defs>
                                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                  <stop offset="0" stop-color="#00b7f1" />
                                                  <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                              </defs>
                                              <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                              <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                              <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                                                <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                                <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                              </g>
                                              <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                                                <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                                <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                              </g>
                                            </svg>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="not-found">
                                      <td colSpan="2">
                                        <img
                                          src="/images/telescope.svg"
                                          alt="Not Found"
                                        />
                                        <p>You're not a Coach to anyone yet.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {coachReports?.length ? (
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={currentCoachPage}
                                  totalCount={coachReports?.length}
                                  pageSize={PageSize}
                                  onPageChange={(page1) => {
                                    setCurrentCoachPage(page1);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            {/* <div className="tablebox">
                                                  <table className="table mb-0 office_list dataTable no-footer">
                                                      <thead>
                                                          <tr>

                                                              <th className="office-province" colSpan="2">Coach to</th>

                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr className="addBGcustom">
                                                              <td className="corporate_manager">
                                                                  <div className="checkrspbox">
                                                                      <span className="chkbx"><input type="checkbox" /><label for="terms_and_conditions"> </label></span> <span className="user_type_list corporate_manager">Rishabh Jain</span>
                                                                  </div>
                                                              </td>
                                                              <td>rishabh2@yopmail.com <img className="img-fluid closesrp" src="/images/cross.png" />

                                                              </td>
                                                          </tr>
                                                          <tr className="addBGcustom">
                                                              <td className="corporate_manager">
                                                                  <div className="checkrspbox">
                                                                      <span className="chkbx"><input type="checkbox" /><label for="terms_and_conditions"> </label></span> <span className="user_type_list corporate_manager">Rishabh Jain</span>
                                                                  </div>
                                                              </td>
                                                              <td>rishabh2@yopmail.com <img className="img-fluid closesrp" src="/images/cross.png" />

                                                              </td>
                                                          </tr>


                                                      </tbody>
                                                  </table>
                                              </div> */}
                          </Slider>
                        </div>
                      )}
                      <div className="table-responsive1 rsp-team-lead-table mt-0">
                        <table className="table mb-0 office_list dataTable no-footer">
                          <thead>
                            <tr>
                              <th className="office-province" colSpan="2">
                                Team Lead to
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {currentMentorData && currentMentorData?.length > 0 ? ( */}
                            {currentTeamLeadData &&
                              currentTeamLeadData?.length > 0 ? (
                              currentTeamLeadData?.map((item, index) => {
                                return (
                                  <tr className="addBGcustom">
                                    <td className="corporate_manager">
                                      {
                                        <div className="checkrspbox">
                                          <span className="user_type_list corporate_manager">
                                            {item?.first_name +
                                              " " +
                                              item?.last_name}
                                          </span>
                                        </div>
                                      }
                                    </td>
                                    <td>
                                      {item.email}{" "}
                                      {/* <img
                                        className="img-fluid closesrp"
                                        src="/images/cross.png"
                                        onClick={
                                          () => {
                                            setcurrentSelectedUser(item);
                                            handleShowRemovUserTL(
                                              "team_lead",
                                              item._id
                                            );
                                          }

                                          
                                        }
                                      /> */}
                                      <svg id="Close" onClick={
                                        () => {
                                          setcurrentSelectedUser(item);
                                          handleShowRemovUserTL(
                                            "team_lead",
                                            item._id
                                          );
                                        }
                                      } className="img-fluid closesrp" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="30" height="30" viewBox="0 0 30 30">

                                        <defs>
                                          <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#00b7f1" />
                                            <stop offset="1" stop-color="#ed156e" />
                                          </linearGradient>
                                        </defs>
                                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                        <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                                          <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                          <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                        </g>
                                        <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                                          <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                          <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                        </g>
                                      </svg>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr className="not-found">
                                <td colSpan="2">
                                  <img
                                    src="/images/telescope.svg"
                                    alt="Not Found"
                                  />
                                  <p>You're not a Team Lead to anyone yet.</p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {teamLeadReports?.length ? (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentTeamLeadPage}
                            totalCount={teamLeadReports?.length}
                            pageSize={PageSize}
                            onPageChange={(page1) => {
                              setCurrentTeamLeadPage(page1);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {!reaRole?.includes("mentor") ? (
                      ""
                    ) : (
                      <div className="col-lg-6 col-md-12">
                        <div className="table-responsive1 mentor-table">
                          <table className="table mb-0 office_list dataTable no-footer">
                            <thead>
                              <tr>
                                <th className="office-province" colSpan="2">
                                  Mentor to
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentMentorData &&
                                currentMentorData?.length > 0 ? (
                                currentMentorData?.map((item, index) => {
                                  return (
                                    <tr className="addBGcustom">
                                      <td className="corporate_manager">
                                        {
                                          <div className="checkrspbox">
                                            <span className="user_type_list corporate_manager">
                                              {item?.first_name +
                                                " " +
                                                item?.last_name}
                                            </span>
                                          </div>
                                        }
                                      </td>
                                      <td>
                                        {item.email}{" "}
                                        {/* <img
                                          className="img-fluid closesrp"
                                          src="/images/cross.png"
                                          onClick={
                                            () => {
                                              setcurrentSelectedUser(item);
                                              handleShowRemovUserMentor(
                                                "mentor",
                                                item._id
                                              );
                                            }
                                            // getRoleReqCancel("mentor", item._id)
                                          }
                                        /> */}
                                        <svg id="Close" onClick={
                                          () => {
                                            setcurrentSelectedUser(item);
                                            handleShowRemovUserMentor(
                                              "mentor",
                                              item._id
                                            );
                                          }
                                          // getRoleReqCancel("mentor", item._id)
                                        } className="img-fluid closesrp" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                          width="30" height="30" viewBox="0 0 30 30">

                                          <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                              <stop offset="0" stop-color="#00b7f1" />
                                              <stop offset="1" stop-color="#ed156e" />
                                            </linearGradient>
                                          </defs>
                                          <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                          <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                          <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                                            <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                            <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                          </g>
                                          <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                                            <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                            <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                          </g>
                                        </svg>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className="not-found">
                                  <td colSpan="2">
                                    <img
                                      src="/images/telescope.svg"
                                      alt="Not Found"
                                    />
                                    <p>You're not a Mentor to anyone yet</p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {mentorReports?.length ? (
                            <Pagination
                              className="pagination-bar"
                              currentPage={currentPage}
                              totalCount={mentorReports?.length}
                              pageSize={PageSize}
                              onPageChange={(page) => {
                                setCurrentPage(page);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <AlertModalRemoveCoach
                  RemoveUser1={() => {
                    getRoleReqCancel("coach", currentSelectedUser._id);
                  }}
                  handleCloseCoach={handleCloseRemoveCoach}
                  showCoach={showRemovemodalCoach}
                />
                <AlertModalRemoveMentor
                  RemoveUser2={() => {
                    getRoleReqCancel("mentor", currentSelectedUser._id);
                  }}
                  handleCloseMentor={handleCloseRemoveMentor}
                  showMentor={showRemovemodalMentor}
                />
                <AlertModalRemoveTeamLead
                  RemoveUser3={() => {
                    getRoleReqCancel("team_lead", currentSelectedUser._id);
                  }}
                  handleCloseTeamLead={handleCloseRemoveTL}
                  showTeamLead={showRemovemodalTL}
                />

                <AllAcceptModal
                  allAccept={() => {
                    handleCloseAllAccept();
                    acceptAll();
                  }}
                  handleCloseAllAccept={handleCloseAllAccept}
                  showAllAccept={showAllAccept}
                />
              </div>
            </section>
          )}

          <ConfirmModal
            confirmModal={confirmModal1}
            setConfirmModal={setConfirmModal1}
            showModalMsg={showModalMsg1}
            isConfirm={false}
            confirmId={""}
            confirmAction={function () { }}
            isRightIcon={true}
          />

          {/* {coachReports?.length ? (
            <Pagination
              className="pagination-bar"
              currentPage={currentCoachPage}
              totalCount={coachReports?.length}
              pageSize={PageSize}
              onPageChange={(page1) => {
                window.scroll({
                  top: 100,
                  left: 0,
                  behavior: "smooth",
                });
                setCurrentCoachPage(page1);
              }}
            />
          ) : (
            ""
          )} */}
        </>
      </Page>
    </>
  );
};

export default AcceptRole;
