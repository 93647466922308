import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import { couponCodeApply, couponCodeApplyClear } from "../../redux/Cart/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";


const CartSummaryCard = (props) => {

    const {
        myCartLength,
        subTotalPrice,
        totalPrice,
        setTotalPrice,
        setSubTotalPrice,
        proceedToPayment,
        userCreditPoint,
        setRedeemCard,
        redeemCard,
        couponDetail,
        setCouponDetail,
        isAppliedCoupon,
        setIsAppliedCoupon,
        confirmModel,
        setConfirmModal,
        showModalMsg,
        setShowModalMsg,
        couponCode,
        setCouponCode,
        redeemAmount,
        redeemPoint,
    } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const authBroker = useSelector(state => state.auth.authBroker)
    const applyCouponRes = useSelector(state => state.carts.couponCodeApplyRes);
    const [userCurrency, setUserCurrency] = useState(0)
    const [className, setClassName] = useState('')
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [isCurrentPage, setIsCurrentPage] = useState(false)

    useEffect(() => { setIsCurrentPage(false) }, [])

    useEffect(() => {
        // console.log('data: ', Object.keys(couponDetail).length)
        if (Object.keys(couponDetail).length > 0) {
            // console.log('data1: ', couponDetail)
            if (!isCurrentPage) {
                setIsAppliedCoupon(true)
                setCouponCode(couponDetail?.discountCode)
            }
        }
        else {
            // console.log('data2: ', couponDetail)
            setIsAppliedCoupon(false)
            setCouponCode('')
        }
    }, [couponDetail])

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false)
                setSuccessMsg('')
            }, 5000)
        }
    }, [success])

    useEffect(() => {
        if (userCreditPoint > 0) {
            setUserCurrency(userCreditPoint / 10)
        }
    }, [userCreditPoint])

    const applyCoupon = (isRemove) => {

        // console.log('sdlkjf')
        if (!couponCode) {
            setSuccess(true)
            setSuccessMsg('Please enter coupon code')
            return;
        } else if (_.toNumber(totalPrice) == 0 && !isAppliedCoupon) {
            setSuccess(true)
            setSuccessMsg('Order total is already zero.')
            return;
        }


        let reqObj = {
            module: 'applyCoupon',
            postData: { 
                email: authBroker.payload.user.email,
                coupon_code: couponCode
            },
        }
        if (isRemove === 1) {
            reqObj.postData.remove_coupon = "true"
        }

        dispatch(couponCodeApply(reqObj))
    }

    useEffect(() => {

        // console.log('couponCodeApplyRes12:, ', applyCouponRes)
        if (!_.isEmpty(applyCouponRes)) {

            if (applyCouponRes.status === 'success') {
                // console.log('couponCodeApplyRes:, ', applyCouponRes)
                let couponData = applyCouponRes.payload.data;
                // if (applyCouponRes.payload.status === true)
                //     setIsAppliedCoupon(true)
                // else setIsAppliedCoupon(false)
    
                couponData.discountAmount = _.toNumber(couponData.discountAmount)
                if (isAppliedCoupon) {
                    setIsAppliedCoupon(false)
                    setCouponCode('')
                    setIsCurrentPage(true)
                    setCouponDetail(couponData)
                }
                else {
                    setIsAppliedCoupon(true)
                    setCouponDetail(couponData)
                }
    
    
                setSuccessMsg(applyCouponRes.payload.message)
                setSuccess(true)
                dispatch(couponCodeApplyClear())
    
    
                
    
            } else if (applyCouponRes.status === 'error') {
                setSuccessMsg(applyCouponRes.error.message)
                setSuccess(true)
            }
            setTimeout(() => {
                // console.log("timeout")
                setSuccess(false)
                setSuccessMsg('')
            }, 5000)
        }

    }, [applyCouponRes])


    return (
        <div className="card border-light payment-card" style={{ maxWidth: '25rem' }}>
            <div className="payment-header">
                <p>{myCartLength}</p>
                <p>Item{myCartLength > 1 ? 's' : ''}</p>
            </div>
            <div className="card-body payment-body">
                <div className="">
                    <div className="d-flex justify-content-between sub-total">
                        <h4>Subtotal</h4>
                        <h2>
                            <span className="dollar-normal">$</span>
                            {subTotalPrice.toFixed(2).split('.')[0]}
                            <sup>{subTotalPrice.toFixed(2).split('.')[1]}</sup>&nbsp;<span>CAD</span>
                        </h2>
                    </div>
                    {isAppliedCoupon ? <div className="d-flex justify-content-between sub-total">
                        <h4>Discount</h4>
                        <h2 className="color-red">
                            <span className="dollar-normal">- $</span>
                            {couponDetail?.discountAmount?.toFixed(2).split('.')[0]}
                            <sup>{couponDetail?.discountAmount?.toFixed(2).split('.')[1]}</sup>&nbsp;<span>CAD</span>
                        </h2>
                    </div> : ""}
                    <div className={`d-flex justify-content-between sub-total ${!redeemCard ? 'color-white' : ''}`}>
                        <h4>Reward Points<br />redeemed</h4>
                        {redeemCard && <h2 className="color-red">
                            <span className="dollar-normal">- $</span>
                            {redeemAmount.toFixed(2).split('.')[0]}
                            <sup>{redeemAmount.toFixed(2).split('.')[1]}</sup> CAD
                            <p className="p-0">{redeemPoint} Reward Points</p>
                        </h2>}
                        {!redeemCard && <h2>
                            <span className="dollar-normal">- $</span>
                            0
                            <sup>00</sup>
                            <p className="p-0 color-white">00 Reward Points</p>
                        </h2>}
                    </div>
                    <div className="body-card">
                        <h5 className="card-title payment-title">Order Total</h5>
                        <h2>
                            <span className="dollar-normal">$</span>
                            {totalPrice.toFixed(2).split('.')[0]}
                            <sup>{totalPrice.toFixed(2).split('.')[1]}</sup>&nbsp;<span>CAD</span>
                        </h2>
                    </div>
                </div>
                <div className="pay-wrapper">
                    <div className="pay-title">
                        {/* <p className="card-text payment-text">$34.99</p> */}
                        <p>Excluding Taxes</p>
                    </div>
                    <div className="pay-perc">
                        {/* <p>-14%</p> */}
                    </div>
                </div>

                {userCreditPoint > 0 && <div className="cartContent d-flex  mt-3 ">
                    <div className="">
                        <input id="cart_status" type="checkbox" 
                        className={`${className} status redeem mt-0 ${(totalPrice === 0 && !redeemCard)? 'disabled' : ''}`} 
                        disabled={totalPrice === 0 && !redeemCard}
                        onChange={(e) => {
                            // console.log(e.target.checked)
                            if (e.target.checked && totalPrice > 0) {
                                setClassName('active-checked')
                                setRedeemCard(true)
                            } else if (!e.target.checked) {
                                setClassName('')
                                setRedeemCard(false)
                                if (couponDetail.discountAmount) {
                                    setTotalPrice(subTotalPrice - couponDetail.discountAmount)
                                } else {
                                    setTotalPrice(subTotalPrice)
                                }
                            }
                        }} />
                    </div>
                    <div className="d-flex flex-column">
                        <p>Redeem {userCreditPoint} Reward Points</p>
                        <span>(Equivalent to <span className="dollor19"> ${userCurrency.toFixed(2)}&nbsp;CAD)</span></span>
                    </div>
                </div>}

                <div className="cartContent d-flex flex-column mt-3 ">
                    <div className="input-text ">
                        <label htmlFor="coupon_code" className="input__label">Coupon Code</label>
                        <input id="coupon_code" type="text" className={`mt-0 input__field mb-1`} value={couponCode} 
                        autocomplete="off"
                        onChange={(evt) => {
                            if (evt.target.value.length === 0) {
                                if (success) {
                                    setSuccess(false)
                                    setSuccessMsg('')
                                }
                            }
                            setCouponCode(evt.target.value)}
                        } 
                        disabled={isAppliedCoupon ? "disabled" : ""} 
                        />
                    </div>
                    <button className="btn-procced btn-primary mt-0" onClick={() => { applyCoupon(isAppliedCoupon ? 1 : 0) }}>{isAppliedCoupon ? "Remove Coupon" : "Apply Coupon"}</button>

                </div>
                <div className="mb-2 login-msg">
                    {success && (<div>{successMsg}</div>)}
                </div>

            </div>
            <button className="btn-procced btn-primary"
                onClick={() => proceedToPayment()}>
                Proceed to Buy
            </button>
        </div>
    )
}

export default CartSummaryCard;