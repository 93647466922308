import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userDetail,
  userUpdate,
  userSelectUser,
  selectedTeamLead,
  selectedMentor,
  selectedCoach,
  searchUser,
  userDetailBroker,
  getLicensedAs,
  fetchTimezoneArray,
  userUpdateClear,
  UserRoleRequestStatus,
  UserRoleRequestStatusClear,
  fetchTimezoneAbbr,
} from "../../redux/user/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ConfirmModal from "../modal/ConfirmModal";
import _ from "lodash";
import Loader from "../Loader/Loader";
import CONFIG from "../../utils/config";

const ProfilEdit = (props) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.role);
  const auth = useSelector((state) => state.auth.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  // const userDetailRes = useSelector(state => state.user.user)
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const licensedAsRes = useSelector((state) => state.user.licensedAsRes);
  const userUpdateRes = useSelector((state) => state.user.userUpdateRes);
  const searchUserRes = useSelector((state) => state.user.searchUserRes);
  // const teamLead = useSelector(state => state.user.selectedTeamLead)
  // const mentor = useSelector(state => state.user.selectedMentor)
  // const coach = useSelector(state => state.user.selectedCoach)
  const [teamLead, setTeamLead] = useState("");
  const [mentor, setMentor] = useState("");
  const [coach, setCoach] = useState("");
  const timezoneArray = useSelector((state) => state.user.timezoneMaster);
  const usersOfRoleAcceptanceStatus = useSelector(
    (state) => state.user.UserRoleRequestStatus
  );
  const [teamLeadInput, setTeamLeadInput] = useState("");
  const [mentorInput, setMentorInput] = useState("");
  const [coachInput, setCoachInput] = useState("");
  const [degn, setDegn] = useState("");
  const [timezone, setTimezone] = useState("");
  const [users, setUsers] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [showModalMsg, setShowModalMsg] = useState("");
  const [isTeamLeadError, setIsTeamLeadError] = useState("");
  const [isMentorError, setIsMentorError] = useState("");
  const [isCoachError, setIsCoachError] = useState("");
  const [isTimeZoneError, setIsTimezoneError] = useState("");
  const [licensedAs, setLicensedAs] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [currentRole, setCurrentRole] = useState(0);
  const [selectedCoachStatus, setSelectedCoachStatus] = useState("");
  const [selectedMentorStatus, setSelectedMentorStatus] = useState("");
  const [selectedTeamLeadStatus, setSelectedTeamLeadStatus] = useState("");

  const [selectedCoachName, setSelectedCoachName] = useState("");
  const [selectedMentorName, setSelectedMentorName] = useState("");
  const [selectedTeamLeadName, setSelectedTeamLeadName] = useState("");

  const [requestedCoachId, setRequestedCoachId] = useState("");
  const [requestedMentorId, setRequestedMentorId] = useState("");
  const [requestedTeamLeadId, setRequestedTeamLeadId] = useState("");

  const [rejectReasonCoach, setRejectReasonCoach] = useState("");
  const [rejectReasonMentor, setRejectReasonMentor] = useState("");
  const [rejectReasonTeamLead, setRejectReasonTeamLead] = useState("");

  const [userRoleAccessStatus, setUserRoleAccessStatus] = useState([]);
  const [recoData, setRecoData] = useState('')

  const [isLoading, setIsLoading] = useState();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const user_id = params.get("user_id");
  const isOther = params.get("isOther");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(userDetailRes) &&
      !_.isEmpty(timezoneArray) &&
      !_.isEmpty(licensedAsRes)
    ) {
      setIsLoading(false);
    }
  }, [userDetailRes, timezoneArray, licensedAsRes]);

  const getUserDetail = () => {
    if (isOther == 1) {
      dispatch(
        userDetailBroker({
          module: "getUserDetailBrokerForOther",
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            rea_user_id: user_id,
          },
        })
      );
    } else {
      dispatch(
        userDetailBroker({
          module: "getOrderDetailBroker",
          apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        })
      );
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [dispatch]);

  useEffect(() => {
    // console.log('licensedAsRes: ', licensedAsRes)
    if (licensedAsRes?.status === "success") {
      setLicensedAs(licensedAsRes.payload.payload);
    }
  }, [licensedAsRes]);

  useEffect(() => {
    if (!_.isEmpty(licensedAs) && !_.isEmpty(userDetailRes)) {
      licensedAs.map((cat) => {
        if (userDetailRes?.payload?.payload?.degn === cat.designation) {
          setRecoData(cat.designation)
        }
      })
    }
  }, [licensedAs, userDetailRes])

  useEffect(() => {
    if (!_.isEmpty(timezoneArray)) {
      if (timezoneArray.status === "success") {
        setTimezoneList(timezoneArray.payload.payload);
      } else {
        setTimezoneList([]);
      }
    }
  }, [timezoneArray]);

  useEffect(() => {
    if (!confirmModal) {
      setConfirmModal(false);
    }
  }, [confirmModal]);

  useEffect(() => {
    if (!_.isEmpty(userDetailRes)) {
      if (userDetailRes.status === "success") {
        // console.log('userDetailRes111: ', userDetailRes)
        // dispatch(selectedTeamLead(userDetailRes?.payload?.payload?.team_lead_id))
        // dispatch(selectedMentor(userDetailRes?.payload?.payload?.mentor_id))
        // dispatch(selectedCoach(userDetailRes?.payload?.payload?.coach_id))

        setTeamLead(userDetailRes?.payload?.payload?.team_lead_id);
        setMentor(userDetailRes?.payload?.payload?.mentor_id);
        setCoach(userDetailRes?.payload?.payload?.coach_id);
        setTeamLeadInput(
          userDetailRes?.payload?.payload?.team_lead === " "
            ? ""
            : userDetailRes?.payload?.payload?.team_lead
        );
        setMentorInput(
          userDetailRes?.payload?.payload?.mentor === " "
            ? ""
            : userDetailRes?.payload?.payload?.mentor
        );
        setCoachInput(
          userDetailRes?.payload?.payload?.coach === " "
            ? ""
            : userDetailRes?.payload?.payload?.coach
        );
        setDegn(
          userDetailRes?.payload?.payload?.degn === " "
            ? ""
            : userDetailRes?.payload?.payload?.degn
        );
        setTimezone(
          userDetailRes.payload?.payload?.timezone === ""
            ? ""
            : userDetailRes?.payload?.payload?.timezone
        );

        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
          dispatch(fetchTimezoneAbbr(userDetailRes.payload.payload.timezone));
        }
      }
      // setIsLoading(false)
    }
  }, [userDetailRes]);

  useEffect(() => {
    if (!_.isEmpty(userUpdateRes)) {
      if (userUpdateRes?.status == "success") {
        getUserDetail();
        setShowModalMsg("Profile updated successfully.");
      } else {
        setShowModalMsg("Error updating profile.");
      }
      setConfirmModal(true);
      setIsConfirm(false);
      dispatch(userUpdateClear());
      setIsLoading(false);
    }
  }, [userUpdateRes]);

  useEffect(() => {
    // console.log('userDetailRes111searchUserRes: ', searchUserRes)
    if (searchUserRes?.status === "success") {
      const persons = searchUserRes?.payload.searchData;
      if (persons !== undefined && persons?.length > 0) {
        var tempArray = [];
        for (let index = 0; index < persons?.length; index++) {
          const element = persons[index];
          // console.log('element: ', element)
          tempArray.push({
            label:
              element.first_name +
              " " +
              element.last_name +
              " " +
              element.username,
            value: element.ID,
            showValue: element.first_name + " " + element.last_name,
          });
        }
        // console.log('searchDataAfterForLoop: ', tempArray)
        setUsers(tempArray);
      } else {
        setUsers([]);
      }
    } else {
      setUsers([]);
    }
  }, [searchUserRes]);

  const userProfileUpdate = () => {
    if (_.isString(coach) && coach.length > 0) {
      setIsCoachError("Please select correct input for Coach");
      return;
    }
    if (_.isString(mentor) && mentor.length > 0) {
      setIsMentorError("Please select correct input for Mentor");
      return;
    }
    if (_.isString(teamLead) && teamLead.length > 0) {
      setIsTeamLeadError("Please select correct input for Team Lead");
      return;
    }

    if (_.isEmpty(timezone)) {
      setIsTimezoneError("Please select timezone from list");
      return;
    }

    setIsEdit(false);
    setIsLoading(true);

    const params = {
      team_lead: teamLead == null ? "" : teamLead,
      mentor: mentor == null ? "" : mentor,
      coach: coach == null ? "" : coach,
      licensedAs: degn == null ? "" : degn,
      timezone: timezone == null ? "" : timezone,
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    };

    dispatch(userUpdate(params));
  };

  const handleChange = (event) => {
    // console.log(event.target.value);
  };

  const getSearchResult = (searchInput, role) => {
    // var reqObj = {
    //   broker_id: userDetailRes?.payload?.payload?.broker_id,
    //   searchInput: searchInput
    // }
    var reqObj = {
      broker_id: localStorage.getItem(SessionKeys.BROKER_ID),
      search: searchInput,
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      user_role: role,
    };

    dispatch(searchUser(reqObj));
  };

  const getOptions = (query) => {
    // console.log(query)
  };

  //User Role Request Status
  useEffect(() => {
    if (usersOfRoleAcceptanceStatus?.status === "success") {
      // console.log('inputs ', coachInput + ', ' + mentorInput + teamLeadInput + ', ')
      if (Array.isArray(usersOfRoleAcceptanceStatus?.payload?.payload)) {
        let roleArr = usersOfRoleAcceptanceStatus?.payload?.payload;
        if (coachInput === "") {
          setSelectedCoachStatus(
            roleArr.filter((i) => i.role_name === "coach")[0]?.status
          );
          setSelectedCoachName(
            roleArr.filter((i) => i.role_name === "coach")[0]?.user_name
          );
          setRequestedCoachId(
            Number(roleArr.filter((i) => i.role_name === "coach")[0]?.role_id)
          );
          setRejectReasonCoach(
            roleArr.filter((i) => i.role_name === "coach")[0]?.reason
          );
        } else {
          setSelectedCoachStatus("Accepted");
          setSelectedCoachName(coachInput);
        }

        if (mentorInput === "") {
          setSelectedMentorStatus(
            roleArr.filter((i) => i.role_name === "mentor")[0]?.status
          );
          setSelectedMentorName(
            roleArr.filter((i) => i.role_name === "mentor")[0]?.user_name
          );
          setRequestedMentorId(
            Number(roleArr.filter((i) => i.role_name === "mentor")[0]?.role_id)
          );
          setRejectReasonMentor(
            roleArr.filter((i) => i.role_name === "mentor")[0]?.reason
          );
        } else {
          setSelectedMentorStatus("Accepted");
          setSelectedMentorName(mentorInput);
        }

        if (teamLeadInput === "") {
          setSelectedTeamLeadStatus(
            roleArr.filter((i) => i.role_name === "team_lead")[0]?.status
          );
          setSelectedTeamLeadName(
            roleArr.filter((i) => i.role_name === "team_lead")[0]?.user_name
          );
          setRequestedTeamLeadId(
            Number(
              roleArr.filter((i) => i.role_name === "team_lead")[0]?.role_id
            )
          );
          setRejectReasonTeamLead(
            roleArr.filter((i) => i.role_name === "team_lead")[0]?.reason
          );
        } else {
          setSelectedTeamLeadStatus("Accepted");
          setSelectedTeamLeadName(teamLeadInput);
        }
      }
    }
  }, [usersOfRoleAcceptanceStatus]);
  // useEffect(() => {
  //   getRoleReqStatus();
  // }, [])


  return (
    <>
      <Loader start={isLoading} />
      <div className="row  mt-5 edit_profile_input">
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="first_name" className="input__label">
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.firstname}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="last_name" className="input__label">
              Middle Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.middlename}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row mt-3 edit_profile_input">
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="last_name" className="input__label">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.lastname}
              readOnly
            />
          </div>
        </div>

        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="email" className="input__label">
              Email Address
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.email}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row mt-3 edit_profile_input">
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field d-flex">
            <label htmlFor="date_join" className="input__label">
              Date of Joining (MM/DD/YYYY)
            </label>
            <input
              type="text"
              id="date_join"
              name="date_join"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.doj}
              readOnly
            />
            <img
              src="/images/edit_profile_calPG011.png"
              width="28"
              height="28"
              alt="calendar"
            />
          </div>
        </div>
        {/* 
        <div className="col-md-6 required">
          <div className="input-lear position-relative disable_field d-flex">
            <label htmlFor="category" className="input__label_learning">
              RECO Licensed As
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="input__field02"
              placeholder=" "
              value={recoData}
              readOnly
            />
         
          </div>

       
        </div> */}
          <div className="col-md-6 required">
          <div className="input-text position-relative disable_field d-flex">
            <label htmlFor="date_join" className="input__label">
              Timezone
            </label>
            <input
              type="text"
              id="date_join"
              name="date_join"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.timezoneAbbr}
              readOnly
            />
           
          </div>
        </div>
      </div>
      <div className="row mt-3 edit_profile_input">
        {/* <div className="col-md-6 required">
          <div className="input-text position-relative disable_field d-flex">
            <label htmlFor="licence_date" className="input__label">
              RECO Licence Issue Date (MM/DD/YYYY)
            </label>
            <input
              type="text"
              id="licence_date"
              name="licence_date"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.payload?.license_date}
              readOnly
            />
            <img
              src="/images/edit_profile_calPG011.png"
              width="28"
              height="28"
              alt="calendar"
            />
          </div>
        </div> */}

        {/* <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="license_num" className="input__label">
              RECO License Number
            </label>
            <input
              type="text"
              id="license_num"
              name="license_num"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.payload?.license_number}
              readOnly
            />
          </div>
        </div> */}
      </div>
      {/* <div className="row mt-3 edit_profile_input">
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="manager" className="input__label">
              Broker of Record
            </label>
            <input
              type="text"
              id="manager"
              name="manager"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.payload?.broker_of_records}
              readOnly
            />
          </div>
        </div>

        {(
          <div className="col-md-6 required">
            <div className="input-text position-relative disable_field">
              <label htmlFor="manager" className="input__label">
                Manager
              </label>
              <input
                type="text"
                id="manager"
                name="manager"
                className="input__field02"
                placeholder=" "
                value={userDetailRes?.payload?.payload?.manager}
                readOnly
              />
            </div>
          </div>
        )}
      </div> */}
      <div className="row mt-3 edit_profile_input">


        {userDetailRes?.payload?.office_province !='' || null && 
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="province" className="input__label">
              State
            </label>
            <input
              type="text"
              id="province"
              name="province"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.office_province}
              readOnly
            />
          </div>
        </div>
        }
        {userDetailRes?.payload?.office_city !='' || null &&
        <div className="col-md-6 required">
          <div className="input-text position-relative disable_field">
            <label htmlFor="city" className="input__label">
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              className="input__field02"
              placeholder=" "
              value={userDetailRes?.payload?.office_city}
              readOnly
            />
          </div>
        </div>
        }
      </div>
      
{/* 
      <div className="row mt-3 edit_profile_input">
        <div className="col-md-6">
          <div className="input-lear position-relative d-flex">
            <label htmlFor="category" className="input__label_learning">
              Timezone
            </label>
            <select
              className="form-select input_searchfield"
              id="category"
              aria-label="Default select example"
              disabled={isEdit ? false : true}
              onChange={(ev) => {
                setTimezone(ev.target.value);
              }}
            >
              {timezoneList.map((cat, index) => {
                return (
                  <option
                    value={cat.offset}
                    selected={
                      userDetailRes?.payload?.payload?.timezone === cat.offset
                        ? true
                        : false
                    }
                    key={index}
                  >
                    {cat.timezone}{" "}
                  </option>
                );
              })}

            </select>
          </div>
          <p className="error-msg">{isTimeZoneError}</p>
        </div>
      </div> */}

      {/* {
        (!_.isEmpty(authBroker) &&
          CONFIG.C21_BROKER_ID === authBroker.payload.user.company_id) ? "" :
          <>
            <div className="row mt-3 mb-2 edit_profile_input userdetslsd">
              <div className="col-md-12">
                <div className="frmsep"></div>
              </div>

              <label className="role_head">Additional Brokerage Support</label>
            </div>

            <div className="row mt-3 edit_profile_input userdetslsd">
              <div className="d-flex align-items-center processnpxf">
                <div className="col-md-6">
                  <div
                    className={`input-text position-relative ${isEdit ? "input__field_auto_cmplt" : ""
                      }`}
                  >
                    <Autocomplete
                      disabled={isEdit ? false : true}
                      disablePortal
                      size="small"
                      id="combo-box-demo"
                      autoComplete
                      includeInputInList
                      className={`${coachInput ? "has-value" : "has-no-value"}`}
                      options={
                        coachInput !== undefined && coachInput?.length >= 2
                          ? users
                          : [
                            {
                              label: "Type at least 3 characters to search",
                              value: "",
                              showValue: "Type at least 3 characters to search",
                            },
                          ]
                      }
                      sx={{ width: "100%" }}
                      onChange={(event, value) => {
                        if (
                          value.value === teamLead ||
                          value.value === mentor ||
                          value.value === requestedTeamLeadId ||
                          value.value === requestedMentorId
                        ) {
                          setIsCoachError(
                            "Please select different user for team lead , mentor and coach."
                          );
                          // dispatch(selectedCoach(""))
                          setCoach("");
                          setCoachInput("");
                        } else {
                          setIsCoachError("");
                          // dispatch(selectedCoach(value.value))
                          setCoach(value.value);
                          setCoachInput(value.showValue);
                        }
                      }}
                      onInputChange={(e) => {
                        setCurrentRole(1);
                        if (e !== null) {
                          setCoachInput(e.target.value);
                          // dispatch(selectedCoach(e.target.value))
                          setCoach(e.target.value);
                          if (e.target.value.length > 2) {
                            getSearchResult(e.target.value.trim(), "coach");
                          } else {
                            setUsers([]);
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Coach" />
                      )}
                      value={coachInput}
                    />
                  </div>
                </div>

                <div className="col-md-6 statusshowsec">
                  {!_.isEmpty(selectedCoachStatus) ? (
                    selectedCoachStatus?.toLowerCase() === "reject" ? (
                      <p>
                        <i className="fa fa-times statusreject" aria-hidden="true"></i>{" "}
                        <span>
                          <b>{selectedCoachName}</b> has rejected your request.{" "}
                          <b>Reason: </b>
                          {rejectReasonCoach}
                        </span>
                      </p>
                    ) : selectedCoachStatus?.toLowerCase() === "pending" ? (
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          className="statuspending"
                        >
                          <g transform="translate(-691 -648)">
                            <g
                              fill="#fff"
                              stroke="#ec892b"
                              strokeWidth="1.5"
                              data-name="Ellipse 254"
                              transform="translate(691 648)"
                            >
                              <circle cx="9" cy="9" r="9" stroke="none"></circle>
                              <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                            </g>
                            <path
                              fill="#f5871f"
                              d="M6.063 0c.024.022 0 6.063 0 6.063s5.458-2.7 5.462-2.656a5.889 5.889 0 01.6 2.656A6.063 6.063 0 116.063 0z"
                              data-name="Path 3558"
                              transform="translate(693.938 650.937)"
                            ></path>
                          </g>
                        </svg>
                        <span>
                          Your request is pending with <b>{selectedCoachName}</b>.
                        </span>
                      </p>
                    ) : selectedCoachStatus?.toLowerCase() === "accepted" ? (
                      <p>
                        <i className="fa fa-check statusaccepted" aria-hidden="true"></i>{" "}
                        <span>
                          <b>{selectedCoachName}</b> has accepted your request.
                        </span>
                      </p>
                    ) : (
                      <p></p>
                    )
                  ) : (
                    <p></p>
                  )}
                </div>

                <p className="error-msg">{isCoachError}</p>
              </div>
              <div className="d-flex align-items-center processnpxf">
                <div className="col-md-6">
                  <div
                    className={`input-text position-relative ${isEdit ? "input__field_auto_cmplt" : ""
                      }`}
                  >
                    <Autocomplete
                      disabled={isEdit ? false : true}
                      disablePortal
                      size="small"
                      required
                      id="combo-box-demo"
                      autoComplete
                      includeInputInList
                      openOnFocus
                      className={`${mentorInput ? "has-value" : "has-no-value"}`}
                      options={
                        mentorInput !== undefined && mentorInput?.length >= 2
                          ? users
                          : [
                            {
                              label: "Type at least 3 characters to search",
                              value: "",
                              showValue: "Type at least 3 characters to search",
                            },
                          ]
                      }
                      sx={{ width: "100%" }}
                      onChange={(event, value) => {
                        // console.log('value', value)
                        if (
                          value.value === teamLead ||
                          value.value === coach ||
                          value.value === requestedTeamLeadId ||
                          value.value === requestedCoachId
                        ) {
                          setIsMentorError(
                            "Please select different user for team lead , mentor and coach."
                          );
                          // dispatch(selectedMentor(""))
                          setMentor("");
                          setMentorInput("");
                        } else {
                          setIsMentorError("");
                          // dispatch(selectedMentor(value.value))
                          setMentor(value.value);
                          setMentorInput(value.showValue);
                        }
                      }}
                      onInputChange={(e) => {
                        setCurrentRole(2);
                        if (e !== null) {
                          // console.log('eMentor', e.target.value)
                          setMentorInput(e.target.value);
                          // dispatch(selectedMentor(e.target.value))
                          setMentor(e.target.value);
                          if (e.target.value.length > 2) {
                            getSearchResult(e.target.value.trim(), "mentor");
                          } else {
                            setUsers([]);
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Mentor" />
                      )}
                      value={mentorInput}
                    />
                  </div>
                </div>

                <div className="col-md-6 statusshowsec">
                  <div>
                    {!_.isEmpty(selectedMentorStatus) ? (
                      selectedMentorStatus?.toLowerCase() === "reject" ? (
                        <p>
                          <i className="fa fa-times statusreject" aria-hidden="true"></i>{" "}
                          <span>
                            <b>{selectedMentorName}</b> has rejected your request.{" "}
                            <b>Reason: </b>
                            {rejectReasonMentor}
                          </span>
                        </p>
                      ) : selectedMentorStatus?.toLowerCase() === "pending" ? (
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            className="statuspending"
                          >
                            <g transform="translate(-691 -648)">
                              <g
                                fill="#fff"
                                stroke="#ec892b"
                                strokeWidth="1.5"
                                data-name="Ellipse 254"
                                transform="translate(691 648)"
                              >
                                <circle cx="9" cy="9" r="9" stroke="none"></circle>
                                <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                              </g>
                              <path
                                fill="#f5871f"
                                d="M6.063 0c.024.022 0 6.063 0 6.063s5.458-2.7 5.462-2.656a5.889 5.889 0 01.6 2.656A6.063 6.063 0 116.063 0z"
                                data-name="Path 3558"
                                transform="translate(693.938 650.937)"
                              ></path>
                            </g>
                          </svg>
                          <span>
                            Your request is pending with{" "}
                            <b>{selectedMentorName + " "}</b>.
                          </span>
                        </p>
                      ) : selectedMentorStatus?.toLowerCase() === "accepted" ? (
                        <p>
                          <i
                            className="fa fa-check statusaccepted"
                            aria-hidden="true"
                          ></i>{" "}
                          <span>
                            <b>{selectedMentorName}</b> has accepted your request.
                          </span>
                        </p>
                      ) : (
                        <p></p>
                      )
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <p className="error-msg">{isMentorError}</p>
              </div>
            </div>
            <div className="row mt-3 edit_profile_input nffgh mb-0">
              <div
                className={`d-flex align-items-center processnpxf ${!isEdit ? "mt-3" : ""
                  }`}
              >
                <div className="col-md-6">
                  <div
                    className={`input-text position-relative ${isEdit ? "input__field_auto_cmplt" : ""
                      }`}
                  >
                    <Autocomplete
                      disablePortal
                      disabled={isEdit ? false : true}
                      size="small"
                      id="combo-box-demo"
                      autoComplete
                      includeInputInList
                      className={`${teamLeadInput ? "has-value" : "has-no-value"}`}
                      // renderOption={option=> {console.log('option',option)}}
                      options={
                        teamLeadInput !== undefined && teamLeadInput?.length >= 2
                          ? users
                          : [
                            {
                              label: "Type at least 3 characters to search",
                              value: "",
                              showValue: "Type at least 3 characters to search",
                            },
                          ]
                      }
                      sx={{ width: "100%" }}
                      onChange={(event, value) => {
                        // console.log("selectedValue ", value)
                        if (
                          value.value === mentor ||
                          value.value === coach ||
                          value.value === requestedMentorId ||
                          value.value === requestedCoachId
                        ) {
                          setIsTeamLeadError(
                            "Please select different user for team lead , mentor and coach."
                          );
                          // dispatch(selectedTeamLead(""))
                          setTeamLead("");
                          setTeamLeadInput("");
                        } else {
                          setIsTeamLeadError("");
                          // dispatch(selectedTeamLead(value.value))
                          setTeamLead(value.value);
                          setTeamLeadInput(value.showValue);
                        }
                      }}
                      onInputChange={(e) => {
                        setCurrentRole(3);
                        if (e !== null) {
                          setTeamLeadInput(e.target.value);
                          setTeamLead(e.target.value);
                          // dispatch(selectedTeamLead(e.target.value))
                          if (e.target.value.length > 2) {
                            getSearchResult(e.target.value.trim(), "team_lead");
                          } else {
                            setUsers([]);
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Team Lead" />
                      )}
                      value={teamLeadInput}
                    />
                  </div>
                </div>

                <div className="col-md-6 statusshowsec">
                  {!_.isEmpty(selectedTeamLeadStatus) ? (
                    selectedTeamLeadStatus?.toLowerCase() === "reject" ? (
                      <p>
                        <i className="fa fa-times statusreject" aria-hidden="true"></i>{" "}
                        <span>
                          <b>{selectedTeamLeadName}</b> has rejected your request.{" "}
                          <b>Reason: </b>
                          {rejectReasonTeamLead}
                        </span>
                      </p>
                    ) : selectedTeamLeadStatus?.toLowerCase() === "pending" ? (
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          className="statuspending"
                        >
                          <g transform="translate(-691 -648)">
                            <g
                              fill="#fff"
                              stroke="#ec892b"
                              strokeWidth="1.5"
                              data-name="Ellipse 254"
                              transform="translate(691 648)"
                            >
                              <circle cx="9" cy="9" r="9" stroke="none"></circle>
                              <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                            </g>
                            <path
                              fill="#f5871f"
                              d="M6.063 0c.024.022 0 6.063 0 6.063s5.458-2.7 5.462-2.656a5.889 5.889 0 01.6 2.656A6.063 6.063 0 116.063 0z"
                              data-name="Path 3558"
                              transform="translate(693.938 650.937)"
                            ></path>
                          </g>
                        </svg>
                        <span>
                          Your request is pending with <b>{selectedTeamLeadName}</b>.
                        </span>
                      </p>
                    ) : selectedTeamLeadStatus?.toLowerCase() === "accepted" ? (
                      <p>
                        <i className="fa fa-check statusaccepted" aria-hidden="true"></i>{" "}
                        <span>
                          <b>{selectedTeamLeadName}</b> has accepted your request.
                        </span>
                      </p>
                    ) : (
                      <p></p>
                    )
                  ) : (
                    <p></p>
                  )}
                </div>
                <p className="error-msg">{isTeamLeadError}</p>
              </div>
            </div>

            <div className="row mt-1 edit_profile_input nffgh">
              <label className="infoedit">
                <i>
                  For more information around these roles, please do contact your
                  Manager.
                </i>
              </label>
            </div>
          </>
      } */}

      {/* <div className="row mt-5 edit_profile_input">
        <div className={`col-md-12 mb-5 ${!isEdit ? "mt-3s" : ""}`}>
          <div
            className={`edit_btn ${isEdit ? "d-flex justify-content-between" : ""
              }`}
          >
            <button
              className="btn-primary"
              onClick={() => {
                setIsCoachError("");
                setIsMentorError("");
                setIsTeamLeadError("");
                setIsTimezoneError("");
                if (isEdit) {
                  clickStreamUser("save_profile_btn_click", {
                    user_id: authBroker.payload.user.user_id,
                  });
                  userProfileUpdate();
                } else {
                  clickStreamUser("edit_profile_btn_click", {
                    user_id: authBroker.payload.user.user_id,
                  });
                  setIsEdit(true);
                }
              }}
            >
              {isEdit ? "Save" : "Edit Profile"}
           
            </button>
            {isEdit ? (
              <button
                className="btn-primary"
                onClick={() => {
                  clickStreamUser("cancel_edit_profile_btn_click", {
                    user_id: authBroker.payload.user.user_id,
                  });
                  setIsEdit(false);
                }}
              >
                Cancel
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div> */}

      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={showModalMsg}
        isConfirm={isConfirm}
        isRightIcon={true}
      />
    </>
  );
};
export default ProfilEdit;
