import { fork, take, call, put } from 'redux-saga/effects'

import {
    createFoldrResponse,
    getOrderHistoryResponse,
    createFileResponse,
    deleteFolderReqResponse,
    deleteFileResponse,
    detailFolderResponse,
    toolboxUpdateTimeResponse
} from './actions'
import types from './types'
import { getAPI, postAPIWithoutModule } from '../../api/generic'

export function* doGetOrderHistory() {
    while (true) {
        const { payload } = yield take(types.ORDER_HISTORY)
        const response = yield call(getAPI, payload)
        yield put(getOrderHistoryResponse(response))
    }
}

export function* doCreateFolder() {
    while (true) {
        const { payload } = yield take(types.CREATE_FOLDER)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(createFoldrResponse(response))
    }
}
// create file -toolbox
export function* doCreateFile() {
    while (true) {
        const { payload } = yield take(types.CREATE_FILE)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(createFileResponse(response))
    }
}
// delete folder -toolbox
export function* doDeleteFolder() {
    while (true) {
        const { payload } = yield take(types.DELETE_FOLDER)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(deleteFolderReqResponse(response))
    }
}
// delete file -toolbox
export function* doDeleteFile() {
    while (true) {
        const { payload } = yield take(types.DELETE_FILE)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(deleteFileResponse(response))
    }
}
// details -toolbox
export function* doGetFolderDetails() {
    while (true) {
        const { payload } = yield take(types.FOLDER_DETAIL)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(detailFolderResponse(response))
    }
}

export function* doGetSearchFolderDetails() {
    while (true) {
        const { payload } = yield take(types.TOOLBOX_SEARCH)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(detailFolderResponse(response))
    }
}

export function* doToolboxUpdateTime() {
    while (true) {
        const { payload } = yield take(types.TOOLBOX_UPDATE_TIME)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(toolboxUpdateTimeResponse(response))
    }
}


function* orders() {
    yield fork(doGetOrderHistory)
    yield fork(doCreateFolder)
    yield fork(doCreateFile)
    yield fork(doDeleteFolder)
    yield fork(doDeleteFile)
    yield fork(doGetFolderDetails)
    yield fork(doGetSearchFolderDetails)
    yield fork(doToolboxUpdateTime)
}
export default orders