const types = {
  CHECKOUT_SUMMARY: 'CHECKOUT_SUMMARY',
  CHECKOUT_SUMMARY_RESPONSE: 'CHECKOUT_SUMMARY_RESPONSE',
  CHECKOUT_SUMMARY_CLEAR: 'CHECKOUT_SUMMARY_CLEAR',

  PAYMENT_INTENT: 'PAYMENT_INTENT',
  PAYMENT_INTENT_RESPONSE: 'PAYMENT_INTENT_RESPONSE',
  PAYMENT_INTENT_CLEAR: 'PAYMENT_INTENT_CLEAR',

  PI_DIRECT_CHECKOUT: 'PI_DIRECT_CHECKOUT',
  PI_DIRECT_CHECKOUT_RESPONSE: 'PI_DIRECT_CHECKOUT_RESPONSE',
  PI_DIRECT_CHECKOUT_CLEAR: 'PI_DIRECT_CHECKOUT_CLEAR',

  SEND_ORDER_PLACE: 'SEND_ORDER_PLACE',
  SEND_ORDER_PLACE_RESPONSE: 'SEND_ORDER_PLACE_RESPONSE',
  SEND_ORDER_PLACE_CLEAR: 'SEND_ORDER_PLACE_CLEAR',

  ZERO_SEND_ORDER_PLACE: 'ZERO_SEND_ORDER_PLACE',
  ZERO_SEND_ORDER_PLACE_RESPONSE: 'ZERO_SEND_ORDER_PLACE_RESPONSE',
  ZERO_SEND_ORDER_PLACE_CLEAR: 'ZERO_SEND_ORDER_PLACE_CLEAR',

  ORDER_PLACED_RESPONSE: 'ORDER_PLACED_RESPONSE',
  ORDER_PLACED_CLEAR: 'ORDER_PLACED_CLEAR',
  CART_PAGE_DATA_RESPONSE: 'CART_PAGE_DATA_RESPONSE',
  CART_PAGE_DATA_CLEAR: 'CART_PAGE_DATA_CLEAR',
}

export default types;