import { useEffect } from "react";
import Page from "../../components/Page";
// import ComplianceReportSection from "../../components/Reports/New Progress Report/ComplianceReportSection";
import ComplianceReportSection from "../../components/Reports/complianceReport/ComplianceReportSection";

export const ComplianceReportPageMobile = () => {
  useEffect(() => {
      if (document.getElementById("id_page_404") !== null) {
        document.getElementById("id_page_404").style.display = "none";
      }
    setTimeout(() => {
      if (document.getElementById("cc_div") !== null)
        document.getElementById("cc_div").style.display = "none";
    }, 100)
  }, [])
  return (
    <ComplianceReportSection 
      isMobile={true}
    />
  );
};

export default ComplianceReportPageMobile;
