import React, { useEffect, useState } from 'react'

import Page from "../../components/Page"
import CourseLaunchSection from '../../components/Course/CourseLaunchSection';
import ModuleDetail from '../modulePages/moduleDetail';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategories, getProductCategoriesDataClear, getSubProductCategoriesDataClear } from '../../redux/courses/actions';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
//import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';

const VideoCategoriesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const productCategoriesData = useSelector(
    (state) => state.courses.productCategories
  );

  const [courseCategories, setCourseCategories] = useState([]);

  useEffect(()=>{
    dispatch(getSubProductCategoriesDataClear());
        dispatch(getProductCategoriesDataClear());
  },[])

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        getProductCategories({
          module: "getProductCategoryFromWoo",
          brokerId: authBroker.payload.user.company_id,
        })
      );
    }
  }, [authBroker])

  useEffect(() => {
    // console.log("product categories", productCategoriesData, courseCategoriesDataBroker);
    if (
      !_.isEmpty(productCategoriesData)
    ) {
      let categoryArray = [];
      if (
        productCategoriesData.status === "success"
      ) {
        if (productCategoriesData?.payload?.length > 0) {
          categoryArray = [...categoryArray, ...productCategoriesData.payload];
        }
        setCourseCategories(categoryArray);
      }


    }
  }, [productCategoriesData]);

  return (
    <Page>
      <section className='my-credit-points course-category-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className="events_banner">
                <p className="label-heading_events mb-4">Categories</p>
              </div>
            </div>
          </div>
          <div className='row mt-3'>

            {courseCategories?.map((item, index) => {
              return <div className='col-md-6 col-lg-4 col-xl-3 col-12 mb-4 cat-broker-box'>
                <div className='catbox cursor-pointer' style={{
                  backgroundImage: item.image===false?'url("../images/course_card_nw.jpg")':`url(${item.image})`
                }}
                onClick={()=>{
                  history.push(`/video-listing?catId=${item.categoryId}`);
                }}>
                  <div className='cat-overlay'>
                    <div className='cat-title-course'>
                      {item?.categoryName}
                    </div>
                  </div>
                </div>
              </div>
            })
            }

          </div>
        </div>
      </section>
    </Page>
  )
}

export default VideoCategoriesPage