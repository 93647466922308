import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Page from "../../components/Page";
import ReportDashboardSection from "../../components/Reports/ReportDashboardSection";
import { loginBrokerResponse, loginIframeUserResponse } from "../../redux/auth/actions";
import { setUserRole, userDetailBroker, setIframeUserRole } from "../../redux/user/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import CONFIG from "../../utils/config";
import $ from "jquery";
import MainCalendarSection from "../../components/Calendar/MainCalendarSection";
import Loader from "../../components/Loader/Loader";

const CalendarEventsMobilePage = () => {

    const dispatch = useDispatch()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');
    const user_id = params.get('user_id');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const role = params.get('role');
    const zoom_id = params.get('zoom_id');

    const [isLoading, setIsLoading] = useState(true)
    const [isValidKey, setIsValidKey] = useState(false)

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
    }, [])

    useEffect(() => {
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
            $('html').css("--color-element-1", color_1 ? '#' + color_1 : CONFIG.COLOR_ELEMENT_1);
            $('html').css("--color-element-2", color_2 ? '#' + color_2 : CONFIG.COLOR_ELEMENT_2);
            localStorage.setItem(SessionKeys.API_TOKEN, token);
            localStorage.setItem(SessionKeys.USERNAME, username);
            setIsValidKey(true)
            if (role === 'pmpro_membership_manager') {
                dispatch(setIframeUserRole('broker'))
            } else if (role === 'broker_admin') {
                dispatch(setIframeUserRole('admin'))
            }
            dispatch(loginIframeUserResponse({
                payload: {
                    user: {
                        user_id: user_id,
                        username: username,
                        email: username,
                        zoom_id: zoom_id,
                        role: [role]
                    },
                    apiToken: token
                }
            }))

            // dispatch(
            //     userDetailBroker({
            //         module: "getOrderDetailBroker",
            //         apiToken: token,
            //     })
            // );
            clickStreamUser('calendar_iframe_page', {
                userId: user_id,
            })
            // setIsLoading(false)
        }
    }, [token, key, user_id, dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Loader start={isLoading} />
            {isValidKey ?
                <>
                    <MainCalendarSection
                        isMobile={true}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                    />
                </>
                :
                <div>
                    Invalid Key
                </div>
            }
        </>
    )
};

export default CalendarEventsMobilePage;
