import React from 'react';

const PrivacyPolicyComp = () => {
  return (
    <div className="container footerstaticpage">
      <h1 className="pagenameheading">Privacy Policy</h1>
    </div>
  )
}

export default PrivacyPolicyComp;