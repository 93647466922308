import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileFilterSearchSection from "./PurchaseFilterSearchSection";
import ProfileTableSeaction from "./PurchaseTableSeaction";
import ProfileTitleSection from "./PurchaseTitleSection";
import { getProfileCompletionReport } from '../../../redux/reports/actions'
import { SessionKeys } from '../../../utils/AppUtils'
import EventTitleSection from "./PurchaseTitleSection";
import EventFilterSearchSection from "./PurchaseFilterSearchSection";
import EventTableSeaction from "./PurchaseTableSeaction";
import ReportViewFilterSection from "../ReportViewFilterSection";
import CONFIG from "../../../utils/config"

const PurchaseReportHomeSection = (props) => {
    const { 
        isLoading, setIsLoading 
    } = props

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [clickBuy, setClickBuy] = useState(true)
    const [clickFree, setClickFree] = useState(false)
    const [clickBrokerage, setClickBrokerage] = useState(false)
    const [dateRange, setDateRange] = useState({})
    const [searchInput, setSearchInput] = useState('');
    const userRole = useSelector(state => state.user.role)
    const [isView, setIsView] = useState('table')
    const [type, setType] = useState('bar')
    const [canShow, setCanShow] = useState(false)
    const [officeIds, setOfficeIds] = useState([])

    const getFilteredData = (action) => {
        let dateRange = {
            start: startDate?.toLocaleDateString("en-US"),
            end: endDate?.toLocaleDateString("en-US"),
        }
        let profileStatus = []
        // let profileStatus = null;

        if (clickBuy) {
            profileStatus.push("Buy")
        }
        if (clickFree) {
            profileStatus.push("Free")
        }
        if (clickBrokerage) {
            profileStatus.push("brokerageSession")
        }

        let req = {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            user_entity: userRole === 'broker' ? 'broker_admin_created_by' : 'user_manager_id'
        };

        if (startDate !== null & endDate !== null) {
            req.date_range = dateRange
            setDateRange(dateRange)
        }

        // if (profileStatus !== null) {
        //     req.status = profileStatus;
        // } 
        if (searchInput !== '') {
            req.search = searchInput;
        }
        req.office_ids = officeIds
        setIsLoading(true)
        dispatch(getProfileCompletionReport({
            module: 'getPurchaseReport',
            postData: req
        }))
    }

    return (
        <>
            <EventTitleSection />
            <EventFilterSearchSection
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                clickBuy={clickBuy}
                setClickBuy={setClickBuy}
                clickFree={clickFree}
                setClickFree={setClickFree}
                clickBrokerage={clickBrokerage}
                setClickBrokerage={setClickBrokerage}
                getFilteredData={getFilteredData}
                setOfficeIds={setOfficeIds}
            />
            {(canShow) ? <ReportViewFilterSection 
                isView={isView}
                setIsView={setIsView}
                type={type}
                setType={setType}
            /> : ''}
            <EventTableSeaction
                setSearchInput={setSearchInput}
                getFilteredData={getFilteredData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                dateRange={dateRange}
                isView={isView}
                setCanShow={setCanShow}
                type={type}
            />
        </>
    )
}

export default PurchaseReportHomeSection;