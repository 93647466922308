const data = {
    productDetail: {
        productInfo: {
            title: 'Realtor Safety - Health and Safety',
            description: 'Pellentesque condimentum egestas risus eleifend ultrices. \
            In et lorem vitae lectus ultrices mattis. Pellentesque \
            iaculis velit at mi sodales egestas. Pellentesque \
            iaculis velit at mi sodales egestas. Pellentesque \
            iaculis velit at mi sodales egestas.',
            duration: 30,
            durationUnit: 'Min',
            lessons: '7 Lessons',
            image: '/images/product_card_img.jpg',
            price: 289.17,
            policy: 'Complete the Accredited Buyer\'s Representative (ABR®) \
                2-Day course online(link is external). Pellentesque iaculis \
                velit at mi sodales egestas. Pellentesque iaculis velit at mi \
                sodales egestas. Pellentesque iaculis velit at mi sodales egestas. \
                Pellentesque iaculis velit at mi sodales egestas.'
        },
        productAnalytics: {
            views: '54920',
            progress: '0',
            ratings: '4.5',
        },
        canAddToCart: true
    },
    recommendProducts: [
        {
            title: 'Overcoming Objections',
            description: '',
            image: '/images/pro_card.jpg',
            link: ''
        },
        {
            title: 'Overcoming Objections',
            description: '',
            image: '/images/product_card_img02.jpg',
            link: ''
        }
    ],
    checkoutData : {
        itemInCart: 1,
        itemPrice: 289.17,
        taxes: 289.17,
        totalPrice: 578.34,
    }
};

export default data;