import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArticleSection from "../../components/Article/ArticleSection";
import { loginResponse } from "../../redux/auth/actions";
import { SessionKeys } from "../../utils/AppUtils";
import CONFIG from "../../utils/config";

const NewsnArticlesNoHeader = () => {

    const dispatch = useDispatch();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');

    const auth = useSelector((state) => state.auth);

    const [isValidKey, setIsValidKey] = useState(false)
    const [savedToken, setSavedToken] = useState('')
    
    useEffect(() => {
        // console.log('token', token, 'key', key)
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
            localStorage.setItem(SessionKeys.API_TOKEN, token);
            setIsValidKey(true)
            dispatch(loginResponse({
                payload: {
                    user: {
                        username: username
                    },
                    apiToken: token
                }
            }))
        }
    }, [token, key, username])


    useEffect(() => {
        const token = localStorage.getItem(SessionKeys.API_TOKEN);
        if (token) {
            setSavedToken(token)
        }
    }, [])
   
    return (
        <>
        {savedToken && <ArticleSection />}
        </>
    )
}

export default NewsnArticlesNoHeader;