import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayer from "react-video-js-player";
import Page from "../../components/Page";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  getVideoCourseCategories,
  getVideoDetailListing,
  getBrokerVideoListing,
  getBrokerVideoDetail,
  getBrokerVideoListingClear,
  getVideoDetailListingClear,
  getBrokerVideoDetailClear,
  getVideoCompt,
} from "../../redux/video/actions";
import Loader from "../../components/Loader/Loader";
import { Spinner } from "react-bootstrap";
import YoutubeEmbed from "./YoutubeEmbed";
import { clickStreamUser, SessionKeys, videoExtensionType } from "../../utils/AppUtils";
import AssignCourseModal from "../../components/modal/AssignCourseModal";
import CONFIG from "../../utils/config";
import { setCurrentView } from "../../redux/assignCourse/actions";
import {
  checkContentAccess,
  checkContentAccessClear,
} from "../../redux/home/actions";
import BackButton from "../../components/Layouts/BackButton";
import { videoEvents } from "../../utils/VideoClickStream";
import VideoContentSection from "../../components/Video/VideoContentSection";
import Assessmentpage from "./Assessmentpage.txt"

const VideoViewPageSection = ({ isMobile = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const source = params.get("source");
  const videoCode = params.get("code");
  const authBroker = useSelector((state) => state.auth.authBroker);
  const auth = useSelector((state) => state.auth);
  // const [productId] = useState(history.location.state?.productId)
  const videoDetailListingData = useSelector(
    (state) => state.video?.videoDetail
  );
  const userRole = useSelector((state) => state.user.role);
  const videoDetailListingDataBroker = useSelector(
    (state) => state.video?.videoDetailBrAdmin
  );
  const checkContent = useSelector((state) => state.home.checkContent);
  // const authBroker = useSelector(state => state.auth.authBroker)
  const [videoDetailInfo, setVideoDetailInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imgLoader, setImgLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [showAssignBtn, setShowAssignBtn] = useState(true);
  const [showContent, setShowContent] = useState(true);
  const [showLearnMore, setShowLearnMore] = useState(false)


  useEffect(() => {
    // dispatch(checkContentAccess({
    // 	module: 'checkContentAccess',
    // 	postData: {
    // 		token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    // 		origin: source === 'xsel' ? 'xsel' : 'broker',
    // 		entity_id: source === 'xsel' ? videoCode : productId,
    // 		entity_type: 'videos'
    // 	}
    // }))
  }, [dispatch]);

  useEffect(() => {
    // console.log('CHECK CONTENT', checkContent)
    if (!_.isEmpty(checkContent)) {
      dispatch(checkContentAccessClear());
      if (checkContent.status === "success") {
        const data = checkContent.payload.payload;
        if (!_.isEmpty(data)) {
          if (!data.is_register) {
            history.push("/not-found?isMobile=" + isMobile);
            return;
          }
          setShowContent(data.is_register);
          setShowAssignBtn(data.is_free);
          return;
        }
      }
      history.push("/not-found?isMobile=" + isMobile);
    }
  }, [checkContent]);

  const onPlayerReady = (player) => {
    // console.log("Player is ready: ", player);
    // splayer;

    let videoData = videoDetailInfo[0]
    const trackEl = player.addRemoteTextTrack(
      {
        src: videoData?.subtitle_url,
        kind: "captions",
        srclang: "en",
        label: "English",
      },
      false
    );
    trackEl.addEventListener("load", function () {
      // your callback goes here
    });

    videoEvents(player, {
      type: 'video',
      video_id: _.toNumber(productId),
      user_id: authBroker?.payload?.user?.user_id
    })

    player.on('ended', () => {
      let videoData = videoDetailInfo[0]
      setTimeout(() => {
        setShowLearnMore(true)
        dispatch(getVideoDetailListingClear());
        dispatch(getBrokerVideoDetailClear());
      }, 3000)
      dispatch(getVideoCompt({
        module: 'videoCompletionStatus',
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          entity_id: videoData.product_id,
          entity_type: 'video',
          status: "completed",
          source: videoData.source,
          entity_name: videoData?.product_name,
          learning_points: videoData?.learning_points
        }
      }))
    })
  };

  useEffect(() => {
    // console.log("PID", productId);
    // console.log("source", source)
    if (showContent && !_.isEmpty(authBroker) && !_.isEmpty(productId)) {
      setIsLoading(true)
      setShowLearnMore(false)
      if (source === "xsel") {
        dispatch(
          getVideoDetailListing({
            module: "videoDetailEcom",
            postData: {
              video_id: _.toNumber(productId),
              email: authBroker?.payload?.user?.email,
            },
          })
        );
      } else {
        dispatch(
          getBrokerVideoDetail({
            module: "videoDetailBroker",
            postData: {
              video_id: _.toNumber(productId),
              email: authBroker?.payload?.user?.email,
            },
          })
        );
      }
    }
  }, [showContent, authBroker, productId]);

  useEffect(() => {
    if (!_.isEmpty(videoDetailListingData)) {
      //  console.log(videoDetailListingData)
      if (videoDetailListingData?.status === "success") {
        const data = videoDetailListingData?.payload?.payload;
        let video_link = data[0].video_link;
        let typeSplit = video_link.includes('.') ? video_link.split('.') : [];
        let extension = typeSplit[typeSplit.length - 1]
        let is_videofile = typeSplit.length > 0 ? ['m3u8', 'mp4', 'mkv', 'webm', 'avi'].includes(extension) : false
        data[0].is_videofile = is_videofile
        data[0].extension = videoExtensionType(extension)
        setVideoDetailInfo(data);
        // setVideoDetailInfo(videoDetailListingData?.payload?.payload);
      }
      setIsLoading(false);
      dispatch(getVideoDetailListingClear());
    }
  }, [videoDetailListingData]);

  useEffect(() => {
    if (!_.isEmpty(videoDetailListingDataBroker)) {
      if (videoDetailListingDataBroker?.status === "success") {
        const data = videoDetailListingDataBroker?.payload?.payload;
        // console.log("VIDEO DATA", data);
        if (!_.isEmpty(data)) {
          let type = data[0].type;
          let video_link = data[0].video_link;
          let typeSplit = video_link.includes('.') ? video_link.split('.') : [];
          let extension = typeSplit[typeSplit.length - 1]
          let is_videofile = typeSplit.length > 0 ? ['m3u8', 'mp4', 'mkv', 'webm', 'avi'].includes(extension) : false
          data[0].video_type = type;
          data[0].is_videofile = is_videofile
          data[0].extension = videoExtensionType(extension)
          setVideoDetailInfo(data);
        }
      }
      setIsLoading(false);
      dispatch(getBrokerVideoDetailClear());
    }
  }, [videoDetailListingDataBroker]);

  useEffect(() => {
    if (!_.isEmpty(videoDetailInfo) && _.isArray(videoDetailInfo)) {
      let videoData = videoDetailInfo[0]
      if (!videoData.is_videofile) {
        dispatch(getVideoCompt({
          module: 'videoCompletionStatus',
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            entity_id: videoData.product_id,
            entity_type: 'video',
            status: "completed",
            source: videoData.source,
            entity_name: videoData?.product_name,
            learning_points: videoData?.learning_points
          }
        }))
      }
      // console.log(videoDetailInfo)
    }
  }, [videoDetailInfo])

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <>
      <Loader start={isLoading} />

      {_.isArray(videoDetailInfo) && videoDetailInfo?.length > 0 ? (
        videoDetailInfo.map((item, i) => {
          return (
            <>
              <div className="my-credit-points videofull-view" key={i}>
                <div className="container">
                  <div className="row">
                    {
                      !isMobile ?
                        <div className="row">
                          <BackButton />
                        </div>
                        : ""
                    }
                    {
                      !showLearnMore ?
                        <div className="col-md-12">
                          <div className="video-fullbox">
                            {/* {imgLoader ? (<Spinner animation="border" />) : (
                                                  
                                              )} */}
                            <div className="search__input">
                              <div className="img-box_RP">
                                <div className="row tilebox">
                                  <div
                                    className={
                                      userRole === "manager"
                                        ? "col-md-6 col-lg-7"
                                        : "col-md-12"
                                    }
                                  >
                                    <p
                                      className={
                                        userRole === "manager"
                                          ? "video-title text-start"
                                          : "video-title"
                                      }
                                    >
                                      {item?.product_name}
                                    </p>
                                  </div>
                                  {/* {userRole === "manager" ? (
                                    <div className="col-md-6 col-lg-5 mb-3 d-flex justify-content-end teambxbe">
                                      <div className="text-box-rs d-flex">
                                        <div className="img-leftbef">
                                          <img src="/images/TeamBenefit.svg" />
                                        </div>
                                        <div className="benefot">
                                          Get your team to <br></br>benefit from
                                          this video
                                        </div>
                                      </div>
                                      <div className="button-sec">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-block setup-button"
                                          onClick={() => {
                                            dispatch(setCurrentView(1));
                                            setShow(true);
                                          }}
                                        >
                                          + Assign
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )} */}
                                </div>

                                {/* {(item?.source === "broker") ? <p className="video-title">
                                {item?.name}
                              </p>
                                : <p className="video-title">
                                  {item?.product_name}
                                </p>} */}

                                {(item?.source === "ecom" ||
                                  item?.source === "brokerage") &&
                                  item?.video_type === "upload" ? (
                                  <div className="video-full">
                                    <VideoPlayer
                                      className="img-videosec learn_image-card"
                                      controls={true}
                                      // src="https://cdn-stg.niit-mts.com/xsel/media/sample-20s.m3u8"
                                      src={item?.video_link}
                                      width="720"
                                      height="420"
                                      autoPictureInPicture={true}
                                      poster={!showLearnMore ? item?.product_thumbnail : ""}
                                      fluid={true}
                                      inline={false}
                                      pictureInPictureToggle={true}
                                      type={item?.extension}
                                      onReady={onPlayerReady}
                                    />
                                  </div>
                                ) : (item?.source === "ecom" ||
                                  item?.source === "xsel" ||
                                  item?.source === "brokerage") &&
                                  item?.video_type === "embed" ? (
                                  <div
                                    className="video-full"
                                    dangerouslySetInnerHTML={{
                                      __html: item.video_link,
                                    }}
                                  >
                                  </div>
                                ) : (
                                  item.is_videofile ?
                                    <div className="video-full mt-3">
                                      <VideoPlayer
                                        className="img-videosec learn_image-card"
                                        controls={true}
                                        // src="https://cdn-stg.niit-mts.com/xsel/media/sample-20s.m3u8"
                                        src={item?.video_link}
                                        width="720"
                                        height="420"
                                        autoPictureInPicture={true}
                                        poster={!showLearnMore ? item?.product_thumbnail : ""}
                                        fluid={true}
                                        inline={false}
                                        pictureInPictureToggle={true}
                                        type={item?.extension}
                                        onReady={onPlayerReady}
                                      />
                                    </div>
                                    :
                                    <div className="video-full">
                                      <iframe
                                        className="w-1000"
                                        id="scorm_player"
                                        height={"500px"}
                                        style={{
                                          minHeight: "500px",
                                          width: "100%",
                                          margin: "0 auto",
                                        }}
                                        frameBorder="0"
                                        marginHeight="0"
                                        marginWidth="0"
                                        src={item?.video_link.replace("\\", "")}
                                        allowFullScreen={true}
                                      />
                                    </div>
                                )}
                                <div className="details-card_RP video-desc">
                                  <div className="row info-bottm-card_nw">
                                    <div className="col-md-12 ">
                                      {/* <p>{londDes} </p> */}
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item?.product_description,
                                        }}
                                      />
                                      {/* <p> Duration: 05 min</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="row videolistbox">
                          <div className="col-md-12">
                            <VideoContentSection />
                          </div>
                        </div>
                    }
                  </div>
                </div>

                <AssignCourseModal
                  show={show}
                  parentName={"Video"}
                  parentId={
                    source === "xsel" && videoDetailInfo?.length > 0
                      ? videoDetailInfo[0].course_code
                      : productId
                  }
                  setShow={setShow}
                  origin={source === "xsel" || source === "ecom" ? "xsel" : "broker"}
                  isMobile={false}
                />
              </div>
            </>
          );
        })
      ) : isLoading ? (
        ""
      ) : (
        <div className="container">
          <div className="row">
            <div className="not-found mt-3">
              <div className="interboxes">
                <img src="/images/telescope.svg" alt="Not Found" />
                <p>No video data found.</p>
              </div>
            </div>
          </div>
        </div>
      )}
       {/* <div className="video-full">
                                      <iframe
                                        className="w-1000"
                                        id="scorm_player"
                                        height={"500px"}
                                        style={{
                                          minHeight: "500px",
                                          width: "70%",
                                          margin: "0 auto",
                                        }}
                                        frameBorder="0"
                                        marginHeight="0"
                                        marginWidth="0"
                                        // src= {Assessmentpage}
                                        // src="https://www.example.com/testhtmlfiles/index.html"
                                        allowFullScreen={true}
                                      />
                                    </div> */}
    </>
  )
}

export default VideoViewPageSection;