import { clickStreamUser } from "./AppUtils"

export const videoEvents = (mPlayer, data) => {
    
    mPlayer.on('ended', () => {
        fireClickStreamEvent('ended', data, mPlayer)
    })
    mPlayer.on('error', () => {
        fireClickStreamEvent('error', data, mPlayer)
    })
    mPlayer.on('loadeddata', () => {
        fireClickStreamEvent('loadeddata', data, mPlayer)
    })
    mPlayer.on('loadedmetadata', () => {
        fireClickStreamEvent('loadedmetadata', data, mPlayer)
    })
    mPlayer.on('useractive', () => {
        fireClickStreamEvent('useractive', data, mPlayer)
    })
    mPlayer.on('userinactive', () => {
        fireClickStreamEvent('userinactive', data, mPlayer)
    })
    mPlayer.on('volumechange', () => {
        fireClickStreamEvent('volumechange', data, mPlayer)
    })
    mPlayer.on('pause', () => {
        fireClickStreamEvent('pause', data, mPlayer)
    })
    mPlayer.on('play', () => {
        fireClickStreamEvent('play', data, mPlayer)
    })
    mPlayer.on('ended', () => {
        fireClickStreamEvent('ended', data, mPlayer)
    })
    mPlayer.on('enterFullWindow', () => {
        fireClickStreamEvent('enterFullWindow', data, mPlayer)
    })
}

const fireClickStreamEvent = (type, data, player) => {
    data.video_time = player.currentTime().toFixed(2) + 'secs'
    clickStreamUser(type, data)
}