import types from './types'

// User Courses
export const getUserCourses = (payload) => ({ type: types.USER_COURSES, payload })
export const getUserCoursesResponse = (payload) => ({ type: types.USER_COURSES_RESPONSE, payload })
export const getUserCoursesClear = () => ({ type: types.USER_COURSES_CLEAR })

// In progress courses
export const getInProgressBroker = (payload) => ({ type: types.IN_PROGRESS_BROKER, payload })
export const getInProgressBrokerResponse = (payload) => ({ type: types.IN_PROGRESS_BROKER_RESPONSE, payload })
export const getInProgressBrokerClear = () => ({ type: types.IN_PROGRESS_BROKER_CLEAR })

// My Courses 
export const getMyCourses = (payload) => ({ type: types.MY_COURSES, payload })
export const getMyCoursesResponse = (payload) => ({ type: types.MY_COURSES_RESPONSE, payload })
export const getMyCoursesClear = () => ({ type: types.MY_COURSES_CLEAR })

// My LearningPaths 
export const getMyLearningPaths = (payload) => ({ type: types.MY_LEARNING_PATHS, payload })
export const getMyLearningPathsResponse = (payload) => ({ type: types.MY_LEARNING_PATHS_RESPONSE, payload })
export const getMyLearningPathsClear = () => ({ type: types.MY_LEARNING_PATHS_CLEAR })

// UnAssigned Learning Paths
export const getLPUnAssigned = (payload) => ({ type: types.LEARNING_PATHS_UNASSIGNED, payload })
export const getLPUnAssignedResponse = (payload) => ({ type: types.LEARNING_PATHS_UNASSIGNED_RESPONSE, payload })
export const getLPUnAssignedClear = () => ({ type: types.LEARNING_PATHS_UNASSIGNED_CLEAR })

// Courses Categories
export const getCourseCategories = (payload) => ({ type: types.COURSE_CATEGORIES, payload })
export const getCourseCategoriesResponse = (payload) => ({ type: types.COURSE_CATEGORIES_RESPONSE, payload })
export const getCourseCategoriesClear = () => ({ type: types.COURSE_CATEGORIES_CLEAR })

// Product Categories
export const getProductCategories = (payload) => ({ type: types.PRODUCT_CATEGORIES, payload })
export const getProductCategoriesResponse = (payload) => ({ type: types.PRODUCT_CATEGORIES_RESPONSE, payload })
export const getProductCategoriesClear = () => ({ type: types.PRODUCT_CATEGORIES_CLEAR })

// Product Categories
export const getProductCategoriesData = (payload) => ({ type: types.PRODUCT_CATEGORIES_DATA, payload })
export const getProductCategoriesDataResponse = (payload) => ({ type: types.PRODUCT_CATEGORIES_DATA_RESPONSE, payload })
export const getProductCategoriesDataClear = () => ({ type: types.PRODUCT_CATEGORIES_DATA_CLEAR })
// Product Categories
export const getSubProductCategoriesData = (payload) => ({ type: types.PRODUCT_SUB_CATEGORIES_DATA, payload })
export const getSubProductCategoriesDataResponse = (payload) => ({ type: types.PRODUCT_SUB_CATEGORIES_DATA_RESPONSE, payload })
export const getSubProductCategoriesDataClear = () => ({ type: types.PRODUCT_SUB_CATEGORIES_DATA_CLEAR })


//get selected category id
export const getSelectedCatId = (payload) => ({ type: types.SELECTED_CATEGORY, payload })

// Courses Listing
export const getCoursesListing = (payload) => ({ type: types.COURSES_LISTING, payload })
export const getCoursesListingResponse = (payload) => ({ type: types.COURSES_LISTING_RESPONSE, payload })
export const getCoursesListingClear = () => ({ type: types.COURSES_LISTING_CLEAR })

// Module Listing
export const getModuleListing = (payload) => ({ type: types.MODULE_LISTING, payload })
export const getModuleListingResponse = (payload) => ({ type: types.MODULE_LISTING_RESPONSE, payload })
export const getModuleListingClear = () => ({ type: types.MODULE_LISTING_CLEAR })

// Module Notes Get
export const getModulenotesGet = (payload) => ({ type: types.MODULE_NOTES_GET, payload })
export const getModuleNotesGetResponse = (payload) => ({ type: types.MODULE_NOTES_GET_RESPONSE, payload })
export const getModuleNotesClear = () => ({ type: types.MODULE_NOTES_GET_CLEAR })

// Module Notes update
export const getModuleNotesUpdate = (payload) => ({ type: types.MODULE_NOTES_UPDATE, payload })
export const getModuleNotesUpdateResponse = (payload) => ({ type: types.MODULE_NOTES_UPDATE_RESPONSE, payload })
export const getModuleNotesUpdateClear = () => ({ type: types.MODULE_NOTES_UPDATE_CLEAR })

// Module Notes Delete
export const getModuleNotesDelete = (payload) => ({ type: types.MODULE_NOTES_DELETE, payload })
export const getModuleNotesDeleteResponse = (payload) => ({ type: types.MODULE_NOTES_DELETE_RESPONSE, payload })
export const getModuleNotesDeleteClear = () => ({ type: types.MODULE_NOTES_DELETE_CLEAR })

// Module Favourites Get
export const getModuleFavGet = (payload) => ({ type: types.MODULE_FAV_GET, payload })
export const getModuleFavGetResponse = (payload) => ({ type: types.MODULE_FAV_GET_RESPONSE, payload })
export const getModuleFavClear = () => ({ type: types.MODULE_FAV_GET_CLEAR })

// Module Favourites Delete
export const getModuleFavDelete = (payload) => ({ type: types.MODULE_FAV_DELETE, payload })
export const getModuleFavDeleteResponse = (payload) => ({ type: types.MODULE_FAV_DELETE_RESPONSE, payload })
export const getModuleFavDeleteClear = () => ({ type: types.MODULE_FAV_DELETE_CLEAR })

// Get Favourite Courses
export const getFavoriteCourses = (payload) => ({ type: types.FAVORITE_COURSES, payload })
export const getFavoriteCoursesResponse = (payload) => ({ type: types.FAVORITE_COURSES_RESPONSE, payload })
export const getFavoriteCoursesClear = () => ({ type: types.FAVORITE_COURSES_CLEAR })

// Set Favourite Courses
export const setFavoriteCourses = (payload) => ({ type: types.SET_FAVORITE_COURSES, payload })
export const setFavoriteCoursesResponse = (payload) => ({ type: types.SET_FAVORITE_COURSES_RESPONSE, payload })
export const setFavoriteCoursesClear = () => ({ type: types.SET_FAVORITE_COURSES_CLEAR })

// Remove Favourite Courses
export const removeFavoriteCourses = (payload) => ({ type: types.REMOVE_FAVORITE_COURSES, payload })
export const removeFavoriteCoursesResponse = (payload) => ({ type: types.REMOVE_FAVORITE_COURSES_RESPONSE, payload })
export const removeFavoriteCoursesClear = () => ({ type: types.REMOVE_FAVORITE_COURSES_CLEAR })


// Buy Modal Show
export const showBuyModal = (payload) => ({ type: types.SHOW_BUY_MODAL, payload })

// Learning Path Listing
export const getLearningPath = (payload) => ({ type: types.LEARNING_PATH, payload })
export const getLearningPathResponse = (payload) => ({ type: types.LEARNING_PATH_RESPONSE, payload })
export const getLearningPathClear = () => ({ type: types.LEARNING_PATH_CLEAR })

// Learning Path Listing Broker
export const getLearningPathBroker = (payload) => ({ type: types.LEARNING_PATH_BROKER, payload })
export const getLearningPathBrokerResponse = (payload) => ({ type: types.LEARNING_PATH_BROKER_RESPONSE, payload })
export const getLearningPathBrokerClear = () => ({ type: types.LEARNING_PATH_BROKER_CLEAR })

export const openHamburger = (payload) => ({ type: types.OPEN_HAMBURGER, payload })
export const openHamburgerClear = (payload) => ({ type: types.OPEN_HAMBURGER_CLEAR, payload })


// Courses Listing from WOO-COMMERSE
export const getCoursesListingFromWoo = (payload) => ({ type: types.COURSES_LISTING_WOO, payload })
export const getCoursesListingResponseFromWoo = (payload) => ({ type: types.COURSES_LISTING_RESPONSE_WOO, payload })
export const getCoursesListingClearFromWoo = () => ({ type: types.COURSES_LISTING_CLEAR_WOO })

// Courses Categories from WOO-COMMERSE
export const getCoursesCategoriesFromBroker = (payload) => ({ type: types.COURSES_CATEGORIES_BROKER, payload })
export const getCoursesCategoriesResponseFromBroker = (payload) => ({ type: types.COURSES_CATEGORIES_RESPONSE_BROKER, payload })
export const getCoursesCategoriesClearFromBroker = () => ({ type: types.COURSES_CATEGORIES_CLEAR_BROKER })

// Courses Listing from WOO-COMMERSE
export const getCoursesListingFromBroker = (payload) => ({ type: types.COURSES_LISTING_BROKER, payload })
export const getCoursesListingResponseFromBroker = (payload) => ({ type: types.COURSES_LISTING_RESPONSE_BROKER, payload })
export const getCoursesListingClearFromBroker = () => ({ type: types.COURSES_LISTING_CLEAR_BROKER })

// Module Listing From Broker
export const getModuleListingBroker = (payload) => ({ type: types.MODULES_LISTING_BROKER, payload })
export const getModuleListingResponseBroker = (payload) => ({ type: types.MODULES_LISTING_RESPONSE_BROKER, payload })
export const getModuleListingClearBroker = () => ({ type: types.MODULES_LISTING_CLEAR_BROKER })


// Assign Course
export const getAssignCourse = (payload) => ({ type: types.ASSIGN_COURSE, payload })
export const getAssignCourseResponse = (payload) => ({ type: types.ASSIGN_COURSE_RESPONSE, payload })
export const getAssignCourseClear = () => ({ type: types.ASSIGN_COURSE_CLEAR })

// Assign Course
export const getAssignBrokerCourse = (payload) => ({ type: types.ASSIGN_COURSE_BROKER, payload })
export const getAssignBrokerCourseResponse = (payload) => ({ type: types.ASSIGN_COURSE_BROKER_RESPONSE, payload })
export const getAssignBrokerCourseClear = () => ({ type: types.ASSIGN_COURSE_BROKER_CLEAR })

export const setMarkView = (payload) => ({ type: types.MARK_VIEW, payload })
export const setMarkViewResponse = (payload) => ({ type: types.MARK_VIEW_RESPONSE, payload })
export const setMarkViewClear = () => ({ type: types.MARK_VIEW_CLEAR })

// Code of EthicsTODO:
export const getCodeOfEthics = (payload) => ({ type: types.MASTER_CODE_OF_ETHICS, payload })
export const getCodeOfEthicsResponse = (payload) => ({ type: types.MASTER_CODE_OF_ETHICS_RESPONSE, payload })
export const getCodeOfEthicsClear = () => ({ type: types.MASTER_CODE_OF_ETHICS_CLEAR })

// Ethics Agree Check
export const postEthicsAgreeCheck = (payload) => ({ type: types.ETHICS_AGREE_CHECK, payload })
export const postEthicsAgreeCheckResponse = (payload) => ({ type: types.ETHICS_AGREE_CHECK_RESPONSE, payload })
export const postEthicsAgreeCheckClear = () => ({ type: types.ETHICS_AGREE_CHECK_CLEAR })

// mark progress
export const markModuleProgress = (payload) => ({ type: types.MARK_MODULE_PROGRESS, payload })
export const markModuleProgressResponse = (payload) => ({ type: types.MARK_MODULE_PROGRESS_RESPONSE, payload })
export const markModuleProgressClear = () => ({ type: types.MARK_MODULE_PROGRESS_CLEAR })
// video progress
export const videoModuleProgress = (payload) => ({ type: types.VIDEO_PROGRESS, payload })
export const videoModuleProgressResponse = (payload) => ({ type: types.VIDEO_PROGRESS_RESPONSE, payload })
export const videoModuleProgressClear = () => ({ type: types.VIDEO_PROGRESS_RESPONSE_CLEAR })

export const getLearningPathListBroker = (payload) => ({ type: types.LEARNING_PATH_LISTING_BROKER, payload })
export const getLearningPathListResponseBroker = (payload) => ({ type: types.LEARNING_PATH_RESPONSE_BROKER, payload })
export const getLearningPathListClearBroker = () => ({ type: types.LEARNING_PATH_CLEAR_BROKER })

// mark progress
export const getLevelDetailReq = (payload) => ({ type: types.GET_LEVEL_DETAIL_REQ, payload })
export const getLevelDetailResponse = (payload) => ({ type: types.GET_LEVEL_DETAIL_RESPONSE, payload })
export const getLevelDetailResponseClear = () => ({ type: types.GET_LEVEL_DETAIL_RESPONSE_CLEAR })

// mark progress
export const getCheckListInsert = (payload) => ({ type: types.CHECKLIST_INSERT, payload })
export const getCheckListInsertResponse = (payload) => ({ type: types.CHECKLIST_INSERT_RESPONSE, payload })
export const getCheckListInsertResponseClear = () => ({ type: types.CHECKLIST_INSERT_RESPONSE_CLEAR })

// Single LP
export const getSingleLP = (payload) => ({ type: types.SINGLE_LP_DATA, payload })
export const getSingleLPResponse = (payload) => ({ type: types.SINGLE_LP_DATA_RESPONSE, payload })
export const getSingleLPClear = () => ({ type: types.SINGLE_LP_DATA_CLEAR })

// Course related
export const getCourseRelated = (payload) => ({ type: types.COURSE_RELATED, payload })
export const getCourseRelatedResponse = (payload) => ({ type: types.COURSE_RELATED_RESPONSE, payload })
export const getCourseRelatedClear = () => ({ type: types.COURSE_RELATED_CLEAR })