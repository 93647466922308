import Page from "../../components/Page";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
import CodeOfEthicsReportHomeSection from "../../components/Reports/codeOfEthics/CodeOfEthicsReportHomeSection";

export const CodeOfEthicsReportPageMobile = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
            if (document.getElementById("id_page_404") !== null) {
              document.getElementById("id_page_404").style.display = "none";
            }
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
              document.getElementById("cc_div").style.display = "none";
          }, 100)
    }, [])
    return (
        <>
            <Loader start={isLoading} />
            <CodeOfEthicsReportHomeSection
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                isMobile={true}
            />
        </>
    )
}

export default CodeOfEthicsReportPageMobile;