import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import moment from "moment";
import _ from "lodash";
import Loader from "../../components/Loader/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SelectOption from "../../components/AssignModal/SelectOption";
import ChooseTeammate from "../../components/AssignModal/ChooseTeammate";
import ChooseTeams from "../../components/AssignModal/ChooseTeams";
import CreateTeam from "../../components/AssignModal/CreateTeam";
import CreateteamOptions from "../../components/AssignModal/CreateteamOptions";
import CoursesAssign from "../../components/AssignModal/CoursesAssign";
import ChoosePeople from "../../components/AssignModal/ChoosePeople";
import SelectpeopleOptions from "../../components/AssignModal/SelectpeopleOptions";
import GenerateaReport from "../../components/AssignModal/GenerateaReport";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentView } from "../../redux/assignCourse/actions";
import AssignCourseModal from "../modal/AssignCourseModal";
import CONFIG from "../../utils/config";
import { useParams } from "react-router-dom";
import {
	checkContentAccess,
	checkContentAccessClear,
} from "../../redux/home/actions";
import { SessionKeys } from "../../utils/AppUtils";
import { useHistory } from "react-router-dom";
import {
	getModuleListing,
	getModuleListingBroker,
	getModuleListingClear,
	getModuleListingClearBroker,
	getSelectedCatId,
} from "../../redux/courses/actions";
import BackButton from "../Layouts/BackButton";
import ShimmerModuleListing from "../Layouts/ShimmerModuleListing";
import ShimmerCardBody from "../Layouts/ShimmerCardBody";
import ShimmerModuleLaunch from "../Layouts/ShimmerModuleLaunch";
import ImageComponent from "../Layouts/ImageComponent";

const ModuleSection = (props) => {
	const { navigateToDetails, moduleListData, isLoading, setIsLoading, isMobile } = props;

	const dispatch = useDispatch();
	const history = useHistory();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const source = params.get("source");
	const cid = params.get("cid");
	const { courseId } = useParams();
	const currentView = useSelector((state) => state.assignCourse.current_view);
	const checkContent = useSelector((state) => state.home.checkContent);
	const userRole = useSelector((state) => state.user.role);
	const auth = useSelector((state) => state.auth);

	const [moduleData, setModuleData] = useState({});
	const [show, setShow] = useState(false);
	const [showAssignBtn, setShowAssignBtn] = useState(true);
	const [showContent, setShowContent] = useState(true);
	const [showShimmer, setShowShimmer] = useState(false);
	const [isFree, setIsFree] = useState(false)

	useEffect(() => {
		dispatch(checkContentAccess({
			module: 'checkContentAccess',
			postData: {
				token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
				origin: source === 'ecom' ? 'xsel' : 'broker',
				entity_id: source === 'ecom' ? courseId : cid,
				entity_type: 'courses'
			}
		}))
		return (() => {
			dispatch(getModuleListingClear());
			dispatch(getModuleListingClearBroker());
		})
	}, []);

	useEffect(() => {
		if (!_.isEmpty(checkContent)) {
			if (checkContent.status === "success") {
				const data = checkContent.payload.payload;
				if (!_.isEmpty(data)) {
					setIsFree(data.is_free)
					// dispatch(checkContentAccessClear());
				}
			}
		}
	}, [checkContent]);

	useEffect(() => {
		if (showContent) {
			setShowShimmer(true)
			if (source === "xsel" || source === "ecom") {
				if (!_.isEmpty(auth.authBroker)) {
					dispatch(getModuleListingClear());
					// setIsLoading(true);
					setModuleData({});
					dispatch(
						getModuleListing({
							module: "getNIITAdminModule",
							postData: {
								user_id: auth.authBroker.payload.user.user_id,
								course_id: courseId,
							},
						})
					);
				}
			} else {
				dispatch(getModuleListingClearBroker());
				// setIsLoading(true);
				setModuleData({});
				dispatch(
					getModuleListingBroker({
						module: "get_all_course_modules",
						token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
						courseId: courseId,
					})
				);
			}
		}
	}, [showContent]);

	useEffect(() => {
		dispatch(setCurrentView(1));
		if (!_.isEmpty(moduleListData.modules)) {
			console.log('modukledataa',moduleListData.modules);
			// setIsLoading(false)
			// moduleListData?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
			setShowShimmer(false)
			setModuleData(moduleListData);
		}
	}, [moduleListData]);

	useEffect(() => {
		// console.log('currentView: ', currentView);
	}, [currentView]);

	return (
		<section className="courselistrsp">
			<section
				className="realtor__safety img-fluid"
				style={{ background: `url(${moduleData?.banner})` }}
			>
				<div className="row realtorRow leveldetails">
					<div className="col-md-6 col-sm-12 bg-whites opacity-68s realtor_col"></div>
				</div>
			
			</section>
			<div className="">
					<div className="container level_detail_progress">
						<div className="header_img_left">
							<div className="main__contrR">
								<div className="container-pie-chart">
									<div className="container-chart">
										<div className="box">
											<div
												className="circularProgress"
												style={{
													width: 150,
													height: 150,
													position: "relative",
												}}
											>
												<CircularProgressbar
													value={moduleData?.progress}
													styles={buildStyles({
														pathColor:
															moduleData?.progress < 100
																? `#6CC869`
																: "#6CC869", //FF9700=yellow
														trailColor: "#EBEBEB",
													})}
												/>
												<div
													style={{
														position: "absolute",
														top: "3rem",
														width: "150px",
														textAlign: "center",
													}}
												>
													{moduleData?.progress > -1 ? (
														<>
															<strong
																style={{ color: "black" }}
															>{`${moduleData.progress.toFixed(0)}%`}</strong>
															<div style={{ fontSize: ".8em", color: "black" }}>
																Completed
															</div>
														</>
													) : (
														""
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <h6 dangerouslySetInnerHTML={{
								__html: moduleData?.courseName,
							}}>
							</h6> */}
						</div>
					</div>
				</div>

			<div className="container">
				{!isMobile && <div className="row mt-5 ">
					<div className="col-md-12 mb-4 d-flex justify-content-start">
						<button
							className="btn btn-primary-complete bckbtn"
							onClick={() => {
								// dispatch(getSelectedCatId(""));
								// history.push(
								// 	`/courseListing?selectedCategory=&selectCourse=top`
								// );
								history.goBack();
							}}
						>
							Back
						</button>

					</div>
				</div>}
				{moduleData?.modules ? (
					<div className="row">
						<div className="col-md-6 main_realtor ">
							<h6
								className=""
								dangerouslySetInnerHTML={{
									__html: "Welcome to " + moduleData?.courseName,
								}}
							></h6>
						</div>
						{userRole === "manager" && showAssignBtn ? (
							<div className="col-md-6 d-flex justify-content-end">
								<div className="text-box-rs d-flex">
									<div className="img-leftbef">
										<img src="/images/TeamBenefit.svg" />
									</div>
									<div className="benefot">
										Get your team to <br></br>benefit from this course
									</div>
								</div>
								<div className="button-sec">
									<button
										type="button"
										className="btn btn-primary btn-block setup-button"
										onClick={() => {
											dispatch(setCurrentView(1));
											setShow(true);
										}}
									>
										+ Assign
									</button>
								</div>
							</div>
						) : (
							""
						)}
						<div className="col-md-12 main_realtor mt-2">
							<div
								className="module-dsic"
								dangerouslySetInnerHTML={{
									__html: moduleData?.coursedescription,
								}}
							></div>
						</div>
					</div>
				) : (
					""
				)}

				<div className="col-md-12 profile-left-sec">
					<div className="row">
						<div className="col-md-12 mt-3">
							{moduleData?.modules && moduleData?.modules.length > 1 ? (
								<p className="labelsearchRealtor">Main Menu</p>
							) : (
								""
							)}
							{/* {moduleData?.modules && moduleData?.modules.length > 1 ? <p className="sub_headingRealtor">Select the first module to start the course.</p> : (moduleData?.modules && moduleData?.modules.length === 1) ? <p className="sub_headingRealtor">Select the module to start.</p> : ""} */}
							{moduleData?.modules && moduleData?.modules.length > 1 ? (
								<p className="sub_headingRealtor">
									Select the first module to start the course.
								</p>
							) : (
								""
							)}
						</div>
						{
							showShimmer ? [1, 2, 3, 4].map((i) => {
								return <ShimmerModuleLaunch key={`shimmer-${i}`} />;
							}) :
								moduleData?.modules &&
									moduleData?.modules.length > 0 ? (
									moduleData?.modules?.map((m, i) => {
										console.log('moduleentriess', m)
										return (
											<div
												className="col-md-6 col-xl-3 col-lg-3 col-sm-12 mb-4 broker-box cursor-pointer module-border-box"
												key={"module-" + i}
											>
												<div className="img-box_realtor">
													<div
														onClick={() => {
															navigateToDetails(m.title, m.progress, m);
														}}
														className={`img-videosec learn-img_card learning ${m.isLock ? "cursor-not-allowed" : "cursor-pointer"
															}`}
													>
														{/* <img
														className={`img-fluid galleryImg ${m.isLock ? "grayscale" : ""
															}`}
														src={m.thumbnail}
													/> */}
														<ImageComponent
															thumbnail={m.thumbnail ? m.thumbnail : ''}
															name={m.title}
															cssClass={`img-fluid galleryImg ${m.isLock ? "grayscale" : ""}`}
															errorClass={`img-fluid grayscale d-flex justify-content-center align-items-center bk-grey h-100 ${m.isLock ? "grayscale" : ""}`}
														/>
														{m.progress != null && m.progress != 0 ? (
															<a href="#">
																<span
																	data-bs-toggle="modal"
																	data-bs-target="#premium-contentpopu"
																>
																	<span className="lear_icon">
																		<img
																			src={
																				m.progress == 100
																					? "/images/Group 2401.svg"
																					: "/images/Group 2850.svg"
																			}
																		/>
																	</span>
																</span>
															</a>
														) : (
															""
														)}
														{m.isLock ? (
															<span className="lear_icon">
																<img src="/images/Locked.svg" alt="lock" />
															</span>
														) : (
															""
														)}
														<div className="">
															<div className="">
																<ProgressBar
																	className="custom_progress_barr custom_bartwoRealtor"
																	style={{
																		paddingLeft: "0px",
																		paddingRight: "0px",
																	}}
																	now={m.progress}
																	variant={
																		m.progress < 100 ? "warning" : "success"
																	}
																/>
															</div>
														</div>
													</div>
													<div className="details-card_realtor">
														<h5
															onClick={() =>
																navigateToDetails(m.title, m.progress, m)
															}
														>
															<a className="course-head-al_search">{m.title}</a>
														</h5>
														<div
															className="row info-bottm-cardRealtor"
															onClick={() =>
																navigateToDetails(m.title, m.progress, m)
															}
														>
															<div className="col-md-12 cardicon_realtor">
																{
																	(m?.time_hrs > 0 || m?.time_min > 0) ? (
																		<>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="16"
																				height="16"
																				viewBox="0 0 16 16"
																			>
																				<path
																					id="clock-time-four"
																					d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
																					transform="translate(-2 -2)"
																					fill="var(--color-element-1)"
																				/>
																			</svg>
																			<span className="cardicon_realtor">
																				{/* {m.durationTime} */}
																				{m?.time_hrs > 0
																					? m?.time_hrs + " " + (m?.time_hrs > 1 ? "Hours" : "Hour")
																					: " "}{" "}
																				{m?.time_min > 0
																					? m?.time_min +
																					" " +
																					(m?.time_min > 1 ? "Mins" : "Min")
																					: " "}{" "}
																			</span>
																		</>
																	) : (
																		<>
																		</>
																	)
																}
																{
																	m?.desc && m?.desc.length && 
																	<OverlayTrigger
																	key="bottom"
																	placement="top"
																	overlay={
																		<Tooltip id={`tooltip-bottom`}>
																			<div
																				style={{
																					color: "white",
																					backgroundColor: "black",
																				}}
																				dangerouslySetInnerHTML={{
																					__html: m?.desc,
																				}}
																			></div>
																		</Tooltip>
																	}
																>
																	<p
																		dangerouslySetInnerHTML={{
																			__html:
																				m.desc.length <= 170
																					? m.desc
																					: m.desc.substring(0, 159) + "...",
																		}}
																	></p>
																</OverlayTrigger>
																}

			
															</div>
														</div>
													</div>
												</div>
											</div>
										);
									})
								) : (
									<div className="col-md-12 notf-ound">
										<div className="not-found mt-3">
											<div className="interboxes">
												<img src="/images/telescope.svg" alt="Not Found" />
												<p>No Module Found</p>
											</div>
										</div>
									</div>
								)

							// <div className="d-flex w-100 gap-2 align-items-center justify-content-start">
							// 	Loading modules listing
							// 	<div className="spinner">
							// 		<div className="bounce1"></div>
							// 		<div className="bounce2"></div>
							// 		<div className="bounce3"></div>
							// 	</div>
							// </div>
						}
					</div>
				</div>
				<div className="col-md-12">
					<div className="row">
						{moduleData?.attribution_text ? (
							<>
								<div className="d-flex align-items-center gap-2 mt-4">
									<div className="legaliconbox">
										{moduleData.attribution_icon ? (
											<img
												src={moduleData?.attribution_icon}
												width="42"
												height="42"
												alt="legal-hammer"
											/>
										) : (
											""
										)}
									</div>
									<div className="legal-disclaimer-text">
										<p
											className="disclaimer-text"
											dangerouslySetInnerHTML={{
												__html: moduleData?.attribution_text,
											}}
										></p>
									</div>
								</div>
							</>
						) : ''}
					</div>
				</div>
				<AssignCourseModal
					show={show}
					parentName={"Course"}
					parentId={
						source === "xsel" || source === "ecom"
							? courseId
							: moduleData?.courseId
					}
					setShow={setShow}
					origin={source === "xsel" || source === "ecom" ? "xsel" : "broker"}
					isMobile={false}
				/>
			</div>
		</section>
	);
};

export default ModuleSection;
