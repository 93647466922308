const types = {
  POST_LIST: "POST_LIST",
  POST_LIST_RESPONSE: "POST_LIST_RESPONSE",
  POST_LIST_RESPONSE_CLEAR: "POST_LIST_RESPONSE_CLEAR",

  ADD_COMMENTS: "ADD_COMMENTS",
  ADD_COMMENTS_RESPONSE: "ADD_COMMENTS_RESPONSE",
  ADD_COMMENTS_RESPONSE_CLEAR: " ADD_COMMENTS_RESPONSE_CLEAR",

  EDIT_COMMENTS: "EDIT_COMMENTS",
  EDIT_COMMENTS_RESPONSE: "EDIT_COMMENTS_RESPONSE",
  EDIT_COMMENTS_RESPONSE_CLEAR: "EDIT_COMMENTS_RESPONSE_CLEAR",

  GET_LIKE: "GET_LIKE",
  GET_LIKE_RESPONSE: "GET_LIKE_RESPONSE",
  GET_LIKE_RESPONSE_CLEAR: "GET_LIKE_RESPONSE_CLEAR",

  GET_ACTIVITY_LIKE: "GET_ACTIVITY_LIKE",
  GET_ACTIVITY_LIKE_RESPONSE: "GET_ACTIVITY_LIKE_RESPONSE",
  GET_ACTIVITY_LIKE_RESPONSE_CLEAR: "GET_ACTIVITY_LIKE_RESPONSE_CLEAR",

  DELETE_COMMENT_REQ: "DELETE_COMMENT_REQ",
  DELETE_COMMENT_RESPONSE: "DELETE_COMMENT_RESPONSE",
  DELETE_COMMENT_RESPONSE_CLEAR: "DELETE_COMMENT_RESPONSE_CLEAR",

  DELETE_POST_REQ: "DELETE_POST_REQ",
  DELETE_POST_RESPONSE: "DELETE_POST_RESPONSE",
  DELETE_POST_RESPONSE_CLEAR: "DELETE_POST_RESPONSE_CLEAR",

  EDIT_POST_REQ: "EDIT_POST_REQ",
  EDIT_POST_RESPONSE: "EDIT_POST_RESPONSE",
  EDIT_POST_RESPONSE_CLEAR: "EDIT_POST_RESPONSE_CLEAR",
};

export default types;
