import React from 'react'

import Page from "../../components/Page"
import CourseLaunchSection from '../../components/Course/CourseLaunchSection';
import ModuleDetail from '../modulePages/moduleDetail';

const CourseLaunchPage = () => {
  return (
    <Page>
      <CourseLaunchSection 
        isMobile={false}
      />
    </Page>
  )
}

export default CourseLaunchPage