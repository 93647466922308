import React, { useState } from "react";
import "./section.css";
const ChipComponent = ({ chipData, chipSelected, setChipSelected }) => {
  function handleChipSelect(id, text) {
    setChipSelected(id, text);
  }
  const singleChip = (text, id) => {
    return (
      <div
        key={text + id}
        onClick={() => handleChipSelect(id, text)}
        className={`single-chip--feedback ${
          chipSelected === text ? "active" : ""
        }`}
      >
        <p>{text}</p>
        <img src="/images/correct-icon--feedback.png" />
      </div>
    );
  };

  return (
    <div className="chip--section">
      {chipData?.map((chip) => {
        return singleChip(chip?.name, chip?.id);
      })}
    </div>
  );
};

export default ChipComponent;
