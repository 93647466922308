import types from './types'

// Login
export const login = (payload) => ({
  type: types.LOGIN, payload
})
export const loginResponse = (payload) => ({
  type: types.LOGIN_RESPONSE, payload
})
export const loginClear = () => ({ type: types.LOGIN_CLEAR })

// Login Broker
export const loginBroker = (payload) => ({
  type: types.LOGIN_BROKER, payload
})
export const loginBrokerResponse = (payload) => ({
  type: types.LOGIN_BROKER_RESPONSE, payload
})
export const loginBrokerClear = () => ({ type: types.LOGIN_BROKER_CLEAR })

export const loginIframeUserResponse = (payload) => ({
  type: types.LOGIN_IFRAME_USER_RESPONSE, payload
})
export const loginIframeUserClear = () => ({ type: types.LOGIN_IFRAME_USER_CLEAR })

// Login Host-Inst
export const loginHostInst = (payload) => ({
  type: types.LOGIN_HOSTINST, payload
})
export const loginHostInstResponse = (payload) => ({
  type: types.LOGIN_HOSTINST_RESPONSE, payload
})
export const loginHostInstClear = () => ({ type: types.LOGIN_HOSTINST_CLEAR })


// Logout
export const logout = (payload) => ({
  type: types.LOGOUT, payload
})
export const logoutResponse = (payload) => ({
  type: types.LOGOUT_RESPONSE, payload
})
export const logoutClear = () => ({ type: types.LOGOUT_CLEAR })

// Logout Broker
export const logoutBroker = (payload) => ({
  type: types.LOGOUT_BROKER, payload
})
export const logoutBrokerResponse = (payload) => ({
  type: types.LOGOUT_BROKER_RESPONSE, payload
})
export const logoutBrokerClear = () => ({ type: types.LOGOUT_BROKER_CLEAR })

// Logout Host Inst
export const logoutHostInst = (payload) => ({
  type: types.LOGOUT_HOSTINST, payload
})
export const logoutHostInstResponse = (payload) => ({
  type: types.LOGOUT_HOSTINST_RESPONSE, payload
})
export const logoutHostInstClear = () => ({ type: types.LOGOUT_HOSTINST_CLEAR })

// Forgot Password
export const forgotPwd = (payload) => ({
  type: types.FORGOT_PWD, payload
})
export const forgotPwdResponse = (payload) => ({
  type: types.FORGOT_PWD_RESPONSE, payload
})
export const forgotPwdResponseClear = () => ({ type: types.FORGOT_PWD_RESPONSE_CLEAR })

// Forgot Password Broker
export const forgotPwdBroker = (payload) => ({
  type: types.FORGOT_PWD_BROKER, payload
})
export const forgotPwdBrokerResponse = (payload) => ({
  type: types.FORGOT_PWD_BROKER_RESPONSE, payload
})
export const forgotPwdBrokerResponseClear = () => ({ type: types.FORGOT_PWD_BROKER_RESPONSE_CLEAR })

// Reset Password
export const resetPwd = (payload) => ({type: types.RESET_PWD, payload})
export const resetPwdResponse = (payload) => ({type: types.RESET_PWD_RESPONSE, payload})
export const resetPwdClear = () => ({type: types.RESET_PWD_CLEAR})

// Reset Password Broker
export const resetPwdBroker = (payload) => ({type: types.RESET_PWD_BROKER, payload})
export const resetPwdBrokerResponse = (payload) => ({type: types.RESET_PWD_BROKER_RESPONSE, payload})
export const resetPwdBrokerClear = () => ({type: types.RESET_PWD_BROKER_CLEAR})

export const verifyActivationLink = (payload) => ({type: types.VERIFY_ACTIVATION_LINK, payload})
export const verifyActivationLinkResponse = (payload) => ({type: types.VERIFY_ACTIVATION_LINK_RESPONSE, payload})
export const verifyActivationLinkClear = () => ({type: types.VERIFY_ACTIVATION_LINK_CLEAR})

// Resend Activation Link
export const resendActivationLink = (payload) => ({type: types.RESEND_ACTIVATION_LINK, payload})
export const resendActivationLinkResponse = (payload) => ({type: types.RESEND_ACTIVATION_LINK_RESPONSE, payload})
export const resendActivationLinkClear = () => ({type: types.RESEND_ACTIVATION_LINK_CLEAR})

// Setup Password
export const setupPassword = (payload) => ({type: types.SETUP_PASSWORD, payload})
export const setupPasswordResponse = (payload) => ({type: types.SETUP_PASSWORD_RESPONSE, payload})
export const setupPasswordClear = (payload) => ({type: types.SETUP_PASSWORD_CLEAR, payload})

// Setup Password Broker Admin
export const setupPasswordBroker = (payload) => ({type: types.SETUP_PASSWORD_BROKER, payload})
export const setupPasswordBrokerResponse = (payload) => ({type: types.SETUP_PASSWORD_BROKER_RESPONSE, payload})
export const setupPasswordBrokerClear = (payload) => ({type: types.SETUP_PASSWORD_BROKER_CLEAR, payload})
