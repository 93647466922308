const types = {
  RECOMMENDATION_VIEW: "RECOMMENDATION_VIEW",
  RECOMMENDATION_VIEW_RESPONSE: "RECOMMENDATION_VIEW_RESPONSE",
  RECOMMENDATION_VIEW_CLEAR: "RECOMMENDATION_VIEW_CLEAR",
  RECOMMENDATION_INSERT_VIEW: "RECOMMENDATION_INSERT_VIEW",
  RECOMMENDATION_INSERT_VIEW_RESPONSE: "RECOMMENDATION_INSERT_VIEW_RESPONSE",
  RECOMMENDATION_INSERT_VIEW_CLEAR: "RECOMMENDATION_INSERT_VIEW_CLEAR",
};

export default types;
