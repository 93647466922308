import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import Page from "../../components/Page";
import ReviewRolePlayPageSection from "../../components/RolePlay/ReviewRolePlayPageSection";

const ReviewRolePlaySection = () => {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <ReviewRolePlayPageSection 
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    isMobile={false}
                />
            </Page>
        </>
    )
}

export default ReviewRolePlaySection