import types from "./types";

const initialState = {
  profileReport: {},
  onBoardingReport: {},
  anyTotalReport: {},
  officeList: {},
  overallSummaryCrsData: {},
  overallData: {},
  crsCompletionTrend: {},
  complianceReport: {},
  complianceCompletionTrend: {},
  coeReportRea: {},
  coeTrend: {},
  coeReportManager: {},
  coeReportOffices: {},
  currentFilterView: 1,
  lpTable: {},
  lpReaRes: {},
  lpManagers: {},
  lpOffices: {},
  lpTrend: {},
  lpStats: {},
  onboardRea: {},
  onboardManagers: {},
  onboardOffices: {},
  onboardTrend: {},
  onboardStats: {},
  crsReportRea: {},
  crsReportManagers: {},
  crsReportOffices: {},
  crsReportStats: {},
  crsReportTrend: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROFILE_COMPLETION_REPORT_RESPONSE:
      // console.log(action.payload);
      return {
        ...state,
        profileReport: action.payload,
      };
    case types.PROFILE_COMPLETION_REPORT_RESPONSE_CLEAR:
      return {
        ...state,
        profileReport: {},
      };

    case types.ANY_TOTAL_REPORT_RESPONSE:
      // console.log(action.payload);
      return {
        ...state,
        anyTotalReport: action.payload,
      };
    case types.ANY_TOTAL_REPORT_CLEAR:
      return {
        ...state,
        anyTotalReport: {},
      };
    //Onboarding section report
    case types.ONBOARDING_COMPLETION_REPORT_RESPONSE:
      // console.log(action.payload);
      return {
        ...state,
        onBoardingReport: action.payload,
      };
    case types.ONBOARDING_COMPLETION_REPORT_RESPONSE_CLEAR:
      return {
        ...state,
        onBoardingReport: {},
      };
    case types.GET_OFFICE_LIST_RESPONSE:
      return {
        ...state,
        officeList: action.payload,
      };
    case types.GET_OFFICE_LIST_CLEAR:
      return {
        ...state,
        officeList: {},
      };
    case types.OVERALL_SUMMARY_PROGRESS_RESPONSE:
      return {
        ...state,
        overallSummaryCrsData: action.payload,
      };
    case types.OVERALL_SUMMARY_PROGRESS_CLEAR:
      return {
        ...state,
        overallSummaryCrsData: {},
      };
    case types.OVERALL_DATA_RESPONSE:
      return {
        ...state,
        overallData: action.payload,
      };
    case types.OVERALL_DATA_CLEAR:
      return {
        ...state,
        overallData: {},
      };
    case types.OVERALL_PROGRESS_GRAPH_RESPONSE:
      return {
        ...state,
        overallSummaryCrsGraph: action.payload,
      };
    case types.OVERALL_PROGRESS_GRAPH_CLEAR:
      return {
        ...state,
        overallSummaryCrsGraph: {},
      };
    //course completion trend
    case types.COURSE_COMPLETION_TREND_RESPONSE:
      return {
        ...state,
        crsCompletionTrend: action.payload,
      };
    case types.COURSE_COMPLETION_TREND_CLEAR:
      return {
        ...state,
        crsCompletionTrend: {},
      };
    //compliance report
    case types.COMPLIANCE_REPORT_RESPONSE:
      return {
        ...state,
        complianceReport: action.payload,
      };
    case types.COMPLIANCE_REPORT_RESPONSE_CLEAR:
      return {
        ...state,
        complianceReport: {},
      };
    case types.COMPLIANCE_COMPLETION_TREND_RESPONSE:
      return {
        ...state,
        complianceCompletionTrend: action.payload,
      };
    case types.COMPLIANCE_COMPLETION_TREND_CLEAR:
      return {
        ...state,
        complianceCompletionTrend: {},
      };
    case types.CURRENT_FILTER_VIEW:
      return {
        ...state,
        currentFilterView: action.payload,
      };
    //COE REA
    case types.COE_REA_RESPONSE:
      return {
        ...state,
        coeReportRea: action.payload,
      };
    case types.COE_REA_CLEAR:
      return {
        ...state,
        coeReportRea: {},
      };
    //COE MANAGERS
    case types.COE_MANAGER_RESPONSE:
      return {
        ...state,
        coeReportManager: action.payload,
      };
    case types.COE_MANAGER_CLEAR:
      return {
        ...state,
        coeReportManager: {},
      };
    //COE OFFICES
    case types.COE_OFFICES_RESPONSE:
      return {
        ...state,
        coeReportOffices: action.payload,
      };
    case types.COE_OFFICES_CLEAR:
      return {
        ...state,
        coeReportOffices: {},
      };
    //COE TREND
    case types.COE_TREND_RESPONSE:
      return {
        ...state,
        coeTrend: action.payload,
      };
    case types.COE_TREND_CLEAR:
      return {
        ...state,
        coeTrend: {},
      };

    //LP TABLE
    case types.LP_TABLE_RESPONSE:
      return {
        ...state,
        lpTable: action.payload,
      };
    case types.LP_TABLE_CLEAR:
      return {
        ...state,
        lpTable: {},
      };
    //LP REA
    case types.LP_REA_RESPONSE:
      return {
        ...state,
        lpReaRes: action.payload,
      };
    case types.LP_REA_CLEAR:
      return {
        ...state,
        lpReaRes: {},
      };
    //LP Managers
    case types.LP_MANAGERS_RESPONSE:
      return {
        ...state,
        lpManagers: action.payload,
      };
    case types.LP_MANAGERS_CLEAR:
      return {
        ...state,
        lpManagers: {},
      };
    //LP Offices
    case types.LP_OFFICES_RESPONSE:
      return {
        ...state,
        lpOffices: action.payload,
      };
    case types.LP_OFFICES_CLEAR:
      return {
        ...state,
        lpOffices: {},
      };
    //LP Trend
    case types.LP_TREND_RESPONSE:
      return {
        ...state,
        lpTrend: action.payload,
      };
    case types.LP_TREND_CLEAR:
      return {
        ...state,
        lpTrend: {},
      };
    //LP Stats
    case types.LP_STATS_RESPONSE:
      return {
        ...state,
        lpStats: action.payload,
      };
    case types.LP_STATS_CLEAR:
      return {
        ...state,
        lpStats: {},
      };

    //Onboard REA
    case types.ONBOARD_REPORT_REA_RESPONSE:
      return {
        ...state,
        onboardRea: action.payload,
      };
    case types.ONBOARD_REPORT_REA_CLEAR:
      return {
        ...state,
        onboardRea: {},
      };
    //Onboard Managers
    case types.ONBOARD_REPORT_MANAGERS_RESPONSE:
      return {
        ...state,
        onboardManagers: action.payload,
      };
    case types.ONBOARD_REPORT_MANAGERS_CLEAR:
      return {
        ...state,
        onboardManagers: {},
      };
    //Onboard Offices
    case types.ONBOARD_REPORT_OFFICES_RESPONSE:
      return {
        ...state,
        onboardOffices: action.payload,
      };
    case types.ONBOARD_REPORT_OFFICES_CLEAR:
      return {
        ...state,
        onboardOffices: {},
      };
    //Onboard Trend
    case types.ONBOARD_REPORT_TREND_RESPONSE:
      return {
        ...state,
        onboardTrend: action.payload,
      };
    case types.ONBOARD_REPORT_TREND_CLEAR:
      return {
        ...state,
        onboardTrend: {},
      };
    //Onboard Stats
    case types.ONBOARD_REPORT_STATS_RESPONSE:
      return {
        ...state,
        onboardStats: action.payload,
      };
    case types.ONBOARD_REPORT_STATS_CLEAR:
      return {
        ...state,
        onboardStats: {},
      };
    //Course Report Rea
    case types.COURSE_REPORT_REA_RESPONSE:
      return {
        ...state,
        crsReportRea: action.payload,
      };
    case types.COURSE_REPORT_REA_CLEAR:
      return {
        ...state,
        crsReportRea: {},
      };
    //Course Report Managers
    case types.COURSE_REPORT_MANAGERS_RESPONSE:
      return {
        ...state,
        crsReportManagers: action.payload,
      };
    case types.COURSE_REPORT_MANAGERS_CLEAR:
      return {
        ...state,
        crsReportManagers: {},
      };
    //Course Report Offices
    case types.COURSE_REPORT_OFFICES_RESPONSE:
      return {
        ...state,
        crsReportOffices: action.payload,
      };
    case types.COURSE_REPORT_OFFICES_CLEAR:
      return {
        ...state,
        crsReportOffices: {},
      };
    //Course Report Stats
    case types.COURSE_REPORT_STATS_RESPONSE:
      return {
        ...state,
        crsReportStats: action.payload,
      };
    case types.COURSE_REPORT_STATS_CLEAR:
      return {
        ...state,
        crsReportStats: {},
      };
      //Course Report Trend
    case types.COURSE_REPORT_COMP_TREND_RESPONSE:
      return {
        ...state,
        crsReportTrend: action.payload,
      };
    case types.COURSE_REPORT_COMP_TREND_CLEAR:
      return {
        ...state,
        crsReportTrend: {},
      };
    default:
      return state;
  }
};
