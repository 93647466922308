import axios from 'axios'
import { SessionKeys } from '../utils/AppUtils'

import CONFIG from '../utils/config'
import { objectToFormData } from './data'

export const resetPwd = async (payload) => {
  // console.log(payload)
  const formData = objectToFormData(payload)

  return axios({
    method: "post",
    url: CONFIG.API_URL + CONFIG.API_ROUTES.LOGIN,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(response => {
    // console.log(response)
    if (response.data.status) {
      return {
        status: 'success',
        payload: {
          message: response.data.message
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}