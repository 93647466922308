import _ from "lodash";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPostList, getPostListResponseClear } from "../../redux/myNetwork/actions";
import { SessionKeys } from "../../utils/AppUtils";
import PostList from "../myNetwork/PostList";
import UploadPostContent from "../myNetwork/UploadPostContent";

const MyWall = (props) => {
  const { fromPage, setIsLoading } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  var postListRes = useSelector((state) => state.myNetwork.postListRes);

  const [postList, setPostList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0);
    setPostList([])
    setPageNumber(1)
    return (() => {
      dispatch(getPostListResponseClear());
    })
  }, [])

  var pageNo = 1;
  useEffect(() => {
    if (!_.isEmpty(postListRes)) {
      setIsLoad(false);

      if (postListRes?.status === "success") {

        let dataRes = postListRes?.payload?.payload;
        dispatch(getPostListResponseClear());
        let postListR = dataRes?.activity_data;

        for (const item of postListR) {
          item.localComment = '';
          item.isShowCmntView = true;
          item.cmntCount=1;
        }

        setHasMore(dataRes?.next_tab)

        if (pageNumber > 1) {
          let PostList = _.cloneDeep(postList);
          setPostList(PostList.concat(postListR));
        } else {
          setPostList(postListR);
        }

      }
    }
  }, [postListRes]);

  useEffect(() => {
    if (pageNumber > 0) {
      getActivityPostList();
    }
  }, [pageNumber])





  const getActivityPostList = () => {
    setIsLoad(true);
    dispatch(
      getPostList({
        module: "getPostList",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          page: pageNumber,
          page_size: "4"
        },
      })
    );
  };

  return (
    <>
      <section className={fromPage === 'myWall' ? "my-wall-section-rsp" : "my-wall-section-rsp with-home-community"}>
        <div className={fromPage === 'myWall' ? "container" : ""}>
          <div className="row">
            <div className="col-md-12 mt-1">
              <div className="d-flex My_Dashboardwall My_Dashboard">
                <h3 className="mb-4">My Wall</h3>
                {fromPage === 'home' ? <a>
                  <button className="button-reset mywallarrow" onClick={() => { history.push('/my-wall') }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Expand"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stopColor="#00b7f1" />
                          <stop offset="1" stopColor="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_57"
                        data-name="Ellipse 57"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_179"
                        data-name="Ellipse 179"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <g
                        id="Group_1344"
                        data-name="Group 1344"
                        transform="translate(-525.5 -568.5)"
                      >
                        <path
                          id="Path_415"
                          data-name="Path 415"
                          d="M0,8H8V0"
                          transform="translate(536.5 587.5) rotate(-90)"
                          fill="none"
                          stroke="var(--color-element-1)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                        />
                        <line
                          id="Line_51"
                          data-name="Line 51"
                          x1="7"
                          y2="7"
                          transform="translate(536.5 580.5)"
                          fill="none"
                          stroke="var(--color-element-1)"
                          strokeLinecap="round"
                          strokeWidth="4"
                        />
                      </g>
                    </svg>
                  </button>
                </a> : ""}
              </div>

              {<UploadPostContent setIsLoading={setIsLoading} setPostList = {setPostList} postList = {postList} getActivityPostList={getActivityPostList} />}
              {isLoad && pageNumber < 2 ? (
                <div className="pt-5 loader-launch">
                  {[...Array(4)].map((el, index) => (
                    <ContentLoader key={index} className="pt-2 w-100" />
                  ))}
                </div>
              ) : <PostList postList={(fromPage === 'home' && postList.length > 0) ? postList : postList} setPostList = {setPostList} getActivityPostList={getActivityPostList} fromPage={fromPage} setIsLoading={setIsLoading} hasMore={hasMore} />}

              {hasMore && fromPage === 'myWall' ? <div>

                <button
                  type="submit"
                  className={`btn btn-primary-complete mt-4 center-horizontal ${isLoad ? "isLogin" : ""
                    }`}
                  disabled={isLoad ? true : ''}
                  onClick={() => {
                    let pageNumberRes = pageNumber + 1;
                    setPageNumber(pageNumberRes)
                  }}
                >
                  {isLoad ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : 
                    "Load More"
                  }
                </button>
              </div>
                : ""}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MyWall;
