import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showBuyModal } from "../../redux/courses/actions";
import { CircularProgress } from "@mui/material";
import { getOrderDetail } from "../../redux/product/actions";
import moment from 'moment'


import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import $ from "jquery";
import jsPDF from "jspdf";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StaticUtils from "../../utils/StaticUtils";

const EventDetailShowModal = (props) => {
  const {
    confirmModal,
    setConfirmModal,
    eventDetail
  } = props

  useEffect(() => {
    // console.log('modalInPopop', eventDetail)
  }, [])


  return (
    <Modal className="invoice-modal" show={confirmModal} onHide={() => setConfirmModal(false)}>
      <Modal.Body style={{ width: '800px', 'marginLeft': '-100px' }} >
        <div className="modal-dialog modal-dialog-centered modal-lg" style={{ backgroundColor: 'white' }}>
          <div className="modal-content ">
            <div className="modal-body custom_modal" >
              <div className="invoiceD">
                <div className="col-md-12 col-sm-12">
                  <div className="invoiceMainModal px-4">
                    <div className="invoice_main_modal ">
                      {/*<a href="home_new.html">
                         <img src="images/cross_logo_white.png" width="50" height="44" /></a>*/}
                      <h4><b>Session Date & Time Details</b></h4>
                    </div>

                    <div className="invoiceIcons_modal">
                      <img src="images/cross.png" width="26" height="26" alt="" onClick={() => { setConfirmModal(false) }} />

                    </div>
                  </div>

                  <section className="p-3">

                    <Table component={Paper}>
                      <TableHead>
                        <TableRow>
                          <TableCell scope="col" className="col-md-2 table-title" />
                          <TableCell scope="col" className="col-md-2 table-title">
                            <b>Start Date & Time</b>
                          </TableCell>
                          <TableCell scope="col" className="col-md-2 table-title">
                            <b>End Date & Time</b>
                          </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {eventDetail.segments &&
                          eventDetail.segments.map((date, index) => {
                            return (
                              <TableRow key={`date-${index}`}>
                                <TableCell>Part {index + 1}</TableCell>
                                <TableCell>
                                  {date.start_date_time !== undefined && date.start_date_time !== '' ? StaticUtils.getDateTimeFromTimeStamp(date.start_date_time) : '-'}
                                </TableCell>
                                <TableCell>
                                  {date.end_date_time !== undefined && date.end_date_time !== '' ? StaticUtils.getDateTimeFromTimeStamp(date.end_date_time) : '-'}
                                </TableCell>

                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>

                  </section>


                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default EventDetailShowModal;