import types from './types'

const initialState = {
    creditPoints: {},
    creditPointsHistory: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CREDIT_POINTS_RESPONSE:
            return {
                ...state,
                creditPoints: action.payload
            }

        case types.GET_CREDIT_POINTS_CLEAR:
            return {
                ...state,
                creditPoints: {}
            }
        
        case types.GET_CREDIT_POINTS_HISTORY_RESPONSE:
            return {
                ...state,
                creditPointsHistory: action.payload
            }

        case types.GET_CREDIT_POINTS_HISTORY_CLEAR:
            return {
                ...state,
                creditPoints: {}
            }

        default:
            return state
    }
}