import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import OrderHistorySection from "../../components/Orders/OrderHistorySection";
import Page from "../../components/Page";
import { getOrderHistory, getOrderHistoryClear } from '../../redux/orders/actions';
import { clickStreamUser, convertHoursToMins, convertSecondsToMins, convertTimeFormat, fixedToDecimal, SessionKeys } from "../../utils/AppUtils";
import { setEnrollBrokerSessionResponse } from "../../redux/events/actions";
import Loader from "../../components/Loader/Loader";
const OrderHistoryPage = () => {

    const dispatch = useDispatch();
    const orderHistory = useSelector(state => state.orders.orderHistory);
    const authBroker = useSelector(state => state.auth.authBroker);
    const [ordersData, setOrdersData] = useState([])
    const [filterList, setFilterList] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        return(()=>{dispatch(getOrderHistoryClear())})
    }, [])

    useEffect(() => {
        // console.log('authBroker: ', authBroker)
        if (authBroker?.payload?.user?.user_id) {
            dispatch(getOrderHistory({
                module: 'getOrderHistory',
                userId: authBroker.payload.user.email
            }))
            clickStreamUser('Order History Page', {
                userId: authBroker?.payload?.user?.user_id
            });
            dispatch(setEnrollBrokerSessionResponse())
        }
    }, [dispatch, authBroker])

    const filterWithYear = (value) => {
        let orders = _.cloneDeep(filterList)
        if (value === '') {
            setOrdersData(orders);
            return;
        }
        let tempArr = [];
        for (const item of orders) {
            if (item.order_completed_date.includes(value)) {
                tempArr.push(item)
            }
        }
        setOrdersData(tempArr)

    }

    useEffect(() => {
        console.log('orderData: ', orderHistory)
        if (!_.isEmpty(orderHistory)) {
            setIsLoading(false);
            if (orderHistory?.status === "success") {
                let ordersDatClone = _.cloneDeep(orderHistory.payload)
                for (let cartData of ordersDatClone) {
                    cartData.total = fixedToDecimal(cartData.total, 2);
                    cartData.subtotal = fixedToDecimal(cartData.subtotal, 2);
                    for (let cart of cartData.courseList) {
                        cart.selling_price = fixedToDecimal(cart.selling_price, 2);
                        cart.is_bundle = false;
                        if (cart?.list !== undefined && cart?.list?.length > 1) {
                            cart.is_bundle = true;
                            for (let course of cart?.list) {
                                cart.course_lessons = cart.course_lessons + course.lessons;
                                cart.course_duration = cart.course_duration + course.duration;
                                cart.course_period = 'Hour(s)'

                                if (course.durationUnit === 'Hours') {
                                    course.duration = convertHoursToMins(course.duration);
                                }
                                course.duration = convertTimeFormat(course.duration)
                                course.durationUnit = ''
                            }
                        } else {

                            if (cart?.product_type === 'videos') {
                                if (
                                    cart.durationUnit === "Second(s)" &&
                                    cart.course_duration >= 60
                                ) {
                                    if (cart.course_duration % 60) {
                                        cart.course_duration = convertTimeFormat(
                                            cart.course_duration,
                                            true
                                        );
                                    } else {
                                        cart.course_duration = convertSecondsToMins(
                                            cart.course_duration
                                        );
                                        cart.course_duration = convertTimeFormat(
                                            cart.course_duration
                                        );
                                    }
                                    cart.durationUnit = "";
                                } else if (
                                    cart.durationUnit === "Second(s)" &&
                                    cart.course_duration < 60
                                ) {
                                    cart.course_duration = `${cart.course_duration} Sec${cart.course_duration > 1 ? "s" : ""
                                        }`;
                                    cart.durationUnit = "";
                                } else if (cart.durationUnit === "Hours") {
                                    cart.course_duration = convertHoursToMins(
                                        cart.course_duration
                                    );
                                    cart.course_duration = convertTimeFormat(
                                        cart.course_duration
                                    );
                                    cart.durationUnit = "";
                                }
                            } else {

                                if (cart.course_period === 'Hours') {
                                    cart.course_duration = convertHoursToMins(cart.course_duration);
                                }
                                cart.course_duration = convertTimeFormat(cart.course_duration)
                                cart.course_period = ''
                                cart.durationUnit = ''
                            }
                        }
                    }
                }
                // setOrdersData(ordersDatClone)
                setFilterList(ordersDatClone)
            } else {
                setOrdersData([])
                setFilterList([])
            }
        } else {
            // if not data found
            setOrdersData([])
            setFilterList([])
        }
    }, [orderHistory])


    useEffect(()=>{
        filterWithYear(new Date().getFullYear().toString())
    },[filterList])

    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <OrderHistorySection ordersData={ordersData} isLoading={isLoading} filterWithYear = {filterWithYear}/>

            </Page>
        </>
    )
}

export default OrderHistoryPage;