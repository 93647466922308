import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import CourseList from "./CourseList";
import { SessionKeys } from "../../utils/AppUtils";
import {
  getCoursesCategoriesFromBroker,
  getCoursesListingResponse,
  getProductCategories,
  getProductCategoriesData,
  getProductCategoriesDataClear,
  getSubProductCategoriesData,
  getSubProductCategoriesDataClear,
  setFavoriteCourses,
  setFavoriteCoursesClear,
} from "../../redux/courses/actions";
import { faV } from "@fortawesome/free-solid-svg-icons";
import { getSelectedCatId } from "../../redux/courses/actions";
import ConfirmModal from "../modal/ConfirmModal";
import CourseCardListBody from "./CourseCardListBody";
import { getCoursesListing } from "../../redux/courses/actions";
import { ThreeCircles } from "react-loader-spinner";
import Loader from "../Loader/Loader";
import ShimmerCardBody from "../Layouts/ShimmerCardBody";
import ShimmerScreen from "../Layouts/ShimmerScreen";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ShimmerBannerCard from "../Layouts/ShimmerBannerCard";
import ShimmerNotification from "../Layouts/ShimmerNotification";
import ShimmerCardBodyThree from "../Layouts/ShimmerCardBodyThree";

const CourseListBody = (props) => {
  const { setCourseListCatSubCat, courseListCatSubCatShow, setIsLoadingParent } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const selectedCategory = params.get('selectedCategory');
  var selectedSubCategory = params.get('selectedSubCategory');

  const courseCategoriesData = useSelector(
    (state) => state.courses.courseCategories
  );
  var productCategoriesDataCatRes = useSelector(
    (state) => state.courses.productCategoriesData
  );
  var productCategoriesDataSubCatRes = useSelector(
    (state) => state.courses.productSubCategoriesData
  );
  const productCategoriesData = useSelector(
    (state) => state.courses.productCategories
  );
  const courseCategoriesDataBroker = useSelector(
    (state) => state.courses.coursesCategoriesBroker
  );
  // const selectedCategory = useSelector(
  //   (state) => state.courses.selectedCategory
  // );

  const favoriteCourse = useSelector(
    (state) => state.courses.setFavoriteCourses
  );
  const authBroker = useSelector((state) => state.auth.authBroker);

  const [courseCategories, setCourseCategories] = useState([]);
  const [pageType, setPageType] = useState("course");
  const [coursesListArray, setCoursesListArray] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [sortedArray, setSortedArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [closeChildModal, setCloseChildModal] = useState(false);
  const [toScrollId, setToScrollId] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const [invalidCategory, setInvalidCategory] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [filterBy, setFilterBy] = useState(0);
  const [sortBy, setSortBy] = useState(0);
  const [categoryDetail, setCategoryDetail] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [isFirst, setIsFirst] = useState(false)
  const [courseListNew, setCourseListNew] = useState([])
  const [filterListNew, setFilterListNew] = useState([])
  const [isOwnCategoryClick, setIsOwnCategoryClick] = useState(false);
  //TODO:
  const [filterData, setFilterData] = useState("New To Old");
  const [option, setOption] = useState(["All", "Available For Purchase", "Purchased Courses"]);
  const [optionSort, setSortOption] = useState(["New To Old", "Old To New"]);

  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setCourseListNew(courseListCatSubCatShow)
    setFilterListNew(courseListCatSubCatShow)
  }, [courseListCatSubCatShow])

  useEffect(() => {
    // if (params.get('selectedCategory')) {
    if (selectedCategory == params.get("selectedCategory")) {
      dispatch(getSelectedCatId(params.get("selectedCategory")));
      // }else{
      //   setInvalidCategory(isNotFound=>!isNotFound);
    }

    //}
  }, [params.get("selectedCategory"), params.get("selectCourse")]);

  const getSubCategoryCourses = (subCatId) => {

    if (checkExistingId(subCatId)) {
      for (let index = 0; index < localData.length; index++) {
        const element = localData[index];
        if (selectedSubCategory === element.catId) {
          parseSubCatRes(element.response)
          break;
        }

      }
    } else {
      setIsLoading(true)
      dispatch(getSubProductCategoriesData({
        module: "getProductSubCategoriesData",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          brokerage_id: authBroker.payload.user.company_id,
          sub_category_id: subCatId
        }
      }))
    }
  }
  const getCategoriesData = () => {


    if (checkExistingId(selectedCategory)) {
      for (let index = 0; index < localData.length; index++) {
        const element = localData[index];
        if (selectedCategory === element.catId) {
          parseCategoryDatRes(element.response)
          break;
        }

      }
    } else {
      setIsLoading(true)
      setIsFirst(true)
      dispatch(getProductCategoriesData({
        module: "getProductCategoriesData",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          brokerage_id: authBroker.payload.user.company_id,
          category_id: selectedCategory
        }
      }))
    }
  }

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {

      getCategoriesData();
      // dispatch(
      //   getProductCategories({
      //     module: "getProductCategoryFromWoo",
      //     brokerId: authBroker.payload.user.company_id,
      //   })
      // );
      // dispatch(
      //   getCoursesCategoriesFromBroker({
      //     module: "get_category_broker",
      //     brokerId: localStorage.getItem(SessionKeys.BROKER_ID),
      //   })
      // );
    }
  }, [dispatch, authBroker, selectedCategory, selectedSubCategory]);


  useEffect(() => {
    console.log('productCategoriesDataCatRes: ', productCategoriesDataCatRes)
    parseCategoryDatRes(productCategoriesDataCatRes)
  }, [productCategoriesDataCatRes])

  const parseCategoryDatRes = (productCategoriesDataCatRes) => {
    if (!_.isEmpty(productCategoriesDataCatRes)) {
      setIsLoading(false);
      setIsOwnCategoryClick(false);
      if (productCategoriesDataCatRes.status === "success") {
        const categoryData = productCategoriesDataCatRes?.payload?.payload
        if (!checkExistingId(categoryData?.category_id)) {
          var tempObj = {
            catId: categoryData?.category_id,
            type: 'cat',
            response: productCategoriesDataCatRes
          }
          var tempArr = _.cloneDeep(localData);
          tempArr.push(tempObj);
          setLocalData(tempArr);
        }
        setCategoryName(categoryData?.category_name)
        setCategoryDetail(productCategoriesDataCatRes?.payload.payload)
        if (selectedSubCategory !== undefined && selectedSubCategory !== "" && selectedSubCategory !== null && !isOwnCategoryClick) {
          // if(isFirst){
          //   setIsFirst(false)
          getSubCategoryCourses(selectedSubCategory)
          // }
        } else {
          setCourseListCatSubCat(categoryData?.courseList)
        }
        // dispatch(getProductCategoriesDataClear());
      }
    }
  }

  useEffect(() => {
    console.log('productCategoriesDataSubCatRes: ', productCategoriesDataSubCatRes)
    parseSubCatRes(productCategoriesDataSubCatRes)
  }, [productCategoriesDataSubCatRes])

  const parseSubCatRes = (productCategoriesDataSubCatRes) => {
    if (!_.isEmpty(productCategoriesDataSubCatRes)) {
      setIsLoading(false)
      if (productCategoriesDataSubCatRes.status === "success") {
        const subCategoryPayload = productCategoriesDataSubCatRes?.payload?.payload;
        if (!checkExistingId(subCategoryPayload?.sub_category_id)) {
          var tempObj = {
            catId: subCategoryPayload?.sub_category_id,
            type: 'subCat',
            response: productCategoriesDataSubCatRes
          }
          var tempArr = _.cloneDeep(localData);
          tempArr.push(tempObj);
          setLocalData(tempArr);
        }
        setCategoryName(subCategoryPayload?.sub_category_name)
        setCourseListCatSubCat(subCategoryPayload?.courseList)
      }
      dispatch(getSubProductCategoriesDataClear());
    }
  }


  const checkExistingId = (id) => {
    let isExist = false;
    let tempArr = _.cloneDeep(localData);
    for (let index = 0; index < tempArr.length; index++) {
      const element = tempArr[index];
      if (element.catId === id) {
        isExist = true;
        break;
      }
    }
    return isExist;
  }

  useEffect(() => {
    // console.log("product categories", productCategoriesData, courseCategoriesDataBroker);
    if (
      !_.isEmpty(productCategoriesData)
    ) {
      let categoryArray = [];
      if (
        !_.isEmpty(productCategoriesData) &&
        productCategoriesData.status === "success"
      ) {
        if (productCategoriesData?.payload?.length > 0) {
          categoryArray = [...categoryArray, ...productCategoriesData.payload];
        }
      }
      // if (
      //   !_.isEmpty(courseCategoriesDataBroker) &&
      //   courseCategoriesDataBroker.status === "success"
      // ) {
      //   if (courseCategoriesDataBroker?.payload?.length > 0) {
      //     categoryArray = [
      //       ...categoryArray,
      //       ...courseCategoriesDataBroker.payload,
      //     ];
      //   }
      // }
      setCourseCategories(categoryArray);
    }
  }, [productCategoriesData, courseCategoriesDataBroker]);

  useEffect(() => {
    if (props.pageType) {
      setPageType(props.pageType);
    }
  }, [props.pageType]);

  useEffect(() => {
    if (!_.isEmpty(props.coursesList)) {
      // setIsLoading(false);
      setCoursesListArray(props.coursesList);
      setFilterList(props.courseList)
      selectCategoryFunc();
    }
  }, [props.coursesList]);

  useEffect(() => {
    selectCategoryFunc();
  }, [selectedCategory]);

  useEffect(() => {
    sortCourses()
  }, [sortBy])

  useEffect(() => {
    // console.log("selectedCategory: ", selectedCategory);
    setIsNotFound(false);
  }, []);

  useEffect(() => {
    if (favoriteCourse?.status === "success") {
      // show Message
      setConfirmModal(true);
      setCloseChildModal(true);
      setShowModalMsg("Course added to favorites successfully.");
      dispatch(setFavoriteCoursesClear());
    }
  }, [favoriteCourse]);

  useEffect(() => {
    if (sortedArray.length > 0) {
      setCourseListNew(sortedArray);
      setFilterListNew(sortedArray)
    }
  }, [sortedArray]);

  useEffect(() => {
    filterCourses();
  }, [filterBy]);

  const selectCategoryFunc = () => {
    // console.log("selectedCategory: ", props.coursesList);
    // setFilterBy(0)
    setCoursesListArray([]);
    if (selectedCategory === "" && !_.isEmpty(props.coursesList)) {
      setCoursesListArray(props.coursesList);
      setFilterList(props.coursesList)
      filterCourses();
    } else {
      var tempArray = [];
      for (let index = 0; index < props.coursesList.length; index++) {
        const element = props.coursesList[index];
        if (element.categoryId == selectedCategory) {
          tempArray.push(element);
        }
      }
      setCoursesListArray(tempArray);
      setFilterList(tempArray)
      filterCourses();
    }

    if (
      params.get("selectCourse") !== "top" &&
      document.getElementById(params.get("selectCourse")) != null
    ) {
      document.getElementById(params.get("selectCourse")).scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
    setIsNotFound(true);
  };

  const makeSearch = () => {
    if (searchText === "" && selectedCategory === "" && !_.isEmpty(filterListNew)) {
      // console.log(props.CourseList, "pprrrrroooooossss");
      setCourseListNew(filterListNew);
    } else {
      var tempArray = [];
      for (let index = 0; index < filterListNew.length; index++) {
        const element = filterListNew[index];

        if (element.name.toLowerCase().includes(searchText.toLowerCase())) {
          tempArray.push(element)
        }
      }
      setCourseListNew(tempArray);
    }
  };

  const sortCourses = () => {
    let coursesArray = []; //_.isEmpty(coursesArrayData) ? [...coursesListArray] : [...coursesArrayData]

    coursesArray = _.cloneDeep(courseListNew);

    if (sortBy === "0") {

      coursesArray?.sort(function (x, y) {
        return y.created_dt - x.created_dt;
      });

      setSortedArray(coursesArray);
    } else if (sortBy === "1") {
      coursesArray?.sort(function (x, y) {
        return x.created_dt - y.created_dt;
      });
      setSortedArray(coursesArray);
    }
  }



  const filterCourses = (action = filterBy, coursesArrayData = []) => {
    // console.log('filterBy: ', props.coursesList)
    let coursesArray = []; //_.isEmpty(coursesArrayData) ? [...coursesListArray] : [...coursesArrayData]
    
      coursesArray = _.cloneDeep(filterListNew);
    if (action === "0") {
        coursesArray?.sort(function (x, y) {
          return y.created_dt - x.created_dt;
        });
      setSortedArray(coursesArray);
    } else if (action === "1") {
      let tempArray = [];
        for (let index1 = 0; index1 < coursesArray.length; index1++) {
          const element1 = coursesArray[index1];
          if (element1.showBuy) {
            tempArray.push(element1);
          }
        }
      setCourseListNew(tempArray);
    } else if (action === "2") {
      let tempArray = [];
        for (let index1 = 0; index1 < coursesArray.length; index1++) {
          const element1 = coursesArray[index1];
          if (element1.is_purchased) {
            tempArray.push(element1);
          }
        }
      setCourseListNew(tempArray);
    }
    // setSortBy(0)
    setSearchText('')
  };

  const setFavorite = (id) => {
    const postData = {
      course_id: {
        _id: {
          $oid: id,
        },
        bkmrk: true,
        type: pageType,
      },
    };
  };
  const toggleSideNav = () => {
    var sideNav = document.querySelector(".side-nav-custom");
    var toogleiconNav = document.querySelector("#openbtnNav .closetoogle");
    // console.log(btnClasslist)
    if (sideNav.classList.contains("opennav")) {
      toogleiconNav.classList.add("closeopen");
      sideNav.classList.remove("opennav");
    } else {
      sideNav.classList.add("opennav");
      toogleiconNav.classList.remove("closeopen");
    }
  };
  

  return (
    <>
      {invalidCategory ? (
        <>
          <div className="row mt-5">
            <div className="col-xl-3 col-md-6 search__input">
              <div className="input-lear position-relative d-flex">
                <label htmlFor="category" className="input__label_learning">
                  Category
                </label>
                <select
                  className="form-select input_searchfield"
                  id="category"
                  aria-label="Default select example"
                  onChange={(ev) => {
                    // dispatch(getSelectedCatId(ev.target.value));
                    history.push(
                      `/courseListing?selectedCategory=${ev.target.value}&selectCourse=top`
                    );
                  }}
                >
                  {courseCategories.map((cat, index) => {
                    return (
                      <option
                        value={cat.categoryId}
                        selected={
                          selectedCategory == cat.categoryId ? true : false
                        }
                        key={`category-${index + 1}`}
                      >
                        {cat.categoryName}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* TODO: */}
            {/* <div className="col-xl-3 col-md-6 search__input">
              <div className="input-lear position-relative d-flex">
                <label htmlFor="sortBy" className="input__label_learning">
                  Filter by
                </label>
          
                <select
                  id="sortBy"
                  className="form-select input_searchfield"
                  aria-label="Default select example"
                  onChange={(ev) => {
                    setFilterBy(ev.target.value);
                  }}
                >
                  {option.map((item, index) => (
                    <option
                      key={`filter-${index + 1}`}
                      value={
                        index
                      } 
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
            <div className="col-xl-3 col-md-6 search__input">
              <div className="input-lear position-relative d-flex">
                <label htmlFor="sortBy" className="input__label_learning">
                  Sort by
                </label>
                {/* //TODO: */}
                <select
                  id="sortBy"
                  className="form-select input_searchfield"
                  aria-label="Default select example"
                  value={sortBy}
                  onChange={(ev) => {
                    setSortBy(ev.target.value);
                  }}
                >
                  {optionSort.map((item, index) => (
                    <option
                      key={`sort-${index + 1}`}
                      value={
                        index
                      } /*selected={filterBy === index ? true : false}*/
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 search__input">
              <div className="input-lear position-relative d-flex">
                <label htmlFor="password" className="input__label_learning">
                  Search
                </label>
                <input
                  type="text"
                  id="password"
                  name="password"
                  className="input_searchfield_search"
                  value={searchText}
                  onChange={(evt) => setSearchText(evt.target.value)}
                />
                <button
                  className="button-reset"
                  style={{ marginTop: "-20px" }}
                  onClick={() => {
                    makeSearch();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    id="Find"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="0.5"
                        x2="1"
                        y2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stopColor="#00b7f1" />
                        <stop offset="1" stopColor="#ed156e" />
                      </linearGradient>
                    </defs>
                    <circle
                      id="Ellipse_191"
                      data-name="Ellipse 191"
                      cx="15"
                      cy="15"
                      r="15"
                      fill="url(#linear-gradient)"
                    />
                    <circle
                      id="Ellipse_192"
                      data-name="Ellipse 192"
                      cx="13"
                      cy="13"
                      r="13"
                      transform="translate(2 2)"
                      fill="#fff"
                    />
                    <path
                      id="Icon_awesome-search"
                      data-name="Icon awesome-search"
                      d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                      transform="translate(7.715 7.714)"
                      fill="var(--color-element-1)"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3" >
            <div className="col-md-3">
              {isOwnCategoryClick || (selectedSubCategory !== null || !isLoading) ? !_.isEmpty(categoryDetail) && <div className="side-navbar active-nav d-flexs flex-wrap flex-column">
                <div className="nav-box-sidebar d-flex gap-2">
                  <div className="nav-side-title cursor-pointer" onClick={() => {
                    history.push(`/courseListing?selectedCategory=${selectedCategory}&selectCourse=top`);
                    setIsOwnCategoryClick(true)
                    // getCategoriesData()
                  }}>
                    {categoryDetail?.category_name}
                  </div>
                  <button className="openbtn" id="openbtnNav"
                    // ref={hamburgerMenu}
                    onClick={() => {
                      toggleSideNav();
                    }}>
                    <span className="closetoogle"></span>
                  </button>
                </div>
                <ul className="nav side-nav-custom opennav flex-column w-100" >
                  {categoryDetail?.sub_categories?.map((item, index) => {
                    return <li className={selectedSubCategory !== undefined && selectedSubCategory !== "" && selectedSubCategory !== null && parseInt(selectedSubCategory) === item?.sub_cat_id ? "nav-link active cursor-pointer" : "nav-link cursor-pointer"}>
                      <span className="mx-2" onClick={() => {
                        // getSubCategoryCourses(item.sub_cat_id)
                        history.replace(
                          `/courseListing?selectedCategory=${selectedCategory}&selectedSubCategory=${item.sub_cat_id}&selectCourse=top`
                        );
                        // getSubCategoryCourses(item.sub_cat_id)

                      }}>{item?.sub_cat_name}</span>
                    </li>
                  })
                  }
                </ul>
              </div> :
                <>
                  <div className="row">
                    {
                      [1, 2].map(n => {
                        return (
                          <ShimmerNotification />
                        )
                      })
                    }
                  </div>
                </>}
            </div>
            <div className="col-md-9">
              {!isLoading ? (
                (_.isArray(courseListNew) && !_.isEmpty(courseListNew)) ?
                  <div className="row" key={`course--`}>
                    <div id={'top'} className="view-button mt-4">
                      <p className="lablesech">{categoryName}</p>
                    </div>
                    {/* {renderCourseCard(course, index)} TODO:*/}
                    <CourseCardListBody
                      key={1}
                      pageType={pageType}
                      coursesList={courseListNew}  //course.courseList
                      setFavoriteCourse={setFavorite}
                      closeModal={closeChildModal}
                      setCloseModal={setCloseChildModal}
                      hasDescription={false}
                      filterData={filterData}
                    />
                  </div>
                  : isNotFound ? (
                    <div className="not-found mt-3">
                      <div className="interboxes">
                        <img src="/images/telescope.svg" alt="Not Found" />
                        <p>Sorry no courses are available for your search!</p>
                      </div>
                    </div>
                  ) : (
                    <div className="not-found mt-3">
                      <div className="interboxes">
                        <img src="/images/telescope.svg" alt="Not Found" />
                        <p>Sorry no courses are available!</p>
                      </div>
                    </div>
                  )
              ) :
                <>
                  <div className="row">
                    <div className="view-button my-4">
                      <ShimmerScreen case="rect" width="10rem" height="1.2rem" />
                    </div>
                    {[1, 2, 3].map((c, i) => {
                      return (
                        <ShimmerCardBodyThree key={`shimmer-${i + 1}`} />
                      );
                    })}
                  </div>
                </>
              }
            </div>
          </div>
          <ConfirmModal
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            showModalMsg={showModalMsg}
          />{" "}
        </>
      ) : (
        <p>
          Invalid category, please go to <Link to="/">Home Page</Link> and try
          again{" "}
        </p>
      )}
    </>
  );
};

export default CourseListBody;
