import Page from "../../components/Page";
import PaymentSection from "../../components/Orders/PaymentSection";
import { useDispatch, useSelector } from "react-redux";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { useEffect, useState } from "react";
import CONFIG from "../../utils/config";
import { loginBrokerResponse } from "../../redux/auth/actions";
import FooterNew from "../../components/FooterNew";
import $ from "jquery";
import { cartPageDataResponse } from "../../redux/checkout/actions";
import _ from "lodash";

const PaymentMobilePage = () => {

    const dispatch = useDispatch();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const user_id = params.get('user_id');
    const broker_id = params.get('broker_id');
    const email = params.get('email');
    const key = params.get('key');
    const token = params.get('token');
    const username = params.get('username');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const item = params.get('item');
    const subTotalPrice = params.get('subTotalPrice');
    const discount = params.get('discount');
    const price = params.get('price');
    const [isValidKey, setIsValidKey] = useState(false)

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
    }, [])

    useEffect(() => {
        // console.log(user_id, email, key, token, username)
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
            $('html').css("--color-element-1", color_1 ? '#' + color_1 : CONFIG.COLOR_ELEMENT_1);
            $('html').css("--color-element-2", color_2 ? '#' + color_2 : CONFIG.COLOR_ELEMENT_2);
            localStorage.setItem(SessionKeys.API_TOKEN_BROKER, token);
            localStorage.setItem(SessionKeys.USERNAME, username);
            setIsValidKey(true)
            dispatch(loginBrokerResponse({
                payload: {
                    user: {
                        user_id: user_id,
                        email: email,
                        broker_id: broker_id
                    },
                    apiToken: token,
                }
            }))
            dispatch(cartPageDataResponse({
                item: _.toNumber(item),
                subTotalPrice: _.toNumber(subTotalPrice),
                discount: _.toNumber(discount),
                price: _.toNumber(price),
            }))
        }
    }, [token, key, username, dispatch])

    return (
        <>
            {
                (isValidKey) ?
                    <>
                        <PaymentSection />
                        <FooterNew />
                    </>
                    :
                    <div>
                        Invalid Key
                    </div>
            }
        </>
    )
}

export default PaymentMobilePage;