const data = {
    categories: [
        {
            id: 'all',
            name: 'All',
        },
        {
            id: 'BKC001',
            name: 'Broker Compliance',
        },
        {
            id: 'ST001',
            name: 'Sales Training (Fundamentals)',
        },
        {
            id: 'TBO001',
            name: 'Transactions Buyer Offer',
        },
        {
            id: 'TSL001',
            name: 'Transactions Sellers Listing',
        },
        {
            id: 'MLG001',
            name: 'Marketing and Lead Generation',
        },
        {
            id: 'RET001',
            name: 'Real Estate Technology',
        },
    ],
    coursesData: [
        {
            categoryName: 'Foundations',
            categoryId: 'BKC001',
            courseList: [
                {
                    id: 'BKC001_PRD001',
                    name: 'Products',
                    link: '',
                    imageURL: 'images/course_card_nw.jpg',
                    isPremium: true,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 547,
                    lessons: '',
                    assignedByManager: true
                },
                {
                    id: 'BKC001_RS001',
                    name: 'Realtor Safety - Health and Safety',
                    link: '',
                    imageURL: 'images/course_card_nw02.jpg',
                    isPremium: false,
                    isNew: false,
                    duration: 30,
                    durationUnit: 'Mins',
                    views: 379,
                    lessons: '',
                    assignedByManager: true
                },
                {
                    id: 'BKC001_WS001',
                    name: 'Workplace Sensitivity',
                    link: '',
                    imageURL: 'images/course_card_nw02.jpg',
                    isPremium: false,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 876,
                    lessons: '',
                    assignedByManager: false
                },
                {
                    id: 'BKC001_ASC001',
                    name: 'Anti-Spam CASL',
                    link: '',
                    imageURL: 'images/course_card_nw.jpg',
                    isPremium: false,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 754,
                    lessons: '',
                    assignedByManager: false
                },
            ]
        },
        {
            categoryName: 'Broker Compliance',
            categoryId: 'BKC001',
            coursesList: [
                {
                    id: 'BKC001_PRD001',
                    name: 'Products',
                    link: '',
                    imageURL: 'images/course_card_nw.jpg',
                    isPremium: true,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 547,
                    lessons: '',
                    assignedByManager: true
                },
                {
                    id: 'BKC001_RS001',
                    name: 'Realtor Safety - Health and Safety',
                    link: '',
                    imageURL: 'images/course_card_nw02.jpg',
                    isPremium: false,
                    isNew: false,
                    duration: 30,
                    durationUnit: 'Mins',
                    views: 379,
                    lessons: '',
                    assignedByManager: true
                },
                {
                    id: 'BKC001_WS001',
                    name: 'Workplace Sensitivity',
                    link: '',
                    imageURL: 'images/course_card_nw02.jpg',
                    isPremium: false,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 876,
                    lessons: '',
                    assignedByManager: false
                },
                {
                    id: 'BKC001_ASC001',
                    name: 'Anti-Spam CASL',
                    link: '',
                    imageURL: 'images/course_card_nw.jpg',
                    isPremium: false,
                    isNew: false,
                    duration: 15,
                    durationUnit: 'Mins',
                    views: 754,
                    lessons: '',
                    assignedByManager: false
                },
            ]
        }
    ]
}

export default data