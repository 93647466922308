import types from './types'

const initialState = {
  // Courses Categories
  videoCourseCategories: {},
  // Video Listing
  videoCoursesListing: {},
  // Video Detail
  videoDetail: {},
  //VIDEO LIST FROM BROKER ADMIN
  videoListBrAdmin:{},
  videoDetailBrAdmin:{},
  videoCompletionTrack:{},
  myVideos: {},
  videoCoursesListingByCat:{},
  videoCoursesListingBySubCat:{},
  videoCoursesListingRelated:{},
}


/**
 * Get User Courses
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
  switch (action.type) {

    case types.VIDEO_COURSE_CATEGORIES_RESPONSE:
      return {
        ...state,
        videoCourseCategories: action.payload,
      }

    case types.VIDEO_COURSE_CATEGORIES_CLEAR:
      return {
        ...state,
        videoCourseCategories: {},
      }

    case types.VIDEO_COURSES_LISTING_RESPONSE:
      return {
        ...state,
        videoCoursesListing: action.payload,
      }

    case types.VIDEO_COURSES_LISTING_CLEAR:
      return {
        ...state,
        videoCoursesListing: {},
      }
      case types.VIDEO_COURSES_LISTING_BY_CAT_RESPONSE:
      return {
        ...state,
        videoCoursesListingByCat: action.payload,
      }

    case types.VIDEO_COURSES_LISTING_BY_CAT_CLEAR:
      return {
        ...state,
        videoCoursesListingByCat: {},
      }
      case types.VIDEO_COURSES_LISTING_BY_SUB_CAT_RESPONSE:
        return {
          ...state,
          videoCoursesListingBySubCat: action.payload,
        }
  
      case types.VIDEO_COURSES_LISTING_BY_SUB_CAT_CLEAR:
        return {
          ...state,
          videoCoursesListingBySubCat: {},
        }
        case types.VIDEO_COURSES_LISTING_RELATED_RESPONSE:
        return {
          ...state,
          videoCoursesListingRelated: action.payload,
        }
  
      case types.VIDEO_COURSES_LISTING_RELATED_CLEAR:
        return {
          ...state,
          videoCoursesListingRelated: {},
        }
      // Video Detail from Ecom
      case types.VIDEO_DETAIL_LISTING_RESPONSE:
      return {
        ...state,
        videoDetail: action.payload,
      }
    case types.VIDEO_DETAIL_LISTING_CLEAR:
      return {
        ...state,
        videoDetail: {},
      }
      //video list from Broker admin      
      case types.VIDEO_DETAIL_BROKER_ADMIN_RESPONSE:
      return {
        ...state,
        videoListBrAdmin: action.payload,
      }
    case types.VIDEO_DETAIL_BROKER_ADMIN_CLEAR:
      return {
        ...state,
        videoListBrAdmin: {},
      }
      // Video detail from broker admin
      case types.VIDEO_INFO_BROKER_ADMIN_RESPONSE:
        return {
          ...state,
          videoDetailBrAdmin: action.payload,
        }
      case types.VIDEO_INFO_BROKER_ADMIN_CLEAR:
        return {
          ...state,
          videoDetailBrAdmin: {},
        }
        // video completion status
        case types.VIDEO_COMPLETION_TRACK_RESPONSE:
          return {
            ...state,
            videoCompletionTrack: action.payload,
          }
        case types.VIDEO_COMPLETION_TRACK_CLEAR:
          return {
            ...state,
            videoCompletionTrack: {},
          }

        case types.MY_VIDEO_RESPONSE:
          return {
            ...state,
            myVideos: action.payload
          }

        case types.MY_VIDEO_CLEAR:
          return {
            ...state,
            myVideos: {}
          }

    default:
      return state
  }
}
