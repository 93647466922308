export const userDetails = {
  _id: "61d7fd3f38feb03c8c1a34c2",
  username: "RS02",
  sfname: "Rishabh",
  slname: "Jain", // added
  licence_issue_date: "22-01-2021", // added
  licence_no: "lc_1234", // added
  office_city: "Gurugram", // added
  gen: "Male",
  ecode: "RS02",
  doj: 1641531600,
  dact_dt: null,
  strcode: null,
  email: "rishabh.jain@niit.com",
  mcode: "1",
  fmcode: null,
  mbl: "9999999999",
  bus_role: "ASM",
  bus_status: null,
  degn: "Manager",
  rgn: "ALL",
  dept: "Global",
  crtfct_typ: "Daikin-Certificate",
  role: "student",
  isAccepted: true,
  show: {
    shw_notification: 1,
    shw_rating: 1,
    shw_post_rating: 1,
    shw_calendar: 1,
    shw_courses: 1,
    shw_exam: 1,
    shw_crs_detail: 1,
    shw_crs_resourse_tab: 1,
    shw_crs_recording_tab: 1,
    shw_crs_share: 0,
    shw_recording_share: 1,
    shw_exclude_btn: 1,
    shw_crs_edit: 1,
    shw_crs_exclude: 1,
  },
};

export const roleServingList = {
  status: "true",
  token: "76df784b-b321-464c-bf94-d28174710cef",
  data: {
    team_lead: [
      {
        _id: 2223,
        first_name: "Shradesh",
        last_name: "P",
        username: "shradesh-9@mailinator.com",
        email: "shradesh-9@mailinator.com",
      },
    ],
    mentor: [
      {
        _id: 2223,
        first_name: "Shradesh",
        last_name: "P",
        username: "shradesh-9@mailinator.com",
        email: "shradesh-9@mailinator.com",
      },
    ],
    coach: [
      {
        _id: 2223,
        first_name: "Shradesh",
        last_name: "P",
        username: "shradesh-9@mailinator.com",
        email: "shradesh-9@mailinator.com",
      },
      {
        _id: 2223,
        first_name: "Suresh",
        last_name: "Gupta",
        username: "suresh-1@mailinator.com",
        email: "suresh-1@mailinator.com",
      },
    ],
  },
  message: "User Data ",
};

export const reportCategoryData = [
  {
    title: "Onboarding Report",
    description: "List of Users with their Onboarding status and progress.",
    role: ["realtor", "manager", "broker"],
    pageName: "/onboarding-report",
  },
  {
    title: "Profile Completion Report",
    description: "List of Users with the status of their Profile completion.",
    role: ["realtor", "manager", "broker"],
    pageName: "/profile-report",
  },
  {
    title: "Learning Path Progress",
    description:
      "List of Users undergoing Learning Path along with their progress.",
    role: ["realtor", "manager", "broker"],
    pageName: "/lp-report",
  },
  {
    title: "Course Progress",
    description:
      "List of Users undergoing various courses along with their progress.",
    role: ["realtor", "manager", "broker"],
    pageName: "/course-report",
  },
  {
    title: "Code Of Ethics Report",
    description:
      "List of Users with the status and progress of their Code Of Ethics.",
    role: ["realtor", "manager", "broker"],
    pageName: "/codeofethics-report",
  },
  {
    title: "Event Report",
    description:
      "List of Users undergoing various events along with their progress.",
    role: ["realtor", "manager", "broker"],
    pageName: "/event-report",
  },
  {
    title: "Purchase Report",
    description:
      "List of Users who have purchased courses, videos and sessions.",
    role: ["manager", "broker"],
    pageName: "/purchase-report",
  },
  {
    title: "Plan Upgrade",
    description:
      "List of Users who have upgraded their subscription plan to Premium.",
    role: ["manager", "broker"],
    pageName: "/plan-upgrade-report",
  },
];

export const reportButtonsArr = [
  {
    id: 0,
    heading: "Generate a Learning Progress Report",
    link: "/progress-report",
    linkMobile:"/progress-reportMobile",
    btnText: "Learning Progress Report",
  },
  // {
  //   id: 1,
  //   heading: "Generate a Compliance Report",
  //   link: "/progress-report&isCompliance=true",
  //   linkMobile:"/progress-report&isComplianceMobile=true",
  //   btnText: " Compliance",
  // },
  {
    id: 1,
    heading: "Generate a Learning Path Report",
    link: "/progress-report&isLearningPath=true",
    linkMobile:"/progress-report&isLearningPathMobile=true",
    btnText: "Learning Path",
  },
  // {
  //   id: 3,
  //   heading: "Generate a Code of Ethics Report",
  //   link: "/codeofethics-report",
  //   linkMobile:"/codeofethicsMobile-report",
  //   btnText: "Code Of Ethics",
  // },
  // {
  //   id: 4,
  //   heading: "Generate an Onboarding Report",
  //   link: "/progress-report&isOnboarding=true",
  //   linkMobile:"/progress-report&isOnboardingMobile=true",
  //   btnText: "Onboarding",
  // },
];

export const leaderBoardDataTest = [
  {
    user_id: "2256",
    user_points: "2256",
    user_rank: "95",
  },
  {
    user_id: "2245",
    user_points: "2245",
    user_rank: "90",
  },
  {
    user_id: "2231",
    user_points: "2231",
    user_rank: "84",
  },
  {
    user_id: "2272",
    user_points: "2272",
    user_rank: "76",
  },
  {
    user_id: "2260",
    user_points: "2260",
    user_rank: "67",
  },
  {
    user_id: "2342",
    user_points: "2342",
    user_rank: "64",
  },
  {
    user_id: "2433",
    user_points: "2433",
    user_rank: "56",
  },
  {
    user_id: "2434",
    user_points: "2434",
    user_rank: "55",
  },
  {
    user_id: "2343",
    user_points: "2343",
    user_rank: "54",
  },
  {
    user_id: "2273",
    user_points: "2273",
    user_rank: "46",
  },
  {
    user_id: "2135",
    user_points: "2135",
    user_rank: "45",
  },
  {
    user_id: "2129",
    user_points: "2129",
    user_rank: "36",
  },
  {
    user_id: "2225",
    user_points: "2225",
    user_rank: "34",
  },
  {
    user_id: "2432",
    user_points: "2432",
    user_rank: "34",
  },
  {
    user_id: "2251",
    user_points: "2251",
    user_rank: "27",
  },
  {
    user_id: "2261",
    user_points: "2261",
    user_rank: "27",
  },
  {
    user_id: "2318",
    user_points: "2318",
    user_rank: "25",
  },
  {
    user_id: "2267",
    user_points: "2267",
    user_rank: "24",
  },
  {
    user_id: "2200",
    user_points: "2200",
    user_rank: "23",
  },
  {
    user_id: "2224",
    user_points: "2224",
    user_rank: "23",
  },
  {
    user_id: "2226",
    user_points: "2226",
    user_rank: "23",
  },
  {
    user_id: "2320",
    user_points: "2320",
    user_rank: "18",
  },
  {
    user_id: "2247",
    user_points: "2247",
    user_rank: "12",
  },
  {
    user_id: "2248",
    user_points: "2248",
    user_rank: "12",
  },
  {
    user_id: "2186",
    user_points: "2427",
    user_rank: "10",
  },
  {
    user_id: "2185",
    user_points: "2427",
    user_rank: "7",
  },
  {
    user_id: "2143",
    user_points: "2427",
    user_rank: "6",
  },
  {
    user_id: "2130",
    user_points: "2427",
    user_rank: "5",
  },
  {
    user_id: "2131",
    user_points: "2427",
    user_rank: "1",
  },
];


export const levelDetailData = 
      {
          "id": "625938",
          "user_id": "1847",
          "brokerage_id": "510",
          "broker_id": "510",
          "entity_type": "learningpath",
          "start_date": "2022-07-09",
          "end_date": "2022-10-17",
          "prgm_nm": "LP 1",
          "prgm_description": "<p>This course provides a practical application of complying with Canada’s Anti-Spam Legislation (CASL).&nbsp;It also covers compliance with CASL when sending commercial electronic messages (CEMs) to an electronic address and compliance with an unsubscribe mechanism.​</p><p><i><strong>Note: </strong>We recommend you also go through the following courses for your better understanding of CASL:</i></p><ul><li><i>Seller Listing Agreement and Seller Customer Service Agreement</i></li><li><i>Buyer Representation and Customer Service Forms</i></li><li><i>Marketing Strategies/Advertising</i></li></ul>",
          "provider": {
              "provider_id": "302",
              "provider_display_name": "dk-test2",
              "provider_name": "dk-test2",
              "provider_img": "https://xselappadministrator.training.com/wp-content/uploads/2022/08/logoimages-1.png"
          },
          "source": "broker",
          "thumbnail": "https://xselappadministrator.training.com/wp-content/uploads/2022/07/1657401175-wstcoursethumb.png",
          "prgm_progress": 100,
          "course_count": 1,
          "program_days": "100",
          "coursesList": [
              {
                  "wc_post_id": "62486",
                  "prgm_nm": "",
                  "crscd": "dk-GOHGX8X",
                  "course_module_dec": null,
                  "is_course": false,
                  "market_price": "",
                  "selling_price": "",
                  "discount": "",
                  "typ": "539",
                  "provider": {
                      "provider_id": "302",
                      "provider_display_name": "dk-test2",
                      "provider_name": "dk-test2",
                      "provider_img": "https://xselappadministrator.training.com/wp-content/uploads/2022/08/logoimages-1.png"
                  },
                  "source": "broker",
                  "ttlTime": "",
                  "banner": "https://xselappadministrator.training.com/wp-content/uploads/2022/07/1657400308-wstsplash.png",
                  "resources_data": null,
                  "partner": false,
                  "sfdc_id": "",
                  "id": "62486",
                  "crs_progress": 100,
                  "isAssigned": false,
                  "views": 2,
                  "name": "LP 1 Course 1",
                  "imageURL": "https://xselappadministrator.training.com/wp-content/uploads/2022/07/1657400308-wstcoursethumb.png",
                  "launch_url": "https://xselapp.training.com/courseModuleListing",
                  "course_categoryId": "",
                  "isPremium": "Basic",
                  "start_date": "2022-07-09",
                  "end_date": "2023-01-25",
                  "is_paid": true,
                  "product_type": "",
                  "lessons": 1,
                  "duration": "1",
                  "durationUnit": "minutes",
                  "assignedByManager": true,
                  "link": "",
                  "created_date": "2022-07-09 20:58:28",
                  "due_date": "01/25/2023",
                  "assigned_date": "2022-07-09",
                  "isOverDue": false,
                  "status": "completed",
                  "assigned_by": ""
              }
          ],
          "status": "completed",
          "assigned_by": ""
      }


      export const levelListData =  [

        {

            "programID": 626521,

            "prgm_nm": "New LP 14-11",

            "program_days": 20,

            "status": true,

            "is_onboarding": true,

            "prgm_description": "<p>Desc</p>",

            "end_date": "2022-12-04",

            "progressstatus": "start",

            "prgm_progress": 35,

            "coursesList": [

                {

                    "prgm_nm": "New LP 14-11",

                    "crscd": "dk-GOHGX8X",

                    "subscription_type": "Premium",

                    "typ": "539",

                    "course_module_dec": "",

                    "provider": {

                        "provider_id": "302",

                        "provider_display_name": "dk-test2",

                        "provider_name": "dk-test2",

                        "provider_img": "https://realtorsuccessfrontity-dev.niit-mts.com/wp-content/uploads/2022/08/logo-search-grid-1x.png"

                    },

                    "banner": "https://realtorsuccessfrontity-dev.niit-mts.com/wp-content/uploads/2022/07/1657400308-wstsplash.png",

                    "resources": "",

                    "id": 62486,

                    "crs_progress": 45,

                    "name": "LP 1 Course 1",

                    "description": "Course 1 for Learning Path 1",

                    "imageURL": "https://realtorsuccessfrontity-dev.niit-mts.com/wp-content/uploads/2022/07/1657400308-wstcoursethumb.png",

                    "launch_url": "https://realtorsuccesslearning-dev.niit-mts.com/courseModuleListing",

                    "course_categoryId": null,

                    "isPremium": false,

                    "crs_status": true,

                    "crs_res_points": 0,

                    "lessons": 1,

                    "duration": 1,

                    "durationUnit": "minutes",

                    "created_date": "2022-07-09 20:58:28",

                    "is_level": false,

                    "source": "broker",

                    "views": 8,

                    "course_days": 200

                },

                {

                    "prgm_nm": "New LP 14-11",

                    "crscd": "8YIDMFN",

                    "subscription_type": null,

                    "typ": "e-Learning",

                    "course_module_dec": "",

                    "provider": "/wp-content/uploads/2022/10/xselonboardlogo.png",

                    "banner": "",

                    "resources": "",

                    "id": 857,

                    "crs_progress": 0,

                    "name": "Canada’s Anti-Spam Legislation (CASL)",

                    "description": "<p>This course provides a practical application of complying with Canada’s Anti-Spam Legislation (CASL).&nbsp;It also covers compliance with CASL when sending commercial electronic messages (CEMs) to an electronic address and compliance with an unsubscribe mechanism.​</p><p><i><strong>Note: </strong>We recommend you also go through the following courses for your better understanding of CASL:</i></p><ul><li><i>Seller Listing Agreement and Seller Customer Service Agreement</i></li><li><i>Buyer Representation and Customer Service Forms</i></li><li><i>Marketing Strategies/Advertising</i></li></ul>",

                    "imageURL": "https://rsplearning-dev.niit-mts.com/meeting/images/5210327456791863.png",

                    "launch_url": "https://realtorsuccesslearning-dev.niit-mts.com/courseModuleListing",

                    "course_categoryId": null,

                    "isPremium": false,

                    "crs_status": false,

                    "crs_res_points": 0,

                    "lessons": 5,

                    "duration": 20,

                    "durationUnit": "Minutes",

                    "created_date": "2022-06-08 21:14:11",

                    "source": "xsel",

                    "is_level": false,

                    "views": 0,

                    "course_days": 365

                },

                {

                    "prgm_nm": "New LP 14-11",

                    "crscd": "G6WUN2H",

                    "subscription_type": null,

                    "typ": "e-Learning",

                    "course_module_dec": "",

                    "provider": "/wp-content/uploads/2022/10/xselonboardlogo.png",

                    "banner": "",

                    "resources": "",

                    "prgm_id": 626521,
                    "id": 1015,

                    "crs_progress": 0,

                    "name": "Privacy Act and PIPEDA",

                    "description": "This course covers the practical application of the Canadian Privacy Act and the Personal Information Protection and Electronic Documents Act (PIPEDA) when conducting Real Estate transactions. It also covers the importance and application of the Privacy Legislation while maintaining records of consumers’ personal information.\r\n\r\n<i><strong>Note:</strong> We recommend you also go through the following for your better understanding of Privacy Act and PIPEDA:</i>​\r\n<ul>\r\n \t<li><i>Seller Listing Agreement and Seller Customer Service Agreement</i>​</li>\r\n \t<li><i>Buyer Representation and Customer Service Forms</i>​</li>\r\n \t<li><i>Marketing Strategies/Advertising</i>​</li>\r\n \t<li><i>Agreements of Purchase and Sale</i></li>\r\n</ul>",

                    "imageURL": "https://rsplearning-dev.niit-mts.com/meeting/images/355397718017558.png",

                    "launch_url": "https://realtorsuccesslearning-dev.niit-mts.com/courseModuleListing",

                    "course_categoryId": null,

                    "isPremium": false,

                    "crs_status": false,

                    "crs_res_points": 0,

                    "lessons": 5,

                    "duration": 35,

                    "durationUnit": "Minutes",

                    "created_date": "2022-06-21 10:09:11",

                    "source": "xsel",

                    "is_level": true,

                    "views": 0,

                    "course_days": 20

                }

            ],

            "provider": {

                "provider_id": "302",

                "provider_display_name": "dk-test2",

                "provider_name": "dk-test2",

                "provider_img": "https://realtorsuccessfrontity-dev.niit-mts.com/wp-content/uploads/2022/08/logo-search-grid-1x.png"

            }

        }

    ]
  