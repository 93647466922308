import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BannerSlider from "./Home/BannerSlider";
import { dynamicBanner, dynamicBannerBroker } from "../redux/Cart/actions";
import Slider from "react-slick";
import moment from "moment";
import { SessionKeys } from "../utils/AppUtils";
import _ from "lodash";
import ContentLoader from 'react-content-loader'
import ShimmerScreen from "./Layouts/ShimmerScreen";
import { clickStreamUser } from "../utils/AppUtils";

const BannerSection = () => {

  const dynamicBannerInfo = useSelector((state) => state.carts.dynamicBanner);
  const dispatch = useDispatch();
  const [bannerData, setBannerData] = useState({});
  const [bannerBrokerData, setBannerBrokerData] = useState({});
  const userRole = useSelector((state) => state.user.role);
  const [isLoading, setIsLoading] = useState(true)
  const auth = useSelector((state) => state.auth.auth);
  const authBroker = useSelector(state => state.auth.authBroker)

  // console.log("User rolesss", userRole);

  useEffect(() => {
    dispatch(
      dynamicBanner({
        broker: {
          module: "splash-banner",
          broker: true,
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          },
        },
      })
    );
    setIsLoading(true)
  }, [dispatch]);

  useEffect(() => {
    // console.log("merge data", dynamicBannerInfo);
    if (_.isArray(dynamicBannerInfo)) {
      let bannerArray = [];
      for (let banner of dynamicBannerInfo) {
        if (banner.status === "success") {
          let bannerData = _.isArray(banner.payload)
            ? banner.payload
            : [];
          bannerArray = [...bannerData, ...bannerArray];
        }
      }
      setIsLoading(false)
      setBannerData(bannerArray);
    }
  }, [dynamicBannerInfo]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    autoplaySpeed: 5000,
    infinite: true,
  };

  return (
    <>
      {userRole === "instructor" || userRole === "host" ? (
        ""
      ) : (
        <Slider {...settings}>
          {bannerData.length > 0 ? (
            bannerData.map((m, index) => {
              return (
                <section className="container-fluid p-0" key={index}>
                  <div className="row m-0">
                    <section className="baner-section home_page_bannner">
                      <div className="row m-0">
                        <div className=" p-0 carousel_slide">
                          <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item active dynamic-banner">
                                <img
                                  src={m.thumbnail}
                                  className="d-block w-100"
                                  alt="..."
                                />
                                <div className="container home-slider-inner">
                                  <div className="banner-text text-white">
                                    {m.banner_title && 
                                      <h2 className="mainHEADING">
                                      {m.banner_title}
                                    </h2>
                                    }
                                    {
                                      m.button_url && 
                                      <div className=" d-flex py-3">
                                      <a
                                        href={m.button_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-block setup-button"
                                          onClick={() => {
                                            clickStreamUser("more-info-button-clicked", {
                                              url: m.button_url,
                                              userId:authBroker?.payload?.user.user_id,
                                            });
                                          }
                                          }
                                        >
                                          {m.button_text}
                                        </button>
                                      </a>
                                    </div>
                                    }
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </section>
              );
            })
          ) : (
            <>
              {/* <div className="nobannerimage"></div>
              <div className="container">
                <p
                  className="my-cart-texts"
                  style={{ fontSize: "18px", fontFamily: "verdana" }}
                ></p>
              </div> */}
              {
                isLoading ?
                  <div className="nobannerimage ">
                    <ShimmerScreen
                      case={'rect'}
                      width={'100%'}
                      height={'100%'}
                    />
                  </div> :
                  <div className="nobannerimage loading-stop">
                    <p
                      className="my-cart-texts"
                      style={{ fontSize: "18px", fontFamily: "verdana" }}
                    ></p>
                  </div>
              }
            </>
          )}
        </Slider>
      )}
    </>
  );
};
export default BannerSection;
