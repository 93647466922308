import Page from "../../components/Page";


const CreditPointsHistoryPage = () => {


    return (
        <Page>
            <section className="pointer-history-events-table pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 points-head-history">
                            <h1><strong>908</strong> Learning Points</h1>
                        </div>
                        <div className="col-md-12">
                            <div className="responsive-carousel-points-table">
                                <div className="pointsbox-table">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Points Earned</th>
                                                <th scope="col">Point Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>

                                            </tr>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>

                                            </tr>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>
                                            </tr>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div className="pointsbox-table">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Points Earned</th>
                                                <th scope="col">Point Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>

                                            </tr>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>

                                            </tr>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>
                                            </tr>
                                            <tr>
                                                <td>Completed FINTRAC course</td>
                                                <td>22 May 2022, 9:34AM</td>
                                                <td><strong>100</strong></td>
                                                <td>980</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>
    )

}

export default CreditPointsHistoryPage;