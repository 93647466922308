import { Fragment, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getModuleNotesDelete, getModuleNotesDeleteClear,
  getModulenotesGet, getModuleNotesUpdate, getModuleNotesUpdateClear
} from "../../redux/courses/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
// import CONFIG from "../../config";
import html2canvas from "html2canvas";
import header from 'jspdf'
// import { renderToString } from "react-dom/server";
// import { updateNotes, deleteNotes } from "../SuperClass/notesAPI";
import { Spinner } from "react-bootstrap";
import AlertDeleteNotesModal from "./alertDeleteNotesModal";
import $ from "jquery";
import { SessionKeys, checkCall } from "../../utils/AppUtils";
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const ModuleNotes = (props) => {

  const {
    interactive,
    notesList,
    onUpdateNotes,
    onPostMessage,
    currentModuleData,
    modifyNotes,
    setStateVar,
    moduleId,
    NewNote,
    courseCode
  } = props;

  const [notesData, setNotesData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [show, setShow] = useState(false);
  const [selFav, setSelFav] = useState({});
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const textAreaRef = useRef(null);
  const notesRef = useRef(null);
  const moduleNoteData = useSelector(state => state.courses.moduleNotesGet);
  const moduleNotesUpdate = useSelector(state => state.courses.moduleNotesUpdate);
  const moduleNotesDelete = useSelector(state => state.courses.moduleNotesDelete);
  const authBroker = useSelector(state => state.auth.authBroker);
  let authObj = JSON.parse(localStorage.getItem('persist:root'))
  const user_id = JSON.parse(authObj?.auth)?.authBroker.payload.user.user_id || localStorage.getItem(SessionKeys.EMAIL_SESSION);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const source = params.get("source");
// console.log("currnt module data",currentModuleData)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    if (!_.isEmpty(authBroker) && (source != "broker")) {
      // console.log('getNotes in module notes', moduleId, courseCode.split('?')[0])
      dispatch(getModulenotesGet({
        module: 'getNotes',
        postData: {
          mid: moduleId,
          uid: authBroker.payload.user.user_id,
          course_code: courseCode.split('?')[0]
        }
      }))
      // console.log('getNotes in module', moduleNoteData)
    }
  }, [moduleId, dispatch, authBroker])

  useEffect(() => {
    // console.log('formData', formData)
  }, [formData])

  useEffect(() => {
    // console.log('moduleNotes: ', moduleNoteData)
    if (!_.isEmpty(moduleNoteData) && moduleNoteData.status === "success") {
      if (!_.isEmpty(moduleNoteData.payload.data)) {
        if (moduleNoteData.payload.data.length > 0) {
          // console.log('notesData', notesData, moduleNoteData.payload.data)
          const nData = _.clone(notesData)
          const moduleNotes = _.clone(moduleNoteData.payload.data)
          let sourceId = []
          for (let notes of moduleNotes) {
            sourceId.push(notes.sid)
          }
          for (let note of nData) {
            // console.log('note', note, sourceId)
            if (note.isEditable
              && !sourceId.includes(note.sid)
            ) {
              moduleNotes.push(note)
            }
          }
          setNotesData(moduleNotes);
        }
      } else {
        setNotesData([])
      }
    } else {
      setNotesData([])
    }
  }, [moduleNoteData])



  useEffect(() => {
    // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
    // dispatch(getModulenotesGet({
    //   module: 'getNotes',
    //   // token: localStorage.getItem(SessionKeys.API_TOKEN),
    //   postData: { mid: moduleId, uid:user_id, course_code:courseCode }
    // }))
  }, [dispatch, moduleId])

  useEffect(() => {
    // console.log('update', moduleNotesUpdate)
    if (!_.isEmpty(moduleNotesUpdate)) {
      // console.log('updated', moduleNotesUpdate.payload.status)
      if (moduleNotesUpdate.payload.status == true) {
        // console.log('getNotes 3')
        // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
        dispatch(getModulenotesGet({
          module: 'getNotes',
          // token: localStorage.getItem(SessionKeys.API_TOKEN),
          postData: { mid: moduleId, uid: user_id, course_code: courseCode.split('?')[0] }
        }))
        dispatch(getModuleNotesUpdateClear())
      }
    }
  }, [moduleNotesUpdate])

  useEffect(() => {
    // console.log('delete', moduleNotesDelete)
    if (!_.isEmpty(moduleNotesDelete)) {
      // console.log('deleted', moduleNotesDelete.payload)
      if (moduleNotesDelete.payload) {
        if (moduleNotesDelete.payload.status == true) {
          // console.log('getNotes 4')
          // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
          dispatch(getModulenotesGet({
            module: 'getNotes',
            // token: localStorage.getItem(SessionKeys.API_TOKEN),
            postData: { mid: moduleId, uid: user_id, course_code: courseCode.split('?')[0] }
          }))
          handleClose()
          dispatch(getModuleNotesDeleteClear())
        }

      }
    }
  }, [moduleNotesDelete])



  useEffect(() => {
    if (interactive) {
      // console.log('formData', interactive)
      // return;
      setHasError(false);
      let currentIndex = notesData.findIndex(
        (x) => x.sid == interactive.sectionId
      );
      // console.log(currentIndex)
      if (currentIndex !== -1) {
        setEditMode(true);
        let filteredList = notesData.map((x) =>
          x.sid == interactive.sectionId
            ? { ...x, isEditable: true }
            : { ...x, isEditable: false }
        );
        // console.log('formData filtered', filteredList)
        setNotesData([...filteredList]);
        setFormData({ ...notesData[currentIndex] });
        setTimeout(() => {
          if (textAreaRef.current) {
            textAreaRef.current.scrollIntoView();
          }
        }, 500);
      } else {

        setImgLoader(true)

        setEditMode(true);
        let url = currentModuleData.url;
        // let url = 'https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657163/launch.html' // currentModuleData.url; //"https://iv17-knowledge.niit-mts.com/ek_site/rsp/COURSE655279/launch.html";
        // let url = currentModuleData.url ? currentModuleData.url : 'https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657163/launch.html';
        // let thumbnailPath =interactive.img

        let newPayload = {
          sid: interactive.sectionId,
          moduleName: interactive.moduleName,
          mid: interactive.moduleId,
          ttl: interactive.title,
          thmb: interactive.img,
          isEditable: true,
          nts: "",
        };
        notesData.unshift(newPayload);
        // console.log('formData filtered', notesData)
        setNotesData([...notesData]);
        setFormData([...notesData]);
        if (notesRef.current) {
          notesRef.current.scrollIntoView();
        }
        setTimeout(() => {
          setImgLoader(false)
        }, 1000)
      }
    }
  }, [interactive]);

  const handleClose = (status) => {
    setShow(false);
    // if (status === "No") {
    //   window.captureMyEvents("notesDeleteNo", {
    //     mid: selFav.module_id,
    //     sid: selFav.sid,
    //   });
    // }
  };

  const handleShow = (mid, sid) => {
    window.captureMyEvents("notesDelete", {
      mid: selFav.module_id,
      sid: selFav.sid,
    })
    setSelFav({ mid: mid, sid: sid });
    setShow(true);
  };

  /**
   * Get updated props
   */

  /**
   * Update notes list
   */
  useEffect(() => {
    if (notesList.length > 0) {
      setEditMode(false);
    }
    setNotesData(notesList);
  }, [notesList]);
  /**
   * Handle notes input action
   * @param {*} evt
   */
  const handleNotesInput = (evt, n) => {
    const { name, value } = evt.target;
    // console.log('formData', value, n)
    const nNotes = _.clone(notesData);
    for (let note of nNotes) {
      if (note.sid === n.sid) {
        note.nts = value;
      }
    }
    setNotesData([...nNotes]);
    // setFormData({ ...formData, [name]: value });
  };

  /**
   * Add/Update Note
   * @param {*} sid
   */
  const addNotes = (n) => {
    // window.captureMyEvents("noteAdded", {
    //   mid: moduleInttro.module_id,
    //   sid: sid,
    // })
    // console.log('add Notes', n)
    // return;
    if (n.nts === "") {
      setHasError(true);
      return;
    }
    setHasError(false);
    setEditMode(false)
    let currentNoteidx = notesData.findIndex((x) => x.sid === n.sid);
    // console.log(n)
    // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
    dispatch(getModuleNotesUpdate({
      // token: localStorage.getItem(SessionKeys.API_TOKEN),
      module: 'updateNotes',
      postData: {
        mid: n.mid,
        sid: n.sid,
        nts: n.nts,
        moduleName: n.moduleName,
        thmb: n.thmb,
        ttl: n.ttl,
        uid: user_id,
        course_code: courseCode.split('?')[0],
      }
    }))
    checkCall()
  };

  /**
   * Cancel Note Add/Update
   * @param {*} sid
   */
  const handleCancel = (sid) => {
    let filteredList = notesData
      .map((x, key) =>
        x.sid === sid ? { ...x, isEditable: !x.isEditable } : x

      )
      .filter((x) => x._id);
    setNotesData([...filteredList]);
    setFormData(null);
    setEditMode(false);
    setHasError(false);
    // window.captureMyEvents("notesCancel", {
    //   mid: moduleInttro.module_id,
    //   sid: sid,
    // })
  };

  /**
   * On edit note action
   * @param {*} sid
   */
  const onEdit = (sid, note) => {
    // window.captureMyEvents("notesEdit", {
    //   mid: moduleInttro.module_id,
    //   sid: sid,
    // })
    let filteredList = notesData.map((x) =>
      x.sid === sid ? { ...x, isEditable: true } : { ...x, isEditable: false }
    );
    setNotesData([...filteredList]);
    setFormData({ ...note });
    setEditMode(true);
    setHasError(false);
    setStateVar(1);
  };

  /**
   * Handle post message data on click
   * @param {*} e
   * @param {*} sid
   */
  const handlePostMessage = (e, sid) => {
    e.preventDefault();
    onPostMessage(sid);
  };

  /**
   delete Notes
  */
  const deleteNote = () => {
    // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
    dispatch(getModuleNotesDelete({
      // token: localStorage.getItem(SessionKeys.API_TOKEN),
      module: 'deleteNotes',
      postData: {
        mid: selFav.mid,
        sid: selFav.sid,
        uid: user_id,
        course_code: courseCode.split('?')[0]
      }
    }))
  }

  const getPDF = (download) => {
    // window.captureMyEvents("notesDownload", {
    //   mid: moduleInttro.module_id,
    //   sid: selFav.sid,
    // })
    if (editMode) {
      return
    }
    // var top_left_margin = 18;
    // var PDF_Width = HTML_Width + (top_left_margin * 2);
    // var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    // var canvas_image_width = HTML_Width;
    // var canvas_image_height = HTML_Height;
    // var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    setDownloading(true);
    setLoader(true)
    html2canvas($(".canvas_div_pdf")[0], {
      allowTaint: true,
      useCORS: true,
      onclone: (document) => {
        var delIcon = document.getElementsByClassName("print-icons");
        if (delIcon.length > 0) {
          for (var i = 0; i < delIcon.length; i++) {
            delIcon[i].style.display = "none";
          }
        }
        let deleteIcon = document.getElementsByClassName("delete-icons-rsp");

        if (deleteIcon.length > 0) {
          for (var i = 0; i < deleteIcon.length; i++) {
            deleteIcon[i].style.display = "none";
          }
        }
      }
    }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);
      // canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);
      // var imgData = canvas.toDataURL("image/jpeg", 0.90);
      // var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      // pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      // for (var i = 1; i <= totalPDFPages; i++) {
      //   pdf.addPage(String(PDF_Width), String(PDF_Height));
      //   pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height, { align: 'center' });
      // }
      let imgWidth = 292;
      let pageHeight = $(".canvas_div_pdf").height();
      let heightt = $(".canvas_div_pdf").height();
      let wdth = $(".canvas_div_pdf").width();
      let imgHeight =
        ((canvas.height * imgWidth)/2000)*3.4;
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "a4",
        setFontType: "verdana",
        setFontSize: "18",
        margins: {
          top: 40,
          bottom: 60,
          left: 40,
          width: 522
        }
      });

      let position = 28;
      // pdf.setfillcolor(248); 

      // pdf.text('SMRF 360', 10, 420);
      // pdf.setFontSize(15);
      // pdf.text(`Feedback report`, 10, 433)
      // pdf.text('March 2021', 10, 440)
      // pdf.addPage();

      pdf.addImage(imgData, "PNG", 1, position, imgWidth, imgHeight);
      var top_left_margin = 18;
      var canvas_image_width = wdth;
      var canvas_image_height = heightt;
      var PDF_Width = imgWidth + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 3.5) + (top_left_margin * 2);
      var pagecount = Math.ceil(heightt / PDF_Width) - 4;
      for (var i = 1; i <= pagecount; i++) {
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height, { align: 'center' });
      }
      // if (pagecount > 0) {
      //   var j = 1;
      //   while (j != pagecount) {    
      //     pdf.addPage(String(PDF_Width), String(PDF_Height));    
      //     pdf.addImage(imgData, "PNG", 1, position, imgWidth, imgHeight);
      //     j++;
      //   }
      // }


      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   // pdf.addPage();
      //   pdf.addImage(imgData, "PNG", 10, totalPDFPages, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;
      // }

      if (download) {
        pdf.save(`${currentModuleData.title}.pdf`);
        // pdf.save("Notes.pdf");
      } else {
        pdf.output(`dataurlnewwindow`);
      }
      setDownloading(false);
      setLoader(false)
    });
  };
  /**
   * render content
   */
  return (
    <div ref={notesRef}>
      <div className="containers p-0">
        <div className="row mb-3 notes-text">
          {notesData.length > 0 ? <div className=" col-md-12 d-flex thumb-image"> <p className="titlesecnotes">Module </p>
            <div className="print-icons">
              <a className="bg cursor-pointer" onClick={() => getPDF(true)}>
                {/* <img className="notesModule_icon" src="/images/save.png" alt="show" /> */}
                <button className={`button-reset ${editMode ? 'cursor-not-allowed' : 'cursor-pointer'}`} disabled={editMode}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <defs>
                      <linearGradient id="linear-gradientnotes" x2="1" y1="0.5" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#00b7f1"></stop>
                        <stop offset="1" stopColor="#ed156e"></stop>
                      </linearGradient>
                    </defs>
                    <circle cx="15" cy="15" r="15" fill="url(#linear-gradientnotes)" data-name="Ellipse 180"></circle>
                    <circle cx="13" cy="13" r="13" fill="#fff" data-name="Ellipse 181" transform="translate(2 2)"></circle>
                    <path
                      fill="#7a549e"
                      d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                      data-name="Icon awesome-file-pdf"
                      transform="translate(9 7)"
                    ></path>
                  </svg>
                </button>
              </a>
              <a className="bg cursor-pointer" onClick={() => getPDF(false)}>

                {/* <img className="notesModule_icon" src="/images/printer.png" alt="show" /> */}
                <button className={`button-reset ${editMode ? 'cursor-not-allowed' : ''}`} disabled={editMode}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <defs>
                      <linearGradient id="linear-gradientnotes" x2="1" y1="0.5" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#00b7f1"></stop>
                        <stop offset="1" stopColor="#ed156e"></stop>
                      </linearGradient>
                    </defs>
                    <circle cx="15" cy="15" r="15" fill="url(#linear-gradientnotes)" data-name="Ellipse 57"></circle>
                    <circle cx="13" cy="13" r="13" fill="#fff" data-name="Ellipse 179" transform="translate(2 2)"></circle>
                    <g fill="var(--color-element-1)" data-name="Icon ionic-ios-print" transform="translate(4.504 4.504)">
                      <path
                        d="M9 16.86v6.846a.549.549 0 00.548.548h7.668a.549.549 0 00.548-.548V16.86a.549.549 0 00-.548-.548H9.548A.549.549 0 009 16.86z"
                        data-name="Path 1248" transform="translate(-2.886 -6.639)"
                      ></path>
                      <g data-name="Group 1142" transform="translate(3.375 3.375)">
                        <path
                          d="M17.186 3.375H8.423a.549.549 0 00-.548.548v.685a.137.137 0 00.137.137H17.6a.137.137 0 00.137-.137v-.685a.549.549 0 00-.551-.548z"
                          data-name="Path 1249" transform="translate(-5.684 -3.375)"
                        ></path>
                        <path
                          d="M16.537 7.875H4.488a1.1 1.1 0 00-1.113 1.071v5.97a1.125 1.125 0 001.113 1.106h.531a.275.275 0 00.274-.274V12.12a.957.957 0 01.958-.958h8.489a.957.957 0 01.958.958v3.629a.275.275 0 00.274.274h.565a1.1 1.1 0 001.078-1.106V8.946a1.066 1.066 0 00-1.078-1.071z"
                          data-name="Path 1250"
                          transform="translate(-3.375 -5.684)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </button>
              </a>
            </div>

          </div> : ''}


          {notesData.length > 0 ? (
            <div id="notePrint" className="col-md-12 canvas_div_pdf">
              {notesData.map((n, index) => {
                return (
                  <Fragment key={index}>
                    {/* <div className="row pb-1">
                      <div className="col-md-12 d-flex justify-content-between">
                        <span className="col-lg-6 course1">Module</span>
                        <div className="d-flex">
                        <a className="bg" onClick={() => getPDF(true)}>
                          <img className="notesModule_icon" src="/images/printer.png" alt="show" />
                        </a>
                        <a className="bg" onClick={() => onEdit(n.sid, n)}>
                          <img className="notesModule_icon" src="/images/edit_pencil.png" alt="edit" />
                        </a>
                        <a className="bg" onClick={() => deleteNote(n.sid, n.mid)}>
                          <img className="notesModule_icon" src="/images/delete.png" alt="show" />
                        </a>
                      </div>
                      </div>
                    </div> */}
                    <div className="row pb-1">
                      <div className="col-md-10 col-xs-10 col-10 note-thumb">
                        {imgLoader ? (<Spinner animation="border" />) : (
                          <img
                            className="img-fluid img-thumbnail cursor-pointer"
                            src={n.thmb}
                            alt={n.ttl}
                            onClick={(e) => handlePostMessage(e, n.sid)}
                            crossOrigin="anonymous"
                          />
                        )}
                      </div>
                      <div className="col-md-2 col-xs-2 col-2 iconboxdel">
                        <div id={"deleteIcons" + index} className="delete-icons-rsp">
                          {/* Delete Button */}
                          {!n.isEditable ?
                            <>
                              <a className="bg cursor-pointer" onClick={() => handleShow(n.mid, n.sid)}>
                                {/* <img className="notesModule_icon" src="/images/delete.png" alt="show" /> */}
                                <button className="button-reset">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                  >
                                    <defs>
                                      <linearGradient
                                        id="linear-gradientnotes"
                                        x2="1"
                                        y1="0.5"
                                        y2="0.5"
                                        gradientUnits="objectBoundingBox"
                                      >
                                        <stop offset="0" stopColor="#00b7f1"></stop>
                                        <stop offset="1" stopColor="#ed156e"></stop>
                                      </linearGradient>
                                    </defs>
                                    <circle
                                      cx="15"
                                      cy="15"
                                      r="15"
                                      fill="url(#linear-gradientnotes)"
                                      data-name="Ellipse 57"
                                    ></circle>
                                    <circle
                                      cx="13"
                                      cy="13"
                                      r="13"
                                      fill="#fff"
                                      data-name="Ellipse 179"
                                      transform="translate(2 2)"
                                    ></circle>
                                    <path
                                      fill="#7a549e"
                                      d="M10.582.784h-2.94l-.23-.458A.588.588 0 006.885 0h-2.8a.581.581 0 00-.524.326l-.23.458H.392A.392.392 0 000 1.176v.784a.392.392 0 00.392.392h10.19a.392.392 0 00.392-.392v-.784a.392.392 0 00-.392-.392zM1.3 11.439a1.176 1.176 0 001.173 1.1H8.5a1.176 1.176 0 001.173-1.1l.519-8.3H.784z"
                                      data-name="Icon awesome-trash"
                                      transform="translate(9.514 8.729)"
                                    ></path>
                                  </svg>
                                </button>
                              </a>
                              {/* Edit Button */}
                              <a className="bg cursor-pointer" onClick={() => onEdit(n.sid, n)}>
                                {/* <img className="notesModule_icon" src="/images/edit_pencil.png" alt="edit" /> */}
                                <button className="button-reset">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                  >
                                    <defs>
                                      <linearGradient
                                        id="linear-gradientnotes"
                                        x2="1"
                                        y1="0.5"
                                        y2="0.5"
                                        gradientUnits="objectBoundingBox"
                                      >
                                        <stop offset="0" stopColor="#00b7f1"></stop>
                                        <stop offset="1" stopColor="#ed156e"></stop>
                                      </linearGradient>
                                    </defs>
                                    <circle
                                      cx="15"
                                      cy="15"
                                      r="15"
                                      fill="url(#linear-gradientnotes)"
                                      data-name="Ellipse 202"
                                    ></circle>
                                    <circle
                                      cx="13"
                                      cy="13"
                                      r="13"
                                      fill="#fff"
                                      data-name="Ellipse 203"
                                      transform="translate(2 2)"
                                    ></circle>
                                    <path
                                      fill="#7a549e"
                                      d="M14.41 2.146L12.67.407a1.39 1.39 0 00-1.965 0L9.068 2.043l3.7 3.7 1.642-1.631a1.39 1.39 0 000-1.965zm-7.143-.594a1.157 1.157 0 00-1.637 0L2.193 4.99a.463.463 0 000 .655l.655.655a.463.463 0 00.655 0L6.45 3.353l.655.655-4.387 4.385A9.464 9.464 0 000 14.039v.007a.7.7 0 00.767.767A9.464 9.464 0 006.423 12.1l5.695-5.7-2.4-2.4-2.451-2.448z"
                                      data-name="Icon awesome-pen-alt"
                                      transform="translate(7.592 7.592)"
                                    ></path>
                                  </svg>
                                </button>
                              </a>
                            </>
                            : ""}
                        </div>

                      </div>
                      <div className="col-md-10 mt-1 mb-3 notes-box">
                        {n.isEditable ? (
                          <>
                            <div className="text-area">
                              <textarea
                                ref={textAreaRef}
                                className={`form-control ${hasError ? "border-danger" : ""
                                  }`}
                                name="nts"
                                rows="6"
                                required
                                onChange={(e) => {
                                  handleNotesInput(e, n);
                                }}
                                value={n.nts}
                              ></textarea>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                              <span className="cancel-icon cursor-pointer"
                                onClick={() => addNotes(n)}
                              >
                                <a className="bg">
                                  {/* <img className="notesModule_icon" src="/images/save.png" alt="add" /> */}
                                  <button className="button-reset">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                    >
                                      <defs>
                                        <linearGradient
                                          id="linear-gradientnotes"
                                          x2="1"
                                          y1="0.5"
                                          y2="0.5"
                                          gradientUnits="objectBoundingBox"
                                        >
                                          <stop offset="0" stopColor="#00b7f1"></stop>
                                          <stop offset="1" stopColor="#ed156e"></stop>
                                        </linearGradient>
                                      </defs>
                                      <circle
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradientnotes)"
                                        data-name="Ellipse 204"
                                      ></circle>
                                      <circle
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        fill="#fff"
                                        data-name="Ellipse 205"
                                        transform="translate(2 2)"
                                      ></circle>
                                      <g
                                        fill="#7a549e"
                                        data-name="Icon ionic-ios-save"
                                        transform="translate(3.972 3.973)"
                                      >
                                        <path
                                          d="M18.253 4.5h-.544a.273.273 0 00-.272.272V7.22c0 .15.258.272.408.272h.408a.273.273 0 00.272-.272V4.772a.273.273 0 00-.272-.272z"
                                          data-name="Path 1246"
                                          transform="translate(-6.682)"
                                        ></path>
                                        <path
                                          d="M17.076 6.747l-1.764-1.764a1.645 1.645 0 00-1.16-.483H13.2a.549.549 0 00-.544.551v2.72a.546.546 0 01-.544.544H7.764a.546.546 0 01-.544-.544v-2.72a.551.551 0 00-.544-.551H5.588A1.091 1.091 0 004.5 5.588v10.88a1.091 1.091 0 001.088 1.088h10.88a1.091 1.091 0 001.088-1.088V7.9a1.632 1.632 0 00-.48-1.153zm-3.8 9.18a.579.579 0 01-.605.544H7.213a.579.579 0 01-.605-.544v-3.264a.579.579 0 01.605-.544h5.453a.579.579 0 01.605.544z"
                                          data-name="Path 1247"
                                        ></path>
                                      </g>
                                    </svg>
                                  </button>
                                </a>
                              </span>
                              <span className="cancel-icon cursor-pointer"
                                onClick={() => handleCancel(n.sid)}
                              >
                                <a className="bg">
                                  <button className="button-reset">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                    >
                                      <defs>
                                        <linearGradient
                                          id="linear-gradientnotes"
                                          x2="1"
                                          y1="0.5"
                                          y2="0.5"
                                          gradientUnits="objectBoundingBox"
                                        >
                                          <stop offset="0" stopColor="#00b7f1"></stop>
                                          <stop offset="1" stopColor="#ed156e"></stop>
                                        </linearGradient>
                                      </defs>
                                      <circle
                                        cx="15"
                                        cy="15"
                                        r="15"
                                        fill="url(#linear-gradientnotes)"
                                        data-name="Ellipse 57"
                                      ></circle>
                                      <circle
                                        cx="13"
                                        cy="13"
                                        r="13"
                                        fill="#fff"
                                        data-name="Ellipse 179"
                                        transform="translate(2 2)"
                                      ></circle>
                                      <g
                                        fill="none"
                                        stroke="var(--color-element-1)"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                        data-name="Group 1344"
                                        transform="rotate(45 -7.668 18.304)"
                                      >
                                        <path d="M12.172 0L0 0" data-name="Line 51"></path>
                                        <path d="M12.172 0L0 0" data-name="Line 275"></path>
                                      </g>
                                      <g
                                        fill="none"
                                        stroke="var(--color-element-1)"
                                        strokeLinecap="round"
                                        strokeWidth="4"
                                        data-name="Group 2376"
                                        transform="rotate(-45 28.107 -3.071) translate(0 .414)"
                                      >
                                        <path d="M12 0L0 0" data-name="Line 51"></path>
                                        <path d="M12 0L0 0" data-name="Line 275"></path>
                                      </g>
                                    </svg>
                                  </button>
                                  {/* <img className="notesModule_icon" src="/images/cancel.png" alt="cancel" /> */}
                                </a>
                              </span>
                            </div>
                          </>
                        ) : (
                          <p className="notes-desc">
                            {n.nts}
                          </p>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <p>No notes have been added for this module yet.</p>
          )}
        </div>
      </div>

      <AlertDeleteNotesModal
        deleteNotes={deleteNote}
        handleClose={handleClose}
        show={show}
      />
      {loader && (
        <div className="common-overlay" >
          <div className="spinner-text">
            <Spinner animation="border" size="lg" />
          </div>
        </div>
      )}
    </div>
  );
};
