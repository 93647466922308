import types from './types'

const initialState = {
    myCart: {},
    myCartDelete: {},
    myCartSaveLater: {},
    myCartSaveLaterView: {},
    delSaveLaterView: {},
    learningPoints: {},
    creditPoints: {},
    dynamicBanner: {},
    dynamicBannerBroker:{},
    couponCodeApplyRes:{}
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    // console.log("action " + action + " triggered")
    switch (action.type) {
        case types.MY_CART_RESPONSE:
            // console.log("action pay load", action.payload)
            return {
                ...state,
                myCart: action.payload.payload
            }
        case types.MY_CART_CLEAR:
            return {
                ...state,
                myCart: {}
            }
        case types.MY_CART_DELETE_RESPONSE:
            // console.log('data record', action.payload)
            return {
                ...state,
                myCartDelete: action.payload
            }
        case types.MY_CART_DELETE_CLEAR:
            return {
                ...state,
                myCartDelete: {}
            }
        case types.SAVE_LATER_RESPONSE:
            return {
                ...state,
                myCartSaveLater: action.payload
            }
        case types.SAVE_LATER_CLEAR:
            return {
                ...state,
                myCartSaveLater: {}
            }
        case types.SAVE_LATER_VIEW_RESPONSE:
            return {
                ...state,
                myCartSaveLaterView: action.payload
            }
        case types.SAVE_LATER_VIEW_CLEAR:
            return {
                ...state,
                myCartSaveLaterView: {}
            }
        case types.DEL_SAVE_LATER_RESPONSE:
            return {
                ...state,
                delSaveLaterView: action.payload
            }
        case types.DEL_SAVE_LATER_CLEAR:
            return {
                ...state,
                delSaveLaterView: {}
            }
        case types.LEARNING_POINTS_RESPONSE:
            //logic
            return {
                ...state,
                learningPoints: action.payload
            }
        case types.LEARNING_POINTS_CLEAR:
            return {
                ...state,
                learningPoints: {}
            }

        case types.CREDIT_POINTS_RESPONSE:
            //logic
            return {
                ...state,
                creditPoints: action.payload
            }
        case types.CREDIT_POINTS_CLEAR:
            return {
                ...state,
                creditPoints: {}
            }
        case types.DYNAMIC_BANNER_RESPONSE:
            //logic
            return {
                ...state,
                dynamicBanner: action.payload
            }
        case types.DYNAMIC_BANNER_CLEAR:
            return {
                ...state,
                dynamicBanner: {}
            }
            case types.DYNAMIC_BANNER_BROKER_RESPONSE:
                //logic
                return {
                    ...state,
                    dynamicBannerBroker: action.payload
                }
            case types.DYNAMIC_BANNER_BROKER_CLEAR:
                return {
                    ...state,
                    dynamicBannerBroker: {}
                }
        case types.COUPON_CODE_APPLY_RES:
            //logic
            return {
                ...state,
                couponCodeApplyRes: action.payload
            }
        case types.COUPON_CODE_APPLY_CLEAR:
            return {
                ...state,
                couponCodeApplyRes: {}
            }

        default:
            return state
    }
}



