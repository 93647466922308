import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChangePasswordSection from "../components/profile/ChangePasswordSection";
import { Button, Spinner } from "react-bootstrap";
import { cnfPwdValidation, passCharValidation, passLimitValidation } from "../utils/AppUtils";
// import Loader from "../Loader/Loader";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordReq, changePasswordResClear } from "../redux/user/actions";
import CONFIG from "../utils/config";
import $ from "jquery";
import { getAllCertifiedNIIT } from "../redux/certified/actions";
import { useHistory } from "react-router-dom";
import {
  clickStreamUser,
  SessionKeys,
  logoutClearSession,
  applyTheme,
  IsLogin,
} from "../utils/AppUtils";
import {
  loginClear,
  logout,
  logoutBroker,
  loginBrokerClear,
  logoutBrokerClear,
  logoutClear,
  loginHostInstClear,
} from "../redux/auth/actions";

const ChangePassowrdPopup = () => {
  const [show, setShow] = useState(false);
  const [showSuccesfullPopup, setShowSuccesfullPopup] = useState(false);
  const [defPass, setdefPass] = useState(false);

  const handleClose = () => {
    console.log("closepopup");
  }

  const handleClosePopup = () => {
    setShow(false);
    setShowSuccesfullPopup(true)
    console.log("hiiii team");
  }
  // const handleShow = () => setShow(true);

  useEffect(() => {
    const defPass = localStorage.getItem("badge");
    console.log(defPass, "badgeAllote");
    if (defPass != null || defPass != undefined) {
      setdefPass(true);
    }

  });

  useEffect(() => {
    if (!defPass) {
      setShow(true);
    } else {
      setShow(false);
    }

  })

  const signOut = () => {
    setIsLoading(true)
    dispatch(
      logoutBroker({
        module: "userlogout",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        },
      })
    );
  };



  const dispatch = useDispatch();

  const changePassRes = useSelector((state) => state.user.changePassRes);

  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('')
  const [cnfPassword, setCnfPassword] = useState('')
  const [htmlFormError, setFormError] = useState('')
  const [htmlFormSuccess, setFormSuccess] = useState('')
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMsg, setErrorModalMsg] = useState("");
  const history = useHistory();


  const auth = useSelector((state) => state.auth);
  const isLogout = useSelector((state) => state.auth.isLogout);
  const authBroker = useSelector(state => state.auth.authBroker)
  const authLogoutBroker = useSelector((state) => state.auth.authLogoutBroker);

  const resetPassword = () => {
    setFormError("");
    // setShow(false);
    // handleClose();
    if (_.isEmpty(oldPassword)) {
      setFormError("Please enter your current password.")
    } else if (!passLimitValidation(password)) {
      setFormError("Please enter at least 6 characters.")
    } else if (!passLimitValidation(cnfPassword)) {
      setFormError("Please enter at least 6 characters.")
    } else if (!passCharValidation(password)) {
      setFormError(<>
        <ul className="setup-password-error">
          <li>Password must contain at least one uppercase letter</li>
          <li>Password must contain at least one lowercase letter</li>
          <li>Password must contain at least one number</li>
          <li>Password must contain at least one special character like !@#$%^&*</li>
        </ul>
      </>)
    } else if (!passCharValidation(cnfPassword)) {
      setFormError(<>
        <ul className="setup-password-error">
          <li>Password must contain at least one uppercase letter</li>
          <li>Password must contain at least one lowercase letter</li>
          <li>Password must contain at least one number</li>
          <li>Password must contain at least one special character like !@#$%^&*</li>
        </ul>
      </>)
    } else if (cnfPwdValidation(password, cnfPassword)) {
      setIsLoading(true);
      setConfirmModal(false);
      setErrorModal(false);
      dispatch(changePasswordReq({
        module: 'changePassword',
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          current_password: oldPassword,
          new_password: cnfPassword
        }
      }))

      //  handleClosePopup();

    } else {
      // alert("Passwords do not match.")
      setFormError("Passwords do not match. Please enter the same password in both fields.");
    }

  }
  useEffect(() => {
    //console.log("LOGOUT IS CALLED", authLogoutBroker);
    if (!_.isEmpty(authLogoutBroker)) {
      window.captureMyEvents("logout", {
        userId: auth?.authBroker?.payload.user.user_id,
        message: "User logged out",
      });
      let email = null,
        pass = null;
      if (localStorage.getItem(SessionKeys.IS_REMEMBERED)) {
        email = localStorage.getItem(SessionKeys.EMAIL);
        pass = localStorage.getItem(SessionKeys.PASS);
      }
      // alert('Logout button clicked',authLogoutBroker)
      localStorage.clear();

      if (email !== null) {
        localStorage.setItem(SessionKeys.IS_REMEMBERED, true);
        localStorage.setItem(SessionKeys.EMAIL, email);
        localStorage.setItem(SessionKeys.PASS, pass);
      }

      $("html").css("--color-element-1", CONFIG.COLOR_ELEMENT_1);
      $("html").css("--color-element-2", CONFIG.COLOR_ELEMENT_2);
      if (authBroker.payload.user.apply_theme) {
        if ($('body').hasClass('cus-bro-theme')) {
          $('body').removeClass('cus-bro-theme')
        }
      }
      dispatch(logoutClear());
      dispatch(loginClear());
      // dispatch(setUserPremium(false))
      logoutClearSession();

      dispatch(loginBrokerClear());
      dispatch(logoutBrokerClear());
      setIsLoading(false)
      history.push("/", { logout: true });
    }
  }, [authLogoutBroker]);
  useEffect(() => {
    // console.log("changePassRes: ", changePassRes);
    if (!_.isEmpty(changePassRes)) {
      setIsLoading(false);
      if (changePassRes?.status === "success") {
        setOldPassword("");
        setPassword("");
        setCnfPassword("");
        // console.log(changePassRes?.status);
        // if (changePassRes?.status) {
        //   localStorage.setItem("defPass", changePassRes?.status);
        //   setdefPass(true)
        // }
        // handleClose();
        setShowModalMsg(changePassRes?.payload?.message);
        setConfirmModal(true);
        // signOut();
        // handleClosePopup(); 


      } else {
        setErrorModalMsg(changePassRes?.error?.message);
        setErrorModal(true);

      }

      dispatch(changePasswordResClear())
    } else {
      setShow(true)
    }
  }, [changePassRes])

  const resetPasswordPopup = () => {
    if (changePassRes?.payload?.message === "Password changed successfully!") {

    } else {
      resetPassword();
    }


  }

  const renderAlertCard = () => {
    return (
      <div
        style={{ 'top': '-85px' }}
        className={`alert-card d-flex justify-content-center ${confirmModal ? "" : "d-none"
          }`}
      >
        <div className="shop-cart show_password_suceess">
          <div className="shop-info justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </svg>
            <div className="main-popup-password">
              <p className="m-0">{showModalMsg}</p>
              <p className="m-0" >Please {" "}
                {
                  <button className="default_pass_login_btn"
                    onClick={() => {
                      signOut();
                    }}>
                      
                    Login
                  </button>
                }
                {" "}
                again to access the Platform</p>
           
            </div>

          </div>
          {/* <div className="view-cart-icon">

            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setConfirmModal(false);
                setShowModalMsg("");
              }}
            ></i>
          </div> */}
        </div>
      </div>
    );
  };

  const renderErrorAlertCard = () => {
    return (
      <div
      style={{ 'top': '-50px' }}
        className={`alert-card d-flex justify-content-center ${errorModal ? "" : "d-none"
          }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                                    <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                                    <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
                                </g>
                            </svg> */}
            <p className="m-0">{errorModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setErrorModal(false);
                setErrorModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };
  const myFunction = () => {
    var x = document.getElementById("passInput");
    var y = document.getElementById("eyeImg");
    if (x.type === "password") {
      x.type = "text";
      y.src = "/images/view.png";
    } else {
      x.type = "password";
      y.src = "/images/private.png";
    }
  };


const  myFunctionNewPasword = () => {
// console.log("hiiiiii")
var x = document.getElementById("passInput2");
var y = document.getElementById("eyeImg2");
if (x.type === "password") {
  x.type = "text";
  y.src = "/images/view.png";
} else {
    console.log("hiiiiii12345")
  x.type = "password";
  y.src = "/images/private.png";
}
}

const myFunctionCnfPassword = () => {
// console.log("hiiiiii12344")
var x = document.getElementById("passInput3");
var y = document.getElementById("eyeImg3");
if (x.type === "password") {
  x.type = "text";
  y.src = "/images/view.png";
} else {
  x.type = "password";
  y.src = "/images/private.png";
}
}
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <div className="change_passowrd_block">
        <Modal show={show} className="password_modal">
          <Modal.Header>
            <Modal.Title className="password_title"> Change your Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="setup-password">
              <div className="container position-relative">
                {renderAlertCard()}
                {renderErrorAlertCard()}
                <div className="row">

                  <div className={`right-space password_change`}>
                    <div className="heading ">

                      <form onSubmit={(event) => {
                        event.preventDefault();
                        resetPassword();
                      }}>
                        <div className="input-text position-relative">
                          <label htmlFor="password" className="input__label">Enter Current Password</label>
                          <input type="password" className="input__field"
                           id="passInput"
                            placeholder="Enter Password"
                            aria-label="password" aria-describedby="basic-addon1"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)} />
                              <div
                                className="show-pass"
                                onClick={() => {
                                  myFunction();
                                }}
                              >
                                <img className="eyeImage"
                                  id="eyeImg"
                                  src="/images/private.png"
                                  alt="view"
                                />
                              </div>
                        </div>
                        <div className="input-text position-relative">
                          <label htmlFor="password" className="input__label">Enter New Password</label>
                          <input type="password" className="input__field"
                           id="passInput2"
                            placeholder="Enter Password"
                            aria-label="password" aria-describedby="basic-addon1"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                             />
                               <div
                                className="show-pass"
                                onClick={() => {
                                  myFunctionNewPasword();
                                }}
                              >
                                <img className="eyeImage2"
                                  id="eyeImg"
                                  src="/images/private.png"
                                  alt="view"
                                />
                              </div>
                        </div>
                        <div className="input-text  position-relative">
                          <label htmlFor="confirm_password" className="input__label">Confirm New Password</label>
                          <input type="password" className="input__field" placeholder="Confirm Password"
                            aria-label="password" aria-describedby="basic-addon1"
                            id="passInput3"
                            value={cnfPassword}
                            onChange={(e) => setCnfPassword(e.target.value)} />
                              <div
                                className="show-pass"
                                onClick={() => {
                                  myFunctionCnfPassword();
                                }}
                              >
                                <img className="eyeImage3"
                                  id="eyeImg"
                                  src="/images/private.png"
                                  alt="view"
                                />
                              </div>
                        </div>
                        <div className="save_button">
                          {
                            isLoading ?
                              <Button
                                className="btn btn-primary setup-button" >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                              :
                              <Button
                                type="submit"
                                className="btn btn-primary setup-button password_change_btn">
                                Submit</Button>


                          }
                          {htmlFormError && (<div className="pasword_field_error">{htmlFormError}</div>)}
                          {htmlFormSuccess && (<div>{htmlFormSuccess}</div>)}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      

      </div>
    </>
  );
}

export default ChangePassowrdPopup;

