import { Button, Modal } from "react-bootstrap";

const FeedbackSucessPopup = (props) => {
  const { submitFeedbackSuccess, setSubmitFeedbackSuccess, message, title } =
    props;
  return (
    <Modal
      show={submitFeedbackSuccess}
      onHide={setSubmitFeedbackSuccess}
      size="md"
      dialogClassName="modalassign"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="submitheader">
        <Modal.Title id="example-custom-modal-styling-title">
          {title || "Submission Successful"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-body">
        <p className="mb-0">
          {message || "Your feedback has been submitted successfully"}
        </p>
      </Modal.Body>
    </Modal>
  );
};
export default FeedbackSucessPopup;
