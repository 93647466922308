import { fork, take, call, put } from 'redux-saga/effects'

import {
    getAllCertifiedNIITResponse,
    getCertifiedDetailsNIITResponse,
    getSessionNIITListResponse,
    getAllCertifiedBrokerResponse,
    getCertifiedDetailsBrokerResponse,
    getSessionBrokerListResponse,
    getEnrollBrokerSessionResponse,
    getCertificateBrokerResponse,
    getBadgesBrokerResponse,
} from './actions'
import types from './types'
import {  postAPIWithoutModule,postAPI } from '../../api/generic'

export function* doGetAllCertifiedNIIT() {
    while (true) {
        const { payload } = yield take(types.GET_ALL_CERTIFIED_NIIT)
        const response = yield call(postAPIWithoutModule, payload, true)
        // console.log("response", response)
        yield put(getAllCertifiedNIITResponse(response))
    }
}
export function* viewsSessionListNIIT() {
    while (true) {
        const { payload } = yield take(types.CERTIFIED_SESSION_LIST_NIIT)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getSessionNIITListResponse(response))
    }
}

export function* doGetCertifiedDetailNIIT() {
    while (true) {
        const { payload } = yield take(types.GET_CERTIFIED_DETAILS_NIIT)
        const response = yield call(postAPIWithoutModule, payload, true)
        yield put(getCertifiedDetailsNIITResponse(response))
    }
}

export function* doGetAllCertifiedBroker() {
    while (true) {
        const { payload } = yield take(types.GET_ALL_CERTIFIED_BROKER)
        const response = yield call(postAPIWithoutModule, payload, true)
        // console.log("response", response)
        yield put(getAllCertifiedBrokerResponse(response))
    }
}
export function* viewsSessionListBroker() {
    while (true) {
        const { payload } = yield take(types.CERTIFIED_SESSION_LIST_BROKER)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getSessionBrokerListResponse(response))
    }
}

export function* doGetCertifiedDetailBroker() {
    while (true) {
        const { payload } = yield take(types.GET_CERTIFIED_DETAILS_BROKER)
        const response = yield call(postAPIWithoutModule, payload, true)
        yield put(getCertifiedDetailsBrokerResponse(response))
    }
}

export function* enrollBrokerSessionSaga() {
    while (true) {
        const { payload } = yield take(types.CERTIFIED_ENROLL_BROKER_SESSION)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getEnrollBrokerSessionResponse(response))
    }
}

export function* doGetCertificatesBroker() {
    while (true) {
        const { payload } = yield take(types.CERTICICATE_GET_BROKER)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getCertificateBrokerResponse(response))
    }
}

export function* doGetBadgesBroker() {
    while (true) {
        const { payload } = yield take(types.BADGES_GET_BROKER)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getBadgesBrokerResponse(response))
    }
}

function* allCertified() {
    yield fork(doGetAllCertifiedNIIT)
    yield fork(viewsSessionListNIIT)
    yield fork(doGetCertifiedDetailNIIT)
    yield fork(doGetAllCertifiedBroker)
    yield fork(viewsSessionListBroker)
    yield fork(doGetCertifiedDetailBroker)
    yield fork(enrollBrokerSessionSaga)
    yield fork(doGetCertificatesBroker)
    yield fork(doGetBadgesBroker)
}

export default allCertified