import Page from "../../components/Page";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
import PlanUpgradeReportHomeSection from "../../components/Reports/planUpgradeReport/PlanUpgradeReportHomeSection";

export const PlanUpgradeReport = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
        <Loader start={isLoading} />
        <Page>
            <PlanUpgradeReportHomeSection 
                setIsLoading={setIsLoading}
                isLoading={isLoading}
            />
        </Page>
        </>
    )
}

export default PlanUpgradeReport;