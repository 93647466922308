import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import Page from "../../components/Page";
import MyRolePlayPageSection from "../../components/RolePlay/MyRolePlayPageSection";

const MyRolePlays = () => {

    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <MyRolePlayPageSection 
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    isMobile={false}
                />
            </Page>
        </>
    )
}

export default MyRolePlays