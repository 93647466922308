import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../Page";
import { learningPoints, learningPointsClear } from "../../redux/Cart/actions";
import { SessionKeys } from "../../utils/AppUtils";
import _ from "lodash";
import Loader from "../Loader/Loader";
import moment from "moment";

const LearningPoints = () => {
  const dispatch = useDispatch();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const myLearningPointsRes = useSelector(
    (state) => state.carts?.learningPoints
  );
  const [learnPoints, setLearnPoints] = useState({});
  const [totalPoint, setTotalPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const toInt = (value) => {
    if (value === null) {
      return 0;
    } else return parseInt(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log('learning', myLearningPointsRes)
    if (myLearningPointsRes?.status === "success") {
      var lpArray = myLearningPointsRes?.payload;
      let userLPArray = []
      let totalPoint = 0;
      if (!_.isEmpty(lpArray)) {
        if (_.isArray(lpArray) && lpArray.length > 0) {
          totalPoint = 0;
          for (let learningPoint of lpArray) {
            if (learningPoint.point > 0) {
              userLPArray.push(learningPoint)
            }
            // learningPoint.acc_points = totalPoint + _.toNumber(learningPoint.point);
            totalPoint = totalPoint + _.toNumber(learningPoint.point);
          }
        }
        setTotalPoints(totalPoint);

        setLearnPoints(userLPArray);
      }
    }
  }, [myLearningPointsRes]);

  useEffect(() => {
    if (!_.isEmpty(myLearningPointsRes)) {
      setIsLoading(false);
      dispatch(learningPointsClear());
    }
  }, [myLearningPointsRes]);

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        learningPoints({
          module: "learning-points/points",
          email: authBroker?.payload?.user?.email,
          // userId: localStorage.getItem(SessionKeys.EMAIL_SESSION)
        })
      );
    }
  }, [dispatch, authBroker]);

  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <div className="my-credit-points">
          <section className="pointer-history-events-table pt-0">
            <div className="container">
              <div className="row">
                <div className="col-md-12 points-head-history">
                  <h1>
                    <strong>{!isLoading && totalPoint}</strong> Learning Points
                  </h1>
                </div>
                <div className="col-md-12">
                  <div className="responsive-carousel-points-table">
                    <div className="pointsbox-table">
                      <table className="table table-striped position-relative">
                        <thead className="position-relative">
                          <tr>
                            <th scope="col">Description</th>
                            <th className="text-center" scope="col">
                              Type
                            </th>
                            <th className="text-center" scope="col">
                              Date
                            </th>
                            <th className="text-center" scope="col">
                              Points Earned
                            </th>
                            <th className="text-center" scope="col">
                              Cumulative Earned
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {learnPoints.length > 0
                            ? learnPoints.map((m, index) => {
                                return (
                                  <tr key={`lp-${index}`}>
                                    <td>{m.course_name}</td>
                                    <td className="text-center">
                                      {m.type.charAt(0).toUpperCase() +
                                        m.type.substring(1)}
                                    </td>
                                    {/* <td class="text-center">{m.date}</td> */}
                                    <td className="text-center">
                                      {moment(m.date, "DD/MM/YYYY").format(
                                        "DD MMM, YYYY"
                                      )}
                                    </td>
                                    {m.point == 0 ||
                                    m.point == null ||
                                    m.point == "0" ||
                                    m.point == undefined ? (
                                      <td className="text-center">0</td>
                                    ) : (
                                      <td className="text-center">{m.point}</td>
                                    )}
                                    <td className="text-center">{m.acc_points}</td>
                                  </tr>
                                );
                              })
                            : !isLoading && (
                                <tr className="not-found">
                                  <td colSpan={5}>
                                    <img
                                      src="/images/telescope.svg"
                                      alt="Not Found"
                                    />
                                    <p>
                                      No learning points are earned to you yet.
                                    </p>
                                  </td>
                                </tr>
                              )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Page>
    </>
  );
};
export default LearningPoints;
