import _ from "lodash"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import MyAreaSection from "../../components/Home/MyAreaSection"
import Loader from "../../components/Loader/Loader"
import Page from "../../components/Page"

const MyLearningCenter = () => {

    const myCoursesData = useSelector(state => state.courses.myCourses)
    const myLearningPathsData = useSelector(state => state.courses.myLearningPaths)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!_.isEmpty(myCoursesData)) {
            setIsLoading(false)
        }
        if (!_.isEmpty(myLearningPathsData)) {
            setIsLoading(false)
        }
    }, [myCoursesData, myLearningPathsData])


    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <MyAreaSection />
            </Page>
        </>
    )
}

export default MyLearningCenter;