import moment from "moment";
import { useEffect, useState } from "react";
import SingleCompletedPopup from "../../components/FeedbackPopups/singleCompletedPopup";
import FeedbackSucessPopup from "../../components/FeedbackPopups/FeedbackSuccessPopup";
import { useDispatch, useSelector } from "react-redux";
import { getAPI } from "../../api/generic";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
const FeedbackCalendarCard = ({ eventData }) => {
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(0);
  const [showReminderPopupStep2, setShowReminderPopupStep2] = useState(false);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [segmentId, setSegmentId] = useState("");
  const authBroker = useSelector((state) => state.auth.authBroker);
  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState();

  function handleSubmitReminderFirstPopup() {
    setShowReminderPopupStep2(true);
    setTimeout(() => {
      setShowReminderPopupStep2(false);
    }, 5500);
  }
  useEffect(() => {
    fetchfeedbackQuestions();
  }, []);

  const fetchfeedbackQuestions = () => {
    (async () => {
      const res = await getAPI({
        module: "getFeedbackQuestions",
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        type: "Segment",
      });
      if (res.status === "success" && res?.payload) {
        setFeedbackQuestions(res?.payload);
      } else {
      }
    })();
  };
  const handleFeedback = (segment) => {
    setCategory_id(segment?.eventId);
    setName(segment?.title);
    setSegmentId(segment?.segmentId);
    setShowFeedbackPopup(true);
  };

  return (
    <>
    <div className="d-flex justify-content-end">
      <div
        className="btn btn-primary calenfeedback feedbackbtn eureka-cursor-allowed eureka-session-feedback mt-3"
        onClick={() => handleFeedback(eventData)}
      >
        <img src="/images/feedback.png" className="img-fluid me-2" />
        Feedback
      </div>
      </div>
      <SingleCompletedPopup
        name={name}
        setShow={setShowFeedbackPopup}
        show={showFeedbackPopup}
        userName={authBroker?.payload?.user?.firstname || ""}
        showReminderPopupStep2={showReminderPopupStep2}
        setShowReminderPopupStep={() => handleSubmitReminderFirstPopup()}
        questions={feedbackQuestions}
        courseId={category_id}
        type={"Segment"}
        sub_category_id={segmentId}
        lastModule={[]}
      />
      <FeedbackSucessPopup
        submitFeedbackSuccess={showReminderPopupStep2}
        setSubmitFeedbackSuccess={() => setShowReminderPopupStep2(false)}
      />
    </>
  );
};

export default FeedbackCalendarCard;
