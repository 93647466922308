import ShimmerScreen from "./ShimmerScreen";

const ShimmerCardBody = () => {

    return (
        <>
            <div
              className="col-md-6 col-lg-4 col-xl-3 col-12 mb-4"
            >
              <div className="img-box_nw">
                <div
                  className="img-videosec learn_image-card cursor-pointer"
                >
                  <div className="p-2 galleryImg_lern no-boxshadow">
                    <ShimmerScreen 
                      width="100%"
                      height="100%"
                      case="rect"
                    />
                  </div>
                </div>
    
                <div className="details-card_nw">
                  <h5
                    className="cursor-pointer"
                  >
                    <ShimmerScreen 
                      case='rect'
                      width='20rem'
                      height="1.2rem"
                    />
                    <div className="mt-1"></div>
                    <ShimmerScreen 
                      case='rect'
                      width='10rem'
                      height="1.2rem"
                    />
                  </h5>
    
                  <div className="row info-bottm-card_nw">
                    <div className="col-md-12 cou_cardIcon">
                      <div className="fgf">
                        <ShimmerScreen 
                          case="rect"
                          width="4rem"
                          height="1.1rem"
                        />
                      </div>
                      <div className="carouseL_Iconno2 d-flex flex-column">
                        <div className="ico__imG01 d-flex gap-2">
                          <ShimmerScreen 
                            case='circle'
                            width="0.8rem"
                            height="0.8rem"
                          />
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              id="clock-time-four"
                              d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                              transform="translate(-2 -2)"
                              fill="var(--color-element-1)"
                            />
                          </svg> */}
                          <ShimmerScreen 
                          case="rect"
                          width="4rem"
                          height="1.1rem"
                        />
                        </div>
                        <div className="ico_texxt02 d-flex pt-2 gap-2">
                          <ShimmerScreen 
                            case='circle'
                            width="0.8rem"
                            height="0.8rem"
                          />
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              id="eye-circle"
                              d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                              transform="translate(-2 -2)"
                              fill="var(--color-element-1)"
                            />
                          </svg> */}
                          <ShimmerScreen 
                            case="rect"
                            width="4rem"
                            height="1.1rem"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div className="row infoIcon_nw">
                    <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                      <ShimmerScreen 
                            case="rect"
                            width="4rem"
                            height="1.1rem"
                        />
                        <ShimmerScreen 
                        case="rect"
                        width="4rem"
                        height="1.1rem"
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>
    )
}

export default ShimmerCardBody;