import { useEffect, useState } from "react";
import _ from "lodash";
import { clickStreamUser, convertTimeFormat, SessionKeys } from "../../utils/AppUtils";
import CourseCardListBody from "./CourseCardListBody";
import SliderCourseListing from "../LearningPaths/SliderCourseListing";
import ProviderComponent from "../Layouts/ProviderComponent";
import { getAssignBrokerCourse, getAssignCourse, getSelectedCatId } from "../../redux/courses/actions";
import { courseDetailResponse } from "../../redux/product/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ImageComponent from "../Layouts/ImageComponent";
import { ProgressBar } from "react-bootstrap";

const LPMoreLearn = (props) => {

    const {
        lpData,
        currentCRSCD,
        setIsLastRecord
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const [lpInfo, setLPInfo] = useState({});
    const [courseData, setCourseData] = useState({});
    const auth = useSelector((state) => state.auth);
    const authBroker = useSelector((state) => state.auth.authBroker);

    useEffect(() =>  {
        if (!_.isEmpty(lpData)) {
            setLPInfo(lpData)
        }
    }, [lpData])

    const goTo = (crscd, source, cid, lpId) => {
        // console.log('GOTO', path, id)
        let url = `/moduleDetail/${crscd}/?source=${source}&cid=${cid}&via_lp=true&lp_id=${lpId}`;
        // return;
        history.push(url);
    }

    const setCourseDetailData = (course) => {
        let tempObj = {
          payload: {
            course_code: course.crscd,
            course_discount: course.discount,
            course_duration: course.duration,
            course_lessons: course.lessons,
            course_period: course.durationUnit,
            course_views: course.views,
            product_description: course.long_description,
            product_id: course.id,
            product_name: course.name,
            product_price: course.market_price,
            product_regular_price: course.market_price,
            product_sale_price: course.selling_price,
            product_short_description: course.short_description,
            product_slug: course.name,
            product_thumbnail: course.imageURL,
            product_total_sales: course.market_price,
            sfdc_product_type: course.sfdc_product_type,
            course_list: _.isArray(course.course_list) ? course.course_list : [],
          },
          status: "success",
        };
        dispatch(courseDetailResponse(tempObj));
    };

    const renderPremiumTag = (course) => {
        return course.is_paid !== undefined && !course.is_paid ? (
    
          <div className={course?.is_addtocart ? "d-flex justify-content-between revisit_icon buTN_buy added-in-cart" : "d-flex justify-content-between revisit_icon buTN_buy"}>
            <div className="calendar_event">
              <button
                type="button"
                className="btn btn-primary-LR  setup-button"
                onClick={() => {
                  // console.log("COURSEDATA", course);
                  setCourseDetailData(course);
                  history.push(
                    `/shopping/${course.id}?addToCart=${course?.is_addtocart}`
                  );
                }}
              >
                {course?.is_addtocart ? "Added in cart" : "Buy"}
              </button>
            </div>
          </div>
        ) : (
          ""
        );
    };

    const redirectToPage = (course, lp_id) => {
        // console.log("course data", course, course.source);
        // return;
    
        if (course?.is_level) {
          history.push('/levelDetail?program_id=' + course?.prgm_id + '&level_id=' + course?.id)
        } else if (course?.source === "ecom" && !course?.is_paid) {
          setCourseDetailData(course);
          history.push(`/shopping/${course.id}/?addToCart=${course?.is_addtocart}`);
        } else if (course?.is_paid === undefined || course?.is_paid) {
          clickStreamUser("course_card_click_goto_course", {
            courseId: course?.id,
            courseName: course?.name,
            userId: authBroker?.payload?.user.user_id,
            courseCode: course?.crscd,
          });
          setCourseData(course);
          if (course?.source === "broker" || course?.origin === 'broker') {
            // console.log("course redirect to module");
            dispatch(
              getAssignBrokerCourse({
                module: "assignedCourseBroker",
                postData: {
                  token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                  entity_type: "courses",
                  entity_id: course.id
                    ? _.toNumber(course?.id)
                    : _.toNumber(course?.wc_post_id),
                  origin: "broker",
                  number_of_days: "",
                  is_type: "free",
                },
              })
            );
            goTo(course?.crscd, course?.source, course.id ? course.id : course.wc_post_id, lp_id);
            // history.push(
            //   `/moduleDetail/${course?.crscd}/?source=${course?.source}&cid=${course.id ? course.id : course.wc_post_id}`
            // );
          } else {
            // console.log("course redirect to assign");
            dispatch(
              getAssignCourse({
                module: "assign-course",
                postData: {
                  user_id: authBroker?.payload?.user.user_id,
                  course_id: course?.crscd,
                },
              })
            );
            dispatch(
              getAssignBrokerCourse({
                module: "assignedCourseBroker",
                postData: {
                  token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                  entity_type: "courses",
                  entity_id: course?.crscd,
                  origin: "xsel",
                  number_of_days: course?.course_days,
                  is_type: course?.is_free ? "free" : "paid",
                  comment: course?.name,
                },
              })
            );
          }
        }
    };

    const renderCourseCard = (course, i, pageType='course', lp_id) => {
        return (
          <>
            <div className="broker-box">
              <div className="img-box_nw">
                <div
                  className={`img-videosec learn_image-card ${course.isLock
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                    }`}
                  onClick={() => {
                    if (!course.isLock) {
                      dispatch(getSelectedCatId(course.categoryId));
                      redirectToPage(course, lp_id);
                    }
                  }}
                  title={(course.is_manager_checklist && course.isLock) ? 'Notification sent to your manager, please discuss before moving forward.' : ""}
                >
                  {pageType === "course" ? (
                    <>
                      {/* <img
                        className={`img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""
                          }`}
                        src={course.imageURL}
                        onError={({currentTarget}) => {
                          currentTarget.onerror = true;
                          currentTarget.src = "/images/default-demo-img.jpg"
                      }}
                      /> */}
                      <ImageComponent
                        name={course.name}
                        thumbnail={course.imageURL ? course.imageURL : ""}
                        cssClass={`img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""}`}
                        errorClass={`d-flex justify-content-center align-items-center bk-grey img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""}`}
                      />
                      {course.crs_progress !== undefined &&
                        course.crs_progress != null &&
                        course.crs_progress > 0 ? (
                        <a href="#">
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#premium-contentpopu"
                          >
                            <span className="lear_icon">
                              <img
                                src={
                                  course.crs_progress >= 100
                                    ? "/images/check.png"
                                    : "/images/forward.png"
                                }
                              />
                            </span>
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                      {course.isLock ? (
                        <span className="lear_icon">
                          <img src="/images/Layer_1.png"
                            alt="lock"
                            title={course.is_manager_checklist ? 'Notification sent to your manager, please discuss before moving forward.' : ""} />
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <video width="100%" height="128" controls="">
                      <source src={course.mp4Link} type="video/mp4" />
                      <source src={course.oggLink} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <ProgressBar
                    className="custom_progress_barr custom_bartwoRealtor"
                    variant="success"
                    now={course.crs_progress ? course.crs_progress : 0}
                  />
                </div>
    
                <div className="details-card_nw">
                  <h5
                    className={`${course.isLock
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                    onClick={() => {
                      if (!course.isLock) {
                        dispatch(getSelectedCatId(course.categoryId));
                        redirectToPage(course);
                      }
                    }}
                  >
                    <a
                      className="course-head-al_search"
                      title={course.name}
                      data-toggle="tooltip"
                      data-html="true"
                      dangerouslySetInnerHTML={{
                        __html:
                          course.name.length < 33
                            ? course.name
                            : course.name.substring(0, 33) + "...",
                      }}
                    ></a>
                  </h5>
    
                  <div className="row info-bottm-card_nw">
                    <div className="col-md-12 cou_cardIcon">
                      {!course?.is_level ? <div className="fgf">
                        <p>
                          <span>{course.lessons} </span>
                          {course.lessons == 1 ? "Module" : "Modules"}
                        </p>
                      </div> : <div className="fgf">
                        <p>
                          <span>{course.course_count} </span>
                          {course.course_count == 1 ? "Course" : "Courses"}
                        </p>
                      </div>}
                      <div className="box-codd">
                        <div className="carouseL_Iconn">
                          <div className="ico__imG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                id="clock-time-four"
                                d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                transform="translate(-2 -2)"
                                fill="var(--color-element-1)"
                              />
                            </svg>
                          </div>
                          <div className="ico_texxt">
                            <p>
                              {" "}
                              <span>{course.duration}</span>{" "}
                              {course.durationUnit}
                            </p>
                          </div>
                        </div>
                        {!course?.is_level ? <div className="carouseL_Iconn">
                          <div className="ico__imG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                id="eye-circle"
                                d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                transform="translate(-2 -2)"
                                fill="var(--color-element-1)"
                              />
                            </svg>
                          </div>
                          <div className="ico_texxt">
                            <p>
                              <span>{course.views}</span> View
                              {course.views > 1 ? "s" : ""}
                            </p>
                          </div>
                        </div> : ''}
                      </div>
                    </div>
                  </div>
    
                  <div className="row infoIcon_nw">
                    <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                      <ProviderComponent providerData={course?.provider} />
                      {course.source !== "ecom" || course.is_paid ? (
                        <div
                          className={`d-flex justify-content-between revisitIconcon comp4530 ${course.isLock
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                            }`}
                          onClick={() => {
                            if (!course.isLock) {
                              dispatch(getSelectedCatId(course.categoryId));
                              redirectToPage(course);
                            }
                          }}
                          title={course.is_manager_checklist && course.isLock ? 'Notification sent to your manager, please discuss before moving forward.' : ""}
                        >
                          <img
                            src={
                              course.crs_progress === undefined ||
                                course.crs_progress == null ||
                                course.crs_progress === 0
                                ? "/images/Component 46-78.svg"
                                : course.crs_progress >= 100
                                  ? "/images/Component 4427.svg"
                                  : "/images/Component 4530.svg"
                            }
                            width="80"
                            height="17"
                            alt=""
                          />
                        </div>
                      ) : (
                        renderPremiumTag(course)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

    return (
        <>
            {
                _.isEmpty(lpInfo) ?
                "" :
                <div className="related-video-section">
                    <div className="relate-title-sec">
                            <h4 className="titlebox">
                                    There's more to Learn
                            </h4>
                            <div className="smalltext-videolist">
                                Continue with {lpInfo.prgm_nm}
                            </div>
                            <div className="row mt-4">
                                {
                                    lpInfo.coursesList.length > 0 ?
                                    lpInfo.coursesList.map((course, index) => {
                                        return (
                                            <div className="col-md-6 col-lg-4 col-xl-4 col-12 videobox search__input mb-4">
                                                {renderCourseCard(course, index, 'course', lpInfo.id)}
                                            </div>
                                        )
                                    })
                                    : ""
                                }
                            </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LPMoreLearn;