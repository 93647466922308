import React, { createRef, useEffect, useState, useMemo } from "react";
import Slider from "react-slick";
// import PrevArrow from "../../components/LearningPaths/PrevArrow";
// import NextArrow from "../../components/LearningPaths/NextArrow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import { SessionKeys } from "../../../utils/AppUtils";
import { useDispatch, useSelector } from "react-redux";
import ReportGraphSection from "../ReportGraphSection";
import { getAnyTotalReportClear, getProfileCompletionReport, getProfileCompletionReportClear } from "../../../redux/reports/actions";
import _ from "lodash";
import Chart from 'react-apexcharts';

const PieChartComponent = (props) => {
  const { type, statusFilterData, setCanShow, isView, legends, series, currentTableData, isCoeReport } = props;
  const dispatch = useDispatch();
  const [overSummaryCrsData, setOverSummaryCrsData] = useState([]);
  const overAllDataRes = useSelector((state) => state.report.overallData);
  const profileReportRes = useSelector(state => state.report.profileReport);
  const anyTotalReportRes = useSelector(state => state.report.anyTotalReport);
  const [profileReports, setProfileReports] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  // const [legends, setLegends] = useState([]);
  // const [series, setSeries] = useState([]);  
  const [searchInput, setSearchInput] = useState("");
  const [officeIds, setOfficeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isView, setIsView] = useState("pie");
  // const [type, setType] = useState("pie");   
  let PageSize = 20;
  const renderGraphReport = () => {
    return (
      <ReportGraphSection
        series={series}
        legends={legends}
        // isLoading={isLoading}
        type={type}
        isAmount={false}
        isCoeReport={isCoeReport}
      />
    )
  }
  return (
    <div>
      <span className="proglefttext">Progress</span><br/>
      {currentTableData && currentTableData?.length > 0 ? renderGraphReport() :(isCoeReport!=="coe")?       
      <div className="no-graph-data d-flex gap-2">
        <img src="./images/no-data-found.jpg"/>
          <div className="legends-data">
            <ul>
              <li><strong className="colord" style={{backgroundColor: "#1db128"}}></strong> Completed (0)</li>
              <li><strong className="colord" style={{backgroundColor: "#febe10"}}></strong> In Progress (0)</li>             
              <li><strong className="colord" style={{backgroundColor: "var(--color-element-1)"}}></strong> Overdue (0)</li>   
              <li><strong className="colord" style={{backgroundColor: "#ed156e"}}></strong> Yet to start (0)</li>          
            </ul>
            </div>
        </div>
        :
        <div className="no-graph-data d-flex gap-2">
        <img src="./images/no-data-found.jpg"/>
          <div className="legends-data">
            <ul>
              <li><strong className="colord" style={{backgroundColor: "#1db128"}}></strong> Completed (0)</li>
              <li><strong className="colord" style={{backgroundColor: "#febe10"}}></strong> In Progress (0)</li>
              {/* <li><strong className="colord" style={{backgroundColor: "var(--color-element-1)"}}></strong> Overdue (0)</li> */}
              <li><strong className="colord" style={{backgroundColor: "#ed156e"}}></strong> Yet to start (0)</li>
            </ul>
            </div>
        </div>  
      }     
    </div>
  )
}

export default PieChartComponent