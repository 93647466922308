import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { parseInt } from "lodash";
import {
  clickStreamUser,
  convertHoursToMins,
  convertSecondsToMins,
  convertTimeFormat,
  convertTimeInHourAndMin,
  fixedToDecimal,
  formatSecondsHand,
  SessionKeys,
} from "../../utils/AppUtils";
import { useHistory } from "react-router-dom";
import WishListCourseCard from "./WishListCourseCard";
import CartSummaryCard from "../Cart/CartSummaryCard";
import { myCart } from "../../redux/Cart/actions";
import { getCreditPoint } from "../../redux/creditPoints/actions";
import { cartPageDataResponse } from "../../redux/checkout/actions";
const WishlistSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const myCartRes = useSelector((state) => state.carts.myCart);
  const saveForLaterRes = useSelector((state) => state.carts.myCartSaveLater);
  const myCartSaveLaterViewRes = useSelector(
    (state) => state?.carts?.myCartSaveLaterView?.payload
  );
  const creditPoints = useSelector((state) => state.creditPoints.creditPoints);
  const wishListRes = useSelector((state) => state.product.getWishList);
  const auth = useSelector((state) => state.auth.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);

  const [wishlist, setWishList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState(
    "Added in cart successfully"
  );
  const [courseId, setCourseId] = useState(false);

  const [userCreditPoint, setUserCreditPoint] = useState(0);
  const [redeemCard, setRedeemCard] = useState(false);
  const [myCartDetails, setMyCartDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [savedData, setSavedData] = useState([]);

  const [couponDetail, setCouponDetail] = useState({});
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        myCart({
          module: "myCart",
          email: authBroker?.payload?.user?.email,
          // userId: localStorage.getItem(SessionKeys.EMAIL_SESSION)
        })
      );
      dispatch(
        getCreditPoint({
          module: "getCreditPoint",
          email: authBroker?.payload?.user?.email,
        })
      );
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    // console.log("subTotalPrice: ", "redeem");
    if (redeemCard) {
      // console.log(_.toNumber((userCreditPoint / 10).toFixed(2)), subTotalPrice);
      setTotalPrice(subTotalPrice - userCreditPoint / 10);
    } else {
      setTotalPrice(subTotalPrice);
    }
  }, [redeemCard]);

  useEffect(() => {
    // console.log("subTotalPrice: ", "redeemUse");
    totalPriceFromApi();
    //    if(couponCode===''){
    //     setIsAppliedCoupon(false)
    //     setCouponCode('')
    //    }
  }, [couponDetail]);

  const totalPriceFromApi = () => {
    // console.log("subTotalPrice: ", "fromApi");
    if (Object.keys(couponDetail).length > 0) {
      // console.log("couponDetailCall ", couponDetail);
      setTotalPrice(_.toNumber(couponDetail?.orderTotal));
    }
    // else {
    //     if (Object.keys(couponDetail).length === 0) {
    //         console.log('couponDetailCall1 ',subTotalPrice)
    //         setTotalPrice(subTotalPrice)
    //     }
    // }
  };

  useEffect(() => {
    // console.log("myCartRes: ", myCartRes);
    // console.log("length", myCartRes);
    if (_.isEmpty(myCartRes) || _.isEmpty(myCartRes.cart_list)) {
      setMyCartDetails([]);
    } else {
      let cartData = _.cloneDeep(myCartRes?.cart_list);
      for (let cart of cartData) {
        // console.log("myCartRes", cart);
        cart.is_bundle = false;
        if (cart.sfdc_product_type.toLowerCase() === "bundle") {
          cart.is_bundle = true;
          for (let course of cart.course_list) {
            cart.course_lessons = cart.course_lessons + course.lessons;
            cart.course_duration = cart.course_duration + course.duration;
            cart.course_period = "Hour(s)";
            if (
              course.durationUnit === "Second(s)" &&
              course.duration >= 60
            ) {
              console.log('COURSE', course)
              if (course.duration % 60) {
                course.duration = convertTimeFormat(
                  course.duration,
                  true
                );
              } else {
                course.duration = convertSecondsToMins(
                  course.duration
                );
                course.duration = convertTimeFormat(
                  course.duration
                );
              }
              course.durationUnit = "";
            } else if (
              course.durationUnit === "Second(s)" &&
              course.duration < 60
            ) {
              course.duration = `${course.duration} Sec${course.duration > 1 ? "s" : ""
                }`;
              course.durationUnit = "";
            } else if (course.durationUnit === "Hours") {
              course.duration = convertHoursToMins(course.duration);
            }
            course.duration = convertTimeFormat(course.duration);
            course.durationUnit = "";
          }
        } else {
          if (cart.durationUnit === "Hours") {
            cart.duration = convertHoursToMins(cart.duration);
          }
          cart.duration = convertTimeFormat(cart.duration);
          cart.durationUnit = "";
        }
      }
      setMyCartDetails(cartData);
    }

    if (_.isEmpty(myCartRes)) {
      setCouponDetail({});
    } else {
      setCouponDetail(myCartRes?.coupon_data);
    }
  }, [myCartRes]);

  useEffect(() => {
    // console.log("myCartDetails: ", myCartDetails);
    if (myCartDetails !== undefined) {
      // console.log("product priice", parseInt(myCartDetails[0].product_sale_price))
      var total = 0;
      for (var i = 0; i < myCartDetails.length; i++) {
        total += parseFloat(myCartDetails[i].product_sale_price);
      }
      setSubTotalPrice(total);
      setTotalPrice(total);
      totalPriceFromApi();
    }
  }, [myCartDetails]);

  useEffect(() => {
    // console.log("creditPoint", creditPoints);
    if (!_.isEmpty(creditPoints)) {
      if (creditPoints.status === "success") {
        setUserCreditPoint(
          _.toNumber(creditPoints.payload.payload.credit_points)
        );
      }
    }
  }, [creditPoints]);

  const proceedToPayment = () => {
    clickStreamUser("proceed_to_buy_btn_click", {
      amount: totalPrice,
      item: myCartDetails.length,
      userId: auth.authBroker?.payload?.user.user_id,
    });
    dispatch(
      cartPageDataResponse({
        item: myCartDetails.length,
        price: fixedToDecimal(totalPrice, 2)
      })
    );
    history.push("/payment?redeempoints=" + redeemCard);
  };

  useEffect(() => {
    // console.log("wishlistres: ", wishListRes);
    if (!_.isEmpty(wishListRes)) {
      props.setIsLoading(false);
      if (wishListRes.status === "success" && !_.isEmpty(wishListRes.payload)) {
        for (let course of wishListRes.payload) {
          course.selling_price = fixedToDecimal(course.selling_price, 2)
          course.is_bundle = true;
          if (course.sfdc_product_type.toLowerCase() !== "bundle") {
            course.is_bundle = false;
            if (
              course.durationUnit === "Second(s)" &&
              course.duration >= 60
            ) {
              if (course.duration % 60) {
                course.duration = convertTimeFormat(
                  course.duration,
                  true
                );
              } else {
                course.duration = convertSecondsToMins(
                  course.duration
                );
                course.duration = convertTimeFormat(
                  course.duration
                );
              }
              course.durationUnit = "";
            } else if (
              course.durationUnit === "Second(s)" &&
              course.duration < 60
            ) {
              course.duration = formatSecondsHand(course.duration);
              course.durationUnit = "";
            } else if (course.durationUnit === "Minutes") {
              course.duration = convertTimeFormat(_.toNumber(course.duration))
            } else if (course.durationUnit === "Hours") {
              course.duration = convertHoursToMins(course.duration);
              course.duration = convertTimeFormat(course.duration);
            }
            course.durationUnit = "";
          } else {
            // console.log('Duration', course)
            for (let cl of course.course_list) {
              if (
                cl.durationUnit === "Second(s)" &&
                cl.duration >= 60
              ) {
                if (cl.duration % 60) {
                  cl.duration = convertTimeFormat(
                    cl.duration,
                    true
                  );
                } else {
                  cl.duration = convertSecondsToMins(
                    cl.duration
                  );
                  cl.duration = convertTimeFormat(
                    cl.duration
                  );
                }
                cl.durationUnit = "";
              } else if (
                cl.durationUnit === "Second(s)" &&
                cl.duration < 60
              ) {
                cl.duration = formatSecondsHand(cl.duration);
                cl.durationUnit = "";
              } else if (cl.durationUnit === "Hours") {
                cl.duration = convertHoursToMins(cl.duration);
                cl.duration = convertTimeFormat(cl.duration);
              } else if (cl.durationUnit === "Minutes") {
                cl.duration = convertTimeFormat(_.toNumber(cl.duration))
              }

              cl.durationUnit = "";

              course.lessons += cl.lessons

            }
          }
        }
        setWishList(wishListRes?.payload);
      } else {
        setWishList([]);
      }
    }
  }, [wishListRes]);

  const renderAlertCard = () => {
    return (
      <div className={`alert-card d-flex justify-content-center `}>
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </svg>
            <p className="m-0">{showModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <span
              className="cursor-pointer"
              onClick={() => {
                clickStreamUser("go-to-cart-from-shopping-cart-btn-clicked", {
                  userId: auth.authBroker?.payload.user.user_id,
                  ecommerceId: courseId,
                });
                history.push("/cart");
              }}
            >
              View Cart
            </span>
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setConfirmModal(false);
                setShowModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <section className="my_cart">
        <div className={`cart-wrapper`}>
          {/*renderAlertCard()*/}
          <div className="container">
            <div className="row">
              <h3>My Wishlist</h3>
            </div>
            {wishlist.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 cart-content">
                    <p className="mb-3">{wishlist.length} Item{wishlist.length > 1 ? 's' : ''} </p>

                    <WishListCourseCard
                      myCartDetails={wishlist}
                      myCartLength={wishlist.length}
                      setConfirmModal={setConfirmModal}
                      setShowModalMsg={setShowModalMsg}
                      setCourseId={setCourseId}
                      confirmModal={confirmModal}
                      showModalMsg={showModalMsg}
                      courseId={courseId}
                      setIsLoading1={props.setIsLoading}
                    />
                  </div>

                  {false ? (
                    <div className="col-xl-4 col-md-12 col-sm-12 cart-content">
                      <CartSummaryCard
                        myCartLength={myCartDetails.length}
                        totalPrice={totalPrice}
                        subTotalPrice={subTotalPrice}
                        userCreditPoint={userCreditPoint}
                        redeemCard={redeemCard}
                        proceedToPayment={proceedToPayment}
                        setRedeemCard={setRedeemCard}
                        couponDetail={couponDetail}
                        setCouponDetail={setCouponDetail}
                        isAppliedCoupon={isAppliedCoupon}
                        setIsAppliedCoupon={setIsAppliedCoupon}
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        setShowModalMsg={setShowModalMsg}
                        showModalMsg={showModalMsg}
                        couponCode={couponCode}
                        setCouponCode={setCouponCode}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

              </>
            ) : (
              <div className="not-found mt-3">
                <div className="interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p>No item added yet.</p>
                </div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-xl-8 col-md-12 col-sm-12 banef-content">
                <div className="wish-cart-offer">
                  <img
                    src="/images/cartbanner.png"
                    className="img-fluid"
                    alt="Cart Offer"
                  />
                  <div className="clickmap-wish" onClick={
                    () => {

                      clickStreamUser("upgrade-btn-clicked", {

                        user_id: authBroker.payload.user.user_id,

                      });

                      history.push("/membership");

                    }
                  }>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default WishlistSection;
