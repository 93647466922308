import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  fetchPaymentIntent,
  fetchPaymentIntentClear,
  fetchPIDirectCheckout,
  fetchPIDirectCheckoutClear,
} from "../../redux/checkout/actions";
import BillingInformationForm from "./BillingInformationForm";
import PaymentForm from "./PaymentForm";
import { clickStreamUser, fixedToDecimal, SessionKeys } from "../../utils/AppUtils";
import { fixedFooter, fixedFooterClear } from "../../redux/home/actions";
import { userDetailBroker } from "../../redux/user/actions";
import {
  getMembershipIntent,
  getMembershipIntentClear,
} from "../../redux/membership/actions";

const PaymentSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paymentIntent = useSelector((state) => state.checkout.paymentIntent);
  const cartPageData = useSelector((state) => state.checkout.cartPageData);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const piDirectCheckout = useSelector(
    (state) => state.checkout.piDirectCheckout
  );
  const membershipIntent = useSelector(
    (state) => state.membership.membershipIntent
  );

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const product_id = params.get("product_id");
  const redeempoints = params.get("redeempoints");
  const subscription = params.get("subscription");
  const auto = params.get("auto");

  const [showPaymentFormError, setShowPaymentError] = useState(false);
  const [cartEmpty, setCartEmpty] = useState(false);
  const [paymentData, setPaymentData] = useState({
    amount: "0.00",
    subTotalAmount: "",
    item: 0,
    discount: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    // console.log('boolean', auto)
    if (!_.isEmpty(authBroker) && authBroker?.payload?.user?.email) {
      if (subscription) {
        dispatch(
          getMembershipIntent({
            module: "get_membership_payment_intent",
            postData: {
              email: authBroker.payload.user.email,
              broker_id: authBroker.payload.user.broker_id,
              user_id: authBroker.payload.user.user_id,
            },
          })
        );
      } else if (product_id) {
        dispatch(
          fetchPIDirectCheckout({
            module: "directCheckout",
            postData: {
              email: authBroker.payload.user.email,
              p_id: product_id,
              qty: 1,
            },
          })
        );
      } else if (auto == "true") {
        if (!_.isEmpty(cartPageData)) {
          setPaymentData({
            amount: _.toNumber(cartPageData.price),
            discount: _.toNumber(cartPageData.discount),
            subTotalAmount: _.toNumber(cartPageData.subTotalPrice),
            item: _.toNumber(cartPageData.item),
          });
        }
      } else {
        dispatch(
          fetchPaymentIntent({
            module: "fetchPaymentIntent",
            email: authBroker.payload.user.email,
            redeem: redeempoints,
          })
        );
      }
      clickStreamUser("payment-form-page", {
        userId: authBroker?.payload?.user.user_id,
      });
      dispatch(
        userDetailBroker({
          module: "getOrderDetailBroker",
          apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        })
      );
      // dispatch(fixedFooter())
    }
  }, [dispatch, authBroker, product_id]);

  useEffect(() => {
    if (!_.isEmpty(cartPageData)) {
      setPaymentData({
        amount: _.toNumber(cartPageData.price),
        discount: _.toNumber(cartPageData.discount),
        subTotalAmount: _.toNumber(cartPageData.subTotalPrice),
        item: _.toNumber(cartPageData.item),
      });
    }
  }, [cartPageData]);

  useEffect(() => {
    // console.log(paymentIntent);
    if (!_.isEmpty(paymentIntent)) {
      if (paymentIntent.status === "success") {
        if (!_.isEmpty(paymentIntent.payload.payload)) {
          const paymentInfo = paymentIntent.payload.payload;
          //   console.log("PaymentInfo", paymentInfo);
          if (!paymentInfo.amount || paymentInfo.amount.toString() == "0") {
            // history.push('/')
            setCartEmpty(true);
          } else {
            clickStreamUser("payment-form-loaded", {
              amount: paymentInfo.amount,
              item: paymentInfo.item_count ? paymentInfo.item_count : 0,
              userId: authBroker?.payload?.user.user_id,
            });
            let discount = _.toNumber(paymentInfo?.discount);
            // console.log(
            //   "DISCOUNT",
            //   discount,
            //   _.toNumber(paymentInfo.coupon_data.coupon_discount_amount),
            //   discount +
            //     _.toNumber(paymentInfo.coupon_data.coupon_discount_amount)
            // );
            discount += paymentInfo?.coupon_data?.coupon_discount_amount
              ? _.toNumber(paymentInfo?.coupon_data?.coupon_discount_amount)
              : 0;
            setShowPaymentError(false);
            // console.log("DISCOUNT", discount);
            discount = discount;
            setPaymentData({
              amount: _.toNumber(paymentInfo?.amount),
              subTotalAmount: paymentInfo?.sub_total
                ? paymentInfo.sub_total
                : "",
              discount: discount ? _.toNumber(discount) : "",
              clientSecret: paymentInfo?.client_secret,
              paymentToken: paymentInfo?.paymentIntentsToken,
              item: paymentInfo?.item_count ? paymentInfo?.item_count : 0,
              orderId: paymentInfo?.order_id ? paymentInfo?.order_id : "",
              isSubscription: false,
            });
            dispatch(fixedFooterClear());
          }
        } else {
          setCartEmpty(true);
          dispatch(fixedFooter());
        }
        dispatch(fetchPaymentIntentClear());
      } else {
        // console.log("error payment form");
        setShowPaymentError(true);
      }
    }
  }, [paymentIntent]);

  useEffect(() => {
    // console.log("Direct Checkout", piDirectCheckout);
    if (!_.isEmpty(piDirectCheckout)) {
      if (piDirectCheckout.status === "success") {
        if (!_.isEmpty(piDirectCheckout.payload.payload)) {
          const paymentInfo = piDirectCheckout.payload.payload;
          //   console.log("PaymentInfo", paymentInfo);
          if (!paymentInfo.amount || paymentInfo.amount.toString() == "0") {
            // history.push('/')
            setCartEmpty(true);
          } else {
            clickStreamUser("payment-form-loaded", {
              amount: paymentInfo.amount,
              item: paymentInfo.item_count ? paymentInfo.item_count : 0,
              userId: authBroker?.payload?.user.user_id,
            });
            setShowPaymentError(false);
            setPaymentData({
              amount: _.toNumber(paymentInfo?.amount),
              subTotalAmount: paymentInfo?.sub_total
                ? paymentInfo.sub_total
                : "",
              discount: paymentInfo?.discount
                ? _.toNumber(paymentInfo.discount)
                : "",
              clientSecret: paymentInfo?.client_secret,
              paymentToken: paymentInfo?.paymentIntentsToken,
              item: 1,
              orderId: paymentInfo?.order_id ? paymentInfo?.order_id : "",
              isSubscription: false,
            });
            dispatch(fixedFooterClear());
          }
        } else {
          setCartEmpty(true);
          dispatch(fixedFooter());
        }
        dispatch(fetchPIDirectCheckoutClear());
      } else {
        // console.log("error payment form");
        setShowPaymentError(true);
      }
    }
  }, [piDirectCheckout]);

  useEffect(() => {
    // console.log("membership", membershipIntent);
    if (!_.isEmpty(membershipIntent)) {
      if (membershipIntent.status === "success") {
        if (!_.isEmpty(membershipIntent.payload.payload)) {
          const paymentInfo = membershipIntent.payload.payload;
          //   console.log("PaymentInfo", paymentInfo);
          if (!paymentInfo.amount || paymentInfo.amount.toString() == "0") {
            // history.push('/')
            setCartEmpty(true);
          } else {
            clickStreamUser("payment-form-loaded", {
              amount: paymentInfo.amount,
              item: paymentInfo.item_count ? paymentInfo.item_count : 0,
              userId: authBroker?.payload?.user.user_id,
            });
            setShowPaymentError(false);
            setPaymentData({
              amount: _.toNumber(paymentInfo?.amount),
              subTotalAmount: paymentInfo?.sub_total
                ? paymentInfo.sub_total
                : "",
              discount: paymentInfo?.discount
                ? _.toNumber(paymentInfo.discount)
                : "",
              clientSecret: paymentInfo?.client_secret,
              paymentToken: paymentInfo?.subscription_id,
              item: paymentInfo?.item_count ? paymentInfo?.item_count : 0,
              orderId: paymentInfo?.order_id ? paymentInfo?.order_id : "",
              productId: product_id,
              isSubscription: true,
            });
            dispatch(fixedFooterClear());
          }
        } else {
          setCartEmpty(true);
          dispatch(fixedFooter());
        }
        dispatch(getMembershipIntentClear());
      } else {
        // console.log("error payment form");
        setShowPaymentError(true);
      }
    }
  }, [membershipIntent]);

  return (
    <div className="my-credit-points position-relative">
      <section className="payment payment-form-section">
        <div className="Payment-container">
          <div className="container h-76vh">
            <div className="row">
              <h3>Payment</h3>
              {!cartEmpty ? (
                <>
                  <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12">
                    {/* <BillingInformationForm /> */}
                    <PaymentForm
                      paymentData={paymentData}
                      showPaymentFormError={showPaymentFormError}
                    />
                  </div>
                  <div className="col-xl-5 col-sm-12 col-md-4 col-lg-5 image-login">
                    <div
                      className="card border-light payment-card"
                      style={{ maxWidth: "30rem" }}
                    >
                      <div className="payment-header">
                        <p>{paymentData.item}</p>
                        <p>Item{_.toNumber(paymentData.item) > 1 ? 's' : ''}</p>
                      </div>
                      <div className="card-body payment-body">
                        <div className="body-card">
                          <h5 className="card-title payment-title">
                            Order Total
                          </h5>
                          <h2>
                            <span className="dollar-normal">$</span>
                            {fixedToDecimal(paymentData.amount, 2).split(".")[0]}
                            <sup>
                              {fixedToDecimal(paymentData.amount, 2).split(".")[1]}
                            </sup>
                            &nbsp;<span>CAD</span>
                          </h2>
                        </div>
                        <div className="pay-wrapper">
                          <div className="pay-title">
                            <>
                              {paymentData.subTotalAmount !=
                                paymentData.amount &&
                                paymentData.subTotalAmount ? (
                                  <p className="card-text payment-text">
                                    ${paymentData.subTotalAmount}&nbsp;CAD
                                  </p>
                                ) : ""
                              }
                            </>
                            <p>Exclusive of all taxes</p>
                          </div>
                          <div className="pay-perc">
                            {(paymentData.discount) ? (
                              <p>-${fixedToDecimal(paymentData.discount, 2)}</p>
                            ) : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p>Error loading form, please try again later.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentSection;
