import React from "react";
import html2canvas from "html2canvas";
import $ from "jquery";
import jsPDF from "jspdf";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCertificateBroker } from "../../redux/certified/actions";
import { SessionKeys } from "../../utils/AppUtils";
import {
  certificateData,
  certificateDataResponse,
  certificateDataClear,
} from "../../redux/certificateURL/actions";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { Spinner } from "react-bootstrap";
import { scroller } from "react-scroll";
import CONFIG from "../../utils/config";

const MyCertificates = () => {
  const dispatch = useDispatch();

  const certificateRes = useSelector(
    (state) => state.certified.getCertificates
  );
  const [certifatesArr, setCertificateArr] = useState([]);
  const certificateURLData = useSelector((state) => state.certificateURL);
  const [clickedId, setClickedId] = useState();
  const [imgLoader, setImgLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [printReq, setPrintReq] = useState({});
  const [clickPrint,setClickPrint]=useState(false)
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const user_id = params.get("user_id");
  const isOther = params.get("isOther");
  const section = params.get("section");
  //console.log("loader", isLoading);
  const authBroker = useSelector(state => state.auth.authBroker)
  useEffect(() => {
    console.log("Return me", certificateURLData);
    setImgLoader(true);
    if (
      !_.isEmpty(certificateURLData) &&
      certificateURLData?.status === "success"
    ) {
      // getBlob();
      // console.log("Useeffect 1 called");
    }
    setTimeout(() => {
      setImgLoader(false);
    }, 1000);
  }, [certificateURLData]);

  useEffect(() => {
    if (certifatesArr.length > 0) {
      //setIsLoading(true);
      console.log(certifatesArr);
      var count = 0;
      for (let x = 0; x <= certifatesArr.length - 1; x++) {
        let item = certifatesArr[x];
        console.log("Item", item);
        if (
          item.certificate_url === null ||
          item.certificate_url === undefined
        ) {
          getBlob(x, "certificate", item.learning_path_id);
        }
      }
    }
  }, [certifatesArr]);

  useEffect(() => {
    if (certificateRes?.status === "success") {
      setCertificateArr(certificateRes?.payload?.payload);
    } else {
      setCertificateArr([]);
    }

    if(section==='badge'){
      scroller.scrollTo("badge", {
        duration: 50,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }

    // console.log("certificates: ", certificateRes);
    // console.log("Useeffect 3 called");
  }, [certificateRes]);

  const getId = (index) => {
    setClickedId(index);
    // console.log("clicked id:", index);
    // console.log(
    //   "999",
    //   certificateRes?.payload?.payload[index].learning_path_id
    // );
  };

  const getBlob = (index, type, learning_path_id) => {
    // console.log("Get blob clicked with index", index);
    //certificate_url = null;
    //if (!certificate_url) {
    var HTML_Width = $(".certificatePrint").width();
    var HTML_Height = $(".certificatePrint").height();
    var top_left_margin = 18;
    var PDF_Width = HTML_Width + top_left_margin * 3;
    var PDF_Height = PDF_Width * 2 + top_left_margin * 3;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // console.log("certificatePrint :", $(".certificatePrint"));
    html2canvas($(".certificatePrint")[index], {
      allowTaint: true,
      useCORS: true,
    }).then(function (canvas) {
      canvas.getContext("2d");
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 3);
      var imgData1 = canvas.toDataURL("image/png", 3);

      dispatch(
        certificateData({
          module: "myCertificateURL",
          postData: {
            img_blob: imgData1,
            learningpath_id: learning_path_id,
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            type: type,
          },
        })
      );
    });
  };

  useEffect(() => {
    if (!_.isEmpty(printReq)&&clickPrint) {
      getPDF(false);
      setClickPrint(false)
    }
  }, [printReq,clickPrint]);

  const getPDF = (download) => {
    var HTML_Width = $(".certificatePrintG").width();
    var HTML_Height = $(".certificatePrintG").height();
    var top_left_margin = 18;
    var PDF_Width = HTML_Width + top_left_margin * 3;
    var PDF_Height = PDF_Width * 2 + top_left_margin * 3;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // console.log("certificatePrintG :", $(".certificatePrintG"));
    html2canvas($(".certificatePrintG")[0], {
      allowTaint: true,
      useCORS: true,
      onclone: (document) => {
        var deleteIcon = document.getElementsByClassName("certificatePrintG");
        if (deleteIcon.length > 0) {
          for (var i = 0; i < deleteIcon.length; i++) {
            deleteIcon[i].style.display = "block";
          }
        }
      },
    }).then(function (canvas) {
      canvas.getContext("2d");
      // console.log(canvas.height + "  " + canvas.width);
      var imgData = canvas.toDataURL("image/jpeg", 3);

      var pdf = new jsPDF("l", "pt", "a4");

      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height,
        "center"
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(String(PDF_Width), String(PDF_Height));
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height,
          { align: "center" }
        );
      }
      if (download) pdf.save("certificate.pdf");
      else pdf.output(`dataurlnewwindow`);
    });
  };

  return (
    <>
      <div id="MyCertificates" className="bg-grey">
        {/*<Loader start={isLoading} setIsLoading={setIsLoading} />*/}
        <section className="container mb-0">
          <div className="MyCertifate">
            <div className="row flexboxprof">
              <div className="col-md-12">
                <h4>My Certificates</h4>
              </div>
              {imgLoader ? (
                <div className="d-flex justify-content-center w-100">
                  <Spinner animation="border" />
                </div>
              ) : certifatesArr?.length > 0 ? (
                certifatesArr.map((item, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 certificateimg certificate-sec mt-3" key={index}>
                      <div id={"certificatePrint" + index}>
                        <div className="certificatePrint">
                          {/* {item?.certificate_url != null ? ( 
                            <img src={item?.certificate_url} />
                          ) 
                          :  */}
                          
                            <div className="certificate-section">
                              <div className="certify-logo-section">
                                <div className="certificate-logo">
                                  <div className="certify-complete">
                                    <p>{item?.certificate_name}</p>
                                  </div>
                                  {/* <div className="cerficatelogobox">
                                    <img
                                      src={item?.broker_logo}
                                      width="60"
                                      height="30"
                                    />
                                  </div> */}
                                </div>
                              </div>
                              <div className="certify-txt-section">
                                <div className="certify-wrapper">
                                  <div className="certify-text">
                                    <div className="certify-text__tagline">
                                      Proudly Presented to
                                    </div>
                                    <div className="certify-text__title">
                                      {item?.realtor_name}
                                    </div>
                                  </div>
                                  <div className="certify-details">
                                    <p>{`On successful completion of ${item?.learning_path_name} learning path.`}</p>
                                  </div>

                                  <div className="certify-wrap">
                                    <div className="certify-sign">
                                      {item?.signature != null &&
                                      item?.signature !== undefined &&
                                      item?.signature !== "" ? (
                                        <img
                                          src={item?.signature}
                                          width="60"
                                          height="30"
                                        />
                                      ) : (
                                        ""
                                      )}
                                        {/* <img
                                          src={item?.signature}
                                          width="60"
                                          height="30"
                                        /> */}
                                      <p className="title-certificate-des">
                                        <strong>{item?.designation}</strong>
                                      </p>
                                      <p>{item?.president}</p>
                                    </div>
                                    <div className="certify-issue">
                                      <p className="title-certificate-des">
                                        <strong>Issued on</strong>
                                      </p>
                                      <p>
                                        {moment(item?.issued_on).format(
                                          "Do MMM YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="certify-detail-logo">
                                      <img
                                        src="/images/LOGO_head.png"
                                        width="170"
                                        height="80"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                         
                        </div>
                        {
                          authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                          <div className="certificateshare icons d-flex justify-content-center">
                          <div className="button-reset sharebtn">
                            <FacebookShareButton
                              id={"certificateSharePrint" + index}
                              url={item?.certificate_url}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                id="facebook"
                                data-name="facebook"
                                width="26"
                                height="26"
                                viewBox="0 0 30 30"
                              >
                                <defs>
                                  <linearGradient
                                    id="linear-gradients"
                                    y1="0.5"
                                    x2="1"
                                    y2="0.5"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop offset="0" stop-color="#00b7f1" />
                                    <stop offset="1" stop-color="#ed156e" />
                                  </linearGradient>
                                </defs>
                                <circle
                                  id="Ellipse_198"
                                  data-name="Ellipse 198"
                                  cx="15"
                                  cy="15"
                                  r="15"
                                  fill="url(#linear-gradients)"
                                />
                                <circle
                                  id="Ellipse_199"
                                  data-name="Ellipse 199"
                                  cx="13"
                                  cy="13"
                                  r="13"
                                  transform="translate(2 2)"
                                  fill="#fff"
                                  className="fillwhite"
                                />
                                <path
                                  id="Icon_awesome-facebook-f"
                                  data-name="Icon awesome-facebook-f"
                                  d="M8.9,8.2l.4-2.638H6.778V3.849A1.319,1.319,0,0,1,8.265,2.424H9.415V.178A14.031,14.031,0,0,0,7.373,0,3.22,3.22,0,0,0,3.926,3.55V5.56H1.609V8.2H3.926v6.376H6.778V8.2Z"
                                  transform="translate(9.487 7.713)"
                                  fill="var(--color-element-1)"
                                />
                              </svg>
                            </FacebookShareButton>
                          </div>
                          <div className="button-reset sharebtn">
                          <button
                            className="button-reset-print"
                            onClick={() => {
                              // console.log("click");
                              if (window.callbackHandler != undefined) {
                                window.callbackHandler.postMessage(item?.certificate_url);
                              } else if (window.webkit != undefined) {
                                if (window.webkit.messageHandlers.callbackHandler != undefined) {
                                  window.webkit.messageHandlers.callbackHandler.postMessage(item?.certificate_url);
                                }
                              }
        
                              console.log(item,"setPrintReq")
                              setPrintReq(item);
                              setClickPrint(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              id="Print"
                              width="26"
                              height="26"
                              viewBox="0 0 30 30"
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradientt"
                                  y1="0.5"
                                  x2="1"
                                  y2="0.5"
                                  gradientUnits="objectBoundingBox"
                                >
                                  <stop offset="0" stop-color="#00b7f1" />
                                  <stop offset="1" stop-color="#ed156e" />
                                </linearGradient>
                              </defs>
                              <circle
                                id="Ellipse_57"
                                data-name="Ellipse 57"
                                cx="15"
                                cy="15"
                                r="15"
                                fill="url(#linear-gradientt)"
                              />
                              <circle
                                id="Ellipse_179"
                                data-name="Ellipse 179"
                                cx="13"
                                cy="13"
                                r="13"
                                transform="translate(2 2)"
                                fill="#fff"
                              />
                              <g
                                id="Icon_ionic-ios-print"
                                data-name="Icon ionic-ios-print"
                                transform="translate(4.504 4.504)"
                              >
                                <path
                                  id="Path_1248"
                                  data-name="Path 1248"
                                  d="M9,16.86v6.846a.549.549,0,0,0,.548.548h7.668a.549.549,0,0,0,.548-.548V16.86a.549.549,0,0,0-.548-.548H9.548A.549.549,0,0,0,9,16.86Z"
                                  transform="translate(-2.886 -6.639)"
                                  fill="var(--color-element-1)"
                                />
                                <g
                                  id="Group_1142"
                                  data-name="Group 1142"
                                  transform="translate(3.375 3.375)"
                                >
                                  <path
                                    id="Path_1249"
                                    data-name="Path 1249"
                                    d="M17.186,3.375H8.423a.549.549,0,0,0-.548.548v.685a.137.137,0,0,0,.137.137H17.6a.137.137,0,0,0,.137-.137V3.923A.549.549,0,0,0,17.186,3.375Z"
                                    transform="translate(-5.684 -3.375)"
                                    fill="var(--color-element-1)"
                                  />
                                  <path
                                    id="Path_1250"
                                    data-name="Path 1250"
                                    d="M16.537,7.875H4.488A1.1,1.1,0,0,0,3.375,8.946v5.97a1.125,1.125,0,0,0,1.113,1.106h.531a.275.275,0,0,0,.274-.274V12.12a.957.957,0,0,1,.958-.958H14.74a.957.957,0,0,1,.958.958v3.629a.275.275,0,0,0,.274.274h.565a1.1,1.1,0,0,0,1.078-1.106V8.946A1.066,1.066,0,0,0,16.537,7.875Z"
                                    transform="translate(-3.375 -5.684)"
                                    fill="var(--color-element-1)"
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                          </div>
                         
                            <div className="button-reset sharebtn">
                              <LinkedinShareButton url={item?.certificate_url}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  id="linkedin"
                                  data-name="linkedin"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 30 30"
                                >
                                  <defs>
                                    <linearGradient
                                      id="linear-gradients"
                                      y1="0.5"
                                      x2="1"
                                      y2="0.5"
                                      gradientUnits="objectBoundingBox"
                                    >
                                      <stop offset="0" stop-color="#00b7f1" />
                                      <stop offset="1" stop-color="#ed156e" />
                                    </linearGradient>
                                  </defs>
                                  <circle
                                    id="Ellipse_57"
                                    data-name="Ellipse 57"
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    fill="url(#linear-gradients)"
                                  />
                                  <circle
                                    id="Ellipse_179"
                                    data-name="Ellipse 179"
                                    cx="13"
                                    cy="13"
                                    r="13"
                                    transform="translate(2 2)"
                                    fill="#fff"
                                    className="fillwhite"
                                  />
                                  <path
                                    id="Icon_awesome-linkedin-in"
                                    data-name="Icon awesome-linkedin-in"
                                    d="M2.781,12.424H.205V4.13H2.781ZM1.492,3A1.5,1.5,0,1,1,2.983,1.492,1.5,1.5,0,0,1,1.492,3Zm10.929,9.426H9.851V8.386c0-.962-.019-2.2-1.339-2.2-1.339,0-1.544,1.045-1.544,2.127v4.107H4.394V4.13h2.47V5.261H6.9A2.706,2.706,0,0,1,9.338,3.922c2.607,0,3.086,1.717,3.086,3.946v4.556Z"
                                    transform="translate(8.788 8.789)"
                                    fill="#7a549e"
                                  />
                                </svg>
                              </LinkedinShareButton>
                              {/* <ShareMe /> */}
                            </div>
                          
                        </div>
                        }
                       
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-md-12">
                <div className="not-found mt-3">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>No certificates found.</p>
                  </div>
                </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <table className="certificatePrintG" style={{ display: "none" }}>
          
          <tr>
            <td>
              <div className="certificate-section">
                
                <div className="certify-logo-section">
                  <div className="certificate-logo">
                    <div className="certify-complete">
                      <p>{printReq?.certificate_name}</p>
                    </div>
                    {/* <div className="cerficatelogobox">
                      <img src={printReq?.broker_logo} width="60" height="30" />
                    </div> */}
                  </div>
                </div>
                <div className="certify-txt-section">
                  <div className="certify-wrapper">
                    <div className="certify-text">
                      <div className="certify-text__tagline">
                        Proudly Presented to
                      </div>
                      <div className="certify-text__title">
                        {printReq?.realtor_name}
                      </div>
                    </div>
                    <div className="certify-details">
                      <p>{`On successful completion of ${printReq?.learning_path_name} learning path.`}</p>
                    </div>

                    <div className="certify-wrap">
                      <div className="certify-sign">
                        {/* {printReq?.signature != null &&
                        printReq?.signature !== undefined &&
                        printReq?.signature !== "" ? (
                          <img
                            src={printReq?.signature}
                            width="60"
                            height="30"
                          />
                        ) : (
                          ""
                        )} */}
                   
                          {printReq?.signature != null &&
                                      printReq?.signature !== undefined &&
                                      printReq?.signature !== "" ? (
                                        <img
                                          src={printReq?.signature}
                                          width="60"
                                          height="30"
                                        />
                                      ) : (
                                        ""
                                      )}
                         
                        <p>
                          <strong>{printReq?.designation}</strong>
                        </p>
                        <p>{printReq?.president}</p>
                      </div>
                      <div className="certify-issue">
                        <p>
                          <strong>Issued on</strong>
                        </p>
                        <p>
                          {moment(printReq?.issued_on).format("Do MMM YYYY")}
                        </p>
                      </div>
                      <div className="certify-detail-logo">
                        <img
                          src="/images/LOGO_head.png"
                          width="170"
                          height="80"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
        {loader && (
          <div className="common-overlay">
            <div className="spinner-text">
              <Spinner animation="border" size="lg" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyCertificates;
