import types from './types'


// userDetails
export const getArticle = (payload) => ({
    type: types.GET_ARTICLE, payload
  })
  export const getArticleResponse = (payload) => (
   {
    type: types.GET_ARTICLE_RESPONSE, payload
  })


  