import types from './types'

const initialState = {
  // User Courses
  userCourses: {},
  // In progress Courses
  inProgressBrokerCourses: {},
  // Courses Categories
  courseCategories: {},
  // Product Categories
  productCategories: {},
  // Courses Listing
  coursesListing: {},
  // Module Listing
  moduleListing: {},
  //ModuleNotes
  moduleNotesGet: {},
  moduleNotesUpdate: {},
  moduleNotesDelete: {},

  //ModuleFavourites
  moduleFavGet: {},
  moduleFavDelete: {},
  //selected category
  selectedCategory: '',
  // favorite courses
  favoriteCourses: {},
  setFavoriteCourses: {},
  removeFavoriteCourses: {},
  showBuyModal: false,

  // Learning Path
  learningPath: {},
  // Learning Path Broker
  learningPathBroker: {},

  openHamburger: false,

  //from woo commerse
  coursesListingWoo: {},

  //from broker admin
  coursesCategoriesBroker: {},

  //from broker admin 
  coursesListingBroker: {},

  //from broker admin
  moduleListingBroker: {},
  learningPathListingBroker:{},
  // Assign Course
  assignCourse: {},
  assignBrokerCourse: {},
  // My Courses
  myCourses: {},
  // My Learning Path
  myLearningPaths: {},
  learningPathUnAssigned: {},
  // Code of EthicsTODO:
  ethicsData: {},
  ethicsAgreeData: {},

  moduleProgress: {},
  videoProgress: {},
  getLevelDetail: {},
  markView: {},
  checkListInsert: {},
  productCategoriesData: {},
  productSubCategoriesData:{},

  // Single LP
  lpData: {},

  // Course related
  courseRelated: {},
}


/**
 * Get User Courses
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_COURSES_RESPONSE:
      return {
        ...state,
        userCourses: action.payload,
      }

    case types.USER_COURSES_CLEAR:
      return {
        ...state,
        userCourses: {},
      }

    case types.IN_PROGRESS_BROKER_RESPONSE:
      return {
        ...state,
        inProgressBrokerCourses: action.payload,
      }

    case types.IN_PROGRESS_BROKER_CLEAR:
      return {
        ...state,
        inProgressBrokerCourses: {},
      }

    case types.COURSE_CATEGORIES_RESPONSE:
      return {
        ...state,
        courseCategories: action.payload,
      }

    case types.COURSE_CATEGORIES_CLEAR:
      return {
        ...state,
        courseCategories: {},
      }

    case types.PRODUCT_CATEGORIES_RESPONSE:
      return {
        ...state,
        productCategories: action.payload,
      }

    case types.PRODUCT_CATEGORIES_CLEAR:
      return {
        ...state,
        productCategories: {},
      }
    case types.PRODUCT_CATEGORIES_DATA_RESPONSE:
      return {
        ...state,
        productCategoriesData: action.payload,
      }

    case types.PRODUCT_CATEGORIES_DATA_CLEAR:
      return {
        ...state,
        productCategoriesData: {},
      }
      case types.PRODUCT_SUB_CATEGORIES_DATA_RESPONSE:
        return {
          ...state,
          productSubCategoriesData: action.payload,
        }
  
      case types.PRODUCT_SUB_CATEGORIES_DATA_CLEAR:
        return {
          ...state,
          productSubCategoriesData: {},
        }
  
    case types.COURSES_LISTING_RESPONSE:
      return {
        ...state,
        coursesListing: action.payload,
      }

    case types.COURSES_LISTING_CLEAR:
      return {
        ...state,
        coursesListing: {},
      }
    case types.MODULE_LISTING_RESPONSE:
      return {
        ...state,
        moduleListing: action.payload,
      }

    case types.MODULE_LISTING_CLEAR:
      return {
        ...state,
        moduleListing: {},
      }

    case types.MODULE_NOTES_GET_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        moduleNotesGet: action.payload,
      }

    case types.MODULE_NOTES_GET_CLEAR:
      return {
        ...state,
        moduleNotesGet: {},
      }

    case types.MODULE_NOTES_UPDATE_RESPONSE:
      return {
        ...state,
        moduleNotesUpdate: action.payload,
      }

    case types.MODULE_NOTES_UPDATE_CLEAR:
      return {
        ...state,
        moduleNotesUpdate: {},
      }

    case types.MODULE_NOTES_DELETE_RESPONSE:
      // console.log('delete in reducer', action.payload)
      return {
        ...state,
        moduleNotesDelete: action.payload,
      }

    case types.MODULE_NOTES_DELETE_CLEAR:
      return {
        ...state,
        moduleNotesDelete: {},
      }
    case types.MODULE_FAV_GET_RESPONSE:
      return {
        ...state,
        moduleFavGet: action.payload,
      }

    case types.MODULE_FAV_GET_CLEAR:
      return {
        ...state,
        moduleFavGet: {},
      }
    case types.MODULE_FAV_DELETE_RESPONSE:
      // console.log('reducer', action.payload)
      return {
        ...state,
        moduleFavDelete: action.payload,
      }

    case types.MODULE_FAV_DELETE_CLEAR:
      return {
        ...state,
        moduleFavDelete: {},
      }
    case types.SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      }
    case types.FAVORITE_COURSES_RESPONSE:
      return {
        ...state,
        favoriteCourses: action.payload,
      }
    case types.FAVORITE_COURSES_CLEAR:
      return {
        ...state,
        favoriteCourses: {}
      }
    case types.SET_FAVORITE_COURSES_RESPONSE:
      return {
        ...state,
        setFavoriteCourses: action.payload,
      }

    case types.SET_FAVORITE_COURSES_CLEAR:
      return {
        ...state,
        setFavoriteCourses: {},
      }

    case types.REMOVE_FAVORITE_COURSES_RESPONSE:
      return {
        ...state,
        removeFavoriteCourses: action.payload,
      }

    case types.REMOVE_FAVORITE_COURSES_CLEAR:
      return {
        ...state,
        removeFavoriteCourses: {},
      }
    case types.SHOW_BUY_MODAL:
      return {
        ...state,
        showBuyModal: action.payload,
      }
    case types.LEARNING_PATH_RESPONSE:
      // console.log('learning path response', action.payload)
      return {
        ...state,
        learningPath: action.payload,
      }
    case types.LEARNING_PATH_CLEAR:
      return {
        ...state,
        learningPath: {},
      }
    case types.LEARNING_PATH_BROKER_RESPONSE:
      // console.log('learning path broker response', action.payload)
      return {
        ...state,
        learningPathBroker: action.payload,
      }
    case types.LEARNING_PATH_BROKER_CLEAR:
      return {
        ...state,
        learningPathBroker: {},
      }
    case types.OPEN_HAMBURGER:
      return {
        ...state,
        openHamburger: true,
      }
    case types.OPEN_HAMBURGER_CLEAR:
      return {
        ...state,
        openHamburger: false,
      }
    case types.COURSES_LISTING_RESPONSE_WOO:
      return {
        ...state,
        coursesListingWoo: action.payload,
      }

    case types.COURSES_LISTING_CLEAR_WOO:
      return {
        ...state,
        coursesListingWoo: {},
      }
    case types.COURSES_CATEGORIES_RESPONSE_BROKER:
      return {
        ...state,
        coursesCategoriesBroker: action.payload,
      }

    case types.COURSES_CATEGORIES_CLEAR_BROKER:
      return {
        ...state,
        coursesCategoriesBroker: {},
      }
    case types.COURSES_LISTING_RESPONSE_BROKER:
      return {
        ...state,
        coursesListingBroker: action.payload,
      }

    case types.COURSES_LISTING_CLEAR_BROKER:
      return {
        ...state,
        coursesListingBroker: {},
      }
    case types.MODULES_LISTING_RESPONSE_BROKER:
      return {
        ...state,
        moduleListingBroker: action.payload,
      }

    case types.MODULES_LISTING_CLEAR_BROKER:
      return {
        ...state,
        moduleListingBroker: {},
      }

    case types.ASSIGN_COURSE_RESPONSE:
      return {
        ...state,
        assignCourse: action.payload,
      }

    case types.ASSIGN_COURSE_CLEAR:
      return {
        ...state,
        assignCourse: {},
      }

    case types.ASSIGN_COURSE_BROKER_RESPONSE:
      return {
        ...state,
        assignBrokerCourse: action.payload,
      }

    case types.MARK_VIEW_RESPONSE:
      return {
        ...state,
        markView: action.payload,
      }

    case types.ASSIGN_COURSE_BROKER_CLEAR:
      return {
        ...state,
        assignBrokerCourse: {},
      }

    case types.MY_COURSES_RESPONSE:
      return {
        ...state,
        myCourses: action.payload,
      }

    case types.MY_COURSES_CLEAR:
      return {
        ...state,
        myCourses: {},
      }
    case types.MY_LEARNING_PATHS_RESPONSE:
      return {
        ...state,
        myLearningPaths: action.payload,
      }

    case types.MY_LEARNING_PATHS_CLEAR:
      return {
        ...state,
        myLearningPaths: {},
      }

    //TODO:
    case types.MASTER_CODE_OF_ETHICS_RESPONSE:
      return {
        ...state,
        ethicsData: action.payload,
      }
    case types.MASTER_CODE_OF_ETHICS_CLEAR:
      return {
        ...state,
        ethicsData: {},
      }
    case types.ETHICS_AGREE_CHECK_RESPONSE:
      return {
        ...state,
        ethicsAgreeData: action.payload,
      }
    case types.ETHICS_AGREE_CHECK_CLEAR:
      return {
        ...state,
        ethicsAgreeData: {},
      }
    case types.MARK_MODULE_PROGRESS_RESPONSE:
      return {
        ...state,
        moduleProgress: action.payload,
      }
    case types.MARK_MODULE_PROGRESS_CLEAR:
      return {
        ...state,
        moduleProgress: {},
      }
      case types.VIDEO_PROGRESS_RESPONSE:
        return {
          ...state,
          videoProgress: action.payload,
        }
      case types.VIDEO_PROGRESS_RESPONSE_CLEAR:
        return {
          ...state,
          videoProgress: {},
        }

      case types.LEARNING_PATH_RESPONSE_BROKER:
        return {
          ...state,
          learningPathListingBroker: action.payload,
        }
  
      case types.LEARNING_PATH_CLEAR_BROKER:
        return {
          ...state,
          learningPathListingBroker: {},
        }
    case types.GET_LEVEL_DETAIL_RESPONSE:
      return {
        ...state,
        getLevelDetail: action.payload,
      }
    case types.GET_LEVEL_DETAIL_RESPONSE_CLEAR:
      return {
        ...state,
        getLevelDetail: {},
      }
    ///
    case types.CHECKLIST_INSERT_RESPONSE:
      return {
        ...state,
        checkListInsert: action.payload,
      }
    case types.CHECKLIST_INSERT_RESPONSE_CLEAR:
      return {
        ...state,
        checkListInsert: {},
      }
    case types.LEARNING_PATHS_UNASSIGNED_RESPONSE:
      return {
        ...state,
        learningPathUnAssigned: action.payload,
      }
    case types.LEARNING_PATHS_UNASSIGNED_CLEAR:
      return {
        ...state,
        learningPathUnAssigned: {},
      }
    case types.SINGLE_LP_DATA_RESPONSE:
      return {
        ...state,
        lpData: action.payload,
      }
    case types.SINGLE_LP_DATA_CLEAR:
      return {
        ...state,
        lpData: {},
      }
    case types.COURSE_RELATED_RESPONSE:
      return {
        ...state,
        courseRelated: action.payload,
      }
    case types.COURSE_RELATED_CLEAR:
      return {
        ...state,
        courseRelated: {},
      }
    default:
      return state
  }
}
