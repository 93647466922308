const SideMenuComponent = () => {
  return (
    <div className="sidenav" id="navbar">
      <a href="#" className="closebtn" >&times;</a>
      <div className="user-show-sec">
        <img className="img-fluid user-dash" src="images/user-dash.png" />
        <div className="dash-user-info">
            <h5>Ronnie Woodkin</h5>
            <p>Accredited Buyer Representative, Calumet City, IL</p>
        </div>
      </div>
      
      <ul className="left-menu">
        <li><a className="" href="my-onboarding-first-time-user.html"><span><i className="handshake" aria-hidden="true"></i></span> Onboarding</a></li>
        <li><a className="" href="dashboard_new.html"><span><i className="dashboard" aria-hidden="true"></i></span> Dashboard</a></li>
        <li><a className="" href="notifications.html"><span><i className="notifications" aria-hidden="true"></i></span> Notifications</a></li>
        <li><a className="" href="my-learning-center.html"><span><i className="mylearningcenters" aria-hidden="true"></i></span> My learning Center</a></li>
        <li><a className="" href="my-event.html"><span><i className="myevents" aria-hidden="true"></i></span> My Events</a></li>
        <li className="active"><a className="" href="favorites.html"><span><i className="favorites" aria-hidden="true"></i></span> Favorites</a></li>
        <li><a className="" href="notes.html"><span><i className="notes" aria-hidden="true"></i></span> Notes</a></li>
        <li><a className="" href="network.html"><span><i className="mynetworks" aria-hidden="true"></i></span> My Network</a></li>
        <li><a className="" href="profile.html"><span><i className="settings" aria-hidden="true"></i></span> Edit Profile / Settings</a></li>
        <li><a className="" href="index.html"><span><i className="signout" aria-hidden="true"></i></span> Logout</a></li>
        <li><a className="" href="#"><span><i className="premium-ic" aria-hidden="true"></i> </span> Upgrade to Premium</a></li>
      </ul>
    </div>
  )
}

export default SideMenuComponent;