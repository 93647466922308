import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnyTotalReport } from "../../../redux/reports/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getAnyTotalReportClear,
  getProfileCompletionReport,
  getProfileCompletionReportClear,
  getLpTable,
  getLpTableClear,
  getLpRea,
  getLpReaClear,
  getLpManagers,
  getLpManagersClear,
  getLpOffices,
  getLpOfficesClear,
  getLpTrend,
  getLpTrendClear,
  getLpStats,
  getLpStatsClear,
} from "../../../redux/reports/actions";
import { getDateTime, SessionKeys } from "../../../utils/AppUtils";
import _ from "lodash";
import CONFIG from "../../../utils/config";
import PieChartComponent from "../ReportsComponents/PieChartComponent";
import ReportTableSectionComp from "../ReportsComponents/ReportTableSectionComp";
import { useHistory } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import ManagerReportSec from "../ReportsComponents/ManagerReportSec";
import OfficeSection from "../ReportsComponents/OfficeSection";
import Loader from "../../../components/Loader/Loader";
import CompletionTrendGraph from "../ReportsComponents/CompletionTrendGraph";
import { Spinner } from "react-bootstrap";
const LearningPathReportHomeSection = (props) => {

  const { isMobile } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [overCrsGraphData, setOverCrsGraphData] = useState([]);
  const [crsCompltTrendData, setCrsCompltTrendData] = useState([]);
  const [overAllData, setOverAllData] = useState([]);
  const [realEstateAgent, setRealEstateAgent] = useState([]);
  const [overAllManagersData, setOverAllManagersData] = useState([]);
  const [overAllLearningPoints, setOverAllLearningPoints] = useState([]);
  const [overAllLearningHours, setOverAllLearningHours] = useState([]);
  const [OverAllStatsData, setOverAllStatsData] = useState([]);
  const [isView, setIsView] = useState("table");
  const [type, setType] = useState("pie");
  const [canShow, setCanShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [limitData, setLimitData] = useState();
  const [imgLoader, setImgLoader] = useState(true);
  const [loadSpining, setLoadSpining] = useState(true)
  const [crsCompltTrendValues, setCrsCompltTrendValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const FILTER_BY = [
    { id: 0, value: "Select" },
    { id: 1, value: "Learning Points" },
    { id: 2, value: "Progress" },
    { id: 3, value: "Learning Hours" },
  ];
  // console.log("Thhh", overAllDataRes?.payload?.payload?.stats);
  const [statusFilterData] = useState([
    "completed",
    "in-progress",
    "not-started",
    "overdue",
  ]);
  const [profileReports, setProfileReports] = useState([]);
  const lpTableRes = useSelector((state) => state.report?.lpTable);
  const lpReaResponse = useSelector((state) => state.report?.lpReaRes);
  const lpManagersRes = useSelector((state) => state.report?.lpManagers);
  const lpTrendRes = useSelector((state) => state.report?.lpTrend);
  const lpStatsRes = useSelector((state) => state.report?.lpStats);
  const [totalRea, setTotalRea] = useState([])
  // const profileReportRes = useSelector((state) => state.report?.profileReport);
  // const anyTotalReportRes = useSelector(
  //   (state) => state.report?.anyTotalReport
  // );
  // const crsCompletionTrendRes = useSelector(
  //   (state) => state.report.complianceCompletionTrend
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);
  const [counter, setCounter] = useState(1);
  const [filterBy, setFilterBy] = useState(FILTER_BY[0].value);
  const [actualCoursesList, setActualCoursesList] = useState([]);
  const [limitStatus, setLimitStatus] = useState("")
  const [loadSpin, setLoadSpin] = useState(true)
  const [reaList, setReaList] = useState([])
  const [filterYearData, setFilterYearData] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [learningHrs,setLearningHrs] = useState("");
  const d = new Date();
  let year = d.getFullYear();
  const [trendYear, setTrendYear] = useState(year)
  const rea_ids = [];
  const office_ids = [];
  const lp_ids = [];
  const [selectedOfficeList] = useState(
    history?.location?.state?.selectedOfficeList
  );
  const [selectedRea] = useState(
    history?.location?.state?.selectedTeamMatesList
  );
  const [selectedLpList] = useState(
    history?.location?.state?.selectedLpList
  );
  let PageSize = 20;

  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }
  const userDetailRes = useSelector(state => state.user.userBroker)
  const userDetailIframe = useSelector(state => state.user.userDetailIframe)
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    // console.log('userDetailRes: ', userDetailRes)
    if (isMobile) {
      if (!_.isEmpty(userDetailIframe?.payload?.payload)) {
        setUserDetails(userDetailIframe?.payload?.payload)
      }
    } else {
      if (!_.isEmpty(userDetailRes?.payload?.payload)) {
        setUserDetails(userDetailRes?.payload?.payload)
      }
    }
  }, [userDetailRes, userDetailIframe, isMobile])
  useEffect(() => {
    selectedOfficeList.selectedOfficeList.forEach(function (obj) {
      office_ids.push(obj.office_id);
    });
    selectedRea.selectedTeamMatesList.forEach(function (obj) {
      rea_ids.push(obj.user_id);
    });
    selectedLpList.selectedLpList.forEach(function (obj) {
      lp_ids.push(obj.id);
    });
  });


  useEffect(() => {
    dispatch(getLpTable({
      module: 'getlPReportTable',
      postData: {
        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        status: ["completed", "in-progress", "not-started"],
        is_onboarding: 0,
        office_ids: office_ids,
        rea_ids: rea_ids,
        entity_id: lp_ids,
      }
    }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getLpRea({
      module: 'getlPReportRea',
      postData: {
        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        rea_ids: rea_ids,
        limit: counter,
        is_onboarding: 0,
        office_ids: office_ids,
        entity_id: lp_ids,
      }
    }))
    // setFilterBy(FILTER_BY[0].value);
  }, [dispatch, counter])
  useEffect(() => {
    setCounter(1)
    setRealEstateAgent([])
    return (() => {
      dispatch(getLpReaClear())
    })
  }, [])



  useEffect(() => {
    dispatch(getLpTrend({
      module: 'getlPReportTrend',
      postData: {
        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        office_ids: office_ids,
        rea_ids: rea_ids,
        is_onboarding: 0,
        entity_id: lp_ids,
        year: _.toNumber(trendYear),
      }
    }))
  }, [dispatch, trendYear])

  useEffect(() => {
    dispatch(getLpStats({
      module: 'getlPReportStats',
      postData: {
        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        office_ids: office_ids,
        rea_ids: rea_ids,
        is_onboarding: 0,
        entity_id: lp_ids,
      }
    }))
  }, [dispatch])

  //LP Table
  useEffect(() => {
    if (!_.isEmpty(lpTableRes)) {
      setIsLoaded(false);
      if (lpTableRes?.status === "success") {
        setIsLoading(false);
        let legends = [];
        let series = [];
        const reportData = lpTableRes?.payload?.payload?.user_data;
        setFilterData([...reportData]);
        setProfileReports(reportData);
        if (statusFilterData.includes("completed")) {
          let completedCount = reportData.filter((data) => data.status === "completed").length;
          series.push(
            reportData.filter((data) => data.status === "completed").length
          );
          legends.push(`Completed (${completedCount})`);
        }
        if (statusFilterData.includes("in-progress")) {
          let inprogressCount = reportData.filter((data) => data.status === "in-progress").length
          series.push(
            reportData.filter((data) => data.status === "in-progress").length
          );
          legends.push(`In Progress(${inprogressCount})`);
        }
        // if (statusFilterData.includes("overdue")) {
        //   let overdueCount = reportData.filter((data) => data.status === "overdue").length
        //   series.push(
        //     reportData.filter((data) => data.status === "overdue").length
        //   );
        //   legends.push(`Overdue(${overdueCount})`);
        // }
        if (statusFilterData.includes("not-started")) {
          let notStartedCount = reportData.filter((data) => data.status === "not-started").length;
          series.push(
            reportData.filter((data) => data.status === "not-started").length
          );
          legends.push(`Yet to start (${notStartedCount})`);
        }
        // if (legends.length < 4) {
        //   legends.push("Others");
        //   series.push(totalRecord - _.sum(series));
        // }
        setLegends(legends);
        setSeries(series);
        setCurrentPage(1);

      } else {
        setProfileReports([]);
        setCanShow(false);
        setIsLoading(false);
      }

      dispatch(getLpTableClear());

    }
  }, [lpTableRes])
  useEffect(() => {
    dispatch(
      getLpStatsClear());
  }, [dispatch])
  //LP Rea
  useEffect(() => {
    if (!_.isEmpty(lpReaResponse)) {
      setLoadSpin(false)
      if (lpReaResponse?.status === "success") {
        let allData = [];
        allData = sortByAlphabetical(lpReaResponse?.payload?.payload?.real_estate_agents);
        // let realEstateAgents = lpReaRes?.payload?.payload?.real_estate_agents;
        setTotalRea(lpReaResponse?.payload?.payload)
        let temparr = _.cloneDeep(realEstateAgent)
        if (counter === 1) {
          temparr = []
        }
        setReaList([...temparr, ...allData])
        setLimitStatus(lpReaResponse?.payload?.payload?.real_estate_agents)
        setRealEstateAgent([...temparr, ...allData]);
        setActualCoursesList([...temparr, ...allData]);
        dispatch(getLpReaClear())
      }
    }
  }, [lpReaResponse])



  useEffect(() => {
    // setCrsCompltTrendValues([])    
    return (() => {
      dispatch(getLpTrendClear())
    })
  }, [])
  //LP Completion trend
  useEffect(() => {
    if (!_.isEmpty(lpTrendRes)) {
      if (lpTrendRes?.status === "success") {
        setImgLoader(false)
        let allData = []
        allData = lpTrendRes?.payload?.payload?.completion_trend;
        setCrsCompltTrendValues(Object.values(allData));
        setFilterYearData(lpTrendRes?.payload?.payload?.year)
        dispatch(getLpTrendClear())
      }

    }
  }, [lpTrendRes])

  //LP Stats
  useEffect(() => {
    if (!_.isEmpty(lpStatsRes)) {
      if (lpStatsRes?.status === "success") {
        setIsLoaded(false);
        let allData = lpStatsRes?.payload?.payload?.stats;
        console.log(allData?.learning_hrs_val,"allData")
        setOverAllLearningPoints(allData)
        setLearningHrs(allData?.learning_hrs_val);
        
      }
      getLpStatsClear()
    }
  }, [lpStatsRes])

  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true);
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, profileReports]);
  // let min = Math.ceil(overAllLearningPoints?.learning_mins * 60);
  // filtration
  const sortByAlphabetical = (list) => {
    return list.sort((a, b) => b.learning_points - a.learning_points);
  };
  const sortByProgress = (list) => {
    return list.sort(
      (a, b) =>
        _.toNumber(b.learning_percentage) - _.toNumber(a.learning_percentage)
    );
  };
  const sortByLearningHrs = (list) => {
    return list.sort(
      (a, b) => _.toNumber(b.learning_hrs) - _.toNumber(a.learning_hrs)
    );
  };
  //REA SECTION
  const onClickFilterBy = (e) => {
    setFilterBy(e.target.value);
    if (e.target.value === "Learning Points") {
      const sortedList = sortByAlphabetical(actualCoursesList);
      setRealEstateAgent(sortedList);
    } else if (e.target.value === "Progress") {
      const sortedList = sortByProgress(actualCoursesList);
      setRealEstateAgent(sortedList);
    } else if (e.target.value === "Learning Hours") {
      const sortedList = sortByLearningHrs(actualCoursesList);
      setRealEstateAgent(sortedList);
    } else {
      setRealEstateAgent(reaList)
    }
  };

  useEffect(() => {
    if (counter > 1)
      sortAfterLoadMore();
  }, [actualCoursesList])

  const sortAfterLoadMore = () => {
    if (filterBy === "Learning Points") {
      const sortedList = sortByAlphabetical(actualCoursesList);
      setRealEstateAgent(sortedList);
    } else if (filterBy === "Progress") {
      const sortedList = sortByProgress(actualCoursesList);
      setRealEstateAgent(sortedList);
    } else if (filterBy === "Learning Hours") {
      const sortedList = sortByLearningHrs(actualCoursesList);
      setRealEstateAgent(sortedList);
    } else {
      setRealEstateAgent(reaList)
    }
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    console.log(value)
  };
  const filterDataSet = () => {
    if(searchTerm.length > 0) {
      console.log(searchTerm.length,"searchTerm.length")
      const filteredData = profileReports.filter((user) => {
        return user && user.full_name && user.full_name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      console.log(filteredData, "resultdataa1233");
        // setProfileReports(filteredData);
        setFilterData(filteredData);
    } else {
      // console.log(searchTerm.length,"searchTerm.length")
      setFilterData([...profileReports]);
      console.log(profileReports, "resultdataa");
    }
};

  return (
    <>
      <Loader start={isLoading} />
      <section className="onboarding-report">
        <div className="container">
          <div className="row">
            <div className="col-md-12 selctdrop-report">
              <div className="progess-report-title">Learning Path Report</div>
            </div>
          </div>
        </div>
      </section>

      <section className="progress-report-data">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-start mb-4">
              <button
                type="button"
                id="backbtnreport"
                className="btn btn-primary-complete bckbtn"
                onClick={() => {
                  history.goBack();
                  dispatch(
                    getLpReaClear(),
                    getLpStatsClear(),
                    getLpTrendClear()
                  );
                }}
              >
                Back
              </button>
            </div>
            {/* <p><strong>Last updated on  </strong>{getDateTime(totalRea?.last_updated, userDetails)}</p> */}
            <div className="col-md-12 col-lg-6 mb-4">
              <div className="graph-box-learnong">
                <PieChartComponent
                  type={type}
                  isView={isView}
                  legends={legends}
                  series={series}
                  setCanShow={setCanShow}
                  statusFilterData={statusFilterData}
                  totalRecord={totalRecord}
                  currentTableData={currentTableData}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 col-12 mb-4">
              <div className="progess-rpeort-content">
                <div className="proglefttext">Learning Points</div>
                <div className="prgcounttext">
                  {((overAllLearningPoints?.learning_points !== null) & (overAllLearningPoints?.learning_points !== " ")) ? overAllLearningPoints?.learning_points : 0}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 col-12 mb-4">
              <div className="progess-rpeort-content tiem">
                <div className="proglefttext">Learning Hours</div>
                <div
                  className={
                    overAllLearningPoints?.learning_hrs_value !== 0 & overAllLearningPoints?.learning_hrs_value !== null & overAllLearningPoints?.learning_hrs_value !== ""
                      ? "prgcounttext tiem"
                      : (overAllLearningPoints?.learning_hrs_value === null || overAllLearningPoints?.learning_hrs_value === 0 || overAllLearningPoints?.learning_hrs_value === "") ? "prgcounttext" : ""
                  }
                >
                  {/* {overAllLearningPoints?.learning_hrs !== 0 &&
                    overAllLearningPoints?.learning_mins !== 0 ? (
                    <span>
                      <span className={(overAllLearningPoints?.learning_hrs === 0) ? "d-none" : ""}>
                        {(overAllLearningPoints?.learning_hrs > 0) ? `${overAllLearningPoints?.learning_hrs}` : ""}
                      </span>
                      &nbsp;
                      <span className={(overAllLearningPoints?.learning_hrs === 0) ? "d-none" : ""}>
                        {overAllLearningPoints?.learning_hrs > 1
                          ? "Hrs"
                          : overAllLearningPoints?.learning_hrs > 0
                            ? "Hr"
                            : ""}
                      </span>
                      &nbsp;
                      <span>{overAllLearningPoints?.learning_mins > 0 ? `${overAllLearningPoints?.learning_mins}` : ""}</span>&nbsp;
                      <span>{overAllLearningPoints?.learning_mins > 1 ? "Mins" : overAllLearningPoints?.learning_mins > 0 ? "Min" : 0}</span>
                    </span>
                  ) : overAllLearningPoints?.learning_hrs !== 0 &&
                    overAllLearningPoints?.learning_mins === 0 ? (
                    <span>
                      <span>
                        {overAllLearningPoints?.learning_hrs > 0
                          ? `${overAllLearningPoints?.learning_hrs}`
                          : ""}
                      </span>
                      &nbsp;
                      <span>
                        {overAllLearningPoints?.learning_hrs > 1
                          ? "Hrs"
                          : overAllLearningPoints?.learning_hrs > 0
                            ? "Hr"
                            : ""}
                      </span>
                    </span>
                  ) : overAllLearningPoints?.learning_hrs === 0 &&
                    overAllLearningPoints?.learning_mins !== 0 ? (
                    <span>
                      <span>{overAllLearningPoints?.learning_mins > 0 ? `${overAllLearningPoints?.learning_mins}` : ""}</span>&nbsp;
                      <span>{overAllLearningPoints?.learning_mins > 1 ? "Mins" : overAllLearningPoints?.learning_mins > 0 ? "Min" : 0}</span>
                    </span>
                  ) : (
                    overAllLearningPoints?.learning_hrs
                  )} */}
                  {/* {overAllLearningPoints?.learning_hrs_value} */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                      ((learningHrs))
                        // ((overAllLearningPoints?.learning_hrs_value !== null) || (overAllLearningPoints?.learning_hrs_value !== " ")) ? overAllLearningPoints?.learning_hrs_value : 0
                      ,
                    }}
                  ></p>
                  {/* <p>
                    {learningHrs}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="progress-box-report">
        <div className="container">
          <div className="row">
            <div className="col-md-12 flex-titlereport d-flex justify-content-between align-items-baseline">
              <div className="progess-count-tiel">
                Real Estate Agents &nbsp;
                <strong>({totalRea?.total_rea
                })</strong>
              </div>
              <div className="input-text fliterreportsgen">
                <label htmlFor="filter" className="input__label">
                  Sort By
                </label>
                <select
                  type="text"
                  id="filter"
                  name="filter"
                  className="form-select input_searchfield mb-0 session-filter"
                  value={filterBy}
                  onChange={onClickFilterBy}
                >
                  {FILTER_BY.map((item, index) => {
                    return (
                      <option seleted key={index}>
                        {item.value}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {(!_.isEmpty(realEstateAgent)) ? realEstateAgent?.map((m, index) => {
              return (
                <div className="col-md-6 col-lg-4 col-xl-3 col-12 mb-report" key={index}>
                  <div className="reopor-box-progess">
                    <div className="progess-profile d-flex">
                      <div className="user-progess-img">
                        <img src={m?.profile_pic} width="48" height="48" />
                      </div>
                      <div className={`userprogress-details ${(m?.is_allow === true) ? "cursor-pointer" : "cursor-not-allowed"}`}>
                        <h5
                          className="user-tiele"
                          onClick={() => {
                            if (m?.is_allow === true) {
                              history.push(`/profile/edit?section=edit&user_id=${m?.uid}&isOther=1`);
                            }
                          }}
                        >
                          {m?.user_name}
                        </h5>
                        <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <div
                                            style={{
                                                color: "white",
                                                backgroundColor: "black",
                                            }}
                                            
                                        >{m?.office_name}</div>
                                    </Tooltip>
                                }
                            >
                        <p >{m?.office_name}</p>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <hr />
                    <div className="progress-details">
                      <div className="progess-report">
                        <div className="circlebox">
                          <CircularProgressbar value={m?.learning_percentage} />
                        </div>
                        <div className="progess-text-repfot">
                          {`${m?.learning_percentage}%`}
                          <div className="progespte">Progress</div>
                        </div>
                      </div>
                      <div className="progess-count-show">
                        <div className="learning-points">
                          <h5>Learning Points</h5>
                          <div className="learningc-countshow">
                            {m?.learning_points}
                          </div>
                        </div>
                        <div className="learning-points ">
                          <h5>Learning Hours</h5>
                          <div className="learningc-countshow">
                            {/* {Math.floor(m?.learning_hrs) > 0
                              ? `${Math.floor(m?.learning_hrs)}`
                              : ""}

                            {Math.floor(m?.learning_hrs) > 1
                              ? "Hrs"
                              : Math.floor(m?.learning_hrs) > 0
                                ? "Hr"
                                : ""}

                            {Math.ceil(
                              (m?.learning_hrs - Math.floor(m?.learning_hrs)) *
                              60
                            ) > 0
                              ? Math.ceil(
                                (m?.learning_hrs -
                                  Math.floor(m?.learning_hrs)) *
                                60
                              )
                              : ""}

                            {Math.ceil(
                              (m?.learning_hrs - Math.floor(m?.learning_hrs)) *
                              60
                            ) > 1
                              ? "Mins"
                              : Math.ceil(
                                (m?.learning_hrs -
                                  Math.floor(m?.learning_hrs)) *
                                60
                              ) > 0
                                ? "Min"
                                : m?.learning_hrs} */}
                            {/* {m?.learning_hrs_val} */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: m?.learning_hrs_val,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
              :
              <div className="col-md-12">
                <div className="not-found mt-3">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>The REA's that are assigned for Learning Paths will be shown on this section.</p>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-12 mt-3">
              <p className="text-center">
                {realEstateAgent?.length === 0 || (_.toNumber(totalRea?.total_rea) === realEstateAgent?.length) ? "" : (_.toNumber(totalRea?.total_rea) < 4) ? "" :
                  <button
                    type="button"
                    id="number"
                    className="btn btn-primary-complete"
                    onClick={() => {
                      setCount(counter, setCounter);
                      setLoadSpin(true);
                    }
                    }
                  >
                    {(loadSpin) ?
                      <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" />
                      </div>
                      : "Load More"}
                  </button>
                }
              </p>
            </div>
          </div>
        </div>
      </section>
      <ManagerReportSec
        mesg={"The Managers that are assigned for Learning Paths will be shown on this section."}
        section={"lp"}
        isMobile={isMobile}
      />
      {/* Offices Section */}
      <OfficeSection
        mesg={"The offices of Managers and assigned REA's will be displayed in this section."}
        section={"lp"}
        isMobile={isMobile}
      />
      <section className="report-graphname">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="report-graph-trend">
                <div className="input-text fliterreportsgen graphselct">
                  <label for="filter" className="input__label">Select Year</label>
                  <select type="text" id="filter" name="filter" className="form-select input_searchfield mb-0 session-filter"
                    onChange={(e) => {
                      const selectedYear = e.target.value;
                      setTrendYear(selectedYear)
                      setImgLoader(true)
                    }}
                  >
                    {filterYearData?.map((item, index) => {
                      return (
                        <option seleted key={index} value={item[index]}
                        >{item}</option>
                      )
                    })}
                  </select>
                </div>
                {imgLoader ? (
                  <div className="d-flex justify-content-center w-100">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-start gap-5 mob-res w-100">
                    <CompletionTrendGraph
                      crsCompltTrendData={crsCompltTrendValues}
                      completionTrendLabel={"Learning Paths Completion"}
                      crsCompltTrend={filterYearData}
                      setTrendYear={setTrendYear}
                      trendYear={trendYear}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container progress_data">
     <div className="input-text position-relative d-flex" >
                <label htmlFor="search" className="input__label">
                  Search
                </label>
                <input
                  type="text"
                  id="search"
                  name="search"
                  className="input_field mr-2"
                  placeholder=" "
                  value={searchTerm}
                  onChange={handleInputChange}
                
                 
                />
                <button
                  className="button-reset"
                  style={{ marginTop: "-20px" }}
                 onClick={filterDataSet}
                  
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    id="Find"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="0.5"
                        x2="1"
                        y2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stopColor="#00b7f1" />
                        <stop offset="1" stopColor="#ed156e" />
                      </linearGradient>
                    </defs>
                    <circle
                      id="Ellipse_191"
                      data-name="Ellipse 191"
                      cx="15"
                      cy="15"
                      r="15"
                      fill="url(#linear-gradient)"
                    />
                    <circle
                      id="Ellipse_192"
                      data-name="Ellipse 192"
                      cx="13"
                      cy="13"
                      r="13"
                      transform="translate(2 2)"
                      fill="#fff"
                    />
                    <path
                      id="Icon_awesome-search"
                      data-name="Icon awesome-search"
                      d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                      transform="translate(7.715 7.714)"
                      fill="var(--color-element-1)"
                    />
                  </svg>
                </button>
              </div>
              </div>

      <ReportTableSectionComp
        setSearchInput={""}
        getFilteredData={[]}
        lpName={"Learning Path"}
        isLearningPath={true}
        isView={isView}
        statusFilterData={statusFilterData}
        setCanShow={setCanShow}
        profileReports={filterData}
        currentTableData={currentTableData}
        type={type}
        message={"Learning Paths that are assigned to users, will appear here."}
      />
    </>
  );
};

export default LearningPathReportHomeSection;
