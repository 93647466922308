import axios from "axios";
import data from "../data/product";
import { SessionKeys } from "../utils/AppUtils";

import CONFIG from "../utils/config";
import { objectToFormData } from "./data";

export const productDetail = async (payload) => {
  // console.log(payload)
  const formData = objectToFormData(payload);
  return {
    status: "success",
    payload: data.productDetail,
  };
  return axios({
    method: "post",
    url: CONFIG.API_URL + "",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      // console.log(response)
      if (response.data.status) {
        return {
          status: "success",
          payload: {
            productDetail: response.data.data,
          },
        };
      } else {
        return {
          status: "error",
          error: {
            message: response.data.message,
          },
        };
      }
    })
    .catch((error) => {
      return {
        status: "error",
        error,
      };
    });
};

export const recommendProducts = async (payload) => {
  // console.log(payload)
  const formData = objectToFormData(payload);
  return {
    status: "success",
    payload: data.recommendProducts,
  };
  return axios({
    method: "post",
    url: CONFIG.API_URL + CONFIG.API_ROUTES.LOGIN,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      // console.log(response)
      if (response.data.status) {
        return {
          status: "success",
          payload: {
            recommendProducts: response.data.data,
          },
        };
      } else {
        return {
          status: "error",
          error: {
            message: response.data.message,
          },
        };
      }
    })
    .catch((error) => {
      return {
        status: "error",
        error,
      };
    });
};
