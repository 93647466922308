import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import VideoCoursesListingCard from "../../pages/videoCourses/VideoCoursListingCard";
import { getSelectedCatId, setFavoriteCourses } from "../../redux/courses/actions";
import { searchData, searchClear } from "../../redux/search/actions";
import { convertHoursToMins, convertSecondsToMins, convertTimeFormat, SessionKeys } from "../../utils/AppUtils";
import CourseCardListBody from "../Course/CourseCardListBody";
import BrokerEventListSectionCard from "../Events/BrokerEventListSectionCard";
import SliderMyLearningPathListing from "../LearningPaths/SliderMyLearningPathListing";
import Loader from "../Loader/Loader";
import { VideoCoursesDataSetupSection } from "../Video/VideoCoursesDataSetupSection";
import CertifySearchSection from "./CertifySearchSection";

const SearchSection = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const searchInfo = useSelector(state => state.search.searchData);
    const isPremiumUser = useSelector(state => state.user.isPremiumUser);
    const authBroker = useSelector(state => state.auth.authBroker)

    const [searchWord, setSearchWord] = useState(params.get('keyword'));
    const [searchInput, setSearchInput] = useState(params.get('keyword'));
    const [searchLookup, setSearchLookup] = useState(false);

    const [courseList, setCourseList] = useState([]);
    const [learningPathList, setLearningPathList] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [allVideo, setAllVideo] = useState([]);
    const [allCertify, setAllCertify] = useState([]);

    const [showModalMsg, setShowModalMsg] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [closeChildModal, setCloseChildModal] = useState(false);
    const [startFlag, setStartFlag] = useState(false);

    const [courseAvailable, setCourseAvailable] = useState(false);
    const [lpAvailable, setLpAvailable] = useState(false);
    const [eventAvailable, setEventAvailable] = useState(false);
    const [videoAvailable, setVideoAvailable] = useState(false);
    const [certifyAvailable, setCertifyAvailable] = useState(false);
    const [isNoData, setIsNoData] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const sortByAlphabetical = (list) => {
        return list.sort((a, b) =>
            b.published_date.localeCompare(a.published_date)
        );
    };
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [dispatch])

    useEffect(() => {
        if (params.get('keyword') && params.get('keyword') !== "" && params.get('keyword') !== "null") {
            setSearchWord(params.get('keyword'))
            setSearchInput(params.get('keyword'))
        } else {
            // setErrorMsg("Please enter a search term before pressing submit.")
        }
    }, [params.get('keyword')])



    useEffect(() => {
        // console.log(searchWord)
        if (searchWord && searchWord !== "" && searchWord !== "null") {
            setSearchLookup(true)
            setIsLoading(true)
            setCourseList([])
            // dispatch(searchData({
            //     module: 'getCoursesBySearch',
            //     postData: {
            //         token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            //         search: searchInput
            //     }
            // }))

            dispatch(getSelectedCatId("wishlist"))

            if (!_.isEmpty(authBroker)) {
                dispatch(searchData({
                    ecom: {
                        module: 'getCoursesBySearch',
                        broker: false,
                        postData: {
                            search: searchInput,
                            email: authBroker?.payload?.user?.email,
                            company_id: authBroker?.payload?.user.company_id,
                            userId: localStorage.getItem(SessionKeys.USER_ID)
                        }
                    },
                    broker: {
                        module: 'getCoursesBySearch',
                        broker: true,
                        postData: {
                            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                            search: searchInput
                        }
                    }
                }))
            }
        }
    }, [searchWord, authBroker])

    useEffect(() => {
        // console.log('searchInfo: ', searchInfo)
        if (!_.isEmpty(searchInfo)) {
            setIsLoading(false)
            setCourseAvailable(false);
            setLpAvailable(false);
            setEventAvailable(false);
            setVideoAvailable(false);
            setCertifyAvailable(false);

            let noData = true;


            if (searchInfo[1]?.status === 'success') {
                if (searchInfo[1]?.payload?.payload?.courses?.length > 0 || searchInfo[0]?.payload?.payload?.courses?.length > 0) {
                    noData = false;
                    setCourseAvailable(true)
                    let coursesList = [...searchInfo[1]?.payload?.payload?.courses, ...getWithShowBuy(searchInfo[0]?.payload?.payload?.courses)]
                    for (let course of coursesList) {
                        if (course.sfdc_product_type.toLowerCase() !== 'bundle') {
                            course.isLock = false;
                            if (course.durationUnit === 'Hours') {
                                course.duration = convertHoursToMins(course.duration);
                            }
                            course.duration = convertTimeFormat(_.toNumber(course.duration));
                            course.durationUnit = ''
                        } else {
                            // console.log('Duration', course)
                            for (let cl of course.course_list) {
                                if (cl.durationUnit === 'Hours') {
                                    cl.duration = convertHoursToMins(cl.duration);
                                }
                                cl.duration = convertTimeFormat(_.toNumber(cl.duration))
                                cl.durationUnit = ''
                                cl.isLock = false;
                            }
                        }
                    }
                    setCourseList(coursesList)
                }
                if (searchInfo[1].payload.payload.learning_paths.length > 0) {
                    noData = false;
                    setLpAvailable(true)
                    setLearningPathList(searchInfo[1].payload.payload.learning_paths)
                }
                if ((searchInfo[1]?.payload?.payload?.events && searchInfo[1]?.payload?.payload?.events?.length > 0) || searchInfo[0]?.payload?.payload?.events?.length > 0) {
                    noData = false;
                    setEventAvailable(true)
                    setAllEvents([...searchInfo[1]?.payload?.payload?.events, ...searchInfo[0]?.payload?.payload?.events])
                }
                if (( searchInfo[0]?.payload?.payload?.events && searchInfo[0].payload.payload.videos.length > 0) || searchInfo[1]?.payload?.payload?.videos?.length > 0) {
                    noData = false;
                    setVideoAvailable(true)
                    let videoListing = searchInfo[0].payload.payload.videos

                    console.log('bundle: ', videoListing)
                    let sortedData = [];
                    // // let searchData = []
                    for (let videodata of searchInfo) {
                        if (videodata.status === "success") {
                            setIsLoading(false);
                            let VidData = _.isArray(videodata.payload.payload.videos)
                                ? videodata.payload.payload.videos
                                : [];
                            sortedData = sortByAlphabetical([...VidData, ...sortedData]);
                            // let sortedData = sortByAlphabetical(arr);
                            if (!_.isEmpty(sortedData)) {
                                sortedData = VideoCoursesDataSetupSection(sortedData, isPremiumUser)
                                // for (let course of sortedData) {
                                //     course.is_purchased = course.is_paid;
                                //     course.showBuy = true;
                                //     course.is_bundle = false;
                                //     course.is_free = false;
                                //     if (course.source != "xsel") {
                                //         course.is_paid = true;
                                //         course.is_purchased = false;
                                //     }
                                //     if (course.sfdc_product_type.toLowerCase() === "basic") {
                                //         course.showBuy = false;
                                //         course.is_addtocart = false;
                                //         course.is_paid = true;
                                //         course.is_purchased = false;
                                //     } else if (course.sfdc_product_type.toLowerCase() === "premium") {
                                //         if (isPremiumUser) {
                                //             course.showBuy = false;
                                //             course.is_paid = true;
                                //             course.is_free = true;
                                //         } else {
                                //             if (course.product_offer_for_basic) {
                                //                 course.sale_price = 0.0;
                                //                 course.regular_price = 0.0;
                                //             } else {
                                //                 course.sale_price = course.product_basic_price;
                                //                 course.regular_price = course.product_market_price;
                                //             }
                                //         }
                                //     } else if (
                                //         course.sfdc_product_type.toLowerCase() === "alacarte"
                                //     ) {
                                //         if (isPremiumUser) {
                                //             if (course.product_offer_for_premium) {
                                //                 course.sale_price = 0.0;
                                //                 course.regular_price = 0.0;
                                //             } else {
                                //                 course.sale_price = course.product_premium_price;
                                //             }
                                //         } else {
                                //             if (course.product_offer_for_basic) {
                                //                 course.sale_price = 0.0;
                                //                 course.regular_price = 0.0;
                                //             } else {
                                //                 course.sale_price = course.product_basic_price;
                                //             }
                                //         }
                                //         course.regular_price = course.product_market_price;
                                //     } else if (
                                //         course.sfdc_product_type.toLowerCase() === "bundle"
                                //     ) {
                                //         course.is_bundle = true;
                                //         for (let video of course.list) {
                                //             video.source = course.source
                                //             video.is_free = course.is_free
                                //             video.is_paid = course.is_paid
                                //             video.product_id = video.id
                                //             video.course_code = video.crscd
                                //         }
                                //     }

                                //     if (course.sfdc_product_type.toLowerCase() !== "bundle") {
                                //         if (
                                //             course.durationUnit === "Second(s)" &&
                                //             course.course_duration >= 60
                                //         ) {
                                //             if (course.course_duration % 60) {
                                //                 course.course_duration = convertTimeFormat(
                                //                     course.course_duration,
                                //                     true
                                //                 );
                                //             } else {
                                //                 course.course_duration = convertSecondsToMins(
                                //                     course.course_duration
                                //                 );
                                //                 course.course_duration = convertTimeFormat(
                                //                     course.course_duration
                                //                 );
                                //             }
                                //             course.durationUnit = "";
                                //         } else if (
                                //             course.durationUnit === "Second(s)" &&
                                //             course.course_duration < 60
                                //         ) {
                                //             course.course_duration = `${course.course_duration} Sec${course.course_duration > 1 ? "s" : ""
                                //                 }`;
                                //             course.durationUnit = "";
                                //         } else if (course.durationUnit === "Hours") {
                                //             course.course_duration = convertHoursToMins(
                                //                 course.course_duration
                                //             );
                                //             course.course_duration = convertTimeFormat(
                                //                 course.course_duration
                                //             );
                                //             course.durationUnit = "";
                                //         }
                                //     } else {
                                //         for (let cl of course.list) {
                                //             if (
                                //                 cl.durationUnit === "Second(s)" &&
                                //                 cl.duration >= 60
                                //             ) {
                                //                 if (cl.duration % 60) {
                                //                     cl.duration = convertTimeFormat(
                                //                         cl.duration,
                                //                         true
                                //                     );
                                //                 } else {
                                //                     cl.duration = convertSecondsToMins(
                                //                         cl.duration
                                //                     );
                                //                     cl.duration = convertTimeFormat(
                                //                         cl.duration
                                //                     );
                                //                 }
                                //                 cl.durationUnit = "";
                                //             } else if (
                                //                 cl.durationUnit === "Second(s)" &&
                                //                 cl.duration < 60
                                //             ) {
                                //                 cl.duration = `${cl.duration} Sec${cl.duration > 1 ? "s" : ""
                                //                     }`;
                                //                 cl.durationUnit = "";
                                //             } else if (cl.durationUnit === "Hours") {
                                //                 cl.duration = convertHoursToMins(
                                //                     cl.duration
                                //                 );
                                //                 cl.duration = convertTimeFormat(
                                //                     cl.duration
                                //                 );
                                //                 cl.durationUnit = "";
                                //             }
                                //             // if (cl.durationUnit === "Hours") {
                                //             //   cl.duration = convertHoursToMins(cl.duration);
                                //             // }
                                //             // cl.duration = convertTimeFormat(_.toNumber(cl.duration));
                                //             // cl.durationUnit = "";
                                //         }
                                //     }
                                // }
                            }
                            // setCoursesList(sortedData);
                            // setActualCoursesList(sortedData);
                        }
                    }
                    setAllVideo([...searchInfo[1].payload.payload.videos, ...videoListing])
                }
                if (searchInfo[0].payload?.payload?.certify && searchInfo[0].payload.payload.certify.length > 0) {
                    noData = false;
                    setCertifyAvailable(false) //will only need to true, when this feature will be on in future
                    setAllCertify(searchInfo[0].payload.payload.certify)
                }

                setSearchWord(searchInput);
                setSearchLookup(false)

                if (noData) {
                    setIsNoData(true)
                } else {
                    setIsNoData(false)
                }


            } else {
                setSearchWord(searchInput);
                setSearchLookup(false)
            }
            dispatch(searchClear())
        }
    }, [searchInfo])




    const getWithShowBuy = (list) => {
        for (let course of list) {
            course.showBuy = true;
            course.is_bundle = false;
            course.is_free = false;
            if (course?.sfdc_product_type?.toLowerCase() === 'basic') {
                course.showBuy = false;
                course.is_paid = true;
                course.is_free = true;
            } else if (course?.sfdc_product_type?.toLowerCase() === 'premium') {
                if (isPremiumUser) {
                    course.showBuy = false;
                    course.is_paid = true;
                    course.is_free = true;
                } else {
                    if (course.product_offer_for_basic) {
                        course.sale_price = 0.00
                        course.regular_price = 0.00
                    } else {
                        course.sale_price = course.product_basic_price;
                        course.regular_price = course.product_market_price;
                    }
                }
            } else if (course?.sfdc_product_type?.toLowerCase() === 'alacarte') {
                if (isPremiumUser) {
                    if (course.product_offer_for_premium) {
                        course.sale_price = 0.00
                        course.regular_price = 0.00
                    } else {
                        course.sale_price = course.product_premium_price
                    }
                } else {
                    if (course.product_offer_for_basic) {
                        course.sale_price = 0.00
                        course.regular_price = 0.00
                    } else {
                        course.sale_price = course.product_basic_price;
                    }
                }
                course.regular_price = course.product_market_price;
            } else if (course?.sfdc_product_type?.toLowerCase() === 'bundle') {
                course.is_bundle = true;
            }
        }

        return list;
    }

    const setFavorite = (id) => {
        // console.log('setFAV_ID', id)
        // return;
        const postData = {
            course_id: {
                _id: {
                    $oid: id
                },
                bkmrk: true,
                type: 'course'
            }
        }
        // dispatch(setFavoriteCourses({
        //     module: 'courseFavorite',
        //     token: localStorage.getItem(SessionKeys.API_TOKEN),
        //     postData
        // }));
    }

    const searchValue = () => {
        setErrorMsg('')
        if (searchInput === null || searchInput === '') {
            setErrorMsg("Please enter a search term.")
            setCourseList([])
            setLearningPathList([])
            setAllEvents([])
            setAllVideo([])
            setAllCertify([])
            setIsNoData(true)
            setSearchWord('')
            return;
        }
        history.push(`/search?keyword=${searchInput}`)
    }

    return (
        <>
            <Loader start={isLoading} />
            <section className="my-credit-points searchpage">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-md-12 col-sm-12">
                            <div className="search_heading profile-left-sec">

                                <p className="lable-heading_search">{
                                    searchLookup ? 'Looking for Result of' : 'Search Result for'
                                }</p>
                            </div>
                            <div className={`sHeadg profile-left-sec ${searchWord ? '' : 'mt-3'}`}>
                                {searchWord && <p className="sub_hedgf">‘{searchWord}’</p>}
                            </div>
                            <div className="profile-left-sec">
                                <div className="input-text position-relative d-flex">
                                    <label htmlFor="search" className="input__label">Search</label>
                                    <input type="text" id="search" name="search"
                                        className="input_field mr-2" placeholder=" "
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    <button className="button-reset" style={{ marginTop: "-20px" }} onClick={() => { searchValue() }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Find" width="30" height="30" viewBox="0 0 30 30">
                                            <defs>
                                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                    <stop offset="0" stop-color="#00b7f1" />
                                                    <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                            </defs>
                                            <circle id="Ellipse_191" data-name="Ellipse 191" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                            <circle id="Ellipse_192" data-name="Ellipse 192" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                            <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z" transform="translate(7.715 7.714)" fill="var(--color-element-1)" />
                                        </svg>
                                    </button>
                                </div>
                                <p className="error-msg">{errorMsg}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start of Course Block */}
                {isNoData ? <div className="container">
                    <div className="not-found mt-3">
                        <div className=" interboxes">
                            <img src="/images/telescope.svg" alt="Not Found" />
                            <p>Sorry! No result found for your search.</p>
                        </div>
                    </div>
                </div> : <div>

                    {courseAvailable ?
                        <div className="container">
                            <div className="row profile-left-sec">
                                <div className="view-button mt-4">
                                    <p className="lablesech">Courses</p>
                                </div>
                                {
                                    courseList.length > 0 ?
                                        <CourseCardListBody
                                            coursesList={courseList}
                                            pageType={'course'}
                                            setFavoriteCourses={setFavorite}
                                            closeModal={closeChildModal}
                                            setCloseModal={setCloseChildModal}
                                            hasDescription={false}
                                        />
                                        :
                                        <div className="mb-5">
                                            {searchLookup ? '...' : 'No courses found'}</div>
                                }
                            </div>
                        </div> : ""}
                    {lpAvailable ? <div className="center_line mt-3"></div> : ""}
                    {lpAvailable ? <div className="container">
                        <div className="row profile-left-sec">
                            <div className="view-button mt-4">
                                <p className="lablesech">Learning Paths</p>
                            </div>
                            <div className="mb-5">
                                {searchLookup ? '...' :

                                    learningPathList.length > 0 ?
                                        <SliderMyLearningPathListing
                                            isOnboarding={false}
                                            learningPathList={learningPathList}
                                            setFavoriteCourse={setFavorite}
                                            closeModal={closeChildModal}
                                            setCloseModal={setCloseChildModal}
                                            hasDescription={false}
                                            pageType="learningPath"
                                            showBlankCard={true}
                                            learningType={''}
                                        />
                                        :
                                        `<div className="not-found">
                                    <img src="/images/telescope.svg" alt="Not Found" />
                                    <p>No learning paths yet.</p>
                            </div> `

                                }
                            </div>

                        </div>
                    </div> : ""}
                    {videoAvailable ? <div className="center_line mt-3"></div> : ""}

                    {videoAvailable ? <div className="container">
                        <div className="row profile-left-sec">
                            <div className="view-button mt-4">
                                <p className="lablesech">Videos</p>
                            </div>
                            <div className="mb-5">
                                {searchLookup ? '...' : <VideoCoursesListingCard coursesList={allVideo} />}
                            </div>


                        </div>
                    </div> : ""}

                    {eventAvailable ? <div className="center_line mt-3"></div> : ""}

                    {eventAvailable ? <div className="container">
                        <div className="row profile-left-sec">
                            <div className="view-button mt-4">
                                <p className="lablesech">Events</p>
                            </div>
                            <div className="mb-5">
                                {searchLookup ? '...' :
                                    allEvents.length > 0 ? <BrokerEventListSectionCard eventsAll={allEvents} /> : "No events found."}
                            </div>


                        </div>
                    </div> : ""}

                    {certifyAvailable ? <div className="center_line mt-3"></div> : ""}

                    {certifyAvailable ? <div className="container">
                        <div className="row profile-left-sec">
                            <div className="view-button mt-4">
                                <p className="lablesech">Certifications</p>
                            </div>
                            <div className="mb-5">
                                {searchLookup ? '...' : allCertify.length > 0 ? <CertifySearchSection eventList={allCertify} /> : "No certify found"}
                            </div>


                        </div>
                    </div> : ""}

                </div>}

            </section>
        </>
    )
}

export default SearchSection;