import data from '../../data/product'
import types from './types'

const initialState = {
  productDetail: {},
  recommendProducts: {},
  courseDetail: {},
  addToCart: {},
  orderDetail: {},
  getWishList: {},
  delWishList: {}

}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.PRODUCT_DETAIL_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        productDetail: action.payload
      }
    case types.PRODUCT_DETAIL_CLEAR:
      return {
        ...state,
        productDetail: {}
      }

    case types.RECOMMEND_PRODUCT_RESPONSE:
      return {
        ...state,
        recommendProducts: action.payload
      }

    case types.RECOMMEND_PRODUCT_CLEAR:
      return {
        ...state,
        recommendProducts: {}
      }
    case types.COURSE_DETAIL_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        courseDetail: action.payload
      }
    case types.COURSE_DETAIL_CLEAR:
      return {
        ...state,
        courseDetail: {}
      }
    case types.ADD_TO_CART_RESPONSE:
      return {
        ...state,
        addToCart: action.payload
      }
    case types.ADD_TO_CART_CLEAR:
      return {
        ...state,
        addToCart: {}
      }
    case types.GET_ORDER_DETAIL_RESPONSE:
      return {
        ...state,
        orderDetail: action.payload
      }
    case types.GET_ORDER_DETAIL_RESPONSE_CLEAR:
      return {
        ...state,
        orderDetail: {}
      }
    case types.ADD_TO_WISHLIST_RESPONSE:
      return {
        ...state,
        addToWishList: action.payload
      }
    case types.ADD_TO_WISHLIST_CLEAR:
      return {
        ...state,
        addToWishList: {}
      }
    case types.GET_WISHLIST_RESPONSE:
      return {
        ...state,
        getWishList: action.payload
      }
    case types.GET_WISHLIST_RESPONSE_CLEAR:
      return {
        ...state,
        getWishList: {}
      }
      case types.DELETE_WISHLIST_RESPONSE:
        return {
          ...state,
          delWishList: action.payload
        }
      case types.DELETE_WISHLIST_RESPONSE_CLEAR:
        return {
          ...state,
          delWishList: {}
        }
    default:
      return state
  }
}



