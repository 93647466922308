
import { SessionKeys } from "../../utils/AppUtils";
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import axios, { post } from "axios";
import { Input, FormGroup } from "reactstrap";
import { useEffect, useState } from "react";
import CONFIG from "../../utils/config";
import { onUploadReq, onUploadReqBroker, userDetail, userDetailBroker } from "../../redux/user/actions";
import ReactRoundedImage from "react-rounded-image";
import { loginResponse } from "../../redux/auth/actions";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
const SideNav = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    var userDetailRes = useSelector(state => state.user.userBroker)
    var uploadRes = useSelector(state => state.user.uploadRes)
    var auth = useSelector(state => state.auth.authBroker)
    const certificateRes = useSelector(state => state.certified.getCertificates)
    const badgeRes = useSelector(state => state.certified.getBadges)
    const authBroker = useSelector((state) => state.auth.authBroker);
    const [imageUrl, setImageUrl] = useState('')
    const [certificateCount, setCertificateCount] = useState(0)
    const [badgeCount, setBadgeCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const userRole = useSelector(state => state.user.role)
    useEffect(() => {
        if (uploadRes !== undefined)
            uploadRes.status = ''
    }, [])

    useEffect(() => {
        setIsLoading(false)
        if (uploadRes?.status === 'success') {
            // console.log('uploadedImage: ', uploadRes.profile_img)
            auth.payload.user.profile_img = uploadRes.profile_img
            dispatch(loginResponse(auth))

            // dispatch(userDetail())
            dispatch(userDetailBroker({
                module: 'getOrderDetailBroker',
                apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
            }))
            alert(uploadRes.message)
            uploadRes.status = ''
        } else {
            // alert(uploadRes.error.message)
        }
    }, [uploadRes])

    useEffect(() => {

        if (certificateRes?.status === 'success') {
            if (!_.isEmpty(certificateRes?.payload?.payload)) {
                setCertificateCount(certificateRes?.payload?.payload.length)
            }
        }
        // console.log('certificates: ', certificateRes)
    }, [certificateRes])

    useEffect(() => {

        if (badgeRes?.status === 'success') {
            if (!_.isEmpty(badgeRes.payload?.payload)) {
                setBadgeCount(badgeRes?.payload?.payload.length)
            }
        }
    }, [badgeRes])




    // useEffect(()=>{
    //     setImageUrl(userDetailRes?.payload?.payload?.profile_img)
    // },[userDetailRes])

    const onUpload = (value, isDelete) => {
        // console.log('user req profileImg: ', value)

        // const formData = new FormData();
        // formData.append('updateprofile-btn', 'Profile UPD');
        // formData.append('email', userDetailRes?.payload?.payload?.email);
        // formData.append('token', localStorage.getItem(SessionKeys.API_TOKEN));
        // formData.append('thumbnail', value);
        // dispatch(onUploadReq(formData))

        setIsLoading(true)
        const formData = new FormData();
        formData.append('token', localStorage.getItem(SessionKeys.API_TOKEN_BROKER));
        formData.append('avatar', isDelete ? "" : value);
        dispatch(onUploadReqBroker(formData))


    }

    return (

        <div className="col-md-4 col-xl-3 col-lg-3 col-12 profile-left-section ">
            <div className="profile-show-sec">
                {/*<img className="img-fluid profileICON" src="/images/dots_img_new.png" width="26" height="26" alt="" />*/}

                {/*<img id="profileImg" className="img-fluid profile-dash" src={userDetailRes?.payload?.payload?.profile_img != undefined ? userDetailRes?.payload?.payload?.profile_img : "/images/profile_side_img.png"} width="168" height="168" />*/}
                <div className="prfile-obx" style={{ 'marginLeft': '15px' }}><ReactRoundedImage id="profileImg" image={userDetailRes?.payload?.profile_img !== undefined && userDetailRes?.payload?.profile_img !== '' ? userDetailRes?.payload?.profile_img : "/images/avatar.png"} roundedSize="0" imageWidth="140" imageHeight="140" />
                    <div className="img-profile_visbty">
                        <FormGroup >
                            <svg className="img-fluid profileICON" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="More" width="30" height="30" viewBox="0 0 30 30">
                                <defs>
                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#00b7f1" />
                                        <stop offset="1" stop-color="#ed156e" />
                                    </linearGradient>
                                </defs>
                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                <g id="Group_2342" data-name="Group 2342" transform="translate(-775 -762)">
                                    <circle id="Ellipse_183" data-name="Ellipse 183" cx="2" cy="2" r="2" transform="translate(782 775)" fill="var(--color-element-1)" />
                                    <circle id="Ellipse_184" data-name="Ellipse 184" cx="2" cy="2" r="2" transform="translate(788 775)" fill="var(--color-element-1)" />
                                    <circle id="Ellipse_185" data-name="Ellipse 185" cx="2" cy="2" r="2" transform="translate(794 775)" fill="var(--color-element-1)" />
                                </g>
                            </svg>

                            <Input type="file" name="file" accept="image/png,image/jpeg" id="exampleFile"
                                onChange={
                                    (e) => {
                                        // console.log('Profile Image:', e.target.files[0]);
                                        if (e.target.files[0] !== undefined && e.target.files[0] !== null)
                                            onUpload(e.target.files[0], false);
                                    }
                                } />

                            {isLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="primary"
                            /> : ""}
                        </FormGroup>
                        {
                            userDetailRes?.payload?.profile_img !== undefined && userDetailRes?.payload?.profile_img !== '' ?
                                <div className="delete-iamge-pointer" onClick={() => { onUpload(null, true) }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                    >
                                        <defs>
                                            <linearGradient
                                                id="linear-gradient"
                                                x2="1"
                                                y1="0.5"
                                                y2="0.5"
                                                gradientUnits="objectBoundingBox"
                                            >
                                                <stop offset="0" stopColor="#00b7f1"></stop>
                                                <stop offset="1" stopColor="#ed156e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <circle
                                            cx="15"
                                            cy="15"
                                            r="15"
                                            fill="url(#linear-gradient)"
                                            data-name="Ellipse 57"
                                        ></circle>
                                        <circle
                                            cx="13"
                                            cy="13"
                                            r="13"
                                            fill="#fff"
                                            data-name="Ellipse 179"
                                            transform="translate(2 2)"
                                        ></circle>
                                        <path
                                            fill="var(--color-element-1)"
                                            d="M10.582.784h-2.94l-.23-.458A.588.588 0 006.885 0h-2.8a.581.581 0 00-.524.326l-.23.458H.392A.392.392 0 000 1.176v.784a.392.392 0 00.392.392h10.19a.392.392 0 00.392-.392v-.784a.392.392 0 00-.392-.392zM1.3 11.439a1.176 1.176 0 001.173 1.1H8.5a1.176 1.176 0 001.173-1.1l.519-8.3H.784z"
                                            data-name="Icon awesome-trash"
                                            transform="translate(9.514 8.729)"
                                        ></path>
                                    </svg>
                                </div> : ''
                        }
                    </div>

                </div>


                <div className="profile-users02">
                    <div className="content_profile-pg">
                        { 
                         authBroker?.payload?.user?.company_id !== 521 &&
                         userRole !== 'instructor' &&
                       <>
                         <span>{certificateCount}</span>
                        <p>{certificateCount <= 1 ? 'Certificate' : 'Certificates'}</p>
                       </>
                        }
                      
                    </div>
                    {/* <div className="content_profile-pg">
                        <span>{badgeCount}</span>
                        <p>{badgeCount <= 1 ? 'Badge' : 'Badges'}</p>
                    </div> */}
                </div>


            </div>
        </div>





    )

}
export default SideNav;