import Modal from 'react-bootstrap/Modal';
import SelectOption from "../../components/AssignModal/SelectOption";
import ChooseTeammate from "../../components/AssignModal/ChooseTeammate";
import ChooseTeams from "../../components/AssignModal/ChooseTeams";
import CreateTeam from "../../components/AssignModal/CreateTeam";
import CreateteamOptions from "../../components/AssignModal/CreateteamOptions";
import CoursesAssign from "../../components/AssignModal/CoursesAssign";
import ChoosePeople from "../../components/AssignModal/ChoosePeople";
import SelectpeopleOptions from "../../components/AssignModal/SelectpeopleOptions";
import GenerateaReport from "../../components/AssignModal/GenerateaReport";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ChooseFromCompletedCourse from '../AssignModal/ChooseFromCompletedCourse';
import Loader from '../Loader/Loader';
import { isAndroid, isIOS } from 'react-device-detect';
const AssignCourseModal = (props) => {
    const { show, setShow, parentName, parentId, origin, isMobile } = props;
    const currentView = useSelector((state) => state.assignCourse.current_view);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedTeamMatesList, setSelectedTeamMatesList] = useState([]);
    const [selectedOfficeList, setSelectedOfficeList] = useState([]);
    const [selectedCompletedCourseList, setSelectedCompletedCourseList] = useState([]);
    const [filteredFromCoursesOffices, setFilteredFromCoursesOffices] = useState([]);
    const [selectedTeamList, setSelectedTeamsList] = useState([]);
    const [filterReq, setFilterReq] = useState([]);
    const [titleHeading, setTitleHeading] = useState('')

    useEffect(() => {
        setSelectedTeamMatesList([]);
        setSelectedOfficeList([]);
        setSelectedCompletedCourseList([]);
        setFilteredFromCoursesOffices([])
        setSelectedTeamsList([])

    }, [show])

    useEffect(() => {
        setTitleHeading(`Assign ${parentName} to`);
    }, [parentName])

    return (
        <>
            <Loader start={isLoading} />
            <Modal
                show={show}
                onHide={() => {
                    if (parentName.includes("Session") && (isAndroid || isIOS) && isMobile) {
                        try {
                            // alert("Window CallbackHanlder");
                            if (window.callbackHandler != undefined) {
                                window.callbackHandler.postMessage("assigned");
                                setShow(false)
                            }
                            if (window.webkit != undefined) {
                                if (window.webkit.messageHandlers.callbackHandler != undefined) {
                                    window.webkit.messageHandlers.callbackHandler.postMessage(
                                        "assigned"
                                    );
                                    setShow(false)
                                }
                            }
                        } catch (err) {
                            // alert("Window CallbackHanlder Err");
                            // console.log('The native context does not exist yet');
                            setShow(false)
                        }
                    } else {
                        setShow(false)
                    }
                }}
                size="md"
                dialogClassName="modalassign modal-dialog-scrollable"
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleHeading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentView === 1 && <SelectOption parentId={parentId} parentName={parentName} selectedTeamMatesList={selectedTeamMatesList} selectedTeamList={selectedTeamList} setIsLoading={setIsLoading} setShow={setShow} origin={origin} setTitleHeading={setTitleHeading} />}
                    {currentView === 2 && <ChooseTeammate parentId={parentId} parentName={parentName} selectedTeamMatesList={selectedTeamMatesList} setSelectedTeamMatesList={setSelectedTeamMatesList} setTitleHeading={setTitleHeading} />}
                    {currentView === 3 && <ChooseTeams parentId={parentId} parentName={parentName} selectedTeamList={selectedTeamList} setSelectedTeamsList={setSelectedTeamsList} setTitleHeading={setTitleHeading} />}
                    {currentView === 4 && <CreateTeam parentId={parentId} parentName={parentName} filteredFromCoursesOffices={filteredFromCoursesOffices} setFilteredFromCoursesOffices={setFilteredFromCoursesOffices} filterReq={filterReq} setTitleHeading={setTitleHeading} setSelectedTeamsList={setSelectedTeamsList} />}
                    {currentView === 5 && <CreateteamOptions parentId={parentId} parentName={parentName} selectedOfficeList={selectedOfficeList} selectedCompletedCourseList={selectedCompletedCourseList} setFilteredFromCoursesOffices={setFilteredFromCoursesOffices} setFilterReq={setFilterReq} setTitleHeading={setTitleHeading} />}
                    {currentView === 6 && <CoursesAssign parentId={parentId} parentName={parentName} setTitleHeading={setTitleHeading} />}
                    {currentView === 7 && <ChoosePeople parentId={parentId} parentName={parentName} selectedOfficeList={selectedOfficeList} setSelectedOfficeList={setSelectedOfficeList} setTitleHeading={setTitleHeading} />}
                    {currentView === 8 && <ChooseFromCompletedCourse parentId={parentId} parentName={parentName} selectedCompletedCourseList={selectedCompletedCourseList} setSelectedCompletedCourseList={setSelectedCompletedCourseList} setTitleHeading={setTitleHeading} />}
                    {currentView === 9 && <SelectpeopleOptions parentId={parentId} parentName={parentName} setTitleHeading={setTitleHeading} />}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AssignCourseModal;