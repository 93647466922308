const types = {
    FIXED_FOOTER: 'FIXED_FOOTER',
    FIXED_FOOTER_CLEAR: 'FIXED_FOOTER_CLEAR',

    HOME_MSG: 'HOME_MSG',
    HOME_MSG_CLEAR: 'HOME_MSG_CLEAR',

    SHOW_HOME_MSG: 'SHOW_HOME_MSG',

    CONTENT_ACCESS: 'CONTENT_ACCESS',
    CONTENT_ACCESS_RESPONSE: 'CONTENT_ACCESS_RESPONSE',
    CONTENT_ACCESS_CLEAR: 'CONTENT_ACCESS_CLEAR',

    BREADCRUMB_STACK: 'BREADCRUMB_STACK',
    CLEAR_BREADCRUMB_STACK: 'CLEAR_BREADCRUMB_STACK'
}

export default types;