import { fork, take, call, put } from 'redux-saga/effects'

import {
  getArticleResponse
} from './actions'
import articleType from './types'
import { getAPI, postAPI } from '../../api/generic'


export function* doGetArticles() {
  while (true) {
    const { payload } = yield take(articleType.GET_ARTICLE)
    const response = yield call(getAPI, payload)
    yield put(getArticleResponse(response))
  }
}




function* article() {
  yield fork(doGetArticles)
 
}

export default article
