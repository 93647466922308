export const toolboxInfo = {
    colorValue: "#FFA500",
    folderNameSec: "Folder 1",
    items_count: 23,
    document_list: [
        {
            title: "Document 01",
            Created: "24 Jan 2022 12:43 PM",
            Pages: 4,
            lastModified: "24 Jan 2022 01:43 PM",
            pdf_url:'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
            size: "323"
        },
        {
            title: "Document 02",
            Created: "24 Jan 2022 01:43 PM",
            Pages: 4,
            lastModified: "21 Dec 2022 01:56 PM",
            pdf_url:'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
            size: "323"
        }
    ]
}


