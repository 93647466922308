import React, { useEffect } from 'react'

import Page from "../../components/Page"
import CourseLaunchSection from '../../components/Course/CourseLaunchSection';
import ModuleDetail from '../modulePages/moduleDetail';
import HeaderNewMenu from '../../components/HeaderNewMenu';
import FooterNew from '../../components/FooterNew';

const CourseLaunchPage = () => {
  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }

    if (document.getElementById("niit_logo_mob") !== null) {
      document.getElementById("niit_logo_mob").style.display = "none";
    }
  }, [])
  return (
    <>
      <HeaderNewMenu
        showMenu={false}
      />
      <CourseLaunchSection
        isMobile={true}
      />
      {/*<FooterNew />*/}
    </>
  )
}

export default CourseLaunchPage