import { useEffect, useState } from "react";
import _ from "lodash"
import moment from "moment";
import Slider from "react-slick/";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import PrevArrow from "../LearningPaths/PrevArrow";
import NextArrow from "../LearningPaths/NextArrow";
import ShimmerScreen from "../Layouts/ShimmerScreen";

const RolePlayParticipateSection = (props) => {

    const [rolePlayListing, setRolePlayListing] = useState([])
    const [unassignedRoleListing, setUnAssignedRoleListing] = useState([])
    const [selectedRolePlayId, setSelectedRolePlayId] = useState('')

    useEffect(() => {
        if (!_.isEmpty(props.listing)) {
            // console.log('myrole play in roleplay', props.listing)
            setRolePlayListing(props.listing)
            props.setLoadingMasterList(true)
            // props.setIsLoading(false)
        }
    }, [props.listing])

    useEffect(() => {
        if (!_.isEmpty(props.unassignedRoleList)) {
            // console.log('myrole play in roleplay', props.listing)
            setUnAssignedRoleListing(props.unassignedRoleList)
            props.setLoadingUnMasterList(true)
        }
    }, [props.unassignedRoleList])

    useEffect(() => {
        
    }, [rolePlayListing])

    useEffect(() => {
        if (!_.isEmpty(props.roleData)) {
            // console.log('SET SELECTED', props.roleData)
            setSelectedRolePlayId(props.roleData.role_play_id)
        }
    }, [props.roleData])

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        dots: true,
        arrows: true,
        autoplaySpeed: 2000,
        infinite: false,
        centerMode: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [  
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
        
                }
            },
            {
                breakpoint: 991,
                settings: {
                slidesToShow: 2,
                slidesToScroll:2,
                infinite: false,
                dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const renderShimmerLoader = () => {
        return (
            <>
                <Slider {...settings}>
                    {[1,2,3,4].map((role, index) => {
                        return (
                        <div className="col-md-6 col-lg-4 col-xl-3 col-12 search__input_role cursor-pointer"
                        key={'role-play-'+(index+1)}
                        >
                            <div className="img-box_RP">
                                <div className="img-videosec learn_image-card">
                                    <div className="img-box-video">
                                        <ShimmerScreen 
                                            case={'rect'}
                                            width={'100%'}
                                            height={'15rem'}
                                        />
                                    </div>
                                </div>
                                <div className="details-card_RP ">
                                    <ShimmerScreen 
                                        case={'rect'}
                                        width="90%"
                                        height="1rem"
                                    />
                                    <ShimmerScreen 
                                        case={'rect'}
                                        width="90%"
                                        height="1rem"
                                    />
                                </div>
                            </div>
                        </div> 
                        )
                    }) 
                    }
            </Slider>
            </>
        )
    }

    return (
        <>
        <section className="role-video-listing my_learningpath_tab">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                            <div className="role-play-head"><p className="lablesech">{props.title}</p></div>
                    </div>
                </div>
                <div className="mt-4 tabsec-roleplay">
                    <Tabs defaultActiveKey={"assigned"}
                        onSelect={(e) => {
                            props.setRolePlayTab(e)
                            setSelectedRolePlayId('')
                        }}
                    >
                        <Tab eventKey={"assigned"} title="Assigned">
                            <div className="row orders videolistbox">
                                {(_.isArray(rolePlayListing) && rolePlayListing.length > 0)
                                ?
                                <Slider {...settings}>
                                        {rolePlayListing.map((role, index) => {
                                            return (
                                            <div className="col-md-6 col-lg-4 col-xl-3 col-12 search__input_role cursor-pointer"
                                            key={'role-play-'+(index+1)}
                                            onClick={() => {
                                                if (role.role_play_id !== selectedRolePlayId) {
                                                    props.sendData(role)
                                                    props.setIsLoading(true)
                                                }
                                            }}
                                            >
                                                <div className="img-box_RP">
                                                    <div className="img-videosec learn_image-card">
                                                        <div className="img-box-video">
                                                            {
                                                                role.type === 'video' ?
                                                                <img className="img-videosec learn_image-card" 
                                                                src={role.thumbnail} alt={role.title} 
                                                                onError={({currentTarget}) => {
                                                                    currentTarget.onerror = true;
                                                                    currentTarget.src = "/images/default-demo-img.jpg"
                                                                }}
                                                                />
                                                                : ''
                                                            }
                                                            {/* <div className="playbtnvideo">
                                                                {
                                                                    role.type ==='video' ? 
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                                                        <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                                                                            <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                                                                            <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" strokeWidth="4">
                                                                                <circle cx="24" cy="24" r="24" stroke="none"/>
                                                                                <circle cx="24" cy="24" r="22" fill="none"/>
                                                                            </g>
                                                                            </g>
                                                                            <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                                                                        </g>
                                                                        </svg>

                                                                    : <img className="play-pdf" src="/images/pdf.svg" alt="Play" />
                                                                }
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="details-card_RP">
                                                        <OverlayTrigger
                                                            key='bottom'
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    <div
                                                                        style={{
                                                                            color: "white",
                                                                            backgroundColor: "black",
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: role.title
                                                                        }}
                                                                    ></div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <h5
                                                                className="cursor-pointer" 
                                                                dangerouslySetInnerHTML={{
                                                                    __html: role.title.length > 40 ? `${role.title.substring(0, 40)}...` : role.title
                                                                }}
                                                            ></h5>
                                                        </OverlayTrigger>
                                                        <div className="row info-bottm-card_nw">
                                                            <div className="col-md-12 rolesspace">
                                                                {
                                                                    props.page === 'participate' ? 
                                                                    <>
                                                                        {/* <p>Category: {role.roleplay_category}</p> */}
                                                                        {/* <p>Assigned date: {moment(role.releaseDate, 'DD/MM/YYYY').format('MMM DD, YYYY')}</p> */}
                                                                    </>
                                                                    : '' 
                                                                }
                                                                {
                                                                    props.page === 'review' ? 
                                                                    <>
                                                                        <p>Submission date: {moment(role.submissionDate, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}</p>
                                                                        <p>Submission by: {role.userFirstName} {role.userLastName}</p>
                                                                    </>
                                                                    : '' 
                                                                }
                                                                {
                                                                    props.page === 'roleplay' ? 
                                                                    <>
                                                                        <p>Created date: {moment(role.createdDate, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}</p>
                                                                        <p>Status: 
                                                                            {role.submitToCoach == 1 ? !_.isEmpty(role.feedbackData) ? ' Feedback Recevied' : ' Submitted' : ' Saved'}
                                                                        </p>
                                                                    </>
                                                                    : '' 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {role.role_play_id === selectedRolePlayId ? 
                                                <div className="selected-bar"></div> 
                                                : ''}
                                            </div> 
                                            )
                                        }) 
                                        }
                                </Slider>
                                :
                                props.loadingMasterList ? 
                                renderShimmerLoader()
                                :
                                rolePlayListing.length === 0 ?
                                <div className="col-md-12">
                                    <div className="not-found mt-3">
                                        <div className="interboxes">
                                        <img src="/images/telescope.svg" alt="Not Found" />
                                        <p>
                                            <>
                                                Role play content is not yet assigned to you.
                                            </>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                : ""
                                }
                            </div>
                        </Tab>
                        <Tab eventKey={"more"} title="Explore More">
                            <div className="row orders videolistbox">
                                {(_.isArray(unassignedRoleListing) && unassignedRoleListing.length > 0)
                                ?
                                <Slider {...settings}>
                                        {unassignedRoleListing.map((role, index) => {
                                            return (
                                            <div className="col-md-6 col-lg-4 col-xl-3 col-12 search__input_role cursor-pointer"
                                            key={'role-play-'+(index+1)}
                                            onClick={() => {
                                                if (role.role_play_id !== selectedRolePlayId) {
                                                    props.sendData(role)
                                                    props.setIsLoading(true)
                                                }
                                            }}
                                            >
                                                <div className="img-box_RP">
                                                    <div className="img-videosec learn_image-card">
                                                        <div className="img-box-video">
                                                            {
                                                                role.type === 'video' ?
                                                                <img className="img-videosec learn_image-card" 
                                                                src={role.thumbnail} alt={role.title} 
                                                                onError={({currentTarget}) => {
                                                                    currentTarget.onerror = true;
                                                                    currentTarget.src = "/images/default-demo-img.jpg"
                                                                }}
                                                                />
                                                                : ''
                                                            }
                                                            {/* <div className="playbtnvideo">
                                                                {
                                                                    role.type ==='video' ? 
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                                                        <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                                                                            <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                                                                            <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" strokeWidth="4">
                                                                                <circle cx="24" cy="24" r="24" stroke="none"/>
                                                                                <circle cx="24" cy="24" r="22" fill="none"/>
                                                                            </g>
                                                                            </g>
                                                                            <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                                                                        </g>
                                                                        </svg>

                                                                    : <img className="play-pdf" src="/images/pdf.svg" alt="Play" />
                                                                }
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="details-card_RP">
                                                        <OverlayTrigger
                                                            key='bottom'
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    <div
                                                                        style={{
                                                                            color: "white",
                                                                            backgroundColor: "black",
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: role.title
                                                                        }}
                                                                    ></div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <h5
                                                                className="cursor-pointer" 
                                                                dangerouslySetInnerHTML={{
                                                                    __html: role.title.length > 40 ? `${role.title.substring(0, 40)}...` : role.title
                                                                }}
                                                            ></h5>
                                                        </OverlayTrigger>
                                                        <div className="row info-bottm-card_nw">
                                                            <div className="col-md-12 rolesspace">
                                                                {
                                                                    props.page === 'participate' ? 
                                                                    <>
                                                                        {/* <p>Category: {role.roleplay_category}</p> */}
                                                                        {/* <p>Assigned date: {moment(role.releaseDate, 'DD/MM/YYYY').format('MMM DD, YYYY')}</p> */}
                                                                    </>
                                                                    : '' 
                                                                }
                                                                {
                                                                    props.page === 'review' ? 
                                                                    <>
                                                                        <p>Submission date: {moment(role.submissionDate, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}</p>
                                                                        <p>Submission by: {role.userFirstName} {role.userLastName}</p>
                                                                    </>
                                                                    : '' 
                                                                }
                                                                {
                                                                    props.page === 'roleplay' ? 
                                                                    <>
                                                                        <p>Created date: {moment(role.createdDate, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}</p>
                                                                        <p>Status: 
                                                                            {role.submitToCoach == 1 ? !_.isEmpty(role.feedbackData) ? ' Feedback Recevied' : ' Submitted' : ' Saved'}
                                                                        </p>
                                                                    </>
                                                                    : '' 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {role.role_play_id === selectedRolePlayId ? 
                                                <div className="selected-bar"></div> 
                                                : ''}
                                            </div> 
                                            )
                                        }) 
                                        }
                                </Slider>
                                :
                                props.loadingUnMasterList ? renderShimmerLoader() :
                                unassignedRoleListing.length === 0 ?
                                <div className="col-md-12">
                                    <div className="not-found mt-3">
                                        <div className="interboxes">
                                        <img src="/images/telescope.svg" alt="Not Found" />
                                        <p>
                                            <>
                                                No role play content.
                                            </>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                : ""
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </section>
        </>
    )   
}

export default RolePlayParticipateSection;