import ShimmerScreen from "./ShimmerScreen";

const ShimmerAcceptRole = () => {
  return (
    <>
      <section className="rsp-accpet-role-page my-credit-points">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1 className="requesthead">
                {/*SHimmer needed */}
                <ShimmerScreen
                  width="7rem"
                  height="1.95rem"
                  case="rect"
                />
              </h1>
            </div>

            <div className="col-md-5">
              <div className="accept-all">
                {/* <button type="button" className="btn btn-primary-startNow">
                  {/* Accept All */}
                {/* </button>  */}
              </div>
            </div>
          </div>
          <div className="role-list-rsp">
            <>
              <div className="row accpet-box">
                <div className="col-lg-6 col-md-6">
                  <div className="list-rsp-box">
                    <div className="prfoli-box">
                      {/* <img src={item?.profile_pic} alt="" /> */}
                      <ShimmerScreen width="3rem" height="3em" case="rect" />
                    </div>
                    <div className="rsp-list-desc">
                      <p className="rsp-list-dec-sort">
                        {/*Shimmer needed */}
                        <ShimmerScreen
                          width="27.997rem"
                          height="1.188rem"
                          case="rect"
                        />
                      </p>

                      <p className="rsp-acp-time">
                        {/*Shimmer Neeede */}
                        <ShimmerScreen
                          width="27.997rem"
                          height="1.188rem"
                          case="rect"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="accpet-box-right">
                    <div className="accpet-box-btn-list d-flex gap-2">
                      {/* Accept */}
                      {/*Shimmer  */}
                      <ShimmerScreen
                        width="4.5rem"
                        height="1.75rem"
                        case="rect"
                      />
                      <ShimmerScreen
                        width="4.5rem"
                        height="1.75rem"
                        case="rect"
                      />

                    </div>

                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div id="myOnboard" className="center_line m-0"></div>
        <div className="list-users">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="requesthead">
                  {/*Shimmer Needed */}
                  <ShimmerScreen
                    width="7rem"
                    height="1.95rem"
                    case="rect"
                  />
                </h2>

                <div className="usr-search profile-left-sec mb-3">
                  <div className="input-text position-relative d-flex">
                    {/* Search */}
                    <ShimmerScreen
                      width="26.246rem"
                      height="2rem"
                      case="rect"
                    />
                    {/*Shimmer Needed */}
                    <div className="ms-1">
                      <ShimmerScreen
                        width="1.625rem"
                        height="1.625rem"
                        case="circle"
                      />
                    </div>
                    {/* Shimmer circle needed.*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row list-coach">
              <div className="col-lg-6 col-md-12">
                <ShimmerScreen
                  width="100%"
                  height="6.656rem"
                  case="rect"
                />

                <div className="table-responsive1 rsp-team-lead-table">
                  <ShimmerScreen
                    width="100%"
                    height="6.656rem"
                    case="rect"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="table-responsive1 mentor-table">
                  <ShimmerScreen
                    width="100%"
                    height="6.656rem"
                    case="rect"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myOnboard" className="center_line m-0"></div>
        <div className="list-users">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="requesthead">
                  {/*Shimmer Needed */}
                  <ShimmerScreen
                    width="7rem"
                    height="1.95rem"
                    case="rect"
                  />
                </h2>

                <div className="usr-search profile-left-sec mb-3">
                  <div className="input-text position-relative d-flex">
                    {/* Search */}
                    <ShimmerScreen
                      width="26.246rem"
                      height="2rem"
                      case="rect"
                    />
                    {/*Shimmer Needed */}
                    <div className="ms-1">
                      <ShimmerScreen
                        width="1.625rem"
                        height="1.625rem"
                        case="circle"
                      />
                    </div>
                    {/* Shimmer circle needed.*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row list-coach">
              <div className="col-lg-6 col-md-12">
                <ShimmerScreen
                  width="100%"
                  height="6.656rem"
                  case="rect"
                />

                <div className="table-responsive1 rsp-team-lead-table">
                  <ShimmerScreen
                    width="100%"
                    height="6.656rem"
                    case="rect"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="table-responsive1 mentor-table">
                  <ShimmerScreen
                    width="100%"
                    height="6.656rem"
                    case="rect"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShimmerAcceptRole;
