import types from './types'

const initialState = {
    orderHistory: {},
    createFolderRes: {},
    createFileRes: {},
    deleteFolderRes: {},
    deleteFileRes: {},
    folderDetails: {},
    searchDetails: {},
    updateTimeRes: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ORDER_HISTORY_RESPONSE:
            // console.log('Reducer', action.payload)
            return {
                ...state,
                orderHistory: action.payload,
            }
        case types.ORDER_HISTORY_CLEAR:
            return {
                ...state,
                orderHistory: [],
            }
        case types.CREATE_FOLDER_RESPONSE:
            // console.log('Reducer', action.payload)
            return {
                ...state,
                createFolderRes: action.payload,
            }
        case types.CREATE_FOLDER_CLEAR:
            return {
                ...state,
                createFolderRes: [],
            }
        //create file - toolbox
        case types.CREATE_FILE_RESPONSE:
            return {
                ...state,
                createFileRes: action.payload,
            }
        case types.CREATE_FILE_CLEAR:
            return {
                ...state,
                createFileRes: [],
            }
        // delete folder- toolbox
        case types.DELETE_FOLDER_RESPONSE:
            return {
                ...state,
                deleteFolderRes: action.payload,
            }
        case types.DELETE_FOLDER_CLEAR:
            return {
                ...state,
                deleteFolderRes: [],
            }
        //delelte file -toolbox
        case types.DELETE_FILE_RESPONSE:
            return {
                ...state,
                deleteFileRes: action.payload,
            }
        case types.DELETE_FILE_CLEAR:
            return {
                ...state,
                deleteFileRes: [],
            }
        // folder details           
        case types.FOLDER_DETAIL_RESPONSE:
            return {
                ...state,
                folderDetails: action.payload,
            }
        case types.FOLDER_DETAIL_CLEAR:
            return {
                ...state,
                folderDetails: [],
            }
        case types.TOOLBOX_SEARCH_RESPONSE:
            return {
                ...state,
                searchDetails: action.payload,
            }
        case types.TOOLBOX_SEARCH_CLEAR:
            return {
                ...state,
                searchDetails: {},
            }
        case types.TOOLBOX_UPDATE_TIME_RESPONSE:
            return {
                ...state,
                updateTimeRes: action.payload,
            }
        case types.TOOLBOX_UPDATE_TIME_CLEAR:
            return {
                ...state,
                updateTimeRes: {},
            }
        default:
            return state
    }
}