import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayer from "react-video-js-player";
import Page from "../../components/Page";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Loader from "../../components/Loader/Loader";
import { sessionWatchURLDataClear } from "../../redux/events/actions";
import { clickStreamUser } from "../../utils/AppUtils";
import { videoEvents } from "../../utils/VideoClickStream";

const VideoPlayerPageSection = (props) => {
    const { isMobile } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    const authBroker = useSelector((state) => state.auth.authBroker);
    const watchURLData = useSelector((state) => state.events.watchURLData);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // console.log('WATCH URL DATA', watchURLData)
        if (!_.isEmpty(watchURLData)) {
            setIsLoading(false);
        }
    }, []);

    const onPlayerReady = (player) => {
        videoEvents(player, {
            type: 'session',
            id: watchURLData.id,
            user_id: authBroker?.payload?.user?.user_id
        })
    }

    return (
        <>
            <Loader start={isLoading} />
            <div className="my-credit-points videofull-view">
                <div className="container-fluid">
                    {!isMobile && <div className="row">
                        <div className="col-md-4">
                            <button
                                className="btn btn-primary setup-button"
                                onClick={() => {
                                    clickStreamUser("back-btn-session-video", {
                                        userId: authBroker?.payload?.user.user_id,
                                    });
                                    history.goBack();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-fullbox">
                                <div className="search__input">
                                    {!_.isEmpty(watchURLData) ? (
                                        <div className="img-box_RP">
                                            <p className="video-title">{watchURLData?.title}</p>
                                            <div className="video-full">
                                                <VideoPlayer
                                                    className="img-videosec learn_image-card"
                                                    controls={true}
                                                    src={watchURLData?.watch_url}
                                                    width="720"
                                                    height="420"
                                                    autoPictureInPicture={true}
                                                    fluid={true}
                                                    inline={false}
                                                    pictureInPictureToggle={true}
                                                    onReady={onPlayerReady}
                                                />
                                            </div>
                                        </div>
                                    ) : isLoading ? (
                                        ""
                                    ) : (
                                        <div className="not-found cart-notfound mt-4">
                                            <div className="interboxes">
                                                <img src="/images/telescope.svg" alt="Not Found" />
                                                <p>No video available to play.</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VideoPlayerPageSection;
