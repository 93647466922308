import { fork, take, call, put, all } from 'redux-saga/effects'

import {
    getAllEventsNIITResponse,
    getEventDetailsNIITResponse,
    getSessionNIITListResponse,
    getAllEventsBrokerResponse,
    getEventDetailsBrokerResponse,
    getSessionBrokerListResponse,
    getEnrollBrokerSessionResponse,
    getCalendarViewSessionsResponse,
    hostSessionResponse,
    joinSessionResponse,
    getCalendarInstSessionsResponse,
    getCalendarHostSessionsResponse,
    getCalendarSessionListResponse,
    eventNotificationResponse,
    sessionAttendenceResponse,
    checkEventNotificationResponse,
    cancelSessionDataClear,
    cancelSessionDataResponse,
    getAllEventsResponse,
    getMyEventsResponse,
    fetchBoardcastResponse,
    getLiveCalendarSessionListResponse,
} from './actions'
import types from './types'
import {  postAPIWithoutModule,postAPI } from '../../api/generic'

export function* doGetAllEventsNIIT() {
    while (true) {
        const { payload } = yield take(types.GET_ALL_EVENTS_NIIT)
        const response = yield call(postAPIWithoutModule, payload, true)
        // console.log("response", response)
        yield put(getAllEventsNIITResponse(response))
    }
}
export function* viewsSessionListNIIT() {
    while (true) {
        const { payload } = yield take(types.SESSION_LIST_NIIT)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getSessionNIITListResponse(response))
    }
}

export function* doGetEventDetailNIIT() {
    while (true) {
        const { payload } = yield take(types.GET_EVENTS_DETAILS_NIIT)
        const response = yield call(postAPIWithoutModule, payload, true)
        yield put(getEventDetailsNIITResponse(response))
    }
}

export function* doGetAllEventsBroker() {
    while (true) {
        const { payload } = yield take(types.GET_ALL_EVENTS_BROKER)
        const response = yield call(postAPIWithoutModule, payload, true)
        // console.log("response", response)
        yield put(getAllEventsBrokerResponse(response))
    }
}
export function* viewsSessionListBroker() {
    while (true) {
        const { payload } = yield take(types.SESSION_LIST_BROKER)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getSessionBrokerListResponse(response))
    }
}

export function* doGetEventDetailBroker() {
    while (true) {
        const { payload } = yield take(types.GET_EVENTS_DETAILS_BROKER)
        const response = yield call(postAPIWithoutModule, payload, true)
        yield put(getEventDetailsBrokerResponse(response))
    }
}

export function* enrollBrokerSessionSaga() {
    while (true) {
        const { payload } = yield take(types.ENROLL_BROKER_SESSION)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getEnrollBrokerSessionResponse(response))
    }
}
//Calendar
export function* viewCalendarSessionsInfo() {
    while (true) {
        const { payload } = yield take(types.CALENDAR_VIEW_SESSIONS)
        const response = yield all([
            call(postAPIWithoutModule, payload.broker)
        ]) 
        yield put(getCalendarViewSessionsResponse(response))
    }
}
export function* calendarSessionList() {
    while (true) {
        const { payload } = yield take(types.CALENDAR_SESSION_LIST)
        const response = yield all([
            call(postAPIWithoutModule, payload.broker)
          ])
        yield put(getCalendarSessionListResponse(response))
    }
}

export function* liveCalendarSessionList() {
    while (true) {
        const { payload } = yield take(types.LIVE_CALENDAR_SESSION_LIST)
        const response = yield all([
            call(postAPIWithoutModule, payload.broker)
          ])
        yield put(getLiveCalendarSessionListResponse(response))
    }
}

export function* doEventList() {
    while (true) {
        const { payload } = yield take(types.GET_ALL_EVENTS)
        const response = yield all([
            call(postAPIWithoutModule, payload.ecom),
            call(postAPIWithoutModule, payload.broker)
          ])
        yield put(getAllEventsResponse(response))
    }
}

export function* doMyEventList() {
    while (true) {
        const { payload } = yield take(types.GET_MY_EVENTS)
        const response = yield all([
            call(postAPIWithoutModule, payload.broker)
          ])
        yield put(getMyEventsResponse(response))
    }
}

//Calendar for host Inst
export function* calendarHostSessionsInfo() {
    while (true) {
        const { payload } = yield take(types.CALENDAR_HOST_SESSIONS)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getCalendarHostSessionsResponse(response))
    }
}
export function* calendarInstSessionsInfo() {
    while (true) {
        const { payload } = yield take(types.CALENDAR_INSTRUCTOR_SESSIONS)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getCalendarInstSessionsResponse(response))
    }
}

export function* createZoomSessionMeeting() {
    while (true) {
        const { payload } = yield take(types.HOST_SESSIONS)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(hostSessionResponse(response))
    }
}
export function* joinZoomSessionMeeting() {
    while (true) {
        const { payload } = yield take(types.JOIN_SESSIONS)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(joinSessionResponse(response))
    }
}

export function* eventNotification() {
    while (true) {
        const { payload } = yield take(types.EVENT_NOTIFICATION)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(eventNotificationResponse(response))
    }
}

export function* doSessionAttendence() {
    while (true) {
        const { payload } = yield take(types.SESSION_ATTENDANCE)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(sessionAttendenceResponse(response))
    }
}

export function* doSessionCancel() {
    while (true) {
        const { payload } = yield take(types.CANCEL_SESSION)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(cancelSessionDataResponse(response))
    }
}

export function* doGetBoardcastInfo() {
    while (true) {
        const { payload } = yield take(types.FETCH_BOARDCAST)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(fetchBoardcastResponse(response))
    }
}


function* allEvents() {
    yield fork(doGetAllEventsNIIT)
    yield fork(viewsSessionListNIIT)
    yield fork(doGetEventDetailNIIT)
    yield fork(doGetAllEventsBroker)
    yield fork(viewsSessionListBroker)
    yield fork(doGetEventDetailBroker)
    yield fork(enrollBrokerSessionSaga)
    yield fork(viewCalendarSessionsInfo)
    yield fork(calendarSessionList)
    yield fork(liveCalendarSessionList)
    yield fork(calendarHostSessionsInfo)
    yield fork(calendarInstSessionsInfo)
    yield fork(createZoomSessionMeeting)
    yield fork(joinZoomSessionMeeting)
    yield fork(eventNotification)
    yield fork(doSessionAttendence)
    yield fork(doSessionCancel)
    yield fork(doEventList)
    yield fork(doMyEventList)
    yield fork(doGetBoardcastInfo)
}

export default allEvents