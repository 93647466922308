import { Modal, Button } from "react-bootstrap";

const AllAcceptModal = ({ showAllAccept, handleCloseAllAccept, allAccept}) => {
    return (
        <>
            <Modal show={showAllAccept} onHide={handleCloseAllAccept}
                size="500px"
                aria-labelledby="contained-modal-title-vcenter"
                className="alert-modal">
                {/* <Modal.Header closeButton className="modal-header">
                    <Modal.Title>Favourite Delete Alert</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="modal-body">Are you sure you want to accept all request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="confirm" onClick={() => {
                        handleCloseAllAccept()                       
                    }
                    }>
                        No
                    </Button>
                    <Button variant="primary" className="confirm" onClick={allAccept}>                                   
                    
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AllAcceptModal;