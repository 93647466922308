import React, { useState, useEffect, useRef } from "react";
// import CONFIG from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import { getFavourites, deleteFavourites } from "./favAPI";
import AlertDeleteFavModal from "../modulePages/alertDeleteFavModal";
import { getModuleFavGet, getModuleFavDeleteClear, getModuleFavDelete } from "../../redux/courses/actions";
import { setUserRole } from "../../redux/user/actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { SessionKeys } from "../../utils/AppUtils";
const ModuleFav = ({ currentModuleData, onPostMessage, favInteractive, moduleId, courseCode }) => {

  const [myFavList, setMyFavList] = useState([]);
  const [selFav, setSelFav] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const favRef = useRef(null);
  const stateFav = useSelector(state => state.courses)
  const moduleFavData = useSelector(state => state.courses.moduleFavGet);
  const moduleFavDelete = useSelector(state => state.courses.moduleFavDelete);
  const authBroker = useSelector(state => state.auth.authBroker)
  const dispatch = useDispatch()
  const history = useHistory()
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const source = params.get("source");
  const handleClose = (status) => {
    setShow(false);
    if (status === "No") {
      window.captureMyEvents("favoriteDelNo", {
        mid: currentModuleData.module_id,
        sid: selFav.sid,
      });
    }
  };
  const handleShow = (selectedFav) => {
    setSelFav(selectedFav);
    // console.log("sel fav111", selectedFav)
    setShow(true);
  };


  useEffect(() => {
    // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
    if (!_.isEmpty(authBroker) && (source != "broker")) {
      dispatch(getModuleFavGet({
        module: 'getModuleFavourites',
        // token: localStorage.getItem(SessionKeys.API_TOKEN),
        postData: {
          mid: moduleId,
          uid: authBroker.payload.user.user_id,
          course_code: courseCode.split('?')[0]
        }
      }))
    }
  }, [dispatch, favInteractive, moduleId])

  useEffect(() => {
    // console.log('module fav data', moduleFavData)
    if (!_.isEmpty(moduleFavData) && moduleFavData.status === "success") {
      if (!_.isEmpty(moduleFavData.payload.data)) {
        if (moduleFavData.payload.data.length > 0) {
          let url = currentModuleData.url;
          // let url = currentModuleData.url ? currentModuleData.url : 'https://iv17-rsp-dev.niit-mts.com/knowledge/plo_content/ORG235690/COURSE657163/launch.html';
          let modifiedList = moduleFavData.payload.data.map((item, index) => {
            // let thumbnailPath =          
            return {
              ...item,
              thmb: <img className="scorm-thumb" src={item.img} alt="logo" />,
              moduleName: "0" + index + 1,
            };
          });
          if (favRef.current) {
            favRef.current.scrollIntoView();
          }
          setMyFavList(modifiedList);
        }
      } else {
        setMyFavList([])
      }
    } else {
      setMyFavList([])
    }
  }, [moduleFavData])

  const deleteFavourite = () => {
    // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
    dispatch(getModuleFavDelete({
      module: 'deleteModuleFavourites',
      //   token: localStorage.getItem(SessionKeys.API_TOKEN),
      postData: {
        mid: moduleId,
        sid: selFav.sid,
        uid: authBroker.payload.user.user_id,
        course_code: courseCode.split('?')[0]
      }
    }))
    onPostMessage(selFav.sid, "deleteFav");
    setSelFav({})
  };
  useEffect(() => {
    // console.log("modFav delete", moduleFavDelete)
    if (!_.isEmpty(moduleFavDelete)) {

      //     dispatch(getModuleFavGet({
      //         module: 'getModuleFavourites',
      //         token: localStorage.getItem(SessionKeys.API_TOKEN),
      //         postData: {mid:moduleId}
      //     }))
      // let index = myFavList.findIndex((item) => item.sid === selFav.sid);
      // if (index !== -1) {
      //   let newList = Object.assign([], myFavList);
      //   newList.splice(index, 1);
      //   setMyFavList(newList);
      // //   onPostMessage(selFav.sid, "deleteFav");
      //   handleClose();
      // }
      if (moduleFavDelete.status === "success") {
        if (moduleFavDelete.payload.status == true) {
          // TODO: TO CHANGE AFTER MAKING USER ID CHANGES 
          dispatch(getModuleFavGet({
            module: 'getModuleFavourites',
            //   token: localStorage.getItem(SessionKeys.API_TOKEN),
            postData: {
              mid: moduleId,
              uid: authBroker.payload.user.user_id,
              course_code: courseCode.split('?')[0]
            }
          }))
          dispatch(getModuleFavDeleteClear())
          handleClose();
        }
      }
    }
  }, [moduleFavDelete])

  /**
   * Handle post message data on click
   * @param {*} e
   * @param {*} sid
   */
  const handlePostMessage = (e, sid) => {
    // console.log('source', sid)
    e.preventDefault();
    onPostMessage(sid);
  };

  return (
    <div className="favorites-tab-content fav-text" ref={favRef}>
      {loading ? (
        <p>Loading...</p>
      ) : myFavList.length > 0 ? (
        myFavList.map((item, index) => {
          return (
            <div key={index}>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <h6 className="fav-title-index thumb-image">
                    {(index + 1).toString().padStart(3, "0")}{" "}
                  </h6>
                  <span className="fav-delete"
                    onClick={() => {
                      handleShow(item)
                      window.captureMyEvents("favDeletePopUp", {
                        mid: currentModuleData.module_id,
                        sid: item.sid,
                      })
                    }
                    }
                  >

                    <a className="bg">
                      <button className="button-reset">

                        {/* <img className="image_on" src={`${CONFIG.API_URL}/img/delete_icon.png`} alt="logo"
                            ></img>
                            <img className="image_off" src={`${CONFIG.API_URL}/img/delete_icon1.png`} alt="logo" /> */}
                        {/* <img className="notesModule_icon" src="/images/delete.png" alt="show"></img> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              x2="1"
                              y1="0.5"
                              y2="0.5"
                              gradientUnits="objectBoundingBox"
                            >
                              <stop offset="0" stopColor="#00b7f1"></stop>
                              <stop offset="1" stopColor="#ed156e"></stop>
                            </linearGradient>
                          </defs>
                          <circle
                            cx="15"
                            cy="15"
                            r="15"
                            fill="url(#linear-gradient)"
                            data-name="Ellipse 57"
                          ></circle>
                          <circle
                            cx="13"
                            cy="13"
                            r="13"
                            fill="#fff"
                            data-name="Ellipse 179"
                            transform="translate(2 2)"
                          ></circle>
                          <path
                            fill="#7a549e"
                            d="M10.582.784h-2.94l-.23-.458A.588.588 0 006.885 0h-2.8a.581.581 0 00-.524.326l-.23.458H.392A.392.392 0 000 1.176v.784a.392.392 0 00.392.392h10.19a.392.392 0 00.392-.392v-.784a.392.392 0 00-.392-.392zM1.3 11.439a1.176 1.176 0 001.173 1.1H8.5a1.176 1.176 0 001.173-1.1l.519-8.3H.784z"
                            data-name="Icon awesome-trash"
                            transform="translate(9.514 8.729)"
                          ></path>
                        </svg>
                      </button>
                    </a>
                  </span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12 mb-3 cursor-pointer fav-thumb">
                  {/* <h6 className="fav-title">{item.ttl}</h6> */}
                  <img
                    className="img-fluig img-responsive"
                    src={item.img}
                    onClick={(e) => handlePostMessage(e, item.sid)}
                  />{" "}
                  <br />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p>No favorites have been added for this module yet.</p>
      )}
      <AlertDeleteFavModal
        deleteFavourite={deleteFavourite}
        handleClose={handleClose}
        show={show}
        selFav={selFav}
      />{" "}
    </div>
  );
};
export default ModuleFav;
