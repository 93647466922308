

const TitleBannerSection = (props) => { 
  return (
    <section className={`inner-p-banner ${props.classes}`}>
      <h1>{props.title}</h1>
    </section>
  )

}
export default TitleBannerSection