import React from "react";
import "./section.css";
import ChipComponent from "./chipComponent";
const ChipSection = ({
  sectionName,
  chipData,
  chipSelected,
  setChipSelected,
  isLoading,
  error,
}) => {
  return (
    <div className="feedback--section--one">
      <div className="section-one">
        <h3>{sectionName}</h3>
        <ChipComponent
          chipSelected={chipSelected}
          setChipSelected={setChipSelected}
          chipData={chipData || []}
        />
        {!chipSelected && !isLoading && (
          <p className="error-msg">No {error} found!</p>
        )}
      </div>
    </div>
  );
};

export default ChipSection;
