import { fork, take, call, put } from 'redux-saga/effects'

import {
    getCreditPointResponse
} from './actions'
import types from './types'
import { getAPI, postAPI, postAPIWithoutModule } from '../../api/generic'

export function* doGetCreditPoints() {
    while (true) {
        const { payload } = yield take(types.GET_CREDIT_POINTS)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getCreditPointResponse(response))
    }
}

function* creditPoints() {
    yield fork(doGetCreditPoints)
}

export default creditPoints