import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import {
  loginClear,
  loginBroker,
  loginBrokerClear,
  forgotPwdBroker,
  forgotPwdBrokerResponseClear,
  logoutClear,
} from "../redux/auth/actions";
import {
  applyTheme,
  clickStreamUser,
  emailValidation,
  LOGIN_CHANNEL,
  logoutClearSession,
  SessionKeys,
  UserIsLoggedIn,
} from "../utils/AppUtils";
import { userDetailBroker } from "../redux/user/actions";
import axios from "axios";
import CONFIG from "../utils/config";
import { objectToFormData } from "../api/data";
import { LinearProgress } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import ConfirmModal from "../components/modal/ConfirmModal";
import { myCart } from "../redux/Cart/actions";
import {
  getBannerCardSummary,
  setUserPremium,
  setUserRole,
} from "../redux/user/actions";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import $ from "jquery";
import _ from "lodash";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import {postAPIWithoutModule} from "../api/generic";
const LoginPage = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  var forgotRes = useSelector((state) => state.auth.forget_pwd_broker_response);
  const userDetailRes = useSelector((state) => state.user.userBroker);

  const [companyName, setCompanyName] = useState("");
  const [ssoEnabled, setSsoEnabled] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [forgetEmailError, setForgetEmailError] = useState("");
  const [isSentResetLink, setIsSentResetLink] = useState(false);
  const [formSuccess, setFormSuccess] = useState("");
  const [show, setShow] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [dayParts, setDayParts] = useState("morning");
  const [confirmModal, setConfirmModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState(
    "Profile updated successfully"
  );
  const [initialValue, setInitialValue] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    getGreetings();
    setCompanyName(window.location.host.split(".")[0]);
    window.runCookie();
    // i18n.changeLanguage('en')
  });

  useEffect(() => {
    const fetchCompanySettings = async () => {
      try {
        const result = await postAPIWithoutModule({
          module: "company-settings"
        });
        if(result.status==='success')
        {
          setSsoEnabled(result.payload.payload.enable_sso)
        }
      } catch (error) {
        console.error("Error fetching Company Settings:", error);
      }
    };
    fetchCompanySettings();
  }, []);

  useEffect(() => {
    clickStreamUser("login-page", {});
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("CompanyName", companyName);
  }, [companyName]);

  useEffect(() => {
    // console.log("userDetailRes: ", userDetailRes);
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
      console.log('TIMEZONE', userDetailRes)
    }
  }, [userDetailRes]);

  // useEffect(() => {
  //   console.log("formErr: ", formError + " " + formSuccess);
  //   if (formError || formSuccess) {
  //     setTimeout(() => {
  //       console.log("timeout");
  //       formError ? setFormError("") : setFormSuccess("");
  //     }, 5000);
  //   }
  // }, [formError, formSuccess]);

   useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (location.state?.logout) {
      setFormSuccess("You have been logged out successfully.");
      history.replace("");
    } else if (location.state?.relogin) {
      setFormSuccess("Please relogin to continue.");
      history.replace("");
    } else if (location.state?.canLogin) {
      setFormSuccess("Password is set successfully, login now.");
      history.replace("");
    } else if (location.state?.sessionExpired) {
      setFormSuccess("Session is expired, relogin to continue.");
      history.replace("");
    } else if (location.state?.resetTokenExpired) {
      setFormSuccess("Link already used.");
      history.replace("");
    } else if (query.get("login") === 'success') {
      setIsLoadingLogin(true);
      const queryParamsObj = {};
      for (let [key, value] of query.entries()) {
        queryParamsObj[key] = value;
      }
      // Call your Laravel API for proxy login
      dispatch(loginBroker({
        module: "ssoLogin",
        action: "proxy_login",
        postData: queryParamsObj
      }));
    }
  }, [location]);

  useEffect(() => {
    // console.log("location: ", window.location);
    // dispatch(loginClear())
    if (
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) != null &&
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== "" &&
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== undefined
    ) {
      if (localStorage.getItem(SessionKeys.IS_AGGREED) === "false") {
        history.push("/home");
      } 
      else {
        // history.push("/privacy-and-terms");
        history.push("/home");
      }
      history.push("/home");
    } else {
      if (
        localStorage.getItem(SessionKeys.IS_REMEMBERED) != null &&
        localStorage.getItem(SessionKeys.IS_REMEMBERED)
      ) {
        setEmail(localStorage.getItem(SessionKeys.EMAIL));
        setPassword(localStorage.getItem(SessionKeys.PASS));
        setInitialValue({
          email: localStorage.getItem(SessionKeys.EMAIL),
          password: localStorage.getItem(SessionKeys.PASS),
        })
      }
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== null &&
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== "" &&
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== undefined
    ) {
      // console.log("inside authBroker");
      if (authBroker.status === "success") {
        // console.log('auth: ', auth.payload)
        setFormSuccess(authBroker.payload.message);
        dispatch(
          userDetailBroker({
            module: "getOrderDetailBroker",
            apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          })
        );
        LOGIN_CHANNEL.onmessage = (event) => {
          if (event.data && event.data?.login) {
            window.location.reload();
          }
        }
        UserIsLoggedIn()
        const roleArray = authBroker.payload.user.role;
        if (_.isArray(roleArray)) {
          // console.log("USER ROLE", roleArray);

          if (roleArray.includes("realtor")) {
            dispatch(setUserRole("realtor"));
          } else if (roleArray.includes("manager")) {
            dispatch(setUserRole("manager"));
          } else if (roleArray.includes("pmpro_membership_manager")) {
            // console.log("USER ROLE PMPRO", roleArray);
            dispatch(setUserRole("broker"));
            localStorage.setItem(
              SessionKeys.BROKER_ID,
              authBroker.payload.user.user_id
            ); // IT WILL BE DYNAMIC
          } else if (roleArray.includes("instructor")) {
            dispatch(setUserRole("instructor"));
          } else if (roleArray.includes("host")) {
            dispatch(setUserRole("host"));
          } else if (roleArray.includes("broker_admin")) {
            dispatch(setUserRole("admin"));
          }
        } else {
          logoutClearSession();
          setFormError("Invalid user role.");
          setIsLoadingLogin(false);
          dispatch(loginClear());
          dispatch(loginBrokerClear());
        }

        const colorElement1 = authBroker.payload.user.branding_primary_color_1;
        const colorElement2 = authBroker.payload.user.branding_primary_color_2;
        $("html").css(
          "--color-element-1",
          colorElement1 ? colorElement1 : CONFIG.COLOR_ELEMENT_1
        );
        $("html").css(
          "--color-element-2",
          colorElement2 ? colorElement2 : CONFIG.COLOR_ELEMENT_2
        );
        window.captureMyEvents("loginSuccessfull", {
          email: authBroker.payload.user.email,
          userId: authBroker.payload.user.user_id,
        });

        if (authBroker.payload.user.isPremium == "1") {
          dispatch(setUserPremium(true));
        }

        const branding = authBroker.payload.user.branding;

        if (authBroker.payload.user.apply_theme) {
          !_.isEmpty(branding) && applyTheme(branding)
        } else {
          if ($('body').hasClass('cus-bro-theme')) {
            $('body').removeClass('cus-bro-theme')
          }
        }
        setTimeout(() => {
          if (localStorage.getItem(SessionKeys.IS_AGGREED) === "false") {
            history.push("/home");
          } 
          else {
            history.push("/home");
            // history.push("/privacy-and-terms");
          }
          // history.push("/home");
        }, 500);
      } else if (authBroker?.status === "error") {
        // console.log('authBroker', auth?.error?.message, authBroker?.error?.message)
        window.captureMyEvents("loginFailed", {
          message: authBroker?.error?.message,
          email: email,
        });
        logoutClearSession();
        setFormError(authBroker?.error?.message);
        setIsLoadingLogin(false);
        dispatch(loginClear());
        dispatch(loginBrokerClear());
      }
    }
    if (authBroker?.status === "error") {
      // console.log('authBroker', auth?.error?.message, authBroker?.error?.message)
      window.captureMyEvents("loginFailed", {
        message: authBroker?.error?.message,
        email: email,
      });
      logoutClearSession();
      setFormError(authBroker?.error?.message);
      setIsLoadingLogin(false);
      dispatch(loginClear());
      dispatch(loginBrokerClear());
    }
  }, [auth, history, authBroker]);

  useEffect(() => {
    setShowLoader(false);
    // console.log("forgotRes: ", forgotRes);
    if (
      forgotRes != null &&
      forgotRes !== undefined &&
      Object.entries(forgotRes).length > 0
    ) {
      switch (forgotRes.status) {
        case "success":
          setIsSentResetLink(true);
          dispatch(forgotPwdBrokerResponseClear());
          break;

        case "error" || false:
          // console.log("error", forgotRes.error.message);
          const msg = forgotRes.error.message ? forgotRes.error.message : "Error sending password reset link.";
          // setShowModalMsg(msg);
          // setConfirmModal(true);
          // setIsConfirm(false);
          // setForgetEmailError(msg)
          setIsSentResetLink(true);
          dispatch(forgotPwdBrokerResponseClear());
          break;

        default:
      }
      // return () => {
      //   console.log('clear')
      //   dispatch(forgotPwdResponseClear())
      // }
    }
  }, [forgotRes]);

  const getGreetings = () => {
    var date = new Date();
    var hours = date.getHours();
    if (hours >= 0 && hours < 12) {
      setDayParts("morning");
    } else if (hours >= 12 && hours < 16) {
      setDayParts("afternoon");
    } else if (hours >= 16 && hours < 24) {
      setDayParts("evening");
    }
  };

  const myFunction = () => {
    var x = document.getElementById("passInput");
    var y = document.getElementById("eyeImg");
    if (x.type === "password") {
      x.type = "text";
      y.src = "/images/view.png";
    } else {
      x.type = "password";
      y.src = "/images/private.png";
    }
  };

  const handleSubmit = (values) => {
    setFormSuccess("");
    // let email = values.email;
    // let password = values.password;
    let email = values.email;
    let password = values.password;
    
    dispatch(loginClear());
    if (isChecked) {
      localStorage.setItem(SessionKeys.IS_REMEMBERED, true);
      localStorage.setItem(SessionKeys.EMAIL, email);
      localStorage.setItem(SessionKeys.PASS, password);
    } else {
      localStorage.setItem(SessionKeys.IS_REMEMBERED, false);
      localStorage.setItem(SessionKeys.EMAIL, "");
      localStorage.setItem(SessionKeys.PASS, "");
    }
    // window.captureMyEvents("loginReq", {
    //   email,
    //   password,
    //   "login-btn": "LOG IN",
    // });
    setIsLoadingLogin(true);
    dispatch(
      loginBroker({
        action: "realtor_login",
        email: email,
        password: password,
      })
    );
  };

  function handleShow(value) {
    setShow(value);
  }

  const sendForgotPassLink = () => {
    if (emailValidation(forgetEmail)) {
      setForgetEmailError('');
      setShowLoader(true);
      const params = {
        "reset-email": forgetEmail,
        "pwdforgot-btn": "FORGOT PWD",
      };
      // console.log(params);
      // dispatch(forgotPwd(params))
      dispatch(
        forgotPwdBroker({
          module: "forgot-password-link",
          postData: {
            "reset-email": forgetEmail,
          },
        })
      );
    } else {
      // setShowModalMsg("Invalid email.");
      // setConfirmModal(true);
      // setIsConfirm(false);
      setForgetEmailError('Please enter valid email address.')
    }
  };

  const renderModal = () => {
    return (
      <Modal
        show={show}
        fullscreen={false}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "white" }}
        >
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "white" }}>
          <div className="fprdm">
            {showLoader ? <LinearProgress /> : ""}
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    {isSentResetLink ?
                      <div className="col backGround">
                        <div className="alert-forgot-message">
                          <span className="alertforgot-cion">i</span>
                          <span className="text-alert-msg">If we find a match, you'll get an email with further instructions.
                          If you don't hear from us in the next 15 minutes,
                          please double check that you entered the correct email address.</span>
                        </div>
                        
                      </div>
                      : <div className="col backGround">
                        <div className="pop_modal_body">
                          {/* <h4>Forgot Password</h4> */}
                          <p>
                            Lost your password? Please enter your Email Address, a
                            link will be sent to reset your password.
                          </p>
                          <div className="input-text position-relative">
                            <label htmlFor="email" className="input__label">
                              Email Address
                            </label>
                            <input
                              className="input__field passwordforgt_input"
                              id="email"
                              type="email"
                              placeholder="Enter your email address"
                              onChange={(e) => setForgetEmail(e.target.value)}
                              autoComplete='off'
                            />
                            <div className="mb-2 login-msg">
                              <div style={{ color: "red" }}>{forgetEmailError}</div>
                            </div>
                          </div>
                        </div>
                        <div className="forget_btn">
                          <button
                            className="btn btn-primary mt-2 mb-2"
                            onClick={() => sendForgotPassLink()}
                          >
                            Password Reset Link
                          </button>
                          <br />
                          <p>
                            Already have an account?{" "}
                            <a
                              href="#"
                              onClick={() => {
                                handleShow(false);
                              }}
                            >
                              Sign In{" "}
                            </a>
                          </p>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const stripeFormSchema = Yup.object().shape({
    email: Yup.string().required("Please enter email"),
    password: Yup.string().required("Please enter password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: (values) => {
      // console.log("value: ", values);
      handleSubmit(values.email, values.password);
    },
  });

  function ssoLogin() {
    window.location.href = CONFIG.API_URL_ADMIN + `auth/redirect`;
  }

  return (
    <>
      <div className="login-ary">
        <section className="login-sec ">
          <div className="login-container login">
            <div className="bannerlog-image d-block d-md-none image-login">
              <img className="img-fluid" src="images/login_img.jpg" />
            </div>
            <div className="header-border"></div>
            <div className="container-fluid">
              <div className="row bglogin">
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 ttxt">
                  <div className=" login-text">
                    <img src="/images/rps-logo.png" alt="images" />
                    <h1>{t("Welcome")}</h1>
                    <p className="ms-2">Beautiful {dayParts} isn't it !</p>
                    <h3>{t("Login")}</h3>
                    <div className="mb-2 login-msg">
                      {/* {formError && (<div>{formError}</div>)}
                      {formSuccess && (<div>{formSuccess}</div>)} */}
                      {formSuccess ? (
                        <div>{formSuccess}</div>
                      ) : formError ? (
                        <div style={{ color: "red" }}>{formError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Formik
                      initialValues={initialValue}
                      validationSchema={stripeFormSchema}
                      validateOnChange={false}
                      validateOnBlur={false}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="input-text ">
                              <label
                                htmlFor="password"
                                className="input__label"
                              >
                                {t("Email Address")}
                              </label>
                              <input
                                className="input__field"
                                name="email"
                                type="email"
                                placeholder="Enter your email address"
                                // value={email}
                                // onChange={(evt) => setEmail(evt.target.value)}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                required
                                autoComplete="off"
                              />
                            </div>
                            <div className="input-text ">
                              <label
                                htmlFor="password"
                                className="input__label"
                              >
                                {t("Password")}
                              </label>
                              <input
                                id="passInput"
                                className="input__field"
                                name="password"
                                type="password"
                                placeholder="Enter your password"
                                // value={password}
                                // onChange={(evt) => setPassword(evt.target.value)}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                required
                                autoComplete="off"
                              />
                              <div
                                className="show-pass"
                                onClick={() => {
                                  myFunction();
                                }}
                              >
                                <img
                                  id="eyeImg"
                                  src="/images/private.png"
                                  alt="view"
                                />
                              </div>
                            </div>

                            <div className="row form-row">
                              <div className="col-6 form-check cursor-pointer">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  onChange={(e) => {
                                    setIsChecked(e.target.checked);
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="flexCheckDefault"
                                >
                                  {t("Remember me")}
                                </label>
                              </div>
                              {/* <div className="col-6 text-right">
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setForgetEmailError('')
                                    setIsSentResetLink(false);
                                    handleShow(true);
                                  }}
                                  className="forgot-pwd"
                                >
                                  {t("Forgot Password?")}
                                </a>
                              </div> */}
                            </div>
                            <p className="">
                              <button
                                type="submit"
                                className={`btn btn-primary mt-4 ${isLoadingLogin ? "isLogin" : ""
                                  }`}
                                disabled={isLoadingLogin ? true : ''}
                              // onClick={() => { !isLoadingLogin && handleSubmit() }}
                              >
                                {isLoadingLogin ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  t("Login")
                                )}
                              </button>
                            </p>
                          </form>
                        );
                      }}
                    </Formik>
                    {ssoEnabled && <div className="hidden xl:flex items-center space-x-5">
                      <div className="hover:text-gray-200">
                        <button
                            type="button"
                            //  className="btn btn-primary mt-4"
                            className={`btn ${ssoEnabled && 'btn-primary'} mt-4`}

                            onClick={() => {

                              ssoLogin();
                            }}
                        >
                          {/* Login With SSO */}
                          {ssoEnabled &&'Login With SSO'}

                        </button>

                      </div>
                    </div>
                    }
                  </div>
                  <div className="d-none d-md-block footer-title">
                    @{new Date().getFullYear()} - NIIT - All Rights Reserved
                  </div>
                </div>
                <div className="col-xl-8 d-none d-md-block col-sm-12 col-md-7 col-lg-8 image-login">
                  <img
                    className="img-fluid"
                    src="images/login_img.jpg"
                    alt="home-scene-login"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {renderModal()}
      </div>
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={showModalMsg}
        isConfirm={isConfirm}
      />
    </>
  );
};

export default LoginPage;
