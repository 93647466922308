import { fork, take, call, put,all } from 'redux-saga/effects'

import {
  searchResponse,
} from './actions'
import types from './types'
import { getAPIWithID, postAPIWithoutModule } from '../../api/generic'


// export function* doSearch1() {
//   while (true) {
//     const { payload } = yield take(types.SEARCH)
//     const response = yield call(postAPIWithoutModule, payload)
//     yield put(searchResponse(response))
//   }
// }

export function* doSearch() {
  while (true) {
    const { payload } = yield take(types.SEARCH)
    // const response = yield call(postAPIWithoutModule, payload)
    const response = yield all([
      call(postAPIWithoutModule, payload.ecom),
      call(postAPIWithoutModule, payload.broker)
    ])
    yield put(searchResponse(response))
  }
}

function* search() {
  yield fork(doSearch)
}


export default search