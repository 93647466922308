import { combineReducers } from "redux";

import authReducer from "./auth/reducers";
import homeReducer from "./home/reducers";
import userReducer from "./user/reducers";
import coursesReducer from "./courses/reducers";
import productReducer from "./product/reducers";
import checkoutReducer from "./checkout/reducers";
import videoReducer from "./video/reducers";
import articleReducer from "./article/reducers";
import searchReducer from "./search/reducers";
import orderReducer from "./orders/reducers";
import cartReducer from "./Cart/reducers";
import creditPointsReducer from "./creditPoints/reducers";
import allEventReducer from "./events/reducers";
import certifiedReducer from "./certified/reducers";
import membershipReducer from "./membership/reducers";
import reportsReducer from "./reports/reducers";
import recommendationReducer from "./recommendation/reducers";
import recommendationViewReducer from "./recommendationView/reducers";
import rolePlayReducer from "./roleplay/reducers";
import certificateURLReducer from "./certificateURL/reducers";
import myNetworkReducer from "./myNetwork/reducers";
import notificationReducer from "./notification/reducers";
import assignCourseReducer from "./assignCourse/reducers"

const appReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  user: userReducer,
  courses: coursesReducer,
  product: productReducer,
  checkout: checkoutReducer,
  video: videoReducer,
  article: articleReducer,
  search: searchReducer,
  orders: orderReducer,
  carts: cartReducer,
  creditPoints: creditPointsReducer,
  events: allEventReducer,
  certified: certifiedReducer,
  membership: membershipReducer,
  report: reportsReducer,
  recommendation: recommendationReducer,
  recommendationView: recommendationViewReducer,
  rolePlay: rolePlayReducer,
  certificateURL: certificateURLReducer,
  myNetwork: myNetworkReducer,
  notification: notificationReducer,
  assignCourse: assignCourseReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_CLEAR") {
    const { routing } = state;
    state = { routing };
  }
  return appReducer(state, action);
};

export default rootReducer;
