import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Page from "../../components/Page";
import CenterLine from "../../components/CenterLine";
import ShoppingSection from "../../components/Shopping/ShoppingSection";
import { getCourseDetail } from "../../redux/product/actions";
import { clickStreamUser } from "../../utils/AppUtils";
import { useParams } from "react-router-dom";
import RecommendProductCard from "../../components/Product/RecommendProductCard";
import Loader from "../../components/Loader/Loader";
import _, { parseInt } from "lodash";

const ShoppingPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const { courseId } = useParams();

  //   const recommendationInsertViewData = useSelector(
  //     (state) => state.recommendationView
  //   );
  //   console.log("RRR--->", recommendationViewData);

  //   const [recommendInsertView, setInsertRecommendView] = useState([]);

  //   useEffect(() => {
  //     console.log(
  //       "RecommendInsertView",
  //       recommendationInsertViewData?.recommendationInsertViewData?.payload
  //         ?.payload
  //     );
  //     if (!_.isEmpty(recommendationInsertViewData)) {
  //       if (
  //         recommendationInsertViewData.recommendationInsertViewData.status ===
  //         "success"
  //       ) {
  //         const list2 =
  //           recommendationInsertViewData.recommendationViewData.payload.payload;
  //         if (!_.isEmpty(list2)) {
  //           setInsertRecommendView(
  //             recommendationInsertViewData.recommendationViewData.payload.payload
  //           );
  //         }
  //       }
  //     }
  //   }, [recommendationInsertViewData]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!_.isEmpty(authBroker)) {
      dispatch(
        getCourseDetail({
          module: "getCourseDetail",
          courseId: courseId,
          email: authBroker?.payload?.user?.email,
        })
      );
      clickStreamUser("shopping_page", {
        userId: auth.authBroker?.payload?.user.user_id,
      });
    }
    // dispatch(
    //   recommendationInsertViewData({
    //     module: "myInsertViewRecommendation",
    //     courseId: courseId,
    //     email: authBroker?.payload?.user?.email,
    //   })
    // );
  }, [dispatch, authBroker]);

  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <ShoppingSection
          getCourseDetail={getCourseDetail}
          setProductLoading={setIsLoading}
        />
        {/* <CenterLine />
            <RelatedContentShopping /> */}

        <div className="container-recom">
          <RecommendProductCard
            courseId={courseId}
            courseIdArr={[]}
            from={"shopping"} />
        </div>
      </Page>
    </>
  );
};

export default ShoppingPage;
