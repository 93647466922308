import types from './types'

// Courses Categories
export const getVideoCourseCategories = (payload) => ({ type: types.VIDEO_COURSE_CATEGORIES, payload })
export const getVideoCourseCategoriesResponse = (payload) => ({ type: types.VIDEO_COURSE_CATEGORIES_RESPONSE, payload })
export const getVideoCourseCategoriesClear = () => ({ type: types.VIDEO_COURSE_CATEGORIES_CLEAR })

// Video Listing
export const getVideoCoursesListing = (payload) => ({ type: types.VIDEO_COURSES_LISTING, payload })
export const getVideoCoursesListingResponse = (payload) => ({ type: types.VIDEO_COURSES_LISTING_RESPONSE, payload })
export const getVideoCoursesListingClear = () => ({ type: types.VIDEO_COURSES_LISTING_CLEAR })


// Video Listing
export const getVideoCoursesListingByCat = (payload) => ({ type: types.VIDEO_COURSES_LISTING_BY_CAT, payload })
export const getVideoCoursesListingByCatResponse = (payload) => ({ type: types.VIDEO_COURSES_LISTING_BY_CAT_RESPONSE, payload })
export const getVideoCoursesListingByCatClear = () => ({ type: types.VIDEO_COURSES_LISTING_BY_CAT_CLEAR })

// Video Listing
export const getVideoCoursesListingBySubCat = (payload) => ({ type: types.VIDEO_COURSES_LISTING_BY_SUB_CAT, payload })
export const getVideoCoursesListingBySubCatResponse = (payload) => ({ type: types.VIDEO_COURSES_LISTING_BY_SUB_CAT_RESPONSE, payload })
export const getVideoCoursesListingBySubCatClear = () => ({ type: types.VIDEO_COURSES_LISTING_BY_SUB_CAT_CLEAR })

// Video Listing
export const getVideoCoursesListingRelated = (payload) => ({ type: types.VIDEO_COURSES_LISTING_RELATED, payload })
export const getVideoCoursesListingRelatedResponse = (payload) => ({ type: types.VIDEO_COURSES_LISTING_RELATED_RESPONSE, payload })
export const getVideoCoursesListingRelatedClear = () => ({ type: types.VIDEO_COURSES_LISTING_RELATED_CLEAR })

// Video Detail from Ecom
export const getVideoDetailListing = (payload) => ({ type: types.VIDEO_DETAIL_LISTING, payload })
export const getVideoDetailListingResponse = (payload) => ({ type: types.VIDEO_DETAIL_LISTING_RESPONSE, payload })
export const getVideoDetailListingClear = () => ({ type: types.VIDEO_DETAIL_LISTING_CLEAR })
//video list from Broker
export const getBrokerVideoListing = (payload) => ({ type: types.VIDEO_DETAIL_BROKER_ADMIN, payload })
export const getBrokerVideoListingResponse = (payload) => ({ type: types.VIDEO_DETAIL_BROKER_ADMIN_RESPONSE, payload })
export const getBrokerVideoListingClear = () => ({ type: types.VIDEO_DETAIL_BROKER_ADMIN_CLEAR })
//VIDEO DETAIL LIST FROM BROKER ADMIN
export const getBrokerVideoDetail = (payload) => ({ type: types.VIDEO_INFO_BROKER_ADMIN, payload })
export const getBrokerVideoDetailResponse = (payload) => ({ type: types.VIDEO_INFO_BROKER_ADMIN_RESPONSE, payload })
export const getBrokerVideoDetailClear = () => ({ type: types.VIDEO_INFO_BROKER_ADMIN_CLEAR })

//VIDEO Completion
export const getVideoCompt = (payload) => ({ type: types.VIDEO_COMPLETION_TRACK, payload })
export const getVideoComptResponse = (payload) => ({ type: types. VIDEO_COMPLETION_TRACK_RESPONSE, payload })
export const getVideoComptClear = () => ({ type: types.VIDEO_COMPLETION_TRACK_CLEAR })

export const getMyVideos = (payload) => ({type: types.MY_VIDEO_FETCH, payload})
export const getMyVideosResponse = (payload) => ({type: types.MY_VIDEO_RESPONSE, payload})
export const getMyVideosClear = () => ({type: types.MY_VIDEO_CLEAR})