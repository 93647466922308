import { useEffect } from "react";
import Page from "../../components/Page";
import ChangePasswordSection from "../../components/profile/ChangePasswordSection";
import ReportDashboardSection from "../../components/Reports/ReportDashboardSection";

const ChangePasswordPage = () => {

  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
        document.getElementById("id_page_404").style.display = "none";
    }
}, [])

  return (<>
      <Page>
<ChangePasswordSection />
    </Page>

  </>
  );
};

export default ChangePasswordPage;
