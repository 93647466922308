import {
  React,
  useState,
  useEffect,
  useCallback,
} from "react";
import Page from "../../components/Page";
import Loader from "../../components/Loader/Loader";
import MainCalendarSection from "../../components/Calendar/MainCalendarSection";

const CalendarEvents = (event) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <MainCalendarSection 
          setIsLoading={setIsLoading}
          isLoading={isLoading} 
          isMobile={false}
        />
      </Page>
    </>
  );
};

export default CalendarEvents;
