import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAssignBrokerCourse,
  getAssignBrokerCourseClear,
  getAssignCourse,
  getAssignCourseClear,
  getSelectedCatId,
  showBuyModal,
  setMarkView, setMarkViewClear
} from "../../redux/courses/actions";
import BuyProductModal from "../modal/BuyProductModal";
import ConfirmModal from "../modal/ConfirmModal";
import Slider from "react-slick/";
import Carousel from "react-bootstrap/Carousel";
import { ProgressBar } from "react-bootstrap";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import PrevArrow from "./PrevArrow";
import NextArrow from "./NextArrow";
import _ from "lodash";
import { courseDetailResponse } from "../../redux/product/actions";
import ImageComponent from "../Layouts/ImageComponent";
import ProviderComponent from "../Layouts/ProviderComponent";
import { setBreadcrumbStack } from "../../redux/home/actions";

const SliderCourseListing = (props) => {
  const {
    coursesList,
    pageType,
    setFavoriteCourse,
    closeModal,
    setCloseModal,
    hasDescription,
    showBlankCard,
    isOnboarding,
    id,
    path
  } = props;

  const coursesListData = coursesList.map((item, index) => {
    const course_hours = Math.floor(item.period / 60);
    const course_minutes = item.period % 60;
    return {
      ...item,
      course_hours,
      course_minutes
    };
  });
  
  console.log("assignCours123444e", coursesListData);
  const dispatch = useDispatch();
  const history = useHistory();

  const [courseData, setCourseData] = useState({});
  const auth = useSelector((state) => state.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const assignCourse = useSelector((state) => state.courses.assignCourse);
  const assignBrokerCourse = useSelector((state) => state.courses.assignBrokerCourse);
  const breadcrumbStack = useSelector((state) => state.home.breadcrumbStack)
  const [confirmModal, setConfirmModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [showModalMsg] = useState(
    "Are you sure you want to set this course as favorite?"
  );
  const [videoData, steVideoData] = useState({})
  const markView = useSelector((state) => state.courses.markView);

  useEffect(() => {
    dispatch(showBuyModal({ isShow: false }));
  }, []);

  useEffect(() => {
    // console.log("assignCourse", assignCourse);
    if (assignCourse?.status === "success") {
      dispatch(getAssignCourseClear());
      // console.log("courseData", courseData);
      if (!_.isEmpty(courseData)) {
        if (props.path) {
          if (!_.isEmpty(breadcrumbStack) && props.index != 0) {
            let stack = _.cloneDeep(breadcrumbStack)
            console.log('set up stack', stack)
            dispatch(setBreadcrumbStack(stack.push(props.path)))
          } else {
            let stack = []
            console.log('set up stack', stack)
            dispatch(setBreadcrumbStack([props.path]))
          }
        }
        // let url = `/moduleDetail/${courseData?.crscd}/?source=${courseData?.source}&cid=${}`;
        console.log(path)

        goTo(courseData?.crscd, courseData?.source, courseData.id ? courseData.id : courseData.wc_post_id);
        // history.push(url);
      }
    }
  }, [assignCourse]);

  useEffect(() => {
    if (assignBrokerCourse?.status === "success" && !_.isEmpty(courseData)) {
      // `/moduleDetail/${course?.crscd}/?source=${course?.source}`
      dispatch(getAssignBrokerCourseClear());
      // history.push(
      //   `/moduleDetail/${courseData?.crscd}/?source=${courseData?.source}&cid=${courseData.id ? courseData.id : courseData.wc_post_id}`
      // );
    }
  }, [assignBrokerCourse])

  const setCourseDetailData = (course) => {
    let tempObj = {
      payload: {
        course_code: course.crscd,
        course_discount: course.discount,
        course_duration: course.duration,
        course_lessons: course.lessons,
        course_period: course.durationUnit,
        course_views: course.views,
        product_description: course.long_description,
        product_id: course.id,
        product_name: course.name,
        product_price: course.market_price,
        product_regular_price: course.market_price,
        product_sale_price: course.selling_price,
        product_short_description: course.short_description,
        product_slug: course.name,
        product_thumbnail: course.imageURL,
        product_total_sales: course.market_price,
        sfdc_product_type: course.sfdc_product_type,
        course_list: _.isArray(course.course_list) ? course.course_list : [],
      },
      status: "success",
    };
    dispatch(courseDetailResponse(tempObj));
  };

  const goTo = (crscd, source, cid) => {
    console.log('GOTO', path, id)
    console.log(crscd, "crscd123");
    if (crscd != null && crscd != "") {
      let url = `/moduleDetail/${crscd}/?source=${source}&cid=${cid}`;
      if (path === '/learning-paths') {
        url = url + `&via_lp=true&lp_id=${id}`
      }
      console.log(url, "url1233");
      history.push(url);
    }
    // return;

  }

  const redirectToPage = (course) => {
    // console.log("course data", course, course.source);
    // return;
    if (course?.entity_type === "video") {
      console.log("course redirect to module");
      redirectToVideoPage(course)
    }
   else if (course?.is_level) {
      history.push('/levelDetail?program_id=' + course?.prgm_id + '&level_id=' + course?.id + '&via_lp=true&lp_id=' + id)
    } else if (course?.source === "ecom" && !course?.is_paid) {
      setCourseDetailData(course);
      history.push(`/shopping/${course.id}/?addToCart=${course?.is_addtocart}`);
    } else if (course?.is_paid === undefined || course?.is_paid) {
      clickStreamUser("course_card_click_goto_course", {
        courseId: course?.id,
        courseName: course?.name,
        userId: authBroker?.payload?.user.user_id,
        courseCode: course?.crscd,
      });
      setCourseData(course);
      if (course?.source === "broker" || course?.origin === 'broker') {
        // console.log("course redirect to module");
        dispatch(
          getAssignBrokerCourse({
            module: "assignedCourseBroker",
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
              entity_type: "courses",
              entity_id: course.id
                ? _.toNumber(course?.id)
                : _.toNumber(course?.wc_post_id),
              origin: "broker",
              number_of_days: "",
              is_type: "free",
            },
          })
        );
        goTo(course?.crscd, course?.source, course.id ? course.id : course.wc_post_id);
        // history.push(
        //   `/moduleDetail/${course?.crscd}/?source=${course?.source}&cid=${course.id ? course.id : course.wc_post_id}`
        // );
      } else {
        // console.log("course redirect to assign");
        dispatch(
          getAssignCourse({
            module: "assign-course",
            postData: {
              user_id: authBroker?.payload?.user.user_id,
              course_id: course?.crscd,
            },
          })
        );
        dispatch(
          getAssignBrokerCourse({
            module: "assignedCourseBroker",
            postData: {
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
              entity_type: "courses",
              entity_id: course?.crscd,
              origin: "xsel",
              number_of_days: course?.course_days,
              is_type: course?.is_free ? "free" : "paid",
              comment: course?.name,
            },
          })
        );
      }
    }
  };

  // const renderPremiumTag = (course) => {
  //     return (
  //         course.isPremium ?
  //         <div className="d-flex justify-content-end revisit_icon ">
  //             <div className="">
  //                 <button type="button"
  //                     className="btn btn-primary btn-block setup-button"
  //                     onClick={() => {
  //                         dispatch(getSelectedCatId(course.categoryId));
  //                         dispatch(showBuyModal({objCourse:course,
  //                         isShow:true}))
  //                     }}>Buy</button>
  //             </div>
  //         </div>
  //         : ''
  //     )
  // }

  const renderPremiumTag = (course) => {
    return course.is_paid !== undefined && !course.is_paid ? (

      <div className={course?.is_addtocart ? "d-flex justify-content-between revisit_icon buTN_buy added-in-cart" : "d-flex justify-content-between revisit_icon buTN_buy"}>
        <div className="calendar_event">
          <button
            type="button"
            className="btn btn-primary-LR  setup-button"
            onClick={() => {
              // console.log("COURSEDATA", course);
              setCourseDetailData(course);
              history.push(
                `/shopping/${course.id}?addToCart=${course?.is_addtocart}`
              );
            }}
          >
            {course?.is_addtocart ? "Added in cart" : "Buy"}
          </button>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    dots: true,
    arrows: true,
    autoplaySpeed: 2000,
    infinite: false,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3

        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const renderCourseCard = (course, i) => {
    return (
      <>
        <div className="broker-box">
          <div className="img-box_nw">
            <div
              className={`img-videosec learn_image-card ${course.isLock
                ? "cursor-not-allowed"
                : "cursor-pointer"
                }`}
              onClick={() => {
                if (!course.isLock) {
                  dispatch(getSelectedCatId(course.categoryId));
                  redirectToPage(course);
                }
              }}
              title={(course.is_manager_checklist && course.isLock) ? 'Notification sent to your manager, please discuss before moving forward.' : ""}
            >
              {pageType === "course" ? (
                <>
                  {/* <img
                    className={`img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""
                      }`}
                    src={course.imageURL}
                    onError={({currentTarget}) => {
                      currentTarget.onerror = true;
                      currentTarget.src = "/images/default-demo-img.jpg"
                  }}
                  /> */}
                  <ImageComponent
                    name={course.name}
                    thumbnail={course.imageURL ? course.imageURL : ""}
                    cssClass={`img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""}`}
                    errorClass={`d-flex justify-content-center align-items-center bk-grey img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""}`}
                  />
                  {course.crs_progress !== undefined &&
                    course.crs_progress != null &&
                    course.crs_progress > 0 ? (
                    <a href="#">
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#premium-contentpopu"
                      >
                        <span className="lear_icon">
                          <img
                            src={
                              course.crs_progress >= 100
                                ? "/images/check.png"
                                : "/images/forward.png"
                            }
                          />
                        </span>
                      </span>
                    </a>
                  ) : (
                    ""
                  )}
                  {course.isLock ? (
                    <span className="lear_icon">
                      <img src="/images/Layer_1.png"
                        alt="lock"
                        title={course.is_manager_checklist ? 'Notification sent to your manager, please discuss before moving forward.' : ""} />
                    </span>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <video width="100%" height="128" controls="">
                  <source src={course.mp4Link} type="video/mp4" />
                  <source src={course.oggLink} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              )}
              <ProgressBar
                className="custom_progress_barr custom_bartwoRealtor"
                variant="success"
                now={course.crs_progress ? course.crs_progress : 0}
              />
            </div>

            <div className="details-card_nw">
              <h5
                className={`${course.isLock
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
                onClick={() => {
                  if (!course.isLock) {
                    dispatch(getSelectedCatId(course.categoryId));
                    redirectToPage(course);
                  }
                }}
              >
                <a
                  className="course-head-al_search"
                  title={course.name}
                  data-toggle="tooltip"
                  data-html="true"
                  dangerouslySetInnerHTML={{
                    __html:
                      course.name.length < 33
                        ? course.name
                        : course.name.substring(0, 33) + "...",
                  }}
                ></a>
              </h5>

              <div className="row info-bottm-card_nw">
                <div className="col-md-12 cou_cardIcon">
                  
                  {!course?.is_level ? <div className="fgf">
                    {course?.lessons != "" &&
                      <p>
                        <span>{course.lessons} </span>
                        {course.lessons == 1 ? "Module" : "Modules"}
                      </p>
                    }


                  </div> : <div className="fgf">
                    {(course.course_count != "") &&
                      <p>
                        <span>{course.course_count} </span>
                        {course.course_count == 1 ? "Course" : "Courses"}
                      </p>
                    }

                  </div>
                  }
                  <div className="box-codd">
                    {
                      (course?.course_hours > 0 || course?.course_minutes > 0) ? (
                        <div className="carouseL_Iconn">
                          <div className="ico__imG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                id="clock-time-four"
                                d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                transform="translate(-2 -2)"
                                fill="var(--color-element-1)"
                              />
                            </svg>
                          </div>
                          <div className="ico_texxt">
                            <p>
                              {" "}

                              <span>
                                {/* {" "}
                                    <span>{course.duration}</span>{" "}
                                    {course.durationUnit} */}
                                {course?.course_hours > 0
                                  ? course?.course_hours + " " + (course?.course_hours > 1 ? "Hours" : "Hour")
                                  : " "}{" "}
                                {course?.course_minutes > 0
                                  ? course?.course_minutes +
                                  " " +
                                  (course?.course_minutes > 1 ? "Mins" : "Min")
                                  : " "}{" "}

                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                        </>
                      )
                    }

                    {!course?.is_level ? <div className="carouseL_Iconn">
                      <div className="ico__imG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            id="eye-circle"
                            d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                            transform="translate(-2 -2)"
                            fill="var(--color-element-1)"
                          />
                        </svg>
                      </div>
                      <div className="ico_texxt">
                        <p>
                          <span>{course.views}</span> View
                          {course.views > 1 ? "s" : ""}
                        </p>
                      </div>
                    </div> : ''}
                  </div>
                </div>
              </div>

              <div className="row infoIcon_nw">
                <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                  {/* {course?.provider?.provider_img !== "" ? (
                    <img
                      src={course?.provider?.provider_img}
                      alt=""
                      title={course?.provider.provider_name}
                    />
                  ) : (
                    <div className="d-flex carouseL_IconnBY">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Exclusion_3"
                          data-name="Exclusion 3"
                          d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                          transform="translate(-21473.998 951.002)"
                          fill="var(--color-element-1)"
                          opacity="1"
                        ></path>
                      </svg>
                      <p>
                        {" "}
                        <span
                          className="sub_txt_lern"
                          title={
                            course?.provider?.provider_display_name
                          }
                        >
                          {course?.provider?.provider_display_name?.substring(
                            0,
                            10
                          ) + "..."}
                        </span>
                      </p>
                    </div>
                  )} */}
                  <ProviderComponent providerData={course?.provider} />
                  {course.source !== "ecom" || course.is_paid ? (
                    <div
                      className={`d-flex justify-content-between revisitIconcon comp4530 ${course.isLock
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                        }`}
                      onClick={() => {
                        if (!course.isLock) {
                          dispatch(getSelectedCatId(course.categoryId));
                          redirectToPage(course);
                        }
                      }}
                      title={course.is_manager_checklist && course.isLock ? 'Notification sent to your manager, please discuss before moving forward.' : ""}
                    >
                      <img
                        src={
                          course.crs_progress === undefined ||
                            course.crs_progress == null ||
                            course.crs_progress === 0
                            ? "/images/Component 46-78.svg"
                            : course.crs_progress >= 100
                              ? "/images/Component 4427.svg"
                              : "/images/Component 4530.svg"
                        }
                        width="80"
                        height="17"
                        alt=""
                      />
                    </div>
                  ) : (
                    renderPremiumTag(course)
                  )}
                </div>
              </div>
            </div>
          </div>
          {hasDescription ? (
            <div
              className="learn-content"
              title={course.description}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    course.description.length < 100
                      ? course.description
                      : course.description.substring(0, 100) +
                      "...",
                }}
                title={
                  course.description.length < 100
                    ? course.description
                    : course.description.substring(0, 100) + "..."
                }
                data-toggle="tooltip"
                data-html="true"
              ></p>
            </div>
          ) : (
            ""
          )}
        </div>
        <ConfirmModal
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
          showModalMsg={showModalMsg}
          isConfirm={isConfirm}
          confirmId={courseId}
          confirmAction={setFavoriteCourse}
        />
        <BuyProductModal />
      </>
    )
  }

  const renderSliderCourseCard = (course, i) => {
    return (
      <>
        <div
          className="broker-box"
          key={"course-" + i}
        >
          <div id={course?.id} className="img-box_nw position-relative">
            <div className="combo-tag">
              <i className="fa fa-star" aria-hidden="true"></i> COMBO
            </div>
            <Carousel id="courseCardCarousel">
              {course.course_list.map((listData, index) => {
                return (
                  <Carousel.Item key={"listdata-" + index}>
                    <div
                      className="img-videosec learn_image-card cursor-pointer"
                      onClick={() => {

                        if (!course.isLock) {
                          dispatch(getSelectedCatId(course.categoryId));
                          redirectToPage(course);
                        }
                      }}
                    >
                      {pageType === "course" || pageType === 'myCourse' ? (
                        <>
                          {/* <img
                            className="img-fluid galleryImg_lern"
                            src={listData.imageURL}
                            onClick={() => {
                              if (!course.isLock) {
                                dispatch(getSelectedCatId(course.categoryId));
                                redirectToPage(course);
                              }
                            }}
                            onError={({currentTarget}) => {
                              currentTarget.onerror = true;
                              currentTarget.src = "/images/default-demo-img.jpg"
                          }}
                          /> */}
                          <ImageComponent
                            name={listData.name}
                            thumbnail={listData.imageURL}
                            cssClass={"img-fluid galleryImg_lern"}
                            errorClass={"img-fluid galleryImg_lern"}
                          />
                        </>
                      ) : (
                        <video width="100%" height="128" controls="">
                          <source src={listData.mp4Link} type="video/mp4" />
                          <source src={listData.oggLink} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>

                    <div className="details-card_nw">
                      <h5
                        className="cursor-pointer"
                        onClick={() => {
                          if (!course.isLock) {
                            dispatch(getSelectedCatId(course.categoryId));
                            redirectToPage(course);
                          }
                        }}
                      >
                        <a
                          className="course-head-al_search"
                          title={listData.name}
                          dangerouslySetInnerHTML={{
                            __html:
                              listData.name.length < 33
                                ? listData.name
                                : listData.name.substring(0, 33) + "...",
                          }}
                        ></a>
                      </h5>
                      <div className="row info-bottm-card_nw">
                        <div className="col-md-12 cou_cardIcon">
                          <p>
                            <span>{listData.lessons} </span>Modules
                          </p>

                          <div className="carouseL_Iconno2 d-flex flex-column">

                            {
                              listData.duration > 0 && (
                                <div className="ico__imG01 d-flex">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      id="clock-time-four"
                                      d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                      transform="translate(-2 -2)"
                                      fill="var(--color-element-1)"
                                    />
                                  </svg>
                                  <p>
                                    {" "}
                                    <span>{listData.duration}</span>{" "}
                                    {listData.durationUnit}
                                  </p>
                                </div>
                              )
                            }

                            <div className="ico_texxt02 d-flex pt-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="eye-circle" d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z" transform="translate(-2 -2)" fill="var(--color-element-1)"></path></svg>
                              <p>
                                <span>{listData.views}</span> View
                                {listData.views > 1 ? "s" : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row infoIcon_nw">
                        <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                          {/* {course?.provider?.provider_img !== "" ? (
                            <img src={course?.provider?.provider_img} alt="" /> //"/images/card_x_icon.png"
                          ) : (
                            <div className="d-flex carouseL_IconnBY">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  id="Exclusion_3"
                                  data-name="Exclusion 3"
                                  d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                                  transform="translate(-21473.998 951.002)"
                                  fill="var(--color-element-1)"
                                  opacity="1"
                                ></path>
                              </svg>
                              <p>
                                {" "}
                                <span
                                  className="sub_txt_lern"
                                  title={
                                    course?.provider?.provider_display_name
                                  }
                                >
                                  {course?.provider?.provider_display_name?.length > 11
                                    ? course?.provider?.provider_display_name?.substring(
                                      0,
                                      11
                                    ) + "..."
                                    : course?.provider?.provider_display_name}
                                </span>
                              </p>
                            </div>
                          )} */}
                          <ProviderComponent providerData={listData?.provider} />

                          {course.source !== "ecom" ||
                            course.is_paid ||
                            !course.showBuy ? (
                            <div
                              className={`d-flex justify-content-between revisitIconcon comp4530 ${course.isLock
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                                }`}
                              onClick={() => {
                                if (!course.isLock) {
                                  dispatch(getSelectedCatId(course.categoryId));
                                  redirectToPage(course);
                                }
                              }}
                            >
                              <img
                                src={
                                  course.crs_progress === undefined ||
                                    course.crs_progress == null ||
                                    course.crs_progress === 0
                                    ? "/images/Component 46-78.svg"
                                    : course.crs_progress >= 100
                                      ? "/images/Component 4427.svg"
                                      : "/images/Component 4530.svg"
                                }
                                width="80"
                                height="17"
                                alt=""
                              />
                            </div>
                          ) : (
                            renderPremiumTag(course)
                          )}
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
            <div className="position-absolute course-length-bar">
              <span>{course.course_list.length}</span> item{course.course_list.length > 1 ? 's' : ''}
            </div>
          </div>
          {hasDescription ? (
            <div className="learn-content">
              <p>
                {course.description.length < 100
                  ? course.description
                  : course.description.substring(0, 100) + "..."}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        <ConfirmModal
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
          showModalMsg={showModalMsg}
          isConfirm={isConfirm}
          confirmId={courseId}
          confirmAction={setFavoriteCourse}
        />
        <BuyProductModal />
      </>
    );
  };
  const redirectToVideoPage = (m) => {
    console.log("coursedataaa",m);
    if (!m.is_paid && m.source === 'xsel') {
        history.push(`/shopping/${m.product_id}?addToCart=${m?.is_addtocart}&source=videos`)
        clickStreamUser("buy-button-clicked", {
            userId: authBroker?.payload?.user.user_id,
            title: m?.product_name
        });
    } else {
        clickStreamUser("view-button-clicked", {
            userId: authBroker?.payload?.user.user_id,
            title: m?.product_name
        });
        steVideoData(m)
        dispatch(setMarkView({
            module: 'setMarkView',
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                entity_type: 'videos',
                entity_id: (m?.source === "xsel") ? m?.course_code : m?.crscd,
                origin: (m?.source === "xsel") ? "xsel" : "broker",
                is_type: m?.is_paid ? 'paid' : 'free',
                comment: (m?.source === "xsel") ? m?.name : ""
            }
        }))
        
        let courseId = (m.source === "xsel") ? m.course_code : m.product_id
        history.push(`/video-view-page/${m?.product_id
        }/?source=${m?.source}&code=${m?.product_id
        }&catId=${m?.product_id
        }`)
    }       
}
  return (
    <div className="slider-course-listing">
      <Slider {...settings}>
        {coursesListData?.length === 0
          ? "No courses found"
          : coursesListData?.map((course, i) => {
            if (course?.name) {
              return (
                <div key={"course-" + i} className="boxslidersp">
                  {
                    course.is_bundle ?
                      renderSliderCourseCard(course, i) :
                      renderCourseCard(course, i)
                  }

                </div>
              );
            } else if (showBlankCard) {
              return (
                <div key={"course-" + i} className="boxslidersp">
                  <div className="broker-box">
                    <div className="disable_Card">
                      <div className="img-box_telescope">
                        <img
                          src="/images/telescope.svg"
                          height="56"
                          width="60"
                          alt=""
                        />
                        {/* <div className=" col-md-12  telescope_text d-flex justify-content-center">
                          <p
                            className="cursor-pointer"
                            onClick={() => {
                              dispatch(getSelectedCatId(""));
                              history.push(
                                `/courseListing?selectCourse=top`
                              );
                            }}
                          >
                            Explore Courses
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </Slider>
    </div>
  );
};

export default SliderCourseListing;
