import types from "./types";

const initialState = {
  notificationData: {},
  notificationAllReadData: {},
  notificationSingleData: {},
  notificationCountData: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATION_RESPONSE:
      return {
        ...state,
        notificationData: action.payload,
      };
    case types.NOTIFICATION_CLEAR:
      return {
        ...state,
        notificationData: {},
      };
    case types.NOTIFICATION_ALL_READ_RESPONSE:
      return {
        ...state,
        notificationAllReadData: action.payload,
      };
    case types.NOTIFICATION_ALL_READ_CLEAR:
      return {
        ...state,
        notificationAllReadData: {},
      };
    case types.NOTIFICATION_SINGLE_RESPONSE:
      return {
        ...state,
        notificationSingleData: action.payload,
      };
    case types.NOTIFICATION_SINGLE_CLEAR:
      return {
        ...state,
        notificationSingleData: {},
      };
    case types.NOTIFICATION_COUNT_RESPONSE:
      return {
        ...state,
        notificationCountData: action.payload,
      };
    case types.NOTIFICATION_COUNT_CLEAR:
      return {
        ...state,
        notificationCountData: {},
      };
    default:
      return state;
  }
};
