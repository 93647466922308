import React, { useState } from "react";
import "./section.css";

const FeedbackSectionTwo = ({
  starSelected,
  setStarSelected,
  userComment,
  setUserComment,
  errors,
  questions,
  setQuestionId,
  questionId,
  selectedRatings,
  setSelectedRatings,
  learnerFeedbackPopup,
  setErrors,
}) => {
  const [rating, setRating] = useState();
  const handleRatingChange = (questionId, rating) => {
    const existingRatingIndex = selectedRatings.findIndex(
      (selectedRating) => selectedRating.question_id === questionId
    );

    if (existingRatingIndex !== -1) {
      const updatedRatings = [...selectedRatings];
      updatedRatings[existingRatingIndex].rating = rating;
      setSelectedRatings(updatedRatings);
    } else {
      setSelectedRatings([
        ...selectedRatings,
        { question_id: questionId, rating: rating },
      ]);
    }
  };
  const handleStarClick = (starIndex, id) => {
    setErrors({ userComment: false });
    handleRatingChange(id, starIndex + 1);
    setRating(starIndex + 1);
  };

  const renderStars = (id) => {
    const stars = [];
    for (let starIndex = 0; starIndex < 5; starIndex++) {
      const starImage =
        selectedRatings.length &&
        selectedRatings.some(
          (selectedRating) =>
            selectedRating.question_id === id &&
            starIndex < selectedRating.rating
        )
          ? true
          : false;
      stars.push(
        starImage ?  <img
        onClick={() =>handleStarClick(starIndex, id)}
        src="/images/fillStar.svg"
        alt="Star"
        width="26"
        height="25"
        style={{ marginRight: 15 }}
        /> :
        <img
        onClick={() => handleStarClick(starIndex, id)}
        src="/images/star.svg"
        alt="Star"
        width="26"
        height="25"
        style={{ marginRight: 15 }}
      />
      );
    }
    return stars;
  };
  const handleStar = (starIndex) => {
    setErrors({ userComment: false });
    setStarSelected(starIndex + 1);
  };
  const renderCommonStars = () => {
    const stars = [];
    console.log(starSelected, "startSetlected");
    for (let starIndex = 0; starIndex < 5; starIndex++) {
      const starImage = starIndex < starSelected ? true : false;
      stars.push(
        starImage ?  <img
          onClick={() => handleStar(starIndex)}
          src="/images/fillStar.svg"
          alt="Star"
          width="26"
          height="25"
          style={{ marginRight: 15 }}
        /> :
        <img
        onClick={() => handleStar(starIndex)}
        src="/images/star.svg"
        alt="Star"
        width="26"
        height="25"
        style={{ marginRight: 15 }}
      />
      );
    }
    return stars;
  };

  return (
    <div className="feedback--section--two">
      {!questions ? (
        <div className="section-one">
          <div className="emoji_container">
            {!learnerFeedbackPopup ? (
              <h3 className="mb-3">
                How was your overall experience with this learning platform?
              </h3>
            ) : (
              <h3>Provide rating to learner</h3>
            )}
            <div className="rating_align">{renderCommonStars()}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {questions?.length &&
        questions.map((item, index) => {
          return (
            <div key={item?.id} className="section-one">
              <div className="emoji_container">
                <h3>{item?.question?.replace(".", "") || ""}</h3>
                <div className="emoji--row">
                  <div className="rating_align">{renderStars(item?.id)}</div>
                </div>
              </div>
            </div>
          );
        })}

      <div className="section-two mt-3">
        <h3 className="mb-2">
          Add Comments<span style={{ color: 'black', fontSize: 'small' }}> *</span>
        </h3>
        <div className="text-area--box">
          <textarea
            onChange={(e) => {
              const { target } = e;
              target.style.height = "auto";
              target.style.height = `${target.scrollHeight}px`;
              setUserComment(e.target.value);
            }}
            placeholder="Comments..."
            maxLength={250}
            value={userComment}
          />
          <p className="max-count">Max 250 Characters</p>
        </div>
        {errors?.userComment && (
          <p className="error--msg-new">{errors?.error}</p>
        )}
      </div>
    </div>
  );
};

export default FeedbackSectionTwo;
