const types = {
    // Get all events
    GET_ALL_CERTIFIED_NIIT: "GET_ALL_CERTIFIED_NIIT",
    GET_ALL_CERTIFIED_NIIT_RES: "GET_ALL_CERTIFIED_NIIT_RES",
    GET_ALL_CERTIFIED_NIIT_CLEAR: "GET_ALL_CERTIFIED_NIIT_CLEAR",

    //Get event details
    GET_CERTIFIED_DETAILS_NIIT: "GET_CERTIFIED_DETAILS_NIIT",
    GET_CERTIFIED_DETAILS_NIIT_RES: "GET_CERTIFIED_DETAILS_NIIT_RES",
    GET_CERTIFIED_DETAILS_NIIT_CLEAR: "GET_CERTIFIED_DETAILS_NIIT_CLEAR",
    // Sessions info
    CERTIFIED_SESSION_LIST_NIIT: "CERTIFIED_SESSION_LIST_NIIT",
    CERTIFIED_SESSION_LIST_NIIT_RES: "CERTIFIED_SESSION_LIST_NIIT_RES",
    CERTIFIED_SESSION_LIST_NIIT_CLEAR: "CERTIFIED_SESSION_LIST_NIIT_CLEAR",

    // Get all events for broker
    GET_ALL_CERTIFIED_BROKER: "GET_ALL_CERTIFIED_BROKER",
    GET_ALL_CERTIFIED_BROKER_RES: "GET_ALL_CERTIFIED_BROKER_RES",
    GET_ALL_CERTIFIED_BROKER_CLEAR: "GET_ALL_CERTIFIED_BROKER_CLEAR",

    //Get event details for broker
    GET_CERTIFIED_DETAILS_BROKER: "GET_CERTIFIED_DETAILS_BROKER",
    GET_CERTIFIED_DETAILS_BROKER_RES: "GET_CERTIFIED_DETAILS_BROKER_RES",
    GET_CERTIFIED_DETAILS_BROKER_CLEAR: "GET_CERTIFIED_DETAILS_BROKER_CLEAR",
    // Sessions info for broker
    CERTIFIED_SESSION_LIST_BROKER: "CERTIFIED_SESSION_LIST_BROKER",
    CERTIFIED_SESSION_LIST_BROKER_RES: "CERTIFIED_SESSION_LIST_BROKER_RES",
    CERTIFIED_SESSION_LIST_BROKER_CLEAR: "CERTIFIED_SESSION_LIST_BROKER_CLEAR",
    // enroll session for broker
    CERTIFIED_ENROLL_BROKER_SESSION: "CERTIFIED_ENROLL_BROKER_SESSION",
    CERTIFIED_ENROLL_BROKER_SESSION_RES: "CERTIFIED_ENROLL_BROKER_SESSION_RES",
    CERTIFIED_ENROLL_BROKER_SESSION_CLEAR: "CERTIFIED_ENROLL_BROKER_SESSION_CLEAR",
     // get learning certificate for broker
     CERTICICATE_GET_BROKER: "CERTICICATE_GET_BROKER",
     CERTICICATE_GET_BROKER_RES: "CERTICICATE_GET_BROKER_RES",
     CERTICICATE_GET_BROKER_CLEAR: "CERTICICATE_GET_BROKER_CLEAR",
     // get learning badges for broker
     BADGES_GET_BROKER: "BADGES_GET_BROKER",
     BADGES_GET_BROKER_RES: "BADGES_GET_BROKER_RES",
     BADGES_GET_BROKER_CLEAR: "BADGES_GET_BROKER_CLEAR",
}
export default types;