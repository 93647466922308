import { fork, take, call, put, all } from "redux-saga/effects";

import { recommendationViewResponse } from "./actions";
import { recommendationInsertViewResponse } from "./actions";
import types from "./types";
import { postAPIWithoutModule } from "../../api/generic";

export function* doViewRecommendation() {
  while (true) {
    const { payload } = yield take(types.RECOMMENDATION_VIEW);

    const response = yield call(postAPIWithoutModule, payload);

    yield put(recommendationViewResponse(response));
  }
}

export function* doInsertViewRecommendation() {
  while (true) {
    const { payload } = yield take(types.RECOMMENDATION_INSERT_VIEW);
    // console.log("5555:");
    const response = yield call(postAPIWithoutModule, payload);
    // console.log("Recommended Insert View Response is:", response);
    yield put(recommendationInsertViewResponse(response));
  }
}

function* recommendationView() {
  yield fork(doViewRecommendation);
  yield fork(doInsertViewRecommendation);
}

export default recommendationView;
