import { useEffect } from "react";
// import { Modal,Button } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import VideoPlayer from "react-video-js-player";
const VideoModal = (props) => {

    const {
        confirmModal,
        setConfirmModal,
        videoData
    } = props

    const closeBtn = <i className="fa fa-times cursor-pointer" aria-hidden="true"
    onClick={setConfirmModal}
></i>

    return (
        <Modal isOpen={confirmModal} toggle={setConfirmModal} 
        backdrop={true} keyboard={true}
        className="alert-modal cl-video video-modal modal-lg" centered>
            <ModalHeader toggle={setConfirmModal} closeBtn={closeBtn}>
                {videoData?.title}</ModalHeader>
            <ModalBody>
            <VideoPlayer
                controls={true}
                src={videoData?.url}
                className="video-player-modal"
                width={'100%'}
            />
            </ModalBody>
        </Modal>
    )
}

export default VideoModal;