import {
  boardcastDataResponse,
  cancelSessionData,
  hostSession,
  joinSession,
  sessionAttendence,
  sessionWatchURLDataResponse,
  setEnrollBrokerSessionResponse,
} from "../../redux/events/actions";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getEnrollBrokerSession } from "../../redux/events/actions";
import { cartPageDataResponse } from "../../redux/checkout/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import _ from "lodash";
import VideoModal from "../../components/modal/VideoModal";
import { addToCart } from "../../redux/product/actions";
class CustomEvent extends Component {

  constructor(props) {
    super(props);
    // console.log('props', props)
    this.state = {
      popoverOpen: false,
      confirmModal: false,
      videoData: {},
    };
    this.toggle = this.toggle.bind(this);
    this.categorySelect = this.categorySelect.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  toggleConfirmModal = () => {
    this.setState({
      confirmModal: !this.state.confirmModal,
      videoData: {},
    });
  };

  categorySelect(category) {
    this.props.event.onCategorize(this.props.event, category);
    this.setState({ popoverOpen: false });
  }

  joinSession(eventData) {

    if (eventData.is_boardcast) {
      this.boardcastSession(eventData);
    }
    else if(eventData.join_url){
			window.open(eventData.join_url, "_blank");
		}
    else if(eventData.is_Zoom_integration == "1") {
      if (eventData.meetingId) {
        eventData.showLoader();
        this.props.joinZoomSession({
          module: "joinZoomMeeting",
          postData: {
            user_id: eventData.userId,
            meeting_id: eventData.meetingId,
            session_id: eventData.sessionId,
            segment_id: eventData.segmentId,
          },
        });
      }    
    }
    else {
      eventData.showAlert("Host has not yet started the meeting.");
    }  
  }

  boardcastSession(eventData) {
    //console.log(eventData)
    this.props.boardcastInfo(eventData);
    eventData.isMobile ?
    eventData.history.push("/session/broadcast/mobile/watch")
    : eventData.history.push("/session/broadcast/watch")
  }

  registerSession(eventData) {
    // console.log('call to register event', eventData)
    eventData.showLoader();
    this.setState({ popoverOpen: false });
    clickStreamUser("register-button-clicked", {
      event_id: eventData.eventId,
      session_id: eventData.session_id,
      session_name: eventData.title,
      email: localStorage.getItem(SessionKeys.EMAIL),
      type: eventData.source === "broker" ? "broker" : "xsel",
    });
    this.props.enrollSession({
      module: "enrollBrokerSession",
      postData: {
        token: eventData.isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        session_id: eventData.sessionId,
        event_id: eventData.eventId,
        type: eventData.source === "broker" ? "broker" : "xsel",
      },
    });
  }

  proceedToBook(eventData) {
    // console.log('call to proceed to book', this.props)
    eventData.showLoader();
    // return;
    clickStreamUser("proceed-to-book-session", {
      event_id: eventData.eventId,
      session_id: eventData.sessionId,
      session_name: eventData.title,
      email: localStorage.getItem(SessionKeys.EMAIL),
    });
    eventData.history.push(
      `/shopping/${eventData.productId}?addToCart=${eventData.is_addToCart}&source=session`
    );
  }

  cancelSession(eventData) {
    eventData.cancelSessionConfirmation(eventData)
    // if (eventData.source === 'broker') {

    // } else {
    //   this.props.cancelSessionInfo({
    //     module: "sessionCancel",
    //     postData: {
    //       token: eventData.isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    //       session_id: eventData.sessionId,
    //       event_id: eventData.eventId,
    //     },
    //   });
    // }

  }

  watchSession(eventData) {
    this.props.watchSessionInfo({
      id: eventData.sessionId,
      title: eventData.title,
      watch_url: eventData.watch_url,
    });
    eventData.history.push("/session/watch");
  }

  hostSession(eventData) {
    if (eventData.is_boardcast) {
      this.boardcastSession(eventData);
    } else {
      if(eventData.start_url){
        window.open(eventData.start_url, "_blank");
      }else if(eventData.is_Zoom_integration === "1"){
      this.props.startHost({
        module: "createZoomMeeting",
        postData: {
          user_id: eventData.userId,
          host_zoom_id: eventData.hostZoomId,
          meeting_topic: eventData.title,
          meeting_agaenda: eventData.title,
          meeting_startdate: moment
            .utc(eventData.startUTC)
            .format("YYYY-MM-DD HH:mm"),
          duration: _.toNumber(eventData.durationValue),
          session_id: eventData.sessionId,
          segment_id: eventData.segmentId,
        },
      });
     }
     else{
      eventData.showAlert("Not able to start the meeting.");  
     }
    }
  }

  renderButton() {

    if (this.props.event.is_registered && this.props.event.is_participant && !this.props.event.is_host) {
      console.log('this.props.event1233',this.props.event.is_host)
      console.log(this.props.event.is_host,"this.props.is_host123")
      if (this.props.event.delivery_mode === "face_to_face") {
        const startTime = moment(`${this.props.event.start}`).subtract(
          30,
          "minutes"
        );
        const endTime = moment(`${this.props.event.end}`);
        const currentTime = moment(this.props.event.current_time);
        if (currentTime.isBefore(startTime)) {
          return (
            <>
              {/* <button className={"btn-join active"}>View</button> */}
              {/* <button
                className={"btn-join active"}
                onClick={() => this.cancelSession(this.props.event)}
              >
                Cancel
              </button> */}
            </>
          )
        } else {
          return (
            <>
              {/* <button className={"btn-join active"}>View</button> */}
            </>
          )
        }
      } else if (this.props.event.tense === "past") {
        if (this.props.event.watch_url) {
          return (
            <button
              className={"btn-join active"}
              onClick={() => this.watchSession(this.props.event)}
            >
              Watch
            </button>
          );
        } else {
          return <>{/* Session has ended. {this.props.event.watch_url} */}</>;
        }
      } else if (this.props.event.tense === "future") {
        return (
          <>
            <button className={"btn-join"}>Join</button>
            {/* <button
              className={"btn-join active"}
              onClick={() => this.cancelSession(this.props.event)}
            >
              Cancel
            </button> */}
          </>
        );
      } else {
        const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startTime = moment(`${this.props.event.start}`).subtract(
          30,
          "minutes"
        );
        const endTime = moment(`${this.props.event.end}`);
        const currentTime = moment(this.props.event.current_time);
        /**
         * Current time should be between startTime (ie. half hour before actual start time)
         * and actual endTime
         */
        if (currentTime.isBetween(startTime, endTime)) {
          return (
            <>
              <button
                className={"btn-join active"}
                onClick={() => this.joinSession(this.props.event)}
              >
                Join
              </button>
            </>
          );
        } else if (currentTime.isAfter(endTime)) {
          if (this.props.event.watch_url) {
            return (
              <button
                className={"btn-join active"}
                onClick={() => this.watchSession(this.props.event)}
              >
                Watch
              </button>
            );
          }
        } else {
          return (
            <>
              <button className={"btn-join"}>Join</button>
              {/* <button
                className={"btn-join active"}
                onClick={() => this.cancelSession(this.props.event)}
              >
                Cancel
              </button> */}
            </>
          );
        }
      }
    } else if (this.props.event.is_host) {
      if (this.props.event.delivery_mode === "face_to_face") {
        return (
          <>
            {/* <button className={"btn-join active"}>View</button> */}
          </>
        )
      } else if (this.props.event.tense === "future") {
        return (
          <>
            <button className={"btn-join"}>Host</button>
          </>
        );
      } else if (this.props.event.tense === "present") {
        const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const startTime = moment(`${this.props.event.start}`).subtract(
          1,
          "hour"
        );
        const endTime = moment(`${this.props.event.end}`);
        const currentTime = moment(this.props.event.current_time);
        /**
         * Current time should be between startTime (ie. one hour before actual start time)
         * and actual endTime
         */
        if (currentTime.isBetween(startTime, endTime)) {
          return (
            <button
              className={"btn-join active"}
              onClick={() => this.hostSession(this.props.event)}
            >
              Host
            </button>
          );
        } else {
          return <button className={"btn-join"}>Host</button>;
        }
      }
    } else {
      if (this.props.event.tense === "past") {
        return <>{
          /* Session has ended. */
        }</>;
      } else {
        if (_.toNumber(this.props.event.selling_price) === 0) {
          if (
            this.props.event.userRole === "instructor" ||
            this.props.event.userRole === "host" || 
            this.props.event.userRole === "admin" || 
            this.props.event.userRole === "broker"
          ) {
            const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = moment(`${this.props.event.start}`).subtract(
              1,
              "hour"
            );
            const endTime = moment(`${this.props.event.end}`);
            const currentTime = moment(this.props.event.current_time);

            if (currentTime.isBetween(startTime, endTime)) {
              return (
                <button
                  className={"btn-join active"}
                  onClick={() => this.joinSession(this.props.event)}
                >
                  Join
                </button>
              );
            } else {
              return (
                <button
                  className={"btn-join"}
                  onClick={() => this.joinSession(this.props.event)}
                >
                  Join
                </button>
              );
            }
          } else {
            return (
              <button
                className={"btn-join active"}
                onClick={() => this.registerSession(this.props.event)}
              >
                Register
              </button>
            );
          }
        } else {
          if (this.props.event.source === 'broker') {
            return (
              <button
                className={"btn-join active"}
                onClick={() => this.registerSession(this.props.event)}
              >
                Register
              </button>
            );
          } else {
            return (
              <>
                {/* <button
                  className={"btn-join active"}
                  onClick={() => this.registerSession(this.props.event)}
                >
                  Register
                </button> */}
                {/* <button className={'btn-join active'}
                  onClick={() => this.proceedToBook(this.props.event)}>
                    Book
                  </button> */}
              </>
            );
          }
        }
      }
      // console.log('EVENT CLICKED NOT PAID', this.props.event.title)
    }
  }

  renderCalendarCard = () => {
    const endTime = moment(`${this.props.event.end}`);
    const currentTime = moment(this.props.event.current_time);
    return (
      <div className="event-title"
        // onMouseEnter={this.toggle}
        // onMouseLeave={this.toggle}
        onMouseDown={() => this.toggle()}
        // onClick={this.toggle}
        id={`popover-${this.props.event.sessionId}-${this.props.event.segmentId}`}
      >
          {
      this.props.event?.delivery_mode === "face_to_face" ? (
        <>
        <div className="event_block">
        <span className="event_session_color">In Person</span>
        </div>
       
        </>
      ): this.props.event?.delivery_mode === "virtual_interactive" ? (
       <>
       <div className="event_block">
       <span className="event_session_color">VILT</span>
       </div>
      
       </>
      ):
      this.props.event?.delivery_mode === "virtual_broadcast" ? (
        <>
        <div className="event_block">
        <span className="event_session_color">VBT</span>
        </div>
       
        </>
       ):
     
      <>
      </>
     }
        <div className="d-flex justify-content-between flex-column w-100 event-block">
          <div>
            {this.props.event.title}
          </div>
          <div className="mt-1 d-flex eventbtn-cal">
            {this.renderButton()}
            <div className="d-flex flex-column gap-2">
              {!(this.props.event.is_paid || this.props.event.is_registered) &&
                (this.props.event.selling_price != 0 && !this.props.event.isMobile)
                && this.props.event.tense !== 'past'
                ? (
                  <>
                    <span className="popover-price">
                      ${this.props.event.selling_price.split(".")[0]}
                      <sup>
                        <b>{this.props.event.selling_price.split(".")[1]}</b>
                      </sup>&nbsp;CAD
                    </span>
                    <button
                      className={"btn-join active"}
                      onClick={() => this.proceedToBook(this.props.event)}
                    >
                      {this.props.event.is_addToCart ? 'Added in Cart' : 'Book'}
                    </button>
                  </>
                ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <VideoModal
          confirmModal={this.state.confirmModal}
          setConfirmModal={this.toggleConfirmModal}
          videoData={this.state.videoData}
        />
        {this.renderCalendarCard()}

        <Popover
          placement="right"
          className="popover-container"
          isOpen={this.state.popoverOpen}
          target={`popover-${this.props.event.sessionId}-${this.props.event.segmentId}`}
          // toggle={this.toggle}
          
        >
          <PopoverHeader className="events-popup">
            {this.props.event.title}
          </PopoverHeader>
          <PopoverBody className="events-popup_body">
            <div className="d-flex gap-2 align-items-start mb-2">
              <div className="side-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.105"
                  height="21.105"
                  viewBox="0 0 11.105 11.105"
                >
                  <path
                    id="clock-time-four"
                    d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z"
                    transform="translate(-2 -2)"
                    fill="var(--color-element-1)"
                  />
                </svg>
              </div>
              <span className="side-text">
                {moment(`${this.props.event.sessionStartDate}`).format(
                  "DD MMM YYYY"
                ) +
                  " at " +
                  moment(`${this.props.event.sessionStartDate}`).format(
                    "hh:mm A"
                  ) +
                  " " +
                  this.props.event.actualTimezoneAbbr}
              </span>
            </div>
            <div className="d-flex gap-2 align-items-start mb-2">
              <div className="side-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.105"
                  height="21.105"
                  viewBox="0 0 11.105 11.105"
                >
                  <path
                    id="clock-time-four"
                    d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z"
                    transform="translate(-2 -2)"
                    fill="var(--color-element-1)"
                  />
                </svg>
              </div>
              <div className="side-text">
                <span>
                  {moment(`${this.props.event.end}`).format(
                    "DD MMM YYYY"
                  ) +
                    " at " +
                    moment(`${this.props.event.end}`).format(
                      "hh:mm A"
                    ) +
                    " " +
                    this.props.event.actualTimezoneAbbr}
                </span>
              </div>
            
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="side-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.623"
                  height="22.159"
                  viewBox="0 0 8.623 12.159"
                >
                  <g
                    id="Icon_feather-map-pin"
                    data-name="Icon feather-map-pin"
                    transform="translate(-4.5 -1.499)"
                  >
                    <path
                      id="Exclusion_6"
                      data-name="Exclusion 6"
                      d="M15192.567,408.817h0c-.043-.045-4.309-4.523-4.309-7.844a4.312,4.312,0,1,1,8.623,0c0,3.317-4.27,7.8-4.312,7.844Zm0-9.6a1.752,1.752,0,1,0,1.75,1.754A1.757,1.757,0,0,0,15192.571,399.218Z"
                      transform="translate(-15183.758 -395.16)"
                      fill="var(--color-element-1)"
                    />
                  </g>
                </svg>
              </div>
              <div className="side-text text-capitalize">
  
                {this.props.event.delivery_mode === "face_to_face" ? this.props.event.location
                  : this.props.event.tense === "past"
                    ? this.props.event.watch_url
                      ? "Recorded"
                      : "Live"
                    : "Live"}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div>
                {!(
                  this.props.event.is_paid || this.props.event.is_registered
                ) &&
                  !this.props.event.is_host &&
                  this.props.event.selling_price != 0 ? (
                  <>
                    <span className="popover-price">
                      ${this.props.event.selling_price} CAD
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
      
            </div>
            <div style={{marginLeft:"20%"}}>  {this.renderButton()}</div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    joinZoomSession: (data) => {
      dispatch(joinSession(data));
    },
    enrollSession: (data) => {
      dispatch(getEnrollBrokerSession(data));
    },
    payForSession: (data) => {
      dispatch(setEnrollBrokerSessionResponse(data));
    },
    cartPageResponse: (data) => {
      dispatch(cartPageDataResponse(data));
    },
    sessionMarkAttendence: (data) => {
      dispatch(sessionAttendence(data));
    },
    cancelSessionInfo: (data) => {
      dispatch(cancelSessionData(data));
    },
    addToCartData: (data) => {
      dispatch(addToCart(data));
    },
    startHost: (data) => {
      dispatch(hostSession(data));
    },
    watchSessionInfo: (data) => {
      dispatch(sessionWatchURLDataResponse(data));
    },
    boardcastInfo: (data) => {
      dispatch(boardcastDataResponse(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    state: state.auth.authBroker,
    userDetail: state.user.userBroker?.payload?.payload,
    isPremiumUser: state.user.isPremiumUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEvent);
