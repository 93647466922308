import { useEffect, useState } from "react";
import Page from "../../components/Page";
import _ from "lodash";
import CertifyDetailSection from "../../components/Certify/CertifyDetailSection";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader"

const CertifiedDetailPage = () => {
  
  const certifyDetailRes = useSelector(state => state.certified.certifyDetail);
  const certifyDetailResBroker = useSelector(state => state.certified.certifyDetailBroker);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    if (!_.isEmpty(certifyDetailRes) || !_.isEmpty(certifyDetailResBroker)) {
      setIsLoading(false)
    }
  }, [certifyDetailRes, certifyDetailResBroker])


  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <CertifyDetailSection 
          setIsLoading={setIsLoading}
        />
      </Page>
    </>
  )
}

export default CertifiedDetailPage;