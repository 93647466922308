import data from '../../data/product'
import types from './types'

const initialState = {
  checkoutSummary: {},
  paymentIntent: {},
  piDirectCheckout: {},
  orderPlacedData: {},
  sendOrderPlace: {},
  cartPageData: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECKOUT_SUMMARY_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        checkoutSummary: action.payload
      }
    case types.CHECKOUT_SUMMARY_CLEAR:
      return {
        ...state,
        checkoutSummary: {}
      }
    case types.PAYMENT_INTENT_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        paymentIntent: action.payload
      }
    case types.PAYMENT_INTENT_CLEAR:
      return {
        ...state,
        paymentIntent: {}
      }
    case types.PI_DIRECT_CHECKOUT_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        piDirectCheckout: action.payload
      }
    case types.PI_DIRECT_CHECKOUT_CLEAR:
      return {
        ...state,
        piDirectCheckout: {}
      }
    case types.SEND_ORDER_PLACE_RESPONSE:
    case types.ZERO_SEND_ORDER_PLACE_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        sendOrderPlace: action.payload
      }
    case types.SEND_ORDER_PLACE_CLEAR:
    case types.ZERO_SEND_ORDER_PLACE_CLEAR:
      return {
        ...state,
        sendOrderPlace: {}
      }
    case types.ORDER_PLACED_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        orderPlacedData: action.payload
      }
    case types.ORDER_PLACED_CLEAR:
      return {
        ...state,
        orderPlacedData: {}
      }
    case types.CART_PAGE_DATA_RESPONSE:
      // console.log(action.payload)
      return {
        ...state,
        cartPageData: action.payload
      }
    case types.CART_PAGE_DATA_CLEAR:
      return {
        ...state,
        cartPageData: {}
      }

    default:
      return state
  }
}



