const types = {
  //MY CART DETAIL
  MY_CART: 'MY_CART',
  MY_CART_RESPONSE: 'MY_CART_RESPONSE',
  MY_CART_CLEAR: 'MY_CART_CLEAR',
  // MY_CART : "MY_CART"
  MY_CART_DELETE: "MY_CART_DELETE",
  MY_CART_DELETE_RESPONSE: "MY_CART_DELETE_RESPONSE",
  MY_CART_DELETE_CLEAR: "MY_CART_DELETE_CLEAR",
  // MY_CART : "SAVE_LATER"
  SAVE_LATER: "SAVE_LATER",
  SAVE_LATER_RESPONSE: "SAVE_LATER_RESPONSE",
  SAVE_LATER_CLEAR: "SAVE_LATER_CLEAR",
  // MY_CART : "SAVE_LATER_VIEW"
  SAVE_LATER_VIEW: "SAVE_LATER_VIEW",
  SAVE_LATER_VIEW_RESPONSE: "SAVE_LATER_VIEW_RESPONSE",
  SAVE_LATER_VIEW_CLEAR: "SAVE_LATER_VIEW_CLEAR",
  // MY_CART : "DEL_SAVE_LATER_VIEW"
  DEL_SAVE_LATER: "DEL_SAVE_LATER",
  DEL_SAVE_LATER_RESPONSE: "DEL_SAVE_LATER_RESPONSE",
  DEL_SAVE_LATER_CLEAR: "DEL_SAVE_LATER_CLEAR",
  // LEARNING POINTS
  LEARNING_POINTS: "LEARNING_POINTS",
  LEARNING_POINTS_RESPONSE: "LEARNING_POINTS_RESPONSE",
  LEARNING_POINTS_CLEAR: "LEARNING_POINTS_CLEAR",

  // CREDIT POINTS
  CREDIT_POINTS: "CREDIT_POINTS",
  CREDIT_POINTS_RESPONSE: "CREDIT_POINTS_RESPONSE",
  CREDIT_POINTS_CLEAR: "CREDIT_POINTS_CLEAR",
  // DYNAMIC BANNER
  DYNAMIC_BANNER: "DYNAMIC_BANNER",
  DYNAMIC_BANNER_RESPONSE: "DYNAMIC_BANNER_RESPONSE",
  DYNAMIC_BANNER_CLEAR: "DYNAMIC_BANNER_CLEAR",

  // DYNAMIC BANNER BROKER
  DYNAMIC_BANNER_BROKER: "DYNAMIC_BANNER_BROKER",
  DYNAMIC_BANNER_BROKER_RESPONSE: "DYNAMIC_BANNER_BROKER_RESPONSE",
  DYNAMIC_BANNER_BROKER_CLEAR: "DYNAMIC_BANNER_BROKER_CLEAR",

  // COUPON CODE
  COUPON_CODE_APPLY: "COUPON_CODE_APPLY",
  COUPON_CODE_APPLY_RES: "COUPON_CODE_APPLY_RES",
  COUPON_CODE_APPLY_CLEAR: "COUPON_CODE_APPLY_CLEAR",

  LOGOUT_CLEAR: "LOGOUT_CLEAR"

}
export default types;