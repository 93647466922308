import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import {
  login, loginClear, forgotPwd,
  forgotPwdResponseClear, loginBroker,
  loginBrokerClear, loginHostInst, loginHostInstClear,
  forgotPwdBroker, forgotPwdBrokerResponseClear
} from '../redux/auth/actions'
import { clickStreamUser, emailValidation, logoutClearSession, SessionKeys } from '../utils/AppUtils'
import axios from 'axios';
import CONFIG from '../utils/config';
import { objectToFormData } from '../api/data';
import { LinearProgress } from '@mui/material';
import Spinner from 'react-bootstrap/Spinner';
import ConfirmModal from '../components/modal/ConfirmModal';
import { myCart } from '../redux/Cart/actions';
import { setUserPremium, setUserRole } from '../redux/user/actions';
import { useTranslation } from 'react-i18next'
import i18n from '../i18n';
import $ from "jquery";

const LoginHostInstPage = () => {

  const { t } = useTranslation()

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authHostInst = useSelector(state => state.auth.authHostInst)
  var forgotRes = useSelector(state => state.auth.forget_pwd_broker_response)

  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState('')
  const [formSuccess, setFormSuccess] = useState('')
  const [show, setShow] = useState(false)
  const [forgetEmail, setForgetEmail] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [dayParts, setDayParts] = useState('morning');
  const [confirmModal, setConfirmModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState('Profile updated successfully');
  const [forgetEmailError, setForgetEmailError] = useState("");
  const [isSentResetLink, setIsSentResetLink] = useState(false);

  useEffect(() => {
    getGreetings();
    setCompanyName(window.location.host.split('.')[0])
    // i18n.changeLanguage('en')
  })

  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])

  useEffect(() => {
    clickStreamUser('pageview', {
      pageName: 'login-host-inst-page',
    })
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('CompanyName', companyName)
  }, [companyName])

  useEffect(() => {
    // console.log('formErr: ', formError + ' ' + formSuccess)
    if (formError || formSuccess) {
      setTimeout(() => {
        // console.log("timeout")
        formError ? setFormError('') : setFormSuccess('');
      }, 5000)
    }
  }, [formError, formSuccess])

  useEffect(() => {
    if (location.state?.logout) {
      setFormSuccess('You have been logged out successfully.')
      history.replace('')
    } else if (location.state?.relogin) {
      setFormSuccess('Please relogin to continue.')
      history.replace('')
    } else if (location.state?.canLogin) {
      setFormSuccess('Password is set successfully, login now.')
      history.replace('')
    } else if (location.state?.sessionExpired) {
      setFormSuccess('Session is expired, relogin to continue.')
      history.replace('')
    }
  }, [location])

  useEffect(() => {
    // console.log('location: ', window.location)
    // if (localStorage.getItem(SessionKeys.API_TOKEN_BROKER) != null && 
    // localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== '' && 
    // localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== undefined) {
    //   history.push('/home')
    // } else {
    //   if (localStorage.getItem(SessionKeys.IS_REMEMBERED) != null && localStorage.getItem(SessionKeys.IS_REMEMBERED)) {
    //     setEmail(localStorage.getItem(SessionKeys.EMAIL))
    //     setPassword(localStorage.getItem(SessionKeys.PASS))
    //   }
    // }
  }, [])

  useEffect(() => {
    // console.log('auth: authBroker', authHostInst)
    if (
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== null
      && localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== ''
      && localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== undefined
    ) {
      // console.log('inside authHostInst')
      if (authHostInst.status === 'success') {
        setFormSuccess(authHostInst.payload.message)
        const role = authHostInst.payload.user.role[0];
        dispatch(setUserRole(role))
        // const colorElement1 = authHostInst.payload.user.branding_primary_color_1;
        // const colorElement2 = authHostInst.payload.user.branding_primary_color_2;
        // $('html').css("--color-element-1", colorElement1);
        // $('html').css("--color-element-2", colorElement2);
        window.captureMyEvents("loginSuccessfull", {
          "email": authHostInst.payload.user.email,
          "userId": authHostInst.payload.user.user_id,
        });
        setTimeout(() => {
          role === 'host' ? history.push('/calendar/host') : history.push('/calendar/instructor')
        }, 500)
      } else if (authHostInst?.status === 'error') {
        window.captureMyEvents("loginFailed", {
          "message": authHostInst?.error?.message,
          "email": email
        })
        logoutClearSession();
        setFormError(authHostInst?.error?.message)
        setIsLoadingLogin(false)
        dispatch(loginHostInstClear())
      }
    } if (authHostInst?.status === 'error') {
      window.captureMyEvents("loginFailed", {
        "message": authHostInst?.error?.message,
        "email": email
      })
      logoutClearSession();
      setFormError(authHostInst?.error?.message)
      setIsLoadingLogin(false)
      dispatch(loginHostInstClear())
    }
  }, [authHostInst])

  useEffect(() => {
    setShowLoader(false)
    // console.log('forgotRes: ', forgotRes)
    if (forgotRes != null && forgotRes !== undefined && Object.entries(forgotRes).length > 0) {
      switch (forgotRes.status) {
        case 'success':
          // console.log('forgotRes: ', forgotRes.payload)
          setIsSentResetLink(true);
          // setShowModalMsg('Password reset link has been sent to your email.')
          // setConfirmModal(true);
          // setIsConfirm(false);
          // setShow(false)
          dispatch(forgotPwdBrokerResponseClear())
          break;

        case 'error' || false:
          // console.log('error', forgotRes.error.message)
          const msg = forgotRes.error.message ? forgotRes.error.message : "Error sending password reset link.";
          // setShowModalMsg(msg);
          // setConfirmModal(true);
          // setIsConfirm(false);
          // setForgetEmailError(msg)
          setIsSentResetLink(true);
          dispatch(forgotPwdBrokerResponseClear())
          break;

        default: ;
      }
      // return () => {
      //   console.log('clear')
      //   dispatch(forgotPwdResponseClear())
      // }
    }
  }, [forgotRes])

  const getGreetings = () => {
    var date = new Date();
    var hours = date.getHours();
    if (hours >= 0 && hours < 12) {
      setDayParts('morning')
    } else if (hours >= 12 && hours < 16) {
      setDayParts('afternoon')
    } else if (hours >= 16 && hours < 24) {
      setDayParts('evening')
    }
  }

  const myFunction = () => {
    var x = document.getElementById("passInput");
    var y = document.getElementById("eyeImg");
    if (x.type === "password") {
      x.type = "text";
      y.src = "/images/view.png"
    } else {
      x.type = "password";
      y.src = "/images/private.png"
    }
  }

  const handleSubmit = (evt) => {
    // dispatch(loginClear())
    if (email && password) {
      setFormError('')
      setFormSuccess('')
      setIsLoadingLogin(true)
      const params = {
        email,
        password,
        'login-btn': 'LOG IN'
      }
      // console.log(params)
      if (isChecked) {
        localStorage.setItem(SessionKeys.IS_REMEMBERED, true)
        localStorage.setItem(SessionKeys.EMAIL, email)
        localStorage.setItem(SessionKeys.PASS, password)
      } else {
        localStorage.setItem(SessionKeys.IS_REMEMBERED, false)
        localStorage.setItem(SessionKeys.EMAIL, '')
        localStorage.setItem(SessionKeys.PASS, '')
      }

      window.captureMyEvents("loginReq", params);
      // dispatch(login(params))
      dispatch(loginHostInst({
        action: 'hostinst_login',
        email: email,
        password: password
      }))
    } else {
      if (!email && !password) {
        setFormError('Please enter email and password.')
      } else if (!email) {
        setFormError('Please enter email.')
      } else {
        setFormError('Please enter password.')
      }
    }
  }

  function handleShow(value) {
    setShow(value);
  }

  const sendForgotPassLink = () => {
    if (emailValidation(forgetEmail)) {
      setForgetEmailError('');
      setShowLoader(true)
      const params = {
        'reset-email': forgetEmail,
        'pwdforgot-btn': 'FORGOT PWD'
      }
      // console.log(params)
      // dispatch(forgotPwd(params))
      dispatch(forgotPwdBroker({
        module: 'forgot-password-link',
        postData: {
          'reset-email': forgetEmail
        }
      }))

    } else {
      // setShowModalMsg("Invalid email.");
      // setConfirmModal(true);
      // setIsConfirm(false);
      setForgetEmailError('Please enter valid email address.')
    }
  }

  const renderModal = () => {
    return (
      <Modal show={show} fullscreen={false} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: 'white' }}>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: 'white' }}>
          <div className="fprdm">
            {showLoader ? <LinearProgress /> : ""}
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    {isSentResetLink ?
                      <div className="col backGround">
                        <div className="alert-forgot-message">
                          <span className="alertforgot-cion">i</span>
                          <span className="text-alert-msg">If we find a match, you'll get an email with further instructions.
                          If you don't hear from us in the next 15 minutes,
                          please double check that you entered the correct email address.</span>
                        </div>
                       
                      </div>
                      :
                      <div className="col backGround">
                        <div className="pop_modal_body">
                          {/* <h4>Forgot Password</h4> */}
                          <p>Lost your password? Please enter your Email Address, a link will be sent to reset your password.</p>
                          <div className="input-text position-relative">
                            <label htmlFor="email" className="input__label">Email Address</label>
                            <input
                              className="input__field passwordforgt_input"
                              id="email"
                              type="email"
                              placeholder="Enter your email address"
                              onChange={(e) => setForgetEmail(e.target.value)}
                            />
                            <div className="mb-2 login-msg">
                              <div style={{ color: "red" }}>{forgetEmailError}</div>
                            </div>
                          </div>
                        </div>
                        <div className="forget_btn">
                          <button className="btn btn-primary mt-2 mb-2" onClick={() => sendForgotPassLink()}>Password Reset Link</button><br />
                          <p>Already have an account?  <a href="#" onClick={() => { handleShow(false) }}>Sign In </a></p>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <div className='login-ary'>
        <section className="login-sec ">
          <div className="login-container login">
            <div className="bannerlog-image d-block d-md-none image-login">
              <img className="img-fluid" src="/images/login_img.jpg" />
            </div>
            <div className="header-border"></div>
            <div className="container-fluid">
              <div className="row bglogin">
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 ttxt">
                  <div className=" login-text">
                    <img src="/images/logo/Untitled-6.png" alt="images" />
                    <h1>{t("Welcome")}</h1>
                    <p className='ms-2'>Beautiful {dayParts} isn't it !</p>
                    <h3>{t("Host/Instructor Login")}</h3>
                    <div className="mb-2 login-msg">
                      {formError && (<div>{formError}</div>)}
                      {formSuccess && (<div>{formSuccess}</div>)}
                    </div>
                    <form>
                      <div className="input-text ">
                        <label htmlFor="password" className="input__label">{t("Email Address")}</label>
                        <input
                          className="input__field"
                          name="email"
                          type="email"
                          placeholder="Enter your email address"

                          onChange={(evt) => setEmail(evt.target.value)}
                          required
                        />

                      </div>
                      <div className="input-text ">
                        <label htmlFor="password" className="input__label">{t("Password")}</label>
                        <input
                          id='passInput'
                          className="input__field"
                          name="password"
                          type="password"
                          placeholder="Enter your password"

                          onChange={(evt) => setPassword(evt.target.value)}
                          required
                        />
                        <div className="show-pass" onClick={() => { myFunction() }}><img id='eyeImg' src="/images/private.png" alt="view" /></div>
                      </div>

                      <div className="row form-row">
                        <div className="col-6 form-check cursor-pointer">
                          <input className="form-check-input cursor-pointer" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => { setIsChecked(e.target.checked) }} />
                          <label className="form-check-label cursor-pointer" htmlFor="flexCheckDefault">
                            {t("Remember me")}
                          </label>
                        </div>
                        <div className="col-6 text-right">
                          <a href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              setForgetEmailError('')
                              setIsSentResetLink(false);
                              handleShow(true)
                            }}
                            className="forgot-pwd"
                          >{t("Forgot Password?")}</a>
                        </div>
                      </div>
                      <p className="">
                        <button
                          type="button"
                          className={`btn btn-primary mt-4 ${isLoadingLogin ? 'isLogin' : ''}`}
                          onClick={() => { !isLoadingLogin && handleSubmit() }}
                        >
                          {isLoadingLogin ?
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> : t("Enter")}
                        </button>
                      </p>
                    </form>
                  </div>
                  <div className="d-none d-md-block footer-title">@2022 - NIIT - All Rights Reserved</div>
                </div>
                <div className="col-xl-8 d-none d-md-block col-sm-12 col-md-7 col-lg-8 image-login">
                  <img className="img-fluid" src="/images/login_img.jpg" alt="home-scene-login" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {renderModal()}
      </div>
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={showModalMsg}
        isConfirm={isConfirm}
      />
    </>
  )
}

export default LoginHostInstPage;