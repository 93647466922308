import {
  React,
  useState,
  useEffect,
  Component,
  createRef,
  useCallback,
} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import 'moment-timezone' // or 'moment-timezone/builds/moment-timezone-with-data[-datarange].js'. See their docs

// Set the IANA time zone you want to use

import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as SmallCalendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { clickStreamUser, SessionKeys, timezoneArea, timezoneTagName } from "../../utils/AppUtils";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  cancelSessionData,
  cancelSessionDataClear,
  getCalendarSessionList,
  getCalendarSessionListClear,
  getCalendarViewSessions,
  getCalendarViewSessionsClear,
  getLiveCalendarSessionList,
  hostSessionClear,
  joinSessionClear,
  sessionAttendenceClear,
  sessionWatchURLDataClear,
  setSessionFilterBy,
  setSessionFilterByClear,
} from "../../redux/events/actions";
import SessionPopUpRealtor from "../../pages/Calender Events/SessionPopUpRealtor";
import CustomToolbar from "../../pages/Calender Events/CustomToolbar";
import { getEnrollBrokerSessionClear } from "../../redux/events/actions";
import { addToCartClear } from "../../redux/product/actions";
import {
  fetchTimezoneAbbr,
  fetchTimezoneAbbrClear,
  updateMobileStatus,
  updateMobileStatusClear
} from "../../redux/user/actions";
import ConfirmModal from "../modal/ConfirmModal";
import FeedbackCalendarCard from "../../pages/Calender Events/FeedbackCalendarCard";
// moment.locale('en-GB');
// BigCalendar.momentLocalizer(moment);
const categories = {
  ONE_ON_ONE: "1 on 1",
  TEAM_MEETING: "Team Meeting",
  CONFERENCE_CALL: "Conference Call",
};
const MainCalendarSection = ({
  isMobile,
  setIsLoading,
  isLoading
}) => {
  
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarRef = createRef();

  const calendarMyListArray = useSelector(
    (state) => state.events.calendarViewSessions
  );
  const calenderSessionListArray = useSelector(
    (state) => state.events.calendarSessionList
  );
  const enrollBrokerSession = useSelector(
    (state) => state.events.enrollBrokerSession
  );
  const setEnrollData = useSelector(
    (state) => state.events.setEnrollBrokerSession
  );
  const attendence = useSelector(
    (state) => state.events.sessionAttendenceMarked
  );
  const sessionCancelledData = useSelector(
    (state) => state.events.sessionCancel
  );
  const sessionFilterBy = useSelector((state) => state.events.sessionFilterBy);
  const authData = useSelector((state) => state.auth.authBroker);
  const authIframeBroker = useSelector((state) => state.auth.authIframeBroker);
  const joinSessionData = useSelector((state) => state.events.joinSession);
  const addToCartRes = useSelector((state) => state.product.addToCart);
  const hostSession = useSelector((state) => state.events.hostSession);
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const userDetailIframe = useSelector((state) => state.user.userDetailIframe);
  const timezoneAbbr = useSelector((state) => state.user.timezoneAbbr);
  const role = useSelector((state) => state.user.role);
  const iframeRole = useSelector((state) => state.user.iframeRole);

  const [userRole, setUserRole] = useState('')
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [sessionCalList, setSessionCalList] = useState({});
  const [myEvents, setMyEvents] = useState([]);
  const [infoModal, setInfoModal] = useState(false);
  const [showInfoModalMsg, setShowInfoModalMsg] = useState("");
  const [upcomingCalendarArray, setUpcomingCalendarArray] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [zoneAbbr, setZoneAbbr] = useState("");
  const [startMonth, setStartMonth] = useState(moment().startOf('M'))

  const [confirmModal, setConfirmModal] = useState(false)
  const [showModalMsg, ] = useState('Would you like to unregister from the session?')
  const [sessionDataForModal, setSessionDataForModal] = useState({})
  const [authBroker, setAuthBroker] = useState({})
  const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf("week"))

  useEffect(() => {
    if (isMobile) {
      setAuthBroker(authIframeBroker)
      dispatch(updateMobileStatus(true))
    } else {
      setAuthBroker(authData)
      dispatch(updateMobileStatusClear())
    }
  }, [authData, authIframeBroker, isMobile])

  useEffect(() => {
    if (isMobile) {
      setUserRole(iframeRole)
    } else {
      setUserRole(role)
    }
  }, [isMobile, role, iframeRole])
  //SESSIONS INFO
  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatchCalendarAPI();
      dispatchCalendarUpcomingAPI();
      window.scrollTo(0, 0);
      dispatch(sessionWatchURLDataClear());
      dispatch(setSessionFilterByClear());
    }
  }, [dispatch, authBroker]);


  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatchCalendarAPI();
      window.scrollTo(0, 0);
      setIsLoading(true);
    }
  }, [sessionFilterBy, authBroker])

  useEffect(() => {
    // setShowInfoModalMsg(addToCartRes?.payload.message)
    if (Object.keys(addToCartRes).length !== 0) {
      history.push("/cart");
      dispatch(addToCartClear());
    }
  }, [addToCartRes]);

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatchCalendarAPI();
    }
  }, [startMonth, authBroker])

  useEffect(() => {
    // console.log("userDetailRes: ", userDetailRes);
    if (isMobile) {
      if (!_.isEmpty(userDetailIframe?.payload?.payload)) {
        setUserDetails(userDetailIframe?.payload?.payload);
        // dispatch(
        //   fetchTimezoneAbbr({
        //     module: "fetchTimezoneAbbr",
        //     postData: {
        //       offset: userDetailIframe.payload.payload.timezone,
        //     },
        //   })
        // );
      }
    } else {
      if (!_.isEmpty(userDetailRes?.payload?.payload)) {
        setUserDetails(userDetailRes?.payload?.payload);
        // dispatch(
        //   fetchTimezoneAbbr({
        //     module: "fetchTimezoneAbbr",
        //     postData: {
        //       offset: userDetailRes.payload.payload.timezone,
        //     },
        //   })
        // );
      }
    }
  }, [userDetailRes, userDetailIframe, isMobile]);

  useEffect(() => {
    if (!_.isEmpty(timezoneAbbr)) {
      if (timezoneAbbr.status === "success") {
        // console.log("timezoneAbbr", timezoneAbbr);
        const timezoneAbbrData = timezoneAbbr.payload?.payload;
        if (!_.isEmpty(timezoneAbbrData)) {
          // const abbr = timezoneAbbrData.abbr[0].abbreviations;
          // setZoneAbbr(abbr);
        }
      }
      dispatch(fetchTimezoneAbbrClear());
    }
  }, [timezoneAbbr]);

  useEffect(() => {
    // console.log("calenderViewSessions", calendarMyListArray);
    // return;
    if (!_.isEmpty(calendarMyListArray)) {
      let upcomingArray = [];
      for (let calendersessionListRes of calendarMyListArray) {
        // setIsLoading(false)
        if (calendersessionListRes.status === "success") {
          if (!_.isEmpty(calendersessionListRes.payload?.payload)) {
            const sessionList =
              calendersessionListRes.payload?.payload?.sessionList;
            if (_.isArray(sessionList)) {
              // console.log("calenderViewSessions UPCOMING ARRAY", sessionList);
              // const userTz = moment.tz.guess();
              const timezoneOffset = !_.isEmpty(userDetails.timezone)
                ? userDetails.timezone.replace(":", "")
                : "+0530";
              const area = timezoneArea(timezoneOffset)
              let startFormat = "MMM DD, YYYY h:mm A";
              let endFormat = "MMM DD, YYYY h:mm A";
              for (let session of sessionList) {
                  const acutalTimeZoneOffset = session.timezone
                

                if (_.isArray(session.date_time)) {
                  for (let eventDateTime of session.date_time) {
                    // const startDateTime = moment.utc(eventDateTime.start_date.replaceAll('-', '/') + ' ' + eventDateTime.start_time).tz(userTz);
                    // const endDateTime = moment.utc(eventDateTime.end_date.replaceAll('-', '/') + ' ' + eventDateTime.end_time).tz(userTz);
                    const startDateTime = moment
                      .utc(
                        eventDateTime?.start_date?.replaceAll("-", "/") +
                          " " +
                          eventDateTime.start_time
                      )
                      .tz(area)
                      // .utcOffset(timezoneOffset);
                    const endDateTime = moment
                      .utc(
                        eventDateTime?.end_date?.replaceAll("-", "/") +
                          " " +
                          eventDateTime.end_time
                      )
                      .tz(area)
                      // .utcOffset(timezoneOffset);
                    // const currentDateTime = moment();
                    // const currentDateTime = moment().utcOffset(timezoneOffset);
                    const currentDateTime = moment().tz(area);
                    const diffCurrentStart = moment
                      .duration(startDateTime.diff(currentDateTime))
                      .asMinutes();
                    // console.log(
                    //   "diffCurrentStart",
                    //   diffCurrentStart,
                    //   session.session_name,
                    //   startDateTime,
                    //   currentDateTime
                    // );
                    if (currentDateTime.isBefore(endDateTime)) {
                      upcomingArray.push({
                        id: session.session_id,
                        title: session.session_name,
                        start: startDateTime.format(startFormat),
                        end: endDateTime.format(endFormat),
                        productId: session.product_id,
                        eventId: session.event_id,
                        startDate: startDateTime.toDate(),
                        endDate: endDateTime.toDate(),
                        // timezone: moment.tz(userTz).format('z'),
                        tz: timezoneOffset,
                        timezone: startDateTime.format('z'), //timezoneTagName(zoneAbbr),
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
      upcomingArray = upcomingArray.sort((a, b) => {
        return a.startDate > b.startDate ? 1 : -1
      })
      // console.log("calenderViewSessions UPCOMING ARRAY", upcomingArray);
      setUpcomingCalendarArray(upcomingArray);
      // else {
      //   setUpcomingCalendarArray([])
      // }
      dispatch(getCalendarViewSessionsClear());
    }
  }, [calendarMyListArray]);

  const dispatchMainCalendarAPICall = () => {
    let weekInput = currentWeekStart.clone();
    let date_range = {
      start_date: weekInput.startOf('week').format("MM/DD/YYYY"),
      end_date: weekInput.clone().endOf('week').format("MM/DD/YYYY")
    }
    // console.log('FETCH DATA', date_range)
    dispatch(getLiveCalendarSessionList({
      broker: {
        module: 'calendarSessionListBroker',
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          date_range
        }
      }
    }))
  }

  useEffect(() => {
    // console.log("enrolled", enrollBrokerSession);
    if (!_.isEmpty(enrollBrokerSession)) {
      dispatchCalendarAPI();
      dispatchMainCalendarAPICall()
      if (enrollBrokerSession?.status === "success") {
        let data = enrollBrokerSession.payload.payload;
        if (!_.isEmpty(data)) {
          const message = enrollBrokerSession.payload.message
            ? enrollBrokerSession.payload.message
            : "Try again later.";
          setShowInfoModalMsg(message);
          window.scrollTo(0, 0);
          dispatchCalendarAPI();
          dispatchCalendarUpcomingAPI();
          clickStreamUser("session-registered-successfull", {
            // event_id: event_id,
            session_id: data.session_id,
            user_id: authBroker?.payload?.user?.user_id,
            message: enrollBrokerSession.payload.message,
          });
        } else {
          if (userRole === "realtor") {
            setShowInfoModalMsg(
              "Error registering session, please contact your manager."
            );
          } else if (userRole === "manager") {
            setShowInfoModalMsg(
              "Error registering session, please contact your broker admin."
            );
          } else {
            setShowInfoModalMsg(
              "Error registering session, please contact support."
            );
          }
          window.scrollTo(0, 0);
        }
      } else if (enrollBrokerSession?.status === "error") {
        setShowInfoModalMsg(enrollBrokerSession.error.message);
        window.scrollTo(0, 0);
      }
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
      }, 5000)
      setIsLoading(false)
      dispatch(getEnrollBrokerSessionClear());
    }
  }, [enrollBrokerSession]);

  useEffect(() => {
    if (!_.isEmpty(attendence)) {
      dispatch(sessionAttendenceClear());
    }
  }, [attendence]);

  useEffect(() => {
    // console.log("joinSessionData", joinSessionData);
    if (!_.isEmpty(joinSessionData)) {
      if (joinSessionData.status === "success") {
        if (!_.isEmpty(joinSessionData.payload.payload)) {
          const sessionData = joinSessionData.payload.payload;
          if (sessionData.join_url) {
            dispatchCalendarAPI();
            
            setShowInfoModalMsg(
              "Zoom meeting will start in new tab, if pop-up is blocked, please unblock it."
            );
            window.open(sessionData.join_url, "_blank");
          } else {
            window.scrollTo(0, 0);
            setShowInfoModalMsg("Server Error, please contact support");
          }
        }
      } else {
        // console.log(joinSessionData.error.message);
        setShowInfoModalMsg((joinSessionData?.error  ? joinSessionData?.error?.message : "Not able to join the session." ));
      }
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
      }, 5000)
      setIsLoading(false);
      dispatch(joinSessionClear());
    }
  }, [joinSessionData]);

  useEffect(() => {
    // console.log("setEnrollBrokerSession", setEnrollData);
    if (!_.isEmpty(setEnrollData)) {
      const productId = setEnrollData.postData?.product_id;
      // console.log("productId", setEnrollData.postData);
      productId && history.push(`/payment?product_id=${productId}&type=event`);
    }
  }, [setEnrollData]);

  useEffect(() => {
    if (!_.isEmpty(calenderSessionListArray)&&!_.isEmpty(authBroker)) {
      let events = [];
      for (let calendarData of calenderSessionListArray) {
        setIsLoading(false);
        if (calendarData.status === "success") {
          const sessionData = _.clone(calendarData.payload.payload);
          // console.log('sessionData Calendar List Array', sessionData)
          if (_.isArray(sessionData)) {
            let api_date_format = "YYYY-MM-DD HH:mm";
            const timezoneOffset = !_.isEmpty(userDetails.timezone)
              ? userDetails.timezone.replace(":", "")
              : "+0530";

            // TODO: Get Area from backend 
            const area = timezoneArea(timezoneOffset)
            // console.log('Area', area, timezoneOffset)

            // User Timezone
            const userTz = moment.tz.guess();

            for (let session of sessionData) {

              // Acutal timezone offset kept while creating session
              const actualTimezoneOffset = session.timezone.includes(':') ? session.timezone.replace(":", "") : "+0530";

              // TODO: Get Area from backend
              const actualArea = timezoneArea(actualTimezoneOffset)

              if (_.isArray(session.date_time)) {
                // Set Start and End date of Session and not segment
                let sessionStartDate = moment.utc(session.first_start_date_time).tz(actualArea);
                let sessionEndDate = moment.utc(session.last_end_date_time).tz(actualArea);

                // Set Event format for calendar as per segment
                for (let event_time of session.date_time) {

                  // Start UTC for zoom meeting creation
                  const startUTC = event_time?.start_date?.replaceAll("-", "/") +
                  " " +
                  event_time.start_time;

                  // Segment start dateTime and end dateTime as per timezone
                  let startDateTime = moment
                    .utc(startUTC)
                    .tz(area)
                  let endDateTime = moment
                    .utc(
                      event_time?.end_date?.replaceAll("-", "/") +
                        " " +
                        event_time.end_time
                    )
                    .tz(area)

                  // Current DateTime as per timezone
                  const currentDateTime = moment().tz(area)

                  const startDate = moment(
                    event_time?.start_date?.replaceAll("-", "/")
                  ).tz(area)

                  // Get tense of startDateTime comparing with currentDateTime
                  const startTense = getTense(
                    getTenseDiff(
                      moment
                        .duration(startDateTime.diff(currentDateTime))
                        .asDays()
                    )
                  );
                  // Get tense of endDateTime comparing with currentDateTime
                  const endTense = getTense(
                    getTenseDiff(
                      moment
                        .duration(endDateTime.diff(currentDateTime))
                        .asDays()
                    )
                  );

                  // 
                  const tense =
                    startTense === "past" && endTense === "past"
                      ? "past"
                      : endTense;
                  
                  
                  /**
                   * Only Segment which are in future/present and segment which are registered in past
                   * will be part of calendar event
                   */
                  // if (
                  //   tense != "past" ||
                  //   (tense === "past" && session.is_registered)
                  // ) {

                    // Format Event Info for Popup and click events
                    let eventInfo = {
                      startUTC: startUTC,
                      startDateTime: startDateTime,
                      endDateTime: endDateTime,
                      start: moment(
                        startDateTime.format(api_date_format)
                      ).toDate(),
                      end: moment(endDateTime.format(api_date_format)).toDate(),
                      durationValue: _.toInteger(
                        moment
                          .duration(endDateTime.diff(startDateTime))
                          .asMinutes()
                      ),
                      duration:
                        _.toInteger(
                          moment
                            .duration(endDateTime.diff(startDateTime))
                            .asMinutes()
                        ) + "mins",
                      tense: tense,
                      tenseDayDiff: getTenseDiff(
                        moment
                          .duration(startDateTime.diff(currentDateTime))
                          .asDays()
                      ),
                      tz: timezoneOffset,
                      timezone: currentDateTime.format('z'), //zoneAbbr,
                      actualTimezoneAbbr: sessionEndDate.format('z'), //timezoneTagName(session.timezoneAbbr),
                      title: session.session_name,
                      channel: session.channel_name,
                      author: session.instructor,
                      meetingId: event_time.meeting_id,
                      sessionId: session.session_id,
                      eventId: session.event_id,
                      userId: authBroker.payload.user.user_id,
                      id: events.length,
                      source: session.source === 'ecom' ? 'xsel' : 'broker',
                      is_addToCart: session.is_addtocart,
                      // is_registered: session.is_registered,
                      is_registered: true,
                      is_paid:
                        event_time.is_host || event_time.is_instructor
                          ? true
                          : session.is_paid,
                      is_cancelled: session.is_cancelled,
                      is_expired: event_time.is_expired,
                      is_completed: event_time.is_completed,
                      is_live: event_time.is_live,
                      watch_url: event_time.watch_url,
                      selling_price:
                        event_time.is_host || event_time.is_instructor
                          ? 0
                          : _.toNumber(session.product_sale_price).toFixed(2),
                      productId: session.product_id,
                      segmentId: event_time.segment_id,
                      is_host: event_time.is_host,
                      is_boardcast: session.delivery_mode != 'virtual_interactive' ? true : false,
                      // is_participant:
                      //   event_time.is_instructor && !event_time.is_host
                      //     ? true
                      //     : event_time.is_participant,
                      is_participant:true,
                      hostZoomId: authBroker.payload.user.zoom_id,
                      start_url: event_time.start_url
                        ? event_time.start_url
                        : "",
                      join_url: event_time.join_url ? event_time.join_url : "",
                      current_time: currentDateTime.format(api_date_format),
                      userRole: userRole,
                      sessionStartDate: moment(
                        startDateTime.format(api_date_format)
                      ).toDate(),
                      sessionEndDate: moment(
                        sessionEndDate.format(api_date_format)
                      ).toDate(),
                      showAlert: (msg) => {
                        showAlert(msg);
                      },
                      showLoader: () => {
                        showLoader();
                      },
                      cancelSessionConfirmation: (session) => cancelSessionConfirmation(session),
                      history: history,
                      random: Math.ceil(Math.random()*100),
                      isMobile: isMobile,
                      delivery_mode:session.delivery_mode,
                      location:session.location,
                      is_Zoom_integration:event_time.is_Zoom_integration
                    }

                    // Check if the event is getting continued on next day
                    if (endDateTime.isAfter(startDateTime.clone().endOf('day'))) {
                      // multiple day setup

                      // For Same Day
                      let newEventInfo = _.cloneDeep(eventInfo)
                      let endDay = startDateTime.clone().endOf('day');
                      newEventInfo.endDateTime = endDay;
                      newEventInfo.end = moment(endDay.format(api_date_format)).toDate();
                      newEventInfo.random = Math.ceil(Math.random()*100)
                      // console.log(startDateTime.format(api_date_format), endDay.format(api_date_format), newEventInfo)
                      events.push(newEventInfo);

                      // For Next Day
                      let nextStartDay = startDateTime.clone().add(1, 'day').startOf('day')
                      
                      let nextEndDateTime = moment
                      .utc(
                        event_time?.end_date?.replaceAll("-", "/") +
                          " " +
                        event_time.end_time
                      )
                      .tz(area)
                      
                      if (!nextStartDay.isSame(nextEndDateTime)) {
                        let nextDayEventInfo = _.cloneDeep(eventInfo)
                        nextDayEventInfo.startDateTime = nextStartDay;
                        nextDayEventInfo.endDateTime = nextEndDateTime;
                        
                        nextDayEventInfo.start = moment(
                          nextStartDay.format(api_date_format)
                        ).toDate()

                        nextDayEventInfo.end = moment(
                          nextEndDateTime.format(api_date_format)
                        ).toDate()
                        nextDayEventInfo.random = Math.ceil(Math.random()*100)
                        events.push(nextDayEventInfo);
                      }
                      // startDateTime = startDateTime.
                    } else {
                      // it's a single day
                      events.push(eventInfo);
                    }
                  // }
                }
              }
            }
          }
        }
      }
      // Set events and pass to calendar
      setMyEvents(events);
      dispatch(getCalendarSessionListClear());
    }
  }, [calenderSessionListArray,authBroker]);

  const showAlert = (msg) => {
    if (msg) {
      window.scrollTo(0, 0);
      setShowInfoModalMsg(msg);
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
      }, 5000)
    }
  };

  const showLoader = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    // console.log("sessionCalledData", sessionFilterBy);
    if (!_.isEmpty(sessionCancelledData)) {
      if (sessionCancelledData.status === "success") {
        setInfoModal(true);
        setTimeout(() => {
          setInfoModal(false)
        }, 5000)
        window.scrollTo(0, 0);
        let msg = 'Xsel team will connect with you shortly.';
        if (sessionDataForModal.source === 'broker') {
          msg = 'You have successfully unregistered from the session.';
        }
        clickStreamUser("session-unregistered-successfull", {
          session_id: sessionDataForModal.sessionId,
          user_id: authBroker?.payload?.user?.user_id,
          message: msg,
        });
        setShowInfoModalMsg(msg);
        dispatchCalendarAPI();
        dispatchCalendarUpcomingAPI();
      }
      dispatch(cancelSessionDataClear());
    }
  }, [sessionCancelledData]);

  useEffect(() => {
    if (!_.isEmpty(hostSession)) {
      if (hostSession.status === "success") {
        if (!_.isEmpty(hostSession.payload.payload)) {
          // console.log("hostSession", hostSession.payload.payload);
          const hostSessionData = hostSession.payload.payload.data;
          if (hostSessionData.start_url) {
            setShowInfoModalMsg(
              "Zoom meeting will start in new tab, if pop-up is blocked, please unblock it."
            );
            dispatchCalendarAPI();
            window.open(hostSessionData.start_url, "_blank");
          } else {
            window.scrollTo(0, 0);
            setShowInfoModalMsg("Server Error, please contact support");
          }
        } else {
          // console.log(hostSession.error.message);
          window.scrollTo(0, 0);
          setShowInfoModalMsg(hostSession?.error ? hostSession?.error.message : "Not able to start the session.");
        }
      } else {
        setShowInfoModalMsg(hostSession?.error ? hostSession?.error.message : "Not able to start the session.");
      }
      setInfoModal(true);
      setTimeout(() => {
        setInfoModal(false)
      }, 5000)
      dispatch(hostSessionClear());
    }
  }, [hostSession]);

  const getTense = (diff) => {
    if (diff > 0) {
      return "future";
    } else if (diff < 0) {
      return "past";
    } else {
      return "present";
    }
  };

  const getTenseDiff = (value) => {
    if (value > 1) {
      return 1;
    } else if (value <= 0) {
      return -1;
    } else {
      return 0;
    }
  };

  const eventBackground = (event) => {
    // console.log('Event', event.is_paid, event.is_registered, event.title)
    return (
      !event.is_paid &&
      !event.is_registered && {
        className: "non-registered-event",
      
      }
    );
  };

  const eventPropGetter = useCallback(eventBackground, []);

  const renderAlertCard = () => {
    return (
      <div
        className={`cal-alert alert-card d-flex justify-content-center ${
          infoModal ? "" : "d-none"
        }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </g>
            </svg> */}
            <p className="m-0">{showInfoModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setInfoModal(false);
                setShowInfoModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  const handleSelectedEvent = (e) => {
    // console.log("handleSelectedEvent", e);
    return (
      <Popover>
        <Popover.Title>{e.title}</Popover.Title>
      </Popover>
    );
  };

  const dispatchCalendarAPI = () => {
    let date_range = {
      start_date: startMonth.clone().subtract(15, 'days').format('MM/DD/YYYY'),
      end_date: startMonth.clone().endOf('M').add(15, 'days').format('MM/DD/YYYY')
    }
    dispatch(
      getCalendarSessionList({
        broker: {
          module: "calendarSessionListBroker",
          postData: {
            token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            filter: sessionFilterBy === "all" ? "" : sessionFilterBy,
            date_range
          },
        },
      })
    );
  };

  const dispatchCalendarUpcomingAPI = () => {
    dispatch(
      getCalendarViewSessions({
        ecom: {
          module: "calendarViewSessions",
          postData: {
            email: authBroker.payload.user.email,
            user_id: authBroker.payload.user.user_id,
            date_range: {
              start: moment().format("DD/MM/YYYY"),
              end: moment().add(2, "Q").format("DD/MM/YYYY"),
            },
          },
        },
        broker: {
          module: "getRegisteredBrokerSession",
          postData: {
            email: authBroker.payload.user.email,
            user_id: authBroker.payload.user.user_id,
            token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            date_range: {
              start: moment().format("DD/MM/YYYY"),
              end: moment().add(2, "Q").format("DD/MM/YYYY"),
            },
          },
        },
      })
    );
  };

  const cancelSessionConfirmation = (session) => {
    // console.log(session)
    setConfirmModal(true)
    setSessionDataForModal(session)
  }

  const cancelSession = (session) => {
    // console.log(session)
    setConfirmModal(false)
    setIsLoading(true)
    clickStreamUser("cancel-session", {
      event_id: session.eventId,
      session_id: session.sessionId,
      session_name: session.title,
      user_id: authBroker.payload.user.user_id,
    });
    dispatch(
      cancelSessionData({
        module: 'sessionCancel',
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          session_id: session.sessionId,
          session_title: session.title,
          event_id: session.eventId,
          origin: session.source === 'broker' ? 'broker' : 'xsel'
        }
      })
    )
  }

  const isSameDay = (date1, date2) => {
    return moment(date1).isSame(date2, 'day');
   };

  return (
    <>
      <div className="container position-relative">
        {renderAlertCard()}
        <div className="row calandpage pdcal">
          <div className="col-md-2 calendar-banner">
            <p className="lable-heading_search">Calendar</p>
          </div>
        </div>
        <div className="row pt-4 calendar-content">
          <div className="col-xl-9 col-sm-12">
            <div className="calscroll" >
              <Calendar
              
                formats={formats}
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventPropGetter}
                events={myEvents}
                style={{ height: "100%", width: "100%" }}
                date={calendarDate}
                defaultDate={calendarDate}
                onNavigate={(date) => {
                  let newDate = moment(date).startOf('M')
                  if (!newDate.isSame(startMonth)) {
                    setStartMonth(newDate);
                    // dispatchCalendarAPI();
                    showLoader()
                  }
                  setCalendarDate(date);
                }}
                showNavigation={false}
                selectable={true}
                min={new Date(0, 0, 0, 0, 0, 0)}
                max={new Date(0, 0, 0, 23, 59, 59)}
                step={30}
                components={{
                  event: SessionPopUpRealtor,
                  // toolbar: (props) => <CustomToolbar {...props} />
                  toolbar: CustomToolbar,
                  // month: {
                  //   dateHeader: ({ date, label }) => {
                  //     let highlightDate =
                  //       myEvents.find(event =>
                  //         moment(date).isBetween(
                  //           moment(event.startDate),
                  //           moment(event.endDate),
                  //           null,
                  //           "[]"
                  //         )
                  //       ) != undefined;
                  //     return (
                  //       <h1 style={highlightDate ? { color: "red" } : null}>{label}</h1>
                  //     );
                  //   }
                  // }
                }}
                views={["day", "week", "month"]}
                onView={(view) => {
                }}
                inputRef={calendarRef}
                defaultView={"week"}
                dayLayoutAlgorithm={"no-overlap"}
                scrollToTime={moment().clone().startOf('day').add(7, "hour")}
                tooltipAccessor={''}
              />
            </div>
          </div>
          <div className="col-xl-3 col-sm-12 mt-5cal">
            <div className="side-calender">
              <div className="month">
                <SmallCalendar
                  onChange={(date) => {
                    let currentDate = moment(date).startOf('M')
                    if (!currentDate.isSame(startMonth)) {
                      setStartMonth(currentDate)
                      // dispatchCalendarAPI()
                      showLoader()
                    }
                    setCalendarDate(date)
                  }}
                  value={calendarDate}
                />
              </div>
              {(!_.isEmpty(myEvents) && userRole !== "instructor") && (
                <div className="containerDiv calenderdesc">
                  {myEvents.some(session => isSameDay(new Date(session.start), calendarDate)) && (
                    <div className="myComingEvent">
                      <b>My Events</b>
                    </div>
                  )}
                  
                    {myEvents.map((session, index) => {
                      if (isSameDay(new Date(session.start), calendarDate)) {
                        const endTime = moment(`${session.end}`);
                        const currentTime = moment(session.current_time);
                        return (
                        <div className="myComingEventlist filled">
                          <div key={"session-" + index}>
                            <b>{session.title}</b>
                            <p>
                              {new Date(session.start).toLocaleString()} - {new Date(session.end).toLocaleString()}&nbsp;{session.timezone}
                            </p>
                            {endTime <= currentTime && <FeedbackCalendarCard eventData={session} />}
                          </div>
                        </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  
                </div>
              )}
  
              {!_.isEmpty(upcomingCalendarArray) ? (
                <div className="containerDiv calenderdesc">
                  <a href="my-event.html"></a>
                  <div className="myComingEvent">
                    <b>My Upcoming Events</b>
                  </div>
                  {upcomingCalendarArray.length > 0
                    ? upcomingCalendarArray.map((session, index) => {
                        if (index < 5) {
                          return (
                            <div
                              key={"session-" + index}
                              className="myComingEventlist filled"
                            >
                              <b>{session.title}</b>
                              <p>
                                {session.start} - {session.end}&nbsp;
                                {session.timezone}
                              </p>
                            </div>
                          );
                        }
                      })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal 
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={showModalMsg}
        isConfirm={true}
        confirmId={sessionDataForModal}
        confirmAction={cancelSession}
      />
    </>
  );
};

export default MainCalendarSection;
