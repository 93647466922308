import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAssignUserReq,
  getTeamMatesReq,
  getTeamMatesResClear,
  setCurrentView,
} from "../../redux/assignCourse/actions";
import { SessionKeys } from "../../utils/AppUtils";
import CONFIG from "../../utils/config";
import StaticUtils from "../../utils/StaticUtils";
const ChooseTeammate = (props) => {
  const {
    selectedTeamMatesList,
    setSelectedTeamMatesList,
    parentId,
    parentName,
    setTitleHeading,
  } = props;

  const dispatch = useDispatch();
  const { courseId } = useParams();
  const teamMatesListRes = useSelector(
    (state) => state.assignCourse.teamMatesList
  );
  const assignedUserRes = useSelector(
    (state) => state.assignCourse.assignedUsers
  );
  const [searchInput, setSearchInput] = useState("");
  const [teamMatesList, setTeamMatesList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    setTitleHeading(`Assign ${parentName} to`);
    dispatch(getTeamMatesResClear());
    getAssignedUsers();
  }, []);

  useEffect(() => {
    setSelectedCount(selectedTeamMatesList?.length);
  }, [selectedTeamMatesList]);

  useEffect(() => {
    if (searchInput !== undefined && searchInput.length > 2)
      getTeamMatesOnSearch();
  }, [searchInput]);

  const getTeamMatesOnSearch = () => {
    dispatch(
      getTeamMatesReq({
        module: "getTeamMatesList",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          search: searchInput,
          content_type: parentName.toLowerCase().includes('event')?'session':parentName.toLowerCase().includes('certificate')?'certificate':parentName.toLowerCase(),
          content_ids: [parentId],
        },
      })
    );
  };

  useEffect(() => {
    // console.log("teamMatesListRes: ", teamMatesListRes);
    if (!_.isEmpty(teamMatesListRes)) {
      if (teamMatesListRes?.status === "success") {
        setTeamMatesList(teamMatesListRes?.payload?.payload);
      }
    } else {
      setTeamMatesList([]);
    }
  }, [teamMatesListRes]);

  const getAssignedUsers = () => {
    dispatch(
      getAssignUserReq({
        module: "getAssignedUsers",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          content_type: parentName.toLowerCase().includes('event')?'session':parentName.toLowerCase().includes('certificate')?'certificate':parentName.toLowerCase(),
          content_ids: [parentId],
          type: "teammates",
        },
      })
    );
  };

  useEffect(() => {
    // console.log("teamMatesListRes: ", teamMatesListRes);
    if (!_.isEmpty(assignedUserRes)) {
      if (assignedUserRes?.status === "success") {
        setAssignedUsers(assignedUserRes?.payload?.payload);
      }
    } else {
      setAssignedUsers([]);
    }
  }, [assignedUserRes]);

  const selectItem = (index) => {
    let tempArr = _.cloneDeep(teamMatesList);
    let selectedTempArray = _.cloneDeep(selectedTeamMatesList);
    if (!StaticUtils.isContainObject(selectedTempArray, tempArr[index], 0)) {
      selectedTempArray.push(tempArr[index]);
    }

    setSelectedTeamMatesList(selectedTempArray);
    tempArr.splice(index, 1);
    setTeamMatesList(tempArr);
  };

  const removeItem = (index) => {
    let selectedTempArray = _.cloneDeep(selectedTeamMatesList);
    let tempArr = _.cloneDeep(teamMatesList);

    if (!StaticUtils.isContainObject(tempArr, selectedTempArray[index], 0)) {
      tempArr.push(selectedTempArray[index]);
    }

    setTeamMatesList(tempArr);
    selectedTempArray.splice(index, 1);
    setSelectedTeamMatesList(selectedTempArray);
  };

  return (
    <>
      <div className="chooseteamate">
        <h4>Choose Agents</h4>
        <div className="input-lear position-relative d-flex">
          <label for="teamsearch" className="input__label_learning">
            Search
          </label>
          <input
            type="text"
            id="teamsearch"
            name="teamsearch"
            className="input_searchfield_search-cart "
            placeholder="Start searching"
            onChange={(evt) => {
              setSearchInput(evt.target.value);
            }}
          />
        </div>
        <div className="search-team-result">
          {teamMatesList?.map((item, index) => {
            return (
              <div className="team-result-box">
                <div className="team-user-img">
                  <img
                    src={
                      item?.profile_img !== ""
                        ? item?.profile_img
                        : CONFIG.DEFAULT_AVATAR
                    }
                    alt="user"
                  />
                </div>
                <div className="userteamname">{item?.name}</div>
                <div
                  className="addteammate cursor-pointer"
                  onClick={() => {
                    selectItem(index);
                  }}
                >
                  +
                </div>
              </div>
            );
          })}
        </div>
        <div className="teamselected">
          <div className="selectresilt">
            Selected Agents<strong>({selectedCount})</strong>
          </div>
          <div className="search-team-result slectedteammates">
            {selectedTeamMatesList?.map((item, index) => {
              return (
                <div className="team-result-box">
                  <div className="team-user-img">
                    <img
                      src={
                        item?.profile_img !== ""
                          ? item?.profile_img
                          : "/images/avatar.png"
                      }
                      alt="user"
                    />
                  </div>
                  <div className="userteamname">{item?.name}</div>
                  <div
                    className="addteammate cursor-pointer"
                    onClick={() => {
                      //   console.log("removeItem");
                      removeItem(index);
                    }}
                  >
                    +
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="buttonsteammate">
          <button
            type="button"
            className="btn btn-primary btn-block back-button"
            onClick={() => {
              dispatch(setCurrentView(1));
            }}
          >
            Back
          </button>
          {selectedCount > 0 ? (
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              onClick={() => {
                dispatch(setCurrentView(1));
              }}
            >
              Proceed
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              disabled
            >
              Proceed
            </button>
          )}
        </div>

        <div className="teamselected">
          <div className="selectresilt">
            Assigned Agents<strong>({assignedUsers?.length})</strong>
          </div>
          <div className="search-team-result slectedteammates">
            {assignedUsers?.map((item, index) => {
              return (
                <div className="team-result-box">
                  <div className="team-user-img">
                    <img
                      src={
                        item?.profile_img !== ""
                          ? item?.profile_img
                          : "/images/avatar.png"
                      }
                      alt="user"
                    />
                  </div>
                  <div className="userteamname">{item?.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseTeammate;
