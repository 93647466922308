import Page from "../components/Page";

const NotFoundPage = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isMobile = params.get("isMobile");
    return (<>
        {
            isMobile !== 'true' ? <Page>
                <section className="page_404">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>


                                </div>

                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Look like you're lost
                                    </h3>

                                    <p>the page you are looking is not available!</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Page> :
                <section className="page_404">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>


                                </div>

                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Look like you're lost
                                    </h3>

                                    <p>the page you are looking is not available!</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        }
    </>
    )
}

export default NotFoundPage;