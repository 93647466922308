import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllEventsBroker,
  getAllEventsNIIT,
  getAllEventsNIITClear,
  getAllEventsBrokerClear,
  getAllEvents,
  getMyEvents,
  getMyEventsClear,
} from "../../redux/events/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import XselEventListSection from "./XselEventListSection";
import BrokerEventListSection from "./BrokerEventListSection";
import Loader from "../Loader/Loader";
import MyEventListDataSection from "./MyEventListDataSection";
import ShimmerEventCard from "../Layouts/ShimmerEventCard";

const MyEventListingSection = () => {
  const history = useHistory();

  const authBroker = useSelector((state) => state.auth.authBroker);
  const eventsList = useSelector((state) => state.events.myEventsList);

  const [allEvents, setAllEvents] = useState([])
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState("");
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(getMyEvents({
        broker: {
          module: 'myEventListBroker',
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          }
        }
      }))
    }
  }, [dispatch, authBroker])

  useEffect(() => {
    // console.log('Event List',eventsList)
    if (!_.isEmpty(eventsList)) {
      setIsLoading(false)
      let eventArray = []
      for (let event of eventsList) {
        if (event.status === 'success') {
          const eventData = event.payload.payload
          for (let e of eventData) {
            e.souce = e.source ? e.source : 'ecom'
            eventArray.push(e)
          }
        }
      }
      // console.log(eventArray)
      setAllEvents(eventArray)
      dispatch(getMyEventsClear())
    }
  }, [eventsList])



  const goToEventDetail = (item) => {
    clickStreamUser("go-to-event-detail", {
      eventId: item.eventId,
      eventCode: item.eventCode,
      source: item.source,
      userId: authBroker?.payload.user.user_id,
    });
    history.push(
      `/event-detail?event_id=${item?.eventId}&event_code=${item?.event_code}&source=${item.source}&register=sessionRegistered`
    );
  };


  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchInput(e.target.value);
    setFilterData("")
  }

  const filterDataHandler = () => {
    // console.log('SEARCH INPUT', searchInput)
    setFilterData(searchInput);
  }

  return (
    <>
      {/* <Loader start={isLoading} /> */}
      <section className="events_page">
        <div className="events_section">
          <div className="container events-content h-76vh">
            <div className="col-md-12">
              <div className="events_banner">
                {/*<img className="img-fluid" src="images/img_icons/event_img.png" alt="events_img" />*/}
                <p className="label-heading_events mb-4">Events</p>
              </div>

              {/* for Search */}
              <div className="input-text position-relative d-flex">
                <label htmlFor="search" className="input__label">
                  Search
                </label>
                <input
                  type="text"
                  id="search"
                  name="search"
                  className="input_field mr-2"
                  placeholder=" "
                  value={searchInput}
                  onChange={inputHandler}
                />
                <button
                  className="button-reset"
                  style={{ marginTop: "-20px" }}
                  onClick={filterDataHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    id="Find"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        y1="0.5"
                        x2="1"
                        y2="0.5"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stopColor="#00b7f1" />
                        <stop offset="1" stopColor="#ed156e" />
                      </linearGradient>
                    </defs>
                    <circle
                      id="Ellipse_191"
                      data-name="Ellipse 191"
                      cx="15"
                      cy="15"
                      r="15"
                      fill="url(#linear-gradient)"
                    />
                    <circle
                      id="Ellipse_192"
                      data-name="Ellipse 192"
                      cx="13"
                      cy="13"
                      r="13"
                      transform="translate(2 2)"
                      fill="#fff"
                    />
                    <path
                      id="Icon_awesome-search"
                      data-name="Icon awesome-search"
                      d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                      transform="translate(7.715 7.714)"
                      fill="var(--color-element-1)"
                    />
                  </svg>
                </button>
              </div>

              <div className="row more_events">
                {/* <XselEventListSection goToEventDetail={goToEventDetail} filterDataset={filterData}/>
                <BrokerEventListSection goToEventDetail={goToEventDetail} filterDataset={filterData}/> */}
                {
                  !isLoading ?
                    allEvents.length == 0 ? <div className="not-found mt-3">
                      <div className=" interboxes">
                        <img src="/images/telescope.svg" alt="Not Found" />
                        <p>Sorry! No Events found.</p>
                      </div>
                    </div> :
                      <MyEventListDataSection goToEventDetail={goToEventDetail} filterDataset={filterData} allEvents={allEvents} />
                    :
                    [1, 2, 3, 4].map(m => {
                      return (
                        <ShimmerEventCard />
                      )
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyEventListingSection;
