import types from './types'
//CERTIFIED
export const getAllCertifiedNIIT = (payload) => ({ type: types.GET_ALL_CERTIFIED_NIIT, payload })
export const getAllCertifiedNIITResponse = (payload) => ({ type: types.GET_ALL_CERTIFIED_NIIT_RES, payload })
export const getAllCertifiedNIITClear = () => ({ type: types.GET_ALL_CERTIFIED_NIIT_CLEAR })

//event details
export const getCertifiedDetailsNIIT = (payload) => ({ type: types.GET_CERTIFIED_DETAILS_NIIT, payload })
export const getCertifiedDetailsNIITResponse = (payload) => ({ type: types.GET_CERTIFIED_DETAILS_NIIT_RES, payload })
export const getCertifiedDetailsNIITClear = () => ({ type: types.GET_CERTIFIED_DETAILS_NIIT_CLEAR })

//SESSIONS
export const getSessionNIITList = (payload) => ({ type: types.CERTIFIED_SESSION_LIST_NIIT, payload })
export const getSessionNIITListResponse = (payload) => ({ type: types.CERTIFIED_SESSION_LIST_NIIT_RES, payload })
export const getSessionNIITListClear = () => ({ type: types.CERTIFIED_SESSION_LIST_NIIT_CLEAR })

//CERTIFIED
export const getAllCertifiedBroker = (payload) => ({ type: types.GET_ALL_CERTIFIED_BROKER, payload })
export const getAllCertifiedBrokerResponse = (payload) => ({ type: types.GET_ALL_CERTIFIED_BROKER_RES, payload })
export const getAllCertifiedBrokerClear = () => ({ type: types.GET_ALL_CERTIFIED_BROKER_CLEAR })

//event details
export const getCertifiedDetailsBroker = (payload) => ({ type: types.GET_CERTIFIED_DETAILS_BROKER, payload })
export const getCertifiedDetailsBrokerResponse = (payload) => ({ type: types.GET_CERTIFIED_DETAILS_BROKER_RES, payload })
export const getCertifiedDetailsBrokerClear = () => ({ type: types.GET_CERTIFIED_DETAILS_BROKER_CLEAR })

//SESSIONS
export const getSessionBrokerList = (payload) => ({ type: types.CERTIFIED_SESSION_LIST_BROKER, payload })
export const getSessionBrokerListResponse = (payload) => ({ type: types.CERTIFIED_SESSION_LIST_BROKER_RES, payload })
export const getSessionBrokerListClear = () => ({ type: types.CERTIFIED_SESSION_LIST_BROKER_CLEAR })

//Enroll Session Broker
export const getEnrollBrokerSession = (payload) => ({ type: types.CERTIFIED_ENROLL_BROKER_SESSION, payload })
export const getEnrollBrokerSessionResponse = (payload) => ({ type: types.CERTIFIED_ENROLL_BROKER_SESSION_RES, payload })
export const getEnrollBrokerSessionClear = () => ({ type: types.CERTIFIED_ENROLL_BROKER_SESSION_CLEAR })


//get certificate Broker
export const getCertificateBroker = (payload) => ({ type: types.CERTICICATE_GET_BROKER, payload })
export const getCertificateBrokerResponse = (payload) => ({ type: types.CERTICICATE_GET_BROKER_RES, payload })
export const getCertificateBrokerClear = () => ({ type: types.CERTICICATE_GET_BROKER_CLEAR })

//get badges Broker
export const getBadgesBroker = (payload) => ({ type: types.BADGES_GET_BROKER, payload })
export const getBadgesBrokerResponse = (payload) => ({ type: types.BADGES_GET_BROKER_RES, payload })
export const getBadgesBrokerClear = () => ({ type: types.BADGES_GET_BROKER_CLEAR })