import { Button, Spinner } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetPwd, resetPwdBroker, resetPwdClear, resetPwdResponse, setupPasswordBroker, setupPasswordBrokerClear } from "../../redux/auth/actions";
import { cnfPwdValidation,passLimitValidation, passCharValidation, SessionKeys } from "../../utils/AppUtils";
import FooterNew from "../../components/FooterNew";
import HeaderLogoSection from "../../components/HeaderLogoSection";
import _ from "lodash"
import Loader from "../../components/Loader/Loader";
import MESSAGE from "../../utils/messages";

const ResetPass = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  var resetRes = useSelector(state => state.auth.reset_pwd_response)
  const setupPasswordBrokerInfo = useSelector(state => state.auth.setupPasswordBroker)
  const authBroker = useSelector((state) => state.auth.authBroker);

  const [password, setPassword] = useState('')
  const [cnfPassword, setCnfPassword] = useState('')
  const [htmlFormError, setFormError] = useState('')
  const [htmlFormSuccess, setFormSuccess] = useState('')
  const [key, setKey] = useState(params.get('key'));
  const [emailId, setEmailId] = useState(params.get('email'));
  const [actionType, setActionType] = useState(params.get('action'));
  const [isUserAlreadyLogin, setIsUserAlreadyLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [infoModal, setInfoModal] = useState(false)
  const [showInfoModalMsg, setShowInfoModalMsg] = useState(MESSAGE.ACTIVE_IN_OTHER_TAB)
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== null &&
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== "" &&
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER) !== undefined
    ) {
      // console.log("inside authBroker");
      if (authBroker.status === "success") {
        setInfoModal(true)
        setIsUserAlreadyLogin(true)
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
}, [authBroker])

  useEffect(() => {
    if (htmlFormError) {
      setTimeout(() => {
        setFormError('')
      }, 2000)
    } else if (htmlFormSuccess) {
      setTimeout(() => {
        setFormSuccess('')
      }, 2000)
    }
  }, [htmlFormError, htmlFormSuccess])


  useEffect(() => {
    // console.log('password', setupPasswordBrokerInfo)
    if (!_.isEmpty(setupPasswordBrokerInfo)) {
        if (setupPasswordBrokerInfo.status === 'success') {
            // console.log('Redirect to dashboard', emailId)
            history.push(`/`, { canLogin: true })
        } else {
          setFormError('E')
        }
        localStorage.removeItem(SessionKeys.API_TOKEN_BROKER);
        setShowSpinner(false)
        dispatch(setupPasswordBrokerClear())
    }
  }, [setupPasswordBrokerInfo])

  const resetPassword = () => {
    console.log('UserAlreadyLoged', isUserAlreadyLogin)
    if (!isUserAlreadyLogin) {
      if (!passLimitValidation(password)) {
        setFormError("Please enter at least 6 characters.")
        setShowSpinner(false)
      } else if (!passLimitValidation(cnfPassword)) {
        setFormError("Please enter at least 6 characters.")
        setShowSpinner(false)
      }  else if(!passCharValidation(password)){
        setFormError(<>
            <ul className="setup-password-error">
                <li>Password must contain at least one uppercase letter</li>
                <li>Password must contain at least one lowercase letter</li>
                <li>Password must contain at least one number</li>
                <li>Password must contain at least one special character like !@#$%^&*</li>
            </ul>
        </>)
        setShowSpinner(false)
      } else if(!passCharValidation(cnfPassword)) {
        setFormError(<>
            <ul className="setup-password-error">
                <li>Password must contain at least one uppercase letter</li>
                <li>Password must contain at least one lowercase letter</li>
                <li>Password must contain at least one number</li>
                <li>Password must contain at least one special character like !@#$%^&*</li>
            </ul>
        </>)
        setShowSpinner(false)
      } else if (cnfPwdValidation(password, cnfPassword)) {
          const params = {
            'pwdreset-btn': 'RESET PWD',
            'action': actionType,
            'reset-email': emailId,
            'key': key,
            'password': password
          }
          // console.log(params)
          // dispatch(resetPwd(params))
          dispatch(setupPasswordBroker({
            password,
            token: key,
            // email: emailId,
            module: 'realtor_update_password'
        }))
        } else {
          // alert("Passwords do not match.")
          setFormError("Passwords do not match. Please enter the same password in both fields.")
          setShowSpinner(false)
        }
    }
  }

  const renderAlertCard = () => {
    return (
      <div
        className={`cal-alert alert-card d-flex justify-content-center ${
          infoModal ? "" : "d-none"
        }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g
                id="Group_5699"
                data-name="Group 5699"
                transform="translate(0.498 0.498)"
              >
                <circle
                  id="Ellipse_222"
                  data-name="Ellipse 222"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(-0.498 -0.498)"
                  fill="#A9C23C"
                />
                <path
                  id="Icon_material-done"
                  data-name="Icon material-done"
                  d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                  transform="translate(-3.013 -6.071)"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </g>
            </svg>
            <p className="m-0">{showInfoModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setInfoModal(false);
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
    <div className="page-wrapper-rsp">
      <HeaderLogoSection />
      <div className="header-border"></div>
      <Loader start={isLoading} />
      <section className="setup-password">
        <div className="container">
          {/* {renderAlertCard()} */}
          <div className="row">
            {isUserAlreadyLogin ?
              <div className="col-md-12 messgebox">
                <div className="alert-card d-flex justify-content-center topsecalert "> 
                  <div className="shop-cart order-details alert-notify-sucess">
                    <div className="shop-info justify-content-center">
                        {showInfoModalMsg} 
                    </div>
                  </div>
                </div> 
              </div>
            : ''
            }
            <div className={`col-8 col-sm-7 col-md-5 col-xl-4 col-lg-4 right-space ${isUserAlreadyLogin ? "blur-section" : ""}`}>
              <div className="heading ">
               
                <h3>Reset your Password</h3>
                {/* <div className="mb-2 login-msg" style={{"width":'700px'}}>
                  
                </div> */}
              </div>
              {/* <p className="user pt-2">Email Address</p>
                    <p className="john-id"><strong>JohnD@gmail.com</strong></p> */}

              <form onSubmit={(event) => {
                event.preventDefault();
                resetPassword()
              }}>
                <div className="input-text position-relative">
                  <label htmlFor="password" className="input__label">Enter New Password</label>
                  <input type="password" className="input__field"
                    placeholder="Enter Password"
                    aria-label="password" aria-describedby="basic-addon1"
                    disabled={isUserAlreadyLogin}
                    onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="input-text  position-relative">
                  <label htmlFor="confirm_password" className="input__label">Confirm New Password</label>
                  <input type="password" className="input__field" placeholder="Confirm Password"
                    aria-label="password" aria-describedby="basic-addon1"
                    disabled={isUserAlreadyLogin}
                    onChange={(e) => setCnfPassword(e.target.value)} />
                </div>
                <div className="save_button">
                  {
                    showSpinner ?
                    <Button
                    className="btn btn-primary setup-button" >
                      <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      />
                    </Button>
                    :
                    <Button 
                    type="submit"
                    className="btn btn-primary setup-button" 
                    disabled={isUserAlreadyLogin}>
                    Reset</Button>
                  }
                  {htmlFormError && (<div>{htmlFormError}</div>)}
                  {htmlFormSuccess && (<div>{htmlFormSuccess}</div>)}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <FooterNew />
      </div>
    </>
  )

}
export default ResetPass;