import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { learningPoints } from "../../redux/Cart/actions";
import { getLeaderBoardReq } from "../../redux/user/actions";
import ContentLoader from "react-content-loader";
import ShimmerScreen from "../Layouts/ShimmerScreen";
import CONFIG from "../../utils/config";

const MyDashboardSection = ({ auth }) => {
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const [totalLearningPoints, setTotalLearningPoints] = useState(null);
  const coursesListingData = useSelector(
    (state) => state.courses.coursesListing
  );
  const myLearningPointsRes = useSelector(
    (state) => state.carts?.learningPoints
  );
  const leaderBoardDataTestRes = useSelector((state) => state.user.leaderBoard);
  const userRole = useSelector((state) => state.user.role);
  const [learningPoint, setLearningPoints] = useState([]);
  const [overAllRank, setOverAllRank] = useState("");
  const [showRank, setShowRank] = useState(true);
  const [name, setName] = useState("");
  const [degn, setDegn] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        learningPoints({
          module: "learning-points/points",
          email: authBroker.payload.user.email,
        })
      );
      setName(
        `${authBroker.payload.user.firstname} ${authBroker.payload.user.lastname}`
      );
    }
    dispatch(
      getLeaderBoardReq({
        module: "leaderBoard",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          user_id: localStorage.getItem(SessionKeys.USER_ID),
        },
      })
    );
  }, [dispatch, authBroker]);

  useEffect(() => {
    // console.log('overAllRankRes', leaderBoardDataTestRes);
    if (!_.isEmpty(leaderBoardDataTestRes)) {
      if (leaderBoardDataTestRes?.status === "success") {

        let data = leaderBoardDataTestRes?.payload?.payload
        if (!_.isEmpty(data)) {
          setOverAllRank(data?.myRank)
          // for (const item of data.list) {
          //   if(item?.is_me){
          //     setOverAllRank(item.rank)
          //   }
          // }
        }

      } else {
        setOverAllRank('')
      }
    }
  }, [leaderBoardDataTestRes]);

  useEffect(() => {
    if (!_.isEmpty(userDetailRes)) {
      const userData = userDetailRes?.payload?.payload;
      const authUserData = auth?.authBroker?.payload?.user;
      if (!_.isEmpty(userData)) {
        let designation = "";
        // if (userData.degn) {
        //   designation = designation + userData.degn;
        // }
        if (userData.degn && authUserData.city) {
          designation = designation;
        }
        if (authUserData.city) {
          designation = designation + authUserData.city;
        }
        if (authUserData.city && authUserData.state) {
          designation = designation + ", ";
        }
        if (authUserData.state) {
          designation = designation + authUserData.state;
        }
        setDegn(designation);
      }
    }
  }, [userDetailRes]);

  const toInt = (value) => {
    if (value === null) {
      return 0;
    } else return parseInt(value);
  };

  useEffect(() => {
    if (myLearningPointsRes?.status === "success") {
      var lpArray = myLearningPointsRes?.payload;
      let totalPoint = 0;
      if (!_.isEmpty(lpArray)) {
        if (_.isArray(lpArray) && lpArray.length > 0 && lpArray.type != "role_play" ) {
          totalPoint = 0;
          for (let learningPoint of lpArray) {
            totalPoint = totalPoint + _.toNumber(learningPoint.point);
          }
        }
        myLearningPointsRes.payload = lpArray;
      }
      setShowRank(totalPoint != 0);
      setTotalLearningPoints(totalPoint);
    }
  }, [myLearningPointsRes]);

  return (
    <>
      {userRole != "instructor" && <><div className="d-flex My_Dashboard New_My_Dashboard icon_MY_DB">
        <h3>My Dashboard</h3>
        <a
          onClick={() => {
            history.push("/profile/edit?section=dashboard");
            clickStreamUser("home-page-dashboard-hyperlink-clicked", {
              userId: authBroker?.payload?.user.user_id,
            });
          }}
        >
          <button className="button-reset">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              id="Expand"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  y1="0.5"
                  x2="1"
                  y2="0.5"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#00b7f1" />
                  <stop offset="1" stopColor="#ed156e" />
                </linearGradient>
              </defs>
              <circle
                id="Ellipse_57"
                data-name="Ellipse 57"
                cx="15"
                cy="15"
                r="15"
                fill="url(#linear-gradient)"
              />
              <circle
                id="Ellipse_179"
                data-name="Ellipse 179"
                cx="13"
                cy="13"
                r="13"
                transform="translate(2 2)"
                fill="#fff"
              />
              <g
                id="Group_1344"
                data-name="Group 1344"
                transform="translate(-525.5 -568.5)"
              >
                <path
                  id="Path_415"
                  data-name="Path 415"
                  d="M0,8H8V0"
                  transform="translate(536.5 587.5) rotate(-90)"
                  fill="none"
                  stroke="var(--color-element-1)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                />
                <line
                  id="Line_51"
                  data-name="Line 51"
                  x1="7"
                  y2="7"
                  transform="translate(536.5 580.5)"
                  fill="none"
                  stroke="var(--color-element-1)"
                  strokeLinecap="round"
                  strokeWidth="4"
                />
              </g>
            </svg>
          </button>
        </a>
      </div>
      <h6 className="text_Dashboard">
        {
          name ? (
            name
          ) : (
            <ShimmerScreen width={"3rem"} height={"1.5rem"} case={"rect"} />
          )
          // <div className="skeleton-len" style={{width: '3rem', height:'1.5rem'}}></div>
          // <div className="skeleton-loader">
          //     <ContentLoader
          //     speed={2}
          //     width={200}
          //     height={60}
          //     viewBox="0 0 200 60">
          //         <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
          //     </ContentLoader>
          // </div>
        }
      </h6>
      <p className="paraGraph_Dashboard">
        {
          degn != null ? (
            degn
          ) : (
            <ShimmerScreen width={"25rem"} height={"1.2rem"} case={"rect"} />
          )
        }
      </p></>
      }
      <div className="col-md-12 user-details-view">
        <div className="d-flex pt-4 align-items-center">
          {coursesListingData.courseList &&
            coursesListingData.courseList?.length > 0}
         {userRole != "instructor" && <div className="container-chart">
            <div className="box">
              <div
                className="chart-1 firstsecd d-flex "
                data-percent="60"
                data-scale-color="#FF6C02"
              >
                <img
                  className="img-fluid "
                  src="images/Group 5109.svg"
                  alt=""
                />
                <div
                  className="progress__txtBar mt-top cursor-pointer"
                  onClick={() => {
                    history.push("/learning-points");
                    clickStreamUser("home-page-learningpoint-clicked", {
                      userId: authBroker?.payload?.user.user_id,
                    });
                  }}
                >
                  {totalLearningPoints !== null ? (
                    <>
                      <h3>{totalLearningPoints}</h3>
                    </>
                  ) : (
                    <div className="skeleton-loader">
                      <ContentLoader
                        speed={2}
                        width={52}
                        height={50}
                        viewBox="0 0 62 70"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="51"
                          height="50"
                        />
                      </ContentLoader>
                    </div>
                  )}
                  <p>Learning Points</p>
                </div>
              </div>
            </div>
          </div>
          }
          {
            authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
            <div className="container-chart">
            <div className="box">
              {showRank ? (
                <div
                  className="chart-2 d-flex"
                  data-percent="25"
                  data-scale-color="#00B70E"
                >
                  <img
                    className="img-fluid "
                    src="images/Group 5110.svg"
                    alt=""
                  />
                  {
                    authBroker?.payload?.user?.company_id !== CONFIG.RPS_COMPANY_ID && 
                    <div className="progress__txtBarRS">
                    {overAllRank ? (
                      <>
                        <p className="circle-2"></p>
                        <h3>{overAllRank}</h3>
                        <p>Overall Rank</p>
                      </>
                    ) : (
                      <div className="skeleton-loader">
                        <ContentLoader
                          speed={2}
                          width={82}
                          height={100}
                          viewBox="0 0 82 100"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="21"
                            height="15"
                          />
                          <rect
                            x="0"
                            y="18"
                            rx="3"
                            ry="3"
                            width="51"
                            height="50"
                          />
                          <rect
                            x="0"
                            y="70"
                            rx="3"
                            ry="3"
                            width="81"
                            height="25"
                          />
                        </ContentLoader>
                      </div>
                    )}
                  </div>
                  }
                  
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          }
         
        </div>
        {!isPremiumUser && (
          <div className="bg__Text">
            <div className="col-lg-8 col-sm-12">
              {/* <div className="d-flex  justify-content-center flex-column align-items-center  newupgrade_btn ">
                            <p>Get <span> unlimited access </span>to courses with<span> personal guidance</span> with a premium
                                account</p>
                            <a>
                                <button type="button"
                                    className="btn btn-primary btn-block setup-button upgrate_colpeteBtn mr-2"
                                    onClick={() => {
                                        clickStreamUser('upgrade-btn-clicked', {
                                            user_id: authBroker.payload.user.user_id
                                        })
                                        history.push('/membership')
                                    }}
                                >
                                    Upgrade Account
                                </button>
                            </a>

                        </div> */}
            </div>
          </div>
        )}
      </div>
      {/* {!isPremiumUser && (
      <div className="dashboard-img">
        <img src="/images/dashboard.png"/>
        <div className="poiterbtn" onClick={() => {
              clickStreamUser("upgrade-btn-clicked", {
                user_id: authBroker.payload.user.user_id,
              });
              history.push("/membership");
            }}></div>
      </div>
      )} */}
    </>
  );
};

export default MyDashboardSection;
