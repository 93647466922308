import React, { useEffect, useState } from 'react';
import Page from "../../components/Page";
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { getModuleListing, getModueleListingClear, getModuleListingBroker, 
    getModuleListingClear, getModuleListingClearBroker } from "../../redux/courses/actions";
import { clickStreamUser, convertTimeFormat, SessionKeys } from "../../utils/AppUtils";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ProgressBar from 'react-bootstrap/ProgressBar'
import moment from 'moment';
import ModuleSection from '../../components/Module/ModuleSection';
import Loader from "../../components/Loader/Loader";

const ModuleDetail = () => {
    const history = useHistory()

    const dispatch = useDispatch()
    const [moduleList, setModuleList] = useState([])
    const [moduleData, setModuleData] = useState({})
    const [courseBrokerId, setCourseBrokerId] = useState('')
    const auth = useSelector(state => state.auth)
    const { courseId } = useParams();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get('source');
    const via_lp = params.get('via_lp');
    const lp_id = params.get('lp_id');
    const via_cat = params.get('via_cat');
    const catId = params.get('catId');
    const [isLoading, setIsLoading] = useState(false);

    const moduleListingData = useSelector(state => state.courses.moduleListing);
    const moduleListingDataBroker = useSelector(state => state.courses.moduleListingBroker);

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
        // console.log(moduleListingData)
        if (source === 'xsel' || source === 'ecom') {
            if (!_.isEmpty(moduleListingData) &&
                moduleListingData.status === 'success') {
                setIsLoading(false);
                // console.log(moduleListingData.payload.payload)
                let modulesInfo = _.cloneDeep(moduleListingData.payload.payload)
                modulesInfo?.modules && modulesInfo?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
                if (!_.isEmpty(modulesInfo)) {
                    if (modulesInfo.modules.length > 0) {
                        modulesInfo.modules[0].isLock = false;
                        for (let [index, modules] of modulesInfo.modules.entries()) {
                            modules.isLock = modules.isLock == false ? modules.isLock : true;
                            if (modules.progress > 0) {
                                modules.isLock = false;
                                if ((index + 1) !== modulesInfo.modules.length)
                                    modulesInfo.modules[index + 1].isLock = _.toNumber(modules.progress) === 100 ? false : true;
                            }
                        }
                        for (let modules of modulesInfo.modules) {
                            const hour = moment(modules.duration, 'hh:mm').hours();
                            const min = moment(modules.duration, 'hh:mm').minutes();
                            if (hour > 0) {
                                modules.durationTime = hour > 1 ? `${hour} hours` : `${hour} hour`;
                            }
                            if (min > 0) {
                                modules.durationTime = min > 1
                                    ? modules.durationTime ? `${modules.durationTime} ${min} minutes` : `${min} minutes`
                                    : modules.durationTime ? `${modules.durationTime} ${min} minute` : `${min} minute`;
                            }
                        }
                    }
                    setModuleData(modulesInfo);
                    // console.log("Modulelisting datas", modulesInfo, source);
                } else {
                    setModuleData({});
                }

            }
        } else {
            if (!_.isEmpty(moduleListingDataBroker) &&
                moduleListingDataBroker.status === 'success') {
                setIsLoading(false);
                // console.log(moduleListingDataBroker.payload)
                let modulesInfo = _.cloneDeep(moduleListingDataBroker.payload)
                modulesInfo?.modules && modulesInfo?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
                if (!_.isEmpty(modulesInfo)) {

                    if (modulesInfo?.modules?.length > 0) {
                        setCourseBrokerId(modulesInfo.courseId)
                        modulesInfo.modules[0].isLock = false;
                        for (let [index, modules] of modulesInfo.modules.entries()) {
                            modules.isLock = modules.isLock == false ? modules.isLock : true;
                            // module.progress = module.crs_progress
                            if (modules.progress > 0) {
                                modules.isLock = false;
                                if ((index + 1) !== modulesInfo.modules.length)
                                    modulesInfo.modules[index + 1].isLock = _.toNumber(modules.progress) === 100 ? false : true;
                            }
                        }
                        for (let modules of modulesInfo.modules) {
                            const hour = moment(modules.time_hrs, 'hh').hours();
                            const min = moment(modules.time_min, 'mm').minutes();
                            // module.durationTime = convertTimeFormat(hour * 60 + min)
                            // console.log(module.durationTime)
                            if (hour > 0) {
                                modules.durationTime = hour > 1 ? `${hour} hours` : `${hour} hour`;
                            }
                            if (min > 0) {
                                modules.durationTime = min > 1
                                    ? modules.durationTime ? `${modules.durationTime} ${min} minutes` : `${min} minutes`
                                    : modules.durationTime ? `${modules.durationTime} ${min} minute` : `${min} minute`;
                            }
                        }
                        if (source === 'broker') {
                            // console.log('Modulelisting data change module')
                            for (let modules of modulesInfo.modules) {
                                modules.module_id = modules.id;
                            }
                        }
                    }

                    setModuleData(modulesInfo);
                    // console.log("Modulelisting data", modulesInfo);
                } else {
                    setModuleData({});
                }

            }
        }
    }, [moduleListingData, moduleListingDataBroker])

    useEffect(() => {
        

        clickStreamUser('module_detail_page', {
            courseId: courseId,
            userId: auth.authBroker?.payload?.user.user_id,
        })
    }, [courseId, source, dispatch, auth.authBroker])

    const navigateToDetails = (title, progress, m) => {
        if (!m.isLock) {
            // console.log("Module", m);
            let courseID = source === 'broker' ? courseBrokerId : courseId;
            clickStreamUser('module_click_goto', {
                moduleId: m.module_id,
                moduleName: m.title,
                courseName: moduleData.courseName,
                courseId: courseID,
                userId: auth?.authBroker?.payload.user.user_id
            })
            let pathname = `/courseDetail/${courseId}?source=${source}`
            console.log('VIA LP', via_lp, lp_id)
            if (via_lp === "true") {
                pathname += `&via_lp=true&lp_id=${lp_id}`
            } else if (via_cat === 'true') {
                pathname += `&via_cat=true&cat_id=${catId}`
            }
            history.push({
                pathname,
                // pathname: `moduleDetail/${courseId}/courseDetail`,
                state: { courseId: courseID, courseCode: courseId, courseTitle: title, courseProgress: progress, mId: m.module_id, module: m }
            })
        }
    }

    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <ModuleSection
                    navigateToDetails={navigateToDetails}
                    moduleListData={moduleData}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isMobile={false}

                />

            </Page>
        </>

    )
}
export default ModuleDetail;