import _ from "lodash";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getProfileCompletionReportClear } from "../../../redux/reports/actions";
import NextArrow from "../../LearningPaths/NextArrow";
import PrevArrow from "../../LearningPaths/PrevArrow";
import Pagination from "../../../pages/Orders/Pagination";
import exportFromJSON from 'export-from-json'
import * as XLSX from 'xlsx';
import StaticUtils from "../../../utils/StaticUtils";
import ReportGraphSection from "../ReportGraphSection";
import moment from "moment"

const fileName = 'PlanUpgradeReport'
const exportType = 'xls'
let PageSize = 20;
const PlanUpgradeTableSeaction = (props) => {

  const { 
    setSearchInput, 
    getFilteredData, 
    isLoading,
    setIsLoading,
    dateRange,
    isView,
    setCanShow,
    type
  } = props;

  const dispatch = useDispatch();
  const profileReportRes = useSelector(state => state.report.profileReport);
  const [profileReports, setProfileReports] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [series, setSeries] = useState([])
  const [legends, setLegends] = useState([])

  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true)
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }

  }, [currentPage, profileReports]);

  useEffect(() => {
    if (isLoading) {
      window.scroll({
        top: 50, 
        left: 0, 
        behavior: 'smooth'
      });
      setSeries([])
      setLegends([])
    }
  }, [isLoading])
  
  useEffect(() => {
    // console.log('profileReportRes: ', profileReportRes)

    if (!_.isEmpty(profileReportRes)) {
      setIsLoading(false)
      if (profileReportRes?.status === "success") {
        const arr = profileReportRes?.payload?.payload
        setProfileReports(arr)
        setSeries([])
        setLegends([])
        let result = {}
        if (!_.isEmpty(dateRange)) {
          let start = moment(dateRange.start, 'MM/DD/YYYY')
          let end = moment(dateRange.end, 'MM/DD/YYYY')
          let diff = start.diff(end, 'hours')
          // console.log('DIFF', diff)
          if (diff >= 719) {
            result = _(arr)
            .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('MMMM'))
            .mapValues(v => _.map(v, 'amount'))
            .value();  
          } else if (diff >= 167) {
            result = _(arr)
            .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('MMMM'))
            .mapValues(v => _.map(v, 'amount'))
            .value();  
          } else  {
            // console.log('RESULT in DIFF')
            result = _(arr)
            .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('DD MMM'))
            .mapValues(v => _.map(v, 'amount'))
            .value();  
          }
          // result = _(arr)
          // .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('DD MMM'))
          // .mapValues(v => _.map(v, 'amount'))
          // .value();
        } else {
          result = _(arr)
          .groupBy(v => moment(v.formated_date, 'MM/DD/YYYY').format('MMMM'))
          .mapValues(v => _.map(v, 'amount'))
          .value();
        }
        // console.log('RESULT', result)
        let series = []
        let legends = []
        Object.keys(result).forEach(data => {
          legends.push(data)
          let total = _.sum(result[data]).toFixed(0)
          series.push(_.toNumber(total))
        })
        // console.log(series)
        // console.log(legends)
        setSeries(series)
        setLegends(legends)

        setCurrentPage(1)
      } else {
        setProfileReports([])
        setCanShow([])
      }
      dispatch(getProfileCompletionReportClear())
    } 
    
  }, [profileReportRes])

  const ExportToExcel = () => {
    const data = _.cloneDeep(profileReports);

    for(let item of data){
      
      item.date = item?.date !== undefined && item?.date !== '' ? StaticUtils.getDateFromTimeStamp(item?.date): "-"
    }

    const newThing = [];
    data.map(item => {
      newThing.push(
        _.mapKeys(item, (value, key) => {
          switch (key) {
            case "name":
              return "Name";
            case "user_email":
              return "Email";
            case "office_name":
              return "Office Name";
            case "date":
              return "Upgrade Date";
            
            default:
              return;
          }
        })
      )
    });

    for (let i = 0; i < newThing.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete newThing[i].undefined;
    }



    for (let i = 0; i < data.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete data[i]._id;
    }

    // exportFromJSON({ data, fileName, exportType })

    const worksheet = XLSX.utils.json_to_sheet(newThing);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName+".xlsx");
  }

  const renderTable = () => {
    return (
      <div className="row list-coach report-table-rsp">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive1">

            <div className="tablebox">
              <table className="table mb-0 office_list table-striped">
                <thead>
                  <tr>
                    <th className="office-province">
                      Name
                    </th>
                    <th className="office-province">
                      Email
                    </th>
                    <th className="office-province">
                      Office Name
                    </th>
                    <th className="office-province">
                      Upgrade Date
                    </th>

                  </tr>
                </thead>
                <tbody>

                  {currentTableData?.map((item, index) => {
                    return <tr className="addBGcustomw">
                      <td>
                        {item?.name}
                      </td>
                      <td>
                        {item?.user_email}
                      </td>
                      <td>
                        {item?.office_name}
                      </td>
                      <td><strong>{item?.date !== undefined && item?.date !== '' ? StaticUtils.getDateFromTimeStamp(item?.date) : "-"}</strong></td>

                    </tr>
                  })
                  }
                </tbody>
              </table>
            </div>


            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={profileReports.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                window.scroll({
                  top: 100,
                  left: 0,
                  behavior: 'smooth'
                }); setCurrentPage(page)
              }}
            />
          </div>

        </div>
      </div> 
    )
  }

  const renderGraph = () => {
    return (
      <ReportGraphSection 
        series={series}
        legends={legends}
        type={type}
        isLoading={isLoading}
        isAmount={false}
      />
    )
  }

  return (
    <>
      <section className="table-report-layout">
        {true ? <div className="container">
          <div className="row">


            <div className="col-md-5 search__input">
             
            </div>
            <div className="col-7">
              {currentTableData && currentTableData?.length > 0 && isView === 'table' ?
                <button className="button-reset downbtnsreport" onClick={() => {
                  if (window.callbackHandler != undefined) {
                    window.callbackHandler.postMessage("downbtnsreport");
                  } else if (window.webkit != undefined) {
                    if (window.webkit.messageHandlers.callbackHandler != undefined) {
                      window.webkit.messageHandlers.callbackHandler.postMessage("downbtnsreport");
                    }
                  }

                  ExportToExcel() }}>
                   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  id="Find"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#00b7f1" />
                      <stop offset="1" stop-color="#ed156e" />
                    </linearGradient>
                  </defs>
                  <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                  <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                  <path id="file-chart" d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z" transform="translate(5.342 5.927)" fill="var(--color-element-1)"/>
                </svg>
                </button> 
              : ''}
            </div>
          </div>
          {currentTableData && currentTableData?.length > 0 ? 
            isView === 'table' ? renderTable() : renderGraph()
          : profileReports !== null ? <div className="not-found mt-3">
            <div className=" interboxes">
              <img src="/images/telescope.svg" alt="Not Found" />
              <p>Sorry! No result found for your search.</p>
            </div>
          </div> : ""}
        </div> : ""}
      </section>
    </>
  )
}

export default PlanUpgradeTableSeaction