import { fork, take, call, put, all } from "redux-saga/effects";

import {
  assignToTeamsResponse,
  doAssignTeamMatesRes,
  getAssignUserResponse,
  getCompletedCourseListRes,
  getOfficeListRes,
  getTeamListResponse,
  getTeamMatesRes,
  getUsersByOfficeListResponse,
  getFilterLpResponse,
} from "./actions";
import types from "./types";
import { postAPIWithoutModule } from "../../api/generic";

export function* doGetTeamMatesList() {
  while (true) {
    const { payload } = yield take(types.GET_TEAMMATES_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getTeamMatesRes(response));
  }
}

export function* doAssignTeamMates() {
  while (true) {
    const { payload } = yield take(types.ASSIGN_TEAMMATES_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(doAssignTeamMatesRes(response));
  }
}

export function* doGetOfficeList() {
  while (true) {
    const { payload } = yield take(types.SEARCH_OFFICE_LIST_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getOfficeListRes(response));
  }
}

export function* doGetCompletedCourseList() {
  while (true) {
    const { payload } = yield take(types.COMPLETED_COURSE_LIST_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getCompletedCourseListRes(response));
  }
}

export function* doGetUsersByOfficeList() {
  while (true) {
    const { payload } = yield take(types.GET_USERS_BY_OFFICE_LIST);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getUsersByOfficeListResponse(response));
  }
}

export function* doGetTeamsListReq() {
  while (true) {
    const { payload } = yield take(types.GET_TEAMS_LIST_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getTeamListResponse(response));
  }
}

export function* doAssignToTeamsReq() {
  while (true) {
    const { payload } = yield take(types.ASSIGN_TO_TEAMS_REQ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(assignToTeamsResponse(response));
  }
}

export function* doGetAssignedUser() {
  while (true) {
    const { payload } = yield take(types.GET_ASSIGN_USERS);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getAssignUserResponse(response));
  }
}
export function* doChooseLp() {
  while (true) {
    const { payload } = yield take(types.CHOOSE_LP_REPORT_FILTER);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(getFilterLpResponse(response));
  }
}


function* getRes() {
  yield fork(doGetTeamMatesList);
  yield fork(doAssignTeamMates);
  yield fork(doGetOfficeList);
  yield fork(doGetCompletedCourseList);
  yield fork(doGetUsersByOfficeList);
  yield fork(doGetTeamsListReq);
  yield fork(doAssignToTeamsReq);
  yield fork(doGetAssignedUser);
  yield fork(doChooseLp);
}
export default getRes;
