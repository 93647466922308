import { useEffect, useState } from "react";
import _ from "lodash"
import VideoPlayer from "react-video-js-player";

const RolePlayAssetLibrarySection = (props) => {

    const [roleInfo, setRoleInfo] = useState('')
    const [solutionArray, setSolutionArray] = useState([])
    const [solutionId, setSolutionId] = useState('')

    useEffect(() => {
        if (!_.isEmpty(props.roleData)) {
            setRoleInfo(props.roleData)
        }
    }, [props.roleData])

    useEffect(() => {
        if (!_.isEmpty(roleInfo)) {
            if (roleInfo.title.includes('Overcoming')) {
                setSolutionArray([{
                    id: 1,
                    title: roleInfo.title,
                    url: "https://cdn.niit-mts.com/xsel/media/xsel_Roleplay-Overcoming_Objections_Section_02.m3u8",
                }])
            } else {
                setSolutionArray([{
                    id: 2,
                    title: roleInfo.title,
                    url: "https://cdn.niit-mts.com/xsel/media/xsel_Roleplay-Door_Knocking_Section_02.m3u8",
                }])
            }
        }
    }, [roleInfo])

    return (
        <>
            {props.showSolution ?
            <section className="asset-library-sec">
                <div className="row orders videolistbox assetlistvideo">
                    <div className="col-lg-8 col-sm-12 search__input_role cursor-pointer">
                        {
                            _.isEmpty(roleInfo) ? "" :
                            <VideoPlayer
                                controls={true}
                                src={roleInfo?.feedbackData.solution_url}
                                width={'100%'}
                                height={'30rem'}
                            />
                        }
                    </div>
                </div>
            </section>
            :
            <section className="asset-library-sec">
                {
                    solutionArray.length > 0 && solutionArray.map((solution, index) => {
                        return (
                            <div className="row orders videolistbox assetlistvideo">
                                <div className="col-lg-3 col-sm-4 search__input_role cursor-pointer"
                                    onClick={() => {
                                        props.setSolutionId(solution.id)
                                        props.setSolutionURL(solution.url)
                                        setSolutionId(solution.id)
                                    }}
                                >
                                    <div className="img-box_RP">
                                        <div className="img-videosec learn_image-card">
                                        
                                                <div className="img-box-video">
                                                    <img className="img-videosec learn_image-card cursor-not-allowed" src="/images/course_card_nw.jpg" alt="Video title" />
                                                    <div className="playbtnvideo">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                                            <g id="Group_5737" data-name="Group 5737" transform="translate(-251 -1026)">
                                                                <g id="Group_2568" data-name="Group 2568" transform="translate(7.795 7.795)">
                                                                <g id="Ellipse_200" data-name="Ellipse 200" transform="translate(243.205 1018.205)" fill="#febe10" stroke="#fff" stroke-width="4">
                                                                    <circle cx="24" cy="24" r="24" stroke="none"/>
                                                                    <circle cx="24" cy="24" r="22" fill="none"/>
                                                                </g>
                                                                </g>
                                                                <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,14H0Z" transform="translate(284 1040) rotate(90)" fill="var(--color-element-1)"/>
                                                            </g>
                                                            </svg>

                                                    </div>
                                                </div>
                                        </div>
                                        <div className="details-card_RP">
                                            <h5>{solution.title}</h5>
                                            <div className="row info-bottm-card_nw">
                                                <div className="col-md-12 rolesspace">
                                                    {/* <p>Release date: Jan 12, 22</p> 
                                                    <p> Duration: 05 min</p> */}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* <div className="assetviddes">
                                        Video 01
                                    </div> */}
                                    {solutionId == solution.id ? <div className="selected-bar"></div> : ''}
                                </div> 
                            </div>
                        )
                    })
                }
            </section>
            }
        </>
)   
}

export default RolePlayAssetLibrarySection;