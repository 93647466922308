import axios, { post } from 'axios'
import { SessionKeys } from '../utils/AppUtils'

import CONFIG from '../utils/config'
import { objectToFormData } from './data'
import { useHistory as history } from "react-router-dom";

export const userDetail = async (payload) => {

  return axios({
    method: "get",
    url: CONFIG.API_URL + CONFIG.API_ROUTES.LOGIN + "?token=" + localStorage.getItem(SessionKeys.API_TOKEN) + "&module=userDetails",
  }).then(response => {
    console.log('userDetil', response)
    if (response.data.status) {
      return {
        status: 'success',
        payload: {
          user: response.data.data
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}

export const userUpdate = async (payload) => {
  // const formData = objectToFormData(payload)
  return axios({
    method: "post",
    url: CONFIG.API_URL_REAL_BROKER + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_UPDATE_PROFILE,
    data: payload,
    headers: { "Content-Type": "application/json" },
  }).then(response => {
    // console.log('userDetil', response)
    if (response.data.status) {
      return {
        status: 'success',
        payload: {
          userUpdateRes: response.data.data
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}

export const searchUser = async (payload) => {

  return axios({
    method: "post",
    url: CONFIG.API_URL_REAL_BROKER + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_SEARCH_USER,                     //CONFIG.API_URL + CONFIG.API_ROUTES.LOGIN + "?token=" + localStorage.getItem(SessionKeys.API_TOKEN) + "&module=get_broker_realtor&broker_id=" + payload.broker_id + "&search=" + payload.searchInput,
    data: payload,
    headers: { "Content-Type": "application/json" }
  }).then(response => {
    // console.log('userDetilInApi', response)
    if (response.data.status) {
      return {
        status: 'success',
        payload: {
          searchData: response.data.data == null ? [] : response.data.data
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}


export const onUpload = async (payload) => {
  const url = CONFIG.API_URL_REAL_BROKER + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_UPLOAD_IMAGE_PATH;
  // console.log('urlBroker: ',url)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return post(url, payload, config).then((response) => {
    // console.log('created profile:' + response.data);
    if (response.data.status) {
      return {
        status: 'success',
        message: response.data.message,
        profile_img: response.data.data.profile_img
      }
    } else {
      if (response.data.message === 'Token is not valid') {
        alert("Your Session is Logged Out. Please Login.")
        window.alert = () => false
        localStorage.clear()
        window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
      }
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch(error => {
    // console.log(
    //   "errorData",
    //   JSON.stringify(error),
    //   error.response,
    //   error.message
    // );
    if (error.response != undefined && error.response?.status === 401) {
      // console.log("erroData clear local storage");
      alert("Your Session is Logged Out. Please Login.")
      window.alert = () => false
      localStorage.clear();
      // console.log("erroData local storage cleared");
      window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
      // console.log("go to window");
      // alert("Session is expired.")
      history.push("/", { sessionExpired: true });
      // console.log("errorData, history");
    }

    return {
      status: 'error',
      error
    }
  });

}


export const postUpload = async (payload) => {
  let url = CONFIG.API_URL_REAL_BROKER + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_UPLOAD_POST;
  if (payload.module === 'uploadPost') {
    url = CONFIG.API_URL_REAL_BROKER + CONFIG.API_ROUTES_REAL_BROKER.REAL_BROKER_UPLOAD_POST;
  } else if (payload.module === 'editPost') {
    url = CONFIG.API_URL_REAL_BROKER + CONFIG.API_ROUTES_REAL_BROKER.EDIT_POST;
  }
  // console.log('urlBroker: ',url)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return post(url, payload.postData, config).then((response) => {
    // console.log('created profile:' + response.data);
    if (response.data.status) {
      return {
        status: 'success',
        message: response.data.message,
        data: response.data.data
      }
    } else {
      if (response.data.message === 'Token is not valid') {
        alert("Your Session is Logged Out. Please Login.")
        window.alert = () => false
        localStorage.clear()
        window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
      }
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch(error => {
    // console.log(
    //   "errorData",
    //   JSON.stringify(error),
    //   error.response,
    //   error.message
    // );
    if (error.response != undefined && error.response?.status === 401) {
      // console.log("erroData clear local storage");
      alert("Your Session is Logged Out. Please Login.")
      window.alert = () => false
      localStorage.clear();
      // console.log("erroData local storage cleared");
      window.open(CONFIG.SESSION_EXPIRED_URL, "_self");
      // console.log("go to window");
      // alert("Session is expired.")
      history.push("/", { sessionExpired: true });
      // console.log("errorData, history");
    }

    return {
      status: 'error',
      error
    }
  });

}


