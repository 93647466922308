import types from "./types";

const initialState = {
  // User object
  auth: {},
  authBroker: {},
  authIframeBroker: {},
  authHostInst: {},
  forget_pwd_response: {},
  forget_pwd_broker_response: {},
  reset_pwd_response: {},
  reset_pwd_broker_response: {},
  authLogout: {},
  authLogoutBroker: {},
  authLogoutHostInst: {},
  verifyLink: {},
  setupPassword: {},
  setupPasswordBroker: {},
  resendLink: {},
  isLogout: false,
};

/**
 * login
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_RESPONSE:
      return {
        ...state,
        auth: action.payload,
      };
    case types.LOGIN_CLEAR:
      return {
        ...state,
        auth: {},
      };
    case types.LOGIN_BROKER_RESPONSE:
      return {
        ...state,
        authBroker: action.payload,
        isLogout: false,
      };
    case types.LOGIN_BROKER_CLEAR:
      return {
        ...state,
        authBroker: {},
        isLogout: true,
      };
    case types.LOGIN_IFRAME_USER_RESPONSE:
      return {
        ...state,
        authIframeBroker: action.payload,
        isLogout: false,
      };
    case types.LOGIN_IFRAME_USER_CLEAR:
      return {
        ...state,
        authIframeBroker: {},
        isLogout: true,
      };
    case types.LOGIN_HOSTINST_RESPONSE:
      return {
        ...state,
        authHostInst: action.payload,
        isLogout: false,
      };
    case types.LOGIN_HOSTINST_CLEAR:
      return {
        ...state,
        authHostInst: {},
        isLogout: true,
      };
    case types.FORGOT_PWD_RESPONSE:
      return {
        ...state,
        forget_pwd_response: action.payload,
      };
    case types.FORGOT_PWD_RESPONSE_CLEAR:
      return {
        ...state,
        forget_pwd_response: {},
      };
    case types.FORGOT_PWD_BROKER_RESPONSE:
      return {
        ...state,
        forget_pwd_broker_response: action.payload,
      };
    case types.FORGOT_PWD_BROKER_RESPONSE_CLEAR:
      return {
        ...state,
        forget_pwd_broker_response: {},
      };
    case types.RESET_PWD_RESPONSE:
      return {
        ...state,
        reset_pwd_response: action.payload,
      };
    case types.RESET_PWD_CLEAR:
      return {
        ...state,
        reset_pwd_response: {},
      };
    case types.RESET_PWD_BROKER_RESPONSE:
      return {
        ...state,
        reset_pwd_broker_response: action.payload,
      };
    case types.RESET_PWD_BROKER_CLEAR:
      return {
        ...state,
        reset_pwd_broker_response: {},
      };
    case types.LOGOUT_RESPONSE:
      return {
        ...state,
        authLogout: action.payload,
      };
    case types.LOGOUT_BROKER_RESPONSE:
      return {
        ...state,
        authLogoutBroker: action.payload,
      };
    case types.LOGOUT_BROKER_CLEAR:
      return {
        ...state,
        authLogoutBroker: {},
      };
    case types.LOGOUT_HOSTINST_RESPONSE:
      return {
        ...state,
        authLogoutHostInst: action.payload,
      };
    case types.LOGOUT_HOSTINST_CLEAR:
      return {
        ...state,
        authLogoutHostInst: {},
      };
    case types.VERIFY_ACTIVATION_LINK_RESPONSE:
      return {
        ...state,
        verifyLink: action.payload,
      };
    case types.VERIFY_ACTIVATION_LINK_CLEAR:
      //console.log('clear')
      return {
        ...state,
        verifyLink: {},
      };
    case types.RESEND_ACTIVATION_LINK_RESPONSE:
      return {
        ...state,
        resendLink: action.payload,
      };
    case types.RESEND_ACTIVATION_LINK_CLEAR:
      // console.log("clear resend");
      return {
        ...state,
        resendLink: {},
      };
    case types.SETUP_PASSWORD_RESPONSE:
      return {
        ...state,
        setupPassword: action.payload,
      };
    case types.SETUP_PASSWORD_CLEAR:
      return {
        ...state,
        setupPassword: {},
      };
    case types.SETUP_PASSWORD_BROKER_RESPONSE:
      return {
        ...state,
        setupPasswordBroker: action.payload,
      };
    case types.SETUP_PASSWORD_BROKER_CLEAR:
      return {
        ...state,
        setupPasswordBroker: {},
      };
    default:
      return state;
  }
};
