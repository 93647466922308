import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAnyTotalReport, getProfileCompletionReport } from '../../../redux/reports/actions'
import { SessionKeys } from '../../../utils/AppUtils'
import CourseFilterSearchSection from "./CourseFilterSearchSection";
import CourseTableSection from "./CourseTableSection";
import CourseTitleSection from "./CourseTitleSection";
import ReportViewFilterSection from "./../ReportViewFilterSection"
import CONFIG from "../../../utils/config"

const CourseReportHomeSection = (props) => {
    const {
        setIsLoading,
        isLoading
    } =props;

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [clickCompleted, setClickCompleted] = useState(true)
    const [clickNotCompleted, setClickNotCompleted] = useState(false)
    const [clickInProgress, setClickInProgress] = useState(false)
    const [clickOverDue, setClickOverDue] = useState(false)
    const [clickCompliance,setClickCompliance] = useState(false)
    const [isView, setIsView] = useState('table')
    const [type, setType] = useState('pie')
    const [statusFilterData, setStatusFilterData] = useState('table')
    const [canShow, setCanShow] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [officeIds, setOfficeIds] = useState([])

    useEffect(() => {
        dispatch(getAnyTotalReport({
            module: 'getCourseProgressReport',  
            postData: {
                date_range: {},
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                profile_status: ['Completed', 'NotStarted', 'Inprogress', 'OverDue'],
                compliance: clickCompliance ? 'yes' : 'no',
                office_ids: []
            }
        }))
    }, [dispatch])

    const getFilteredData = (action) => {
        let dateRange = {
            start: startDate?.toLocaleDateString("en-US"),
            end: endDate?.toLocaleDateString("en-US")
        }
        let profileStatus = []
        // let profileStatus = null;

        if(clickCompleted){
            profileStatus.push("Completed")
        }
        if(clickNotCompleted){
            profileStatus.push("NotStarted")
        }
        if(clickInProgress){
            profileStatus.push("Inprogress")
        }
        if(clickOverDue){
            profileStatus.push("OverDue")
        }
        
        setStatusFilterData(profileStatus)
        let req = {
            date_range: dateRange,
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
        };
        if (profileStatus !== null) {
            req.profile_status = profileStatus;
        } 
        if(searchInput!==''){
            req.search=searchInput;
        }
        if(clickCompliance){
            req.compliance = 'yes'
        }else{
            req.compliance = 'no'
        }
        req.office_ids = officeIds
        setIsLoading(true)
        dispatch(getProfileCompletionReport({
            module: 'getCourseProgressReport',  
            postData: req
        }))
        dispatch(getAnyTotalReport({
            module: 'getCourseProgressReport',  
            postData: {
                date_range: dateRange,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                profile_status: ['Completed', 'NotStarted', 'Inprogress', 'OverDue'],
                compliance: clickCompliance ? 'yes' : 'no',
                office_ids: officeIds
            }
        }))
    }

    return (
        <>
            <CourseTitleSection />
            <CourseFilterSearchSection
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                clickCompleted={clickCompleted}
                setClickCompleted={setClickCompleted}
                clickNotCompleted={clickNotCompleted}
                setClickNotCompleted={setClickNotCompleted}
                clickInProgress={clickInProgress}
                setClickInProgress={setClickInProgress}
                clickOverDue= {clickOverDue}
                setClickOverDue = {setClickOverDue}
                getFilteredData={getFilteredData}
                clickCompliance={clickCompliance}
                setClickCompliance={setClickCompliance}
                isView={isView}
                setIsView={setIsView}
                setOfficeIds={setOfficeIds}
            />
            {(canShow) ? <ReportViewFilterSection 
                isView={isView}
                setIsView={setIsView}
                type={type}
                setType={setType}
            /> : ''}
            <CourseTableSection
                setSearchInput={setSearchInput}
                getFilteredData={getFilteredData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isView={isView}
                statusFilterData={statusFilterData}
                setCanShow={setCanShow}
                type={type}
            />
        </>
    )
}

export default CourseReportHomeSection;