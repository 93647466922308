import React from 'react'

import Page from "../../components/Page"
import MenuDetailComponent from "../../components/menu_detail/MenuDetailComponent"
import { useEffect } from 'react'

const EditProfilePage = ({condition}) => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <Page>
      <MenuDetailComponent condition={condition} />
     
    </Page>
  )
}

export default EditProfilePage
