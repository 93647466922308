import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash';
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { useParams, useHistory } from 'react-router-dom';
import { myCart, myCartDelete, myCartDeleteClear, doGetMyCartDelete, myCartSaveLater, myCartSaveLaterClear, myCartSaveLaterViewClear, myCartSaveLaterView } from "../../redux/Cart/actions"
import { Carousel, Spinner } from 'react-bootstrap';
import { cartPageDataResponse } from '../../redux/checkout/actions';
import { userDetailBroker } from '../../redux/user/actions';
import { addToCart, addToCartClear, delWishList, delWishListClear, getWishList } from '../../redux/product/actions';
import ConfirmModal from '../modal/ConfirmModal';
import AlertDeleteCartsModal from '../Cart/alertModalDelCart';
import Slider from "react-slick";
import PrevArrow from "../LearningPaths/PrevArrow";
import NextArrow from "../LearningPaths/NextArrow";
import ImageComponent from '../Layouts/ImageComponent';

const WishListCourseCard = (props) => {

    const { myCartDetails, myCartLength, setConfirmModal, setShowModalMsg, setCourseId, confirmModal, showModalMsg, courseId, setIsLoading1 } = props;
    const isPremiumUser = useSelector(state => state.user.isPremiumUser)
    const userDetailRes = useSelector(state => state.user.userBroker)
    const addToCartRes = useSelector(state => state.product.addToCart)
    const authBroker = useSelector(state => state.auth.authBroker)
    const auth = useSelector(state => state.auth)
    const delWishListRes = useSelector(state => state.product.delWishList);

    const history = useHistory();
    const dispatch = useDispatch();

    const courseDetailRes = useSelector(state => state.product.courseDetail)

    const [cartProductId, setCartProductId] = useState()
    const [isLoading, setIsLoading] = useState()
    const [loadPos, setLoadPos] = useState()

    const [userDetails, setUserDetails] = useState({})
    const [showdeletecartmodal, setShowDeleteCartModal] = useState(false);

    useEffect(() => {
        // dispatch(userDetail())
        dispatch(userDetailBroker({
            module: 'getOrderDetailBroker',
            apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
        }))
    }, [])

    useEffect(() => {
        // console.log('userDetailRes: ', userDetailRes)
        setUserDetails(userDetailRes?.payload?.payload)
    }, [userDetailRes])

    useEffect(() => {
        // console.log('courseDetail1 idddd: ', cartProductId)
        setCartProductId(courseDetailRes?.payload)
    }, [courseDetailRes])

    useEffect(() => {
        // setShowModalMsg(addToCartRes?.payload.message)
        // console.log('addToCartRes: ', addToCartRes)
        if (Object.keys(addToCartRes).length !== 0) {
            dispatch(myCart({
                module: 'myCart',
                email: userDetails?.email
            }))
            dispatch(getWishList({ module: "getWishList", email: localStorage.getItem(SessionKeys.EMAIL_SESSION) }))
            setShowModalMsg(addToCartRes?.payload?.message)
            setConfirmModal(true)
            setIsLoading(false)
            // myCartDetails[loadPos].isLoading = false;

            // setIsAlreadyInCart(true)
            dispatch(addToCartClear())

        }
    }, [addToCartRes])

    useEffect(() => {
        // console.log('delWishListRes: ', delWishListRes);
        setIsLoading1(false)
        if (!_.isEmpty(delWishListRes)) {
            if (delWishListRes.status === "success") {

                setIsLoading1(true)
                dispatch(getWishList({ module: "getWishList", email: localStorage.getItem(SessionKeys.EMAIL_SESSION) }))
                dispatch(delWishListClear())

            }
        }
        setShowDeleteCartModal(true);
        handleCloseDelete();
    }, [delWishListRes])

    const addToCartFunction = (courseId) => {
        setIsLoading(true)
        dispatch(addToCart({
            module: 'addToCart',
            postData: {
                "pid": courseId,
                "email": userDetails?.email ? userDetails?.email : localStorage.getItem(SessionKeys.EMAIL_ID),
                "first_name": userDetails?.firstname,
                "last_name": userDetails?.lastname,
                "username": userDetails?.username,
                "company": "",
                "address_1": "",
                "address_2": "",
                "city": "",
                "state": "",
                "postcode": "",
                "country": "",
                "phone": "",
                "isPremium": isPremiumUser,
                "broker_id": userDetails?.broker_id,
                "company_id": userDetails?.company_id,
            }
        }))
    }

    const handleCloseDelete = () => {
        // currentProductId=null
        setShowDeleteCartModal(false);
    };

    const handleShowForDelete = (product_id) => {
        setShowDeleteCartModal(true);
    };

    const delMyWishListItem = () => {
        setIsLoading1(true)
        dispatch(delWishList({
            module: 'delWishList',
            postData: { id: courseId, email: authBroker.payload.user.email }
        }))
    };
    //THUMBNAIL
    const renderThumbnail = (m) => {
        return (
            <div className="image-cart-session">
                {/* <img
                    src={m.imageURL}
                    className="img-fluid galleryImg_lern"
                    alt=""
                    onError={({currentTarget}) => {
                        currentTarget.onerror = true;
                        currentTarget.src = "/images/default-demo-img.jpg"
                    }}
                /> */}
                <ImageComponent 
                    name={m.name}
                    thumbnail={m.imageURL ? m.imageURL : ""}
                    cssClass={"img-fluid galleryImg_lern"}
                    errorClass={"img-fluid galleryImg_lern"}
                />
            </div>
        );
    };
   
    const renderBundleCard = (m, index) => {
        return (
            // <div className="image-cart">
            //     <div id="cartCardCarousel" className="position-relative">
            //         <Carousel id="cartCourseCarousel">
            //             {
            //                 m.course_list.map((course, index) => {
            //                     return (
            //                         <Carousel.Item key={index}>
            //                             <img src={course.imageURL} alt="" />
            //                             <div className="combo-tag"><i className="fa fa-star" aria-hidden="true"></i> COMBO</div>
            //                         </Carousel.Item>
            //                     )
            //                 })
            //             }
            //         </Carousel>
            //     </div>
            // </div>

            //
            <div className="cart-wishox comboxwishlist">
                <div className="img-box_nw position-relative">
                <svg id="Close" onClick={() => { setCourseId(m?.id); handleShowForDelete(m?.id) }} className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                                            width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#00b7f1"/>
                            <stop offset="1" stop-color="#ed156e"/>
                            </linearGradient>
                        </defs>
                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"/>
                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"/>
                        <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                            <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                            <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                        </g>
                        <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                            <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                            <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                        </g>
                        </svg>
                    {/* <img
                        className="img-fluid closebtn-pfl"
                        onClick={() => { setCourseId(m?.id); handleShowForDelete(m?.id) }}
                        src="/images/cross.png"
                    /> */}
                    <div className="wish-btn-button-reset">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Like"
                                            width="30" height="30" viewBox="0 0 30 30">
                                            <defs>
                                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                    <stop offset="0" stop-color="#00b7f1" />
                                                    <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                            </defs>
                                            <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15"
                                                fill="url(#linear-gradient)" />
                                            <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)"
                                                fill="#fff" />
                                            <path id="Path_491" data-name="Path 491"
                                                d="M498.393,492.377c-2.813-.525-3.636,2.346-3.636,2.346s-.823-2.87-3.636-2.346a3.375,3.375,0,0,0-2.838,3.5c-.023,4.474,6.473,7.665,6.473,7.665s6.5-3.191,6.473-7.665A3.375,3.375,0,0,0,498.393,492.377Z"
                                                transform="translate(-479.757 -482.926)" fill="#ED156E" />
                                        </svg>
                                    </div>
                    <div className="combo-tag">
                        <i className="fa fa-star" aria-hidden="true"></i> COMBO
                    </div>
                    {/* <p>{m?.m.course_lessons}</p> */}
                    <div id="cartCardCarousels">
                        <Carousel id="courseCardCarousel" className="position-relative">
                            {m.course_list.map((course, ind) => {
                                return (
                                    <Carousel.Item key={ind}>
                                        <div className="img-videosec learn_image-card cursor-pointer">
                                            {/* <img
                                                src={course.imageURL}
                                                className="img-fluid galleryImg_lern"
                                                alt=""
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = true;
                                                    currentTarget.src = "/images/default-demo-img.jpg"
                                                }}
                                            /> */}
                                            <ImageComponent 
                                                name={course.name}
                                                thumbnail={course.imageURL ? course.imageURL : ""}
                                                cssClass={"img-fluid galleryImg_lern"}
                                                errorClass={"img-fluid galleryImg_lern"}
                                            />
                                        </div>

                                        {/* <p>{course.course_lessons}</p> */}
                                        <div className="img-box_nw-cart">
                                            {/* <img className="img-fluid galleryImg_lern" onClick={() => delSaveLaterItem(m.product_id) }
                                                src="/images/delete-icon.PNG" /> */}

                                            {/* <div className="img-videosec learn_image-card cross_Iconn">
                                                    <img className="img-fluid galleryImg_lern"
                                                        src={m.product_thumbnail} />
                                                    <div className="progress custom_progress_barr custom_bartwoRealtor">
                                                        <div className="progress-bar "></div>
                                                    </div>
                                                </div> */}
                                            <div className="details-card_nw">
                                                <h5>
                                                    <a
                                                        className="course-head-al_search"
                                                        
                                                    >
                                                        {course.name}
                                                    </a>
                                                </h5>
                                                <div className="row info-bottm-card_nw">
                                                    <div className="col-md-12 cou_cardsearch cart-card">
                                                        {m.type === 'videos' ? "" : <div className="inner-contentcar">
                                                            {course.lessons > 1 ? (
                                                                <p>
                                                                    {course.lessons}
                                                                    <span>&nbsp;Modules</span>
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {course.lessons}
                                                                    <span>&nbsp;Module</span>
                                                                </p>
                                                            )}
                                                            {/* {_.toNumber(m.product_regular_price) !== _.toNumber(m.product_sale_price) ? <div className=" shop-cost">
                                                                    <p>Retail price:</p>
                                                                    <p> ${m.product_regular_price}&nbsp;CAD</p>
                                                                </div> : ""} */}
                                                            {/* <p className="price-order">$29.99</p> */}
                                                        </div>}
                                                        {/* <p><span>{m.course_lessons}</span>Module(s)</p> */}
                                                        <div className="card-info">
                                                            <div className="card-sec">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        id="clock-time-four"
                                                                        d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                                                        transform="translate(-2 -2)"
                                                                        fill="var(--color-element-1)"
                                                                    />
                                                                </svg>

                                                                <div className="cart-timer">
                                                                    <p><b>{course.duration}</b></p>
                                                                    {/* <p>{course.durationUnit}</p> */}
                                                                </div>

                                                            </div>
                                                            <div className="card-sec">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        id="eye-circle"
                                                                        d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                                                        transform="translate(-2 -2)"
                                                                        fill="var(--color-element-1)"
                                                                    />
                                                                </svg>
                                                                <p>
                                                                    {course.views}<span>&nbsp;View</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                        <div className="position-absolute course-length-bar">
                            <span>{m.course_list.length}</span>&nbsp;
                            item{(m.course_list.length > 1 ? 's' : '')}
                        </div>
                    </div>
                </div>
                <div className="cart-body mt-3">
                    {/* <h2><sup>$</sup>{m.product_sale_price.split('.')[0]}<sup>{m.product_sale_price.split('.')[1]}</sup><span>CAD</span></h2> */}
                    <h2>
                        ${m.selling_price.toString().split(".")[0]}
                        {<sup>{m.selling_price.toString().split(".")[1]}</sup>}
                        <span>CAD</span>
                    </h2>
                    <button className="btn-carts cart-btn-primary" onClick={() => {
                        setCourseId(m?.id);
                        setLoadPos(index);
                        m.isLoading = true;
                        addToCartFunction(m?.id)
                    }}> {m.isLoading ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="primary"
                    /> : m?.is_addtocart ? "Added in Cart" : "Add to Cart"} </button>
                </div>
                <AlertDeleteCartsModal
                    deleteCart={() => { delMyWishListItem() }}
                    handleClose={handleCloseDelete}
                    show={showdeletecartmodal}
                />
                {/* <AlertAddtoCart
                        AddCart={() => {
                            addToCartFunction(m.product_id);
                        }}
                        handleClose={handleCloseAdd}
                        show={showaddmodal}
                    /> */}
                <ConfirmModal
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    showModalMsg={showModalMsg}
                    isConfirm={false}
                    confirmId={courseId}
                    confirmAction={function () { }}
                    isRightIcon={true} />
            </div>
        )
    }


    const renderSingleCard = (m, index) => {
        return (
            // <div className="image-cart">
            //     <img src={m.imageURL} alt="" />
            //     <div className="image-icons">
            //         <div className="cart-timer">
            //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            //                 <path id="clock-time-four"
            //                     d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
            //                     transform="translate(-2 -2)" fill="#fff" />
            //             </svg>
            //             <p>{m.duration}</p>
            //         </div>
            //         <div className="cart-timer">
            //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            //                 <path id="eye-circle"
            //                     d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
            //                     transform="translate(-2 -2)" fill="#fff" />
            //             </svg>
            //             <p>{m.views}<span>&nbsp;View</span></p>

            //         </div>
            //     </div>
            // </div>

            <div className="my_carts">
                <div className="cart-wishox">
                    <div className="cartCarousel position-relative">
                    <svg id="Close" onClick={() => { setCourseId(m?.id); handleShowForDelete(m?.id) }} className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                                            width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#00b7f1"/>
                            <stop offset="1" stop-color="#ed156e"/>
                            </linearGradient>
                        </defs>
                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"/>
                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"/>
                        <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                            <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                            <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                        </g>
                        <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                            <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                            <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"/>
                        </g>
                        </svg>
                        {/* <img
                            className="img-fluid closebtn-pfl"
                            src="/images/cross.png"
                            onClick={() => { setCourseId(m?.id); handleShowForDelete(m?.id) }}
                        /> */}
                        <div className="cart-head">
                            <div className="responsive-carousel">
                                <div className="col-md-12 mb-4 broker-box calendar_event">
                                    <div className="img-box_nw">
                                    <div className="wish-btn-button-reset">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Like"
                                            width="30" height="30" viewBox="0 0 30 30">
                                            <defs>
                                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                    <stop offset="0" stop-color="#00b7f1" />
                                                    <stop offset="1" stop-color="#ed156e" />
                                                </linearGradient>
                                            </defs>
                                            <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15"
                                                fill="url(#linear-gradient)" />
                                            <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)"
                                                fill="#fff" />
                                            <path id="Path_491" data-name="Path 491"
                                                d="M498.393,492.377c-2.813-.525-3.636,2.346-3.636,2.346s-.823-2.87-3.636-2.346a3.375,3.375,0,0,0-2.838,3.5c-.023,4.474,6.473,7.665,6.473,7.665s6.5-3.191,6.473-7.665A3.375,3.375,0,0,0,498.393,492.377Z"
                                                transform="translate(-479.757 -482.926)" fill="#ED156E" />
                                        </svg>
                                    </div>
                                        <div className="img-videosec learn_image-card cross_Iconn">
                                            {/* <img
                                                className="img-fluid galleryImg_lern"
                                                src={m.imageURL}
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = true;
                                                    currentTarget.src = "/images/default-demo-img.jpg"
                                                }}
                                            /> */}
                                            <ImageComponent 
                                                name={m.name}
                                                thumbnail={m.imageURL ? m.imageURL : ""}
                                                cssClass={"img-fluid galleryImg_lern"}
                                                errorClass={"img-fluid galleryImg_lern"}
                                            />
                                            <div className="progress custom_progress_barr custom_bartwoRealtor">
                                                <div className="progress-bar "></div>
                                            </div>
                                        </div>
                                        <div className="details-card_nw">
                                            <h5><span className="course-head-al_search" dangerouslySetInnerHTML={{
                                                __html: m.name,
                                            }}
                                                onClick={() => {
                                                    history.push(`/shopping/${m.id}/?addToCart=true`)
                                                }}></span></h5>

                                            <div className="row info-bottm-card_nw">
                                                <div className="col-md-12 cou_cardsearch cart-card">
                                                    {(m.type === 'video' || m.type === 'videos') ? <p></p> : (m.lessons) > 1 ? <p>{m.lessons}<span>&nbsp;Modules</span></p> : <p>{m.lessons}<span>&nbsp;Module</span></p>}
                                                    {/* <p><span>{m.course_lessons}</span>Module(s)</p> */}
                                                    <div className="card-info">
                                                        <div className="card-sec">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    id="clock-time-four"
                                                                    d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                                                    transform="translate(-2 -2)"
                                                                    fill="var(--color-element-1)"
                                                                />
                                                            </svg>
                                                            <p>
                                                                {m.duration}
                                                                <span>&nbsp;</span>
                                                            </p>
                                                        </div>
                                                        <div className="card-sec">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    id="eye-circle"
                                                                    d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                                                    transform="translate(-2 -2)"
                                                                    fill="var(--color-element-1)"
                                                                />
                                                            </svg>
                                                            {m.product_type === "videos" ? (
                                                                <p>
                                                                    {m.views}
                                                                    <span>&nbsp;View(s)</span>
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {m.views}<span>&nbsp;View</span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-body mt-3">
                                        {/* <h2><sup>$</sup>{m.product_sale_price.split('.')[0]}<sup>{m.product_sale_price.split('.')[1]}</sup><span>CAD</span></h2> */}
                                        {/* <h2>
                                            ${m.selling_price.toString().split(".")[0]}
                                            {<sup>{m.selling_price.toString().split(".")[1]}
                                            </sup>}
                                            <span>CAD</span>
                                        </h2> */}
                                      
                                            {/* {(m.type === 'video' || m.type === 'videos') ? <p></p> : (m.lessons) > 1 ? <p>{m.lessons}<span>&nbsp;Modules</span></p> : <p>{m.lessons}<span>&nbsp;Module</span></p>} */}
                                            {/* {_.toNumber(m.product_market_price) !== _.toNumber(m.selling_price) ? <div className=" shop-cost text-right">
                                                <p>Retail price:</p>
                                                <p> ${m.product_market_price}&nbsp;CAD</p>
                                            </div> : ""} */}

                                            {/* <p className="price-order">$29.99</p> */}
                                            {m.selling_price === m}
                                            <h2><span className="dollar-normal">$</span>{m?.selling_price.toString()?.split('.')[0]}<sup>{m?.selling_price.toString()?.split('.')[1]}</sup>&nbsp;<span>CAD</span></h2>
                                            <button className="btn-carts cart-btn-primary" onClick={() => {
                                                setCourseId(m?.id);
                                                setLoadPos(index);
                                                m.isLoading = true;
                                                addToCartFunction(m?.id)
                                            }}> {m.isLoading ? <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="primary"
                                            /> : m?.is_addtocart ? "Added in Cart" : "Add to Cart"} </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AlertDeleteCartsModal
                        deleteCart={() => { delMyWishListItem() }}
                        handleClose={handleCloseDelete}
                        show={showdeletecartmodal}
                    />
                    {/* <AlertAddtoCart
                        AddCart={() => {
                            addToCartFunction(m.product_id);
                        }}
                        handleClose={handleCloseAdd}
                        show={showaddmodal}
                    /> */}
                    <ConfirmModal
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        showModalMsg={showModalMsg}
                        isConfirm={false}
                        confirmId={courseId}
                        confirmAction={function () { }} 
                        isRightIcon={true}/>
                </div>
            </div>

        )
    }
    const renderCartCard = (m, index) => {
        return (
        <div className= "col-md-6 col-lg-4 col-xl-3 col-12 mb-4 broker-box">
                <div className="boxsliderspsv">
                    <div className="borker-box" key={index}>
                        {!_.isEmpty(m)
                            ? m.is_bundle
                                ? renderBundleCard(m)
                                : renderSingleCard(m)
                            : renderSingleCard(m)}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {myCartDetails.length > 0 ?
                <div>
                    {/* <div id="myOnboard" className="center_line catsap"></div> */}
                    <section className="wishlist-boxd">
                       
                            {/* <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <div className="learn-head-learning">
                                            <p className="cart-title"></p>
                                        </div>
                                    </div>
                                </div> */}
                            <div className="slider-course-listing row">
                               
                                    {myCartDetails.length > 0 ? (
                                        myCartDetails.map((m, index) => {
                                            return (
                                                <>
                                                    {renderCartCard(m, index)}
                                                    {/* {m.product_type === "videos" &&
                                                            renderCartCard(m, index)} */}
                                                    {/* {m.product_type === "session" &&
                                                            renderSessionCard(m, index)} */}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <p> </p>
                                    )}
                               
                            </div>
                        
                    </section>
                </div>
                : (
                    ""
                )}
        </>
    );
}

export default WishListCourseCard;