import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  myCart,
  myCartSaveLaterClear,
  myCartSaveLaterView,
  myCartSaveLater,
  myCartSaveLaterViewClear,
  myCartClear,
} from "../../redux/Cart/actions";
import { addToCart, addToCartClear } from "../../redux/product/actions";
import {
  cartPageDataResponse,
  sendOrderPlace,
  sendOrderPlaceClear,
} from "../../redux/checkout/actions";
import CartCourseCard from "./CartCourseCard";
import CartSummaryCard from "./CartSummaryCard";
import CartWishlistCard from "./CartWishlistCard";
import _, { parseInt } from "lodash";
import { convertHoursToMins, convertSecondsToMins, convertTimeFormat, fixedToDecimal, SessionKeys } from "../../utils/AppUtils";
import ConfirmModal from "../modal/ConfirmModal";
import { useHistory, useParams } from "react-router-dom";
import { clickStreamUser } from "../../utils/AppUtils";
import { getCreditPoint } from "../../redux/creditPoints/actions";
import SubscriptionCard from "./SubscriptionCard";
import Loader from "../Loader/Loader";

const CartSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const myCartRes = useSelector((state) => state.carts.myCart);
  const saveForLaterRes = useSelector((state) => state.carts.myCartSaveLater);
  const myCartSaveLaterViewRes = useSelector(
    (state) => state.carts.myCartSaveLaterView
  );
  const creditPoints = useSelector((state) => state.creditPoints.creditPoints);
  // console.log("saved res", myCartSaveLaterViewRes.length)
  const auth = useSelector((state) => state.auth.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const sendOrderPlaced = useSelector((state) => state.checkout.sendOrderPlace);
  const userDetailRes = useSelector((state) => state.user.userBroker);

  const [userCreditPoint, setUserCreditPoint] = useState(0);
  const [redeemCard, setRedeemCard] = useState(false);
  const [myCartDetails, setMyCartDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [savedData, setSavedData] = useState([]);
  const { courseId } = useParams();
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState("Cart successfully deleted");
  const [couponDetail, setCouponDetail] = useState({});
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [redeemPoint, setRedeemPoint] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);
  const [isCartLoading, setIsCartLoading] = useState(true)
  const [cartIdArr,setCartIdArr] = useState(null)
  const addToCartRes = useSelector((state) => state.product.addToCart);
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);

  useEffect(() => { }, [savedData]);


  useEffect(() => {
    // console.log("userDetailRes: ", userDetailRes);
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
      setUserDetails(userDetailRes?.payload?.payload);
    }
  }, [userDetailRes]);

  useEffect(() => {
    // console.log("Save for later data", myCartSaveLaterViewRes);
    if (!_.isEmpty(myCartSaveLaterViewRes)) {
      
      if (myCartSaveLaterViewRes.status === "success") {
        let cartData = myCartSaveLaterViewRes.payload;
        for (let cart of cartData) {
          cart.is_bundle = false;
          if (cart.sfdc_product_type.toLowerCase() === "bundle") {
            cart.is_bundle = true;
            for (let course of cart.course_list) {
              cart.course_lessons = cart.course_lessons + course.lessons;
              cart.course_duration = cart.course_duration + course.duration;
              cart.course_period = "Hour(s)";
            }
          }
        }
        setSavedData(cartData);
      } else {
        // console.log('Save for later data reset')
        setSavedData([]);
      }
      props.setIsLoading(false);
      dispatch(myCartSaveLaterViewClear());
    }
  }, [myCartSaveLaterViewRes]);
  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      props.setIsLoading(true);
      dispatch(
        myCart({
          module: "myCart",
          email: authBroker.payload.user.email,
        })
      );
      dispatch(
        getCreditPoint({
          module: "getCreditPoint",
          email: authBroker.payload.user.email,
        })
      );
      dispatch(
        myCartSaveLaterView({
          module: "show-save-later",
          userId: authBroker.payload.user.email,
        })
      );
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    setRedeemFunctionality(totalPrice);
  }, [redeemCard]);

  useEffect(() => {
    // console.log("sendOrderPlaced", sendOrderPlaced);
    if (!_.isEmpty(sendOrderPlaced)) {
      if (sendOrderPlaced.status === "success") {
        const data = sendOrderPlaced.payload.payload;
        props.setIsLoading(false);
        dispatch(
          myCart({
            module: "myCart",
            email: authBroker?.payload?.user?.email,
          })
        );
        clickStreamUser("order-placed-succesfully", {
          amount: totalPrice,
          itemCount: myCartDetails.length,
          userId: authBroker?.payload?.user.user_id,
        });

        if (!_.isEmpty(sendOrderPlaced.payload)) {
          history.push(
            `/orders?orderNumber=${data?.order_id}&orderStatus=succeeded&itemCount=${myCartDetails.length}`
          );
        } else {
          history.push(
            `/orders?orderStatus=succeeded&itemCount=${myCartDetails.length}`
          );
        }
      }
      dispatch(sendOrderPlaceClear());
    }
  }, [sendOrderPlaced]);

  useEffect(() => {
    totalPriceFromApi();
    //    if(couponCode===''){
    //     setIsAppliedCoupon(false)
    //     setCouponCode('')
    //    }
  }, [couponDetail]);

  const setRedeemFunctionality = (price) => {
    if (redeemCard) {
      // console.log(
      //   _.toNumber((userCreditPoint / 10).toFixed(2)),
      //   subTotalPrice,
      //   couponDetail.discountAmount
      // );
      if (userCreditPoint / 10 < price) {
        setRedeemAmount(userCreditPoint / 10);
        setRedeemPoint(userCreditPoint);
        if (isAppliedCoupon) {
          // console.log("coupon is applied");
          setTotalPrice(
            subTotalPrice - userCreditPoint / 10 - couponDetail.discountAmount
          );
        } else {
          setTotalPrice(subTotalPrice - userCreditPoint / 10);
        }
      } else if (userCreditPoint / 10 >= price) {
        // console.log(totalPrice * 10, totalPrice);
        setRedeemAmount(totalPrice);
        setRedeemPoint(fixedToDecimal(totalPrice * 10, 2));
        setTotalPrice(0);
      }
    } else {
      // setTotalPrice(subTotalPrice)
      if (isAppliedCoupon) {
        setTotalPrice(subTotalPrice - couponDetail.discountAmount);
        // console.log(
        //   "total price after redeem, with discount",
        //   subTotalPrice - couponDetail.discountAmount
        // );
      } else {
        // console.log(
        //   "total price after redeem, without discount",
        //   subTotalPrice
        // );
        setTotalPrice(subTotalPrice);
      }
      setRedeemAmount(0);
      setRedeemPoint(0);
    }
  };

  const totalPriceFromApi = () => {
    if (Object.keys(couponDetail).length > 0) {
      let orderTotal = couponDetail?.orderTotal.replace(",", "");
      if (redeemAmount) {
        setRedeemFunctionality(_.toNumber(orderTotal));
      } else {
        setTotalPrice(_.toNumber(orderTotal));
      }
    } else {
      // if(Object.keys(couponDetail).length ===0){
      //     console.log('total price in coupon', subTotalPrice)
      //     setTotalPrice(subTotalPrice)
      // }
    }
  };

  useEffect(() => {
    // console.log("myCartRes: ", myCartRes);
    // console.log("length", myCartRes);
    if (!_.isEmpty(myCartRes)) {
      let cartData = _.cloneDeep(myCartRes?.cart_list);
      let productIdArr = [];
      for (let cart of cartData) {
        productIdArr.push(cart.product_id);
        cart.is_bundle = false;
        if (cart.sfdc_product_type.toLowerCase() === "bundle") {
          cart.is_bundle = true;
          for (let course of cart.course_list) {
            cart.course_lessons = cart.course_lessons + course.lessons;
            cart.course_duration = cart.course_duration + course.duration;
            cart.course_period = "Hour(s)";
            if (course.durationUnit === "Hours") {
              const convertArray = _.toString(course.duration).includes(":")
                ? course.duration.split(":")
                : [course.duration, 0];
              const hoursMinutes = _.toNumber(convertArray[0]) * 60;
              const minutes = _.toNumber(convertArray[1]);
              const totalMinutes = hoursMinutes + minutes;
              course.duration = totalMinutes;
            }
            if (_.toNumber(course.duration) > 60) {
              let hours = Math.floor(course.duration / 60);
              let minutes = course.duration % 60;
              hours = hours >= 10 ? hours : `0${hours}`;
              minutes = minutes >= 10 ? minutes : `0${minutes}`;
              // console.log(course.duration, hours, minutes);
              course.duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
                } ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
              course.durationUnit = ""; //(hours == 1 && minutes == 0) ? 'Hour' : 'Hours'
              course.course_period = ""; //(hours == 1 && minutes == 0) ? 'Hour' : 'Hours'
            } else if (_.toNumber(course.duration) < 60) {
              // console.log("Minutes");
              course.duration = `${course.duration} ${course.duration > 1 ? "Mins" : "Min"
                }`;
              course.durationUnit = "";
              course.course_period = "";
            } else {
              // console.log("hour");
              course.duration = "1 Hour";
              course.durationUnit = "";
              course.course_period = "";
            }
          }
        } else {
          if (cart.course_period === "Hours") {
            const convertArray = _.toString(cart.course_duration).includes(":")
              ? cart.course_duration.split(":")
              : [cart.course_duration, 0];
            const hoursMinutes = _.toNumber(convertArray[0]) * 60;
            const minutes = _.toNumber(convertArray[1]);
            const totalMinutes = hoursMinutes + minutes;
            cart.course_duration = totalMinutes;
          }

          if (cart?.product_type === 'videos') {
            if (
              cart.durationUnit === "Second(s)" &&
              cart.course_duration >= 60
            ) {
              if (cart.course_duration % 60) {
                cart.course_duration = convertTimeFormat(
                  cart.course_duration,
                  true
                );
              } else {
                cart.course_duration = convertSecondsToMins(
                  cart.course_duration
                );
                cart.course_duration = convertTimeFormat(
                  cart.course_duration
                );
              }
              cart.durationUnit = "";
            } else if (
              cart.durationUnit === "Second(s)" &&
              cart.course_duration < 60
            ) {
              cart.course_duration = `${cart.course_duration} Sec${cart.course_duration > 1 ? "s" : ""
                }`;
                cart.durationUnit = "";
            } else if (cart.durationUnit === "Hours") {
              cart.course_duration = convertHoursToMins(
                cart.course_duration
              );
              cart.course_duration = convertTimeFormat(
                cart.course_duration
              );
              cart.durationUnit = "";
            }
          } else {
            if (_.toNumber(cart.course_duration) > 60) {
              let hours = Math.floor(cart.course_duration / 60);
              let minutes = cart.course_duration % 60;
              hours = hours >= 10 ? hours : `0${hours}`;
              minutes = minutes >= 10 ? minutes : `0${minutes}`;
              // console.log(cart.course_duration, hours, minutes);
              cart.course_duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
                } ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
              cart.course_period = ""; //(hours == 1 && minutes == 0) ? 'Hour' : 'Hours'
              cart.durationUnit = ""; //(hours == 1 && minutes == 0) ? 'Hour' : 'Hours'
            } else if (_.toNumber(cart.course_duration) < 60) {
              // console.log("Minutes");
              cart.course_duration = `${cart.course_duration} ${cart.course_duration > 1 ? "Mins" : "Min"
                }`;
              cart.course_period = "";
              cart.durationUnit = "";
            } else {
              // console.log("hour");
              cart.course_duration = "1 Hour";
              cart.course_period = "";
              cart.durationUnit = "";
            }
          }
        }
      }
      setCartIdArr(productIdArr);
      setMyCartDetails(cartData);
      let couponData = myCartRes?.coupon_data;
      if (!_.isEmpty(couponData)) {
        couponData.discountAmount = _.toNumber(couponData.discountAmount);
        setCouponDetail(couponData);
      }
      props.setIsLoading(false);
      setIsLoaded(false);
      setIsCartLoading(false)
      // dispatch(myCartClear())
    }
  }, [myCartRes]);

  useEffect(() => {
    // console.log("myCartDetails: ", myCartDetails);
    if (myCartDetails !== undefined) {
      // console.log("product priice", parseInt(myCartDetails[0].product_sale_price))
      var total = 0;
      for (var i = 0; i < myCartDetails.length; i++) {
        total += parseFloat(
          myCartDetails[i].product_sale_price?.replace(",", "")
        );
      }
      setSubTotalPrice(total);
      // console.log("calculated total price: ", total);
      setTotalPrice(total);
      totalPriceFromApi();
    }
  }, [myCartDetails]);

  useEffect(() => {
    return () => {
      // console.log("Saved for later Clear: ");
      dispatch(myCartSaveLaterViewClear());
    };
  }, []);

  useEffect(() => {
    return () => {
      // console.log("save-later: ");
      dispatch(myCartSaveLaterClear());
    };
  }, []);

  useEffect(() => {
    // console.log("creditPoint", creditPoints);
    if (!_.isEmpty(creditPoints)) {
      if (creditPoints.status === "success") {
        setUserCreditPoint(
          _.toNumber(creditPoints.payload.payload.credit_points)
        );
      }
    }
  }, [creditPoints]);

  const proceedToPayment = () => {
    clickStreamUser("proceed_to_buy_btn_click", {
      amount: totalPrice,
      item: myCartDetails.length,
      userId: auth.authBroker?.payload?.user.user_id,
    });
    let discount = _.toNumber(redeemAmount);
    discount += couponDetail?.discountAmount ? couponDetail.discountAmount : 0;
    dispatch(
      cartPageDataResponse({
        item: myCartDetails.length,
        subTotalPrice: fixedToDecimal(subTotalPrice, 2),
        discount: discount == 0 ? 0 : fixedToDecimal(discount, 2),
        price: fixedToDecimal(totalPrice, 2),
      })
    );

    // console.log("TOTALPRICE", totalPrice, {
    //   item: myCartDetails.length,
    //   subTotalPrice: _.toNumber(subTotalPrice.toFixed(2)),
    //   discount: _.toNumber(discount.toFixed(2)),
    //   price: _.toNumber(totalPrice.toFixed(2)),
    // });

    if (totalPrice === 0) {
      // return;
      history.push("/payment?&auto=true&redeempoints=" + redeemCard);
    } else {
      history.push("/payment?&auto=false&redeempoints=" + redeemCard);
    }
  };
  useEffect(() => {
    if (Object.keys(addToCartRes).length !== 0) {
      dispatch(addToCartClear());
      dispatch(
        myCart({
          module: "myCart",
          email: authBroker.payload.user.email,
        })
      );
      dispatch(
        myCartSaveLaterView({
          module: "show-save-later",
          userId: authBroker.payload.user.email,
        })
      );
    }
  }, [addToCartRes]);
  return (
    <>
      <Loader start={isLoaded} />
      <section className="my_cart">
        {/* <div className={`${myCartDetails.length > 0 ? '' : 'h-75'} cart-wrapper pb-5`}> */}
        <div className={`cart-wrapper`}>
          <div className={`container`}>
            <div className="row">
              <h3>My Cart</h3>
            </div>
            {!_.isEmpty(myCartDetails) && myCartDetails.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-xl-8 col-md-12 col-sm-12 cart-content">
                    <p className="mb-3">
                      {myCartDetails.length} Item{myCartDetails.length > 1 ? 's' : ''} for{" "}
                      <span className="dollar-normal">$</span>
                      {fixedToDecimal(subTotalPrice, 2).split(".")[0]}
                      <sup>{fixedToDecimal(subTotalPrice, 2).split(".")[1]}</sup>&nbsp;
                      <span>CAD</span>
                    </p>
                    <CartCourseCard
                      myCartDetails={myCartDetails}
                      myCartLength={myCartDetails.length}
                      setIsLoading={props.setIsLoading}
                    />
                    {/* <SubscriptionCard/> */}
                    <div className="center_line"></div>
                    <div className="cart-info-bottom">
                      <div className="cart-total">
                        <p className="content-total">Sub-Total</p>
                        <h2>
                          <span className="dollar-normal">$</span>
                          {fixedToDecimal(subTotalPrice, 2).split(".")[0]}
                          <sup>{fixedToDecimal(subTotalPrice, 2).split(".")[1]}</sup>
                          &nbsp;<span>CAD</span>
                        </h2>
                      </div>
                      <div className="inner-total">
                        <p>{myCartDetails.length}</p>
                        <p>Item{myCartDetails.length > 1 ? 's' : ''}</p>
                      </div>
                      {/* <div className="d-flex justify-content-end">
                                            <button className="btn-procced cart-btn btn-primary" onClick={() => proceedToPayment()}>
                                                Proceed to Buy </button>
                                        </div> */}
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-12 col-sm-12 paymentboxcart">
                    <CartSummaryCard
                      myCartLength={myCartDetails.length}
                      totalPrice={totalPrice}
                      setTotalPrice={setTotalPrice}
                      subTotalPrice={subTotalPrice}
                      setSubTotalPrice={setSubTotalPrice}
                      userCreditPoint={userCreditPoint}
                      redeemCard={redeemCard}
                      proceedToPayment={proceedToPayment}
                      setRedeemCard={setRedeemCard}
                      couponDetail={couponDetail}
                      setCouponDetail={setCouponDetail}
                      isAppliedCoupon={isAppliedCoupon}
                      setIsAppliedCoupon={setIsAppliedCoupon}
                      confirmModal={confirmModal}
                      setConfirmModal={setConfirmModal}
                      setShowModalMsg={setShowModalMsg}
                      showModalMsg={showModalMsg}
                      couponCode={couponCode}
                      setCouponCode={setCouponCode}
                      redeemAmount={redeemAmount}
                      redeemPoint={redeemPoint}
                    />
                  </div>
                </div>
                {!isPremiumUser && <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12">
                    <div className="cart-offer">
                      <img
                        src="/images/static-banner1.png"
                        className="img-fluid"
                        alt="Cart Offer"

                      />
                      <div className="clickmap" onClick={
                        () => {

                          clickStreamUser("upgrade-btn-clicked", {

                            user_id: authBroker.payload.user.user_id,

                          });

                          history.push("/membership");

                        }
                      }>
                      </div>
                    </div>
                  </div>
                </div>}
              </>
            ) : (
              (!props.isLoading && !isCartLoading) && (
                <div className="not-found cart-notfound">
                  <div className="interboxes">
                    <img src="/images/telescope.svg" alt="Not Found" />
                    <p>No item added in the cart.</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <CartWishlistCard
        savedDataList={savedData}
        setIsLoading={props.setIsLoading}
        cartIdArr={cartIdArr}
      />
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={showModalMsg}
        isConfirm={false}
        confirmId={courseId}
        confirmAction={function () { }}
        isRightIcon={true}
      />
      {/* <div className="my-cart-page">
                <section className="payment payment-form-section">
                    <div className="Payment-container">
                        <div className="container">
                            
                        </div>
                        
                    </div>
                </section>
            </div> */}
    </>
  );
};
export default CartSection;
