import types from './types'

const initialState = {
    membershipProducts: {},
    membershipIntent: {},
    membershipOrderPlace: {},
    isMembershipSucceed: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.MEMBERSHIP_PRODUCTS_RESPONSE:
            return {
                ...state,
                membershipProducts: action.payload
            }
        case types.MEMBERSHIP_PRODUCTS_CLEAR:
            return {
                ...state,
                membershipProducts: {}
            }
        case types.MEMBERSHIP_INTENT_RESPONSE:
            return {
                ...state,
                membershipIntent: action.payload
            }
        case types.MEMBERSHIP_INTENT_CLEAR:
            return {
                ...state,
                membershipIntent: {}
            }
        case types.MEMBERSHIP_ORDER_PLACE_RESPONSE:
            return {
                ...state,
                membershipOrderPlace: action.payload
            }
        case types.MEMBERSHIP_ORDER_PLACE_CLEAR:
            return {
                ...state,
                membershipOrderPlace: {}
            }
        case types.MEMBERSHIP_IS_SUCCEED:
            return {
                ...state,
                isMembershipSucceed: true
            }
        case types.MEMBERSHIP_IS_CLEAR:
            return {
                ...state,
                isMembershipSucceed: false
            }
        
        default:
            return state
    }
}