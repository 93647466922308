import ArticleCard from "./ArticleCard";

const MoreArticles = (props) => {
    const { articles } = props
    return (
        <div className="more-articles">
            <div className="row course-cards_nw">
                <div className="view-button mt-4">
                    <p className="lablesech">More Articles</p>
                </div>
               {articles?.map((article,index)=>{
                   return <ArticleCard articleData={article}/>
               })
                }
            </div>
        </div>
    )
}

export default MoreArticles;