import { routerToPath } from "./AppUtils";

const MESSAGE = {
    LINK_EXPIRED: "The link you are trying to access is invalid or has expired.",
    ACTIVE_IN_OTHER_TAB: "Unable to perform your action. Logout from all instances of this portal and try again.",

    // My Roleplay Empty card message
    EMPTY_MYROLEPLAY_ALL: "Select a new Role play to participate.",
    EMPTY_MYROLEPLAY_SAVED: "No role play in draft.",
    EMPTY_MYROLEPLAY_SEARCH: "Sorry, no role play as per your search",
    EMPTY_ROLEPLAY_REVIEW: "No role play received for review.",
    EMPTY_ROLEPLAY_COMPLETE: "No role play review completed.",
    EMPTY_MYROLEPLAY_SUBMITTED: "No role play submitted for review.",
    EMPTY_MYROLEPLAY_REVIEWED: "No role play feedback pending from reviewer.",
    COACH_REQUIRED_MESSAGE: "You need a Coach to submit role play for review.",
}

export default MESSAGE;