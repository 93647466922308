import { hostSession, joinSession } from "../../redux/events/actions";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Component } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";

class CustomEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.categorySelect = this.categorySelect.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  categorySelect(category) {
    this.props.event.onCategorize(this.props.event, category);
    this.setState({ popoverOpen: false });
  }

  joinSession(eventData) {
    // console.log(eventData)
    this.props.joinZoomSession({
      module: "joinZoomMeeting",
      postData: {
        user_id: eventData.userId,
        meeting_id: eventData.meetingId,
        session_id: eventData.sessionId,
        segment_id:eventData.segmentId
      },
    });
  }

  renderButton() {
    if (this.props.event.tense === "past") {
      return <></>;
    } else if (this.props.event.tense === "future") {
      // return (
      // <button className={'btn-join'}>
      //   Join
      // </button>
      // )
    } else {
      const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const startTime = moment(`${this.props.event.start}`)
        .tz(systemTz)
        .subtract(15, "minutes");
      const endTime = moment(`${this.props.event.end}`).tz(systemTz);
      const currentTime = moment().tz(systemTz);
      if (
        startTime.isSameOrBefore(currentTime) &&
        currentTime.isSameOrBefore(endTime)
      ) {
        return (
          <button
            className={"btn-join active"}
            onClick={() => this.joinSession(this.props.event)}
          >
            Join
          </button>
        );
      } else {
        // return (
        // <button className={'btn-join'}>
        //   Join
        // </button>
        // )
      }
    }
  }

  renderCalendarCard = () => {
    return (
      <div className="event-title">
        <div className="d-flex justify-content-between flex-column w-100 event-block">
          <div
            onClick={this.toggle}
            id={"popover-" + this.props.event.id}
            title={this.props.event.title}
          >
            {this.props.event.title}
          </div>
          <div className="mt-2">{this.renderButton()}</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {/* <div className="event-title" onClick={this.toggle} id={"popover-" + this.props.event.id} title={this.props.event.title}>{this.props.event.title}</div> */}
        {this.renderCalendarCard()}

        <Popover
          placement="right"
          className="popover-container"
          isOpen={this.state.popoverOpen}
          target={"popover-" + this.props.event.id}
          toggle={this.toggle}
        >
          <PopoverHeader className="events-popup">
            {this.props.event.title}
          </PopoverHeader>
          <PopoverBody className="events-popup_body">
            <div className="d-flex gap-2 align-items-center mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.105"
                height="21.105"
                viewBox="0 0 11.105 11.105"
              >
                <path
                  id="clock-time-four"
                  d="M7.553,2a5.553,5.553,0,1,0,5.553,5.553A5.569,5.569,0,0,0,7.553,2M9.94,9.33,7,7.719V4.776H7.83V7.22l2.5,1.388Z"
                  transform="translate(-2 -2)"
                  fill="var(--color-element-1)"
                />
              </svg>
              <span className="side-text">
                {/* {this.props.event.start} */}
                {moment(`${this.props.event.start}`).format("DD MMM YYYY") +
                  " at " +
                  moment(`${this.props.event.start}`).format("hh:mm A") +
                  " " +
                  this.props.event.timezone}
              </span>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.623"
                height="22.159"
                viewBox="0 0 8.623 12.159"
              >
                <g
                  id="Icon_feather-map-pin"
                  data-name="Icon feather-map-pin"
                  transform="translate(-4.5 -1.499)"
                >
                  <path
                    id="Exclusion_6"
                    data-name="Exclusion 6"
                    d="M15192.567,408.817h0c-.043-.045-4.309-4.523-4.309-7.844a4.312,4.312,0,1,1,8.623,0c0,3.317-4.27,7.8-4.312,7.844Zm0-9.6a1.752,1.752,0,1,0,1.75,1.754A1.757,1.757,0,0,0,15192.571,399.218Z"
                    transform="translate(-15183.758 -395.16)"
                    fill="var(--color-element-1)"
                  />
                </g>
              </svg>
              <span className="side-text text-capitalize">
                {this.props.event.channel}
              </span>
            </div>
            <div className="d-flex justify-content-end mt-2">
              {this.renderButton()}
            </div>

            {/* <ListGroup>
                  {Object.keys(categories).map((category, inx) => {
                    return (
                      <ListGroupItem
                        key={inx}
                        onClick={() => this.categorySelect(category)}
                      >
                        {categories[category]}
                      </ListGroupItem>
                    );
                  })}
                </ListGroup> */}
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    joinZoomSession: (data) => {
      dispatch(joinSession(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(CustomEvent);
