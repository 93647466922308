import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CourseListBody from "./CourseListBody";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCheckListInsert,
  getCourseRelated,
  getCourseRelatedClear,
  getModuleFavGet,
  getModuleListing,
  getModuleListingBroker,
  getSingleLP,
  getSingleLPClear,
  markModuleProgress,
  markModuleProgressClear,
  openHamburger,
} from "../../redux/courses/actions";
import { clickStreamUser, convertHoursToMins, convertTimeFormat, formatLPCoursesList, getFormatCourseListFromWoo, SessionKeys } from "../../utils/AppUtils";
import { ModuleComp } from "./ModuleComp";
import JSZip from "jszip";
import { Spinner } from "react-bootstrap";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import CONFIG from "../../utils/config";
import { ModuleNotes } from "../../pages/modulePages/ModuleNotes";
import { getModulenotesGet } from "../../redux/courses/actions";
import {
  getModuleNotesDelete,
  getModuleNotesDeleteClear,
  getModuleNotesUpdate,
  getModuleNotesUpdateClear,
} from "../../redux/courses/actions";
import ModuleFav from "../../pages/modulePages/ModuleFav";
import { array } from "yup";
import CongratulationsPopUp from "./CongratulationsPopUp";
import CongPopupAnimation from "./CongPopupAnimation";
import { checkContentAccess } from "../../redux/home/actions";
import Loader from "../Loader/Loader";
import CourseCardListBody from "./CourseCardListBody";
import LPMoreLearn from "./LPMoreLearn"


const CourseLaunchSection = ({ isMobile }) => {
  const history = useHistory();

  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState("Please wait")
  const [activeTab, setActiveTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const [courseTitle, setCourseTitle] = useState(
    history?.location?.state?.courseTitle
  );
  const [courseProgress, setCourseProgress] = useState(
    history?.location?.state?.courseProgress
  );
  const [selectedModule, setSelectedModule] = useState(
    history?.location?.state?.module
  );
  const [moduleId, setModuleId] = useState(history?.location?.state?.mId);
  const moduleListingData = useSelector((state) => state.courses.moduleListing);
  const moduleListingDataBroker = useSelector(
    (state) => state.courses.moduleListingBroker
  );
  const [notesList, setNotesList] = useState([]);
  const [insertNoteRecord, setInsertNoteRecord] = useState(null);
  const [insertFavRecord, setInsertFavRecord] = useState(null);
  const [moduleData, setModuleData] = useState({});
  const [iframeId, setIframeId] = useState(null);
  const [selectedModulePos, setSelectedModulePos] = useState(null);
  const [isLoad, setIsLoad] = useState(true)
  const [isModuleChange, setIsModuleChange] = useState(false)
  const [reload, setReload] = useState(true);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const source = params.get("source");
  const cId = params.get("source");
  const via_lp = params.get('via_lp');
  const lp_id = params.get('lp_id');
  const via_cat = params.get('via_cat');
  const cat_id = params.get('cat_id');
  const authBroker = useSelector((state) => state.auth.authBroker);
  const moduleNotesUpdate = useSelector(
    (state) => state.courses.moduleNotesUpdate
  );
  const moduleProgress = useSelector((state) => state.courses.moduleProgress);
  const lpData = useSelector((state) => state.courses.lpData)
  const courseRelatedData = useSelector((state) => state.courses.courseRelated)
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const checkContent = useSelector((state) => state.home.checkContent);

  // const moduleData = moduleListing?.payload

  const { courseId } = useParams();
  const { courseCode } = useParams();
  const [congModal, setCongModal] = useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const moduleNoteData = useSelector(
    (state) => state.courses.moduleNotesGet.payload
  );
  const nextButton = useRef(null);
  const [isShowCongrat, setIsShowCongrat] = useState(false)
  const [isFree, setIsFree] = useState(true)
  const [singleLpData, setSingleLpData] = useState({})
  const [courseListingData, setCourseListingData] = useState([])
  const [showNextCourse, setShowNextCourse] = useState(false)
  const [isLastRecord, setIsLastRecord] = useState(true)

  useEffect(() => {
    if (!isMobile) {
      clickStreamUser("course_launch_page", {
        courseCode: courseCode,
        userId: auth.authBroker?.payload?.user.user_id,
      });
    } else {
      clickStreamUser("course_launch_page", {
        courseCode: courseCode,
        username: localStorage.getItem(SessionKeys.USERNAME),
      });
    }
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    // find the type of module
    // if type == scorm and source != broker, call the notes and fav api
    // else if source == broker and type != scorm, call the marProgress api
    if (moduleId && !_.isEmpty(authBroker)) {
      // console.log("getNotes 2");
      // console.log("TODO AFTER CHANGES");
      if ((source != "broker")) {
        dispatch(
          getModulenotesGet({
            module: "getNotes",
            postData: {
              mid: moduleId,
              uid: authBroker.payload.user.user_id,
              course_code: courseCode.split("?")[0],
            },
          })
        );
        dispatch(
          getModuleFavGet({
            module: "getModuleFavourites",
            // token: localStorage.getItem(SessionKeys.API_TOKEN),
            postData: {
              mid: moduleId,
              uid: authBroker.payload.user.user_id,
              course_code: courseCode.split("?")[0],
            },
          })
        );
      }
      if ((source === "broker") &&
        (!['mov', 'mp4', 'mkv', 'webm', 'm3u8', 'url', 'pdf'].includes(selectedModule?.rsp_scromdata[0]?.type))) {
        dispatch(
          markModuleProgress({
            module: "markModuleProgress",
            postData: {
              module_id: moduleId,
              token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
              course_id: selectedModule?.crs,
            },
          })
        );
      }
    }
  }, [moduleId, authBroker]);

  useEffect(() => {
    if (source === "xsel" || source === "ecom") {
      if (!_.isEmpty(auth.authBroker)) {
        // setIsLoading(true);
        setModuleData({});
        dispatch(
          getModuleListing({
            module: "getNIITAdminModule",
            postData: {
              user_id: auth.authBroker.payload.user.user_id,
              course_id: courseCode.split('?')[0],
            },
          })
        );

        // courseCode.split("?")[0]

        dispatch(checkContentAccess({
          module: 'checkContentAccess',
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            origin: source === 'ecom' ? 'xsel' : 'broker',
            entity_id: source === 'ecom' ? courseCode.split("?")[0] : '',
            entity_type: 'courses'
          }
        }))


      }
    } else {
      // setIsLoading(true);
      setModuleData({});
      dispatch(
        getModuleListingBroker({
          module: "get_all_course_modules",
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          courseId: courseCode.split('?')[0],
        })
      );
    }
  }, [authBroker])

  useEffect(() => {
    // console.log('CHECK CONTENT', checkContent)
    if (!_.isEmpty(checkContent)) {
      if (checkContent.status === "success") {
        const data = checkContent.payload.payload;
        if (!_.isEmpty(data)) {
          setIsFree(data.is_free)
          // dispatch(checkContentAccessClear());
        }
      }
    }
  }, [checkContent]);

  useEffect(() => {
    if (source === "xsel" || source === "ecom") {
      if (
        !_.isEmpty(moduleListingData) &&
        moduleListingData.status === "success"
      ) {
        console.log('dataModules: ',moduleListingData.payload.payload);
        if (moduleListingData?.payload) {
          const modulesInfo = _.cloneDeep(moduleListingData.payload.payload);
          console.log('dataModules: ',modulesInfo );
          modulesInfo?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
          if (modulesInfo.modules.length > 0) {
            modulesInfo.modules[0].isLock = false;
            for (let [index, modules] of modulesInfo.modules.entries()) {
              modules.isLock = modules.isLock == false ? modules.isLock : true;
              if (modules.progress > 0) {
                modules.isLock = false;
                if (index + 1 !== modulesInfo.modules.length)
                  modulesInfo.modules[index + 1].isLock =
                    _.toNumber(modules.progress) === 100 ? false : true;
              }
            }
          }
          // console.log("modulesInfo", modulesInfo);
          setModuleData(modulesInfo);
          modulesInfo.modules.map((item, index) => {
            if (item.module_id === selectedModule?.module_id) {
              setSelectedModulePos(index);
              onClickModule(item, index)
            }
          });
        }
      }
    } else {
      if (
        !_.isEmpty(moduleListingDataBroker) &&
        moduleListingDataBroker.status === "success"
      ) {
        console.log(moduleListingDataBroker,"moduleListingDataBroker1234");
        if (moduleListingDataBroker?.payload) {
          const modulesInfo = _.cloneDeep(moduleListingDataBroker.payload);
          console.log('dataModules: ',modulesInfo );
          modulesInfo?.modules?.sort((a, b) => parseFloat(a.module_sequence) - parseFloat(b.module_sequence));
          if (modulesInfo.modules.length > 0) {
            modulesInfo.modules[0].isLock = false;
            for (let [index, modules] of modulesInfo.modules.entries()) {
              modules.isLock = modules.isLock == false ? modules.isLock : true;
              if (modules.progress > 0) {
                modules.isLock = false;
                if (index + 1 !== modulesInfo.modules.length)
                  modulesInfo.modules[index + 1].isLock =
                    _.toNumber(modules.progress) === 100 ? false : true;
              }
            }
          }
          console.log("modulesInfoDataaaa", modulesInfo);
          setModuleData(modulesInfo);
          modulesInfo.modules.map((item, index) => {
            if (item.id === selectedModule?.module_id) {
              setSelectedModulePos(index);
            }
          });
        }

        // console.log("moduleData: ", moduleListingDataBroker);
      }
    }
  }, [moduleListingData, moduleListingDataBroker]);

  useEffect(() => {

    if (!_.isEmpty(moduleData) && !_.isEmpty(moduleProgress)) {
      console.log(moduleData,"moduleData");
      console.log(moduleProgress,"moduleProgress");
      setIsLoading(false)
      if (
        source === "broker" &&
        moduleProgress?.status === "success"
      ) {
        const selectModule = _.cloneDeep(selectedModule);
        console.log(selectModule,"selectModule");
        if (parseInt(selectModule.progress) !== 100) {
          if (['mov', 'mp4', 'mkv', 'webm', 'm3u8', 'url', 'pdf'].includes(selectModule?.rsp_scromdata[0]?.type)) {
            selectModule.progress = 100;
            setSelectedModule(selectModule);
          } else {
            setTimeout(() => {
              selectModule.progress = 100;
              setSelectedModule(selectModule);
            }, 8000)
          }

          if ((moduleData?.modules?.length === selectedModulePos + 1)) {
            if (['mov', 'mp4', 'mkv', 'webm', 'm3u8', 'url', 'pdf'].includes(selectModule?.rsp_scromdata[0]?.type)) {
              setTimeout(() => {
                setIsShowCongrat(true)
              }, 2000)
            } else {
              setTimeout(() => {
                setIsShowCongrat(true)
              }, 10000)
            }
            if (via_lp === 'true') {
              getSingleLPData()
            } else if (via_cat === 'true') {
              getCoursesListingData()
            }
          }

        }
        dispatch(markModuleProgressClear());

      }
    }
  }, [moduleProgress]);

  useEffect(() => {

    // console.log("selectedmodule", selectedModule);
    if (!_.isEmpty(moduleData)) {
      // console.log("selectedmodule", moduleData);
      const moduleDataClone = _.cloneDeep(moduleData);
      console.log("moduleDataAtProgress3: ", moduleDataClone);
      for (let [index, module] of moduleDataClone.modules.entries()) {
        if (module.module_id === selectedModule?.module_id) {
          // if (moduleDataClone.modules[index].progress < 100)
          moduleDataClone.modules[index].progress = _.toNumber(
            selectedModule?.progress
          );
        }
        module.isLock = module.isLock == false ? module.isLock : true;
        if (module.progress > 0) {
          module.isLock = false;
          if (index + 1 !== moduleDataClone.modules.length)
            moduleDataClone.modules[index + 1].isLock =
              _.toNumber(module.progress) === 100 ? false : true;
        }
      }
      setModuleData(moduleDataClone);
    }
  }, [selectedModule]);

  useEffect(() => {

    const handleProgress = (evt) => {
      let dataObj =
        typeof evt.data === "object" ? evt.data : JSON.parse(evt.data);
      if (dataObj && dataObj.method === "progress") {
        const selectModule = _.cloneDeep(selectedModule);

        // console.log('moduleDataAtProgress: ', dataObj, selectModule)
        if (parseInt(selectModule.progress) !== 100 && dataObj.module_id === selectModule.module_id) {
          if (source !== 'broker' || parseInt(dataObj.value) !== 100) {
            selectModule.progress = parseInt(dataObj.value);
          }
          console.log('moduleDataAtProgress1: ', dataObj, selectModule)
          if (source !== 'broker' && parseInt(dataObj.value) === 100 && (moduleData?.modules?.length === (selectedModulePos + 1))) {
            // console.log('moduleDataAtProgress1: ',moduleData)
            setIsShowCongrat(true)
            window.scrollTo(0,0)
            if (via_lp === 'true') {
              getSingleLPData()
            } else if (via_cat === 'true') {
              getCoursesListingData()
            }
          }
          if (parseInt(dataObj.value) === 100 && source === 'broker') {
            dispatch(
              markModuleProgress({
                module: "markModuleProgress",
                postData: {
                  module_id: selectModule.module_id,
                  token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                  course_id: selectedModule?.crs,
                },
              })
            );
          }

          setSelectedModule(selectModule);

        }


      }
    };
    // if (source !== "broker") {
    // window.addEventListener("message", handleProgress);
    // return () => {
    //   window.removeEventListener("message", handleProgress);
    // };
    // }
  }, [selectedModule]);

  useEffect(() => {
    let activeTabName = null;
    switch (activeTab) {
      case 1:
        activeTabName = "Modules";
        break;
      case 2:
        activeTabName = "Resources/Toolkits";
        break;
      case 3:
        activeTabName = "My Favorites";
        break;
      case 4:
        activeTabName = "Notes";
        break;
    }
    clickStreamUser("course_tab_change", {
      courseCode: courseCode,
      activeTab: activeTabName,
    });
  }, [activeTab]);

  useEffect(() => {
    if (!_.isEmpty(lpData)) {
      if (lpData.status === 'success') {
        let learning = _.cloneDeep(lpData.payload.payload)
        if (!_.isEmpty(learning)) {
          let sendFlag = false;
          let pushCourse = false;
          learning = formatLPCoursesList(learning)
          if (learning.coursesList) {
            for (let [index, course] of learning.coursesList.entries()) {
              let currentCourse = course;
              let nextCourse = learning.coursesList[index + 1];
              if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present && !sendFlag) {
                if (currentCourse.crs_progress >= 100 && (index !== learning.coursesList.length - 1)) {
                    dispatch(
                        getCheckListInsert({
                        module: 'checklist-insert',
                        postData: {
                            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                            lp_id: learning?.id,
                            entity_id: currentCourse.source === 'broker' ? currentCourse.id : currentCourse?.crscd,
                            entity_type: !currentCourse?.is_level ? "course" : "level"
                        }
                    }))
                    sendFlag = true;
                }
              }
              if (pushCourse) {
                learning.newCourseList.push(course)   
              }
              if (course.crscd === courseCode.split("?")[0]) {
                  pushCourse = true;
              }
            }
            learning.coursesList = learning.newCourseList;
          }
          if (learning.coursesList.length === 0) {
            setIsLastRecord(true)
            setShowNextCourse(false)
          } else {
            setIsLastRecord(false)
            setShowNextCourse(true)
          }
          console.log('LP DATA', learning,courseCode.split("?")[0])
          setSingleLpData(learning)
        }
      } else {
        setSingleLpData({})
      }
      dispatch(getSingleLPClear())
    }
    // return (() => {
    //   setShowNextCourse(false)
    //   setIsLastRecord(true)
    // })
  }, [lpData])

  useEffect(() => {
    if (!_.isEmpty(courseRelatedData)) {
      if (courseRelatedData.status === 'success') {
        if (!_.isEmpty(courseRelatedData.payload.payload)) {
          let courseListing = _.cloneDeep(courseRelatedData.payload.payload);

          if (_.isArray(courseListing)) {
            let formattedList = [];
            for (let course of courseListing) {
              formattedList.push(getFormatCourseListFromWoo(course, isPremiumUser));
            }
            // let formattedList = getFormatCourseListFromWoo(courseListing, isPremiumUser);
            let newFormattedList = [];
            for (let course of formattedList) {
              let pushNextCourse = true;

              // let coursesData = formatWooCourses(data)
              course.isLock = false;
      
              if (course.source === 'broker') {
                course.is_paid = true;
                if (course.durationUnit === "Hours") {
                  course.duration = convertHoursToMins(course.duration);
                }
                course.duration = convertTimeFormat(_.toNumber(course.duration));
                course.durationUnit = "";
              }
              if (course.crscd === courseCode.split("?")[0]) {
                pushNextCourse = false;
              }
              if (pushNextCourse) {
                newFormattedList.push(course)
              }
            }
            if (!_.isEmpty(newFormattedList)) {
              setShowNextCourse(true)
              setIsLastRecord(false)
            }
            console.log('FORMAT LISTING', newFormattedList)
            setCourseListingData(newFormattedList)
          }
        }
      } else {
        setCourseListingData([])
      }
      dispatch(getCourseRelatedClear())
    }
    // return (() => {
    //   setShowNextCourse(false)
    //   setIsLastRecord(true)
    // })
  }, [courseRelatedData])

  const onClickModule = (m, idx) => {
    // console.log("moduleData", m);


    setModuleId(m.module_id);
    setSelectedModulePos(idx);
    setSelectedModule(m);
    if (selectedModule.module_id === m.module_id) {
      // setReload(false)
      // setTimeout(() => {
      //   setIsLoad(false)
      //   setReload(true)
      // }, 3000)
    } else {
      setIsModuleChange(true)
      
      
      setCourseTitle(m.title);
      
      setIsModuleChange(false)
      setReload(true)
      setIsLoad(true)
    }
    if (!isMobile) {
      clickStreamUser("course_module_click", {
        courseCode: courseCode,
        moduleId: m.module_id,
        moduleName: m.title,
        userId: auth.authBroker?.payload?.user.user_id,
      });
    } else {
      clickStreamUser("course_module_click", {
        courseCode: courseCode,
        moduleId: m.module_id,
        moduleName: m.title,
        username: localStorage.getItem(SessionKeys.USER_NAME),
      });
    }
  };
  const gotoHome = () => {

    if (via_lp === 'true') {
      isMobile
      ? window.history.back()
      : history.goBack();
      // history.push(
      //   `/moduleDetail/${history?.location?.state?.courseCode}?source=${source}&via_lp=true&lp_id=${lp_id}`
      // );
    } else if (via_cat === 'true') {
      isMobile
      ? window.history.back()
      :history.goBack();
      //  history.push(
      //   `/moduleDetail/${history?.location?.state?.courseCode}?source=${source}&via_cat=true&catId=${cat_id}`
      // );
    } else {
      isMobile
        ? window.history.back()
        :history.goBack();
        //  history.push(
        //   `/moduleDetail/${history?.location?.state?.courseCode}?source=${source}`
        // );
    }
  };

  const generateZIP = () => {
    // window.captureMyEvents(
    //   "jbcToolKitCourseLevel",
    //   {
    //     ttl: moduleList.title,
    //   }
    // )
    const links = [];
    setLoader(true);
    moduleData?.modules?.forEach((module) => {
      module.rsp_toolkit.forEach((m) => {
        links.push(m.url);
      });
    });
    var zip = new JSZip();
    var count = 0;
    var zipFilename = `${moduleData.courseName}.zip`;
    // var zipFilename = "a.zip"

    links.forEach(function (url, i) {
      var filename = links[i];
      filename = filename
        .replace(/[\/\*\|\:\<\>\?\"\\]/gi, "")
        .replace(CONFIG.API_URL, "");
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          setLoader(false);
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count == links.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
            setLoader(false);
          });
        }
      });
    });
  };

  const nextModule = () => {
    for (let [index, module] of moduleData.modules.entries()) {
      if (module.module_id === selectedModule?.module_id) {
        onClickModule(moduleData.modules[index + 1], index + 1);
      }
    }
    window.scrollTo(0, 0);
  };

  const openHamburgerMenu = (data) => {
    dispatch(openHamburger(true));
    if (data.sectionId) {
      const positionTop = document.getElementById("courseTitle").offsetTop;
      // console.log(positionTop);
      // window.scrollY(positionTop);
    }
  };

  const fetchDataFromIframe = (data) => {
    // console.log(data);
    setActiveTab(4);
    setInsertNoteRecord(data);
    openHamburgerMenu(data);
  };

  const openFavourites = (data) => {
    // console.log(data);
    setActiveTab(3);
    setInsertFavRecord(data);
    openHamburgerMenu(data);
  };

  const handlePostMessage = (sid, methodName = "bookmark") => {
    // console.log("sid, methodName", sid, methodName);
    if (iframeRef && iframeRef.current) {
      // console.log("iframe ref present");
      let payload = { method: methodName, section: sid };
      // console.log("payload", payload);
      iframeRef.current.contentWindow.postMessage(JSON.stringify(payload), "*");
    }
  };
  useEffect(() => {
    // dispatch(getModuleNotesUpdate())
  }, [moduleNotesUpdate]);

  useEffect(() => {
    // console.log("update", moduleNotesUpdate);
    if (!_.isEmpty(moduleNotesUpdate)) {
      // console.log("updated", moduleNotesUpdate.payload.status);
      if (moduleNotesUpdate.payload.status == true) {
        // console.log("getNotes 3");
        // TODO: TO CHANGE AFTER MAKING USER ID CHANGES
        // dispatch(getModulenotesGet({
        //   module: 'getNotes',
        //   // token: localStorage.getItem(SessionKeys.API_TOKEN),
        //   postData: { mid: moduleId, uid:user_id, course_code:courseCode }
        // }))
        dispatch(getModuleNotesUpdateClear());
      }
    }
  }, [moduleNotesUpdate]);

  // useEffect(() => {
  //     console.log('update', moduleNotesUpdate)
  //     if (!_.isEmpty(moduleNotesUpdate)) {
  // console.log('updated', moduleNotesUpdate.payload.status)
  // if (moduleNotesUpdate.payload.status === true) {
  //     console.log('getNotes 3')
  // TODO: AFTER CHANGE TO USER ID
  //     dispatch(getModulenotesGet({
  //         module: 'getNotes',
  //         postData: { mid: moduleId, uid:user_id, course_code:courseCode }
  //     }))
  //     dispatch(getModuleNotesUpdateClear())
  // }
  //     }
  // }, [moduleNotesUpdate])

  const sortResources = (arr) => {
    // console.log("ModuleDataa sort resources",arr)
    var array1 = [];
    var array2 = [];
    var glossary = null;
    var coursehelp = null;
    arr?.map((item) => {
      if (item?.title.toLowerCase() === "glossary") {
        glossary = item;
      } else if (item?.title.toLowerCase() === "course help") {
        coursehelp = item;
      } else array1.push(item);
      // if (item.title.toLowerCase() === 'pdf') {
      //     array2 = item.sort(function (a, b) {
      //         return a.localeCompare(b)
      //     })
      //     return array2
      // } else array2.push(item)
    });
    if (glossary != null) {
      array1.push(glossary);
    }
    if (coursehelp != null) {
      array1.push(coursehelp);
    }
    return array1;
  };

  const sortByAlphabetical = (list) => {
    return list
    // if (list.title)


    // return list.sort((a, b) => a.title.localeCompare(b.title));
  };

  // console.log("Index===>", selectedModulePos + 1)
  // console.log("module length", moduleData?.modules?.length)
  // console.log("module progress", parseInt(selectedModule?.progress))
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
  }, [])

  const getSingleLPData = () => {
    dispatch(getSingleLP({
      module: 'singleLPInfo',
      postData: {
        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        lp_id: lp_id
      }
    }))
  }

  const getCoursesListingData = () => {
    dispatch(getCourseRelated({
      module: 'courseRelated',
      postData: {
        token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        brokerage_id: localStorage.getItem(SessionKeys.BROKER_ID),
        category_id: cat_id
      }
    }))
  }

  return (
    <>
      <Loader start={isLoading} msg={loaderMsg} />
      <div className={scroll ? "main scrollnavmob" : "main"} style={{ marginLeft: "400px" }}>
        <div id="moduleTitle" className="video_img" aria-hidden="true">
          <div className="container">
            <div id="mySidenav" className="sidenav" style={{ marginTop: "17px" }}>
              <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                style={{ marginBottom: "18px" }}
              >
                <li className="nav-item intro_navitem" role="presentation">
                  <button
                    className={"nav-link " + (activeTab === 1 ? "active" : "")}
                    id="modules-tab"
                    onClick={() => {
                      setActiveTab(1);
                    }}
                  >
                    Modules
                  </button>
                </li>
                <li className="nav-item intro_navitem" role="presentation">
                  <button
                    className={"nav-link  " + (activeTab === 2 ? "active" : "")}
                    id="modules-tab"
                    onClick={() => {
                      setActiveTab(2);
                    }}
                  >
                    Resources
                  </button>
                </li>
                <li className="nav-item intro_navitem" role="presentation">
                  {source === "xsel" || source === "woo" || source === "ecom" ? (
                    <button
                      className={"nav-link " + (activeTab === 3 ? "active" : "")}
                      id="modules-tab"
                      onClick={() => {
                        setActiveTab(3);
                      }}
                    >
                      My Favorites
                    </button>
                  ) : (
                    ""
                  )}
                </li>
                <li className="nav-item intro_navitem" role="presentation">
                  {source === "xsel" || source === "woo" || source === "ecom" ? (
                    <button
                      className={"nav-link " + (activeTab === 4 ? "active" : "")}
                      id="modules-tab"
                      onClick={() => {
                        setActiveTab(4);
                      }}
                    >
                      Notes
                    </button>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {activeTab === 1 ? (
                  <div
                    className={" "}
                    id="modules"
                    role="tabpanel"
                    aria-labelledby="modules-tab"
                  >
                    {/* <h4 onClick={gotoHome}>Home</h4> */}
                    <div className="modules-menu">
                      <div
                        className="modules-menu-item d-flex "

                      >
                        <div className="left-arrow cursor-pointer button-reset "
                          onClick={() => gotoHome()}>
                          <button className="button-reset">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="45"
                              height="45"
                              viewBox="0 0 30 30"
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradient"
                                  x2="1"
                                  y1="0.5"
                                  y2="0.5"
                                  gradientUnits="objectBoundingBox"
                                >
                                  <stop offset="0" stopColor="#00b7f1"></stop>
                                  <stop offset="1" stopColor="#ed156e"></stop>
                                </linearGradient>
                              </defs>
                              <circle
                                cx="15"
                                cy="15"
                                r="15"
                                fill="url(#linear-gradient)"
                                data-name="Ellipse 57"
                              ></circle>
                              <circle
                                cx="13"
                                cy="13"
                                r="13"
                                fill="#fff"
                                data-name="Ellipse 179"
                                transform="translate(2 2)"
                              ></circle>
                              <g
                                fill="none"
                                stroke="var(--color-element-1)"
                                strokeLinecap="round"
                                strokeWidth="3"
                                data-name="Group 2517"
                                transform="rotate(-90 42.776 229.302)"
                              >
                                <g
                                  data-name="Group 2452"
                                  transform="translate(251 196.001)"
                                >
                                  <g
                                    data-name="Group 2450"
                                    transform="rotate(135 3.039 1.259)"
                                  >
                                    <path
                                      d="M8.594 0L0 0"
                                      data-name="Line 51"
                                    ></path>
                                    <path
                                      d="M8.594 0L0 0"
                                      data-name="Line 275"
                                    ></path>
                                  </g>
                                  <g
                                    data-name="Group 2451"
                                    transform="rotate(45 3.039 7.337)"
                                  >
                                    <path
                                      d="M8.594 0L0 0.001"
                                      data-name="Line 51"
                                    ></path>
                                    <path
                                      d="M8.594 0L0 0.001"
                                      data-name="Line 275"
                                    ></path>
                                  </g>
                                </g>
                                <g
                                  data-name="Group 2516"
                                  transform="rotate(-90 232 -24.948)"
                                >
                                  <path
                                    d="M9.483 0L0 0"
                                    data-name="Line 51"
                                  ></path>
                                  <path
                                    d="M9.483 0L0 0"
                                    data-name="Line 275"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </button>
                          {/* <img src="/images/left_arrow.png" /> */}
                        </div>
                        <span
                          className="title sidenav_home"
                          style={{
                            cursor: "pointer",
                            color: "black",
                            padding: "0px 31px",
                          }}
                          onClick={() => gotoHome()}
                        >
                          Home
                        </span>
                      </div>
                      {moduleData &&
                        moduleData?.modules &&
                        moduleData?.modules?.map((module, index) => {
                          return (
                            <div
                              className={`tab-content cursor pointer ${module.isLock
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                                }`}
                              id="myTabContent"
                              onClick={() => {
                                if (!module.isLock) {
                                  onClickModule(module, index);
                                }
                              }}
                              key={index}
                            >
                              {/* <div className="tab-content cursor-pointer" id="myTabContent"
                                                    onClick={() => { onClickModule(module, index) }}> */}
                              <div
                                className="modules-menu-item d-flex moduleIcon cursor pointer"
                                style={{ padding: "8px" }}
                              >
                                <div className="box">
                                  <div
                                    style={{ width: 45, height: 45 }}
                                    className="chart-01"
                                  >
                                    <CircularProgressbar
                                      value={module.progress}
                                      styles={buildStyles({
                                        pathColor:
                                          parseInt(module.progress) < 100
                                            ? `#FF9700`
                                            : "#00B70E",
                                        trailColor: "#EBEBEB",
                                      })}
                                    />
                                  </div>
                                </div>
                                <span
                                  className={`title sidenav_title 
                                    ${module.module_id ===
                                      selectedModule.module_id
                                      ? "active"
                                      : ""
                                    }
                                    `}
                                >
                                  {module.title}
                                  {/* {module.isLock ? <span className="lear_icon"><img src="/images/Locked.svg" alt="lock" /></span> : "cursor pointer"}                                                             */}
                                </span>
                              </div>

                              <span className="title"></span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {activeTab === 2 ? (
                  <div
                    className=" "
                    id="resources"
                    role="tabpanel"
                    aria-labelledby="resources-tab"
                  >
                    <div
                      className="resources-item"
                      style={{ marginLeft: "21px" }}
                    >
                      {/* <h5 style={{ color: "black", padding:"0px 35px"}}>Resources section</h5> */}

                      <div className="modules-menu mb-3">
                        {/* <div className="row course-text">
                                                <span className="col-lg-10 course">Course</span>
                                                <span className="col-lg-2 p-0" >
                                                    <a className="bg" onClick={generateZIP}>
                                                        <img
                                                            className="image_on"
                                                            src="/images/note_icon.png"
                                                            alt="logo"
                                                        />
                                                    </a>
                                                </span>
                                            </div> */}
                        {moduleData &&
                          _.isArray(moduleData?.resources_tab) &&
                          moduleData.resources_tab.length > 0 ? (
                          sortResources(
                            sortByAlphabetical(moduleData?.resources_tab)
                          ).map((module, i) => {
                            return (
                              <ul
                                className="module-list"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="module mt-0" key={i}>
                                  <>
                                    <div className="module-item-container row">
                                      <span className="col-lg-10 col-10">
                                        {/* {i + 1}. &nbsp; */}
                                        {module.title}
                                      </span>
                                      <span className="col-lg-2 col-2 p-2">
                                        <a
                                          href={module.url}
                                          className="download-link"
                                          download
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <span>
                                            {module.type === "pdf" ? (
                                              <a className="bg cursor-pointer">
                                                <button className="button-reset">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    viewBox="0 0 30 30"
                                                  >
                                                    <defs>
                                                      <linearGradient
                                                        id="linear-gradient"
                                                        x2="1"
                                                        y1="0.5"
                                                        y2="0.5"
                                                        gradientUnits="objectBoundingBox"
                                                      >
                                                        <stop
                                                          offset="0"
                                                          stopColor="#00b7f1"
                                                        ></stop>
                                                        <stop
                                                          offset="1"
                                                          stopColor="#ed156e"
                                                        ></stop>
                                                      </linearGradient>
                                                    </defs>
                                                    <circle
                                                      cx="15"
                                                      cy="15"
                                                      r="15"
                                                      fill="url(#linear-gradient)"
                                                      data-name="Ellipse 180"
                                                    ></circle>
                                                    <circle
                                                      cx="13"
                                                      cy="13"
                                                      r="13"
                                                      fill="#fff"
                                                      data-name="Ellipse 181"
                                                      transform="translate(2 2)"
                                                    ></circle>
                                                    <path
                                                      fill="#7a549e"
                                                      d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                                                      data-name="Icon awesome-file-pdf"
                                                      transform="translate(9 7)"
                                                    ></path>
                                                  </svg>
                                                </button>
                                              </a>
                                            ) : module.type === "doc" ? (
                                              <button className="button-reset">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="30"
                                                  height="30"
                                                  viewBox="0 0 30 30"
                                                >
                                                  <defs>
                                                    <linearGradient
                                                      id="linear-gradient"
                                                      x2="1"
                                                      y1="0.5"
                                                      y2="0.5"
                                                      gradientUnits="objectBoundingBox"
                                                    >
                                                      <stop
                                                        offset="0"
                                                        stopColor="#00b7f1"
                                                      ></stop>
                                                      <stop
                                                        offset="1"
                                                        stopColor="#ed156e"
                                                      ></stop>
                                                    </linearGradient>
                                                  </defs>
                                                  <circle
                                                    cx="15"
                                                    cy="15"
                                                    r="15"
                                                    fill="url(#linear-gradient)"
                                                    data-name="Ellipse 180"
                                                  ></circle>
                                                  <circle
                                                    cx="13"
                                                    cy="13"
                                                    r="13"
                                                    fill="#fff"
                                                    data-name="Ellipse 181"
                                                    transform="translate(2 2)"
                                                  ></circle>
                                                  <path
                                                    fill="#7a549e"
                                                    d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                                                    data-name="Icon awesome-file-pdf"
                                                    transform="translate(9 7)"
                                                  ></path>
                                                </svg>
                                              </button>
                                            ) : module.type === "ppt" ? (
                                              <button className="button-reset">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="30"
                                                  height="30"
                                                  viewBox="0 0 30 30"
                                                >
                                                  <defs>
                                                    <linearGradient
                                                      id="linear-gradient"
                                                      x2="1"
                                                      y1="0.5"
                                                      y2="0.5"
                                                      gradientUnits="objectBoundingBox"
                                                    >
                                                      <stop
                                                        offset="0"
                                                        stopColor="#00b7f1"
                                                      ></stop>
                                                      <stop
                                                        offset="1"
                                                        stopColor="#ed156e"
                                                      ></stop>
                                                    </linearGradient>
                                                  </defs>
                                                  <circle
                                                    cx="15"
                                                    cy="15"
                                                    r="15"
                                                    fill="url(#linear-gradient)"
                                                    data-name="Ellipse 180"
                                                  ></circle>
                                                  <circle
                                                    cx="13"
                                                    cy="13"
                                                    r="13"
                                                    fill="#fff"
                                                    data-name="Ellipse 181"
                                                    transform="translate(2 2)"
                                                  ></circle>
                                                  <path
                                                    fill="#7a549e"
                                                    d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                                                    data-name="Icon awesome-file-pdf"
                                                    transform="translate(9 7)"
                                                  ></path>
                                                </svg>
                                              </button>
                                            ) : (
                                              <button className="button-reset">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="30"
                                                  height="30"
                                                  viewBox="0 0 30 30"
                                                >
                                                  <defs>
                                                    <linearGradient
                                                      id="linear-gradient"
                                                      x2="1"
                                                      y1="0.5"
                                                      y2="0.5"
                                                      gradientUnits="objectBoundingBox"
                                                    >
                                                      <stop
                                                        offset="0"
                                                        stopColor="#00b7f1"
                                                      ></stop>
                                                      <stop
                                                        offset="1"
                                                        stopColor="#ed156e"
                                                      ></stop>
                                                    </linearGradient>
                                                  </defs>
                                                  <circle
                                                    cx="15"
                                                    cy="15"
                                                    r="15"
                                                    fill="url(#linear-gradient)"
                                                    data-name="Ellipse 180"
                                                  ></circle>
                                                  <circle
                                                    cx="13"
                                                    cy="13"
                                                    r="13"
                                                    fill="#fff"
                                                    data-name="Ellipse 181"
                                                    transform="translate(2 2)"
                                                  ></circle>
                                                  <path
                                                    fill="#7a549e"
                                                    d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                                                    data-name="Icon awesome-file-pdf"
                                                    transform="translate(9 7)"
                                                  ></path>
                                                </svg>
                                              </button>
                                            )}
                                          </span>
                                        </a>
                                      </span>
                                    </div>
                                  </>
                                </div>
                              </ul>
                            );
                          })
                        ) : (
                          <div className="module-list no-found-msg">
                            There are no Resources in this course.
                          </div>
                        )}
                        {/* <div className="jba-toolkit-module-item d-flex justify-content-between align-items-center mb-2 help-text">
                                                <div className="content">Help</div>
                                                <div className="icon cursor-pointer">
                                                    <button className="button-reset">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="30"
                                                            height="30"
                                                            viewBox="0 0 30 30"
                                                        >
                                                            <defs>
                                                                <linearGradient
                                                                    id="linear-gradient"
                                                                    x2="1"
                                                                    y1="0.5"
                                                                    y2="0.5"
                                                                    gradientUnits="objectBoundingBox"
                                                                >
                                                                    <stop offset="0" stopColor="#00b7f1"></stop>
                                                                    <stop offset="1" stopColor="#ed156e"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                            <circle
                                                                cx="15"
                                                                cy="15"
                                                                r="15"
                                                                fill="url(#linear-gradient)"
                                                                data-name="Ellipse 180"
                                                            ></circle>
                                                            <circle
                                                                cx="13"
                                                                cy="13"
                                                                r="13"
                                                                fill="#fff"
                                                                data-name="Ellipse 181"
                                                                transform="translate(2 2)"
                                                            ></circle>
                                                            <path
                                                                fill="#7a549e"
                                                                d="M7 0l7 10H0z"
                                                                data-name="Polygon 4"
                                                                transform="rotate(90 6.5 14.5)"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                    
                                                </div>
                                            </div> */}
                      </div>
                      {/* <div className="jba-toolkit-module-item d-flex justify-content-between align-items-center mb-2">
                                            <div className="content">Glossary</div>
                                            <div className="icon cursor-pointer">
                                                <button className="button-reset">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                    >
                                                        <defs>
                                                            <linearGradient
                                                                id="linear-gradient"
                                                                x2="1"
                                                                y1="0.5"
                                                                y2="0.5"
                                                                gradientUnits="objectBoundingBox"
                                                            >
                                                                <stop offset="0" stopColor="#00b7f1"></stop>
                                                                <stop offset="1" stopColor="#ed156e"></stop>
                                                            </linearGradient>
                                                        </defs>
                                                        <circle
                                                            cx="15"
                                                            cy="15"
                                                            r="15"
                                                            fill="url(#linear-gradient)"
                                                            data-name="Ellipse 180"
                                                        ></circle>
                                                        <circle
                                                            cx="13"
                                                            cy="13"
                                                            r="13"
                                                            fill="#fff"
                                                            data-name="Ellipse 181"
                                                            transform="translate(2 2)"
                                                        ></circle>
                                                        <path
                                                            fill="#7a549e"
                                                            d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                                                            data-name="Icon awesome-file-pdf"
                                                            transform="translate(9 7)"
                                                        ></path>
                                                    </svg>
                                                </button>                                             
                                            </div>
                                        </div> */}
                      {/* <div className="jba-toolkit-module-item d-flex justify-content-between align-items-center mb-2">
                                            <div className="content">Help</div>
                                            <div className="icon cursor-pointer">
                                                <button className="button-reset">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                    >
                                                        <defs>
                                                            <linearGradient
                                                                id="linear-gradient"
                                                                x2="1"
                                                                y1="0.5"
                                                                y2="0.5"
                                                                gradientUnits="objectBoundingBox"
                                                            >
                                                                <stop offset="0" stopColor="#00b7f1"></stop>
                                                                <stop offset="1" stopColor="#ed156e"></stop>
                                                            </linearGradient>
                                                        </defs>
                                                        <circle
                                                            cx="15"
                                                            cy="15"
                                                            r="15"
                                                            fill="url(#linear-gradient)"
                                                            data-name="Ellipse 180"
                                                        ></circle>
                                                        <circle
                                                            cx="13"
                                                            cy="13"
                                                            r="13"
                                                            fill="#fff"
                                                            data-name="Ellipse 181"
                                                            transform="translate(2 2)"
                                                        ></circle>
                                                        <path
                                                            fill="#7a549e"
                                                            d="M7 0l7 10H0z"
                                                            data-name="Polygon 4"
                                                            transform="rotate(90 6.5 14.5)"
                                                        ></path>
                                                    </svg>
                                                </button>
                                               

                                            </div>
                                        </div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={
                    activeTab === 3 ? "note-display-show" : "note-display-hide"
                  }
                  id="my-favourites"
                  role="tabpanel"
                  aria-labelledby="fav-tab"
                >
                  <ModuleFav
                    onPostMessage={handlePostMessage}
                    favInteractive={insertFavRecord}
                    currentModuleData={selectedModule}
                    moduleId={moduleId}
                    courseCode={courseCode}
                  />
                </div>

                <div
                  className={
                    activeTab === 4 ? "note-display-show" : "note-display-hide"
                  }
                  id="notes"
                  role="tabpanel"
                  aria-labelledby="notes-tab"
                >
                  {/* <h5 style={{ color: "black", padding: "0px 35px" }}>My Notes Section</h5> */}
                  <ModuleNotes
                    onPostMessage={handlePostMessage}
                    moduleId={moduleId}
                    interactive={insertNoteRecord}
                    notesList={notesList}
                    currentModuleData={selectedModule}
                    courseCode={courseCode}
                  />
                </div>
              </div>
            </div>

            {/* <div className="open"><button className="openbtn" id="openbtn" onClick={() => { toggleNav() }}>☰</button></div> */}
            {/* <a href={`/moduleDetail/${history?.location?.state?.courseId}`} className="card-link"
                        onClick={() => { <CourseListBody /> }}>

                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i></a> */}
            <h3 className="text-white" id="courseTitle">
              {courseTitle}
            </h3>
            {/* <section className=" video_img">
                        <div className="container">
                            <div className="text-white ">
                                <h1>{courseTitle}</h1>

                            </div>
                        </div>
                    </section> */}
          </div>
          {/* <div>
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    <i className="fa fa-sticky-note" aria-hidden="true"></i>
                    <i className="fa fa-heart" aria-hidden="true"></i>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </div> */}
        </div>
        <ProgressBar
          variant={selectedModule?.progress < 100 ? "warning" : "#00B70E"}
          className="progress-launch stick-top"
          now={selectedModule?.progress}
        />
        {
          (!showNextCourse || isLastRecord) ?
          <div className="container mt-4" aria-hidden="false">
          <div>
            <ModuleComp
              refer={iframeRef}
              mId={moduleId}
              courseId={history?.location?.state?.courseId}
              fetchDataFromIframe={fetchDataFromIframe}
              openFavourites={openFavourites}
              isMobile={isMobile}
              source={source}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              moduleInfo={selectedModule}
              setIsLoad={setIsLoad}
              isLoad={isLoad}
              isModuleChange={isModuleChange}
              reload={reload}
              setIsLoading={setIsLoading}
            />
          </div>

          {source==="broker"&&['pdf'].includes(selectedModule?.rsp_scromdata[0]?.type) ? <div className="text-end mb-3"><button
                    className="btn btn-primary btn-block next-course-btn"
                    style={{'margin-right': '20px'}}
                    disabled={
                      parseInt(selectedModule?.progress) === 100 ? true : false
                    }
                    onClick={() => {
                      setIsLoading(true)
                      dispatch(
                        markModuleProgress({
                          module: "markModuleProgress",
                          postData: {
                            module_id: moduleId,
                            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                            course_id: selectedModule?.crs,
                          },
                        })
                      );
                    }}
                  >
                    Mark as complete {/*<i className="fa fa-angle-right"></i>*/}
                  </button></div> : ""}
          {moduleData &&
            moduleData?.modules &&
            moduleData?.modules[moduleData?.modules.length - 1].module_id !==
            moduleId &&
            selectedModulePos + 1 < moduleData.modules.length ? (
            <div className="container">
              <div className=" row mb-5" aria-hidden="false">
                <hr className="sepbtnse" />
                <div className=" d-flex justify-content-center cong">

                  <div className="text-center border-info">
                    {parseInt(selectedModule?.progress) != 100 ? (
                      "You must complete all activities in this module before continuing."
                    ) :
                      ((!_.isEmpty(selectedModule?.rsp_scromdata)) && ((selectedModule?.rsp_scromdata[0]?.type?.toLowerCase() === "mov") ||
                        (selectedModule?.rsp_scromdata[0].type.toLowerCase() === "mp4") ||
                        (selectedModule?.rsp_scromdata[0].type.toLowerCase() === "pdf") ||
                        (selectedModule?.rsp_scromdata[0].type.toLowerCase() === "docx") ||
                        (selectedModule?.rsp_scromdata[0].type.toLowerCase() === "xlsx") ||
                        (selectedModule?.rsp_scromdata[0].type.toLowerCase() === "pptx"))
                      ) ? "" : (
                        <div className="next-learn-items">
                          {" "}
                          <span>
                            {"Congratulations on completing "}
                            <b>
                              {moduleData.modules[selectedModulePos]?.title + "!"}
                            </b>
                          </span>
                        </div>
                      )}
                    {parseInt(selectedModule?.progress) != 100 ? (
                      ""
                    ) : (
                      <div className="next-learn-item">
                        <span>
                          {"Select Continue to proceed to "}
                          <b>
                            {moduleData.modules[selectedModulePos + 1]?.title}.
                          </b>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4 next-btn-center" id="nextButon">
                  <button
                    className="btn btn-primary btn-block next-course-btn"
                    disabled={
                      parseInt(selectedModule?.progress) < 100 ? true : false
                    }
                    onClick={() => {
                      nextModule();
                    }}
                  >
                    Continue <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {(congModal) ? <CongratulationsPopUp title={moduleData?.courseName}/> : ""} */}
          {(isShowCongrat) ?  //isShowCongrat
            <>
              {false && <CongratulationsPopUp title={moduleData?.courseName}
                lpPoints={moduleData?.learning_points}
                fromPage={isFree ? 0 : 1}
              />}
              <CongPopupAnimation title={moduleData?.courseName}
                lpPoints={moduleData?.learning_points}
                fromPage={isFree ? 0 : 1} />
            </>
            : ""}
          {/* <CongPopupAnimation/> */}
          </div>
          :
          via_lp === 'true'
          ?
          <div className="container mt-4">
            <LPMoreLearn 
              lpData={singleLpData} 
              currentCRSCD={courseCode.split("?")[0]}
              setIsLastRecord={setIsLastRecord}
            />
          </div>
          :
          via_cat === 'true'
          ?
          <div className="container mt-4">
            <div className="related-video-section">
              <div className="relate-title-sec">
                <h4 className="titlebox">
                        There's more to Learn
                </h4>
                <div className="smalltext-videolist">
                    Here are some Related Courses
                </div>
                <div className="row mt-4">
                  <CourseCardListBody
                    key={1}
                    pageType={'course'}
                    coursesList={courseListingData}  //course.courseList
                    setFavoriteCourse={() => {}}
                    closeModal={() => {}}
                    setCloseModal={() => {}}
                    hasDescription={false}
                    filterData={''}
                  />
                </div>
              </div>
            </div>
            
          </div>
          : ""
        }
        {!isMobile}

      </div>
    </>
  );
};
export default CourseLaunchSection;


