import types from "./types";

// Product Detail Fetch
export const getTeamMatesReq = (payload) => ({
  type: types.GET_TEAMMATES_REQ,
  payload,
});
export const getTeamMatesRes = (payload) => ({
  type: types.GET_TEAMMATES_RESPONSE,
  payload,
});
export const getTeamMatesResClear = () => ({
  type: types.GET_TEAMMATES_RESPONSE_CLEAR,
});

export const setCurrentView = (payload) => ({
  type: types.CURRENT_VIEW,
  payload,
});

export const setCongratClassName = (payload) => ({
  type: types.GET_CLASS,
  payload,
});

export const doAssignTeamMates = (payload) => ({
  type: types.ASSIGN_TEAMMATES_REQ,
  payload,
});
export const doAssignTeamMatesRes = (payload) => ({
  type: types.ASSIGN_TEAMMATES_RESPONSE,
  payload,
});
export const doAssignTeamMatesResClear = () => ({
  type: types.ASSIGN_TEAMMATES_RESPONSE_CLEAR,
});

export const getOfficeListReq = (payload) => ({
  type: types.SEARCH_OFFICE_LIST_REQ,
  payload,
});
export const getOfficeListRes = (payload) => ({
  type: types.SEARCH_OFFICE_LIST_RESPONSE,
  payload,
});
export const getOfficeListResClear = () => ({
  type: types.SEARCH_OFFICE_LIST_RESPONSE_CLEAR,
});

export const getCompletedCourseList = (payload) => ({
  type: types.COMPLETED_COURSE_LIST_REQ,
  payload,
});
export const getCompletedCourseListRes = (payload) => ({
  type: types.COMPLETED_COURSE_LIST_RESPONSE,
  payload,
});
export const getCompletedCourseListResClear = () => ({
  type: types.COMPLETED_COURSE_LIST_RESPONSE_CLEAR,
});

// Get office list by user

export const getUsersByOfficeList = (payload) => ({
  type: types.GET_USERS_BY_OFFICE_LIST,
  payload,
});
export const getUsersByOfficeListResponse = (payload) => ({
  type: types.GET_USERS_BY_OFFICE_LIST_RESPONSE,
  payload,
});

export const getUsersByOfficeListClear = (payload) => ({
  type: types.GET_USERS_BY_OFFICE_LIST_CLEAR,
  payload,
});

export const getTeamListReq = (payload) => ({
  type: types.GET_TEAMS_LIST_REQ,
  payload,
});
export const getTeamListResponse = (payload) => ({
  type: types.GET_TEAMS_LIST_RES,
  payload,
});

export const getTeamListResponseClear = (payload) => ({
  type: types.GET_TEAMS_LIST_RES_CLEAR,
  payload,
});

export const assignToTeamsReq = (payload) => ({
  type: types.ASSIGN_TO_TEAMS_REQ,
  payload,
});
export const assignToTeamsResponse = (payload) => ({
  type: types.ASSIGN_TO_TEAMS_RES,
  payload,
});

export const assignToTeamsResponseClear = (payload) => ({
  type: types.ASSIGN_TO_TEAMS_RES_CLEAR,
  payload,
});

export const getAssignUserReq = (payload) => ({
  type: types.GET_ASSIGN_USERS,
  payload,
});
export const getAssignUserResponse = (payload) => ({
  type: types.GET_ASSIGN_USERS_RES,
  payload,
});

export const getAssignUserResponseClear = (payload) => ({
  type: types.GET_ASSIGN_USERS_RES_CLEAR,
  payload,
});
//Filter LP Report
export const getFilterLp = (payload) => ({
  type: types.CHOOSE_LP_REPORT_FILTER,
  payload,
});
export const getFilterLpResponse = (payload) => ({
  type: types.CHOOSE_LP_REPORT_FILTER_RES,
  payload,
});
export const getFilterLpClear = (payload) => ({
  type: types.CHOOSE_LP_REPORT_FILTER_CLEAR,
  payload,
});

