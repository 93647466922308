import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import OnBoardingSectionBroker from "../../components/Home/OnBoardingSectionBroker";
import Loader from "../../components/Loader/Loader";
import Page from "../../components/Page";

const MyOnboardingPage = () => {

    const learningPathBroker = useSelector(state => state.courses.learningPathBroker);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // console.log('learning path broker', learningPathBroker)
        if (!_.isEmpty(learningPathBroker)) {
            setIsLoading(false);
        }
    }, [learningPathBroker])

    return (
        <>
            <Loader start={isLoading} />
            <Page>
                
                <OnBoardingSectionBroker 
                    hideLine={true}
                />
            </Page>
        </>
    );
}

export default MyOnboardingPage;