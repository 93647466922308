import React, { createRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { convertTimeFormat, SessionKeys, timezoneArea } from "../../utils/AppUtils";
import ConfirmCheckListModal from "./CheckListConfirmPopup";
import ShimmerAcceptRole from "../../components/Layouts/ShimmerAcceptRole";
import {
    checkListApprovalStatus,
    checkListApprovalStatusClear,
    getCheckListApproval,
} from "../../redux/user/actions";
import moment from "moment";

const CheckListApprPopUp = (props) => {
    const { checkListLp1, show, 
        handleClose, setIsLoading, 
        setApprovalStatus, approvalStatus, 
        confirmMesg, setConfirmMesg, 
        setConfirmModal2, confirmModal2,
        tabKey, reloadData
    } = props

    const dispatch = useDispatch();

    const checkListApprStatus = useSelector((state) => state.user.checkListApprovalCheck);
    const userDetailRes = useSelector(state => state.user.userBroker)

    const [statusPosition, setStatusPosition] = useState(false)
    const [indexValue, setIndexValue] = useState(null)
    const [positionPro, setPositionPro] = useState(null)
    const [isApprove, setIsApprove] = useState(false);
    const [isSaveClick, setIsSaveClick] = useState(false);
    const [titleStatus, setTitleStatus] = useState(false)
    const [checkListLp, setCheckListLp] = useState([])
    const [checkListObj, setCheckObj] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [timezone, setTimezone] = useState({})

    const [isLoadingPopUp, setIsLoadingPopuUp] = useState(false)
    const ModalClose = (status) => {
        setShowModal(false);
    };

    useEffect(() => {
        // console.log('userDetailRes: ', userDetailRes)
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
            const userDetails = userDetailRes.payload.payload;
            const timezoneOffset = !_.isEmpty(userDetails.timezone) ? userDetails.timezone.replace(':','') : '+0530'
            const area = timezoneArea(timezoneOffset)
            setTimezone(area)
        }
      }, [userDetailRes])

    useEffect(() => {
        if (!_.isEmpty(checkListLp1) && timezone) {
            let listData = checkListLp1;
            if (listData.course_completion_date) {
                let completion_date = moment.utc(listData.course_completion_date, 'YYYY-MM-DD HH:mm:ss').tz(timezone)
                console.log('completion date', completion_date.format('DD MMM YYYY, hh:mm A z'))
                listData.completion_date =  completion_date.format('DD MMM YYYY, hh:mm A z');
            } else {
                listData.completion_date = "";
            }
            setCheckListLp(listData)
        }
    }, [checkListLp1, timezone])
    // console.log("checkListLp", isSave, isApprove, confirmModal)
    // const statusHandler = (index) => {
    //     if (indexValue === index) {
    //         setApprovalStatus(true);
    //     }
    // }
    // const handleChange = (index) => {
    //     if (indexValue === index) {
    //         setStatusPosition(!statusPosition);
    //         setApprovalStatus(!approvalStatus);
    //     }

    // }
    // console.log("statuc value", indexValue, statusPosition)


    // *** Save and Approval API section *** //
    // useEffect(() => {     
    //         ApprovalConfirmation()        
    // }, [])

    useEffect(() => {
        setIsSaveClick(true);
    }, [isSaveClick])

    useEffect(() => {
        setIsApprove(true);
    }, [isApprove])


    // const ApprovalConfirmation = () => {
    //     dispatch(
    //         checkListApprovalStatus({
    //             module: "checklist-approval",
    //             postData: {
    //                 token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    //                 rea_id: checkListLp?.uid,
    //                 title_array: checkListLp?.title_array,
    //                 checklist_id: checkListLp?.checklist_id,
    //                 lp_id: checkListLp?.lp_id,
    //                 course_id: checkListLp?.course_or_level_id,
    //                 is_approve: isApprove,
    //                 is_save: isSaveClick
    //             },
    //         })
    //     );
    //     setShowModal(true);
    //     handleClose()
    // }
    // const ApprovalConfirmation1 = () => {
    //     dispatch(
    //         checkListApprovalStatus({
    //             module: "checklist-approval",
    //             postData: {
    //                 token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    //                 rea_id: checkListLp?.uid,
    //                 title_array: checkListLp?.title_array,
    //                 checklist_id: checkListLp?.checklist_id,
    //                 lp_id: checkListLp?.lp_id,
    //                 course_id: checkListLp?.course_or_level_id,
    //                 is_approve: isApprove,
    //                 is_save:false
    //             },
    //         })
    //     );
    //     setShowModal(true);
    //     handleClose();
    // }

    useEffect(() => {
        dispatch(checkListApprovalStatusClear());
    }, [confirmMesg])

    useEffect(() => {
        setIsLoadingPopuUp(false)
        if (!_.isEmpty(checkListApprStatus)) {
            if (checkListApprStatus?.status == "success") {
                setConfirmMesg(checkListApprStatus.payload.message)
                setConfirmModal2(true)
                window.scrollTo(0, 0);
                reloadData(tabKey)
                // dispatch(
                //     getCheckListApproval({
                //         module: "checklisttApproval-detail",
                //         postData: {
                //             token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
                //         },
                //     })
                // );
            }
            if (checkListApprStatus?.status == "error") {
                setConfirmMesg("Cannot approve,as one of the checklist is not checked.")
            }
            // } else {
            //     setConfirmMesg("Cannot approve,as one of the checklist is not checked.")
            // }
            dispatch(checkListApprovalStatusClear());
        }
    }, [checkListApprStatus, confirmMesg]);
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                dialogClassName="modal-90w modalassign"
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Checklist
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body">
                    <div className="checklistboxdata">
                        <div className="usr-title">
                            {checkListLp?.rea_name}
                        </div>
                        <div className='d-flex justify-content-between gap-2 courspop'>
                            <div className='taxttitle-left'>
                                <strong>Course/Level: </strong>{checkListLp?.course_name}
                            </div>
                            <div className='taxttitle-left'>
                                <strong>Learning Path: </strong>{checkListLp?.lp_name}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between gap-2 datbox mt-2'>
                            <div className='taxttitle-left'>
                                <strong>Completion Date: </strong>{checkListLp?.completion_date}
                            </div>
                        </div>
                        <div className="tablebox">
                            <table className="table mb-0 table-striped office_list dataTable no-footer">
                                <thead>
                                    <tr>
                                        <th className="office-province">
                                            Title
                                        </th>
                                        <th className="office-province text-center">
                                            Status
                                        </th>
                                        <th className="office-province text-center actiontable">
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {checkListLp?.title_array?.map((val, index) => {
                                        return (
                                            <tr className="addBGcustom" key={index}>
                                                <td>
                                                    {val?.title}
                                                    {/* Lorem ipsum dolor sit amet. This is sample text. */}
                                                </td>
                                                <td className="text-center">
                                                    {val.title_status === 1 ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Group_6582" data-name="Group 6582" transform="translate(0.498 0.498)">
                                                                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#0fb41c" />
                                                                <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
                                                            </g>
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Group_6583" data-name="Group 6583" transform="translate(0.498 0.498)">
                                                                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#ed156e" />
                                                                <line id="Line_420" data-name="Line 420" x2="9" transform="translate(4.002 8.502)" fill="none" stroke="#fff" stroke-width="3" />
                                                            </g>
                                                        </svg>

                                                    }
                                                </td>
                                                <td className="text-center" >
                                                    <label className="switch" key={index}>
                                                        <input type="checkbox"
                                                            checked={val.title_status === 1 ? true : false}
                                                            name={""}
                                                            onChange={(e) => {
                                                                console.log('click')
                                                                let tempObj = _.cloneDeep(checkListLp)
                                                                if (val.title_status === 1) {
                                                                    tempObj.title_array[index].title_status = 0
                                                                } else {
                                                                    tempObj.title_array[index].title_status = 1
                                                                }
                                                                setCheckListLp(tempObj);
                                                            }}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" id="save-btn" className="confirm" onClick={() => {
                        // ApprovalConfirmation(true);
                        dispatch(
                            checkListApprovalStatus({
                                module: "checklist-approval",
                                postData: {
                                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                                    rea_id: checkListLp?.uid,
                                    title_array: checkListLp?.title_array,
                                    checklist_id: checkListLp?.checklist_id,
                                    lp_id: checkListLp?.lp_id,
                                    course_id: checkListLp?.course_or_level_id,
                                    is_approve: false,
                                    is_save: true
                                },
                            })
                        );
                        // setShowModal(true);                           
                        handleClose()
                    }}>
                        Save
                    </Button>
                    <Button variant="primary" id="approve" className="confirm" onClick={() => {
                        // ApprovalConfirmation1()
                        dispatch(
                            checkListApprovalStatus({
                                module: "checklist-approval",
                                postData: {
                                    token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                                    rea_id: checkListLp?.uid,
                                    title_array: checkListLp?.title_array,
                                    checklist_id: checkListLp?.checklist_id,
                                    lp_id: checkListLp?.lp_id,
                                    course_id: checkListLp?.course_or_level_id,
                                    is_approve: true,
                                    is_save: false
                                },
                            })
                        );                     
                        
                        handleClose();
                    }}>
                        Approve
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmCheckListModal
                handleClose={ModalClose}
                show={showModal}
                showMesg={confirmMesg}
                setIsLoadingPopuUp={setIsLoadingPopuUp}
            />
        </>
    )
}
export default CheckListApprPopUp;