import { ThreeCircles } from "react-loader-spinner";


const Loader = (props) => {
    return (
        <>
        {props.start ? 
        <div className="page-loader">
            <div className="fixed-loader">
                <ThreeCircles
                height="100"
                width="100"
                color=""
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="var(--color-element-1)"
                innerCircleColor="var(--color-element-2)"
                middleCircleColor="var(--color-element-1)"
                />
                {props.msg ? <p>{props.msg}</p> : ""}
            </div> 
        </div>
        : ''}
        </>
    )
}

export default Loader;