import types from "./types";

// Notification
export const notificationData = (payload) => ({
  type: types.NOTIFICATION,
  payload,
});

export const notificationResponse = (payload) => ({
  type: types.NOTIFICATION_RESPONSE,
  payload,
});
export const notificationClear = () => ({ type: types.NOTIFICATION_CLEAR });

// Notification Single
export const notificationSingleData = (payload) => ({
  type: types.NOTIFICATION_SINGLE,
  payload,
});

export const notificationSingleResponse = (payload) => ({
  type: types.NOTIFICATION_SINGLE_RESPONSE,
  payload,
});
export const notificationSingleClear = () => ({
  type: types.NOTIFICATION_SINGLE_CLEAR,
});

// Notification Single
export const notificationAllReadData = (payload) => ({
  type: types.NOTIFICATION_ALL_READ,
  payload,
});

export const notificationAllReadResponse = (payload) => ({
  type: types.NOTIFICATION_ALL_READ_RESPONSE,
  payload,
});
export const notificationAllReadClear = () => ({
  type: types.NOTIFICATION_ALL_READ_CLEAR,
});

// Notification Count
export const notificationCountData = (payload) => ({
  type: types.NOTIFICATION_COUNT,
  payload,
});

export const notificationCountResponse = (payload) => ({
  type: types.NOTIFICATION_COUNT_RESPONSE,
  payload,
});
export const notificationCountClear = () => ({
  type: types.NOTIFICATION_COUNT_CLEAR,
});
