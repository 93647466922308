import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { calculateRolePlayScore, clickStreamUser, SessionKeys, timezoneArea } from "../../utils/AppUtils";
import RolePlayHeadingSection from "./RolePlayHeadingSection";
import  MyRolePlay from "./MyRolePlay";
import RolePlayParticipateSection from "./RolePlayParticipateSection";
import MyRolePlayOverview from "./MyRolePlayOverview";
import { fetchMyRolePlayList, fetchMyRolePlayListClear, 
    myRolePlayFilterList, myRolePlayFilterListClear, 
    setParticipateFormValueClear, 
    submitParticipateForm, submitParticipateFormClear 
} from "../../redux/roleplay/actions";
import MyRolePlayPick from "./MyRolePlayPick";
import moment from "moment";
import RolePlayTabSection from "./RolePlayTabSection";

const MyRolePlayPageSection = (props) => {

    const dispatch = useDispatch();
    const authBroker = useSelector(state => state.auth.authBroker)
    const myRolePlayList = useSelector(state => state.rolePlay.myRolePlayList)
    const myRolePlayArr = useSelector(state => state.rolePlay.myRolePlayFilterList)
    const userDetailRes = useSelector((state) => state.user.userBroker);
    const participateForm = useSelector(
        (state) => state.rolePlay.participateForm
    );
    const setParticipateFormValue = useSelector(state => state.rolePlay.setParticipateValue)
    const [clickedRolePlay, setClickedRolePlayData] = useState({});
    const [rolePlayListing, setRolePlayListing] = useState([])
    const [reviewType, setReviewType] = useState('all')
    const [savedCount, setSavedCount] = useState(0)
    const [submittedCount, setSubmittedCount] = useState(0)
    const [reviewedCount, setReviewedCount] = useState(0)
    const [isShowReview, setIsShowReview] = useState(false)
    const [userDetails, setUserDetails] = useState({});
    const [confirmModal, setConfirmModal] = useState(false)
    const [showModalMsg, setShowModalMsg] = useState('')

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            clickStreamUser('review-role-play-page', {
                user_id: authBroker.payload.user.user_id
            })
            filterList();
        }
    }, [authBroker, reviewType])

    useEffect(() => {
        if (confirmModal) {
            setTimeout(() => {
                setConfirmModal(false)
                setShowModalMsg('')
            }, 5000)
        }
    }, [confirmModal])

    useEffect(() => {
        // console.log("userDetailRes: ", userDetailRes);
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
          setUserDetails(userDetailRes?.payload?.payload);
        }
    }, [userDetailRes]);

    useEffect(() => {
        // console.log('myrole play list', myRolePlayArr)
        if (!_.isEmpty(myRolePlayArr) && !_.isEmpty(userDetailRes)) {
            if (myRolePlayArr.status === 'success') {
                const payload = myRolePlayArr.payload?.payload
                let rolePlayList = payload.list
                setSavedCount(payload.stats.pending_submit)
                setSubmittedCount(payload.stats.pending_review)
                setReviewedCount(payload.stats.reviewed)
                if (!_.isEmpty(rolePlayList)) {
                    const timezone = userDetailRes.payload.payload.timezone
                    const offset = timezone.includes(":") ? 
                    timezone.replace(":", "") : "-0500";
                    const area = timezoneArea(offset) 
                    rolePlayList = _.reverse(_.sortBy(_.cloneDeep(rolePlayList), 'created_dt'))
                    setRolePlayListing(rolePlayList.map(role => {
                        let totalPoint = 0;
                        if (!_.isEmpty(role.feedback_data)) {
                            let data = role.feedback_data
                            // console.log(role)
                            totalPoint = !_.isEmpty(data.rating) ? calculateRolePlayScore(data.rating) : 0
                        }
                        return {
                            role_play_id: role.roleplay_id,
                            title: role.roleplay_title,
                            situation: role.situation,
                            description: role.description,
                            releaseDate: role.release_date,
                            duration: role.duration,
                            durationUnit: role.durationUnit,
                            userFirstName: role.user_first_name,
                            userLastName: role.user_last_name,
                            type: role.uploaded_type,
                            originalType: role.type,
                            url: role.roleplay_video_or_doc_path,
                            originalURL: role.url,
                            thumbnail: role.thumbnail,
                            uploaded_file: role.roleplay_video_or_doc_path,
                            submissionDate: moment.utc(role.submission_dt, "YYYY-MM-DD HH:mm:ss").tz(area).format("DD MMM, YYYY hh:mm A z"), //role.submission_dt,
                            createdDate: role.created_dt,
                            brokerageId: role.brokerage_id,
                            userId: role.user_id,
                            id: role.reference_video_id,
                            uploadedType: role.uploaded_type,
                            submitToCoach: _.toNumber(role.submit_to_coach),
                            coachName: role.coach_name,
                            coachAvatar: role.coach_avatar,
                            additionalComment: role.additional_comment,
                            roleplay_category: role.roleplay_category,
                            roleplay_url: role.roleplay_url,
                            roleplay_wpm: role.roleplay_wpm,
                            roleplay_duration: role.roleplay_duration,
                            roleplay_sentiment_positive: role.roleplay_sentiment_positive,
                            roleplay_sentiment_negative: role.roleplay_sentiment_negative,
                            roleplay_sentiment_neutral: role.roleplay_sentiment_neutral,
                            roleplay_keywords: role.roleplay_keywords ? JSON.parse(role.roleplay_keywords) : [],
                            roleplay_keyword_analysis: role.roleplay_keyword_analysis ? JSON.parse(role.roleplay_keyword_analysis) : [],
                            roleplay_learningpoint: role.roleplay_learningpoint,
                            roleplay_score: totalPoint,
                            status: role.status,
                            reviewedDate: !_.isEmpty(role.feedback_data) && role.feedback_data.created_at ? moment.utc(role.feedback_data.created_at, "YYYY-MM-DD HH:mm:ss").tz(area).format("DD MMM, YYYY hh:mm A z") : "",
                            feedbackData: role.feedback_data
                        }
                    }))
                } else {
                    setRolePlayListing([])
                }
            }
            props.setIsLoading(false)
            dispatch(myRolePlayFilterListClear())
        }
    }, [myRolePlayArr, userDetailRes])

    useEffect(() => {
        // console.log("PARTICIPATE FORM", participateForm);
        if (!_.isEmpty(participateForm)) {
            props.setIsLoading(false)
            if (participateForm.status === "success") {
                // console.log('MSG TO SET FOR SUBMISSION')
                window.scrollTo(0, 0)
                setConfirmModal(true);
                setClickedRolePlayData({});
                filterList();
                setIsShowReview(false)
                setClickedRolePlayData({})
                if (!_.isEmpty(setParticipateFormValue)) {
                    if (setParticipateFormValue.isCoachSubmit) {
                        setShowModalMsg("Thank you for your participation!");    
                    } else if (setParticipateFormValue.isEditable) {
                        setShowModalMsg("Successfully updated.");
                    } else if (setParticipateFormValue.isSaved) {
                        setShowModalMsg("Successfully saved.");
                    }
                    dispatch(setParticipateFormValueClear())
                } else {
                    setShowModalMsg("Thank you for your participation!");
                }
            } else {
                window.scrollTo(0, 0)
                setConfirmModal(true);
                setShowModalMsg("Error! Try again later.");
            }
            
            dispatch(submitParticipateFormClear());
        }
      }, [participateForm, setParticipateFormValue]);

    const filterList = () => {
        setRolePlayListing([])
        dispatch(myRolePlayFilterList({
            module: 'myRolePlayFilterList',
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                type: reviewType
            }
        }))
    }

    const showForReview = (id) => {
        console.log('Show Review', id)
        if (!_.isEmpty(id)) {
            for (let record of rolePlayListing) {
                if (record.role_play_id === id) {
                    setClickedRolePlayData(record)
                    setIsShowReview(true)
                    props.setIsLoading(true)
                }
            }
        } else {
            setIsShowReview(false)
            setClickedRolePlayData({})
        }
    }

    const renderAlertCard = () => {
        return (
            <div className={`alert-card d-flex justify-content-center ${confirmModal ? '' : 'd-none'}`}>
                <div className="shop-cart">
                    <div className="shop-info justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                                <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                        <p className="m-0">{showModalMsg}</p>
                    </div>
                    <div className="view-cart-icon">
                        <i className="fa fa-times cursor-pointer" aria-hidden="true"
                            onClick={() => {
                                setConfirmModal(false)
                                setShowModalMsg('')
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        )
    }

    const setFormData = (id, type) => {
        props.setIsLoading(true)
        // console.log(
        //   "Check uploaded",
        //   roleInfo
        // );
        let roleInfo = {};
        for (let record of rolePlayListing) {
            if (record.role_play_id === id) {
                roleInfo = record;
                props.setIsLoading(true)
            }
        }
        if (!_.isEmpty(roleInfo)) {

            let formData = new FormData();
            formData.append("submit_to_coach", 1);
            formData.append("coach_id", userDetails.coach_id);
            // setIsSubmitLoading(true);
            if (type === 'update') {
                formData.append("action", "update");
                formData.append("roleplay_id", roleInfo.role_play_id);
            } else {
                formData.append("action", "insert");
            }
            formData.append("uid", authBroker.payload.user.user_id);
            formData.append("brokerage_id", authBroker.payload.user.company_id);
            // formData.append('roleplay_metadata', roleInfo.title)
            formData.append("reference_video_id", roleInfo.id);
            formData.append("roleplay_title", roleInfo.title);
            formData.append("situation", roleInfo.situation);
            formData.append("description", roleInfo.description);
            formData.append("duration", roleInfo.duration);
            formData.append("durationUnit", roleInfo.durationUnit);
            formData.append("thumbnail", roleInfo.thumbnail);
            formData.append("provider_id", roleInfo.provider_id);
            formData.append("type", roleInfo.type);
            formData.append("url", roleInfo.originalURL);
            formData.append(
              "release_date",
              moment(roleInfo.releaseDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            );
            formData.append("roleplay_sentiment_negative", roleInfo.roleplay_sentiment_negative);
            formData.append("roleplay_sentiment_neutral", roleInfo.roleplay_sentiment_neutral);
            formData.append("roleplay_sentiment_positive", roleInfo.roleplay_sentiment_positive);
            formData.append("roleplay_duration", roleInfo.roleplay_duration);
            formData.append("roleplay_wpm", roleInfo.roleplay_wpm);
            formData.append("roleplay_learningpoint", roleInfo.roleplay_learningpoint);
            formData.append(
              "roleplay_keywords",
              JSON.stringify(roleInfo.roleplay_keywords)
            );
            formData.append(
              "roleplay_keywords",
              JSON.stringify(roleInfo.roleplay_keyword_analysis)
            );
        
            
            formData.append("roleplay_url", roleInfo.roleplay_url);
            formData.append("uploaded_type", roleInfo.originalType);
        
            formData.append("additional_comment", roleInfo.additionalComment);
        
            formData.append(
              "token",
              localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
            );
            // console.log(
            //   "FORMDATA",
            //   formData
            // );
        
            dispatch(
              submitParticipateForm({
                module: "participateForm",
                postData: formData,
              })
            );
        } else {
            window.scrollTo(0, 0)
            setConfirmModal(true);
            setShowModalMsg("Error submitting data, please try again later.");
        }
    };

    return (
        <div className="position-relative roleplaytables">
            {renderAlertCard()}
            {/* Show Header Tab Section */}
            <RolePlayHeadingSection 
                page='roleplay'
                isMobile={props.isMobile}
            />
            <RolePlayTabSection 
                page='roleplay'
                isMobile={props.isMobile}
            />
            {/* <RolePlayParticipateSection 
                page='roleplay'
                title={'My Role plays'}
                listing={rolePlayListing}
                sendData={setClickedRolePlayData}
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
                roleData={clickedRolePlay}
            /> */}
            {/* Show RolePlay master list section */}
            {(rolePlayListing && !isShowReview) ? 
            <MyRolePlayPick 
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
                roleData={clickedRolePlay}
                reviewType={reviewType}
                setReviewType={setReviewType}
                listing={rolePlayListing}
                savedCount={savedCount}
                submittedCount={submittedCount}
                reviewedCount={reviewedCount}
                showForReview={showForReview}
                setFormData={setFormData}
            /> 
            : (!props.isLoading && !isShowReview) ? 'No Data' : ''}
            {/* Show Feedback Received Section */}
            {isShowReview ? 
            <MyRolePlayOverview 
                page="roleplay"
                roleData={clickedRolePlay}
                listing={rolePlayListing}
                setIsLoading={props.setIsLoading}
                isLoading={props.isLoading}
                setClickedRolePlayData={setClickedRolePlayData}
                filterList={filterList}
                showForReview={showForReview}
                setFormData={setFormData}
            /> : ''}
            {/* <MyRolePlay /> */}
        </div>
    )
}

export default MyRolePlayPageSection;