import React,{ useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import CONFIG from "../../utils/config";
import { SessionKeys } from "../../utils/AppUtils";
import VideoPlayer from "react-video-js-player";
// import ReactPDF from from 'react-pdf';
import _ from "lodash";
// import $ from "jquery";
// import FileViewer from "react-file-viewer";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { videoEvents } from "../../utils/VideoClickStream";
import { markModuleProgress } from "../../redux/courses/actions";
// import {learningPathV2} from './learningPathV2.json';
import ReactPlayer from 'react-player';

export const ActivityCompRender = (props) => {

    const {
        mId,
        courseId,
        fetchDataFromIframe,
        openFavourites,
        refer,
        moduleInfo,
        setIsLoad,
        isLoad,
        isModuleChange,
        reload,
        setIsLoading,
        source,
        moduleID
    } = props;
    const [iframeHeight, setIframeHeight] = useState(600)
    const [iframeMinHeight, setIframeMinHeight] = useState(600);
    const dispatch = useDispatch()
    const moduleListingData = useSelector(state => {
        return state.courses.moduleListing
    });
    const authBroker = useSelector(state => state.auth.authBroker)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [moduleDataInfo, setModuleDataInfo] = useState({})

  useEffect(() => {
    if (!_.isEmpty(moduleInfo)) {
        const moduleobj = moduleInfo;
        var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|playlist\?list=|channel\/|user\/)|youtu\.be\/)([\w\-]+)(?:\S+)?$/;
        const url = moduleobj.fileurl;
        if (!url) {
          setModuleDataInfo(moduleobj);
         }
        else{
          if (regExp.test(url)) {
            const prop1 = "video_type";
            moduleobj[prop1] = "video";
            setModuleDataInfo(moduleobj);
        }
        else if(url.includes("zoom.us")){
            const prop1 = "video_type";
            moduleobj[prop1] = "recordingVideo";
            setModuleDataInfo(moduleobj)
        }
        else if(url.indexOf('.m3u8') != -1){
            const prop1 = "video_type";
            moduleobj[prop1] = "m3u8";
            setModuleDataInfo(moduleobj)
        }
        else{
        setModuleDataInfo(moduleobj);
        }
          }
    }
}, [moduleInfo]);


    useEffect(() => {
        if (isLoad) {
            setIframeHeight(600)
        }
    }, [isLoad])


    useEffect(() => {
        if (!_.isEmpty(moduleListingData)) {
            setIframeHeight(600)
        }
    }, [moduleListingData])

    useEffect(() => {
        if (iframeHeight < iframeMinHeight) {
            setIframeMinHeight(iframeHeight)
        }
    }, [iframeHeight])

    useEffect(() => {
    },[])


    const setLoadFalse = () => {
        setTimeout(() => {
            setIsLoad(false)
        }, 500)
    }

    const markProgress = () => {
        return;
    }
    const viewQuiz = (moduleDataInfo) =>{
       window.open(moduleDataInfo.fileurl, '_blank')
       if(moduleDataInfo?.progress<=100){
        setIsLoading(true)
        dispatch(
            markModuleProgress({
                module: "markModuleProgress",
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                postData: {
                    module_id: moduleID,
                    course_id: moduleInfo.crs,
                    activity_id: mId
                },
            })
        );
       }
    }
    const onPlayerEnd = () => {
    setIsLoading(true)
    dispatch(
        markModuleProgress({
            module: "markModuleProgress",
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            postData: {
                module_id: moduleID,
                course_id: moduleInfo.crs,
                activity_id: mId
            },
        })
    );
    }
    const renderVideoPlayer = (moduleDataInfo) => {
        if (moduleDataInfo.video_type === "recordingVideo") {
          return (
            <div className="mt-3">
              <iframe
                className="w-100"
                onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); }}
                src={moduleDataInfo.fileurl}
                title={moduleDataInfo.title}
                height="100%"
                width="100%"
                allowFullScreen={true}
              />
              <div className="d-flex justify-content-end mt-4 next-btn-center">
                <button
                  className="btn btn-primary btn-block next-course-btn"
                  onClick={onPlayerEnd}
                  disabled={moduleDataInfo?.progress >= 100}
                >
                  Mark As Complete
                </button>
              </div>
            </div>
          );
        } else if (moduleDataInfo.video_type === "video") {
          return (
            <div className="mt-3">
              <div className="w-100">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url={moduleDataInfo.fileurl}
                  controls
                  onEnded={(state) => onPlayerEnd(state)}
                />
              </div>
            </div>
          );
        } else if (moduleDataInfo.video_type === "m3u8") {
          return (
            <div className="container-fluid">
            <div className="row" >
                <div className="col-md-12" >
                    <div className="video-fullbox">
                        <div className="search__input">
                            <div className="img-box_RP">
                                <div className="video-full mt-3">
                                    <VideoPlayer
                                        className="img-videosec learn_image-card"
                                        controls={true}
                                        // src="https://cdn-stg.niit-mts.com/xsel/media/sample-20s.m3u8"
                                        src={moduleDataInfo.fileurl}
                                        width="720"
                                        height="420"
                                        autoPictureInPicture={true}
                                        // poster={module.}
                                        // onLoad={setLoadFalse}
                                        fluid={true}
                                        inline={false}
                                        pictureInPictureToggle={true}
                                        type={"video/mp4"}
                                        onEnd={onPlayerEnd}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end mt-4 next-btn-center">
            <button
              className="btn btn-primary btn-block next-course-btn"
              onClick={onPlayerEnd}
              disabled={moduleDataInfo?.progress >= 100}
            >
              Mark As Complete
            </button>
          </div>
        </div>
          );
        }
      };
      
      const renderUrl = (moduleDataInfo, iframeHeight, iframeMinHeight, refer, mId) => (
        <>
          <iframe
            key={mId}
            type="text/html"
            className="w-100"
            id="scorm_player"
            height={iframeHeight}
            style={{ minHeight: iframeMinHeight, width: "70%", margin: "0 auto" }}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); }}
            ref={refer}
            src={moduleDataInfo.fileurl}
            allowFullScreen={true}
          />
          <div className="d-flex justify-content-end mt-4 next-btn-center">
            <button
              className="btn btn-primary btn-block next-course-btn"
              onClick={onPlayerEnd}
              disabled={moduleDataInfo?.progress >= 100}
            >
              Mark As Complete
            </button>
          </div>
        </>
      );
      
      const renderPdf = (moduleDataInfo) => (
        <div className="mt-3">
          <div className="pdf-view-page">
          <iframe
              className="w-100"
              onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
              src={CONFIG.API_URL_ADMIN + "pdfview/?id=" + mId + "&access=new"+"&u_token=" + localStorage.getItem(SessionKeys.API_TOKEN_BROKER)} title={moduleDataInfo.title} height="100%" width="100%" />
          </div>
          <div className="d-flex justify-content-end mt-4 next-btn-center">
            <button
              className="btn btn-primary btn-block next-course-btn"
              onClick={onPlayerEnd}
              disabled={moduleDataInfo?.progress >= 100}
            >
              Mark As Complete
            </button>
          </div>
        </div>
      );
      
      const renderScorm = (moduleDataInfo, iframeHeight, iframeMinHeight, refer, mId, moduleInfo, moduleID, authBroker) => (
        <div className="mt-3">
          <iframe
            key={mId}
            type="text/html"
            className="w-100"
            id="scorm_player"
            height={iframeHeight}
            style={{ minHeight: iframeMinHeight, width: "70%", margin: "0 auto" }}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); }}
            ref={refer}
            src={`${CONFIG.SCORM_URL}?course_id=${moduleInfo.crs}&module_id=${moduleID}&activity_id=${mId}&user_id=${authBroker?.payload?.user?.user_id}&token=${localStorage.getItem(SessionKeys.API_TOKEN_BROKER)}`}
            allowFullScreen={true}
          />
          <div className="d-flex justify-content-end mt-4 next-btn-center">
            <button
              className="btn btn-primary btn-block next-course-btn"
              onClick={onPlayerEnd}
              disabled={moduleDataInfo?.progress >= 100}
            >
              Mark As Complete
            </button>
          </div>
        </div>
      );

      const renderQuiz = (moduleDataInfo) =>{
        return(
          <>
        <div className="centered-span">
          <span>This content contains an external link. Please click the button below to view the content.</span>
          <div className="d-flex justify-content-center mt-4 next-btn-center">
          <button
            className="btn btn-primary btn-block next-course-btn"
          onClick={() => viewQuiz(moduleDataInfo)}
          style={{width:'11%'}}
          >
            View
          </button>
      </div>
        </div>
        
      </>
        )
      }

      const renderDefault = () =>{
        return (
          <div className="centered-span">
          <span>
          It seems that either the video is not ready or the learning activity has not found correctly!
          </span>
          </div>
        )
      }

    const renderContent = () => {
        if (_.isEmpty(moduleDataInfo) || !reload) return null;
      
        if (source === 'xsel' || source === 'ecom') {
          return (
            <iframe
              key={mId}
              type="text/html"
              className="w-1000"
              id="scorm_player"
              height={iframeHeight}
              style={{ minHeight: iframeMinHeight, width: "70%", margin: "0 auto" }}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); }}
              src={`${CONFIG.SCORM_URL}?course_id=${courseId}&module_id=${mId}&user_id=${authBroker?.payload?.user?.user_id}&token=${localStorage.getItem(SessionKeys.API_TOKEN_BROKER)}`}
              allowFullScreen={true}
            />
          );
        }
      
        const { file_type } = moduleDataInfo;
        if(!moduleDataInfo.fileurl){
          return renderDefault();
        }else{
          switch (file_type?.toLowerCase()) {
            case "video":
              return renderVideoPlayer(moduleDataInfo);
            case "url":
              return renderUrl(moduleDataInfo, iframeHeight, iframeMinHeight, refer, mId);
            case "pdf":
              return renderPdf(moduleDataInfo);
            case "scorm":
              return renderScorm(moduleDataInfo, iframeHeight, iframeMinHeight, refer, mId, moduleInfo, moduleID, authBroker);
            case "quiz" :
              return renderQuiz(moduleDataInfo);
            default:
              return (
                <div className="mt-3">
                  <iframe
                    className="w-100"
                    onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); }}
                    src={moduleDataInfo.fileurl}
                    title={moduleDataInfo.title}
                    height="100%"
                    width="100%"
                    allowFullScreen={true}
                  />
                </div>
              );
          }
        }
      };
    
    return (
        <div className="row mb-2" aria-hidden="false">
            {renderContent()}
        </div>
    )
}