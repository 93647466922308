import { useEffect, useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getDateTime, SessionKeys } from "../../utils/AppUtils";
import PaginationMyOrders from "../Orders/PaginationMyOrders";
import CONFIG from "../../utils/config";
import ConfirmModal from '../modal/ConfirmModal';
import { toolboxUpdateTimeRequest } from "../../redux/orders/actions";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router-dom";
let PageSize = 8;
const DocumentList = (props) => {
    const { document_list, globalDocumentList, deleteFileFun, confirmModal, setConfirmModal, noDataMsg,getFolderDetails,updateTime } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const [showDetailsInfo, setShowDetailsInfo] = useState([])
    const [showpopup, setShowDetails] = useState(false);
    const handleCloseDetails = () => setShowDetails(false);
    const [showUpload, setShowDetailsUpload] = useState(false)
    const [userDetails, setUserDetails] = useState({});
    const [isLoading1,setIsLoading1] = useState(false)

    const [viewPdf, setViewPdf] = useState(false)
    const handleCloseUpload = () => setShowDetailsUpload(false);
    const handleCloseViewPdf = () => setViewPdf(false);
    const [showDetailsInfoAlert, setShowDetailsInfoAlert] = useState([])
    const [showpopupAlert, setShowDetailsAlert] = useState(false);
    const [updatedTime, setUpdatedTime] = useState(0)

    

    const handleShowDetails = (item) => {
        setShowDetails(true)
        // setDetails(item)
        setShowDetailsInfo(item)
    }
    const handleShowalertDetails = (item) => {
        setShowDetailsAlert(true)
        // setDetails(item)
        setShowDetailsInfoAlert(item)
    }
    const [currentPage, setCurrentPage] = useState(1);
    // const [isLoading, setLoading] = useState(true);

    const [showPdfTron, setShowPdfTron] = useState(false);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        return document_list.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, document_list]);


    const userDetailRes = useSelector(state => state.user.userBroker)
    useEffect(() => {
        // console.log('userDetailRes: ', userDetailRes)
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
            setUserDetails(userDetailRes?.payload?.payload)
        }
    }, [userDetailRes])

    useEffect(() => {
        return (() => {
            setShowPdfTron(false);
        })
    }, [])

    const showFileUpload = () => {
        setShowDetailsUpload(true)
    }

    const viewPdfMode = () => {
        setViewPdf(true)
    }

   

    useEffect(() => {
        const timeChange = evt => {
            let dataObj = typeof evt.data === 'object' ? evt.data : JSON.parse(evt.data);
            console.log('timeChangeBefore: ', dataObj)
            if (dataObj && dataObj.method === 'timeChange') {
                console.log('timeChange: ', dataObj)
                setUpdatedTime(dataObj.time)
                // getFolderDetails();
                updateTime(dataObj.time,showDetailsInfo?.document_id)
               

            }
        }
        window.addEventListener("message", timeChange)
        return () => window.removeEventListener("message", timeChange)
    })

    

    return (
        <>
        <Loader start={isLoading1} />
            <div className="row mt-3">

                {currentTableData && currentTableData.length > 0 && <div className="col-md-12">
                    <h6 className="totl-file">Files</h6>
                </div>
                }
                {currentTableData && currentTableData?.length > 0 && currentTableData?.map((item, i) => {
                    return (

                        <div className='col-md-3 mt-3'>
                            <div className="uploaded-pdf-preview cursor-pointer" key={i}>
                                <svg onClick={() => {
                                    handleShowalertDetails(item)
                                    setConfirmModal(true)
                                }} id="Close" className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="30" height="30" viewBox="0 0 30 30">

                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#00b7f1" />
                                            <stop offset="1" stop-color="#ed156e" />
                                        </linearGradient>
                                    </defs>
                                    <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                                    <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                                    <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                                        <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                        <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                    </g>
                                    <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                                        <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                        <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                                    </g>
                                </svg>
                                <div className="pef-preview-bocy edt" style={{
                                    backgroundImage: 'url("../images/default-demo-event-img.jpg")',
                                    backgroundRepeat: "no-repeat", backgroundSize: "contain"
                                }} onClick={() => handleShowDetails(item)}>

                                    {false && <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="30" height="30" viewBox="0 0 30 30" id="Edit">
                                        <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stop-color="#00b7f1"></stop>
                                                <stop offset="1" stop-color="#ed156e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <circle id="Ellipse_202" data-name="Ellipse 202" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                        <circle id="Ellipse_203" data-name="Ellipse 203" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                        <path id="Icon_awesome-pen-alt" data-name="Icon awesome-pen-alt" d="M14.41,2.146,12.67.407a1.39,1.39,0,0,0-1.965,0L9.068,2.043l3.7,3.7L14.41,4.112a1.39,1.39,0,0,0,0-1.965ZM2.718,8.393A9.464,9.464,0,0,0,0,14.039v.007a.7.7,0,0,0,.767.767A9.464,9.464,0,0,0,6.423,12.1L12.118,6.4,8.379,2.8Z" transform="translate(7.592 7.592)" fill="#7a549e"></path>
                                    </svg>}
                                </div>
                                <div className="pdf-preview-name">
                                    <svg id="picture_as_pdf_24px-131985228199487546" data-name="picture+as+pdf+24px-131985228199487546" xmlns="http://www.w3.org/2000/svg" width="23.963" height="23.963" viewBox="0 0 23.963 23.963">
                                        <path id="Path_14006" data-name="Path 14006" d="M0,0H23.963V23.963H0Z" fill="none" />
                                        <path id="Path_14007" data-name="Path 14007" d="M19.973,2H7.991a2,2,0,0,0-2,2V15.979a2,2,0,0,0,2,2H19.973a2,2,0,0,0,2-2V4A2,2,0,0,0,19.973,2ZM11.486,9.489a1.5,1.5,0,0,1-1.5,1.5h-1v2h-1.5V6.992h2.5a1.5,1.5,0,0,1,1.5,1.5Zm4.992,2a1.5,1.5,0,0,1-1.5,1.5h-2.5V6.992h2.5a1.5,1.5,0,0,1,1.5,1.5Zm3.994-3h-1.5v1h1.5v1.5h-1.5v2h-1.5V6.992h3Zm-11.482,1h1v-1h-1ZM4,5.994H2V19.973a2,2,0,0,0,2,2H17.976v-2H4Zm9.985,5.492h1v-3h-1Z" transform="translate(-0.003 -0.003)" fill="#fd0a00" />
                                    </svg>
                                    <strong className="pdf-name-prev">{item.title}</strong>
                                </div>
                            </div>
                        </div>
                    )
                })}

                <PaginationMyOrders
                    className="pagination-bar "
                    currentPage={currentPage}
                    totalCount={document_list.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />


                {/* <div className="table-repost-de">
                                    Showing 1-4 of 16
                                </div> */}
            </div>

            {confirmModal &&
                <ConfirmModal
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    showModalMsg={"Are you sure you want to delete the file?"}
                    isConfirm={true}
                    confirmId={showDetailsInfoAlert?.document_id}
                    confirmAction={deleteFileFun} />
            }


            <Modal
                show={showpopup}
                onHide={handleCloseDetails}
                size="md"
                dialogClassName="modalassign addfoldermodal"
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="form-add-folder pdf-detilspop">
                            <div className="pdf-details row">
                                <div className="col-12">
                                    <p className="pdf-title-iploade"><strong>{showDetailsInfo?.title}</strong></p>
                                </div>
                                <div className="col-6">
                                    <p className="pdf-title-iploade"><strong>Date Created</strong></p>
                                    <p>{getDateTime(showDetailsInfo?.Created, userDetails)}</p>
                                </div>
                                <div className="col-6">
                                    <p className="pdf-title-iploade"><strong>Last modified</strong></p>
                                    <p>{getDateTime(showDetailsInfo?.Updated, userDetails)}</p>
                                </div>
                                <div className="col-6">
                                    <p className="pdf-title-iploade"><strong>Size</strong></p>
                                    <p>{parseInt(parseInt(showDetailsInfo?.size) / 1024)} Kb</p>
                                </div>
                            </div>
                            <div className="buttonsteammate mt-3" onClick={() => {
                                // setShowPdfTron(true);
                                // handleCloseDetails();
                            }}>
                                <button type="button" className="btn btn-primary btn-block back-button" onClick={() => {
                                    // setUpdatedTime(showDetailsInfo?.Updated)
                                    // viewPdfMode();
                                    // history.push('/documentView?title='+showDetailsInfo?.title+"&updatedTime="+showDetailsInfo?.Updated+"&documentId="+showDetailsInfo?.document_id)
                                    window.open(CONFIG.DOMAIN_URL+'documentView?title='+showDetailsInfo?.title+"&updatedTime="+showDetailsInfo?.Updated+"&documentId="+showDetailsInfo?.document_id,"_blank")
                                }}
                                >View</button>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>

            <Modal className="modalassign pdf-view-toolbox" show={showPdfTron} fullscreen={true} onHide={() => {
                // getFolderDetails();
                setShowPdfTron(false)}}>
                <Modal.Header closeButton>
                    <div>
                        <Modal.Title>{showDetailsInfo?.title}</Modal.Title>
                        <p><strong>Last modified  </strong>{getDateTime(updatedTime, userDetails)}</p>
                    </div>
                </Modal.Header>
                <Modal.Body className="pdf-view-uploaded">
                    <iframe
                        className="w-100"
                        onLoad={() => { window.scrollTo(0, 0); }}
                        src={CONFIG.API_URL_ADMIN + "pdfview/?id=" + showDetailsInfo?.document_id + "&u_token=" + localStorage.getItem(SessionKeys.API_TOKEN_BROKER) + "&access=toolbox"} height="100%" width="100%" />
                </Modal.Body>
            </Modal>



        </>
    )

}

export default DocumentList;