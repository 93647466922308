
import { useEffect, useState } from "react";
import { getVideoCoursesListingRelated } from "../../redux/video/actions";
import { useDispatch, useSelector } from "react-redux";
import { SessionKeys } from "../../utils/AppUtils";
import _ from "lodash";
import { VideoCoursesDataSetupSection } from "./VideoCoursesDataSetupSection";
import VideoCoursesListingCard from "../../pages/videoCourses/VideoCoursListingCard";
import ShimmerCardBodyThree from "../Layouts/ShimmerCardBodyThree";
const VideoContentSection = () => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const catId = params.get('catId');
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const videoListingDataRelatedRes = useSelector(
    (state) => state.video.videoCoursesListingRelated
  );
  const [relatedVideoList, setRelatedVideoList] = useState([]);
  const [isLoader,setIsLoader] = useState();

  useEffect(() => {
    if (!_.isEmpty(catId)) {
      setIsLoader(true);
      dispatch(getVideoCoursesListingRelated({
        module: "getVideoCourseListingRelated",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          category_id: catId
        }
      }))
    }

  }, [catId])

  useEffect(()=>{
    if(!_.isEmpty(videoListingDataRelatedRes)){
      setIsLoader(false);
      let sortedData = [];
      if(videoListingDataRelatedRes.status==="success"){
        sortedData = videoListingDataRelatedRes?.payload?.payload;
        // let sortedData = sortByAlphabetical(arr);
        if (!_.isEmpty(sortedData)) {
          sortedData = VideoCoursesDataSetupSection(sortedData, isPremiumUser)
        }
      }
      setRelatedVideoList(sortedData);
    }
  },[videoListingDataRelatedRes])
  return (
    <>
      <div className="related-video-section related-video-section-launchpage">
        <div className="relate-title-sec">
          <h4 className="titlebox">
            There's more to Learn
          </h4>
          <div className="smalltext-videolist">
            Here are some Related Videos
          </div>
        </div>
       {/* <Slider {...settings}>*/}
        {!isLoader?<VideoCoursesListingCard
                      innerCategoryName={""}
                      coursesList={relatedVideoList}
                      isLoading={false}
                      isSlider={true}
                    />:
                    <>
                  <div className="row videolistbox">
                    {
                      [1, 2, 3].map(i => {
                        return (
                          <ShimmerCardBodyThree key={`shimmer-${i}`} />
                        )
                      })
                    }
                  </div>
                </>
                  }
        
      </div>
    </>
  );
};
export default VideoContentSection;
