import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionKeys } from "../../utils/AppUtils";
import { onPostUploadClear, onPostUploadReq } from "../../redux/user/actions";
import ReactRoundedImage from "react-rounded-image";
import _ from "lodash";
import MsgModal from "../modal/MsgModal";

const UploadPostContent = (props) => {
    const { setIsLoading, getActivityPostList, postList,setPostList } = props;
    const dispatch = useDispatch();

    var uploadRes = useSelector(state => state.user.postUploadRes);
    const authBroker = useSelector(state => state.auth.authBroker);

    const [postContent, setPostContent] = useState('');
    const [postImage, setPostImage] = useState([]);
    const [postVideo, setPostVideo] = useState(null);
    const [uploadImgErr, setUploadImgErr] = useState('');
    const [uploadContentErr, setUploadContentErr] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [confirmModal,setConfirmModal] = useState(false);
    const [modalMsg,setModalMsg] = useState('Your post uploaded successfully.')

    useEffect(()=>{
        return(()=>{
            dispatch(onPostUploadClear())
        })
    },[])

    useEffect(() => {
        if (!_.isEmpty(uploadRes)) {
            setIsLoading(false);
            if (uploadRes?.status === "success") {
                setConfirmModal(true);
                setPostContent('')
                setPostImage([])
                getActivityPostList();
                addPostLocally(uploadRes?.data)
                dispatch(onPostUploadClear())
                

            }
        }
    }, [uploadRes])

    const addPostLocally = (res) =>{
        
        let tempObj = {
            activity_id: res['activity_id '],
            user_details: res?.user_details,
            post_content:res?.post_content,
            images: res?.images,
            like: 0,
            is_me: true,
            is_like_by_me: false,
            comment: 0,
            comment_details: [],
            created_date: res?.created_date
        }

        let arr = _.cloneDeep(postList)
        
        setPostList([tempObj].concat(arr));
        
    }

    const onUpload = (mediaType) => {
        setUploadImgErr("")
        if (postContent.trim() === '' && postImage.length === 0) {
            setUploadContentErr('Post content should not be empty');
            return;
        }
        setUploadContentErr('');
        setIsLoading(true)
        const formData = new FormData();
        formData.append('token', localStorage.getItem(SessionKeys.API_TOKEN_BROKER));
        formData.append('post_content', postContent);
        postImage.map((item) => {
            formData.append('image[]', item);
        })

        formData.append('video', postVideo);
        formData.append('media_type', postImage.length > 0 ? 'image' : postVideo !== null ? 'video' : '');
        let tempObj = {
            module:'uploadPost',
            postData:formData
        }
        dispatch(onPostUploadReq(tempObj))


    }

    const removeSelectedImage = (index) => {

        let imgArr = _.cloneDeep(postImage)
        imgArr.splice(index, 1);
        setPostImage(imgArr);
        setUploadImgErr("");
    }



    return (<>
        <section className="Mywall_profile mywall-comm mb-3">
            <div className=" d-flex justify-content-between profileUserList community-postsec">
                <div className="wallavatarimg">
                    <ReactRoundedImage
                        image={authBroker?.payload?.user?.profile_img !== undefined && authBroker?.payload?.user?.profile_img !== '' ? authBroker?.payload?.user?.profile_img : "/images/avatar.png"}
                        roundedSize="0" imageWidth="55" imageHeight="55" />
                </div>
                <div className="input-text position-relative">
                    <label htmlFor="password" className="input__label">
                        Write a Post
                    </label>
                    <textarea
                        type="text"
                        id="password"
                        name="password"
                        className="input__Field02 "
                        placeholder=" "
                        value={postContent}
                        onChange={(evt) => {
                            setUploadContentErr('');
                             setPostContent(evt.target.value) }}
                    ></textarea>
                    <div style={{ color: "red" }}>{uploadContentErr}</div>
                </div>
                
                <div className="d-flex justify-content-end iconLP02 acticons-post">
                    <a >
                        {/* <button className="button-reset" >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                id="Like"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        y1="0.5"
                                        x2="1"
                                        y2="0.5"
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset="0" stop-color="#00b7f1" />
                                        <stop offset="1" stop-color="#ed156e" />
                                    </linearGradient>
                                </defs>
                                <circle
                                    id="Ellipse_57"
                                    data-name="Ellipse 57"
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    fill="url(#linear-gradient)"
                                />
                                <circle
                                    id="Ellipse_179"
                                    data-name="Ellipse 179"
                                    cx="13"
                                    cy="13"
                                    r="13"
                                    transform="translate(2 2)"
                                    fill="#fff"
                                />
                               
                                 <path id="Path_491"
                                    data-name="Path 491" d="M 4 4 C 2.9069372 4 2 4.9069372 2 6 L 2 18 C 2 19.093063 2.9069372 20 4 20 L 20 20 C 21.093063 20 22 19.093063 22 18 L 22 6 C 22 4.9069372 21.093063 4 20 4 L 4 4 z M 4 6 L 20 6 L 20 18 L 4 18 L 4 6 z M 14.5 11 L 11 15 L 8.5 12.5 L 5.7773438 16 L 18.25 16 L 14.5 11 z" transform="translate(3 2)"
                                    fill="var(--color-element-1)"/>
                            </svg>
                        </button> */}
                        <div className="upload-gallery-mywall">
                            {/* <label><strong>Upload Images</strong></label> */}

                            <div className="images-uploadbox">

                                <div className="upload-image-gallery cursor-pointer" title="Upload image">
                                    <input type="file" name="file" accept="image/png,image/jpeg" className="form-control" id="exampleFile"
                                        onChange={
                                            (e) => {
                                                if (e.target.files[0] !== undefined && e.target.files[0] !== null) {

                                                    let imgArr = _.cloneDeep(postImage)
                                                    if (imgArr.length < 5) {
                                                        imgArr.push(e.target.files[0])
                                                        setPostImage(imgArr);
                                                        setUploadImgErr("");
                                                    } else {
                                                        setUploadImgErr("You can't upload more than five image")
                                                    }
                                                }
                                            }
                                        } />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stopColor="#00b7f1"></stop>
                                                <stop offset="1" stopColor="#ed156e"></stop>
                                            </linearGradient>
                                        </defs>
                                        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                        <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                        <g id="surface1" className="imageuploadsv" transform="translate(5 4.586)">
                                            <path fill="var(--color-element-1)" stroke="var(--color-element-1)" d="M 3.332031 3.332031 C 2.421875 3.332031 1.667969 4.089844 1.667969 5 L 1.667969 15 C 1.667969 15.910156 2.421875 16.667969 3.332031 16.667969 L 16.667969 16.667969 C 17.578125 16.667969 18.332031 15.910156 18.332031 15 L 18.332031 5 C 18.332031 4.089844 17.578125 3.332031 16.667969 3.332031 Z M 3.332031 5 L 16.667969 5 L 16.667969 15 L 3.332031 15 Z M 12.082031 9.167969 L 9.167969 12.5 L 7.082031 10.417969 L 4.816406 13.332031 L 15.207031 13.332031 Z M 12.082031 9.167969 " />
                                        </g>
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </a>
                    <a >
                        <button className="button-reset" title="Post" onClick={() => { onUpload() }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                id="Post"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        y1="0.5"
                                        x2="1"
                                        y2="0.5"
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset="0" stopColor="#00b7f1" />
                                        <stop offset="1" stopColor="#ed156e" />
                                    </linearGradient>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_1976"
                                            data-name="Rectangle 1976"
                                            width="16.27"
                                            height="12.079"
                                            fill="var(--color-element-1)"
                                        />
                                    </clipPath>
                                </defs>
                                <circle
                                    id="Ellipse_180"
                                    data-name="Ellipse 180"
                                    cx="15"
                                    cy="15"
                                    r="15"
                                    fill="url(#linear-gradient)"
                                />
                                <circle
                                    id="Ellipse_181"
                                    data-name="Ellipse 181"
                                    cx="13"
                                    cy="13"
                                    r="13"
                                    transform="translate(2 2)"
                                    fill="#fff"
                                />
                                <g
                                    id="Group_1978"
                                    data-name="Group 1978"
                                    transform="translate(6.865 8.961)"
                                >
                                    <path
                                        id="Path_3107"
                                        data-name="Path 3107"
                                        d="M15.429,41.841l3.338-3.482L15.429,36Z"
                                        transform="translate(-12.084 -29.763)"
                                        fill="var(--color-element-1)"
                                    />
                                    <g
                                        id="Group_1977"
                                        data-name="Group 1977"
                                        transform="translate(0 0)"
                                    >
                                        <g
                                            id="Group_1976"
                                            data-name="Group 1976"
                                            clipPath="url(#clip-path)"
                                        >
                                            <path
                                                id="Path_3108"
                                                data-name="Path 3108"
                                                d="M15.917.034.263,5.214c-.323.107-.353.345-.069.528L3.474,7.868,14.878.842,4.488,8.525l4.567,2.963a.579.579,0,0,0,.8-.153c.01-.014.019-.029.027-.044L16.19.371c.174-.29.05-.444-.273-.337"
                                                transform="translate(0 0)"
                                                fill="var(--color-element-1)"
                                                fillRule="evenodd"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </a>
                </div>
            </div>
            
            <div className="img-uploaded-sec">
                {
                    postImage.map((item, index) => {
                        return <div className="img-box-wall">
                            <img src={URL.createObjectURL(item)} width="120" height="120" className="img-wall-uploaded" />
                            <button className="remove-img-wall" onClick={() => { removeSelectedImage(index) }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30">

                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#00b7f1"></stop>
                                            <stop offset="1" stopColor="#ed156e"></stop>
                                        </linearGradient>
                                    </defs>
                                    <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)"></circle>
                                    <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff"></circle>
                                    <g id="Group_1344 prev" title="Move Back" data-name="Group 1344" transform="translate(15 9) rotate(90)">
                                        <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                        <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                    </g>
                                    <g id="Group_2376 next" title="Move Ahead" data-name="Group 2376" transform="translate(9 14.586)">
                                        <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                        <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4"></line>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    })
                }
            </div>
            <div style={{ color: "red" }}>{uploadImgErr}</div>
        </section>
        <MsgModal 
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={modalMsg}
        isConfirm={false}
        confirmId={""}
        confirmAction={function name(params) {
            }} />
    </>)
}
export default UploadPostContent;