import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssignUserReq,
  getTeamListReq,
  getTeamListResponseClear,
  setCurrentView,
} from "../../redux/assignCourse/actions";
import { SessionKeys } from "../../utils/AppUtils";
import StaticUtils from "../../utils/StaticUtils";
import Loader from "../Loader/Loader";
const ChooseTeams = (props) => {
  const { selectedTeamList, setSelectedTeamsList, parentId, parentName, setTitleHeading } =
    props;

  const dispatch = useDispatch();
  const teamsListRes = useSelector((state) => state.assignCourse.teamsList);
  const assignedUserRes = useSelector(
    (state) => state.assignCourse.assignedUsers
  );
  const [searchInput, setSearchInput] = useState("");
  const [teamsList, setTeamsList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(()=>{
    return(()=>{
      dispatch(getTeamListResponseClear());
    })
  },[])

  useEffect(() => {
    setTitleHeading(`Assign ${parentName} to`);
    dispatch(getTeamListResponseClear());
    setAssignedUsers([]);
    getAssignedUsers();
  }, [parentId]);

  useEffect(() => {
    setSelectedCount(selectedTeamList?.length);
  }, [selectedTeamList]);

  useEffect(() => {
    console.log('searchInput', searchInput);
    if (searchInput !== undefined && searchInput.length > 2) getTeamsOnSearch();

    if (searchInput === undefined || searchInput === '') getDefaultTeams();
  }, [searchInput]);

  const getTeamsOnSearch = () => {
    setIsLoading(true);
    dispatch(
      getTeamListReq({
        module: "getTeamsList",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          search: searchInput,
        },
      })
    );
  };

  const getDefaultTeams = () => {
    setIsLoading(true);
    dispatch(
      getTeamListReq({
        module: "getDefaultTeamsList",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
        },
      })
    );
  };

  useEffect(() => {
    // console.log("getTeamsList: ", teamsListRes);
    if (!_.isEmpty(teamsListRes)) {
      setIsLoading(false);
      if (teamsListRes?.status === "success") {
        setTeamsList(teamsListRes?.payload?.payload);
      }
    } else {
      setTeamsList([]);
    }
  }, [teamsListRes]);

  const getAssignedUsers = () => {
    dispatch(
      getAssignUserReq({
        module: "getAssignedUsers",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          content_type: parentName.toLowerCase().includes('event') ? 'session' : parentName.toLowerCase().includes('certificate') ? 'certificate' : parentName.toLowerCase(),
          content_ids: [parentId],
          type: "teams",
        },
      })
    );
  };

  useEffect(() => {
    // console.log("teamMatesListRes: ", teamMatesListRes);
    if (!_.isEmpty(assignedUserRes)) {
      if (assignedUserRes?.status === "success") {
        setAssignedUsers(assignedUserRes?.payload?.payload);
      } else {
        setAssignedUsers([]);
      }
    } else {
      setAssignedUsers([]);
    }
  }, [assignedUserRes]);

  const selectItem = (index) => {
    let tempArr = _.cloneDeep(teamsList);
    let selectedTempArray = _.cloneDeep(selectedTeamList);
    // console.log("isContainArray: ", selectedTempArray, selectedTeamList);
    if (!StaticUtils.isContainObject(selectedTempArray, tempArr[index], 1)) {
      selectedTempArray.push(tempArr[index]);
    }

    setSelectedTeamsList(selectedTempArray);
    tempArr.splice(index, 1);
    setTeamsList(tempArr);
  };

  const removeItem = (index) => {
    let selectedTempArray = _.cloneDeep(selectedTeamList);
    let tempArr = _.cloneDeep(teamsList);

    if (!StaticUtils.isContainObject(tempArr, selectedTempArray[index], 1)) {
      tempArr.push(selectedTempArray[index]);
    }

    setTeamsList(tempArr);
    selectedTempArray.splice(index, 1);
    setSelectedTeamsList(selectedTempArray);
  };

  return (
    <>
      <Loader start={isLoading} />
      <div className="chooseteams chooseppl">
        <div className="chooseteamate">
          <div className="headong d-flex justify-content-between">
            <h4>Choose Teams</h4>{" "}
            <button
              type="button"
              className="btn btn-primary btn-block create-team-btn"
              onClick={() => {
                dispatch(setCurrentView(5));
              }}
            >
              + Create a Team
            </button>
          </div>
          <div className="input-lear position-relative d-flex">
            <label for="teamsearch" className="input__label_learning">
              Search
            </label>
            <input
              type="text"
              id="teamsearch"
              name="teamsearch"
              className="input_searchfield_search-cart "
              placeholder="Start searching"
              onChange={(evt) => {
                setSearchInput(evt.target.value);
              }}
            />
          </div>
          <div className="search-team-result">
            {teamsList?.map((item, index) => {
              return (
                <div className="team-result-box">
                  <div className="userteamname">
                    {item?.team_name}{" "}
                    <span className="peoplecount">
                      {item?.user_count} people
                    </span>
                  </div>
                  <div
                    className="addteammate cursor-pointer"
                    onClick={() => {
                      selectItem(index);
                    }}
                  >
                    +
                  </div>
                </div>
              );
            })}
          </div>
          <div className="teamselected">
            <div className="selectresilt">
              Selected Teams <strong>({selectedCount})</strong>
            </div>
            <div className="search-team-result slectedteammates">
              {selectedTeamList?.map((item, index) => {
                return (
                  <div className="team-result-box">
                    <div className="userteamname">
                      {item?.team_name}{" "}
                      <span className="peoplecount">
                        {item?.user_count} people
                      </span>
                    </div>
                    <div
                      className="addteammate cursor-pointer"
                      onClick={() => {
                        removeItem(index);
                      }}
                    >
                      +
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="buttonsteammate">
            <button
              type="button"
              className="btn btn-primary btn-block back-button"
              onClick={() => {
                dispatch(setCurrentView(1));
              }}
            >
              Back
            </button>

            {selectedCount > 0 ? (
              <button
                type="button"
                className="btn btn-primary btn-block setup-button"
                onClick={() => {
                  dispatch(setCurrentView(1));
                }}
              >
                Proceed
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block setup-button"
                disabled
              >
                Proceed
              </button>
            )}
          </div>

          <div className="teamselected">
            <div className="selectresilt">
              Assigned Teams <strong>({assignedUsers.length})</strong>
            </div>
            <div className="search-team-result slectedteammates">
              {assignedUsers?.map((item, index) => {
                return (
                  <div className="team-result-box">
                    <div className="userteamname">
                      {item?.team_name}{" "}
                      <span className="peoplecount">
                        {item?.user_count} people
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseTeams;
