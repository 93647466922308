import _ from "lodash";
import { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { assignToTeamsReq, assignToTeamsResponseClear, doAssignTeamMates, doAssignTeamMatesResClear, setCurrentView } from "../../redux/assignCourse/actions";
import { SessionKeys } from "../../utils/AppUtils";
import Loader from "../Loader/Loader";
import ConfirmModal from "../modal/ConfirmModal"
const SelectOption = (props) => {
  const { selectedTeamMatesList, selectedTeamList, setShow, parentId, parentName, origin, setTitleHeading } = props
  const dispatch = useDispatch();
  const assignTeammatesRes = useSelector((state) => state.assignCourse.assignTeamMates);
  const assignTeamsRes = useSelector((state) => state.assignCourse.assignTeamsRes);
  const { courseId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);

  useEffect(() => {
    setTitleHeading(`Assign ${parentName} to`);
    return (() => {
      dispatch(doAssignTeamMatesResClear())
      dispatch(assignToTeamsResponseClear())
    })
  }, [])

  const assignTeamMates = () => {

    let selectedUsers = [];
    for (const item of selectedTeamMatesList) {
      selectedUsers.push(item.user_id);
    }
    let req = {
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      users: selectedUsers,
      type: 'teammates',
      content_ids: [parentId],
      content_type: parentName.toLowerCase().includes('event') ? 'session' : parentName.toLowerCase().includes('certificate') ? 'certificate' : parentName.toLowerCase(),
      origin: origin
    }
    dispatch(doAssignTeamMates({
      module: 'assignTeamMates',
      postData: req
    }))


  }

  const assignToTeams = () => {
    setIsLoading(true)
    let selectedUsers = [];
    for (const item of selectedTeamList) {
      selectedUsers.push(item.id);
    }
    let req = {
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      team_ids: selectedUsers,
      content_ids: [parentId],
      content_type: parentName.toLowerCase().includes('event') ? 'session' : parentName.toLowerCase().includes('certificate') ? 'certificate' : parentName.toLowerCase(),
      origin: origin
    }
    dispatch(assignToTeamsReq({
      module: 'assignToTeams',
      postData: req
    }))


  }


  useEffect(() => {
    // console.log('assignTeammatesRes: ', assignTeammatesRes)
    if (!_.isEmpty(assignTeammatesRes)) {
      setIsLoading(false)
      if (assignTeammatesRes?.status === "success") {
        setConfirmModal(true);
        
      }
    }

  }, [assignTeammatesRes])

  useEffect(() => {
    // console.log('assignTeamsRes: ', assignTeamsRes)
    if (!_.isEmpty(assignTeamsRes)) {
      setIsLoading(false)
      if (assignTeamsRes?.status === "success") {
        setConfirmModal(true)

      }
    }
  }, [assignTeamsRes])

  useEffect(() => {
    if (confirmModal !== null && !confirmModal) {
      if ((isAndroid || isIOS)) {
        try {
          // alert("Window CallbackHanlder");
          if (window.callbackHandler != undefined) {
            window.callbackHandler.postMessage("assignedSuccess");
            setShow(false)
          }
          if (window.webkit != undefined) {
            if (window.webkit.messageHandlers.callbackHandler != undefined) {
              window.webkit.messageHandlers.callbackHandler.postMessage(
                "assignedSuccess"
              );
              setShow(false)
            }
          }
        } catch (err) {
          // alert("Window CallbackHanlder Err");
          // console.log('The native context does not exist yet');
          setShow(false)
        }
      }
      else {
        setShow(false)
      }
    }
  }, [confirmModal])


  return (
    <>
      <Loader start={isLoading} />
      <div className="optionsd">
        Select an option below
      </div>
      <p>Assign this {parentName.toLowerCase()} to <strong className={selectedTeamMatesList?.length === 0 ? "chosoeselect" : "cursor-pointer"} onClick={() => {
        if (selectedTeamMatesList?.length === 0) {
          dispatch(setCurrentView(3))
        }

      }}>{selectedTeamList?.length > 0 && selectedTeamList?.length} Teams</strong> or <strong className={selectedTeamList?.length === 0 ? "chosoeselect" : "cursor-pointer"} onClick={() => {
        if (selectedTeamList?.length === 0)
          dispatch(setCurrentView(2))
      }}>{selectedTeamMatesList?.length > 0 && selectedTeamMatesList?.length} Agents</strong></p>
      <div className="button-sec-pop">
        <button type="button" className="btn btn-primary btn-block setup-button" disabled={(selectedTeamMatesList.length > 0 || selectedTeamList.length > 0) ? false : true}
          onClick={() => {
            setIsLoading(true)
            if (selectedTeamMatesList.length > 0) {
              assignTeamMates();
            } else if (selectedTeamList.length > 0) {
              assignToTeams();
            }
          }}>+ Assign</button>
      </div>
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        showModalMsg={"Your changes will be reflected after 15 minutes."}
        isConfirm={false}
        confirmId={''}
        confirmAction={function name(params) { }}
        isRightIcon={false} />
    </>

  )
}

export default SelectOption;