import { useEffect } from "react";
import ProgressReportSection from "../../components/Reports/New Progress Report/ProgressReportSection";

export const ProgressReportPageMobile = () => {
    useEffect(() => {
            if (document.getElementById("id_page_404") !== null) {
              document.getElementById("id_page_404").style.display = "none";
            }
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
                document.getElementById("cc_div").style.display = "none";
        }, 100)
    }, [])
    return (

        <ProgressReportSection 
            isMobile={true}
        />
    )
}

export default ProgressReportPageMobile;