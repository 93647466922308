import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clickStreamUser } from "../../utils/AppUtils";

const BackButton = () => {
  const history = useHistory();
  const authBroker = useSelector((state) => state.auth.authBroker);

  return (
    
      <div className="col-md-12 mb-4 d-flex justify-content-start">
        <button
          className="btn btn-primary-complete bckbtn"
          onClick={() => {
            clickStreamUser("back-btn", {
              userId: authBroker?.payload?.user.user_id,
            });
            history.goBack();
          }}
        >
          Back
        </button>
        
      </div>
    
  );
};

export default BackButton;
