import { fork, take, call, put } from 'redux-saga/effects'

// import { fetchTimezoneResponse } from './actions'
import types from './types'
import { postAPIWithoutModule } from '../../api/generic'
import { checkContentAccessResponse } from './actions'

// export function* doTimezoneFetch() {
//   while (true) {
//       const { payload } = yield take(types.TIMEZONE_FETCH)
//       const response = yield call(postAPIWithoutModule, payload)
//       yield put(fetchTimezoneResponse(response))
//   }
// }

export function* doCheckContentAccess() {
  while (true) {
    const { payload } = yield take(types.CONTENT_ACCESS)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(checkContentAccessResponse(response))
  }
}

function* home() {
  // yield fork(doTimezoneFetch)
  yield fork(doCheckContentAccess)
}

export default home