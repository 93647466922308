import _ from "lodash";
import { useHistory } from "react-router-dom";

const toggleSideNav = () => {
    var sideNav = document.querySelector(".side-nav-custom");
    var toogleiconNav = document.querySelector("#openbtnNav .closetoogle");
    // console.log(btnClasslist)
    if (sideNav.classList.contains("opennav")) {
      toogleiconNav.classList.add("closeopen");
      sideNav.classList.remove("opennav");
    } else {
      sideNav.classList.add("opennav");
      toogleiconNav.classList.remove("closeopen");
    }
  };
const VideoCatLeftMenu=(props)=>{
  const {subCategories, categoryName,getSubCategoryVideos,setIsOwnCategoryClick,getCategoryVideos} = props;
  const history = useHistory();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const catId = params.get('catId');
  const subCatId = params.get('subCatId');

    return(
        <div className="side-navbar active-nav d-flexs flex-wrap flex-column">
                          <div className="nav-box-sidebar d-flex gap-2">
                          <div className="nav-side-title cursor-pointer" onClick={()=>{
                            history.push(`/video-listing?catId=${catId}`);
                            setIsOwnCategoryClick(true);
                            getCategoryVideos();
                          }}>
                                {categoryName}
                          </div>
                          <button className="openbtn" id="openbtnNav" 
                          // ref={hamburgerMenu}
                          onClick={() => {
                            toggleSideNav();
                          }}>
                            <span className="closetoogle"></span>
                          </button>
                          </div>
                        <ul className="nav side-nav-custom opennav flex-column w-100">   
                        {!_.isEmpty(subCategories)&&subCategories.map((item,index)=>{
                          return <li className={subCatId!==null&&parseInt(subCatId)===item?.sub_cat_id?"nav-link active":"nav-link"} onClick={()=>{
                            getSubCategoryVideos(item?.sub_cat_id)
                            history.replace(`/video-listing?catId=${catId}&subCatId=${item.sub_cat_id}`);
                          }}>
                          <span className="mx-2">{item?.sub_cat_name}</span>
                        </li>
                        })}    
                        </ul>
                      </div>
    )
}

export default VideoCatLeftMenu;