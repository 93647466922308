import CourseListBody from "../../components/Course/CourseListBody";
import Page from "../../components/Page";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesListing,
  getCoursesListingClear,
  getCoursesListingClearFromBroker,
  getCoursesListingClearFromWoo,
  getCoursesListingFromBroker,
  getCoursesListingFromWoo,
} from "../../redux/courses/actions";
import _ from "lodash";
import {
  convertHoursToMins,
  convertTimeFormat,
  getFormatCourseListFromWoo,
  SessionKeys,
} from "../../utils/AppUtils";
import ShimmerScreen from "../../components/Layouts/ShimmerScreen";
import ShimmerCardBody from "../../components/Layouts/ShimmerCardBody";

const CourseListingPage = () => {

  const dispatch = useDispatch();

  const courseCategoriesData = useSelector((state) => state.courses.courseCategories);
  const productCategoriesData = useSelector((state) => state.courses.productCategories);
  const courseCategoriesDataBroker = useSelector((state) => state.courses.coursesCategoriesBroker);
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const coursesListingData = useSelector((state) => state.courses.coursesListing);
  const coursesListingDataWithWoo = useSelector((state) => state.courses.coursesListingWoo);
  const coursesListingDataWithBroker = useSelector((state) => state.courses.coursesListingBroker);

  const [coursesList, setCoursesList] = useState([]);
  const [, setCourseCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [courseListCatSubCat, setCourseListCatSubCat] = useState([])
  const [courseListCatSubCatShow, setCourseListCatSubCatShow] = useState([]);

  useEffect(() => {
    if (authBroker?.payload?.user.user_id) {
      // dispatch(getCoursesListing({
      //   broker: {
      //     module: "get_all_courses",
      //     postData: {
      //       token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      //     }
      //   },
      //   ecom: {
      //     module: "courseListingWoo",
      //     token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      //     email: authBroker?.payload?.user.email,
      //     userId: authBroker?.payload?.user.user_id,
      //     company_id: authBroker?.payload?.user.company_id,
      //   }
      // }))

    }
  }, [dispatch, authBroker]);


  useEffect(() => {
    // console.log('product categories', productCategoriesData)
    if (
      !_.isEmpty(courseCategoriesDataBroker) &&
      courseCategoriesDataBroker.status === "success" &&
      !_.isEmpty(productCategoriesData) &&
      productCategoriesData.status === "success"
    ) {
      let categoryArray = [];
      if (productCategoriesData.payload.length > 0) {
        categoryArray = productCategoriesData.payload;
      }
      if (courseCategoriesDataBroker?.payload?.length > 0) {
        categoryArray = [
          ...categoryArray,
          ...courseCategoriesDataBroker.payload,
        ];
      }
      setCourseCategories(categoryArray);
    }
  }, [courseCategoriesData, productCategoriesData, courseCategoriesDataBroker]);


  useEffect(() => {
    if (!_.isEmpty(courseListCatSubCat)) {
      let listingData = _.cloneDeep(courseListCatSubCat)
      let formattedList = listingData;// getFormatCourseListFromWoo(listingData, isPremiumUser)
      console.log('formattedList: ', formattedList)
      for (let course of formattedList) {

        // let coursesData = formatWooCourses(data)
        course.isLock = false;

        if (course.source === 'broker') {
          course.is_paid = true;
          if (course.durationUnit === "Hours") {
            course.duration = convertHoursToMins(course.duration);
          }
          course.duration = convertTimeFormat(_.toNumber(course.duration));
          course.durationUnit = "";
          course.isLock = false;
        }else{
          course = getFormatCourseListFromWoo(course, isPremiumUser)
        }

      }
      setCourseListCatSubCatShow(formattedList)
      dispatch(getCoursesListingClear())
    }
    setIsLoading(false)

  }, [courseListCatSubCat])


  useEffect(() => {
    if (!_.isEmpty(coursesListingData)) {
      let listingData = [];
      for (let data of coursesListingData) {
        if (data.status === 'success') {
          if (data.from === 'courseListingWoo') {
            let coursesData = formatWooCourses(data)
            for (let record of coursesData) {
              if (record?.courseList?.length > 0) {
                listingData.push(record)
              }
              for (let course of record?.courseList) {
                course.isLock = false;
              }
            }
          } else if (data.from === 'get_all_courses') {
            let courseListData = _.cloneDeep(
              data.payload.payload
            );
            if (!_.isEmpty(courseListData.courseList) && courseListData.courseList !== null) {
              courseListData?.courseList?.sort(function (x, y) {
                return y.created_dt - x.created_dt;
              });
              for (let record of courseListData.courseList) {
                record.is_paid = true;
                if (record.durationUnit === "Hours") {
                  record.duration = convertHoursToMins(record.duration);
                }
                record.duration = convertTimeFormat(_.toNumber(record.duration));
                record.durationUnit = "";
                record.isLock = false;
              }
              // console.log('dataCoursesWithBroker', courseListData)
              listingData.push(courseListData)
              // console.log('PAYLOAD', courseListData)
            }
          }
        }
      }
      setIsLoading(false)
      setCoursesList(listingData)
      dispatch(getCoursesListingClear())
    }
  }, [coursesListingData])


  const formatWooCourses = (coursesListingDataWithWoo) => {
    let courseListData = _.cloneDeep(coursesListingDataWithWoo.payload);
    if (!_.isEmpty(courseListData)) {
      // console.log('dataCoursesWithWoo', courseListData)
      for (let record of courseListData) {
        if (!_.isEmpty(record.courseList)) {
          record?.courseList?.sort(function (x, y) {
            return y.created_dt - x.created_dt;
          });
          for (let course of record.courseList) {
            course.is_purchased = course.is_paid;
            course.showBuy = course.is_purchased ? false : true;
            course.is_bundle = false;
            course.is_free = false;
            if (course.sfdc_product_type.toLowerCase() === "basic") {
              course.showBuy = false;
              course.is_paid = true;
              course.is_free = true;
            } else if (
              course.sfdc_product_type.toLowerCase() === "premium"
            ) {
              if (isPremiumUser) {
                course.showBuy = false;
                course.is_paid = true;
                course.is_free = true;
              } else {
                if (course.product_offer_for_basic) {
                  course.sale_price = 0.0;
                  course.regular_price = 0.0;
                } else {
                  course.sale_price = course.product_basic_price;
                  course.regular_price = course.product_market_price;
                }
              }
            } else if (
              course.sfdc_product_type.toLowerCase() === "alacarte"
            ) {
              if (isPremiumUser) {
                if (course.product_offer_for_premium) {
                  course.sale_price = 0.0;
                  course.regular_price = 0.0;
                } else {
                  course.sale_price = course.product_premium_price;
                }
              } else {
                if (course.product_offer_for_basic) {
                  course.sale_price = 0.0;
                  course.regular_price = 0.0;
                } else {
                  course.sale_price = course.product_basic_price;
                }
              }
              course.regular_price = course.product_market_price;
            } else if (
              course.sfdc_product_type.toLowerCase() === "bundle"
            ) {
              course.is_bundle = true;
              for (let cl of course.course_list) {
                cl.source = course.source;
                cl.is_free = course.is_free
              }
            }
            if (course.sfdc_product_type.toLowerCase() !== "bundle") {
              if (course.durationUnit === "Hours") {
                course.duration = convertHoursToMins(course.duration);
              }
              course.duration = convertTimeFormat(
                _.toNumber(course.duration)
              );
              course.durationUnit = "";
            } else {
              // console.log('Duration', course)
              for (let cl of course.course_list) {
                if (cl.durationUnit === "Hours") {
                  cl.duration = convertHoursToMins(cl.duration);
                }
                cl.duration = convertTimeFormat(_.toNumber(cl.duration));
                cl.durationUnit = "";
              }
            }
          }
        }
      }
      return courseListData;
    }
  }

  return (
    <>
      {/* <Loader start={isLoading} /> */}
      <Page>
        <div className="my-credit-points course-listing-page">
          <section className="container">
            <div className="row">
              <div className="col-md-12 profile-left-sec">
                <div className="search_heading">
                  {/* <img src="/images/learning_img.png" width="109" height="106" alt="" /> */}
                  <p className="lable-heading_search">Courses</p>
                </div>

                {
                  !isLoading ?

                    <CourseListBody coursesList={coursesList} setIsLoadingParent={setIsLoading} pageType="course" setCourseListCatSubCat={setCourseListCatSubCat} courseListCatSubCatShow={courseListCatSubCatShow} />

                    :
                    <>
                      <div className="row">
                        <div className="view-button my-4">
                          <ShimmerScreen case="rect" width="10rem" height="1.2rem" />
                        </div>
                        {[1, 2, 3, 4].map((c, i) => {
                          return (
                            <ShimmerCardBody key={`shimmer-${i + 1}`} />
                          );
                        })}
                      </div>
                    </>
                }
              </div>
            </div>
          </section>
        </div>
      </Page>
    </>
  );
};
export default CourseListingPage;
