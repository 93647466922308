import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  myCartSaveLaterView,
  myCartSaveLaterClear,
  delSaveLaterView,
  delSaveLaterViewClear,
} from "../../redux/Cart/actions";
import { convertHoursToMins, convertSecondsToMins, convertTimeFormat, fixedToDecimal, SessionKeys } from "../../utils/AppUtils";
import { myCart } from "../../redux/Cart/actions";
import { addToCart, addToCartClear } from "../../redux/product/actions";
import { userDetail, userDetailBroker } from "../../redux/user/actions";
import ConfirmModal from "../modal/ConfirmModal";
import { useParams } from "react-router-dom";
import AlertDeleteSave from "./alertModalDelSave";
import AlertAddtoCart from "./alertModalAddCart";
import Slider from "react-slick";
import PrevArrow from "../LearningPaths/PrevArrow";
import NextArrow from "../LearningPaths/NextArrow";
import { Carousel } from "react-bootstrap";

import _, { parseInt } from "lodash";
import { useHistory } from "react-router-dom";
import ImageComponent from "../Layouts/ImageComponent";
import RecommendProductCard from "../Product/RecommendProductCard";

const CartWishlistCard = (props) => {

  const { savedDataList,cartIdArr } = props;
  const dispatch = useDispatch();
  const authBroker = useSelector((state) => state.auth.authBroker);
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const addToCartRes = useSelector((state) => state.product.addToCart);
  const [userDetails, setUserDetails] = useState({});
  const [savedData, setSavedData] = useState({});
  const [productId, setProductId] = useState('')
  // const [confirmModal, setConfirmModal] = useState(false)
  // const [showModalMsg, setShowModalMsg] = useState("Added in cart successfully")
  const { courseId } = useParams();
  const [showdeletecartmodal, setShowDeleteCartModal] = useState(false);
  const [showaddmodal, setShowAddModal] = useState(false);
  const delSaveFordata = useSelector((state) => state.carts.delSaveLaterView);
  // console.log("del res", delSaveFordata);
  const history = useHistory();
  // const myCartSaveLaterViewRes = useSelector(state => state.carts.myCartSaveLaterView)

  const handleshowDelItem = (product_id) => {
    setProductId(product_id)
    setShowDeleteCartModal(true);
  };
  const handleShowAddToCart = (product_id) => {
    setProductId(product_id)
    setShowAddModal(true);
  };
  const handleCloseDelete = () => {
    setShowDeleteCartModal(false);
  };
  const handleCloseAdd = () => {
    setShowAddModal(false);
  };

  const delSaveLaterItem = (product_id) => {
    // console.log("ppp od", product_id);
    // return;
    dispatch(
      delSaveLaterView({
        module: "delete-save-later",
        postData: { pid: product_id, uemail: authBroker.payload.user.email },
      })
    );
    window.scrollTo(0, 0);
    setShowDeleteCartModal(true);
    handleCloseDelete();
    props.setIsLoading(true);
  };

  useEffect(() => {
    // console.log("my cart delete", cartDetail)

    if (!_.isEmpty(delSaveFordata)) {
      
      if (delSaveFordata.status === "success") {
        if (delSaveFordata?.payload?.status) {
          
          dispatch(
            myCartSaveLaterView({
              module: "show-save-later",
              userId: authBroker.payload.user.email,
            })
          );
          // setShowModalMsg(saveForLaterRes?.payload?.message)
          // setConfirmModal(true)
          dispatch(
            myCart({
              module: "myCart",
              email: authBroker.payload.user.email,
            })
          );
        }
        // props.setIsLoading(false);
        dispatch(delSaveLaterViewClear());
      }
    }
  }, [delSaveFordata]);

  useEffect(() => {
    if (!_.isEmpty(savedDataList)) {
      // console.log("SavedDataList", savedDataList);
      for (let data of savedDataList) {
        data.selling_price = fixedToDecimal(data.selling_price, 2)
        if (data.product_type === "course") {
          if (data.sfdc_product_type.toLowerCase() === "bundle") {

            for (let cl of data.course_list) {
              if (cl.durationUnit === 'Hours') {
                cl.duration = convertHoursToMins(cl.duration);
                cl.course_duration = convertHoursToMins(cl.course_duration);
              }
              cl.duration = convertTimeFormat(_.toNumber(cl.duration))
              cl.course_duration = convertTimeFormat(_.toNumber(cl.course_duration))
              cl.durationUnit = ''

            }


          } else {
            if (data.durationUnit === 'Hours') {
              data.course_duration = convertHoursToMins(data.course_duration);
            }
            data.course_duration = convertTimeFormat(_.toNumber(data.course_duration));
            data.durationUnit = ''
            data.course_period = ''
          }
        } else {
          if (data.product_type === 'videos') {
            if (data.sfdc_product_type.toLowerCase() === "bundle") {

              for (let cl of data.course_list) {
                if (
                  cl.durationUnit === "Second(s)" &&
                  cl.duration >= 60
                ) {
                  if (cl.duration % 60) {
                    cl.duration = convertTimeFormat(
                      cl.duration,
                      true
                    );
                  } else {
                    cl.duration = convertSecondsToMins(
                      cl.duration
                    );
                    cl.duration = convertTimeFormat(
                      cl.duration
                    );
                  }
                  cl.durationUnit = "";
                } else if (
                  cl.durationUnit === "Second(s)" &&
                  cl.duration < 60
                ) {
                  cl.duration = `${cl.duration} Sec${cl.duration > 1 ? "s" : ""
                    }`;
                  cl.durationUnit = "";
                } else if (cl.durationUnit === "Hours") {
                  cl.duration = convertHoursToMins(
                    cl.duration
                  );
                  cl.duration = convertTimeFormat(
                    cl.duration
                  );
                  cl.durationUnit = "";
                }
                // if (cl.durationUnit === "Hours") {
                //   cl.duration = convertHoursToMins(cl.duration);
                // }
                // cl.duration = convertTimeFormat(_.toNumber(cl.duration));
                // cl.durationUnit = "";
              }

            } else {
              if (
                data.durationUnit === "Second(s)" &&
                data.course_duration >= 60
              ) {
                if (data.course_duration % 60) {
                  data.course_duration = convertTimeFormat(
                    data.course_duration,
                    true
                  );
                } else {
                  data.course_duration = convertSecondsToMins(
                    data.course_duration
                  );
                  data.course_duration = convertTimeFormat(
                    data.course_duration
                  );
                }
                data.durationUnit = "";
              } else if (
                data.durationUnit === "Second(s)" &&
                data.course_duration < 60
              ) {
                data.course_duration = `${data.course_duration} Sec${data.course_duration > 1 ? "s" : ""
                  }`;
                  data.durationUnit = "";
              } else if (data.durationUnit === "Hours") {
                data.course_duration = convertHoursToMins(
                  data.course_duration
                );
                data.course_duration = convertTimeFormat(
                  data.course_duration
                );
                data.durationUnit = "";
              }
            }
          }
        }
      }
      setSavedData(savedDataList);
    } else {
      setSavedData(savedDataList);
    }
  }, [savedDataList]);

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        myCart({
          module: "myCart",
          email: authBroker.payload.user.email,
          // userId: localStorage.getItem(SessionKeys.EMAIL_SESSION)
        })
      );
    }
  }, [dispatch, authBroker]);
  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        myCartSaveLaterView({
          module: "show-save-later",
          userId: authBroker.payload.user.email,
          // userId: localStorage.getItem(SessionKeys.EMAIL_SESSION)
        })
      );
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    setUserDetails(userDetailRes?.payload?.payload);
  }, [userDetailRes]);

  const addToCartFunction = (product_id) => {
    // console.log(product_id)
    // return;
    dispatch(
      addToCart({
        module: "addToCart",
        postData: {
          pid: product_id,
          email: userDetails?.email,
          first_name: userDetails?.firstname,
          last_name: userDetails?.lastname,
          username: userDetails?.username,
          company: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          phone: "",
        },
      })
    );
    window.scrollTo(0, 0);
    setShowAddModal(true);
    handleCloseAdd();
    props.setIsLoading(true);
  };

  useEffect(() => {
    if (Object.keys(addToCartRes).length !== 0) {
      dispatch(
        myCart({
          module: "myCart",
          email: authBroker.payload.user.email,
        })
      );
      dispatch(
        myCartSaveLaterView({
          module: "show-save-later",
          userId: authBroker.payload.user.email,
        })
      );
    }
  }, [addToCartRes]);

  useEffect(() => {
    return () => {
      // console.log("addToClear: ");
      dispatch(addToCartClear());
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(userDetail())
    dispatch(
      userDetailBroker({
        module: "getOrderDetailBroker",
        apiToken: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      })
    );
  }, []);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    dots: true,
    arrows: true,
    autoplaySpeed: 2000,
    infinite: false,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /// COMBO DISPLAY
  const renderBundleCard = (m) => {
    return (
      <div className="cart-wishox">
        <div className="img-box_nw position-relative">
          <svg id="Close" onClick={() => handleshowDelItem(m.product_id)} className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            width="30" height="30" viewBox="0 0 30 30">

            <defs>
              <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#00b7f1" />
                <stop offset="1" stop-color="#ed156e" />
              </linearGradient>
            </defs>
            <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
            <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
            <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
              <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
              <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
            </g>
            <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
              <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
              <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
            </g>
          </svg>
          {/* <img
            className="img-fluid closebtn-pfl"
            onClick={() => handleshowDelItem(m.product_id)}
            src="/images/cross.png"
          /> */}
          <div className="combo-tag">
            <i className="fa fa-star" aria-hidden="true"></i> COMBO
          </div>
          {/* <p>{m?.m.course_lessons}</p> */}
          <div id="cartCardCarousels">
            <Carousel id="courseCardCarousel" className="position-relative">
              {m.course_list.map((course, index) => {
                return (
                  <Carousel.Item key={index}>
                    <div className="img-videosec learn_image-card cursor-pointer">
                      {/* <img
                        src={course.imageURL}
                        className="img-fluid galleryImg_lern"
                        alt=""
                        onError={({currentTarget}) => {
                          currentTarget.onerror = true;
                          currentTarget.src = "/images/default-demo-img.jpg"
                      }}
                      /> */}
                      <ImageComponent
                        name={course.name}
                        thumbnail={course.imageURL ? course.imageURL : ""}
                        cssClass={"img-fluid galleryImg_lern"}
                        errorClass={"img-fluid galleryImg_lern"}
                      />
                    </div>

                    {/* <p>{course.course_lessons}</p> */}
                    <div className="img-box_nw-cart">
                      {/* <img className="img-fluid galleryImg_lern" onClick={() => delSaveLaterItem(m.product_id) }
                                            src="/images/delete-icon.PNG" /> */}

                      {/* <div className="img-videosec learn_image-card cross_Iconn">
                                                <img className="img-fluid galleryImg_lern"
                                                    src={m.product_thumbnail} />
                                                <div className="progress custom_progress_barr custom_bartwoRealtor">
                                                    <div className="progress-bar "></div>
                                                </div>
                                            </div> */}
                      <div className="details-card_nw">
                        <h5>
                          <a
                            className="course-head-al_search"
                            dangerouslySetInnerHTML={{
                              __html: course.name
                            }}
                            onClick={() => {
                              history.push(`/shopping/${m.product_id}/?addToCart=true&source=${m.product_type}`)
                            }}
                          >
                          </a>
                        </h5>
                        <div className="row info-bottm-card_nw">
                          <div className="col-md-12 cou_cardsearch cart-card">
                            <div className="inner-contentcar">
                              {m.product_type === "videos" ? (
                                ""
                              ) : course.lessons > 1 ? (
                                <p>
                                  {course.lessons}
                                  <span>&nbsp;Module{course.lesssons > 1 ? 's' : ''}</span>
                                </p>
                              ) : (
                                <p>
                                  {course.lessons}
                                  <span>&nbsp;Module</span>
                                </p>
                              )}
                              {/* {_.toNumber(m.product_regular_price) !== _.toNumber(m.product_sale_price) ? <div className=" shop-cost">
                                                                <p>Retail price:</p>
                                                                <p> ${m.product_regular_price}&nbsp;CAD</p>
                                                            </div> : ""} */}
                              {/* <p className="price-order">$29.99</p> */}
                            </div>
                            {/* <p><span>{m.course_lessons}</span>Module(s)</p> */}
                            <div className="card-info">
                              <div className="card-sec">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    id="clock-time-four"
                                    d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                    transform="translate(-2 -2)"
                                    fill="var(--color-element-1)"
                                  />
                                </svg>
                                {course.product_type === "videos" ? (
                                  <div className="cart-timer">
                                    <p>{m.course_duration}</p>
                                    {/* <p>{m.durationUnit}</p> */}
                                  </div>
                                ) : (
                                  <div className="cart-timer">
                                    <p><b>{course.duration}</b></p>
                                    {/* <p>{course.durationUnit}</p> */}
                                  </div>
                                )}
                              </div>
                              <div className="card-sec">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    id="eye-circle"
                                    d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                    transform="translate(-2 -2)"
                                    fill="var(--color-element-1)"
                                  />
                                </svg>
                                <p>
                                  {course.views}<span>&nbsp;View{course.views > 1 ? 's' : ''}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
            <div className="position-absolute course-length-bar">
              <span>{m.course_list.length}</span>&nbsp;
              item{(m.course_list.length > 1 ? 's' : '')}
            </div>
          </div>
        </div>
        <div className="cart-body mt-3">
          {/* <h2><sup>$</sup>{m.product_sale_price.split('.')[0]}<sup>{m.product_sale_price.split('.')[1]}</sup><span>CAD</span></h2> */}
          <h2>
            ${m.selling_price.toString().split(".")[0]}
            {<sup>{m.selling_price.toString().split(".")[1]}</sup>}
            <span> CAD</span>
          </h2>
          <button
            className="btn-carts cart-btn-primary"
            onClick={() => {
              handleShowAddToCart(m.product_id);
            }}
          >
            {" "}
            Add to Cart{" "}
          </button>
        </div>
        <AlertDeleteSave
          deleteSave={() => {
            delSaveLaterItem(productId);
          }}
          handleClose={handleCloseDelete}
          show={showdeletecartmodal}
        />
        <AlertAddtoCart
          AddCart={() => {
            addToCartFunction(productId);
          }}
          handleClose={handleCloseAdd}
          show={showaddmodal}
        />
      </div>
    );
  };

  // SINGLE CARD
  const renderSingleCard = (m, index) => {
    return (
      <div className="my_carts" key={index}>
        <div className="cart-wishox">
          <div className="cartCarousel position-relative">
            <svg id="Close" onClick={() => handleshowDelItem(m.product_id)} className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              width="30" height="30" viewBox="0 0 30 30">
              <defs>
                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#00b7f1" />
                  <stop offset="1" stop-color="#ed156e" />
                </linearGradient>
              </defs>
              <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
              <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
              <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
              </g>
              <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
              </g>
            </svg>
            {/* <img
              className="img-fluid closebtn-pfl"
              src="/images/cross.png"
              onClick={() => handleshowDelItem(m.product_id)}
            /> */}
            {/* <img className="img-fluid galleryImg_lern cart_Card cursor-pointer closebtn-pfl" style={{}} onClick={() => handleshowDelItem(m.product_id)}
                        src="/images/cross.png" /> */}
            <div className="cart-head">
              <div className="responsive-carousel">
                <div className="col-md-12 mb-4 broker-box calendar_event">
                  <div className="img-box_nw">
                    {/* <img className="img-fluid galleryImg_lern" onClick={() => delSaveLaterItem(m.product_id) }
                                            src="/images/delete-icon.PNG" /> */}

                    <div className="img-videosec learn_image-card cross_Iconn">
                      {/* <img
                        className="img-fluid galleryImg_lern"
                        src={m.product_thumbnail}
                        onError={({currentTarget}) => {
                          currentTarget.onerror = true;
                          currentTarget.src = "/images/default-demo-img.jpg"
                      }}
                      /> */}
                      <ImageComponent
                        name={m.product_name}
                        thumbnail={m.product_thumbnail ? m.product_thumbnail : ""}
                        cssClass={"img-fluid galleryImg_lern"}
                        errorClass={"img-fluid galleryImg_lern"}
                      />
                      <div className="progress custom_progress_barr custom_bartwoRealtor">
                        <div className="progress-bar "></div>
                      </div>
                    </div>
                    <div className="details-card_nw">
                      <h5>
                        <a
                          className="course-head-al_search"
                          dangerouslySetInnerHTML={{
                            __html: m.product_name,
                          }}
                        >
                        </a>
                      </h5>
                      <div className="row info-bottm-card_nw">
                        <div className="col-md-12 cou_cardsearch cart-card">
                          {m.product_type === "videos" ? (
                            ""
                          ) : m.course_lessons > 1 ? (
                            <p>
                              <span>{m.course_lessons}</span>Modules
                            </p>
                          ) : (
                            <p>
                              <span>{m.course_lessons}</span>Module
                            </p>
                          )}
                          {/* <p><span>{m.course_lessons}</span>Module(s)</p> */}
                          <div className="card-info">
                            <div className="card-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  id="clock-time-four"
                                  d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                  transform="translate(-2 -2)"
                                  fill="var(--color-element-1)"
                                />
                              </svg>
                              <p>
                                {m.course_duration}
                                {/*<span>&nbsp;{m.course_period}</span>*/}
                              </p>
                            </div>
                            <div className="card-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  id="eye-circle"
                                  d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                  transform="translate(-2 -2)"
                                  fill="var(--color-element-1)"
                                />
                              </svg>
                              {m.product_type === "videos" ? (
                                <p>
                                  {m.views}
                                  <span>&nbsp;View{m.views > 1 ? 's' : ''}</span>
                                </p>
                              ) : (
                                <p>
                                  {m.views}<span>&nbsp;View{m.views > 1 ? 's' : ''}</span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cart-body mt-3">
                    {/* <h2><sup>$</sup>{m.product_sale_price.split('.')[0]}<sup>{m.product_sale_price.split('.')[1]}</sup><span>CAD</span></h2> */}
                    <h2>
                      ${m.selling_price.toString().split(".")[0]}
                      {<sup>{m.selling_price.toString().split(".")[1]}</sup>}
                      <span> CAD</span>
                    </h2>
                    <button
                      className="btn-carts cart-btn-primary"
                      onClick={() => {
                        handleShowAddToCart(m.product_id);
                      }}
                    >
                      {" "}
                      Add to Cart{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AlertDeleteSave
            deleteSave={() => {
              delSaveLaterItem(productId);
            }}
            handleClose={handleCloseDelete}
            show={showdeletecartmodal}
          />
          <AlertAddtoCart
            AddCart={() => {
              addToCartFunction(productId);
            }}
            handleClose={handleCloseAdd}
            show={showaddmodal}
          />
        </div>
      </div>
    );
  };
  //THUMBNAIL
  const renderThumbnail = (m) => {
    return (
      <div className="image-cart-session">
        {/* <img
          src={m.product_thumbnail}
          className="img-fluid galleryImg_lern"
          alt=""
          onError={({currentTarget}) => {
            currentTarget.onerror = true;
            currentTarget.src = "/images/default-demo-img.jpg"
        }}
        /> */}
        <ImageComponent
          name={m.product_name}
          thumbnail={m.product_thumbnail ? m.product_thumbnail : ""}
          cssClass={"img-fluid galleryImg_lern"}
          errorClass={"img-fluid galleryImg_lern"}
        />
      </div>
    );
  };
  //SESSION CARD
  const renderSessionCard = (m, index) => {
    return (
      <div className="boxsliderspsv">
        <div className="borker-box">
          <div className="my_carts" key={index}>
            <div className="cart-wishox">
              <div className="cartCarousel position-relative">
                <svg id="Close" onClick={() => handleshowDelItem(m.product_id)} className="closebtn-pfl" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="30" height="30" viewBox="0 0 30 30">
                  <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#00b7f1" />
                      <stop offset="1" stop-color="#ed156e" />
                    </linearGradient>
                  </defs>
                  <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                  <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                  <g id="Group_1344" data-name="Group 1344" transform="translate(10.697 10.783) rotate(45)">
                    <line id="Line_51" data-name="Line 51" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                    <line id="Line_275" data-name="Line 275" x1="12.172" transform="translate(0 0)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                  </g>
                  <g id="Group_2376" data-name="Group 2376" transform="translate(10.404 18.975) rotate(-45)">
                    <line id="Line_51-2" data-name="Line 51" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                    <line id="Line_275-2" data-name="Line 275" x1="12" transform="translate(0 0.414)" fill="none" stroke="var(--color-element-1)" stroke-linecap="round" stroke-width="4" />
                  </g>
                </svg>
                {/* <img
                  className="img-fluid closebtn-pfl"
                  src="/images/cross.png"
                  onClick={() => handleshowDelItem(m.product_id)}
                /> */}

                <div className="cart-head">
                  <div className="responsive-carousel">
                    <div className="col-md-12 mb-4 broker-box calendar_event">
                      <div className="img-box_nw">
                        <div className="img-videosec learn_image-card cross_Iconn">
                          {renderThumbnail(m)}
                        </div>
                        <div className="details-card_nw">
                          <h5>
                            <a
                              className="course-head-al_search"

                            >
                              {m.product_name}
                            </a>
                          </h5>
                          <div className="row info-bottm-card_nw">
                            <div className="col-md-12 cou_cardsearch cart-card">
                              <p>
                                <span>{m.course_lessons}</span>Modules
                              </p>
                              {/* <p><span>{m.course_lessons}</span>Module(s)</p> */}
                              <div className="card-info">
                                <div className="card-sec">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      id="eye-circle"
                                      d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                      transform="translate(-2 -2)"
                                      fill="var(--color-element-1)"
                                    />
                                  </svg>
                                  <p>
                                    0<span>&nbsp;View(s)</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cart-body mt-3">
                        <h2>
                          ${m.selling_price.toString().split(".")[0]}
                          {
                            <sup>
                              {m.selling_price.toString().split(".")[1]}
                            </sup>
                          }
                          <span>CAD</span>
                        </h2>
                        <button className="btn-carts cart-btn-primary">
                          {" "}
                          Add to Cart{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // CART CARD
  const renderCartCard = (m, index) => {
    return (
      <div className="boxsliderspsv">
        <div className="borker-box" key={index}>
          {!_.isEmpty(m)
            ? m.is_bundle
              ? renderBundleCard(m)
              : renderSingleCard(m)
            : renderSingleCard(m)}
        </div>
      </div>
    );
  };

  return (
    <>
      {savedData?.length > 0 ? (
        <div>
          <div id="myOnboard" className="center_line catsap"></div>
          <section className="wishlist-boxd">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="learn-head-learning">
                    <p className="cart-title">Saved for later</p>
                  </div>
                </div>
              </div>
              <div className="slider-course-listing">
                <Slider {...settings}>
                  {savedData?.length > 0 ? (
                    savedData.map((m, index) => {
                      return (
                        <>
                          {m.product_type === "course" &&
                            renderCartCard(m, index)}
                          {m.product_type === "videos" &&
                            renderCartCard(m, index)}
                          {m.product_type === "session" &&
                            renderSessionCard(m, index)}
                        </>
                      );
                    })
                  ) : (
                    <p> </p>
                  )}
                </Slider>
              </div>
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {cartIdArr!==null&&<RecommendProductCard
            courseId={""}
            courseIdArr={cartIdArr}
            from={"cart"} />}
    </>
  );
};
export default CartWishlistCard;
