import _ from "lodash";
import { useEffect, useState } from "react";
import { Carousel, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getSelectedCatId,
  showBuyModal,
  getAssignCourse,
  getAssignCourseClear,
  getAssignBrokerCourse,
  getAssignBrokerCourseClear,
  setMarkView, setMarkViewClear,
  getMyLearningPaths
} from "../../redux/courses/actions";
import {
  recommendationInsertViewData,
  recommendationInsertViewResponse,
  recommendationInsertViewClear,
} from "../../redux/recommendationView/actions";
import { courseDetailResponse } from "../../redux/product/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import BuyProductModal from "../modal/BuyProductModal";
import ConfirmModal from "../modal/ConfirmModal";
import Slider from "react-slick/";
import moment from "moment";
import ImageComponent from "../Layouts/ImageComponent";
import ProviderComponent from "../Layouts/ProviderComponent";
import { markModuleProgress } from "../../redux/courses/actions";
import HeaderNewMenu from '../../components/HeaderNewMenu';
import FooterNew from "../../components/FooterNew";
import ShimmerEventCard from "../Layouts/ShimmerEventCard";
import { getAPI } from "../../api/generic";
import SingleCompletedPopup from "../FeedbackPopups/singleCompletedPopup";
import FeedbackSucessPopup from "../FeedbackPopups/FeedbackSuccessPopup";


const LearningPathCardBody = (props) => {


  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const selectedCategory = useSelector(
    (state) => state.courses.selectedCategory
  );
  const [allLearningPathList, setAllLearningPathList] = useState([]);
  const assignCourse = useSelector((state) => state.courses.assignCourse);
  const assignBrokerCourse = useSelector((state) => state.courses.assignBrokerCourse);
  const markView = useSelector((state) => state.courses.markView);
  const [courseData, setCourseData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [showModalMsg] = useState(

    "Are you sure you want to set this course as favorite?"
  );
  const [videoData, steVideoData] = useState({})
  const recommendationInsertViewData1 = useSelector(
    (state) => state.recommendationView.recommendationInsertViewData
  );
  const [myLearningPathsData,setMyLearningPathsData] = useState([]);
  const [recommendInsertView, setInsertRecommendView] = useState({});
  const [name, setName] = useState("");
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(0);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [showReminderPopupStep2, setShowReminderPopupStep2] = useState(false);

  const redirectToPage = (learningId) => {
    history.push('/learning-path-section?id=' + learningId)
  }

  useEffect(() => {
    getProgramDetails();
  },[]);

  const getProgramDetails = async () => {
    setIsLoading(true);
    const res = await getAPI({
      module: "getAllProgramDetails",
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
    });
    if (res.status == "success") {
      setMyLearningPathsData(res?.payload);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!_.isEmpty(myLearningPathsData)) {
      if (!_.isEmpty(myLearningPathsData)) {
        const learning = myLearningPathsData;
        setAllLearningPathList(learning);
      }
    }
  }, [myLearningPathsData]);

  const renderCourseCard = (course, i) => {
    return (
      <>
        <div
          className="col-md-6 col-lg-4 col-xl-4 col-12 mb-4 lp_course_width"
          key={"course-" + i}
        >
          <div id={course?.id} className="img-box_nw ">
            <div
              className={`img-videosec learn_image-card assesmeent_tile ${course.isLock
                ? "cursor-not-allowed"
                : "cursor-pointer"
                }`}
              onClick={() => {
                redirectToPage(course.id);
              }}
            >

              <>
                <ImageComponent
                  name={course.prgm_nm}
                  thumbnail={course.thumbnail ? course.thumbnail : ''}
                  cssClass={`img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""
                    }`}
                  errorClass={`img-fluid galleryImg_lern ${course.isLock ? "grayscale" : ""
                    }`}
                />
                {course.prgm_progress !== undefined &&
                  course.prgm_progress != null &&
                  course.prgm_progress > 0 ? (
                  <a href="#">
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#premium-contentpopu"
                    >
                      <span className="lear_icon">
                        <img
                          src={
                            course.prgm_progress >= 100
                              ? "/images/check.png"
                              : "/images/forward.png"
                          }
                        />
                      </span>
                    </span>
                  </a>
                ) : (
                  ""
                )}

              </>
              <ProgressBar
                className="custom_progress_barr custom_bartwoRealtor"
                variant="success"
                now={course.prgm_progress ? course.prgm_progress : 0}
              />


            </div>




            <div className="lp_details-card_nw">
              <h5
                className={`${course.isLock
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
                onClick={() => {
                  redirectToPage(course.id);
                }}
              >
                <a
                  className="lp_course-head-al_search"
                  title={course.prgm_nm}
                  dangerouslySetInnerHTML={{
                    __html: course.prgm_nm,
                  }}
                ></a>
              </h5>

              <div className="row info-bottm-card_nw">
                <div className="col-md-12 cou_cardIcon assesmennt_card">
                  {
                    course.level_entity_type !== "assessment" &&
                    <div className="fgf">
                      {
                        (course.course_count != "") &&
                        <p>
                          <span>{course.course_count} </span>
                          {course.course_count == 1 ? "Course" : "Courses"}
                        </p>
                      }
                       {(course?.lessons != "" &&  course?.lessons != null) &&
                      <p>
                        <span>{course.lessons} </span>
                        {course.lessons == 1 ? "Module" : "Modules"}
                      </p>
                    }



                    </div>

                  }



                  <div className="carouseL_Iconno2 d-flex flex-column">

                    <div className="ico__imG01 d-flex course_view_height">
                      {
                        (course?.program_hrs > 0 || course?.program_mins > 0) &&
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            id="clock-time-four"
                            d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                            transform="translate(-2 -2)"
                            fill="var(--color-element-1)"
                          />
                        </svg>
                      }

                      <p>
                        {" "}

                        <span>     {course?.program_hrs > 0
                          ? course?.program_hrs + " " + (course?.program_hrs > 1 ? "Hours" : "Hour")
                          : " "}{" "}
                          {course?.program_mins > 0
                            ? course?.program_mins +
                            " " +
                            (course?.program_mins > 1 ? "Mins" : "Min")
                            : " "}{" "}</span>
                      </p>
                    </div>




                  </div>
                </div>


              </div>

              <div className="row infoIcon_nw">
                <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                  {/* {course?.provider?.provider_img !== "" ? (
                    <img
                      src={course?.provider?.provider_img}
                      alt=""
                      title={course?.provider?.provider_display_name}
                    />
                  ) : (
                    <div className="d-flex carouseL_IconnBY">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Exclusion_3"
                          data-name="Exclusion 3"
                          d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                          transform="translate(-21473.998 951.002)"
                          fill="var(--color-element-1)"
                          opacity="1"
                        ></path>
                      </svg>
                      <p>
                        {" "}
                        <span
                          className="sub_txt_lern"
                          title={`${
                            course?.provider?.provider_display_name ===
                            "A Lady's Touch Inc"
                              ? `${course?.provider?.provider_display_name}.`
                              : course?.provider?.provider_display_name
                          }`}
                        >
                          {course?.provider?.provider_display_name?.length > 11
                            ? course?.provider?.provider_display_name?.substring(
                                0,
                                11
                              ) + "..."
                            : course?.provider?.provider_display_name}
                        </span>
                      </p>
                    </div>
                  )} */}
                  <ProviderComponent providerData={course.provider} />


                  {course.source !== "ecom" ||
                    course.is_paid ||
                    !course.showBuy ? (
                    <div
                      className={`d-flex justify-content-between revisitIconcon comp4530 ${course.isLock
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                        }`}
                      onClick={() => {
                        redirectToPage(course.id);
                      }}
                    >
                      <img
                        src={
                          course.prgm_progress === "" ||
                            course.prgm_progress === undefined ||
                            course.prgm_progress == null ||
                            course.prgm_progress === 0
                            ? "/images/Component 46-78.svg"
                            : course.prgm_progress >= 100
                              ? "/images/Component 4427.svg"
                              : "/images/Component 4530.svg"
                        }
                        width="80"
                        height="17"
                        alt=""
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>



      </>
    );
  };

  const handleFeedback = (course) => {
    fetchfeedbackQuestions();
    setName(course?.name);
    setCourseId(course?.id);
    setShowFeedbackPopup(true);
  };

  const fetchfeedbackQuestions = () => {
    (async () => {
      const res = await getAPI({
        module: "getFeedbackQuestions",
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        type: "Course",
      });
      if (res.status === "success" && res?.payload) {
        setFeedbackQuestions(res?.payload);
      }
    })();
  };

  function handleSubmitReminderFirstPopup() {
    setShowReminderPopupStep2(true);
    setTimeout(() => {
      setShowReminderPopupStep2(false);
    }, 5500);
  }



  return (
    <div className="page-wrapper-rsp">
      <div className="rspinnerwrapper header_margin">
        <HeaderNewMenu
          showMenu={true}
        />
      </div>
      <SingleCompletedPopup
        name={name || ""}
        setShow={setShowFeedbackPopup}
        show={showFeedbackPopup}
        userName={authBroker?.payload?.user?.firstname || ""}
        showReminderPopupStep2={showReminderPopupStep2}
        setShowReminderPopupStep={() => handleSubmitReminderFirstPopup()}
        questions={feedbackQuestions}
        courseId={courseId}
        lastModule={[]}
        // type={type}
        // sub_category_id={moduleId}
      />
      <FeedbackSucessPopup
        submitFeedbackSuccess={showReminderPopupStep2}
        setSubmitFeedbackSuccess={() => setShowReminderPopupStep2(false)}
      />


      <div className="search_heading mt-5 container newlp_path">
              {/* <img src="images/learning_img.png" width="109" height="106" alt="" /> */}
              <p className="lable-heading_search">Programs</p>
            </div>

      <div className="container">

        <div className="col-md-12 profile-left-sec">
          <div className="row">
{
                  !isLoading ?
                  allLearningPathList.length == 0 ? <div className="not-found mt-3">
                      <div className=" interboxes">
                        <img src="/images/telescope.svg" alt="Not Found" />
                        <p>Sorry! No Programs found.</p>
                      </div>
                    </div> :
                         allLearningPathList.map((course, i) => {

                          return renderCourseCard(course, i);
                        })
                    :
                    [1, 2, 3, 4].map(m => {
                      return (
                        <ShimmerEventCard />
                      )
                    })
                }

              
          </div>
        </div>
      </div>

      <FooterNew />
    </div>
  )

};

export default LearningPathCardBody;
