import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clickStreamUser } from "../../utils/AppUtils";
import _ from "lodash"
import CONFIG from "../../utils/config";

const RolePlayHeadingSection = (props) => {

    const history = useHistory();

    const authBroker = useSelector(state => state.auth.authBroker)

    const redirectToRecord = () => {
        clickStreamUser('participate-roleplay-tab-clicked', {
            user_id: authBroker?.payload.user?.user_id
        })
        props.isMobile ?
            history.push(`/role-play-mobile`) :
            history.push('/role-play')
    }

    return (
        <>
            <section className="newsarticle roleplay-page pt-6">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="events_banner roplbnpag">
                                <p className="label-heading_events mb-4 rphnain">Role play</p>
                                {props.page != 'participate' ? <button className="btn-primary reviewbtn rpsubmitbtn" onClick={redirectToRecord}>Select a new role play to participate</button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="certificate" className="certify-profile roleplaysap center_line mt-3"></div>
        </>
    )   
}

export default RolePlayHeadingSection;