import types from './types'
// Add To Cart
export const myCart = (payload) => ({  type: types.MY_CART, payload})
export const myCartResponse = (payload) => ({type: types.MY_CART_RESPONSE, payload})  
export const myCartClear = () => ({ type: types.MY_CART_CLEAR })
// Delete My Cart items
export const myCartDelete = (payload) => ({type: types.MY_CART_DELETE, payload})
export const myCartDeleteResponse=(payload)=>({type: types.MY_CART_DELETE_RESPONSE, payload})
export const myCartDeleteClear=()=>({type:types.MY_CART_DELETE_CLEAR})
  
// Save later my cart products
export const myCartSaveLater = (payload) => ({type: types.SAVE_LATER, payload})
export const myCartSaveLaterResponse=(payload)=>({type: types.SAVE_LATER_RESPONSE, payload})
export const myCartSaveLaterClear=()=>({type:types.SAVE_LATER_CLEAR})

// Save later my cart products view
export const myCartSaveLaterView = (payload) => ({type: types.SAVE_LATER_VIEW, payload})
export const myCartSaveLaterViewResponse=(payload)=>({type: types.SAVE_LATER_VIEW_RESPONSE, payload})
export const myCartSaveLaterViewClear=()=>({type:types.SAVE_LATER_VIEW_CLEAR})
// Delelte Save later view
export const delSaveLaterView = (payload) => ({type: types.DEL_SAVE_LATER, payload})
export const delSaveLaterViewResponse=(payload)=>({type: types.DEL_SAVE_LATER_RESPONSE, payload})
export const delSaveLaterViewClear=()=>({type:types.DEL_SAVE_LATER_CLEAR})
// Learning Points
export const learningPoints = (payload) => ({type:types.LEARNING_POINTS, payload})
export const learningPointsResponse=(payload)=>({type:types.LEARNING_POINTS_RESPONSE, payload})
export const learningPointsClear=()=>({type:types.LEARNING_POINTS_CLEAR})


// Credit Points
export const creditPoints = (payload) => ({type:types.CREDIT_POINTS, payload})
export const creditPointsResponse=(payload)=>({type:types.CREDIT_POINTS_RESPONSE, payload})
export const creditPointsClear=()=>({type:types.CREDIT_POINTS_CLEAR})

//Dynamic banner
export const dynamicBanner = (payload) => ({type:types.DYNAMIC_BANNER, payload})
export const dynamicBannerResponse=(payload)=>({type:types.DYNAMIC_BANNER_RESPONSE, payload})
export const dynamicBannerClear=()=>({type:types.DYNAMIC_BANNER_CLEAR})

//Dynamic banner from Broker
export const dynamicBannerBroker = (payload) => ({type:types.DYNAMIC_BANNER_BROKER, payload})
export const dynamicBannerBrokerResponse=(payload)=>({type:types.DYNAMIC_BANNER_BROKER_RESPONSE, payload})
export const dynamicBannerBrokerClear=()=>({type:types.DYNAMIC_BANNER_BROKER_CLEAR})

//Dynamic Coupon
export const couponCodeApply = (payload) => ({type:types.COUPON_CODE_APPLY, payload})
export const couponCodeApplyRes=(payload)=>({type:types.COUPON_CODE_APPLY_RES, payload})
export const couponCodeApplyClear=()=>({type:types.COUPON_CODE_APPLY_CLEAR})