import Modal from 'react-bootstrap/Modal';
import SelectOption from "../AssignModal/SelectOption";
import ChooseTeammate from "../AssignModal/ChooseTeammate";
import ChooseTeams from "../AssignModal/ChooseTeams";
import CreateTeam from "../AssignModal/CreateTeam";
import CreateteamOptions from "../AssignModal/CreateteamOptions";
import CoursesAssign from "../AssignModal/CoursesAssign";
import ChoosePeople from "../AssignModal/ChoosePeople";
import SelectpeopleOptions from "../AssignModal/SelectpeopleOptions";
import GenerateaReport from "../AssignModal/GenerateaReport";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ChooseFromCompletedCourse from '../AssignModal/ChooseFromCompletedCourse';
import Loader from '../Loader/Loader';
import GenerateComplianceReport from '../AssignModal/GenerateComplianceReport';
import ChooseREA from '../reportFilter/ChooseREA';
import ChooseOfficePeople from '../reportFilter/ChooseOfficePeople';
import ChooseManager from '../reportFilter/ChooseManager';
import ChooseCourse from '../reportFilter/ChooseCourse';
import ChooseLp from '../reportFilter/ChooseLP';
import ChooseProvider from '../reportFilter/ChooseProvider';
const ReportFilterModel = (props) => {

  const { 
    show, setShow, 
    heading, showCompliance, 
    routeLink, currentReportObj,
    isMobile 
  } = props;

  const currentFilterView = useSelector((state) => state.report.currentFilterView);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTeamMatesList, setSelectedTeamMatesList] = useState([]);
  const [selectedOfficeList, setSelectedOfficeList] = useState([]);
  const [selectedCourseList, setSelectedCourseList] = useState([]);
  const [selectedManagerCoachList, setSelectedManagerCoachList] = useState([]);
  const [selectedProviderList, setSelectedProviderList] = useState([]);
  const [selectedLpList, setSelectedLpList] = useState([]);

  const [selectedCompletedCourseList, setSelectedCompletedCourseList] = useState([]);
  const [filteredFromCoursesOffices, setFilteredFromCoursesOffices] = useState([]);
  const [selectedTeamList, setSelectedTeamsList] = useState([]);
  const [filterReq, setFilterReq] = useState([]);

  useEffect(() => {
    setSelectedTeamMatesList([]);
    setSelectedOfficeList([]);
    setSelectedCompletedCourseList([]);
    setFilteredFromCoursesOffices([])
    setSelectedTeamsList([])
    setSelectedLpList([])

  }, [show])

  return (
    <>
      <Loader start={isLoading} />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        dialogClassName="modal-90w modalassign"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFilterView === 1 && 
          (<GenerateaReport currentReportObj={currentReportObj} routeLink={routeLink} 
            heading={heading} selectedTeamMatesList={selectedTeamMatesList} 
            selectedOfficeList={selectedOfficeList} selectedCourseList={selectedCourseList} 
            selectedManagerCoachList={selectedManagerCoachList} selectedProviderList={selectedProviderList} 
            setIsLoading={setIsLoading} setShow={setShow}
            selectedLpList={selectedLpList} isMobile={isMobile}
          />)}

          {(currentReportObj.id ===2 || currentReportObj.id===4) ?
            currentFilterView === 2 && 
            <ChooseLp parentId={''} parentName={'course'} selectedLpList={selectedLpList}
              setSelectedLpList={setSelectedLpList} currentReportObj={currentReportObj} 
              isMobile={isMobile} />
            :
            currentFilterView === 2 && 
            <ChooseCourse parentId={''} parentName={'course'} selectedCourseList={selectedCourseList}
              setSelectedCourseList={setSelectedCourseList} currentReportObj={currentReportObj} isMobile={isMobile} />
          }
          {currentFilterView === 3 && 
          <ChooseOfficePeople selectedOfficeList={selectedOfficeList} 
          setSelectedOfficeList={setSelectedOfficeList} isMobile={isMobile} />}

          {currentFilterView === 4 && 
          <ChooseREA parentId={''} 
            parentName={'course'} selectedTeamMatesList={selectedTeamMatesList} 
            setSelectedTeamMatesList={setSelectedTeamMatesList} 
            isMobile={isMobile} />}

          {currentFilterView === 5 && 
          <ChooseManager parentId={''} 
            parentName={'course'} selectedManagerCoachList={selectedManagerCoachList} 
            setSelectedManagerCoachList={setSelectedManagerCoachList} 
            isMobile={isMobile} />}

          {currentFilterView === 6 && 
          <ChooseProvider parentId={''} 
            parentName={'course'} selectedProviderList={selectedProviderList} 
            setSelectedProviderList={setSelectedProviderList} 
            isMobile={isMobile} />}

        </Modal.Body>
      </Modal>
    </>
  )
}
export default ReportFilterModel;