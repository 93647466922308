/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import Chart from "react-apexcharts"

let themeColors = ["#F77E0B", "#5E94CE", '#45A735', "#0D988C", "#76187E"]

const BarChart = (props) => {
  
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    colors: themeColors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: true
    },
    legend: {
      show: true
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 350
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    xaxis: {
      categories: props.legends ? props.legends : [],
      labels: {
        style: {
          colors: themeColors,
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      show: true,
      showAlways: true,
      seriesName: 'Candian Dollars',
      labels: {
        show: true
      },
      title: {
        text: props.showDollar ? '$ (CAD)' : '(count)'
      },
      formatter: function (val) {
        return val.toFixed(0)
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return props.showDollar ? "$ " + val + " CAD" : ""
        }
      }
    }
  })
  const [series, setSeries] = useState(props.series ? props.series : [])

  useEffect(() => {
    setSeries([{
      data: series
    }])
    setOptions({
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors: themeColors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      xaxis: {
        categories: props.legends,
        labels: {
          style: {
            colors: themeColors,
            fontSize: '12px'
          }
        }
      }
    })
    // console.log(props)
  }, [props])

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height={500}
      width={650}
    />
  )
}

export default BarChart
