import { useEffect, useState, useMemo } from "react";
import { SessionKeys } from "../../../utils/AppUtils";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "../../../utils/config";
import {
  getCoeOffices,
  getCoeOfficesResClear,
} from "../../../redux/reports/actions";
import _ from "lodash";

import { useHistory } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import Loader from "../../../components/Loader/Loader";
import { Spinner } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const OfficeSectionCoe = ({ isMobile }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [overCrsGraphData, setOverCrsGraphData] = useState([]);
  const [crsCompltTrendValues, setCrsCompltTrendValues] = useState([]);
  const [crsCompleTrendKeys, setCrsCompleTrendKeys] = useState([])
  const [overAllData, setOverAllData] = useState([]);
  const [realEstateAgent, setRealEstateAgent] = useState([]);
  const [overAllOfficesData, setOverAllOfficesData] = useState([]);
  // const [overAllManagersData, setOverAllManagersData] = useState([]);
  // const [overAllLearningPoints, setOverAllLearningPoints] = useState([]);
  // const [overAllLearningHours, setOverAllLearningHours] = useState([]);
  // const [OverAllStatsData, setOverAllStatsData] = useState([]);
  const [loadSpinner, setLoadSpinner] = useState(true)
  const [isView, setIsView] = useState("table");
  const [type, setType] = useState("pie");
  const [canShow, setCanShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [limitData, setLimitData] = useState();
  const [imgLoader, setImgLoader] = useState(true);
  const [reaList, setReaList] = useState([])
  const [limitStatus, setLimitStatus] = useState("")
  const [loadSpin, setLoadSpin] = useState(true)
  const [profileReports, setProfileReports] = useState([]);
  const [totalRea, setTotalRea] = useState([])
  const [totalOffices, setTotalOffices] = useState([])
  const profileReportRes = useSelector((state) => state.report?.profileReport);
  const anyTotalReportRes = useSelector(
    (state) => state.report?.anyTotalReport
  );
  const coeReaSectRes = useSelector(
    (state) => state.report?.coeReportRea
  );
  const coeManagerSectRes = useSelector(
    (state) => state.report?.coeReportManager
  );
  const coeOfficesSectRes = useSelector(
    (state) => state.report?.coeReportOffices
  );
  const coeTrendCurveRes = useSelector(
    (state) => state.report?.coeTrend
  );
  // const crsCompletionTrendRes = useSelector(
  //   (state) => state.report.complianceCompletionTrend
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);
  const [counter, setCounter] = useState(1);
  const [actualCoursesList, setActualCoursesList] = useState([]);
  const [totalCountRea, setTotalCountRea] = useState("")



  // console.log("Selected REA", selectedRea);
  const rea_ids = []
  const office_ids = []
  const [selectedOfficeList] = useState(
    history?.location?.state?.selectedOfficeList
  );
  const [selectedRea] = useState(
    history?.location?.state?.selectedTeamMatesList
  );



  useEffect(() => {
    //   array.forEach(function(obj){
    //     a.push(obj.user_id);
    // })
    selectedOfficeList.selectedOfficeList.forEach(function (obj) {
      office_ids.push(obj.office_id);
    })
    selectedRea.selectedTeamMatesList.forEach(function (obj) {
      rea_ids.push(obj.user_id);
    })

  })



  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }




  // COE OFFICES SECTION API DISPATCH
  useEffect(() => {
    dispatch(
      getCoeOffices({
        module: "getCoeReportOffices",
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          office_ids: office_ids,
          limit: counter,
          rea_ids: rea_ids,
        },
      })
    );
  }, [dispatch, counter]);
  useEffect(() => {
    if (!_.isEmpty(coeOfficesSectRes)) {
      setLoadSpinner(false)
      if (coeOfficesSectRes?.status === "success") {
        let allData = coeOfficesSectRes?.payload?.payload?.offices;
        setTotalOffices(coeOfficesSectRes?.payload?.payload)
        let temparr = _.cloneDeep(overAllOfficesData)
        if (counter === 1) {
          temparr = []
        }
        setOverAllOfficesData([...temparr, ...allData]);
        dispatch(getCoeOfficesResClear())
      }
    }
  }, [coeOfficesSectRes])
  useEffect(() => {
    setCounter(1)
    setOverAllOfficesData([])
    return (() => {
      dispatch(getCoeOfficesResClear())
    })
  }, [])
  return (
    <section className="offices-rpeort progress-box-report">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="progess-count-tiel">
              Offices &nbsp;<strong>({totalOffices?.total_office})</strong>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {(!_.isEmpty(overAllOfficesData)) ? overAllOfficesData?.map((i, index) => {
            return (
              <>
                <div className="col-md-6 col-lg-4 col-xl-3 col-12 mb-report" key={index}>
                  <div className="reopor-box-progess">
                    <div className="progess-profile d-flex">
                      <div className="userprogress-details">
                        <h5 className="user-tiele">{i?.office_name}</h5>
                        <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <div
                                            style={{
                                                color: "white",
                                                backgroundColor: "black",
                                            }}
                                            
                                        >{i?.office_address}</div>
                                    </Tooltip>
                                }
                            >
                        <p >{i?.office_address}</p>
                        </OverlayTrigger>
                        {/* <p>{i?.office_address}</p> */}
                      </div>
                    </div>
                    <hr />
                    <div className="progress-details">
                      <div className="progess-report">
                        <div className="circlebox">
                          <CircularProgressbar value={i?.percentage} />
                        </div>
                        <div className="progess-text-repfot">
                          {`${i?.percentage}%`}
                          <div className="progespte">Progress</div>
                        </div>
                      </div>
                      {/* <div className="progess-count-show">
                        <div className="learning-points">
                          <h5>Learning Points</h5>
                          <div className="learningc-countshow">
                            {i?.learning_points}
                          </div>
                        </div>
                        <div className="learning-points ">
                          <h5>Learning Hours</h5>
                          <div className="learningc-countshow">
                            {(Math.floor(i?.learning_hrs)) > 0 ? `${Math.floor(i?.learning_hrs)}` : ""}&nbsp;
                            {Math.floor(i?.learning_hrs) > 1 ? "Hrs" : Math.floor(i?.learning_hrs) > 0 ? "Hr" : ""}{" "}
                            &nbsp;
                            {(Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60)) > 0 ?
                              Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60) : ""}&nbsp;
                            {(Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60)) > 1 ? "Mins" : (Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60)) > 0 ? "Min" : ""}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </>
            );
          }) :loadSpinner && counter === 1 ?
          <div className="not-found mt-3">
            <div className="interboxes">
              <Spinner animation="border" />
            </div>
          </div>
          :
            <div className="col-md-12">
              <div className="not-found mt-3">
                <div className="interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p>The offices of Managers and REA's of above section will be displayed in this section.</p>
                </div>
              </div>
            </div>
          }
          <div className="col-md-12 mt-3">
            <p className="text-center">
              {overAllOfficesData?.length === 0 || (totalOffices?.total_office === overAllOfficesData?.length || (totalOffices?.total_office < 4)) ? "" :
                <button
                  type="button"
                  id="number"
                  className="btn btn-primary-complete"
                  onClick={() => {
                    setCount(counter, setCounter);
                    setLoadSpinner(true);
                  }
                  }
                >
                  {(loadSpinner) ?
                    <div className="d-flex justify-content-center w-100">
                      <Spinner animation="border" />
                    </div>
                    : "Load More"}
                </button>
              }
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default OfficeSectionCoe;