import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProfileFilterSearchSection from "./ProfileFilterSearchSection";
import ProfileTableSeaction from "./ProfileTableSeaction";
import ProfileTitleSection from "./ProfileTitleSection";
import { getAnyTotalReport, getProfileCompletionReport } from '../../../redux/reports/actions'
import { SessionKeys } from '../../../utils/AppUtils'
import Loader from "../../Loader/Loader";
import ReportViewFilterSection from "../ReportViewFilterSection";
import CONFIG from "../../../utils/config"

const ProfileReportHomeSection = (props) => {

    const {
        setIsLoading,
        isLoading
    } = props;

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [clickCompleted, setClickCompleted] = useState(true)
    const [clickNotCompleted, setClickNotCompleted] = useState(false)
    const [isView, setIsView] = useState('table')
    const [type, setType] = useState('pie')
    const [statusFilterData, setStatusFilterData] = useState('table')
    const [canShow, setCanShow] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [officeIds, setOfficeIds] = useState([])

    useEffect(() => {
        dispatch(getAnyTotalReport({
            module: 'getProfileReport',  
            postData: {
                date_range: {},
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                office_ids: []
            }
        }))
    }, [dispatch])

    const getFilteredData = (action) => {
        let dateRange = {
            start: startDate?.toLocaleDateString("en-US"),
            end: endDate?.toLocaleDateString("en-US")
        }
        let profileStatus = (clickCompleted && clickNotCompleted) || (!clickCompleted && !clickNotCompleted) ? null : clickCompleted ? "Completed" : "Inprogress";
        let status = []
        if(clickCompleted){
            status.push("Completed")
        }
        if(clickNotCompleted){
            status.push("Inprogress")
        }

        let req = {
            date_range: dateRange,
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
        };
        if (profileStatus !== null) {
            req.profile_status = profileStatus;
        } 
        if(searchInput!==''){
            req.search=searchInput;
        }
        req.office_ids = officeIds
        setStatusFilterData(status)
        setIsLoading(true);
        dispatch(getProfileCompletionReport({
            module: 'getProfileReport',
            postData: req
        }))
        dispatch(getAnyTotalReport({
            module: 'getProfileReport',  
            postData: {
                date_range: dateRange,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                office_ids: officeIds
            }
        }))
    }

    return (
        <>
            <ProfileTitleSection />
            <ProfileFilterSearchSection
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                clickCompleted={clickCompleted}
                setClickCompleted={setClickCompleted}
                clickNotCompleted={clickNotCompleted}
                setClickNotCompleted={setClickNotCompleted}
                getFilteredData={getFilteredData}
                isView={isView}
                setIsView={setIsView}
                setOfficeIds={setOfficeIds}
            />
            {(canShow) ? <ReportViewFilterSection 
                isView={isView}
                setIsView={setIsView}
                type={type}
                setType={setType}
            /> : ''}
            <ProfileTableSeaction
                setSearchInput={setSearchInput}
                getFilteredData={getFilteredData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isView={isView}
                statusFilterData={statusFilterData}
                setCanShow={setCanShow}
                type={type}
            />
        </>
    )
}

export default ProfileReportHomeSection;