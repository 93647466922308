import types from './types'

const initialState = {
    allCertified: {},
    certifyDetail: {},
    sessionList: {},
    allCertifiedBroker: {},
    certifyDetailBroker: {},
    sessionListBroker: {},
    enrollBrokerSession: {},
    calendarViewSessions: {},
    getCertificates: {},
    getBadges:{},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_CERTIFIED_NIIT_RES:
            // console.log("GET_ALL_CERTIFIED_NIIT_RES", action.payload)
            return {
                ...state,
                allCertified: action.payload
            }
        case types.GET_ALL_CERTIFIED_NIIT_CLEAR:
            return {
                ...state,
                allCertified: {}
            }
        case types.GET_CERTIFIED_DETAILS_NIIT_RES:
            return {
                ...state,
                certifyDetail: action.payload
            }
        case types.GET_CERTIFIED_DETAILS_NIIT_CLEAR:
            return {
                ...state,
                certifyDetail: {}
            }
        case types.CERTIFIED_SESSION_LIST_NIIT_RES:
            return {
                ...state,
                sessionList: action.payload
            }
        case types.CERTIFIED_SESSION_LIST_NIIT_CLEAR:
            return {
                ...state,
                sessionList: {}
            }
        case types.GET_ALL_CERTIFIED_BROKER_RES:
            // console.log("GET_ALL_CERTIFIED_BROKER_RES", action.payload)
            return {
                ...state,
                allCertifiedBroker: action.payload
            }
        case types.GET_ALL_CERTIFIED_BROKER_CLEAR:
            return {
                ...state,
                allCertifiedBroker: {}
            }
        case types.GET_CERTIFIED_DETAILS_BROKER_RES:
            return {
                ...state,
                certifyDetailBroker: action.payload
            }
        case types.GET_CERTIFIED_DETAILS_BROKER_CLEAR:
            return {
                ...state,
                certifyDetailBroker: {}
            }
        case types.CERTIFIED_SESSION_LIST_BROKER_RES:
            return {
                ...state,
                sessionListBroker: action.payload
            }
        case types.CERTIFIED_SESSION_LIST_BROKER_CLEAR:
            return {
                ...state,
                sessionListBroker: {}
            }
        case types.CERTIFIED_ENROLL_BROKER_SESSION_RES:
            return {
                ...state,
                enrollBrokerSession: action.payload
            }
        case types.CERTIFIED_ENROLL_BROKER_SESSION_CLEAR:
            return {
                ...state,
                enrollBrokerSession: {}
            }
        case types.CERTICICATE_GET_BROKER_RES:
            return {
                ...state,
                getCertificates: action.payload
            }
        case types.CERTICICATE_GET_BROKER_CLEAR:
            return {
                ...state,
                getCertificates: {}
            }
        case types.BADGES_GET_BROKER_RES:
            return {
                ...state,
                getBadges: action.payload
            }
        case types.BADGES_GET_BROKER_CLEAR:
            return {
                ...state,
                getBadges: {}
            }
        default:
            return state
    }
}