import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AssignCourseModal from "../../components/modal/AssignCourseModal";
import { loginBrokerResponse } from "../../redux/auth/actions";
import { setUserRole, userDetailBroker } from "../../redux/user/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import CONFIG from "../../utils/config";
import $ from "jquery";
import { setCurrentView } from "../../redux/assignCourse/actions";


const AssignContentPage = () => {

    const dispatch = useDispatch()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');
    const user_id = params.get('user_id');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const role = params.get('role');

    const parentId = params.get('parentId');
    const parentName = params.get('parentName');
    const source = params.get('source');


    const [isLoading, setIsLoading] = useState(true)
    const [isValidKey, setIsValidKey] = useState(false)
    const [show, setShow] = useState(true);

    useEffect(() => {
        console.log('key', params.get('key'))
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
            $('html').css("--color-element-1", color_1 ? '#' + color_1 : CONFIG.COLOR_ELEMENT_1);
            $('html').css("--color-element-2", color_2 ? '#' + color_2 : CONFIG.COLOR_ELEMENT_2);
            localStorage.setItem(SessionKeys.API_TOKEN_BROKER, token);
            localStorage.setItem(SessionKeys.USERNAME, username);
            setIsValidKey(true)
            dispatch(setUserRole(role))
            dispatch(loginBrokerResponse({
                payload: {
                    user: {
                        user_id: user_id,
                        username: username,
                        role: [role]
                    },
                    apiToken: token
                }
            }))

            dispatch(
                userDetailBroker({
                    module: "getOrderDetailBroker",
                    apiToken: token,
                })
            );
            clickStreamUser('roleplay_mobile_page', {
                userId: user_id,
            })
        }
    }, [token, key, user_id, dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
                document.getElementById("cc_div").style.display = "none";
        }, 100)
        dispatch(setCurrentView(1))
    }, [])

    return (
        <>
            {isValidKey ?
                <>
                    <AssignCourseModal
                        show={show}
                        parentName={parentName}
                        parentId={parentId}
                        setShow={setShow} origin={source}
                        isMobile={true}
                    />
                </>
                :
                <div>
                    Invalid Key
                </div>
            }
        </>
    )



}

export default AssignContentPage;