import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CourseList from "../components/Course/CourseList";
import Page from "../components/Page";
import ProductCheckoutSection from "../components/Product/ProductCheckoutSection";
import ProductDetailCard from "../components/Product/ProductDetailCard";
import ProductDetailSection from "../components/Product/ProductDetailSection";
import TitleBannerSection from "../components/TitleBannerSection";
import { product } from "../redux/product/actions";
import { checkoutSummary as checkout } from '../redux/checkout/actions'
import { SessionKeys } from "../utils/AppUtils";


const ProductPage = () => {

    const {slug} = useParams();
    const dispatch = useDispatch();

    const productDetailData = useSelector(state => state.product.productDetail);
    const recommendProductsData = useSelector(state => state.product.recommendProducts);
    const checkoutSummary = useSelector(state => state.checkout.checkoutSummary);

    const [productInfo, setProductInfo] = useState({});
    const [productAnalytics, setProductAnalytics] = useState({});
    const [checkoutData, setCheckoutData] = useState({});
    const [canAddToCart, setCanAddToCart] = useState(false);

    useEffect(() => {
        // console.log(slug)
        // dispatch(product({
        //     module: 'getCourseDetails',
        //     token: localStorage.getItem(SessionKeys.API_TOKEN),
        //     course_id: slug
        // }))
        dispatch(checkout({}))
    }, [dispatch, slug])

    useEffect(() => {
        // console.log('PRODUCT DETAIL', productDetailData)
        if (productDetailData?.status === 'success') {
            // console.log('fetched successfully', productDetailData.payload.productDetail)
            setProductInfo(productDetailData.payload.productDetail.productInfo)
            setProductAnalytics(productDetailData.payload.productDetail.productAnalytics)
            setCanAddToCart(productDetailData.payload.productDetail.canAddToCart)
        }
    }, [productDetailData])

    useEffect(() => {
        if (checkoutSummary.status === 'success') {
            // console.log('fetched checkout successfully')
            setCheckoutData(checkoutSummary.payload)
        }
    }, [checkoutSummary])

    return (
        <Page>
            <TitleBannerSection 
                title="Products"
                classes="productsbanner"
            />
            <section className="certificat-container">
                <div className="row">
                    <div className="col-md-4 mb-4 cursor-pointer">
                        <ProductDetailCard 
                            productInfo={productInfo}
                            productAnalytics={productAnalytics}
                            canAddToCart={canAddToCart}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <ProductDetailSection 
                            productInfo={productInfo}
                            productAnalytics={productAnalytics}
                        />
                    </div>
                    <div className="col-md-4 product_tb_bg">
                        <ProductCheckoutSection 
                            checkoutData={checkoutData}
                            productPrice={productInfo?.price}
                            policy={productInfo?.policy}
                        />
                    </div>
                </div>
            </section>
        </Page>
    )

}
export default ProductPage;