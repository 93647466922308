const types = {
  // user courses
  USER_COURSES: "USER_COURSES",
  USER_COURSES_RESPONSE: "USER_COURSES_RESPONSE",
  USER_COURSES_CLEAR: "USER_COURSES_CLEAR",

  // user courses
  IN_PROGRESS_BROKER: "IN_PROGRESS_BROKER",
  IN_PROGRESS_BROKER_RESPONSE: "IN_PROGRESS_BROKER_RESPONSE",
  IN_PROGRESS_BROKER_CLEAR: "IN_PROGRESS_BROKER_CLEAR",

  // my courses
  MY_COURSES: "MY_COURSES",
  MY_COURSES_RESPONSE: "MY_COURSES_RESPONSE",
  MY_COURSES_CLEAR: "MY_COURSES_CLEAR",

  // my learning path
  MY_LEARNING_PATHS: "MY_LEARNING_PATHS",
  MY_LEARNING_PATHS_RESPONSE: "MY_LEARNING_PATHS_RESPONSE",
  MY_LEARNING_PATHS_CLEAR: "MY_LEARNING_PATHS_CLEAR",

  LEARNING_PATHS_UNASSIGNED: "LEARNING_PATHS_UNASSIGNED",
  LEARNING_PATHS_UNASSIGNED_RESPONSE: "LEARNING_PATHS_UNASSIGNED_RESPONSE",
  LEARNING_PATHS_UNASSIGNED_CLEAR: "LEARNING_PATHS_UNASSIGNED_CLEAR",

  // SINGLE LP
  SINGLE_LP_DATA: "SINGLE_LP_DATA",
  SINGLE_LP_DATA_RESPONSE: "SINGLE_LP_DATA_RESPONSE",
  SINGLE_LP_DATA_CLEAR: "SINGLE_LP_DATA_CLEAR",


  // courses categories
  COURSE_CATEGORIES: "COURSE_CATEGORIES",
  COURSE_CATEGORIES_RESPONSE: "COURSE_CATEGORIES_RESPONSE",
  COURSE_CATEGORIES_CLEAR: "COURSE_CATEGORIES_CLEAR",

  // product categories
  PRODUCT_CATEGORIES: "PRODUCT_CATEGORIES",
  PRODUCT_CATEGORIES_RESPONSE: "PRODUCT_CATEGORIES_RESPONSE",
  PRODUCT_CATEGORIES_CLEAR: "PRODUCT_CATEGORIES_CLEAR",

  // product  categories
  PRODUCT_CATEGORIES_DATA: "PRODUCT_CATEGORIES_DATA",
  PRODUCT_CATEGORIES_DATA_RESPONSE: "PRODUCT_CATEGORIES_DATA_RESPONSE",
  PRODUCT_CATEGORIES_DATA_CLEAR: "PRODUCT_CATEGORIES_DATA_CLEAR",

  PRODUCT_SUB_CATEGORIES_DATA: "PRODUCT_SUB_CATEGORIES_DATA",
  PRODUCT_SUB_CATEGORIES_DATA_RESPONSE: "PRODUCT_SUB_CATEGORIES_DATA_RESPONSE",
  PRODUCT_SUB_CATEGORIES_DATA_CLEAR: "PRODUCT_SUB_CATEGORIES_DATA_CLEAR",

  //selected category
  SELECTED_CATEGORY: "SELECTED_CATEGORY",

  // courses listing
  COURSES_LISTING: "COURSES_LISTING",
  COURSES_LISTING_RESPONSE: "COURSES_LISTING_RESPONSE",
  COURSES_LISTING_CLEAR: "COURSE_CATEGORIES_CLEAR",

  // Modules listing
  MODULE_LISTING: "MODULE_LISTING",
  MODULE_LISTING_RESPONSE: "MODULE_LISTING_RESPONSE",
  MODULE_LISTING_CLEAR: "MODULE_CATEGORIES_CLEAR",
  // ModuleNotesGet 
  MODULE_NOTES_GET: "MODULE_NOTES_GET",
  MODULE_NOTES_GET_RESPONSE: "MODULE_NOTES_GET_RESPONSE",
  MODULE_NOTES_GET_CLEAR: "MODULE_NOTES_GET_CLEAR",
  // ModuleNotesUpdate 
  MODULE_NOTES_UPDATE: "MODULE_NOTES_UPDATE",
  MODULE_NOTES_UPDATE_RESPONSE: "MODULE_NOTES_UPDATE_RESPONSE",
  MODULE_NOTES_UPDATE_CLEAR: "MODULE_NOTES_UPDATE_CLEAR",
  // ModuleNotesDelete 
  MODULE_NOTES_DELETE: "MODULE_NOTES_DELETE",
  MODULE_NOTES_DELETE_RESPONSE: "MODULE_NOTES_DELETE_RESPONSE",
  MODULE_NOTES_DELETE_CLEAR: "MODULE_NOTES_DELETE_CLEAR",
  // ModuleFavouritesGet 
  MODULE_FAV_GET: "MODULE_FAV_GET",
  MODULE_FAV_GET_RESPONSE: "MODULE_FAV_GET_RESPONSE",
  MODULE_FAV_GET_CLEAR: "MODULE_FAV_GET_CLEAR",

  // ModuleFavouritesDelete 
  MODULE_FAV_DELETE: "MODULE_FAV_DELETE",
  MODULE_FAV_DELETE_RESPONSE: "MODULE_FAV_DELETE_RESPONSE",
  MODULE_FAV_DELETE_CLEAR: "MODULE_FAV_DELETE_CLEAR",

  // favorite courses
  FAVORITE_COURSES: "FAVORITE_COURSES",
  FAVORITE_COURSES_RESPONSE: "FAVORITE_COURSES_RESPONSE",
  FAVORITE_COURSES_CLEAR: "FAVORITE_COURSES_CLEAR",

  // set favorite courses
  SET_FAVORITE_COURSES: "SET_FAVORITE_COURSES",
  SET_FAVORITE_COURSES_RESPONSE: "SET_FAVORITE_COURSES_RESPONSE",
  SET_FAVORITE_COURSES_CLEAR: "SET_FAVORITE_COURSES_CLEAR",

  // remove favorite courses
  REMOVE_FAVORITE_COURSES: "REMOVE_FAVORITE_COURSES",
  REMOVE_FAVORITE_COURSES_RESPONSE: "REMOVE_FAVORITE_COURSES_RESPONSE",
  REMOVE_FAVORITE_COURSES_CLEAR: "REMOVE_FAVORITE_COURSES_CLEAR",

  //show Buy Modal
  SHOW_BUY_MODAL: "SHOW_BUY_MODAL",

  // learning path
  LEARNING_PATH: "LEARNING_PATH",
  LEARNING_PATH_RESPONSE: "LEARNING_PATH_RESPONSE",
  LEARNING_PATH_CLEAR: "LEARNING_PATH_CLEAR",

  // learning path broker
  LEARNING_PATH_BROKER: "LEARNING_PATH_BROKER",
  LEARNING_PATH_BROKER_RESPONSE: "LEARNING_PATH_BROKER_RESPONSE",
  LEARNING_PATH_BROKER_CLEAR: "LEARNING_PATH_BROKER_CLEAR",

  OPEN_HAMBURGER: "OPEN_HAMBURGER",
  OPEN_HAMBURGER_CLEAR: "OPEN_HAMBURGER_CLEAR",



  // courses listing from WOO commerse
  COURSES_LISTING_WOO: "COURSES_LISTING_WOO",
  COURSES_LISTING_RESPONSE_WOO: "COURSES_LISTING_RESPONSE_WOO",
  COURSES_LISTING_CLEAR_WOO: "COURSE_CATEGORIES_CLEAR_WOO",


  // courses listing from Broker admin
  COURSES_CATEGORIES_BROKER: "COURSES_CATEGORIES_BROKER",
  COURSES_CATEGORIES_RESPONSE_BROKER: "COURSES_CATEGORIES_RESPONSE_BROKER",
  COURSES_CATEGORIES_CLEAR_BROKER: "COURSE_CATEGORIES_CLEAR_BROKER",

  // courses listing from Broker admin
  COURSES_LISTING_BROKER: "COURSES_LISTING_BROKER",
  COURSES_LISTING_RESPONSE_BROKER: "COURSES_LISTING_RESPONSE_BROKER",
  COURSES_LISTING_CLEAR_BROKER: "COURSE_CATEGORIES_CLEAR_BROKER",


  // courses listing from Broker admin
  MODULES_LISTING_BROKER: "MODULES_LISTING_BROKER",
  MODULES_LISTING_RESPONSE_BROKER: "MODULES_LISTING_RESPONSE_BROKER",
  MODULES_LISTING_CLEAR_BROKER: "MODULES_CATEGORIES_CLEAR_BROKER",


  ASSIGN_COURSE: "ASSIGN_COURSE",
  ASSIGN_COURSE_RESPONSE: "ASSIGN_COURSE_RESPONSE",
  ASSIGN_COURSE_CLEAR: "ASSIGN_COURSE_CLEAR",

  ASSIGN_COURSE_BROKER: "ASSIGN_COURSE_BROKER",
  ASSIGN_COURSE_BROKER_RESPONSE: "ASSIGN_COURSE_BROKER_RESPONSE",
  ASSIGN_COURSE_BROKER_CLEAR: "ASSIGN_COURSE_BROKER_CLEAR",

  MARK_VIEW: "MARK_VIEW",
  MARK_VIEW_RESPONSE: "MARK_VIEW_RESPONSE",
  MARK_VIEW_CLEAR: "MARK_VIEW_CLEAR",

  // courses code of Ethics

  MASTER_CODE_OF_ETHICS: "MASTER_CODE_OF_ETHICS",
  MASTER_CODE_OF_ETHICS_RESPONSE: "MASTER_CODE_OF_ETHICS_RESPONSE",
  MASTER_CODE_OF_ETHICS_CLEAR: "MASTER_CODE_OF_ETHICS_CLEAR",

  // Course Ethics Agree Check

  ETHICS_AGREE_CHECK: "ETHICS_AGREE_CHECK",
  ETHICS_AGREE_CHECK_RESPONSE: "ETHICS_AGREE_CHECK_RESPONSE",
  ETHICS_AGREE_CHECK_CLEAR: "ETHICS_AGREE_CHECK_CLEAR",


  MARK_MODULE_PROGRESS: "MARK_MODULE_PROGRESS",
  MARK_MODULE_PROGRESS_RESPONSE: "MARK_MODULE_PROGRESS_RESPONSE",
  MARK_MODULE_PROGRESS_CLEAR: "MARK_MODULE_PROGRESS_CLEAR",

  VIDEO_PROGRESS: "VIDEO_PROGRESS",
  VIDEO_PROGRESS_RESPONSE: "VIDEO_PROGRESS_RESPONSE",
  VIDEO_PROGRESS_RESPONSE_CLEAR: "VIDEO_PROGRESS_RESPONSE_CLEAR",

  LEARNING_PATH_LISTING_BROKER: "LEARNING_PATH_LISTING_BROKER",
  LEARNING_PATH_RESPONSE_BROKER: "LEARNING_PATH_RESPONSE_BROKER",
  LEARNING_PATH_CLEAR_BROKER: "LEARNING_PATH_CLEAR_BROKER",

  GET_LEVEL_DETAIL_REQ: "GET_LEVEL_DETAIL_REQ",
  GET_LEVEL_DETAIL_RESPONSE: "GET_LEVEL_DETAIL_RESPONSE",
  GET_LEVEL_DETAIL_RESPONSE_CLEAR: "GET_LEVEL_DETAIL_RESPONSE_CLEAR",
  //

  CHECKLIST_INSERT: "CHECKLIST_INSERT",
  CHECKLIST_INSERT_RESPONSE: "CHECKLIST_INSERT_RESPONSE",
  CHECKLIST_INSERT_RESPONSE_CLEAR: "CHECKLIST_INSERT_RESPONSE_CLEAR",

  // RELATED COURSE BY CAT ID
  COURSE_RELATED: "COURSE_RELATED",
  COURSE_RELATED_RESPONSE: "COURSE_RELATED_RESPONSE",
  COURSE_RELATED_CLEAR: "COURSE_RELATED_CLEAR",
}

export default types;