import React from "react";

import Page from "../../components/Page";
import VideoViewPageSection from "./VideoViewPageSection";


const VideoViewPage = () => {
  
  return (
    <>
      <Page>
        <VideoViewPageSection 
          isMobile={false}
        />
      </Page>
    </>
  );
};

export default VideoViewPage;
