import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { useHistory as history, useHistory } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import RenderButtonForCalendar from "../../Home/RenderButtonForCalender";
import moment from "moment";
import { boardcastDataResponse, calendarLiveEventSaved, 
  cancelSessionDataClear, getCalendarSessionList, 
  getCalendarSessionListClear, getEnrollBrokerSession, 
  getEnrollBrokerSessionClear, getLiveCalendarSessionList, 
  getLiveCalendarSessionListClear, 
  hostSession, hostSessionClear, joinSession, joinSessionClear, 
  sessionWatchURLDataClear, sessionWatchURLDataResponse } from "../../../redux/events/actions";
import { clickStreamUser, SessionKeys, timezoneArea, timezoneTagName } from "../../../utils/AppUtils";
import { homePageMsg, homePageShowMsg } from "../../../redux/home/actions";
import { fetchTimezoneAbbr, fetchTimezoneAbbrClear } from "../../../redux/user/actions";
import { addToCartClear } from "../../../redux/product/actions";


const LiveButtonDropDown = (props) => {
  const { activeSessionList } = props
  const history = useHistory();
  const dispatch = useDispatch();
  const calendarsessionListRes = useSelector(state => state.events.calendarViewSessions);
  const calenderSessionListArray = useSelector(state => state.events.liveCalendarSessionList);
  const authBroker = useSelector(state => state.auth.authBroker);
  const joinSessionData = useSelector(state => state.events.joinSession);
  const addToCartRes = useSelector(state => state.product.addToCart)
  const hostSessionInfo = useSelector(state => state.events.hostSession);
  const enrollBrokerSession = useSelector((state) => state.events.enrollBrokerSession);
  const attendence = useSelector(state => state.events.sessionAttendenceMarked)
  const sessionCancelledData = useSelector(state => state.events.sessionCancel)
  const showHomePageMsg = useSelector(state => state.home.showHomePageMsg)
  const isPremiumUser = useSelector(state => state.user.isPremiumUser)
  const userDetailRes = useSelector(state => state.user.userBroker)
  const userRole = useSelector(state => state.user.role)
  const timezoneAbbr = useSelector(state => state.user.timezoneAbbr)

  const [sessionCalList, setSessionCalList] = useState({});
  const [fetchData, setFetchData] = useState(false);
  const [myEvents, setMyEvents] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [showModalMsg,] = useState('Would you like to unregister from the session?')
  const [showLoader, setShowLoader] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [zoneAbbr, setZoneAbbr] = useState('CDT')
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [sessionDataForModal, setSessionDataForModal] = useState({})
  useEffect(() => {
    // console.log('userDetailRes: ', userDetailRes)
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
      setUserDetails(userDetailRes?.payload?.payload)
      dispatch(fetchTimezoneAbbr({
        module: 'fetchTimezoneAbbr',
        postData: {
          offset: userDetailRes.payload.payload.timezone
        }
      }))
    }
  }, [userDetailRes])

  useEffect(() => {
    if (!_.isEmpty(timezoneAbbr)) {
      if (timezoneAbbr.status === 'success') {
        // console.log('timezoneAbbr', timezoneAbbr)
        const timezoneAbbrData = timezoneAbbr.payload?.payload
        if (!_.isEmpty(timezoneAbbrData)) {
          const abbr = timezoneAbbrData?.abbr[0]?.abbreviations
          setZoneAbbr(abbr)
        }
      }
      dispatch(fetchTimezoneAbbrClear())
    }
  }, [timezoneAbbr])

  useEffect(() => {
    if (!confirmModal) {
      setShowLoader(false)
    }
  }, [confirmModal])

  const range = (start, end) => {
    var arr = []
    for (var i = start; i <= end; i++)arr.push(i)
    return arr
  }

  const formatEventsToWeekArray = (sessionsList) => {
    var weekArr = range(0, 6).map(() => [])
    if (sessionsList.length > 0) {
      for (let session of sessionsList) {
        let api_date_format = "YYYY-MM-DD HH:mm"
        const userTz = moment.tz.guess();
        // console.log('SessionData', session, !_.isEmpty(session.date_time) && _.isArray(session.date_time))
        if (!_.isEmpty(session.date_time) && _.isArray(session.date_time)) {
          for (let event of session.date_time) {
            // const startDateTime = moment.utc(event.start_date.replaceAll('-', '/') + ' ' + event.start_time);
            // const endDateTime = moment.utc(event.end_date.replaceAll('-', '/') + ' ' + event.end_time);
            const startDateTime = moment(event.start_date + ' ' + event.start_time, 'MM-DD-YYYY HH:mm');
            const endDateTime = moment(event.end_date + ' ' + event.end_time, 'MM-DD-YYYY HH:mm');
            const weekDay = startDateTime.day()
            const currentDateTime = moment();
            const tense = getTense(_.toInteger(moment.duration(startDateTime.diff(currentDateTime)).asDays()));
            // console.log('weekday', weekDay, session.session_name, event.start_date)
            if (!isNaN(weekDay)) {
              let data = {
                title: session.session_name,
                tense: tense,
                duration: _.toInteger(moment.duration(endDateTime.diff(startDateTime)).asMinutes()) + 'mins',
                tenseDays: _.toInteger(moment.duration(startDateTime.diff(currentDateTime)).asDays()),
                meetingId: session.channel_name === 'zoom' ? event.meeting_id : '',
                start: startDateTime,
                end: endDateTime,
                watchUrl: event.watch_url,
                timezone: moment.tz(userTz).format('z'),
                segmentId: event.segment_id,
                channel: session.channel_name,
                author: session.instructor,
              }
              // console.log('week data', data)
              weekArr[weekDay].push(data)
            }
          }
        }
      }
    }
    return weekArr
  }

  const getTense = (diff) => {
    if (diff > 0) {
      return "future"
    } else if (diff < 0) {
      return "past"
    } else {
      return "present"
    }
  }

  const getTenseDiff = (value) => {
    if (value > 1) {
      return 1;
    } else if (value <= 0) {
      return -1;
    } else {
      return 0;
    }
  }

  // const events = formatEventsToWeekArray([sessionCalList.payload.sessionList])
  const [events, setEvents] = useState([])
  const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf("week"))

  const formatWeekHeader = (weekStart) => {
    return weekStart.format("YYYY, MMM DD - ") + (weekStart.clone().add(1, "week").subtract(1, "days").format("MMM  DD"))
  }

  const [weekHeader, setWeekHeader] = useState(formatWeekHeader(currentWeekStart))

  useEffect(() => {
    setShowLoader(!showHomePageMsg)
  }, [showHomePageMsg])

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(sessionWatchURLDataClear())
      setFetchData(false)

      let date_range = {
        start_date: currentWeekStart.format("MM/DD/YYYY"),
        end_date: currentWeekStart.clone().endOf('week').format("MM/DD/YYYY")
      }
      // console.log('FETCH DATA', date_range)
      // return;
      dispatch(getLiveCalendarSessionList({
        broker: {
          module: 'calendarSessionListBroker',
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
            date_range
          }
        }
      }))
    }
  }, [dispatch, authBroker])

  useEffect(() => {
    // console.log('Calendar List Array', calenderSessionListArray)
    if (!_.isEmpty(calenderSessionListArray)) {
      setFetchData(true)
      let events = range(0, 6).map(() => [])
      for (let calendarData of calenderSessionListArray) {
        if (calendarData.status === 'success') {
          const sessionData = _.clone(calendarData.payload.payload);
          // console.log('sessionData Calendar List Array', sessionData)
          if (_.isArray(sessionData) && !_.isEmpty(sessionData)) {
            let api_date_format = "YYYY-MM-DD HH:mm"
            const userTz = moment.tz.guess();
            const timezoneOffset = !_.isEmpty(userDetails.timezone) ? userDetails.timezone.replace(':', '') : '-0500'
            const area = timezoneArea(timezoneOffset)
            for (let session of sessionData) {
              const actualTimezoneOffset = session.timezone.includes(':') ? session.timezone.replace(":", "") : "-0500";
              const actualArea = timezoneArea(actualTimezoneOffset)
              // console.log('event session Calendar List Array', session)
              if (_.isArray(session.date_time)) {
                let startDate = session?.first_start_date_time?.split(' ')[0]
                let startTime = session?.first_start_date_time?.split(' ')[1]
                let endDate = session?.last_end_date_time?.split(' ')[0]
                let endTime = session?.last_end_date_time?.split(' ')[1]
                let sessionStartDate = moment.utc(
                  startDate?.replaceAll("-", "/") +
                  " " +
                  startTime
                  // ).utcOffset(actualTimezoneOffset);
                ).tz(actualArea);
                let sessionEndDate = moment.utc(
                  endDate?.replaceAll("-", "/") +
                  " " +
                  endTime
                  // ).utcOffset(actualTimezoneOffset);
                ).tz(actualArea);
                for (let event_time of session.date_time) {
                  // const startDateTime = moment.utc(event_time?.start_date?.replaceAll('-', '/') + ' ' + event_time.start_time).utcOffset(timezoneOffset);
                  // const endDateTime = moment.utc(event_time?.end_date?.replaceAll('-', '/') + ' ' + event_time.end_time).utcOffset(timezoneOffset);
                  const startUTC = event_time?.start_date?.replaceAll('-', '/') + ' ' + event_time.start_time;
                  const endUTC = event_time?.end_date?.replaceAll('-', '/') + ' ' + event_time.end_time;
                  // console.log('START UTC', startUTC)
                  const startDateTime = moment.utc(startUTC).tz(area);
                  const endDateTime = moment.utc(event_time?.end_date?.replaceAll('-', '/') + ' ' + event_time.end_time).tz(area);
                  // const currentDateTime = moment();
                  // const currentDateTime = moment().utcOffset(timezoneOffset);
                  const currentDateTime = moment().tz(area);
                  const weekDay = startDateTime?.day()
                  const startTense = getTense(getTenseDiff(moment.duration(startDateTime?.diff(currentDateTime)).asDays()));
                  const endTense = getTense(getTenseDiff(moment.duration(endDateTime?.diff(currentDateTime)).asDays()));
                  const tense = startTense === 'past' && endTense === 'past' ? 'past' : endTense
                  // console.log('eventsData', session.session_name, event_time.is_host, tense, endDateTime, moment.duration(startDateTime.diff(currentDateTime)).asDays(), event_time)
                  if (tense != 'past' || (tense === 'past' && session.is_registered)) {
                    if (!isNaN(weekDay)) {
                      let data = {
                        startTense: startTense,
                        endTense: endTense,
                        startUTC: startUTC,
                        endUTC: endUTC,
                        start: moment(startDateTime?.format(api_date_format)).toDate(),
                        end: moment(endDateTime?.format(api_date_format)).toDate(),
                        durationValue: _.toInteger(moment.duration(endDateTime?.diff(startDateTime)).asMinutes()),
                        duration: _.toInteger(moment.duration(endDateTime?.diff(startDateTime)).asMinutes()) + 'mins',
                        tense: tense,
                        tenseDayDiff: getTenseDiff(moment.duration(startDateTime?.diff(currentDateTime)).asDays()),
                        // timezone: moment.tz(userTz).format('z'),
                        timezone: timezoneTagName(zoneAbbr),
                        actualTimezoneAbbr: sessionEndDate.format('z'), //timezoneTagName(session.timezoneAbbr),
                        title: session.session_name,
                        channel: session.channel_name,
                        author: session.instructor,
                        meetingId: event_time.meeting_id,
                        sessionId: session.session_id,
                        eventId: session.event_id,
                        userId: authBroker.payload.user.user_id,
                        id: events.length,
                        source: session.source === 'ecom' ? 'xsel' : 'broker',
                        is_addToCart: session.is_addtocart,
                        // is_registered: session.is_registered,
                        is_registered:true,
                        is_paid: (event_time.is_host || event_time.is_instructor) ? true : session.is_paid,
                        is_cancelled: session.is_cancelled,
                        is_expired: event_time.is_expired,
                        is_completed: event_time.is_completed,
                        is_live: event_time.is_live,
                        watch_url: event_time.watch_url,
                        selling_price: (event_time.is_host || event_time.is_instructor) ? 0 : session.product_sale_price,
                        productId: session.product_id,
                        segmentId: event_time.segment_id,
                        is_host: event_time.is_host,
                        is_boardcast: session.delivery_mode != 'virtual_interactive' ? true : false,
                        is_participant:true,
                        // is_participant: (event_time.is_instructor && !event_time.is_host) ? true : event_time.is_participant,
                        hostZoomId: authBroker?.payload.user.zoom_id,
                        start_url: event_time.start_url ? event_time.start_url : '',
                        join_url: event_time.join_url ? event_time.join_url : '',
                        current_time: currentDateTime?.format(api_date_format),
                        sessionStartDate: moment(
                          startDateTime.format(api_date_format)
                        ).toDate(),
                        sessionEndDate: moment(
                          sessionEndDate.format(api_date_format)
                        ).toDate(),
                        delivery_mode: session.delivery_mode,
                        location: session.location,
                        is_Zoom_integration:event_time.is_Zoom_integration
                      }
                      // console.log('Calendar List Array Individual', data)
                      events[weekDay].push(data)
                    }
                  }
                }
              }
            }
          }
        }
      }
      // console.log('events final data Calendar List Array', events)
      //   setMyEvents(events)
      dispatch(getLiveCalendarSessionListClear())
      if (_.flatten(events).length > 0) {
        const currentWeekEnd = currentWeekStart.clone().endOf('week');
        if (moment().isBetween(currentWeekStart, currentWeekEnd)) {
          const timezoneOffset = !_.isEmpty(userDetails.timezone) ?
            userDetails.timezone.replace(':', '') : '-0500'
          const area = timezoneArea(timezoneOffset)
          var today = moment().tz(area)
          var todayDay = today.day()
          dispatch(calendarLiveEventSaved(events[todayDay]))
        }
        // console.log(events)
        setEvents(events)
      } else {
        setEvents([])
      }
    }
  }, [calenderSessionListArray])

  useEffect(() => {
    if (!_.isEmpty(hostSessionInfo)) {
      if (hostSessionInfo.status === "success") {
        if (!_.isEmpty(hostSessionInfo.payload.payload)) {
          // console.log('hostSessionInfo', hostSessionInfo.payload.payload);
          const hostData = hostSessionInfo.payload.payload.data;
          setShowLoader(false)
          if (hostData.start_url) {
            // window.open(hostData.start_url, '_blank');
            dispatchAPILiveCall();
          } else {
            if (userRole === 'realtor') {
              dispatch(homePageMsg('Server Error, please contact your manager'))
            } else if (userRole === 'manager') {
              dispatch(homePageMsg('Server Error, please contact your admin'))
            } else {
              dispatch(homePageMsg('Server Error, please contact support'))
            }
          }
        } else {
          // console.log(hostSessionInfo.error.message)
          dispatch(homePageMsg(hostSessionInfo?.error ? hostSessionInfo?.error.message : "Not able to start the session."))
        }
      } else {
        dispatch(homePageMsg(hostSessionInfo?.error ? hostSessionInfo?.error.message : "Not able to start the session."))
      }
      dispatch(homePageShowMsg(true))
      setShowLoader(false)
      setTimeout(() => {
        dispatch(homePageShowMsg(false))
      }, 5000)
      dispatch(hostSessionClear())
    }
  }, [hostSessionInfo])

  useEffect(() => {
    // console.log('joinSessionData', joinSessionData);
    if (!_.isEmpty(joinSessionData)) {
      if (joinSessionData.status === 'success') {
        if (!_.isEmpty(joinSessionData.payload.payload)) {
          const sessionData = joinSessionData.payload.payload;
          setShowLoader(false)
          if (sessionData.join_url) {
            // window.open(sessionData.join_url, '_blank');
            dispatchAPILiveCall();
          } else {
            if (userRole === 'realtor') {
              dispatch(homePageMsg('Error joining session, please contact your manager'))
            } else if (userRole === 'manager') {
              dispatch(homePageMsg('Error joining session, please contact your admin'))
            } else {
              dispatch(homePageMsg('Error joining session, please contact support'))
            }
          }
        } else {
          dispatch(homePageMsg(joinSessionData?.error  ? joinSessionData?.error?.message : "Not able to join the session." ))
        }
      } else {
        // console.log(joinSessionData.error.message)
        dispatch(homePageMsg(joinSessionData?.error  ? joinSessionData?.error?.message : "Not able to join the session." ))
      }
      dispatch(homePageShowMsg(true))
      setShowLoader(false)
      setTimeout(() => {
        dispatch(homePageShowMsg(false))
      }, 5000)
      dispatch(joinSessionClear())
    }
  }, [joinSessionData])

  useEffect(() => {
    // console.log('sessionCalledData', sessionCancelledData)
    if (!_.isEmpty(sessionCancelledData)) {
      if (sessionCancelledData.status === 'success') {
        dispatchAPILiveCall();
        let msg = 'Xsel team will connect with you shortly.';
        if (sessionDataForModal.source === 'broker') {
          msg = 'You have successfully unregistered from the session.';
        }
        dispatch(homePageMsg(msg))
        clickStreamUser("session-unregistered-successfull", {
          session_id: sessionDataForModal.sessionId,
          user_id: authBroker?.payload?.user?.user_id,
          message: msg,
        });
        dispatchAPILiveCall();
      } else {
        dispatch(homePageMsg('Unable to unregister. Please try again later.'))
      }
      dispatch(homePageShowMsg(true))
      setShowLoader(false)
      setTimeout(() => {
        dispatch(homePageShowMsg(false))
      }, 5000)
      dispatch(cancelSessionDataClear())
    }
  }, [sessionCancelledData])

  useEffect(() => {
    // console.log("enrolled", enrollBrokerSession, currentWeekStart);
    if (!_.isEmpty(enrollBrokerSession)) {
      if (enrollBrokerSession?.status === "success") {
        let data = enrollBrokerSession.payload.payload;
        dispatch(homePageMsg(enrollBrokerSession.payload.message))
        clickStreamUser("session-registered-successfull", {
          // event_id: event_id,
          session_id: data.session_id,
          user_id: authBroker?.payload?.user?.user_id,
          message: enrollBrokerSession.payload.message,
        });
        dispatchAPILiveCall();
      } else if (enrollBrokerSession?.status === "error") {
        dispatch(homePageMsg(enrollBrokerSession.error.message))
      }
      dispatch(homePageShowMsg(true))
      setShowLoader(false)
      setTimeout(() => {
        dispatch(homePageShowMsg(false))
      }, 5000)
      dispatch(getEnrollBrokerSessionClear());
    }
  }, [enrollBrokerSession]);

  useEffect(() => {
    // setShowModalMsg(addToCartRes?.payload.message)
    if (Object.keys(addToCartRes).length !== 0) {
      history.push('/cart')
      dispatch(addToCartClear())
    }
  }, [addToCartRes])

  const changeWeek = (dir) => {

    var localCurrentWeekStart = currentWeekStart.clone()
    if (dir == "prev") {
      localCurrentWeekStart = currentWeekStart.clone().subtract(1, "week").startOf("week")
    }
    else {
      localCurrentWeekStart = currentWeekStart.clone().add(1, "week").startOf("week")
    }
    // localCurrentWeekStart = localCurrentWeekStart.add(1, "days")
    setWeekHeader(formatWeekHeader(localCurrentWeekStart))
    setCurrentWeekStart(localCurrentWeekStart)
    setEvents([])
    setFetchData(false)

    const date_range = {
      start_date: localCurrentWeekStart.format("MM/DD/YYYY"),
      end_date: localCurrentWeekStart.clone().endOf('week').format("MM/DD/YYYY")
    }
    // return;
    dispatch(getLiveCalendarSessionList({
      broker: {
        module: 'calendarSessionListBroker',
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          date_range
        }
      }
    }))
  }

  const changeWeekToPrev = () => { changeWeek("prev") }

  const changeWeekToNext = () => { changeWeek("next") }

  const weekClassName = (weekDay) => {
    // console.log('Week Current', currentWeekStart.toDate())
    if (!_.isEmpty(userDetails)) {
      // return "active-week-color"
      const timezoneOffset = !_.isEmpty(userDetails.timezone) ?
        userDetails.timezone.replace(':', '') : '-0500'
      const area = timezoneArea(timezoneOffset)
      var today = moment().tz(area)
      var currentWeekEnd = currentWeekStart.clone().add("week", 1)
      // console.log('WEEK CLASS CHECK', weekDay, today, currentWeekStart, currentWeekEnd)
      if (today?.isBetween(currentWeekStart, currentWeekEnd)) {
        var todayDay = today?.day()
        // console.log('WEEK CLASS CHECK', weekDay, todayDay)
        if (weekDay == todayDay) return "active-week"
        else if (weekDay > todayDay) return "future-week"
        else return "past-week"
      }
      else if (today > currentWeekStart) return "past-week"
      else if (today < currentWeekEnd) return "future-week"
    }
  }

  const weekBody = (weekDay) => {
    if (!_.isEmpty(userDetails)) {
      // console.log('Week', weekDay)
      const timezoneOffset = !_.isEmpty(userDetails.timezone) ?
        userDetails.timezone.replace(':', '') : '-0500'
      const area = timezoneArea(timezoneOffset)
      var today = moment().tz(area)
      var currentWeekEnd = currentWeekStart.clone().add("week", 1)
      if (today >= currentWeekStart && today <= currentWeekEnd) {
        var todayDay = today.day()
        if (weekDay == todayDay) return "active-block"
        else if (weekDay > todayDay) return "future-block"
        else return "past-block"
      }
      else if (today > currentWeekStart) return "past-block"
      else if (today < currentWeekEnd) return "future-block"
    }
  }

  const dispatchAPILiveCall = () => {
    return;
    let weekInput = currentWeekStart.clone();
    let date_range = {
      start_date: weekInput.startOf('week').format("MM/DD/YYYY"),
      end_date: weekInput.clone().endOf('week').format("MM/DD/YYYY")
    }
    // console.log('FETCH DATA', date_range)
    dispatch(getLiveCalendarSessionList({
      ecom: {
        module: 'calendarSessionListEcom',
        postData: {
          email: authBroker.payload.user.email,
          user_id: authBroker.payload.user.user_id,
          company_id: authBroker?.payload?.user.company_id,
          date_range
        }
      },
      broker: {
        module: 'calendarSessionListBroker',
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          date_range
        }
      }
    }))
  }

  //till after paste

  const watchSession = (session) => {
    // dispatch(homePageShowMsg(true))
    // dispatch(homePageMsg('You are registered for the Session.'))
    dispatch(sessionWatchURLDataResponse({
      id: session.sessionId,
      title: session.title,
      watch_url: session.watch_url
    }))
    history.push('/session/watch')
  }

  const cancelSessionConfirmation = (session) => {
    setConfirmModal(true)
    setSessionDataForModal(session)
    setShowLoader(true)
  }

  const showBoardcast = (session) => {
    // console.log('boardcast', session)
    dispatch(boardcastDataResponse(session))
    history.push('/session/broadcast/watch');
  }

  const launchSession = (session) => {
    clickStreamUser("launch-session", {
      event_id: session.eventId,
      session_id: session.sessionId,
      session_name: session.session_name,
      user_id: authBroker.payload.user.user_id,
    });
    if (session.is_boardcast) {
      showBoardcast(session)
    } else if(session.join_url){
			window.open(session.join_url, "_blank");
		}else {
      if (session.meetingId) {
        if (session.join_url) {
          window.open(session.join_url, '_blank')
        } else if(session.is_Zoom_integration === "1"){
          dispatch(
            joinSession({
              module: "joinZoomMeeting",
              postData: {
                user_id: authBroker.payload.user.user_id,
                meeting_id: session.meetingId,
                session_id: session.sessionId,
                segment_id: session.segmentId
              },
            })
          );
        }else{
          dispatch(homePageMsg('Host has not yet started the meeting.'))
          dispatch(homePageShowMsg(true))
          setShowLoader(false)
          setTimeout(() => {
            dispatch(homePageShowMsg(false))
          }, 5000)
        }
      } else {
        dispatch(homePageMsg('Host has not yet started the meeting.'))
        dispatch(homePageShowMsg(true))
        setShowLoader(false)
        setTimeout(() => {
          dispatch(homePageShowMsg(false))
        }, 5000)
      }
    }
  };

  const hostSessionData = (session) => {
    if (session.start_url) {
      window.open(session.start_url, '_blank');
      setShowLoader(false)
    } else if(session.is_Zoom_integration === "1") {
      dispatch(hostSession({
        module: 'createZoomMeeting',
        postData: {
          user_id: session.userId,
          host_zoom_id: session.hostZoomId,
          meeting_topic: session.title,
          meeting_agaenda: session.title,
          meeting_startdate: moment.utc(session.startUTC).format("YYYY-MM-DD HH:mm"),
          duration: _.toNumber(session.durationValue),
          session_id: session.sessionId,
          segment_id: session.segmentId
        }
      }))
    } else{
      dispatch(homePageMsg('Not able to start the meeting.'))
        dispatch(homePageShowMsg(true))
        setShowLoader(false)
        setTimeout(() => {
          dispatch(homePageShowMsg(false))
        }, 5000)
    }
  }

  const proceedToRegister = (session) => {
    // console.log("session", session);
    clickStreamUser("register-button-clicked", {
      event_id: session.eventId,
      session_id: session.sessionId,
      session_name: session.title,
      user_id: authBroker.payload.user.user_id,
      type: session.source,
    });
    dispatch(
      getEnrollBrokerSession({
        module: "enrollBrokerSession",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          session_id: session.sessionId,
          event_id: session.eventId,
          type: session.source,
        },
      })
    );
  };

  const proceedToBook = (session) => {
    clickStreamUser("proceed-to-book-session", {
      event_id: session.eventId,
      session_id: session.sessionId,
      session_name: session.title,
      user_id: authBroker.payload.user.user_id,
    });
    history.push(`/shopping/${session.productId}?addToCart=${session.is_addToCart}&source=session`)
    // dispatch(addToCart({
    //   module: 'addToCart',
    //   postData: {
    //     "pid": session.productId,
    //     "email": userDetails?.email ? userDetails?.email : localStorage.getItem(SessionKeys.EMAIL_ID),
    //     "first_name": userDetails?.firstname,
    //     "last_name": userDetails?.lastname,
    //     "username": userDetails?.username,
    //     "company": "",
    //     "address_1": "",
    //     "address_2": "",
    //     "city": "",
    //     "state": "",
    //     "postcode": "",
    //     "country": "",
    //     "phone": "",
    //     "isPremium": isPremiumUser,
    //     "broker_id": userDetails?.broker_id,
    //     "company_id": userDetails?.company_id,
    //   }
    // }))
  };

  const renderButton = (eventDetail) => {
    // console.log('Event Detail', eventDetail)
    if (!_.isEmpty(eventDetail)) {
      if (eventDetail.source === 'broker') {
        if (eventDetail.is_registered && eventDetail.is_participant && !eventDetail.is_host) {
          // console.log('eventDetail', eventDetail)
          if (eventDetail.delivery_mode === 'face_to_face') {
            const startTime = moment(`${eventDetail.start}`).subtract(30, 'minutes');
            const endTime = moment(`${eventDetail.end}`);
            const currentTime = moment(eventDetail.current_time);
            if (currentTime.isBefore(startTime)) {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={false}
                    title="In-person"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={() => {}}
                  />
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Cancel"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => cancelSessionConfirmation(eventDetail)}
                  />
                </>
              )
            } else {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={false}
                    title="In-person"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={() => {}}
                  />
                </>
              )
            }
          } else if (eventDetail.tense === 'past') {
            if (eventDetail.watch_url) {
              return (
                <RenderButtonForCalendar
                  isActive={true}
                  title="Watch"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={(eventDetail) => watchSession(eventDetail)}
                />
              )
            } else {
              return (
                <>
                  {/* Session has ended. {eventDetail.watch_url} */}
                </>
              )
            }
          } else if (eventDetail.tense === 'future') {
            return (
              <>
                <RenderButtonForCalendar
                  isActive={false}
                  title="Join"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={() => { }}
                />
                <RenderButtonForCalendar
                  isActive={true}
                  title="Cancel"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={(eventDetail) => cancelSessionConfirmation(eventDetail)}
                />
              </>
            )
          } else {
            const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = moment(`${eventDetail.start}`).subtract(30, 'minutes');
            const endTime = moment(`${eventDetail.end}`);
            const currentTime = moment(eventDetail.current_time); //moment().utcOffset(eventDetail.tz);
            // console.log('meetingId', eventDetail.meetingId)
            if (currentTime.isBetween(startTime, endTime)) {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Join"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => eventDetail.is_boardcast ? showBoardcast(eventDetail) : launchSession(eventDetail)}
                  />
                </>
              )
            } else if (currentTime.isAfter(endTime)) {
              if (eventDetail.watch_url) {
                return (
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Watch"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => watchSession(eventDetail)}
                  />
                )
              } else {
                return (
                  <>
                    {/* Session has ended. */}
                  </>
                )
              }
            } else {
              return (
                <>
                  <button className={'btn-join'}>
                    Join
                  </button>
                  {/* <button className={'btn-join active'}
                      onClick={() => cancelSession(eventDetail)}
                      >
                        Cancel
                      </button> */}
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Cancel"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => cancelSessionConfirmation(eventDetail)}
                  />
                </>
              )
            }
          }
        } else if (eventDetail.is_host) {
          if (eventDetail.delivery_mode === 'face_to_face') {
            return (
              <>
                <RenderButtonForCalendar
                  isActive={false}
                  title="In-person"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={() => {}}
                />
              </>
            )
          } else if (eventDetail.tense === 'future') {
            return (
              <>
                <button className={'btn-join'}
                >
                  Host
                </button>
              </>
            )
          } else if (eventDetail.tense === 'present') {
            const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = moment(`${eventDetail.start}`).tz(systemTz).subtract(30, 'minutes');
            const endTime = moment(`${eventDetail.end}`).tz(systemTz);
            const currentTime = moment(eventDetail.current_time); //moment().utcOffset(eventDetail.tz);
            // console.log('meetingId', eventDetail.meetingId)
            if (currentTime.isBetween(startTime, endTime)) {
              return (
                <>
                  {/* <button className={'btn-join active'}
                    onClick={() => hostSessionData(eventDetail)}>
                      Host
                    </button> */}
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Host"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => eventDetail.is_boardcast ? showBoardcast(eventDetail) : hostSessionData(eventDetail)}
                  />
                </>
              )
            } else if (currentTime.isAfter(endTime)) {
              return (
                <></>
              )
            } else {
              return (
                <>
                  <button className={'btn-join'}
                  >
                    Host
                  </button>
                </>
              )
            }
          }
        } else {
          if (eventDetail.tense === 'past') {
            return (
              <>
                {/* Session has ended. */}
              </>
            )
          } else {
            if (userRole === 'host' || userRole === 'instructor') {
              <RenderButtonForCalendar
                isActive={true}
                title="Join"
                isLoaded={showLoader}
                eventData={eventDetail}
                onClick={(eventDetail) => eventDetail.is_boardcast ? showBoardcast(eventDetail) : launchSession(eventDetail)}
              />
            } else {
              return (
                // <button className={'btn-join active'}
                // onClick={() => proceedToRegister(eventDetail)}
                // >
                //   Register
                // </button>
                <RenderButtonForCalendar
                  isActive={true}
                  title="Register"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={(eventDetail) => proceedToRegister(eventDetail)}
                />
              )
            }
          }
          // console.log('EVENT CLICKED NOT PAID', eventDetail.title)

        }
      } else {
        if (eventDetail.is_registered && eventDetail.is_participant && !eventDetail.is_host) {
          // console.log('eventDetail', eventDetail)
          if (eventDetail.delivery_mode === 'face_to_face') {
            const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = moment(`${eventDetail.start}`).subtract(30, 'minutes');
            const endTime = moment(`${eventDetail.end}`);
            const currentTime = moment(eventDetail.current_time); //moment().utcOffset(eventDetail.tz);
            if (currentTime.isBefore(startTime)) {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={false}
                    title="In-person"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={() => {}}
                  />
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Cancel"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => cancelSessionConfirmation(eventDetail)}
                  />
                </>
              )
            } else {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={false}
                    title="In-person"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={() => {}}
                  />
                </>
              )
            }
          } else if (eventDetail.tense === 'past') {
            if (eventDetail.watch_url) {
              return (
                <RenderButtonForCalendar
                  isActive={true}
                  title="Watch"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={(eventDetail) => watchSession(eventDetail)}
                />
              )
            } else {
              return (
                <>
                  {/* Session has ended. {eventDetail.watch_url} */}
                </>
              )
            }
          } else if (eventDetail.tense === 'future') {

            return (
              <>
                <button className={'btn-join'}>
                  Join
                </button>
                <RenderButtonForCalendar
                  isActive={true}
                  title="Cancel"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={(eventDetail) => cancelSessionConfirmation(eventDetail)}
                />
              </>
            )
          } else {
            const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = moment(`${eventDetail.start}`).subtract(30, 'minutes');
            const endTime = moment(`${eventDetail.end}`);
            const currentTime = moment(eventDetail.current_time); //moment().utcOffset(eventDetail.tz);
            // console.log('meetingId', eventDetail.meetingId)
            if (currentTime.isBetween(startTime, endTime)) {
              return (
                <>
                  {/* <button className={'btn-join active'}
                  onClick={() => launchSession(eventDetail)}>
                    Join
                  </button> */}
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Join"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => launchSession(eventDetail)}
                  />
                </>
              )
            } else if (currentTime.isAfter(endTime)) {
              if (eventDetail.watch_url) {
                return (
                  // <button className={'btn-join active'}
                  // onClick={() => watchSession(eventDetail)}
                  // >
                  //   Watch
                  // </button>
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Watch"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => watchSession(eventDetail)}
                  />
                )
              } else {
                return (
                  <>
                    {/* Session has ended. */}
                  </>
                )
              }
            } else {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={false}
                    title="Join"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={() => { }}
                  />
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Cancel"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => cancelSessionConfirmation(eventDetail)}
                  />
                </>
              )
            }
          }
        } else if (eventDetail.is_host) {
          if (eventDetail.delivery_mode === 'face_to_face') {
            return (
              <>
                <RenderButtonForCalendar
                  isActive={false}
                  title="In-person"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={() => {}}
                />
              </>
            )
          } else if (eventDetail.tense === 'future') {
            return (
              <>
                <RenderButtonForCalendar
                  isActive={false}
                  title="Host"
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={() => { }}
                />
              </>
            )
          } else if (eventDetail.tense === 'present') {
            const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startTime = moment(`${eventDetail.start}`).tz(systemTz).subtract(30, 'minutes');
            const endTime = moment(`${eventDetail.end}`).tz(systemTz);
            const currentTime = moment(eventDetail.current_time); //moment().utcOffset(eventDetail.tz);
            // console.log('meetingId', eventDetail.meetingId)
            if (currentTime.isBetween(startTime, endTime)) {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Host"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => eventDetail.is_boardcast ? showBoardcast(eventDetail) : hostSessionData(eventDetail)}
                  />
                </>
              )
            } else if (currentTime.isAfter(endTime)) {
              return (
                <></>
              )
            } else {
              return (
                <>
                  <RenderButtonForCalendar
                    isActive={false}
                    title="Host"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={() => { }}
                  />
                </>
              )
            }
          }
        } else {
          if (eventDetail.tense === 'past') {
            return (
              <>
                {/* Session has ended. */}
              </>
            )
          } else {
            if (eventDetail.selling_price === 0) {
              if (userRole === 'host' || userRole === 'instructor') {
                const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const startTime = moment(`${eventDetail.start}`).tz(systemTz).subtract(30, 'minutes');
                const endTime = moment(`${eventDetail.end}`).tz(systemTz);
                const currentTime = moment(eventDetail.current_time); //moment().utcOffset(eventDetail.tz);
                if (currentTime.isBetween(startTime, endTime)) {
                  return (
                    <RenderButtonForCalendar
                      isActive={true}
                      title="Join"
                      isLoaded={showLoader}
                      eventData={eventDetail}
                      onClick={(eventDetail) => eventDetail.is_boardcast ? showBoardcast(eventDetail) : launchSession(eventDetail)}
                    />
                  )
                } else {
                  return (
                    <RenderButtonForCalendar
                      isActive={false}
                      title="Join"
                      isLoaded={showLoader}
                      eventData={eventDetail}
                      onClick={(eventDetail) => { }}
                    />
                  )
                }
              } else {
                return (
                  // <button className={'btn-join active'}
                  // onClick={() => proceedToRegister(eventDetail)}
                  // >
                  //   Register
                  // </button>
                  <RenderButtonForCalendar
                    isActive={true}
                    title="Register"
                    isLoaded={showLoader}
                    eventData={eventDetail}
                    onClick={(eventDetail) => proceedToRegister(eventDetail)}
                  />
                )
              }
            } else {
              return (
                <RenderButtonForCalendar
                  isActive={true}
                  title='Book'
                  isLoaded={showLoader}
                  eventData={eventDetail}
                  onClick={(eventDetail) => proceedToBook(eventDetail)}
                />
              )
            }
          }
          // console.log('EVENT CLICKED NOT PAID', eventDetail.title)

        }
      }
    }
  }

  return (
    <>
      <ul className="livedrop dropdown-menu">
        {activeSessionList.map((session, index) => {
          return (
            <li className="dropdown-item">

              <h6 className="menu-head">
                <span className="iconboxl">
               
                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="ð-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="ic_fluent_live_24_regular" fill="#000000" fill-rule="nonzero">
                            <path d="M5.98959236,4.92893219 C6.28248558,5.22182541 6.28248558,5.69669914 5.98959236,5.98959236 C2.67013588,9.30904884 2.67013588,14.6909512 5.98959236,18.0104076 C6.28248558,18.3033009 6.28248558,18.7781746 5.98959236,19.0710678 C5.69669914,19.363961 5.22182541,19.363961 4.92893219,19.0710678 C1.02368927,15.1658249 1.02368927,8.83417511 4.92893219,4.92893219 C5.22182541,4.63603897 5.69669914,4.63603897 5.98959236,4.92893219 Z M19.0710678,4.92893219 C22.9763107,8.83417511 22.9763107,15.1658249 19.0710678,19.0710678 C18.7781746,19.363961 18.3033009,19.363961 18.0104076,19.0710678 C17.7175144,18.7781746 17.7175144,18.3033009 18.0104076,18.0104076 C21.3298641,14.6909512 21.3298641,9.30904884 18.0104076,5.98959236 C17.7175144,5.69669914 17.7175144,5.22182541 18.0104076,4.92893219 C18.3033009,4.63603897 18.7781746,4.63603897 19.0710678,4.92893219 Z M8.81801948,7.75735931 C9.1109127,8.05025253 9.1109127,8.52512627 8.81801948,8.81801948 C7.06066017,10.5753788 7.06066017,13.4246212 8.81801948,15.1819805 C9.1109127,15.4748737 9.1109127,15.9497475 8.81801948,16.2426407 C8.52512627,16.5355339 8.05025253,16.5355339 7.75735931,16.2426407 C5.41421356,13.8994949 5.41421356,10.1005051 7.75735931,7.75735931 C8.05025253,7.46446609 8.52512627,7.46446609 8.81801948,7.75735931 Z M16.2426407,7.75735931 C18.5857864,10.1005051 18.5857864,13.8994949 16.2426407,16.2426407 C15.9497475,16.5355339 15.4748737,16.5355339 15.1819805,16.2426407 C14.8890873,15.9497475 14.8890873,15.4748737 15.1819805,15.1819805 C16.9393398,13.4246212 16.9393398,10.5753788 15.1819805,8.81801948 C14.8890873,8.52512627 14.8890873,8.05025253 15.1819805,7.75735931 C15.4748737,7.46446609 15.9497475,7.46446609 16.2426407,7.75735931 Z M12,10.5 C12.8284271,10.5 13.5,11.1715729 13.5,12 C13.5,12.8284271 12.8284271,13.5 12,13.5 C11.1715729,13.5 10.5,12.8284271 10.5,12 C10.5,11.1715729 11.1715729,10.5 12,10.5 Z" id="ð¨-Color">
                            </path>
                        </g>
                    </g>
                </svg>
                </span>
                {session?.title}
              </h6>
              <p className="menu-content">

              </p>
              <div className="live-footer-menu-btn">
                {renderButton(session)}
              </div>


            </li>

          );
        })}
      </ul>
    </>
  );

};

export default LiveButtonDropDown;
