import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { calculateRolePlayScore, clickStreamUser, SessionKeys, timezoneArea } from "../../utils/AppUtils";
import RolePlayHeadingSection from "./RolePlayHeadingSection";
import  ReviewRolePlayPick from "./ReviewRolePlayPick";
import  ReviewOverview from "./ReviewOverview";
import RolePlayParticipateSection from "./RolePlayParticipateSection";
import { reviewFilterList, reviewFilterListClear } from "../../redux/roleplay/actions";
import RolePlayTabSection from "./RolePlayTabSection";
import moment from "moment";

const ReviewRolePlayPageSection = (props) => {

    const dispatch = useDispatch();
    const authBroker = useSelector(state => state.auth.authBroker)
    const reviewList = useSelector(state => state.rolePlay.reviewList)
    const reviewFilterListing = useSelector(state => state.rolePlay.reviewFilterList)
    const userDetailRes = useSelector(state => state.user.userBroker)

    const [clickedRolePlay, setClickedRolePlayData] = useState({});
    const [reviewListing, setReviewListing] = useState([])
    const [showModalMsg, setShowModalMsg] = useState('')
    const [confirmModal, setConfirmModal] = useState(false)
    const [reviewType, setReviewType] = useState('pending')
    const [pendingCount, setPendingCount] = useState(0)
    const [reviewedCount, setReviewedCount] = useState(0)
    const [isShowReview, setIsShowReview] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            clickStreamUser('review-role-play-page', {
                user_id: authBroker.payload.user.user_id,
                tab: reviewType
            })
            fetchReviewFilterList()
        }
    }, [authBroker, reviewType])

    useEffect(() => {
        if (confirmModal) {
            setTimeout(() => {
                setConfirmModal(false)
                setShowModalMsg('')
            }, 5000)
        }
    }, [confirmModal])

    useEffect(() => {
        // console.log('myrole play list', reviewFilterListing)
        if (!_.isEmpty(reviewFilterListing) && !_.isEmpty(userDetailRes)) {
            if (reviewFilterListing.status === 'success') {
                const payload = reviewFilterListing.payload?.payload
                let rolePlayList = payload.list
                setPendingCount(payload.stats.pending)
                setReviewedCount(payload.stats.reviewed)
                if (!_.isEmpty(rolePlayList)) {
                    const timezone = userDetailRes.payload.payload.timezone
                    const offset = timezone.includes(":") ? 
                    timezone.replace(":", "") : "-0500";
                    const area = timezoneArea(offset)
                    rolePlayList = _.reverse(_.sortBy(_.cloneDeep(rolePlayList), 'submission_dt'))
                    // console.log('ROLEPLAYLIST', rolePlayList)
                    // setReviewRolePlayList(rolePlayList)
                    setReviewListing(rolePlayList.map(role => {
                        let totalPoint = 0;
                        if (!_.isEmpty(role.feedback_data)) {
                            let data = role.feedback_data
                            // console.log(role)
                            totalPoint = !_.isEmpty(data.rating) ? calculateRolePlayScore(data.rating) : 0
                        }
                        return {
                            role_play_id: role.roleplay_id,
                            title: role.roleplay_title,
                            situation: role.situation,
                            description: role.description,
                            releaseDate: role.release_date,
                            duration: role.duration,
                            durationUnit: role.durationUnit,
                            userFirstName: role.user_first_name,
                            userLastName: role.user_last_name,
                            type: role.uploaded_type,
                            originalType: role.type,
                            url: role.roleplay_video_or_doc_path,
                            originalURL: role.url,
                            thumbnail: role.thumbnail,
                            uploaded_file: role.roleplay_video_or_doc_path,
                            submissionDate: moment.utc(role.submission_dt, "YYYY-MM-DD HH:mm:ss").tz(area).format("DD MMM, YYYY hh:mm A z"), //role.submission_dt,
                            createdDate: role.created_dt,
                            brokerageId: role.brokerage_id,
                            userId: role.user_id,
                            id: role.reference_video_id,
                            uploadedType: role.uploaded_type,
                            submitToCoach: _.toNumber(role.submit_to_coach),
                            additionalComment: role.additional_comment,
                            roleplay_category: role.roleplay_category,
                            roleplay_url: role.roleplay_url,
                            roleplay_wpm: role.roleplay_wpm,
                            roleplay_duration: role.roleplay_duration,
                            roleplay_sentiment_positive: role.roleplay_sentiment_positive,
                            roleplay_sentiment_negative: role.roleplay_sentiment_negative,
                            roleplay_sentiment_neutral: role.roleplay_sentiment_neutral,
                            roleplay_keywords: role.roleplay_keywords ? JSON.parse(role.roleplay_keywords) : [],
                            roleplay_keyword_analysis: role.roleplay_keyword_analysis ? JSON.parse(role.roleplay_keyword_analysis) : [],
                            roleplay_score: totalPoint,
                            status: role.status,
                            reviewedDate: !_.isEmpty(role.feedback_data) && role.feedback_data.created_at ? moment.utc(role.feedback_data.created_at, "YYYY-MM-DD HH:mm:ss").tz(area).format("DD MMM, YYYY hh:mm A z") : "",
                            // review_status: role.review_status,
                            // awareness_star: role.awareness_star,
                            // research_star: role.research_star,
                            // impact_star: role.impact_star,
                            // empathy_star: role.empathy.start,
                            // roleplay_score: role.awareness_star + role.research_star + role.impact_star + role.empathy_star,
                            feedbackData: role.feedback_data
                        }
                    }))
                } else {
                    setReviewListing([])
                    props.setIsLoading(false)
                }
            }
            props.setIsLoading(false)
            dispatch(reviewFilterListClear())
        }
    }, [reviewFilterListing, userDetailRes])

    const fetchReviewFilterList = () => {
        setIsShowReview(false)
        setReviewListing([])
        dispatch(reviewFilterList({
            module: 'roleplayReviewList',
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                type: reviewType
            }
        }))
    }
    
    const renderAlertCard = () => {
        return (
            <div className={`alert-card d-flex justify-content-center ${confirmModal ? '' : 'd-none'}`}>
                <div className="shop-cart">
                    <div className="shop-info justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                                <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                        <p className="m-0">{showModalMsg}</p>
                    </div>
                    <div className="view-cart-icon">
                        <i className="fa fa-times cursor-pointer" aria-hidden="true"
                            onClick={() => {
                                setConfirmModal(false)
                                setShowModalMsg('')
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        )
    }

    const showForReview = (id) => {
        // console.log('Show Review', id)
        
        if (!_.isEmpty(id)) {
            for (let record of reviewListing) {
                if (record.role_play_id == id) {
                    setClickedRolePlayData(record)
                    setIsShowReview(true)
                    props.setIsLoading(true)
                }
            }
        } else {
            setIsShowReview(false)
            setClickedRolePlayData({})
        }
    }

    return (
        <div className="position-relative roleplaytables">
            {renderAlertCard()}
            {/* Show Header Tab Section */}
            <RolePlayHeadingSection 
                page="review"
                isMobile={props.isMobile}
            />
            <RolePlayTabSection 
                page="review"
                isMobile={props.isMobile}
            />
            {/* Show RolePlay master list section */}
            {(reviewListing && !isShowReview) ? 
            <ReviewRolePlayPick 
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
                roleData={clickedRolePlay}
                reviewType={reviewType}
                setReviewType={setReviewType}
                listing={reviewListing}
                pendingCount={pendingCount}
                reviewedCount={reviewedCount}
                showForReview={showForReview}
            /> 
            : (!props.isLoading && !isShowReview) ? 'No Data' : ''}
            {/* Show Review Section */}
            {isShowReview ? 
            <ReviewOverview 
                page="review"
                roleData={clickedRolePlay}
                listing={reviewListing}
                setIsLoading={props.setIsLoading}
                isLoading={props.isLoading}
                setClickedRolePlayData={setClickedRolePlayData}
                setConfirmModal={setConfirmModal}
                setShowModalMsg={setShowModalMsg}
                fetchReviewFilterList={fetchReviewFilterList}
                showForReview={showForReview}
                reviewType={reviewType}
            /> : ''}
        </div>
    )
}

export default ReviewRolePlayPageSection;