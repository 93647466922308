import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getMyCourses } from "../../redux/courses/actions";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { ProgressBar, Tab, Tabs } from 'react-bootstrap';
import _ from "lodash";
import CourseListBody from "../../components/Course/CourseListBody";
import Page from "../../components/Page";
import CourseCardListBody from "../../components/Course/CourseCardListBody";
import { getWishList } from "../../redux/product/actions";
import WishlistSection from "../../components/wishlist/WishlistSection";
import Loader from "../../components/Loader/Loader";

const MyWishList = () => {

    const dispatch = useDispatch();
    const authBroker = useSelector(state => state.auth.authBroker);
    const wishListRes = useSelector(state => state.product.getWishList);



    const [pageType, setPageType] = useState('course');
    const [wishlist, setWishList] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(() => {
        setIsLoading(true)
        dispatch(getWishList({ module: "getWishList", email: localStorage.getItem(SessionKeys.EMAIL_SESSION) }))
    }, [dispatch])

    // useEffect(()=>{
    //     if (!_.isEmpty(wishListRes)) {
    //         set
    //         setWishList(wishListRes?.payload)
    //     }
    // },[wishListRes])


    return (<>
        <Loader start={isLoading} />
        <Page>
            <WishlistSection setIsLoading={setIsLoading} />
        </Page>
    </>
    )
}

export default MyWishList