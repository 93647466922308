import types from "./types";

const initialState = {
  user: {},
  userBroker: {},
  userDetailIframe: {},
  userUpdateRes: {},
  isSelectUser: false,
  selectedTeamLead: "",
  selectedMentor: "",
  selectedCoach: "",
  searchUserRes: {},
  uploadRes: {},
  uploadResBroker: {},
  isPremiumUser: false,
  profilePercentageData: {},
  role: "",
  iframeRole: "",
  otherRole: "",
  sessionCount: 0,
  bannerCard: {},
  roleRequested: {},
  acceptRoleReq: {},
  userOfRoleAccepted: {},
  userRoleCancel: {},
  UserRoleRequestStatus: {},
  timezoneMaster: [],
  timezoneAbbr: "",
  isMobileWebView: false,
  postUploadRes: {},
  editUploadRes: {},
  leaderBoard: {},
  userPoints: {},
  checkListApprovalList: {},
  checkListApprovalCheck: {},
  changePassRes: {},
  isMobileDevice: false,
  getAssesments:{}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_DETAIL_RESPONSE:
      return {
        ...state,
        user: action.payload,
      };
    case types.USER_DETAIL_BROKER_RESPONSE:
      return {
        ...state,
        userBroker: action.payload,
      };
    case types.USER_DETAIL_BROKER_CLEAR:
      return {
        ...state,
        userBroker: {},
      };
    case types.USER_DETAIL_IFRAME_USER_RESPONSE:
      return {
        ...state,
        userDetailIframe: action.payload,
      };
    case types.USER_DETAIL_IFRAME_USER_CLEAR:
      return {
        ...state,
        userDetailIframe: {},
      };
    case types.USER_UPDATE_RESPONSE:
      return {
        ...state,
        userUpdateRes: action.payload,
      };

    case types.USER_UPDATE_CLEAR:
      return {
        ...state,
        userUpdateRes: {},
      };
    case types.SELECT_USER:
      return {
        ...state,
        isSelectUser: action.payload,
      };
    case types.SELECTED_TEAM_LEAD:
      return {
        ...state,
        selectedTeamLead: action.payload,
      };
    case types.SELECTED_MENTOR:
      return {
        ...state,
        selectedMentor: action.payload,
      };
    case types.SELECTED_COACH:
      return {
        ...state,
        selectedCoach: action.payload,
      };
    case types.SEARCH_USER_RES:
      return {
        ...state,
        searchUserRes: action.payload,
      };
    case types.ON_UPLOAD_RES:
      return {
        ...state,
        uploadRes: action.payload,
      };

    case types.ON_UPLOAD_RES_BROKER:
      return {
        ...state,
        uploadRes: action.payload,
      };
    case types.SET_USER_PREMIUM:
      return {
        ...state,
        isPremiumUser: action.payload,
      };
    case types.GET_PROFILE_PERCENTAGE_RESPONSE:
      return {
        ...state,
        profilePercentageData: action.payload,
      };
    case types.GET_PROFILE_PERCENTAGE_CLEAR:
      return {
        ...state,
        profilePercentageData: {},
      };
    case types.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case types.SET_ROLE_CLEAR:
      return {
        ...state,
        role: {},
      };
    case types.SET_IFRAME_USER_ROLE:
      return {
        ...state,
        iframeRole: action.payload,
      };
    case types.SET_IFRAME_USER_ROLE_CLEAR:
      return {
        ...state,
        iframeRole: {},
      };
    case types.SET_OTHER_ROLE:
      return {
        ...state,
        otherRole: action.payload,
      };
    case types.SET_OTHER_ROLE_CLEAR:
      return {
        ...state,
        otherRole: {},
      };
    case types.SET_SESSION_COUNT:
      return {
        ...state,
        sessionCount: action.payload,
      };
    case types.SET_SESSION_COUNT_CLEAR:
      return {
        ...state,
        sessionCount: 0,
      };
    case types.GET_LICENSED_AS_RESPONSE:
      return {
        ...state,
        licensedAsRes: action.payload,
      };
    case types.GET_LICENSED_AS_CLEAR:
      return {
        ...state,
        licensedAsRes: {},
      };
    case types.BANNER_CARD_SUMMARY_RESPONSE:
      return {
        ...state,
        bannerCard: action.payload,
      };
    case types.BANNER_CARD_SUMMARY_CLEAR:
      return {
        ...state,
        bannerCard: {},
      };
    case types.GET_ROLE_REQUESTED_RESPONSE:
      return {
        ...state,
        roleRequested: action.payload,
      };
    case types.GET_ROLE_REQUESTED_CLEAR:
      return {
        ...state,
        roleRequested: {},
      };
    case types.ACCEPT_ROLE_REQUESTED_RESPONSE:
      return {
        ...state,
        acceptRoleReq: action.payload,
      };
    case types.ACCEPT_ROLE_REQUESTED_CLEAR:
      return {
        ...state,
        acceptRoleReq: {},
      };
    case types.GET_USER_OF_ROLE_ACCEPTED_RESPONSE:
      return {
        ...state,
        userOfRoleAccepted: action.payload,
      };
    case types.GET_USER_OF_ROLE_ACCEPTED_CLEAR:
      return {
        ...state,
        userOfRoleAccepted: {},
      };
    case types.USER_ROLE_CANCEL_RESPONSE:
      return {
        ...state,
        userRoleCancel: action.payload,
      };
    case types.USER_ROLE_CANCEL_CLEAR:
      return {
        ...state,
        userRoleCancel: {},
      };
    case types.USER_ROLE_REQUEST_STATUS_RESPONSE:
      return {
        ...state,
        UserRoleRequestStatus: action.payload,
      };
    case types.USER_ROLE_REQUEST_STATUS_CLEAR:
      return {
        ...state,
        UserRoleRequestStatus: {},
      };
    case types.TIMEZONE_ARRAY_RESPONSE:
      //  console.log('timezone to fetch in action')
      return {
        ...state,
        timezoneMaster: action.payload,
      };

    case types.TIMEZONE_ARRAY_CLEAR:
      return {
        ...state,
        timezoneMaster: [],
      };
    case types.TIMEZONE_ABBR_RESPONSE:
      return {
        ...state,
        timezoneAbbr: action.payload,
      };
    case types.TIMEZONE_ABBR_CLEAR:
      return {
        ...state,
        timezoneAbbr: "",
      };
    case types.IS_MOBILE_WEBVIEW:
      return {
        ...state,
        isMobileWebView: true,
      };
    case types.IS_MOBILE_WEBVIEW_CLEAR:
      return {
        ...state,
        isMobileWebView: false,
      };
    case types.POST_UPLOAD_RESPONSE:
      return {
        ...state,
        postUploadRes: action.payload,
      };
    case types.POST_UPLOAD_CLEAR:
      return {
        ...state,
        postUploadRes: {},
      };
    case types.EDIT_UPLOAD_RESPONSE:
      return {
        ...state,
        editUploadRes: action.payload,
      };
    case types.EDIT_UPLOAD_CLEAR:
      return {
        ...state,
        editUploadRes: {},
      };
    case types.LEADERBOARD_RESPONSE:
      return {
        ...state,
        leaderBoard: action.payload,
      };
    case types.LEADERBOARD_CLEAR:
      return {
        ...state,
        leaderBoard: {},
      };
    case types.USER_POINTS_RESPONSE:
      return {
        ...state,
        userPoints: action.payload,
      };
    case types.USER_POINTS_CLEAR:
      return {
        ...state,
        userPoints: {},
      };
      case types.ASSESSMENTS_RESPONSE:
        return {
          ...state,
          getAssesments: action.payload,
        };
      case types.ASSESSMENT_CLEAR:
        return {
          ...state,
          getAssesments: {},
        };
    // checklist approval by managers section
    case types.CHECKLITST_APPROVAL_LIST_RESPONSE:
      return {
        ...state,
        checkListApprovalList: action.payload,
      };
    case types.CHECKLITST_APPROVAL_LIST_CLEAR:
      return {
        ...state,
        checkListApprovalList: {},
      };

    // checklist approval status by managers section
    case types.CHECKLITST_APPROVAL_STATUS_RESPONSE:
      return {
        ...state,
        checkListApprovalCheck: action.payload,
      };
    case types.CHECKLITST_APPROVAL_STATUS_CLEAR:
      return {
        ...state,
        checkListApprovalCheck: {},
      };
    case types.CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        changePassRes: action.payload,
      };
    case types.CHANGE_PASSWORD_RES_CLEAR:
      return {
        ...state,
        changePassRes: {},
      };

    default:
      return state;
  }
};

export default userReducer;
