import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Config from '../../utils/config';
import StripeCheckoutForm from './StripeCheckoutForm';
import _ from "lodash";
const stripePromise = loadStripe(Config.KEY.STRIPE_KEY);

const PaymentForm = (props) => {

    const [paymentInfo, setPaymentInfo] = useState({})

    useEffect(() => {
        if (!_.isEmpty(props.paymentData)) {
            setPaymentInfo(props.paymentData)
        }
    }, [props.paymentData])

    return (
        <Elements stripe={stripePromise}>
            <StripeCheckoutForm  paymentData={paymentInfo}  showPaymentFormError={props.showPaymentFormError} />
        </Elements>
    )
}

export default PaymentForm;