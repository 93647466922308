import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCompletedCourseList, getCompletedCourseListResClear, getOfficeListReq, getOfficeListResClear, setCurrentView } from "../../redux/assignCourse/actions";
import { SessionKeys } from "../../utils/AppUtils";
const ChooseFromCompletedCourse = (props) => {

	const { selectedCompletedCourseList, setSelectedCompletedCourseList,parentName,setTitleHeading } = props;

	const dispatch = useDispatch();
	const completedCourseListRes = useSelector((state) => state.assignCourse.completedCourseList);
	const [searchInput, setSearchInput] = useState('')
	const [completedCourseList, setCompletedCourseList] = useState([])
	const [selectedCount, setSelectedCount] = useState(0);

	useEffect(() => {
		setTitleHeading(`Assign ${parentName} to`);
		dispatch(getCompletedCourseListResClear())
	}, [])

	useEffect(() => {
		setSelectedCount(selectedCompletedCourseList?.length)
	}, [selectedCompletedCourseList])

	useEffect(() => {
		if (searchInput !== undefined && searchInput.length > 2)
			searchOffice();
	}, [searchInput])

	const searchOffice = () => {
		// console.log('completedCourseListRes1')
		dispatch(getCompletedCourseList({
			module: 'getCompletedCourseList',
			postData: {
				token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
				search: searchInput
			}
		}))
	}

	useEffect(() => {
		// console.log('completedCourseListRes: ', completedCourseListRes)
		if (!_.isEmpty(completedCourseListRes)) {
			if (completedCourseListRes?.status === 'success') {
				setCompletedCourseList(completedCourseListRes?.payload?.payload)
			}
		} else {
			setCompletedCourseList([])
		}
	}, [completedCourseListRes])


	const selectItem = (index) => {
		let tempArr = _.cloneDeep(completedCourseList);
		let selectedTempArray = _.cloneDeep(selectedCompletedCourseList);
		selectedTempArray.push(tempArr[index])
		setSelectedCompletedCourseList(selectedTempArray)
		tempArr.splice(index,  1);
		setCompletedCourseList(tempArr)
	}

	const removeItem = (index) => {
		let selectedTempArray = _.cloneDeep(selectedCompletedCourseList);
		let tempArr = _.cloneDeep(completedCourseList);
		tempArr.push(selectedTempArray[index])
		setCompletedCourseList(tempArr)
		selectedTempArray.splice(index, 1);
		setSelectedCompletedCourseList(selectedTempArray)
	}


	return (
		<>
			<div className="chooseteams chooseppl">
				<div className="chooseteamate">
					<div className="headong d-flex justify-content-between"><h4>Select Course(s)</h4> </div>
					<div className="input-lear position-relative d-flex">
						<label for="peoplesearch" className="input__label_learning">Search</label>
						<input type="text" id="peoplesearch" name="peoplesearch" className="input_searchfield_search-cart " placeholder="Start searching"
							onChange={(evt) => {
								setSearchInput(evt.target.value)
							}} />
					</div>
					<div className="search-team-result">
						{
							completedCourseList?.map((item, index) => {
								return <div className="team-result-box">
									<div className="userteamname">{item?.crs} <span className="peoplecount">{item?.user_count} people</span></div>
									<div className="addteammate" onClick={() => { selectItem(index) }}>+</div>
								</div>
							})
						}


					</div>
					<div className="teamselected">
						<div className="selectresilt">Selected Courses<strong>({selectedCount})</strong></div>
						<div className="search-team-result slectedteammates">
							{selectedCompletedCourseList?.map((item, index) => {
								return <div className="team-result-box">
									<div className="userteamname">{item?.crs} <span className="peoplecount">{item?.user_count} people</span></div>
									<div className="addteammate" onClick={() => {
										// console.log('removeItem');
										removeItem(index)
									}}>+</div>
								</div>
							})}

						</div>
					</div>
					<div className="buttonsteammate">
						<button type="button" className="btn btn-primary btn-block back-button" onClick={() => { dispatch(setCurrentView(5)) }}>Back</button>
						<button type="button" className="btn btn-primary btn-block setup-button" onClick={() => { dispatch(setCurrentView(5)) }}>Proceed</button>
					</div>
				</div>
			</div>
		</>

	)
}

export default ChooseFromCompletedCourse;