const types = {
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  PRODUCT_DETAIL_RESPONSE: 'PRODUCT_DETAIL_RESPONSE',
  PRODUCT_DETAIL_CLEAR: 'PRODUCT_DETAIL_CLEAR',

  // Recommend Products
  RECOMMEND_PRODUCT: 'RECOMMEND_PRODUCT',
  RECOMMEND_PRODUCT_RESPONSE: 'RECOMMEND_PRODUCT_RESPONSE',
  RECOMMEND_PRODUCT_CLEAR: 'RECOMMEND_PRODUCT_CLEAR',

  //COURSE DETAIL
  COURSE_DETAIL: 'COURSE_DETAIL',
  COURSE_DETAIL_RESPONSE: 'COURSE_DETAIL_RESPONSE',
  COURSE_DETAIL_CLEAR: 'COURSE_DETAIL_CLEAR',

  //COURSE DETAIL
  ADD_TO_CART: 'ADD_TO_CART',
  ADD_TO_CART_RESPONSE: 'ADD_TO_CART_RESPONSE',
  ADD_TO_CART_CLEAR: 'ADD_TO_CART_CLEAR',
  
  //COURSE DETAIL
  GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
  GET_ORDER_DETAIL_RESPONSE: 'GET_ORDER_DETAIL_RESPONSE',
  GET_ORDER_DETAIL_RESPONSE_CLEAR: 'GET_ORDER_DETAIL_RESPONSE_CLEAR',


  //COURSE DETAIL
  ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
  ADD_TO_WISHLIST_RESPONSE: 'ADD_TO_WISHLIST_RESPONSE',
  ADD_TO_WISHLIST_CLEAR: 'ADD_TO_WISHLIST_CLEAR',


  //COURSE DETAIL
  GET_WISHLIST: 'GET_WISHLIST',
  GET_WISHLIST_RESPONSE: 'GET_WISHLIST_RESPONSE',
  GET_WISHLIST_RESPONSE_CLEAR: 'GET_WISHLIST_RESPONSE_CLEAR',

    //DELETE WISHLIST
    DELETE_WISHLIST: 'DELETE_WISHLIST',
    DELETE_WISHLIST_RESPONSE: 'DELETE_WISHLIST_RESPONSE',
    DELETE_WISHLIST_RESPONSE_CLEAR: 'DELETE_WISHLIST_RESPONSE_CLEAR',

}

export default types;