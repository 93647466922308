import { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import InvoiceShowModal from "../modal/InvoiceShowModal";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import ImageComponent from "../Layouts/ImageComponent";
import { clickStreamUser } from "../../utils/AppUtils";
import { getAssignCourse, getAssignCourseClear } from "../../redux/courses/actions";
import { useDispatch, useSelector } from "react-redux";

const OrderCourseCard = ({ order, orderDate, orderNumber, parent, isLast }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const assignCourse = useSelector((state) => state.courses.assignCourse);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const [orderInfo, setOrderInfo] = useState({});
  const [price, setPrice] = useState(0);
  const [cent, setCent] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [orderDetail, setOrderDetail] = useState({})

  useEffect(() => {
    // if (!_.isEmpty(order)) {
    //     setOrderInfo(order)
    //     setPrice(order.total.split('.')[0])
    //     setCent(order.total.split('.')[1])
    // }
    // console.log("orderDta: ", order);
  }, [order]);

  useEffect(() => {
    if (assignCourse?.status === "success" && !_.isEmpty(orderDetail)) {
      dispatch(getAssignCourseClear());
      history.push(
        `/moduleDetail/${orderDetail?.code}/?source=ecom&cid=${orderDetail.id}`
      );
    }
  }, [assignCourse, orderDetail]);

  const goToModuleDetail = (courseCode, id) => {
    console.log('ASSIGN COURSE');
    dispatch(
      getAssignCourse({
        module: "assign-course",
        postData: {
          user_id: authBroker?.payload?.user.user_id,
          course_id: courseCode
        }
      })
    )
    setOrderDetail({
      id: id,
      code: courseCode
    })
    // history.push(`/moduleDetail/${courseCode}/?source=ecom&cid=${id}`);
    // clickStreamUser('from-order-page-to-module', {
    //   course_code: courseCode
    // })
  };

  const redirectToPage = (order) => {
    switch (order.product_type) {
      case "course":
        
        if (order.list.length > 0) {
          dispatch(
            getAssignCourse({
              module: "assign-course",
              postData: {
                user_id: authBroker?.payload?.user.user_id,
                course_id: order?.list[0].crscd
              }
            })
          )
          setOrderDetail({
            id: order.list[0].id,
            code: order?.list[0].crscd
          })
          // history.push(`/moduleDetail/${order?.list[0].crscd}/?source=ecom&cid=${order.list[0].id}`);
          // clickStreamUser('from-order-page-to-module', {
          //   course_code: order?.list[0].crscd
          // })
        } else {
          dispatch(
            getAssignCourse({
              module: "assign-course",
              postData: {
                user_id: authBroker?.payload?.user.user_id,
                course_id: order?.course_code
              }
            })
          )
          setOrderDetail({
            id: order.product_id,
            code: order?.course_code
          })
          // history.push(`/moduleDetail/${order?.course_code}/?source=ecom&cid=${order.product_id}`);
          // clickStreamUser('from-order-page-to-module', {
          //   course_code: order?.course_code
          // })
        }
      return;
      case 'videos':
        if (order.list.length > 0) {
          history.push(`/video-view-page/${order?.list[0].id}/?source=xsel&code=${order.list[0].crscd}`);
          clickStreamUser('from-order-page-to-module', {
            video_code: order?.list[0].course_code
          })
        } else {
          history.push(`/video-view-page/${order?.product_id}?source=xsel&code=${order.course_code}`)
          clickStreamUser('from-order-page-to-module', {
            video_code: order?.course_code
          })
        }
      return;
      case 'session':
        history.push(
          `/event-detail?event_id=${order?.eventId}&event_code=${order?.event_code}&source=ecom`
        )
        clickStreamUser('from-order-page-to-module', {
          event_id: order?.eventId,
          event_code: order.event_code
        })
      return;
      case 'certificate':
        history.push(
          `/certify-detail?certify_id=${order?.eventId}&certify_code=${order?.event_code}&source=ecom`
        )
        clickStreamUser('from-order-page-to-module', {
          certify_id: order?.eventId,
          certify_code: order.event_code
        })
      return;
    }
    
  }

  const renderBundleCard = (order) => {
    return (
      <div className="image-cart">
        <div id="cartCardCarousel" className="position-relative">
          <Carousel id="cartCourseCarousel">
            {order.list.map((course, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="cursor-pointer"
                    src={course.imageURL}
                    alt=""
                    onClick={() =>
                      order?.product_type === "course"
                        ? goToModuleDetail(course?.crscd, course?.id)
                        : order?.product_type === "videos"
                        ? history.push(`/video-view-page/${course?.id}?source=xsel&code=${course.crscd}`)
                        : order?.product_type === "session"
                        ? history.push(
                            `/event-detail?event_id=${order?.eventId}&event_code=${order?.event_code}&source=ecom`
                          )
                        : ""
                    }
                  />
                  <div className="combo-tag">
                    <i className="fa fa-star" aria-hidden="true"></i> COMBO
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={
          isLast ? "cart-description-ex-border" : "cart-description-ex"
        }
      >
        {order?.list !== undefined && order?.list.length > 0 ? (
          renderBundleCard(order)
        ) : (
          <div className="image-cart">
            <ImageComponent 
              name={order.product_name}
              thumbnail={order.product_thumbnail ? order.product_thumbnail : ''}
              cssClass={'cursor-pointer'}
              errorClass={'h-100'}
              handleClick={() =>
                redirectToPage(order)
              }
            />
            {/* <img
              className="cursor-pointer"
              src={order?.product_thumbnail}
              alt=""
              onClick={() =>
                order?.product_type === "course"
                  ? goToModuleDetail(order?.course_code)
                  : order?.product_type === "videos"
                  ? history.push(`/video-view-page/${order?.product_id}?source=xsel&code=${order.course_code}`)
                  : order?.product_type === "session"
                  ? history.push(
                      `/event-detail?event_id=${order?.eventId}&event_code=${order?.event_code}&source=ecom`
                    )
                  : ""
              }
            /> */}
            {order?.product_type === "videos" ? (
              <div className="image-icons">
                <div className="cart-timer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="clock-time-four"
                      d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                      transform="translate(-2 -2)"
                      fill="#fff"
                    />
                  </svg>
                  <p>{order?.course_duration}</p>
                  {/* <p>{order?.durationUnit}</p> */}
                </div>
                <div className="cart-timer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="eye-circle"
                      d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                      transform="translate(-2 -2)"
                      fill="#fff"
                    />
                  </svg>
                  <p>
                    {order?.course_views}
                    {order?.course_views > 1 ? (
                      <span>&nbsp;Views</span>
                    ) : (
                      <span>&nbsp;View</span>
                    )}
                  </p>
                </div>
              </div>
            ) : order?.product_type === "membership" ? (
              ""
            ) : (
              order?.product_type != "session" &&
              order?.product_type !== "certificate" && (
                <div className="image-icons">
                  <div className="cart-timer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        id="clock-time-four"
                        d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                        transform="translate(-2 -2)"
                        fill="#fff"
                      />
                    </svg>
                    <p>{order?.course_duration}</p>
                    {/* <p>{order?.course_period}</p> */}
                  </div>
                  <div className="cart-timer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        id="eye-circle"
                        d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                        transform="translate(-2 -2)"
                        fill="#fff"
                      />
                    </svg>
                    <p>
                      {order?.course_views}
                      {order?.course_views > 1 ? (
                        <span>&nbsp;Views</span>
                      ) : (
                        <span>&nbsp;View</span>
                      )}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        <div className="desc-content">
          <div className="wrapper-desc">
            <p
              className="cursor-pointer"
              dangerouslySetInnerHTML={{
                __html: order?.product_name,
              }}
              onClick={() =>
                redirectToPage(order)
                // order?.product_type === "course" &&
                // (order?.list === undefined || order?.list.length < 2)
                //   ? goToModuleDetail(order?.course_code)
                //   : order?.product_type === "videos"
                //   ? history.push(`/video-view-page/${order?.product_id}?source=xsel&code=${order.course_code}`)
                //   : order?.product_type === "session"
                //   ? history.push(
                //       `/event-detail?event_id=${order?.eventId}&event_code=${order?.event_code}&source=ecom`
                //     )
                //   : ""
              }
            ></p>
            <div className="desc-wrap content-cart">
              <h2>
                ${order?.selling_price?.split(".")[0]}
                <sup>
                  {order?.selling_price?.split(".")[1]
                    ? order?.selling_price?.split(".")[1]
                    : ""}
                </sup>
                &nbsp;CAD
              </h2>
              {
                //     <div className="button-reset">
                //     <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                //         id="Component_80_1" data-name="Component 80 – 1" width="30" height="30" viewBox="0 0 30 30">
                //         <defs>
                //             <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5"
                //                 gradientUnits="objectBoundingBox">
                //                 <stop offset="0" stop-color="#00b7f1" />
                //                 <stop offset="1" stop-color="#ed156e" />
                //             </linearGradient>
                //         </defs>
                //         <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15"
                //             fill="url(#linear-gradient)" />
                //         <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13"
                //             transform="translate(2 2)" fill="#fff" />
                //         <path id="share-variant"
                //             d="M15,13.265a2.33,2.33,0,0,0-1.568.616l-5.7-3.32A2.619,2.619,0,0,0,7.8,10a2.619,2.619,0,0,0-.072-.56l5.64-3.288A2.395,2.395,0,1,0,12.6,4.4a2.619,2.619,0,0,0,.072.56L7.032,8.249a2.4,2.4,0,1,0,0,3.5l5.7,3.32a2.272,2.272,0,0,0-.064.528A2.336,2.336,0,1,0,15,13.265Z"
                //             transform="translate(3.799 5.035)" fill="var(--color-element-1)" />
                //     </svg>
                // </div>
              }
            </div>
          </div>
          <div className="d-flex inner-content-order">
            {order?.product_type === "course" && (
                order?.list.length > 0 ?
                ""
                :
                <p>
                  {order?.course_lessons}{" "}
                  <span>Module{order?.course_lessons > 1 ? "s" : ""}</span>{" "}
                </p>
            )}
          </div>
          <div className="order-content">
            <div className="order-text">
              <p>
                Order placed on{" "}
                <span>
                  {orderDate ? moment(orderDate).format("Do MMM YYYY") : ""}
                </span>{" "}
              </p>
              <p>ORDER# XSEL_{orderNumber}</p>
            </div>
            <div className="order-cart">
              <button
                className="btn-order btn-primary"
                onClick={() => {
                  setConfirmModal(true);
                  setOrderId(orderInfo.order_id);
                }}
              >
                View Order Details
              </button>
              <InvoiceShowModal
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
                order={parent}
              />
              <button
                className="btn-order btn-primary"
                onClick={() => {
                  setConfirmModal(true);
                  setOrderId(orderInfo.order_id);
                }}
              >
                Download Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCourseCard;
