import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import VideoPlayer from "react-video-js-player";
import ConfirmModal from "../modal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addArchiveVideoReq,
  addArchiveVideoReqClear,
  assignRolePlayData,
  getAWSStatusRolePlay,
  getAWSStatusRolePlayClear,
  getRolePlayRecordedData,
  getRolePlayRecordedDataClear,
  relatedRolePlayList,
  relatedRolePlayListClear,
  setParticipateFormValue,
  submitParticipateForm,
  submitParticipateFormClear,
} from "../../redux/roleplay/actions";
import { getWordSpeedType, SessionKeys } from "../../utils/AppUtils";
import { userDetail } from "../../redux/user/actions";
import Spinner from "react-bootstrap/Spinner";
import WebcamCapture from "./WebcamCapture";
import { scroller } from "react-scroll";
import { io } from "socket.io-client";
import RolePlayVideoCard from "./RolePlayVideoCard";
import * as $ from "jquery";
// import FineUploaderTraditional from "fine-uploader-wrappers";
// import Gallery from "react-fine-uploader";
import CONFIG from "../../utils/config";
import FineUploaderVideoComponent from "./FineUploaderVideoComponent";
import FineUploaderAudioComponent from "./FineUploaderAudioComponent";
import { useHistory } from "react-router-dom";

const RolePlayVideoRecord = (props) => {
  const {
    roleData,
    isLoading,
    setIsLoading,
    setShowModalMsg,
    setConfirmModal,
    listing,
    setLoaderMsg,
    rolePlayTabName
  } = props;

  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const stopBtnRef = useRef(null);
  const history = useHistory();
  const mediaRecorderRef = useRef(null);
  const webcamCaptureRef = useRef(null);

  const authBroker = useSelector((state) => state.auth.authBroker);
  const userDetailRes = useSelector((state) => state.user.userBroker);
  const participateForm = useSelector(
    (state) => state.rolePlay.participateForm
  );
  const archiveVideoAdded = useSelector(
    (state) => state.rolePlay.addArchiveVideo
  );
  const relatedRolePlayArr = useSelector(
    (state) => state.rolePlay.relatedRolePlayList
  );
  const checkAWSStatus = useSelector((state) => state.rolePlay.checkAWS)
  const videoDataRolePlay = useSelector((state) => state.rolePlay.getVideoDataRolePlay)
  const recordedDataRolePlay = useSelector((state) => state.rolePlay.recordedDataRolePlay)

  const [roleListing] = useState(true);
  const [roleInfo, setRoleInfo] = useState({});
  const [roleInfo_roleplay_url, setRoleInfoVideoUrl] = useState('');
  const [coachName, setCoachName] = useState('')
  const [playVideo, setPlayVideo] = useState(false);
  const [submitBtnName, setSubmitBtnName] = useState('Submit')
  const [videoInstance, setVideoInstance] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [archiveId, setArchiveId] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [startRecording, setStartRecording] = useState(false);
  const [recordedURL, setRecordedURL] = useState("");
  const [uploadedData, setUploadedData] = useState("");
  const [tempFile, setTempFile] = useState("");
  const [formTextArea, setFormTextArea] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [recordedFile, setRecordedFile] = useState("");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [startCapture, setStartCapture] = useState(false);
  const [timer, setTimer] = useState("05:00");
  const [recordTimer, setRecordTimer] = useState("");
  const [relatedList, setRelatedList] = useState([]);
  const [socket, setSocket] = useState();
  const [mp4fileName, setMp4FileName] = useState("");
  const [duration, setDuration] = useState("");
  const [fetchInterval, setFetchInterval] = useState('')
  const [analytics, setAnalytics] = useState({
    wpm: 0
  });
  const [keywordAnalysis, setKeywordAnalysis] = useState([])
  const [selectedRP, setSelectedRP] = useState("");
  const [positiveValue, setPositiveValue] = useState("0.00");
  const [negativeValue, setNegativeValue] = useState("0.00");
  const [neutralValue, setNeutralValue] = useState("0.00");
  const [overallScore, setOverallScore] = useState('0');
  const [videoId, setVideoId] = useState("")
  const [canCallAPI, setCanCallAPI] = useState(true)
  const [deleteUploadFile, setDeleteUploadFile] = useState(false)
  const [videoConstraints] = useState({
    facingMode: { exact: "environment" },
  });
  const [uploadResponse, setUploadResponse] = useState('')
  const [coachSubmit, setCoachSubmit] = useState(false)
  const [checkAWSCount, setCheckAWSCount] = useState(0)
  const [chunkNumber, setChunkNumber] = useState(0);
  const [uploadType, setUploadType] = useState('video')

  var noSavedRecord = "No Saved Recordings Yet.";
  var noList = "No List";

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      setCoachName(authBroker.payload.user.coach ? authBroker.payload.user.coach : '')
    }
  }, [authBroker])

  useEffect(() => {
    setRoleInfo({})
  }, [rolePlayTabName])

  useEffect(() => {
    if (deleteUploadFile) {
      setDeleteUploadFile(false)
    }
  }, [deleteUploadFile])

  useEffect(() => {
    // console.log('uploadResponse',uploadResponse)
    if (!_.isEmpty(uploadResponse) && isUploaded) {
      setCheckAWSCount(0)
      addArchiveVideoData();
      videoInstance.src("");
      // videoInstance.src("https://cdn-stg.niit-mts.com/rsp/media/leadersedgetraining_advertising_and_privacy.m3u8");
      setRecordedURL('')
      setUploadType(uploadResponse.type)
    }
  }, [uploadResponse, isUploaded])

  useEffect(() => {

  })

  useEffect(() => {
    setSocket(
      io(CONFIG.ROLEPLAY_SOCKET_URL, {
        cors: {
          origin: "*",
        },
      })
    );
    return () => {
      clearInterval(recordTimer);
      if (!_.isEmpty(socket)) {
        socket.off("connection", () => {
          // console.log("RANDOM Disconnect to socket");
        });
        socket.off("disconnect", () => {
          // console.log("RANDOM Disconnect", socket.id); // undefined
        });
        socket.off("newvideoprocessing", () => {
          // console.log("RANDOM Disconnect", socket.id); // undefined
        });
        socket.disconnect()
        setSocket("");
        
      }
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (recordedURL) {
  //     $(".video-js").css('opacity', ``)
  //     $(".video-js").removeClass('opacity-0')
  //   } else {
  //     $(".video-js").css('opacity', 0)
  //     $(".video-js").addClass('opacity-0')
  //   }
  // }, [recordedURL])

  useEffect(() => {
    if (
      recordedURL &&
      positiveValue &&
      negativeValue &&
      neutralValue &&
      duration
    ) {
      setIsLoading(false);
    }
  }, [recordedURL, positiveValue, negativeValue, neutralValue, duration]);

  useEffect(() => {
    if (roleInfo_roleplay_url) {
        setPlayVideo(true);
    }
  }, [roleInfo_roleplay_url]);

  useEffect(() => {
    if (!_.isEmpty(selectedRP)) {
      // console.log('SELECTEDRP', selectedRP);
      setRoleInfo({});
      setIsLoading(true)
      setRecordedChunks([]);
      setRecordedURL("");
      setDuration("");
      setAnalytics({
        wpm: 0,
        smile: "0.00%",
        expressionLess: "0.00%",
        unamused: "0.00%",
      });
      const role = _.clone(selectedRP);
      role.isEditable = true;
      
      setRoleInfoVideoUrl('');
      setPlayVideo(false);
      setTimeout(() => {
        setRoleInfoVideoUrl(selectedRP.roleplay_url);
        setRecordedURL(selectedRP.roleplay_url);
        if (videoInstance) {
          videoInstance.src(selectedRP.roleplay_url);
        }
      }, 5 * 1000);
      if (!_.isEmpty(selectedRP.feedbackData)) {
        setSubmitBtnName('Re-Submit Selected')
      } else {
        setSubmitBtnName('Submit Selected')
      }
      setRoleInfo(selectedRP);
      setIsEditable(true);
      setDuration(selectedRP.roleplay_duration);
      let analyticsData = _.clone(analytics);
      analyticsData.wpm = selectedRP.roleplay_wpm;
      setAnalytics(analyticsData);
      setKeywordAnalysis(selectedRP.roleplay_keyword_analysis);
      setPositiveValue(selectedRP.roleplay_sentiment_positive);
      setNegativeValue(selectedRP.roleplay_sentiment_negative);
      setNeutralValue(selectedRP.roleplay_sentiment_neutral);
      setFormTitle(selectedRP.title)
      setFormTextArea(selectedRP.additionalComment);
      let score = 0;
      for (let keyword of selectedRP.roleplay_keyword_analysis) {
        score = score + _.toNumber(keyword.wt)
      }
      if (score == 0) {
        setOverallScore(score)
      } else {
        setOverallScore((score/selectedRP.roleplay_keyword_analysis.length).toFixed(2))
      }
    }
  }, [selectedRP]);

  // useEffect(() => {
  //   console.log('Video Instance useEffect', videoInstance)
  //   if (videoInstance) {
  //     videoInstance.src(recordedURL);
  //   }
  // }, [videoInstance, recordedURL])

  useEffect(() => {
    if (!_.isEmpty(socket)) {
      // Recevied Data from websocket
      socket.on("video_attr_update", (response) => {
        //console.log("video attr update", response);
        // if (response.attribute === "data-video-url") {
        //   let url = response.value;
        //   checkAWSUploadedStatus(videoId)
        //   // setRoleInfoVideoUrl(url);
        //   // setRecordedURL(url);
          
        //   url = url.replace(
        //     CONFIG.IN_BUCKET_URL,
        //     CONFIG.OUT_BUCKET_URL
        //   );
        //   url = url.replace("mp4", "m3u8");
        //   // setRoleInfoVideoUrl(url)
        //   // https://cdn-stg.niit-mts.com/xsel/media/kr6dar14mjwi019fxv7s/archive.m3u8
        //  // console.log("video attr update", url);
        //   let fetchInv = setInterval(() => {
        //     fetch(url)
        //     .then(response => {
        //       // console.log('FETCH RES', response)
        //       if (response.status === 200) {
        //         // console.log('FETCH SUCCESS', response)
        //         setRecordedURL(url)
        //         setRoleInfoVideoUrl(url);
        //         if (videoInstance) {
        //           videoInstance.src({type: 'application/x-mpegURL', src: url});
        //         }
        //         clearInterval(fetchInv)
        //       }
        //     })
        //     .catch((err) => {
        //      // console.log('FETCH ERR', err)
        //     })
        //   }, 1000)
        //   // setTimeout(() => {
        //     // console.log('Video Instance', videoInstance)
        //     // if (videoInstance) {
        //     //   videoInstance.src({type: 'application/x-mpegURL', src: url});
        //     // }
        //   // }, 10 * 1000);
        //   // setTimeout(() => {
        //   //   setRecordedURL(url);
        //   // }, 12*1000)
        // }
        // // Setting sentiments icons
        // if (response.attribute == "data-sentiment-negative") {
        //   // console.log("data-sentiment-negative", Math.round(response.value));
        //   setNegativeValue(response.value);
        // }
        // if (response.attribute == "data-sentiment-positive") {
        //   // console.log("data-sentiment-positive", Math.round(response.value));
        //   setPositiveValue(response.value);
        // }
        // if (response.attribute == "data-sentiment-neutral") {
        //   // console.log("data-sentiment-neutral", Math.round(response.value));
        //   setNeutralValue(response.value);
        // }
        // if (response.attribute === "data-duration") {
        //   setDuration(response.value);
        // }
        // if (response.attribute === "data-wpm") {
        //   let analyticsData = _.clone(analytics);
        //   analyticsData.wpm = response.value;
        //   setAnalytics(analyticsData);
        // }

        if (response.attribute === "data-processing") {
          if (response.value == 2) {
            // if (videoInstance) {
            //   setRecordedURL(roleInfo_roleplay_url);
            //   videoInstance.src({type: 'application/x-mpegURL', src: roleInfo_roleplay_url});
            // }
          } else if (response.value == 3) {
            setIsLoading(false);
            // window.scrollTo(0, 0);
            setConfirmModal(true);
            setRecordedChunks([]);
            setCanCallAPI(false)
            setIsEditable(false)
            setShowModalMsg({error: true, message: "Error processing, please try again."});
          }
        }
      });
      socket.on("setCurrentVideoSelected", (response) => {
        //console.log('FETCH', response)
      })
      socket.io.on("error", (error) => {
        console.log('error connecting', error)
        // setConfirmModal(true)
        // setCanCallAPI(false)
        // setShowModalMsg({error: true, message: "Error connecting to server."});
      })
      socket.io.on("disconnect", (reason) => {
        console.log('socket server disconnected', reason)
      })
      return () => {
        socket.off("video_attr_update", () => {})
        socket.off("setCurrentVideoSelected", () => {})
      }
    }
  }, [socket, videoInstance]);

  useEffect(() => {
    // console.log("userDetailRes: ", userDetailRes);
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
      setUserDetails(userDetailRes?.payload?.payload);
    }
  }, [userDetailRes]);

  useEffect(() => {
    if (!_.isEmpty(roleData)) {
      // console.log("setRole Data", roleData);
      dispatch(assignRolePlayData({
        module: "assignRolePlay",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          entity_id: roleData.id,
          entity_type: 'roleplay',
          comment: roleData.title,
          origin: 'broker',
          is_type: 'free'
        }
      }))
      setRoleInfo({});
      resetData();
      setConfirmModal(false)
      setTimeout(() => {
        // setIsLoading(false)
        setRoleInfo(roleData);
        setKeywordAnalysis(roleData.keywords.map(keyword => {
          return {
            kw: keyword.kw,
            wt: 0,
            wt_og: keyword.wt
          }
        }))
        getRelatedList();
        if (roleData.isEditable) {
          setIsEditable(true);
          setRecordedURL(roleData.roleplay_url);
          setDuration(roleData.roleplay_duration);
          let analyticsData = _.clone(analytics);
          analyticsData.wpm = roleData.roleplay_wpm;
          setAnalytics(analyticsData);
          setPositiveValue(roleData.roleplay_sentiment_positive);
          setNegativeValue(roleData.roleplay_sentiment_negative);
          setNeutralValue(roleData.roleplay_sentiment_neutral);
          setFormTextArea(roleData.additionalComment);
        } else {
          setIsEditable(false);
        }
        setFormTitle(roleData.title)
        scroller.scrollTo("role-play-video", {
          duration: 50,
          delay: 5,
          smooth: "easeInOutQuart",
        });
      }, 500);
    }
  }, [roleData]);

  useEffect(() => {
    // console.log("Related", relatedRolePlayArr);
    if (!_.isEmpty(relatedRolePlayArr)) {
      if (relatedRolePlayArr.status === "success") {
        const data = relatedRolePlayArr.payload.payload;
        // console.log("Related Array", data);
        if (_.isArray(data)) {
          let newArr = []
          for (let role of data) {
            role.videoComponent = () => {
              return (
                <VideoPlayer
                    className="img-videosec learn_image-card video-type-select"
                    controls={true}
                    src={role.roleplay_url}
                    type="video/mp4"
                    width="720"
                    height="420"
                    autoPictureInPicture={true}
                    // poster={""}
                    fluid={true}
                    inline={false}
                    pictureInPictureToggle={true}
                    // playbackRates={[]}
                />  
              )
            }
            let totalPoint = 0;
            if (_.isArray(role.feedback_data)) {
              let data = role.feedback_data[0];
              totalPoint = calTotalPoint(data)
            } else if (!_.isEmpty(role.feedback_data)) {
              let data = role.feedback_data
              totalPoint = calTotalPoint(data)
            }
            newArr.push({
              roleplay_id: role.roleplay_id,
              title: role.roleplay_title,
              situation: role.situation,
              description: role.description,
              releaseDate: role.release_date,
              duration: role.duration,
              durationUnit: role.durationUnit,
              userFirstName: role.user_first_name,
              userLastName: role.user_last_name,
              type: role.uploaded_type,
              originalType: role.type,
              url: role.url,
              originalURL: role.url,
              thumbnail: role.thumbnail,
              uploaded_file: role.roleplay_video_or_doc_path,
              submissionDate: role.submission_dt,
              createdDate: role.created_dt,
              brokerageId: role.brokerage_id,
              userId: role.user_id,
              role_play_id: role.reference_video_id,
              uploadedType: role.uploaded_type,
              submitToCoach: _.toNumber(role.submit_to_coach),
              coach_id: role.coach_id,
              additionalComment: role.additional_comment,
              roleplay_category: role.roleplay_category,
              roleplay_url: role.roleplay_url,
              roleplay_wpm: role.roleplay_wpm,
              roleplay_duration: role.roleplay_duration,
              roleplay_sentiment_positive: role.roleplay_sentiment_positive,
              roleplay_sentiment_negative: role.roleplay_sentiment_negative,
              roleplay_sentiment_neutral: role.roleplay_sentiment_neutral,
              roleplay_keywords: role.roleplay_keywords ? JSON.parse(role.roleplay_keywords) : [],
              roleplay_keyword_analysis: role.roleplay_keyword_analysis ? JSON.parse(role.roleplay_keyword_analysis) : [],
              roleplay_score: totalPoint,
              // review_status: role.review_status,
              // awareness_star: role.awareness_star,
              // research_star: role.research_star,
              // impact_star: role.impact_star,
              // empathy_star: role.empathy.start,
              // roleplay_score: role.awareness_star + role.research_star + role.impact_star + role.empathy_star,
              feedbackData: _.isArray(role.feedback_data) ? role.feedback_data[0] : role.feedback_data
            })
            // setKeywordAnalysis(role.roleplay_keyword_analysis)
          }
          setRelatedList(newArr);
        }
      }
      setIsLoading(false);
      dispatch(relatedRolePlayListClear());
    }
  }, [relatedRolePlayArr]);

  // useEffect(() => {
  //     if (recordedChunks.length) {
  //         console.log('RECORDED CHUNKS', recordedChunks)
  //         const blob = new Blob(recordedChunks, {
  //           type: "video/mp4"
  //         });
  //         const fileName = `xsel-video-${moment().unix()}-${userDetails.firstname}-${roleInfo.title}.mp4`
  //         const url = URL.createObjectURL(blob);
  //         // console.log('URL', url)
  //         // setRecordedURL(url)
  //         setRecordedFile(new File([blob], fileName, { lastModified: new Date().getTime(), type: "video/mp4" }))
  //         setUploadedData('')
  //     }
  // }, [recordedChunks])

  useEffect(() => {
    if (!_.isEmpty(archiveVideoAdded)) {
      if (archiveVideoAdded.status === "success") {
        setCanCallAPI(true)
        setCheckAWSCount(0)
        const archiveData = archiveVideoAdded.payload.payload;
      //  console.log('ARCHIVE DATA', archiveData)
        //                 {"status":true,"msg":"Success","data":"63512eb36e84fa6220455d42","mid":"63512eb36e84fa6220455d42","title":"Prospecting
        // scenario 1.1 -T1","rp_keywords":null}
        let data = {
          archiveId: archiveId,
          chunkNumber: sessionStorage.getItem("chunkNumber"),
          country: "",
          mapId: roleInfo.role_play_id,
          rp_keywords: archiveData.rp_keywords, //roleInfo.roleplay_keywords,
          takeIndex: archiveData?.takeIndex ? archiveData.takeIndex : 0,
          username: authBroker.payload.user.username,
          videoId: archiveData.videoId,
        };
        if (!isUploaded) {
          socket.emit("newvideoprocessing", data);
          checkAWSUploadedStatus(archiveData.videoId)
        } else {
          checkAWSUploadedStatus(archiveData.videoId)
        }
        setVideoId(archiveData.videoId)
        // console.log("newvideoprocessing", data);
      } else {
        setIsLoading(false)
        setConfirmModal(true);
        setRecordedChunks([]);
        setCanCallAPI(false)
        setIsEditable(false)
        setShowModalMsg({error: true, message: "Error processing, please try again."});
      }
      dispatch(addArchiveVideoReqClear());
    }
  }, [archiveVideoAdded]);

  useEffect(() => {
    // console.log("PARTICIPATE FORM", participateForm);
    if (!_.isEmpty(participateForm)) {
      setIsSaveLoading(false);
      setIsSubmitLoading(false);
      if (participateForm.status === "success") {
        // console.log('MSG TO SET FOR SUBMISSION')
        
        setRecordedURL("");
        setRecordedChunks([])
        setTimer("05:00");
        setStartCapture(false)
        setFormTextArea('')
        getRelatedList();
        setConfirmModal(true);
        setFormTitle(roleData.title)
        // if (coachSubmit) {
        //   setShowModalMsg({error: false, message: "Thank you for participating in Role play."});
        // } else if (isEditable) {
        //   setShowModalMsg({error: false, message: "Successfully updated."});
        // } else {
        //   setShowModalMsg({error: false, message: "Successfully saved."});
        // }
        if (coachSubmit || isEditable) {
          dispatch(setParticipateFormValue({
            isCoachSubmit: coachSubmit,
            isEditable: isEditable,
            isSaved: !isEditable && !coachSubmit
          }))
          history.push('/my-role-plays')
        } else {
          setShowModalMsg({error: false, message: "Successfully saved."});
          dispatch(submitParticipateFormClear());
        }
        setIsEditable()
      } else {
        setConfirmModal(true);
        setShowModalMsg({error: true, message: "Error! Try again later."});
        dispatch(submitParticipateFormClear());
      }
    }
  }, [participateForm]);

  useEffect(() => {
    // console.log('CHECK AWS Status', checkAWSStatus)
    if (!_.isEmpty(checkAWSStatus)) {
      if (checkAWSStatus.status === 'success') {
        const data = checkAWSStatus.payload.payload;
        if (_.toNumber(data.video_processing_status) === 3) {
          setIsLoading(false)
          setConfirmModal(true);
          setRecordedChunks([]);
          setCanCallAPI(false)
          setIsEditable(false)
          setShowModalMsg({error: true, message: "Error processing, please try again."});
          setUploadResponse('')
        } else {
          if (_.toNumber(data.is_uploaded_aws) === 1) {
            // console.log('call next method')
            checkRecordedStatus(videoId)
            const url = data.media_url;
            setDeleteUploadFile(true)
            // setRecordedURL(url)
            // setRoleInfoVideoUrl(url);
            // if (videoInstance) {
            //   videoInstance.src({type: 'application/x-mpegURL', src: url});
            // }
            let fetchInv = setInterval(() => {
              fetch(url)
              .then(response => {
                // console.log('FETCH RES', response)
                if (response.status === 200) {
                  // console.log('FETCH SUCCESS', response)
                  setRecordedURL(url)
                  setRoleInfoVideoUrl(url);
                  if (videoInstance) {
                    videoInstance.src({type: 'application/x-mpegURL', src: url});
                  }
                  clearInterval(fetchInv)
                  if (!isUploaded) {
                    setLoaderMsg("Your recording has been processed.")
                  } else {
                    setLoaderMsg("Your file has been processed.")
                  }
                  setTimeout(() => {
                    setLoaderMsg('')
                    setIsLoading(false)
                  }, 2000)
                }
              })
              .catch((err) => {
               // console.log('FETCH ERR', err)
              })
            }, 1000)
          } else {
            checkAWSUploadedStatus(videoId)
          }
        }
      } else {
        setIsLoading(false)
        setConfirmModal(true);
        setRecordedChunks([]);
        setCanCallAPI(false)
        setIsEditable(false)
        setShowModalMsg({error: true, message: "Error processing, please try again."});
      }
      dispatch(getAWSStatusRolePlayClear())
    }
  }, [checkAWSStatus])

  useEffect(() => {
    // console.log('Recorded data roleplay', recordedDataRolePlay);
    if (!_.isEmpty(recordedDataRolePlay)) {
      if (recordedDataRolePlay.status === 'success') {
        const data = recordedDataRolePlay.payload.payload;
        setVideoAnalyticsData(data);
        setUploadResponse('')
      } else {
        setIsLoading(false)
        setCanCallAPI(false)
        setIsEditable(false)
        setShowModalMsg({error: true, message: "Error processing, please try again."});
      }
      // setIsUploaded(false)
      // setIsLoading(false)
      dispatch(getRolePlayRecordedDataClear())
      
    }
  }, [recordedDataRolePlay])

  const checkAWSUploadedStatus = (videoId) => {
    // console.log('AWS count', checkAWSCount)
    setTimeout(() => {
      // if (checkAWSCount <= 40) {
      if (true) {
        dispatch(getAWSStatusRolePlay({
          module: "roleplayNiitAdmin",
          postData: {
            module: 'get_aws_media_status',
            rec_mid: videoId
          }
        }))
        let count = checkAWSCount + 1;
        setCheckAWSCount(count)
      } else {
        setIsLoading(false)
        setConfirmModal(true);
        setRecordedChunks([]);
        setCanCallAPI(false)
        setDeleteUploadFile(true)
        setShowModalMsg({error: true, message: "Error processing, please try again."});
      }
    }, 4000)
  }

  const checkRecordedStatus = (videoId) => {
    dispatch(getRolePlayRecordedData({
      module: "roleplayNiitAdmin",
      postData: {
        module: "checkRecordedVideoStatus",
        channelId: videoId,
        username: localStorage.getItem(SessionKeys.EMAIL_SESSION)
      }
    }))
  }

  const setVideoAnalyticsData = (data) => {
    const videoInfo = data.videoInfo;
    if (!_.isEmpty(videoInfo) && data.video_processing_status === 2) {
      const durationArray = videoInfo.duration.split(":")
      const duration = `${durationArray[durationArray.length-2]}:${durationArray[durationArray.length-1]}`
      const negative = data.sent_neg;
      const positive = data.sent_pos;
      const neutral = data.sent_neutral;
      const mixed = data.sent_mixed;
      const wpm = videoInfo.wpm;
      const scoreValue = data.score_val;
      let analyticsData = _.clone(analytics);
      analyticsData.wpm = wpm;
      setAnalytics(analyticsData);
      setPositiveValue(positive);
      setNegativeValue(negative);
      setNeutralValue((_.toNumber(neutral) + _.toNumber(mixed)).toFixed(2));
      setDuration(duration);
      let score = 0;
      let total_wt = 0;
      if (data.matching_score && !_.isEmpty(data.matching_score)) {
        let keywordData = _.clone(keywordAnalysis);
        for (let keyword of keywordData) {
          
          for (let score of data.matching_score) {
            if (score.kw.toLowerCase() === keyword.kw.toLowerCase()) {
              keyword.wt = _.toNumber(score.wt);
            }
          }
        }
        for (let keyword of keywordData) {
          score = score + _.toNumber(keyword.wt)
          total_wt = total_wt + _.toNumber(keyword.wt_og);
        }
        // console.log('KeywordData', keywordData, score, keywordData.length)
        setKeywordAnalysis(keywordData)
        if (score === 0) {
          setOverallScore(0)
        } else {
          setOverallScore((score/keywordData.length).toFixed(2))
        }
      }
    }
    
  }

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `xsel-${roleInfo.title}.mp4`;
      a.click();
      window.URL.revokeObjectURL(url);
      // setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const resetData = () => {
    // setIsLoading(true)
    setRecordedChunks([]);
    setRecordedURL("");
    setDuration("");
    setAnalytics({
        wpm: 0
    });
    setPositiveValue("0.00");
    setNegativeValue("0.00");
    setNeutralValue("0.00");
    setFormTextArea("");
    setStartCapture(false)
    setStartRecording(false)
    setRelatedList([]);
  }

  const startTimer = (duration, display) => {
    var timer = duration,
      minutes,
      seconds;
    setRecordTimer(
      setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        // console.log('STOP RECORD TIMER', timer)
        // setTimer(minutes + ":" + seconds);
        setTimer(`${minutes}:${seconds}`);
        // if (startCapture) {
        //   setTimer("05:00");
        //   clearInterval(recordTimer);
        // }
        // if( $("#stop").data('stopped') == '1' ){
        //     clearInterval(recordTimer);
        //     // delete recordTimer;
        //     timer = 0;
        //     console.log("clearInterval before 0 sec")
        // }else{
        //     if( timer <= 0){
        //     clearInterval(recordTimer);
        //     // delete recordTimer;
        //     console.log("clearInterval= 0 sec")
        //     stopRecord();
        //     }
        // }
        if (timer <= 0) {
          clearInterval(recordTimer)
          // console.log('STOP TIMER')
        }
        if (--timer < 0) {
          clearInterval(recordTimer);
          // console.log('STOP - CLEAR INTERVAL')
          // console.log('STOP TIMER RECORDING')
          stopBtnRef.current.click();
          // setTimer("05:00")
          // handleClick();
          // addArchiveVideoData();
          timer = duration;
          // addArchiveVideoData();
          // handleClick();
          // setTimer("05:00");
          
        }
      }, 1000)
    );
  };

  const getRelatedList = () => {
    dispatch(
      relatedRolePlayList({
        module: "roleplayRelateList",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          video_id: roleData.role_play_id,
        },
      })
    );
  };

  const stopRecording = () => {
    addArchiveVideoData();
    handleClick();
    setTimer("05:00");
    clearInterval(recordTimer);
    setLoaderMsg("Your recording is in process, please wait...")
  }

  const addArchiveVideoData = () => {
    // console.log("AddArchiveVideo", archiveId, isUploaded);
    let customVideoUploadName = "";
    if (archiveId || isUploaded) {
      setIsLoading(true);
      if (recordedChunks.length) {
        // console.log("RECORDED CHUNKS", recordedChunks);
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        const fileName = `xsel-video-${moment().unix()}-${
          userDetails.firstname
        }-${roleInfo.title}.mp4`;
        const url = URL.createObjectURL(blob);
        // console.log('URL', url)
        // setRecordedURL(url)
        setRecordedFile(
          new File([blob], fileName, {
            lastModified: new Date().getTime(),
            type: "video/mp4",
          })
        );
        setUploadedData("");
      }

      let fileURL = "";
      let uploadedMediaType = "";
      let fileName = ""
      if (uploadResponse) {
        uploadedMediaType = uploadResponse.type; // set upload media type
        customVideoUploadName = uploadResponse.uploadName;
        fileName = uploadResponse.mediaNameWithoutExt
      } else {
        fileURL = archiveId + "/archive.m3u8";
        fileName = archiveId;
      }
      // console.log("AddArchiveVideo", fileURL);
      let postData = {
        module: "addArchivedVideo",
        filename: fileName,
        fileURL: fileURL,
        UrlReferrer: document.referrer,
        title: roleInfo.title,
        archiveID: archiveId,
        mapId: roleInfo.role_play_id,
        totalVideoChunks: uploadedMediaType ? 0 : sessionStorage.getItem("chunkNumber"),
        username: authBroker.payload.user.username,
        fullname: `${authBroker.payload.user.firstname} ${authBroker.payload.user.lastname}`,
        uploaded_media_type: uploadedMediaType,
        custom_video_uploadName: customVideoUploadName,
      };
      // console.log("AddArchiveVideo", postData);
      dispatch(
        addArchiveVideoReq({
          module: "roleplayNiitAdmin",
          postData,
        })
      );
    }
  };

  const calTotalPoint = (data) => {
    return _.toNumber(data.awareness_star) + _.toNumber(data.research_star) + _.toNumber(data.impact_star) + _.toNumber(data.empathy_star)
  }

  // Send data to backend when user selects on participating 
  const setFormData = (type) => {
    // console.log(
    //   "Check uploaded",
    //   uploadedData,
    //   recordedURL,
    //   type,
    //   roleInfo,
    //   formTitle
    //   // selectedRP
    // );
    // console.log(keywordAnalysis)
    if (!recordedURL) {
      setMsg("Please record video.");
      return;
    } else if (!formTitle) {
      setMsg('Title is mandatory');
      return;
    }
    let formData = new FormData();
    if (isEditable) {
      formData.append("roleplay_id", roleInfo.roleplay_id);
      if (type === 1) {
        formData.append("action", "update");
        formData.append("submit_to_coach", roleInfo.submitToCoach ? roleInfo.submitToCoach : 0);
        formData.append("coach_id", roleInfo.coach_id ? roleInfo.coach_id : "");
        if (roleInfo.submitToCoach) {
          formData.append("submission_date", roleInfo.submissionDate)
        }
        setIsSaveLoading(true);
      } else {
        if (!_.isEmpty(roleInfo.feedbackData)) {
          formData.append("action", "insert");
          formData.append("submit_to_coach", 1);
          formData.append("coach_id", userDetails.coach_id);
        } else {
          formData.append("submit_to_coach", 1);
          formData.append("coach_id", userDetails.coach_id);
          formData.append("action", "update");
          setCoachSubmit(true)
        }
        setIsSubmitLoading(true);
      }
    } else {
      formData.append("action", "insert");
      if (type === 1) {
        formData.append("submit_to_coach", 0);
        formData.append("coach_id", "");
        setIsSaveLoading(true);
      } else {
        formData.append("submit_to_coach", 1);
        formData.append("coach_id", userDetails.coach_id);
        setIsSubmitLoading(true);
        setCoachSubmit(true)
      }
    }
    
    formData.append("uid", authBroker.payload.user.user_id);
    formData.append("brokerage_id", authBroker.payload.user.company_id);
    // formData.append('roleplay_metadata', roleInfo.title)
    formData.append("reference_video_id", roleInfo.role_play_id);
    // formData.append("roleplay_title", formTitle);
    formData.append("roleplay_title", roleInfo.title);
    formData.append("situation", roleInfo.situation);
    formData.append("description", roleInfo.description);
    formData.append("duration", roleInfo.duration);
    formData.append("durationUnit", roleInfo.durationUnit);
    formData.append("thumbnail", roleInfo.thumbnail);
    formData.append("provider_id", roleInfo.provider_id);
    formData.append("type", roleInfo.type);
    formData.append("url", roleInfo.url);
    formData.append(
      "release_date",
      moment(roleInfo.releaseDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    );
    formData.append("roleplay_sentiment_negative", negativeValue);
    formData.append("roleplay_sentiment_neutral", neutralValue);
    formData.append("roleplay_sentiment_positive", positiveValue);
    formData.append("roleplay_duration", duration);
    formData.append("roleplay_wpm", analytics.wpm);
    formData.append("roleplay_learningpoint", roleInfo.roleplay_learningpoint);
    formData.append("roleplay_category", roleInfo.roleplay_category);
    formData.append(
      "roleplay_keywords",
      JSON.stringify(roleInfo.roleplay_keywords)
    );
    formData.append(
      "roleplay_keyword_analysis",
      JSON.stringify(keywordAnalysis)
    );

    // if (uploadedData) {
    //   formData.append("uploaded_file", uploadedData);
    //   formData.append(
    //     "uploaded_type",
    //     uploadedData.type === "video/mp4" ? "video" : "audio"
    //   );
    // } else 
    // if (recordedURL) {
      formData.append("roleplay_url", recordedURL);
      
    // }
    if (isUploaded) {
      formData.append("uploaded_type", uploadType);
      setIsUploaded(false)
      setUploadResponse({})
    } else {
      formData.append("uploaded_type", "video");
    }

    formData.append("additional_comment", formTextArea);

    formData.append(
      "token",
      localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
    );
    // console.log(
    //   "FORMDATA", uploadResponse
    // );
    // formData.forEach((e, i) => {
    //   console.log(i, e);
    // })


    dispatch(
      submitParticipateForm({
        module: "participateForm",
        postData: formData,
      })
    );
  };

  let handleClick = () => {};
  let handleStartRecordAgain = () => {};

  const renderAssignedVideo = () => {
      return (
          <VideoPlayer
              controls={true}
              src={roleInfo?.url !== roleData.url ? roleData.url : roleInfo.url}
              width="720"
              height="420"
              autoPictureInPicture={false}
              fluid={true}
              inline={false}
              pictureInPictureToggle={false}
              poster={roleInfo.thumbnail}
              type={
              roleInfo?.url.includes(".mp4")
                  ? "video/mp4"
                  : "application/x-mpegURL"
              }
          />
      )
  }

  const renderAssignedDetail = () => {
      return (
          <div className="player-text-desc">
              <div className="d-flex tiltebox">
                  <div className="tiltrolebox">
                  {/* <p className="titlerole">
                      <strong>Role play Title: </strong>
                      {roleInfo.title}{" "}
                  </p> */}
                  <p className="titlerole">
                      <strong>Scenario&nbsp;</strong>
                      <br /> 
                      <span dangerouslySetInnerHTML={{
                          __html: roleInfo.situation
                          }}></span>
                  </p>
                  </div>
                  {/* {roleInfo.roleplay_scenario ? 
                  <div className="pdf-download-role">
                  {" "}
                    <a href={roleInfo.roleplay_scenario} target="_blank">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                      >
                          <defs>
                          <linearGradient
                              id="linear-gradient"
                              x2="1"
                              y1="0.5"
                              y2="0.5"
                              gradientUnits="objectBoundingBox"
                          >
                              <stop offset="0" stopColor="#00b7f1"></stop>
                              <stop offset="1" stopColor="#ed156e"></stop>
                          </linearGradient>
                          </defs>
                          <circle
                          cx="15"
                          cy="15"
                          r="15"
                          fill="url(#linear-gradient)"
                          data-name="Ellipse 180"
                          ></circle>
                          <circle
                          cx="13"
                          cy="13"
                          r="13"
                          fill="#fff"
                          data-name="Ellipse 181"
                          transform="translate(2 2)"
                          ></circle>
                          <path
                          fill="#7a549e"
                          d="M5.684 8a3.015 3.015 0 01-.062-1.466c.262.003.237 1.157.062 1.466zm-.053 1.478a14.42 14.42 0 01-.887 1.959 11.507 11.507 0 011.966-.684 4.048 4.048 0 01-1.079-1.275zm-2.941 3.9c0 .025.412-.169 1.091-1.256a4.319 4.319 0 00-1.09 1.256zM7.75 5H12v10.25a.748.748 0 01-.75.75H.75a.748.748 0 01-.75-.75V.75A.748.748 0 01.75 0H7v4.25a.752.752 0 00.75.75zm-.25 5.369a3.136 3.136 0 01-1.334-1.681 4.492 4.492 0 00.194-2.006.783.783 0 00-1.494-.213 5.2 5.2 0 00.253 2.406 29.344 29.344 0 01-1.275 2.681h-.006c-.847.434-2.3 1.391-1.7 2.125a.971.971 0 00.668.319c.559 0 1.116-.562 1.909-1.931a17.813 17.813 0 012.469-.725 4.736 4.736 0 002 .609.809.809 0 00.616-1.359c-.434-.425-1.7-.3-2.3-.225zm4.281-7.087L8.719.219A.749.749 0 008.188 0H8v4h4v-.191a.748.748 0 00-.219-.528zm-2.315 7.977c.128-.084-.078-.372-1.338-.281 1.16.494 1.338.281 1.338.281z"
                          data-name="Icon awesome-file-pdf"
                          transform="translate(9 7)"
                          ></path>
                      </svg>
                    </a>
                  </div>: ''} */}
              </div>
              <p className="discrole">
                  <strong>Instructions&nbsp;</strong>
                  <br /> <span dangerouslySetInnerHTML={{
                          __html: roleInfo.description,
                          }}></span>
              </p>
          </div>
      )
  }

  const renderMyRecording = () => {
    return (
      <div className="myrecordings">
        <h5>My Recordings</h5>
        <div className="recordings-roleplay">
            {_.isArray(relatedList) ? (
            relatedList.length > 0 ? (
                relatedList.map((roleList, i) => {
                return (
                    <RolePlayVideoCard
                      key={`rolelist-${i + 1}`}
                      roleInfo={roleList}
                      setSelectedRP={setSelectedRP}
                      selectedRP={selectedRP}
                    />
                );
                })
            ) : (
                <p className="norecordings">{noSavedRecord}</p>
            )
            ) : (
            <p className="norecordings">{noList}</p>
            )}
        </div>
      </div>
    )
  }

  const renderButtons = () => {
      return (
        <>
          {/* <div className="inst-roleplay p-2">Choose any one (record a video or upload video(*.mp4) or upload an audio(*.mp3))</div> */}
          <div className="inst-roleplay p-2">Record your response by selecting the preferred mode out of the given options.</div>
          <div className="role_upload_btn edit_btn">
            <div className="form-upload-box px-2">
              <>
                <FineUploaderVideoComponent 
                  setUploadResponse={setUploadResponse} 
                  recordingStarted={startCapture}
                  setIsUploaded={setIsUploaded} 
                  deleteUploadFile={deleteUploadFile}
                  setDeleteUploadFile={setDeleteUploadFile}
                  setIsLoading={setIsLoading}
                  setLoaderMsg={setLoaderMsg}
                  type={'video'}
                />
                <FineUploaderAudioComponent 
                  setUploadResponse={setUploadResponse} 
                  recordingStarted={startCapture}
                  setIsUploaded={setIsUploaded}
                  deleteUploadFile={deleteUploadFile}
                  setDeleteUploadFile={setDeleteUploadFile}
                  setIsLoading={setIsLoading}
                  setLoaderMsg={setLoaderMsg}
                  type={'audio'}
                />
              </>
            </div>
          </div>
        </>
      )
  }

  const renderWebCam = () => {
      return (
          <WebcamCapture
              roleInfo={roleInfo}
              startCapture={startCapture}
              archiveId={archiveId}
              setArchiveId={setArchiveId}
              setStartRecording={setStartRecording}
              setStartCapture={setStartCapture}
              recordedChunks={recordedChunks}
              setRecordedChunks={setRecordedChunks}
              mp4fileName={mp4fileName}
              setMp4FileName={setMp4FileName}
              chunkNumber={chunkNumber}
              setChunkNumber={setChunkNumber}
              socket={socket}
              setSocket={setSocket}
              setRecordedFile={setRecordedFile}
              setUploadedData={setUploadedData}
              stopRecord={(stopRecord) => {
                  handleClick = stopRecord;
              }}
              startRecordAgain={(startRecordAgain) => {
                  handleStartRecordAgain = startRecordAgain;
              }}
              />
      )
  }

  const renderRecordArea = () => {
    return (
        <div className="recordebtnsec cursor-pointer">
            <div
                className="record-btn"
                onClick={() => {
                  setChunkNumber(0)
                  setStartCapture(true);
                  handleStartRecordAgain();
                  startTimer(300, 300);
                  setConfirmModal(false)
                  setShowModalMsg({error: false, message: ""})
                }}
            ></div>
            <span className="recordtext">Record Video</span>
        </div>
    )
  }

  const renderStopArea = () => {
    return (
      <div className="recordstopbtnsec">
          <div
              className="record-btn"
              onClick={stopRecording}
              ref={stopBtnRef}
          ></div>
          <span className="recordtext">Stop Recording</span>
      </div>
    )
  }

  const renderRecordPlayerArea = () => {
    // src={"https://cdn-stg.niit-mts.com/rsp/media/leadersedgetraining_advertising_and_privacy.m3u8"}
      return (
        <VideoPlayer
          id={`roleinfo-${roleInfo.roleplay_id}`}
          className={`img-videosec learn_image-card video-type-select `}
          controls={true}
          src={""}
          type="video/mp4"
          width="720"
          height="420"
          autoPictureInPicture={false}
          poster={""}
          fluid={true}
          inline={false}
          pictureInPictureToggle={false}
          playbackRates={[]}
          onReady={(instance) => setVideoInstance(instance)}
        />
      )
  }

  const renderPlayArea = () => {
      return (
        <>
          {
            startCapture 
            ? 
              <>
                {renderStopArea()}
                {renderWebCam()}
              </>
            :
            !recordedURL 
            ?
              renderRecordArea()
            :
              ""
          }
        </>
      )
  }

  // const uploader = new FineUploaderTraditional({
  //   options: {
  //       request: {
  //           method: 'POST',
  //           endpoint: CONFIG.API_URL + '/meeting/fine-uploader/endpoing-cors.php',
  //           // customHeaders: {
  //           //     Authorization: `Bearer ${accessToken}`
  //           // },
  //           filenameParam: 'name',
  //           uuidName: 'id',
  //           totalFileSizeName: 'size',
  //           // inputName,
  //       },
  //       validation: {
  //           allowedExtensions: "*",
  //           itemLimit: 5,
  //           // sizeLimit,
  //       },
  //       messages: {
  //           typeError: 'Type is not supported',
  //           noFilesError: 'You should add files',
  //       },
  //       multiple: true,
  //       callbacks: {
  //           onSubmit: async (id) => {
  //               console.log('onsubmit id', id)
  //               // The files are submitting here to the uplaoder
  //           },
  //           onComplete: (id, name, response) => {
  //               // onFilesComplete(response, uploadedFiles, setUploadedFiles, setErrorMessage);
  //           },
  //           onTotalProgress: (totalUploadedBytes, totalBytes) => {
  //             console.log('onTotalProgress', totalUploadedBytes, totalBytes)
  //               // const totalProgress = Math.round((totalUploadedBytes * 100) / totalBytes);
  //               // setProgress(totalProgress);
  //           },
  //           onError: (id, name, errorReason) => {
  //             console.log('error', errorReason)
  //               // setErrorMessage(errorReason);
  //           },
  //       },
  //       chunking: {
  //         enabled: true,
  //         concurrent: {
  //           enabled: true
  //         },
  //         success: {
  //           endpoint: CONFIG.API_URL + '/meeting/fine-uploader/endpoing-cors.php?done'
  //         }
  //       },
  //       resume: {
  //         enabled: true
  //       },
  //       autoUpload: false,
  //       debug: true,
  //   },
  // });

  return (
      <>
      <section className="door-knowking pb-0" id="role-play-video">
          <div className="container">
          {!_.isEmpty(roleInfo) && roleListing ? (
              <>
              <div className="row">
                  <div className="col-md-12">
                  <div className="videobox row">
                      <div className="col-md-12">
                      <h5
                          dangerouslySetInnerHTML={{
                          __html: roleInfo.title,
                          }}
                      ></h5>
                      </div>
                      <div className="col-md-6">
                      <div className="videoplayer">
                          {renderAssignedVideo()}
                          {/* {roleInfo.type === "video" ? (
                          ) : (
                          <img src="/images/pdf.svg" alt={roleInfo.title} />
                          )} */}
                      </div>
                      </div>
                      <div className="col-md-6">
                      {renderAssignedDetail()}
                      </div>
                  </div>
                  </div>
              </div>
              <div className="row">
                  
                  <div className="col-md-6">
                    <div className="video-tiel d-flex justify-content-between">
                          <h5>My Response</h5>
                         
                          <div className="video-side-title d-flex align-items-center gap-2">
                            <strong>Time Remaining</strong>
                            <strong className={`timerbox ${timer != '05:00' ? 'recstart' : ""}`}>{timer}</strong>
                          </div>
                    </div>
                    <div className="record-video">
                        <div
                        className={`videobox-recorder 
                            ${
                                startCapture ? "" : recordedURL  ? "d-none" : "stop-video"
                            }`}
                        >
                          {renderPlayArea()}
                        </div>
                        <div className={recordedURL ? '' : 'd-none'}>
                          {renderRecordPlayerArea()}
                        </div>
                        {isEditable ? 
                          <div className="edit_btn text-center">
                            <button
                              className="btn-primary cursor-pointer"
                              onClick={() => {
                                setStartCapture(true)
                                handleStartRecordAgain()
                                startTimer(300, 300)
                                setRecordedChunks([])
                                setRecordedURL('')
                                setDeleteUploadFile(true)
                                setCheckAWSCount(0)
                                setIsUploaded(false)
                                if (videoInstance) {
                                  videoInstance.pause()
                                }
                                setIsEditable(false)
                              }}
                            >Record New</button> 
                          </div>
                          : ""}
                        {renderButtons()}
                        {(!startRecording && recordedURL && !isEditable) && (
                            <div className="retakevideo" 
                                onClick={() => {
                                    setChunkNumber(0)
                                    setStartCapture(true)
                                    handleStartRecordAgain()
                                    startTimer(300, 300)
                                    setRecordedChunks([])
                                    setRecordedURL('')
                                    setDeleteUploadFile(true)
                                    setCheckAWSCount(0)
                                    setIsUploaded(false)
                                    if (videoInstance) {
                                      videoInstance.pause()
                                    }
                                }}
                            >
                              <div className="reloadbox">
                                  <img src="/images/refresh.png" />
                              </div>
                            Retake</div>
                        )}
                        
                    </div>
                  </div>
                  <div className="col-md-6">
                      {renderMyRecording()}
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                      <div className="form-role-play formrolsec mt-0">
                          

                          {(recordedURL && !startRecording) || uploadedData ? (
                              <div>
                                <div className="Auto-submitting">
                                      {
                                        coachName ?
                                        <>
                                          <div className="subtiles">Auto-submitting to</div>
                                          <div className="d-flex gap-2">
                                          <div className="user-boxtext d-flex gap-2">
                                              <div className="user-img-box">
                                                <img src={CONFIG.DEFAULT_AVATAR} alt=""/>
                                              </div>
                                              <div className="user-role-detials">
                                                <div className="username-de">{coachName}</div>
                                                <div className="subdtextre">Reviewer</div>
                                              </div>
                                          </div>
                                          <div className="user-boxtext d-flex gap-2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                            id="clock-time-four"
                                            d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                            transform="translate(-2 -2)"
                                            fill="var(--color-element-1)"
                                            />
                                        </svg>
                                        <span>
                                            <strong> {duration}</strong> mins
                                        </span>
                                          </div>
                                          </div>
                                        </> : ""
                                      }
                                </div>
                                <div className="video-rating-role mt-2 rspratingsec">
                                  <div className="box-rating-rolplay d-flex">
                                    <div className="videotimebox d-flex gap-2 iconspitch">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="35"
                                          height="35"
                                          viewBox="0 0 16 16"
                                      >
                                          <path
                                          id="Speed"
                                          d="M11690-1677a8.009,8.009,0,0,1-8-8,8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8A8.009,8.009,0,0,1,11690-1677Zm-.023-6.749h0l.967.909v2.727h.919v-3.408l-.966-.91.275-1.365a3.409,3.409,0,0,0,2.534,1.136v-.907a2.267,2.267,0,0,1-1.979-1.092l-.461-.728a.958.958,0,0,0-.783-.455.9.9,0,0,0-.186.024.908.908,0,0,1-.185.023l-2.4,1v2.134h.922v-1.544l.828-.32-.737,3.684-2.255-.455-.185.91,3.224.637.461-2Zm1.2-6.139a.917.917,0,0,0-.922.91.917.917,0,0,0,.922.91.917.917,0,0,0,.922-.91A.917.917,0,0,0,11691.174-1689.888Z"
                                          transform="translate(-11682 1693)"
                                          fill="#FF007F"
                                          />
                                      </svg>
                                      <div className="textbox">
                                      Pitch Rate 
                                      <strong className="coloredtrack">{getWordSpeedType(analytics.wpm)}</strong>
                                          {/* <strong className="slowcolor">
                                          {" "}
                                          {getWordSpeedType(analytics.wpm)}
                                          </strong>{" "}
                                          - {analytics.wpm} words/min */}
                                      </div>
                                    </div>
                                    <div className="videotimebox textnpm-box">
                                          <div className="wpmtitle">{analytics.wpm} WPM</div>
                                          <div className="subwpmtext">Target: 120-150</div>
                                    </div>
                                  </div>
                                {/* <div className="videotiming-role box-rating-roleplay d-flex">
                                    <div className="videotimebox">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                        id="clock-time-four"
                                        d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                        transform="translate(-2 -2)"
                                        fill="var(--color-element-1)"
                                        />
                                    </svg>
                                    <span>
                                        <strong> {duration}</strong> mins
                                    </span>
                                    </div>
                                    <div className="videotimebox">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                        id="Speed"
                                        d="M11690-1677a8.009,8.009,0,0,1-8-8,8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8A8.009,8.009,0,0,1,11690-1677Zm-.023-6.749h0l.967.909v2.727h.919v-3.408l-.966-.91.275-1.365a3.409,3.409,0,0,0,2.534,1.136v-.907a2.267,2.267,0,0,1-1.979-1.092l-.461-.728a.958.958,0,0,0-.783-.455.9.9,0,0,0-.186.024.908.908,0,0,1-.185.023l-2.4,1v2.134h.922v-1.544l.828-.32-.737,3.684-2.255-.455-.185.91,3.224.637.461-2Zm1.2-6.139a.917.917,0,0,0-.922.91.917.917,0,0,0,.922.91.917.917,0,0,0,.922-.91A.917.917,0,0,0,11691.174-1689.888Z"
                                        transform="translate(-11682 1693)"
                                        fill="#7a549e"
                                        />
                                    </svg>
                                    <span>
                                        <strong className="slowcolor">
                                        {" "}
                                        {getWordSpeedType(analytics.wpm)}
                                        </strong>{" "}
                                        - {analytics.wpm} words/min
                                    </span>
                                    </div>
                                </div> */}
                                <div className="sentimant-box">
                                    <p className="setimatetitle">Sentiment</p>
                                    <div className="d-flex ratings-role">
                                      <div className="rolerate">
                                          <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="35"
                                          height="35"
                                          viewBox="0 0 18.71 18.71"
                                          >
                                          <g id="emoji" transform="translate(0 0)">
                                              <path
                                              id="Path_12027"
                                              data-name="Path 12027"
                                              d="M138.895,283.408a4.3,4.3,0,0,0,4.3-4.3h-8.607A4.3,4.3,0,0,0,138.895,283.408Z"
                                              transform="translate(-129.54 -268.511)"
                                              fill="#0faa36"
                                              />
                                              <ellipse
                                              id="Ellipse_57"
                                              data-name="Ellipse 57"
                                              cx="1.662"
                                              cy="1.662"
                                              rx="1.662"
                                              ry="1.662"
                                              transform="translate(4.372 5.043)"
                                              fill="#0faa36"
                                              />
                                              <ellipse
                                              id="Ellipse_58"
                                              data-name="Ellipse 58"
                                              cx="1.662"
                                              cy="1.662"
                                              rx="1.662"
                                              ry="1.662"
                                              transform="translate(11.013 5.043)"
                                              fill="#0faa36"
                                              />
                                              <path
                                              id="Path_12028"
                                              data-name="Path 12028"
                                              d="M2.638,18.71H16.072a2.641,2.641,0,0,0,2.638-2.638V2.638A2.641,2.641,0,0,0,16.072,0H2.638A2.641,2.641,0,0,0,0,2.638V16.072A2.641,2.641,0,0,0,2.638,18.71ZM1.169,2.638A1.47,1.47,0,0,1,2.638,1.169H16.072a1.47,1.47,0,0,1,1.469,1.469V16.072a1.47,1.47,0,0,1-1.469,1.469H2.638a1.47,1.47,0,0,1-1.469-1.469Z"
                                              transform="translate(0 0)"
                                              fill="#0faa36"
                                              />
                                          </g>
                                          </svg>
                                          <strong> {positiveValue}%</strong>
                                      </div>
                                      <div className="rolerate">
                                          <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="35"
                                          height="35"
                                          viewBox="0 0 18.71 18.71"
                                          >
                                          <g
                                              id="sad_1_"
                                              data-name="sad (1)"
                                              transform="translate(0 0)"
                                          >
                                              <ellipse
                                              id="Ellipse_59"
                                              data-name="Ellipse 59"
                                              cx="1.662"
                                              cy="1.662"
                                              rx="1.662"
                                              ry="1.662"
                                              transform="translate(4.372 5.043)"
                                              fill="#e78312"
                                              />
                                              <ellipse
                                              id="Ellipse_60"
                                              data-name="Ellipse 60"
                                              cx="1.662"
                                              cy="1.662"
                                              rx="1.662"
                                              ry="1.662"
                                              transform="translate(11.014 5.043)"
                                              fill="#e78312"
                                              />
                                              <rect
                                              id="Rectangle_321"
                                              data-name="Rectangle 321"
                                              width="7.162"
                                              height="1.194"
                                              transform="translate(5.774 12.104)"
                                              fill="#e78312"
                                              />
                                              <path
                                              id="Path_12029"
                                              data-name="Path 12029"
                                              d="M2.638,18.71H16.072a2.641,2.641,0,0,0,2.638-2.638V2.638A2.641,2.641,0,0,0,16.072,0H2.638A2.641,2.641,0,0,0,0,2.638V16.072A2.641,2.641,0,0,0,2.638,18.71ZM1.169,2.638A1.47,1.47,0,0,1,2.638,1.169H16.072a1.47,1.47,0,0,1,1.469,1.469V16.072a1.47,1.47,0,0,1-1.469,1.469H2.638a1.47,1.47,0,0,1-1.469-1.469Z"
                                              transform="translate(0 0)"
                                              fill="#e78312"
                                              />
                                          </g>
                                          </svg>
                                          <strong> {neutralValue}%</strong>
                                      </div>
                                      <div className="rolerate">
                                          <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="35"
                                          height="35"
                                          viewBox="0 0 18.71 18.71"
                                          >
                                          <g id="sad" transform="translate(0 0)">
                                              <ellipse
                                              id="Ellipse_61"
                                              data-name="Ellipse 61"
                                              cx="1.662"
                                              cy="1.662"
                                              rx="1.662"
                                              ry="1.662"
                                              transform="translate(4.372 5.043)"
                                              fill="#e91e63"
                                              />
                                              <ellipse
                                              id="Ellipse_62"
                                              data-name="Ellipse 62"
                                              cx="1.662"
                                              cy="1.662"
                                              rx="1.662"
                                              ry="1.662"
                                              transform="translate(11.014 5.043)"
                                              fill="#e91e63"
                                              />
                                              <path
                                              id="Path_12030"
                                              data-name="Path 12030"
                                              d="M120.075,300.717c.165-.127,4.072-3.076,8.031,0l.685-.957c-4.656-3.618-9.356-.036-9.4,0Z"
                                              transform="translate(-114.735 -286.797)"
                                              fill="#e91e63"
                                              />
                                              <path
                                              id="Path_12031"
                                              data-name="Path 12031"
                                              d="M2.638,18.71H16.072a2.641,2.641,0,0,0,2.638-2.638V2.638A2.641,2.641,0,0,0,16.072,0H2.638A2.641,2.641,0,0,0,0,2.638V16.072A2.641,2.641,0,0,0,2.638,18.71ZM1.169,2.638A1.47,1.47,0,0,1,2.638,1.169H16.072a1.47,1.47,0,0,1,1.469,1.469V16.072a1.47,1.47,0,0,1-1.469,1.469H2.638a1.47,1.47,0,0,1-1.469-1.469Z"
                                              transform="translate(0 0)"
                                              fill="#e91e63"
                                              />
                                          </g>
                                          </svg>
                                          <strong> {negativeValue}%</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {/* <div className="col-lg-12 col-sm-12">
                                        <div className="scoresecfbbtm">
                                          <span className="scoredigit">{_.toNumber(overallScore)}</span> <label className="scoretitledf">OVERALL SCORE</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-5 col-sm-12">
                                            <h5 className="headfrf">Keyword Analysis</h5>
                                            <div className="accordion-box">
                                            {
                                              !_.isEmpty(keywordAnalysis)?
                                                keywordAnalysis.map((keyword, index) => {
                                                  return (
                                                    <div 
                                                      className="accordion-item-div"
                                                      key={`keyword-${index+1}`}>
                                                      {keyword.kw}<span className="ratingbox">{keyword.wt}</span>
                                                    </div>
                                                  )
                                                })
                                              :
                                              ""
                                            }
                                            </div>
                                        </div>
                                    </div> */}
                                  </div>
                                </div>
                               
                              </div>
                          ) : (
                              ""
                          )}
                         
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="form-role-sec">
                        {/* <div className="input-text position-relative d-flex">
                          <label htmlFor="date_join" className="input__label">
                            Title*
                          </label>
                          <input
                          type="text"
                          name="Form_Title"
                          className="input__field"
                          placeholder=""
                          maxLength={50}
                          minLength={1}
                          onChange={(e) => {
                            setFormTitle(e.target.value);
                          }}
                          value={formTitle}
                          />
                        </div> */}
                        <div className="input-text position-relative d-flex">
                            <label htmlFor="date_join" className="input__label">
                            Additional comments for coach
                            </label>
                            <textarea
                            name="Addtional_Comments"
                            className="input__field commentfield"
                            rows="4"
                            cols="50"
                            placeholder=""
                            onChange={(e) => {
                                setFormTextArea(e.target.value);
                            }}
                            value={formTextArea}
                            ></textarea>
                        </div>
                      </div>
                          {/* <div className="filename-roleplay">
                              {recordedChunks.length && !startRecording ? (
                              <span>
                                  Download your recording&nbsp;
                                  <a onClick={handleDownload}>
                                  {`xsel-${roleInfo.title}.mp4`}
                                  </a>
                                  &nbsp;
                                  <span
                                  onClick={() => {
                                      setRecordedChunks([]);
                                      setRecordedFile("");
                                  }}
                                  >
                                  <i className="fa-times fa" />
                                  </span>
                              </span>
                              ) : (
                              ""
                              )}
                              {uploadedData ? (
                              <span>
                                  <a>{uploadedData.name}</a>&nbsp;
                                  <span
                                  onClick={() => {
                                      setUploadedData("");
                                  }}
                                  >
                                  <i className="fa-times fa" />
                                  </span>
                              </span>
                              ) : (
                              ""
                              )}
                          </div> */}
                      </div>
                      <div className="col-md-12">
                        <div className="role_frm_upload_btn edit_btn d-flex">
                              <button
                              className="btn-primary"
                              disabled={recordedURL ? '' : true}
                              onClick={() => {
                                  if (!isSaveLoading) {
                                      setMsg("");
                                      setFormData(1);
                                  }
                              }}
                              >
                              {isSaveLoading ? (
                                  <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  variant="primary"
                                  />
                              ) : (
                                  "Save"
                              )}
                              </button>
                              <button
                              className="btn-primary"
                              disabled={recordedURL ? '' : true}
                              onClick={() => {
                                  if (!isSubmitLoading) {
                                    setMsg("");
                                    // console.log(userDetails);
                                    if (_.toNumber(userDetails.coach_id) == 0) {
                                        setMsg("You need a Coach to submit role play for review.");
                                        return;
                                    } else {
                                      setFormData(2);
                                    }
                                  }
                              }}
                              >
                              {isSubmitLoading ? (
                                  <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  variant="primary"
                                  />
                              ) : (
                                  <>
                                    {submitBtnName}
                                  </>
                              )}
                              </button>
                          </div>
                          <div className="form-msg">
                            <span>{msg}</span>
                          </div>
                        </div>
                    </div>
                  
                  </div>
              </div>
              </>
          ) : (
              !_.isEmpty(listing) ?
              ""
              // <p className="notifcationbox">
              //   <img src="/images/click-icon.png" alt="click above" />{" "}
              //   {/* <span>
              //       Click on above Role play card to participate in Role play.
              //   </span> */}
              // </p>
              : ""
          )}
          </div>
          
          {/* <Gallery uploader={uploader} /> */}
              {/* <VideoPlayer
                  className="img-videosec learn_image-card video-type-select"
                  controls={true}
                  src={'https://cdn-stg.niit-mts.com/xsel/media/c6evhgwrxt0smpz1ba/archive.m3u8'}
                  type="video/mp4"
                  width="720"
                  height="420"
                  autoPictureInPicture={false}
                  poster={""}
                  fluid={true}
                  inline={false}
                  pictureInPictureToggle={false}
                  playbackRates={[]}
                  onReady={(instance) => setVideoInstance(instance)}
              /> */}
      </section>
      </>
  );
};

export default RolePlayVideoRecord;
