import React from 'react';
import CookiePolicyComp from '../../components/FooterSectComponents/CookiePolicyComp';
import Page from "../../components/Page";
const CookiePolicy = () => {
  return (
    <Page>
      <CookiePolicyComp />
    </Page>
  )
}
export default CookiePolicy;
