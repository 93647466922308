import axios from 'axios'
import { SessionKeys } from '../utils/AppUtils'

import CONFIG from '../utils/config'
import { objectToFormData } from './data'

export const login = async (payload) => {
  // console.log(payload)
  let formData = objectToFormData(payload)
  let isJSON = false;
  let isAdminBroker = false;
  let isHostBroker = false;
  let url = CONFIG.API_URL + CONFIG.API_ROUTES.LOGIN;

  if (payload.action === 'realtor_login') {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.LOGIN_BASE_PATH
    delete payload.action;
    formData = payload
    isJSON = true
    isAdminBroker = true
    // console.log('formData', formData)
  } else if (payload.action === 'hostinst_login') {
    url = CONFIG.API_URL_BROKER_ADMIN + CONFIG.API_ROUTES_BROKER.LOGIN_HOSTINST_BASE_PATH
    delete payload.action;
    formData = payload
    isJSON = true
    isHostBroker = true
    // console.log('formData', formData)
  } else if (payload.action === 'proxy_login') {
    url = CONFIG.API_URL_ADMIN + CONFIG.API_ROUTES_BROKER.GET_PROXY_LOGIN_TOKEN
    delete payload.action;
    formData = payload.postData
    isJSON = true
    isAdminBroker = true
    // console.log('formData', formData)
  }

  return axios({
    method: "post",
    url,
    data: formData,
    headers: isJSON ? { "Content-Type": "application/json" } : { "Content-Type": "multipart/form-data" },
  }).then(response => {
    // console.log('login: ',response)
    if (response.data.status) {
      localStorage.setItem(SessionKeys.NOTI_SHOW, true)
      // localStorage.setItem(SessionKeys.API_TOKEN, response.data.apiToken)
      localStorage.setItem(SessionKeys.IS_PREMIUM, response.data.data.isPremium)
      localStorage.setItem(SessionKeys.BROKER_ID, response.data.data.broker_id) // IT WILL BE DYNAMIC
      localStorage.setItem(SessionKeys.API_TOKEN_BROKER, response.data.token )
      localStorage.setItem(SessionKeys.IS_AGGREED,response.data.data.isagreed)
      localStorage.setItem(SessionKeys.USER_ID, response.data.data.user_id )
      localStorage.setItem(SessionKeys.BROKER_NAME,response.data.data.broker_name)
      isHostBroker && localStorage.setItem(SessionKeys.HOSTINST_ID, response.data.data.user_id )
      let apiToken = isAdminBroker ? response.data.token : response.data.apiToken;

      return {
        status: 'success',
        payload: {
          user: response.data.data,
          apiToken: apiToken,
          message: response.data.message
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}

export const logout = async (payload) => {
  // console.log(payload)

  let url = `${CONFIG.API_URL}${CONFIG.API_ROUTES.BASE_PATH}?token=${payload.token}&module=${payload.module}`;
  if (payload.action && payload.action === 'realtor_logout') {
    url = CONFIG.API_URL_BROKER + CONFIG.API_ROUTES_BROKER.AJAX_BASE_PATH;
  }
  return axios({
    method: "get",
    url,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(response => {
    // console.log(response)
    if (response.data.status) {
      // localStorage.setItem(SessionKeys.API_TOKEN, response.data.apiToken)
      return {
        status: 'success',
        payload: {
          message: response.data.message
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}