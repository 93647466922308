import { React, useState, useEffect, Component, createRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Page from "../../components/Page";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as SmallCalendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/Loader/Loader";

import {
  getCalendarHostSessions,
  getCalendarViewSessions,
  hostSession,
  hostSessionClear,
} from "../../redux/events/actions";
import CustomEventPopUp from "./CustomEventPopUp";
import CustomEvent from "./CustomEvent";
import { setSessionCount } from "../../redux/user/actions";
import CustomToolbar from "./CustomToolbar";
// moment.locale('en-GB');
// BigCalendar.momentLocalizer(moment);
const categories = {
  ONE_ON_ONE: "1 on 1",
  TEAM_MEETING: "Team Meeting",
  CONFERENCE_CALL: "Conference Call",
};
const CalendarHostEvents = (event) => {
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const calendarRef = createRef();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const authHostInst = useSelector((state) => state.auth.authHostInst);
  const calendarsessionListRes = useSelector(
    (state) => state.events.calendarHostSessions
  );
  const hostSession = useSelector((state) => state.events.hostSession);
  const userRole = useSelector((state) => state.user.role);
  const [sessionCalList, setSessionCalList] = useState({});
  const [myEvents, setMyEvents] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModalMsg, setShowModalMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log('UserRole', userRole)
  }, [userRole]);

  useEffect(() => {
    // console.log('CalendarDate', calendarDate)
    setCalendarDate(calendarDate);
  }, [calendarDate]);

  useEffect(() => {
    if (!_.isEmpty(hostSession)) {
      if (hostSession.status === "success") {
        if (!_.isEmpty(hostSession.payload.payload)) {
          // console.log('hostSession', hostSession.payload.payload);
          const hostSessionData = hostSession.payload.payload.data;
          if (hostSessionData.start_url) {
            window.open(hostSessionData.start_url, "_blank");
          } else {
            window.scrollTo(0, 0);
            setShowModalMsg("Server Error, please contact support");
            setConfirmModal(true);
          }
          dispatch(hostSessionClear());
        } else {
          // console.log(hostSession.error.message)
          window.scrollTo(0, 0);
          setShowModalMsg(hostSession.error.message);
          setConfirmModal(true);
        }
      } else {
        setShowModalMsg(hostSession.error.message);
        setConfirmModal(true);
      }
    }
  }, [hostSession]);

  useEffect(() => {
    // console.log('events calendars', sessionCalList)
    if (!_.isEmpty(sessionCalList)) {
      let events = [];
      let api_date_format = "YYYY-MM-DD HH:mm";
      // sessionCalList.payload.payload.sessionList.map((item) => {
      const sessionList = sessionCalList.payload;
      // console.log('events session', sessionCalList.payload)
      if (!_.isEmpty(sessionList)) {
        const userTz = moment.tz.guess();
        for (let item of sessionList) {
          // console.log("event item", item);
          // item.date_time.map((event_time) => {
          for (let event_time of item.date_time) {
            // console.log('events', item)
            const startDateTime = moment
              .utc(
                event_time?.start_date?.replaceAll("-", "/") +
                  " " +
                  event_time.start_time
              )
              .tz(userTz);
            const endDateTime = moment
              .utc(
                event_time?.end_date?.replaceAll("-", "/") +
                  " " +
                  event_time.end_time
              )
              .tz(userTz);
            const currentDateTime = moment();
            const tense = getTense(
              _.toInteger(
                moment.duration(startDateTime.diff(currentDateTime)).asDays()
              )
            );
            if (tense != "past") {
              events.push({
                start: moment(startDateTime.format(api_date_format)).toDate(),
                end: moment(endDateTime.format(api_date_format)).toDate(),
                timezone: moment.tz(userTz).format("z"),
                duration: _.toInteger(
                  moment.duration(endDateTime.diff(startDateTime)).asHours()
                ),
                tense: tense,
                day: _.toInteger(
                  moment.duration(startDateTime.diff(currentDateTime)).asDays()
                ),
                title: item.title,
                channel: item.channel,
                author: item.instructor,
                hostZoomId: item.channel === "zoom" ? item.host_zoom_id : "",
                sessionId: item.session_id,
                segmentId: event_time.segment_id,
                userId: authHostInst.payload.user.user_id,
                id: events.length,
              });
            }
          }
          let presentCount = 0;
          for (let event of events) {
            if (event.tense === "present") {
              // console.log("is present", event);
              presentCount = presentCount + 1;
            }
          }
          dispatch(setSessionCount(presentCount));
        }
      }
      // console.log("events final data", events);
      setMyEvents(events);
    }
  }, [sessionCalList]);

  //SESSIONS INFO
  useEffect(() => {
    // console.log("calendar");
    if (!_.isEmpty(authHostInst)) {
      dispatch(
        getCalendarHostSessions({
          module: "getHostCalendar",
          postData: {
            user_id: authHostInst.payload.user.user_id,
          },
        })
      );
    }
  }, [dispatch, authHostInst]);

  useEffect(() => {
    // console.log("events calendar", calendarsessionListRes);
    if (calendarsessionListRes?.status === "success") {
      if (!_.isEmpty(calendarsessionListRes?.payload)) {
        setIsLoading(false);
        setSessionCalList(calendarsessionListRes?.payload);
      }
    }
  }, [calendarsessionListRes]);

  const getTense = (diff) => {
    if (diff > 0) {
      return "future";
    } else if (diff < 0) {
      return "past";
    } else {
      return "present";
    }
  };

  const renderAlertCard = () => {
    return (
      <div
        className={`alert-card d-flex justify-content-center ${
          confirmModal ? "" : "d-none"
        }`}
      >
        <div className="shop-cart">
          <div className="shop-info justify-content-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                            <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C"/>
                            <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" stroke-width="2"/>
                        </g>
                    </svg> */}
            <p className="m-0">{showModalMsg}</p>
          </div>
          <div className="view-cart-icon">
            {/* <span
                        className="cursor-pointer"
                        onClick={() => {
                            clickStreamUser('go-to-cart-from-shopping-cart-btn-clicked', {
                                userId: auth.authBroker?.payload.user.user_id,
                                ecommerceId: courseId
                            })
                            history.push('/cart')
                        }}
                    >View Cart</span> */}
            <i
              className="fa fa-times cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                setConfirmModal(false);
                setShowModalMsg("");
              }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  return (
    <>
      <Loader start={isLoading} />
      <Page>
        <div className="container position-relative">
          {renderAlertCard()}
          <div className="row calandpage mt-5">
            <div className="col-md-2 calendar-banner">
              <p className="lable-heading_search">Calendar</p>
            </div>
          </div>
          <div className="row pt-4 calendar-content">
            <div className="col-xl-9 col-sm-12">
              <div className="calscroll">
                <Calendar
                  formats={formats}
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  events={myEvents}
                  style={{ height: "100%", width: "100%" }}
                  date={calendarDate}
                  defaultDate={calendarDate}
                  onNavigate={(date) => {
                    setCalendarDate(date);
                  }}
                  selectable={true}
                  min={new Date(0, 0, 0, 0, 0, 0)}
                  max={new Date(0, 0, 0, 23, 59, 59)}
                  step={30}
                  components={{
                    event: CustomEvent,
                    toolbar: CustomToolbar,
                  }}
                  views={["month", "week", "day"]}
                  inputRef={calendarRef}
                  dayLayoutAlgorithm={"no-overlap"}
                  defaultView={"week"}
                  // onSelectSlot={(e) => handleSelect(e)}
                  // onSelectEvent={(e) => handleSelectedEvent(e)}
                  // messages={{'today': "Aujourd'hui", "previous":'précédente', "next":"suivante"}}
                />
              </div>
            </div>
            <div className="col-xl-3 col-sm-12 mt-5cal">
              <div className="month">
                <SmallCalendar
                  onChange={setCalendarDate}
                  value={calendarDate}
                />
              </div>
              {/* <div className="containerDiv calenderdesc">
                <a href="my-event.html"></a>
                <div className="myComingEvent"><b>My Upcoming Events</b></div>
                <div className="myComingEventlist filled"><b>Lorem ipsum dolor sit</b>
                  <p>Dec 14, 2021  1:00am - 12:00pm</p>
                </div>
                <div className="myComingEventlist filled"><b>Morbi metus eros </b>
                  <p>Dec 15, 2021  9:00am - 6:00pm</p>
                </div>
                <div className="myComingEventlist filled"><b>Sed malesuada est in urna volutpat</b>
                  <p> Dec16  8:00am - 9:00 am</p>
                </div>
                <div className="myComingEventlist filled"><b>Sed malesuada est in urna volutpat</b>
                  <p> Dec16  8:00am - 9:00 am</p>
                </div>
                <div className="myComingEventlist"></div>
                <div className="myComingEventlist"></div>
              </div> */}
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default CalendarHostEvents;
