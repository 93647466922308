const data = {
  banners: [
    {
      banner: 'images/banner_img01.jpg',
      data: {
        tagline: 'Upcoming Course - Fintrac',
        title: 'Realtor Safety - <br/> Health and Safety',
        eventType: 'Course',
        duration: '4 hours',
        clicked: true,
        link: ''
      }
    },
    {
      banner: 'images/home-banner.png',
      data: {
        tagline: 'Upcoming Events',
        title: 'Sales/Lead Generation <br/> and Referral Strategies',
        eventType: 'Event',
        duration: '4 hours',
        clicked: true,
        link: ''
      }
    },
    {
      banner: 'images/banner_img04.jpg',
      data: {
        tagline: 'Upcoming Course',
        title: 'Transactions<br/> Buyer Offer',
        description: 'How to Purchase a course?',
        eventType: 'Course',
        duration: '4 hours',
        clicked: true,
        link: ''
      }
    },
  ],

  featured: {
    profile: {
      image: 'images/user-img.png',
      name: 'Ronnie Woodkin',
      address: 'Accredited Buyer Representative, Calumet City, IL',
      premium: false,
      stats: [
        {
          value: 0,
          caption: 0,
          type: 'learning_points'
        },
        {
          value: 25,
          caption: 'Incomplete Profile',
          type: 'profile'
        },
      ]
    }
  },

  events: [
    {
      eventTitle: 'Workplace Sensitivity Training',
      eventDateTime: '2022-01-17 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Real Estate Technology',
      eventDateTime: '2022-01-17 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Marketing and Lead Generation',
      eventDateTime: '2022-01-17 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Privacy Act',
      eventDateTime: '2022-01-18 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Broker Compliance',
      eventDateTime: '2022-01-18 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Mutual Releases',
      eventDateTime: '2022-01-19 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Transactions Buyer Offer',
      eventDateTime: '2022-01-19 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Mandatory Document Workflow',
      eventDateTime: '2022-01-20 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Transactions Sellers Listing',
      eventDateTime: '2022-01-20 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Anti-Spam CASL',
      eventDateTime: '2022-01-21 00:00:00',
      link: '',
      iconType: ''
    },
    {
      eventTitle: 'Marketing and Lead Generation',
      eventDateTime: '2022-01-21 00:00:00',
      link: '',
      iconType: ''
    },
  ],

  featured_posts: [
    {
      profile_image: 'images/card-img.png',
      author: 'Maria John',
      job_title: 'Sr. Manager',
      post_description: "The theme of this year's World Environment Day is Ecosystem Restoration. Pakistan will act as global host of the day. World Environment Day 2022 will see the launch of the UN Decade on Ecosystem Restoration.",
      featured_image: 'images/right-card.png',
      likes: 378,
      comments: 65,
    },
    {
      profile_image: 'images/img_thumnail.png',
      author: 'Sofia',
      job_title: 'Manager',
      post_description: "The theme of this year's World Environment Day is Ecosystem Restoration. Pakistan will act as global host of the day. World Environment Day 2022 will see the launch of the UN Decade on Ecosystem Restoration.",
      featured_image: 'images/network_img_2.jpg',
      likes: 378,
      comments: 65,
    }
  ],

  onboarding: [
    {
      title: 'Broker',
      type: 'Compliance',
      progress: 100,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Sales Training',
      type: '(Fundamentals)',
      progress: 100,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Transaction',
      type: 'Buyer Offer',
      progress: 100,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Transactions ',
      type: 'Sellers Listing',
      progress: 100,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Marketing and',
      type: 'Lead Generation',
      progress: 100,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Real Estate',
      type: 'Technology',
      progress: 56,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Mutual',
      type: 'Releases',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Listing',
      type: 'Presentation',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Privacy',
      type: 'Act',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Buyer',
      type: 'Presentation',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Welcom',
      type: 'eBlast',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Mandatory',
      type: 'Document',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Door',
      type: 'Knock',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
    {
      title: 'Sensitivity',
      type: 'Training',
      progress: 0,
      link: 'my-onboarding-first-time-user.html',
    },
  ],

  user_courses: [
    {
      title: 'Fintrac',
      featured_image: 'images/fin2.jpg',
      link: '#',
      duration: 15,
      isPremium: true,
      progress: 0,
      views: 547
    },
    {
      title: 'Realtor Safety - Health and Safety',
      featured_image: 'images/reality&safety.jpg',
      link: '#',
      duration: 30,
      isPremium: false,
      progress: 0,
      views: 379
    },
    {
      title: 'Workplace Sensitivity Training',
      featured_image: 'images/Workplace-Sensitivity.jpg',
      link: '#',
      duration: 15,
      isPremium: false,
      progress: 0,
      views: 876
    },
  ],

  recommended_courses: [
    {
      title: 'Fintrac',
      featured_image: 'images/fin2.jpg',
      link: '#',
      duration: '15 Min',
      isPremium: true,
      progress: 0,
      views: 547
    },
  ]
}

export default data