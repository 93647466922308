import types from './types'

const initialState = {
    masterList: {},
    masterListUnassigned: {},
    participateList: {},
    reviewList: {},
    myRolePlayList: {},
    participateForm: {},
    feedbackForm: {},
    isAggree: {},
    privacyContent:{},
    addArchiveVideo: {},
    editRolePlayData: {},
    relatedRolePlayList: {},
    reviewFilterList: {},
    myRolePlayFilterList: {},
    rolePlayData: {},
    getVideoDataRolePlay: {},
    checkAWS: {},
    recordedDataRolePlay: {},
    setParticipateValue: {},
    assignRolePlay: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ROLEPLAY_MASTER_LIST_RESPONSE:
            // console.log('myrole play list in reducers', action.payload)
            return {
                ...state,
                masterList: action.payload
            }
        case types.ROLEPLAY_MASTER_LIST_CLEAR:
            return {
                ...state,
                masterList: {}
            }
        case types.ROLEPLAY_MASTER_LIST_UNASSIGNED_RESPONSE:
            // console.log('myrole play list in reducers', action.payload)
            return {
                ...state,
                masterListUnassigned: action.payload
            }
        case types.ROLEPLAY_MASTER_LIST_UNASSIGNED_CLEAR:
            return {
                ...state,
                masterListUnassigned: {}
            }
        case types.GET_ALL_ROLE_PARTICIPATE_LIST_RESPONSE:
            // console.log('myrole play list in reducers', action.payload)
            return {
                ...state,
                participateList: action.payload
            }
        case types.GET_ALL_ROLE_PARTICIPATE_LIST_CLEAR:
            return {
                ...state,
                participateList: {}
            }
        case types.PARTICIPATE_IN_ROLEPLAY_LIST_RESPONSE:
            return {
                ...state,
                participateForm: action.payload
            }
        case types.PARTICIPATE_IN_ROLEPLAY_LIST_CLEAR:
            return {
                ...state,
                participateForm: {}
            }
        case types.REVIEW_ROLEPLAY_LIST_RESPONSE:

            return {
                ...state,
                reviewList: action.payload
            }
        case types.REVIEW_ROLEPLAY_LIST_CLEAR:
            return {
                ...state,
                reviewList: {}
            }
        case types.REVIEW_FILTER_LIST_RESPONSE:

            return {
                ...state,
                reviewFilterList: action.payload
            }
        case types.REVIEW_FILTER_LIST_CLEAR:
            return {
                ...state,
                reviewFilterList: {}
            }
        case types.REVIEW_FEEDBACK_SUBMIT_LIST_RESPONSE:
            return {
                ...state,
                feedbackForm: action.payload
            }
        case types.REVIEW_FEEDBACK_SUBMIT_LIST_CLEAR:
            return {
                ...state,
                feedbackForm: {}
            }
        case types.MY_ROLE_PLAY_LIST_RESPONSE:
            return {
                ...state,
                myRolePlayList: action.payload
            }
        case types.MY_ROLE_PLAY_LIST_CLEAR:
            return {
                ...state,
                myRolePlayList: {}
            }
        case types.MY_ROLE_PLAY_FILTER_LIST_RESPONSE:
            return {
                ...state,
                myRolePlayFilterList: action.payload
            }
        case types.MY_ROLE_PLAY_FILTER_LIST_CLEAR:
            return {
                ...state,
                myRolePlayFilterList: {}
            }
        case types.IS_AGREE_RESPONSE:
            return {
                ...state,
                isAggree: action.payload
            }
        case types.IS_AGREE_CLEAR:
            return {
                ...state,
                isAggree: {}
            }
        case types.PRIVACY_CONTENT_RESPONSE:
            return {
                ...state,
                privacyContent: action.payload
            }
        case types.PRIVACY_CONTENT_CLEAR:
            return {
                ...state,
                privacyContent: {}
            }
        case types.ADD_ARCHIVE_VIDEO_RESPONSE:
            return {
                ...state,
                addArchiveVideo: action.payload
            }
        case types.ADD_ARCHIVE_VIDEO_CLEAR:
            return {
                ...state,
                addArchiveVideo: {}
            }
        case types.EDIT_ROLEPLAY_DATA_RESPONSE:
            return {
                ...state,
                editRolePlayData: action.payload
            }
        case types.EDIT_ROLEPLAY_DATA_CLEAR:
            return {
                ...state,
                editRolePlayData: {}
            }
        case types.RELATED_VIDEO_LIST_RESPONSE:
            return {
                ...state,
                relatedRolePlayList: action.payload
            }
        case types.RELATED_VIDEO_LIST_CLEAR:
            return {
                ...state,
                relatedRolePlayList: {}
            }

        case types.FETCH_ROLE_PLAY_DATA_RESPONSE:
            return {
                ...state,
                rolePlayData: action.payload
            }

        case types.FETCH_ROLE_PLAY_DATA_CLEAR:
            return {
                ...state,
                rolePlayData: {}
            }

        case types.GET_VIDEO_DATA_ROLEPLAY_RESPONSE:
            return {
                ...state,
                getVideoDataRolePlay: action.payload
            }

        case types.GET_VIDEO_DATA_ROLEPLAY_CLEAR:
            return {
                ...state,
                getVideoDataRolePlay: {}
            }

        case types.CHECK_AWS_STATUS_ROLEPLAY_RESPONSE:
            return {
                ...state,
                checkAWS: action.payload
            }

        case types.CHECK_AWS_STATUS_ROLEPLAY_CLEAR:
            return {
                ...state,
                checkAWS: {}
            }

        case types.GET_RECORDED_DATA_ROLEPLAY_RESPONSE:
            return {
                ...state,
                recordedDataRolePlay: action.payload
            }

        case types.GET_RECORDED_DATA_ROLEPLAY_CLEAR:
            return {
                ...state,
                recordedDataRolePlay: {}
            }

        case types.SET_PARTICIPATE_FORM_VALUE:
            return {
                ...state,
                setParticipateValue: action.payload
            }

        case types.SET_PARTICIPATE_FORM_VALUE_CLEAR:
            return {
                ...state,
                setParticipateValue: {}
            }
            
        case types.ASSIGN_ROLEPLAY_RESPONSE:
            return {
                ...state,
                assignRolePlay: action.payload
            }

        case types.ASSIGN_ROLEPLAY_CLEAR:
            return {
                ...state,
                assignRolePlay: {}
            }
        default:
            return state;
    }
}