import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import OrderCourseCard from "./OrderCourseCard";
import OrderPlacedToastMessage from "./OrderPlacedToastMessage";
import { orderPlacedClear } from "../../redux/checkout/actions";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "../../pages/Orders/Pagination";
import PaginationMyOrders from "./PaginationMyOrders";
import Loader from "../Loader/Loader";
let PageSize = 10;
const OrderHistorySection = (props) => {
  const { ordersData, isLoading, filterWithYear } = props;
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const orderStatus = params.get("orderStatus");
  const orderNumber = params.get("orderNumber");
  const itemCount = params.get("itemCount");

  const courseCategoriesData = useSelector(
    (state) => state.courses.courseCategories
  );
  const orderPlacedData = useSelector(
    (state) => state.checkout.orderPlacedData
  );
  // console.log("hahaha", orderPlacedData);
  const [courseCategories, setCourseCategories] = useState([]);
  const [orderDataArray, setOrderDataArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isNewOrder, setIsNewOrder] = useState(false);
  const [orderPlacedInfo, setOrderPlacedInfo] = useState({});
  const [orderInfoData, setOrderInfoData] = useState({});
  const [totalCount, setTotalCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  // const [isLoading, setLoading] = useState(true);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return orderDataArray.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, orderDataArray]);

  const [filterYears, setFilterYears] = useState([]);

  useEffect(() => {
    let tempArr = [];
    for (let index = 2022; index <= new Date().getFullYear(); index++) {
      tempArr.push(index.toString());
    }
    setFilterYears(tempArr)
    
  }, [])

  // console.log("Data for order", currentTableData);
  useEffect(() => {
    if (
      !_.isEmpty(courseCategoriesData) &&
      courseCategoriesData.status === "success"
    ) {
      setCourseCategories(courseCategoriesData.payload);
    }
  }, [courseCategoriesData]);

  useEffect(() => {
    // console.log("ordersData.length: ", ordersData);

    setOrderDataFunc(ordersData)

  }, [ordersData]);

  const setOrderDataFunc = (ordersData) => {
    if (ordersData.length > 0) {
      // setLoading(false);
      setOrderDataArray(ordersData);

      setCurrentPage(1);

      // let tempCount = 0;
      // for (let orders of ordersData) {
      //   tempCount += _.isArray(orders.courseList)
      //     ? orders.courseList.length
      //     : 0;
      // }
      setTotalCount(ordersData?.length);
    } else {
      setOrderDataArray([])
      setTotalCount(0);
    }
  }



  useEffect(() => {
    if (!_.isEmpty(orderPlacedData) && orderPlacedData.status === "succeeded") {
      // console.log("New orders data", orderPlacedData);

      setIsNewOrder(true);

      setTimeout(() => {
        setIsNewOrder(false);
      }, 10000);

      dispatch(orderPlacedClear());
    }
  }, [orderPlacedData]);

  useEffect(() => {
    if (orderStatus && orderNumber && itemCount) {
      if (orderStatus === "success") {
        setIsNewOrder(true);
        setTimeout(() => {
          setIsNewOrder(false);
        }, 20000);
      }

      setOrderInfoData({
        orderStatus: orderStatus,
        orderNumber: orderNumber,
        itemCount: itemCount,
      });
    }
  }, [orderStatus, orderNumber, itemCount]);

  useEffect(() => {
    makeSearch();
  }, [searchText]);

  const makeSearch = () => {
    if (searchText === "" && !_.isEmpty(ordersData)) {
      setOrderDataArray(ordersData);
    } else {
      var tempArray = [];
      var tempCount = 0;
      var tempData = ordersData;

      // console.log("searchText3: ", tempData);
      for (let x = 0; x < tempData.length; x++) {
        const element = tempData[x];
        // console.log("searchText2: ", tempArray);
        const tempObj = _.clone(element);
        // console.log("searchText1: ", tempArray);
        tempObj.courseList = [];
        for (let index1 = 0; index1 < element.courseList.length; index1++) {
          const element1 = element.courseList[index1];
          if (
            element1.product_name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            tempCount++;
            tempObj.courseList.push(element1);
          }
        }
        tempArray.push(tempObj);
      }
      if (tempCount === 0) tempArray = [];
      setOrderDataArray(tempArray);


    }
  };

  return (
    <>
      <section className="my_order">
        <div className="order-wrapper">
          <div className="container">
            {isNewOrder && (
              <OrderPlacedToastMessage orderInfo={orderInfoData} />
            )}
            <div className="row">
              <h3>My Orders</h3>

              <div className="row orders">
                <div className="d-flex order-column">
                  <div className="col-md-5 search__input">
                    <div className="input-lear position-relative d-flex">
                      <label htmlFor="password" className="input__label_learning">
                        Search
                      </label>
                      <input
                        type="text"
                        id="password"
                        name="password"
                        className="input_searchfield_search-cart "
                        placeholder=" "
                        onChange={(evt) => setSearchText(evt.target.value)}
                      />
                      <a href="#">
                        <button className="button-reset">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Find"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                          >
                            <defs>
                              <linearGradient
                                id="linear-gradient"
                                y1="0.5"
                                x2="1"
                                y2="0.5"
                                gradientUnits="objectBoundingBox"
                              >
                                <stop offset="0" stopColor="#00b7f1" />
                                <stop offset="1" stopColor="#ed156e" />
                              </linearGradient>
                            </defs>
                            <circle
                              id="Ellipse_191"
                              data-name="Ellipse 191"
                              cx="15"
                              cy="15"
                              r="15"
                              fill="url(#linear-gradient)"
                            />
                            <circle
                              id="Ellipse_192"
                              data-name="Ellipse 192"
                              cx="13"
                              cy="13"
                              r="13"
                              transform="translate(2 2)"
                              fill="#fff"
                            />
                            <path
                              id="Icon_awesome-search"
                              data-name="Icon awesome-search"
                              d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                              transform="translate(7.715 7.714)"
                              fill="var(--color-element-1)"
                            />
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="select-date">
                    {totalCount > 0 && <p>
                      {totalCount} <span>order(s) in</span>{" "}
                    </p>}
                    <div className="input-lear position-relative d-flex">
                      <select
                        className="form-select input_searchfield order-select"
                        aria-label="Default select example"
                        onChange={(evt) => {

                          filterWithYear(evt.target.value);
                        }}
                      >
                        {filterYears?.map((item, index) => {
                          return <option selected={item === (new Date().getFullYear()).toString()} value={item}>{item}</option>
                        })

                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 cart-content">
                <div className="order-total_wrapper">
                  {!isLoading && currentTableData.length > 0 ? (
                    <div className="carousel-content">
                      {currentTableData.map((order, index) => {
                        return (
                          <>
                            {!isLoading &&
                              _.isArray(order.courseList) &&
                              order?.courseList.length > 0 ? (
                              <div className="bg-content" key={index}>
                                <div className="order-placed">
                                  <div className="order-history">
                                    <p>ORDER PLACED</p>
                                    <p>
                                      {" "}
                                      {order?.order_created?.date
                                        ? moment(
                                          order.order_created.date
                                        ).format("Do MMM YYYY")
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="order-total">
                                    <p>TOTAL</p>
                                    <p>${order?.total} CAD</p>
                                  </div>
                                </div>
                                <div className="order_id">
                                  <p>ORDER# XSEL_{order?.order_number}</p>
                                  <p>View Order Details</p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {_.isArray(order?.courseList) &&
                              order?.courseList.map((course, index) => {
                                return (
                                  <OrderCourseCard
                                    key={`order-${index}`}
                                    order={course}
                                    orderDate={order?.order_created?.date}
                                    orderNumber={order?.order_number}
                                    parent={order}
                                    isLast={
                                      index === order.courseList.length - 1
                                    }
                                  />
                                );
                              })}
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    !isLoading && (
                      <div className="not-found mt-3">
                        <div className="interboxes">
                          <img src="/images/telescope.svg" alt="Not Found" />
                          <p>No item found.</p>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <PaginationMyOrders
                  className="pagination-bar "
                  currentPage={currentPage}
                  totalCount={orderDataArray.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderHistorySection;
