import axios from 'axios'
import data from '../data/product'
import { SessionKeys } from '../utils/AppUtils'

import CONFIG from '../utils/config'
import { objectToFormData } from './data'

export const checkoutData = async (payload) => {
  // console.log(payload)
  const formData = objectToFormData(payload)
  return {
    status: 'success',
    payload: data.checkoutData
  }
  return axios({
    method: "post",
    url: CONFIG.API_URL + '',
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(response => {
    // console.log(response)
    if (response.data.status) {
      localStorage.setItem(SessionKeys.API_TOKEN, response.data.apiToken)
      return {
        status: 'success',
        payload: {
          user: response.data.data,
          apiToken: response.data.apiToken,
          message: response.data.message
        }
      }
    } else {
      return {
        status: 'error',
        error: {
          message: response.data.message
        }
      }
    }
  }).catch((error) => {
    return {
      status: 'error',
      error
    }
  })
}