import React from "react";
import Page from "../../components/Page";
import VideoPlayerPageSection from "./VideoPlayerPageSection";

const VideoPlayerPage = () => {
  
  return (
    <>
      <Page>
        <VideoPlayerPageSection isMobile={false}/>
      </Page>
    </>
  );
};

export default VideoPlayerPage;
