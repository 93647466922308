import Page from "../../components/Page";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
import EventReportHomeSection from "../../components/Reports/EventReport/EventReportHomeSection";

export const EventReportPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
        <Loader start={isLoading} />
        <Page>
            <EventReportHomeSection setIsLoading={setIsLoading} />
        </Page>
        </>
    )
}

export default EventReportPage;