import React, { useEffect, useState} from "react";
import Page from "../../components/Page";
import EthicsAggreeCheck from "../../components/Course/EthicsAggreeCheck";
import { useDispatch, useSelector } from "react-redux";
import { getCodeOfEthics, getCodeOfEthicsResponse } from "../../redux/courses/actions";
import { SessionKeys } from "../../utils/AppUtils";
import _ from "lodash";
import Loader from "../../components/Loader/Loader";
import { useHistory } from "react-router-dom";


const MasterCodeOfEthicsPage = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const authBroker = useSelector(state => state.auth.authBroker)
  const codeOfEthics = useSelector((state) => state.courses.ethicsData);

  const [isAllChecked, setIsAllChecked] = useState(false)
  const [ethicsFullInfo, setEthicsFullInfo] = useState({})
  const [ethicsData, setEthicsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  


  const open = anchorEl;
  const id = open ? "simple-popover" : undefined;
 
  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      dispatch(
        getCodeOfEthics({
          module: "get_Code_Of_Ethics",
          postData: {
            token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          }
        })
      );
    }
  }, [dispatch, authBroker])

  useEffect(() => {
    // console.log('codeOfEthics: ',codeOfEthics)
    if (!_.isEmpty(codeOfEthics)) {
      if (codeOfEthics?.status === "success") {
        setIsLoading(false)
        if (!_.isEmpty(codeOfEthics.payload?.payload)) {
          const ethicsRes = codeOfEthics.payload.payload
          setEthicsFullInfo(ethicsRes)
          setIsAllChecked(ethicsRes.isAllChecked)
          let tempList = ethicsRes.list;
          for(let tempObj of tempList){
            // tempObj.isChecked= false;
            tempObj.isEnable=undefined;
            tempObj.isComplete=tempObj.isChecked;
            tempObj.isShowText=tempObj.isChecked;
          }
          setEthicsData(tempList);
        }
      }
    }
  }, [codeOfEthics]);

  const goToNextHandler = () => {
    setShow(false);
    history.push({
      pathname: "/home",
      id: id, 
    });
    // setSubmit(false)
  };
  
  return (
    <>
    <Loader start={isLoading} />
    <Page>         
          <React.Fragment>
            <section className="master_COE">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 checklistpages">

                  {show && (
                    <div className="eticslaert alert-card d-flex justify-content-center event-alter-sucess" id="msg">
                      <div className="shop-cart order-details event-notify-sucess mt-0">
                        <div className="shop-info justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <g
                              id="Group_5699"
                              data-name="Group 5699"
                              transform="translate(0.498 0.498)"
                            >
                              <circle
                                id="Ellipse_222"
                                data-name="Ellipse 222"
                                cx="9"
                                cy="9"
                                r="9"
                                transform="translate(-0.498 -0.498)"
                                fill="#A9C23C"
                              />
                              <path
                                id="Icon_material-done"
                                data-name="Icon material-done"
                                d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z"
                                transform="translate(-3.013 -6.071)"
                                fill="#fff"
                                stroke="#fff"
                                stroke-width="2"
                              />
                            </g>
                          </svg>
                          <p className="m-0">
                            Your code of ethics submitted successfully.
                          </p>
                        </div>
                        <div className="view-cart-icon">
                          {/* <span className="cursor-pointer">View Cart</span> */}
                          <i
                            className="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={goToNextHandler}
                          ></i>
                        </div>
                      </div>
            
                      
                    </div>
                  )}

                    <div className="search_heading">
                      {/* <img
                        src="images/master_COE_img.jpg" 
                        width="109"
                        height="106"
                        alt=""
                      /> */}
                      <p className="lable-heading_ethics ethicsheadi">
                      {ethicsFullInfo?.checklist_title}
                      </p>
                    </div>

                    <section className="cert_ethics-container pb-0">
                      <div className="row">
                        <div className="col-xl-12 col-md-12 main_ethics">
                          <h6 className="checklisttile">{ethicsFullInfo?.description}</h6>
                          <p>
                            If you have any questions, contact your Manager.{" "}
                          </p>
                        </div>
                      
                      </div>
                    </section>
                  
                  </div>
                </div>
              </div>
            </section>
            
            <EthicsAggreeCheck 
            list={ethicsData}
            isAllChecked={isAllChecked}
            isLoading={isLoading}
            show={show}
            setShow={setShow}
            />
             </React.Fragment>             
    </Page>
    </>
  );
};
export default MasterCodeOfEthicsPage;
