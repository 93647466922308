import types from './types'

import homePageData from '../../data/home'

const initialState = {
  banners: homePageData.banners,
  featuredProfile: homePageData.featured.profile,
  featuredPosts: homePageData.featured_posts,
  events: homePageData.events,
  onboarding: homePageData.onboarding,
  userCourses: homePageData.user_courses,
  recommendedCourses: homePageData.recommended_courses,
  showHomePageMsg: false,
  homePageMsg: '',
  fixedFooter: false,
  checkContent: {},
  breadcrumbStack: [],
}

const homeReducer = (state=initialState, action) => {
  switch (action.type) {

    case types.FIXED_FOOTER:
      return {
        ...state,
        fixedFooter: true
      }
    case types.FIXED_FOOTER_CLEAR:
      return {
        ...state,
        fixedFooter: false
      }
    case types.HOME_MSG:
      return {
        ...state,
        homePageMsg: action.payload
      }
    
    case types.HOME_MSG_CLEAR:
      return {
        ...state,
        homePageMsg: ''
      }

    case types.SHOW_HOME_MSG:
      return {
        ...state,
        showHomePageMsg: action.payload
      }

    case types.CONTENT_ACCESS_RESPONSE:
      return {
        ...state,
        checkContent: action.payload
      }

    case types.CONTENT_ACCESS_CLEAR:
      return {
        ...state,
        checkContent: {}
      }

    case types.BREADCRUMB_STACK:
      console.log(action.payload)
      return {
        ...state,
        breadcrumbStack: action.payload
      }

    case types.CLEAR_BREADCRUMB_STACK:
      return {
        ...state,
        breadcrumbStack: []
      }

    default:
      return state
  }
}

export default homeReducer;