import { fork, take, call, put } from 'redux-saga/effects'
import {
    getMembershipProductsResponse,
    getMembershipIntentResponse,
    sendMembershipOrderPlaceResponse
} from './actions'
import types from './types'
import { postAPIWithoutModule } from '../../api/generic'

export function* doGetMembershipProducts() {
    while (true) {
        const { payload } = yield take(types.MEMBERSHIP_PRODUCTS)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getMembershipProductsResponse(response))
    }
}

export function* doGetMemberShipIntent() {
    while (true) {
        const { payload } = yield take(types.MEMBERSHIP_INTENT)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(getMembershipIntentResponse(response))
    }
}

export function* doSendMembershipOrderPlace() {
    while (true) {
        const { payload } = yield take(types.MEMBERSHIP_ORDER_PLACE)
        const response = yield call(postAPIWithoutModule, payload)
        yield put(sendMembershipOrderPlaceResponse(response))
    }
}

function* membership() {
    yield fork(doGetMembershipProducts)
    yield fork(doGetMemberShipIntent)
    yield fork(doSendMembershipOrderPlace)
}

export default membership