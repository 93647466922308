import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import reportWebVitals from './reportWebVitals'
import { store } from "./redux/store"
// import {store} from './state/store'
import App from './App'
import { I18nextProvider } from "react-i18next";
import i18n from './i18n'

import './index.css'
import CONFIG from "./utils/config"

const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>
      {/* <React> */}
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      {/* </React> */}
    </Suspense>
  </Provider>,
  document.getElementById('root')
);


switch (CONFIG.ENV) {
  case 'UAT':
  case 'PROD':
  // case 'DEV':
    console.log = () => {};
    console.warn = () => {};
    // console.error = () => {};
    // console.log('ENV', CONFIG.ENV)
    break;
  default:
    break;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// )

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
