import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SessionKeys, clickStreamUser } from "../../utils/AppUtils";
import { useEffect, useState } from "react";
import { boardcastDataClear, fetchBoardcast,
    fetchBoardcastClear } from "../../redux/events/actions";
import _ from "lodash"

const VideoPlayerBroadcastSection = (props) => {
    const {
        isLoading,
        setIsLoading,
        isMobile
    } = props

    const history = useHistory();
    const dispatch = useDispatch();

    const authBroker = useSelector((state) => state.auth.authBroker);
    const boardcastData = useSelector((state) => state.events.boardcastData);
    const boardcastInfo = useSelector((state) => state.events.boardcastInfo);

    const [sessionData, setSessionData] = useState({});

    useEffect(() => {
        //  console.log('WATCH URL DATA', boardcastData)
        if (!_.isEmpty(boardcastData)) {
          dispatch(
            fetchBoardcast({
              module: "calendarBoardcastData",
              postData: {
                event_id: _.toNumber(boardcastData.eventId),
                session_id: boardcastData.source === 'broker' ? _.toNumber(boardcastData.sessionId) : boardcastData.sessionId,
                origin: boardcastData.source === 'broker' ? 'broker' : 'xsel',
                token: isMobile ?
                localStorage.getItem(SessionKeys.API_TOKEN) :
                localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
              },
            })
          );
          dispatch(boardcastDataClear())
        }
      }, [dispatch, boardcastData]);
    
      useEffect(() => {
        if (!_.isEmpty(boardcastInfo)) {
          //  console.log("BOARDCASTINFO", boardcastInfo);
          if (boardcastInfo.status === "success") {
            if (!_.isEmpty(boardcastInfo.payload.payload)) {
              setSessionData(boardcastInfo.payload.payload);
            }
          }
          dispatch(fetchBoardcastClear())
          setIsLoading(false);
        }
      }, [boardcastInfo]);

    return (
        <div className="my-credit-points videofull-view">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <button
                  className="btn btn-primary setup-button"
                  onClick={() => {
                    clickStreamUser("back-btn-session-video", {
                      userId: authBroker?.payload?.user.user_id,
                    });
                    history.goBack();
                  }}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="video-fullbox">
                  <div className="search__input">
                    {!_.isEmpty(sessionData) ? (
                      <div className="img-box_RP">
                        <p className="video-title">
                          {sessionData?.session_title}
                        </p>
                        {sessionData.type === "embed" ? (
                          <div
                            className="video-full"
                            dangerouslySetInnerHTML={{
                              __html: sessionData.session_url,
                            }}
                          ></div>
                        ) : (
                          <div className="video-full">
                            <iframe
                              className="w-1000"
                              id="scorm_player"
                              height={"500px"}
                              style={{
                                minHeight: "500px",
                                width: "70%",
                                margin: "0 auto",
                              }}
                              frameBorder="0"
                              marginHeight="0"
                              marginWidth="0"
                              src={sessionData.session_url.replace("\\", "")}
                              allowFullScreen={true}
                            />
                          </div>
                        )}
                      </div>
                    ) : isLoading ? (
                      ""
                    ) : (
                      <div className="not-found cart-notfound mt-4">
                        <div className="interboxes">
                          <img src="/images/telescope.svg" alt="Not Found" />
                          <p>Unauthorized Access.</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default VideoPlayerBroadcastSection