import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeList, getOfficeListClear } from "../../redux/reports/actions";
import { SessionKeys } from "../../utils/AppUtils";
import ReactSelect from "../Layouts/ReactSelect";

const ReportOfficeNameFilter = (props) => {
  const dispatch = useDispatch();
  const officeList = useSelector((state) => state.report.officeList);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(
      getOfficeList({
        module: "getOfficeList",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(officeList)) {
      if (officeList.status === "success") {
        const list = officeList.payload.payload;
        //  console.log('OFFICE LIST', list)
        if (!_.isEmpty(list)) {
          setOptions(
            list.map((l) => {
              return {
                value: l.office_id,
                label: l.office_name,
              };
            })
          );
        }
      } else {
        setOptions([]);
      }
      dispatch(getOfficeListClear());
    }
  }, [officeList]);

  return (
    <>
      <div className="input-text position-relative d-flex">
        <label for="date_join" className="input__label">
          Office Name
        </label>
        <ReactSelect options={options} setOfficeIds={props.setOfficeIds} />
      </div>
    </>
  );
};

export default ReportOfficeNameFilter;
