import CourseMenu from "../../components/Course/CourseMenu";
import CourseListBody from "../../components/Course/CourseListBody";
import Page from "../../components/Page";
import TitleBannerSection from "../../components/TitleBannerSection";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  getVideoCourseCategories,
  getVideoCoursesListing,
  getVideoCoursesListingByCat,
  getVideoCoursesListingByCatClear,
  getVideoCoursesListingBySubCat,
  getVideoCoursesListingBySubCatClear,
  getVideoCoursesListingClear,
} from "../../redux/video/actions";
import VideoCoursesListingCard from "./VideoCoursListingCard";
import {
  clickStreamUser,
  convertHoursToMins,
  convertSecondsToMins,
  convertTimeFormat,
  SessionKeys,
} from "../../utils/AppUtils";
import Loader from "../../components/Loader/Loader";
import BackButton from "../../components/Layouts/BackButton";
import { VideoCoursesDataSetupSection } from "../../components/Video/VideoCoursesDataSetupSection";
import VideoCatLeftMenu from "../../components/Video/VideoCatLeftMenu";
import ShimmerCardBodyThree from "../../components/Layouts/ShimmerCardBodyThree";
import ShimmerNotification from "../../components/Layouts/ShimmerNotification";
import { useHistory } from "react-router-dom";

// import VideoViewPage from "./VideoViewPage";
const FILTER_BY = [
  { id: 1, value: "All" },
  { id: 2, value: "Available For Purchase" },
  { id: 3, value: "Purchased Videos" },
  { id: 4, value: "Assigned To Me" },
];
const SORT_BY = [
  { id: 1, value: "New to Old" },
  { id: 2, value: "Old to New" },
];
const VideoCoursesListingPage = () => {

  const dispatch = useDispatch();
  const _searchRef = useRef(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const catId = params.get('catId');
  const subCatId = params.get('subCatId');

  const history = useHistory();

  const courseCategoriesData = useSelector(
    (state) => state.video.videoCourseCategories
  );
  const videoListingData = useSelector(
    (state) => state.video.videoCoursesListing
  );
  const videoListingDataByCatRes = useSelector(
    (state) => state.video.videoCoursesListingByCat
  );

  const videoListingDataBySubCatRes = useSelector(
    (state) => state.video.videoCoursesListingBySubCat
  );

  const productCategoriesData = useSelector(
    (state) => state.courses.productCategories
  );

  const authBroker = useSelector((state) => state.auth.authBroker);
  const isPremiumUser = useSelector((state) => state.user.isPremiumUser);
  const [coursesList, setCoursesList] = useState([]);
  const [filterList, setFilterList] = useState([])
  const [courseCategories, setCourseCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setFilterBy] = useState(FILTER_BY[0].id);
  const [sortBy, setSortBy] = useState(SORT_BY[0].id);
  const [actualCoursesList, setActualCoursesList] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [innerCategoryName, setInnerCategoryName] = useState("");
  const [isOwnCategoryClick, setIsOwnCategoryClick] = useState(false)


  useEffect(() => {
    if (authBroker?.payload?.user.user_id) {
      // dispatch(
      //   getVideoCoursesListing({
      //     ecom: {
      //       module: "videoListing",
      //       broker: false,
      //       postData: {
      //         email: localStorage.getItem(SessionKeys.EMAIL_SESSION),
      //         company_id: authBroker?.payload?.user.company_id,
      //         broker_user_id: authBroker?.payload?.user.user_id,
      //       },
      //     },
      //     broker: {
      //       module: "videoListing",
      //       broker: true,
      //       postData: {
      //         email: localStorage.getItem(SessionKeys.EMAIL_SESSION),
      //         // email: localStorage.getItem(SessionKeys.EMAIL_SESSION),
      //       },
      //     },
      //   })
      // );
      // setIsLoading(true);
    }
  }, [dispatch, authBroker]);

  useEffect(() => {
    // console.log("Mergerd data videos", videoListingData);
    if (!_.isEmpty(videoListingData)) {
      // console.log('Mergerd data videos 1', videoListingData)
      let sortedData = [];
      // // let searchData = []
      // for (let videodata of videoListingData) {
      //   if (videodata.status === "success") {
      //     setIsLoading(false);
      //     let VidData = _.isArray(videodata.payload.data)
      //       ? videodata.payload.data
      //       : [];
      //     sortedData = sortByAlphabetical([...VidData, ...sortedData]);
      //     // let sortedData = sortByAlphabetical(arr);
      //     if (!_.isEmpty(sortedData)) {
      //       sortedData = VideoCoursesDataSetupSection(sortedData, isPremiumUser)
      //     }
      //     // setCoursesList(sortedData);
      //     // setActualCoursesList(sortedData);
      //   }
      // }
      // setCoursesList(sortedData);
      // setFilterList(sortedData)
      // setActualCoursesList(sortedData);
      dispatch(getVideoCoursesListingClear());
    }
  }, [videoListingData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!_.isEmpty(authBroker) && !_.isEmpty(catId)) {
     
      getCategoryVideos()
    }
  }, [authBroker, catId]);

  const getCategoryVideos = ()=>{
    setIsLoading(true);
    dispatch(getVideoCoursesListingByCat({
      module: "getVideoCourseListingByCat",
      postData: {
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        brokerage_id: authBroker.payload.user.company_id,
        category_id: catId
      }
    }))
  }

  const getSubCategoryVideos = (subCatId) => {
    setIsLoading(true)
    dispatch(getVideoCoursesListingBySubCat({
      module: "getVideoCourseListingBySubCat",
      postData: {
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        brokerage_id: authBroker.payload.user.company_id,
        sub_category_id: subCatId
      }
    }))
  }

  useEffect(() => {
    if (!_.isEmpty(videoListingDataByCatRes)) {
      setIsLoading(false);
      setIsOwnCategoryClick(false);
      let sortedData = [];
      if (videoListingDataByCatRes?.status === "success") {
        sortedData = videoListingDataByCatRes?.payload?.payload?.videoList;
        // let sortedData = sortByAlphabetical(arr);
        if (!_.isEmpty(sortedData)) {
          sortedData = VideoCoursesDataSetupSection(sortedData, isPremiumUser)
        }
        setSubCategories(videoListingDataByCatRes?.payload?.payload?.sub_categories);
        setCategoryName(videoListingDataByCatRes?.payload?.payload?.category_name);
        setInnerCategoryName(videoListingDataByCatRes?.payload?.payload?.category_name);

      }

      if (subCatId !== undefined && subCatId !== "" && subCatId !== null) {
        getSubCategoryVideos(subCatId)
      } else {
        setCoursesList(sortedData);
        setFilterList(sortedData)
        setActualCoursesList(sortedData);
      }

      dispatch(getVideoCoursesListingByCatClear());
    }
  }, [videoListingDataByCatRes])

  useEffect(() => {
    if (!_.isEmpty(videoListingDataBySubCatRes)) {
      setIsLoading(false);
      let sortedData = [];
      if (videoListingDataBySubCatRes?.status === "success") {
        sortedData = videoListingDataBySubCatRes?.payload?.payload?.videoList;
        // let sortedData = sortByAlphabetical(arr);
        if (!_.isEmpty(sortedData)) {
          sortedData = VideoCoursesDataSetupSection(sortedData, isPremiumUser)
        }
        setInnerCategoryName(videoListingDataBySubCatRes?.payload?.payload?.sub_category_name);
      }
      setCoursesList(sortedData);
      setFilterList(sortedData)
      setActualCoursesList(sortedData);
      dispatch(getVideoCoursesListingBySubCatClear());
    }
  }, [videoListingDataBySubCatRes])

  useEffect(() => {
    // console.log("product categories", productCategoriesData, courseCategoriesDataBroker);
    if (
      !_.isEmpty(productCategoriesData)
    ) {
      let categoryArray = [];
      if (
        !_.isEmpty(productCategoriesData) &&
        productCategoriesData.status === "success"
      ) {
        if (productCategoriesData?.payload?.length > 0) {
          categoryArray = [...categoryArray, ...productCategoriesData.payload];
        }
      }
      // if (
      //   !_.isEmpty(courseCategoriesDataBroker) &&
      //   courseCategoriesDataBroker.status === "success"
      // ) {
      //   if (courseCategoriesDataBroker?.payload?.length > 0) {
      //     categoryArray = [
      //       ...categoryArray,
      //       ...courseCategoriesDataBroker.payload,
      //     ];
      //   }
      // }
      setCourseCategories(categoryArray);
    }
  }, [productCategoriesData]);



  //Sorting Video listing
  const sortByAlphabetical = (list) => {
    // console.log(
    //   "sortingggg1--->",
    //   list.sort((a, b) => a.published_date.localeCompare(b.published_date))
    // );
    return list.sort((a, b) =>
      b.published_date.localeCompare(a.published_date)
    );
  };

  const sortByAlphabeticalReverse = (list) => {
    // console.log(
    //   "sortingggg1--->",
    //   list.sort((a, b) => a.published_date.localeCompare(b.published_date))
    // );
    return list.sort((a, b) =>
      a.published_date.localeCompare(b.published_date)
    );
  };

  const sortByAlphabeticalDes = (list) => {

    return (list.filter((item) => item.is_purchased)
    )

  };

  const searchCourses = (list, searchText) => {
    return list.filter((item) => {
      return (
        item.product_name != null &&
        item.product_name.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  };

  const onClickFilterBy = (e) => {
    const value = e.target.value;
    setFilterBy(value);
    // console.log("FILTER BY", value);
    if (value == 1) {
      // const sortedList = sortByAlphabetical(actualCoursesList);
      setCoursesList(actualCoursesList);
      setFilterList(actualCoursesList)
      clickStreamUser("clicked-All-View", {
        userId: authBroker?.payload?.user.user_id,
      });
    } else if (value == 2) {
      const filtered = actualCoursesList.filter((c) => c.showBuy);
      setCoursesList(filtered);
      clickStreamUser("clicked-available-for-purchase", {
        userId: authBroker?.payload?.user.user_id,
      });
    } else if (value == 3) {
      const sortedListbyDes = sortByAlphabeticalDes(actualCoursesList);
      setCoursesList(sortedListbyDes);
      clickStreamUser("clicked-purchased-list", {
        userId: authBroker?.payload?.user.user_id,
      });
    } else if (value == 4) {
      const sortedData = actualCoursesList.filter((c) => c.is_assigned && !c.is_purchased)
      setCoursesList(sortedData);
      clickStreamUser("clicked-assign-to-me-filter", {
        userId: authBroker?.payload?.user.user_id,
      });
    }
    setSortBy(1);
    _searchRef.current.value = ''
  };

  const onClickSortBy = (e) => {
    const value = e.target.value;
    setSortBy(value);
    //  console.log("SORT BY", value);
    if (value == 1) {
      const sortedList = sortByAlphabetical(coursesList);
      setCoursesList(sortedList);
      setFilterList(sortedList)
      clickStreamUser("clicked-New-to-Old", {
        userId: authBroker?.payload?.user.user_id,
      });
    } else if (value == 2) {
      const sortedListbyDes = sortByAlphabeticalReverse(coursesList);
      setCoursesList(sortedListbyDes);
      setFilterList(sortedListbyDes)
      clickStreamUser("clicked-Old-to-New", {
        userId: authBroker?.payload?.user.user_id,
      });
    }
  };

  const onSearchList = (e) => {
    // onClickFilterBy({"target":{"value":filterBy}})
    // var tempList =
    //   filterBy == 1
    //     ? sortByAlphabetical(actualCoursesList)
    //     : sortByAlphabeticalDes(actualCoursesList);
    const sortedListbyDes = searchCourses(filterList, e.target.value);
    setCoursesList(sortedListbyDes);
  };

  return (
    <>
      {/* <Loader start={isLoading} /> */}
      <Page>
        <div className="my-credit-points video-page-rsp">
          <section className="container">
            <div className="row">
              <div className="col-md-12 profile-left-sec">
                <div className="search_heading">
                  {/* <img src="/images/learning_img.png" width="109" height="106" alt="" /> */}
                  <p className="lable-heading_search">Videos</p>
                </div>
              </div>
            </div>
            {/* <CourseListBody
                                coursesList={coursesList}
                                pageType="video"
                            /> */}

            <div className="row mt-5">
              {/* <div className="col-md-4 search__input">
                                    <div className="input-lear position-relative d-flex">
                                        <label htmlFor="category" className="input__label_learning"></label>
                                        <select className="form-select input_searchfield" id="category" aria-label="Default select example" onChange={(ev) => {
                                        }}>
                                            <option selected="" value="">All</option>
                                        </select>
                                    </div>
                                </div> */}
              <div className="col-md-3 search__input">
                <div className="input-lear position-relative d-flex">
                  <label htmlFor="category" className="input__label_learning">
                    Category
                  </label>
                  <select
                    className="form-select input_searchfield"
                    id="category"
                    aria-label="Default select example"
                    onChange={(ev) => {
                      // dispatch(getSelectedCatId(ev.target.value));
                      history.push(`/video-listing?catId=${ev.target.value}`);
                    }}
                  >
                    {courseCategories.map((cat, index) => {
                      return (
                        <option
                          value={cat.categoryId}
                          selected={
                            catId == cat.categoryId ? true : false
                          }
                          key={`category-${index + 1}`}
                        >
                          {cat.categoryName}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 search__input">
                <div className="input-lear position-relative d-flex">
                  <label htmlFor="sortBy" className="input__label_learning">
                    Filter by
                  </label>
                  {/* <select id="sortBy" className="form-select input_searchfield" aria-label="Default select example" >
                                            <option selected="" value='1'>New to O</option>
                                            <option value="2">Old to New</option>
                                            <option value="3">Purchased Courses</option>
                                        </select> */}
                  <select
                    className="form-select input_searchfield"
                    aria-label="Default select example"
                    // width="30px"
                    value={filterBy}
                    onChange={onClickFilterBy}
                  >
                    {FILTER_BY.map((item, index) => {
                      return (
                        <option seleted key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 search__input">
                <div className="input-lear position-relative d-flex">
                  <label htmlFor="sortBy" className="input__label_learning">
                    Sort by
                  </label>
                  {/* <select id="sortBy" className="form-select input_searchfield" aria-label="Default select example" >
                                            <option selected="" value='1'>New to O</option>
                                            <option value="2">Old to New</option>
                                            <option value="3">Purchased Courses</option>
                                        </select> */}
                  <select
                    className="form-select input_searchfield"
                    aria-label="Default select example"
                    // width="30px"
                    value={sortBy}
                    onChange={onClickSortBy}
                  >
                    {SORT_BY.map((item, index) => {
                      return (
                        <option seleted key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-3 search__input">
                <div className="input-lear position-relative d-flex">
                  <label htmlFor="search" className="input__label_learning">
                    Search
                  </label>
                  <input
                    type="text"
                    id="search"
                    name="search"
                    ref={_searchRef}
                    className="input_searchfield_search"
                    //placeholder="Search here"
                    onChange={onSearchList}
                  />
                  <button
                    className="button-reset"
                    style={{ marginTop: "-20px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stopColor="#00b7f1" />
                          <stop offset="1" stopColor="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_191"
                        data-name="Ellipse 191"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_192"
                        data-name="Ellipse 192"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="Icon_awesome-search"
                        data-name="Icon awesome-search"
                        d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                        transform="translate(7.715 7.714)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* <p>This is video listing page</p> */}
            <div className="row mt-3">
              <div className="col-md-3">
                {isOwnCategoryClick || (subCatId !== null || !isLoading) ? <VideoCatLeftMenu subCategories={subCategories} categoryName={categoryName} getSubCategoryVideos={getSubCategoryVideos} setIsOwnCategoryClick={setIsOwnCategoryClick} getCategoryVideos={getCategoryVideos}/> :
                  <>
                    <div className="row">
                      {
                        [1, 2].map(n => {
                          return (
                            <ShimmerNotification />
                          )
                        })
                      }
                    </div>
                  </>}
              </div>
              <div className="col-md-9">
                {!isLoading ? <VideoCoursesListingCard
                  innerCategoryName={innerCategoryName}
                  coursesList={coursesList}
                  isLoading={isLoading}
                /> : <>
                  <div className="row videolistbox">
                    {
                      [1, 2, 3].map(i => {
                        return (
                          <ShimmerCardBodyThree key={`shimmer-${i}`} />
                        )
                      })
                    }
                  </div>
                </>}
              </div>
            </div>
            {/* <VideoViewPage coursesList={coursesList}/> */}

          </section>
        </div>
      </Page>
    </>
  );
};
export default VideoCoursesListingPage;
