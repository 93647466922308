import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCoursesListing } from "../../redux/courses/actions";
import { recommendProduct } from "../../redux/product/actions";
import {
  convertHoursToMins,
  convertTimeFormat,
  SessionKeys,
} from "../../utils/AppUtils";
import {
  recommendationResponse,
  recommendationData,
  recommendationClear,
} from "../../redux/recommendation/actions";
import CourseCardListBody from "../Course/CourseCardListBody";
import PrevArrow from "../LearningPaths/PrevArrow";
import NextArrow from "../LearningPaths/NextArrow";
import Slider from "react-slick";
import SliderCourseListing from "../LearningPaths/SliderCourseListing";
import CenterLine from "../CenterLine";
import { Spinner } from "react-bootstrap";

const RecommendProductCard = (props) => {
  const { courseId,
    courseIdArr,
    from } = props;
  const dispatch = useDispatch();

  const authBroker = useSelector((state) => state.auth.authBroker);
  const recommendationDataList = useSelector(
    (state) => state.recommendation.recommendationData
  );
  const [recommendationList, setRecommendationList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [recommendView1, setRecommendView1] = useState({});
  const recommendationData2 = [];

  useEffect(() => {
    if (!_.isEmpty(authBroker)) {
      getRecommendedData();
    }
  }, [dispatch, authBroker, courseIdArr]);

  useEffect(() => {
    // console.log("RECOMMENED DATA", recommendationDataList);
    if (!_.isEmpty(recommendationDataList)) {
      setIsLoading(false)
      if (recommendationDataList.status === "success") {
        const list = recommendationDataList.payload.payload;
        if (!_.isEmpty(list)) {
          for (let course of list) {
            course.is_bundle = false;
            if (course.sfdc_product_type.toLowerCase() !== "bundle") {
              if (course.durationUnit === "Hours") {
                course.duration = convertHoursToMins(course.duration);
              }
              course.duration = convertTimeFormat(course.duration);
              course.durationUnit = "";
            } else {
              // console.log('Duration', course)
              if (!_.isEmpty(course.course_list)) {
                course.is_bundle = true;
                for (let cl of course.course_list) {
                  if (cl.durationUnit === "Hours") {
                    cl.duration = convertHoursToMins(cl.duration);
                  }
                  cl.duration = convertTimeFormat(cl.duration);
                  cl.durationUnit = "";
                }
              }
            }
          }
          list.sort((a, b) => parseFloat(b.views) - parseFloat(a.views));
          setRecommendationList(list);
        }
      }
      dispatch(recommendationClear());
    }
  }, [recommendationDataList]);

  const getRecommendedData = () => {
    setIsLoading(true)

    let req = {
      email: authBroker.payload.user.email,
      user_id: authBroker?.payload?.user.user_id,
      company_id: authBroker?.payload?.user.company_id,
    }
    if (from === 'shopping') {
      req['product_id'] = _.toNumber(courseId)
    }else{
      req['cart_ids'] = courseIdArr
    }

    dispatch(
      recommendationData({
        module: "myRecommendation",
        postData: req
      })
    );
  };

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    dots: true,
    arrows: true,
    autoplaySpeed: 2000,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {
        isLoading ? <div className="container not-found mt-3">
          <div className="interboxes">
            <Spinner animation="border" />
          </div>
        </div> :
          recommendationList.length > 0 ? (
            <>
              {/* <CenterLine /> */}
              <div className="container mt-5">
                <div className="details-course-desp">
                  <h5 style={{ padding: "0px" }}>Recommendations</h5>
                  <p className="recommendations-product">
                    Based on the learning you are taking, here are our
                    recommendations for you to improve your skills.
                  </p>
                </div>
                <div className="row">
                  {/* <Slider {...settings}>
              {
                recommendationList.map((item, i) => {
                  return (
                    <div
                      className="col-md-6 col-lg-4 col-xl-3 col-12 mb-4 broker-box"
                      key={i}
                    >
                      <div className="img-box_nw">
                        <div className="img-videosec learn_image-card cursor-pointer">
                          <img
                            className="img-fluid galleryImg_lern"
                            src={item.imageURL}
                          />
                        </div>
                        <div className="row"></div>
                        <div className="details-card_nw">
                          <h5 className="cursor-pointer">
                            <a
                              className="course-head-al_search"
                              title="Morbi tempus nibh semper"
                              dangerouslySetInnerHTML={{
                                __html: item.name,
                              }}
                            ></a>
                          </h5>

                          <div className="row info-bottm-card_nw">
                            <div className="col-md-12 cou_cardIcon">
                              <p>
                                <span>{item.lessons} &nbsp;</span>
                                {item.lessons > 1 ? "Modules" : "Module"}
                              </p>
                              <div className="carouseL_Iconno2 d-flex flex-column">
                                <div className="ico__imG01 d-flex">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      id="clock-time-four"
                                      d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8m3.44,10.56L9.2,10.24V6h1.2V9.52l3.6,2Z"
                                      transform="translate(-2 -2)"
                                      fill="var(--color-element-1)"
                                    />
                                  </svg>
                                  <p>
                                    {" "}
                                    <span>{item.duration}</span> Minutes
                                  </p>
                                </div>
                                <div className="ico_texxt02 d-flex pt-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      id="eye-circle"
                                      d="M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,11.2A5.178,5.178,0,0,1,5.2,10a5.2,5.2,0,0,1,9.6,0A5.178,5.178,0,0,1,10,13.2M10,8a2,2,0,1,0,2,2,2,2,0,0,0-2-2m0,2.8a.8.8,0,1,1,.8-.8A.8.8,0,0,1,10,10.8Z"
                                      transform="translate(-2 -2)"
                                      fill="var(--color-element-1)"
                                    />
                                  </svg>
                                  <p>
                                    <span>{item.views}</span>{" "}
                                    {item.views > 1 ? "Views" : "View"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row infoIcon_nw">
                            <div className="col-md-12 d-flex justify-content-between align-items-center action_learning02 action_learning">
                              <div className="d-flex carouseL_IconnBY">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    id="Exclusion_3"
                                    data-name="Exclusion 3"
                                    d="M21486.014-926.971a11.938,11.938,0,0,1-8.5-3.519,11.932,11.932,0,0,1-3.52-8.5,11.932,11.932,0,0,1,3.52-8.5,11.938,11.938,0,0,1,8.5-3.519,11.938,11.938,0,0,1,8.5,3.519,11.932,11.932,0,0,1,3.52,8.5,11.932,11.932,0,0,1-3.52,8.5A11.938,11.938,0,0,1,21486.014-926.971Zm.146-15.8,2.725,4.543v2.725h1.877v-2.811l2.682-4.456h-2.066l-1.516,2.8-1.564-2.8Zm-6.734,0h0v7.267h3.178a5.257,5.257,0,0,0,1.307-.135,2.731,2.731,0,0,0,.943-.459,1.955,1.955,0,0,0,.584-.691,2.082,2.082,0,0,0,.209-.939,1.653,1.653,0,0,0-.414-1.176,2.017,2.017,0,0,0-1.129-.583v-.043a1.78,1.78,0,0,0,.783-.626,1.6,1.6,0,0,0,.275-.928,1.605,1.605,0,0,0-.2-.809,1.431,1.431,0,0,0-.619-.566,2.9,2.9,0,0,0-.8-.259,10.487,10.487,0,0,0-1.289-.054h-2.822Zm2,5.935h-.135v-1.813h.41l.418,0h.006l.381,0a2.429,2.429,0,0,1,.6.07.8.8,0,0,1,.453.281.886.886,0,0,1,.135.534,1.009,1.009,0,0,1-.107.469.806.806,0,0,1-.4.324,1.631,1.631,0,0,1-.631.124C21482.334-936.834,21481.953-936.834,21481.428-936.834Zm.156-3.065h-.291v-1.538h.178c.365,0,.652,0,.857.005a1.5,1.5,0,0,1,.508.086.548.548,0,0,1,.318.27.752.752,0,0,1,.092.362,1.053,1.053,0,0,1-.076.41.616.616,0,0,1-.334.291,1.4,1.4,0,0,1-.49.1C21482.135-939.9,21481.879-939.9,21481.584-939.9Z"
                                    transform="translate(-21473.998 951.002)"
                                    fill="var(--color-element-1)"
                                    opacity="1"
                                  ></path>
                                </svg>
                                <p>
                                  {" "}
                                  <span className="sub_txt_lern" title="Xsel">
                                    {item.provider.provider_display_name}
                                  </span>
                                </p>
                              </div>
                              <div className="d-flex justify-content-between revisitIconcon comp4530 cursor-pointer">
                                <img
                                  src="/images/Component 46-78.svg"
                                  width="80"
                                  height="17"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider> */}
                  <SliderCourseListing
                    isOnboarding={false}
                    coursesList={recommendationList}
                    setFavoriteCourse={() => { }}
                    closeModal={"false"}
                    setCloseModal={() => { }}
                    hasDescription={false}
                    pageType="course"
                    showBlankCard={false}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
    </>
  );
};

export default RecommendProductCard;
