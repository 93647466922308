import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import Page from "../../components/Page";
import RolePlayPageSection from "../../components/RolePlay/RolePlayPageSection";


const RolePLay = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [loaderMsg, setLoaderMsg] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Loader start={isLoading} msg={loaderMsg} />
            <Page>
                <RolePlayPageSection 
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    isMobile={false}
                    setLoaderMsg={setLoaderMsg}
                />
            </Page>
        </>
    )
}

export default RolePLay