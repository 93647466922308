import BarChart from "../Layouts/Graphs/BarChart";
import PieChart from "../Layouts/Graphs/PieChart";
import DonutChart from "../Layouts/Graphs/DonutChart";
import RadialBarChart from "../Layouts/Graphs/RadialBarChart";
import PolarAreaChart from "../Layouts/Graphs/PolarAreaChart";
import _ from "lodash";

const ReportGraphSection = ({
    series,
    legends,
    type,
    isLoading,
    isAmount,
    isCoeReport
}) => {

    const renderChart = () => {
        switch (type) {
            case 'bar':
                return barChart();
            case 'pie':
                return pieChart();
            case 'donut':
                return donutChart();
            case 'radial':
                return radialBarChart();
            case 'polar area':
                return polarAreaChart();
        }
    }

    const barChart = () => {
        return (
            <BarChart
                series={series}
                legends={legends}
                showDollar={isAmount}
            />
        )
    }

    const pieChart = () => {
        return (
            <PieChart
                series={series}
                legends={legends}
                isCoeReport={isCoeReport}
            />
        )
    }

    const donutChart = () => {
        return (
            <DonutChart
                series={series}
                legends={legends}
            />
        )
    }

    const radialBarChart = () => {
        const total = _.sum(series)
        let newSeries = []
        for (let s of series) {
            newSeries.push()
        }
        return (
            <RadialBarChart
                series={series.map(s => {
                    return _.toNumber((s / total) * 100).toFixed(2)
                })}
                legends={legends}
            />
        )
    }

    const polarAreaChart = () => {
        return (
            <PolarAreaChart
                series={series}
                legends={legends}
            />
        )
    }

    return (
        <div className="container-chart">

            <div className="chartsmboxreport">
                {
                    !isLoading ?
                        renderChart()
                        : ''}
            </div>

        </div>
    )
}

export default ReportGraphSection