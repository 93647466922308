import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CountUp from 'react-countup';
import $ from "jquery";
import { SettingsRemoteRounded } from '@mui/icons-material';
import CongPopupAnimation from "./CongPopupAnimation";
import { useDispatch } from 'react-redux';
import { setCongratClassName } from '../../redux/assignCourse/actions';
function CongratulationsPopup(props) {
  const [timeOutAnimation, setTimeOutAnimation] = useState(true)
  const [counterValue, setCountValue] = useState(null)
  const [timerValue, setTimerValue] = useState(true)
  const [timeZoom, setTimeZoom] = useState(true)
  const [timerZoom, setTimerZoom] = useState(true)
  const { title, lpPoints, fromPage } = props; // fromPage ==> 0 = learningpoint, 1 = badge, 2 = certificate
console.log(lpPoints,"title");
console.log(title,"title");
  const dispatch = useDispatch();

  useEffect(() => {
    // flyToProfileSec()
    setTimeout(() => {
      setTimeOutAnimation(false)
    }, 5500)
    setTimeout(() => {
      setTimerValue(false)
    }, 3200)
    setTimeout(() => {
      setTimeout(() => {
        dispatch(setCongratClassName('step'))
        setTimeout(() => {
          dispatch(setCongratClassName('step1')) //setting class for blinking profile pic
        }, 3000)
      }, 2000)
      setTimerZoom(false)   // setting class on this flag, for fly points
    }, 3700)
  }, [])

  return (
    <>
      {timeOutAnimation ? <div
        className="modal congrtsmodal show"
        style={{ display: 'block' }}
      >
        <Modal.Dialog centered>

          <Modal.Body className="congratulationmodal">
            <h4>Congratulations</h4>
            <div className="congrtsbox">
              <p>You have successfully completed the <strong dangerouslySetInnerHTML={{
                __html: title,
              }}></strong> course.</p>
              <p>You have just earned yourself</p>
            </div>
            <div className="d-flex gap-2 justify-content-center centerboxcongrats">
              <div className="counmainbx">
                <div className={(timerZoom === true) ? "countbox" : "zoom"}>
                  <div className="d-flex gap-4 justify-content-between">
                    <div className="points-box-earned">
                      <div className="countno">+<CountUp duration={3} start={0} end={lpPoints} delay={0}>
                        {({ countUpRef }) => (
                          <strong className="counteranime">
                            <span ref={countUpRef} />
                          </strong>
                        )}
                      </CountUp></div>
                      <div className="lerpoints">Learning <br />Points</div>
                    </div>

                    {fromPage === 1 ? <div className="earned_badge">
                      <img src="/images/badge-1.jpg" className="img-fluid" style={{ width: '64px' }} />
                      <div className="lerpoints">{title} Badge</div>
                    </div>
                      :
                      fromPage === 2 ? <div className="earned_badge">
                        <img src="/images/my_certificate.jpg" className="img-fluid" style={{ width: '64px' }} />
                        <div className="lerpoints">{title} Certificate</div>
                      </div> : ""
                    }
                  </div>
                </div>

              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div> : ""}
      {false && (timerValue) ? <CongPopupAnimation /> : ""}
    </>
  );
}

export default CongratulationsPopup;

