import { fork, take, call, put, all } from "redux-saga/effects";

import { recommendationResponse } from "./actions";
import types from "./types";
import { postAPIWithoutModule } from "../../api/generic";

export function* doRecommendation() {
  while (true) {
    const { payload } = yield take(types.RECOMMENDATION);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(recommendationResponse(response));
  }
}

function* recommendation() {
  yield fork(doRecommendation);
}

export default recommendation;
