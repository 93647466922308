import data from "../../data/product";
import types from "./types";

const initialState = {
  recommendationData: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.RECOMMENDATION_RESPONSE:
      return {
        ...state,
        recommendationData: action.payload,
      };
    case types.RECOMMENDATION_CLEAR:
      return {
        ...state,
        recommendationData: {},
      };
    default:
      return state;
  }
};
