import { fork, take, call, put,all } from 'redux-saga/effects'

import {
  getVideoCourseCategoriesResponse,
  getVideoCoursesListingResponse,
  getVideoDetailListingResponse,
  getBrokerVideoListingResponse,
  getVideoComptResponse,
  getBrokerVideoDetailResponse,
  getMyVideosResponse,
  getVideoCoursesListingByCatResponse,
  getVideoCoursesListingBySubCatResponse,
  getVideoCoursesListingRelatedResponse,
} from './actions'
import types from './types'
import { getAPI,postAPI,postAPIWithoutModule} from '../../api/generic'

export function* doGetVideoCourseCategories() {
  while (true) {
    const { payload } = yield take(types.VIDEO_COURSE_CATEGORIES)
    const response = yield call(getAPI, payload)
    yield put(getVideoCourseCategoriesResponse(response))
  }
}

// export function* doGetVideoCoursesListing() {
//   while (true) {
//     const { payload } = yield take(types.VIDEO_COURSES_LISTING)
//     const response = yield call(postAPI, payload)
//     yield put(getVideoCoursesListingResponse(response))
//   }
// }

// export function* doGetVideoDetailListing() {
//   while (true) {
//     const { payload } = yield take(types.VIDEO_DETAIL_LISTING)
//     const response = yield call(postAPIWithoutModule, payload)
//     yield put(getVideoDetailListingResponse(response))
//   }
// }

// export function* doGetBrokerVideoDetailList() {
//   while (true) {
//     const { payload } = yield take(types.VIDEO_DETAIL_BROKER_ADMIN)
//     const response = yield call(postAPI, payload)
//     yield put(getBrokerVideoListingResponse(response))
//   }
// }


// video listing page
export function* doGetVideoCoursesListing() {
  while (true) {
    const { payload } = yield take(types.VIDEO_COURSES_LISTING)
    const response = yield all([
      call(postAPI, payload.ecom),
      call(postAPI, payload.broker)
    ])
    yield put(getVideoCoursesListingResponse(response))
  }
}

// video view page 
export function* doGetVideoCourseListingByCat() {
  while (true) {
    const { payload } = yield take(types.VIDEO_COURSES_LISTING_BY_CAT)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getVideoCoursesListingByCatResponse(response))
  }
}

// video view page 
export function* doGetVideoCourseListingBySubCat() {
  while (true) {
    const { payload } = yield take(types.VIDEO_COURSES_LISTING_BY_SUB_CAT)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getVideoCoursesListingBySubCatResponse(response))
  }
}

// video view page 
export function* doGetVideoCourseListingRelated() {
  while (true) {
    const { payload } = yield take(types.VIDEO_COURSES_LISTING_RELATED)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getVideoCoursesListingRelatedResponse(response))
  }
}

// video view page 
export function* doGetVideoDetailFromEcom() {
  while (true) {
    const { payload } = yield take(types.VIDEO_DETAIL_LISTING)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getVideoDetailListingResponse(response))
  }
}
export function* doGetVideoDetailFromBroker() {
  while (true) {
    const { payload } = yield take(types.VIDEO_INFO_BROKER_ADMIN)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getBrokerVideoDetailResponse(response))
  }
}

export function* doGetVideoCompletion() {
  while (true) {
    const { payload } = yield take(types.VIDEO_COMPLETION_TRACK)
    const response = yield call(postAPIWithoutModule, payload)
    yield put(getVideoComptResponse(response))
  }
}

export function* doGetMyVideos() {
  while (true) {
    const { payload }  = yield take(types.MY_VIDEO_FETCH)
    const response = yield all([
      call(postAPIWithoutModule, payload.broker)
    ])
    yield put(getMyVideosResponse(response))
  }
}

function* video() {
  yield fork(doGetVideoCourseCategories)
  yield fork(doGetVideoCoursesListing)
  yield fork(doGetVideoCompletion)
  yield fork(doGetVideoDetailFromEcom)
  yield fork(doGetVideoDetailFromBroker)
  yield fork(doGetMyVideos)
  yield fork(doGetVideoCourseListingByCat)
  yield fork(doGetVideoCourseListingBySubCat)
  yield fork(doGetVideoCourseListingRelated)
}

export default video