import React, { Component } from "react";
import ReactDOM from "react-dom";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

import "react-fine-uploader/gallery/gallery.css";
import CONFIG from "../../utils/config";
import FileInput from "react-fine-uploader/file-input";
import Status from "react-fine-uploader/status";
import ProgressBar from "react-fine-uploader/progress-bar";
import Filename from "react-fine-uploader/filename";
import DeleteButton from "react-fine-uploader/delete-button";
import Thumbnail from "react-fine-uploader/thumbnail";

const uploader = new FineUploaderTraditional({
  options: {
    chunking: {
      enabled: true,
      success: {
        endpoint: CONFIG.API_URL + "meeting/fine-uploader/endpoint-cors.php?done"  
      }
    },
    deleteFile: {
      method: 'DELETE',
      enabled: true,
      forceConfirm: true,
      endpoint: CONFIG.API_URL + "meeting/fine-uploader/endpoint-cors.php"
    },
    request: {
      endpoint: CONFIG.API_URL + "meeting/fine-uploader/endpoint-cors.php"
    },
    retry: {
      enableAuto: true
    },
    resume: {
        enabled: true
    },
    multiple: false,
    callbacks: {
        onSubmit: async (id) => {
            console.log('onsubmit id', id)
        },
        onComplete: (id, name, response) => {
            console.log('on complete', id, name, response)
        },
        onTotalProgress: (totalUploadedBytes, totalBytes) => {
            console.log('onTotalProgress', totalUploadedBytes, totalBytes)
        },
        onError: (id, name, errorReason) => {
            console.log('error', id, name, errorReason)
        },
        onDelete: (id) => {
          console.log('on delete', id)
        },
        onDeleteComplete: (id, xhr, isError) => {
          console.log('on delete complete', id, xhr, isError)
        }
        // onUploadChunk: (id, name, chunkData) => {
        //   console.log('upload chunk', id, name, chunkData)
        // },
        // onUploadChunkSuccess: (id, chunkData, responseJSON) => {
        //   console.log('onUploadChunkSuccess', id, chunkData, responseJSON)
        // }
    },
    validation: {
      itemLimit: 1,
      minSizeLimit: 100,
      sizeLimit: (60 * 1000000),
      allowedExtensions: ['mp4']
    }
  }
});

class FineUploaderVideoComponent extends Component {

    constructor(props) {
      super()
      this.state = {
        submittedFiles: [],
        error: ""
        // completed: false
      }
      this.deleteButtonRef = React.createRef(null);
    }

    componentDidMount() {
      uploader.on('statusChange', (id, oldStatus, newStatus) => {
        if (newStatus === 'submitted') {
            const submittedFiles = this.state.submittedFiles

            submittedFiles.push(id)
            this.setState({ submittedFiles })
        }
        else if (isFileGone(newStatus)) {
            const submittedFiles = this.state.submittedFiles
            const indexToRemove = submittedFiles.indexOf(id)

            submittedFiles.splice(indexToRemove, 1)
            this.setState({ submittedFiles })
        } else {
          this.props.setIsLoading(true)
          this.props.setLoaderMsg('Your uploaded file is in process, please wait...')
        }
      })
      uploader.on('complete', (id, name, response) => {
        if (response.success) {
          this.setState({completed: true})
          response.type = this.props.type;
          this.props.setUploadResponse(response)
          this.props.setIsUploaded(true)
        }
      })
      uploader.on('error', (id, name, errorReason) => {
        this.setState({error: errorReason})
      })
      // uploader.on('submitted', id => {
      //   const submittedFiles = this.state.submittedFiles

      //   submittedFiles.push(id)
      //   this.setState({ submittedFiles })
      // })
      uploader.on('delete', () => {
        const submittedFiles = []
        this.setState({submittedFiles})
      })
    }

    componentDidUpdate() {
      if (this.props.deleteUploadFile) {
        this.state.submittedFiles.map(id => {
          uploader.methods.deleteFile(id)
        })
      }
    }

    componentWillUnmount() {
      uploader.off('submitted')
      uploader.off('complete')
      uploader.off('error')
      uploader.off('delete')
    }

    render() {
        return (
          <div className="d-flex flex-column gap-2 w-50">
            {/* <h1>Upload your files</h1> */}
            {/* <Gallery uploader={uploader} /> */}
            {/* <FileInput accept={'video/*'} uploader={ uploader }> */}
            <FileInput 
              id="upload-video"
              className="cursor-pointer"
              accept='.mp4'
              uploader={ uploader }
              disabled={this.props.recordingStarted}
              onClick={() => {
                this.state.submittedFiles.map(id => {
                  uploader.methods.deleteFile(id)
                })
                this.props.setDeleteUploadFile(true)
              }}
            >
              <button className="btn-primary cursor-pointer" htmlFor="upload-video">
                Upload Video
              </button>
            </FileInput>
            {
              this.state.submittedFiles.map(id => (
                <>
                  {/* // <Thumbnail id={ id } text={ { upload_successful: 'Success!' } uploader={ uploader } /> */}
                    <div className="statusboxfile" key={id}>
                        {
                          this.state.completed 
                          ?
                          <Filename id={ id } uploader={ uploader } />
                        :
                          <Status id={ id } uploader={ uploader } />
                        }
                      <DeleteButton id={ id } uploader={ uploader } ref={this.deleteButtonRef}>
                        <i className="fa fa-times" />
                      </DeleteButton>
                    </div>
                  {/* <ProgressBar id={ id } uploader={ uploader } /> */}
                </>
              ))
            }
            {
              this.state.error ? 
                <div className="error-message">{this.state.error}</div>
              :
              ""
            }
          </div>
        );
    }
}

const isFileGone = status => {
  return [
    'canceled',
    'deleted',
  ].indexOf(status) >= 0
}

export default FineUploaderVideoComponent;

// ReactDOM.render(<UploadComponent />, document.getElementById("root"));
