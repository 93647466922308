import MyWall from "../../components/Home/MyWall"
import Page from "../../components/Page"
import Loader from "../../components/Loader/Loader"
import { useEffect, useState } from "react"

const MyWallPage = () => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])


    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <MyWall setIsLoading = {setIsLoading} fromPage={'myWall'}/>
            </Page>
        </>
    )
}
export default MyWallPage