import Page from "../../components/Page";
import { useEffect, useState } from "react";
import LearningPathReportHomeSection from "../../components/Reports/learningPathProgress/LearningPathReportHomeSection";

export const LearningPathProgressReportPageMobile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
            if (document.getElementById("id_page_404") !== null) {
              document.getElementById("id_page_404").style.display = "none";
            }
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
              document.getElementById("cc_div").style.display = "none";
          }, 100)
    }, [])
    return (
        <>
            <LearningPathReportHomeSection
                isMobile={true}
            />
        </>
    )
}

export default LearningPathProgressReportPageMobile;