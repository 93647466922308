import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        Welcome: "Welcome",
        Login: "Login",
        "Email Address":"Email Address",
        Password:"Password",
        "Remember me":"Remember me",
        "Forgot Password?":"Forgot Password?",
        "Enter":"Enter",

        
      }
    },

    hin: {
      translations: {
        Welcome: "स्वागत",
        Login: "लॉग इन करें",
        "Email Address":"ईमेल पता",
        Password:"पासवर्ड",
        "Remember me": "मुझे याद किया",
        "Forgot Password?":"पासवर्ड भूल गए?",
        "Enter":"दर्ज करें"
        
      }
    },

    fr: {
      translations: {
        Welcome: "Bienvenu",
        Login: "Connexion",
        "Email Address":"Adresse e-mail",
        Password:"Mot de passe",
        "Remember me":"Souviens-toi de moi",
        "Forgot Password?":"Mot de passe oublié?",
        "Enter":"Entrer",
      }
    },

  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
