import types from "./types";

const initialState = {
  teamMatesList: {},
  current_view: 1,
  assignTeamMates: {},
  officeList: {},
  completedCourseList: {},
  usersByOfficeList: {},
  teamsList: {},
  assignTeamsRes: {},
  assignedUsers: {},
  congrat_class_name: "step1",
  chooseLpPath: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TEAMMATES_RESPONSE:
      return {
        ...state,
        teamMatesList: action.payload,
      };
    case types.GET_TEAMMATES_RESPONSE_CLEAR:
      return {
        ...state,
        teamMatesList: {},
      };
    case types.CURRENT_VIEW:
      return {
        ...state,
        current_view:
          action.payload === undefined ? state.current_view : action.payload,
      };
    case types.GET_CLASS:
      return {
        ...state,
        congrat_class_name:
          action.payload === undefined ? state.congrat_class_name : action.payload,
      };
    case types.ASSIGN_TEAMMATES_RESPONSE:
      return {
        ...state,
        assignTeamMates: action.payload,
      };
    case types.ASSIGN_TEAMMATES_RESPONSE_CLEAR:
      return {
        ...state,
        assignTeamMates: {},
      };
    case types.SEARCH_OFFICE_LIST_RESPONSE:
      return {
        ...state,
        officeList: action.payload,
      };
    case types.SEARCH_OFFICE_LIST_RESPONSE_CLEAR:
      return {
        ...state,
        officeList: {},
      };
    case types.COMPLETED_COURSE_LIST_RESPONSE:
      return {
        ...state,
        completedCourseList: action.payload,
      };
    case types.COMPLETED_COURSE_LIST_RESPONSE_CLEAR:
      return {
        ...state,
        completedCourseList: {},
      };
    case types.GET_USERS_BY_OFFICE_LIST_RESPONSE:
      return {
        ...state,
        usersByOfficeList: action.payload,
      };
    case types.GET_USERS_BY_OFFICE_LIST_CLEAR:
      return {
        ...state,
        usersByOfficeList: {},
      };
    case types.GET_TEAMS_LIST_RES:
      return {
        ...state,
        teamsList: action.payload,
      };
    case types.GET_TEAMS_LIST_RES_CLEAR:
      return {
        ...state,
        teamsList: {},
      };
    case types.ASSIGN_TO_TEAMS_RES:
      return {
        ...state,
        assignTeamsRes: action.payload,
      };
    case types.ASSIGN_TO_TEAMS_RES_CLEAR:
      return {
        ...state,
        assignTeamsRes: {},
      };
    case types.GET_ASSIGN_USERS_RES:
      return {
        ...state,
        assignedUsers: action.payload,
      };
    case types.GET_ASSIGN_USERS_RES_CLEAR:
      return {
        ...state,
        assignedUsers: {},
      };
    //Filter LP Report
    case types.CHOOSE_LP_REPORT_FILTER_RES:
      return {
        ...state,
        chooseLpPath: action.payload,
      };
    case types.CHOOSE_LP_REPORT_FILTER_CLEAR:
      return {
        ...state,
        chooseLpPath: {},
      };
    default:
      return state;
  }
};
