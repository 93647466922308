import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getCrsReportOffices,
  getCrsReportOfficesClear,
} from "../../../redux/reports/actions";
import { SessionKeys } from "../../../utils/AppUtils";
import _ from "lodash";
import CONFIG from "../../../utils/config";
import { CircularProgressbar } from "react-circular-progressbar";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const OfficesReportSection = (props) => {

  const { currentReportObj, mesg, isMobile } = props

  const dispatch = useDispatch();
  const history = useHistory();
  const crsOfficesRes = useSelector((state) => state.report?.crsReportOffices);
  const rea_ids = [];
  const office_ids = [];
  const lp_ids = [];
  const providerList = [];
  const courseList = []
  const [selectedCrsList] = useState(
    history?.location?.state?.selectedCourseList)
  const [counter, setCounter] = useState(1);
  const [totalOffices, setTotalOffices] = useState([])
  const [overAllOfficesData, setOverAllOfficesData] = useState([]);
  const [selectedOfficeList] = useState(
    history?.location?.state?.selectedOfficeList
  );
  const [selectedRea] = useState(
    history?.location?.state?.selectedTeamMatesList
  );
  const [selectedLpList] = useState(
    history?.location?.state?.selectedLpList
  );
  const [provrlist] = useState(history?.location?.state?.selectedProviderList)
  const [loadSpinner, setLoadSpinner] = useState(true)
  function setCount(counter, setCounter) {
    return setCounter(counter + 1);
  }
  useEffect(() => {
    selectedOfficeList.selectedOfficeList.forEach(function (obj) {
      office_ids.push(obj.office_id);
    });
    selectedRea.selectedTeamMatesList.forEach(function (obj) {
      rea_ids.push(obj.user_id);
    });
    selectedLpList.selectedLpList.forEach(function (obj) {
      lp_ids.push(obj.id);
    });
    provrlist.selectedProviderList.forEach(function (obj) {
      providerList.push(obj.p_id)
    })
    selectedCrsList.selectedCourseList.forEach(function (obj) {
      if (obj.source === "xsel") {
        courseList.push(obj.course_code)
      } else {
        courseList.push(obj.course_id)
      }
    })
  });
  useEffect(() => {
    if (currentReportObj === "course") {
      dispatch(getCrsReportOffices({
        module: 'getCourseReportOffices',
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          office_ids: office_ids,
          rea_ids: rea_ids,
          limit: counter,
          entity_id: lp_ids,
          entity_id: courseList,
          provider_ids: providerList,
          compliance: 0
        }
      }))
    }
    if (currentReportObj === "compliance") {
      dispatch(getCrsReportOffices({
        module: 'getCourseReportOffices',
        postData: {
          token: isMobile ? localStorage.getItem(SessionKeys.API_TOKEN) : localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          office_ids: office_ids,
          rea_ids: rea_ids,
          limit: counter,
          entity_id: lp_ids,
          entity_id: courseList,
          compliance: 1
        }
      }))
    }
  }, [dispatch])
  //LP Offices
  useEffect(() => {
    setCounter(1)
    setOverAllOfficesData([])
    return (() => {
      dispatch(getCrsReportOfficesClear())
    })
  }, [])

  useEffect(() => {
    if (!_.isEmpty(crsOfficesRes)) {
      setLoadSpinner(false)
      if (crsOfficesRes?.status === "success") {
        let allData = (crsOfficesRes?.payload?.payload?.offices);
        setTotalOffices(crsOfficesRes?.payload?.payload)
        let temparr = _.cloneDeep(overAllOfficesData)
        if (counter === 1) {
          temparr = []
        }
        setOverAllOfficesData([...temparr, ...allData])
        getCrsReportOfficesClear()
      }
    }
  }, [crsOfficesRes])
  return (
    <section className="offices-rpeort progress-box-report">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="progess-count-tiel">
              Offices&nbsp;<strong>({totalOffices?.total_offices})</strong>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {(overAllOfficesData !== undefined) && (overAllOfficesData !== "") && (!_.isEmpty(overAllOfficesData)) ? overAllOfficesData?.map((i, index) => {
            return (
              <>
                <div className="col-md-6 col-lg-4 col-xl-3 col-12 mb-report" key={index}>
                  <div className="reopor-box-progess">
                    <div className="progess-profile d-flex">
                      <div className="userprogress-details">
                        <h5 className="user-tiele">{i?.office_name}</h5>
                        <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <div
                                            style={{
                                                color: "white",
                                                backgroundColor: "black",
                                            }}
                                            
                                        >{i?.office_address}</div>
                                    </Tooltip>
                                }
                            >
                        <p >{i?.office_address}</p>
                        </OverlayTrigger>
                        {/* <p>{i?.office_address}</p> */}
                      </div>
                    </div>
                    <hr />
                    <div className="progress-details">
                      <div className="progess-report">
                        <div className="circlebox">
                          <CircularProgressbar value={i?.percentage} />
                        </div>
                        <div className="progess-text-repfot">
                          {`${i?.percentage}%`}
                          <div className="progespte">Progress</div>
                        </div>
                      </div>
                      <div className="progess-count-show">
                        <div className="learning-points">
                          <h5>Learning Points</h5>
                          <div className="learningc-countshow">
                            {i?.learning_points}
                          </div>
                        </div>
                        <div className="learning-points ">
                          <h5>Learning Hours</h5>
                          <div className="learningc-countshow">
                            {/* {(Math.floor(i?.learning_hrs)) > 0 ? `${Math.floor(i?.learning_hrs)}` : ""}
                            {Math.floor(i?.learning_hrs) > 1 ? "Hrs" : Math.floor(i?.learning_hrs) > 0 ? "Hr" : ""}{" "}
                            {(Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60)) > 0 ?
                              Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60) : ""}
                            {(Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60)) > 1 ? "Mins" : (Math.ceil((i?.learning_hrs - Math.floor(i?.learning_hrs)) * 60)) > 0 ? "Min" : i?.learning_hrs}
                          */}
                            {/* {i?.learning_hrs_val} */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: i?.learning_hrs_val,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
            :
            <div className="col-md-12">
              <div className="not-found mt-3">
                <div className="interboxes">
                  <img src="/images/telescope.svg" alt="Not Found" />
                  <p> {mesg}</p>
                </div>
              </div>
            </div>
          }
          <div className="col-md-12 mt-3">
            <p className="text-center">
              {overAllOfficesData?.length === 0 || (totalOffices?.total_offices === overAllOfficesData?.length) ? "" : (totalOffices?.total_offices < 4) ? "" :
                <button
                  type="button"
                  id="number"
                  className="btn btn-primary-complete"
                  onClick={() => {
                    setCount(counter, setCounter);
                    setLoadSpinner(true);
                  }
                  }
                >
                  {(loadSpinner) ?
                    <div className="d-flex justify-content-center w-100">
                      <Spinner animation="border" />
                    </div>
                    : "Load More"}
                </button>
              }
            </p>
          </div>
        </div>
      </div>
    </section>
  )
};

export default OfficesReportSection;
