const types = {
    ROLEPLAY_MASTER_LIST: "ROLEPLAY_MASTER_LIST",
    ROLEPLAY_MASTER_LIST_RESPONSE: "ROLEPLAY_MASTER_LIST_RESPONSE",
    ROLEPLAY_MASTER_LIST_CLEAR: "ROLEPLAY_MASTER_LIST_CLEAR",

    ROLEPLAY_MASTER_LIST_UNASSIGNED: "ROLEPLAY_MASTER_LIST_UNASSIGNED",
    ROLEPLAY_MASTER_LIST_UNASSIGNED_RESPONSE: "ROLEPLAY_MASTER_LIST_UNASSIGNED_RESPONSE",
    ROLEPLAY_MASTER_LIST_UNASSIGNED_CLEAR: "ROLEPLAY_MASTER_LIST_UNASSIGNED_CLEAR",

    GET_ALL_ROLE_PARTICIPATE_LIST: "GET_ALL_ROLE_PARTICIPATE_LIST",
    GET_ALL_ROLE_PARTICIPATE_LIST_RESPONSE: "GET_ALL_ROLE_PARTICIPATE_LIST_RESPONSE",
    GET_ALL_ROLE_PARTICIPATE_LIST_CLEAR: "GET_ALL_ROLE_PARTICIPATE_LIST_CLEAR",
    
    PARTICIPATE_IN_ROLEPLAY_LIST: "PARTICIPATE_IN_ROLEPLAY_LIST",
    PARTICIPATE_IN_ROLEPLAY_LIST_RESPONSE: "PARTICIPATE_IN_ROLEPLAY_LIST_RESPONSE",
    PARTICIPATE_IN_ROLEPLAY_LIST_CLEAR: "PARTICIPATE_IN_ROLEPLAY_LIST_CLEAR",

    REVIEW_ROLEPLAY_LIST: "REVIEW_ROLEPLAY_LIST",
    REVIEW_ROLEPLAY_LIST_RESPONSE: "REVIEW_ROLEPLAY_LIST_RESPONSE",
    REVIEW_ROLEPLAY_LIST_CLEAR: "REVIEW_ROLEPLAY_LIST_CLEAR",

    REVIEW_FEEDBACK_SUBMIT_LIST: "REVIEW_FEEDBACK_SUBMIT_LIST",
    REVIEW_FEEDBACK_SUBMIT_LIST_RESPONSE: "REVIEW_FEEDBACK_SUBMIT_LIST_RESPONSE",
    REVIEW_FEEDBACK_SUBMIT_LIST_CLEAR: "REVIEW_FEEDBACK_SUBMIT_LIST_CLEAR",

    MY_ROLE_PLAY_LIST: "MY_ROLE_PLAY_LIST",
    MY_ROLE_PLAY_LIST_RESPONSE: "MY_ROLE_PLAY_LIST_RESPONSE",
    MY_ROLE_PLAY_LIST_CLEAR: "MY_ROLE_PLAY_LIST_CLEAR",

    IS_AGREE: "IS_AGREE",
    IS_AGREE_RESPONSE: "IS_AGREE_RESPONSE",
    IS_AGREE_CLEAR: "IS_AGREE_CLEAR",

    PRIVACY_CONTENT_REQ: "PRIVACY_CONTENT_REQ",
    PRIVACY_CONTENT_RESPONSE: "PRIVACY_CONTENT_RESPONSE",
    PRIVACY_CONTENT_CLEAR: "PRIVACY_CONTENT_CLEAR",

    ADD_ARCHIVE_VIDEO_REQ: "ADD_ARCHIVE_VIDEO_REQ",
    ADD_ARCHIVE_VIDEO_RESPONSE: "ADD_ARCHIVE_VIDEO_RESPONSE",
    ADD_ARCHIVE_VIDEO_CLEAR: "ADD_ARCHIVE_VIDEO_CLEAR",

    EDIT_ROLEPLAY_DATA_RESPONSE: "EDIT_ROLEPLAY_DATA_RESPONSE",
    EDIT_ROLEPLAY_DATA_CLEAR: "EDIT_ROLEPLAY_DATA_CLEAR",

    RELATED_VIDEO_LIST: "RELATED_VIDEO_LIST",
    RELATED_VIDEO_LIST_RESPONSE: "RELATED_VIDEO_LIST_RESPONSE",
    RELATED_VIDEO_LIST_CLEAR: "RELATED_VIDEO_LIST_CLEAR",

    REVIEW_FILTER_LIST: "REVIEW_FILTER_LIST",
    REVIEW_FILTER_LIST_RESPONSE: "REVIEW_FILTER_LIST_RESPONSE",
    REVIEW_FILTER_LIST_CLEAR: "REVIEW_FILTER_LIST_CLEAR",

    MY_ROLE_PLAY_FILTER_LIST: "MY_ROLE_PLAY_FILTER_LIST",
    MY_ROLE_PLAY_FILTER_LIST_RESPONSE: "MY_ROLE_PLAY_FILTER_LIST_RESPONSE",
    MY_ROLE_PLAY_FILTER_LIST_CLEAR: "MY_ROLE_PLAY_FILTER_LIST_CLEAR",

    FETCH_ROLE_PLAY_DATA: "FETCH_ROLE_PLAY_DATA",
    FETCH_ROLE_PLAY_DATA_RESPONSE: "FETCH_ROLE_PLAY_DATA_RESPONSE",
    FETCH_ROLE_PLAY_DATA_CLEAR: "FETCH_ROLE_PLAY_DATA_CLEAR",

    GET_VIDEO_DATA_ROLEPLAY: "GET_VIDEO_DATA_ROLEPLAY",
    GET_VIDEO_DATA_ROLEPLAY_RESPONSE: "GET_VIDEO_DATA_ROLEPLAY_RESPONSE",
    GET_VIDEO_DATA_ROLEPLAY_CLEAR: "GET_VIDEO_DATA_ROLEPLAY_CLEAR",

    CHECK_AWS_STATUS_ROLEPLAY: "CHECK_AWS_STATUS_ROLEPLAY",
    CHECK_AWS_STATUS_ROLEPLAY_RESPONSE: "CHECK_AWS_STATUS_ROLEPLAY_RESPONSE",
    CHECK_AWS_STATUS_ROLEPLAY_CLEAR: "CHECK_AWS_STATUS_ROLEPLAY_CLEAR",

    GET_RECORDED_DATA_ROLEPLAY: "GET_RECORDED_DATA_ROLEPLAY",
    GET_RECORDED_DATA_ROLEPLAY_RESPONSE: "GET_RECORDED_DATA_ROLEPLAY_RESPONSE",
    GET_RECORDED_DATA_ROLEPLAY_CLEAR: "GET_RECORDED_DATA_ROLEPLAY_CLEAR",
    
    SET_PARTICIPATE_FORM_VALUE: "SET_PARTICIPATE_FORM_VALUE",
    SET_PARTICIPATE_FORM_VALUE_CLEAR: "SET_PARTICIPATE_FORM_VALUE_CLEAR",

    ASSIGN_ROLEPLAY: "ASSIGN_ROLEPLAY",
    ASSIGN_ROLEPLAY_RESPONSE: "ASSIGN_ROLEPLAY_RESPONSE",
    ASSIGN_ROLEPLAY_CLEAR: "ASSIGN_ROLEPLAY_CLEAR",
}

export default types