import { useEffect } from "react";
import { Modal,Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { homePageMsgClear, homePageShowMsg } from "../../redux/home/actions";

const MsgModal = (props) => {

    const {
        confirmModal,
        setConfirmModal,
        showModalMsg,
        isConfirm,
        confirmId,
        confirmAction
    } = props

    const dispatch = useDispatch();

    return (
        <Modal show={confirmModal} onHide={() => setConfirmModal(false)}
        size="500px"
        aria-labelledby="contained-modal-title-vcenter"
        className="alert-modal" centered>
        {/* <Modal.Header closeButton className="modal-header">
            <Modal.Title>Favourite Delete Alert</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="modal-body text-center">{showModalMsg}</Modal.Body>
        <Modal.Footer className="justify-content-center">
            {isConfirm?<><Button variant="secondary" className="confirm" onClick={() => setConfirmModal(false)}>
                No
            </Button>
            <Button variant="primary" className="confirm" onClick={() => confirmAction(confirmId)}>                                     
            
                Yes
            </Button></>:
            <Button variant="primary" className="msg" onClick={() => {
                dispatch(homePageShowMsg(false))
                dispatch(homePageMsgClear(''))
                setConfirmModal(false);}}>                                     
            
            Ok
        </Button>
        }
        </Modal.Footer>
    </Modal>

    )
}

export default MsgModal;