const types = {
  // login
  LOGIN: "LOGIN",
  LOGIN_RESPONSE: "LOGIN_RESPONSE",
  LOGIN_CLEAR: "LOGIN_CLEAR",

  // login broker
  LOGIN_BROKER: "LOGIN_BROKER",
  LOGIN_BROKER_RESPONSE: "LOGIN_BROKER_RESPONSE",
  LOGIN_BROKER_CLEAR: "LOGIN_BROKER_CLEAR",

  // login for iframe users
  LOGIN_IFRAME_USER_RESPONSE: "LOGIN_IFRAME_USER_RESPONSE",
  LOGIN_IFRAME_USER_CLEAR: "LOGIN_IFRAME_USER_CLEAR",

  // login host-inst
  LOGIN_HOSTINST: "LOGIN_HOSTINST",
  LOGIN_HOSTINST_RESPONSE: "LOGIN_HOSTINST_RESPONSE",
  LOGIN_HOSTINST_CLEAR: "LOGIN_HOSTINST_CLEAR",

  // logout
  LOGOUT: "LOGOUT",
  LOGOUT_RESPONSE: "LOGOUT_RESPONSE",
  LOGOUT_CLEAR: "LOGOUT_CLEAR",

  // logout broker
  LOGOUT_BROKER: "LOGOUT_BROKER",
  LOGOUT_BROKER_RESPONSE: "LOGOUT_BROKER_RESPONSE",
  LOGOUT_BROKER_CLEAR: "LOGOUT_BROKER_CLEAR",

  // logout host-inst
  LOGOUT_HOSTINST: "LOGOUT_HOSTINST",
  LOGOUT_HOSTINST_RESPONSE: "LOGOUT_HOSTINST_RESPONSE",
  LOGOUT_HOSTINST_CLEAR: "LOGOUT_HOSTINST_CLEAR",

  // forgot password
  FORGOT_PWD: "FORGOT_PWD",
  FORGOT_PWD_RESPONSE: "FORGOT_PWD_RESPONSE",
  FORGOT_PWD_RESPONSE_CLEAR: "FORGOT_PWD_RESPONSE_CLEAR",

  // forgot password broker
  FORGOT_PWD_BROKER: "FORGOT_PWD_BROKER",
  FORGOT_PWD_BROKER_RESPONSE: "FORGOT_PWD_BROKER_RESPONSE",
  FORGOT_PWD_BROKER_RESPONSE_CLEAR: "FORGOT_PWD_BROKER_RESPONSE_CLEAR",

  // reset password
  RESET_PWD: "RESET_PWD",
  RESET_PWD_RESPONSE: "RESET_PWD_RESPONSE",
  RESET_PWD_CLEAR: "RESET_PWD_CLEAR",
  
  // reset password broker
  RESET_PWD_BROKER: "RESET_PWD_BROKER",
  RESET_PWD_BROKER_RESPONSE: "RESET_PWD_BROKER_RESPONSE",
  RESET_PWD_BROKER_CLEAR: "RESET_PWD_BROKER_CLEAR",

  //  verify activation link
  VERIFY_ACTIVATION_LINK: "VERIFY_ACTIVATION_LINK",
  VERIFY_ACTIVATION_LINK_RESPONSE: "VERIFY_ACTIVATION_LINK_RESPONSE",
  VERIFY_ACTIVATION_LINK_CLEAR: "VERIFY_ACTIVATION_LINK_CLEAR",

  //  resend activation link
  RESEND_ACTIVATION_LINK: "RESEND_ACTIVATION_LINK",
  RESEND_ACTIVATION_LINK_RESPONSE: "RESEND_ACTIVATION_LINK_RESPONSE",
  RESEND_ACTIVATION_LINK_CLEAR: "VERIFY_ACTIVATION_LINK_CLEAR",

  // Setup password
  SETUP_PASSWORD: "SETUP_PASSWORD",
  SETUP_PASSWORD_RESPONSE: "SETUP_PASSWORD_RESPONSE",
  SETUP_PASSWORD_CLEAR: "SETUP_PASSWORD_CLEAR",

  // Setup password broker admin
  SETUP_PASSWORD_BROKER: "SETUP_PASSWORD_BROKER",
  SETUP_PASSWORD_BROKER_RESPONSE: "SETUP_PASSWORD_BROKER_RESPONSE",
  SETUP_PASSWORD_BROKER_CLEAR: "SETUP_PASSWORD_BROKER_CLEAR",

}

export default types;