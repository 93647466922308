import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import moment from "moment";
import _, { random } from "lodash";

const WebcamCapture = (props) => {
  const {
    roleInfo,
    startCapture,
    setUploadedData,
    setRecordedFile,
    setStartCapture,
    setRecordedChunks,
    recordedChunks,
    stopRecord,
    startRecordAgain,
    setStartRecording,
    setArchiveId,
    archiveId,
    setSocket,
    socket,
    setMp4FileName,
    mp4fileName,
    chunkNumber,
    setChunkNumber
  } = props;

  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  // const socket = io('https://realtorsuccesslearning-qcvideosocket.niit-mts.com/');
  // const socket = io('https://uat-videosocket.niit-mts.com/');

  const [capturing, setCapturing] = useState(false);
  const [videoConstraints] = useState({
    facingMode: { exact: "environment" },
  });

  // const [archiveId,] = useState('')
  
  // const [myBlobBuilder, setMyBlobBuilder] = useState(new MyBlobBuilder())
  const [myBlobBuilder, setMyBlobBuilder] = useState("");

  const [videoChunks, setVideoChunks] = useState([]);

  useEffect(() => {
    stopRecord && stopRecord(handleStopCaptureClick);
  }, [stopRecord]);

  useEffect(() => {
    startRecordAgain && startRecordAgain(handleStartCaptureClick);
  }, [startRecordAgain]);

  useEffect(() => {
    // console.log('RANDOM CHECK SOCKET', socket)
    if (!_.isEmpty(socket)) {
      socket.on("connection", () => {
        // Check on reconnection if data is available to send to websocket

        // console.log('RANDOM Connection to socket', archiveId, recordedChunks)
        if(typeof archiveId != "undefined") {
        //   // Coming in this block means connection was interrupted while video creation
        //   //console.dir("ArchiveId " + archiveId + " Chunk Number " + chunkNumber);
        //   for(var i=1; i<=chunkNumber; i++) {
        //     var sKey = "archive_id_"+archiveId+"_"+i;
        //     if(sessionStorage.getItem(sKey) != null) {
        //       console.dir("Sending chunk number " + i);
        //       console.dir("SEND DATA AGAIN TO SERVER");
        //       var partData = JSON.parse(sessionStorage.getItem(sKey));
        //       //console.dir(videoChunks[i]);
        //       var formData = {}; // Create an arbitrary FormData instancetype:
        //       //console.dir(partData.fileData);
        //       //formData.file = new Blob([partData.fileData], { type: "video/webm;codecs=H264" });
        //       //formData.file = new Blob([partData.fileData], { type: "text/plain" });
        //       formData.file = recordedChunks[i];
        //       formData.archive_id = partData.archive_id;
        //       formData.chunk_number = partData.chunk_number;
        //       formData.file_name = partData.file_name;
        //       formData.metadata = "test metadata";
        //       formData.action = "upload_video_aws";
        //       formData.wasFailed=1;
        //       //console.dir(formData);
        //       socket.emit('savevideopart', formData);
        //     }
        //   }
          console.log(recordedChunks)
          for (let i = 1; i <= chunkNumber; i++) {
            let key = "archive_id_" + archiveId + "_" + i;
            let data = sessionStorage.getItem(key);
            if (data) {
              console.log('RANDOM', JSON.parse(data), recordedChunks[i-1])
              var formData = {}; // Create an arbitrary FormData instance
              formData.file = recordedChunks[i-1];
              formData.archive_id = archiveId;
              formData.videoId = data.videoId;
              formData.chunk_number = data.chunk_number;
              formData.file_name = data.file_name;
              formData.metadata = "test metadata";
              formData.action = "upload_video_aws";
              formData.wasFailed = 1;
              formData.size = data.size;
              // socket.emit("savevideopart", formData);
            }
          }
        }
      });
      socket.on("partsCaptured", (data) => captureParts(data, chunkNumber));
      socket.on("connect_error", function () {
        console.log('RANDOM Failed to connect to server');
      });
      socket.on("welcome", function (response) {
        // console.log('RADMON welcome', response);
      });
      return () => {
        socket.off("partsCaptured", () => {
          // console.log('RANDOM Disconnect to socket partsCaptured')
        });
        socket.off("welcome",() => {})
        socket.off("connect_error",() => {})
      };
    }
  }, [socket, recordedChunks]);

  useEffect(() => {
    const randomString = generateRandomString();
    // console.log('RANDOM', randomString)
    setArchiveId(randomString);
    setMp4FileName("ani_" + randomString + ".mp4");
  }, [dispatch]);

  // Check if parts are captured and remove from session storage
  const captureParts = (data, chunkNumber) => {
    // console.log('RANDOM capture parts', data, chunkNumber)
    sessionStorage.removeItem(
      "archive_id_" + data.archiveId + "_" + data.currentPart
    );
    if (
      !startCapture &&
      data.totalCaptured == sessionStorage.getItem("chunkNumber")
    ) {
      sessionStorage.removeItem('chunkNumber');
      console.log('RANDOM - All capture now we can save the video')
    }
  };

  const handleStartCaptureClick = useCallback(() => {
    // console.log('start recording')
    setStartRecording(true);
    setCapturing(true);
    setRecordedChunks([]);
    setUploadedData("");
    // console.log(mediaRecorderRef)
    if (mediaRecorderRef) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        // mimeType: "video/webm"
        audio: true,
        video: true,
      });
      // console.log('RANDOM', mediaRecorderRef)
      // mediaRecorderRef.current.addEventListener(
      //   "dataavailable",
      //   handleDataAvailable
      // );
      mediaRecorderRef.current.ondataavailable = (e) => {
        // console.log('RANDOM ondataavailable', e)
        if (e.size > 0) {
          postVideoToServerUsingWebSocket(e);
          // console.log('RANDOM handleDataAvailable', e, archiveId)
          setRecordedChunks((prev) => prev.concat(e));
          setChunkNumber(chunkNumber + 1);
        }
      };
      mediaRecorderRef.current.start(500);
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setChunkNumber(chunkNumber + 1);
        postVideoToServerUsingWebSocket(data);
        // console.log('RANDOM handleDataAvailable', data, archiveId, chunkNumber)
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks, archiveId, setChunkNumber, chunkNumber]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setStartRecording(false);
    setStartCapture(false);
    // let jsonData = {
    //   archiveId: archiveId,
    //   chunkNumber: sessionStorage.getItem('chunkNumber'),
    //   country: "CNTRY006",
    //   mapId: "633e81a17ccd392b6a8b457a",
    //   rp_keywords: [{kw: "insurance", wt: "10"}, {kw: "retirement", wt: "5"}],
    //   takeIndex: 2,
    //   username: "coagyuat0011",
    //   videoId: "635148532c38d1702c619934",
    // }
    // console.log('newvideoprocessing', jsonData)
    // socket.emit('newvideoprocessing', jsonData);
    if (webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => track.stop());
    }
  }, [mediaRecorderRef, webcamRef, setCapturing, archiveId, setStartCapture]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "xsel-video.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      // setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const generateRandomString = () => {
    if (window.crypto) {
      var a = window.crypto.getRandomValues(new Uint32Array(3)),
        token = "";
      for (var i = 0, l = a.length; i < l; i++) token += a[i].toString(36);
      return token;
    } else {
      // console.log((Math.random() * new Date().getTime()).toString(36).replace(/\./g, ''))
      return (Math.random() * new Date().getTime())
        .toString(36)
        .replace(/\./g, "");
    }
  };

  // Post Video chunk wise using websocket.
  const postVideoToServerUsingWebSocket = (videoblob, chunkNumber) => {
    // console.log('RANDOM archieve', videoblob, chunkNumber, recordedChunks)
    //console.log('VIDEO ID', roleInfo)
    var formData = {}; // Create an arbitrary FormData instance
    formData.file = videoblob;
    formData.archive_id = archiveId;
    formData.videoId = roleInfo.id;
    formData.chunk_number = chunkNumber;
    formData.file_name = mp4fileName;
    formData.metadata = "test metadata";
    formData.action = "upload_video_aws";
    formData.wasFailed = 0;
    formData.size = videoblob.size;
    // myBlobBuilder.append(videoblob);

    let videoChunk = _.clone(videoChunks);
    let videoBlob = {}
    videoBlob[chunkNumber] = videoblob;
    videoChunk.push(videoBlob)
    // console.log('RANDOM', videoChunk, videoChunks)
    setVideoChunks(videoChunk);
    //console.dir(videoChunks);
    socket.emit("savevideopart", formData, (response) => {
      console.log('RANDOM RESPONSE', response)
    });
    formData.blob = JSON.stringify(videoblob)
    sessionStorage.setItem("chunkNumber", chunkNumber);
    sessionStorage.setItem(
      "archive_id_" + archiveId + "_" + chunkNumber,
      JSON.stringify(formData)
    );
    // console.log('RANDOM savevideopart', formData);
    
  };

  const onUserMedia = (e, chunkNo, setChunkNo) => {
    // console.log('User Media', e)
    setStartRecording(true);
    setCapturing(true);
    setRecordedChunks([]);
    setUploadedData("");
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      // mimeType: "video/webm"
      audio: true,
      video: true,
    });
    mediaRecorderRef.current.ondataavailable = (e) => {
      // console.log('RANDOM ondataavailable', e, chunkNo)
      setChunkNo(chunkNo++);
      postVideoToServerUsingWebSocket(e.data, chunkNo);
      if (e.data.size > 0) {
        setRecordedChunks((prev) => prev.concat(e.data));
      }
    };
    // checkData
    mediaRecorderRef.current.start(300);
  };

  const onUserMediaError = (e) => {
    // console.log('User Media Error', e)
    setStartRecording(false);
  };

  return (
    <>
      <Webcam
        audio={true}
        muted={true}
        ref={webcamRef}
        width={"100%"}
        height={250}
        onUserMedia={(e) => onUserMedia(e, chunkNumber, setChunkNumber)}
        onUserMediaError={onUserMediaError}
        // videoConstraints={videoConstraints}
      />
    </>
  );
};

export default WebcamCapture;
