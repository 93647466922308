import _ from "lodash";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getAnyTotalReportClear, getProfileCompletionReportClear } from "../../../redux/reports/actions";
import NextArrow from "../../LearningPaths/NextArrow";
import PrevArrow from "../../LearningPaths/PrevArrow";
import Pagination from "../../../pages/Orders/Pagination";
import exportFromJSON from 'export-from-json'
import StaticUtils from "../../../utils/StaticUtils";
import * as XLSX from 'xlsx';
import ReportGraphSection from "../ReportGraphSection";

const fileName = 'ProfileCompletionReport'
const exportType = 'xls'
let PageSize = 20;
const ProfileTableSeaction = (props) => {

  const { 
    setSearchInput, 
    getFilteredData, 
    setIsLoading,
    isLoading,
    isView,
    statusFilterData,
    setCanShow,
    type
  } = props;

  const dispatch = useDispatch();
  const profileReportRes = useSelector(state => state.report.profileReport);
  const anyTotalReportRes = useSelector(state => state.report.anyTotalReport);
  const [profileReports, setProfileReports] = useState(null);
  const [totalRecord, setTotalRecord] = useState(0);
  const [legends, setLegends] = useState([]);
  const [series, setSeries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    if (profileReports !== null) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCanShow(true)
      return profileReports.slice(firstPageIndex, lastPageIndex);
    }

  }, [currentPage, profileReports]);

  useEffect(() => {
    if (isLoading) {
      window.scroll({
        top: 50, 
        left: 0, 
        behavior: 'smooth'
      });
      setSeries([])
      setLegends([])
    }
  }, [isLoading])
  
  useEffect(() => {
    // console.log('profileReportRes: ', profileReportRes)
    if (!_.isEmpty(profileReportRes)) {
      setIsLoading(false);
      if (profileReportRes?.status === "success") {
        
        const reportData = profileReportRes?.payload?.payload
        setSeries([])
        setLegends([])
        setProfileReports(reportData)
        if (statusFilterData.includes('Completed')) {
          series.push(reportData.filter(data => data.profile_status === 'Completed').length)
          legends.push('Completed')
        }
        if (statusFilterData.includes('Inprogress')) {
          series.push((reportData.filter(data => data.profile_status === 'Inprogress')).length)
          legends.push('Not Completed')
        }

        if (legends.length < 4) {
          legends.push('Others')
          // console.log('TOTALRECORD', totalRecord, series)
          series.push(totalRecord - _.sum(series))
        }
        // console.log('LEGENDS', legends)
        // console.log('SERIES', series)
        setLegends(legends)
        setSeries(series)
        setCurrentPage(1)
      }else{
        setProfileReports([])
        setCanShow(false)
      }
      dispatch(getProfileCompletionReportClear())
    } 

  }, [profileReportRes])

  useEffect(() => {
    if (!_.isEmpty(anyTotalReportRes)) {
      if (anyTotalReportRes.status === 'success') {
        setTotalRecord((anyTotalReportRes.payload.payload).length)
      }
      return (() => {

        dispatch(getAnyTotalReportClear())
      })
    }
  }, [anyTotalReportRes])

  const ExportToExcel = () => {
    const data = _.cloneDeep(profileReports);
  
    for(let item of data){
      item.profile_status = item?.profile_status === "Inprogress" ? "Not Completed" : "Completed";
      item.profile_progress = item?.profile_progress !== undefined && item?.profile_progress !== '' ? item?.profile_progress + '%' : "-";
      item.complete_date = item?.complete_date !== undefined && item?.complete_date !== '' ? StaticUtils.getDateFromTimeStamp(item?.complete_date) : "-";
    }

    const newThing = [];
    data.map(item => {
      newThing.push(
        _.mapKeys(item, (value, key) => {
          switch (key) {
            case "first_name":
              return "First Name";
            case "last_name":
              return "Last Name";
            case "email":
              return "Email";
            case "office_name":
              return "Office Name";
            case "profile_status":
              return "Status";
            case "profile_progress":
              return "% Completed"
            case "complete_date":
              return "Date of Completion"
            default:
              return;
          }
        })
      )
    });

    for (let i = 0; i < newThing.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete newThing[i].undefined;
    }

    
    for (let i = 0; i < data.length; i++) {
      // arrayObj[i].stroke = arrayObj[i]['key1'];
      delete data[i]._id;
    }
    // console.log('data:', data)
    // exportFromJSON({ data, fileName, exportType })

    const worksheet = XLSX.utils.json_to_sheet(newThing);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName+".xlsx");
  }

  const renderTableReport = () => {
    return (
      <div className="row list-coach report-table-rsp">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive1">

            <div className="tablebox">
              <table className="table mb-0 office_list table-striped">
                <thead>
                  <tr>
                    <th className="office-province">
                      Name
                    </th>
                    <th className="office-province">
                      Email
                    </th>
                    <th className="office-province">
                      Office Name
                    </th>
                    <th className="office-province">
                      Status
                    </th>
                    <th className="office-province text-center">
                      % Completed
                    </th>
                    <th className="office-province">
                      Date of Completion
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {currentTableData?.map((item, index) => {
                    return <tr className="addBGcustomw">
                      <td>
                        {item?.first_name + ' ' + item?.last_name}
                      </td>
                      <td>
                        {item?.email}
                      </td>
                      <td>
                        {item?.office_name}
                      </td>
                      <td>
                        <span className="complte"><img src={item?.profile_status === "Inprogress" ? "/images/inprogress.svg" : "/images/check-report.svg"} width="18" height="18" /></span><strong>{item?.profile_status === "Inprogress" ? "Not Completed" : "Completed"}</strong>
                      </td>
                      <td className="text-center"><strong>{item?.profile_progress !== undefined && item?.profile_progress !== '' ? item?.profile_progress + '%' : "-"}</strong></td>
                      <td className="text-center"><strong>{item?.complete_date !== undefined && item?.complete_date !== '' ? StaticUtils.getDateFromTimeStamp(item?.complete_date) : "-"}</strong></td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </div>


            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={profileReports.length}
              pageSize={PageSize}
              onPageChange={(page) =>{window.scroll({
                top: 100, 
                left: 0, 
                behavior: 'smooth'
              }); setCurrentPage(page)}}
            />
          </div>

        </div>
      </div> 
    )
  }

  const renderGraphReport = () => {
    return (
      <ReportGraphSection 
        series={series}
        legends={legends}
        isLoading={isLoading}
        type={type}
        isAmount={false}
      />
    )
  }

  return (
    <>
      <section className="table-report-layout">
        {true ? <div className="container">
          <div className="row">


            <div className="col-md-5 search__input">
              {/* <div className="input-lear position-relative d-flex">
                <label for="password" className="input__label_learning">Search</label>
                <input type="text" id="password" name="password" className="input_searchfield_search-cart " placeholder=" "
                  onChange={(evt) => { setSearchInput(evt.target.value) }} />
                <a>
                  <button className="button-reset" onClick={() => {
                    getFilteredData(1)
                  }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      id="Find"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.5"
                          x2="1"
                          y2="0.5"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#00b7f1" />
                          <stop offset="1" stop-color="#ed156e" />
                        </linearGradient>
                      </defs>
                      <circle
                        id="Ellipse_191"
                        data-name="Ellipse 191"
                        cx="15"
                        cy="15"
                        r="15"
                        fill="url(#linear-gradient)"
                      />
                      <circle
                        id="Ellipse_192"
                        data-name="Ellipse 192"
                        cx="13"
                        cy="13"
                        r="13"
                        transform="translate(2 2)"
                        fill="#fff"
                      />
                      <path
                        id="Icon_awesome-search"
                        data-name="Icon awesome-search"
                        d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                        transform="translate(7.715 7.714)"
                        fill="var(--color-element-1)"
                      />
                    </svg>
                  </button>
                </a>
              </div> */}
            </div>
            <div className="col-7">
              {currentTableData && currentTableData?.length > 0 && isView === 'table' ?
                <button className="button-reset downbtnsreport" onClick={() => {
                  if (window.callbackHandler != undefined) {
                    window.callbackHandler.postMessage("downbtnsreport");
                  } else if (window.webkit != undefined) {
                    if (window.webkit.messageHandlers.callbackHandler != undefined) {
                      window.webkit.messageHandlers.callbackHandler.postMessage("downbtnsreport");
                    }
                  }

                  ExportToExcel() }}>
                   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  id="Find"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <defs>
                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#00b7f1" />
                      <stop offset="1" stop-color="#ed156e" />
                    </linearGradient>
                  </defs>
                  <circle id="Ellipse_57" data-name="Ellipse 57" cx="15" cy="15" r="15" fill="url(#linear-gradient)" />
                  <circle id="Ellipse_179" data-name="Ellipse 179" cx="13" cy="13" r="13" transform="translate(2 2)" fill="#fff" />
                  <path id="file-chart" d="M10.365,6.951h3.89l-3.89-3.89v3.89M5.415,2h5.658l4.244,4.244v8.487A1.415,1.415,0,0,1,13.9,16.146H5.415A1.414,1.414,0,0,1,4,14.731V3.415A1.41,1.41,0,0,1,5.415,2m.707,12.731H7.536V10.487H6.122v4.244m2.829,0h1.415V9.073H8.951v5.658m2.829,0h1.415V11.9H11.78Z" transform="translate(5.342 5.927)" fill="var(--color-element-1)"/>
                </svg>
                </button> : ''}
            </div>
          </div>
          {currentTableData && currentTableData?.length > 0 ? 
          isView === 'table' ? renderTableReport() : renderGraphReport()
          : profileReports !== null ? <div className="not-found mt-3">
            <div className=" interboxes">
              <img src="/images/telescope.svg" alt="Not Found" />
              <p>Sorry! No result found for your search.</p>
            </div>
          </div> : ""}
        </div> : ""}
      </section>
    </>
  )
}

export default ProfileTableSeaction