import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FeedbackSectionTwo from "./Common/section2";
import { postAPIWithoutModule } from "../../api/generic";
import { SessionKeys } from "../../utils/AppUtils";
import CongratulationsPopUp from "../Course/CongratulationsPopUp";
import SpinnerLoader from "../Spinner";

import { useHistory } from "react-router-dom";
import { clickStreamUser } from "../../utils/AppUtils";
import { useSelector } from "react-redux";
import CONFIG from "../../utils/config";

const SingleCompletedPopup = ({
  show,
  setShow,
  name: courseName,
  userName,
  setShowReminderPopupStep,
  showCongratsPopUp,
  setShowCongratsPopUp,
  questions,
  type,
  courseId,
  sub_category_id,
  navigateToModuleUrl,
  courseCode,
  lastSelectModule,
  lastModule,
}) => {
  const [starSelected, setStarSelected] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [errors, setErrors] = useState({ userComment: false });
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [questionId, setQuestionId] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const authBroker = useSelector((state) => state.auth.authBroker);

  function handleSubmitPopup() {
    if (selectedRatings.length == 0)
      return setErrors({ error: "Ratings are required.", userComment: true });
    if (!userComment)
      return setErrors({ userComment: true, error: "Comments are required." });
    submitFeedbackMain();
  }

  async function submitFeedbackMain() {
    const batch_id = localStorage.getItem(SessionKeys.BATCH_ID);
    let sumofRatings = 0;
    if (selectedRatings?.length) {
      selectedRatings.map((item) => {
        sumofRatings += item?.rating;
      });
    }
    let averageRating = sumofRatings / selectedRatings?.length;
    let requestBody = {};
    {
      if (batch_id?.length && batch_id != null) {
        requestBody = {
          remarks: userComment,
          category_id: courseId,
          rating: averageRating.toFixed(0),
          questions: selectedRatings,
          type: type || "Course",
          sub_category_id: sub_category_id || "",
          batch_id: batch_id || "",
        };
        // clickStreamUser("feedback_btn_module", {
        //   remarks: userComment,
        //   category_id: courseId,
        //   rating: averageRating.toFixed(0),
        //   questions: selectedRatings,
        //   type: type || "Course",
        //   sub_category_id: sub_category_id || "",
        //   batch_id: batch_id || "",
        // });
      } else {
        requestBody = {
          remarks: userComment,
          category_id: courseId,
          rating: averageRating.toFixed(0),
          questions: selectedRatings,
          type: type || "Course",
          sub_category_id: sub_category_id || "",
        };
        // clickStreamUser("feedback_btn_module", {
        //   remarks: userComment,
        //   category_id: courseId,
        //   rating: averageRating.toFixed(0),
        //   questions: selectedRatings,
        //   type: type || "Course",
        //   sub_category_id: sub_category_id || "",
        // });
      }
      
    }

    setLoading(true);
    const res = await postAPIWithoutModule(
      {
        postData: requestBody,
        module: "postFeedbackQuestions",
        token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      },
      true
    );

    if (res.status === "success") {
      setLoading(false);
      setShow(false);
      clearRating();
      setShowReminderPopupStep();
    } else {
      setLoading(false);
    }
    if (lastModule?.length === lastSelectModule + 1) {
      if (navigateToModuleUrl) {
        console.log(courseId, "source12344");
        history.replace({
          pathname: "/moduleDetail",
          state: { courseId: courseCode, source: "broker", cat_id: courseId },
        });
      }
    }
  }
  const clearRating = () => {
    setErrors({ userComment: false });
    setSelectedRatings([]);
    setUserComment("");
  };

  useEffect(() => {
    userComment &&
      errors?.userComment &&
      setErrors({ ...errors, userComment: false });
  }, [userComment]);

  return (
    <div>
      <Modal
        className={"submit-feedback--popup past-module--complete--popup"}
        show={show}
        onHide={setShow}
        size="lg"
        dialogClassName="modalassign"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          className={"header submit--feedback" }
        >
          <Modal.Title id="example-custom-modal-styling-title">
            Submit your feedback
          </Modal.Title>
          <img
          onClick={() => setShow(false)}
          src="/images/closeFeedback.svg"
          alt="Close"
          style={{cursor:"pointer"}}
        />
        </Modal.Header>
        <Modal.Body className="modal-body ">
          <h3 className="single--header">
            Hi {userName}, Did the "{courseName}" meet your expectations in
            terms of quality learning? Please Rate!
          </h3>
          <div className="body">
            <div className="feedback-popup--s-2">
              <FeedbackSectionTwo
                setStarSelected={setStarSelected}
                starSelected={starSelected}
                userComment={userComment}
                setUserComment={setUserComment}
                errors={errors}
                questions={questions}
                questionId={questionId}
                setQuestionId={setQuestionId}
                selectedRatings={selectedRatings}
                setSelectedRatings={setSelectedRatings}
                setErrors={setErrors}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer ">
            <Button
              onClick={() => {
                setShow(false);
                clearRating();
                {
                  lastModule.length === lastSelectModule + 1 &&
                    navigateToModuleUrl &&
                    history.replace({
                      pathname: "/moduleDetail",
                      state: {
                        courseId: courseCode,
                        source: "broker",
                        cat_id: courseId,
                      },
                    });
                }

                clickStreamUser("feedback_skip", {
                  userId: authBroker?.payload?.user.user_id,
                });
              }}
              variant="secondary"
              id="save-btn"
              className="skipBtn btn btn-primary"
            >
              Skip
            </Button>

            <Button
              variant="primary"
              id="approve"
              className="confirm feedbackBtn btn btn-primary"
              onClick={handleSubmitPopup}
            >
              {loading ? <SpinnerLoader /> : " Send Feedback"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SingleCompletedPopup;
