import RolePlayHeadingSection from "./RolePlayHeadingSection";
import RolePlayParticipateSection from "./RolePlayParticipateSection";
import RolePlayVideoRecord from "./RolePlayVideoRecord";
import RolePlayAssetLibrarySection from "./RolePlayAssetLibrarySection";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { clickStreamUser } from "../../utils/AppUtils";
import { editRolePlayDataClear, fetchRolePlayMasterList, fetchRolePlayMasterListClear, fetchRolePlayMasterListUnAssigned, fetchRolePlayMasterListUnAssignedClear } from "../../redux/roleplay/actions";
import moment from "moment";
import CONFIG from "../../utils/config";
import FineUploaderVideoComponent from "./FineUploaderVideoComponent";
import RolePlayTabSection from "./RolePlayTabSection";

const RolePlayPageSection = (props) => {

    const dispatch = useDispatch();
    const authBroker = useSelector(state => state.auth.authBroker)
    const editRolePlayDataRes = useSelector(state => state.rolePlay.editRolePlayData)
    const masterList = useSelector(state => state.rolePlay.masterList)
    const masterListUnassigned = useSelector(state => state.rolePlay.masterListUnassigned)
    const [clickedRolePlay, setClickedRolePlayData] = useState();
    const [showModalMsg, setShowModalMsg] = useState({error: false, message: ""})
    const [confirmModal, setConfirmModal] = useState(false)
    const [roleplayTab, setRolePlayTab] = useState('assigned')
    const [loadingMasterList, setLoadingMasterList] = useState(true)
    const [loadingUnMasterList, setLoadingUnMasterList] = useState(true)

    const [participateRoleList, setParticipateRoleList] = useState(
        [
            // {
            //     id: 1,
            //     role_play_id: "6350f0847eaf5bde3a7e7693",
            //     title: "Overcoming Objections",
            //     situation: "Broker, Agent",
            //     description: "Nulla ultricies id elit ac molestie. In felis lorem, sagittis ac nibh varius, efficitur suscipit urna. Praesent vel est justo. Suspendisse congue dapibus tempor. Nullam mattis mi non eros venenatis tincidunt. Pellentesque rutrum orci vel eros pellentesque, id porta purus eleifend. Suspendisse vitae neque varius, fringilla tellus viverra, convallis sapien.",
            //     releaseDate: "05/09/2022", //DD/MM/YYYY
            //     duration: "5",
            //     durationUnit: "Mins",
            //     type: "video", // PDF / Video
            //     url: "https://cdn-stg.niit-mts.com/rsp/media/leadersedgetraining_advertising_and_privacy.m3u8",
            //     thumbnail: "https://rsplearning-dev.niit-mts.com/meeting/images/579230283869587.jpg",
            //     provider_id: {},
            //     roleplay_keywords: [{"kw":"broker","wt":"10"},{"kw":"agent","wt":"5"}]
            // },
        ]
    )
    const [unassignedRoleList, setUnAssignedRoleList] = useState([])

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            clickStreamUser('participate-roleplay-page', {
                user_id: authBroker.payload.user.user_id
            })
            fetchMasterList();
            fetchUnAssignedMasterList();
        }
    }, [dispatch, authBroker])

    useEffect(() => {
        if (confirmModal) {
            setTimeout(() => {
                setConfirmModal(false)
                setShowModalMsg('')
            }, 5000)
        }
    }, [confirmModal])

    useEffect(() => {
        if (!_.isEmpty(editRolePlayDataRes)) {
            // console.log('EDIT roleplay', editRolePlayDataRes)
            setClickedRolePlayData(editRolePlayDataRes)
            dispatch(editRolePlayDataClear())
        }
    }, [editRolePlayDataRes])

    useEffect(() => {
        console.log('masterListUnassigned ', masterListUnassigned)
        if (!_.isEmpty(masterListUnassigned)) {
            if (masterListUnassigned.status === 'success') {
                const data = masterListUnassigned.payload.payload;
                if (_.isArray(data) && !_.isEmpty(data)) {
                    setUnAssignedRoleList(data.map(d => {
                        return {
                            id: d._id.$oid,
                            role_play_id: d._id.$oid,
                            title: d.title,
                            situation: d.s_title,
                            roleplay_category: d.cat_name,
                            description: d.instruction,
                            releaseDate: moment.unix(d.created_on).format('DD/MM/YYYY'),
                            duration: 0,
                            durationUnit: '',
                            type: 'video',
                            url: d.roleplay_url,
                            thumbnail: d.cover_img_url,
                            provider_id: "",
                            roleplay_keywords: d.key_w,
                            roleplay_scenario: d.roleplay_pdf_url,
                            roleplay_learningpoint: d.learning_points,
                            keywords: d.key_w
                        }
                    }))
                } else {
                    setLoadingUnMasterList(false)
                    setUnAssignedRoleList([])
                }
            } else {
                setLoadingUnMasterList(false)
            }
            props.setIsLoading(false)
            dispatch(fetchRolePlayMasterListUnAssignedClear());
        }
    }, [masterListUnassigned])

    useEffect(() => {
        console.log('Master List', masterList)
        if (!_.isEmpty(masterList)) {
            if (masterList.status === 'success') {
                const data = masterList.payload.payload;
                if (_.isArray(data) && !_.isEmpty(data)) {
                    setParticipateRoleList(data.map(d => {
                        return {
                            id: d._id.$oid,
                            role_play_id: d._id.$oid,
                            title: d.title,
                            situation: d.s_title,
                            roleplay_category: d.cat_name,
                            description: d.instruction,
                            releaseDate: moment.unix(d.created_on).format('DD/MM/YYYY'),
                            duration: 0,
                            durationUnit: '',
                            type: 'video',
                            url: d.roleplay_url,
                            thumbnail: d.cover_img_url,
                            provider_id: "",
                            roleplay_keywords: d.key_w,
                            roleplay_scenario: d.roleplay_pdf_url,
                            roleplay_learningpoint: d.learning_points,
                            keywords: d.key_w
                        }
                    }))
                } else {
                    setLoadingMasterList(false)
                    setParticipateRoleList([])
                }
            } else {
                setLoadingMasterList(false)
            }
            props.setIsLoading(false)
            dispatch(fetchRolePlayMasterListClear());
        }
    }, [masterList])

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            if (roleplayTab === 'assigned') {
                setLoadingMasterList(true)
                fetchMasterList()
                setClickedRolePlayData({})
            } else {
                setLoadingUnMasterList(true)
                fetchUnAssignedMasterList()
                setClickedRolePlayData({})
            }
        }
    }, [roleplayTab, authBroker])

    const fetchMasterList = () => {
        dispatch(fetchRolePlayMasterList({
            module: 'getRolePlayMasterList',
            postData: {
                email: authBroker?.payload?.user.email,
                broker_user_id: authBroker?.payload?.user.user_id,
                company_id: authBroker?.payload?.user.company_id,
                assigned: 1
            }
        }))
    }

    const fetchUnAssignedMasterList = () => {
        dispatch(fetchRolePlayMasterListUnAssigned({
            module: 'getRolePlayMasterList',
            postData: {
                email: authBroker?.payload?.user.email,
                broker_user_id: authBroker?.payload?.user.user_id,
                company_id: authBroker?.payload?.user.company_id,
                assigned: 0
            }
        }))
    }

    const renderAlertCard = () => {
        return (
            <div className={`alert-card d-flex justify-content-center ${confirmModal ? '' : 'd-none'}`}>
                <div className="shop-cart">
                    <div className="shop-info justify-content-center">
                        {!showModalMsg.error ? 
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_5699" data-name="Group 5699" transform="translate(0.498 0.498)">
                                <circle id="Ellipse_222" data-name="Ellipse 222" cx="9" cy="9" r="9" transform="translate(-0.498 -0.498)" fill="#A9C23C" />
                                <path id="Icon_material-done" data-name="Icon material-done" d="M9.712,16.558,7.79,14.88l-.9.9L9.712,18.35l6.425-6.657-.9-.9Z" transform="translate(-3.013 -6.071)" fill="#fff" stroke="#fff" strokeWidth="2" />
                            </g>
                        </svg> : ""}
                        <p className="m-0">{showModalMsg.message}</p>
                    </div>
                    <div className="view-cart-icon">
                        <i className="fa fa-times cursor-pointer" aria-hidden="true"
                            onClick={() => {
                                setConfirmModal(false)
                                setShowModalMsg({error: false, message: ""})
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="position-relative">
            {/* Show Header Tab Section */}
            <RolePlayHeadingSection 
                page='participate'
                isMobile={props.isMobile}
            />
            {/* <RolePlayTabSection 
                page='participate'
                isMobile={props.isMobile}
            /> */}
            {/* Show RolePlay master list section */}
            <RolePlayParticipateSection 
                title={'Select a role play to participate.'}
                page='participate'
                listing={participateRoleList}
                sendData={setClickedRolePlayData}
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
                roleData={clickedRolePlay}
                unassignedRoleList={unassignedRoleList}
                setRolePlayTab={setRolePlayTab}
                loadingMasterList={loadingMasterList}
                setLoadingMasterList={setLoadingMasterList}
                loadingUnMasterList={loadingUnMasterList}
                setLoadingUnMasterList={setLoadingUnMasterList}
            />
            {/* Show Video Record Section */}
            <RolePlayVideoRecord 
                page="participate"
                roleData={clickedRolePlay}
                listing={participateRoleList}
                setIsLoading={props.setIsLoading}
                isLoading={props.isLoading}
                setConfirmModal={setConfirmModal}
                setShowModalMsg={setShowModalMsg}
                setLoaderMsg={props.setLoaderMsg}
                rolePlayTabName={roleplayTab}
            />
            {/* <RolePlayAssetLibrarySection 
                roleData={clickedRolePlay}
            /> */}
            <div className="position-relative p-2 alert-btm">
                {renderAlertCard()}
            </div>
        </div>
    )
}

export default RolePlayPageSection;