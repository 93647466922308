import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Page from "../../components/Page";
import { creditPoints, creditPointsClear } from '../../redux/Cart/actions';
import { clickStreamUser, SessionKeys } from '../../utils/AppUtils';
import _ from "lodash"
import Loader from '../Loader/Loader';
import { fetchTimezoneAbbr, fetchTimezoneAbbrClear } from '../../redux/user/actions';
import moment from 'moment'

const CreditPoints = () => {
    const dispatch = useDispatch();
    const creditPointsRes = useSelector(state => state.carts.creditPoints);
    const authBroker = useSelector(state => state.auth.authBroker);
    const userDetailRes = useSelector(state => state.user.userBroker)
    const timezoneAbbr = useSelector(state => state.user.timezoneAbbr)

    const [pointsData, setPointsData] = useState([]);
    const [totalPoint, setTotalPoint] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({})
    const [zoneAbbr, setZoneAbbr] = useState('CDT')
    const [timezoneOffset, setTimezoneOffset] = useState('-0500')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(() => {
        if (authBroker?.payload?.user.email) {
            dispatch(creditPoints({
                module: "creditPoints",
                email: authBroker.payload.user.email     // localStorage.getItem(SessionKeys.EMAIL_SESSION)
            }))
            clickStreamUser('Credit Points Page', {
                user_id: authBroker.payload.user.user_id,
            });
        }

    }, [dispatch, authBroker])

    useEffect(() => {
        // console.log('userDetailRes: ', userDetailRes)
        if (!_.isEmpty(userDetailRes?.payload?.payload)) {
            setUserDetails(userDetailRes?.payload?.payload)
            const timezoneOffset = !_.isEmpty(userDetails.timezone) ? userDetails.timezone.replace(':', '') : '-0500'
            setTimezoneOffset(timezoneOffset)
            dispatch(fetchTimezoneAbbr({
                module: 'fetchTimezoneAbbr',
                postData: {
                    offset: userDetailRes.payload.payload.timezone
                }
            }))
        }
    }, [userDetailRes])

    useEffect(() => {
        if (!_.isEmpty(timezoneAbbr)) {
            if (timezoneAbbr.status === 'success') {
                // console.log('timezoneAbbr', timezoneAbbr)
                const timezoneAbbrData = timezoneAbbr.payload?.payload
                if (!_.isEmpty(timezoneAbbrData)) {
                    const abbr = timezoneAbbrData?.abbr[0]?.abbreviations
                    setZoneAbbr(abbr)
                }
            }
            dispatch(fetchTimezoneAbbrClear())
        }
    }, [timezoneAbbr])

    useEffect(() => {

        if (!_.isEmpty(creditPointsRes)) {
            setIsLoading(false);
            dispatch(creditPointsClear());
            if (creditPointsRes?.status === "success") {
                setPointsData(creditPointsRes?.payload.payload)
                // console.log('creditPoints1: ', creditPointsRes?.payload.payload)
                let pointData = creditPointsRes?.payload.payload
                if (pointData?.length > 0) {
                    let point = 0;
                    pointData.map((item, index) => {
                        if (item.via === 'credit') {
                            point += _.toNumber(item.points);
                        } else {
                            point -= _.toNumber(item.points);
                        }
                    })
                    // console.log('POINT', point)
                    setTotalPoint(point)

                }
            }
        }

    }, [creditPointsRes])



    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <div className="my-credit-points">
                    <section className="pointer-events-table">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="responsive-carousel-points">
                                        <div className="pointsbox">
                                            <div className="pointshead d-flex">
                                                <div className="invoice_main_moda-point ">
                                                    <span>Reward Points Balance</span> </div>
                                                <div className="points-count">
                                                    <span className="bs">{!isLoading && totalPoint}</span> <span className="pnts">points</span>
                                                </div>
                                            </div>
                                            {_.isArray(pointsData) && pointsData.length > 0 ?
                                                <div className="points-table">

                                                    {pointsData?.map((point, index) => {
                                                        return <div className="points-box" key={index}>
                                                            <div className="row align-items-center table-poits-row">
                                                                <div className="col-8 leftpotsdat">
                                                                    <p className="orderid">ORDER ID #{point?.order_id}</p>
                                                                    <p className="coursename">{point?.title}</p>
                                                                    <p className="datetime">{moment.utc(point?.date, 'DD MMMM YYYY, hh:mm A').utcOffset(timezoneOffset).format('DD MMMM YYYY, hh:mm A')} {zoneAbbr}</p>
                                                                </div>
                                                                <div className="col-4 rightpoins">
                                                                    <div className={`userpoints ${point?.via === 'credit' ? 'pluspoints' : 'minuspoints'}`}>   {/* minuspoints */}
                                                                        {point?.via === 'credit' ? '+' : '-'} {point?.points} <small>points</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                    }

                                                </div> :
                                                !isLoading &&
                                                <div className="points-table mt-4 pb-4 text-center padlist">
                                                    You can earn Reward Points with your purchases from our catalog of Courses, Videos & Event Sessions.
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5 pointsdetails">
                                    <p className="pinds">1 Reward point = $0.10 CAD</p>
                                    <p className="pindss">Each Reward Point is equivalent to 10 cents or $0.10 CAD</p>
                                    <a href="https://www.xsel.app/terms-of-service/" target="_blank" className="readmored">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Page>
        </>
    )
}

export default CreditPoints;