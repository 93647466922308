import { useHistory } from "react-router-dom";
import { clickStreamUser } from "../../utils/AppUtils";
import ImageComponent from "../Layouts/ImageComponent";

const CertifySearchSection = (props) => {
    const { eventList } = props;
    const history = useHistory();
    const goToEventDetail = (item) => {
        clickStreamUser('go-to-event-detail', {
            eventId: item.product_id,
            eventCode: item.event_code,
            source: item.source,
            // userId: authBroker?.payload.user.user_id
        })
        history.push(`/certify-detail?certify_id=${item?.wp_id}&certify_code=${item?.event_code}&source=${item.source}`)
    }

    return (
        <>
            {eventList.length > 0 ?
                <div className="row" >
                    <div className="col-md-12" >
                        <div className="row orders">

                            {eventList?.map((m, index) => {
                                return <div className="col-md-6 col-lg-4 col-xl-3 col-12 search__input">
                                    <div className="img-box_RP">
                                        <div className="img-videosec learn_image-card">
                                            {/* <img className="img-fluid " src={'/images/default-demo-event-img.jpg'} style={{ borderBottom: "4px solid #E5E5E5" }} /> */}
                                            <ImageComponent 
                                                name={m.title}
                                                thumbnail={""}
                                                cssClass={"img-fluid"}
                                                errorClass={"img-fluid"}
                                            />

                                        </div>
                                        <div className="details-card_RP">
                                            <h5><a className="course-head-al_search cursor-pointer" onClick={()=>{goToEventDetail(m)}}>
                                                {m.title}</a></h5>
                                            {/*<div className="row info-bottm-card_nw">
                                            <div className="col-md-12 ">
                                                <p>{m.short_description} </p>
                                            </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                    </div>

                </div>
                :
                <p className="my-cart-texts m-0">No videos are available.</p>
            }
        </>
    )

}
export default CertifySearchSection;