import types from "./types";

const initialState = {
  recommendationViewData: {},
  recommendationInsertViewData: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.RECOMMENDATION_VIEW_RESPONSE:
      return {
        ...state,
        recommendationViewData: action.payload,
      };
    case types.RECOMMENDATION_VIEW_CLEAR:
      return {
        ...state,
        recommendationViewData: {},
      };
    case types.RECOMMENDATION_INSERT_VIEW_RESPONSE:
      return {
        ...state,
        recommendationInsertViewData: action.payload,
      };

    case types.RECOMMENDATION_INSERT_VIEW_CLEAR:
      return {
        ...state,
        recommendationInsertViewData: {},
      };

    default:
      return state;
  }
};
