import ProfilEdit from "./ProfileEdit"

const SideNavDetail = (props) => {
    return (
            <div className="col-md-8 col-xl-9 col-lg-9 col-12 profile-right-sec">

                <ProfilEdit setIsLoading = {props.setIsLoading}/>

            </div>)
}
export default SideNavDetail;