import { React, useState } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
const CompletionTrendGraph = (props) => {
  const { crsCompltTrendData, completionTrendLabel, crsCompTrendKeys, crsCompltTrend, setTrendYear, trendYear, setImgLoader, setLoaderChart, loaderChart } = props;
  const [trendData, setTrendData] = useState(true)

  useEffect(() => {
  }, [crsCompltTrendData])
  var options = {
    colors: ["#0FB41C", "#28C76F"],
    chart: {
      type: "scatter",
      height: 200,
      width: 930,
      zoom: {
        enabled: false,
        type: "xy",
      },
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 1356,
        options: {
          chart: {
            width: 830,
            height: 200,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 750,
            height: 150,
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            width: 650,
            height: 150,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 300,
            height: 200,
          },
        },
      },
    ],
    series: [
      // crsCompltTrendData
      // { name: 'Courses Completed', data: [50,75,10,23,54,12,15,24,32,17,29,48] }
      { name: completionTrendLabel, data: crsCompltTrendData },
    ],
    xaxis: {
      type: "category",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      // categories: crsCompTrendKeys,
      labels: {
        formatter: function (value, timestamp, index) {
          return [value];
        },
        style: {
          fontSize: "10px",
        },
      },
      tickAmount: 12,
      tickPlacement: "between",
    },
    yaxis: {
      show: true,
      min: 0,
      max: 100,
      tickAmount: 4,
      showAlways: true,
      labels: {
        show: true,
      },
      title: {
        text: "Percentage (%)",
      },
      formatter: function (val) {
        // console.log("Val comptend",val)
        return val.toFixed(0) + "%";
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    // yaxis: {
    //     min: 0,
    //     max: 100,
    //     tickAmount: 4
    // },
    markers: {
      strokeWidth: 0,
    },
    legend: {
      height: 30,
    },
  };
  useEffect(() => {
    if (trendData) {
      setTrendData(!trendData)
      var chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
    }
  }, [options, crsCompltTrendData]);

  // useEffect(() => {
  //   onChangeHandlerForm()
  // }, [trendYear])
  // const onChangeHandlerForm = (e) => {
  //   setTrendYear(e.target.value)
  //   console.log("selctd Year", trendYear)
  // }
  // console.log("selctd Year", trendYear)
  return (
    <>
      <span>
        <b>
          Completion
          <br /> Trend
        </b>
      </span>
      <div className="chartfilterbox">
        {/* <div className="input-text fliterreportsgen graphselct">
          <label for="filter" className="input__label">Select Year</label>
          <select type="text" id="filter" name="filter" className="form-select input_searchfield mb-0 session-filter"
            onChange={(e) => {
              const selectedYear = e.target.value;
              setTrendYear(selectedYear)
              setImgLoader(true)             
            }}
          >
            {crsCompltTrend?.map((item, index) => {
              return (
                <option seleted key={index} value={item[index]}
                >{item}</option>
              )
            })}            
          </select>
        </div> */}
        <div id="chart" className="pl-2">
        </div>
      </div>
    </>
  );
}

export default CompletionTrendGraph