import Page from "../../components/Page";
// import ComplianceReportSection from "../../components/Reports/New Progress Report/ComplianceReportSection";
import ComplianceReportSection from "../../components/Reports/complianceReport/ComplianceReportSection";

export const ComplianceReportPage = () => {
  return (
    <Page>
      <ComplianceReportSection 
        isMobile={false}
      />
    </Page>
  );
};

export default ComplianceReportPage;
