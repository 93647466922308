import { fork, take, call, put, all } from "redux-saga/effects";

import {
  notificationResponse,
  notificationAllReadResponse,
  notificationSingleResponse,
  notificationCountResponse,
} from "./actions";

import types from "./types";
import { postAPIWithoutModule } from "../../api/generic";

export function* doNotification() {
  while (true) {
    const { payload } = yield take(types.NOTIFICATION);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(notificationResponse(response));
  }
}

export function* doNotificationAllRead() {
  while (true) {
    const { payload } = yield take(types.NOTIFICATION_ALL_READ);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(notificationAllReadResponse(response));
  }
}

export function* doNotificationSingle() {
  while (true) {
    const { payload } = yield take(types.NOTIFICATION_SINGLE);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(notificationSingleResponse(response));
  }
}

export function* doNotificationCount() {
  while (true) {
    const { payload } = yield take(types.NOTIFICATION_COUNT);
    const response = yield call(postAPIWithoutModule, payload);
    yield put(notificationCountResponse(response));
  }
}

function* notification() {
  yield fork(doNotification);
  yield fork(doNotificationAllRead);
  yield fork(doNotificationSingle);
  yield fork(doNotificationCount);
}

export default notification;
