import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import Page from "../../components/Page";
import Modal from 'react-bootstrap/Modal';
import { toolboxInfo } from "../../utils/toolbox";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import ToolboxFolders from "./ToolboxFolders";
import Slider from "react-slick";
// import PrevArrow from "../LearningPaths/PrevArrow";
// import NextArrow from "../learningPaths/"
import "react-fine-uploader/gallery/gallery.css";
import _ from "lodash";
import DocumentList from "../../components/ToolBox/DocumentList";
import { useDispatch, useSelector } from "react-redux";
import { SessionKeys } from "../../utils/AppUtils";
import {
    createFoldrReq, createFoldrResponse, createFileReq, createFileResponseClear, deleteFolderReq, deleteFolderReqResClear,
    deleteFile, deleteFileClear, detailFolder, detailFolderClear, toolboxSearchRequest, toolboxUpdateTimeRequest
} from "../../redux/orders/actions";
import { Spinner } from "react-bootstrap";
import FolderCreateFileUpload from "./FolderCreateFileUploadComp";
import { useHistory } from "react-router-dom";

const MyToolBox = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const folderId = params.get("folderId");

    const [viewPdf, setViewPdf] = useState(false)
    const handleCloseViewPdf = () => setViewPdf(false);
    const [isLoading, setIsLoading] = useState(false)
    const [color, setColor] = useState("grey");
    const [folderList, setFolderList] = useState([])
    const [globalFolderList, setGlobalFolderList] = useState([])
    const [documentList, setDocumentList] = useState([])
    const [globalDocumentList, setGlobalDocumentList] = useState([]);
    const [breadCrumList, setBreadCrumList] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmModalFolder, setConfirmModalFolder] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState("Upload files here.");
    const [searchValue, setSearchValue] = useState("");
    const [isSearch, setIsSearch] = useState(false);

    const FILTER_BY = [
        { id: 0, value: "Last Modified" },
        { id: 1, value: "Old to New" },
    ];
    const createFileRes = useSelector(state => state.orders.createFileRes);
    const deleteFolderRes = useSelector(state => state.orders.deleteFolderRes);
    const deleteFileRes = useSelector(state => state.orders.deleteFileRes);
    const toolboxDetailsRes = useSelector(state => state.orders.folderDetails);
    const searchDetailsRes = useSelector(state => state.orders.searchDetails);
    const updateTimeRes = useSelector(state => state.orders.updateTimeRes);



    useEffect(() => {
        window.scrollTo(0, 0)
        getFolderDetails();
    }, [folderId])

    const onClickFilterBy = (value) => {

        if (value === '0') {
            let docsArr = _.cloneDeep(globalDocumentList);
            docsArr.sort(function (a, b) {

                return b.Updated - a.Updated;
            });
            setDocumentList(docsArr);
            setGlobalDocumentList(docsArr);

        } else {
            let docsArr = _.cloneDeep(globalDocumentList);
            docsArr.sort(function (a, b) {

                return a.Updated - b.Updated;
            });
            setDocumentList(docsArr);
            setGlobalDocumentList(docsArr);

        }
    }



    //delete folder -- toolbox
    const deleteFolderFun = (id) => {
        setIsLoading(true);
        dispatch(deleteFolderReq({
            module: "deleteFolder",
            postData: {
                folder_id: id,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
            }
        }))
    }
    useEffect(() => {
        if (!_.isEmpty(deleteFolderRes)) {
            setConfirmModalFolder(false)
            setIsLoading(false)
            if (deleteFolderRes?.status === "success") {
                getFolderDetails();
            }
        }
    }, [deleteFolderRes])
    //delete file -- toolbox
    const deleteFileFun = (id) => {
        setIsLoading(true)
        dispatch(deleteFile({
            module: "deleteFile",
            postData: {
                file_id: id,
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER)
            }
        }))
    }
    useEffect(() => {
        if (!_.isEmpty(deleteFileRes)) {
            setConfirmModal(false)
            setIsLoading(false)
            if (deleteFileRes?.status === "success") {
                getFolderDetails();
            }
        }
    }, [deleteFileRes])

    // Toolbox details-- api integration
    const getFolderDetails = () => {
        setIsLoading(true);
        setNoDataMsg("Upload files here.");
        setIsSearch(false);
        dispatch(detailFolder({
            module: "toolbox-detail",
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                folder_id: folderId
            }
        }))
    }

    useEffect(() => {
        // console.log('folderdetailRes: ', toolboxDetailsRes)
        if (!_.isEmpty(toolboxDetailsRes)) {
            setIsLoading(false);
            if (toolboxDetailsRes?.status === "success") {
                let res = toolboxDetailsRes?.payload?.payload;
                setFolderList(res.folder_list);
                setGlobalFolderList(res.folder_list)

                let docsArr = _.cloneDeep(res?.document_list);
                docsArr.sort(function (a, b) {

                    return b.Created - a.Created;
                });
                setDocumentList(docsArr);
                setGlobalDocumentList(docsArr);
                if (res.folder_breadcrumb_list !== undefined) {
                    let obj = { id: "0", folderNameSec: "My Toolbox" };

                    let breadCrumArr = []
                    if (res?.folder_breadcrumb_list?.length === 0) {
                        breadCrumArr = [obj];
                    } else {
                        breadCrumArr = [obj].concat(res?.folder_breadcrumb_list?.reverse());
                    }
                    breadCrumArr.push({ id: folderId, folderNameSec: res?.folderNameSec })
                    setBreadCrumList(breadCrumArr)
                }
            }
        }
    }, [toolboxDetailsRes])

    const getSearchDetails = (value) => {

        if (value === null || value === undefined || value === '') {
            getFolderDetails();
            return;
        }

        history.push("/toolbox?folderId=" + folderId + "&search=true")
        setIsLoading(true);
        setNoDataMsg("No search result found.");
        setIsSearch(true)
        dispatch(toolboxSearchRequest({
            module: "toolbox-search",
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                search: value
            }
        }))
    }

    useEffect(() => {
        // console.log('folderdetailRes: ', searchDetailsRes)
        if (!_.isEmpty(searchDetailsRes)) {
            setIsLoading(false);

            if (searchDetailsRes?.status === "success") {
                let res = searchDetailsRes?.payload?.payload;
                setFolderList(res.folder_list);
                setGlobalFolderList(res.folder_list)

                let docsArr = _.cloneDeep(res?.document_list);
                docsArr.sort(function (a, b) {

                    return b.Created - a.Created;
                });
                setDocumentList(docsArr);
                setGlobalDocumentList(docsArr);

                // if (res.folder_breadcrumb_list !== undefined) {
                //     let obj = { id: "0", folderNameSec: "My Toolbox" };

                //     let breadCrumArr = []
                //     if (res?.folder_breadcrumb_list?.length === 0) {
                //         breadCrumArr = [obj];
                //     } else {
                //         breadCrumArr = [obj].concat(res?.folder_breadcrumb_list?.reverse());
                //     }
                //     breadCrumArr.push({ id: folderId, folderNameSec: res?.folderNameSec })
                //     setBreadCrumList(breadCrumArr)
                // }
            }
        }
    }, [searchDetailsRes])

    const updateTime = (updateTime,docId)=>{
        setIsLoading(true);
        dispatch(toolboxUpdateTimeRequest({
            module: "toolboxUpdateTime",
            postData: {
                token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
                document_id: docId,
                updated_at:updateTime
            }
        }))
    }

    useEffect(()=>{
        if(!_.isEmpty(updateTimeRes)){
            setIsLoading(false)
            if(updateTimeRes?.status==="success"){
                getFolderDetails();
            }
        }
    
    },[updateTimeRes])
    const searchRecord = (searchValue) => {
        let tempArrFolder = [];
        for (const item of globalFolderList) {
            if (item.folderNameSec.toLowerCase().includes(searchValue.toLowerCase())) {
                tempArrFolder.push(item)
            }
        }
        let tempArr = [];
        for (const item of globalDocumentList) {
            if (item.title.toLowerCase().includes(searchValue.toLowerCase())) {
                tempArr.push(item)
            }
        }
        if (tempArr.length === 0) {
            setNoDataMsg("No search result found.");
        }
        setDocumentList(tempArr)
        setFolderList(tempArrFolder)

    }

    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <section className="my-credit-points report-page toobox-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h1 className="requesthead cursor-pointer" onClick={() => {
                                    history.push('/toolbox?folderId=0')
                                }}>Toolbox</h1>
                                <p className="subhead">Upload, View, Edit and Download all your pdf documents and resources.</p>
                            </div>
                        </div>
                    </div>
                    <div className="center_line mt-0"></div>

                    <FolderCreateFileUpload getFolderDetails={getFolderDetails} setIsLoading={setIsLoading} folderId={folderId} />
                    <div className="search-section-toolbox">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-9 search__input">
                                    <div className="input-lear position-relative d-flex">
                                        <label for="search" className="input__label_learning">Search</label>
                                        <input type="text" id="search" name="search" className="input_searchfield_search" autocomplete="off" onChange={(e) => { setSearchValue(e.target.value) }} />
                                        <button onClick={() => { getSearchDetails(searchValue) }} className="button-reset" >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                id="Find"
                                                width="28"
                                                height="28"
                                                viewBox="0 0 30 30"
                                            >
                                                <defs>
                                                    <linearGradient
                                                        id="linear-gradient"
                                                        y1="0.5"
                                                        x2="1"
                                                        y2="0.5"
                                                        gradientUnits="objectBoundingBox"
                                                    >
                                                        <stop offset="0" stop-color="#00b7f1" />
                                                        <stop offset="1" stop-color="#ed156e" />
                                                    </linearGradient>
                                                </defs>
                                                <circle
                                                    id="Ellipse_191"
                                                    data-name="Ellipse 191"
                                                    cx="15"
                                                    cy="15"
                                                    r="15"
                                                    fill="url(#linear-gradient)"
                                                />
                                                <circle
                                                    id="Ellipse_192"
                                                    data-name="Ellipse 192"
                                                    cx="13"
                                                    cy="13"
                                                    r="13"
                                                    transform="translate(2 2)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    id="Icon_awesome-search"
                                                    data-name="Icon awesome-search"
                                                    d="M14.372,12.6,11.535,9.762a.683.683,0,0,0-.484-.2h-.464a5.917,5.917,0,1,0-1.025,1.025v.464a.683.683,0,0,0,.2.484L12.6,14.372a.68.68,0,0,0,.965,0l.805-.805A.686.686,0,0,0,14.372,12.6ZM5.92,9.562A3.643,3.643,0,1,1,9.562,5.92,3.641,3.641,0,0,1,5.92,9.562Z"
                                                    transform="translate(7.715 7.714)"
                                                    fill="var(--color-element-1)"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3 search__input">
                                    <div className="input-lear position-relative d-flex">
                                        <label for="sortBy" className="input__label_learning">Sort by</label>
                                        <select className="form-select input_searchfield" aria-label="Default select example"
                                            onChange={(e) => { onClickFilterBy(e.target.value) }}
                                        >
                                            {FILTER_BY.map((item, index) => {
                                                return (
                                                    <option seleted key={index} value={item.id}>
                                                        {item.value}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="folder-box-sec mt-4">
                        <div className="container">
                            <div className="row">

                                {folderId !== "0" && !isSearch &&
                                    <div className="col-md-12">
                                        <div className="tiltebox-folder mb-4">
                                            <div className="breadCrum">
                                                {breadCrumList?.map((item, position) => {
                                                    return <span className="cursor-pointer" onClick={() => {
                                                        history.push('/toolbox?folderId=' + item.id)
                                                    }}><span className="seprtor">{item.folderNameSec}</span></span>
                                                })}
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            {(folderList.length > 0 || documentList.length > 0) ? <div>
                                <div className="row"> <ToolboxFolders
                                    folderList={folderList}
                                    globalFolderList={globalFolderList}
                                    color={color}
                                    deleteFolderFun={deleteFolderFun} confirmModal={confirmModalFolder} setConfirmModal={setConfirmModalFolder}
                                /></div>
                                <DocumentList document_list={documentList} globalDocumentList={globalDocumentList} deleteFileFun={deleteFileFun} confirmModal={confirmModal} setConfirmModal={setConfirmModal} noDataMsg={noDataMsg} getFolderDetails={getFolderDetails} updateTime={updateTime}/>
                            </div> :
                                <div className="col-md-12">
                                    <div className="not-found mt-3">
                                        <div className="interboxes">
                                            <img src="/images/folder-files.png" alt="Not Found" />
                                            <p>{noDataMsg}</p>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>

                </section>

                <Modal
                    show={viewPdf}
                    onHide={handleCloseViewPdf}
                    size="md"
                    dialogClassName="modalassign addfoldermodal"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            PDF View
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="pdf-view-page" style={{ height: "700px" }}>
                                <iframe
                                    className="w-100"
                                    // onLoad={() => { window.scrollTo(0, 0); setLoadFalse(); markProgress(); }}
                                    src=""
                                    // title={module.title} 
                                    height="100%" width="100%" />
                            </div>
                        </>
                    </Modal.Body>
                </Modal>
            </Page>
        </>






    )
}

export default MyToolBox;