import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  doAssignTeamMates,
  doAssignTeamMatesResClear,
  setCurrentView,
} from "../../redux/assignCourse/actions";
import { SessionKeys } from "../../utils/AppUtils";
import Loader from "../Loader/Loader";
import AlertDeleteCartsModal from "../Cart/alertModalDelCart";
import AlertModalAssign from "../Cart/alertModalAssign";
import StaticUtils from "../../utils/StaticUtils";
const CreateTeam = (props) => {
  const {
    filteredFromCoursesOffices,
    setFilteredFromCoursesOffices,
    filterReq,
    parentName,
    setTitleHeading,
    setSelectedTeamsList
  } = props;
  const dispatch = useDispatch();

  const assignTeammatesRes = useSelector(
    (state) => state.assignCourse.assignTeamMates
  );
  useEffect(() => {
    // console.log("filterFrom: ", filteredFromCoursesOffices);
  }, [filteredFromCoursesOffices]);

  useEffect(() => {
    setTitleHeading(`Assign ${parentName} to`);
   
  }, []);

  const [teamName, setTeamName] = useState("");
  const [flag, setFlag] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [clickIndex, setClickIndex] = useState("");
  const [clickName, setClickName] = useState("");
  const [rejectedMember, setRejectedMember] = useState([]);
  const [selectTeamError, setSelectTeamError] = useState([]);

  const createTeam = () => {
    if (teamName?.trim() === "") {
      setSelectTeamError("Please enter your team name.");
      return;
    } else {
      setSelectTeamError("");
    }

    let selectedUsers = [];
    for (const item of rejectedMember) {
      selectedUsers.push(item.user_id);
    }
    let req = {
      token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
      users: selectedUsers,
      type: "team",
      team_name: teamName,
    };
    let reqBody = { ...req, ...filterReq };
    setIsLoading(true)
    dispatch(
      doAssignTeamMates({
        module: "assignTeamMates",
        postData: reqBody,
      })
    );
  };

  useEffect(() => {
    console.log("assignTeammatesRes: ", assignTeammatesRes);
    if (!_.isEmpty(assignTeammatesRes)) {
      setIsLoading(false)
      if (assignTeammatesRes?.status === "success") {
        // setShow(false)
        let resObj = _.cloneDeep(assignTeammatesRes?.payload?.payload);
        resObj.user_count= resObj.team_count
        resObj.id = resObj.id+""
        setSelectedTeamsList([resObj])
        dispatch(doAssignTeamMatesResClear());
        dispatch(setCurrentView(3));
      }
    }
  }, [assignTeammatesRes]);

  const selectItem = (index) => {
    let tempArr = _.cloneDeep(filteredFromCoursesOffices);
    let selectedTempArray = _.cloneDeep(rejectedMember);
    if (!StaticUtils.isContainObject(selectedTempArray, tempArr[index], 0)) {
      selectedTempArray.push(tempArr[index]);
    }

    setRejectedMember(selectedTempArray);
    tempArr.splice(index, 1);
    setFilteredFromCoursesOffices(tempArr);
    setShowModal1(false);
  };

  const removeItem = (index) => {
    let selectedTempArray = _.cloneDeep(rejectedMember);
    let tempArr = _.cloneDeep(filteredFromCoursesOffices);

    if (!StaticUtils.isContainObject(tempArr, selectedTempArray[index], 0)) {
      tempArr.push(selectedTempArray[index]);
    }

    setFilteredFromCoursesOffices(tempArr);
    selectedTempArray.splice(index, 1);
    setRejectedMember(selectedTempArray);
  };

  const removeItem1 = (index) => {
    let selectedTempArray = _.cloneDeep(filteredFromCoursesOffices);
    // let tempArr = _.cloneDeep(teamMatesList);

    // 	if (!StaticUtils.isContainObject(tempArr,selectedTempArray[index])) {
    // 		tempArr.push(selectedTempArray[index])
    // 	}

    // setTeamMatesList(tempArr)

    selectedTempArray.splice(index, 1);
    setFilteredFromCoursesOffices(selectedTempArray);
    setShowModal1(false);
  };

  const handleClose1 = () => {
    setShowModal1(false);
  };

  return (
    <>
    <Loader start={isLoading} />
      <div className="createteams">
        <div className="chooseteamate">
          <h4>Name your Team</h4>
          {/* {flag == 1 ? (
            <p
              style={{
                fontSize: "20px",
                fontFamily: "verdana",
                color: "green",
              }}
            >
              Team creation is successful
            </p>
          ) : (
            ""
          )} */}
          <div className="col-md-6">
            <div className="input-lear position-relative d-flex">
              <label for="addteam" className="input__label_learning">
                Name
              </label>
              <input
                type="text"
                id="addteam"
                name="addteam"
                className="input_searchfield_search-cart "
                placeholder="What do you want to call this Team?"
                onChange={(evt) => {
                  setTeamName(evt.target.value);
                }}
              />
            </div>
            <p className="error-msg">{selectTeamError}</p>
          </div>

          <div className="teamselected">
            <div className="selectresilt">
              <strong>
                Team Members({filteredFromCoursesOffices?.length})
              </strong>
            </div>
            <div className="search-team-result slectedteammates">
              {filteredFromCoursesOffices?.map((item, index) => {
                return (
                  <div className="team-result-box">
                    <div className="team-user-img">
                      <img
                        src={
                          item?.profile_img !== ""
                            ? item?.profile_img
                            : "/images/avatar.png"
                        }
                        alt="user"
                      />
                    </div>
                    <div className="userteamname">{item?.name}</div>
                    {/* <AlertModalAssign
                      deleteCart={() => {
                        selectItem(index);
                      }}
                      handleClose={handleClose1}
                      show={showModal1}
                    /> */}
                    <div
                      className="addteammate cursor-pointer"
                      onClick={() => {
                        setShowModal1(true);
                        setClickIndex(index);
                        setClickName(item?.name);
                      }}
                    >
                      +
                    </div>
                  </div>
                );
              })}
              <AlertModalAssign
                deleteCart={() => {
                  selectItem(clickIndex);
                }}
                handleClose={handleClose1}
                show={showModal1}
                name={clickName}
              />
            </div>
          </div>

          {/*<div className="teamselected">
						<div className="selectresilt">
							<strong>Deleted Members ({rejectedMember?.length})</strong>
						</div>
						<Loader start={isLoading} />
						<div class="search-team-result slectedteammates">
							{rejectedMember?.map((item, index) => {
								return (
									<div className="team-result-box" style={{ 'background': '#b995db' }}>
										<div class="team-user-img">
											<img
												src={
													item?.profile_img !== ""
														? item?.profile_img
														: "/images/avatar.png"
												}
												alt="user"
											/>
										</div>
										<div className="userteamname">{item?.name}</div>
										
										<div
											className="addteammate cursor-pointer"
											onClick={() => {
												removeItem(index);
												// setShowModal1(true);
												// setClickIndex(index);
												// setClickName(item?.name);
											}}
										>
											+
										</div>
									</div>
								);
							})}

						</div>
						</div>*/}

          <div className="buttonsteammate">
            <button
              type="button"
              className="btn btn-primary btn-block back-button"
              onClick={() => {
                dispatch(setCurrentView(5));
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block setup-button"
              onClick={() => {
                createTeam();
                setFlag(1);
              }}
            >
              + Create Team
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTeam;
