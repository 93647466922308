import CertifySection from "../../components/Certify/CertifySection";
import Page from "../../components/Page";

const CertifyPage = () => {
    return (
        <Page>
            <CertifySection />
        </Page>
    );
}

export default CertifyPage;