import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { resendActivationLink, resendActivationLinkClear } from '../redux/auth/actions';
import { useHistory } from 'react-router-dom';
import FooterNew from '../components/FooterNew';
import HeaderLogoSection from "../components/HeaderLogoSection";
import MESSAGE from '../utils/messages';

const ActivationLinkExpiryPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email');
    const action = params.get('action');
    const resendLink = useSelector(state => {
        //  console.log(state.auth); 
        return state.auth.resendLink
    })

    const [htmlFormError, setFormError] = useState('')
    const [htmlFormSuccess, setFormSuccess] = useState('')
    const [linkSend, setLinkSend] = useState(false)

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
            document.getElementById("id_page_404").style.display = "none";
        }
    }, [])

    useEffect(() => {
        // console.log(resendLink)
        if (!_.isEmpty(resendLink)) {
            // console.log('not empty')
            dispatch(resendActivationLinkClear())
            if (resendLink.status === 'success') {
                setFormSuccess('Activation link has been resent to your email.')
                setTimeout(() => {
                    // console.log('Redirect to login', email)
                    setFormSuccess('')
                }, 5000)
                // history.push(`/`, { canLogin: true })
            } else {
                // console.log('Redirect to error', email)
                setFormError(resendLink.error.message)
                setTimeout(() => {
                    setFormError('')
                }, 5000)
                setLinkSend(false)
            }

        }
    }, [resendLink])

    const resend = () => {
        if (linkSend) {
            return;
        }
        setLinkSend(true)
        const params = {
            'verify-email-btn': 'VERIFY EMAIL',
            'email': email
        }
        // console.log(params)
        dispatch(resendActivationLink({
            module: 'resend-verification-email',
            postData: {
                email: email
            }
        }))
    }

    return (
        <>
            <div className="page-wrapper-rsp">
                <HeaderLogoSection />
                <div className="header-border"></div>
                <section className="setup-password"> {/*className="setup-password" for commented ui(previous one) */}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 py-5 right-space">

                                <p><i style={{ 'fontWeight': '100' }}>{MESSAGE.LINK_EXPIRED}</i></p>
                                {
                                    (action != 'reset' && !linkSend) ?
                                        <p>
                                            <i style={{ 'fontWeight': '100' }}>
                                                Click&nbsp;
                                                <u style={{ color: 'royalblue' }} onClick={() => resend()} className="cursor-pointer">here</u>&nbsp;
                                                to request a new link.
                                            </i>
                                        </p>
                                        : ""
                                }


                                {/*<div className="heading ">
                            <p className="active_text">The activation link you <br />followed has expired.</p>
                            <h3>Activation link</h3>
                        </div>
                        <p className="user pt-2">Email Address</p>
                        <p className="john-id"><strong>{email}</strong></p>
                        <div className="save_button">
                            <button 
                                type="button" 
                                className="btn btn-primary setup-button"
                                onClick={() => resend()}>
                                    Resend Link
                            </button>
    </div>*/}
                                {htmlFormError && (<div className="mt-2 text-danger" style={{ 'font-size': '13px' }}>{htmlFormError}</div>)}
                                {htmlFormSuccess && (<div className="mt-2 text-success" style={{ 'font-size': '13px' }}>{htmlFormSuccess}</div>)}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <FooterNew />
        </>
    );
}

export default ActivationLinkExpiryPage;