import React from 'react'
import PrivacyPolicyComp from '../../components/FooterSectComponents/PrivacyPolicyComp';
import Page from "../../components/Page";

const PrivacyPolicy = () => {
  return (
    <Page>
      <PrivacyPolicyComp />
    </Page>
  )
}

export default PrivacyPolicy