import types from "./types";

// Product Detail Fetch
export const getPostList = (payload) => ({
  type: types.POST_LIST,
  payload,
});
export const getPostListResponse = (payload) => ({
  type: types.POST_LIST_RESPONSE,
  payload,
});
export const getPostListResponseClear = () => ({
  type: types.POST_LIST_RESPONSE_CLEAR,
});

// add comments
export const getAddComments = (payload) => ({
  type: types.ADD_COMMENTS,
  payload,
});
export const getAddCommentsResponse = (payload) => ({
  type: types.ADD_COMMENTS_RESPONSE,
  payload,
});
export const getAddCommentsResponseClear = () => ({
  type: types.ADD_COMMENTS_RESPONSE_CLEAR,
});

// edit comments
export const getEditComments = (payload) => ({
  type: types.EDIT_COMMENTS,
  payload,
});
export const getEditCommentsResponse = (payload) => ({
  type: types.EDIT_COMMENTS_RESPONSE,
  payload,
});
export const getEditCommentsResponseClear = () => ({
  type: types.EDIT_COMMENTS_RESPONSE_CLEAR,
});

// comment like

export const getLikeComment = (payload) => ({
  type: types.GET_LIKE,
  payload,
});
export const getLikeCommentResponse = (payload) => ({
  type: types.GET_LIKE_RESPONSE,
  payload,
});
export const getLikeCommentResponseClear = () => ({
  type: types.GET_LIKE_RESPONSE_CLEAR,
});

// activity-like

export const getActivityLike = (payload) => ({
  type: types.GET_ACTIVITY_LIKE,
  payload,
});
export const getActivityLikeResponse = (payload) => ({
  type: types.GET_ACTIVITY_LIKE_RESPONSE,
  payload,
});
export const getActivityLikeResponseClear = () => ({
  type: types.GET_ACTIVITY_LIKE_RESPONSE_CLEAR,
});

export const deleteCommentReq = (payload) => ({
  type: types.DELETE_COMMENT_REQ,
  payload,
});
export const deleteCommentResponse = (payload) => ({
  type: types.DELETE_COMMENT_RESPONSE,
  payload,
});
export const deleteCommentResponseClear = () => ({
  type: types.DELETE_COMMENT_RESPONSE_CLEAR,
});


export const deletePostReq = (payload) => ({
  type: types.DELETE_POST_REQ,
  payload,
});
export const deletePostResponse = (payload) => ({
  type: types.DELETE_POST_RESPONSE,
  payload,
});
export const deletePostResponseClear = () => ({
  type: types.DELETE_POST_RESPONSE_CLEAR,
});

export const editPostReq = (payload) => ({
  type: types.EDIT_POST_REQ,
  payload,
});
export const editPostResponse = (payload) => ({
  type: types.EDIT_POST_RESPONSE,
  payload,
});
export const editPostResponseClear = () => ({
  type: types.EDIT_POST_RESPONSE_CLEAR,
});
