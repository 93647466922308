import Page from "../../components/Page";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
import CodeOfEthicsReportHomeSection from "../../components/Reports/codeOfEthics/CodeOfEthicsReportHomeSection";

export const CodeOfEthicsReportPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <CodeOfEthicsReportHomeSection 
                    setIsLoading={setIsLoading} 
                    isLoading={isLoading}
                    isMobile={false}
                />
            </Page>
        </>
    )
}

export default CodeOfEthicsReportPage;