import { useSelector } from "react-redux";
import { clickStreamUser } from "../../utils/AppUtils";

const ContentBox = (props) => {
    const {
        title,
        count,
        tag,
        reviewType,
        setReviewType,
        setIsLoading
    } = props;

    const authBroker = useSelector(state => state.auth.authBroker)

    const changeTab = () => {
        if (reviewType != tag) {
            setReviewType(tag)
            setIsLoading(true)
            clickStreamUser('review-role-play-page', {
                user_id: authBroker.payload.user.user_id,
                tab: reviewType
            })
        }
    }

    return (
        <div className="roleplayfilterbtn">
            <div className={`reviewbox-content ${reviewType === tag ? 'clicked' : ''}`} onClick={() => changeTab(tag)}>
                <div className="lefttext">{title}</div>
                <div className="counttext">{count}</div>
            </div>
        </div>
    )
}

export default ContentBox;