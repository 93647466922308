import { style } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CONFIG from "../utils/config";

const FooterNew = () => {
  const fixedFooter = useSelector((state) => state.home.fixedFooter);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const [showFixedFooter, setShowFixedFooter] = useState(false);
const location = useLocation();
  
useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])

  useEffect(() => {
    // console.log('Fixed Footer', fixedFooter)
    if (fixedFooter) {
      setShowFixedFooter(true);
    } else {
      setShowFixedFooter(false);
    }
  }, [fixedFooter]);

  return (
    <footer className={`home-footer_new`} style={{marginLeft: location.pathname.includes("/courseDetail/")?"400px":"0px" }}>
     <div className="container">
     <div className="row">
        <div className="col-md-4 d-flex align-items-center">
        <div className="footer_logotwo">
            {authBroker.payload?.user?.apply_theme ?
              <img src="/images/rps-logo-white.png" width="84" height="46" alt="" />
              :
              <img src="/images/rps-logo-white.png" width="84" height="46" alt="" />
            }
          </div>
          <div className="footer_logoone">
            {authBroker.payload?.user?.apply_theme ?
              <img src="/images/logo_1.png" width="55" height="21" alt="" />
              :
              <img src="/images/Niit_Logo.png" width="55" height="21" alt="" />
            }
          </div>
         

        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-center">
      
          <div className="footer_cont">
            {/* More ways to Connect:  */}
            {/* <p>
              <span className="footer_contone">
                www.niit.com Or call 1-800-00-MYSEL.
              </span>
            </p> */}
             <p className="">
              <span className="footer_conttwo">
                Copyright © {new Date().getFullYear()} NIIT Inc. All rights
                reserved.
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-4 text-right d-flex align-items-center justify-content-end">
        <div className="footer_cont">
            <p>
              <span className="footer_contthree">
                <a className="footerhlinlk" href="https://www.niit.com/india/training/policy/pages/privacy-statement.aspx" target="_blank">Privacy Policy</a>&nbsp;|&nbsp;
                <a className="footerhlinlk" href="https://www.niit.com/india/training/policy/pages/terms-of-use.aspx" target="_blank">Terms of Use</a>
                {/* &nbsp;|&nbsp; */}
                {/* <a className="footerhlinlk" href="http://www.xsel.app/cookie-policy/" target="_blank">Cookie Policy</a> */}
              </span>
            </p>
          </div>
        </div>
      </div>
     </div>
    </footer>
  );
};

export default FooterNew;
