import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reportCategoryData } from '../../../utils/testJson';


const PurchaseTitleSection = () => {
    const history = useHistory();
    const [reportDesc,setReportDesc] = useState('')
    const [reportListing, setReportListing] = useState([])
    const userRole = useSelector(state => state.user.role)

    useEffect(() => {
        if (userRole) {
            let newList = []
            for (let list of reportCategoryData) {
                if (list.role.includes(userRole)) {
                    newList.push(list)
                }
            }
            setReportListing(newList)
        }
    }, [userRole])

    useEffect(()=>{        
        for(let item of reportListing){
            if(window.location.pathname === item?.pageName){
                setReportDesc(item?.description)
            }
        }
    },[reportListing])
    const switchReportPage = (path) => {
        history.push(path)
    }
    return (
        <>
            <section className="onboarding-report">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 selctdrop-report">

                            <Form.Select aria-label="Default select example" onChange={(ev) => {
                                switchReportPage(ev.target.value)
                            }}>
                                {reportListing?.map((item, position) => {
                                    return <option value={item?.pageName} selected={window.location.pathname === item?.pageName ? true : false} >{item?.title}</option>
                                })
                                }
                            </Form.Select>
                            <p className="subheadd">{reportDesc}</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PurchaseTitleSection