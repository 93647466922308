import React from "react";
import { useEffect, useRef, useState } from "react";
import { NavDropdown, Nav } from "react-bootstrap";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationResponse,
  notificationData,
  notificationClear,
  notificationSingleData,
  notificationSingleResponse,
  notificationSingleClear,
  notificationAllReadData,
  notificationAllReadResponse,
  notificationAllReadClear,
  notificationCountData,
  notificationCountResponse,
  notificationCountClear,
} from "../redux/notification/actions";
import { clickStreamUser, SessionKeys, timezoneArea } from "../utils/AppUtils";
import { useHistory as history, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import CONFIG from "../utils/config";
import moment from "moment";
import ShimmerNotification from "./Layouts/ShimmerNotification";

const Notification = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const notificationResData = useSelector(
    (state) => state?.notification?.notificationData
  );
  const notificationSingleResData = useSelector(
    (state) => state?.notification?.notificationSingleData
  );
  const notificationAllReadResData = useSelector(
    (state) => state?.notification?.notificationAllReadData
  );
  const notificationCountResult = useSelector(
    (state) => state?.notification?.notificationCountData
  );

  // console.log("Count notificationss", notificationCountResult);
  const authBroker = useSelector((state) => state.auth.authBroker);
  const auth = useSelector((state) => state.auth);
  const userDetailRes = useSelector((state) => state.user.userBroker);

  const [notification, setNotification] = useState([]);
  const [showUnRead, setShowUnRead] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [id, setId] = useState(null);
  const [imgLoader, setImgLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [showNotificationPulse, setShowNotificationPulse] = useState(
    localStorage.getItem(SessionKeys.NOTI_SHOW) == "true" ? true : false
  )
  const [timezone, setTimezone] = useState('')

  useEffect(() => {
    // console.log("userDetailRes: ", userDetailRes);
    if (!_.isEmpty(userDetailRes?.payload?.payload)) {
      const timeZone = userDetailRes.payload.payload.timezone ?
        userDetailRes.payload.payload.timezone.replace(':', '') : '-0500'
      setTimezone(timeZone)
    }
  }, [userDetailRes]);

  useEffect(() => {
    if (!_.isEmpty(notificationResData)) {
      setImgLoader(false);
      if (notificationResData?.status === "success") {
        setNotification(notificationResData?.payload?.payload);
      }
      setIsLoading(false)
      // dispatch(notificationClear());
    }
  }, [notificationResData]);

  useEffect(() => {
    if (!_.isEmpty(notificationCountResult)) {
      setImgLoader(false);
      if (notificationCountResult?.status === "success") {
        const count = notificationCountResult?.payload?.payload?.unread_count;
        if (count > notificationCount) {
          localStorage.setItem(SessionKeys.NOTI_SHOW, true)
          setShowNotificationPulse(true)
        }
        setNotificationCount(count);
      }
    }
  }, [notificationCountResult]);

  useEffect(() => {
    if (!_.isEmpty(notificationSingleResData)) {
      if (notificationSingleResData?.status === "success") {
        // console.log(notificationSingleResData);
        const notificationDataSingle =
          notificationSingleResData.payload.payload;
        if (!_.isEmpty(notificationDataSingle)) {
          setNotification(notificationDataSingle);
          for (let n of notificationDataSingle) {
            if (n.id == id) {
              goToNotification(n.redirection_key, n.id);
            }
          }
        }
        dispatch(notificationSingleClear());
      }
    }
  }, [notificationSingleResData]);

  useEffect(() => {
    if (isOpen) {
      getNotificationList();
      if (showNotificationPulse) {
        localStorage.setItem(SessionKeys.NOTI_SHOW, false)
        setShowNotificationPulse(false)
      }
    }
  }, [isOpen])

  const getNotificationList = () => {
    dispatch(
      notificationData({
        module: "myNotification",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
        },
      })
    );
  };

  const markAsRead = (id) => {
    setId(id);
    dispatch(
      notificationSingleData({
        module: "myNotification",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          status: "read",
          id: id,
        },
      })
    );
  };

  const getNotificationAllRead = () => {
    dispatch(
      notificationAllReadData({
        module: "myNotification",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          status: "read",
        },
      })
    );
  };

  const getNotificationAllUnread = () => {
    dispatch(
      notificationData({
        module: "myNotification",
        postData: {
          token: localStorage.getItem(SessionKeys.API_TOKEN_BROKER),
          status: "unread",
        },
      })
    );
  };

  const goToNotification = (keyword, id) => {
    switch (keyword) {
      case "certificate":
        // console.log("my certificates clicked");
        // console.log("Id clicked", id);
        history.push("/profile/edit?section=certificate");
        clickStreamUser("my-certificates_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "badge":
        // console.log("Id clicked", id);
        history.push("/profile/edit?section=badge");

        clickStreamUser("notification-clicked-redirect-badge", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "request-role":
      case "administrator-dashboard":
        history.push("/accept-role");
        // console.log("Id clicked", id);
        clickStreamUser("role_requests_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "home":
        history.push("/home");
        // console.log("Id clicked", id);
        clickStreamUser("role_requests_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "review":
        history.push("/review-role-play");
        // console.log("Id clicked", id);
        clickStreamUser("role_requests_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "feedback":
        history.push("/my-role-plays");
        clickStreamUser("role_requests_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "profile":
        history.push("/profile/edit?section=edit");
        // console.log("Id clicked", id);
        clickStreamUser("role_requests_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "course-listing":
        history.push("/my-courses");
        // console.log("Id clicked", id);
        clickStreamUser("my_course_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "video-listing":
        history.push("/my-videos");
        // console.log("Id clicked", id);
        clickStreamUser("my_video_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "learning path":
        history.push("/learning-paths");
        // console.log("Id clicked", id);
        clickStreamUser("learning_paths_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "onboarding-path":
        history.push("/my-onboarding");
        // console.log("Id clicked", id);
        clickStreamUser("my_onboarding_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
        break;
      case "course":
        history.push("/my-courses");
        // console.log("Id clicked", id);
        clickStreamUser("learning_paths_menu_click", {
          userId: auth.authBroker?.payload?.user.user_id,
        });
    }
  };
  //setKeyword(payload.data);
  var NinetyNine = "99+";

  return (
    <>
      <li
        className="nav-item dropdown notifydroprsp"
        title="Notifications"
        id="myCheck"
      >
        {showNotificationPulse == true
          ?
          <span
            className={
              notificationCount === 0
                ? ""
                : "noticount"
            }
          >
            {/* {notificationCount > 99
            ? `${NinetyNine}`
            : notificationCount > 0
            ? notificationCount
            : ""} */}
          </span> : ''}

        <NavDropdown
          onToggle={() => {
            setIsOpen(!isOpen)
          }}

          className="notification-nav"
          title={
            <svg
              xmlnsXlink="http://www.w3.org/2000/svg"
              width="13.163"
              height="16.463"
              viewBox="0 0 13.163 16.463"
              onClick={() => {
                clickStreamUser("notifications-button-clicked", {
                  userId: authBroker?.payload?.user.user_id,
                });
              }}
            >
              <g
                id="Icon_ionic-ios-notifications"
                data-name="Icon ionic-ios-notifications"
                transform="translate(-6.761 -3.93)"
              >
                <path
                  id="Path_3370"
                  data-name="Path 3370"
                  d="M16.587,30.517a1.94,1.94,0,0,0,1.979-2.181H14.6A1.941,1.941,0,0,0,16.587,30.517Z"
                  transform="translate(-3.253 -10.123)"
                />
                <path
                  id="Path_3371"
                  data-name="Path 3371"
                  d="M19.757,16.123c-.634-.835-1.88-1.325-1.88-5.065,0-3.839-1.7-5.382-3.275-5.752-.148-.037-.255-.086-.255-.243V4.943a1,1,0,1,0-2.008,0v.119c0,.152-.107.206-.255.243-1.584.374-3.275,1.913-3.275,5.752,0,3.74-1.247,4.226-1.88,5.065a.817.817,0,0,0,.654,1.309H19.107A.817.817,0,0,0,19.757,16.123Z"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          }
        >
          {
            isLoading ?
              [1, 2].map(m => {
                return (
                  <ShimmerNotification 
                    key={`notify-${m}-shimmer`}
                  />
                )
              })
              :
              notification.length > 0 ? (
                <>
                  {" "}
                  <div className="scroll-nofify">
                    {notification.map((item, i) => {
                      return (
                        <>
                          <NavDropdown.Item
                            key={`notify-${i + 1}`}
                            className="get_CertifiedContainer dropdownItem_notiFcation"
                          // onClick={() => goToNotification(item.redirection_key, item.id)}
                          >
                            <div className="getCertified">
                              <a href="#">
                                {" "}
                                {/* <img
                      src="/images/get_certified01.jpg"
                      width="25px"
                      height="25px"
                      alt=""
                    /> */}
                              </a>
                            </div>
                            <div
                              className="getCertified_content"
                              onClick={() => item.status === 'read' ? goToNotification(item.redirection_key, item.id) : markAsRead(item.id)}
                            >
                              {/* <h6>{item?.notification_title}</h6> */}
                              <h6
                                className={`${item.status === "read" ? "hide-bold" : ""
                                  }`}
                              >
                                {item?.notification_content}
                              </h6>
                              <p className="text-right">

                                {timezone ? moment.utc(
                                  item.created_at,
                                  "YYYY-MM-DD hh:mm:ss"
                                ).tz(timezoneArea(timezone)).fromNow()
                                  :
                                  moment.utc(
                                    item.created_at,
                                    "YYYY-MM-DD hh:mm:ss"
                                  ).tz(timezoneArea('-0500')).fromNow()
                                }
                              </p>
                            </div>
                          </NavDropdown.Item>
                        </>
                      );
                    })}
                  </div>
                  <div className="getCertified buutndf">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                    >
                      <button
                        type="button"
                        className="btn btn-primary btn-block setup-button markAll_btn"
                        onClick={() => {
                          setImgLoader(true);
                          showUnRead
                            ? getNotificationList()
                            : getNotificationAllUnread();
                          setShowUnRead(!showUnRead);
                        }}
                      >
                        {imgLoader ? (
                          <div className="d-flex justify-content-center w-100">
                            <Spinner animation="border" />
                          </div>
                        ) : showUnRead ? (
                          "Show all"
                        ) : (
                          "Show unread only"
                        )}

                      </button>
                    </a>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      id="notificationDropDown"
                    >
                      <button
                        className="getNotFieedbutton btn-primary"
                        onClick={() => {
                          document.getElementById("myCheck").click();
                        }}
                      >
                        Close
                      </button>
                    </a>
                  </div>
                </>
              ) : (
                <p className="no-notifation">Yet to receive notifications</p>
              )}
        </NavDropdown>
      </li>
    </>
  );

};

export default Notification;
