import _ from "lodash";
import $ from "jquery";
import { BroadcastChannel } from 'broadcast-channel';
import moment from "moment";

export const LOGIN_CHANNEL = new BroadcastChannel('login');

export const emailValidation =(emailStr)=>{
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if(!emailStr || regex.test(emailStr) === false){
      return false;
  }
  return true;
}

export const cnfPwdValidation =(pass,cnfPwd)=>{
  if(pass===null||pass===undefined||pass===''||pass!==cnfPwd){
    return false
  }
  return true;
}

export const passLimitValidation =(pass)=>{
  if(pass===null||pass===undefined||pass.length<6){
    return false
  }
  return true;
}

export const passCharValidation =(password)=>{
  var regularExpression = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()-_+={}[]|\/:;"'<>,.?])[a-zA-Z0-9~`!@#$%^&*()-_+={}[]|\/:;"'<>,.?]{6,}$/;
  return regularExpression.test(password)
}

export const logoutClearSession = () => {
  // localStorage.removeItem(SessionKeys.API_TOKEN);
  localStorage.removeItem(SessionKeys.BROKER_ID);
  localStorage.removeItem(SessionKeys.IS_PREMIUM);
  localStorage.removeItem(SessionKeys.EMAIL_SESSION);
  localStorage.removeItem(SessionKeys.API_TOKEN_BROKER);
  localStorage.removeItem(SessionKeys.HOSTINST_ID);
  localStorage.removeItem(SessionKeys.NOTI_SHOW);
  // localStorage.setItem(SessionKeys.REMIND_ME_LATER,false)
  localStorage.removeItem('persist:root')
}


export const SessionKeys = {
  EMAIL: "EMAIL",
  EMAIL_SESSION: "EMAIL_SESSION",
  PASS:"PASSWORD",
  API_TOKEN:"API_TOKEN",
  API_TOKEN_BROKER:"API_TOKEN_BROKER",
  IS_REMEMBERED:"IS_REMEMBERED",
  IS_PREMIUM:"IS_PREMIUM",
  REMIND_ME_LATER:"REMIND_ME_LATER",
  USERNAME: "USERNAME",
  IFRAME_USERNAME: "IFRAME_USERNAME",
  BROKER_ID: "BROKER_ID",
  BROKER_NAME: "BROKER_NAME",
  HOSTINST_ID: "HOSTINST_ID",
  IS_AGGREED:"IS_AGGREED",
  USER_ID:"USER_ID",
  NOTI_SHOW:"NOTI_SHOW",
};

export const clickStreamUser = (eventName, eventObj) => {
  window.captureMyEvents(eventName, eventObj);
}

export const checkCall = () => {
  // console.log('checkCall')
}

export const setIconForReport = (status) => {
  switch (status) {
    case 'in-progress':
      return "/images/inprogress.svg"
    case 'not-started':
    case 'over-due':
      return "/images/not-started.svg"
    default:
      return "/images/check-report.svg"
  }
}

export const convertTimeFormat = (duration, isSecond=false) => {
  // console.log(isSecond ? duration : "")
  if (isSecond) {
    let secondHand = duration % 60;
    duration = parseInt(duration/60);
    if (secondHand) {
      // return `${duration} ${duration > 1 ? 'Mins' : 'Min'} ${secondHand} ${secondHand > 1 ? 'Secs' : 'Sec'}`
      if (duration > 60) {
        let hours = Math.floor(duration / 60);
        let minutes = duration % 60;
        hours = hours >= 10 ? hours : `0${hours}`;
        minutes = minutes >= 10 ? minutes : `0${minutes}`;
        secondHand = secondHand >= 10 ? secondHand : `0${secondHand}`;
        if (_.toNumber(minutes) === 0) {
          return `${hours} ${hours > 1 ? 'Hours' : 'Hour'} ${secondHand} ${secondHand > 1 ? 'Secs' : 'Sec'}`
        } else {
          return `${hours} ${hours > 1 ? 'Hours' : 'Hour'} ${minutes} ${minutes > 1 ? 'Mins' : 'Min'} ${secondHand} ${secondHand > 1 ? 'Secs' : 'Sec'}`
        }
      } else if (duration < 60) {
        let minutes = duration >= 10 ? duration : `0${duration}`;
        secondHand = secondHand >= 10 ? secondHand : `0${secondHand}`;
        return `${minutes} ${minutes > 1 ? 'Mins' : 'Min'} ${secondHand} ${secondHand > 1 ? 'Secs' : 'Sec'}`
      } else if (duration === 60) {
        return `01 Hour ${secondHand} ${secondHand > 1 ? 'Secs' : 'Sec'}`
      }
    } else {
      return convertTimeInHourAndMin(duration)
    }
  } else {
    return convertTimeInHourAndMin(duration)
  }
}

export const formatSecondsHand = (duration) => {
  const secondsHand = duration >= 10 ? duration : `0${duration}`
  return `${secondsHand} Sec${duration > 1 ? "s" : ""}`;
}


export const convertTimeInHourAndMin = (duration) => {
  if (duration > 60) {
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;
    hours = hours >= 10 ? hours : `0${hours}`;
    minutes = minutes >= 10 ? minutes : `0${minutes}`;
    if (_.toNumber(minutes) === 0) {
      return `${hours} ${hours > 1 ? 'Hours' : 'Hour'}`
    } else {
      return `${hours} ${hours > 1 ? 'Hours' : 'Hour'} ${minutes} ${minutes > 1 ? 'Mins' : 'Min'}`
    }
  } else if (duration < 60) {
    let minutes = duration >= 10 ? duration : `0${duration}`;
    return `${minutes} ${minutes > 1 ? 'Mins' : 'Min'}`
  } else if (duration === 60) {
    return '01 Hour'
  }
}

export const convertHoursToMins = (duration) => {
  const convertArray = _.toString(duration).includes(':') ? duration.split(":") : [duration, 0]
  const hoursMinutes = _.toNumber(convertArray[0]) * 60
  const minutes = _.toNumber(convertArray[1])
  const totalMinutes = hoursMinutes + minutes
  return totalMinutes;
}

export const convertSecondsToMins = (duration) => {
  // console.log('SECONDS', duration)
  const totalMinutes = _.toNumber(duration) / 60
  // console.log('SECONDS', totalMinutes)
  return totalMinutes;
}

export const getWordSpeedType = (wpm) => {
  if (wpm >= 1 && wpm <= 120) {
      return 'Slow';
  } else if (wpm > 120 && wpm <= 160) {
      return 'Appropriate';
  } else if (wpm > 160) {
      return 'Faster';
  } else {
      return '';
  }
}

export const timezoneTagName = (abbr) => {
  switch (abbr) {
    case 'PDT':
      return 'PT'
    case 'EDT':
      return 'ET'
    case 'ADT':
      return 'AT'
    case 'MDT':
      return 'MT'
    case 'NDT':
      return 'NT'
    case 'CDT':
      return 'CT'
    default:
      return abbr
  }
}

export const timezoneArea = (offset) => {
  switch (offset) {
    case "+0530":
      return "Asia/Kolkata"; // IST
    case '-0500':
      return 'America/Chicago' // CST
    case '-0600':
      return 'America/Denver' // MST
    case '-0400':
      return 'America/New_York' // EST
    case '-0700':
      return 'America/Los_Angeles' // PT (480)
    case '-0230':
      return 'America/St_Johns' // NT
    case '-0300':
      return 'America/Blanc-Sablon' // AT
      default:
        return 'Asia/Kolkata'
  }
}

export const videoExtensionType = (extension) => {
  switch (extension) {
    case 'm3u8':
      return 'application/x-mpegURL'
    default:
      return 'video/mp4'
  }
}

export const applyTheme = (branding) => {
  $('body').addClass('cus-bro-theme')
  $("html").css(
    "--primary-brokarage-color",
    branding.primary_btn_color
  );
  $("html").css(
    "--secondary-brokarage-color",
    branding.secondary_btn_color
  );
  $("html").css(
    "--icon-color",
    branding.icon_color
  );
  $("html").css(
    "--currntweek",
    branding.currwk_header_color
  );
  $("html").css(
    "--past-week",
    branding.ptwk_header_color
  );
  $("html").css(
    "--future-week",
    branding.ftwk_header_color
  );
  $("html").css(
    "--footer-bg-color",
    branding.footer_bg_color
  );
  $("html").css(
    "--footer-text-color",
    branding.footer_text_color
  );
  
  branding.course_launch_url && $(".cus-bro-theme .video_img").css('background', 'url(' + branding.course_launch_url + ')')
  return true;
}

export const UserIsLoggedIn = () => {
  LOGIN_CHANNEL.postMessage({login: true})
}

export const IsLogin = () => {
  LOGIN_CHANNEL.onmessage = (event) => {
    if (event.data && event.data?.login) {
      window.location.reload();
    }
  }
}

export const calculateRolePlayScore = (data) => {
  let point = 0;
  Object.keys(data).forEach((d) => {
      point = point + _.toNumber(data[d])
  })
  return point;
}

export const fixedToDecimal = (value, decimalValue) => {
  return _.toNumber(value).toFixed(decimalValue)
}

export const rolePlayStatus = (type, role='participant') => {
  switch (type) {
    case "draft":
      return (<span className="pfrpending draftrev">Draft</span>)
    case "pending_review":
      return (<span className="pfrpending">Pending Review</span>)
    case "feedback_received":
      return (
        <span className="frdone">Feedback {role === 'participant' ? 'Received' : 'Provided'}</span>
      )
  }
}

export const starRatingCaptions = (rating) => {
  if (rating >= 1 && rating < 2) {
    return "Give it another go!";
  } else if (rating >= 2 && rating < 3) {
    return "You're almost there!";
  } else if (rating >= 3) {
    return "You nailed it!"
  }
}

export const getDateTime = (time,userDetails) => {

  if(time===undefined){
    return '- -'
  }

  const timezoneOffset = !_.isEmpty(userDetails.timezone) ?
      userDetails.timezone.replace(':', '') : '-0500'
  const area = timezoneArea(timezoneOffset)
  var today = moment.unix(time).tz(area)?.format('MMM DD YYYY h:mm A')
  return today;
}


export const getFormatCourseListFromWoo = (course,isPremiumUser)=>{
  // for (let course of courseList) {
    course.is_purchased = course.is_paid;
    course.showBuy = course.is_purchased ? false : true;
    course.is_bundle = false;
    course.is_free = false;
    if (course?.sfdc_product_type?.toLowerCase() === "basic") {
      course.showBuy = false;
      course.is_paid = true;
      course.is_free = true;
    } else if (
      course?.sfdc_product_type?.toLowerCase() === "premium"
    ) {
      if (isPremiumUser) {
        course.showBuy = false;
        course.is_paid = true;
        course.is_free = true;
      } else {
        if (course.product_offer_for_basic) {
          course.sale_price = 0.0;
          course.regular_price = 0.0;
        } else {
          course.sale_price = course.product_basic_price;
          course.regular_price = course.product_market_price;
        }
      }
    } else if (
      course?.sfdc_product_type?.toLowerCase() === "alacarte"
    ) {
      if (isPremiumUser) {
        if (course.product_offer_for_premium) {
          course.sale_price = 0.0;
          course.regular_price = 0.0;
        } else {
          course.sale_price = course.product_premium_price;
        }
      } else {
        if (course.product_offer_for_basic) {
          course.sale_price = 0.0;
          course.regular_price = 0.0;
        } else {
          course.sale_price = course.product_basic_price;
        }
      }
      course.regular_price = course.product_market_price;
    } else if (
      course?.sfdc_product_type?.toLowerCase() === "bundle"
    ) {
      course.is_bundle = true;
      for (let cl of course.course_list) {
        cl.source = course.source;
        cl.is_free = course.is_free
      }
    }
    if (course?.sfdc_product_type?.toLowerCase() !== "bundle") {
      if (course.durationUnit === "Hours") {
        course.duration = convertHoursToMins(course.duration);
      }
      course.duration = convertTimeFormat(
        _.toNumber(course.duration)
      );
      course.durationUnit = "";
    } else {
      // console.log('Duration', course)
      for (let cl of course.course_list) {
        if (cl.durationUnit === "Hours") {
          cl.duration = convertHoursToMins(cl.duration);
        }
        cl.duration = convertTimeFormat(_.toNumber(cl.duration));
        cl.durationUnit = "";
      }
    }
  // }
  return course;
}

export const formatLPCoursesList = (learning) => {
  if (learning.coursesList) {
    learning.is_manager_checklist = false;
    learning.newCourseList = []
    for (let [index, course] of learning.coursesList.entries()) {
        let currentCourse = course;
        let nextCourse = learning.coursesList[index + 1];
        if (learning.sequence === 1) {
            if (index === 0) {
                currentCourse.isLock = false;
            }
            currentCourse.isLock = currentCourse.isLock == false ? currentCourse.isLock : true;
            if (currentCourse.crs_progress > 0) {
                currentCourse.isLock = false;
                if (index != learning.coursesList.length - 1) {
                    nextCourse.isLock = _.toNumber(currentCourse.crs_progress) >= 100 ? false : true;
                }
            }
        } else {
            currentCourse.isLock = currentCourse.isLock ? currentCourse.isLock : false;
        }
        currentCourse.duration = convertTimeFormat(_.toNumber(currentCourse.duration));
        currentCourse.durationUnit = ''
        // console.log("detaiiii", course)
        if (index < learning.coursesList.length - 1) {
            if (!currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present) {
                nextCourse.isLock = nextCourse.crs_progress == 0 ? true : false
            } else if ((currentCourse?.is_checklist_approved && currentCourse?.is_checklist_present)) {
                nextCourse.isLock = false
            }

            if (currentCourse.is_checklist_present) {
                learning.is_manager_checklist = true;
            }
        }
    }
    for (let course of learning.coursesList) {
        course.is_manager_checklist = learning.is_manager_checklist
    }
    // learning.courseList[0].isLock = false;

    for (let course of learning.newCourseList) {
      course.is_manager_checklist = learning.is_manager_checklist
      if (course.durationUnit === "Hours") {
        const convertArray = _.toString(course.duration).includes(":")
          ? course.duration.split(":")
          : [course.duration, 0];
        const hoursMinutes = _.toNumber(convertArray[0]) * 60;
        const minutes = _.toNumber(convertArray[1]);
        const totalMinutes = hoursMinutes + minutes;
        course.duration = totalMinutes;
      }
      if (_.toNumber(course.duration) > 60) {
        let hours = Math.floor(course.duration / 60);
        let minutes = course.duration % 60;
        hours = hours >= 10 ? hours : `0${hours}`;
        minutes = minutes >= 10 ? minutes : `0${minutes}`;
        // console.log(course.duration, hours, minutes)
        // course.duration = `${hours}:${minutes}`
        course.duration = `${hours} ${hours > 1 ? "Hours" : "Hour"
          } ${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
        course.durationUnit = "";
      } else if (_.toNumber(course.duration) < 60) {
        // console.log('Minutes')
        course.duration = `${course.duration} ${course.duration > 1 ? "Mins" : "Min"
          }`;
        course.durationUnit = "";
      } else {
        // console.log('hour')
        course.duration = "1 Hour";
        course.durationUnit = "";
      }
    }
  }
  return learning;
}