import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clickStreamUser } from "../../utils/AppUtils";
import _ from "lodash"
import CONFIG from "../../utils/config";

const RolePlayTabSection = (props) => {

    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');
    const user_id = params.get('user_id');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const role = params.get('role');

    const authBroker = useSelector(state => state.auth.authBroker)
    const [roleArray, setRoleArray] = useState([])
    const [mobileURL, setMobileURL] = useState('')

    useEffect(() => {
        if (!_.isEmpty(authBroker)) {
            setRoleArray(authBroker.payload.user.role)
        }
    }, [authBroker])

    useEffect(() => {
        if (props.isMobile) {
            let URL = `color_1=${color_1}&color_2=${color_2}&username=${username}&user_id=${user_id}&role=${role}&key=${key}&token=${token}`
            console.log(URL)
            setMobileURL(URL)
        }
    }, [props.isMobile])


    const redirectTo = (page) => {

        switch (page) {
            case 'role-play':
                clickStreamUser('participate-roleplay-tab-clicked', {
                    user_id: authBroker?.payload.user?.user_id
                })
                props.isMobile ?
                    history.push(`/role-play-mobile?${mobileURL}`) :
                    history.push('/role-play')
                break;
            case 'review':
                clickStreamUser('review-tab-clicked', {
                    user_id: authBroker?.payload.user?.user_id
                })

                props.isMobile ?
                    history.push(`/review-role-play-mobile?${mobileURL}`) :
                    history.push('/review-role-play')
                break;
            case 'my-roleplay':
                clickStreamUser('my-roleplay-tab-clicked', {
                    user_id: authBroker?.payload.user?.user_id
                })
                props.isMobile ?
                    history.push(`/my-role-play-mobile?${mobileURL}`) :
                    history.push('/my-role-plays')
                break;
        }

    }

    return (
        <>
            <section className="secrpmailtab">
                <div className="roleplaypagetabsec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="xseltabsnav d-flex justify-content-start">
                                    {/* <button 
                                className={`btn-primary ${props.page === 'participate' ? 'active-page' : ''}`}
                                onClick={() => {
                                    if (props.page != 'participate') {
                                        redirectTo('role-play')
                                    }
                                }}
                                >
                                    Record a New Role play
                                </button> */}
                                    <button
                                        className={`tabroleplay ${props.page === 'roleplay' ? 'active-page' : ''}`}
                                        onClick={() => {
                                            if (props.page != 'roleplay') {
                                                redirectTo('my-roleplay')
                                            }
                                        }}
                                    >
                                        {/* My Role play */}
                                        My Participation Summary
                                    </button>
                                    {roleArray.includes('coach') ? <button
                                        className={`tabroleplay ${props.page === 'review' ? 'active-page' : ''}`}
                                        onClick={() => {
                                            redirectTo('review')

                                        }}
                                    >
                                        {/* For You to Review */}
                                        My Coaching Summary
                                    </button> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="sepratortabbtm"></span>
            </section>
        </>
    )
}

export default RolePlayTabSection;