import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from 'react-redux';
import Loader from "../../components/Loader/Loader";
import RolePlayPageSection from "../../components/RolePlay/RolePlayPageSection";
import CONFIG from '../../utils/config';
import { loginBrokerResponse } from '../../redux/auth/actions';
import { setUserRole } from '../../redux/user/actions';
import $ from "jquery";
import { clickStreamUser, SessionKeys } from "../../utils/AppUtils";

const RolePlayMobile = () => {

    const dispatch = useDispatch()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const key = params.get('key');
    const username = params.get('username');
    const user_id = params.get('user_id');
    const color_1 = params.get('color_1');
    const color_2 = params.get('color_2');
    const role = params.get('role');

    const [isLoading, setIsLoading] = useState(true)
    const [isValidKey, setIsValidKey] = useState(false)

    useEffect(() => {
        if (document.getElementById("id_page_404") !== null) {
          document.getElementById("id_page_404").style.display = "none";
        }
      }, [])

    useEffect(() => {
        console.log('key', params.get('key'))
        if (key === CONFIG.KEY.MOBILE_ACCESS_KEY) {
            $('html').css("--color-element-1", color_1 ? '#' + color_1 : CONFIG.COLOR_ELEMENT_1);
            $('html').css("--color-element-2", color_2 ? '#' + color_2 : CONFIG.COLOR_ELEMENT_2);
            localStorage.setItem(SessionKeys.API_TOKEN_BROKER, token);
            localStorage.setItem(SessionKeys.USERNAME, username);
            setIsValidKey(true)
            dispatch(setUserRole(role))
            dispatch(loginBrokerResponse({
                payload: {
                    user: {
                        user_id: user_id,
                        username: username,
                        role: [role]
                    },
                    apiToken: token
                }
            }))
            clickStreamUser('roleplay_mobile_page', {
                userId: user_id,
            })
        }
    }, [token, key, user_id, dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            if (document.getElementById("cc_div") !== null)
                document.getElementById("cc_div").style.display = "none";
        }, 100)
    }, [])

    return (
        <>
            {isValidKey ?
                <>
                    <Loader start={isLoading} />
                    <RolePlayPageSection
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        isMobile={true}
                    />
                </>
                :
                <div>
                    Invalid Key
                </div>
            }
        </>
    )
}

export default RolePlayMobile