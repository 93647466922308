import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartSection from "../../components/Cart/CartSection";
import Page from "../../components/Page";
import { clickStreamUser } from "../../utils/AppUtils";
import Loader from "../../components/Loader/Loader";

const CartPage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const myCartRes = useSelector(state => state.carts.myCart)

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        clickStreamUser('cart_page', {
            amount: '',
            userId: auth.authBroker?.payload?.user.user_id,
        })
    }, [dispatch])

    useEffect(() => {
        if (!_.isEmpty(myCartRes)) {
            setIsLoading(false);
        }
    }, [myCartRes])


    return (
        <>
            <Loader start={isLoading} />
            <Page>
                <CartSection 
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </Page>
        </>
    )
}

export default CartPage;