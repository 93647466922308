import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showBuyModal } from "../../redux/courses/actions";
import { CircularProgress } from "@mui/material";
const BuyProductModal = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const course = useSelector(state => state.courses.showBuyModal)
  const objCourse = course?.objCourse

  var arrList = ["Rishabh", "Shradesh", "Sunil", "Rahul", "Kunal", "Rishabh", "Shradesh", "Sunil", "Rahul", "Kunal", "Rishabh", "Shradesh", "Sunil", "Rahul", "Kunal"]
  return (
    <Modal show={course?.isShow} onHide={() => dispatch(showBuyModal({ isShow: false }))} >
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: '800px', 'marginLeft': '-100px' }}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body custom_modal" style={{ backgroundColor: "white" }}>

              <div className="row">
                <div className="header-border_modal"></div>
                <div className="col-md-6 modal_popup_img modal_btnleft">
                  <button className="btn-modal" onclick="window.location.href='#';">Add to cart</button>
                  <button className="btn-modal" onclick="window.location.href='realtor_safety.html';">Checkout Now</button>

                </div>
                <div className="col-md-6 col-sm-12">

                  <div className="color_main_modal">   <img src="images/LOGO_head.png" width="76" height="44" /><h6>{objCourse?.name}</h6> </div>
                  <div className="cou_cardsearch_modal">

                    <p>{objCourse?.lessons} lessons, Duration: {objCourse?.duration} {objCourse?.durationUnit}<br /> {objCourse?.views} Views</p>


                  </div>
                  <div className="courseDes_icon">

                    <a href="#">  <img src="images/icon001.png" width="25px" height="25px" alt="" /></a>
                    <a href="#"> <img src="images/head_phone002.png" width="25px" height="25px" alt="" /></a>
                    <a href="#">  <img src="images/icon003.png" width="25px" height="25px" alt="" /></a>
                  </div>
                  <div className="modal--content">
                    <p><span style={{ "font-weight": "600", "color": "black" }}> Mauris tristique ante quis turpis dapibus,</span> A pellentes lectus auctor. Aliquam erat volutpat. Donec sagittis vehicula est ut tempor. Etiam cursus suscipit leo id fringilla. Praesent lobortis risus at sodales commodo. Curabitur ultrices nunc felis. Fusce egestas tincidunt sapien et congue.</p>
                    <p>Aliquam condimentum odio non augue elementum malesuada. Proin vehicula rutrum malesuada. Morbi quis tempor erat. Ut purus orci, ultrices ut fringilla accumsan, eleifend finibus sem. Suspendisse porttitor ac libero a egestas. Mauris sed aliquam nisi.</p>
                    <h6>$ 29.99</h6>
                  </div>
                  <div className="modal_btnright">
                    <button className="btn-modal_right_sec" onclick="window.location.href='#';">More Content like this</button>
                    <button className="btn-modal_right_sec" onclick="window.location.href='#';">Upgrade Subscription $ 10.99/m</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default BuyProductModal;