import React, { useEffect } from 'react'

import Footer from "./Footer"
import FooterNew from './FooterNew'
import HeaderNewMenu from './HeaderNewMenu'
import HeaderSearch from "./HeaderSearch"
import Loader from "../components/Loader/Loader"

const Page = (props) => {

  const [isLoading, setIsLoading] = React.useState(false)
  useEffect(() => {
    if (document.getElementById("id_page_404") !== null) {
      document.getElementById("id_page_404").style.display = "none";
    }
  }, [])
  return (
    <div className="page-wrapper-rsp">
      <Loader start={isLoading} setIsLoading={setIsLoading} />
      <div className="rspinnerwrapper">
        {/* <HeaderSearch /> */}
        <HeaderNewMenu
          showMenu={true}
        />
        {/* <HeaderMenu /> */}
        {props.children}
      </div>
      {/* <Footer /> */}
      <FooterNew />
    </div>
  )
}

export default Page